import { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { translation } from "constants/translation";
import Image from "components/Image/Image";
import ModuleTag from "components/ModuleTag/ModuleTag";
import { formatDateToYYYYMMDD } from "helper/helper";
import { deletePortfolioObservation, updatePortfolioObservation } from "api/portfolio-api";
import { API_Child } from "types/portfolio";
import useStore from "store/store";
import { Action } from "containers/SchoolProfilePage/SchoolProfileComponents";
import Logo from "images/logo-2.png";

//warning
import useDialog from "hooks/useDialog";
import useSnack from "hooks/useSnack";
import WarningDialog from "components/Dialog/WarningDialog";

const SinglePortfolioObservationComponentForEdit = ({
  childPortfolioObservation,
}: {
  childPortfolioObservation: API_Child.PortfolioObservation;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { setIsRefreshPortfolioObservation } = useStore((state) => ({
    setIsRefreshPortfolioObservation: state.setIsRefreshPortfolioObservation,
  }));

  const openSnack = useSnack();
  const [updatedChildPortfolioObservation, setUpdatedChildPortfolioObservation] =
    useState<API_Child.PortfolioObservation>(childPortfolioObservation);

  useEffect(() => {
    setUpdatedChildPortfolioObservation(childPortfolioObservation);
  }, [
    childPortfolioObservation.caption,
    childPortfolioObservation.is_published,
    childPortfolioObservation.image,
  ]);

  const handleVisibilityChange = async () => {
    const res = await updatePortfolioObservation({
      id: updatedChildPortfolioObservation.id,
      image: updatedChildPortfolioObservation.image,
      is_published: !updatedChildPortfolioObservation.is_published,
    });
    // console.log("res: ", res);
    if (typeof res !== "string") {
      setUpdatedChildPortfolioObservation({
        ...updatedChildPortfolioObservation,
        is_published: res.is_published,
      });
      openSnack(`${res.is_published ? "Published!" : "Un-published!"}`, true);
      setIsRefreshPortfolioObservation(true);
    }
  };
  const initialFormData = {
    id: updatedChildPortfolioObservation.id,
    image: updatedChildPortfolioObservation.image,
    caption: updatedChildPortfolioObservation.caption,
    is_published: updatedChildPortfolioObservation.is_published,
  };

  const [formData, setFormData] = useState<API_Child.UpdatePortfolioObservation>(initialFormData);

  //Edit action button
  const [currImage, setCurrImage] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const hiddenText = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hiddenText.current) {
      setWidth(hiddenText.current.offsetWidth + 10);
    }
  }, [formData.caption, updatedChildPortfolioObservation.caption]);

  const handleEdit = () => {
    setFormData((prev: API_Child.UpdatePortfolioObservation) => ({
      ...prev,
      caption: updatedChildPortfolioObservation.caption ? updatedChildPortfolioObservation.caption : "",
    }));
    setEdit(true);
  };

  const handleCancel = () => {
    setFormData((prev: API_Child.UpdatePortfolioObservation) => ({
      ...prev,
      caption: "",
      image: updatedChildPortfolioObservation.image,
    }));
    setCurrImage("");
    setEdit(false);
  };

  const handleSubmit = async () => {
    const res = await updatePortfolioObservation(formData);

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      handleCancel();
      setUpdatedChildPortfolioObservation({
        ...updatedChildPortfolioObservation,
        image: res.image,
        caption: res.caption,
      });
      setIsRefreshPortfolioObservation(true);
    }
  };

  // Delete button
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleDelete = async () => {
    const res = await deletePortfolioObservation(childPortfolioObservation.id);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.deleteSuccess, true);
      setIsRefreshPortfolioObservation(true);
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete Observation with caption ${childPortfolioObservation.caption}?`,
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isXs ? "column" : "row",
            gap: 1,
            opacity: updatedChildPortfolioObservation.is_published ? 1 : 0.6,
            width: "100%",
          }}
          onClick={() => {
            console.log(childPortfolioObservation)
          }}
        >
          <Box
            style={{
              minWidth: isXs ? 150 : 250,
              maxWidth: isXs ? 150 : 250,
              minHeight: isXs ? 250 : 150,
              maxHeight: isXs ? 250 : 350,
              position: "relative",
            }}
          >
            <Image
              style={{
                minWidth: isXs ? 150 : 250,
                maxWidth: isXs ? 150 : 250,
                maxHeight: isXs ? 150 : 250,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              src={
                currImage
                  ? currImage
                  : updatedChildPortfolioObservation.image
                    ? updatedChildPortfolioObservation.image
                    : Logo
              }
              alt={"No images"}
            />
            {/* if return number which is -1, means it's not derived from project */}
            {typeof childPortfolioObservation.project === "number" && (
              <>
                <input
                  accept="image/*"
                  type="file"
                  id={`observation-image-button-for-edit-${childPortfolioObservation.id}`}
                  hidden
                  onChange={(event) => {
                    if (event.target.files && event.target.files.length > 0) {
                      setCurrImage(URL.createObjectURL(event.target.files![0]));
                      setFormData((prev: API_Child.UpdatePortfolioObservation) => ({
                        ...prev,
                        image: event.target.files![0],
                      }));
                      handleEdit();
                    }
                  }}
                />

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(128, 128, 128, 0)",
                    // backgroundColor: 'purple',
                    transition: "all 150ms linear",
                    "&:hover": {
                      transition: "all 150ms linear",
                      backgroundColor: "rgba(128, 128, 128, 0.7)",
                    },
                    maxWidth: isXs ? 150 : 250,
                  }}
                >
                  <label htmlFor={`observation-image-button-for-edit-${childPortfolioObservation.id}`}>
                    <IconButton
                      component="span"
                      sx={{
                        "&:hover": {
                          backgroundColor: "inherit",
                        },
                      }}
                    >
                      <PhotoCameraIcon
                        sx={{
                          fontSize: { xs: "5vw", sm: "2.5vw" },
                          color: "primary.light",
                          cursor: "pointer",
                          transition: "all 50ms linear",
                          opacity: 0.4,
                          "&:hover": {
                            opacity: 0.8,
                            // color: "txt.secondary",
                            transition: "all 50ms linear",
                          },
                        }}
                      />
                    </IconButton>
                  </label>
                </Box>
              </>
            )}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontWeight: 600 }}>{translation.caption}:</Typography>
            <Box
              sx={{
                display: "flex",
                mb: { xs: 1, sm: 2.5 },
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                ref={hiddenText}
                sx={{
                  position: "absolute",
                  opacity: 0,
                  pointerEvents: "none",
                }}
              >
                {edit
                  ? formData.caption
                  : updatedChildPortfolioObservation.caption
                    ? updatedChildPortfolioObservation.caption
                    : translation.noCaption}
              </Typography>

              <TextField
                placeholder={translation.name}
                variant="standard"
                value={
                  edit
                    ? formData.caption
                    : updatedChildPortfolioObservation.caption
                      ? updatedChildPortfolioObservation.caption
                      : translation.noCaption
                }
                onChange={(e) =>
                  setFormData((prev: API_Child.UpdatePortfolioObservation) => ({
                    ...prev,
                    caption: e.target.value,
                  }))
                }
                InputProps={{
                  readOnly: !edit,
                  disableUnderline: !edit,
                }}
                inputProps={{
                  style: {
                    height: "auto",
                    padding: 0,
                    width: width,
                    minWidth: edit ? "65px" : 0,
                    maxWidth: edit ? "40vw" : "40vw",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
              />

              <Action
                edit={edit}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                handleEdit={handleEdit}
              />
            </Box>

            <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
              <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{translation.dateObserved}:</Typography>
              <Typography sx={{ color: "primary.main", fontSize: 14 }}>
                {`${formatDateToYYYYMMDD(updatedChildPortfolioObservation.completed_at)}`}
              </Typography>
            </Box>
            {typeof updatedChildPortfolioObservation.project !== "number" && (
              <>
                <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
                  <ModuleTag
                    projModule={updatedChildPortfolioObservation.project.module}
                    styles={{ mb: 0 }}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
                  <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{translation.project}:</Typography>
                  <Typography
                    sx={{
                      maxWidth: { lg: "40vw", sm: "30vw", xs: "20vw" },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {updatedChildPortfolioObservation.project.name}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{ position: "absolute", right: 0 }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* only able to delete observation */}
          {typeof updatedChildPortfolioObservation.project === "number" && (
            <IconButton onClick={handleOpenDialog} size="small">
              <DeleteIcon />
            </IconButton>
          )}

          <FormControlLabel
            control={
              <Switch
                checked={updatedChildPortfolioObservation.is_published}
                onChange={() => {
                  handleVisibilityChange();
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              isXs
                ? ""
                : updatedChildPortfolioObservation.is_published
                  ? translation.visible
                  : translation.hidden
            }
            sx={{ marginRight: 0 }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SinglePortfolioObservationComponentForEdit;

import React, { useEffect, useState, Fragment } from "react";

import { useNavigate } from "react-router-dom";

import useStore from "store/store";
import { translation } from "constants/translation";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Button as MuiButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import useSnack from "hooks/useSnack";
import Autocomplete from "@mui/material/Autocomplete";
import {
  API_Create_Schedule,
  API_Group_Calendar,
  ProjectBoxProps,
} from "types/calendar";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import {
  createSchedule,
  getGroupCalendar,
  updateScheduleDatetime,
} from "api/calendar-api";
import Button from "components/Button/Button";
import { API_Project } from "types/project";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Logo from "images/logo-2-colour.png";
import { DATETIME_FORMAT } from "./constants/constants";

import { getProjectBoxObjects } from "./constants/helper";
import NavigateProjectComponent from "./CalendarSection/CalendarBodyComponents/RightColumnComponents/NavigateProjectComponent";
import { populateScheduleObject } from "helper/project";

const ScheduleGroupPageSimpleMobile = ({
  projectId,
  projectTitle,
  groupId,
  scheduleId,
  scheduleDatetimeId,
  handleClose,
  preDefinedStartAt,
  preDefinedEndAt,
  handleOpenDialog,
  projectSlug,
  isUpdate,
  projectModuleName,
  projectImage,
  setProjectCard,
  setBackpackOpen,
}: {
  projectId: number;
  projectTitle: string;
  groupId: number;
  scheduleId?: number;
  scheduleDatetimeId?: number;
  handleClose: () => void;
  preDefinedStartAt?: string;
  preDefinedEndAt?: string;
  handleOpenDialog?: () => void;
  projectSlug?: string;
  isUpdate?: boolean;
  projectModuleName: string;
  projectImage: string;
  setProjectCard: any;
  setBackpackOpen: any;
}) => {
  const navigate = useNavigate();

  const {
    currentProject,
    currLanguage,
    calendarDates,
    currGroup,
    groupProjectBoxProps,
    setGroupProjectBoxProps,
    setRefreshProjectBox,
    setRefreshGroupLessonInfoList,
    setCurrScheduleObj,
    setLessonLearningMomentCheckpointCount,
  } = useStore((state) => ({
    currentProject: state.currentProject,
    currLanguage: state.currLanguage,
    calendarDates: state.calendarDates,
    currGroup: state.groupList.filter((group) => group.id === groupId)[0],
    groupProjectBoxProps: state.groupProjectBoxProps,
    setGroupProjectBoxProps: state.setGroupProjectBoxProps,
    setRefreshProjectBox: state.setRefreshProjectBox,
    setRefreshGroupLessonInfoList: state.setRefreshGroupLessonInfoList,
    setCurrScheduleObj: state.setCurrScheduleObj,
    setLessonLearningMomentCheckpointCount:
      state.setLessonLearningMomentCheckpointCount,
  }));

  // console.log(projectSlug);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const initialState = {
    project_id: projectId,
    group_id: groupId,
    start_at: preDefinedStartAt
      ? preDefinedStartAt
      : moment().format(DATETIME_FORMAT),
    end_at: preDefinedEndAt
      ? preDefinedEndAt
      : moment().add(7, "days").format(DATETIME_FORMAT),
  };

  const [formData, setFormData] = useState<API_Create_Schedule>(initialState);

  const [startAt, setStartAt] = useState<Date | null>(
    new Date(initialState.start_at)
  );
  const [endAt, setEndAt] = useState<Date | null>(
    new Date(initialState.end_at)
  );

  const openSnack = useSnack();

  const handleSubmit = async () => {
    const res = await createSchedule(formData);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      let updatedProjectBoxes: ProjectBoxProps[] = [];
      res.schedules.map((schedule) => {
        const temp_schedules = getProjectBoxObjects(schedule, calendarDates);
        updatedProjectBoxes = [...updatedProjectBoxes, ...temp_schedules];
      });

      setGroupProjectBoxProps({
        ...groupProjectBoxProps,
        [groupId]: updatedProjectBoxes,
      });
      openSnack("Scheduled!", true);
      setRefreshProjectBox(true);
      setRefreshGroupLessonInfoList(true);
      populateScheduleObject(
        currLanguage,
        formData.group_id,
        formData.project_id,
        setCurrScheduleObj,
        setLessonLearningMomentCheckpointCount
      );
      setProjectCard({});
      setBackpackOpen(false);
      handleClose();
    }
    return res;
  };

  const handleUpdate = async () => {
    if (scheduleDatetimeId && scheduleId) {
      const res = await updateScheduleDatetime(
        scheduleDatetimeId,
        scheduleId,
        formData.start_at,
        formData.end_at
      );
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        const updatedProjectBoxProps = groupProjectBoxProps[groupId.toString()];
        const index = updatedProjectBoxProps.findIndex(
          (item) => item.id === scheduleDatetimeId
        );

        const projectBox = updatedProjectBoxProps[index];
        projectBox.startAt = formData.start_at;
        projectBox.endAt = formData.end_at;

        setGroupProjectBoxProps({
          ...groupProjectBoxProps,
          [groupId.toString()]: updatedProjectBoxProps,
        });

        setRefreshProjectBox(true);

        openSnack("Updated!", true);
        handleClose();
        setProjectCard({});
      }
      return res;
    }
  };

  // console.log(preDefinedStartAt);
  return (
    <Stack
      component="form"
      sx={{
        width: "100%",
        minWidth: "250px",
        alignItems: "center",
      }}
      spacing={1}
      noValidate
      autoComplete="off"
    >
      {!isUpdate && (
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          {translation.formatString(translation.scheduleModalTagline, <br />)}
        </Typography>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Avatar
          src={typeof projectImage === "string" ? projectImage : Logo}
          sx={{
            width: isUpdate ? "56px" : "101px",
            height: isUpdate ? "56px" : "101px",
            backgroundColor: "primary.main",
            color: "txt.light",
            border: "2px solid",
            borderColor: "primary.main",
          }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        />
      </Box>

      {formData.project_id !== -1 ? (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
            width: "100%",
            // px: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              //   variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              {projectModuleName}
              {/* {isUpdate && handleOpenDialog && (
                <DeleteScheduleObjectComponent
                  handleOpenDialog={handleOpenDialog}
                  inModal={true}
                />
              )} */}

              {isUpdate && projectSlug && (
                <NavigateProjectComponent
                  projectSlug={projectSlug}
                  inModal={true}
                />
              )}
            </Typography>
            <Typography
              //   variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {projectTitle}
            </Typography>
          </Box>

          <LocalizationProvider
            dateAdapter={AdapterMoment}
            sx={{ backgroundColor: "pink" }}
          >
            <DatePicker
              inputFormat={DATETIME_FORMAT}
              label={translation.startDate}
              value={startAt}
              onChange={(newValue: Date | null) => {
                setStartAt(newValue);
                setFormData((prev) => ({
                  ...prev,
                  start_at:
                    newValue !== null
                      ? moment(newValue).format(DATETIME_FORMAT)
                      : "",
                }));
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {params?.InputProps?.endAdornment}
                        <InputAdornment position="end">
                          <InsertInvitationIcon />
                        </InputAdornment>
                      </Fragment>
                    ),
                  }}
                />
              )}
            />

            <DatePicker
              inputFormat={DATETIME_FORMAT}
              label={translation.endDate}
              value={endAt}
              onChange={(newValue: Date | null) => {
                setEndAt(newValue);
                setFormData((prev) => ({
                  ...prev,
                  end_at:
                    newValue !== null
                      ? moment(newValue).format(DATETIME_FORMAT)
                      : "",
                }));
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {params?.InputProps?.endAdornment}
                        <InputAdornment position="end">
                          <InsertInvitationIcon />
                        </InputAdornment>
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>

          {/* <Button
            buttonText={
              preDefinedStartAt ? translation.update : translation.submit
            }
            disabled={formData.project_id === -1}
            onClick={preDefinedStartAt ? handleUpdate : handleSubmit}
          /> */}
          {isUpdate ? (
            <Box
              sx={{
                // backgroundColor: "pink",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <DeleteOutlineIcon
                sx={{ width: "10%", color: "#FF5D53" }}
                onClick={handleOpenDialog}
              />
              <MuiButton
                onClick={() => {
                  setProjectCard({});
                  handleClose();
                }}
                sx={{
                  textTransform: "none",
                  fontWeight: 450,
                  fontSize: "16px",
                  width: "45%",
                }}
              >
                Cancel
              </MuiButton>
              <MuiButton
                sx={{
                  color: "white",
                  fontWeight: 450,
                  fontSize: "16px",
                  textTransform: "none",
                  width: "45%",
                }}
                variant="contained"
                onClick={handleUpdate}
              >
                Update
              </MuiButton>
            </Box>
          ) : (
            <Box
              sx={{
                // backgroundColor: "pink",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <MuiButton
                onClick={() => {
                  handleClose();
                  setProjectCard({});
                }}
                sx={{
                  textTransform: "none",
                  fontWeight: 450,
                  fontSize: "16px",
                  width: "50%",
                }}
              >
                Cancel
              </MuiButton>
              <MuiButton
                sx={{
                  width: "50%",
                  color: "white",
                  fontWeight: 450,
                  fontSize: "16px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={handleSubmit}
              >
                Add Project
              </MuiButton>
            </Box>
          )}
        </Box>
      ) : (
        <LoadingIndicator />
      )}
    </Stack>
  );
};

const ProjectForm = ({
  selectedProject,
  setSelectedProject,
  projects,
  disabled,
}: {
  selectedProject: string;
  setSelectedProject: (project: string) => void;
  projects: API_Project[];
  disabled: boolean;
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedProject(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth required>
        <InputLabel>{translation.project}</InputLabel>
        <Select
          value={selectedProject}
          label={translation.project}
          onChange={handleChange}
          disabled
        >
          {projects.map((project) => {
            return (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ScheduleGroupPageSimpleMobile;

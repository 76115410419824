import { useState } from 'react';
import { Box } from '@mui/material';
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import VideoDurationDisplay from "../VideoDurationDisplay";
import CustomImage from "../../Image/Image";
import type { Image } from "./types";
import Logo from "images/logo-2.png";

import TextOverlay from './TextOverlay';
import {
  secondToMinuteAndSecondString,
} from "helper/helper";

type ImageProps = {
  image: Image;
  onClick: () => void;
  isForLearningMomentTab: boolean;
  textOverlay?: string;
};

const ImageCard = ({ image, isForLearningMomentTab, textOverlay, onClick }: ImageProps) => {
  const [isHovered, setIsHovered] = useState(false);
  // const size = isForLearningMomentTab ? 100 : 120;
  const size = 120

  let isVideo = false;

  // Check if image.src exists and is a string
  if (typeof image.src === 'string') {
    isVideo = image.src.includes(".mp4") || image.src.includes(".mov");
  } else {
    console.warn('No image source provided.');
    // Optionally, set a default value or handle the absence of image.src differently here
  }

  const isLearningMoment = String(image.key).includes(
    "learning-moment"
  );
  const videoDuration: any = document.getElementById(
    String(image.key)
  );

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        width: size,
        height: size,
        borderRadius: "10px",
      }}
    >
      {isLearningMoment && !isForLearningMomentTab && (
        <Box sx={{ position: "absolute", top: 5, left: 5, zIndex: 99 }}>
          <LightbulbIcon color="primary" />
        </Box>
      )}
      {isVideo ? (
        <Box sx={{ width: "inherit", height: "inherit" }}>
          <video
            width="100%"
            id={String(image.key)}
            height="100%"
            preload="metadata"
            style={{
              objectFit: "cover",
              borderRadius: "10px",
              width: size,
              height: size,
            }}
          >
            <source src={image.src} type="video/mp4" />
          </video>
          <Box sx={{ position: "absolute", bottom: 5, left: 5, zIndex: 99 }}>
            {videoDuration && (
              <VideoDurationDisplay
                duration={
                  isNaN(videoDuration.duration)
                    ? ""
                    : secondToMinuteAndSecondString(videoDuration.duration)
                }
              />
            )}
          </Box>
        </Box>
      ) : image.src ? (
        <CustomImage
          src={image.src}
          objectFit="cover"
          style={{
            width: size,
            height: size,
            borderRadius: "10px",
          }}
          alt="secondary"
        />
      ) : (
        <CustomImage
          src={Logo}
          objectFit="cover"
          style={{
            width: size,
            height: size,
            borderRadius: "10px",
          }}
          alt="secondary"
        />
      )}
      <TextOverlay text={textOverlay ?? image.alt} show={isHovered || !!textOverlay} isForLearningMomentTab={isForLearningMomentTab} />
    </Box>
  );
};

export default ImageCard;

import { populateScheduleObject } from "helper/project";
import { useEffect } from "react";
import useStore from "store/store";

export const usePopulateScheduleObject = () => { 
  const {
    currGroup,
    currentProject,
    currLanguage,
    setCurrScheduleObj,
    setLessonLearningMomentCheckpointCount,
  } = useStore((state) => state);

  useEffect(() => {
    if (currentProject.id !== -1) {
      populateScheduleObject(
        currLanguage,
        currGroup.id,
        currentProject.id,
        setCurrScheduleObj,
        setLessonLearningMomentCheckpointCount
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.id, currGroup.id, currLanguage]);
}
import { ReactNode } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";

import { Selected } from "../EditModulePage/EditModulePage";
import { translation } from "constants/translation";
import AddIcon from "@mui/icons-material/Add";
import { TreeViewData } from "./types";

const wrapperStyles: SxProps = {
  borderRadius: "8px",
  cursor: "pointer",
  "&:not(.active):hover": {
    backgroundColor: "rgba(0,0,0,0.04)",
  },
  "&.active": {
    backgroundColor: "rgba(209, 228, 227, 0.37)",
  },
};

export const Lesson = ({
  lessons,
  lessonId,
  lessonIndex,
  selected,
  isSortingContainer,
  children,
  handleOpen,
}: {
  lessons: TreeViewData[];
  lessonId: string;
  selected: Selected | null;
  lessonIndex: number;
  isSortingContainer: boolean;
  children: ReactNode;
  handleOpen: (selected: Selected) => void;
}) => {
  const { listeners, isDragging } = useSortable({ id: lessonId });
  const lesson = lessons.find((lesson) => lesson.id === lessonId);
  
  if (!lesson) return null;
  const wrapperClassName = selected && selected.id === lesson.id && selected.type === lesson.type
    ? "active"
    : "";
  
  const handleCLick = () => { 
    handleOpen({
      id: lesson.id,
      index: lessonIndex,
      type: lesson.type,
    })
  }

  return (
    <Box
      sx={{
        px: 2,
      }}
    >
      <Box
        className={wrapperClassName}
        sx={{ ...wrapperStyles, cursor: isDragging ? "grabbing" : "pointer" }}
        onClick={handleCLick}
      >
        <Typography
          {...listeners}
          sx={{
            color: "#5C9391",
            fontSize: "1rem",
            lineHeight: 1.5,
            fontWeight: 450,
          }}
        >
          {translation.formatString(translation.lessonNo, {
            number: lessonIndex + 1,
          })}
          : {lesson.name}
        </Typography>
      </Box>
      {/* Activities */}
      {children}
      <Box
        sx={{
          mb: 1,
          ml: 2,
          cursor: isDragging || isSortingContainer ? "grabbing" : "auto",
        }}
      >
        <DashedButton
          title={translation.add_activity}
          onClick={() =>
            handleOpen({
              id: "-1",
              index: 0,
              type: "Activity",
              parentId: lesson.id,
              parentIndex: lessonIndex,
            })
          }
        />
      </Box>
    </Box>
  );
};

export const Activity = ({
  activities,
  activityId,
  activityIndex,
  selected,
  isSortingContainer,
  handleOpen,
}: {
  activities: TreeViewData[];
  activityId: string;
  activityIndex: number;
  selected: Selected | null;
  isSortingContainer: boolean;
  handleOpen: (selected: Selected) => void;
}) => {
  const { listeners, isDragging } = useSortable({
    id: activityId,
  });

  const activity = activities.find((activity) => activity.id === activityId);

  return activity ? (
    <Box
      className={
        selected &&
        selected.id === activity.id &&
        selected.type === activity.type
          ? "active"
          : ""
      }
      sx={{
        ...wrapperStyles,
        p: "4px 32px",
        cursor: isDragging || isSortingContainer ? "grabbing" : "pointer",
      }}
      onClick={() =>
        handleOpen({
          id: activity.id,
          index: activityIndex,
          parentIndex: activity.parentIndex,
          parentId: activity.parentId,
          type: activity.type,
        })
      }
      >
        <Typography
          sx={{
            color: "#323031",
            fontSize: "1rem",
            lineHeight: 1.5,
          }}
          {...listeners}
        >
        {`${activityIndex + 1}: ${activity.name} ${
          activity.isPublished ? "" : ` (${translation.draft})`
        }`}
      </Typography>
    </Box>
  ) : null;
};

export const DashedButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => (
  <Typography
    sx={{
      width: "fit-content",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      p: "8px 16px",
      borderRadius: "8px",
      border: "1px dashed #444",
      transition: "all 100ms ease-in-out",
      fontSize: "0.875rem",
      ml: 1.5,
      mt: 0.5,
      lineHeight: 1,
      "&:hover": {
        borderColor: "primary.main",
        backgroundColor: "rgba(209, 228, 227, 0.37)",
        transition: "all 100ms ease-in-out",
      },
    }}
    onClick={onClick}
  >
    <AddIcon sx={{ ml: -0.5, mr: 0.5, fontSize: "0.875rem" }} />
    {title}
  </Typography>
);

import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useLocation } from "react-router-dom";

import {
  teachertoolbox,
  teachertrainingacademy,
  parentportal,
} from "./TopicsQuestionsAnswers";

const HelpTabs = () => {
  const pages = [
    "About FAQs",
    "Onboarding Videos",
    "Teacher Toolbox",
    "Teacher Training Academy",
    "Parent Portal",
    "Feedback",
  ];
  return (
    <>
      {pages.map((page: string) => (
        <HelpTab key={page} tabName={page} />
      ))}
    </>
  );
};

const HelpTab = ({ tabName }: { tabName: any }) => {
  const navigate = useNavigate();
  const location = tabName.replace(/\s+/g, "-").toLowerCase();

  const { pathname } = useLocation();
  const final = pathname.split("/").pop();
  const [matchedData, setMatchedData] = useState<any>();

  const handleAccordionClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    
    switch (location.replace(/-/g, "").toLowerCase()) {
      case "teachertoolbox":
        setMatchedData(teachertoolbox);
        break;
      case "teachertrainingacademy":
        setMatchedData(teachertrainingacademy);
        break;
      case "parentportal":
        setMatchedData(parentportal);
        break;
      default:
        console.log("No matching data found");
    }
    navigate(location);
  };

  if (location === "about-faqs" || location === "feedback") {
    return (
      <Box
        sx={{
          padding: "12px 8px",
          border: "2px solid #91C6C5",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={handleAccordionClick}
      >
        {tabName}
      </Box>
    );
  } else {
    return (
      <Accordion
        disableGutters={true}
        expanded={final == location}
        sx={{ boxShadow: "none" }}
        onClick={handleAccordionClick}
      >
        <AccordionSummary
          sx={{
            padding: "12px 8px",
            border: "2px solid #91C6C5",
            "& .MuiAccordionSummary-contentGutters": {
              padding: "0 !important",
              margin: "0 !important",
            },
            "& .MuiAccordionSummary-content": {
              padding: "0 !important",
              margin: "0 !important",
            },
            height: "fit-content !important",
            minHeight: "fit-content !important",
            maxHeight: "fit-content !important",
            margin: 0,
            boxShadow: "none",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${location}-content`}
          id={`${location}-header`}
        >
          <Typography>{tabName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {matchedData?.map((item: any) => {
            return Object.keys(item).map((key: string) => (
              <Box key={key}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    textWrap: "wrap",
                    paddingY: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    document
                      .getElementById(key)
                      ?.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  {key}
                </Typography>
              </Box>
            ));
          })}
        </AccordionDetails>
      </Accordion>
    );
  }
};

export default HelpTabs;

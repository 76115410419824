import { Box } from "@mui/material";
import { styles } from "./constant";

const Page = ({ children }: { children: React.ReactNode }) => (
  <Box className="page">
    {/* Page numbering here */}
    <Box sx={styles.body}>
      {/* Content here */}
      {children}
    </Box>
  </Box>
);

export default Page;

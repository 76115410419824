import React from "react";

import { Box, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import useStore from "store/store";
import {
  API_Lesson_Learning_Moment,
  API_Project_Lesson,
} from "types/project";
import { GalleryPhotoVideo } from "types/gallery";

import "components/ProjectSummaryScreen/ReactGridGallery.css";

import { translation } from "constants/translation";
import {
  isProfileInGalleryAccessRoleList,
} from "helper/helper";
import UploadPhotoVideoModal from "./UploadPhotoVideoModal";
import UploadPhotoVideoLearningMomentModal from "./UploadPhotoVideoLearningMomentModal";
import Gallery from "./Gallery";

type Props = {
  lesson?: API_Project_Lesson;
  isLearningMomentPhotoVideoGallery?: boolean;
  isForLearningMomentTab?: boolean;
  lessonNo?: number;
  learningMomentObject?: API_Lesson_Learning_Moment;
  milestone?: any;
  photoVideoGalleryList: GalleryPhotoVideo[];
  setGalleryIndex: React.Dispatch<React.SetStateAction<number>>;
  setCurrPhotoVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initializeGallery: () => Promise<void>;
  setIsCreateObservationModalOpen?: any;
  setSelectedMilestoneId?: any
}

const PhotoVideoGalleryComponent = ({
  lesson,
  isLearningMomentPhotoVideoGallery,
  isForLearningMomentTab = false,
  lessonNo, //lesson index
  learningMomentObject,
  photoVideoGalleryList,
  setGalleryIndex,
  setCurrPhotoVideoIndex,
  setOpen,
  initializeGallery,
  milestone,
  setIsCreateObservationModalOpen,
  setSelectedMilestoneId,
}: Props) => {
  // const HEIGHT = isForLearningMomentTab ? 90 : 120;
  const HEIGHT = 120;
  // console.log(photoVideoGalleryList)
  const role = useStore((state) => state.profileDetails.role);

  const galleryIndex = photoVideoGalleryList.findIndex(
    (gallery) => milestone ? gallery.lesson === milestone.name : gallery.lesson === lesson?.name
  );

  const AddPhoto = milestone ?
    <Box
      sx={{
        // flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        borderRadius: "10px",
        color: "#444",
        border: "1px dashed",
        borderColor: "#444",
        transition: "all 100ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms ease-in-out",
        },
        width: 120,
        height: 120
      }}
      onClick={() => {
        setSelectedMilestoneId(milestone.id)
        setIsCreateObservationModalOpen(true)
      }}
    >
      <AddAPhotoIcon sx={{

        // height: milestone ? "100px" : "100px"
        height: '300px'
      }} />
    </Box>
    // <Typography onClick={() => {
    // setSelectedMilestoneId(milestone.id)
    // setIsCreateObservationModalOpen(true)

    //   // console.log(milestone.id)
    // }}>milestone</Typography> 

    : ((isLearningMomentPhotoVideoGallery || isForLearningMomentTab) &&
      learningMomentObject) ? (
      lesson?.activities.length !== 0 ? (
        isProfileInGalleryAccessRoleList(role) && (
          <UploadPhotoVideoLearningMomentModal
            lesson={lesson}
            lessonNo={lessonNo}
            learningMomentObject={learningMomentObject}
            initializeGallery={initializeGallery}
            isForLearningMomentTab={isForLearningMomentTab}
          />

        )
      ) : (
        <Typography>{translation.noActivity}</Typography>
      )
    ) : lesson?.activities.length !== 0 ? (
      isProfileInGalleryAccessRoleList(role) && (
        <UploadPhotoVideoModal
          lesson={lesson}
          lessonNo={lessonNo}
          initializeGallery={initializeGallery}
        />
        // <h1>no</h1>
      )
    ) : (
      <Typography>{translation.noActivity}</Typography>
    );


  return (
    <Box
      className="project-moments"
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: "relative",

        margin: isForLearningMomentTab ? "0px -0px 0" : "8px -4px 0",
        // backgroundColor: 'pink'
      }}
      // onClick={() => {
      //   if (photoVideoGalleryList[galleryIndex].photo_video_list.length > 0) {
      //     console.log(photoVideoGalleryList[galleryIndex].photo_video_list)
      //   }
      // }}
      onClick={() => {
        // console.log(photoVideoGalleryList[galleryIndex].photo_video_list)
      }}


    >
      <Gallery
        images={
          galleryIndex !== -1
            ? photoVideoGalleryList[galleryIndex].photo_video_list
            : []
        }
        margin={4}
        rowHeight={HEIGHT}
        maxRows={
          isLearningMomentPhotoVideoGallery || isForLearningMomentTab ? 1 : 2
        }
        isForLearningMomentTab={isForLearningMomentTab}
        onClick={(photoIndex) => {
          if (galleryIndex === -1) return;
          setGalleryIndex(galleryIndex);
          console.log(galleryIndex)
          setCurrPhotoVideoIndex(photoIndex);
          setOpen(true);
        }}
        addPhoto={AddPhoto}
      />
    </Box>
  );
};

export default PhotoVideoGalleryComponent;

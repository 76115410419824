import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { API_Group_Id_Only } from "types/group";
import { API_Profile_Short } from "types/profile";
import { Avatar, Box, Chip } from "@mui/material";
import { AddButton } from "components/Button/Button";
import CreateProfileModal from "containers/SiteAdminPage/ManagementPage/components/CreateProfileModal";
import { translation } from "constants/translation";

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
  return [...a, ...not(b, a)];
}

export default function TeacherTransferList({
  selectedTeacher,
  setSelectedTeacher,
  currentGroupData,
  teacherList,
}: {
  selectedTeacher: number[];
  setSelectedTeacher: React.Dispatch<React.SetStateAction<number[]>>;
  currentGroupData: API_Group_Id_Only;
  teacherList: API_Profile_Short[];
}) {
  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [teachersNotInGroup, setTeachersNotInGroup] = React.useState<
    readonly number[]
  >([]);
  const [teachersInGroup, setTeachersInGroup] = React.useState<
    readonly number[]
  >([]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  React.useEffect(() => {
    const initialTeacherList = () => {
      return currentGroupData.teachers;
    };

    const teachersNotInGroupTemp = () => {
      const notSelectedTeachers = () =>
        teacherList.filter((teacher) => {
          return !initialTeacherList().includes(teacher.id);
        });

      return notSelectedTeachers().map((teacher) => {
        return teacher.id;
      });
    };

    setSelectedTeacher(initialTeacherList());
    setTeachersInGroup(initialTeacherList());
    setTeachersNotInGroup(teachersNotInGroupTemp());
  }, [teacherList, currentGroupData.id]);

  const teachersNotInGroupChecked = intersection(checked, teachersNotInGroup);
  const teachersInGroupChecked = intersection(checked, teachersInGroup);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (teachersId: readonly number[]) =>
    intersection(checked, teachersId).length;

  const handleToggleAll = (teachersId: readonly number[]) => () => {
    if (numberOfChecked(teachersId) === teachersId.length) {
      setChecked(not(checked, teachersId));
    } else {
      setChecked(union(checked, teachersId));
    }
  };

  const handleCheckedAssignedteachers = () => {
    setTeachersInGroup(teachersInGroup.concat(teachersNotInGroupChecked));
    setSelectedTeacher(teachersInGroup.concat(teachersNotInGroupChecked));
    setTeachersNotInGroup(not(teachersNotInGroup, teachersNotInGroupChecked));
    setChecked(not(checked, teachersNotInGroupChecked));
  };

  const handleCheckedUnassignedteachers = () => {
    setTeachersNotInGroup(teachersNotInGroup.concat(teachersInGroupChecked));
    setTeachersInGroup(not(teachersInGroup, teachersInGroupChecked));
    setSelectedTeacher(not(teachersInGroup, teachersInGroupChecked));
    setChecked(not(checked, teachersInGroupChecked));
  };

  const CustomList = (
    title: React.ReactNode,
    teachersId: readonly number[],
    teacherList: API_Profile_Short[],
    addButton: boolean
  ) => {
    const [searchQuery, setSearchQuery] = useState<string>("");

    return (
      <Card
        sx={{
          border: "1px solid #98C4C5",
          borderRadius: "10px",
          width: { xs: "min(400px, calc(100vw - 48px))", sm: 250, md: 300 },
        }}
      >
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(teachersId)}
              checked={
                numberOfChecked(teachersId) === teachersId.length &&
                teachersId.length !== 0
              }
              indeterminate={
                numberOfChecked(teachersId) !== teachersId.length &&
                numberOfChecked(teachersId) !== 0
              }
              disabled={teachersId.length === 0}
              inputProps={{
                "aria-label": "all teachersId selected",
              }}
            />
          }
          title={
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItem: "center",
                }}
              >
                <Box>{title}</Box>
                {addButton && (
                  <AddButton
                    style={{
                      alignSelf: "center",
                      maxWidth: 1,
                      maxHeight: 1,
                      background: "None",
                      color: "#98C4C5",
                    }}
                    btnText={``}
                    onClick={() => {
                      setOpenModal(true);
                      // navigate(`${CURLANG(currLanguage)}/${CREATE_TEACHER_PAGE}`);
                    }}
                  />
                )}
              </Box>
            </>
          }
          subheader={`${numberOfChecked(teachersId)}/${
            teachersId.length
          } selected`}
        />

        <Divider sx={{ borderColor: "primary.main" }} />

        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
          sx={{
            px: 2,
            pt: 2,
          }}
        />

        <List
          sx={{
            height: 230,
            bgcolor: "background.paper",
            overflowX: "hidden",
          }}
          dense
          component="div"
          role="list"
        >
          {teachersId.map((teacherId: number) => {
            const labelId = `transfer-list-all-item-${teacherId}-label`;
            const targetTeacher = teacherList.filter((teacher) => {
              return teacher.id === teacherId;
            })[0];
            // console.log("targetTeacher: ", targetTeacher);
            return (
              <Fragment key={teacherId}>
                {targetTeacher &&
                targetTeacher.preferred_name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ? (
                  <ListItem
                    role="listitem"
                    button
                    onClick={handleToggle(teacherId)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(teacherId) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </ListItemIcon>
                    {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                    <ListItemText
                      id={labelId}
                      primary={
                        <Chip
                          avatar={
                            <Avatar
                              alt={targetTeacher.preferred_name}
                              src={targetTeacher.image}
                            />
                          }
                          label={`${targetTeacher.preferred_name}`}
                          variant="outlined"
                        />
                      }
                    />
                  </ListItem>
                ) : (
                  <> </>
                )}
              </Fragment>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <>
      <CreateProfileModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        role={"TR"}
        groupId={currentGroupData.id}
      />
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1.25, sm: 2 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          {CustomList(
            "Teachers To Be Assigned",
            teachersNotInGroup,
            teacherList,
            true
          )}
        </Grid>
        <Grid item>
          <Grid
            container
            direction={{ xs: "row", sm: "column" }}
            alignItems="center"
          >
            <Button
              sx={{
                my: { xs: 1, sm: 0.5 },
                mx: { xs: 1, sm: 0 },
                transform: { xs: "rotate(90deg)", sm: "rotate(0deg)" },
                padding: { xs: "5px", md: "5px 15px" },
                minWidth: { xs: 40, md: 65 },
              }}
              variant="outlined"
              size="medium"
              onClick={() => {
                handleCheckedAssignedteachers();
              }}
              disabled={teachersNotInGroupChecked.length === 0}
              aria-label="move selected teachersInGroup"
            >
              &gt;
            </Button>
            <Button
              sx={{
                my: { xs: 1, sm: 0.5 },
                mx: { xs: 1, sm: 0 },
                transform: { xs: "rotate(90deg)", sm: "rotate(0deg)" },
                padding: { xs: "5px", md: "5px 15px" },
                minWidth: { xs: 40, md: 65 },
              }}
              variant="outlined"
              size="medium"
              onClick={handleCheckedUnassignedteachers}
              disabled={teachersInGroupChecked.length === 0}
              aria-label="move selected teachersNotInGroup"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {CustomList(
            translation.teacherInGroup,
            teachersInGroup,
            teacherList,
            false
          )}
        </Grid>
      </Grid>
    </>
  );
}

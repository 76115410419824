import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import Button from "components/Button/Button";
import useStore from "store/store";
import { translation } from "constants/translation";
import DiscussionPostModal from "./DiscussionPostModal";
import DiscussionPostCard from "./DiscussionPostCard";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useInitialiseTeacherLesson from "hooks/useInitialiseTeacherLesson";
import useInitialiseTeacherLessonDiscussionBoard from "hooks/useInitialiseTeacherLessonDiscussionBoard";
import {
  TeacherLessonActivityScreenNextButton,
  TeacherLessonActivityScreenPrevButton,
} from "../TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";
import FeedbackModal from "../TeacherPageComponents/FeedbackModal";
import EligibleCertificateModal from "../TeacherPageComponents/EligibleCertificateModal";

const TeacherLessonDiscussionScreen = () => {
  useInitialiseTeacherLesson();
  useInitialiseTeacherLessonDiscussionBoard();
  const {
    teacherCurrentLesson,
    lessonDiscussionPostList,
    isLessonDiscussionPostLoading,
  } = useStore((state) => ({
    teacherCurrentLesson: state.teacherCurrentLesson,
    isLessonDiscussionPostLoading: state.isLessonDiscussionPostLoading,
    lessonDiscussionPostList: state.lessonDiscussionPostList,
  }));

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>("");
  const [isEditPost, setIsEditPost] = useState<boolean>(false);
  const [postId, setPostId] = useState<number>(-1);

  const handleCreatePost = () => {
    setIsEditPost(false);
    setAnswer("");
    setPostId(-1);
    setOpenModal(true);
  };

  const handleEditPost = ({
    answer,
    postId,
  }: {
    answer: string;
    postId: number;
  }) => {
    setIsEditPost(true);
    setAnswer(answer);
    setPostId(postId);
    setOpenModal(true);
  };

  // const isPosted =
  //   lessonDiscussionPostList.length > 0 &&
  //   lessonDiscussionPostList
  //     .map((post) => post.profile.id)
  //     .includes(Number(profileID));
  // console.log("lessonDiscussionPostList:", lessonDiscussionPostList);
  return isLessonDiscussionPostLoading ? (
    <LoadingIndicator />
  ) : (
    <Box sx={{ p: "30px" }}>
      <FeedbackModal />
      <EligibleCertificateModal />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          mb: 1.5,
        }}
      >
        <Title />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
            }}
          >
            {teacherCurrentLesson.discussion_topic}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {lessonDiscussionPostList.length !== 0 ? (
          lessonDiscussionPostList.map((post) => (
            <DiscussionPostCard
              key={post.id}
              post={post}
              handleEditPost={handleEditPost}
            />
          ))
        ) : (
          // Change to no post
          <Typography>{translation.noReview}</Typography>
        )}
      </Box>
      {/* justify to the end */}
      <Box sx={{ mt: 1, display: "flex", justifyContent: "end" }}>
        <CreatePostButton handleCreatePost={handleCreatePost} />
      </Box>

      <DiscussionPostModal
        open={openModal}
        setOpen={setOpenModal}
        answer={answer}
        setAnswer={setAnswer}
        isEdit={isEditPost}
        postId={postId}
      />
    </Box>
  );
};

const Title = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: 500,
        }}
      >
        {translation.discussion}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <TeacherLessonActivityScreenPrevButton />
        <TeacherLessonActivityScreenNextButton isNavigatePosition />
      </Box>
    </Box>
  );
};
const CreatePostButton = ({
  handleCreatePost,
}: {
  handleCreatePost: () => void;
}) => (
  <Button
    arrow={false}
    style={{
      padding: "8px 16px",
      fontWeight: 500,
      fontSize: 18,
    }}
    buttonText={translation.createPost || "Create Post"}
    onClick={handleCreatePost}
  />
);

export default TeacherLessonDiscussionScreen;

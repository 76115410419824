import { useState, useEffect } from "react";

import useStore from "store/store";
import DashedButton from "components/Button/DashedButton";
import { translation } from "constants/translation";

import DndContainer from "./DndContainer";
import DndItems from "./DndItems";
import DndItem from "./DndItem";
import Project from "./Project";
import Lesson from "./Lesson";
import Activity from "./Activity";
import { Selected, TreeViewData } from "./types";

export interface Items {
  [key: string]: string[];
}

const ProjectTreeView = ({
  selected,
  handleOpen,
  handleClose,
}: {
  selected: Selected | null;
  handleOpen: (selected: Selected) => void;
  handleClose: () => void;
}) => {
  const [isSortingContainer, setIsSortingContainer] = useState<boolean>(false);

  const { currentProject, currentProjectLessons } = useStore((state) => ({
    currentProject: state.currentProject,
    currentProjectLessons: state.currentLessons,
  }));

  const [lessons, setLessons] = useState<TreeViewData[]>([]);
  const [activities, setActivities] = useState<TreeViewData[]>([]);

  const [items, setItems] = useState<Items>({});
  const [containers, setContainers] = useState<string[]>([]);

  useEffect(() => {
    const lessons: TreeViewData[] = [];
    const activities: TreeViewData[] = [];
    const items: Items = {};
    const containers: string[] = [];

    currentProjectLessons.lessons.forEach((lesson, lessonIndex) => {
      const lessonID = lesson.id.toString();

      lessons.push({
        id: lessonID,
        name: lesson.name,
        type: "Lesson",
        index: lessonIndex,
        assessment: currentProject.assessment,
      });

      const activitiesId: string[] = [];

      lesson.activities.forEach((activity, activityIndex) => {
        activities.push({
          id: activity.id.toString(),
          name: activity.name,
          type: "Activity",
          index: activityIndex,
          parentIndex: lessonIndex,
          parentId: lessonID,
          isCompulsory: activity.is_compulsory,
          assessment: currentProject.assessment,
        });

        activitiesId.push(activity.id.toString());
      });

      items[lessonID] = activitiesId;
      containers.push(lessonID);
    });

    setLessons(lessons);
    setActivities(activities);
    setItems(items);
    setContainers(containers);
  }, [currentProjectLessons.lessons]);

  return (
    <>
      <Project
        project={currentProject}
        type="Project"
        handleOpen={handleOpen}
      />
      <DndContainer
        items={items}
        setItems={setItems}
        containers={containers}
        setContainers={setContainers}
        setIsSortingContainer={setIsSortingContainer}
        handleClose={handleClose}
      >
        <DndItems items={containers}>
          {containers.map((lessonId, lessonIndex) => (
            <DndItem key={lessonId} id={lessonId}>
              <Lesson
                lessons={lessons}
                lessonId={lessonId}
                lessonIndex={lessonIndex}
                selected={selected}
                isSortingContainer={isSortingContainer}
                handleOpen={handleOpen}
              >
                <DndItems items={items[lessonId]}>
                  {items[lessonId].map((activityId, activityIndex) => (
                    <DndItem
                      key={activityId}
                      id={activityId}
                      disabled={isSortingContainer}
                    >
                      <Activity
                        activities={activities}
                        activityId={activityId}
                        activityIndex={activityIndex}
                        selected={selected}
                        isSortingContainer={isSortingContainer}
                        handleOpen={handleOpen}
                      />
                    </DndItem>
                  ))}
                </DndItems>
              </Lesson>
            </DndItem>
          ))}
          <DashedButton
            text={
              currentProject.assessment
                ? "Add Assessment Plan"
                : translation.add_lesson || "Add Lesson"
            }
            onClick={() =>
              handleOpen({
                id: "-1",
                index: 0,
                type: "Lesson",
                parentId: currentProject.id.toString(),
                assessment: currentProject.assessment,
              })
            }
          />
        </DndItems>
      </DndContainer>
    </>
  );
};

export default ProjectTreeView;

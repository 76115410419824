import React, { useMemo, useState} from "react";
import type { ReactNode } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import type { Active, UniqueIdentifier } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import "./InstructionSortableList.css";

import { DragHandle, SortableItem } from "./InstructionSortableItem";
import { SortableOverlay } from "./InstructionSortableOverlay";
import { Box } from "@mui/system";
import { SxProps } from "@mui/material";

interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  sx?: SxProps;
  items: T[];
  setItems(items: T[]): void;
  renderItem(item: T): ReactNode;
}

export function InstructionSortableList<T extends BaseItem>({
  sx,
  items,
  setItems,
  renderItem,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          let updatedItems = arrayMove(items, activeIndex, overIndex);
          updatedItems = updatedItems.map((item, itemIndex) => {
            // console.log("item: ", item);
            return { ...item, weight: itemIndex + 1 };
          });
          // console.log("updatedItems: ", updatedItems);
          setItems(updatedItems);
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        <Box sx={{ p: 1, ...sx }}>
          <ul className="InstructionSortableList" role="application">
            {items.map((item) => {
              // console.log(item);
              return (
                <React.Fragment key={item.id}>
                  {renderItem(item)}
                </React.Fragment>
              );
            })}
          </ul>
        </Box>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
}

InstructionSortableList.Item = SortableItem;
InstructionSortableList.DragHandle = DragHandle;

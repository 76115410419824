import React from "react";
import { updateLessonProgress } from "../../../../api/profile-api";
import useStore from "../../../../store/store";
import CustomButton from "../../../../components/Button/Button";
import { translation } from "../../../../constants/translation";
import { useNavigate } from "react-router-dom";
import { CURLANG, LESSON_ACTIVITY_PAGE } from "../../../../constants/url";
import useSnack from "../../../../hooks/useSnack";
import { API_Current_Lesson } from "../../../../types/profile";
import { Box, LinearProgress, Typography } from "@mui/material";

export const UpdateLessonProgressButton = ({
  projectId,
  groupId,
}: {
  projectId: string;
  groupId: number;
}) => {
  const openSnack = useSnack();
  const { groupLessonInfoList, setGroupLessonInfoList, currLanguage } =
    useStore((state) => ({
      groupLessonInfoList: state.groupLessonInfoList,
      setGroupLessonInfoList: state.setGroupLessonInfoList,
      currLanguage: state.currLanguage,
    }));

  const handleUpdateLessonProgress = async (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    const updateLessonProgressRes = await updateLessonProgress(
      currLanguage,
      localStorage.getItem("access"),
      projectId,
      groupId
    );

    if (typeof updateLessonProgressRes === "string") {
      openSnack(updateLessonProgressRes, false);
    } else {
      openSnack("Lesson progress updated!", true);
      //Update statae
      const groupIndex = groupLessonInfoList.findIndex(
        (groupLessonInfo) => groupLessonInfo.id === groupId
      );
      const currentLessonIndex = groupLessonInfoList[
        groupIndex
      ].current_lessons.findIndex(
        (currentLesson) => currentLesson.project_id === projectId
      );
      const nextLessonIndex = groupLessonInfoList[
        groupIndex
      ].next_lessons.findIndex(
        (nextLesson) => nextLesson.project_id === projectId
      );

      const updatedGroupLessonInfoList = groupLessonInfoList;

      if (updateLessonProgressRes.current_lesson !== null) {
        updatedGroupLessonInfoList[groupIndex].current_lessons[
          currentLessonIndex
        ] = {
          ...updatedGroupLessonInfoList[groupIndex].current_lessons[
            currentLessonIndex
          ],
          ...updateLessonProgressRes.current_lesson,
        };
      } else {
        //null then remove
        updatedGroupLessonInfoList[groupIndex].current_lessons.splice(
          currentLessonIndex,
          1
        );
      }

      if (updateLessonProgressRes.next_lesson !== null) {
        updatedGroupLessonInfoList[groupIndex].next_lessons[nextLessonIndex] = {
          ...updatedGroupLessonInfoList[groupIndex].next_lessons[
            nextLessonIndex
          ],
          ...updateLessonProgressRes.next_lesson,
        };
      } else {
        updatedGroupLessonInfoList[groupIndex].next_lessons.splice(
          nextLessonIndex,
          1
        );
      }

      setGroupLessonInfoList(updatedGroupLessonInfoList);
    }
  };

  return (
    <CustomButton
      buttonText={translation.lessonCompleted}
      btnType="outlined"
      arrow={false}
      onClick={handleUpdateLessonProgress}
    />
  );
};

export const GoToLessonButton = ({
  lesson,
  buttonText,
}: {
  lesson: API_Current_Lesson;
  buttonText: string;
}) => {
  const navigate = useNavigate();
  const { currLanguage, setRefreshProjAndLess } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setRefreshProjAndLess: state.setRefreshProjAndLess,
  }));
  const activitySlug =
    lesson.activities.length > 0 ? `activity/${lesson.activities[0].id}` : "";
  return (
    <CustomButton
      buttonText={buttonText}
      arrow={false}
      onClick={() => {
        setRefreshProjAndLess(true);
        navigate(
          `${CURLANG(currLanguage)}/${LESSON_ACTIVITY_PAGE(
            lesson.project_slug,
            lesson.id.toString()
          )}/${activitySlug}`
        );
      }}
      style={{
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
    />
  );
};

export const LessonProgressComponent = ({
  currLessonWeight,
  lesson,
}: {
  currLessonWeight: number;
  lesson: API_Current_Lesson;
}) => {
  return (
    <Box
      sx={{
        marginTop: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5))",
      }}
    >
      <Typography
        sx={{
          alignSelf: "center",
          fontWeight: "500",
          mb: 0.5,
        }}
      >
        {`${currLessonWeight}/${lesson.total_lessons} ${
          lesson.total_lessons > 1
            ? translation.lessonsConducted
            : translation.lessonConducted
        }`}
      </Typography>

      <LinearProgress
        sx={{ width: "inherit" }}
        variant="determinate"
        value={(currLessonWeight / lesson.total_lessons) * 100}
      />
    </Box>
  );
};

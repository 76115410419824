import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { translation } from "constants/translation";
import debounce from "helper/debounce";
import { useState } from "react";

type Props = {
  searchVal?: string;
  handleChange: (searchVal: string) => void;
  mode?: "debounce" | "click";
  debounceValue?: number;
  entity?: string;
};

const SearchBar = ({
  searchVal = "",
  handleChange,
  mode = "click",
  debounceValue = 500,
  entity = "name",
}: Props) => {
  const [query, setQuery] = useState<string>(searchVal);

  const debouncedHandleChange = debounce(handleChange, debounceValue);

  return (
    <Paper
      component="form"
      sx={{
        boxShadow: "none",
        border: "1px solid #C9C9C9",
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 43,
        borderRadius: "4px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={`${translation.search} ${entity}`}
        inputProps={{ "aria-label": `search for ${entity}` }}
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          if (mode === "debounce") {
            debouncedHandleChange(e.target.value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleChange(query);
          }
        }}
      />
      <IconButton
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={() => handleChange(query)}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
import {
  initialDevelopmentalGoal,
  TagType,
} from "../containers/DevelopmentalGoalPage/EditDevelopmentalGoalPage";
import { API_Fundamental } from "../types/project";
import { StoreSlice } from "./store";
export interface FundamentalSlice {
  currDevelopmentalGoal: API_Fundamental;
  currDevelopmentalGoalTag: TagType;
  developmentalGoals: API_Fundamental[];
  isRefreshAllFundamental: boolean;
  fundamentalDict: { [id: string]: API_Fundamental };
  fundamentalListToDisplay: API_Fundamental[];
  setCurrDevelopmentalGoal: (currDevelopmentalGoal: API_Fundamental) => void;
  setCurrDevelopmentalGoalTag: (currDevelopmentalGoalTag: TagType) => void;
  setDevelopmentalGoals: (developmentalGoals: API_Fundamental[]) => void;
  setIsRefreshAllFundamental: (isRefreshAllFundamental: boolean) => void;
  setFundamentalDict: (fundamentalDict: {
    [id: string]: API_Fundamental;
  }) => void;
  setFundamentalListToDisplay: (
    fundamentalListToDisplay: API_Fundamental[]
  ) => void;
}

export const createFundamentalSlice: StoreSlice<FundamentalSlice> = (
  set,
  get
) => ({
  currDevelopmentalGoal: initialDevelopmentalGoal,
  currDevelopmentalGoalTag: "Head",
  developmentalGoals: [],
  isRefreshAllFundamental: false,
  fundamentalDict: {},
  fundamentalListToDisplay: [],
  setCurrDevelopmentalGoal: (currDevelopmentalGoal: API_Fundamental) => {
    set((prev: FundamentalSlice) => ({
      ...prev,
      currDevelopmentalGoal: currDevelopmentalGoal,
    }));
  },
  setCurrDevelopmentalGoalTag: (currDevelopmentalGoalTag: TagType) => {
    set((prev: FundamentalSlice) => ({
      ...prev,
      currDevelopmentalGoalTag: currDevelopmentalGoalTag,
    }));
  },
  setDevelopmentalGoals: (developmentalGoals: API_Fundamental[]) => {
    set((prev: FundamentalSlice) => ({
      ...prev,
      developmentalGoals: developmentalGoals,
    }));
  },
  setIsRefreshAllFundamental: (isRefreshAllFundamental: boolean) => {
    set((prev: FundamentalSlice) => ({
      ...prev,
      isRefreshAllFundamental: isRefreshAllFundamental,
    }));
  },
  setFundamentalDict: (fundamentalDict: { [id: string]: API_Fundamental }) => {
    set((prev: FundamentalSlice) => ({
      ...prev,
      fundamentalDict: fundamentalDict,
    }));
  },
  setFundamentalListToDisplay: (
    fundamentalListToDisplay: API_Fundamental[]
  ) => {
    set((prev: FundamentalSlice) => ({
      ...prev,
      fundamentalListToDisplay: fundamentalListToDisplay,
    }));
  },
});

import React, { useState } from "react";
import { Box, Avatar, Typography } from "@mui/material";

import Logo from "images/logo-2-colour.png";
import useStore from "store/store";
import useSnack from "hooks/useSnack";
import useDialog from "hooks/useDialog";

import { translation } from "constants/translation";
import { InputBar, Like } from "./DiscussionPostComponents";
import { isProfileInReviewEditableRoleList } from "helper/helper";
import WarningDialog from "components/Dialog/WarningDialog";
import {
  API_Lesson_Discussion_Post,
  API_Lesson_Discussion_Post_Comment,
} from "types/teacher";
import { deleteLessonDiscussionPostComment } from "api/teacher-api";
import { MoreButton, Time } from "components/Review/Components";

const MAX_DEPTH = 2;

const DiscussionPostCommentSection = ({
  post,
  isComment,
  setIsComment,
}: {
  post: API_Lesson_Discussion_Post;
  isComment: boolean;
  setIsComment: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [message, setMessage] = useState<string>("");
  return (
    <Box
      sx={{
        borderTop: "1px solid rgba(0,0,0,0.12)",
        mt: 1,
        pt: 2,
        pb: 0.5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {isComment && (
        <InputBar
          type="add"
          objectId={post.id}
          message={message}
          setMessage={setMessage}
          setShow={setIsComment}
        />
      )}

      {post.comments.length !== 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {post.comments.map((comment) => (
            <CommentCard
              key={comment.id}
              comment={comment}
              postId={post.id}
              depth={1}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const CommentCard = ({
  comment,
  postId,
  depth = 1,
}: {
  comment: API_Lesson_Discussion_Post_Comment;
  postId: number;
  depth: number;
}) => {
  const [message, setMessage] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isReply, setIsReply] = useState<boolean>(false);

  const Children = () => (
    <Reply
      comment={comment}
      postId={postId}
      isReply={isReply}
      setIsReply={setIsReply}
      depth={depth}
    />
  );

  return isEditing ? (
    depth < MAX_DEPTH ? (
      <InputBar
        type="edit"
        objectId={comment.id}
        message={message}
        setMessage={setMessage}
        setShow={setIsEditing}
      >
        <Children />
      </InputBar>
    ) : (
      <>
        <InputBar
          type="edit"
          objectId={comment.id}
          message={message}
          setMessage={setMessage}
          setShow={setIsEditing}
        />

        <Children />
      </>
    )
  ) : depth < MAX_DEPTH ? (
    <Card
      comment={comment}
      setIsReply={setIsReply}
      setMessage={setMessage}
      setIsEditing={setIsEditing}
    >
      <Children />
    </Card>
  ) : (
    <>
      <Card
        comment={comment}
        setIsReply={setIsReply}
        setMessage={setMessage}
        setIsEditing={setIsEditing}
      />

      <Children />
    </>
  );
};

const Card = ({
  comment,
  setIsReply,
  setMessage,
  setIsEditing,
  children,
}: {
  comment: API_Lesson_Discussion_Post_Comment;
  setIsReply: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}) => {
  const openSnack = useSnack();

  const {
    profileDetails,
    currLanguage,
    setIsRefreshLessonDiscussionPost,
    profileID,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
    setIsRefreshLessonDiscussionPost: state.setIsRefreshLessonDiscussionPost,
    profileID: state.profileID,
  }));

  const isMyComment = comment.profile.id.toString() === profileID;

  const editMyComment = () => {
    setMessage(comment.name);
    setIsEditing(true);
  };

  const deleteMyComment = async () => {
    const res = await deleteLessonDiscussionPostComment(
      comment.id,
      localStorage.getItem("access")!
    );

    if (res !== "Error") {
      openSnack(translation.commentDeleted, true);
      setIsRefreshLessonDiscussionPost(true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: translation.deleteComment,
    warningContext: translation.irreversibleAction,
    handleDelete: deleteMyComment,
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      <Avatar
        src={comment.profile.image}
        alt={comment.profile.preferred_name}
        sx={{ width: "32px", height: "32px", mt: 0.5 }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      <Box
        sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#FBFBFB",
              borderRadius: "10px",
              py: 1,
              px: 1.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: 1.5,
                mb: 0.125,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  lineHeight: 1.25,
                }}
              >
                {comment.profile.preferred_name}
              </Typography>

              {(isMyComment ||
                isProfileInReviewEditableRoleList(profileDetails.role)) && (
                <>
                  <WarningDialog {...warningBody} />

                  <MoreButton
                    type="comment"
                    handleEdit={editMyComment}
                    handleDelete={handleOpenDialog}
                    isDeleteOnly={!isMyComment}
                  />
                </>
              )}
            </Box>

            <Typography sx={{ whiteSpace: "pre-wrap", lineHeight: 1.333 }}>
              {comment.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
              mt: 0.75,
              mx: 1.5,
            }}
          >
            <Like type="comment" likes={comment.likes} objectId={comment.id} />

            {/* Disable reply for now */}
            {/* <CommentOrReply
              type="reply"
              onClick={() => setIsReply((prev) => !prev)}
            /> */}

            <Time
              type="comment"
              created_at={comment.created_at}
              updated_at={comment.updated_at}
            />
          </Box>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

const Reply = ({
  comment,
  postId,
  isReply,
  setIsReply,
  depth,
}: {
  comment: API_Lesson_Discussion_Post_Comment;
  postId: number;
  isReply: boolean;
  setIsReply: React.Dispatch<React.SetStateAction<boolean>>;
  depth: number;
}) => {
  const [replyMessage, setReplyMessage] = useState<string>("");

  return isReply ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {isReply && (
        <InputBar
          type="reply"
          objectId={postId}
          parent={comment}
          message={replyMessage}
          setMessage={setReplyMessage}
          setShow={setIsReply}
        />
      )}
    </Box>
  ) : null;
};

export default DiscussionPostCommentSection;

import { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Box } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { containerStyles } from "./MyChildrenOverviewPage";
import { getChildProfile } from "api/profile-api";
import { API_Child } from "types/profile";
import { tabs } from "containers/ChildrenPortfolioPage/constants";
import {
  H3_TABS,
  H3_TAB_TYPE,
  HEART_TEXT,
} from "containers/PortfolioPage/portfolioPageConstants";
import { CURLANG, MY_CHILDREN_PORTFOLIO } from "constants/url";
import useStore from "store/store";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import ChildPortfolioMiniDrawer from "containers/ChildrenPortfolioPage/ChildPortfolioMiniDrawer";
import Breadcrumbs from "containers/ChildrenPortfolioPage/Breadcrumbs";

type ContextType = {
  childDetails: API_Child;
};

const MyChildrenPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { childID, h3TabSlug } = useParams();

  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);
  const [childDetails, setChildDetails] = useState<API_Child>();

  const { currLanguage, childrenInSession } = useStore((state) => ({
    currLanguage: state.currLanguage,
    childrenInSession: state.childrenInSession,
  }));

  useEffect(() => {
    if (childID === undefined) return;

    const getChildDetails = async (childID: string) => {
      const childDetails = await getChildProfile(
        childID,
        localStorage.getItem("access")
      );

      if (typeof childDetails === "string") return;
      setChildDetails(childDetails);
    };

    getChildDetails(childID);
  }, [childID]);

  useEffect(() => {
    if (childID === undefined) return;

    const landingScreen = String(pathname).split("/").slice(-1)[0];

    // Is photo album
    if (pathname.includes(tabs[4])) {
      setCurrentTab(tabs[4]);
      // Is one of the developmental goal without h3
    } else if (!h3TabSlug && landingScreen === tabs[1]) {
      navigate(
        `${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${childID}/${
          tabs[1]
        }/${HEART_TEXT}`
      );
    } else if (H3_TABS.includes(landingScreen as H3_TAB_TYPE)) {
      setCurrentTab(tabs[1]);
      // only child id, navigate to first tab
    } else if (!tabs.includes(landingScreen)) {
      navigate(`${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${childID}/${tabs[0]}`);
    } else {
      setCurrentTab(landingScreen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navigate]);

  const isOwnChild =
    childID !== undefined && childrenInSession.length > 0
      ? childrenInSession.some((child) => child.id.toString() === childID)
      : false;

  return childDetails === undefined || childrenInSession.length === 0 ? (
    <Box sx={containerStyles}>
      <LoadingIndicator />
    </Box>
  ) : !isOwnChild ? (
    <Box sx={containerStyles}>
      <NotFoundPage />
    </Box>
  ) : (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <ChildPortfolioMiniDrawer
        childName={childDetails.preferred_name}
        currentTab={currentTab}
        isParentView
      />

      <Box
        sx={{
          ...containerStyles,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumbs
          childName={childDetails.preferred_name}
          currentTab={currentTab}
          isParentView
        />

        <Outlet context={{ childDetails }} />
      </Box>
    </Box>
  );
};

export const useMyChildrenContext = () => useOutletContext<ContextType>();

export default MyChildrenPage;

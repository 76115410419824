import UploadAdapterPlugin from 'helper/UploadAdapter/UploadAdapterPlugin'

const { CKEditor } = require("@ckeditor/ckeditor5-react");
const ClassicEditor = require("ckeditor5-classic-plus");

const RichCKEditor = ({
  data,
  onChange,
  ...props
}: {
  data: string | undefined;
  onChange: (event: any, editor: any) => void;
}) => {
  const config = {
    // fontSize: {
    //   options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    //   supportAllValues: true,
    // },
    removePlugins: [
      // "Font",
      "Strikethrough",
      "Code",
      "Subscript",
      "Superscript",
      // "Alignment",
      "CodeBlock",
      "HorizontalLine",
      "SimpleUploadAdapter",
      "FindAndReplace",
    ],
    alignment: {
      options: ["left", "center", "right", "justify"],
    },
    toolbar: [
      "heading",
      "|",
      "fontSize",
      "fontColor",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "bulletedList",
      "numberedList",
      "alignment",
      "|",
      "outdent",
      "indent",
      "|",
      "uploadImage",
      "blockQuote",
      "insertTable",
      "mediaEmbed",
      "|",
      "undo",
      "redo",
    ],
    image: {
      toolbar: [
        "resizeImage",
        "|",
        "imageStyle:alignLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignRight",
        "|",
        "linkImage",
        "|",
        "toggleImageCaption",
        "imageTextAlternative",
      ],
    },
    mediaEmbed: { previewsInData: true },
    link: {
      addTargetToExternalLinks: true,
    },
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      config={config}
      onReady={(editor: any) => {
        UploadAdapterPlugin(editor);
      }}
      data={data}
      onChange={onChange}
      onError={() => {}}
      {...props}
    />
  );
};

export default RichCKEditor;

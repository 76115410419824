import React, { useState, MouseEvent, Fragment, useEffect } from "react";
import {
  Box,
  Typography,
  Popover,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Title from "../ProjectSummarySideMenu/Title";
import Misc from "../ProjectSummarySideMenu/Misc";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

import useStore from "store/store";
import BackButton from "components/BackButton";
import NextActionButton from "./NextActionButton";
import { OVERVIEW, getProjectLessonActivityTabs } from "../constant";
import { LITTLE_PROJECTS } from "containers/CalendarPage/constants/constants";
import TabsContainerCopy from "./TabsContainerCopy";
import { useInsights, usePopulateScheduleObject } from "hooks/projects";

//Side menu count

const ProjectSummarySideMenuCopy = () => {
  const {
    profileDetails,
    currentProject,
  } = useStore((state) => state);

  usePopulateScheduleObject();
  const { isInBag, isInSchedule } = useInsights();

  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [popOverGroupName, setPopOverGroupName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onlyOverviewTab = [OVERVIEW];


  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setPopOverGroupName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen((prev) => !prev);

  useEffect(() => {
    if (isBelowSm) {
      setOpen(false);
    }
  }, [isBelowSm]);

  // useEffect(() => {
  //   if (location.pathname.includes("lesson")) {
  //     setOpen(false);
  //   }
  // }, [location]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        maxWidth: "300px",
        width: "auto",
        flex: open ? "1 0 auto" : "0 0 auto",
        p: 2,
        pt: "21px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
        alignSelf: "flex-start",
        position: "sticky",
        top: "64px",
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          // alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          mb: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            // m: 0.5,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",

            width: "100%",
            // backgroundColor: "purple",
          }}
          onClick={handleDrawer}
        >
          {open && (
            <Box
              sx={{
                // alignSelf: "flex-start",
                display: "flex",
                width: "100%",
                // backgroundColor: "pink",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <BackButton variant="ttb" />
              </Box>
              {open && <CloseIcon fontSize="small" />}
            </Box>
          )}
          {!open && <ChevronRightIcon fontSize="small" />}
        </Box>
        {open && (
          <>
            <Box
              sx={{
                // borderRadius: "10px",
                // border: "1px solid",
                borderColor: "#F0F0F0",
                // mt: 2,
                // px: 2,
                py: 2,
                overflow: "auto",
                alignSelf: "center",
              }}
            >
              <Title />
              <Misc />
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            width: "100%",
          }}
        >
          <TabsContainerCopy
            projectLessonActivityTabs={
              (isInBag() && isInSchedule()) ||
              profileDetails.role === "WAD" ||
              profileDetails.role === "CE"
                ? getProjectLessonActivityTabs(
                    currentProject.module.category === LITTLE_PROJECTS
                  )
                : onlyOverviewTab
            }
            open={open}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        </Box>
        {open && profileDetails.role !== "CE" && (
          <NextActionButton
            currentProject={currentProject}
            isInBag={isInBag()}
            isInSchedule={isInSchedule()}
          />
        )}
      </Box>
      {!open && (
        <Popover
          sx={{
            pointerEvents: "none",
            borderRadius: "10px",
            ml: 1,
          }}
          elevation={2}
          open={openPopOver}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              color: "#444",
              p: 1.5,
              lineHeight: 1,
            }}
          >
            {popOverGroupName}
          </Typography>
        </Popover>
      )}
    </Box>
  );
};

export default ProjectSummarySideMenuCopy;

import { useMemo } from "react";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { ImageCard, ImageType, containerStyles } from "./PhotoAlbumComponents";

interface MonthGroup {
  [year: string]: {
    [month: string]: ImageType[];
  };
}

const MonthView = ({
  images,
  handleClick,
}: {
  images: ImageType[];
  handleClick: (year: string, month: string) => void;
}) => {
  const monthGroup: MonthGroup = useMemo(
    () =>
      images.reduce((group: MonthGroup, image: ImageType) => {
        const year = moment(image.created_at).year().toString();
        const month = (moment(image.created_at).month() + 1).toString();

        if (!group[year] || !group[year][month]) {
          group[year] = {
            [month]: [image],
            ...group[year],
          };
        } else {
          group[year][month].push(image);
        }

        return group;
      }, {}),
    [images]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {Object.entries(monthGroup)
        .sort((a, b) => parseInt(b[0], 10) - parseInt(a[0], 10))
        .map(([year, months], yIndex) => (
          <Box key={yIndex} id={year}>
            <Typography
              sx={{
                fontWeight: 500,
                lineHeight: 1,
                mb: 1.25,
              }}
            >
              {year}
            </Typography>

            <Box sx={containerStyles}>
              {Object.entries(months)
                .sort((a, b) => parseInt(b[0], 10) - parseInt(a[0], 10))
                .map(([month, _images], mIndex) =>
                  _images.length !== 0 ? (
                    <ImageCard
                      key={`${yIndex}-${mIndex}`}
                      src={_images[0].link}
                      label={moment()
                        .month(Number(month) - 1)
                        .format("MMMM")}
                      onClick={() => handleClick(year, month)}
                    />
                  ) : null
                )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default MonthView;

import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { translation } from "constants/translation";
import {
  Container,
  Title,
  ProgressBar,
  BottomNavigation,
  Answer,
  SelectionBox,
  SelectAll,
} from "../Components";
import { API_Fundamental } from "types/project";
import { H3_TABS } from "containers/PortfolioPage/portfolioPageConstants";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { updateGroupFundamental } from "api/prediscoveryquestionnaire-api";

const ChildGoals = ({
  pageNumber,
  setCurrentPage,
  answer,
  setAnswer,
  fundamentalList,
}: {
  pageNumber: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  answer: Answer;
  setAnswer: React.Dispatch<React.SetStateAction<Answer>>;
  fundamentalList: API_Fundamental[];
}) => {
  const openSnack = useSnack();

  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));

  const isAllSelected = answer.goals.length === fundamentalList.length;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const res = await updateGroupFundamental({
      groupId: currGroup.id,
      fundamentalList: answer.goals,
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setCurrentPage(pageNumber + 1);
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <Title
        title={translation.myChildGoals}
        subTitle={translation.myChildGoalsSub}
      />

      <ProgressBar pageNumber={pageNumber} />

      <SelectAll
        selectedNo={answer.goals.length}
        onChange={() =>
          setAnswer((prev) => ({
            ...prev,
            goals: isAllSelected
              ? []
              : fundamentalList.map((fundamental) => fundamental.id),
          }))
        }
        isAllSelected={isAllSelected}
      />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 1,
          mt: -0.5,
          flex: "1 1 0",
          overflowY: "auto",
          mr: -3,
          pr: { xs: 2, sm: 3 },
        }}
      >
        {H3_TABS.map((tag, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                textTransform: "capitalize",
                fontWeight: 500,
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                pb: 0.5,
                mb: -0.5,
              }}
            >
              {tag}
            </Typography>

            {fundamentalList
              .filter((fundamental) => fundamental.tag === tag)
              .map((fundamental, fIndex) => (
                <SelectionBox
                  key={fIndex}
                  image={fundamental?.image_colored || ""}
                  name={fundamental.name}
                  selected={answer.goals.includes(fundamental.id)}
                  onClick={() =>
                    setAnswer((prev) => ({
                      ...prev,
                      goals: prev.goals.includes(fundamental.id)
                        ? prev.goals.filter((goal) => goal !== fundamental.id)
                        : [...prev.goals, fundamental.id],
                    }))
                  }
                />
              ))}
          </Box>
        ))}
      </Box>

      <BottomNavigation
        pageNumber={pageNumber}
        setCurrentPage={setCurrentPage}
        disabled={answer.goals.length === 0}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default ChildGoals;

import React from "react";
import Image from "components/Image/Image";
import NailBorderBottom from "images/dashboard/nail-border-bottom.png";
import NailBorderTop from "images/dashboard/nail-border-top.png";
import NailBorderLeft from "images/dashboard/nail-border-left.png";
import NailBorderRight from "images/dashboard/nail-border-right.png";
import NailBottomLeft from "images/dashboard/nail-bottom-left.png";
import NailBottomRight from "images/dashboard/nail-bottom-right.png";
import NailTopLeft from "images/dashboard/nail-top-left.png";
import NailTopRight from "images/dashboard/nail-top-right.png";
// import DesktopLamp from "images/dashboard/desktop-lamp.png";
import { Box } from "@mui/material";

const NowLearningBackground = () => {
  return (
    <>
      <Image
        src={NailBorderLeft}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          left: 15,
          top: 15,
          bottom: 15,
          width: "initial",
          height: "-webkit-fill-available",
        }}
      />
      <Image
        src={NailBorderRight}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          right: 15,
          top: 15,
          bottom: 15,
          width: "initial",
          height: "-webkit-fill-available",
        }}
      />
      <Box
        sx={{
          backgroundImage: `url(${NailBorderTop})`,
          position: "absolute",
          top: 15,
          left: 15,
          right: 15,
          height: "15px",
          overflow: "hidden",
          backgroundRepeat: "repeat-x",
        }}
      />
      {/* <Image
        src={NailBorderTop}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          top: 15,
          left: 15,
          right: 15,
          width: "-webkit-fill-available",
          height: "initial",
          overflow: "hidden",
        }}
      /> */}
      <Box
        sx={{
          backgroundImage: `url(${NailBorderBottom})`,
          position: "absolute",
          bottom: 15,
          left: 15,
          right: 15,
          height: "15px",
          backgroundRepeat: "repeat-x",
        }}
      />
      <Image
        src={NailTopLeft}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "45px",
          height: "45px",
        }}
      />
      <Image
        src={NailTopRight}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "45px",
          height: "45px",
        }}
      />
      <Image
        src={NailBottomLeft}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "45px",
          height: "45px",
        }}
      />
      <Image
        src={NailBottomRight}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "45px",
          height: "45px",
        }}
      />
      {/* <Image
        src={DesktopLamp}
        alt="background"
        objectFit="contain"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: 200,
          height: 200,
        }}
      /> */}
    </>
  );
};

export default NowLearningBackground;

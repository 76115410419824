import moment from "moment";

const formatDate = (date: string, isToday = false) => {
  const dateMoment = moment(date);

  if (dateMoment.isSame(moment(), "day")) {
    return isToday ? "Today" : dateMoment.format("hh:mm a");
  } else if (dateMoment.isSame(moment().subtract(1, "d"), "day")) {
    return "Yesterday";
  } else if (dateMoment.isSame(moment(), "week")) {
    return dateMoment.format("dddd");
  } else {
    return dateMoment.format("DD/MM/YYYY");
  }
};

export default formatDate;

import { API_Profile_Short } from "../types/profile";
import { API_School } from "../types/school";
import { StoreSlice } from "./store";

export interface AdminPageSlice {
  adminPageSchoolList: API_School[];
  setAdminPageSchoolList: (adminPageSchoolList: API_School[]) => void;
  adminPageParentList: API_Profile_Short[];
  setAdminPageParentList: (adminPageParentList: API_Profile_Short[]) => void;
}

export const createAdminPageSlice: StoreSlice<AdminPageSlice> = (set, get) => ({
  adminPageSchoolList: [],
  setAdminPageSchoolList: (adminPageSchoolList: API_School[]) => {
    set((prev: AdminPageSlice) => ({
      ...prev,
      adminPageSchoolList: adminPageSchoolList,
    }));
  },
  adminPageParentList: [],
  setAdminPageParentList: (adminPageParentList: API_Profile_Short[]) => {
    set((prev: AdminPageSlice) => ({
      ...prev,
      adminPageParentList: adminPageParentList,
    }));
  },
});

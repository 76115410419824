import { useMemo, ReactNode } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { API_Teacher_Module } from "types/teacher";

const DndCard = ({
  module,
  children,
}: {
  module: API_Teacher_Module;
  children: ReactNode;
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transition,
    transform,
    isDragging,
  } = useSortable({ id: module.id });

  const style = useMemo(
    () => ({
      transition,
      transform: CSS.Transform.toString(transform),
      zIndex: isDragging ? 5 : 0,
      opacity: isDragging ? 0.5 : 1,
    }),
    [transition, transform, isDragging]
  );

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export default DndCard;

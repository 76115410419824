import { StoreSlice } from "./store";
import { API_Schedule_Simple } from "../types/profile";
import { API_Lesson_Learning_Moment_Checkpoint_Count } from "../types/lesson-activity";

export interface ScheduleSlice {
  currScheduleObj: API_Schedule_Simple;
  setCurrScheduleObj: (currScheduleObj: API_Schedule_Simple) => void;
  lessonLearningMomentCheckpointCount: API_Lesson_Learning_Moment_Checkpoint_Count;
  setLessonLearningMomentCheckpointCount: (
    lessonLearningMomentCheckpointCount: API_Lesson_Learning_Moment_Checkpoint_Count
  ) => void;
}

export const initialScheduleObj = {
  id: -1,
  project: -1,
  group: -1,
  current_lesson: -1,
  activities: [],
  learning_moments: [],
  checkpoints: [],
};

export const initiallessonLearningMomentCheckpointCount = {
  learning_moment_done: 0,
  learning_moment_total: 0,
  lesson_done: 0,
  lesson_total: 0,
};

export const createScheduleSlice: StoreSlice<ScheduleSlice> = (set, get) => ({
  currScheduleObj: initialScheduleObj,
  setCurrScheduleObj: (currScheduleObj: API_Schedule_Simple) => {
    set((prev: ScheduleSlice) => ({
      ...prev,
      currScheduleObj: currScheduleObj,
    }));
  },
  lessonLearningMomentCheckpointCount:
    initiallessonLearningMomentCheckpointCount,
  setLessonLearningMomentCheckpointCount: (
    lessonLearningMomentCheckpointCount: API_Lesson_Learning_Moment_Checkpoint_Count
  ) => {
    set((prev: ScheduleSlice) => ({
      ...prev,
      lessonLearningMomentCheckpointCount: lessonLearningMomentCheckpointCount,
    }));
  },
});

import React, { useState } from "react";
import { Chip, Typography, Box, TextField, Switch, FormGroup, FormControlLabel, Button } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const CustomActionBar = ({ setView }: { setView: any }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {" "}
            <Button
                onClick={() => {
                    setView("other");
                }}
                sx={{
                    marginBottom: 2,
                    color: "white",
                    width: "90%",
                    textAlign: "center",
                    backgroundColor: "#91C6C5",
                    "&:hover": {
                        backgroundColor: "#91C6C5",
                    },
                }}
            >
                <Typography
                    sx={{
                        textTransform: "none",
                    }}
                >
                    Apply
                </Typography>
            </Button>
        </Box>
    );
};

const CustomTabs = ({
    range,
    setRange,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    choice,
    setChoice,
}: {
    range: boolean;
    setRange: any;
    selectedDay: Dayjs;
    setSelectedDay: any;
    startDate: any;
    setStartDate: any;
    endDate: any;
    setEndDate: any;
    choice: any;
    setChoice: any;
}) => {
    // handlingswitchchange
    const handleChange = () => {
        if (!startDate && !endDate && !range) {
            setChoice("startDate");
        }
        setRange((prev: any) => !prev);
    };

    return (
        <>
            <FormGroup sx={{ margin: "10px" }}>
                <FormControlLabel
                    control={<Switch checked={range} onChange={handleChange} name="Date Range" />}
                    label="Date Range"
                />
            </FormGroup>
            {range && (
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        paddingX: 1,
                        paddingY: 0,
                    }}
                    onClick={() => {
                        console.log(choice);
                        console.log(startDate);
                        console.log(endDate);
                    }}
                >
                    <TextField
                        size="small"
                        sx={{
                            flex: 1,
                            minWidth: 100,
                            maxWidth: "148px",
                        }}
                        label="Start Date"
                        value={startDate ? startDate.format("YYYY-MM-DD") : ""}
                        onClick={() => {
                            setChoice("startDate");
                            setStartDate(null);
                        }}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField
                        size="small"
                        sx={{
                            flex: 1,
                            minWidth: 100,
                            maxWidth: "148px",
                        }}
                        label="End Date"
                        value={endDate ? endDate.format("YYYY-MM-DD") : ""}
                        onClick={() => {
                            setChoice("endDate");
                            setEndDate(null);
                        }}
                    />
                </Box>
            )}
        </>
    );
};

export default function CustomDatePicker({
    view,
    setView,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}: {
    view: string;
    setView: React.Dispatch<React.SetStateAction<string>>;
    startDate: any;
    setStartDate: React.Dispatch<React.SetStateAction<any>>;
    endDate: any;
    setEndDate: React.Dispatch<React.SetStateAction<any>>;
}) {
    const [selectedDay, setSelectedDay] = useState<Dayjs>(dayjs());

    const [choice, setChoice] = useState("");
    const [open, setOpen] = useState(false);
    const [range, setRange] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setRange(false);
        setOpen(false);
    };

    const shouldDisableDate = (date: Date) => {
        const isFutureDate = startDate && date < startDate;
        const isPastDate = endDate && date > endDate;
        return isFutureDate || isPastDate;
    };

    const formatChipDate = (datestring: any) => {
        const date = new Date(datestring);
        const formattedDate = date.toLocaleDateString("en-US", { day: "2-digit", month: "short" });
        return formattedDate;
    };

    return (
        <>
            <Chip
                variant="outlined"
                label={
                    !startDate || !endDate
                        ? "Other"
                        : `Other: ${formatChipDate(startDate)} - ${formatChipDate(endDate)}`
                }
                onClick={handleOpen}
                sx={{
                    borderColor: "#79B5B4",
                    backgroundColor: view != "other" ? "white" : "#91C6C5",
                    color: view != "other" ? "#91C6C5" : "white",
                }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label="Tabs"
                    renderInput={(params) => (
                        <TextField
                            disabled
                            {...params}
                            sx={{ width: "0.00000000001px", height: "32px" }}
                            onClick={handleOpen}
                        />
                    )}
                    value={selectedDay}
                    onChange={(newValue: any) => {
                        setSelectedDay(newValue);
                        if (choice === "startDate") {
                            setStartDate(newValue);
                        } else if (choice === "endDate") {
                            setEndDate(newValue);
                        }
                        console.log("setting");
                    }}
                    hideTabs={false}
                    components={{
                        Tabs: (props) => (
                            <CustomTabs
                                range={range}
                                setRange={setRange}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                choice={choice}
                                setChoice={setChoice}
                            />
                        ),
                        ActionBar: (props) => <CustomActionBar setView={setView} />,
                    }}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    views={["year", "month", "day"]}
                    closeOnSelect={false}
                    shouldDisableDate={shouldDisableDate}
                />
            </LocalizationProvider>
        </>
    );
}

import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";

export const PDFScale = 1.59;

export const photoBookPageOneImageInfo = (scale: number) => ({
  top: 85 * scale,
  left: 75 * scale,
  width: 153 * scale,
  height: 113 * scale,
});

export const photoBookPageOneCaptionInfo = (scale: number) => ({
  top: 240 * scale,
  left: 50 * scale,
  width: 200 * scale,
  clampLine: 2,
  fontSize: 11 * scale,
});

export const photoBookPageTwoImageInfo = (scale: number) => ({
  top: 100 * scale,
  width: 70 * scale,
  height: 75 * scale,
});

export const photoBookPageTwoCaptionInfo = (scale: number) => ({
  top: 190 * scale,
  width: 80 * scale,
  clampLine: 7,
  fontSize: 6 * scale,
  textAlign: "center",
});

export const photoBookPageTwoImagePositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      src: images[0].link,
      left: 32 * scale,
    },
    {
      src: images[1].link,
      left: 119 * scale,
    },
    {
      src: images[2].link,
      left: 204 * scale,
    },
  ];
};

export const photoBookPageTwoCaptionPositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      caption: images[0].caption,
      left: 30 * scale,
    },
    {
      caption: images[1].caption,
      left: 115 * scale,
    },
    {
      caption: images[2].caption,
      left: 205 * scale,
    },
  ];
};

export const photoBookPageThreeImageInfo = (scale: number) => ({
  top: 60 * scale,
  left: 73 * scale,
  width: 145 * scale,
  height: 160 * scale,
});

export const photoBookPageThreeCaptionInfo = (scale: number) => ({
  top: 235 * scale,
  left: 65 * scale,
  width: 163 * scale,
  clampLine: 3,
  fontSize: 11 * scale,
});

export const photoBookPageFourCaptionInfo = (scale: number) => ({
  top: 220 * scale,
  clampLine: 3,
  fontSize: 8 * scale,
});

export const photoBookPageFourImagePositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      src: images[0].link,
      top: 110 * scale,
      left: 32 * scale,
      width: 110 * scale,
      height: 90 * scale,
    },
    {
      src: images[1].link,
      top: 100 * scale,
      left: 173 * scale,
      width: 85 * scale,
      height: 105 * scale,
    },
  ];
};

export const photoBookPageFourCaptionPositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      caption: images[0].caption,
      width: 120 * scale,
      left: 30 * scale,
    },
    {
      caption: images[1].caption,
      width: 110 * scale,
      left: 160 * scale,
    },
  ];
};

export const photoBookPageFiveImageInfo = (scale: number) => ({
  top: 110 * scale,
  left: 50 * scale,
  width: 195 * scale,
  height: 150 * scale,
});

export const photoBookPageFiveCaptionInfo = (scale: number) => ({
  top: 50 * scale,
  left: 45 * scale,
  width: 205 * scale,
  clampLine: 1,
  fontSize: 11 * scale,
});

export const photoBookPageSixImageInfo = (scale: number) => ({
  top: 80 * scale,
  width: 85 * scale,
  height: 99 * scale,
});

export const photoBookPageSixCaptionInfo = (scale: number) => ({
  top: 200 * scale,
  clampLine: 6,
  fontSize: 9 * scale,
});

export const photoBookPageSixImagePositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      src: images[0].link,
      left: 47 * scale,
    },
    {
      src: images[1].link,
      left: 160 * scale,
    },
  ];
};

export const photoBookPageSixCaptionPositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      caption: images[0].caption,
      width: 100 * scale,
      left: 42 * scale,
    },
    {
      caption: images[1].caption,
      width: 90 * scale,
      left: 155 * scale,
    },
  ];
};

export const photoBookPageSevenImageInfo = (scale: number) => ({
  top: 65 * scale,
  left: 107 * scale,
  width: 145 * scale,
  height: 175 * scale,
});

export const photoBookPageSevenCaptionInfo = (scale: number) => ({
  top: 65 * scale,
  left: 10 * scale,
  width: 88 * scale,
  clampLine: 8,
  fontSize: 11 * scale,
});

export const photoBookPageEightImageInfo = (scale: number) => ({
  left: 46 * scale,
  width: 105 * scale,
  height: 80 * scale,
});

export const photoBookPageEightCaptionInfo = (scale: number) => ({
  left: 165 * scale,
  width: 100 * scale,
  clampLine: 4,
  fontSize: 11 * scale,
});

export const photoBookPageEightImagePositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      src: images[0].link,
      top: 58 * scale,
    },
    {
      src: images[1].link,
      top: 160 * scale,
    },
  ];
};

export const photoBookPageEightCaptionPositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      caption: images[0].caption,
      top: 50 * scale,
    },
    {
      caption: images[1].caption,
      top: 155 * scale,
    },
  ];
};

export const photoBookPageElevenImageInfo = (scale: number) => ({
  left: 24 * scale,
  width: 95 * scale,
  height: 76 * scale,
});

export const photoBookPageElevenCaptionInfo = (scale: number) => ({
  left: 140 * scale,
  width: 145 * scale,
  clampLine: 4,
  fontSize: 11 * scale,
});

export const photoBookPageElevenImagePositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      src: images[0].link,
      top: 13 * scale,
    },
    {
      src: images[1].link,
      top: 108 * scale,
    },
    {
      src: images[2].link,
      top: 205 * scale,
    },
  ];
};

export const photoBookPageElevenCaptionPositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      caption: images[0].caption,
      top: 13 * scale,
    },
    {
      caption: images[1].caption,
      top: 108 * scale,
    },
    {
      caption: images[2].caption,
      top: 205 * scale,
    },
  ];
};

const firstColumnLeft = 35;
const secondColumnLeft = 168;
const firstRowTop = 13;
const secondRowTop = 160;

export const photoBookPageTwelveImageInfo = (scale: number) => ({
  width: 110 * scale,
  height: 90 * scale,
});

export const photoBookPageTwelveCaptionInfo = (scale: number) => ({
  width: 115 * scale,
  clampLine: 2,
  fontSize: 11 * scale,
});

export const photoBookPageTwelveImagePositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      src: images[0].link,
      top: firstRowTop * scale,
      left: firstColumnLeft * scale,
    },
    {
      src: images[1].link,
      top: firstRowTop * scale,
      left: secondColumnLeft * scale,
    },
    {
      src: images[2].link,
      top: secondRowTop * scale,
      left: (firstColumnLeft - 2) * scale,
    },
    {
      src: images[3].link,
      top: secondRowTop * scale,
      left: (secondColumnLeft + 3) * scale,
    },
  ];
};

export const photoBookPageTwelveCaptionPositionList = (
  images: ImageType[],
  scale: number
) => {
  return [
    {
      caption: images[0].caption,
      top: (firstRowTop + 95) * scale,
      left: (firstColumnLeft - 4) * scale,
    },
    {
      caption: images[1].caption,
      top: (firstRowTop + 95) * scale,
      left: secondColumnLeft * scale,
    },
    {
      caption: images[2].caption,
      top: (secondRowTop + 95) * scale,
      left: (firstColumnLeft - 4) * scale,
    },
    {
      caption: images[3].caption,
      top: (secondRowTop + 95) * scale,
      left: secondColumnLeft * scale,
    },
  ];
};

export const componentList = [
  {
    // page-1
    vertical: 0,
    horizontal: 1,
  },
  {
    // page-2
    vertical: 3,
    horizontal: 0,
  },
  {
    // page-3
    vertical: 1,
    horizontal: 0,
  },
  {
    // page-4 > horizontal then vertical
    vertical: 1,
    horizontal: 1,
  },
  {
    //page-5
    vertical: 0,
    horizontal: 1,
  },
  {
    //page-6
    vertical: 2,
    horizontal: 0,
  },
  {
    //page-7
    vertical: 1,
    horizontal: 0,
  },
  {
    //page-8
    vertical: 0,
    horizontal: 2,
  },
  {
    //page-11
    vertical: 0,
    horizontal: 3,
  },
  {
    //page-12
    vertical: 0,
    horizontal: 4,
  },
];

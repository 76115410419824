import React from "react";
import { Page, Text, Image, View, Font } from "@react-pdf/renderer";

import { pageSize, styles } from "./constant";
import Background from "images/children-portfolio-v2/concluding-remark.png";
import { checkIsChineseChar } from "helper/helper";

const ConcludingRemarksPageChinese = ({
  paragraphs,
  englishFirst,
  multipleLanguages,
  exception,
}: {
  paragraphs: any[];
  englishFirst: boolean;
  multipleLanguages: boolean;
  exception?: boolean;
}) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);

  const chineseText = paragraphs.join("\n\n");

  let partOne = "";
  let accumulatedLength = 0;
  let partTwo = "";
  let i = 0;

  if (paragraphs.length > 4 || chineseText.length > 1000) {
    for (; i < paragraphs.length; i++) {
      let newAccumulatedLength = accumulatedLength + paragraphs[i].length + 40;
      if (newAccumulatedLength <= 1000) {
        partOne += paragraphs[i] + "\n\n";
        accumulatedLength = newAccumulatedLength;
      } else {
        break;
      }
    }
    partTwo = paragraphs.slice(i).join("\n\n");
  } else {
    partOne = chineseText;
  }

  if (exception) {
    return (
      <Page size={pageSize} style={styles.body}>
        <View style={styles.main} wrap={false}>
          <Image src={Background} style={styles.background} />
          <Text
            style={{
              position: "relative",
              top: 170,
              left: 85,
              right: 80,
              fontSize: 12,
              maxWidth: "78%",
              fontFamily: "KaiTi",
            }}
          >
            {chineseText}
          </Text>
        </View>
      </Page>
    );
  }

  if (chineseText.length <= 310 && paragraphs.length <= 3) {
    if (multipleLanguages) {
      return (
        <Text
          style={{
            position: "relative",
            top: englishFirst ? 190 : 170,
            left: 85,
            right: 80,
            fontSize: 12,
            maxWidth: "78%",
            fontFamily: "KaiTi",
          }}
        >
          {chineseText}
        </Text>
      );
    } else {
      return (
        <Page size={pageSize} style={styles.body}>
          <Image src={Background} style={styles.background} />
          <View style={styles.main} wrap={false}>
            <Text
              style={{
                position: "relative",
                top: englishFirst ? 150 : 170,
                left: 85,
                right: 80,
                fontSize: 12,
                maxWidth: "78%",
                fontFamily: "KaiTi",
              }}
            >
              {chineseText}
            </Text>
          </View>
        </Page>
      );
    }
  }
  return (
    <>
      <Page size={pageSize} style={styles.body}>
        <View style={styles.main} wrap={false}>
          <Image src={Background} style={styles.background} />

          <Text
            style={{
              position: "relative",
              top: 170,
              left: 85,
              right: 80,
              fontSize: 12,
              maxWidth: "78%",

              fontFamily: "KaiTi",
            }}
          >
            {partOne}
          </Text>
        </View>
      </Page>

      {partTwo && (
        <Page size={pageSize} style={styles.body}>
          <View style={styles.main} wrap={false}>
            <Image src={Background} style={styles.background} />

            <Text
              style={{
                position: "relative",
                top: 170,
                left: 85,
                right: 80,
                fontSize: 12,
                maxWidth: "78%",

                fontFamily: "KaiTi",
              }}
            >
              {partTwo}
            </Text>
          </View>
        </Page>
      )}
    </>
  );
};

export default ConcludingRemarksPageChinese;

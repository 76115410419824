import React from "react";
import { Box } from "@mui/material";

import {
  API_Activity_Instruction,
  API_Lesson_Learning_Moment,
  API_Lesson_Learning_Moment_Question,
} from "types/project";
import EvaluationMomentQuestion from "./EvaluationMomentQuestion";
import DashedButton from "components/Button/DashedButton";
import useStore from "store/store";

type Props = {
  activityInstructionLearningMoment: API_Lesson_Learning_Moment;
  activityInstructionId: string;
  activityInstructions: API_Activity_Instruction[];
  setActivityInstructions: React.Dispatch<
    React.SetStateAction<API_Activity_Instruction[]>
  >;
};

const ActivityInstructionEvaluationMomentField = ({
  activityInstructionLearningMoment,
  activityInstructionId,
  activityInstructions,
  setActivityInstructions,
}: Props) => {
  const { fundamentalDict } = useStore((state) => state);

  const handleAddQuestion = () => {
    const updatedActivityInstructions = activityInstructions.map(
      (instruction) => {
        if (instruction.id.toString() === activityInstructionId) {
          const existingQuestions =
            instruction.learning_moment?.questions ?? [];

          const updatedQuestions: API_Lesson_Learning_Moment_Question[] = [
            ...existingQuestions,
            {
              id: `new-question-${existingQuestions.length}`,
              mode: "create",
              name: "",
              fundamental_set: [
                {
                  id: `question-0-fundamental-0`,
                  mode: "create",
                  fundamental:
                    fundamentalDict[Object.keys(fundamentalDict)[0]].id,
                  milestone: -1,
                },
              ],
            },
          ];

          return {
            ...instruction,
            learning_moment: {
              ...instruction.learning_moment,
              questions: updatedQuestions,
            },
          };
        }
        return instruction;
      }
    );

    setActivityInstructions(updatedActivityInstructions);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, pt: 2 }}>
      {activityInstructionLearningMoment.questions?.map((question, index) => (
        <EvaluationMomentQuestion
          index={index}
          key={question.id}
          evaluationMomentQuestion={question}
          activityInstructionId={activityInstructionId}
          activityInstructions={activityInstructions}
          setActivityInstructions={setActivityInstructions}
        />
      ))}
      <DashedButton text="Add Question" onClick={handleAddQuestion} />
    </Box>
  );
};

export default ActivityInstructionEvaluationMomentField;

// import React from "react";
// import TeacherArchiveProgressReportScreenByYear from "./TeacherArchiveProgressReportScreenByYear";

const ProgressReportByYear = () => {
  return null;
};

// const ProgressReportByYear = () => {
//   return <TeacherArchiveProgressReportScreenByYear />;
// };

export default ProgressReportByYear;

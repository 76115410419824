import React from "react";
import { TextField } from "@mui/material";
import { API_Create_Activity } from "types/project";
import { translation } from "constants/translation";

export const DurationField = ({
  activity,
  setActivity,
  onChange,
}: {
  activity: API_Create_Activity;
  setActivity: React.Dispatch<React.SetStateAction<API_Create_Activity>>;
  onChange?: (input: number) => void;
}) => (
  <TextField
    label={translation.expected_duration_mins}
    value={activity.duration}
    onChange={(event) => {
      let currVal = Number(event.target.value);

      if (Number.isNaN(currVal)) {
        currVal = activity.duration || 0;
      } else if (currVal < 0) {
        currVal = 0;
      }

      setActivity((prev) => ({ ...prev, duration: currVal }));
      onChange && onChange(currVal);
    }}
    required
  />
);

import { API_Announcement } from "../types/announcement";
import { StoreSlice } from "./store";

export interface AnnouncementSlice {
  announcementList: API_Announcement[];
  setAnnouncementList: (announcementList: API_Announcement[]) => void;
  parentDashboardUnseenAnnouncementCount: number;
  setParentDashboardUnseenAnnouncementCount: (
    parentDashboardUnseenAnnouncementCount: number
  ) => void;
}

export const createAnnouncementSlice: StoreSlice<AnnouncementSlice> = (
  set,
  get
) => ({
  announcementList: [],
  setAnnouncementList: (announcementList: API_Announcement[]) => {
    set((prev: AnnouncementSlice) => ({
      ...prev,
      announcementList: announcementList,
    }));
  },
  parentDashboardUnseenAnnouncementCount: -1,
  setParentDashboardUnseenAnnouncementCount: (
    parentDashboardUnseenAnnouncementCount: number
  ) => {
    set((prev: AnnouncementSlice) => ({
      ...prev,
      parentDashboardUnseenAnnouncementCount:
        parentDashboardUnseenAnnouncementCount,
    }));
  },
});

import React, { useEffect, useState } from "react";
import { PROFILE_SECTION_REFLECTIONS } from "../constants";
import { slugify } from "helper/slugify";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useStore from "store/store";
import { API_Reflection } from "types/profile";
import moment from "moment";
import { translation } from "constants/translation";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { deleteReflection } from "api/profile-api";
import useSnack from "hooks/useSnack";

const ReflectionComponent = ({
  setIsModalOpen,
  setType,
  isOwner,
  setSelectedReflection,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  isOwner: boolean;
  setSelectedReflection: React.Dispatch<React.SetStateAction<API_Reflection>>;
}) => {
  const openSnack = useSnack();
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "Delete Reflection?",
    confirmDelete: false,
  });

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Reflection?",
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  const handleDeleteClick = (experience: API_Reflection) => {
    //call delete api
    handleOpenDialog();
    setWarningBody({
      id: experience.id,
      warningTitle: `Delete Reflection "${experience.name}"?`,
      confirmDelete: false,
    });
  };

  const handleDelete = (id: number) => {
    //call delete api
    const callDeleteReflection = async () => {
      const res = await deleteReflection(id);
      if (typeof res === "string" && res.length >= 1) {
        //if delete success, it will return ""
        openSnack(res, false);
      } else {
        openSnack(`Delete Reflection successfully!`, true);
        const filteredReflection = visitedProfileDetails.reflections?.filter(
          (experience) => experience.id !== id
        );
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          reflections: filteredReflection,
        });
      }
    };

    callDeleteReflection();
  };

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);
  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <Card id={slugify(PROFILE_SECTION_REFLECTIONS)} sx={{ width: "100%" }}>
        <CardHeader
          sx={{ backgroundColor: "#d0e3e2bd" }}
          title={(
            <Typography sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 500 }}>
              {translation.profileSectionReflections}
            </Typography>)
          }
          action={
            <IconButton
              aria-label="New"
              onClick={() => {
                setType(slugify(PROFILE_SECTION_REFLECTIONS));
                setIsModalOpen(true);
              }}
            >
              {isOwner && <AddIcon />}
            </IconButton>
          }
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            {!!visitedProfileDetails.reflections?.length
              ? visitedProfileDetails.reflections.map(
                  (reflection: API_Reflection) => {
                    return (
                      <Box key={reflection.id} sx={{ position: "relative" }}>
                        <IconButton
                          onClick={() => {
                            setIsModalOpen(true);
                            setType(slugify(PROFILE_SECTION_REFLECTIONS));
                            setSelectedReflection(reflection);
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 40,
                          }}
                        >
                          {isOwner && (
                            <EditIcon
                              sx={{
                                cursor: "pointer",
                                color: "txt.secondary",
                                transition: "all 100ms ease-in-out",
                                "&:hover": {
                                  color: "primary.main",
                                  transition: "all 100ms ease-in-out",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleDeleteClick(reflection);
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                          }}
                        >
                          {isOwner && (
                            <DeleteIcon
                              sx={{
                                cursor: "pointer",
                                color: "txt.secondary",
                                transition: "all 100ms ease-in-out",
                                "&:hover": {
                                  color: "primary.main",
                                  transition: "all 100ms ease-in-out",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h5">
                            {reflection.name}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {moment(reflection.created_at).format(
                              "DD MMMM YYYY"
                            )}
                          </Typography>
                          <Typography variant="body1">
                            {reflection.description}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                )
              : isOwner
              ? translation.noReflections
              : ""}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ReflectionComponent;

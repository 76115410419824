import useStore from "store/store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import axios from "axios";
import moment from "moment";

import Image from "components/Image/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTimetableEventList } from "api/timetable-api";
import { getProjectCategories } from "api/project-api";
import { getAllSchoolForRegister } from "api/school-api";
import { getCurrentNextGroupLessonsInfo, getGroupLessonInfo, getScheduleByGroup } from "api/profile-api";

import { API_School_Simple } from "types/school";
import {  API_Group_Lesson_Info } from "types/profile";
import { ProjectCategoryModule, ProjectCategory } from "types/project";

import Person from "./Person";
import DrawerButton from "../DrawerButton";
import Logo from "images/logo-2-colour.png";
import noLessonFound from "images/noLessonFound.svg"
import QuickItems from "../TeacherDashboardComponent/QuickItems";

import {
    Box,
    Button,
    Typography,
    Avatar,
    Divider,
    FormControl,
    IconButton,
    Select,
    MenuItem,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useColors from "hooks/useColors";

import { translation } from "constants/translation";
import { LESSON_ACTIVITY_PAGE } from "constants/url";
import { CURLANG, TEACHER_TRAINING_ACADEMY, CREATE_GROUP_PAGE, MARKETPLACE } from "constants/url";



const MobileDashboard = ({ setAttendanceModalOpen }: { setAttendanceModalOpen: any }) => {

    const quickItemsRef = useRef<HTMLDivElement>(null);

    const {
        role,
        profileDetails,
        currLanguage,
        groupList,
        currGroup,
        groupProjectBoxProps,
    } = useStore((state) => ({
        role: state.profileDetails.role,
        profileDetails: state.profileDetails,
        currLanguage: state.currLanguage,
        refreshGroup: state.refreshGroup,
        groupList: state.groupList,
        currGroup: state.currGroup,
        groupProjectBoxProps: state.groupProjectBoxProps,
    }));

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const dateConversion = (date: string) => {
        let dateToConvert = new Date(date);
        let day = dateToConvert.getDate();
        let month = dateToConvert.getMonth();
        let year = dateToConvert.getFullYear();
        let monthName = monthNames[month];
        let formattedDate = `${day} ${monthName} ${year}`;
        return formattedDate;
    };

    const initialGroupLessonInfo: API_Group_Lesson_Info = {
        id: -1,
        name: "",
        slug: "",
        image: '',
        school: -1,
        next_lessons: [],
        current_lessons: [],
        completed_projects: []
      };

    const today = moment();
    const oddColor = "#FFFFEF";
    const evenColor = "#F8FDFD";
    const colors = useColors();
    const navigate = useNavigate();
    const dayOfWeek = today.isoWeekday();
    

    const getProjectBoxes = () => {
        if (Object.keys(groupProjectBoxProps).length === 0) {
            return [];
        }
        const projects: any[] = groupProjectBoxProps[currGroup.id.toString()];
        if (projects) {
            projects?.sort((a: any, b: any) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime());
            projects?.forEach((project: any) => {
                project.startAtDisplay = dateConversion(project.startAt);
                project.endAtDisplay = dateConversion(project.endAt);
            });
            return projects;
        }
    };

    const [allEvents, setAllEvents] = useState<any>();
    const [schoolId, setSchoolId] = useState<number>(-1);
    const [modules, setModules] = useState<string[]>([]);
    const [isNotFound, setIsNotFound] = useState<boolean>(false);
    const [schoolList, setSchoolList] = useState<API_School_Simple[]>();
    const [timetableClass, setTimetableClass] = useState<any>("All Classes");
    const [projects, setProjects] = useState<any[] | undefined>(getProjectBoxes());
    const [isQuickItemsExpanded, setIsQuickItemsExpanded] = useState<boolean>(true);
    const [groupLessonInfo, setGroupLessonInfo] = useState<API_Group_Lesson_Info>(initialGroupLessonInfo);



    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const allEvents = await getTimetableEventList(currLanguage, profileDetails.id.toString());

                const events = allEvents.filter((event) => {
                    return event.day === dayOfWeek && event.currGroup !== 0;
                });
                const groupedEvents = events.reduce((acc: any, event: any) => {
                    const key: any = `${event.start} - ${event.end}`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(event);
                    return acc;
                }, {});

                setAllEvents(groupedEvents);

                setIsNotFound(false);
            } catch (error) {
                setIsNotFound(true);
            }
        };

        const fetchModules = async () => {
            const allModules: ProjectCategoryModule[] = (await getProjectCategories(currLanguage)).reduce(
                (arr: ProjectCategoryModule[], curr: ProjectCategory) => [...arr, ...curr.modules],
                []
            );

            setModules(allModules.map((module) => module.name));
        };

        fetchEvents();
        fetchModules();
    }, [currLanguage, profileDetails.id.toString(), ]);

    useEffect(() => {
        setAllEvents(allEvents);
    }, [allEvents]);

    useEffect(() => {
        if (role === "TTACE") {
            navigate(`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`);
            return;
        }
        if (role !== "WAD") {
            setSchoolId(profileDetails.school ? profileDetails.school.id : -1);
            return;
        }

        const getAllSchools = async () => {
            const res = await getAllSchoolForRegister();
            setSchoolList(res);
            setSchoolId(res[0].id);
        };

        getAllSchools();
    }, [role]);

    useEffect(() => {
        const source = axios.CancelToken.source();
    
        const populateAllLessonsInfo = async () => {
          const res = await getCurrentNextGroupLessonsInfo(
            currLanguage,
            currGroup.id,
            localStorage.getItem("access"),
            source,
          );
    
          if (typeof res !== "string") {
            setGroupLessonInfo(res)
          }
        };
    
        const populateCompletedProjects = async () => {
          const res = await getScheduleByGroup(
            currLanguage,
            currGroup.id,
            localStorage.getItem("token")
          )
          if (typeof res !== "string") {
            if (res.detail == 'No Record') {
              return
            }
            else {
              let completed = res.filter((project: any) => project.current_lesson == null && project.next_lesson == null).map((project: any) => project.project.name)
              setGroupLessonInfo((prevState:any) => ({
                ...prevState,
                completed_projects: completed
              }));
            }
          }
        }
    
        setGroupLessonInfo(initialGroupLessonInfo);
        const fetchAndPopulateData = async () => {
          try {
            if (!currLanguage || currGroup.id === -1 || groupLessonInfo.id === currGroup.id) {
              return;
            }
            await populateAllLessonsInfo();
    
            if (groupLessonInfo.id) {
              await populateCompletedProjects();
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchAndPopulateData()
    
        return () => {
          source.cancel();
        };
      }, [currLanguage, currGroup.id]);

  
    return (
        <>
            {
                !profileDetails.role ? <LoadingIndicator /> :
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "auto",
                            overflowY: "auto",
                            width: "100%",
                            padding: "16px",
                            gap: "11px",
                            backgroundColor: "#D8F2EE",
                        }}
                    >
                        <QuickItems
                            ref={quickItemsRef}
                            isExpanded={isQuickItemsExpanded}
                            setIsExpanded={setIsQuickItemsExpanded}
                        />

                        {/* TIMETABLE ACCORDION */}
                        <Accordion
                            sx={{
                                backgroundColor: "white",
                                width: "100%",
                                borderRadius: "4px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                            disableGutters={true}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    paddingLeft: 1,
                                    paddingRight: 1.5,
                                    borderRadius: "4px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        flex: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: {
                                                xs: 2,
                                            },
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ fontSize: { xs: 16, sm: 20 } }}>
                                            {translation.timeTable}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {today.format("D MMMM (dddd)")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    backgroundColor: "white",
                                    position: "relative",
                                    borderRadius: "4px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        maxHeight: "406px",
                                        overflowY: "auto",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <FormControl
                                        required
                                        sx={{
                                            maxHeight: "56px",
                                            height: "56px",
                                            width: "100%",
                                            px: 2,
                                        }}
                                    >
                                        <Select
                                            sx={{
                                                overflowY: "hidden",
                                                maxHeight: "56px",
                                                height: "56px",
                                            }}
                                            renderValue={(value) => {
                                                if (value === "All Classes") {
                                                    return "All Classes";
                                                } else {
                                                    const group = groupList.find((group) => group.name === value);
                                                    if (group) {
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    gap: 1,
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Avatar
                                                                    src={group.image ? group.image : Logo}
                                                                    sx={{
                                                                        width: "24px",
                                                                        height: "24px",
                                                                        display: "flex",
                                                                    }}
                                                                />
                                                                <Typography>{group.name}</Typography>
                                                            </Box>
                                                        );
                                                    }
                                                }
                                            }}
                                            value={timetableClass}
                                            onChange={(event) => {
                                                setTimetableClass(event.target.value);
                                            }}
                                        >
                                            {groupList.map((group: any, index: number) => (
                                                <MenuItem value={group.name} key={index}>
                                                    {group.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Box
                                        sx={{
                                            overflowY: "auto",
                                            width: "100%",
                                            p: 2,
                                        }}
                                    >
                                        {timetableClass === "All Classes" && allEvents
                                            ? Object.keys(allEvents).map((key, index) => (
                                                <Box key={index} sx={{}}>
                                                    <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{key}</Typography>
                                                    <ul>
                                                        {allEvents[key].map((event: any) => {
                                                            const groupName = groupList.find((group: any) => group.id === event.currGroup);
                                                            const color = colors[event.color % colors.length];
                                                            return (
                                                                <Box
                                                                    key={event.id}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "flex-end",
                                                                        backgroundColor: color,
                                                                        borderRadius: "4px",
                                                                        px: "8px",
                                                                        py: "4px",
                                                                        mb: 1,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {groupName ? groupName.name : "Unknown"}
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "14px",
                                                                                fontWeight: "450",
                                                                                color: "#8C8C8C",
                                                                            }}
                                                                        >
                                                                            {event.name}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: "14px",
                                                                            fontWeight: "400",
                                                                        }}
                                                                    >
                                                                        {event.start}
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        })}
                                                    </ul>
                                                </Box>
                                            ))
                                            : allEvents
                                                ? Object.keys(allEvents).map((key) => {
                                                    const group = groupList.find((group: any) => {
                                                        return group.name == timetableClass;
                                                    });
                                                    const filteredEvents = allEvents[key].filter(
                                                        (event: any) => event.currGroup === group?.id
                                                    );

                                                    if (filteredEvents.length > 0) {
                                                        return (
                                                            <Box key={key}>
                                                                <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>{key}</Typography>
                                                                <ul>
                                                                    {filteredEvents.map((event: any) => {
                                                                        const groupName = groupList.find(
                                                                            (group: any) => group.id === event.currGroup
                                                                        );
                                                                        const color = colors[event.color % colors.length];
                                                                        return (
                                                                            <Box
                                                                                key={event.id}
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    alignItems: "flex-end",
                                                                                    backgroundColor: color,
                                                                                    borderRadius: "4px",
                                                                                    px: "8px",
                                                                                    py: "4px",
                                                                                    mb: 1,
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        // mb: 1,
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        justifyContent: "space-between",
                                                                                    }}
                                                                                >
                                                                                    {groupName ? groupName.name : "Unknown"}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: "14px",
                                                                                            fontWeight: "450",
                                                                                            color: "#8C8C8C",
                                                                                        }}
                                                                                    >
                                                                                        {event.name}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: "14px",
                                                                                        fontWeight: "400",
                                                                                    }}
                                                                                >
                                                                                    {event.start}
                                                                                </Typography>
                                                                            </Box>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </Box>
                                                        );
                                                    }
                                                    return null;
                                                })
                                                : null}
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>

                        {/* END OF TIMETABLE ACCORDION */}

                        {/* MY CLASSES */}
                        <Box sx={{}}>
                            <Box
                                sx={{
                                    width: "100%",
                                    backgroundColor: "#F8FDFD",
                                    display: "flex",
                                    flexDirection: "column ",
                                    borderRadius: "4px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "12px 8px",
                                    }}
                                >
                                    {" "}
                                    <Typography
                                        sx={{
                                            fontWeight: "450",
                                            fontSize: { xs: 16, sm: 20 },
                                        }}
                                    >
                                        {translation.myClasses}
                                    </Typography>
                                    {
                                        profileDetails.role != "TR" && (
                                            <IconButton
                                                onClick={() =>
                                                    navigate(`${CURLANG(currLanguage)}/${CREATE_GROUP_PAGE}`, {
                                                        state: schoolId !== -1 ? { schoolId } : null,
                                                    })
                                                }
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        )
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        overflowX: "auto",

                                        width: "auto",
                                        display: "flex",
                                        gap: "8px",
                                        backgroundColor: "#F8FDFD",
                                    }}
                                >
                                    {groupList.map((group, index) => (
                                        <Box key={index}>
                                            <DrawerButton group={group} index={index} />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    border: "1px solid #C4E4DF",
                                    backgroundColor: "white",

                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                {profileDetails.role != "TR" && (
                                    <>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                pb: "16px",
                                                height: "30%",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "450",

                                                    fontSize: { xs: 16, sm: 18 },

                                                    color: "#5C9391",
                                                    px: "12px",
                                                    py: "8px",
                                                    pb: "10px",
                                                }}
                                            >
                                                {translation.teachers}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    flexWrap: "wrap",
                                                    height: "100%",
                                                    gap: "16px",
                                                    overflowY: "auto",
                                                    "&::-webkit-scrollbar": {
                                                        width: "5px",
                                                    },
                                                    px: "12px",
                                                }}
                                            >
                                                {currGroup.teachers.map((teacher, index) => (
                                                    <Box key={index}>
                                                        <Person profile={teacher} />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                        <Divider sx={{ mx: 2 }} />
                                    </>
                                )}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "60%",
                                        pt: "16px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            px: "8px",
                                            py: "8px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: profileDetails.role == "TR" ? "flex-start" : "space-between",
                                                alignItems: "center",
                                                gap: 2,
                                                width: profileDetails.role == "TR" ? "auto" : "100%",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "450",
                                                    fontSize: { xs: 16, sm: 18 },
                                                    color: "#5C9391",
                                                }}
                                            >
                                                {translation.children}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {translation.total}: {currGroup.children.length} {translation.children}
                                            </Typography>
                                        </Box>

                                        {profileDetails.role == "TR" && (
                                            <Box
                                                sx={{
                                                    display: " flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",

                                                    height: "100%",
                                                }}
                                            >
                                                <Button sx={{ textTransform: "none" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: "450",
                                                        }}
                                                    >
                                                        {translation.viewClass}
                                                    </Typography>
                                                </Button>
                                                <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#5C9391" }} />
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            overflowX: "auto",
                                            overflowY: "none",
                                            "&::-webkit-scrollbar": {
                                                width: "5px",
                                            },
                                            minHeight: "0",
                                            height: "108px",
                                            maxHeight: "108px",
                                            gap: "16px",
                                            px: "12px",
                                        }}
                                    >
                                        {currGroup.children.map((child, index) => (
                                            <Box key={index}>
                                                <Person profile={child} />
                                            </Box>
                                        ))}
                                    </Box>
                                    {profileDetails.role == "TR" && (
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#91C6C5",
                                                    borderRadius: "4px",
                                                    marginTop: 3,
                                                    marginBottom: 2,
                                                }}
                                                variant="contained"
                                                onClick={() => {
                                                    setAttendanceModalOpen(true);
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "white",
                                                        fontSize: "16px",
                                                        fontWeight: "450",
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    Take Attendance
                                                </Typography>
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                {profileDetails.role != "TR" && (
                                    <Box
                                        sx={{
                                            display: " flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            marginRight: 2,
                                        }}
                                    >
                                        <Button sx={{ textTransform: "none" }}>{translation.viewClass}</Button>
                                        <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#5C9391" }} />
                                    </Box>
                                )}
                            </Box>

                            {/* CURRENTLY ON ACCORDION */}

                            <Accordion
                                sx={{
                                    backgroundColor: "white",
                                    width: "100%",

                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                                onChange={() => { }}
                                disableGutters={true}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        paddingLeft: 1,
                                        paddingRight: 1.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            flex: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: {
                                                    xs: 2,
                                                },
                                            }}
                                        >
                                            <Typography variant="h6" sx={{ fontSize: { xs: 16, sm: 20 }, color: "#5C9391" }}>
                                                Ongoing Lessons
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        backgroundColor: "white",
                                        position: "relative",
                                        height: "300px",
                                        maxHeight: "320px",

                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        //
                                    }}
                                >
                                    <Box
                                        sx={{
                                            overflowY: groupLessonInfo.id == -1 ? "hidden" : "auto",
                                            height: "100%",
                                            width: "100%",
                                            "&::-webkit-scrollbar": {
                                                width: "5px",
                                            },
                                        }}
                                    >
                                        {groupLessonInfo.id == -1 ? (
                                            <LoadingIndicator />
                                        ) : groupLessonInfo.id != -1 && groupLessonInfo.current_lessons.length > 0 ? (
                                            groupLessonInfo.current_lessons.map((lesson, index) => {
                                                const activitySlug =
                                                    lesson.activities.length > 0 ? `activity/${lesson.activities[0].id}` : "";
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            justifyContent: "space-between",
                                                            border: "1px solid #91C6C5",
                                                            borderRadius: "4px",
                                                            mb: 1,
                                                            cursor: "pointer",
                                                            backgroundColor: index % 2 == 0 ? evenColor : oddColor,
                                                        }}
                                                        key={index}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                p: "8px",

                                                                width: "50%",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "450",
                                                                }}
                                                            >
                                                                {lesson.project_name}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "12px",
                                                                    fontWeight: "400",
                                                                }}
                                                            >
                                                                Lesson {(lesson?.total_completed_lessons ?? 0) + 1}: {lesson.name}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "flex-end",
                                                                justifyContent: "space-between",
                                                                px: "8px",
                                                                gap: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "10px",
                                                                    fontWeight: "400",
                                                                }}
                                                            >
                                                                {lesson?.total_completed_lessons ?? 0}/{lesson?.total_lessons} Lesson Conducted
                                                            </Typography>
                                                            <Button
                                                                onClick={() => {
                                                                    navigate(
                                                                        `${CURLANG(currLanguage)}/${LESSON_ACTIVITY_PAGE(
                                                                            lesson.project_slug,
                                                                            lesson.id.toString()
                                                                        )}/${activitySlug}`
                                                                    );
                                                                }}
                                                                sx={{
                                                                    px: "10px",
                                                                    py: "4px",
                                                                    color: "#5C9391",
                                                                    border: "1px solid #5C9391",
                                                                    borderRadius: "4px",
                                                                    textTransform: "none",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        fontWeight: "450",
                                                                    }}
                                                                >
                                                                    Resume
                                                                </Typography>
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <Box
                                            sx={{
                                              width: '100%',
                                              height: '100%',
        
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              gap: 1
                                            }}
                                          >
                                            <Box sx={{ height: "150px", width: "150px" }}>
                                              <Image src={noLessonFound} alt="No Lessons Found" objectFit="contain" />
        
                                            </Box>
                                            <Typography sx={{ fontSize: "16px", fontWeight: '400px', color: '#8C8C8C' }}>
                                              No Lesson Found</Typography>
                                            <Button sx={{ borderRadius: '4px', border: "1px solid #5C9391", color: "#5C9391", textTransform: "none" }} variant="outlined" onClick={() => {
                                              navigate(`/${currLanguage}/${MARKETPLACE}`)
                                            }}>
                                              <Typography sx={{ fontSize: '16px', fontWeight: '450' }}>Go to Lesson Plan Library</Typography>
                                            </Button>
                                          </Box>
                                        )}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            {/* END OF CURRENTLY ON ACCORDION */}

                            {/* START OF ONGOING ACCORDION */}

                            <Accordion
                                sx={{
                                    backgroundColor: "white",
                                    width: "100%",

                                    border: "1px solid #C4E4DF",
                                }}
                                disableGutters={true}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        paddingLeft: 1,
                                        paddingRight: 1.5,

                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            flex: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: {
                                                    xs: 2,
                                                },
                                            }}
                                        >
                                            <Typography variant="h6" sx={{ fontSize: { xs: 16, sm: 20 }, color: "#5C9391" }}>
                                                Upcoming Lessons
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        backgroundColor: "white",
                                        position: "relative",
                                        height: "300px",
                                        maxHeight: "320px",

                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        //
                                    }}
                                >
                                    <Box
                                        sx={{
                                            overflowY: groupLessonInfo.id == -1 ? "hidden" : "auto",
                                            height: "100%",
                                            width: "100%",
                                            "&::-webkit-scrollbar": {
                                                width: "5px",
                                            },
                                        }}
                                    >
                                        {groupLessonInfo.id === -1 && groupLessonInfo.next_lessons.length> 0 ? (
                                            <LoadingIndicator />
                                        ) : groupLessonInfo.id !== -1 && groupLessonInfo.next_lessons.length > 0 ? (
                                            groupLessonInfo.next_lessons.map((lesson: any, index: number) => {
                                                
                                                const nextLessonNumber =
                                                    (lesson.total_completed_lessons ?? 0) + 2 > lesson.total_lessons
                                                        ? lesson.total_lessons
                                                        : (lesson.total_completed_lessons ?? 0) + 2;

                                                return (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            justifyContent: "space-between",
                                                            border: "1px solid #91C6C5",
                                                            borderRadius: "4px",
                                                            mb: 1,
                                                            cursor: "pointer",
                                                            backgroundColor: index % 2 == 0 ? evenColor : oddColor,
                                                        }}
                                                        key={index}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                p: "8px",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "450",
                                                                }}
                                                            >
                                                                {lesson.project_name}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "12px",
                                                                    fontWeight: "400",
                                                                }}
                                                            >
                                                                Lesson {nextLessonNumber ?? 0}: {lesson.name}
                                                            </Typography>
                                                        </Box>
                                                        
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <Box
                                            sx={{
                                              width: '100%',
                                              height: '100%',
          
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              gap: 1
                                            }}
                                          >
                                            <Box sx={{ height: "150px", width: "150px" }}>
                                              <Image src={noLessonFound} alt="No Lessons Found" objectFit="contain" />
          
                                            </Box>
                                            <Typography sx={{ fontSize: "16px", fontWeight: '400px', color: '#8C8C8C' }}>
                                              No Upcoming Lessons</Typography>
                                            <Button sx={{ borderRadius: '4px', border: "1px solid #5C9391", color: "#5C9391", textTransform: "none" }} variant="outlined" onClick={() => {
                                              navigate(`/${currLanguage}/${MARKETPLACE}`)
                                            }}>
                                              <Typography sx={{ fontSize: '16px', fontWeight: '450' }}>Go to Lesson Plan Library</Typography>
                                            </Button>
                                          </Box>
                                        )}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            
                            {/* END OF COMPLETED ACCORDION */}


                               {/* START OF COMPLETED ACCORDION */}

                               <Accordion
                                sx={{
                                    backgroundColor: "white",
                                    width: "100%",

                                    border: "1px solid #C4E4DF",
                                }}
                                disableGutters={true}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        paddingLeft: 1,
                                        paddingRight: 1.5,

                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            flex: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: {
                                                    xs: 2,
                                                },
                                            }}
                                        >
                                            <Typography variant="h6" sx={{ fontSize: { xs: 16, sm: 20 }, color: "#5C9391" }}>
                                                Completed Projects
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        backgroundColor: "white",
                                        position: "relative",
                                        height: "300px",
                                        maxHeight: "320px",

                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        //
                                    }}
                                >
                                    <Box
                                        sx={{
                                            overflowY: groupLessonInfo.id == -1 ? "hidden" : "auto",
                                            height: "100%",
                                            width: "100%",
                                            "&::-webkit-scrollbar": {
                                                width: "5px",
                                            },
                                        }}
                                    >
                                        {groupLessonInfo.id === -1  ? (
                                            <LoadingIndicator />
                                        ) : groupLessonInfo.id !== -1 && groupLessonInfo.completed_projects && groupLessonInfo.completed_projects.length > 0 ? (
                                            groupLessonInfo.completed_projects.map((project: any, index: number) => {
                                               
                                                return (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "100%",
                                                            justifyContent: "space-between",
                                                            border: "1px solid #91C6C5",
                                                            borderRadius: "4px",
                                                            mb: 1,
                                                            cursor: "pointer",
                                                            backgroundColor: index % 2 == 0 ? evenColor : oddColor,
                                                        }}
                                                        key={index}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                p: "8px",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    fontWeight: "450",
                                                                }}
                                                            >
                                                                {project}
                                                            </Typography>
                                                           
                                                        </Box>
                                                       
                                                    </Box>
                                                );
                                            })
                                        ) : (
                                            <Box
                                            sx={{
                                              width: '100%',
                                              height: '100%',
          
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              gap: 1
                                            }}
                                          >
                                            <Box sx={{ height: "150px", width: "150px" }}>
                                              <Image src={noLessonFound} alt="No Lessons Found" objectFit="contain" />
          
                                            </Box>
                                            <Typography sx={{ fontSize: "16px", fontWeight: '400px', color: '#8C8C8C' }}>
                                              No Completed Projects</Typography>
                                            <Button sx={{ borderRadius: '4px', border: "1px solid #5C9391", color: "#5C9391", textTransform: "none" }} variant="outlined" onClick={() => {
                                              navigate(`/${currLanguage}/${MARKETPLACE}`)
                                            }}>
                                              <Typography sx={{ fontSize: '16px', fontWeight: '450' }}>Go to Lesson Plan Library</Typography>
                                            </Button>
                                          </Box>
                                        )}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            
                            {/* END OF ONGOING ACCORDION */}
                        </Box>
                    </Box>
            }
        </>
    );
};

export default MobileDashboard;

import { AxiosError } from "axios";
import {
  API_Create_Timetable_Event,
  API_Timetable_Event_Info,
  API_Create_Custom_Timetable,
  API_Custom_Timetable,
  API_Update_Custom_Timetable,
} from "types/timetable";
import axios from "api/axiosConfig";

export const getTimetableEventList = async (
  language: string,
  profileId: string
) => {
  const res = await axios.get(`${language}/timetable/event/${profileId}/list`);
  const data: API_Timetable_Event_Info[] = await res.data;
  return data;
};

export const getTimetableEvent = async (
  language: string,
  timetableEventId: number
) => {
  const res = await axios.get(
    language + `/timetable/event/${timetableEventId}`
  );
  const data: API_Timetable_Event_Info = await res.data;
  return data;
};

export const getTimetableEventListByDay = async (
  language: string,
  day: number
) => {
  const res = await axios.get(`${language}/timetable/event/day/${day}/list`);
  const data: API_Timetable_Event_Info[] = await res.data;
  return data;
};

export const createTimetableEvent = async (
  timetableEventData: API_Create_Timetable_Event
) => {
  try {
    const res = await axios.post(
      "en/timetable/event/create",
      timetableEventData
    );
    const data: API_Timetable_Event_Info = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateTimetableEvent = async (
  timetableEventData: API_Timetable_Event_Info
) => {
  try {
    const res = await axios.patch(
      `en/timetable/event/${timetableEventData.id}/update`,
      timetableEventData
    );
    const data: API_Timetable_Event_Info = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteTimetableEvent = async (timetableEventId: number) => {
  try {
    await axios.delete(`en/timetable/event/${timetableEventId}/delete`);
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getCustomTimetableByProfile = async (
  language: string,
  profileId: string
) => {
  const res = await axios.get(`${language}/timetable/custom/${profileId}`);
  const data: API_Custom_Timetable = await res.data;
  return data;
};

export const createCustomTimetable = async (
  language: string,
  customTimetableData: API_Create_Custom_Timetable,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      `${language}/timetable/custom/create`,
      customTimetableData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Custom_Timetable = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateCustomTimetable = async (
  language: string,
  customTimetableId: number,
  customTimetableData: API_Update_Custom_Timetable,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(
      `${language}/timetable/custom/${customTimetableId}/update`,
      customTimetableData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Custom_Timetable = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteCustomTimetable = async (
  language: string,
  customTimetableId: number
) => {
  try {
    await axios.delete(
      `${language}/timetable/custom/${customTimetableId}/delete`
    );
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

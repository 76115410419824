import React from "react";
import {
  Box,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

import useStore from "store/store";

import CustomImage from "../../Image/Image";
import "components/ProjectSummaryScreen/ReactGridGallery.css";
import VideoDurationDisplay from "../VideoDurationDisplay";
import { Image } from "react-grid-gallery";

import {
  isProfileInGalleryAccessRoleList,
  secondToMinuteAndSecondString,
} from "helper/helper";

type Props = {
  setCurrPhotoVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  setEditCaption: React.Dispatch<React.SetStateAction<boolean>>;
  isLearningMomentPhotoAlbum?: boolean;
  selectedPhotoVideoIDs: string[];
  setSelectedPhotoVideoIDs: React.Dispatch<React.SetStateAction<string[]>>;
  index: number;
  galleryPhotoVideo: Image;
  
}


const PhotoVideo = ({
  index,
  galleryPhotoVideo,
  setCurrPhotoVideoIndex,
  setEditCaption,
  isLearningMomentPhotoAlbum = false,
  selectedPhotoVideoIDs,
  setSelectedPhotoVideoIDs
}: Props) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    role,
  } = useStore((state) => ({
    role: state.profileDetails.role,
  }));

  const isVideo =
    galleryPhotoVideo.src.includes(".mp4") ||
    galleryPhotoVideo.src.includes(".mov");

  const isLearningMoment = String(
    galleryPhotoVideo.key
  ).includes("learning-moment");

  const isSelected = selectedPhotoVideoIDs.includes(
    String(galleryPhotoVideo.key)
  );

  const videoDuration: any = document.getElementById(
    String(galleryPhotoVideo.key)
  );

  const isCanSelect =
    isLearningMomentPhotoAlbum ||
    (!isLearningMomentPhotoAlbum && !isLearningMoment);

  const iconStyle: SxProps = {
    position: "absolute",
    top: 4,
    right: 4,
    color: "txt.secondary",
    cursor: "pointer",
    opacity:
      (isSelected || selectedPhotoVideoIDs.length !== 0) &&
        isCanSelect
          ? 1
          : 0,
    pointerEvents:
      selectedPhotoVideoIDs.length !== 0 ? "none" : "auto",
  };

  const handleSelect = () => { 
    setSelectedPhotoVideoIDs((prev) => [
      ...prev,
      String(galleryPhotoVideo.key),
    ]);
  }

  const handleDeselect = () => { 
    setSelectedPhotoVideoIDs((prev) => {
      return prev.filter(
        (p) => p !== String(galleryPhotoVideo.key)
      );
    });
  }

  const handleClick = () => {
    if (selectedPhotoVideoIDs.length !== 0 && isCanSelect) {
      isSelected ? handleDeselect() : handleSelect();
    }

    setEditCaption(false);
    setCurrPhotoVideoIndex(index);
  }

  return (
    <Box
      key={`${galleryPhotoVideo.key}`}
      sx={{
        position: "relative",
        cursor: "pointer",
        opacity: isSelected ? 1 : 0.7,
        transition: "all 200ms linear",
        "&:hover": {
          opacity: 1,
          transition: "all 200ms linear",
        },
        "&:hover .icon": {
          opacity: 1,
        },
        height: "120px",
        width: "120px",
        borderRadius: "10px",
        overflow: "hidden",
        border: "3px solid",
        borderColor: isSelected
          ? "txt.secondary"
          : "transparent",
      }}
      onClick={handleClick}
    >
      {isProfileInGalleryAccessRoleList(role) && (
        <>
          {isSelected ? (
            <CheckBoxIcon
              className="icon"
              sx={iconStyle}
              onClick={handleDeselect}
            />
          ) : isCanSelect ? (
            <CheckBoxOutlineBlankIcon
              className="icon"
              sx={iconStyle}
              onClick={handleSelect}
            />
          ) : null}
        </>
      )}
      <Box
        sx={{
          position: "relative",
          zIndex: -1,
          display: "flex",
          width: "100%",
          height: "100%",
        }}
        key={galleryPhotoVideo.key}
      >
        {isLearningMoment && (
            <Box sx={{ position: "absolute", top: 5, left: 5 }}>
                <LightbulbIcon color="primary" />
            </Box>
        )}
        {isVideo ? (
          <Box sx={{ width: "inherit", height: "inherit" }}>
            <Box
              sx={{ position: "absolute", bottom: 5, left: 5 }}
            >
              {videoDuration && videoDuration.duration && (
                <VideoDurationDisplay
                  duration={secondToMinuteAndSecondString(videoDuration.duration)}
                />
              )}
            </Box>
            <video
              id={String(galleryPhotoVideo.key)}
              height={92} //follow above parent
              preload="metadata"
            >
              <source
                src={galleryPhotoVideo.src}
                type="video/mp4"
              />
            </video>
          </Box>
        ) : (
          <CustomImage
            src={galleryPhotoVideo.src}
            objectFit="cover"
            style={{
              minWidth: "50px",
              maxWidth: isXs ? "100%" : "150px",
            }}
            alt="secondary"
          />
        )}
      </Box>
    </Box>
  );
};



export default PhotoVideo;
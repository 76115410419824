import React from "react";

import { Box, SxProps, Typography } from "@mui/material";

import Background from "images/end-of-project-report/three-polaroids.png";
import Wave from "images/end-of-project-report/wave.png";
import Image from "../../Image/Image";

import { Overlay } from "./Preview";
import { editableStyle, styles, LessonType } from "./constant";

const LT3Images = ({
  scale,
  lesson,
  handleChange,
  setOpenModal,
  setSelectedImageIndex,
  children,
}: {
  scale: number;
  lesson: LessonType;
  handleChange?: (key: string, value: string | null) => void;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedImageIndex?: React.Dispatch<React.SetStateAction<number>>;
  children?: React.ReactNode;
}) => {
  const isTemplatePreview =
    !handleChange && !setOpenModal && !setSelectedImageIndex && !children;

  const imageStyles: SxProps = {
    position: "absolute",
    width: `${325 * scale}px`,
    height: `${325 * scale}px`,
    transformOrigin: "top left",
    zIndex: 2,
    minHeight: 0,
    cursor: "pointer",
  };

  const textStyles: SxProps = {
    position: "absolute",
    textAlign: "center",
    left: `${40 * scale}px`,
    right: `${40 * scale}px`,
    zIndex: 2,
  };

  return (
    <Box sx={styles.body}>
      {children}

      <Image
        src={Wave}
        style={{
          transform: "scaleX(-1)",
        }}
        alt=""
      />

      <Box
        sx={{
          ...imageStyles,
          ...(isTemplatePreview ? {} : editableStyle),
          top: `${215 * scale}px`,
          left: `${172 * scale}px`,
          transform: "rotate(9deg)",
        }}
        onClick={() => {
          setSelectedImageIndex && setSelectedImageIndex(0);
          setOpenModal && setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={lesson.images[0].file} alt="" objectFit="cover" />
      </Box>

      <Box
        sx={{
          ...imageStyles,
          ...(isTemplatePreview ? {} : editableStyle),
          top: `${276 * scale}px`,
          left: `${563 * scale}px`,
          transform: "rotate(-1.6deg)",
        }}
        onClick={() => {
          setSelectedImageIndex && setSelectedImageIndex(1);
          setOpenModal && setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={lesson.images[1].file} alt="" objectFit="cover" />
      </Box>

      <Box
        sx={{
          ...imageStyles,
          ...(isTemplatePreview ? {} : editableStyle),
          top: `${260 * scale}px`,
          left: `${983 * scale}px`,
          transform: "rotate(-6.6deg)",
        }}
        onClick={() => {
          setSelectedImageIndex && setSelectedImageIndex(2);
          setOpenModal && setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={lesson.images[2].file} alt="" objectFit="cover" />
      </Box>

      <Typography
        sx={{
          ...textStyles,
          ...(isTemplatePreview ? {} : editableStyle),
          fontSize: 40 * scale,
          fontWeight: 500,
          lineHeight: 1.25,
          top: `${40 * scale}px`,
        }}
        contentEditable
        onBlur={(e) =>
          handleChange && handleChange("title", e.currentTarget.textContent)
        }
        suppressContentEditableWarning={true}
      >
        {lesson.title}
      </Typography>

      <Typography
        sx={{
          ...textStyles,
          ...(isTemplatePreview ? {} : editableStyle),
          fontSize: 24 * scale,
          bottom: `${40 * scale}px`,
        }}
        contentEditable
        onBlur={(e) =>
          handleChange && handleChange("caption", e.currentTarget.textContent)
        }
        suppressContentEditableWarning={true}
      >
        {lesson.caption}
      </Typography>

      <Image
        src={Background}
        style={{
          ...styles.staticImage,
          zIndex: 3,
          transform: `translateY(-${15 * scale}px)`,
        }}
        alt=""
      />
    </Box>
  );
};

export default LT3Images;

import React from "react";
import { Box, Typography } from "@mui/material";

import Background from "images/end-of-project-report/front-page-bg.png";
import Image from "components/Image/Image";

import { PDFInfoType, editableStyle, styles } from "./constant";

const FrontPage = ({
  scale,
  PDFInfo,
  setPDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
}) => {
  const handleChange = (key: string, value: string | null) =>
    setPDFInfo((prev) => ({ ...prev, [key]: value ? value : "" }));

  return (
    <Box sx={styles.body}>
      <Image src={Background} alt="" />

      <Box
        sx={{
          ...styles.main,
          p: `${40 * scale}px`,
          my: `${140 * scale}px`,
          mx: `${220 * scale}px`,
        }}
      >
        <Typography
          sx={{
            ...editableStyle,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            fontSize: 60 * scale,
            fontWeight: 500,
            lineHeight: 1.25,
            mb: `${60 * scale}px`,
            textAlign: "center",
          }}
          contentEditable
          onBlur={(e) => handleChange("name", e.currentTarget.textContent)}
          suppressContentEditableWarning={true}
        >
          {PDFInfo.name}
        </Typography>

        <Typography
          sx={{
            fontSize: 24 * scale,
            textAlign: "center",
          }}
        >
          {PDFInfo.presentedBy}
        </Typography>

        <Typography
          sx={{
            ...editableStyle,
            textAlign: "center",
            fontSize: 32 * scale,
            fontWeight: 500,
          }}
          contentEditable
          onBlur={(e) => handleChange("group", e.currentTarget.textContent)}
          suppressContentEditableWarning={true}
        >
          {PDFInfo.group}
        </Typography>

        <Typography
          sx={{
            ...editableStyle,
            textAlign: "center",
            fontSize: 32 * scale,
            fontWeight: 500,
            mt: "-1px",
          }}
          contentEditable
          onBlur={(e) => handleChange("school", e.currentTarget.textContent)}
          suppressContentEditableWarning={true}
        >
          {PDFInfo.school}
        </Typography>
      </Box>
    </Box>
  );
};

export default FrontPage;

import * as React from "react";
import { Box, Typography } from "@mui/material";
import Image from "../../../Image/Image";
import Frame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";
interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
  scale: number;
};

const ImageStructureForOne: React.FC<ImageStructureProps> = ({
  projects,
  scale,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {projects.map((project, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            height: `${600 * scale}px`,
            width: `${400 * scale}px`,
            boxSizing: "border-box",
          }}
        >
          {/* Frame Image */}
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              // backgroundColor: 'purple'
            }}
          >
            <Image
              style={{
                justifySelf: "center",
                position: "absolute",
                width: "110%",
                height: "75%",
                marginTop: "50px",
                // left: "9%",
                transform: "rotate(90deg)",
                zIndex: 3,
              }}
              src={Frame}
              alt="frame"
            />
            <Image
              style={{
                position: "absolute",
                top: "23%",
                left: 10,
                // left: "8%",
                width: "92.5%",
                height: "46%",
                objectFit: "cover",
                zIndex: 1,
              }}
              src={project.image_url}
              alt={project.title || "projects"}
            />
          </Box>
          {/* Caption positioned below the frame */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F9F6F1",
              textAlign: "center",
              m: 1,
              position: "absolute",
              top: 450,
            }}
          >
            <Typography
              sx={{
                fontSize: `${14 * scale}px`,
                fontFamily: "FuturaBkBT",
                color: "#000",
                lineHeight: "22px",
                letterSpacing: "1.4px",
                fontWeight: 400,
              }}
            >
              {project.category}
            </Typography>
            <Typography
              sx={{
                fontSize: `${12 * scale}px`,
                fontFamily: "FuturaBkBT",
                color: "#000",
                lineHeight: "18px",
                letterSpacing: "1.2px",
                fontWeight: 300,
                top: 140,
              }}
            >
              {project.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ImageStructureForOne;

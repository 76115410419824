import { Page, Text, View, Image, Font } from "@react-pdf/renderer";

import watermark from "images/ttb-watermark-pdf.png";

import { removeHtmlTagsAndStyles } from "./utils";
import { styles } from "./styles";
import LessonInstruction from "./LessonInstructions";
import { checkIsChineseChar } from "helper/helper";

interface PdfComponentProps {
  currentProject: any;
  lesson: any;
  fundamentalDict: Record<string, any>;
}

const LessonActivitiesPage = ({
  currentProject,
  lesson,
  fundamentalDict,
}: PdfComponentProps) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);

  return <Page style={styles.page} wrap>
    <View style={styles.watermarkContainer} fixed>
      <Image style={styles.watermarkImage} src={watermark} />
    </View>
    {lesson.activities && lesson.activities.length > 0 && (
      <View style={styles.regularSection} wrap>
        {lesson.activities.map((activity: any, index: number) => (
          <View
            style={styles.regularSection}
            key={`lesson-activity-${index}`}
            wrap
          >
            <View style={styles.regularSection}>
              {activity.name && (
                <Text style={styles.activityHeaderText}>
                  Activity {index + 1}: <Text style={{ ...styles.activityHeaderText, ...checkIsChineseChar(currentProject.name) ? styles.chineseText : styles.englishTextHeader }}>{removeHtmlTagsAndStyles(activity.name)}</Text>
                </Text>
              )}
            </View>
            <View style={styles.regularSection}>
              {activity.instructions.map(
                (instruction: any, instIndex: number) => (
                  <LessonInstruction
                    fundamentalDict={fundamentalDict}
                    key={instIndex}
                    assessment={!!currentProject.assessment}
                    instruction={instruction}
                    instIndex={instIndex}
                  />
                )
              )}
            </View>
          </View>
        ))}
      </View>
    )}
  </Page>
}

export default LessonActivitiesPage;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  Chip,
  Avatar,
  ListSubheader,
  Autocomplete,
  Tooltip,
  Stack,
  Switch,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";

import {
  Project_Main_Form_Data,
  Age_Group,
  API_Create_Project_Fundamental,
  ProjectCategoryModule,
  API_Create_Project,
  API_Project_Fundamental,
} from "types/project";
import useStore from "store/store";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";

import { SubtitleWithAddIcon } from "./ActivityFormComponents";
import { getInterestList } from "api/prediscoveryquestionnaire-api";
import { API_Interest } from "types/prediscoveryquestionnaire";
import Logo from "../../images/logo-2-colour.png";
import CustomButton from "../Button/Button";
import {
  deleteProjectObjective,
  generateProjectTags,
  getTextSimilarityJobStatus,
} from "api/project-api";
import useSnack from "hooks/useSnack";
import { JOB_STATUS_DONE_LIST } from "containers/teacher/TeacherPage/teacherPageConstants";
import WarningDialog from "../Dialog/WarningDialog";
import useDialog from "hooks/useDialog";
import DashedButton from "components/Button/DashedButton";

interface ModuleFieldProps {
  selectedModule: string;
  setSelectedModule: React.Dispatch<React.SetStateAction<string>>;
  moduleList: ProjectCategoryModule[];
  onChange?: (input: string) => void;
  handleCreateModal?: any;
}
const ModuleField: React.FC<ModuleFieldProps> = ({
  selectedModule,
  setSelectedModule,
  moduleList,
  onChange,
  handleCreateModal,
}) => {
  return (
    <FormControl fullWidth required>
      <InputLabel id="demo-simple-select-label">
        {translation.programme}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedModule}
        label={translation.programme}
        onChange={(event: SelectChangeEvent) => {
          setSelectedModule(event.target.value);
          onChange && onChange(event.target.value);
        }}
      >
        {useLocation().pathname.split("/").pop() === "create" && (
          <Button onClick={handleCreateModal}>
            <AddIcon sx={{ color: "#79B5B4", marginRight: 1 }} />
            <Typography
              sx={{
                width: "100%",
                display: "flex",
                color: "#79B5B4",
                textTransform: "none",
              }}
            >
              {translation.createAndManageModules}
            </Typography>
          </Button>
        )}

        {moduleList.map((mod) => (
          <MenuItem key={mod.id} value={mod.id}>
            {mod.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const NameField = ({
  label,
  data,
  setData,
  onChange,
}: {
  label?: string;
  data: Project_Main_Form_Data;
  setData: React.Dispatch<React.SetStateAction<Project_Main_Form_Data>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    sx={{
      width: "100%",
    }}
    label={label ?? translation.proj_name}
    value={data.name}
    onChange={(event) => {
      setData((prev) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

const AgeField = ({
  age,
  setAge,
  onChange,
}: {
  age: Age_Group;
  setAge: React.Dispatch<React.SetStateAction<Age_Group>>;
  onChange?: (input: Age_Group) => void;
}) => (
  <FormControl fullWidth required>
    <InputLabel id="demo-simple-select-label">{translation.level}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={age}
      label={translation.age}
      onChange={(event: SelectChangeEvent) => {
        setAge(event.target.value as Age_Group);
        onChange && onChange(event.target.value as Age_Group);
      }}
    >
      <MenuItem value="Sprouts">{translation.sprouts_with_age}</MenuItem>
      <MenuItem value="Sproutlings">
        {translation.sproutlings_with_age}
      </MenuItem>
      <MenuItem value="Buds">{translation.buds_with_age}</MenuItem>
      <MenuItem value="Blossoms">{translation.blossoms_with_age}</MenuItem>
      <MenuItem value="Blooms">{translation.blooms_with_age}</MenuItem>
    </Select>
  </FormControl>
);

const DurationField = ({
  data,
  setData,
  onChange,
}: {
  data: Project_Main_Form_Data;
  setData: React.Dispatch<React.SetStateAction<Project_Main_Form_Data>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={translation.proj_duration}
    value={data.duration}
    onChange={(event) => {
      let currVal = Number(event.target.value);

      if (Number.isNaN(currVal)) {
        currVal = Number(data.duration) || 0;
      } else if (currVal < 0) {
        currVal = 0;
      }

      setData((prev) => ({ ...prev, duration: currVal.toString() }));
      onChange && onChange(currVal.toString());
    }}
    required
  />
);

const DescriptionField = ({
  label,
  data,
  setData,
  onChange,
}: {
  label?: string;
  data: Project_Main_Form_Data;
  setData: React.Dispatch<React.SetStateAction<Project_Main_Form_Data>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>
      {label ?? translation.proj_description}
      <Typography component="span" sx={{ color: "red", ml: "2px" }}>
        *
      </Typography>
    </Typography>

    <RichCKEditor
      data={data.description}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setData((prev) => ({ ...prev, description: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

export const AssessmentTagField = ({
  label,
  assessmentTag,
  setAssessmentTag,
  onChange,
}: {
  label?: string;
  assessmentTag: string;
  setAssessmentTag: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (input: string) => void;
}) => (
  <FormControl fullWidth >
    <InputLabel id="demo-simple-select-label">{"Assessment Tag"}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={assessmentTag}
      label={"Assessment Tag"}
      onChange={(event: SelectChangeEvent) => {
        setAssessmentTag(event.target.value as Age_Group);
        onChange && onChange(event.target.value as Age_Group);
      }}
    >
      <MenuItem value="Assessment 1">{"Assessment 1"}</MenuItem>
      <MenuItem value="Assessment 2">{"Assessment 2"}</MenuItem>
      <MenuItem value="Assessment 3">{"Assessment 3"}</MenuItem>
    </Select>
  </FormControl>
);
export const TagsField = ({
  data,
  setData,
  onChange,
}: {
  data: Project_Main_Form_Data;
  setData: React.Dispatch<React.SetStateAction<Project_Main_Form_Data>>;
  onChange?: (input: string) => void;
}) => {
  const textSimilarityJobId = "text_similarity_job_id";
  const textSimilarityJobProjectId = "text_similarity_job_project_id";
  const openSnack = useSnack();
  const [isClick, setIsClick] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string | null>(
    localStorage.getItem(textSimilarityJobId)
  );
  const [jobStatus, setJobStatus] = useState<string | null>(null);

  const { currentProject } = useStore((state) => state);
  const [interestList, setInterestList] = useState<API_Interest[]>([]);
  useEffect(() => {
    const populateData = async () => {
      const interestListRes = await getInterestList();
      setInterestList(interestListRes);
    };

    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is where you poll the task status
  useEffect(() => {
    if (!jobId) {
      return;
    }

    const interval = setInterval(async () => {
      const res = await getTextSimilarityJobStatus(jobId);

      // If job is done, clear the interval
      if (typeof res !== "string") {
        // console.log("res: ", res);
        console.log(`Text similarity status now: ${res.status}`);
        setJobStatus(res.status);
        if (JOB_STATUS_DONE_LIST.includes(res.status)) {
          clearInterval(interval);
          localStorage.removeItem(textSimilarityJobId);
          localStorage.removeItem(textSimilarityJobProjectId);
          setJobId(null);
          setJobStatus(null);
          // setRefreshProjAndLess(true); // This is not working, in future, query by only the project and refresh that state.
          openSnack("Tag is generated successfully!", true);
        }
      }
    }, 10000); // Check every 10 seconds

    return () => clearInterval(interval);
  }, [jobId]);

  const handlePopulateTags = async () => {
    setIsClick(true);
    const populateTagsRes = await generateProjectTags(
      currentProject.id,
      localStorage.getItem("access")
    );
    if (typeof populateTagsRes !== "string") {
      // console.log("populateTagsRes: ", populateTagsRes);
      const jobIdTemp = populateTagsRes.job_id ? populateTagsRes.job_id : null;
      if (jobIdTemp) {
        setJobId(jobIdTemp);
        localStorage.setItem(textSimilarityJobId, jobIdTemp);
        localStorage.setItem(
          textSimilarityJobProjectId,
          currentProject.id.toString()
        );
      }
    } else {
      openSnack("Something went wrong. Please try again", false);
    }
    setIsClick(false);
  };
  const isJobDone = JOB_STATUS_DONE_LIST.includes(jobStatus!);

  return (
    <>
      <Typography>{translation.projectTags}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormControl sx={{ marginTop: "0px !important", flex: 1 }}>
          {/* <InputLabel id="multiple-chip-label">{translation.profiles}</InputLabel> */}
          <Autocomplete
            multiple
            id="tags-standard"
            value={interestList.filter((interest) =>
              data.tags.split(",").includes(interest.name)
            )}
            onChange={(event, values) => {
              const projectTags = values.map((interest) => interest.name);
              setData((prev) => ({ ...prev, tags: projectTags.join(",") }));
            }}
            options={interestList}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Tags" />
            )}
            renderTags={(value, getTagProps) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {value.map((option, index) => (
                      <Chip
                        key={`chip-of-${option.name}`}
                        avatar={
                          <Avatar
                            alt={option.name}
                            src={option.image}
                            imgProps={{
                              onError: (
                                event: React.SyntheticEvent<
                                  HTMLImageElement,
                                  Event
                                >
                              ) => {
                                event.currentTarget.onerror = null;
                                event.currentTarget.src = Logo;
                              },
                            }}
                          />
                        }
                        // key={`${option.preferred_name}-${option.profile_id}`}
                        label={option.name}
                        //   {...getTagProps({ index })}
                      />
                    ))}
                  </Box>
                </>
              );
            }}
          />
        </FormControl>
        {currentProject.id !== -1 && currentProject.id && (
          <Tooltip
            title={
              (!!jobId &&
                !isJobDone &&
                localStorage.getItem(textSimilarityJobProjectId) ===
                  currentProject.id.toString()) ||
              isClick
                ? "The existing tags will be overwritten in the backend once the process is done. You can close the window/continue updating the content."
                : ""
            }
          >
            <span>
              <CustomButton
                buttonText={
                  !!jobId && !isJobDone
                    ? `${
                        localStorage.getItem(textSimilarityJobProjectId) ===
                        currentProject.id.toString()
                          ? "Tagging is in progress, please wait for a few mins... "
                          : "Tagging another project..."
                      }`
                    : "Auto Populate Tags"
                }
                arrow={false}
                onClick={handlePopulateTags}
                isLoading={
                  (!!jobId &&
                    !isJobDone &&
                    localStorage.getItem(textSimilarityJobProjectId) ===
                      currentProject.id.toString()) ||
                  isClick
                }
                disabled={(!!jobId && !isJobDone) || isClick}
              />
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

const DevelopmentalGoalsField = ({
  fundamentals,
  setFundamentals,
  deleteFundamental,
  setDeleteFundamental,
  onChange,
}: {
  fundamentals: API_Create_Project_Fundamental[];
  setFundamentals: React.Dispatch<
    React.SetStateAction<API_Create_Project_Fundamental[]>
  >;
  deleteFundamental?: boolean[];
  setDeleteFundamental?: React.Dispatch<React.SetStateAction<boolean[]>>;
  onChange?: () => void;
}) => {
  const { fundamentalListToDisplay, fundamentalDict } = useStore((state) => state);

  const projectId = useStore((state) => state.currentProject.id);

  return fundamentalListToDisplay.length === 0 ? null : (
    <Box>
      <SubtitleWithAddIcon
        subtitle={`${translation.fundamental} (legacy)`}
        handleAdd={() => {
          const updatedFundamentals = [...fundamentals];
          updatedFundamentals.push({
            project: projectId.toString(),
            fundamental: "-1",
            objective: "",
          });
          setFundamentals(updatedFundamentals);
          onChange && onChange();
        }}
        disabled
      />

      {fundamentals.map((fundamental, index) => {
        const isDelete =
          deleteFundamental &&
          index < deleteFundamental.length &&
          deleteFundamental[index]
            ? true
            : false;

        return (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              mt: "16px",
              mr: "12px",
              alignItems: "center",
            }}
            key={index}
          >
            <FormControl
              fullWidth
              required
              // disabled={isDelete}
              disabled={true}
              sx={{
                maxWidth: "350px",
                textDecoration: isDelete ? "line-through" : "none",
              }}
            >
              <InputLabel id="demo-simple-select-label">Goal</InputLabel>
              {/* use this */}
              <Select
                labelId="multiple-chip-label-developmental-goal-objective"
                value={fundamental.fundamental}
                onChange={(event: SelectChangeEvent) => {
                  const updatedFundamentals = fundamentals.map((f) => ({
                    ...f,
                  }));
                  updatedFundamentals[index].fundamental = event.target.value;
                  setFundamentals(updatedFundamentals);
                  onChange && onChange();
                }}
                input={
                  <OutlinedInput
                    id="select-multiple-chip-developmental-goal-objective"
                    label="Goal"
                  />
                }
                renderValue={(learningMomentFundamentalId) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    <Chip
                      avatar={
                        <Avatar
                          alt={fundamentalDict[fundamental.fundamental].name}
                          src={fundamentalDict[fundamental.fundamental].image}
                        />
                      }
                      key={fundamental.fundamental}
                      label={fundamentalDict[fundamental.fundamental].name}
                    />
                  </Box>
                )}
              >
                {fundamentalListToDisplay.map((fundamental) => {
                  return fundamental.sub_fundamentals &&
                    fundamental.sub_fundamentals.length > 0 ? (
                    <ListSubheader
                      key={`${fundamental.id}-sub-header`}
                      disableGutters
                    >
                      {fundamental.name}
                    </ListSubheader>
                  ) : fundamental.main_fundamental ? (
                    <MenuItem
                      key={`${fundamental.id}-dev-goal`}
                      value={fundamental.id}
                    >
                      {fundamental.name}
                    </MenuItem>
                  ) : (
                    [
                      <ListSubheader
                        key={`${fundamental.id}-sub-header`}
                        disableGutters
                      >
                        {fundamental.name}
                      </ListSubheader>,
                      <MenuItem
                        key={`${fundamental.id}-dev-goal`}
                        value={fundamental.id}
                      >
                        {fundamental.name}
                      </MenuItem>,
                    ]
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label={translation.objective_str}
              value={fundamental.objective}
              onChange={(event) => {
                const updatedFundamentals = fundamentals.map((f) => ({
                  ...f,
                }));
                updatedFundamentals[index].objective = event.target.value;
                setFundamentals(updatedFundamentals);
                onChange && onChange();
              }}
              // disabled={isDelete}
              disabled={true}
              sx={{
                textDecoration: isDelete ? "line-through" : "none",
              }}
            />

            {isDelete && (
              <Box
                sx={{
                  backgroundColor: "#fc6f6fb3",
                  borderRadius: "10px",
                  p: "5px 10px",
                  fontSize: "12px",
                  userSelect: "none",
                }}
              >
                {translation.delete}
              </Box>
            )}

            {/* <ClearIcon
              sx={{
                color: "red",
                cursor: "pointer",
                transition: "all 100ms linear",
                "&:hover": {
                  color: "darkred",
                  transition: "all 100ms linear",
                },
              }}
              onClick={() => {
                if (
                  deleteFundamental &&
                  setDeleteFundamental &&
                  index < deleteFundamental.length
                ) {
                  const newDelFundamental = JSON.parse(
                    JSON.stringify(deleteFundamental)
                  );
                  newDelFundamental[index] = !newDelFundamental[index];
                  setDeleteFundamental(newDelFundamental);
                } else {
                  const updatedFundamentals = fundamentals.map((f) => ({
                    ...f,
                  }));
                  updatedFundamentals.splice(index, 1);
                  setFundamentals(updatedFundamentals);
                }

                onChange && onChange();
              }}
            /> */}
          </Box>
        );
      })}
    </Box>
  );
};

const ObjectiveField = ({
  data,
  setData,
  onChange,
}: {
  data: Project_Main_Form_Data;
  setData: React.Dispatch<React.SetStateAction<Project_Main_Form_Data>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>
      {translation.learning_objectives}
      <Typography component="span" sx={{ color: "red", ml: "2px" }}>
        *
      </Typography>
    </Typography>

    <RichCKEditor
      data={data.objective}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setData((prev) => ({ ...prev, objective: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

export const ProjectObjectiveField = ({
  label,
  project,
  setProject,
  onChange,
}: {
  label?: string;
  project: API_Create_Project;
  setProject: React.Dispatch<React.SetStateAction<API_Create_Project>>;
  onChange?: (input: string) => void;
}) => {
  const { fundamentalDict } = useStore((state) => state);

  const handleAdd = () => {
    const updatedProject: API_Create_Project = JSON.parse(
      JSON.stringify(project)
    );
    updatedProject.objective_fundamentals = [
      ...updatedProject.objective_fundamentals,
      {
        id: `unassigned-id-${updatedProject.objective_fundamentals.length}`,
        project: -1,
        fundamental: fundamentalDict[Object.keys(fundamentalDict)[0]].id,
        milestone:
          Number(
            fundamentalDict[Object.keys(fundamentalDict)[0]].milestones[0].id
          ) || -1,
      },
    ];

    setProject(updatedProject);
  };

  const handleDevelopmentalGoalChange = (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => {
    const fundamentalId = Number(event.target.value);
    const updatedProject: API_Create_Project = JSON.parse(
      JSON.stringify(project)
    );
    updatedProject.objective_fundamentals[
      objectiveFundamentalIndex
    ].fundamental = fundamentalId;
    updatedProject.objective_fundamentals[objectiveFundamentalIndex].milestone =
      Number(fundamentalDict[fundamentalId].milestones[0].id) || -1;

    setProject(updatedProject);
  };

  const handleMilestoneChange = (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => {
    const milestoneId = Number(event.target.value);
    const updatedProject: API_Create_Project = JSON.parse(
      JSON.stringify(project)
    );
    updatedProject.objective_fundamentals[objectiveFundamentalIndex].milestone =
      milestoneId;
    setProject(updatedProject);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        alignItems: "left",
      }}
    >
      <Typography>{label ?? translation.learningObjectives} </Typography>
      {project.objective_fundamentals.map((objectiveFundamental, index) => {
        return (
          <ProjectObjectiveFundamentalField
            key={`objective-fundamental-id-${objectiveFundamental.id}`}
            objectiveFundamental={objectiveFundamental}
            index={index}
            handleDevelopmentalGoalChange={handleDevelopmentalGoalChange}
            handleMilestoneChange={handleMilestoneChange}
            project={project}
            setProject={setProject}
          />
        );
      })}
      <DashedButton
        onClick={handleAdd}
        text={translation.addObjective || "Add Objective"}
      />
    </Box>
  );
};

const ProjectObjectiveFundamentalField = ({
  objectiveFundamental,
  index,
  handleDevelopmentalGoalChange,
  handleMilestoneChange,
  project,
  setProject,
}: {
  objectiveFundamental: API_Project_Fundamental;
  index: number;
  handleDevelopmentalGoalChange: (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => void;
  handleMilestoneChange: (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => void;
  project: API_Create_Project;
  setProject: React.Dispatch<React.SetStateAction<API_Create_Project>>;
}) => {
  const openSnack = useSnack();
  const { fundamentalDict, fundamentalListToDisplay } = useStore((state) => state);
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const removeProjectObjectiveFundamental = () => {
    const updatedObjectiveFundamentals: API_Project_Fundamental[] = JSON.parse(
      JSON.stringify(project.objective_fundamentals)
    );
    let filteredObjectiveFundamentals = updatedObjectiveFundamentals.filter(
      (objFundamental) => objFundamental.id !== objectiveFundamental.id
    );

    if (objectiveFundamental.id.toString().includes("unassigned-id")) {
      let count = -1;
      filteredObjectiveFundamentals = filteredObjectiveFundamentals.map(
        (objFundamental, index) => {
          if (objFundamental.id.toString().includes("unassigned-id")) {
            count += 1;
            return { ...objFundamental, id: `unassigned-id-${count}` };
          } else {
            return objFundamental;
          }
        }
      );
    }
    setProject({
      ...project,
      objective_fundamentals: filteredObjectiveFundamentals,
    });
  };

  const handleDelete = async () => {
    const res = await deleteProjectObjective(Number(objectiveFundamental.id));
    if (res !== "success") {
      openSnack(res, false);
    } else {
      removeProjectObjectiveFundamental();
      openSnack(translation.deleteSuccess, true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! The Project Objective will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Project Objective?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="multiple-chip-label-developmental-goal-learning-moment">
            Developmental Goal
          </InputLabel>

          <Select
            labelId="multiple-chip-label-developmental-goal-learning-moment"
            value={
              objectiveFundamental
                ? objectiveFundamental.fundamental.toString()
                : "-1"
            }
            onChange={(event) => {
              handleDevelopmentalGoalChange(event, index);
            }}
            input={
              <OutlinedInput
                id="select-multiple-chip-developmental-goal-learning-moment"
                label="Developmental Goal"
              />
            }
            renderValue={(lessonObjectiveFundamentalId) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                <Chip
                  avatar={
                    <Avatar
                      alt={fundamentalDict[lessonObjectiveFundamentalId].name}
                      src={fundamentalDict[lessonObjectiveFundamentalId].image}
                    />
                  }
                  key={lessonObjectiveFundamentalId}
                  label={fundamentalDict[lessonObjectiveFundamentalId].name}
                />
              </Box>
            )}
          >
            {fundamentalListToDisplay.map((fundamental) => {
              return fundamental.sub_fundamentals &&
                fundamental.sub_fundamentals.length > 0 ? (
                <ListSubheader
                  key={`${fundamental.id}-sub-header`}
                  disableGutters
                >
                  {fundamental.name}
                </ListSubheader>
              ) : fundamental.main_fundamental ? (
                <MenuItem
                  key={`${fundamental.id}-dev-goal`}
                  value={fundamental.id}
                >
                  {fundamental.name}
                </MenuItem>
              ) : (
                [
                  <ListSubheader
                    key={`${fundamental.id}-sub-header`}
                    disableGutters
                  >
                    {fundamental.name}
                  </ListSubheader>,
                  <MenuItem
                    key={`${fundamental.id}-dev-goal`}
                    value={fundamental.id}
                  >
                    {fundamental.name}
                  </MenuItem>,
                ]
              );
            })}
          </Select>
        </FormControl>
        {fundamentalDict[objectiveFundamental.fundamental || "-1"]
          .milestones ? (
          <>
            {fundamentalDict[objectiveFundamental.fundamental || "-1"]
              .milestones.length > 0 ? (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="multiple-chip-label-milestone">
                  {translation.milestone}
                </InputLabel>

                <Select
                  labelId="multiple-chip-label-milestone"
                  value={
                    objectiveFundamental.milestone
                      ? objectiveFundamental.milestone?.toString()
                      : "-1"
                  }
                  onChange={(event) => {
                    handleMilestoneChange(event, index);
                  }}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip-milestone-learning-moment"
                      label="Milestones"
                    />
                  }
                  renderValue={(selectedMilestoneId) => {
                    const filteredMilestone = fundamentalDict[
                      objectiveFundamental.fundamental || "-1"
                    ].milestones.filter(
                      (milestone) =>
                        milestone.id.toString() === selectedMilestoneId
                    );
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        <Chip
                          key={selectedMilestoneId}
                          label={
                            filteredMilestone.length > 0
                              ? filteredMilestone[0].name
                              : "Please select a milestone"
                          }
                        />
                      </Box>
                    );
                  }}
                >
                  {fundamentalDict[
                    objectiveFundamental
                      ? objectiveFundamental.fundamental
                      : "-1"
                  ].milestones.map((milestone) => (
                    <MenuItem key={milestone.id} value={milestone.id}>
                      {milestone.name}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="-1">
                    Please select a milestone
                  </MenuItem>
                </Select>
              </FormControl>
            ) : null}
          </>
        ) : null}
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (
              typeof objectiveFundamental.id === "string" &&
              objectiveFundamental.id.includes("unassigned")
            ) {
              removeProjectObjectiveFundamental();
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Stack>
    </>
  );
};

const GuidingQuestionField = ({
  data,
  setData,
  onChange,
}: {
  data: Project_Main_Form_Data;
  setData: React.Dispatch<React.SetStateAction<Project_Main_Form_Data>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>{translation.guidingQuestions}</Typography>

    <RichCKEditor
      data={data.guiding_question}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setData((prev) => ({ ...prev, guiding_question: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

const PublishField = ({
  publish,
  setPublish,
  onChange,
}: {
  publish: boolean;
  setPublish: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: (input: boolean) => void;
}) => (
  <Box
    sx={{
      display: "flex",
      height: "56px",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Switch
      checked={publish}
      onChange={() => {
        setPublish(!publish);
        onChange && onChange(!publish);
      }}
    />
    <Typography>{translation.publish}</Typography>
  </Box>
);

export {
  ModuleField,
  NameField,
  AgeField,
  DurationField,
  DescriptionField,
  DevelopmentalGoalsField,
  ObjectiveField,
  GuidingQuestionField,
  PublishField,
};

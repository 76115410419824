import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

export const ModalContainer = styled(Box)`
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 646px;
  padding: 16px;
  border-radius: 10px;
  overflow: auto;
  max-height: 100%;
  &:focus-visible {
    outline: none;
  }
  z-index: 100;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const HeadingStyle = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  font-family: Futura PT;
  color: #323031;
  font-style: normal;
`;

export const ContentContainer = styled(Box)`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 16px;
  margin-bottom: 24px;
`;

export const CropBox = styled(Box)`
  width: 528px;
  height: auto;
  position: relative;
`;

export const CropBoxButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
`;

export const ImageContainer = styled(Box)`
  width: 528px;
  height: 336px;
  display: flex;
`;

export const PhotoEditorContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  top: 0;
  right: 0;
`;

export const ActionButtonsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 16px;
`;

export const SaveButton = styled(Button)`
  background-color: #91c6c5;
  flex: 1;
  border-radius: 4px;
  border:none;
  &:hover {
    background-color: #d8f2ee;
  }
`;

export const CancelButton = styled(Button)`
  background-color: #ffffff;
  flex: 1;
  border-radius: 4px;
  border: 1px solid var(--Cyan-700, #5c9391);
`;

export const ButtonText = styled(Typography)`
  font-family: "Futura PT";
  font-size: 16px;
  font-weight: 450;
  color: white;
`;

export const ButtonCancelText = styled(Typography)`
  font-family: "Futura PT";
  font-size: 16px;
  font-weight: 450;
  color: #91c6c5;
`;

export const HideDateButton = styled(Typography)`
  color: var(--Cyan-800, #466b6a);
  font-family: "Futura PT";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
`;

import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "components/Button/Button";
import {
  API_Document,
  API_Create_Document,
  API_Update_Document,
  API_Document_File,
} from "types/announcement";
import useStore from "store/store";
import {
  addDocumentFile,
  createDocument,
  deleteDocumentFile,
  downloadDocumentFile,
  updateDocument,
} from "api/announcement-api";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import Image from "components/Image/Image";
import { useParams } from "react-router-dom";
import { API_Group_Full } from "types/group";
import DashboardDocumentCard from "containers/WelcomeBackPage/components/ParentDashboardComponents/DashboardDocumentCard";
import SmallLoadingIndicator from "components/LoadingIndicator/SmallLoadingIndicator";

const CustomModal = ({
  open,
  handleClose,
  children,
}: {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      sx: {
        backgroundColor: "rgba(49,47,48,0.4)",
      },
    }}
    sx={{
      m: "24px",
    }}
  >
    <Fade in={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "600px",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: "10px",
          overflow: "auto",
          maxHeight: "100%",
          color: "#312F30",
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </Box>

        {children}
      </Box>
    </Fade>
  </Modal>
);

export const DocumentModal = ({
  open,
  handleClose,
  document,
  setDocuments,
}: {
  open: boolean;
  handleClose: () => void;
  document: API_Document;
  setDocuments: React.Dispatch<React.SetStateAction<API_Document[]>>;
}) => {
  const openSnack = useSnack();
  const { schoolId } = useParams();

  const { groupList } = useStore((state) => ({
    groupList: state.groupList,
  }));

  const classList = groupList.filter(
    (group) => group.school.toString() === schoolId
  );

  const classIds = classList.map((group) => group.id);

  const getClassIds = (): number[] => {
    const classIds: number[] = [];

    classList.forEach((group) => {
      const childrenIds = group.children.map((child) => child.id);

      if (
        childrenIds.length > 0 &&
        childrenIds.every((profileId) => document.profiles.includes(profileId))
      ) {
        classIds.push(group.id);
      }
    });

    return classIds;
  };

  const [formData, setFormData] = useState<API_Document>(document);
  const [selectedClassIds, setSelectedClassIds] = useState<number[]>(
    document.id === -1 ? classIds : getClassIds()
  );

  const [files, setFiles] = useState<File[]>([]);
  const [delFiles, setDelFiles] = useState<boolean[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isCreate = document.id === -1;

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const getProfiles = () =>
    Array.from(
      new Set(
        classList
          .filter((group) => selectedClassIds.includes(group.id))
          .flatMap((group) => group.children)
          .map((child) => child.id)
      )
    );

  const handleAdd = async () => {
    if (files.length === 0) {
      openSnack(translation.pleaseUploadAtLeastOneDocument, false);
      return;
    }
    setIsLoading(true);

    const profiles = getProfiles();

    const data: API_Create_Document = {
      school: formData.school,
      files,
      profiles,
    };

    const documents = await createDocument(currLanguage, data);

    if (typeof documents !== "string") {
      openSnack(translation.createSuccess, true);
      setDocuments((prev) => [...documents, ...prev]);
      handleClose();
    } else {
      openSnack(translation.createFailed, false);
    }

    setIsLoading(false);
  };

  const handleUpdate = async () => {
    setIsLoading(true);

    if (files.length > 0) {
      // Delete all files, to remain file number as one, cause now it only support 1
      await deleteDocumentFile(currLanguage, document.files[0].id);
      // Then add (if support multiple, delete the line above)
      await addDocumentFile(currLanguage, formData.id, files);
    }

    for (let i = 0; i < delFiles.length; i++) {
      if (delFiles[i]) {
        await deleteDocumentFile(currLanguage, formData.files[i].id);
      }
    }

    const profiles = getProfiles();
    const data: API_Update_Document = {
      ...formData,
      profiles,
    };

    const updatedDocument = await updateDocument(
      currLanguage,
      formData.id,
      data
    );

    if (typeof updatedDocument !== "string") {
      openSnack(translation.updateSuccess, true);
      setDocuments((prev) =>
        prev.map((document) =>
          document.id === updatedDocument.id ? updatedDocument : document
        )
      );
      handleClose();
    } else {
      openSnack(translation.updateFailed, false);
    }

    setIsLoading(false);
  };

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Typography
        sx={{
          fontSize: "1.25rem",
          fontWeight: 500,
          mb: 2,
        }}
      >
        {isCreate ? translation.createDocument : translation.editDocument}
      </Typography>

      <DocumentForm
        formData={formData}
        setFormData={setFormData}
        handleSubmit={isCreate ? handleAdd : handleUpdate}
        isCreate={isCreate}
        isLoading={isLoading}
        fileField={
          <FileField
            files={files}
            setFiles={setFiles}
            oldFiles={document}
            delFiles={delFiles}
            setDelFiles={setDelFiles}
            language={currLanguage}
            multiple={isCreate ? true : false}
            isCreate={isCreate}
            isRequired
          />
        }
        classSelectionField={
          <ClassSelectionField
            classList={classList}
            classIds={classIds}
            selectedClassIds={selectedClassIds}
            setSelectedClassIds={setSelectedClassIds}
          />
        }
      />
    </CustomModal>
  );
};

const DocumentForm = ({
  formData,
  setFormData,
  handleSubmit,
  isCreate,
  isLoading,
  fileField,
  classSelectionField,
}: {
  formData: API_Document;
  setFormData: React.Dispatch<React.SetStateAction<API_Document>>;
  handleSubmit: () => void;
  isCreate: boolean;
  isLoading: boolean;
  fileField: React.ReactNode;
  classSelectionField: React.ReactNode;
}) => (
  <Stack spacing={2}>
    <Stack spacing={1.5} direction="column">
      {/* <TextField
        autoComplete="off"
        fullWidth
        required
        label={translation.name}
        value={formData.name}
        onChange={(event) => {
          setFormData((prev) => ({
            ...prev,
            name: event.target.value,
          }));
        }}
      /> */}
      {classSelectionField}
      {fileField}
    </Stack>

    <Button
      arrow={!isLoading}
      buttonText={
        isLoading
          ? translation.loading || "Loading"
          : isCreate
          ? translation.submit || "Submit"
          : translation.update || "Update"
      }
      style={{
        opacity: isLoading ? 0.5 : 1,
        minHeight: "45px",
      }}
      onClick={handleSubmit}
      disabled={isLoading}
      isLoading={isLoading}
    />
  </Stack>
);

const FileField = ({
  files,
  setFiles,
  multiple = false,
  oldFiles,
  delFiles,
  setDelFiles,
  language,
  isCreate,
  isRequired,
}: {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  multiple?: boolean;
  oldFiles?: API_Document;
  delFiles?: boolean[];
  setDelFiles?: React.Dispatch<React.SetStateAction<boolean[]>>;
  language?: string;
  isCreate?: boolean;
  isRequired?: boolean;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>
      {isCreate ? translation.documents : translation.document}{" "}
      {isRequired ? <span style={{ color: "red" }}>*</span> : ""}
    </Typography>

    <input
      type="file"
      id="files-button"
      multiple={multiple}
      hidden
      onChange={(event) => {
        const files: File[] = Array.prototype.slice.call(event.target.files);

        if (files.length === 0) {
          event.target.value = "";
          return;
        }

        if (multiple) {
          setFiles((prev: File[]) => [...prev, ...files]);
        } else {
          setFiles([...files]);
        }

        event.target.value = "";
      }}
    />

    <label htmlFor="files-button">
      <IconButton
        component="span"
        sx={{
          borderRadius: "8px",
          backgroundColor: "primary.light",
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
      >
        <ImageIcon sx={{ color: "txt.secondary" }} />
      </IconButton>
    </label>

    <Box
      sx={{
        display: "flex",
        rowGap: 0.75,
        columnGap: 1.5,
        flexWrap: "wrap",
        my: 1,
      }}
    >
      {(files.length === 0 || multiple) && //can remove files.length if support multiple
        oldFiles &&
        delFiles &&
        setDelFiles &&
        language &&
        oldFiles.files.map((oldFile, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
            key={index}
          >
            <a
              style={{
                textDecoration: `underline${
                  delFiles[index] ? " line-through" : ""
                }`,
              }}
              href={oldFile.file}
              target="_blank"
              rel="noreferrer"
            >
              {oldFile.name}
            </a>

            {/* <ClearIcon
              sx={{
                color: "txt.secondary",
                cursor: "pointer",
                backgroundColor: "primary.light",
                borderRadius: "50%",
                p: "3px",
                transition: "all 100ms linear",
                "&:hover": {
                  p: "4px",
                  backgroundColor: "primary.main",
                  transition: "all 100ms linear",
                },
              }}
              onClick={() => {
                setDelFiles((prev) => {
                  const temp = JSON.parse(JSON.stringify(prev));
                  temp[index] = !temp[index];
                  return temp;
                });
              }}
            /> */}
          </Box>
        ))}

      {files.map((file, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer">
            {file.name}
          </a>

          <ClearIcon
            sx={{
              color: "txt.secondary",
              cursor: "pointer",
              backgroundColor: "primary.light",
              borderRadius: "50%",
              p: "3px",
              transition: "all 100ms linear",
              "&:hover": {
                p: "4px",
                backgroundColor: "primary.main",
                transition: "all 100ms linear",
              },
            }}
            onClick={() => {
              setFiles((prev) => {
                const temp = [...prev];
                temp.splice(index, 1);
                return temp;
              });
            }}
          />
        </Box>
      ))}
    </Box>
  </Box>
);

const ClassSelectionField = ({
  classList,
  classIds,
  selectedClassIds,
  setSelectedClassIds,
}: {
  classList: API_Group_Full[];
  classIds: number[];
  selectedClassIds: number[];
  setSelectedClassIds: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedClassIds>) => {
    const {
      target: { value },
    } = event;

    if ((value as (string | number)[]).includes("all")) {
      if (selectedClassIds.length === classIds.length) {
        setSelectedClassIds([]);
      } else {
        setSelectedClassIds(classIds);
      }
    } else {
      setSelectedClassIds(
        typeof value === "string"
          ? value.split(",").map((v) => Number(v))
          : value
      );
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="class-selector">{translation.group}</InputLabel>
      <Select
        labelId="class-selector"
        label={translation.group}
        multiple
        value={selectedClassIds}
        onChange={handleChange}
        input={<OutlinedInput label={translation.group} />}
        renderValue={(selected) =>
          selectedClassIds.length === classIds.length
            ? "All Classes"
            : selectedClassIds.length === 0
            ? "No Class"
            : classList
                .filter((group) => selected.includes(group.id))
                .map((group) => group.name)
                .join(", ")
        }
        MenuProps={{
          autoFocus: false,
          sx: {
            maxHeight: "300px",
          },
        }}
      >
        <MenuItem value="all">
          <ListItemText primary="Select All" />
          <Checkbox checked={selectedClassIds.length === classIds.length} />
        </MenuItem>

        {classList.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            <Image
              src={group.image ? group.image : ""}
              style={{ width: 20, height: 20, marginRight: 8 }}
              alt={group.name}
            />
            <ListItemText
              primary={group.name}
              primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            />
            <Checkbox checked={selectedClassIds.indexOf(group.id) > -1} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const DocumentCard = ({
  document,
  handleClickEdit,
  handleClickDelete,
  isViewOnly = true,
  isEditable = true,
}: {
  document: API_Document;
  handleClickEdit: () => void;
  handleClickDelete: () => void;
  isViewOnly?: boolean;
  isEditable?: boolean;
}) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
  const handleDownload = (file: API_Document_File) => {
    const urls = [file.file];
    const names = [file.name];
    const zipName = `_`;
    downloadDocumentFile(urls, names, zipName, setIsDownloadLoading);
  };

  return (
    <DashboardDocumentCard
      document={document}
      sx={{
        position: "relative",
        // borderRadius: "10px",
        // border: "1px solid #C4C4C4",
        p: 2,
      }}
      isViewOnly={isViewOnly}
    >
      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          display: "flex",
          alignItems: "center",
        }}
      >
        {isDownloadLoading ? (
          <SmallLoadingIndicator />
        ) : (
          <IconButton
            onClick={() => {
              handleDownload(document.files[0]);
            }}
            size="small"
          >
            <DownloadIcon
              fontSize="small"
              sx={{
                color: "txt.secondary",
              }}
            />
          </IconButton>
        )}
        {/* 
        {!isViewOnly && isEditable && (
          <>
            <IconButton onClick={handleClickEdit} size="small">
              <EditIcon
                fontSize="small"
                sx={{
                  color: "txt.secondary",
                }}
              />
            </IconButton>

            <IconButton onClick={handleClickDelete} size="small">
              <DeleteIcon
                fontSize="small"
                sx={{
                  color: "txt.secondary",
                }}
              />
            </IconButton>
          </>
        )} */}
      </Box>
    </DashboardDocumentCard>
  );
};

import { Stack, TextField } from "@mui/material";
import Button from "components/Button/Button";
import { useEffect, useState } from "react";
import { createReflection, updateReflection } from "api/profile-api";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_Reflection } from "types/profile";

const ReflectionForm = ({
  reflection,
  handleClose,
}: {
  reflection: API_Reflection;
  handleClose: () => void;
}) => {
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );
  const openSnack = useSnack();

  const [formData, setFormData] = useState<API_Reflection>(reflection);
  const [isCreate, setIsCreate] = useState<boolean>(reflection.id === -1);

  useEffect(() => {
    setFormData(reflection);
    setIsCreate(reflection.id === -1);
  }, [reflection]);

  const handleSubmit = async () => {
    //Register auth account
    const response: API_Reflection | string = isCreate
      ? await createReflection(formData)
      : await updateReflection(formData);
    if (typeof response === "string") {
      openSnack(response, false);
    } else {
      if (isCreate) {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          reflections: [response, ...visitedProfileDetails.reflections!],
        });
      } else {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          reflections: visitedProfileDetails.reflections?.map(
            (refl: API_Reflection) => {
              return refl.id === reflection.id ? response : refl;
            }
          ),
        });
      }
      openSnack(translation.updateSuccess, true);
      handleClose();
    }
  };

  return (
    <Stack
      component="form"
      sx={{ margin: "auto", my: "16px", alignItems: "center" }}
      spacing={1}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={1} direction="column">
        <TextField
          sx={{ width: 400 }}
          fullWidth
          required
          label={translation.title}
          value={formData.name}
          onChange={(event) => {
            setFormData((prev: API_Reflection) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          required
          label={translation.description}
          value={formData.description}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              description: event.target.value,
            }));
          }}
        />
      </Stack>

      <Button
        buttonText={
          isCreate
            ? translation.submit || "Submit"
            : translation.update || "Update"
        }
        style={{ marginTop: "20px" }}
        onClick={handleSubmit}
      />
    </Stack>
  );
};

export default ReflectionForm;

import WalkthroughStep from "./components/WalkthroughStep";
import { getHorizontalElementDetails } from "./computeProjectPageSteps";
import type { EmptyFn, Step } from "./types";

const addTeacherStepId = "admin-tab-Teacher";

const computeTeacherSteps = (handleNextStep: EmptyFn, handleClose: EmptyFn, isLg: boolean, isSm: boolean): (() => Step)[] => {
  const addTeacherStep = (): Step => {
    const { left, top } = getHorizontalElementDetails(addTeacherStepId, 140);
        
    return {
      width: isLg ? 300 : 350,
      height: 140,
      top,
      left,
      line: "horizontal",
      component: (
        <WalkthroughStep
          handleNext={handleNextStep}
          steps={1} step={1}
          description="Next, add and assign new teachers to class"
        />
      ),
    }
  };

  if (isSm) { 
    return [];
  }

  return [
    addTeacherStep
  ];
};

export default computeTeacherSteps;

import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";

import useSnack from "../../../../../../hooks/useSnack";
import useStore from "../../../../../../store/store";
import Button from "../../../../../../components/Button/Button";
import { translation } from "../../../../../../constants/translation";
import {
  NameField,
  PublishField,
  ImageField,
  DescriptionField,
  DescriptionFieldNormal,
} from "../../TeacherFormComponents/ActivityFormComponents";
import { API_Create_Teacher_Activity } from "../../../../../../types/teacher";
import { RESOURCE_TYPE_PICTORIAL_OBSERVATION } from "../../../teacherPageConstants";
import { createTeacherActivity } from "../../../../../../api/teacher-api";

const CreatePictorialObservationActivityForm = ({
  lessonId,
  handleClose,
}: {
  lessonId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();

  const [isCreatingActivity, setIsCreatingActivity] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [image, setImage] = useState<File | string>("");

  const { currLanguage, teacherCurrentLesson, setIsRefreshTeacherModule } =
    useStore((state) => ({
      currLanguage: state.currLanguage,
      teacherCurrentLesson: state.teacherCurrentLesson,
      setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
    }));
  const [updatedActivity, setUpdatedActivity] =
    useState<API_Create_Teacher_Activity>({
      lesson: lessonId,
      resourcetype: RESOURCE_TYPE_PICTORIAL_OBSERVATION,
      name: "",
      description: "",
      is_published: false,
    });

  const activityRef = useRef(updatedActivity);
  activityRef.current = updatedActivity;

  const submitForm = async () => {
    if (
      updatedActivity.name === "" ||
      updatedActivity.description === "" ||
      image === ""
    ) {
      openSnack("Name and Image are required!", false);
      return;
    }

    const submitData: API_Create_Teacher_Activity = {
      lesson: lessonId,
      resourcetype: updatedActivity.resourcetype,
      name: updatedActivity.name,
      description: updatedActivity.description,
      is_published: updatedActivity.is_published,
    };

    if (image === "" || typeof image === "object") {
      submitData.image = image;
    }

    const res = await createTeacherActivity(
      currLanguage,
      submitData,
      setIsCreatingActivity,
      setProgressPercentage,
      localStorage.getItem("access")!
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setIsRefreshTeacherModule(true);
      openSnack("Activity created successfully!", true);
      handleClose();
    }
  };

  return (
    <Stack
      component="form"
      sx={{
        width: "100%",
        mb: 8,
      }}
      spacing={3}
      noValidate
      autoComplete="off"
    >
      <PublishField
        activity={updatedActivity}
        setActivity={setUpdatedActivity}
      />
      <ImageField isRequired image={image} setImage={setImage} />
      <NameField activity={updatedActivity} setActivity={setUpdatedActivity} />
      <DescriptionFieldNormal
        label={translation.pictorialDescription}
        activity={updatedActivity}
        setActivity={setUpdatedActivity}
      />

      <Box
        sx={{
          display: "flex",
          gap: "24px",
          position: "fixed",
          bottom: 24,
          right: 34,
          zIndex: 10,
        }}
      >
        <Button
          buttonText={
            isCreatingActivity
              ? progressPercentage !== 100
                ? `${progressPercentage}%`
                : translation.uploadFinalizing
              : translation.create
          }
          style={{
            fontSize: "14px",
            backgroundColor: "var(--txt-secondary)",
            height: "auto",
          }}
          onClick={() => submitForm()}
        />
      </Box>
    </Stack>
  );
};

export default CreatePictorialObservationActivityForm;

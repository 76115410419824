import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import React, { Fragment, useEffect, useState } from "react";
import { getGroupList } from "api/profile-api";
import { API_Group_Full } from "types/group";
import { Outlet } from "react-router-dom";

import GroupDetailCard from "components/DetailCard/GroupDetailCard";

import { translation } from "constants/translation";

import useStore from "store/store";

import Button from "components/Button/Button";
import GroupPageModal from "./GroupPageModal";
import { isProfileInCreateGroupRoleList } from "helper/helper";
import { currGroupInitialState } from "./constants";
import { getAllSchool } from "api/school-api";
import ClassListMiniDrawer from "containers/ChildrenPortfolioPage/ClassListMiniDrawer";
const GroupsPage = () => {
  const navigate = useNavigate();
  const {
    profileDetails,
    currLanguage,
    setCurrGroup,
    groupList,
    setGroupList,
    setSchools,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
    setCurrGroup: state.setCurrGroup,
    groupList: state.groupList,
    setGroupList: state.setGroupList,
    setSchools: state.setSchools,
  }));

  //Modal
  const [currModalGroup, setCurrModalGroup] = useState<API_Group_Full>(
    currGroupInitialState
  );
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = (currModalGroupInfo: API_Group_Full) => {
    setCurrModalGroup(currModalGroupInfo);
    setOpenModal(true);
  };

  useEffect(() => {
    const populateSchools = async () => {
      setSchools(await getAllSchool());
    };
    const populateData = async () => {
      const res = await getGroupList(currLanguage);
      setGroupList(res);
    };
    populateData();
    populateSchools();
  }, []);
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <ClassListMiniDrawer />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default GroupsPage;

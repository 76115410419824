import Image from "components/Image/Image";
import IntroductionPageBackground from "images/children-portfolio-v2/introduction.png";
import Page from "./Page";

const IntroPage = () => {
  return (
    <Page>
      <Image src={IntroductionPageBackground} alt="" />
    </Page>
  );
};

export default IntroPage;

import { API_Chat } from "../types/chat";
import { API_Profile_Details } from "../types/profile";
import { StoreSlice } from "./store";

export interface SocketSlice {
  allChats: API_Chat[];
  setAllChats: (allChats: API_Chat[]) => void;
  allContacts: API_Profile_Details[];
  setAllContacts: (allContacts: API_Profile_Details[]) => void;
  chatSockets: { [id: string]: WebSocket };
  setChatSockets: (chatSockets: { [id: string]: WebSocket }) => void;
  isLoadingChat: boolean;
  setIsLoadingChat: (allChats: boolean) => void;
  unseenChatCount: number;
  setUnseenChatCount: (unseenChatCount: number) => void;
}

export const createSocketSlice: StoreSlice<SocketSlice> = (set, get) => ({
  allChats: [],
  setAllChats: (allChats: API_Chat[]) => {
    set((prev: SocketSlice) => ({
      ...prev,
      allChats: allChats,
    }));
  },
  allContacts: [],
  setAllContacts: (allContacts: API_Profile_Details[]) => {
    set((prev: SocketSlice) => ({
      ...prev,
      allContacts: allContacts,
    }));
  },
  chatSockets: {},
  setChatSockets: (chatSockets: { [id: string]: WebSocket }) => {
    set((prev: SocketSlice) => ({
      ...prev,
      chatSockets: chatSockets,
    }));
  },
  isLoadingChat: true,
  setIsLoadingChat: (isLoadingChat: boolean) => {
    set((prev: SocketSlice) => ({
      ...prev,
      isLoadingChat: isLoadingChat,
    }));
  },
  unseenChatCount: 0,
  setUnseenChatCount: (unseenChatCount: number) => {
    set((prev: SocketSlice) => ({
      ...prev,
      unseenChatCount: unseenChatCount,
    }));
  },
});

import { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { translation } from "constants/translation";
import { getProjectAlbum } from "api/gallery-api";
import {
  Album,
  ChildPhotoProjectCard,
} from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Logo from "images/logo-2.png";

import Slider from "react-slick";
import "./NowLearningComponents/childrenPhotoSection.css";
import { setResponsiveness } from "./NowLearningSection";
import AlbumPreviewModal from "./ChildrenPhotoComponents/AlbumPreviewModal";
import Image from "components/Image/Image";
import UnderlineHighlight from "images/dashboard/underline-highlight.png";

const ChildrenPhotoSection = ({ childId }: { childId: number }) => {
  const [albums, setAlbums] = useState<Album[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedAlbum, setSelectedAlbum] = useState<Album>();

  useEffect(() => {
    if (childId === -1) return;

    const initializeChildImages = async () => {
      setIsLoading(true);

      const res = await getProjectAlbum(
        childId,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        setAlbums(res);
      }

      setIsLoading(false);
    };

    initializeChildImages();
  }, [childId]);

  const handleOpen = (album: Album) => {
    setOpen(true);
    setSelectedAlbum(album);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAlbum(undefined);
  };

  const [justifyContent, setJustifyContent] = useState("center");

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setJustifyContentFunction = () => {
      if (containerRef.current) {
        // Check if there's overflow
        const isOverflowing =
          containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setJustifyContent(isOverflowing ? "flex-start" : "center");
      }
    };
    window.addEventListener("resize", () => setJustifyContentFunction());

    return () => {
      window.removeEventListener("resize", () => setJustifyContentFunction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // re-run effect when 'projects' changes

  // slick
  const [slidesToShow, setSlidesToShow] = useState(6);

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    infinite: true,
    // speed: 2000,
    autoplaySpeed: 5000,
    className: "center",
    centerMode: true,
    cssEase: "linear",
    variableWidth: true,
    // variableHeight: true,
  };

  useEffect(() => {
    setResponsiveness(setSlidesToShow);
    window.addEventListener("resize", () => setResponsiveness(setSlidesToShow));

    return () => {
      window.removeEventListener("resize", () =>
        setResponsiveness(setSlidesToShow)
      );
    };
  }, []);
  // console.log("selectedAlbum: ", selectedAlbum);
  // console.log("slidesToShow: ", slidesToShow);
  return (
    <Box
      sx={{
        // backgroundColor: "white",
        // border: "1px solid #C4C4C4",
        borderRadius: "10px",
        // p: 1,
        px: 5,
      }}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: -2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 24,
              }}
            >
              {translation.littleAlbum}
            </Typography>
            <Image
              src={UnderlineHighlight}
              alt={"underline-highlight"}
              style={{ width: 175, marginTop: "-4px" }}
            />
          </Box>

          <Box sx={{ px: 2 }}>
            {albums.length === 0 ? (
              <Typography>{translation.noPhoto}</Typography>
            ) : albums.length <= slidesToShow ? (
              <Box
                ref={containerRef}
                sx={{
                  display: "flex",
                  p: 1,
                  gap: 7,
                  justifyContent,
                  overflowX: "auto",
                }}
              >
                {albums.map((album, index) => (
                  <ChildPhotoProjectCard
                    sx={{ width: { xs: 180, sm: 230 } }}
                    key={index}
                    image={album}
                    onClick={() => {
                      handleOpen(album);
                    }}
                  />
                ))}
              </Box>
            ) : (
              <Slider {...settings} className="childrenPhotoSectionSlider">
                {albums.map((album, index) => (
                  <ChildPhotoProjectCard
                    sx={{ width: { xs: 180, sm: 230 } }}
                    key={index}
                    image={album}
                    onClick={() => {
                      handleOpen(album);
                    }}
                  />
                ))}
              </Slider>
            )}
          </Box>

          {open && selectedAlbum && (
            <AlbumPreviewModal
              open={open}
              childId={childId}
              projectId={selectedAlbum.project.id}
              projectName={selectedAlbum.project.name}
              projectImageSrc={selectedAlbum.project.image || Logo}
              moduleName={selectedAlbum.project.module.name}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ChildrenPhotoSection;

import React, { useEffect, useState } from "react";
import { Box, Switch, Divider, Typography } from "@mui/material";
import EmailPreferenceIcon from "images/email_preferences_icon.svg";
import { translation } from "../../constants/translation";
import useStore from "store/store";
import { toggleEmailNotifications } from "../../api/profile-api";
import useSnack from "hooks/useSnack";

// Define the toggle options array
const optionsData = [
  {
    label: translation.announcements || "Announcements",
    toggleAnnouncements: async (
      profileId: number,
      accessToken: string | null,
      emailNotifications: boolean
    ) => {
      await toggleEmailNotifications(profileId, accessToken, emailNotifications);
    },
  },
  // Add more options as needed
];

const EmailPreferencesPage: React.FC = () => {
  const [options, setOptions] = useState<{ [key: string]: boolean }>({});
  const openSnack = useSnack();
  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));

  useEffect(() => {
    const initialOptionsState = optionsData.reduce((acc, option) => ({ ...acc, [option.label]: false }), {});
    setOptions(initialOptionsState);
  }, []);

  const handleToggleChange = (optionLabel: string) => {
    const updatedOptions = { ...options, [optionLabel]: !options[optionLabel] };
    setOptions(updatedOptions);
    const option = optionsData.find((opt) => opt.label === optionLabel);
    if (option) {
      option
        .toggleAnnouncements(profileDetails.id, localStorage.getItem("access"), updatedOptions[optionLabel])
        .then(() => {
          openSnack(`Announcements notifications changes have been saved!`, true);
        })
        .catch((error) => {
          console.error("Error toggling announcements:", error);
          openSnack(`Announcements notifications changes could not be saved!`, false);
          setOptions((prevOptions) => ({
            ...prevOptions,
            [optionLabel]: !prevOptions[optionLabel],
          }));
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          marginTop: "24px",
          width: { xs: "450px", sm: "948px" },
          gap: "24px",
          height: "140px",
          padding: "16px 60px",
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
          borderRadius: "8px",
          background: "var(--Cyan-100, #EBF8F8)",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: "var(--Cyan-700, #5C9391)",
              textAlign: "left",
              fontFamily: "Futura PT",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            {translation.stayInformed}
          </Typography>
          <Typography
            sx={{
              color: "var(--Cyan-700, #5C9391)",
              textAlign: "left",
              fontFamily: "Futura PT",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            {translation.toggleOnOrOffEmails}
          </Typography>
        </Box>
        <Box>
          {" "}
          <img src={EmailPreferenceIcon} alt="icon" />
        </Box>
      </Box>
      <Box
        sx={{
          width: "60%",
          marginTop: "50px",
        }}
      >
        {optionsData.map((option) => (
          <React.Fragment key={option.label}>
            <Divider sx={{ margin: "8px 0" }} />
            <OptionToggle
              label={option.label}
              checked={options[option.label]}
              onChange={() => handleToggleChange(option.label)}
            />
            <Divider sx={{ margin: "8px 0" }} />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

interface OptionToggleProps {
  label: string;
  checked: boolean;
  onChange: () => void;
}

// Component for individual option toggle
const OptionToggle: React.FC<OptionToggleProps> = ({ label, checked, onChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography>{label}</Typography>
      <Switch
        color="primary"
        checked={checked}
        onChange={onChange}
        sx={{ "& .MuiSwitch-thumb": { backgroundColor: "#91C6C5" } }}
      />
    </Box>
  );
};

export default EmailPreferencesPage;

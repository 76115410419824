import { StoreSlice } from "./store";

import {
  API_Edit_Activity,
  API_Edit_Lesson,
  API_Edit_Project,
  API_Project,
  API_Project_Lesson,
  API_Project_Lesson_Activity,
  API_Project_Lessons,
  API_Project_Review,
  Age_Group,
  ObjChecked,
} from "../types/project";

import { API_Child, API_Enrollment_Status } from "../types/profile";
import { SPROUTS } from "../constants/project-lesson-activity";

export interface ProjectSlice {
  objChecked: ObjChecked[]; //objChecked = whether the child has attached to the checkpoint id
  childrenInSession: API_Child[];
  currentProjectLessonActivityTab: string;
  currentProject: API_Project;
  currentProjectReviewList: API_Project_Review[];
  currentLessons: API_Project_Lessons;
  currLesson: API_Project_Lesson;
  currActivity: API_Project_Lesson_Activity;
  littleLearners: boolean;
  enrollmentStatus?: API_Enrollment_Status;
  refreshEnrollment: boolean;
  refreshProjAndLess: boolean;
  isRefreshReviewList: boolean;
  bringOverProject: API_Edit_Project;
  bringOverLesson: API_Edit_Lesson;
  bringOverActivity: API_Edit_Activity;
  isProjectLoading: boolean;
  isRefreshLearningMomentPhoto: boolean;
  setObjChecked: (objChecked: ObjChecked[]) => void;
  setChildrenInSession: (childrenInSession: API_Child[]) => void;
  setCurrentProjectLessonActivityTab: (
    currentProjectLessonActivityTab: string
  ) => void;
  setCurrentProject: (project: API_Project) => void;
  setCurrentProjectReviewList: (
    currentProjectReviewList: API_Project_Review[]
  ) => void;
  setCurrentLessons: (currentLessons: API_Project_Lessons) => void;
  setCurrLesson: (currLesson: API_Project_Lesson) => void;
  setCurrActivity: (currActivity: API_Project_Lesson_Activity) => void;
  setLittleLearners: (littleLearners: boolean) => void;
  setEnrollmentStatus: (enrollmentStatus: API_Enrollment_Status) => void;
  setRefreshEnrollment: (refreshEnrollment: boolean) => void;
  setRefreshProjAndLess: (refreshProjAndLess: boolean) => void;
  setIsRefreshReviewList: (isRefreshReviewList: boolean) => void;
  setBringOverProject: (bringOverProject: API_Edit_Project) => void;
  setBringOverLesson: (bringOverLesson: API_Edit_Lesson) => void;
  setBringOverActivity: (bringOverActivity: API_Edit_Activity) => void;
  setIsProjectLoading: (isProjectLoading: boolean) => void;
  setIsRefreshLearningMomentPhoto: (
    isRefreshLearningMomentPhoto: boolean
  ) => void;
}

export const initialProject = {
  id: -1,
  reviews: [],
  images: [],
  fundamentals: [],
  checkpoint_fundamentals: [],
  average_rating: -1,
  name: "",
  slug: "",
  description: "",
  objective: "",
  objective_fundamentals: [],
  guiding_question: "",
  unit_guides: [],
  age: SPROUTS as Age_Group,
  created_by: "",
  updated_by: "",
  is_published: false,
  duration: -1,
  tags: "",
  assessment: false,
  module: {
    id: -1,
    name: "",
    image: "",
    category: "",
  },
};

export const initialCurrLesson = {
  id: -1,
  checkpoint_fundamentals: [],
  checkpoints: [],
  images: [],
  objective: "",
  objective_fundamentals: [],
  activities: [],
  created_at: "",
  updated_at: "",
  name: "",
  description: "",
  duration: 0,
  material: "",
  instruction: "",
  intention: "",
  vocabulary: "",
  resources: "",
  weight: 0,
  created_by: "",
  updated_by: "",
  project: -1,
  is_required: false,
  module_category: "",
  module: "",
  module_icon: "",
  project_id: "",
  project_name: "",
  project_slug: "",
  image: "",
  total_lessons: -1,
};

export const initialActivity = {
  id: -1,
  created_at: "",
  updated_at: "",
  name: "",
  duration: 0,
  description: "",
  instruction: "",
  instructions: [],
  material: "",
  image: "",
  remark: "",
  weight: 0,
  created_by: "",
  updated_by: "",
  lesson: -1,
  is_compulsory: true,
  files: [],
  captions: [],
  assessment: false,
};

export const createProjectSlice: StoreSlice<ProjectSlice> = (set, get) => ({
  objChecked: [],
  childrenInSession: [],
  currentProjectLessonActivityTab: "",
  currentProject: initialProject,
  currentProjectReviewList: [],
  currentLessons: { id: -1, name: "", lessons: [] },
  currLesson: initialCurrLesson,
  currActivity: initialActivity,
  littleLearners: false,
  refreshEnrollment: true,
  refreshProjAndLess: true,
  isRefreshReviewList: true,
  bringOverProject: {},
  bringOverLesson: {},
  bringOverActivity: {},
  isProjectLoading: false,
  isRefreshLearningMomentPhoto: false,
  setObjChecked: (objChecked: ObjChecked[]) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      objChecked: objChecked,
    }));
  },
  setCurrentProjectLessonActivityTab: (
    currentProjectLessonActivityTab: string
  ) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      currentProjectLessonActivityTab: currentProjectLessonActivityTab,
    }));
  },
  setCurrentProject: (currentProject: API_Project) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      currentProject: currentProject,
    }));
  },
  setCurrentProjectReviewList: (
    currentProjectReviewList: API_Project_Review[]
  ) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      currentProjectReviewList: currentProjectReviewList,
    }));
  },
  setCurrentLessons: (currentLessons: API_Project_Lessons) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      currentLessons: currentLessons,
    }));
  },
  setCurrLesson: (currLesson: API_Project_Lesson) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      currLesson: currLesson,
    }));
  },
  setCurrActivity: (currActivity: API_Project_Lesson_Activity) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      currActivity: currActivity,
    }));
  },
  setLittleLearners: (littleLearners: boolean) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      littleLearners: littleLearners,
    }));
  },
  setChildrenInSession: (childrenInSession: API_Child[]) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      childrenInSession: childrenInSession,
    }));
  },
  setEnrollmentStatus: (enrollmentStatus: API_Enrollment_Status) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      enrollmentStatus: enrollmentStatus,
    }));
  },
  setRefreshEnrollment: (refreshEnrollment: boolean) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      refreshEnrollment: refreshEnrollment,
    }));
  },
  setRefreshProjAndLess: (refreshProjAndLess: boolean) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      refreshProjAndLess: refreshProjAndLess,
    }));
  },
  setIsRefreshReviewList: (isRefreshReviewList: boolean) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      isRefreshReviewList: isRefreshReviewList,
    }));
  },
  setBringOverProject: (bringOverProject: API_Edit_Project) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      bringOverProject: bringOverProject,
    }));
  },
  setBringOverLesson: (bringOverLesson: API_Edit_Lesson) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      bringOverLesson: bringOverLesson,
    }));
  },
  setBringOverActivity: (bringOverActivity: API_Edit_Activity) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      bringOverActivity: bringOverActivity,
    }));
  },
  setIsProjectLoading: (isProjectLoading: boolean) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      isProjectLoading: isProjectLoading,
    }));
  },
  setIsRefreshLearningMomentPhoto: (isRefreshLearningMomentPhoto: boolean) => {
    set((prev: ProjectSlice) => ({
      ...prev,
      isRefreshLearningMomentPhoto: isRefreshLearningMomentPhoto,
    }));
  },
});

import { translation } from "constants/translation";
import {
  API_Experience,
  API_Reflection,
  API_Testimonial,
  API_Certification,
} from "types/profile";

export const PROFILE_SECTION_ABOUT = "About";
export const PROFILE_SECTION_EXPERIENCE = "Experience";
export const PROFILE_SECTION_REFLECTIONS = "Reflections";
export const PROFILE_SECTION_CERTIFICATIONS = "Certifications";
export const PROFILE_SECTION_TESTIMONIALS = "Testimonials";
export const PROFILE_SECTION_PROJECTS = "Past Projects";

export const tabs: string[] = [
  PROFILE_SECTION_ABOUT,
  PROFILE_SECTION_EXPERIENCE,
  PROFILE_SECTION_REFLECTIONS,
  PROFILE_SECTION_CERTIFICATIONS,
  PROFILE_SECTION_TESTIMONIALS,
  PROFILE_SECTION_PROJECTS,
];

export const translatedTabs: { [key: string]: string } = {
  [PROFILE_SECTION_ABOUT]: translation.profileSectionAbout,
  [PROFILE_SECTION_EXPERIENCE]: translation.profileSectionExperience,
  [PROFILE_SECTION_REFLECTIONS]: translation.profileSectionReflections,
  [PROFILE_SECTION_CERTIFICATIONS]: translation.profileSectionCertifications,
  [PROFILE_SECTION_TESTIMONIALS]: translation.profileSectionTestimonials,
  [PROFILE_SECTION_PROJECTS]: translation.profileSectionProjects,
};

export const GENDER_LIST: { [key: string]: string } = {
  M: translation.male,
  F: translation.female,
};

export const EDUCATION_LIST: { [key: string]: string } = {
  NR: translation.noFormalRecordYet,
  HS: translation.highSchool,
  AD: translation.associateDegree,
  BD: translation.bachelorsDegree,
  MD: translation.mastersDegree,
  DD: translation.doctorateDegree,
};

export const dataInitialExperience: (profileId: string) => API_Experience = (
  profileId: string
) => {
  return {
    id: -1,
    name: "",
    description: "",
    start_at: "",
    end_at: undefined,
    profile: Number(profileId),
  };
};

export const dataInitialReflection: (profileId: string) => API_Reflection = (
  profileId: string
) => {
  return {
    id: -1,
    is_published: true,
    created_at: "",
    name: "",
    description: "",
    profile: Number(profileId),
    endorsements: [],
  };
};

export const dataInitialCertification: (
  profileId: string
) => API_Certification = (profileId: string) => {
  return {
    id: -1,
    name: "",
    description: "",
    issuing_organization: "",
    issue_date: null,
    expiration_date: null,
    credential_id: "",
    credential_url: "",
    certificate: null,
    profile: {
      id: Number(profileId),
      dob: "",
      full_name: "",
      image: "",
      preferred_name: "",
    },
    created_at: "",
  };
};

export const dataInitialTestimonial: (
  profileId: string,
  authorId: number
) => API_Testimonial = (profileId: string, authorId: number) => {
  return {
    id: -1,
    is_published: true,
    created_at: "",
    name: "",
    description: "",
    profile: Number(profileId),
    author: {
      id: authorId,
      image: "",
      preferred_name: "",
      full_name: "",
      dob: "",
    },
    endorsements: [],
  };
};

import React, { useMemo, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { API_Profile_Short } from "types/profile";
import { translation } from "constants/translation";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, ListSubheader, TextField } from "@mui/material";

const ProfileSelectionList = ({
  profileList,
  selectedProfile,
  setSelectedProfile,
}: {
  profileList: API_Profile_Short[];
  selectedProfile: API_Profile_Short;
  setSelectedProfile: React.Dispatch<React.SetStateAction<API_Profile_Short>>;
}) => {
  const isContainsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const [searchText, setSearchText] = useState<string>("");
  const filteredProfileList = useMemo(
    () =>
      profileList.filter((profile) =>
        isContainsText(profile.preferred_name, searchText)
      ),
    [profileList.length, searchText]
  );

  const handleChange = (event: SelectChangeEvent) => {
    const index = profileList.findIndex(
      (profile) => profile.id === Number(event.target.value)
    );
    setSelectedProfile(profileList[index]);
  };

  return profileList.length > 0 ? (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>
      <InputLabel id="demo-simple-select-standard-label">
        {translation.profile}
      </InputLabel>
      <Select
        MenuProps={{ autoFocus: false }}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={selectedProfile.id.toString()}
        onChange={handleChange}
        onClose={() => setSearchText("")}
        label="Profile"
      >
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {filteredProfileList.map((profile) => {
          return (
            <MenuItem sx={{ pl: 2 }} value={profile.id.toString()}>
              {profile.preferred_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : null;
};

export default ProfileSelectionList;

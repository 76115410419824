import CloseIcon from "@mui/icons-material/Close";

import {
  API_Certification,
  API_Experience,
  API_Reflection,
  API_Testimonial,
} from "types/profile";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import ExperienceForm from "./Forms/ExperienceForm";
import ReflectionForm from "./Forms/ReflectionForm";
import {
  PROFILE_SECTION_CERTIFICATIONS,
  PROFILE_SECTION_EXPERIENCE,
  PROFILE_SECTION_REFLECTIONS,
  PROFILE_SECTION_TESTIMONIALS,
} from "./constants";
import { slugify } from "helper/slugify";
import TestimonialForm from "./Forms/TestimonialForm";
import CertificationForm from "./Forms/CertificationForm";

const ModalForm = ({
  open,
  handleClose,
  object,
  type,
}: {
  open: boolean;
  handleClose: () => void;
  object: API_Experience | API_Reflection | API_Certification | API_Testimonial;
  type: string;
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            paddingY: 0.5,
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          {type === slugify(PROFILE_SECTION_EXPERIENCE) && (
            <ExperienceForm
              experience={object as API_Experience}
              handleClose={handleClose}
            />
          )}

          {type === slugify(PROFILE_SECTION_REFLECTIONS) && (
            <ReflectionForm
              reflection={object as API_Reflection}
              handleClose={handleClose}
            />
          )}

          {type === slugify(PROFILE_SECTION_CERTIFICATIONS) && (
            <CertificationForm
              certification={object as API_Certification}
              handleClose={handleClose}
            />
          )}

          {type === slugify(PROFILE_SECTION_TESTIMONIALS) && (
            <TestimonialForm
              testimonial={object as API_Testimonial}
              handleClose={handleClose}
            />
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalForm;

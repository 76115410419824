

import useStore from "store/store";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  Backdrop,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BrowseProjectsButton } from "components/Button/Button";

import { CURLANG, MARKETPLACE } from "constants/url";
import BackpackToggleButton from "./BackpackSideMenuComponents/BackpackToggleButton";
import BackpackTitle from "./BackpackSideMenuComponents/BackpackTitle";
import BackpackContentMobile from "./BackpackSideMenuComponents/BackpackContentMobile";

const BackpackSideMenuMobile = ({
  setProjectCard,
  handleOpen,
  setIsUpdate,
  open,
  setOpen,
}: {
  setProjectCard: any;
  handleOpen: any;
  setIsUpdate: any;
  open: any;
  setOpen: any;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { currGroup, currGroupBackpack, groupCalendarList } = useStore(
    (state) => ({
      currGroup: state.currGroup,
      currGroupBackpack: state.currGroupBackpack,
      groupCalendarList: state.groupCalendarList,
    })
  );

  return (
    <>
      <Backdrop
        open={open}
        onClick={() => setOpen(false)}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />
      <BackpackToggleButton open={open} setOpen={setOpen} />
      <Box
        sx={{
          height: open ? "55vh" : "0px",
          width: "100%",
          backgroundColor: "primary.light",

          transform: open ? "translateX(0%)" : "translateX(-100%)",
          transition: "all 200ms ease-in-out",
          position: "absolute",
          bottom: 0,
          // bottom: `${BACKPACK_BOTTOM_PERCENT}%`,
          overflowX: "auto",
          display: "flex",
          flexDirection: "column",

          scrollbarWidth: "none", // for firefox browsers
          "&::-webkit-scrollbar": {
            display: "none", // for chromuim browsers
          },
          zIndex: (theme) => theme.zIndex.drawer + 2, // higher zIndex than the Backdrop
        }}
      >
        <BackpackTitle />

        <Box
          sx={{
            mt: 1,
            overflowY: "auto",
            display: "flex",
            justifyContent: "center",
          }}
          key={Math.random()}
        >
          {currGroupBackpack.projects.length > 0 ? (
            <BackpackContentMobile
              projects={currGroupBackpack.projects}
              groupId={currGroup.id}
              setProjectCard={setProjectCard}
              handleOpen={handleOpen}
              setIsUpdate={setIsUpdate}
            />
          ) : (
            <EmptyBackpack groupName={currGroup?.name} />
          )}
        </Box>
      </Box>
    </>
  );
};

const EmptyBackpack = ({ groupName }: { groupName: string }) => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        width: "100%",
        // height: `${BACKPACK_HEIGHT - (BACKPACK_HEIGHT / 100) * 30}px`,
        // border: CALENDAR_BORDER_STYLE,
        borderRadius: "10px",
        textAlign: "center",
      }}
    >
      <Typography>
        You will need to add some projects into {groupName}'s backpack before
        adding it to the calendar
      </Typography>
      <BrowseProjectsButton
        style={{ margin: "auto" }}
        onClick={() => navigate(`${CURLANG(currLanguage)}/${MARKETPLACE}`)}
      />
    </Box>
  );
};
export default BackpackSideMenuMobile;

import { useState, MouseEvent, useEffect } from "react";
import {
  Box,
  Typography,
  Popover,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  translatedTabs,
  tabs,
  PROJECT_SUMMARY,
  PROGRESS_REPORT,
} from "./constants";
import { translation } from "constants/translation";
import { slugify } from "helper/slugify";
import {
  CHILDREN_PORTFOLIO_PAGE,
  CURLANG,
  DEVELOPMENTAL_GOALS,
  LEARNING_STORIES,
  MY_CHILDREN_PORTFOLIO,
  PROFILE,
  CLASS,
} from "../../constants/url";
import useStore from "../../store/store";
import Image from "../../components/Image/Image";

import Logo from "../../images/logo.png";
import devGoalsIcon from "../../images/children-portfolio/sidebar/developmental-goals.png";
import progressReportIcon from "../../images/children-portfolio/sidebar/progress-report.png";
import completedWorksIcon from "../../images/children-portfolio/sidebar/completed-works.png";
import photoAlbumIcon from "../../images/children-portfolio/sidebar/photo-album.png";
import projectSummaryIcon from "../../images/children-portfolio/sidebar/project-summary.png";
import { ChildContainer } from "../WelcomeBackPage/components/ParentDashboardComponents/MyChildrenSection";
import { COMPLETED_WORKS } from "../ProjectSummaryPage/constant";
import { BackButton } from "components/Button/BackButton";

const ICONS = [
  Logo,
  devGoalsIcon,
  progressReportIcon,
  completedWorksIcon,
  photoAlbumIcon,
  photoAlbumIcon,
  projectSummaryIcon,
];

const ChildPortfolioMiniDrawer = ({
  childName,
  currentTab,
  isParentView = false,
}: {
  childName: string;
  currentTab: string;
  isParentView?: boolean;
}) => {
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const { childID } = useParams();
  const { open, setOpen, childrenInSession, currLanguage, currGroup } = useStore(
    (state) => ({
      open: state.isPortfolioMiniDrawerOpen,
      setOpen: state.setIsPortfolioMiniDrawerOpen,
      childrenInSession: state.childrenInSession,
      currLanguage: state.currLanguage,
      currGroup: state.currGroup,
    })
  );
  const [name, setName] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen(!open);

  const handleProfileClick = (childId: number) => {
    const path = location.pathname;
    let navigateTo;
    if (path.includes(DEVELOPMENTAL_GOALS)) {
      navigateTo = `${CURLANG(
        currLanguage
      )}/${MY_CHILDREN_PORTFOLIO}/${childId}/${DEVELOPMENTAL_GOALS}`;
    } else if (path.includes(PROGRESS_REPORT)) {
      navigateTo = `${CURLANG(
        currLanguage
      )}/${MY_CHILDREN_PORTFOLIO}/${childId}/${PROGRESS_REPORT}`;
    } else if (path.includes(PROJECT_SUMMARY)) {
      navigateTo = `${CURLANG(
        currLanguage
      )}/${MY_CHILDREN_PORTFOLIO}/${childId}/${PROJECT_SUMMARY}`;
    } else if (path.includes(COMPLETED_WORKS)) {
      navigateTo = `${CURLANG(
        currLanguage
      )}/${MY_CHILDREN_PORTFOLIO}/${childId}/${COMPLETED_WORKS}`;
    } else if (path.includes(LEARNING_STORIES)) {
      navigateTo = `${CURLANG(
        currLanguage
      )}/${MY_CHILDREN_PORTFOLIO}/${childId}/${LEARNING_STORIES}`;
    } else {
      navigateTo = `${CURLANG(
        currLanguage
      )}/${MY_CHILDREN_PORTFOLIO}/${childId}/${PROFILE}`;
    }
    navigate(navigateTo);
  };

  useEffect(() => {
    if (isBelowSm) {
      setOpen(false);
    }
  }, [isBelowSm]);

  return (
    // <Drawer
    //   disablePortal={true}
    //   anchor="left"
    //   open={true}
    //   onClose={handleDrawer}
    //   sx={{
    //     "& .MuiDrawer-paper": {
    //       minWidth: 200,
    //       backgroundColor: "#F8F8F8",
    //       p: 3,
    //       // mt: isFullscreen ? "0px" : "64px",
    //       // marginRight: isFullscreen ? "0px" : "10px",
    //     },
    //   }}
    // >
    <Box
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        maxWidth: "300px",
        flex: open ? "1 0 auto" : "0 0 auto",
        p: 2,
        pt: "21px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
        alignSelf: "flex-start",
        position: "sticky",
        top: "64px",
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        {open && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <BackButton btnText={"Back to class list"} onClick={() => {
              navigate(`${CURLANG(currLanguage)}/children-portfolio/${currGroup.slug}`)
            }} />
           
          </Box>
        )}

        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            m: 0.5,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
            },
          }}
          onClick={handleDrawer}
        >
          {open ? (
            <CloseIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </Box>
      
      </Box>
      {
          open &&  <Typography
              sx={{
                fontWeight: "bold",
                lineHeight: 1,
                mr: "auto",
                my: 3, 
                ml: 1
              }}
            >
              {isParentView
                ? // ? childName
                  "Viewing For"
                : translation.formatString(translation.childPortfolio, {
                    name: childName,
                  })}
            </Typography>
        }
      {isParentView && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              overflowX: "auto",
              gap: 2,
              mb: 1,
              maxWidth: 300,
            }}
          >
            {!open ? (
              <ChildContainer
                child={
                  childrenInSession.filter(
                    (child) => child.id === Number(childID)
                  )[0]
                }
                selectedChildId={Number(childID)}
                onClick={() => {}}
                width={30}
                height={30}
              />
            ) : (
              childrenInSession.map((child, index) => (
                <ChildContainer
                  key={index}
                  child={child}
                  selectedChildId={Number(childID)}
                  onClick={() => {
                    handleProfileClick(child.id);
                  }}
                  width={30}
                  height={30}
                />
              ))
            )}
          </Box>
        </>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {tabs.map(
          (tab, index) => (
            // isParentView && tab === PROJECT_SUMMARY ? null : (
            <DrawerButton
              key={index}
              tab={tab}
              currentTab={currentTab}
              icon={ICONS[index]}
              open={open}
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
              isParentView={isParentView}
            />
          )
          // )
        )}
      </Box>

      {!open && name !== "" && (
        <Popover
          sx={{
            pointerEvents: "none",
            borderRadius: "10px",
            ml: 1,
          }}
          elevation={2}
          open={openPopOver}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              color: "#444",
              p: 1.5,
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
        </Popover>
      )}
    </Box>
    // </Drawer>
  );
};

// const ClosedDrawer = () => {
//   return (

//   )
// }

const DrawerButton = ({
  tab,
  currentTab,
  icon,
  open,
  handlePopoverOpen,
  handlePopoverClose,
  isParentView = false,
}: {
  tab: string;
  currentTab: string;
  icon: string;
  open: boolean;
  handlePopoverOpen: (event: MouseEvent<HTMLElement>, name: string) => void;
  handlePopoverClose: () => void;
  isParentView?: boolean;
}) => {
  const { groupSlug, childID } = useParams();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const isActiveTab = tab.toLowerCase() === currentTab.toLowerCase();

  return (
    <Link
      to={`${CURLANG(currLanguage)}/${
        isParentView
          ? `${MY_CHILDREN_PORTFOLIO}/${childID}`
          : CHILDREN_PORTFOLIO_PAGE(groupSlug!, childID!)
      }/${slugify(tab)}`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "8px",
        cursor: isActiveTab ? "default" : "pointer",
        textDecoration: "none",
        color: "#444",
      }}
      onMouseEnter={(e) => handlePopoverOpen(e, translatedTabs[tab])}
      onMouseLeave={() => handlePopoverClose()}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          p: 0.5,
          width: "40px",
          height: "40px",
          borderRadius: "999px",
          border: "1px solid",
          borderColor: isActiveTab ? "#98C4C5" : "#F0F0F0",
          backgroundColor: isActiveTab ? "primary.light" : "var(--txt-light)",
        }}
      >
        <Image
          src={icon}
          alt={slugify(tab)}
          style={{
            backgroundColor: "white",
            borderRadius: "999px",
            padding: "2px",
          }}
          objectFit="contain"
        />
      </Box>

      {open && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: isActiveTab ? "bold" : "normal",
            color: isActiveTab ? "primary.main" : "#444",
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {translatedTabs[tab]}
        </Typography>
      )}
    </Link>
  );
};

export default ChildPortfolioMiniDrawer;

import React from "react";
import { useNavigate } from "react-router-dom";

import useStore from "store/store";

import styles from "./Navbar.module.css";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";

import LogoImage from "images/logo.png";
import LoginButtonImage from "images/login-button.png";
import Image from "components/Image/Image";

import { TITLE } from "constants/title";

import {
  CURLANG,
  HOME,
  HELP,
  LOGIN,
  ACCOUNT,
  SETTINGS,
  ADMIN_PAGE,
  TICKETING_SYSTEM,
  CREATE_TICKET_PAGE,
  CREATE_PROJECT_PAGE,
  PAYMENT,
  PROJECT_SCHOOL_MANAGE_PAGE,
  EDIT_DEVELOPMENTAL_GOAL,
  MANAGE_PROHIBITED_WORD,
  SCHOOL_PROFILE,
  PROFILE_PAGE,
  CHILD,
  CREATE_GROUP_PAGE,
} from "constants/url";
import { logoutAccount } from "api/auth-api";
import { Divider } from "@mui/material";
import { translation } from "constants/translation";
import { currGroupInitialState } from "containers/GroupPage/constants";
import { isProfileInFundamentalEditableRoleList } from "helper/helper";
import useSnack from "hooks/useSnack";
import { initialProfileDetails } from "store/auth-slice";

const LoginButton = () => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    currLanguage,
    profileDetails,
    setProfileID,
    setProfileDetails,
    setCurrGroup,
    setGroupList,
    setChildrenInSession,
  } = useStore((state) => state);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    document.title = TITLE;
    logoutAccount();
    setProfileID("");
    setProfileDetails(initialProfileDetails);
    setCurrGroup(currGroupInitialState);
    setGroupList([]);
    setChildrenInSession([]);
    openSnack("Logged out successfully!", true);
    navigate(`${CURLANG(currLanguage)}/${HOME}`);
    setAnchorEl(null);
  };

  const handleProfile = () => {
    document.title = `${TITLE} | Profile`;
    navigate(
      `${CURLANG(currLanguage)}/${PROFILE_PAGE(profileDetails.id.toString())}`
    );
    setAnchorEl(null);
  };

  const handleAccount = () => {
    document.title = `${TITLE} | Account`;
    navigate(`${CURLANG(currLanguage)}/${ACCOUNT}`);
    setAnchorEl(null);
  };

  const handleSettings = () => {
    document.title = `${TITLE} | Settings`;
    navigate(`${CURLANG(currLanguage)}/${SETTINGS}`);
    setAnchorEl(null);
  };

  const handleSchoolProfile = () => {
    document.title = `${TITLE} | School Profile`;
    navigate(`${CURLANG(currLanguage)}/${SCHOOL_PROFILE}`);
    setAnchorEl(null);
  };

  const handleAdmin = () => {
    document.title = `${TITLE} | Admin Dashboard`;
    navigate(`${CURLANG(currLanguage)}/${ADMIN_PAGE}`);
    setAnchorEl(null);
  };

  const handlePayment = () => {
    document.title = `${TITLE} | ${translation.subscriptions}`;
    navigate(`${CURLANG(currLanguage)}/${PAYMENT}`);
    setAnchorEl(null);
  };

  // Create New Class > Add New Child
  const handleCreateNewClass = () => {
    document.title = `${TITLE} | Add New Class`;
    navigate(`${CURLANG(currLanguage)}/${CREATE_GROUP_PAGE}`);
    setAnchorEl(null);
  };

  const handleAddNewChild = () => {
    document.title = `${TITLE} | Add New Child`;
    navigate(`${CURLANG(currLanguage)}/${ADMIN_PAGE}/${CHILD}`);
    setAnchorEl(null);
  };

  const handleHelp = () => {
    document.title = `${TITLE} | Help Center`;
    navigate(`${CURLANG(currLanguage)}/${HELP}`);
    setAnchorEl(null);
  };

  // const handleEnrollChild = () => {
  //   document.title = `${TITLE} | Enroll Child`;
  //   navigate(`${CURLANG(currLanguage)}/${ENROLL_CHILD_PAGE}`);
  //   setAnchorEl(null);
  // };

  const handleFeedback = () => {
    document.title = `${TITLE} | Add Feedback`;
    navigate(`${CURLANG(currLanguage)}/${CREATE_TICKET_PAGE}`);
    setAnchorEl(null);
  };

  const handleSchoolManageProject = () => {
    document.title = `${TITLE} | ${translation.manageProjects}`;
    navigate(`${CURLANG(currLanguage)}/${PROJECT_SCHOOL_MANAGE_PAGE}`);
    setAnchorEl(null);
  };

  const handleViewFeedback = () => {
    document.title = `${TITLE} | View Feedback`;
    navigate(`${CURLANG(currLanguage)}/${TICKETING_SYSTEM}`);
    setAnchorEl(null);
  };

  const handleCreateProject = () => {
    document.title = `${TITLE} | Create Project`;
    navigate(`${CURLANG(currLanguage)}/${CREATE_PROJECT_PAGE}`);
    setAnchorEl(null);
  };

  const handleManageFundamental = () => {
    document.title = `${TITLE} | ${translation.manageDevelopmentalGoals}`;
    navigate(`${CURLANG(currLanguage)}/${EDIT_DEVELOPMENTAL_GOAL}`);
    setAnchorEl(null);
  };

  const handleManageProhibitedWord = () => {
    document.title = `${TITLE} | ${translation.manageProhibitedWords}`;
    navigate(`${CURLANG(currLanguage)}/${MANAGE_PROHIBITED_WORD}`);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 0, display: "flex", gap: "6px" }}>
      {profileDetails.id !== -1 ? (
        <>
          <Box id="user-avatar" sx={{ display: "hidden" }} />
          <Avatar
            src={profileDetails.image}
            sx={{ cursor: "pointer", mr: "4px" }}
            onClick={handleClick}
            imgProps={{
              onError: (
                event: React.SyntheticEvent<HTMLImageElement, Event>
              ) => {
                event.currentTarget.onerror = null;
                event.currentTarget.src = LogoImage;
              },
            }}
          >
            {profileDetails.preferred_name &&
            profileDetails.preferred_name !== ""
              ? profileDetails.preferred_name.charAt(0).toUpperCase()
              : ""}
          </Avatar>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiMenuItem-root": {
                margin: "5px",
                "&:hover": {
                  backgroundColor: "txt.secondary",
                  borderRadius: "5px",
                  color: "white",
                },
              },
            }}
          >
            {/* <MenuItem
              onClick={handleToggleMode}
              sx={{
                color: "txt.secondary",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <Typography>{translation.switchToTTA}</Typography>
            </MenuItem>
            <Divider /> */}

            {/* Don't render old account page on menu item for parents -- to be changed later */}
            {profileDetails.role !== "PR" && (
              <MenuItem onClick={handleAccount}>{translation.account}</MenuItem>
            )}

            {profileDetails.role !== "AD" && (
              <MenuItem onClick={handleProfile}>{translation.profile}</MenuItem>
            )}

            {/* Render new settings page on menu item for parents */}
            {profileDetails.role === "PR" && (
              <MenuItem onClick={handleSettings}>
                {translation.settings}
              </MenuItem>
            )}

            {profileDetails.role === "AD" && (
              <MenuItem onClick={handleSchoolProfile}>
                {translation.schoolProfile}
              </MenuItem>
            )}

            <Divider />

            {profileDetails.role === "WAD" ||
            profileDetails.role === "CD" ||
            profileDetails.role === "AD" ? (
              <MenuItem onClick={handleAdmin}>{translation.adminPage}</MenuItem>
            ) : null}

            {(profileDetails.role === "WAD" ||
              profileDetails.role === "AD") && [
              <MenuItem
                key={"handle-create-new-class"}
                onClick={handleCreateNewClass}
              >
                {translation.createNewClass}
              </MenuItem>,
              <MenuItem
                key={`handle-add-new-child`}
                onClick={handleAddNewChild}
              >
                {translation.addNewChild}
              </MenuItem>,
            ]}

            {profileDetails.role === "WAD" && (
              <MenuItem onClick={handleSchoolManageProject}>
                {translation.manageProjects}
              </MenuItem>
            )}

            {profileDetails.role === "WAD" ||
            profileDetails.role === "CD" ||
            profileDetails.role === "CE" ? (
              <MenuItem onClick={handleCreateProject}>
                {translation.create_project}
              </MenuItem>
            ) : null}

            {isProfileInFundamentalEditableRoleList(profileDetails.role) ? (
              <MenuItem onClick={handleManageFundamental}>
                {translation.manageDevelopmentalGoals}
              </MenuItem>
            ) : null}

            {profileDetails.role === "WAD" && (
              <MenuItem onClick={handleManageProhibitedWord}>
                {translation.manageProhibitedWords}
              </MenuItem>
            )}

            {(profileDetails.role === "WAD" ||
              profileDetails.role === "CD" ||
              profileDetails.role === "AD") && <Divider />}

            {profileDetails.role === "WAD" || profileDetails.role === "AD" ? (
              <Box>
                <MenuItem onClick={handlePayment}>
                  {translation.subscriptions}
                </MenuItem>
                <Divider />
              </Box>
            ) : null}

            {profileDetails.role === "WAD" ? (
              <MenuItem onClick={handleViewFeedback}>
                {translation.viewFeedback}
              </MenuItem>
            ) : (
              <MenuItem onClick={handleFeedback}>
                {translation.provideFeedback}
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleHelp}>{translation.helpCenter}</MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>{translation.logout}</MenuItem>
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            width: {
              xs: "80px",
              sm: "100px",
            },
          }}
          onClick={() => {
            navigate(`${CURLANG(currLanguage)}/${LOGIN}`);
          }}
        >
          <Image
            src={LoginButtonImage}
            alt="Login Button"
            className={styles.loginBtn}
          />
        </Box>
      )}
    </Box>
  );
};

export default LoginButton;

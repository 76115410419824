import  { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Typography, Stack, TextField, Autocomplete } from "@mui/material";

import Button from "components/Button/Button";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { translation } from "constants/translation";
import { API_School } from "types/school";
import { getAllSchool } from "api/school-api";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import { GridRowsProp } from "@mui/x-data-grid";
import { createPaymentRecord } from "api/payment-api";
import { CreatePaymentRecord } from "types/payment";

const CreatePaymentRecordPage = ({
  handleClose,
  setRows,
}: {
  handleClose?: () => void;
  setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
}) => {
  const openSnack = useSnack();
  const {
    schools,
    setSchools,
  } = useStore((state) => ({
    schools: state.schools,
    setSchools: state.setSchools,
  }));
  const [formData, setFormData] = useState<CreatePaymentRecord>({
    schoolId: -1,
    customerId: "",
  });

  // Get school list
  useEffect(() => {
    const populateData = async () => {
      setSchools(await getAllSchool());
    };
    populateData();
  }, []);

  const handleSubmit = async () => {
    const res = await createPaymentRecord({ ...formData });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(`Payment record is created!`, true);

      if (setRows) {
        setRows((prev: any) => {
          return [...prev, res];
        });
      }

      if (handleClose) {
        handleClose();
      }
    }
  };

  return (
    <Box sx={{ p: "16px" }}>
      <Stack
        component="form"
        sx={{ margin: "auto", alignItems: "center" }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        <Typography
          sx={{ fontSize: "2rem", fontWeight: "500", marginBottom: 2 }}
        >
          {translation.createPaymentRecord}
        </Typography>

        <TextField
          sx={TEXT_FIELD_STYLE}
          label={translation.customerId}
          defaultValue={formData.customerId}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              customerId: event.target.value,
            }));
          }}
        />

        <Autocomplete
          sx={TEXT_FIELD_STYLE}
          disablePortal
          id="combo-box-school"
          onChange={(event: any, school_updated: API_School | null) => {
            if (school_updated !== null) {
              setFormData((prev) => ({
                ...prev,
                schoolId: school_updated.id,
              }));
            }
          }}
          value={
            schools.filter((school) => school.id === formData.schoolId)[0] ||
            null
          }
          options={schools}
          getOptionLabel={(school) => school.name}
          // renderOption={(props, option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => {
            return (
              <>
                <TextField
                  {...params}
                  required
                  label="Schools"
                  placeholder=""
                />
              </>
            );
          }}
        />
        <Button
          buttonText={translation.submit || "Submit"}
          onClick={handleSubmit}
          disabled={formData.schoolId === -1 || formData.customerId === ""}
        />
      </Stack>
    </Box>
  );
};

export default CreatePaymentRecordPage;

import { pdf } from "@react-pdf/renderer";
import {
  checkCertificateEligibility,
  issueCertificate,
  updateLearnerActivityProgress,
} from "api/teacher-api";
import {
  API_Teacher_Display_Obtained_Certificate_Modal_Info,
  API_Teacher_Module_Learner_Lesson_Progress,
} from "types/teacher";
import TrehausCertificatePDF from "./TeacherCertificate/TrehausCertificatePDF";
import moment from "moment";
import { API_Profile } from "types/profile";

export const updateLearnerActivityProgressFunctionCall = async ({
  profileDetails,
  currLanguage,
  teacherCurrentActivityId,
  accessToken,
  learnerLessonProgressList,
  currLessonIndex,
  setLearnerLessonProgressList,
  setDisplayObtainedCertificateModalInfo,
}: {
  profileDetails: API_Profile;
  currLanguage: string;
  teacherCurrentActivityId: number;
  accessToken: string;
  learnerLessonProgressList: API_Teacher_Module_Learner_Lesson_Progress[];
  currLessonIndex: number;
  setLearnerLessonProgressList: (
    learnerLessonProgressList: API_Teacher_Module_Learner_Lesson_Progress[]
  ) => void;
  setDisplayObtainedCertificateModalInfo: (
    displayObtainedCertificateModal: API_Teacher_Display_Obtained_Certificate_Modal_Info
  ) => void;
}) => {
  const completedActivityList = await updateLearnerActivityProgress(
    teacherCurrentActivityId,
    accessToken
  );

  const isUpdateSuccess = typeof completedActivityList !== "string";

  if (isUpdateSuccess) {
    if (
      learnerLessonProgressList[currLessonIndex].activity_done_list.length !==
      completedActivityList.length // If length is not equal, means activity become done, hence need to update existing.
    ) {
      //updated
      const learnerLessonProgressCopyList: API_Teacher_Module_Learner_Lesson_Progress[] =
        JSON.parse(JSON.stringify(learnerLessonProgressList));
      learnerLessonProgressCopyList[currLessonIndex].activity_done_list =
        completedActivityList;
      setLearnerLessonProgressList(learnerLessonProgressCopyList);

      // Check eligible for certz
      const checkCertificateEligibilityRes = await checkCertificateEligibility(
        teacherCurrentActivityId,
        localStorage.getItem("access")!
      );
      if (typeof checkCertificateEligibilityRes !== "string") {
        if (checkCertificateEligibilityRes.is_eligible) {
          console.log("profile is eligble for certz!");
          // issue certz
          const blob = await pdf(
            <TrehausCertificatePDF
              profileFullName={profileDetails.full_name}
              certificateBackgroundImage={
                checkCertificateEligibilityRes.certificate_background_image
              }
              certificateBackgroundWidth={
                checkCertificateEligibilityRes.certificate_background_width
              }
              certificateBackgroundHeight={
                checkCertificateEligibilityRes.certificate_background_height
              }
              currentLanguage={currLanguage}
              issueDate={moment().format("DD MMM YYYY")}
            />
          ).toBlob();

          const issueCertificateRes = await issueCertificate(
            checkCertificateEligibilityRes.teacher_certificate_id!,
            localStorage.getItem("access")!,
            blob
          );

          if (typeof issueCertificateRes !== "string") {
            console.log("Issue certificate success!");
            setDisplayObtainedCertificateModalInfo({
              isOpen: true,
              certificateName: checkCertificateEligibilityRes.certificate_name
                ? checkCertificateEligibilityRes.certificate_name
                : "",
            });
          } else {
            console.log("Issue certificate failed!", issueCertificateRes);
          }
        } else {
          console.log("profile is not eligble for certz!");
        }
      }
    }
  } else {
    console.log("Error when update activity list: ", completedActivityList);
  }

  return isUpdateSuccess;
};

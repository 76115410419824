import React, { Fragment, useEffect, useState } from "react";
import { Box, TextField, Typography, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";
import { translation } from "constants/translation";
import {
  API_Mcq_Question,
  API_Mcq_Option,
} from "types/teacher";
import useDialog from "hooks/useDialog";
import {
  deleteMcqQuestion,
  deleteMcqOption,
} from "api/teacher-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import WarningDialog from "components/Dialog/WarningDialog";
import { McqOptionSortableList } from "../../TeacherForms/ActivityForms/McqOptionDnd/McqOptionSortableList";
import { SubtitleWithAddIcon } from "../ActivityFormComponents";

export const updateMcqQuestionsCall = (
  mcqQuestions: API_Mcq_Question[],
  setMcqQuestions: React.Dispatch<React.SetStateAction<API_Mcq_Question[]>>,
  currMcqQuestionId: string | number,
  updatedMcqOptions: API_Mcq_Option[]
) => {
  const currMcqQuestionIndex = mcqQuestions.findIndex(
    (mcqQuestion) => mcqQuestion.id === currMcqQuestionId
  );
  const updatedMcqQuestions: API_Mcq_Question[] = JSON.parse(
    JSON.stringify(mcqQuestions)
  );
  updatedMcqQuestions[currMcqQuestionIndex].mcq_options = updatedMcqOptions;
  setMcqQuestions(updatedMcqQuestions);
};

export const McqQuestionFieldForDnd = ({
  item,
  mcqQuestions,
  setMcqQuestions,
}: {
  item: API_Mcq_Question;
  mcqQuestions: API_Mcq_Question[];
  setMcqQuestions: React.Dispatch<React.SetStateAction<API_Mcq_Question[]>>;
  onChange?: (input: string) => void;
}) => {
  const openSnack = useSnack();
  const [mcqQuestionName, setMcqQuestionName] = useState<string>(item.name);
  useEffect(() => {
    const inputNameTimeout = setTimeout(async () => {
      // console.log("Updating name now");
      const currMcqQuestionIndex = mcqQuestions.findIndex(
        (mcqQuestion) => mcqQuestion.id === item.id
      );
      const updatedMcqQuestions: API_Mcq_Question[] = JSON.parse(
        JSON.stringify(mcqQuestions)
      );
      updatedMcqQuestions[currMcqQuestionIndex].name = mcqQuestionName;
      setMcqQuestions(updatedMcqQuestions);
    }, 250);
    return () => clearTimeout(inputNameTimeout);
  }, [mcqQuestionName]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteMcqQuestion(Number(item.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setMcqQuestions(res.mcq_questions ? res.mcq_questions : []);
      openSnack(translation.mcqQuestionRemoved || "MCQ Question Removed", true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${item.name}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.name]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete MCQ Question?",
    warningContext: warningContext,
    handleDelete,
  };

  //MCQ options
  const [mcqOptions, setMcqOptions] = useState<API_Mcq_Option[]>(
    item.mcq_options
  );

  const handleAddOption = () => {
    const updatedMcqOptions: API_Mcq_Option[] = [
      ...mcqOptions,
      {
        id: `unassigned-id-option-${mcqOptions.length}`,
        mcq_question: item.id,
        is_correct: false,
        name: "",
        weight: mcqOptions.length + 1,
      },
    ];
    setMcqOptions(updatedMcqOptions);
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 4, width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography>
            {`${translation.formatString(
              translation.mcqQuestionNo || "MCQ Question {number}",
              {
                number: item.weight,
              }
            )}`}
          </Typography>

          <TextField
            fullWidth
            label={translation.mcqQuestion}
            value={mcqQuestionName}
            onChange={(event) => {
              setMcqQuestionName(event.target.value);
            }}
            required
          />

          <Box>
            <Typography>{translation.pleaseIndicateAnswer}</Typography>
            <McqOptionSortableList
              items={mcqOptions}
              setItems={setMcqOptions}
              currMcqQuestionId={item.id}
              mcqQuestions={mcqQuestions}
              setMcqQuestions={setMcqQuestions}
              renderItem={(optionItem) => {
                return (
                  <McqOptionSortableList.Item id={optionItem.id}>
                    <McqOptionFieldForDnd
                      item={optionItem}
                      mcqOptions={mcqOptions}
                      setMcqOptions={setMcqOptions}
                      currMcqQuestionId={item.id}
                      mcqQuestions={mcqQuestions}
                      setMcqQuestions={setMcqQuestions}
                    />
                    <McqOptionSortableList.DragHandle />
                  </McqOptionSortableList.Item>
                );
              }}
            />
            <SubtitleWithAddIcon
              subtitle={translation.addMcqOption || "Add MCQ Option"}
              handleAdd={handleAddOption}
            />
          </Box>
        </Box>
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (typeof item.id === "string" && item.id.includes("unassigned")) {
              const filteredMcqQuestions = mcqQuestions.filter(
                (mcqQuestion) => mcqQuestion.id !== item.id
              );
              const finalMcqQuestions: API_Mcq_Question[] = JSON.parse(
                JSON.stringify(filteredMcqQuestions)
              );
              filteredMcqQuestions.forEach((mcqQuestions, index) => {
                finalMcqQuestions[index].weight = index + 1;
              });
              setMcqQuestions(finalMcqQuestions);
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};

const McqOptionFieldForDnd = ({
  item,
  mcqOptions,
  setMcqOptions,
  currMcqQuestionId,
  mcqQuestions,
  setMcqQuestions,
}: {
  item: API_Mcq_Option;
  mcqOptions: API_Mcq_Option[];
  setMcqOptions: React.Dispatch<React.SetStateAction<API_Mcq_Option[]>>;
  currMcqQuestionId: number | string;
  mcqQuestions: API_Mcq_Question[];
  setMcqQuestions: React.Dispatch<React.SetStateAction<API_Mcq_Question[]>>;
}) => {
  const openSnack = useSnack();
  const [mcqOptionName, setMcqOptionName] = useState<string>(item.name);

  useEffect(() => {
    const inputNameTimeout = setTimeout(async () => {
      const currMcqOptionIndex = mcqOptions.findIndex(
        (mcqOption) => mcqOption.id === item.id
      );
      const updatedMcqOptions: API_Mcq_Option[] = JSON.parse(
        JSON.stringify(mcqOptions)
      );
      updatedMcqOptions[currMcqOptionIndex].name = mcqOptionName;
      setMcqOptions(updatedMcqOptions);
      updateMcqQuestionsCall(
        mcqQuestions,
        setMcqQuestions,
        currMcqQuestionId,
        updatedMcqOptions
      );
    }, 250);
    return () => clearTimeout(inputNameTimeout);
  }, [mcqOptionName]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteMcqOption(Number(item.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setMcqOptions(res.mcq_options);
      updateMcqQuestionsCall(
        mcqQuestions,
        setMcqQuestions,
        currMcqQuestionId,
        res.mcq_options
      );
      openSnack(translation.mcqOptionRemoved || "MCQ Option Removed", true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${item.name}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.name]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete MCQ Option?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 4, width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* <Typography>{`${translation.formatString(translation.mcqOptionNo, {
            number: item.weight,
          })}`}</Typography> */}
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{`${item.weight}. `}</Typography>
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "primary",
                  },
                }}
                checked={item.is_correct}
                onChange={() => {
                  const currMcqOptionIndex = mcqOptions.findIndex(
                    (mcqOption) => mcqOption.id === item.id
                  );
                  const updatedMcqOptions: API_Mcq_Option[] = JSON.parse(
                    JSON.stringify(mcqOptions)
                  );
                  updatedMcqOptions[currMcqOptionIndex].is_correct =
                    !updatedMcqOptions[currMcqOptionIndex].is_correct;

                  setMcqOptions(updatedMcqOptions);

                  updateMcqQuestionsCall(
                    mcqQuestions,
                    setMcqQuestions,
                    currMcqQuestionId,
                    updatedMcqOptions
                  );
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <TextField
                fullWidth
                label={translation.mcqOption}
                value={mcqOptionName}
                onChange={(event) => {
                  setMcqOptionName(event.target.value);
                }}
                required
              />
            </Box>
          </Box>
        </Box>
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (typeof item.id === "string" && item.id.includes("unassigned")) {
              const filteredMcqOptions = mcqOptions.filter(
                (mcqOption) => mcqOption.id !== item.id
              );
              const finalMcqOptions: API_Mcq_Option[] = JSON.parse(
                JSON.stringify(filteredMcqOptions)
              );
              filteredMcqOptions.forEach((mcqOptions, index) => {
                finalMcqOptions[index].weight = index + 1;
              });
              setMcqOptions(finalMcqOptions);

              updateMcqQuestionsCall(
                mcqQuestions,
                setMcqQuestions,
                currMcqQuestionId,
                finalMcqOptions
              );
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};

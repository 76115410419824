import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";

import useStore from "store/store";

import Button from "components/Button/Button";
import { API_Child } from "types/profile";
import AddChildrenFromMenu from "./AddChildrenFromMenu";
import BulkAddChildrenModal from "./BulkAddChildrenModal";

type Props = {
  childLearningMomentCheckList: number[];
  updateChecked: (children: API_Child[], type?: "bulkAdd", toNone?: boolean) => Promise<void>;
};

const CHOICES = [
  {
    key: "select-all",
    label: "Yes, they all did",
  },
  {
    key: "select-some",
    label: "No, not all of them",
  },
  {
    key: "select-none",
    label: "None"
  }
];

const LearningMomentCheckboxes = ({
  childLearningMomentCheckList,
  updateChecked,
}: Props) => {
  const { childList, groupName } = useStore((state) => ({
    groupName: state.currGroup.name,
    childList: state.currGroup.children,
  }));

  const [showAllChildren, setShowAllChildren] = useState(false);
  const [selectOption, setSelectOption] = useState("");

  useEffect(() => {
    if (childLearningMomentCheckList.length === childList.length) {
      setSelectOption(CHOICES[0].key);
    } else if (childLearningMomentCheckList.length > 0) {
      setSelectOption(CHOICES[1].key);
    }
    else if (childLearningMomentCheckList.length == 0) {
      setSelectOption(CHOICES[2].key);
    }
  }, [childLearningMomentCheckList.length, childList.length]);

  const handleChange = async (choiceIndex: number) => {
    const childListToPass = () => {
      if (childLearningMomentCheckList.length === childList.length) {
        //deselect
        return childList;
      } else {
        return childList.filter(
          (child) => !childLearningMomentCheckList.includes(child.id)
        )
      }
    };

    const newChildListToPass = () => {

      return childList.filter(
        (child) => childLearningMomentCheckList.includes(child.id)
      )
    }

    if (selectOption && choiceIndex === 1) {
      if (childLearningMomentCheckList.length == 0) {
        await updateChecked([childList[0]])
      }
      else {
        await updateChecked(newChildListToPass().filter(child => child.id != childList[0].id));
      }

    }
    if (!selectOption && choiceIndex === 0) {
      await updateChecked(childListToPass());
    }

    if (selectOption && choiceIndex === 0) {
      await updateChecked(childListToPass());
    }

    if (selectOption && choiceIndex == 2) {
      console.log(newChildListToPass())
      await updateChecked(newChildListToPass(), 'bulkAdd', true)
    }

    setSelectOption(CHOICES[choiceIndex].key);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          px: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <RadioGroup
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0.5, md: 3 },
            }}
          >
            {CHOICES.map((choice, index) => (
              <FormControlLabel
                key={choice.key}
                sx={{
                  gap: 1,
                }}
                control={
                  <Radio
                    size="small"
                    checked={selectOption === choice.key}
                    sx={{ padding: 0 }}
                  />
                }
                value={choice.key}
                onChange={() => handleChange(index)}
                label={<Typography>{choice.label}</Typography>}
              />
            ))}
          </RadioGroup>
          <Button
            arrow={false}
            icon={<ListAltIcon />}
            buttonText="Children List"
            btnType="outlined"
            style={{
              whiteSpace: "nowrap",
              border: "none",
              color: "txt.primary",
              paddingRight: "8px 4px 8px 16px",
              width: "fit-content",
              height: "fit-content",
              fontSize: "14px",
            }}
            onClick={() => setShowAllChildren(true)}
          />
        </Box>
      </Box>
      {selectOption === "select-some" && (
        <AddChildrenFromMenu
          childList={childList}
          childLearningMomentCheckList={childLearningMomentCheckList}
          updateChild={(child) => updateChecked([child])}
        />
      )}
      <BulkAddChildrenModal
        className={groupName}
        open={showAllChildren}
        setOpen={setShowAllChildren}
        childList={childList}
        childLearningMomentCheckList={childLearningMomentCheckList}
        updateChildren={updateChecked}
      />
    </Box>
  );
};

export default LearningMomentCheckboxes;

import React, { useEffect, useState } from "react";
import {
  API_Announcement,
  API_Announcement_Profile,
} from "types/announcement";
import { useParams } from "react-router-dom";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  addAnnouncementProfileFile,
  deleteAnnouncementProfileFile,
  getAnnouncementProfile,
  updateAnnouncementProfile,
} from "api/announcement-api";
import { translation } from "constants/translation";
import TickImage from "images/tick.png";

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CustomButton from "components/Button/Button";

import ClearIcon from "@mui/icons-material/Clear";
import {
  CustomModal,
  INPUT_FORMAT,
} from "containers/AnnouncementsPage/AnnouncementComponents";
import {
  ACKNOWLEDGE,
  CHECK_BOXES,
  DECISION,
  MULTIPLE_CHOICE,
  NO,
  YES,
} from "containers/AnnouncementsPage/announcementConstant";
import UploadIcon from "@mui/icons-material/Upload";
import Image from "components/Image/Image";
import moment from "moment";

export const initialAnnouncementProfile: API_Announcement_Profile = {
  id: -1,
  announcement: -1,
  profile: -1,
  is_seen: false,
  is_starred: false,
  response_answer_text: "",
  text_response: "",
  files: [],
};

export const AnnouncementResponseModal = ({
  open,
  handleClose,
  announcement,
}: {
  open: boolean;
  handleClose: () => void;
  announcement: API_Announcement;
}) => {
  const theme = useTheme();
  const { childID } = useParams();
  const openSnack = useSnack();

  const { currLanguage, announcementList, setAnnouncementList } = useStore(
    (state) => ({
      currLanguage: state.currLanguage,
      announcementList: state.announcementList,
      setAnnouncementList: state.setAnnouncementList,
    })
  );

  const [announcementProfile, setAnnouncementProfile] =
    useState<API_Announcement_Profile>(initialAnnouncementProfile);
  const [formData, setFormData] = useState<API_Announcement_Profile>(
    initialAnnouncementProfile
  );
  const [files, setFiles] = useState<File[]>([]);
  const [delFiles, setDelFiles] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!childID) return;
    setFiles([]);
    setDelFiles([]);
    const populateAnnouncementProfile = async () => {
      setIsLoading(true);
      const announcementProfile = await getAnnouncementProfile(
        currLanguage,
        Number(childID),
        Number(announcement.id)
      );

      if (typeof announcementProfile !== "string") {
        setFormData(announcementProfile);
        setAnnouncementProfile(announcementProfile);
      }

      setIsLoading(false);
    };

    populateAnnouncementProfile();
    //eslint-disable-next-line
  }, [currLanguage, childID, announcement.id, open]);

  const handleUpdate = async (answerText?: string) => {
    setIsLoading(true);

    // Handle announcement profile files if any
    if (files.length > 0) {
      await addAnnouncementProfileFile(currLanguage, formData.id, files);
    }

    for (let i = 0; i < delFiles.length; i++) {
      if (delFiles[i] && formData.files) {
        await deleteAnnouncementProfileFile(currLanguage, formData.files[i].id);
      }
    }

    // Update Announcement Profile

    const updatedAnnouncementProfile = await updateAnnouncementProfile(
      currLanguage,
      formData.id,
      {
        ...formData,
        response_answer_text: answerText
          ? answerText
          : formData.response_answer_text,
      }
    );

    if (typeof updatedAnnouncementProfile !== "string") {
      openSnack(translation.responseUpdated, true);
      const updatedAnnouncementList: API_Announcement[] = announcementList.map(
        (announcement_) => {
          if (announcement_.id === announcement.id) {
            return {
              ...announcement_,
              is_responded: true,
            };
          } else {
            return announcement_;
          }
        }
      );
      setAnnouncementList(updatedAnnouncementList);
      handleClose();
    } else {
      openSnack(translation.announcementUpdatedFailed, false);
    }

    setIsLoading(false);
  };
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Typography
        sx={{
          fontSize: "1.25rem",
          fontWeight: 500,
          mb: 1,
          textAlign: "center",
        }}
      >
        {announcement.name}
      </Typography>
      <Box
        sx={{ backgroundColor: theme.palette.custom.lightBlue, p: 1, mb: 1 }}
      >
        <Box
          className="ck-content"
          sx={{
            fontSize: 16,
            lineHeight: 1.5,
            color: "#312F30",
            "& figure": {
              display: "block",
            },
          }}
          dangerouslySetInnerHTML={{
            __html:
              announcement.announcement_response_information.response_question,
          }}
        />
      </Box>

      <AnnouncementProfileForm
        announcement={announcement}
        announcementProfile={announcementProfile}
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleUpdate}
        isLoading={isLoading}
        fileField={
          <FileField
            files={files}
            setFiles={setFiles}
            oldFiles={formData}
            delFiles={delFiles}
            setDelFiles={setDelFiles}
            language={currLanguage}
            multiple
            disabled={announcementProfile.response_answer_text !== ""}
          />
        }
        isExpired={
          moment(announcement.announcement_response_information.end_at) <=
          moment()
        }
      />
      {!announcement.is_responded && (
        <Typography
          sx={{
            fontSize: 13,
            lineHeight: 1.25,
            color: "rgba(0, 0, 0, 0.6)",
            textAlign: "right",
            fontStyle: "italic",
            mt: 1,
          }}
        >
          {`Please response before: 
        ${moment(announcement.announcement_response_information.end_at).format(
          INPUT_FORMAT
        )}`}
        </Typography>
      )}
    </CustomModal>
  );
};

const AnnouncementProfileForm = ({
  announcement,
  announcementProfile,
  formData,
  setFormData,
  handleSubmit,
  isLoading,
  fileField,
  isExpired,
}: {
  announcement: API_Announcement;
  announcementProfile: API_Announcement_Profile;
  formData: API_Announcement_Profile;
  setFormData: React.Dispatch<React.SetStateAction<API_Announcement_Profile>>;
  handleSubmit: (answerText?: string) => Promise<void>;
  isLoading: boolean;
  fileField: React.ReactNode;
  isExpired: boolean;
}) => {
  const openSnack = useSnack();
  const responseTypeText =
    announcement.announcement_response_information.response_type;
  const isResponded = announcementProfile.response_answer_text !== "";
  // console.log("formData.response_answer_text: ", formData.response_answer_text);
  // console.log(
  //   "form data includes: ",
  //   formData.response_answer_text.includes(
  //     announcement.announcement_response_information.response_options[0].name
  //   )
  // );
  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        {responseTypeText === ACKNOWLEDGE &&
        announcementProfile.response_answer_text !== "" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: 20, fontWeight: 500, color: "primary.main" }}
            >
              Acknowledged
            </Typography>

            <Box sx={{ width: 32, height: 32 }}>
              <Image src={TickImage} alt="tick-image" />
            </Box>
          </Box>
        ) : responseTypeText === DECISION ? (
          <>
            {!announcementProfile.response_answer_text && (
              <>
                <Typography>
                  {announcementProfile.response_answer_text
                    ? "Response Submitted"
                    : "Grant Permission"}
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CustomButton
                    arrow={false}
                    buttonText={YES}
                    btnType={
                      formData.response_answer_text === YES
                        ? "filled"
                        : "outlined"
                    }
                    onClick={() => {
                      setFormData((prev) => ({
                        ...prev,
                        response_answer_text: YES,
                      }));
                    }}
                    disabled={isResponded}
                  />
                  <CustomButton
                    arrow={false}
                    buttonText={NO}
                    btnType={
                      formData.response_answer_text === NO
                        ? "filled"
                        : "outlined"
                    }
                    onClick={() => {
                      setFormData((prev) => ({
                        ...prev,
                        response_answer_text: NO,
                      }));
                    }}
                    disabled={isResponded}
                  />
                </Box>
              </>
            )}

            {announcementProfile.response_answer_text === YES ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    color: "primary.main",
                  }}
                >
                  Permission Granted
                </Typography>

                <Box sx={{ width: 32, height: 32 }}>
                  <Image src={TickImage} alt="tick-image" />
                </Box>
              </Box>
            ) : announcementProfile.response_answer_text === NO ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    color: "primary.main",
                  }}
                >
                  Permission Not Granted
                </Typography>

                {/* <Box sx={{ width: 32, height: 32 }}>
                    <Image src={TickImage} alt="tick-image" />
                  </Box> */}
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : responseTypeText === CHECK_BOXES ? (
          announcement.announcement_response_information.response_options.map(
            (option) => {
              const optionName = option.name.trim();
              return (
                <CheckBoxComponent
                  name={optionName}
                  selected={formData.response_answer_text.includes(optionName)}
                  onClick={() => {
                    if (formData.response_answer_text.includes(optionName)) {
                      setFormData((prev) => ({
                        ...prev,
                        response_answer_text: prev.response_answer_text
                          .split(";")
                          .filter((item) => item !== optionName)
                          .join(";"),
                      }));
                    } else {
                      setFormData((prev) => ({
                        ...prev,
                        response_answer_text: [
                          ...prev.response_answer_text.split(";"),
                          optionName,
                        ].join(";"),
                      }));
                    }
                  }}
                  disabled={isResponded}
                />
              );
            }
          )
        ) : responseTypeText === MULTIPLE_CHOICE ? (
          <RadioGroup
            sx={{
              flexWrap: "nowrap",
              gap: 1,
            }}
          >
            {announcement.announcement_response_information.response_options.map(
              (choice, index) => (
                <FormControlLabel
                  disabled={isResponded}
                  key={index}
                  control={
                    <Radio
                      size="small"
                      checked={choice.name === formData.response_answer_text}
                      sx={{ padding: 0 }}
                    />
                  }
                  value={index}
                  onChange={() => {
                    setFormData((prev) => ({
                      ...prev,
                      response_answer_text: choice.name,
                    }));
                  }}
                  label={
                    <Typography
                      sx={{
                        lineHeight: 1.25,
                      }}
                    >
                      {choice.name}
                    </Typography>
                  }
                  sx={{
                    border: "2px solid",
                    borderColor:
                      choice.name === formData.response_answer_text
                        ? "#98C4C5"
                        : "#F0F0F0",
                    borderRadius: "10px",
                    backgroundColor:
                      choice.name === formData.response_answer_text
                        ? "rgba(209, 228, 227, 0.37)"
                        : "transparent",
                    mx: "auto",
                    p: 1,
                    alignItems: "flex-start",
                    gap: 1.5,
                    width: "100%",
                    maxWidth: "500px",
                  }}
                />
              )
            )}
          </RadioGroup>
        ) : null}
        <Divider sx={{ width: "100%" }} />

        {announcement.announcement_response_information.is_allow_text && (
          <>
            <Typography sx={{ textAlign: "left", width: "100%" }}>
              {announcement.announcement_response_information.text_instruction}
            </Typography>
            <TextField
              autoComplete="off"
              fullWidth
              // label={
              //   announcement.announcement_response_information.text_instruction
              // }
              disabled={isResponded}
              value={formData.text_response}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  text_response: event.target.value,
                }));
              }}
            />
          </>
        )}
        {announcement.announcement_response_information.is_allow_attachment &&
          fileField}
        {(announcement.announcement_response_information.response_type !==
          ACKNOWLEDGE ||
          announcement.announcement_response_information.is_allow_attachment ||
          announcement.announcement_response_information.is_allow_text ||
          announcementProfile.response_answer_text === "") && (
          <CustomButton
            arrow={false}
            buttonText={
              isLoading
                ? translation.loading
                : announcementProfile.response_answer_text === ""
                ? responseTypeText === ACKNOWLEDGE
                  ? translation.acknowledge
                  : translation.submit
                : translation.update
            }
            style={{
              opacity: isLoading ? 0.5 : 1,
              minHeight: "45px",
              display: isResponded ? "none" : "flex",
            }}
            onClick={() => {
              if (isExpired) {
                openSnack("Ops! The response time has ended.", false);
                return;
              }
              if (responseTypeText === ACKNOWLEDGE) {
                handleSubmit("Acknowledged");
              } else {
                handleSubmit();
              }
            }}
            disabled={isLoading}
            isLoading={isLoading}
          />
        )}
      </Box>
    </Stack>
  );
};

const CheckBoxComponent = ({
  name,
  selected,
  onClick,
  disabled,
}: {
  name: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // width: "100%",
        px: 1.5,
        border: "2px solid",
        borderColor: selected ? "#98C4C5" : "#F0F0F0",
        borderRadius: "10px",
        backgroundColor: selected ? "rgba(209, 228, 227, 0.37)" : "transparent",
        cursor: disabled ? "default" : "pointer",
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
      onClick={disabled ? () => {} : onClick}
    >
      <Checkbox
        sx={{ color: theme.palette.primary.main }}
        checked={selected}
        disabled={disabled}
      />
      <Typography
        sx={{
          textAlign: "left",
          fontSize: 14,
          textTransform: "capitalize",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

const FileField = ({
  files,
  setFiles,
  multiple = false,
  oldFiles,
  delFiles,
  setDelFiles,
  language,
  disabled,
}: {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  multiple?: boolean;
  oldFiles?: API_Announcement_Profile;
  delFiles?: boolean[];
  setDelFiles?: React.Dispatch<React.SetStateAction<boolean[]>>;
  language?: string;
  disabled?: boolean;
}) => (
  <Box
    sx={{
      width: "100%",
    }}
  >
    <input
      type="file"
      id="files-button"
      multiple={multiple}
      hidden
      onChange={(event) => {
        const files: File[] = Array.prototype.slice.call(event.target.files);

        if (files.length === 0) {
          event.target.value = "";
          return;
        }

        setFiles((prev: File[]) => [...prev, ...files]);

        event.target.value = "";
      }}
      disabled={disabled}
    />

    <label
      htmlFor="files-button"
      style={{ textAlign: "-webkit-center" as "center" }}
    >
      <IconButton
        component="span"
        sx={{
          width: "30%",
          borderRadius: "8px",
          border: `2px dashed var(--primary-main)`,
          "&:hover": {
            backgroundColor: "primary.main",
            border: `2px dashed var(--primary-main)`,
          },
          display: "flex",
          gap: 1,
          opacity: disabled ? 0.5 : 1,
        }}
        disabled={disabled}
      >
        <UploadIcon sx={{ color: "txt.secondary" }} />
        <Typography sx={{ color: "black" }}>
          {translation.uploadDocument}
        </Typography>
      </IconButton>
    </label>

    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        rowGap: 0.75,
        columnGap: 1.5,
        flexWrap: "wrap",
        my: 1,
      }}
    >
      {oldFiles &&
        delFiles &&
        setDelFiles &&
        language &&
        oldFiles.files &&
        oldFiles.files.map((oldFile, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
            key={index}
          >
            <a
              style={{
                textDecoration: `underline${
                  delFiles[index] ? " line-through" : ""
                }`,
              }}
              href={oldFile.file}
              target="_blank"
              rel="noreferrer"
            >
              {oldFile.name}
            </a>
            {!disabled && (
              <ClearIcon
                sx={{
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setDelFiles((prev) => {
                    const temp = JSON.parse(JSON.stringify(prev));
                    temp[index] = !temp[index];
                    return temp;
                  });
                }}
              />
            )}
          </Box>
        ))}

      {files.map((file, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer">
            {file.name}
          </a>

          <ClearIcon
            sx={{
              color: "txt.secondary",
              cursor: "pointer",
              backgroundColor: "primary.light",
              borderRadius: "50%",
              p: "3px",
              transition: "all 100ms linear",
              "&:hover": {
                p: "4px",
                backgroundColor: "primary.main",
                transition: "all 100ms linear",
              },
            }}
            onClick={() => {
              setFiles((prev) => {
                const temp = [...prev];
                temp.splice(index, 1);
                return temp;
              });
            }}
          />
        </Box>
      ))}
    </Box>
  </Box>
);

import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const ModalContainer = styled(Box)`
  width: 724px;
  max-height: 604px;
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 10px;
  overflow: hidden;
  &:focus-visible {
    outline: none;
  }
  z-index: 100;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const HeadingStyle = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  font-family: "Futura PT", sans-serif;
  color: #323031;
`;

export const ImageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  padding: 0 16px;
  overflow-y: auto;
`;

export const ImageContainer = styled(Box)`
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
  &:hover img {
    opacity: 0.3;
  }
  &:hover div {
    opacity: 1;
  }
`;

export const ImageCaption = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

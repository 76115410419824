import React from "react";
import { Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { API_Project_Lesson_Activity } from "types/project";
import FileUploadIcon from "images/FileUploadIcon";
import { translation } from "constants/translation";

type FileFieldProps = {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  multiple?: boolean;
  oldFiles?: API_Project_Lesson_Activity;
  delFiles?: boolean[];
  setDelFiles?: React.Dispatch<React.SetStateAction<boolean[]>>;
  language?: string;
};

export const FileField = ({
  files,
  setFiles,
  multiple = false,
  oldFiles,
  delFiles,
  setDelFiles,
  language,
}: FileFieldProps) => {
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Box sx={{ minWidth: "fit-content" }}>
        <input
          type="file"
          id="image-button-files"
          multiple={multiple}
          hidden
          onChange={(event) => {
            const newFiles: File[] = Array.prototype.slice.call(
              event.target.files
            );
            setFiles((prev: File[]) => [...prev, ...newFiles]);
          }}
        />
        <label htmlFor="image-button-files" style={{ width: "fit-content" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              border: "1px dashed #8C8C8C",
              padding: "24px 12px",
              gap: 1.5,
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            <FileUploadIcon />
            <Typography>{translation.selectDropAttachments}</Typography>
          </Box>
        </label>
      </Box>
      {!!(oldFiles?.files.length || files.length) && (
        <Box
          sx={{
            backgroundColor: "neutral.100",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          {oldFiles &&
            delFiles &&
            setDelFiles &&
            language &&
            oldFiles.files.map((img, index) => (
              <Box
                sx={{
                  display:
                    // @ts-ignore
                    img[`name_${language === "id" ? "ind" : language}`] === null
                      ? "none"
                      : "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                key={index}
              >
                <a
                  style={{
                    color: "#5C9391",
                    textDecoration: `${
                      delFiles[index] ? " line-through" : "none"
                    }`,
                  }}
                  href={img.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  {img.name}
                </a>

                <ClearIcon
                  sx={{
                    color: "txt.secondary",
                    cursor: "pointer",
                    backgroundColor: "primary.light",
                    borderRadius: "50%",
                    p: "3px",
                    transition: "all 100ms linear",
                    "&:hover": {
                      p: "4px",
                      backgroundColor: "primary.main",
                      transition: "all 100ms linear",
                    },
                  }}
                  onClick={() => {
                    setDelFiles((prev) => {
                      const temp = JSON.parse(JSON.stringify(prev));
                      temp[index] = !temp[index];
                      return temp;
                    });
                  }}
                />
              </Box>
            ))}
          {files.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <a
                style={{
                  color: "#5C9391",
                  textDecoration: "none",
                }}
                href={URL.createObjectURL(file)}
                target="_blank"
                rel="noreferrer"
              >
                {file.name}
              </a>
              <ClearIcon
                sx={{
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setFiles((prev) => {
                    const temp = [...prev];
                    temp.splice(index, 1);
                    return temp;
                  });
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getActivityBySlug, getProfileModuleReview } from "api/teacher-api";
import useStore from "store/store";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  RESOURCE_TYPE_DRAG_AND_DROP,
  RESOURCE_TYPE_FLIP_CARD,
  RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY,
  RESOURCE_TYPE_MCQ,
  RESOURCE_TYPE_PICTORIAL_OBSERVATION,
  RESOURCE_TYPE_TEXT,
  RESOURCE_TYPE_VIDEO,
} from "containers/teacher/TeacherPage/teacherPageConstants";
import TeacherTextActivityScreen from "../../TeacherLessonActivityTypeScreen/TeacherTextActivityScreen";
import TeacherVideoActivityScreen from "../../TeacherLessonActivityTypeScreen/TeacherVideoActivityScreen";
import { Box, Typography } from "@mui/material";
import TeacherFlipCardActivityScreen from "../../TeacherLessonActivityTypeScreen/TeacherFlipCardActivityScreen";

import TeacherMcqActivityScreen from "../../TeacherMCQActivityScreen/TeacherMcqActivityScreen";
import TeacherInteractiveDataEntryActivityScreen from "../../TeacherLessonActivityTypeScreen/TeacherInteractiveDataEntryActivityScreen";
import TeacherPictorialObservationActivityScreen from "../../TeacherLessonActivityTypeScreen/TeacherPictorialObservationActivityScreen";
import TeacherDragAndDropActivityScreen from "../../TeacherLessonActivityTypeScreen/TeacherDragAndDropActivityScreen";
import useInitialiseTeacherLesson from "hooks/useInitialiseTeacherLesson";
import { API_Teacher_Mcq_Activity } from "types/teacher";
import { shuffleArray } from "helper/helper";
import FeedbackModal from "../TeacherPageComponents/FeedbackModal";
import EligibleCertificateModal from "../TeacherPageComponents/EligibleCertificateModal";
import LessonActivityBreadcrumbs from "./LessonActivityBreadcrumbs";
import FloatingNoteDiscussionComponent from "./FloatingNoteDiscussionComponent";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const TeacherLessonActivityScreen = () => {

  useInitialiseTeacherLesson();
  const { module, activitySlug } = useParams();
  const {
    currLanguage,
    setTeacherCurrentModuleReview,
    teacherCurrentActivity,
    setTeacherCurrentActivity,
    teacherCurrentLesson,
    isTeacherModuleLoading,
    fullScreen
  } = useStore((state) => state);

  const populateCurrActivity = async () => {
    const activity = await getActivityBySlug(currLanguage, activitySlug!);
    if (activity.resourcetype === RESOURCE_TYPE_MCQ) {
      const activityMcq: API_Teacher_Mcq_Activity = JSON.parse(
        JSON.stringify(activity)
      );
      activityMcq.mcq_questions = shuffleArray(activityMcq.mcq_questions!);
      setTeacherCurrentActivity(activityMcq);
      return;
    }
    setTeacherCurrentActivity(activity);
  };

  useEffect(() => {
    if (activitySlug) {
      populateCurrActivity();
    }
  }, [activitySlug]);

  useEffect(() => {
    const populateProfileModuleReview = async () => {
      const profileModuleReviewRes = await getProfileModuleReview(module!);
      setTeacherCurrentModuleReview(profileModuleReviewRes);
    };
    module && populateProfileModuleReview();
  }, [module]);

  return (
    <>
      <FeedbackModal />
      <EligibleCertificateModal />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}>
        {/* <LessonActivityBreadcrumbs /> */}
        {!fullScreen && <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>

          <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>{teacherCurrentActivity.name}</Typography>
          <Box sx={{ display: 'flex', gap: 3, mx: 3 }}>
            <Box sx={{ display: 'flex', color: "#5C9391", gap: 1 }}>
              <ArrowBackIcon />
              <Typography sx={{ color: "#5C9391" }}>Previous</Typography>
            </Box>
            <Box sx={{ display: 'flex', color: "#5C9391", gap: 1 }}>
              <Typography sx={{ color: "#5C9391" }}>Next</Typography>
              <ArrowForwardIcon />
            </Box>
          </Box>
        </Box>}


        {teacherCurrentActivity.slug !== activitySlug ||
          isTeacherModuleLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Box
              sx={{
                width: "inherit",
                height: "inherit",
                position: "relative",
                display: "flex",
                flex: 1,
                p: fullScreen ? 0 : 1
              }}
            >
              {teacherCurrentActivity.resourcetype === RESOURCE_TYPE_TEXT ? (
                <>
                  <TeacherTextActivityScreen
                    teacherCurrentActivity={teacherCurrentActivity}
                    teacherCurrentLesson={teacherCurrentLesson}
                  />
                </>
              ) : teacherCurrentActivity.resourcetype ===
                RESOURCE_TYPE_VIDEO ? (
                <>
                  <TeacherVideoActivityScreen
                    teacherCurrentActivity={teacherCurrentActivity}
                    teacherCurrentLesson={teacherCurrentLesson}
                  />
                </>
              ) : teacherCurrentActivity.resourcetype ===
                RESOURCE_TYPE_FLIP_CARD ? (
                <TeacherFlipCardActivityScreen
                  teacherCurrentActivity={teacherCurrentActivity}
                  teacherCurrentLesson={teacherCurrentLesson}
                />
              ) : teacherCurrentActivity.resourcetype ===
                RESOURCE_TYPE_DRAG_AND_DROP ? (
                <TeacherDragAndDropActivityScreen
                  teacherCurrentActivity={teacherCurrentActivity}
                  teacherCurrentLesson={teacherCurrentLesson}
                />
              ) : teacherCurrentActivity.resourcetype === RESOURCE_TYPE_MCQ ? (
                <TeacherMcqActivityScreen
                  teacherCurrentActivity={teacherCurrentActivity}
                  teacherCurrentLesson={teacherCurrentLesson}
                />
              ) : teacherCurrentActivity.resourcetype ===
                RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY ? (
                <TeacherInteractiveDataEntryActivityScreen
                  teacherCurrentActivity={teacherCurrentActivity}
                  teacherCurrentLesson={teacherCurrentLesson}
                />
              ) : teacherCurrentActivity.resourcetype ===
                RESOURCE_TYPE_PICTORIAL_OBSERVATION ? (
                <TeacherPictorialObservationActivityScreen
                  teacherCurrentActivity={teacherCurrentActivity}
                  teacherCurrentLesson={teacherCurrentLesson}
                />
              ) : null}
              {!fullScreen && <FloatingNoteDiscussionComponent />}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default TeacherLessonActivityScreen;

import React, { useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import useStore from "store/store";
import { translation } from "constants/translation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { languageOptions } from "types/language";

import Image from "components/Image/Image";
import UKIcon from "images/languages/gb.png";
import CNIcon from "images/languages/cn.png";
import IDIcon from "images/languages/id.png";
import FRIcon from "images/languages/fr.png";
import PKIcon from "images/languages/ur.png";

const languages = {
  en: "English",
  zh: "中文",
  id: "Bahasa",
  ur: "اردو", // Urdu
  fr: "Français", // French
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&.active": {
        backgroundColor: theme.palette.primary.light,
        pointerEvents: "none",
      },
    },
  },
}));

export default function LanguageSelector({
  isMobileView = false,
  isTTA = false,
}: {
  isMobileView?: boolean;
  isTTA?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;
  const { languageSlug } = useParams();
  const navigate = useNavigate();

  const {
    currLanguage,
    setCurrLanguage,
    setRefreshGroupBackpack,
    setRefreshGroupFavourite,
  } = useStore((state) => state);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (languageSlug) {
      setCurrLanguage(languageSlug);
      translation.setLanguage(languageSlug);
    } else {
      setCurrLanguage(currLanguage);
      translation.setLanguage(currLanguage);
    }
    setRefreshGroupBackpack(true);
    setRefreshGroupFavourite(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLanguage, languageSlug]);

  // useEffect(() => {
  //   console.log("path: ", currentPath);
  //   navigate(currentPath); //remove /en or /zh or /id
  // }, [currLanguage, currentPath]);
  // console.log("currentLocation: ", currentLocation);

  let currLangImg = "";

  if (currLanguage === "zh") {
    currLangImg = CNIcon;
  } else if (currLanguage === "id") {
    currLangImg = IDIcon;
  } else if (currLanguage === "ur") {
    currLangImg = PKIcon;
  } else if (currLanguage === "fr") {
    currLangImg = FRIcon;
  } else {
    currLangImg = UKIcon;
  }
  const languageStr = (currLanguage: string) => {
    switch (currLanguage) {
      case "en":
        return languages.en;
      case "zh":
        return languages.zh;
      case "id":
        return languages.id;
      case "ur":
        return languages.ur;
      case "fr":
        return languages.fr;
      default:
        return languages.en;
    }
  };

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: { xs: isMobileView ? "column" : "row", lg: "row" },
          mr: { xs: isMobileView ? 0 : "10px", lg: "10px" },
          cursor: "pointer",
          alignItems: "center",
          backgroundColor: "#DFDDDD",
          p: 1,
          borderRadius: `10px`,
          "&:hover": {
            backgroundColor: `white`,
          },
          width: isMobileView ? 120 : "default",
        }}
      >
        <Box
          sx={{
            display: { xs: isMobileView ? "none" : "flex", lg: "flex" },
            // mr: "10px",
          }}
        >
          <Image
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
            }}
            objectFit="cover"
            src={currLangImg}
            alt={currLanguage}
          />
        </Box>

        <Box
          sx={{
            display: { xs: isMobileView ? "flex" : "none", lg: "none" },
            mb: 1,
          }}
        >
          <Image
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
            }}
            objectFit="cover"
            src={currLangImg}
            alt={currLanguage}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          {isMobileView ? languageStr(currLanguage) : ""}
          <ArrowDropDownIcon fontSize="small" />
        </Box>
      </Box>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.keys(languageOptions).map((lang: string) => {
          let langImg = "";

          if (lang === "zh") {
            langImg = CNIcon;
          } else if (lang === "id") {
            langImg = IDIcon;
          } else if (lang === "ur") {
            langImg = PKIcon;
          } else if (lang === "fr") {
            langImg = FRIcon;
          } else {
            langImg = UKIcon;
          }

          return (
            <MenuItem
              className={currLanguage === lang ? "active" : ""}
              key={lang}
              onClick={() => {
                handleClose();
                setCurrLanguage(lang);
                translation.setLanguage(lang);
                if (
                  currentPath.slice(0, 3).includes("/en") ||
                  currentPath.slice(0, 3).includes("/zh") ||
                  currentPath.slice(0, 3).includes("/ur") ||
                  currentPath.slice(0, 3).includes("/fr") ||
                  currentPath.slice(0, 3).includes("/id")
                ) {
                  navigate(`/${lang}` + currentPath.slice(3));
                } else {
                  navigate(`/${lang}` + currentPath);
                }
              }}
              disableRipple
            >
              <Image
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
                objectFit="cover"
                src={langImg}
                alt={lang}
              />
              {languageStr(lang)}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
}

import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TeacherLandingPageBackgroundImage from "images/teacher-landing-page/teacher-landing-page-background.png";
import SearchBar from "./SearchBar";
import Image from "components/Image/Image";

type Suggestion = {
  name: string;
  url: string;
};

type Props = {
  userName?: string;
  suggestions?: Suggestion[];
};

const Suggestions = ({ suggestions }: { suggestions: Suggestion[] }) => {
  if (suggestions.length) {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "100%",
        }}
      >
        <Typography>Suggestions:</Typography>
        {suggestions.map(({ name, url }) => (
          <Link
            key={name}
            to={url}
            style={{
              color: "#5C9391",
            }}
          >
            <Typography>{name}</Typography>
          </Link>
        ))}
      </Box>
    );
  }

  return null;
};

const TopSection = ({ userName = "", suggestions = [] }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = useMemo(
    () => (location.search ? location.search.split("=")[1] : null),
    [location.search]
  );

  const handleSearch = (inputQuery: string) => {
    if (inputQuery) {
      navigate({
        pathname: location.pathname,
        search: `?query=${inputQuery}`,
      });
    } else if (query) {
      navigate({
        pathname: location.pathname,
      });
    }
  };

  if (query) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          p: "40px 80px",
          pb: "0",
        }}
      >
        <SearchBar
          searchVal={query}
          handleChange={handleSearch}
          entity="module"
        />
        <Suggestions suggestions={suggestions} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        height: { lg: "500px" },
        justifyContent: { xs: "center", sm: "flex-start" },
        alignItems: "center",
        width: "100%",
        backgroundColor: "#EBF8F8",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "flex-start" },
          width: { xs: "100%", md: "45%" },
          paddingLeft: { sm: "40px", lg: "80px" },
          flexGrow: 1,
          mb: 6,
          mt: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
            maxWidth: { xs: "70%", md: "414px" },
          }}
        >
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: 600,
              color: "#323031",
              textAlign: "left",
              lineHeight: "normal",
            }}
          >
            It takes a big heart <br /> to help shape little minds
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#323031",
              width: "100%",
              textAlign: "left",
              lineHeight: "1.5",
            }}
          >
            Hello {userName}, what you want to learn today?
          </Typography>
          <SearchBar handleChange={handleSearch} entity="Module" />
          <Suggestions suggestions={suggestions} />
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
          width: { md: "50%", lg: "55%" },
          maxWidth: { md: "50%", lg: "55%" },
          maxHeight: "100%",
          overflowY: "hidden",
        }}
      >
        <Image
          src={TeacherLandingPageBackgroundImage}
          alt="background-1"
          style={{
            objectFit: "contain",
            width: "100%",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default TopSection;

import React from "react";

import useStore from "store/store";
import { Box } from "@mui/material";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Title from "./Title";

import TeacherLessonActivityList from "../TeacherPageComponents/TeacherLessonActivityList";

const ModuleSummarySideMenu = () => {
  const { isTeacherModuleLoading,  } =
    useStore((state) => ({
      isTeacherModuleLoading: state.isTeacherModuleLoading,
    }));

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      {isTeacherModuleLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Title />
          <Box
            sx={{
              px: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              mt: 1.5,
              maxHeight: "75vh",
              overflow: "auto",
            }}
          >
            <TeacherLessonActivityList />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ModuleSummarySideMenu;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useStore from "store/store";
import { API_Document } from "types/announcement";
import {
  deleteDocument,
  getDocumentList,
  getDocumentListByChild,
} from "api/announcement-api";
import { translation } from "constants/translation";
import { NothingMessage } from "containers/WelcomeBackPage/components/TeacherDashboard";
import useSnack from "hooks/useSnack";
import { DocumentCard, DocumentModal } from "./DocumentComponents";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";

const DocumentsPage = () => {
  const openSnack = useSnack();
  const { childID, schoolId } = useParams();

  const { currLanguage, userId, profileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    userId: state.profileDetails.user,
    profileDetails: state.profileDetails,
  }));

  const initialDocument: API_Document = {
    id: -1,
    school: Number(schoolId)!,
    files: [],
    created_at: "",
    created_by: -1,
    created_by_preferred_name: "",
    created_by_role: "",
    profiles: [],
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<API_Document[]>([]);
  const [document, setDocument] = useState<API_Document>(initialDocument);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (!childID && !schoolId) return;

    const fetchDocuments = async () => {
      setIsLoading(true);

      let documents;
      if (childID) {
        documents = await getDocumentListByChild(currLanguage, Number(childID));
      } else {
        documents = await getDocumentList(currLanguage, Number(schoolId));
      }

      if (typeof documents !== "string") {
        setDocuments(documents);
      }

      setIsLoading(false);
    };

    fetchDocuments();
  }, [currLanguage, childID, schoolId]);

  const handleDelete = async () => {
    const response = await deleteDocument(currLanguage, document.id);

    if (response === "Success") {
      openSnack(translation.deleteSuccess, true);
      setDocuments(documents.filter((a) => a.id !== document.id));
    } else {
      openSnack(translation.deleteFailed, false);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: translation.deleteDocument + "?",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  const isViewOnly = profileDetails.role === "PR";

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <WarningDialog {...warningBody} />
          {/* {!isViewOnly && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "end",
                gap: 2,
                mb: 1.5,
                mr: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  // flex: 1,
                  borderRadius: "10px",
                  border: "1px solid var(--primary-main)",
                  px: 1,
                  width: "fit-content",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDocument(initialDocument);
                  setOpenModal(true);
                }}
              >
                <Image
                  src={AddIcon}
                  alt="add-icon"
                  style={{ paddingLeft: 1, width: 15, height: 15 }}
                />
                <Typography sx={{ fontSize: 14 }}>
                  {translation.addNewDocument}
                </Typography>
              </Box>
            </Box>
          )} */}
          {documents.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {documents.map((document, index) => (
                <>
                  <DocumentCard
                    key={document.id}
                    document={document as API_Document}
                    handleClickEdit={() => {
                      setDocument(document);
                      setOpenModal(true);
                    }}
                    handleClickDelete={() => {
                      setDocument(document);
                      handleOpenDialog();
                    }}
                    isViewOnly={isViewOnly}
                    isEditable={
                      profileDetails.role === "WAD" ||
                      profileDetails.role === "AD" ||
                      document.created_by === userId
                    }
                  />
                  {index !== documents.length - 1 && <Divider />}
                </>
              ))}
            </Box>
          ) : (
            <NothingMessage message={translation.noDocumentYet} />
          )}
          {openModal && (
            <DocumentModal
              open={openModal}
              handleClose={() => setOpenModal(false)}
              document={document}
              setDocuments={setDocuments}
            />
          )}
        </>
      )}
    </>
  );
};

export default DocumentsPage;

import React from "react";

import { Box, Typography, Avatar } from "@mui/material";
import LogoImage from "../../images/logo.png";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const currentLocation = useLocation();
  const page = currentLocation.pathname;

  // console.log("currentPath: ", currentPath);
  const isHidePage =
    page.includes("calendar") ||
    page.includes("admin-page") ||
    localStorage.getItem("access");
  return (
    <>
      {!isHidePage && (
        <Box
          sx={{
            pt: 2,
            pb: 1,
            px: 3,
            width: "100%",
            backgroundColor: "primary.main",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            mt: "auto",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={LogoImage}
              variant="square"
              imgProps={{
                onError: (
                  event: React.SyntheticEvent<HTMLImageElement, Event>
                ) => {
                  event.currentTarget.onerror = null;
                  event.currentTarget.src = LogoImage;
                },
              }}
            ></Avatar>

            <Typography
              variant="h6"
              sx={{
                display: "flex",
                fontFamily: "monospace",
                fontWeight: 700,
                fontSize: ".8rem",
                lineHeight: "12px",
                textTransform: "uppercase",
                width: 100,
                textAlign: "center",
                color: "txt.dark",
              }}
            >
              The Little Lab
            </Typography>
          </Box>

          <Typography variant="caption">
            Copyright 2022. All rights reserved.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Footer;

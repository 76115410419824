import { useEffect } from "react";

import { useParams } from "react-router-dom";
import {
  getModuleBySlug,
  getProfileModuleNote,
  getPublishedActivityModuleBySlug,
} from "../api/teacher-api";

import useStore from "../store/store";
import useSnack from "./useSnack";

const useInitialiseCurrModule = (isAdminAndEdit?: boolean) => {
  const { moduleSlug } = useParams();
  const openSnack = useSnack();
  const {
    profileDetails,
    setTeacherCurrentModule,
    setTeacherCurrentModuleNote,
    isRefreshTeacherModule,
    setIsRefreshTeacherModule,
    setIsTeacherModuleLoading,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    setTeacherCurrentModule: state.setTeacherCurrentModule,
    setTeacherCurrentModuleNote: state.setTeacherCurrentModuleNote,
    isRefreshTeacherModule: state.isRefreshTeacherModule,
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
    setIsTeacherModuleLoading: state.setIsTeacherModuleLoading,
  }));

  const initialiseModule = async (showLoading: boolean) => {
    if (moduleSlug) {
      // populate module
      showLoading && setIsTeacherModuleLoading(true);
      let module;
      if (isAdminAndEdit) {
        module = await getPublishedActivityModuleBySlug(moduleSlug);
      } else {
        module = await getModuleBySlug(moduleSlug);
      }

      // console.log("Returned module: ", module);
      if (typeof module === "string") {
        openSnack(module, false);
      } else {
        setTeacherCurrentModule(module);
      }
      // populate module note
      const moduleNote = await getProfileModuleNote(moduleSlug);
      // console.log("Returned module: ", module);
      if (typeof moduleNote === "string") {
        openSnack(moduleNote, false);
      } else {
        setTeacherCurrentModuleNote(moduleNote);
      }

      showLoading && setIsTeacherModuleLoading(false);
      setIsRefreshTeacherModule(false);
    }
  };

  useEffect(() => {
    initialiseModule(true);
  }, [moduleSlug]);

  useEffect(() => {
    isRefreshTeacherModule && initialiseModule(false);
  }, [isRefreshTeacherModule]);
};

export default useInitialiseCurrModule;

import { useState, useEffect } from "react";

import { Selected } from "../EditModulePage/EditModulePage";
import useStore from "store/store";
import { translation } from "constants/translation";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import DndContainer from "../DndContainer";
import DndItems from "../DndItems";
import DndItem from "../DndItem";

import { DashedButton, Lesson, Activity } from "./components";
import { Module } from "./Module";
import { TreeViewData } from "./types";

export interface Items {
  [key: string]: string[]; //items[lessonID] = activitiesId;
}

const ModuleTreeView = ({
  selected,
  handleOpen,
  handleClose,
}: {
  selected: Selected | null;
  handleOpen: (selected: Selected) => void;
  handleClose: () => void;
}) => {
  const [isSortingContainer, setIsSortingContainer] = useState<boolean>(false);

  const { teacherCurrentModule, isTeacherModuleLoading } = useStore(
    (state) => ({
      teacherCurrentModule: state.teacherCurrentModule,
      isTeacherModuleLoading: state.isTeacherModuleLoading,
    })
  );

  const module_: TreeViewData = {
    id: teacherCurrentModule.id.toString(),
    name: teacherCurrentModule.name,
    description: teacherCurrentModule.description,
    image: teacherCurrentModule.image,
    isPublished: teacherCurrentModule.is_published,
    type: "Module",
    index: 0,
  };

  const [lessons, setLessons] = useState<TreeViewData[]>([]);
  const [activities, setActivities] = useState<TreeViewData[]>([]);

  const [items, setItems] = useState<Items>({});
  const [containers, setContainers] = useState<string[]>([]);

  useEffect(() => {
    const lessons: TreeViewData[] = [];
    const activities: TreeViewData[] = [];
    const items: Items = {};
    const containers: string[] = [];
    teacherCurrentModule.lessons.forEach((lesson, lessonIndex) => {
      const lessonID = lesson.id.toString();
      lessons.push({
        id: lessonID,
        name: lesson.name,
        type: "Lesson",
        index: lessonIndex,
      });
      const activitiesId: string[] = [];
      lesson.activities.forEach((activity, activityIndex) => {
        activities.push({
          id: activity.id.toString(),
          name: activity.name,
          resourcetype: activity.resourcetype,
          isPublished: activity.is_published,
          type: "Activity",
          index: activityIndex,
          parentIndex: lessonIndex,
          parentId: lessonID,
        });
        activitiesId.push(activity.id.toString());
      });
      items[lessonID] = activitiesId;
      containers.push(lessonID);
    });
    setLessons(lessons);
    setActivities(activities);
    setItems(items);
    setContainers(containers);
  }, [teacherCurrentModule]);

  if (isTeacherModuleLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Module module_={module_} selected={selected} handleOpen={handleOpen} />
      <DndContainer
        items={items}
        setItems={setItems}
        containers={containers}
        setContainers={setContainers}
        setIsSortingContainer={setIsSortingContainer}
        handleClose={handleClose}
      >
        <DndItems items={containers}>
          {containers.map((lessonId, lessonIndex) => (
            <DndItem key={lessonId} id={lessonId}>
              <Lesson
                lessons={lessons}
                lessonId={lessonId}
                lessonIndex={lessonIndex}
                selected={selected}
                isSortingContainer={isSortingContainer}
                handleOpen={handleOpen}
              >
                <DndItems items={items[lessonId]}>
                  {items[lessonId].map((activityId, activityIndex) => (
                    <DndItem
                      key={activityId}
                      id={activityId}
                      disabled={isSortingContainer}
                    >
                      <Activity
                        activities={activities}
                        activityId={activityId}
                        activityIndex={activityIndex}
                        selected={selected}
                        isSortingContainer={isSortingContainer}
                        handleOpen={handleOpen}
                      />
                    </DndItem>
                  ))}
                </DndItems>
              </Lesson>
            </DndItem>
          ))}
          <DashedButton
            title={translation.add_lesson || "Add Lesson"}
            onClick={() =>
              handleOpen({
                id: "-1",
                index: 0,
                type: "Lesson",
                parentId: module_.id,
              })
            }
          />
        </DndItems>
      </DndContainer>
    </>
  );
};

export default ModuleTreeView;

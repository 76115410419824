import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  TextField,
  Typography,
  Box,
  Fade,
  Modal,
  MenuItem,
  useTheme,
  useMediaQuery,
  Paper,
  IconButton,
  InputBase,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import useStore from "store/store";

import Backdrop from "@mui/material/Backdrop";
import Image from "components/Image/Image";
import Logo from "images/logo-2-colour.png";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { createOrUpdateAttendance, getAttendanceToday } from "api/auth-api";
import useSnack from "hooks/useSnack";

const TeacherDashboardAttendanceModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [studentSearch, setStudentSearch] = useState<string>("");
  const [absenteeReasons, setAbsenteeReasons] = useState<any[]>([]);
  const [todayAttendances, setTodayAttendances] = useState<any[]>([]);

  const handleReasonChange = (childId: string, reason: string) => {
    setAbsenteeReasons((prev) => {
      const existingIndex = prev.findIndex((item) => item.profile === childId);
      if (existingIndex !== -1) {
        const updated = [...prev];
        updated[existingIndex] = { profile: childId, absent_reason: reason };
        return updated;
      } else {
        return [...prev, { profile: childId, absent_reason: reason }];
      }
    });
  };
  const { currGroup, setCurrGroup, groupList, firstName, role, profileDetails, currLanguage, refreshGroup } =
    useStore((state) => ({
      ...state,
      firstName: state.profileDetails.preferred_name,
      role: state.profileDetails.role,
    }));

  function formatDate(): string {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();

    function getOrdinalSuffix(day: number): string {
      if (day % 10 === 1 && day !== 11) return "st";
      if (day % 10 === 2 && day !== 12) return "nd";
      if (day % 10 === 3 && day !== 13) return "rd";
      return "th";
    }

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format the date
    return `${day}${getOrdinalSuffix(day)} ${monthNames[month]} ${year}`;
  }

  useEffect(() => {
    if (currGroup.id !== -1) {
      const fetchAttendance = async () => {
        const access = localStorage.getItem("access");
        if (access) {
          const response = await getAttendanceToday(currGroup.id, access);
          setTodayAttendances(response);
        }
      };

      fetchAttendance();
    }
  }, [currGroup]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: "16px",
            borderRadius: "10px",
            width: isLg ? "85%" : "40%",
          }}
        >
          <Box
            sx={{
              height: isLg ? "85vh" : "60vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "space-between",
                marginBottom: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography
                  onClick={() => { }}
                  sx={{
                    fontSize: isLg ? "20px" : "24px",
                    fontWeight: "600",
                  }}
                >
                  Attendance
                </Typography>
                <Box
                  sx={{
                    py: "8px",
                    px: "12px",
                    // borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                    border: "1px solid #C9C9C9",
                    borderRadius: '4px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: isLg ? "16px" : "auto",
                    }}
                  >
                    {formatDate()}
                  </Typography>
                  <TodayOutlinedIcon sx={{ color: "#91C6C5" }} />
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 4,
                }}
              >
                <TextField
                  sx={{
                    maxWidth: currGroup.name.length > 20 ? "50%" : "35%",
                    minWidth: currGroup.name.length > 20 ? "50%" : "35%",
                  }}
                  select
                  label="Class"
                  value={currGroup.name}
                  onChange={(event) => {
                    const foundGroup = groupList.find((group) => group.name === event.target.value);
                    if (foundGroup) {
                      setCurrGroup(foundGroup);
                    } else {
                      setCurrGroup(groupList[0]);
                    }
                  }}
                  variant="outlined"
                  size={isLg ? "small" : "medium"}
                  SelectProps={{
                    renderValue: (selected) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                          src={typeof currGroup?.image === "string" ? currGroup?.image : Logo}
                          alt=""
                        />
                        <span style={{ fontSize: isLg ? "12px" : "16px" }}>{selected as string}</span>
                      </div>
                    ),
                  }}
                >
                  {groupList.map((group) => (
                    <MenuItem
                      key={group.id}
                      value={group.name}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                        src={typeof group?.image === "string" ? group?.image : Logo}
                        alt=""
                      />
                      {group?.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Paper
                  component="form"
                  sx={{
                    py: 0.5,
                    px: 1.5,
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: currGroup.name.length > 20 ? "65%" : "70%",
                    borderRadius: "4px",
                    flex: 1,
                    boxShadow: "none",
                    border: "1px solid",
                    borderColor: "neutral.300",
                  }}
                  elevation={0}
                >
                  <IconButton sx={{ p: 0, pointerEvents: "none" }} aria-label="search">
                    <SearchOutlinedIcon fontSize="small" />
                  </IconButton>

                  <InputBase
                    sx={{
                      ml: 1.5,
                      flex: 1,
                      fontSize: isLg ? "12px" : "16px",
                      width: '100%',
                    }}
                    placeholder={"Enter Name"}
                    inputProps={{ "aria-label": "Search" }}
                    value={studentSearch === null ? "" : studentSearch}
                    onChange={(e) => {
                      setStudentSearch(e.target.value);
                    }}
                    onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                  />
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                height: "auto",
                overflow: "auto",
                marginBottom: 2,
              }}
            >
              {currGroup.children.map((child: any) => {
                return (
                  <ChildAbsent
                    key={child.id}
                    child={child}
                    onReasonChange={handleReasonChange}
                    todayAttendances={todayAttendances}
                  />
                );
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Button sx={{ width: "100%", textTransform: "none" }} onClick={handleClose}>
                <Typography
                  sx={{
                    fontSize: isLg ? "13px" : "16px",
                    fontWeight: '450',
                    color: '#5C9391'
                  }}
                >
                  Cancel
                </Typography>
              </Button>
              <Button
                variant="contained"
                sx={{ width: "100%", color: "white", textTransform: "none" }}
                onClick={() => {
                  if (absenteeReasons.length === 0) {
                    openSnack("No changes made", false);
                  }
                  const access = localStorage.getItem("access");
                  if (access) {
                    const updateMultipleAbsentees = async () => {
                      const response = await createOrUpdateAttendance(absenteeReasons, currGroup.id, access);
                    };
                    updateMultipleAbsentees();
                    openSnack("Attendance Updated!", true);
                    handleClose();
                  }

                }}
              >
                <Typography
                  sx={{
                    fontSize: isLg ? "13px" : "16px",
                    fontWeight: '450',

                  }}
                >
                  Submit Attendance
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const ChildAbsent = ({
  child,
  onReasonChange,
  todayAttendances,
}: {
  child: any;
  onReasonChange: (childId: any, reason: any) => void;
  todayAttendances: any;
}) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [absent, setAbsent] = useState<boolean>(false);
  const [absenteeReason, setAbsenteeReason] = useState<string>("");

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newReason = e.target.value;
    setAbsenteeReason(newReason);
    onReasonChange(child.id, newReason);
  };
  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));

  useEffect(() => {
    if (currGroup.id != -1) {
      todayAttendances.map((attendance: any) => {
        if (attendance.profile == child.id) {
          setAbsent(true);
          setAbsenteeReason(attendance.absent_reason);
        }
      });
    }
  }, [currGroup, todayAttendances]);

  return (
    <Box
      sx={{ backgroundColor: "#F2F2F2", my: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: 1,
          py: "8px",
          px: "12px",
        }}
      >
        {/* image and name */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Image
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
            objectFit="cover"
            src={typeof child?.image === "string" ? child?.image : Logo}
            alt=""
          />
          <Typography
            sx={{
              fontSize: isLg ? "10px" : "16px",
              fontWeight: "400",
            }}
          >
            {child.full_name}
          </Typography>
        </Box>
        {/* end of image and name */}
        <Box sx={{ gap: 1, display: "flex", alignItems: "center" }}>
          <Chip
            onClick={() => {
              setAbsent(false);
              handleReasonChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
            }}
            label="Present"
            variant="outlined"
            sx={{
              backgroundColor: absent ? "#C9C9C9" : "#5CCA9C",
              color: "white",
              // maxHeight: isLg ? "20px" : "auto",
              maxHeight: '32px',
              "&:hover": {
                backgroundColor: "#5CCA9C",
              },
              fontSize: isLg ? "10px" : "13px",
            }}
            icon={
              <DoneIcon
                sx={{
                  color: "white !important",
                  fontSize: "13px",
                }}
              />
            }
          />
          <Chip
            onClick={() => {
              setAbsent(true);
              handleReasonChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
            }}
            label="Absent"
            variant="outlined"
            sx={{
              backgroundColor: absent ? "#FF5D53" : "#C9C9C9",
              maxHeight: '32px',
              color: "white",
              "&:hover": {
                backgroundColor: absent ? "#FF5D53" : "#C9C9C9",
              },
              fontSize: isLg ? "10px" : "13px",
            }}
            icon={
              <CloseIcon
                sx={{
                  color: "white !important",
                  fontSize: "13px",
                }}
              />
            }
          />
        </Box>
      </Box>
      {absent && (
        <TextField
          sx={{
            width: "100%", p: "10px", backgroundColor: "white",


          }}
          InputProps={{ sx: { height: "40px" } }}

          value={absenteeReason}
          onChange={handleReasonChange}
          placeholder="Reason for being Absented"
        />
      )}
    </Box>
  );
};
export default TeacherDashboardAttendanceModal;

import { Avatar, Box, Typography } from "@mui/material";
import { API_Current_Lesson } from "types/profile";
import { LessonProgressComponent } from "./CommonButtons";

const LessonCardContent = ({
  lesson,
  mediaHeight,
  currLessonWeight,
}: {
  lesson: API_Current_Lesson;
  mediaHeight: string;
  currLessonWeight: number;
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "2px",
        width: "100%",
        height: mediaHeight,
        bgcolor: "rgba(0, 0, 0, 0.15)",
        color: "white",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            backgroundColor: "primary.light",
            padding: "0.5rem",
            borderRadius: "5px",
            fontSize: ".75rem",
            fontWeight: "500",
            display: "flex",
            gap: 0.875,
            mr: "auto",
            mb: 0.5,
            color: "txt.dark",
          }}
          key={`${lesson.id}-module`}
        >
          <Avatar
            alt={lesson.module[0]}
            src={lesson.module_icon}
            sx={{
              width: "1rem",
              height: "1rem",
            }}
            imgProps={{
              style: {
                objectFit: "contain",
              },
            }}
          />
          {lesson.module}
        </Box>

        <Typography
          sx={{
            color: "white",
            fontSize: "1.25rem",
            fontWeight: "500",
            filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5))",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 2,
            lineClamp: 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {lesson.project_name}
        </Typography>
      </Box>

      <LessonProgressComponent
        currLessonWeight={currLessonWeight}
        lesson={lesson}
      />
    </Box>
  );
};

export default LessonCardContent;

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Child_Progress_Report, Query_Data } from "types/project";
import ChildProgressReportCard from "containers/ChildrenPortfolioPage/Screens/ChildProgressReportComponents/ChildProgressReportCard";
import { getChildProgressReportList } from "api/report-api";
import useStore from "store/store";

// Filter
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import CustomButton from "components/Button/Button";
import ClassFilterButton from "../TeacherArchiveCommonComponent/ClassFilterButton";
import ChildFilterButton from "../TeacherArchiveCommonComponent/ChildFilterButton";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import {
  ClickSearchToBegin,
  MAX_QUERY,
  MaxQueryAdvice,
  teacherArchiveCardStyle,
} from "../TeacherArchiveCommonComponent/TeacherArchiveCommonComponent";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const TeacherArchiveProgressReportScreen = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  // Search
  const [isLoading, setIsLoading] = useState(false);
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });
  const [selectedClassSlugs, setSelectedClassSlugs] = useState<string[]>([]);
  const [selectedChildIds, setSelectedChildIds] = useState<number[]>([]);
  const [childProgressReportList, setChildProgressReportList] = useState<
    Child_Progress_Report[]
  >([]);

  const populateProgressReportList = async () => {
    setIsLoading(true);
    const res = await getChildProgressReportList({
      language: currLanguage,
      selectedClassSlugs: selectedClassSlugs,
      selectedChildIds: selectedChildIds,
      queryData: queryData,
      isTeacherArchive: true,
    });

    if (typeof res !== "string") {
      setChildProgressReportList(res);
    }
    setIsLoading(false);
  };

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      isInitialized ||
      selectedClassSlugs.length === 0 ||
      selectedChildIds.length === 0
    )
      return;

    populateProgressReportList();
    setIsInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClassSlugs, selectedChildIds]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
          mb: 2,
          width: {
            xs: "100%",
            xl: "80%",
          },
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <SearchBar
            queryData={queryData}
            setQueryData={setQueryData}
            maxWidth={9999}
          />
          <CustomButton
            buttonText={isXs ? "" : "Search"}
            onClick={() => {
              populateProgressReportList();
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: {
              xs: 2,
              sm: 1,
            },
            justifyContent: "flex-start",
            flexWrap: {
              xs: "wrap",
              sm: "nowrap",
            },
            mb: 1,
          }}
        >
          <StartAtDateField
            queryData={queryData}
            setQueryData={setQueryData}
            // maxWidth={150}
          />
          <EndAtDateField
            queryData={queryData}
            setQueryData={setQueryData}
            // maxWidth={150}
          />
          <ClassFilterButton
            selectedClassSlugs={selectedClassSlugs}
            setSelectedClassSlugs={setSelectedClassSlugs}
          />
          <ChildFilterButton
            selectedClassSlugs={selectedClassSlugs}
            selectedChildIds={selectedChildIds}
            setSelectedChildIds={setSelectedChildIds}
          />
        </Box>
      </Box>
      {isLoading ? (
        <LoadingIndicator />
      ) : childProgressReportList.length === 0 ? (
        <ClickSearchToBegin />
      ) : (
        <>
          <Box sx={teacherArchiveCardStyle}>
            {childProgressReportList.map((childProgressReport) => {
              return (
                <ChildProgressReportCard
                  key={`child-progress-report-${childProgressReport.id}`}
                  childProgressReport={childProgressReport}
                  isChildPortfolio
                  // handleLeftButton={() => {
                  //   console.log('')
                  // }}
                  handleOpenDialog={() => {
                    console.log('hello')
                  }}
                />
              );
            })}
          </Box>
          {childProgressReportList.length === MAX_QUERY && (
            <MaxQueryAdvice maxQuery={MAX_QUERY} />
          )}
        </>
      )}
    </>
  );
};

export default TeacherArchiveProgressReportScreen;

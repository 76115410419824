import { Stack, TextField } from "@mui/material";
import Button from "components/Button/Button";
import { useEffect, useState } from "react";
import { createTestimonial, updateTestimonial } from "api/profile-api";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  API_Testimonial,
  API_Testimonial_Create,
} from "types/profile";

const TestimonialForm = ({
  testimonial,
  handleClose,
}: {
  testimonial: API_Testimonial;
  handleClose: () => void;
}) => {
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );
  const openSnack = useSnack();

  const [formData, setFormData] = useState<API_Testimonial>(testimonial);
  const [isCreate, setIsCreate] = useState<boolean>(testimonial.id === -1);

  useEffect(() => {
    setFormData(testimonial);
    setIsCreate(testimonial.id === -1);
  }, [testimonial]);

  const handleSubmit = async () => {
    //Register auth account
    const updatedFormData: API_Testimonial_Create = {
      ...formData,
      author: formData.author.id,
    };
    const response: API_Testimonial | string = isCreate
      ? await createTestimonial(updatedFormData)
      : await updateTestimonial(updatedFormData);
    if (typeof response === "string") {
      openSnack(response, false);
    } else {
      if (isCreate) {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          testimonials: [response, ...visitedProfileDetails.testimonials!],
        });
      } else {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          testimonials: visitedProfileDetails.testimonials?.map(
            (testimonial_: API_Testimonial) => {
              return testimonial_.id === testimonial.id
                ? response
                : testimonial_;
            }
          ),
        });
      }
      openSnack(translation.updateSuccess, true);
      handleClose();
    }
  };

  return (
    <Stack
      component="form"
      sx={{ margin: "auto", my: "16px", alignItems: "center" }}
      spacing={1}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={1} direction="column">
        <TextField
          sx={{ width: 400 }}
          fullWidth
          required
          label={translation.title}
          value={formData.name}
          onChange={(event) => {
            setFormData((prev: API_Testimonial) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          required
          label={translation.description}
          value={formData.description}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              description: event.target.value,
            }));
          }}
        />
      </Stack>

      <Button
        buttonText={
          isCreate
            ? translation.submit || "Submit"
            : translation.update || "Update"
        }
        style={{ marginTop: "20px" }}
        onClick={handleSubmit}
      />
    </Stack>
  );
};

export default TestimonialForm;

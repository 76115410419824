import { Box } from "@mui/material";
import Image from "../../Image/Image";
import { PDFInfoType } from "./constant";
import HeadBackground from "images/children-portfolio-v2/head-background.png";
import HandsBackground from "images/children-portfolio-v2/hands-background.png";
import HeartBackground from "images/children-portfolio-v2/heart-background.png";
import DevelopmentalGoal from "./DevelopmentalGoalComponents/DevelopmentalGoal";
import { API_Fundamental, API_Milestone } from "types/project";
import {
  HEART_TEXT,
  HANDS_TEXT,
} from "containers/PortfolioPage/portfolioPageConstants";
import Page from "./Page";
import { useMemo } from "react";

export const calculateItemArrangement = (
  developmentalGoals: API_Fundamental[],
  isTemplate = false
) => {
  const pages: API_Fundamental[][] = [];
  let currentPage: API_Fundamental[] = [];
  const maxPageValues = 23;
  const normalizer = 4;
  let currentPageSize = 0;

  const newDevelopmentalGoals: API_Fundamental[] = [];

  developmentalGoals.forEach((developmentalGoal) => {
    let start = 0;
    const step = maxPageValues - (normalizer + 2);
    const totalMilestones = developmentalGoal.milestones.length;

    do {
      const end = start + step;
      const devGoalSplit = {
        ...developmentalGoal,
        milestones: developmentalGoal.milestones.slice(start, end),
        previous: start > 0,
        next: end < totalMilestones,
        page: Math.floor(start / step),
      };
      newDevelopmentalGoals.push(devGoalSplit);
      start = end;
    } while (start < totalMilestones);
  });

  newDevelopmentalGoals.forEach((developmentalGoal) => {
    const addition = developmentalGoal.milestones.length
      ? developmentalGoal.milestones.length + normalizer
      : normalizer;

    if (currentPageSize + addition > maxPageValues) {
      pages.push(currentPage);
      currentPageSize = 0;
      currentPage = [];
    }

    currentPageSize += addition;
    currentPage.push(developmentalGoal);
  });

  if (currentPage.length > 0) {
    pages.push(currentPage);
  }

  return pages;
};

interface DevelopmentalGoalPageProps {
  scale: number;
  PDFInfo: PDFInfoType;
  tag: string;
}

const DevelopmentalGoalPage = ({
  scale,
  PDFInfo,
  tag,
}: DevelopmentalGoalPageProps) => {
  const achievedMilestones = useMemo(
    () =>
      PDFInfo.childAllAchievedMilestoneTrackers
        .filter((tracker) => tracker.status && tracker.milestone != null)
        .map((tracker) => tracker.milestone),
    [PDFInfo.childAllAchievedMilestoneTrackers]
  );

  const backgroundImage =
    tag.toLowerCase() === HEART_TEXT.toLowerCase()
      ? HeartBackground
      : tag.toLowerCase() === HANDS_TEXT.toLowerCase()
      ? HandsBackground
      : HeadBackground;

  const relatedDevelopmentalGoals = useMemo(() => {
    return PDFInfo.developmentalGoalList.filter(
      (developmentalGoal) =>
        developmentalGoal.tag.toLowerCase() === tag.toLowerCase()
    );
  }, [PDFInfo.developmentalGoalList, tag]);

  const consolidatedDevGoals = useMemo(() => {
    const _consolidatedDevGoals: API_Fundamental[] = [];

    relatedDevelopmentalGoals.forEach((devGoal) => {
      const newDevGoal = {
        ...devGoal,
        achievedMilestones: [] as API_Milestone[],
      };

      newDevGoal.achievedMilestones = newDevGoal.milestones.filter(
        (milestone) => {
          return achievedMilestones.includes(milestone.id);
        }
      );

      if (devGoal.sub_fundamentals && devGoal.sub_fundamentals.length > 0) {
        const subFundamentals = devGoal.sub_fundamentals?.sort(
          (a: any, b: any) => a.priority - b.priority
        );

        subFundamentals.forEach((subDevGoal) => {
          subDevGoal.milestones.forEach((milestone) => {
            if (achievedMilestones.includes(milestone.id)) {
              newDevGoal.achievedMilestones.push(milestone);
            }
          });
        });
      }

      newDevGoal.milestones = newDevGoal.achievedMilestones;
      _consolidatedDevGoals.push(newDevGoal);
    });

    return _consolidatedDevGoals;
  }, [achievedMilestones, relatedDevelopmentalGoals]);

  const pages = useMemo(() => {
    const devGoalsWithMilestones = consolidatedDevGoals.filter(
      (devGoal) => devGoal.milestones.length > 0
    );
    return calculateItemArrangement(devGoalsWithMilestones);
  }, [consolidatedDevGoals]);

  if (pages.length === 0) {
    return null;
  }

  return (
    <>
      {pages.map((devGoals, index) => {
        if (devGoals.length === 0) {
          return null;
        }

        return (
          <Page key={index}>
            <Image src={backgroundImage} alt="" />

            <Box
              sx={{
                position: "absolute",
                top: `${100 * scale}px`,
                left: 0,
                width: "100%",
                paddingRight: `${20 * scale}px`,
                paddingLeft: `${40 * scale}px`,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {devGoals.map((devGoal, devGoalIndex, devGoalArray) => {
                const devGoalIds = [devGoal.id];
                if (
                  devGoal.sub_fundamentals &&
                  devGoal.sub_fundamentals.length > 0
                ) {
                  devGoalIds.push(
                    ...devGoal.sub_fundamentals.map(
                      (subFundamental) => subFundamental.id
                    )
                  );
                }

                if (devGoal.main_fundamental) {
                  devGoalIds.push(devGoal.main_fundamental);
                }

                const devGoalObservations = PDFInfo.observationList.filter(
                  (observation) =>
                    devGoalIds.includes(observation.developmental_goal)
                );

                if (devGoal.milestones.length > 0) {
                  return (
                    <DevelopmentalGoal
                      key={devGoal.id}
                      devGoal={devGoal}
                      previousDevGoal={
                        devGoalIndex === 0
                          ? undefined
                          : devGoalArray[devGoalIndex - 1]
                      }
                      observation={
                        (devGoal as any).page <= devGoalObservations.length
                          ? devGoalObservations[(devGoal as any).page]
                          : undefined
                      }
                      scale={scale}
                      milestonesAchieved={
                        PDFInfo.childAllAchievedMilestoneTrackers
                      }
                    />
                  );
                }
              })}
            </Box>
          </Page>
        );
      })}
    </>
  );
};

export default DevelopmentalGoalPage;

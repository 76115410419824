import { Page, Document, Font, Image, Text, View } from "@react-pdf/renderer";
import FuturaBkBT from "fonts/FuturaBkBT.ttf";
import FuturaMediumBT from "fonts/FuturaMediumBT.ttf";
import FuturaLtBT from "fonts/FuturaLtBT.ttf";
import TrajanPro3Regular from "fonts/TrajanPro3Regular.ttf";

import {
  imageSrc,
  styles,
} from "components/ProjectReportPDF/ProjectReportPDF";
import TrehausCertificateBackground from "images/teacher-certificate/certz-to-test.png";

//Register Font
Font.register({
  family: "FuturaLtBT",
  fonts: [
    {
      src: FuturaLtBT,
    },
  ],
});

Font.register({
  family: "FuturaMediumBT",
  fonts: [
    {
      src: FuturaMediumBT,
    },
  ],
});

Font.register({
  family: "FuturaBkBT",
  fonts: [
    {
      src: FuturaBkBT,
    },
  ],
});

Font.register({
  family: "TrajanPro3Regular",
  fonts: [
    {
      src: TrajanPro3Regular,
    },
  ],
});

const TrehausCertificatePDF = ({
  profileFullName,
  certificateBackgroundImage,
  certificateBackgroundWidth,
  certificateBackgroundHeight,
  currentLanguage,
  issueDate,
}: {
  profileFullName: string;
  certificateBackgroundImage: string | undefined;
  certificateBackgroundWidth: number | undefined;
  certificateBackgroundHeight: number | undefined;
  currentLanguage: string;
  issueDate: string;
}) => {
  return (
    <Document title={profileFullName} language={currentLanguage}>
      <Page
        size={{
          width: 842,
          height: 595,
        }}
        style={styles.body}
        wrap={false}
      >
        <View style={styles.main} wrap={false}>
          <Image
            src={imageSrc(TrehausCertificateBackground)}
            style={styles.background}
          />

          <Text
            style={{
              fontFamily: "TrajanPro3Regular",
              fontSize: 42,
              lineHeight: 1.25,
              textAlign: "left",
              position: "absolute",
              top: 287,
              left: 133,
              right: 266,
              color: "black",
            }}
          >
            {profileFullName}
          </Text>

          <Text
            style={{
              fontFamily: "FuturaMediumBT",
              fontSize: 14,
              lineHeight: 1.25,
              textAlign: "left",
              position: "absolute",
              top: 377,
              left: 133,
              right: 266,
              color: "black",
            }}
          >
            on {issueDate}.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default TrehausCertificatePDF;

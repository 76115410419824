export const removeHtmlTagsAndStyles = (htmlText: string) => {
  let textWithoutTags = htmlText.replace(/<[^>]*>/g, "");
  textWithoutTags = textWithoutTags.replace(/&nbsp;/g, " ");
  return textWithoutTags;
};

export const removeHtmlTagsWithLiAsListAndStyles = (htmlText: string) => {
  let textWithoutTags = htmlText.replace(/<(?!li)[^>]*>/g, "");
  textWithoutTags = textWithoutTags.replace(/&nbsp;/g, " ");

  const liRegex = /<li[^>]*>(.*?)<\/li>/g;
  const liMatches = htmlText.match(liRegex);
  const liArray = liMatches
    ? liMatches.map((li) => li.replace(/<[^>]*>/g, ""))
    : [textWithoutTags];

  return liArray;
};

export const removeHtmlTagsAndExtractImages = (htmlText: string) => {
  const imageSrcs: string[] = [];
  const imageRegex = /<img[^>]+src="([^">]+)"/g;

  let match;
  while ((match = imageRegex.exec(htmlText)) !== null) {
    const src = match[1];
    imageSrcs.push(src);
  }

  let textWithoutTags = htmlText.replace(/<[^>]*>/g, "");
  textWithoutTags = textWithoutTags.replace(/&nbsp;/g, " ");

  return { textWithoutTags, imageSrcs };
};




export function extractText(html: string): any {
  const imageSrcs: string[] = [];
  const imageRegex = /<img[^>]+src="([^">]+)"/g;
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Extract image sources
  let match;
  while ((match = imageRegex.exec(html)) !== null) {
    const src = match[1];
    imageSrcs.push(src);
  }

  // Array to hold extracted text
  let result: any[] = [];

  // Iterate through the body's children
  const bodyChildren = Array.from(doc.body.childNodes);

  for (let child of bodyChildren) {
    if (child.nodeType === Node.ELEMENT_NODE) { // Ensure we're dealing with an element node
      const element = child as HTMLElement;
      switch (element.tagName.toLowerCase()) {
        case 'p':
          const pContent = element.textContent?.trim();
          if (pContent) {
            result.push(pContent);
          }
          break;
        case 'ul':
          let ulArray: string[] = [];
          const liElements = element.getElementsByTagName('li');
          for (let liTag of Array.from(liElements)) {
            ulArray.push(liTag.textContent?.trim() ?? '');
          }
          result.push(ulArray);
          break;
        case 'ol':
          let orderedListObject: Record<string, string> = {};
          const olLiElements = element.getElementsByTagName('li');
          for (let i = 0; i < olLiElements.length; i++) {
            const liTag = olLiElements[i];
            orderedListObject[`${i + 1}`] = liTag.textContent?.trim() ?? '';
          }
          result.push(orderedListObject);
          break;
      }
    }
  }

  return { result, imageSrcs };
}

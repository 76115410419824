import { ReactNode } from "react";
import {
  verticalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";

const DndItems = ({
  items,
  children,
}: {
  items: string[];
  children: ReactNode;
}) => {
  return (
    <SortableContext items={items} strategy={verticalListSortingStrategy}>
      {children}
    </SortableContext>
  );
};

export default DndItems;

import React from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
} from "recharts";
import { API_Teacher_Module_Visualization_Rating_List } from "types/teacher";
import { responsiveContainerStyle } from "./VisualizationPage";
import { translation } from "constants/translation";
import { useTheme } from "@mui/material";

const ModuleRatingChart = ({
  data,
}: {
  data: API_Teacher_Module_Visualization_Rating_List[];
}) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer {...responsiveContainerStyle}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="module_name" />
        <YAxis domain={[0, 5]} label={translation.ratings} />
        <Tooltip />
        {/* <Legend
          formatter={(value, entry, index) => {
            return (
              <span style={{ color: entry.color }}>{`Number of rating`}</span>
            );
          }}
        /> */}
        <Bar dataKey="avg_rating" fill={theme.palette.custom.lightOrange} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ModuleRatingChart;

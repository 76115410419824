import React, { useState, useRef } from "react";

import { Backdrop, Box, Fade, Modal, Typography, SxProps } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import Button from "components/Button/Button";
import { translation } from "constants/translation";
import { LessonType } from "./constant";

import LT1Image from "./LT1Image";
import LT2Images from "./LT2Images";
import LT3Images from "./LT3Images";
import LT4Images from "./LT4Images";
import LT5Images from "./LT5Images";

import useScale from "hooks/useScale";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lesson: LessonType;
  setTemplateNo: (templateNo: number) => void;
  selectedTemplateNo: number;
};

const SelectTemplateModal = ({
  open,
  setOpen,
  lesson,
  setTemplateNo,
  selectedTemplateNo,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const thumbnailRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(0);
  const [thumbnailScale, setThumbnailScale] = useState<number>(0);

  useScale(ref, setScale);
  useScale(thumbnailRef, setThumbnailScale);

  const TemplateProps = {
    scale,
    lesson,
  };

  const Templates = [
    { image: <LT1Image {...TemplateProps} />, maxImages: 1 },
    { image: <LT2Images {...TemplateProps} />, maxImages: 2 },
    { image: <LT3Images {...TemplateProps} />, maxImages: 3 },
    { image: <LT4Images {...TemplateProps} />, maxImages: 4 },
    { image: <LT5Images {...TemplateProps} />, maxImages: 5 },
  ];

  const handleClose = () => setOpen(false);

  const [selected, setSelected] = useState<number>(selectedTemplateNo);

  const maxWidth = (1440 / 810) * 350;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "950px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
          }}
        >
          <Box
            ref={ref}
            sx={{
              m: "auto",
              maxWidth: `${maxWidth}px`,
            }}
          >
            {Templates[selected - 1].image}
          </Box>

          <Typography
            sx={{
              textAlign: "center",
              m: "12px 0 24px",
            }}
          >
            Template for {Templates[selected - 1].maxImages} Image
            {Templates[selected - 1].maxImages > 1 ? "s" : ""}
          </Typography>

          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              mb: "8px",
            }}
          >
            {translation.pickTemplate}
          </Typography>

          <Box
            sx={{
              display: { xs: "grid", sm: "flex" },
              gridTemplateColumns: "1fr 1fr",
              gap: "4px",
              flexWrap: "wrap",
              maxHeight: "204px",
              overflowY: "auto",
            }}
          >
            {Templates.map((template, index) => {
              if (lesson.images.length < template.maxImages) return null;

              const isSelected = selected === index + 1;

              const iconStyle: SxProps = {
                position: "absolute",
                top: 4,
                right: 4,
                color: "txt.secondary",
                cursor: "pointer",
                opacity: isSelected ? 1 : 0,
                zIndex: 1,
              };

              return (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    cursor: "pointer",
                    opacity: isSelected ? 1 : 0.7,
                    transition: "all 200ms linear",
                    "&:hover": {
                      opacity: 1,
                      transition: "all 200ms linear",
                    },
                    borderRadius: "10px",
                    overflow: "hidden",
                    border: "3px solid",
                    borderColor: isSelected ? "txt.secondary" : "transparent",
                    pointerEvents: isSelected ? "none" : "auto",
                  }}
                  onClick={() => setSelected(index + 1)}
                >
                  {isSelected ? (
                    <CheckBoxIcon className="icon" sx={iconStyle} />
                  ) : (
                    <CheckBoxOutlineBlankIcon className="icon" sx={iconStyle} />
                  )}

                  <Box
                    ref={thumbnailRef}
                    sx={{
                      width: { xs: "auto", sm: "167px" },
                    }}
                  >
                    {React.cloneElement(template.image, {
                      scale: thumbnailScale,
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box
            sx={{
              mt: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              buttonText={translation.cancel || "Cancel"}
              arrow={false}
              style={{
                fontSize: "14px",
                backgroundColor: "transparent",
                color: "var(--txt-secondary)",
                padding: "0",
                marginRight: "20px",
              }}
              onClick={handleClose}
            />

            <Button
              arrow={false}
              style={{
                fontSize: "14px",
                backgroundColor: "var(--txt-secondary)",
              }}
              buttonText={translation.done || "Done"}
              onClick={() => {
                setTemplateNo(selected);
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SelectTemplateModal;

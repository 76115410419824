import { translation } from "constants/translation";
import LearningMomentIcon from "images/project-navigation/learning-moment-icon.png";
import LessonPlanIcon from "images/project-navigation/lesson-plan-icon.png";
import LittleWorkIcon from "images/project-navigation/little-work-icon.png";
import MilestoneCheckIcon from "images/project-navigation/milestone-check-icon.png";
import PhotoAlbumIcon from "images/project-navigation/photo-album-icon.png";
import ProjectOverviewIcon from "images/project-navigation/project-overview-icon.png";
import ProjectSummaryIcon from "images/project-navigation/project-summary-icon.png";
import ResourceGuideIcon from "images/project-navigation/resource-guide-icon.png";
import TeachingMaterialIcon from "images/project-navigation/teaching-material-icon.png";
import WhatDoPeopleLikeIcon from "images/project-navigation/what-people-like-icon.png";

export const iconStyle = {
  cursor: "pointer",
  color: "txt.secondary",
  // transition: "all 100ms ease-in-out",
  // "&:hover": {
  //   color: "primary.main",
  //   transition: "all 100ms ease-in-out",
  // },
  borderRadius: "50%",
  // marginRight: 1,
};

export const EXTENSIONS = [
  ".png",
  ".jpeg",
  ".jpg",
  ".pdf",
  ".mp3",
  ".mp4",
  ".mov",
];

export const OVERVIEW = "Overview";
export const UNIT_GUIDE = "Resource Guide";
export const LESSON_PLANS = "Lesson Plans";
export const LEARNING_MOMENTS = "Learning Moments";
export const TEACHING_MATERIALS = "Teaching Materials";
export const COMPLETED_WORKS = "Little Works";
export const MILESTONE_CHECK = "Milestone Check";
export const RESOURCES = "Resources";
export const PHOTO_ALBUM = "Photo Album";
export const PROJECT_SUMMARY = "End";
export const COMMUNITY_SHARING = "Community Sharing";
export const WHAT_PEOPLE_LIKE = "What People Like";
export const DISCUSSION = "Discussion";

// export const projectLessonActivityTabs: string[] =  [
//   OVERVIEW,
//   LESSON_PLANS,
//   LEARNING_MOMENTS,
//   TEACHING_MATERIALS,
//   // MILESTONE_CHECK,
//   PHOTO_ALBUM,
//   PROJECT_SUMMARY,
//   WHAT_PEOPLE_LIKE,
// ];

export const getProjectLessonActivityTabs = (isLittleProject: boolean) => {
  const tabs = isLittleProject
    ? [
        OVERVIEW,
        UNIT_GUIDE,
        LESSON_PLANS,
        LEARNING_MOMENTS,
        TEACHING_MATERIALS,
        COMPLETED_WORKS,
        PHOTO_ALBUM,
        PROJECT_SUMMARY,
        WHAT_PEOPLE_LIKE,
      ]
    : [
        OVERVIEW,
        UNIT_GUIDE,
        LESSON_PLANS,
        LEARNING_MOMENTS,
        TEACHING_MATERIALS,
        COMPLETED_WORKS,
        MILESTONE_CHECK,
        PHOTO_ALBUM,
        PROJECT_SUMMARY,
        WHAT_PEOPLE_LIKE,
      ];
  return tabs;
};

export const translatedProjectLessonActivityTabIcons: {
  [key: string]: string;
} = {
  [OVERVIEW]: ProjectOverviewIcon,
  [UNIT_GUIDE]: ResourceGuideIcon,
  [LESSON_PLANS]: LessonPlanIcon,
  [LEARNING_MOMENTS]: LearningMomentIcon,
  [TEACHING_MATERIALS]: TeachingMaterialIcon,
  [COMPLETED_WORKS]: LittleWorkIcon,
  [MILESTONE_CHECK]: MilestoneCheckIcon,
  [PHOTO_ALBUM]: PhotoAlbumIcon,
  [PROJECT_SUMMARY]: ProjectSummaryIcon,
  [WHAT_PEOPLE_LIKE]: WhatDoPeopleLikeIcon,
};

export const translatedProjectLessonActivityTabs: { [key: string]: string } = {
  [OVERVIEW]: translation.overview,
  [UNIT_GUIDE]: translation.unitGuide,
  [LESSON_PLANS]: translation.plan,
  [LEARNING_MOMENTS]: translation.learningMoments,
  [TEACHING_MATERIALS]: translation.teachingMaterials,
  [COMPLETED_WORKS]: translation.completedWorks,
  [MILESTONE_CHECK]: translation.milestoneCheck,
  [PHOTO_ALBUM]: translation.photoAlbum,
  [PROJECT_SUMMARY]: translation.projectSummary,
  [WHAT_PEOPLE_LIKE]: translation.whatPeopleLike,
};

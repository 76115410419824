import axios from "api/axiosConfig";
import { AxiosError } from "axios";
import {
  API_Create_School,
  API_Edit_School,
  API_School,
  API_School_Detail,
  API_School_Simple,
  API_School_with_Profiles,
} from "types/school";

export const getAllSchoolForRegister = async () => {
  const res = await axios.get("/en/profile/school/registerlist");
  const data: API_School_Simple[] = await res.data;
  return data;
};

export const getAllSchool = async () => {
  const res = await axios.get("/en/profile/school/list");
  const data: API_School[] = await res.data;
  return data;
};

export const getAllSchoolWithStripeInfo = async () => {
  const res = await axios.get("/en/profile/school/list-with-stripe-info");
  const data: API_School[] = await res.data;
  return data;
};

export const getSchoolByID = async (schoolID: string) => {
  const res = await axios.get(`/en/profile/school/${schoolID}`);
  const data: API_School = await res.data;
  return data;
};

export const getSchoolBySlug = async (schoolSlug: string) => {
  try {
    const res = await axios.get(`/en/profile/school/slug/${schoolSlug}`);
    const data: API_School_Detail = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getSchoolwithProfiles = async (
  schoolSlug: string,
  accessToken: string | null
) => {
  const res = await axios.get(`/en/profile/getbyschool/${schoolSlug}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_School_with_Profiles = await res.data;
  return data;
};

export const createSchool = async (
  schoolData: API_Create_School,
  accessToken: string | null
) => {
  try {
    const res = await axios.post("/en/profile/school/create", schoolData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_School = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editSchool = async (
  schoolID: string,
  schoolData: API_Edit_School,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(
      `/en/profile/school/${schoolID}/update`,
      schoolData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_School = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteSchool = async (schoolID: string) => {
  try {
    const res = await axios.delete(`/en/profile/school/${schoolID}/delete`);
    const data: API_School = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getNumberOfActiveUser = async (schoolID: number) => {
  try {
    const res = await axios.get(
      `/en/profile/school/${schoolID}/get-active-user-number`
    );
    const data: number = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

import { useState, useEffect, Fragment, useRef } from "react";
import {
  Box,
  Divider,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { translation } from "constants/translation";
import AnnouncementCard from "./DashboardAnnouncementCard";
import useStore from "store/store";
import { getAnnouncementList } from "api/announcement-api";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import moment from "moment";
import { UpdateLastSeenAnnouncementTime } from "containers/AnnouncementsPage/AnnouncementComponents";
import Image from "components/Image/Image";
import AnnouncementHeaderBackground from "images/dashboard/announcement-header.png";
import AnnouncementBodyBackground from "images/dashboard/announcement-body.png";

const Sidebar = ({ sx }: { sx: SxProps }) => {
  const {
    currLanguage,
    schoolId,
    parentDashboardUnseenAnnouncementCount,
    setParentDashboardUnseenAnnouncementCount,
    announcementList,
    setAnnouncementList,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    schoolId: state.profileDetails.school?.id,
    parentDashboardUnseenAnnouncementCount:
      state.parentDashboardUnseenAnnouncementCount,
    setParentDashboardUnseenAnnouncementCount:
      state.setParentDashboardUnseenAnnouncementCount,
    announcementList: state.announcementList,
    setAnnouncementList: state.setAnnouncementList,
  }));
  const theme = useTheme();
  const isLessThan1200 = useMediaQuery(theme.breakpoints.down("lg"));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [imageInfo, setImageInfo] = useState({
    width: 0,
    height: 0,
  });
  // console.log("announcementList; ", announcementList);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (schoolId === undefined || schoolId === -1) return;

    const fetchAnnouncements = async () => {
      setIsLoading(true);

      const announcementList = await getAnnouncementList(
        currLanguage,
        schoolId
      );

      if (typeof announcementList !== "string") {
        setAnnouncementList(announcementList);
        setParentDashboardUnseenAnnouncementCount(
          announcementList.filter(
            (announcement) =>
              announcement.is_seen !== true &&
              announcement.is_responded !== true
          ).length
        );
      }

      setIsLoading(false);
    };

    fetchAnnouncements();
  }, [currLanguage, schoolId]);

  const updateImageInfo = () => {
    if (imgRef.current) {
      const { offsetWidth: width, offsetHeight: height } = imgRef.current;
      // console.log("width: ", width);
      // console.log("height: ", height);
      setImageInfo({ width: width / 4, height: height / 2 });
    }
  };
  useEffect(() => {
    updateImageInfo();
  }, []);

  const activeAnnouncementList = announcementList.slice(0, 10);

  return (
    <Box
      sx={{
        position: "sticky",
        top: "calc(64px + 32px)",
        backgroundColor: isLessThan1200 ? "white" : "transparent",
        border: `${isLessThan1200 ? 1 : 0}px solid #C4C4C4`,
        borderRadius: "10px",
        p: 3,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxWidth: "350px",
        maxHeight: "calc(100vh - (64px + 32px + 32px))",
        ...sx,
      }}
    >
      {!isLessThan1200 && (
        <>
          <Image
            ref={imgRef}
            src={AnnouncementHeaderBackground}
            alt="background"
            objectFit="contain"
            style={{
              opacity: 1,
              objectPosition: "top",
              position: "absolute",
              height: "initial",
              top: 0,
              right: 0,
              zIndex: 1,
            }}
            onLoad={updateImageInfo}
          />
          <Image
            src={AnnouncementBodyBackground}
            alt="background"
            objectFit="fill"
            style={{
              opacity: 1,
              position: "absolute",
              bottom: -10,
              right: 0,
              zIndex: -2,
            }}
          />
        </>
      )}

      <UpdateLastSeenAnnouncementTime />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box sx={{ width: imageInfo.width, height: imageInfo.height }} />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "1.60rem",
          }}
        >
          {translation.announcements}
        </Typography>
        {parentDashboardUnseenAnnouncementCount > 0 && (
          <Typography
            sx={{
              borderRadius: "50%",
              backgroundColor: "#f0f4d2",
              // position: "absolute",
              // top: 0,
              // right: 0,
              width: 24,
              height: 24,
              textAlign: "center",
            }}
          >
            {parentDashboardUnseenAnnouncementCount}
          </Typography>
        )}
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            mb: 3,
          }}
        >
          <LoadingIndicator />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: 2,
            flex: "0 1 auto",
            overflowY: "auto",
            pr: 2,
            mr: -2.5,
            ml: 0.25,
            mt: 2.1,
            mb: 3,
          }}
        >
          {!isLessThan1200 && (
            <Box
              sx={{ width: imageInfo.width, height: imageInfo.height / 5 }}
            />
          )}
          {announcementList.length !== 0 ? (
            [...(isShowAll ? announcementList : activeAnnouncementList)].map(
              (announcement, index) => (
                <Fragment key={index}>
                  {index !== 0 && <Divider />}
                  <AnnouncementCard
                    key={`announcement-${index}-${announcement.id}`}
                    announcement={announcement}
                    isParentView
                    isPreview
                  />
                </Fragment>
              )
            )
          ) : (
            <Typography>{translation.noAnnouncementYet}</Typography>
          )}
        </Box>
      )}

      {announcementList.length !== 0 &&
        activeAnnouncementList.length !== announcementList.length && (
          <Typography
            sx={{
              alignSelf: "flex-start",
              color: "var(--txt-secondary)",
              cursor: "pointer",
              ml: 0.5,
            }}
            onClick={() => setIsShowAll((prev) => !prev)}
          >
            {isShowAll
              ? translation.showActiveAnnouncementsOnly
              : translation.viewAllAnnouncements}
          </Typography>
        )}
    </Box>
  );
};

export default Sidebar;

import { checkIsChineseChar } from "helper/helper";

export const truncateText = (
  text: string,
  maxLines: number,
  maxCharsPerLine: number
) => {
  const isChinese = checkIsChineseChar(text);
  const lines = isChinese ? text.split("") : text.split("\n");
  let truncatedText = "";
  let charCount = 0;
  let lineCount = 0;
  let lineBuffer = "";

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const lineLength = isChinese ? 1 : line.length;

    if (isChinese) {
      // For Chinese text, treat each character as a line
      lineBuffer += line;
      charCount++;

      if (charCount >= maxCharsPerLine || i === lines.length - 1) {
        truncatedText += lineBuffer + "\n";
        lineBuffer = "";
        charCount = 0;
        lineCount++;

        if (lineCount >= maxLines) {
          truncatedText = truncatedText.trimEnd() + "...";
          break;
        }
      }
    } else {
      // For non-Chinese text, treat each actual line
      if (lineCount >= maxLines) {
        truncatedText = truncatedText.trimEnd() + "...";
        break;
      }

      if (lineLength > maxCharsPerLine) {
        truncatedText += line.substring(0, maxCharsPerLine) + "...";
        break;
      } else {
        truncatedText += line + "\n";
        lineCount++;
      }
    }
  }

  return truncatedText.trim();
};

import { useState, useEffect, useMemo } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, Stack } from "@mui/material";

import MaterialButton from "components/Button/MaterialButton";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import {
  Age_Group,
  API_Edit_Project,
  API_Project_Image,
  ProjectCategory,
  ProjectCategoryModule,
  API_Create_Project_Fundamental,
  Project_Main_Form_Data,
  API_Checkpoint_Fundamental,
  API_Project_Unit_Guide,
  API_Create_Project,
} from "types/project";

import {
  addOrUpdateProjectUnitGuide,
  createCheckpoint,
  deleteProjectImage,
  editProject,
  getProjectCategories,
  updateCheckpoint,
} from "api/project-api";

import { CURLANG, EDIT_PROJECT_PAGE } from "constants/url";
import { translation } from "constants/translation";
import getBase64 from "helper/getBase64";

//Prompt user when leaving without saving
import BlockLeavingDialog from "components/Dialog/BlockLeavingDialog";
import useCallbackPrompt from "hooks/useCallbackPrompt";
import KeepChangesDialog from "components/Dialog/KeepChangesDialog";

import {
  ModuleField,
  NameField,
  AgeField,
  DurationField,
  DescriptionField,
  DevelopmentalGoalsField,
  PublishField,
  GuidingQuestionField,
  TagsField,
  ProjectObjectiveField,
  AssessmentTagField,
} from "../ProjectFormComponents";

import { ImageField } from "../LessonFormComponents";
import ProjectCheckpointFundamentalField from "../ProjectFormComponents/ProjectCheckpointFundamentalField";
import ProjectUnitGuideFieldWithDnd from "../ProjectFormComponents/ProjectUnitGuideField";
import { SPROUTS } from "constants/project-lesson-activity";
import DrawerHeader from "containers/EditProjectPage/DrawerHeader";
import DeleteProjectButton from "./DeleteProjectButton";

export const initialProjectMainFormData = {
  name: "",
  description: "",
  tags: "",
  objective: "",
  guiding_question: "",
  created_by: "",
  updated_by: "",
  duration: "", // in days
  age: SPROUTS as Age_Group,
  objective_fundamentals: [],
};

const EditProjectForm = ({ handleClose }: { handleClose: () => void }) => {
  const openSnack = useSnack();
  const { projectSlug } = useParams();
  const navigate = useNavigate();

  const {
    currentProject,
    setRefreshProjAndLess,
    currLanguage,
    bringOverProject,
    isProjectLoading,
  } = useStore((state) => state);

  // console.log("currentProject: ", currentProject);
  const [images, setImages] = useState<File[]>([]);
  const [selectedModule, setSelectedModule] = useState<string>("");
  const [moduleList, setModuleList] = useState<ProjectCategoryModule[]>([]);
  const [filledRequired, setFilledRequired] = useState<boolean>(false);

  const [mainFormData, setMainFormData] = useState<Project_Main_Form_Data>(
    initialProjectMainFormData
  );

  const [oldImages, setOldImages] = useState<API_Project_Image[]>(
    currentProject.images
  );
  const [isDeleteImageList, setIsDeleteImageList] = useState<boolean[]>(
    Array(currentProject.images.length).fill(false)
  );

  const [age, setAge] = useState<Age_Group>(currentProject.age as Age_Group);
  const [publish, setPublish] = useState<boolean>(currentProject.is_published);
  const [assessmentTag, setAssessmentTag] = useState<string>(currentProject.assessment_tag as string)

  const oldFundamentals = currentProject.fundamentals.map((fundamental) => ({
    fundamental: fundamental.funid.toString(),
    project: currentProject.id.toString(),
    objective: fundamental.objective ? fundamental.objective : "",
  }));
  const [fundamentals, setFundamentals] =
    useState<API_Create_Project_Fundamental[]>(oldFundamentals);
  const [deleteFundamental, setDeleteFundamental] = useState<boolean[]>(
    Array(currentProject.fundamentals.length).fill(false)
  );

  // checkpoint fundmanetal (consist of)> checkpointFundamentals
  const [checkpointFundamentals, setCheckpointFundamentals] = useState<
    API_Checkpoint_Fundamental[]
  >([]);

  //Unit guides
  const [projectUnitGuides, setProjectUnitGuides] = useState<
    API_Project_Unit_Guide[]
  >([]);

  useEffect(() => {
    const populateOldData = () => {
      setMainFormData({
        name: currentProject.name,
        description: currentProject.description,
        tags: currentProject.tags,
        objective: currentProject.objective,
        guiding_question: currentProject.guiding_question,
        created_by: currentProject.created_by,
        updated_by: currentProject.updated_by,
        duration: currentProject.duration.toString(),
        age: currentProject.age,
        objective_fundamentals: currentProject.objective_fundamentals,
      });

      setImages([]);
      setOldImages(currentProject.images);
      setIsDeleteImageList(Array(currentProject.images.length).fill(false));
      setAge(currentProject.age as Age_Group);
      setPublish(currentProject.is_published);
      setCheckpointFundamentals(currentProject.checkpoint_fundamentals);
      setProjectUnitGuides(currentProject.unit_guides);

      const oldFundamentals = currentProject.fundamentals.map(
        (fundamental) => ({
          fundamental: fundamental.funid.toString(),
          project: currentProject.id.toString(),
          objective: fundamental.objective ? fundamental.objective : "",
        })
      );
      setFundamentals(oldFundamentals);
      setDeleteFundamental(Array(oldFundamentals.length).fill(false));
    };

    const updateModules = async () => {
      const updatedModule: ProjectCategoryModule[] = (
        await getProjectCategories(currLanguage)
      ).reduce(
        (arr: ProjectCategoryModule[], curr: ProjectCategory) => [
          ...arr,
          ...curr.modules.map((module) => ({
            ...module,
            assessment: curr.assessment,
          })),
        ],
        []
      );

      setModuleList(updatedModule);
      if (bringOverProject.module) {
        setSelectedModule(bringOverProject.module);
      } else {
        setSelectedModule(currentProject.module.id.toString());
      }
    };

    const populateForm = async () => {
      populateOldData();
      await updateModules();
    };
    populateForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isFundamentalsFilled =
      fundamentals.length !== 0
        ? Object.values(fundamentals).every(
            (fundamental) => fundamental.fundamental !== "-1"
          )
        : true;

    if (
      mainFormData.name !== "" &&
      mainFormData.description !== "" &&
      mainFormData.duration !== "" &&
      selectedModule !== "" &&
      isFundamentalsFilled
    ) {
      setFilledRequired(true);
    } else {
      setFilledRequired(false);
    }
  }, [mainFormData, images, age, selectedModule, publish, fundamentals]);

  const isSelectedModuleAssessment = useMemo(() => {
    if (!selectedModule) return false;

    const selectedModuleData = moduleList.find(
      (module) => module.id === Number(selectedModule)
    );
    return !!selectedModuleData?.assessment;
  }, [selectedModule, moduleList]);

  const handleSubmit = async () => {
    if (
      new Set(
        projectUnitGuides.map((projectUnitGuide) => projectUnitGuide.name)
      ).size !==
      projectUnitGuides.map((projectUnitGuide) => projectUnitGuide.name).length
    ) {
      openSnack("Please ensure the resource guides have unique name!", false);
      return;
    }
    setFilledRequired(false);

    const editData: API_Edit_Project = {};

    if (mainFormData.name !== currentProject.name)
      editData.name = mainFormData.name;
    if (mainFormData.description !== currentProject.description)
      editData.description = mainFormData.description;
    if (mainFormData.tags !== currentProject.tags)
      editData.tags = mainFormData.tags;
    if (mainFormData.objective !== currentProject.objective)
      editData.objective = mainFormData.objective;
    if (
      mainFormData.objective_fundamentals !==
      currentProject.objective_fundamentals
    )
      editData.objective_fundamentals = mainFormData.objective_fundamentals;
    if (mainFormData.guiding_question !== currentProject.guiding_question)
      editData.guiding_question = mainFormData.guiding_question;
    if (mainFormData.created_by !== currentProject.created_by)
      editData.created_by = mainFormData.created_by;
    if (mainFormData.updated_by !== currentProject.updated_by)
      editData.updated_by = mainFormData.updated_by;
    if (age !== currentProject.age) editData.age = age;
    if (assessmentTag !== currentProject.assessment_tag) editData.assessment_tag = assessmentTag;
    if (publish !== currentProject.is_published)
      editData.is_published = publish;
    if (selectedModule !== currentProject.module.name)
      editData.module = selectedModule;
    if (mainFormData.duration !== currentProject.duration.toString())
      editData.duration = mainFormData.duration;

    if (images.length > 0) {
      const base64Images: { file: string }[] = [];
      for (let i = 0; i < images.length; i++) {
        const base64 = await getBase64(images[i]);
        base64Images.push({ file: base64 });
      }
      editData.images = base64Images;
    }
    for (let i = 0; i < isDeleteImageList.length; i++) {
      if (isDeleteImageList[i]) {
        const res = await deleteProjectImage(oldImages[i].id);
        if (res !== "success") openSnack(res, false);
      }
    }
    // --- This fundamental section update will be replaced by objective fundamentals
    // update existing fundamentals
    // for (let i = 0; i < oldFundamentals.length; i++) {
    //   const objData: API_Update_Project_Fundamental = {};
    //   const obj = fundamentals[i];

    //   if (currentProject.fundamentals[i].id.toString() !== obj.fundamental)
    //     objData.fundamental = obj.fundamental;
    //   if (oldFundamentals[i].objective !== obj.objective)
    //     objData.objective = obj.objective;

    //   if (Object.keys(objData).length > 0) {
    //     await editProjectFundamental(
    //       currLanguage,
    //       currentProject.fundamentals[i].id.toString(),
    //       objData
    //     );
    //   }
    // }

    // // add new fundamentals
    // for (let i = oldFundamentals.length; i < fundamentals.length; i++) {
    //   await createObjective(currLanguage, fundamentals[i]);
    // }

    // // delete fundamentals
    // for (let i = 0; i < deleteFundamental.length; i++) {
    //   if (deleteFundamental[i]) {
    //     await deleteObjective(currentProject.fundamentals[i].id.toString());
    //   }
    // }
    //  This fundamental section update will be replaced by objective fundamentals ---

    // update checkpoint
    checkpointFundamentals.forEach((checkpoint_fundamental) => {
      checkpoint_fundamental.checkpoints.forEach(async (checkpoint_) => {
        const checkpointData = {
          name: checkpoint_.name,
          description: checkpoint_.description,
          fundamental: checkpoint_.fundamental,
          is_required: checkpoint_.is_required,
          project: currentProject.id,
        };

        if (checkpoint_.milestone && checkpoint_.milestone > 0) {
          Object.assign(checkpointData, {
            milestone: checkpoint_.milestone,
          });
        }

        let res;
        if (checkpoint_.id && checkpoint_.id > 0) {
          res = await updateCheckpoint(
            currLanguage,
            checkpointData,
            checkpoint_.id!
          );
        } else {
          res = await createCheckpoint(currLanguage, checkpointData);
        }

        if (typeof res === "string") {
          openSnack(res, false);
        }
      });
    });

    //Update Unit Guide
    const projectUnitGuideRes = addOrUpdateProjectUnitGuide(
      currLanguage,
      projectUnitGuides,
      localStorage.getItem("access")
    );
    if (typeof projectUnitGuideRes === "string") {
      openSnack(projectUnitGuideRes, false);
      return;
    }

    //Update project
    const res = await editProject(currLanguage, currentProject.id, editData);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      if (res.slug === projectSlug) {
        setRefreshProjAndLess(true);
      } else {
        navigate(`${CURLANG(currLanguage)}/${EDIT_PROJECT_PAGE(res.slug)}`);
      }
      openSnack("Edited project successfully!", true);
      handleClose();
    }
  };

  const [keptData, setKeptData] = useState<API_Edit_Project>({});

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const dialogBody = {
    showDialog: showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleAction: handleSubmit,
  };

  const changesDialogBody = {
    showChangesDialog: showChanges,
    confirmNavigation,
    cancelNavigation,
    project: keptData,
    lesson: {},
    activity: {},
  };

  if (isProjectLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {showPrompt && <BlockLeavingDialog {...dialogBody} />}
      {showChanges && <KeepChangesDialog {...changesDialogBody} />}
      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
       <DrawerHeader
          type="Project"
          assessment={isSelectedModuleAssessment}
          handleClose={handleClose}
        >
          <Box
            sx={{
              display: "flex",
              gap: "16px",
            }}
          >
            <DeleteProjectButton
              setShowDialog={setShowDialog}
              handleClose={handleClose}
            />
            <MaterialButton
              buttonText={translation.update || "Update"}
              onClick={() => {
                if (filledRequired) {
                  setShowDialog(false);
                  handleSubmit();
                }
              }}
              disabled={!filledRequired}
            />
          </Box>
        </DrawerHeader>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <NameField
            label={isSelectedModuleAssessment ? "Assessment Name" : undefined}
            data={mainFormData}
            setData={setMainFormData}
            onChange={(input) => {
              setKeptData((prev) => ({ ...prev, name: input }));
              setShowDialog(true);
            }}
          />
          <PublishField publish={publish} setPublish={setPublish} />
        </Box>
        <ModuleField
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          moduleList={moduleList}
          onChange={(input) => {
            setKeptData((prev) => ({
              ...prev,
              module: input,
            }));
            setShowDialog(true);
          }}
        />
        {!isSelectedModuleAssessment && (
          <AgeField
            age={age}
            setAge={setAge}
            onChange={(input) => {
              setKeptData((prev) => ({ ...prev, age: input }));
              setShowDialog(true);
            }}
          />
        )}
        <DurationField
          data={mainFormData}
          setData={setMainFormData}
          onChange={(input) => {
            setKeptData((prev) => ({ ...prev, duration: input }));
            setShowDialog(true);
          }}
        />
        <DescriptionField
          label={
            isSelectedModuleAssessment ? "Assessment Description" : undefined
          }
          data={mainFormData}
          setData={setMainFormData}
          onChange={(input) => {
            setKeptData((prev) => ({ ...prev, description: input }));
            setShowDialog(true);
          }}
        />
        {isSelectedModuleAssessment && (
          <AssessmentTagField
            assessmentTag={assessmentTag}
            setAssessmentTag={setAssessmentTag}
            onChange={(input) => {
              setKeptData((prev) => ({ ...prev, assessment_tag: input }));
              setShowDialog(true);
            }}
          />
        )}
        {!isSelectedModuleAssessment && (
          <TagsField
            data={mainFormData}
            setData={setMainFormData}
            onChange={(input) => {
              setKeptData((prev) => ({ ...prev, tags: input }));
              setShowDialog(true);
            }}
          />
        )}
        {!isSelectedModuleAssessment && (
          <DevelopmentalGoalsField
            fundamentals={fundamentals}
            setFundamentals={setFundamentals}
            deleteFundamental={deleteFundamental}
            setDeleteFundamental={setDeleteFundamental}
            onChange={() => setShowDialog(true)}
          />
        )}
        <ProjectObjectiveField
          label={
            isSelectedModuleAssessment ? "Assessment Objectives" : undefined
          }
          project={mainFormData as API_Create_Project}
          setProject={
            setMainFormData as React.Dispatch<
              React.SetStateAction<API_Create_Project>
            >
          }
        />

        {!isSelectedModuleAssessment && (
          <>
            <ProjectCheckpointFundamentalField
              checkpointFundamentals={checkpointFundamentals}
              setCheckpointFundamentals={setCheckpointFundamentals}
            />
            <ProjectUnitGuideFieldWithDnd
              projectUnitGuides={projectUnitGuides}
              setProjectUnitGuides={setProjectUnitGuides}
            />
            <GuidingQuestionField
              data={mainFormData}
              setData={setMainFormData}
              onChange={(input) => {
                setKeptData((prev) => ({ ...prev, guiding_question: input }));
                setShowDialog(true);
              }}
            />
          </>
        )}
        <ImageField
          images={images}
          setImages={setImages}
          oldImages={oldImages}
          isDeleteImageList={isDeleteImageList}
          setIsDeleteImageList={setIsDeleteImageList}
          multiple
        />
      </Stack>
    </>
  );
};

export default EditProjectForm;

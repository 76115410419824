import { useParams } from "react-router-dom";

import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useStore from "../../../store/store";

import "../LessonActivityPage.css";

import { translation } from "../../../constants/translation";
import { API_Activity_Files, API_Project_Lesson } from "../../../types/project";
import { useEffect, useState } from "react";
import { FilePreview } from "../../ProjectSummaryPage/Screens/TeachingMaterialsScreen";

const LessonToolboxContent = ({
  currentLesson,
}: {
  currentLesson: API_Project_Lesson;
}) => {
  const theme = useTheme();
  const { currLanguage, fundamentalDict, developmentalGoals } = useStore(
    (state) => ({
      currLanguage: state.currLanguage,
      fundamentalDict: state.fundamentalDict,
      developmentalGoals: state.developmentalGoals,
    })
  );

  const { activityID } = useParams();
  const [currentActivityIndex, setCurrentActivityIndex] = useState<number>(-1);
  const [attachments, setAttachments] = useState<API_Activity_Files[]>([]);

  useEffect(() => {
    if (activityID) {
      const tempActivityIndex = currentLesson.activities.findIndex(
        (activity) => activity.id === Number(activityID)
      );
      setCurrentActivityIndex(tempActivityIndex);

      setAttachments(
        currentLesson.activities[tempActivityIndex].files.filter(
          (file) =>
            // @ts-ignore
            file[`name_${currLanguage === "id" ? "ind" : currLanguage}`] !==
            null
        )
      );
    }
  }, [activityID]);

  const [isExpandSetupInstruction, setIsExpandSetupInstruction] =
    useState<boolean>(false);
  const [isExpandLessonObjectives, setIsExpandLessonObjectives] =
    useState<boolean>(false);

  const lessonObjectives: any = [];

  currentLesson.objective_fundamentals.forEach((objective: any) => {
    const newDevGoal = developmentalGoals.find(
      (goal) => goal.id === fundamentalDict[objective.fundamental].id
    );

    if (newDevGoal) {
      lessonObjectives.push({
        fundamental: newDevGoal?.name,
        objective: newDevGoal?.milestones.find(
          (milestone: any) => milestone.id == objective.milestone
        )?.name,
        tag: newDevGoal?.tag,
        priority: newDevGoal?.priority,
      });
    } else {
      const mainFundamental =
        fundamentalDict[objective.fundamental]?.main_fundamental;
      if (mainFundamental) {
        lessonObjectives.push({
          fundamental: fundamentalDict[objective.fundamental].name,
          objective: fundamentalDict[objective.fundamental].milestones.filter(
            (milestone: any) => milestone.id == objective.milestone
          )[0].name,
          tag: fundamentalDict[objective.fundamental].tag,
          priority: fundamentalDict[mainFundamental].priority,
        });
      }
    }
  });

  lessonObjectives.sort((a: any, b: any) => {
    const order = ["heart", "head", "hands"];
    const tagDifference = order.indexOf(a.tag) - order.indexOf(b.tag);
    if (tagDifference === 0) {
      return a.priority - b.priority;
    }
    return tagDifference;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 2,
        minWidth: 300,
      }}
    >
      {currentLesson.intention && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Typography variant="h6" sx={{ fontSize: 20 }}>
            {translation.lesson_intention}
          </Typography>
          <Typography sx={{ lineHeight: 1.25 }}>
            {currentLesson.intention}
          </Typography>
        </Box>
      )}

      {lessonObjectives.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            overflow: "hidden", // Add overflow hidden
          }}
          onClick={() => {
            setIsExpandLessonObjectives((prev) => !prev);
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: 20 }}>
              {translation.lesson_objectives}
            </Typography>
            {isExpandLessonObjectives ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              transition:
                "max-height 0.5s ease-in-out, opacity 0.5s ease-in-out", // Add opacity to the transition
              maxHeight: isExpandLessonObjectives ? 9999 : 0, // You may need to adjust this
              opacity: isExpandLessonObjectives ? 1 : 0, // Add opacity
            }}
          >
            {lessonObjectives.map((objective: any) => {
              const fundamentalName = objective.fundamental;
              const milestoneName = objective.objective;
              return (
                <Chip
                  sx={{
                    placeSelf: "start",
                    borderColor: theme.palette.primary.main,
                    p: 0.5,
                  }}
                  key={`fundamental-${fundamentalName}-${milestoneName}`}
                  // color={"primary"}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, lineHeight: 1.2 }}>
                        {fundamentalName}
                      </Typography>
                      <Typography sx={{ lineHeight: 1, paddingBottom: 0.5 }}>
                        {milestoneName}
                      </Typography>
                    </Box>
                  }
                  variant="outlined"
                />
              );
            })}
          </Box>
        </Box>
      )}

      {currentLesson.instruction && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            transition: "all 100ms ease-in-out",
          }}
          onClick={() => {
            setIsExpandSetupInstruction((prev) => !prev);
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              transition: "all 100ms ease-in-out",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: 20 }}>
              {translation.setupInstruction}
            </Typography>
            {isExpandSetupInstruction ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>

          <Box
            sx={{
              lineHeight: 1.3,
              "& .image img": { width: "100%" },
              overflow: "hidden",
              maxHeight: isExpandSetupInstruction ? 9999 : 0,
              opacity: isExpandSetupInstruction ? 1 : 0, // Add opacity
              transition:
                "max-height 0.5s ease-in-out, opacity 0.5s ease-in-out",
            }}
            dangerouslySetInnerHTML={{
              __html: currentLesson.instruction,
            }}
          />
        </Box>
      )}

      {currentLesson.vocabulary && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Typography variant="h6" sx={{ fontSize: 20 }}>
            {translation.key_vocabulary}
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {currentLesson.vocabulary.split(",").map((vocab) => {
              return (
                <Chip
                  key={`vocabulary-${vocab}`}
                  // color={"primary"}
                  label={vocab}
                  variant="outlined"
                />
              );
            })}
          </Box>
        </Box>
      )}

      {/* Material start */}
      {currentActivityIndex !== -1 &&
        currentLesson.activities[currentActivityIndex].material && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
            }}
          >
            <Typography variant="h6" sx={{ fontSize: 20 }}>
              {translation.materials}
            </Typography>
            <Box
              sx={{ lineHeight: 1.3, "& .image img": { width: "100%" } }}
              dangerouslySetInnerHTML={{
                __html: currentLesson.activities[currentActivityIndex].material,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                flexWrap: "wrap",
                mt: 2,
              }}
            >
              {attachments.map((file, index) => {
                return (
                  <Box
                    key={`file-preview-${index}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FilePreview
                      file={file}
                      noName
                      style={{ width: 90, height: 90 }}
                    />
                    <Tooltip title={file.name}>
                      <a
                        key={index}
                        href={file.file}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {file.name.length > 10
                          ? `...${file.name.slice(-10)}`
                          : file.name}
                      </a>
                    </Tooltip>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

      {/* Material end */}
    </Box>
  );
};

export default LessonToolboxContent;

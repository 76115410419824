import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export interface PopupProps {
  open: boolean;
  onClose: (value: string) => void;
  title: string;
  child?: React.ReactElement;
}

function Popup(props: PopupProps) {
  const { onClose, open, title, child } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      {child}
    </Dialog>
  );
}

export default Popup;

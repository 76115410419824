import { Box, Typography, Modal, Backdrop, Fade } from "@mui/material";

import { translation } from "constants/translation";
import Button from "components/Button/Button";
import Image from "components/Image/Image";
import { API_Teacher_Mcq_Modal_Info } from "types/teacher";
import KeepGoingIcon from "images/keep-going.png";

const CongrazModal = ({
  mcqModalInfo,
  setMcqModalInfo,
}: {
  mcqModalInfo: API_Teacher_Mcq_Modal_Info;
  setMcqModalInfo: React.Dispatch<
    React.SetStateAction<API_Teacher_Mcq_Modal_Info>
  >;
}) => {
  // const { profile } = useStore((state) => ({
  //   profile: state.profileDetails,
  // }));

  const handleClose = () => {
    setMcqModalInfo((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const open = mcqModalInfo.open;
  //   const open = true;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(49,47,48,0.4)",
        },
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 500,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <ScoreTitle
              correctCount={mcqModalInfo.correctCount}
              totalCount={mcqModalInfo.totalCount}
            />

            {/* EMOTICON */}
            <Image
              src={KeepGoingIcon}
              alt={translation.keepGoing || "Keep Going"}
              style={{ width: 100, height: 100 }}
            />

            {/* Great! */}
            <Typography variant="h2" sx={{ fontWeight: 500 }}>
              {translation.great}
            </Typography>
            <Typography> {translation.letsKeepStreakGoing}</Typography>

            {/* Okay button */}
            <Button
              buttonText={translation.okay || "Okay"}
              arrow={false}
              style={{
                display: "flex",
                width: "100%",
                textAlign: "center",
              }}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export const ScoreTitle = ({
  correctCount,
  totalCount,
}: {
  correctCount: number;
  totalCount: number;
}) => (
  <Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
    <Typography
      variant="h6"
      component="span"
      sx={{
        display: "block",
        lineHeight: "1.25",
        fontSize: 14,
      }}
    >
      {`${translation.score}: ${translation.formatString(
        translation.mcqAnswered || "MCQ Answered",
        { answerCount: correctCount, totalCount: totalCount }
      )}`}
    </Typography>
  </Typography>
);

export default CongrazModal;

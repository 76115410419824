
import { Box, Typography } from "@mui/material";
import { translation } from "constants/translation";
import ManageProhibitedWordPage from "./ManageProhibitedWordPage";

const ViewProhibitedWordPage = () => {
  return (
    <Box
      sx={{
        minHeight: "71vh",
        p: "16px",
        mx: "60px",
        my: "16px",
      }}
    >
      <Typography variant="h3" sx={{ mb: "8px" }}>
        {translation.prohibitedWords}
      </Typography>

      <ManageProhibitedWordPage />
    </Box>
  );
};

export default ViewProhibitedWordPage;

import { initialCustomerInfo } from "../containers/PaymentPage/constants/helper";
import { CustomerInfo } from "../types/payment";
import { StoreSlice } from "./store";

export interface PaymentSlice {
  customerInfo: CustomerInfo;
  setCustomerInfo: (customerInfo: CustomerInfo) => void;
}

export const createPaymentSlice: StoreSlice<PaymentSlice> = (set, get) => ({
  customerInfo: initialCustomerInfo,
  setCustomerInfo: (customerInfo: CustomerInfo) => {
    set((prev: PaymentSlice) => ({
      ...prev,
      customerInfo: customerInfo,
    }));
  },
});

import React, { useLayoutEffect, useState } from "react";

import useStore from "store/store";

import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

import { Tooltip, useMediaQuery, useTheme } from "@mui/material";

import { API_Project_Lesson } from "types/project";

import Logo from "images/logo-2.png";
import LogoBlur from "images/logo-2-colour-blur.png";
import { CURLANG, EDIT_LESSON_PAGE } from "constants/url";
import EditIcon from "@mui/icons-material/Edit";
import { translation } from "constants/translation";
import { useSortable } from "@dnd-kit/sortable";
import { isProfileInProjectEditableRoleList } from "helper/helper";
import {
  descriptionTextFontSize,
  descriptionTextMobileFontSize,
  titleFontSize,
  titleMobileFontSize,
} from "constants/constantInGeneral";

type LessonDetailCardProps = {
  assessmentPlan?: boolean;
  lesson: API_Project_Lesson;
  lessonIndex: number;
  handleClick: () => void;
}

const LessonDetailCard = ({
  assessmentPlan = false,
  lesson,
  lessonIndex,
  handleClick,
}: LessonDetailCardProps) => {
  const [imgSrc, setImgSrc] = useState<string>(LogoBlur);
  const { profileDetails, currLanguage, projectSlug, setCurrLesson } = useStore(
    (state) => ({
      ...state,
      projectSlug: state.currentProject.slug,
    })
  );

  const navigate = useNavigate();

  const { isDragging } = useSortable({ id: lesson.id });

  useLayoutEffect(() => {
    if (lesson.images.length > 0) {
      setImgSrc(lesson.images[0].file);
    } else {
      setImgSrc(Logo);
    }
  }, [lesson.images]);

  const Title = ({ title }: { title: number }) => {
    return (
      <Typography
        sx={{ fontWeight: "500", fontSize: { xs: "22px", sm: "24px" } }}
      >
        {translation.formatString(translation.lessonNo, { number: title })}
      </Typography>
    );
  };

  const NameAndDescription = ({
    assessmentPlan,
    name,
    description,
  }: {
    assessmentPlan?: boolean;
    name?: string;
    description?: string;
  }) => {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Box
        sx={{ mt: "-16px", display: "flex", flexDirection: "column", gap: 0.5 }}
      >
        <Typography
          sx={{ fontSize: { xs: titleMobileFontSize, sm: titleFontSize } }}
        >
          {name}
        </Typography>
        {assessmentPlan ? (
        <Typography
            sx={{
              fontSize: {
                xs: descriptionTextMobileFontSize,
                sm: descriptionTextFontSize,
              },
            }}
            dangerouslySetInnerHTML={{ __html: description || "" }}
          />
        ): (
          <Typography
            sx={{
              fontSize: {
                xs: descriptionTextMobileFontSize,
                sm: descriptionTextFontSize,
              },
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
    );
  };

  const Duration = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "fit-content",
        gap: "4px",
      }}
    >
      <Tooltip title="Expected Duration (mins)">
        <AccessTimeIcon sx={{ fontSize: { xs: "25px", sm: "35px" } }} />
      </Tooltip>
      <Box>
        <Typography
          sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
        >
          {lesson.duration || "?"}
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
        >
          {translation.min}
        </Typography>
      </Box>
    </Box>
  );

  const Activities = () => (
    <Box
      sx={{
        display: "flex",
        gap: "4px",
        alignItems: "center",
        height: "fit-content",
      }}
    >
      <Tooltip title="Total Activities">
        <AssignmentOutlinedIcon sx={{ fontSize: { xs: "25px", sm: "35px" } }} />
      </Tooltip>
      <Box>
        <Typography
          sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
        >
          {lesson.activities.length}
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
        >
          {translation.activities}
        </Typography>
      </Box>
    </Box>
  );

  const EditButton = () => (
    !(isDragging &&
      isProfileInProjectEditableRoleList(profileDetails)) ? (
        <EditIcon
          sx={{
            pointerEvents: "auto",
            cursor: "pointer",
            color: "txt.secondary",
            transition: "all 100ms ease-in-out",
            "&:hover": {
              color: "primary.main",
              transition: "all 100ms ease-in-out",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            setCurrLesson(lesson);
            navigate(
              `${CURLANG(currLanguage)}/${EDIT_LESSON_PAGE(
                projectSlug,
                lesson.id.toString()
              )}`
            );
          }}
        />
      ) : null
  );

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      sx={{
        minWidth: { xs: 223, sm: 267 },
        maxWidth: { xs: 223, sm: 267 },
        height: isProfileInProjectEditableRoleList(profileDetails)
          ? "100%"
          : "auto",
        borderRadius: "10px",
        backgroundColor: "#F0F0F0",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        cursor: "pointer",
        transition: "200ms all ease-in-out",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        "&:active": {
          cursor: !isDragging ? "pointer" : "grabbing",
        },
      }}
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        alt={lesson.images.length > 0 ? lesson.images[0].file : "No Image"}
        height={isXs ? "100px" : "206px"}
        image={imgSrc}
        sx={{
          pointerEvents: "none",
          backgroundColor: "primary.light",
          "&::before": { display: "none" },
          // objectFit: "contain",
        }}
        onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
          event.currentTarget.onerror = null;
          event.currentTarget.src = Logo;
        }}
      />
      <CardContent
        sx={{
          pointerEvents: "none",
          display: "flex",
          flexDirection: "column",
          gap: { xs: "0.7rem", sm: "1rem" },
          padding: { xs: "0px 5px 0px", sm: "13px 18px 20px" },
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!assessmentPlan && (
            <>
              <Title title={lessonIndex + 1} />
              <EditButton />
            </>
          )}
        </Box>
        <NameAndDescription
          assessmentPlan={assessmentPlan}
          name={lesson.name}
          description={lesson.description}
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mt: "auto",
          }}
        >
          <Duration />
          <Box
            sx={{
              borderRight: "2px solid black",
              ml: { xs: "40px", sm: "40px" },
              mr: { xs: "20px", sm: "20px" },
            }}
          />
          <Activities />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LessonDetailCard;

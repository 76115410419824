import React, { useEffect, useState } from "react";

import { Box, Fade, Modal, Backdrop, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LineChart,
  BarChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from "recharts";

import Button from "components/Button/Button";
import { translation } from "constants/translation";
import { AnalyticPeriodType } from "types/analytic";
import {
  GENERAL_DATE_FORMAT,
  ANALYTIC_PERIOD_LIST,
  ONE_YEAR,
  PERIOD_MAPPING,
  SEVEN_DAYS,
  THIRTY_DAYS,
} from "constants/constantInGeneral";

import useStore from "store/store";
import { getStartEndDate } from "helper/helper";
import moment from "moment";
import {
  API_Teacher_Certificate,
  API_Teacher_Certificate_Chart_Info,
  API_Teacher_Certificate_Issued_Info,
} from "types/teacher";
import { getCertificateChartInfo } from "api/teacher-api";

interface WeekBucket {
  count: number;
  weekEnd: string;
}

const preprocessData = (
  data: string[],
  interval: AnalyticPeriodType
): API_Teacher_Certificate_Issued_Info[] => {
  let intervalNum = 7; //7 days default
  let bucketSize = 1; // default to daily buckets
  if (interval === ONE_YEAR) {
    intervalNum = 364;
    bucketSize = 7; // use weekly buckets for 364-day interval
  } else if (interval === THIRTY_DAYS) {
    intervalNum = 30;
  }

  const startDate = moment().subtract(intervalNum, "days");
  const bucketCount = intervalNum === 364 ? 52 : intervalNum;
  const buckets: Record<number, WeekBucket> = {};

  // Create empty buckets for all time periods
  for (let i = 0; i < bucketCount; i++) {
    const weekEnd = startDate.clone().add(i * bucketSize + bucketSize, "days");
    buckets[i] = {
      count: 0,
      weekEnd: weekEnd.toISOString(),
    };
  }

  data.forEach((created_at) => {
    const createdAt = moment(created_at);
    const bucketNumber = Math.floor(
      createdAt.diff(startDate, "days") / bucketSize
    );

    if (bucketNumber >= 0 && bucketNumber < bucketCount) {
      buckets[bucketNumber].count++;
    }
  });

  const values = Object.values(buckets);
  const finalData = values.map((value) => {
    return {
      created_at: moment(value.weekEnd).format(GENERAL_DATE_FORMAT),
      count: value.count,
    };
  });
  return finalData;
};

const IndividualCertificateChartModal = ({
  selectedCertificate,
  open,
  handleClose,
}: {
  selectedCertificate: API_Teacher_Certificate;
  open: boolean;
  handleClose: () => void;
}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [interval, setInterval] = useState<AnalyticPeriodType>(SEVEN_DAYS);

  const [certificateChartInfo, setCertificateChartInfo] =
    useState<API_Teacher_Certificate_Chart_Info>({
      id: -1,
      name: "",
      slug: "",
      created_at_list: [],
      certificate_issued_info: [],
    });

  useEffect(() => {
    const populateCertificateChartInfo = async () => {
      const [startDate, endDate] = getStartEndDate(interval);

      const res = await getCertificateChartInfo(
        currLanguage,
        selectedCertificate.id,
        startDate,
        endDate,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        // console.log("Res: ", res);
        const processedDataList = preprocessData(res.created_at_list, interval);
        const cleanedCertificateChartInfo = {
          ...res,
          certificate_issued_info: processedDataList,
        };
        setCertificateChartInfo(cleanedCertificateChartInfo);
      }
    };
    selectedCertificate.id !== -1 && populateCertificateChartInfo();
  }, [interval, selectedCertificate.id]);

  const gridItemStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // console.log("certificateChartInfo: ", certificateChartInfo);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            paddingY: 0.5,
            borderRadius: "10px",
            width: "60vw",
            height: "80vh",
            // overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          {/* Content here */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              position: "relative",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 1,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "45%",
                  marginRight: 1,
                }}
              >
                {selectedCertificate.name}
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mr: 2, flex: 1 }}>
                {ANALYTIC_PERIOD_LIST.map((period) => {
                  return (
                    <Button
                      key={`${period}-key`}
                      btnType={period === interval ? "filled" : "outlined"}
                      arrow={false}
                      style={{
                        fontSize: 12,
                        width: "100%",
                        flex: 1,
                        textAlign: "center",
                        display: "flex",
                      }}
                      buttonText={PERIOD_MAPPING[period]}
                      onClick={() => {
                        setInterval(period as AnalyticPeriodType);
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "1fr",
                gridGap: 2,
              }}
            >
              <Box sx={gridItemStyle}>
                <LineChartComponent
                  data={certificateChartInfo.certificate_issued_info}
                />
              </Box>

              {/* <Box sx={gridItemStyle}>
                <BarChartComponent data={certificateChartInfo.rating_count} />
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const LineChartComponent = ({
  data,
}: {
  data: API_Teacher_Certificate_Issued_Info[];
}) => {
  // console.log("data: ", data);
  return (
    <ResponsiveContainer width="90%" height="90%">
      <LineChart
        width={400}
        height={250}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" />
        <YAxis
          dataKey="count"
          tickCount={6}
          domain={[0, Math.max(5, Math.max(...data.map((item) => item.count)))]}
          interval={0}
        />
        <Tooltip />
        <Legend
          formatter={(value, entry, index) => {
            return (
              <span
                style={{ color: entry.color }}
              >{`# Completed Learners`}</span>
            );
          }}
        />
        {/* <Line
          type="monotone"
          dataKey="pv"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        /> */}
        <Line type="monotone" dataKey="count" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};

// const BarChartComponent = ({ data }: { data: API_Project_Rating_Count }) => {
//   const dataArray = Object.entries(data).map(([rating, count]) => ({
//     rating,
//     count,
//   }));

//   const transformLabel = (label: string) => {
//     const ratingNumber = label.split("_")[1];
//     return `${ratingNumber} Star${ratingNumber === "1" ? "" : "s"}`;
//   };

//   interface CustomXTickProps {
//     x: number;
//     y: number;
//     payload: {
//       value: string;
//     };
//   }

//   const CustomXTick = (props: CustomXTickProps) => {
//     const { x, y, payload } = props;
//     const labelText = transformLabel(payload.value);

//     return (
//       <g transform={`translate(${x},${y})`}>
//         <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
//           {labelText}
//         </text>
//       </g>
//     );
//   };

//   return (
//     <ResponsiveContainer width="90%" height="90%">
//       <BarChart
//         width={500}
//         height={300}
//         data={dataArray}
//         margin={{
//           top: 5,
//           right: 30,
//           left: 20,
//           bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="rating" tick={CustomXTick} />
//         <YAxis />
//         <Tooltip />
//         <Legend
//           formatter={(value, entry, index) => {
//             return (
//               <span style={{ color: entry.color }}>{`Number of rating`}</span>
//             );
//           }}
//         />
//         <Bar dataKey="count" fill="#82ca9d" />
//       </BarChart>
//     </ResponsiveContainer>
//   );
// };

export default IndividualCertificateChartModal;

import React, { useState } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import Image from "components/Image/Image";
import { PDFInfoType, editableStyle, Overlay, ImageType } from "./constant";
import Background from "images/children-portfolio-v2/front-page-bg.png";
import SelectImageModal from "../../ProjectReportPDF/Preview/SelectImageModal";
import Page from "./Page";

export const textStyles: SxProps = {
  fontWeight: 500,
  fontFamily: "FuturaMediumBT",
  lineHeight: 1.25,
  textAlign: "center",
  position: "absolute",
  py: 0.5,
  left: 0,
  right: 0,
  zIndex: 2,
};

const FrontPage = ({
  scale,
  PDFInfo,
  setPDFInfo,
  childImageList,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
  childImageList: ImageType[];
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleChange = (key: string, value: string | null) =>
    setPDFInfo((prev) => ({ ...prev, [key]: value ? value : "" }));

  const editableTitleStyles = {
    ...editableStyle,
    ...textStyles,
  };
  return (
    <Page>
      <Box
        sx={{
          ...editableStyle,
          position: "absolute",
          top: `${216 * scale}px`,
          left: `${72 * scale}px`,
          width: `${463 * scale}px`,
          height: `${463 * scale}px`,
          cursor: "pointer",
        }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={PDFInfo.profileImage} alt="" objectFit="cover" />
      </Box>

      <Image
        style={{
          position: "relative",
          zIndex: 1,
          pointerEvents: "none",
        }}
        src={Background}
        alt=""
      />

      <Typography
        sx={{
          ...editableTitleStyles,
          fontSize: 24 * scale,
          bottom: `${100 * scale}px`,
          mx: `${40 * scale}px`,
        }}
        contentEditable
        onBlur={(e: any) => {
          handleChange("profileName", e.currentTarget.textContent);
        }}
        suppressContentEditableWarning={true}
      >
        {PDFInfo.profileName}
      </Typography>

      <Typography
        sx={{
          ...textStyles,
          fontFamily: "FuturaBkBT",

          fontSize: 16 * scale,
          bottom: `${75 * scale}px`,
          color: "primary.main",
        }}
      >
        {PDFInfo.startMonth} - {PDFInfo.endMonth}
      </Typography>

      {openModal && (
        <SelectImageModal
          open={openModal}
          setOpen={setOpenModal}
          images={childImageList}
          setImage={(image: ImageType) =>
            setPDFInfo((prev) => ({
              ...prev,
              profileImage: image.file,
            }))
          }
          selectedImage={{
            id: -1,
            file: PDFInfo.profileImage,
          }}
        />
      )}
    </Page>
  );
};

export default FrontPage;

import React, { useState, MouseEvent, Fragment, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getGroupList } from "api/profile-api";
import {
  Box,
  Typography,
  Popover,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { translation } from "constants/translation";
import useStore from "store/store";
import { API_Group_Full } from "types/group";
import Logo from "images/logo-2-colour.png";
import { useLocation } from "react-router-dom";

const EditGroupDrawer = () => {
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const navigate = useNavigate();
  const { groupSlug } = useParams();
  const {
    currLanguage,
    groupList,
    setGroupList,
    setCurrGroup,
    setRefreshGroup,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    groupList: state.groupList,
    setCurrGroup: state.setCurrGroup,
    setGroupList: state.setGroupList,

    setRefreshGroup: state.setRefreshGroup,
  }));
  const [popOverGroupName, setPopOverGroupName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setPopOverGroupName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen((prev) => !prev);

  useEffect(() => {
    const populateData = async () => {
      const res = await getGroupList(currLanguage);
      if (typeof res === "string" || res.length === 0) {
        return;
      } else {
        setGroupList(res);
        if (groupSlug) {
          const tempGroup = res.find((group) => group.slug === groupSlug);
          if (tempGroup) {
            console.log(tempGroup);
            setCurrGroup(tempGroup);
            localStorage.setItem("curr_group_id", String(tempGroup.id));
          } else {
          }
        } else {
          navigate(res[0].slug);
        }
      }
    };

    populateData();
  }, [groupSlug, currLanguage]);

  useEffect(() => {
    if (isBelowSm) {
      setOpen(false);
    }
  }, [isBelowSm]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        maxWidth: "300px",
        // width
        flex: open ? "1 0 auto" : "0 0 auto",
        p: 2,
        pt: "21px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
        alignSelf: "flex-start",
        position: "sticky",
        top: "64px",
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mb: 1,
        }}
      >
        {open && (
          <Typography
            sx={{
              fontWeight: "bold",
              lineHeight: 1,
              mr: "auto",
            }}
          >
            {translation.classList}
          </Typography>
        )}

        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            m: 0.5,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
            },
          }}
          onClick={handleDrawer}
        >
          {open ? (
            <CloseIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {/* Overall button */}

        {groupList.map((group, index) => (
          <DrawerButton
            key={index}
            group={group}
            open={open}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        ))}
      </Box>

      {!open && (
        <Popover
          sx={{
            pointerEvents: "none",
            borderRadius: "10px",
            ml: 1,
          }}
          elevation={2}
          open={openPopOver}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              color: "#444",
              p: 1.5,
              lineHeight: 1,
            }}
          >
            {popOverGroupName}
          </Typography>
        </Popover>
      )}
    </Box>
  );
};

const DrawerButton = ({
  group,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}: {
  group: API_Group_Full;
  open: boolean;
  handlePopoverOpen: (event: MouseEvent<HTMLElement>, name: string) => void;
  handlePopoverClose: () => void;
}) => {
  const { groupSlug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const isActiveTab = group.slug === groupSlug;
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "8px",
        cursor: isActiveTab ? "default" : "pointer",
        textDecoration: "none",
        color: "#444",
        marginBottom: 1,
      }}
      onMouseEnter={(e) => handlePopoverOpen(e, group.name)}
      onMouseLeave={() => handlePopoverClose()}
      onClick={() => {
        navigate(`/en/my-classes/${group.slug}/edit`);
      }}
    >
      {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            p: 0.5,
            width: "40px",
            height: "40px",
            borderRadius: "999px",
            border: "1px solid",
            borderColor: isActiveTab ? "#98C4C5" : "#F0F0F0",
            backgroundColor: isActiveTab ? "primary.light" : "var(--txt-light)",
          }}
        >
          <PlaylistAddCheckIcon
            sx={{
              backgroundColor: "white",
              borderRadius: "999px",
              p: "4px",
            }}
          />
        </Box> */}

      <Avatar
        src={group.image ? group.image : Logo}
        sx={{
          width: "40px",
          height: "40px",
          border: "4px solid",
          borderColor: isActiveTab ? "#98C4C5" : "#F0F0F0",
          backgroundColor: isActiveTab ? "primary.light" : "var(--txt-light)",
          display: "flex",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: isActiveTab ? "bold" : "normal",
              color: isActiveTab ? "primary.main" : "#444",
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {group.name}
          </Typography>
          {location.pathname.split("/")[2] == "my-classes" ? (
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#323031",
              }}
            >
              The {group.communication_lvl} group
            </Typography>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default EditGroupDrawer;

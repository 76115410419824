import { useState, useEffect } from "react";
import { fetchLearningStoriesWithTimeFrame } from "api/learning-stories-api";
import moment from "moment";

type Story = {
  id: number;
  date: string;
  image: string;
  story: string;
  fundamental: string;
  milestone: string;
};

type StoriesByYear = {
  [key: string]: Story[];
};

const useLearningStories = (
  groupID: string,
  childID: string,
  timeframe: string
) => {
  const [stories, setStories] = useState<StoriesByYear>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getStories = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const data: StoriesByYear = await fetchLearningStoriesWithTimeFrame(
        Number(groupID),
        Number(childID),
        timeframe
      );

      if (data) {
        if (timeframe === "Month") {
          // Step 1: Flatten all entries into one array
          const allStories: Story[] = Object.entries(data).flatMap(
            ([_, stories]) => stories
          );

          // Step 2: Sort all stories by date in descending order (most recent first)
          allStories.sort((a, b) => moment(b.date).diff(moment(a.date)));

          // Step 3: Get unique years in sorted order
          const uniqueYears = Array.from(
            new Set(
              allStories.map((story) => moment(story.date).format("YYYY"))
            )
          );

          // Step 4: Create a suffix iterator
          const suffixes = "123456789012345678901234567890".split("");

          // Step 5: Create a new object with stories grouped by year with suffixes
          const groupedStories: StoriesByYear = {};

          uniqueYears.forEach((year, index) => {
            const yearWithSuffix = `${year}, ${suffixes[index]}`; // Add suffix with a comma

            groupedStories[yearWithSuffix] = allStories.filter(
              (story) => moment(story.date).format("YYYY") === year
            );
          });
          setStories(groupedStories);
        } else if (timeframe === "Year") {
          // Flatten and sort all stories by date in descending order
          const allStories: Story[] = Object.entries(data).flatMap(
            ([_, stories]) => stories
          );

          allStories.sort((a, b) => moment(b.date).diff(moment(a.date)));

          // Group all stories under one key, e.g., "All Years"
          const groupedStories: StoriesByYear = {
            "All Years": allStories,
          };

          setStories(groupedStories);
        } else {
          setStories(data);
        }
      } else {
        setStories({});
      }
    } catch (err) {
      console.error("Failed to fetch stories:", err);
      setError("Failed to fetch stories. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStories();
  }, [timeframe, groupID, childID]);

  return { stories, isLoading, error, getStories };
};

export default useLearningStories;

import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import { TITLE } from "constants/title";

import {
  HOME,
  COMMUNITY_TEXT,
  MY_PROGRESS_TEXT,
  OUR_STORY_TEXT,
} from "constants/navbar";

type Props = {
  pages: { [item: string]: string };
  page: string;
  handleClose: (() => void) | undefined;
  icons: { [item: string]: string };
  text: { [item: string]: string };
  isMobileView?: boolean;
}

const NavbarMenuBlock = ({
  pages,
  page,
  handleClose,
  text,
  isMobileView = false,
}: Props) => {
  const location = useLocation();

  const isTabSelected =
    location.pathname === pages[page] ||
    (location.pathname.includes("/teacher-training-academy") && page === HOME) ||
    (location.pathname.includes("/my-progress") && page === MY_PROGRESS_TEXT) ||
    (location.pathname.includes(`/${COMMUNITY_TEXT}/`) &&
      page === COMMUNITY_TEXT) ||
    (location.pathname.includes(OUR_STORY_TEXT) &&
      page === OUR_STORY_TEXT);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => { 
    if (isTabSelected) {
      return;
    }
    
    if (!isMobileView && event instanceof MouseEvent) {
      handleClose && handleClose();
      document.title = `${TITLE} | ${page}`;
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "4px 8px",
        width: "145px",
        height: "32px",
        transition: "all 200ms ease-in-out",
        "&:hover": {
          transition: "all 200ms ease-in-out",
          backgroundColor: `background.paper`,
        },
        borderRadius: "8px",
        backgroundColor: isTabSelected
          ? "white"
          : "primary.light",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >  
      <Link
        to={pages[page]}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Typography
          sx={{
            color: "txt.dark",
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            lineHeight: 1.25,
            textAlign: "center",
          }}
        >
          {text[page]?.toUpperCase()}
        </Typography>
      </Link>
    </Box>
  );
};

export default NavbarMenuBlock;

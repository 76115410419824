import {
  Box,
  Typography,
  LinearProgress,
  LinearProgressProps,
} from "@mui/material";

type Props = LinearProgressProps & {
  minutesLeft?: number;
};

const LinearProgressWithLabel = ({ minutesLeft = 0, ...props }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
      }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography variant="body2" color="#8C8C8C">
          {`${Math.round(props.value ?? 0)}%`}
        </Typography>
        <Typography variant="body2" color="#8C8C8C">
          {minutesLeft == 0 ? "Completed!" : `${minutesLeft} minutes left`}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import { API_Fundamental_SHORT } from "types/project";
import Image from "components/Image/Image";
import useStore from "store/store";
import { getAllFundamentalsShort } from "api/fundamental-api";
import AssessmentSideTab from "components/SideTabs/AssessmentSideTab";

const DevGaolsSection = () => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [devGaols, setDevGaols] = useState<API_Fundamental_SHORT[]>([]);

  useEffect(() => {
    const populateProjectCategories = async () => {
      const data: API_Fundamental_SHORT[] = await getAllFundamentalsShort(currLanguage);
      setDevGaols(data);
    };
    populateProjectCategories();
  }, [currLanguage]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <Typography>Filter By Dev Goals</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {devGaols.map((goal) => (
          <AssessmentSideTab
            key={goal.id}
            title={goal.name}
            icon={
              goal.image !== "" && goal.image !== null ? (
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    overflow: "hidden",
                    mr: "12px",
                  }}
                >
                  <Image src={goal.image} alt={goal.name} objectFit="contain" />
                </Box>
              ) : (
                <AccessibilityNewIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                    overflow: "hidden",
                    mr: "12px",
                  }}
                />
              )
            }
            type="devGoal"
            slug={goal.slug}
          />
        ))}
      </Box>
    </Box>
  );
};

export default DevGaolsSection;

import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  API_Teacher_Module,
  API_Teacher_Module_Learner_Progress,
} from "types/teacher";
import useStore from "store/store";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import TeacherDetailCard from "../TeacherPageComponents/TeacherDetailCard/TeacherDetailsCard";

import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import { translation } from "constants/translation";
import DndGrid from "./DndModule/DndGrid";
import DndCard from "./DndModule/DndCard";
import { MODULE_CARD_HEIGHT, MODULE_CARD_WIDTH } from "../teacherPageConstants";
import { CURLANG, CREATE_TEACHER_MODULE_PAGE } from "constants/url";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Add } from "@mui/icons-material";

const AddModuleButton = () => {
  const navigate = useNavigate();

  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));

  return isProfileInTTAModuleEditableRoleList(profileDetails) ? (
    <Box
      sx={{
        flex: 1,
        minHeight: MODULE_CARD_HEIGHT,
        minWidth: MODULE_CARD_WIDTH,
        maxWidth: MODULE_CARD_WIDTH,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        borderRadius: "10px",
        color: "#444",
        border: "1px dashed",
        borderColor: "#444",
        transition: "all 100ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms ease-in-out",
        },
      }}
      onClick={() =>
        navigate(`${CURLANG(currLanguage)}/${CREATE_TEACHER_MODULE_PAGE}`)
      }
    >
      <AddIcon />
    </Box>
  ) : null;
};

type Props = {
  newModuleList: API_Teacher_Module[];
  setNewModuleList: Dispatch<SetStateAction<API_Teacher_Module[]>>;
  learnerModuleProgressList: API_Teacher_Module_Learner_Progress[];
};

const ModulesSection = ({
  newModuleList,
  setNewModuleList,
  learnerModuleProgressList,
}: Props) => {
  const { profileDetails, teacherModules, isTeacherModuleLoading } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      teacherModules: state.teacherModules,
      isTeacherModuleLoading: state.isTeacherModuleLoading,
    })
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "10px",
        width: "100%",
        maxWidth: "90vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mb: 4,
        }}
      >
        <Box>
          <Typography
            sx={{
              alignSelf: "center",
              color: "#5C9391",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            {translation.modules}
          </Typography>
        </Box>
        {newModuleList.length > 8 && (
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Typography sx={{ mr: 1 }}>View More</Typography>
            <ArrowRightAltIcon />
          </Box>
        )}
      </Box>
      <Box>
        {isTeacherModuleLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <LoadingIndicator />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {isProfileInTTAModuleEditableRoleList(profileDetails) &&
              newModuleList.length === teacherModules.length ? (
                <DndGrid
                  moduleList={newModuleList}
                  setModuleList={setNewModuleList}
                >
                  {newModuleList.map((module_, index) => {
                    const progress = learnerModuleProgressList.filter(
                      (learnerModuleProgress) =>
                        learnerModuleProgress.id === module_.id
                    )[0];
                    return (
                      <DndCard key={module_.id} module={module_}>
                        <TeacherDetailCard
                          key={`module-id-${module_.id}`}
                          module={module_}
                          progress={progress}
                        />
                      </DndCard>
                    );
                  })}
                </DndGrid>
              ) : (
                newModuleList.map((module_, index) => {
                  const progress = learnerModuleProgressList.filter(
                    (learnerModuleProgress) =>
                      learnerModuleProgress.id === module_.id
                  )[0];
                  const tag =
                    index === 0 || index === 1
                      ? "Popular"
                      : index === newModuleList.length - 2 ||
                        index === newModuleList.length - 1
                      ? "New"
                      : undefined;

                  return (
                    <TeacherDetailCard
                      key={`module-id-${module_.id}`}
                      module={module_}
                      progress={progress}
                      tag={tag}
                    />
                  );
                })
              )}
              <AddModuleButton />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ModulesSection;

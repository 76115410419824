import React, { useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";

import TagIcon from "@mui/icons-material/Tag";
import CropIcon from "@mui/icons-material/Crop";
import useStore from "store/store";
import useSnack from "hooks/useSnack";

import {
  tagProfilesToImage,
  editImage,
  tagProfilesToVideo,
} from "api/gallery-api";

import { API_Profile_Short } from "types/profile";
import { GalleryPhotoVideo } from "types/gallery";

import CustomImage from "../../Image/Image";

import "components/ProjectSummaryScreen/ReactGridGallery.css";

import { translation } from "constants/translation";

import {
  isProfileInGalleryAccessRoleList,
} from "helper/helper";
import ProfileTaggingMenu from "../ProfileTaggingMenu";

type Props = {
  open: boolean;
  photoVideoGalleryList: GalleryPhotoVideo[];
  galleryIndex: number;
  currPhotoVideoIndex: number;
  profileDict: {
    [id: string]: API_Profile_Short;
  };
  selectedProfiles: string[][][]; //Gallery, PhotoVideo index array, profile array
  editedPhoto: File | undefined;
  photoId: string;
  setSelectedProfiles: React.Dispatch<React.SetStateAction<string[][][]>>;
  setOpenEditModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setPhotoName: React.Dispatch<React.SetStateAction<string>>;
  setPhotoSource: React.Dispatch<React.SetStateAction<string>>;
  setEditedPhoto: React.Dispatch<React.SetStateAction<File | undefined>>;
  setPhotoId: React.Dispatch<React.SetStateAction<string>>;
  initializeGallery: () => Promise<void>;
  milestone?: any;
};

const ImageAndVideoBox = ({
  open,
  photoVideoGalleryList,
  galleryIndex,
  currPhotoVideoIndex,
  profileDict,
  selectedProfiles,
  editedPhoto,
  photoId,
  setSelectedProfiles,
  setOpenEditModal,
  setPhotoName,
  setPhotoSource,
  setEditedPhoto,
  setPhotoId,
  initializeGallery,
  milestone
}: Props) => {
  const {
    role,
    currLanguage,
  } = useStore((state) => ({
    ...state,
    role: state.profileDetails.role,
  }));

  const openSnack = useSnack();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    const photoVideoKeyStr =
      photoVideoGalleryList[galleryIndex].photo_video_list[
        currPhotoVideoIndex
      ].key!.toString();
    if (photoVideoKeyStr.includes("photo")) {
      tagProfilesToImage(
        photoVideoKeyStr.split("-")[1],
        selectedProfiles[galleryIndex][currPhotoVideoIndex]
      );
    } else {
      tagProfilesToVideo(
        photoVideoKeyStr.split("-")[1],
        selectedProfiles[galleryIndex][currPhotoVideoIndex]
      );
    }
  };

  const handleProfileTagChange = (profileIdList: string[]) => {
    setSelectedProfiles((prev: string[][][]) => {
      const newArr = [...prev];
      newArr[galleryIndex][currPhotoVideoIndex] = profileIdList;
      return newArr;
    });
  };


  const handleEditImage = () => {
    console.log('editing')
    //To assign Photo src, id and name, pass it to the edit model
    const selectedPhoto =
      photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex];
    const photoSource = selectedPhoto.src;
    const filePath = new URL(photoSource).pathname.slice(1);
    const fileExtension = filePath.split(".").pop();
    const fileName = filePath.split("_")[0] + "." + fileExtension;

    fetch(photoSource, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((res) => res.blob())
      .then((imageBlob) => setPhotoSource(URL.createObjectURL(imageBlob)));

    setPhotoId(selectedPhoto.key!.toString().split("-")[1]);
    setPhotoName(fileName);
    if (setOpenEditModal) {

      setOpenEditModal(true);
    }
  };

  useEffect(() => {
    if (editedPhoto === undefined || photoId === "") return;

    const updateImage = async () => {
      const res = await editImage(currLanguage, {
        id: photoId,
        image: editedPhoto,
      });

      if (res === "Error!") {
        openSnack(res, false);
      } else {
        openSnack(translation.success_update_image, true);
        setPhotoId("");
        setEditedPhoto(undefined);
        initializeGallery();
      }
    };

    updateImage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedPhoto, photoId]);


  const isPhoto = String(
    photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex]
      .key
  ).includes("photo");

  return (
    <Box
      sx={{
        height: 350,
        mx: "auto",
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box key={`video-gallery-${currPhotoVideoIndex}`}>
        {isPhoto ? (
          <CustomImage
            src={photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex].src}
            alt="Image"
          />
        ) : (
          <video
            height={"100%"}
            controls
            controlsList="nodownload"
            preload="metadata"
          >
            <source
              src={photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex].src}
              type="video/mp4"
            />
          </video>
        )}
      </Box>
      {isProfileInGalleryAccessRoleList(role) && (
        <>
          {Object.keys(profileDict).length !== 0 && (
            <Tooltip title={translation.tagChildren}>
              <IconButton
                onClick={handleOpenMenu}
                size="small"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <TagIcon
                  sx={{
                    color: "txt.dark",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isPhoto ? (
            <Tooltip title={translation.edit_image}>
              <IconButton
                onClick={handleEditImage}
                size="small"
                sx={{
                  position: "absolute",
                  top: Object.keys(profileDict).length !== 0 ? 40 : 0,
                  right: 0,
                }}
              >
                <CropIcon
                  sx={{
                    p: 0.25,
                    color: "txt.dark",
                    stroke: "currentcolor",
                    strokeWidth: "0.25px",
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      )}
      <ProfileTaggingMenu
        anchorEl={anchorEl}
        isOpenMenu={isOpenMenu}
        handleCloseMenu={handleCloseMenu}
        selectedProfiles={selectedProfiles}
        galleryIndex={galleryIndex}
        currPhotoVideoIndex={currPhotoVideoIndex}
        handleProfileTagChange={handleProfileTagChange}
        profileDict={profileDict}
      />
    </Box>
  );
};

export default ImageAndVideoBox;
import { Box, Typography } from "@mui/material";

import useStore from "store/store";
import LessonsContainer from "./LessonContainer";
import AddLessonButton from "./AddLessonButton";


type Props = {
  teacher: boolean;
}

const LessonPlanScreen = ({ teacher }: Props) => {
  const lessons = useStore((state) => state.currentLessons);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: { xs: "16px", sm: "30px" },
        justifyContent: "center",
        position: "relative",
        p: "30px 0",
      }}
      >
      {lessons.lessons.length > 0 ? (
        <LessonsContainer lessons={lessons.lessons} teacher={teacher} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            No Lessons
          </Typography>

          <AddLessonButton />
        </Box>
      )}
    </Box>
  );
};



export default LessonPlanScreen;

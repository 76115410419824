import React, { useEffect, useState } from "react";
import Image from "components/Image/Image";
import Logo from "images/logo-2.png";

import useStore from "store/store";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { unSlugify } from "helper/slugify";

const ClassFilterButton = ({
  selectedClassSlugs,
  setSelectedClassSlugs,
}: {
  selectedClassSlugs: string[];
  setSelectedClassSlugs: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { currLanguage, groupList } = useStore((state) => ({
    currLanguage: state.currLanguage,
    groupList: state.groupList,
  }));
  const [classSlugs, setClassSlugs] = useState<string[]>([]);

  useEffect(() => {
    const populateClassSlugs = async () => {
      const classSlugs = groupList.map((group) => group.slug);
      setSelectedClassSlugs(classSlugs);
      setClassSlugs(classSlugs);
    };
    groupList && groupList.length !== 0 && populateClassSlugs();
  }, [groupList.length]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedClassSlugs>
  ) => {
    // console.log("event:", event);
    if (event.target.value.includes("all") as boolean) {
      if (selectedClassSlugs.length === classSlugs.length) {
        setSelectedClassSlugs([]);
      } else {
        setSelectedClassSlugs(classSlugs);
      }
    } else {
      setSelectedClassSlugs(event.target.value as unknown as string[]);
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  return groupList && groupList.length !== 0 ? (
    <FormControl
      sx={{
        flex: 1,
        minWidth: 100,
      }}
      size="small"
    >
      <InputLabel id="module-selector">Class</InputLabel>
      <Select
        labelId="module-selector"
        label="Class"
        multiple
        value={selectedClassSlugs}
        onChange={handleChange}
        input={<OutlinedInput label="Class" />}
        renderValue={(selected) =>
          selectedClassSlugs.length === classSlugs.length
            ? "All Classes"
            : selectedClassSlugs.length === 0
            ? "No Class"
            : selected.map((select) => unSlugify(select)).join(", ")
        }
        MenuProps={MenuProps}
      >
        <MenuItem value="all">
          <ListItemText primary="Select All" />
          <Checkbox checked={selectedClassSlugs.length === classSlugs.length} />
        </MenuItem>
        {groupList.map((group) => {
          return (
            <MenuItem key={group.slug} value={group.slug}>
              <Image
                src={group.image ? group.image : Logo}
                style={{ width: 20, height: 20, marginRight: 8 }}
                alt={group.name}
              />
              <ListItemText
                primary={group.name}
                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
              />
              <Checkbox checked={selectedClassSlugs.indexOf(group.slug) > -1} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : null;
};

export default ClassFilterButton;

export const groupCardStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
  backgroundColor: "white",
  width: "20vw",
  minHeight: "30vh",
  height: "inherit",
  padding: 1.5,
  borderRadius: "15px",
};

export const teacherLessonContainerStyle = {
  display: "flex",
  gap: 4,
  mt: 1,
  width: "100%",
  overflow: "auto",
  pb: 1,
};

export const LESSON_CARD_MEDIA_HEIGHT = "200px";
export const LESSON_CARD_MODAL_MEDIA_HEIGHT = "200px";
export const LESSON_CONTAINER_NUMBER_WITHOUT_SCROLL = 3; // if less or equal this number, then remove scroll and justify content center

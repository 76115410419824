import { Box, Tab, Tabs } from "@mui/material";
import React, { useState, useEffect } from "react";
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import { Query_Data } from "types/project";
import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useStore from "store/store";
import { staticTabStyle } from "containers/ProjectSummaryPage/Screens/ConceptScreen";
import { CURLANG, TEACHER_PORTFOLIO } from "constants/url";
import { translation } from "constants/translation";
import {
  BY_CLASS,
  BY_YEAR,
  allCategoryTabs,
  translatedCategoryTabs,
} from "../teacherArchivePageConstant";
import { PROGRESS_REPORT } from "containers/ChildrenPortfolioPage/constants";
import { BreadCrumbsTitle } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/AlbumBreadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { unSlugify } from "helper/slugify";

type TeacherArchiveContextType = {
  query: string;
};

const initialQueryData = {
  query: null,
  startDate: null,
  endDate: null,
};

export const TeacherArchiveHeader = ({
  currentTab,
}: {
  currentTab: string;
}) => {
  // Search
  const [queryData, setQueryData] = useState<Query_Data>(initialQueryData);

  useEffect(() => {
    setQueryData(initialQueryData);
  }, [currentTab]);

  return (
    <Box sx={{ p: "16px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <SearchBar queryData={queryData} setQueryData={setQueryData} />
        </Box>
      </Box>
      <TeacherArchiveTabContainer currentTab={currentTab}>
        <Outlet context={{ query: queryData.query }} />
      </TeacherArchiveTabContainer>
    </Box>
  );
};

export const useTeacherArchiveContext = () =>
  useOutletContext<TeacherArchiveContextType>();

const TeacherArchiveTabContainer = ({
  currentTab,
  children,
}: {
  currentTab: string;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const categoryTabsFilterd =
    currentTab === PROGRESS_REPORT
      ? allCategoryTabs.slice(0, 2)
      : allCategoryTabs;

  const tabIndex = categoryTabsFilterd.findIndex((tab) =>
    pathname.includes(tab)
  );

  const handleTabChange = (event: React.SyntheticEvent, index: number) =>
    navigate(
      `${CURLANG(currLanguage)}/${TEACHER_PORTFOLIO}/${currentTab}/${
        categoryTabsFilterd[index]
      }`
    );

  useEffect(() => {
    if (
      !categoryTabsFilterd.includes(String(pathname).split("/").slice(-1)[0])
    ) {
      navigate(
        `${CURLANG(currLanguage)}/${TEACHER_PORTFOLIO}/${currentTab}/${
          categoryTabsFilterd[0]
        }`
      );
    }
  }, [currentTab]);

  return (
    <>
      <Tabs
        value={tabIndex === -1 ? 0 : tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {categoryTabsFilterd.map((tab, index) => (
          <Tab
            key={index}
            label={translatedCategoryTabs[tab]}
            sx={{
              ...staticTabStyle,
              ml: index === 0 ? 0 : 2,
              backgroundColor:
                index === tabIndex ? "primary.light" : "transparent",
              pointerEvents: index === tabIndex ? "none" : "auto",
            }}
          />
        ))}
      </Tabs>

      <Box
        sx={{
          p: 3,
          border: "2px solid var(--primary-main)",
          backgroundColor: "background.paper",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export const TeacherArchiveContainerBreadCrumbs = ({
  classSlug,
  childName,
}: {
  classSlug: string;
  childName: string | undefined;
}) => {
  const [_, setSearchParams] = useSearchParams();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        mb: 1.25,
        minHeight: "20px",
      }}
    >
      <BreadCrumbsTitle
        title={translation.progressReport}
        isActive={false}
        onClick={() => {
          setSearchParams({});
        }}
      />

      <NavigateNextIcon fontSize="small" />

      <BreadCrumbsTitle
        title={unSlugify(classSlug)}
        isActive={!childName}
        onClick={() => {
          setSearchParams({ classSlug: classSlug });
        }}
      />

      {!!childName && (
        <>
          <NavigateNextIcon fontSize="small" />
          <BreadCrumbsTitle title={childName} isActive={!!childName} />
        </>
      )}
    </Box>
  );
};

import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { getLessonBySlug } from "../api/teacher-api";

import useStore from "../store/store";
import useSnack from "./useSnack";

const useInitialiseTeacherLesson = () => {
  const openSnack = useSnack();
  const { lessonSlug } = useParams();

  const { setTeacherCurrentLesson, currLanguage } = useStore((state) => ({
    setTeacherCurrentLesson: state.setTeacherCurrentLesson,
    currLanguage: state.currLanguage,
  }));

  const initialiseLesson = async (isShowLoading: boolean) => {
    // if (isShowLoading) {
    //   setIsRefreshLessonDiscussionPost(true);
    // }
    if (lessonSlug) {
      const lesson = await getLessonBySlug(currLanguage, lessonSlug);
      // console.log("Returned lesson: ", lesson);
      if (typeof lesson === "string") {
        openSnack(lesson, false);
      } else {
        setTeacherCurrentLesson(lesson);
      }
    }
    // setIsRefreshLessonDiscussionPost(false);
  };

  useEffect(() => {
    initialiseLesson(true);
  }, [lessonSlug]);

  // useEffect(() => {
  //   isRefreshLessonDiscussionPost && initialiseLesson(false);
  // }, [isRefreshLessonDiscussionPost]);
};

export default useInitialiseTeacherLesson;

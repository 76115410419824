import axios from "api/axiosConfig";
import { AxiosError } from "axios";

import {
  API_Review,
  API_Update_Review,
  API_Review_Response,
  API_Create_Review_Like,
  API_Review_Like,
  API_Create_Review_Comment,
  API_Create_Comment_Like,
  API_Comment_Like,
  API_Review_Comment,
  API_Update_Review_Comment,
  API_Prohibited_Word,
  API_Prohibited_Word_Create,
  API_Update_Development_Feedback,
  API_Development_Feedback_Response,
  API_Development_Feedback,
} from "types/review";
import { API_Project_Review } from "types/project";

//Development feedback start
export const getIsDevelopmentFeedbackDone = async (
  projectSlug: string,
  language: string,
  accessToken: string
) => {
  try {
    const res = await axios.get(
      language +
        `/review/development-feedback/is-development-feedback-done?projectSlug=${projectSlug}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: boolean = await res.data[0];
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const createDevelopmentFeedback = async (
  feedbackData: API_Development_Feedback,
  accessToken: string | null,
  language: string
) => {
  try {
    const res = await axios.post(
      language + "/review/development-feedback/create",
      feedbackData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const updateDevelopmentFeedback = async (
  language: string,
  feedbackId: number,
  feedbackData: API_Update_Development_Feedback,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(
      language + `/review/development-feedback/${feedbackId}/update`,
      feedbackData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Development_Feedback_Response = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const deleteDevelopmentFeedback = async (
  language: string,
  feedbackId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.delete(
      language + `/review/development-feedback/${feedbackId}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const deleteDevelopmentFeedbackImages = async (
  language: string,
  imageIDs: number[],
  accessToken: string | null
) => {
  try {
    await axios.delete(language + `/review/development-feedback/image/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        imageIDs,
      },
    });

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};
//Development feedback end

export const getProjectReviewList = async (
  projectSlug: string,
  isMostLike: boolean,
  accessToken: string | null,
  language: string
) => {
  try {
    const res = await axios.get(
      language +
        `/review/list?projectSlug=${projectSlug}&isMostLike=${
          isMostLike ? "true" : "false"
        }`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Project_Review[] = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const createReview = async (
  reviewData: API_Review,
  accessToken: string | null,
  language: string
) => {
  try {
    const res = await axios.post(language + "/review/create", reviewData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const updateReview = async (
  language: string,
  reviewID: number,
  reviewData: API_Update_Review,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(
      language + `/review/${reviewID}/update`,
      reviewData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Review_Response = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const deleteReview = async (
  language: string,
  reviewID: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.delete(language + `/review/${reviewID}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const deleteReviewImages = async (
  language: string,
  imageIDs: number[],
  accessToken: string | null
) => {
  try {
    await axios.delete(language + `/review/image/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        imageIDs,
      },
    });

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const likeReview = async (
  language: string,
  likeData: API_Create_Review_Like,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(language + "/review/like/create", likeData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data: API_Review_Like = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const unlikeReview = async (
  language: string,
  likeID: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.delete(language + `/review/like/${likeID}/delete`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const createComment = async (
  language: string,
  commentData: API_Create_Review_Comment,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      language + "/review/comment/create",
      commentData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Create_Review_Comment = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const updateComment = async (
  language: string,
  commentID: number,
  commentData: API_Update_Review_Comment,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(
      language + `/review/comment/${commentID}/update`,
      commentData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Review_Comment = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const deleteComment = async (
  language: string,
  commentID: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.delete(
      language + `/review/comment/${commentID}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const likeComment = async (
  language: string,
  likeData: API_Create_Comment_Like,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      language + "/review/comment/like/create",
      likeData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Comment_Like = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

export const unlikeComment = async (
  language: string,
  likeID: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.delete(
      language + `/review/comment/like/${likeID}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

//prohibited words
export const getProhibitedWordList = async (language: string) => {
  const res = await axios.get(language + `/review/prohibited-word/list`);
  const data: API_Prohibited_Word[] = await res.data;
  return data;
};

export const createProhibitedWord = async (
  language: string,
  prohibitedWordData: API_Prohibited_Word_Create,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      language + "/review/prohibited-word/create",
      prohibitedWordData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Prohibited_Word = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error! The word already existed.";
  }
};

export const updateProhibitedWord = async (
  language: string,
  prohibitedWordData: API_Prohibited_Word,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(
      language + `/review/prohibited-word/${prohibitedWordData.id}/update`,
      prohibitedWordData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data: API_Prohibited_Word = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error! The word already existed.";
  }
};

export const deleteProhibitedWord = async (
  language: string,
  prohibitedWordId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.delete(
      language + `/review/prohibited-word/${prohibitedWordId}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    return "Error";
  }
};

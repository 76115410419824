import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ChatComponent from "./ChatComponent";
import useStore from "store/store";
import ChatHeader from "./ChatHeader";

const SidePanel = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { allChats } = useStore((state) => ({
    allChats: state.allChats,
  }));

  const chats =
    searchQuery === ""
      ? allChats
      : allChats.filter(
          (chat) =>
            (chat.name !== null &&
              chat.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            chat.profiles.find((profile) =>
              profile.preferred_name
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            )
        );

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { xs: "100vw", sm: "320px" },
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid #E1E2E9",
      }}
    >
      <ChatHeader />

      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {chats.length !== 0 ? (
          chats
            .sort(
              (x, y) =>
                new Date(y.latest_messages?.[0]?.timestamp).valueOf() -
                new Date(x.latest_messages?.[0]?.timestamp).valueOf()
            )
            .map((chat) => (
              <ChatComponent key={chat.id} chat={chat} isSidePanel={true} />
            ))
        ) : (
          <Typography sx={{ textAlign: "center", px: 3, py: 2 }}>
            {searchQuery !== "" ? "No chats found" : "No chat yet"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const SearchBar = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <TextField
    fullWidth
    variant="standard"
    size="small"
    placeholder="Search name"
    value={searchQuery}
    onChange={(event) => setSearchQuery(event.target.value)}
    onKeyDown={(event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    }}
    InputProps={{
      startAdornment: (
        <SearchIcon
          sx={{
            color: "#312F30",
            opacity: 0.5,
            mr: 1,
          }}
        />
      ),
      disableUnderline: true,
      sx: {
        py: 2,
        px: 3,
      },
    }}
    inputProps={{
      style: {
        padding: 0,
      },
    }}
    sx={{
      borderTop: "1px solid #E1E2E9",
      borderBottom: "1px solid #E1E2E9",
    }}
  />
);

export default SidePanel;

import { Page, Image, View, StyleSheet } from "@react-pdf/renderer";
import { imageSrc, pageSize, styles } from "./constant";
import { PDFInfoType } from "../Preview/constant";
import IntroductionPageBackground from "images/children-portfolio-v2/introduction.png";

const templateStyles = StyleSheet.create({
  text: {
    fontWeight: 500,
    lineHeight: 1.25,
    textAlign: "left",
    position: "absolute",
    left: 0,
    right: 0,
  },
});

const IntroPage = ({ PDFInfo }: { PDFInfo: PDFInfoType }) => {
  return (
    <Page size={pageSize} style={styles.body} wrap={false}>
      <View style={styles.main} wrap={false}>
        <Image
          src={imageSrc(IntroductionPageBackground)}
          style={styles.background}
        />
        {/* <Text
          style={[
            templateStyles.text,
            {
              fontFamily: "FuturaLtBT",
              textAlign: "left",
              lineHeight: "1.50",
              fontSize: 14,
              top: 90,
              marginHorizontal: 40,
            },
          ]}
        >
          {PDFInfo.introductionText}
        </Text> */}
      </View>
    </Page>
  );
};

export default IntroPage;

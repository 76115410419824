import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Image from "../../../Image/Image";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";

interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
  scale: number;
};

const ImageStructureForFour: React.FC<ImageStructureProps> = ({
  projects,
  scale,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid container spacing={4} sx={{ width: "100%" }}>
        {projects.map((project, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                height: `${250 * scale}px`,
                width: `${350 * scale}px`,
                boxSizing: "border-box",
              }}
            >
              {/* Frame Image */}
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  mb: `${`-${20 * scale}px`}`,
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                  }}
                  src={Frame}
                  alt="frame"
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "15.4%",
                    left: "11%",
                    width: "79%",
                    height: "53%",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                  src={project.image_url}
                  alt={project.title || "projects"}
                />
              </Box>

              {/* Caption positioned below the frame */}
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#F9F6F1",
                  textAlign: "center",
                  mt: `${`-${25 * scale}px`}`,
                }}
              >
                <Typography
                  sx={{
                    fontSize: `${14 * scale}px`,
                    fontFamily: "FuturaBkBT",
                    color: "#000",
                    lineHeight: "22px",
                    letterSpacing: "1.4px",
                    fontWeight: 400,
                  }}
                >
                  {project.category}
                </Typography>
                <Typography
                  sx={{
                    fontSize: `${12 * scale}px`,
                    fontFamily: "FuturaBkBT",
                    color: "#000",
                    lineHeight: "18px",
                    letterSpacing: "1.2px",
                    fontWeight: 300,
                  }}
                >
                  {project.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ImageStructureForFour;

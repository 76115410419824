import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Calendar,
  momentLocalizer,
  Views,
  stringOrDate,
  SlotInfo,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import { convert24HrsTo12Hrs } from "helper/timetable";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { API_Timetable_Event_Info } from "types/timetable";
import {
  TIME_FORMAT,
  MIN_TIME,
  MAX_TIME,
  DISPLAY_TIME_FORMAT,
} from "constants/timetable";
import { EventCardPopup } from "./TimetableComponents";
import useColors from "hooks/useColors";
import { translation } from "constants/translation";
import "./RBC.css";

const localizer = momentLocalizer(moment);

const ViewTimetable = ({
  events,
  onlyToday,
  setOnlyToday,
}: {
  events: API_Timetable_Event_Info[];
  onlyToday: boolean;
  setOnlyToday: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const colors = useColors();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const min = moment(MIN_TIME, TIME_FORMAT);
  const max = moment(MAX_TIME, TIME_FORMAT);

  const isTimeRangeExceeded = (date: stringOrDate) =>
    moment(moment(date).format(TIME_FORMAT), TIME_FORMAT).isAfter(max) ||
    moment(moment(date).format(TIME_FORMAT), TIME_FORMAT).isBefore(min);

  const isMaxTime = (date: stringOrDate) =>
    moment(date).format(TIME_FORMAT) === max.format(TIME_FORMAT);

  const GetUpdatedTime = (date: stringOrDate, isEnd: boolean) => {
    return isEnd && isTimeRangeExceeded(date)
      ? moment(MAX_TIME, TIME_FORMAT).format(DISPLAY_TIME_FORMAT)
      : isEnd && !isMaxTime(date) && moment(date).second() === 59
      ? moment(date).add(1, "second").format(DISPLAY_TIME_FORMAT)
      : moment(date).format(DISPLAY_TIME_FORMAT);
  };

  const formatTimeRange = ({
    start,
    end,
  }: {
    start: stringOrDate;
    end: stringOrDate;
  }) => `${GetUpdatedTime(start, false)} — ${GetUpdatedTime(end, true)}`;

  useEffect(() => {
    if (isSmallScreen) {
      setOnlyToday(true);
    } else {
      setOnlyToday(false);
    }
  }, [isSmallScreen]);

  return (
    <Box
      className="custom-rbc"
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Calendar
        localizer={localizer}
        slotPropGetter={(date: Date) => {
          const currentDate = moment();
          if (currentDate.isSame(date, "day") && !onlyToday) {
            return {
              style: {
                backgroundColor: "#FBFBFB",
              },
            };
          } else {
            return {};
          }
        }}
        view={onlyToday ? Views.DAY : Views.WEEK}
        min={min.toDate()}
        max={max.toDate()}
        step={15}
        timeslots={2}
        toolbar={false}
        dayLayoutAlgorithm="overlap"
        events={events.map((event) => ({
          ...event,
          start: moment(event.start, TIME_FORMAT).day(event.day).toDate(),
          end: moment(event.end, TIME_FORMAT).day(event.day).toDate(),
        }))}
        formats={{
          timeGutterFormat: (date: Date) =>
            moment(date).format(DISPLAY_TIME_FORMAT),
          eventTimeRangeStartFormat: formatTimeRange,
          eventTimeRangeEndFormat: formatTimeRange,
          eventTimeRangeFormat: formatTimeRange,
          selectRangeFormat: formatTimeRange,
        }}
        components={{
          event: ({ event }: { event: any }) => (
            <EventCard event={event} onlyToday={onlyToday} />
          ),
        }}
        eventPropGetter={(event: object) => {
          const color =
            colors[(event as API_Timetable_Event_Info).color % colors.length];
          return {
            style: {
              backgroundColor: color,
              width: "100%",
              m: 0,
              p: 0,
            },
          };
        }}
      />
    </Box>
  );
};

const EventCard = ({
  event,
  onlyToday,
}: {
  event: API_Timetable_Event_Info;
  onlyToday: boolean;
}) => {
  const colors = useColors();
  const [open, setOpen] = useState<boolean>(false);
  const startTime = convert24HrsTo12Hrs(event.start);
  const endTime = convert24HrsTo12Hrs(event.end);
  const color = colors[event.color % colors.length];

  const startDate = new Date(event.start);
  const endDate = new Date(event.end);

  const starthours = startDate.getHours();
  const startminutes = startDate.getMinutes();
  const endhours = endDate.getHours();
  const endminutes = endDate.getMinutes();

  const duration = endhours * 60 + endminutes - (starthours * 60 + startminutes);

  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  return (
    <>
      <Box
        sx={{
          pt: duration < 30 || (isSm && !onlyToday) ? 0 : 1,
          pl: duration < 30 || (isSm && !onlyToday) ? 0 : 1,
          borderRadius: "10px",
          backgroundColor: color,
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          height: "100%",
        }}
        onClick={() => setOpen(true)}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: duration < 30 || (isSm && !onlyToday) ? "12px" : "16px",
            wrap: "justify",
          }}
        >
          {event.name}
        </Typography>

        <Typography
          sx={{
            color: "black",
            backgroundColor: "white",
            fontSize: duration < 30 || (isSm && !onlyToday) ? "10px" : "12px",
            lineHeight: 1,
            py: 0.5,
            px: 1,
            borderRadius: "6px",
            display: "inline-block",
            mt: 0.25,
            mb: 0.75,
          }}
        >
          {event.type}
        </Typography>

        <Typography
          sx={{
            fontSize: duration < 30 || (isSm && !onlyToday) ? "10px" : "12px",
            lineHeight: 1,
            color: "black",
          }}
        >
          {startTime} - {endTime}
        </Typography>
      </Box>

      {open && <EventCardPopup event={event} open={open} setOpen={setOpen} />}
    </>
  );
};
export default ViewTimetable;

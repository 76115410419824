import { StoreSlice } from './store';

export interface NavbarSlice {
  showNavbar: boolean;
  setShowNavbar: (show: boolean) => void;
}

export const createNavbarSlice: StoreSlice<NavbarSlice> = (set, get) => ({
  showNavbar: false,
  setShowNavbar: (show: boolean) => {
    set((prev: NavbarSlice) => ({
      ...prev,
      showNavbar: show,
    }));
  },
});

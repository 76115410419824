import { useEffect } from "react";

import {
  getEvaluationQuestionnaires,
} from "../api/evaluationquestionnaire-api";

import useStore from "../store/store";

const useInitialiseEvaluationQuestionnaires = () => {
  const {
    setEvaluationQuestionnaires,
    setSnackMessage,
    setSnackSuccess,
    setSnackOpen,
  } = useStore((state) => ({
    setEvaluationQuestionnaires: state.setEvaluationQuestionnaires,
    setSnackMessage: state.setSnackMessage,
    setSnackSuccess: state.setSnackSuccess,
    setSnackOpen: state.setSnackOpen,
    currLanguage: state.currLanguage,
  }));

  useEffect(() => {
    const initialiseEvaluationQuestionnaire = async () => {
      const evaluationQuestionnaires = await getEvaluationQuestionnaires();
      if (typeof evaluationQuestionnaires === "string") {
        setSnackMessage(evaluationQuestionnaires);
        setSnackSuccess(false);
        setSnackOpen(true);
        console.log("Error: ", evaluationQuestionnaires);
      } else {
        setEvaluationQuestionnaires(evaluationQuestionnaires);
      }
    };
    initialiseEvaluationQuestionnaire();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useInitialiseEvaluationQuestionnaires;

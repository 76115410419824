import React from "react";
import { TextField } from "@mui/material";

import {
  API_Create_Lesson,
} from "types/project";
import { translation } from "constants/translation";

const NameField = ({
  label,
  lesson,
  setLesson,
  onChange,
}: {
  label?: string;
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={label ?? translation.lesson_name}
    value={lesson.name ? lesson.name : ""}
    onChange={(event) => {
      setLesson((prev) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

export default NameField;
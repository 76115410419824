import { Typography } from "@mui/material";

export const MaxQueryAdvice = ({ maxQuery }: { maxQuery: number }) => {
  return (
    <Typography sx={{ mt: 1, fontSize: 12, textAlign: "center" }}>
      {`Please note that each page on our platform displays a maximum of ${maxQuery} items for optimal performance. To find specific items beyond this limit, we encourage you to use the filter options to refine your search accordingly.`}
    </Typography>
  );
};

export const ClickSearchToBegin = () => {
  return (
    <Typography sx={{ textAlign: "center", fontSize: 30, fontWeight: 500 }}>
      No result!
    </Typography>
  );
};

export const teacherArchiveCardStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: 2,
  justifyContent: {
    xs: "center",
    md: "flex-start",
  },
};

export const MAX_QUERY = 20;

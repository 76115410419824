import React, { useEffect, useState } from "react";
import {
  API_Announcement,
  API_Announcement_Profile,
} from "types/announcement";
import { useParams } from "react-router-dom";
// import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { getAnnouncementProfileList } from "api/announcement-api";
// import { translation } from "constants/translation";
// import TickImage from "images/tick.png";
import EventNoteIcon from "@mui/icons-material/EventNote";

import {
  Avatar,
  Box,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import PaperClip from "images/paper-clip.png";
import { CustomModal } from "containers/AnnouncementsPage/AnnouncementComponents";
import {
  ACKNOWLEDGE,
  CHECK_BOXES,
  DECISION,
  MULTIPLE_CHOICE,
  NO,
  YES,
} from "containers/AnnouncementsPage/announcementConstant";
import Image from "components/Image/Image";
import Logo from "images/logo-2-colour.png";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

export const AnnouncementChartModal = ({
  open,
  handleClose,
  announcement,
}: {
  open: boolean;
  handleClose: () => void;
  announcement: API_Announcement;
}) => {
  const theme = useTheme();
  const { childID } = useParams();
  // const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const [announcementProfileList, setAnnouncementProfileList] = useState<
    API_Announcement_Profile[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const populateAnnouncementProfileList = async () => {
      setIsLoading(true);
      const announcementProfileList = await getAnnouncementProfileList(
        currLanguage,
        Number(announcement.id)
      );

      if (typeof announcementProfileList !== "string") {
        setAnnouncementProfileList(announcementProfileList);
      }

      setIsLoading(false);
    };

    populateAnnouncementProfileList();
    //eslint-disable-next-line
  }, [currLanguage, childID, announcement.id, open]);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <CustomModal
      open={open}
      handleClose={handleClose}
      sx={{ maxWidth: { xs: 350, sm: 450, md: 700, lg: 1000 } }}
    >
      <Typography
        sx={{
          fontSize: "1.5rem",
          fontWeight: 500,
          mb: 1,
          textAlign: "center",
        }}
      >
        {announcement.name}
      </Typography>
      <Box
        sx={{ backgroundColor: theme.palette.custom.lightBlue, p: 1, mb: 1 }}
      >
        <Box
          className="ck-content"
          sx={{
            fontSize: 14,
            lineHeight: 1.5,
            color: "#312F30",
            "& figure": {
              display: "block",
            },
          }}
          dangerouslySetInnerHTML={{
            __html:
              announcement.announcement_response_information.response_question,
          }}
        />
      </Box>

      <Typography
        sx={{ textAlign: "center", fontSize: "1.25rem", fontWeight: 500 }}
      >
        Response
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          justifyContent: "space-between",
        }}
      >
        {announcementProfileList.map((announcementProfile, index) => {
          return (
            <>
              <AnnouncementProfileInfo
                announcement={announcement}
                announcementProfile={announcementProfile}
              />
              <Divider />
            </>
          );
        })}
      </Box>
    </CustomModal>
  );
};

const AnnouncementProfileInfo = ({
  announcement,
  announcementProfile,
}: {
  announcement: API_Announcement;
  announcementProfile: API_Announcement_Profile;
}) => {
  const theme = useTheme();
  const noResponseTextStyle = { fontStyle: "italic" };
  const responseTypeText =
    announcement.announcement_response_information.response_type;
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          minWidth: 150,
          maxWidth: 150,
          alignItems: "center",
        }}
      >
        <Avatar
          src={
            announcementProfile.profile_image
              ? announcementProfile.profile_image
              : Logo
          }
          sx={{
            mb: 1,
            width: 40,
            height: 40,
            backgroundColor: "primary.main",
          }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        />

        <Typography
          sx={{
            fontSize: "1.0rem",
            lineHeight: "1.25",
            fontWeight: "normal",
            wordWrap: "break-word",
            overflow: "auto",
          }}
        >
          {announcementProfile?.profile_preferred_name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          width: "100%",
          alignSelf: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "left",
            }}
          >
            {responseTypeText === ACKNOWLEDGE ? (
              announcementProfile.response_answer_text !== "" ? (
                <Typography
                  sx={{
                    color: "primary.main",
                  }}
                >
                  Acknowledged
                </Typography>
              ) : (
                <Typography sx={noResponseTextStyle}>No reponse yet</Typography>
              )
            ) : responseTypeText === DECISION ? (
              <>
                {announcementProfile.response_answer_text === YES ? (
                  <Typography
                    sx={{
                      border: "1px solid var(--primary-main)",
                      px: 1,
                      width: "fit-content",
                    }}
                  >
                    {YES}
                  </Typography>
                ) : announcementProfile.response_answer_text === NO ? (
                  <Typography
                    sx={{
                      border: "1px solid var(--primary-main)",
                      px: 1,
                      width: "fit-content",
                    }}
                  >
                    {NO}
                  </Typography>
                ) : (
                  <Typography sx={noResponseTextStyle}>
                    No reponse yet
                  </Typography>
                )}
              </>
            ) : (responseTypeText === CHECK_BOXES ||
                responseTypeText === MULTIPLE_CHOICE) &&
              announcementProfile.response_answer_text !== "" ? (
              announcementProfile.response_answer_text
                .split(";")
                .map((answer) => {
                  if (!answer) {
                    return null;
                  }
                  return (
                    <Typography>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ width: "10px", marginRight: "10px" }}>
                          &bull;
                        </span>
                        <span>{answer}</span>
                      </span>
                    </Typography>
                  );
                })
            ) : (
              <Typography sx={noResponseTextStyle}>No reponse yet</Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              minWidth: 200,
              maxWidth: 300,
            }}
          >
            {announcement.announcement_response_information
              .is_allow_attachment && (
              <>
                {announcementProfile.files?.map((file) => (
                  <a
                    key={file.id}
                    href={file.file}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Tooltip title={file.name}>
                      <Box
                        sx={{
                          borderRadius: 24,
                          border: "1px solid var(--primary-main)",
                          display: "flex",
                          gap: 0.5,
                          alignItems: "center",
                          p: 1,
                        }}
                      >
                        {[".png", ".jpg", ".jpeg"].some((ext) =>
                          file.file.includes(ext)
                        ) && (
                          <ImageIcon
                            sx={{
                              width: 24,
                              height: 24,
                              color: "primary.main",
                            }}
                          />
                        )}

                        {[".pdf"].some((ext) => file.file.includes(ext)) && (
                          <Image
                            src={PaperClip}
                            alt="paper-clip"
                            style={{ width: 24, height: 24 }}
                          />
                        )}
                        <Typography
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {file.name}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </a>
                ))}
              </>
            )}
          </Box>
        </Box>
        {announcement.announcement_response_information.is_allow_text &&
          announcementProfile.text_response && (
            <Box sx={{ display: "flex", gap: 1 }}>
              <EventNoteIcon sx={{ color: theme.palette.primary.main }} />
              <Typography>{announcementProfile.text_response}</Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
};

import { useState } from "react";
import { Stack } from "@mui/material";

import { ActivityTypeField } from "../TeacherFormComponents/ActivityFormComponents";
import {
  RESOURCE_TYPE_DRAG_AND_DROP,
  RESOURCE_TYPE_FLIP_CARD,
  RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY,
  RESOURCE_TYPE_MCQ,
  RESOURCE_TYPE_PICTORIAL_OBSERVATION,
  RESOURCE_TYPE_TEXT,
  RESOURCE_TYPE_VIDEO,
} from "../../teacherPageConstants";
import CreateTextActivityForm from "./ActivityForms/CreateTextActivityForm";
import CreateVideoActivityForm from "./ActivityForms/CreateVideoActivityForm";
import CreateFlipCardActivityForm from "./ActivityForms/CreateFlipCardActivityForm";
import CreateMcqActivityForm from "./ActivityForms/CreateMcqActivityForm";
import CreateInteractiveDataEntryActivityForm from "./ActivityForms/CreateInteractiveDataEntryActivityForm";
import CreatePictorialObservationActivityForm from "./ActivityForms/CreatePictorialObservationActivityForm";
import CreateDragAndDropActivityForm from "./ActivityForms/CreateDragAndDropActivityForm";

const CreateActivityForm = ({
  lessonId,
  handleClose,
}: {
  lessonId: number;
  handleClose: () => void;
}) => {
  const [resourcetype, setResourcetype] = useState<any>({
    resourcetype: RESOURCE_TYPE_TEXT,
  });

  return (
    <Stack
      component="form"
      sx={{
        width: "100%",
        mb: 8,
      }}
      spacing={3}
      noValidate
      autoComplete="off"
    >
      <ActivityTypeField
        activity={resourcetype}
        setActivity={setResourcetype}
      />
      {resourcetype.resourcetype === RESOURCE_TYPE_TEXT && (
        <CreateTextActivityForm lessonId={lessonId} handleClose={handleClose} />
      )}
      {resourcetype.resourcetype === RESOURCE_TYPE_VIDEO && (
        <CreateVideoActivityForm
          lessonId={lessonId}
          handleClose={handleClose}
        />
      )}
      {resourcetype.resourcetype === RESOURCE_TYPE_FLIP_CARD && (
        <CreateFlipCardActivityForm
          lessonId={lessonId}
          handleClose={handleClose}
        />
      )}
      {resourcetype.resourcetype === RESOURCE_TYPE_DRAG_AND_DROP && (
        <CreateDragAndDropActivityForm
          lessonId={lessonId}
          handleClose={handleClose}
        />
      )}

      {resourcetype.resourcetype === RESOURCE_TYPE_MCQ && (
        <CreateMcqActivityForm lessonId={lessonId} handleClose={handleClose} />
      )}
      {resourcetype.resourcetype === RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY && (
        <CreateInteractiveDataEntryActivityForm
          lessonId={lessonId}
          handleClose={handleClose}
        />
      )}
      {resourcetype.resourcetype === RESOURCE_TYPE_PICTORIAL_OBSERVATION && (
        <CreatePictorialObservationActivityForm
          lessonId={lessonId}
          handleClose={handleClose}
        />
      )}
    </Stack>
  );
};

export default CreateActivityForm;

import { Box } from "@mui/system";
import React from "react";
import {
  BACKPACK_HEIGHT,
} from "containers/CalendarPage/constants/constants";
import { useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const BackpackToggleButton = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        // bottom: `${BACKPACK_HEIGHT - 58}px`,
        width: "40px",
        height: "40px",
        backgroundColor: "primary.light",
        transform: open
          ? isXs
            ? "translateY(-55vh)"
            : `translateY(-${BACKPACK_HEIGHT}px)`
          : "",

        // transform: open ? `translateY(-${BACKPACK_HEIGHT}px)` : ``,
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        transition: "all 200ms ease-in-out",
        zIndex: (theme: any) => (isXs ? theme.zIndex.drawer + 2 : 10),

        // zIndex: (theme) => theme.zIndex.drawer + 2, // higher zIndex than the Backdrop
      }}
      onClick={() => setOpen((prev) => !prev)}
    >
      {open ? <CloseIcon /> : <ShoppingBagIcon />}
    </Box>
  );
};

export default BackpackToggleButton;

import {
  Box,
  Modal,
  Fade,
  Backdrop,
  Typography,
  Checkbox,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ReactNode } from "react";
import Button from "components/Button/Button";
import { translation } from "constants/translation";
import Image from "components/Image/Image";
import { Age_Group } from "types/project";

export interface Answer {
  interests: number[];
  goals: number[];
  level: Age_Group | string;
}

export const CustomModal = ({
  open,
  children,
  isQuestion = false,
}: {
  open: boolean;
  children: React.ReactNode;
  isQuestion?: boolean;
}) => (
  <Modal
    open={open}
    onClose={() => {}}
    closeAfterTransition
    disableAutoFocus={true}
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      sx: {
        backgroundColor: "rgba(49,47,48,0.4)",
      },
    }}
    sx={{
      m: "24px",
    }}
  >
    <Fade in={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "background.paper",
          p: 3,
          pb: 2,
          borderRadius: "10px",
          overflow: isQuestion ? "hidden" : "auto",
          height: isQuestion ? "100%" : "auto",
          display: isQuestion ? "flex" : "block",
          maxHeight: "100%",
          color: "#312F30",
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        {children}
      </Box>
    </Fade>
  </Modal>
);

export const Container = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 2,
      width: "100%",
      position: "relative",
    }}
  >
    {children}
  </Box>
);

export const Title = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle?: string;
}) => (
  <Typography
    sx={{
      fontWeight: 500,
      textAlign: "center",
      fontSize: 20,
    }}
  >
    {title}
    {subTitle && (
      <Typography
        sx={{
          display: "block",
          textAlign: "center",
          fontStyle: "italic",
          fontSize: 14,
        }}
        component="span"
      >
        {subTitle}
      </Typography>
    )}
  </Typography>
);

export const ProgressBar = ({ pageNumber }: { pageNumber: number }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 1.5,
      mb: 0.5,
    }}
  >
    {Array.from({ length: 3 }).map((_, index) => (
      <Box
        key={index}
        sx={{
          height: "4px",
          width: "36px",
          backgroundColor: index < pageNumber - 1 ? "primary.main" : "#F0F0F0",
        }}
      />
    ))}
  </Box>
);

export const BottomNavigation = ({
  pageNumber,
  setCurrentPage,
  disabled,
  onSubmit,
  isLoading = false,
}: {
  pageNumber: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  onSubmit: () => void;
  isLoading?: boolean;
}) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: pageNumber !== 1 ? "repeat(3, 1fr)" : "1fr",
      alignItems: "center",
      gap: 2,
      mt: "auto",
    }}
  >
    {pageNumber !== 1 && (
      <>
        <CustomButton
          text={pageNumber === 2 ? translation.intro : translation.previous}
          onClick={() => setCurrentPage(pageNumber - 1)}
          isPrevious={true}
        />

        <Typography
          sx={{
            fontSize: 14,
            lineHeight: 1,
            opacity: 0.5,
            justifySelf: "center",
          }}
        >
          {pageNumber - 1} / 3
        </Typography>
      </>
    )}

    <CustomButton
      text={
        isLoading
          ? translation.loading
          : pageNumber < 5
          ? translation.next
          : translation.done
      }
      disabled={disabled}
      onClick={onSubmit}
      isLoading={isLoading}
      alignCenter={pageNumber === 1}
    />
  </Box>
);

export const CustomButton = ({
  text,
  disabled = false,
  onClick,
  isPrevious = false,
  isLoading = false,
  alignCenter = false,
  yellowButton = false,
}: {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  isPrevious?: boolean;
  isLoading?: boolean;
  alignCenter?: boolean;
  yellowButton?: boolean;
}) =>
  isPrevious ? (
    <Button
      arrow={false}
      buttonText={text}
      onClick={onClick}
      style={{
        fontWeight: 500,
        backgroundColor: "transparent",
        color: "var(--txt-secondary)",
        padding: 0,
      }}
    />
  ) : (
    <Button
      arrow={false}
      buttonText={text}
      onClick={onClick}
      style={{
        fontWeight: 500,
        opacity: disabled || isLoading ? 0.5 : 1,
        minWidth: alignCenter ? "200px" : "100px",
        justifySelf: alignCenter ? "center" : "end",
        color: yellowButton ? "black" : "var(--txt-light)",
        backgroundColor: yellowButton ? "#EDEB6F" : "var(--secondary-bg-color)",
      }}
      disabled={disabled || isLoading}
      isLoading={isLoading}
    />
  );

export const SelectionBox = ({
  image,
  name,
  selected,
  onClick,
}: {
  image: string;
  name: string;
  selected: boolean;
  onClick: () => void;
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.75,
        p: { xs: 0, sm: 1.5 },
        border: "2px solid",
        borderColor: selected ? "#98C4C5" : "#F0F0F0",
        borderRadius: "10px",
        backgroundColor: selected ? "rgba(209, 228, 227, 0.37)" : "transparent",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Image
        src={image}
        alt={name}
        style={{
          width: isMobileView ? "40px" : "80px",
          height: isMobileView ? "40px" : "80px",
          objectFit: "contain",
        }}
      />

      <Typography
        sx={{
          textAlign: "center",
          fontSize: 14,
          textTransform: "capitalize",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

export const SelectAll = ({
  selectedNo,
  onChange,
  isAllSelected,
}: {
  selectedNo: number;
  onChange: () => void;
  isAllSelected: boolean;
}) => (
  <Box
    sx={{
      mt: -0.5,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={isAllSelected}
          onChange={onChange}
          name={translation.selectAll}
          sx={{
            p: 0,
            mr: 0.75,
          }}
          disableRipple
        />
      }
      label={translation.selectAll}
      sx={{
        m: 0,
        "& .MuiFormControlLabel-label": {
          fontSize: 14,
          lineHeight: 1,
          mb: "1px",
        },
      }}
    />

    <Typography
      sx={{
        lineHeight: 1,
        fontSize: 14,
        opacity: 0.75,
        textTransform: "lowercase",
      }}
    >
      {selectedNo + " " + translation.selected}
    </Typography>
  </Box>
);

import React from 'react'
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  IconButton,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  SxProps,
  TextField,
  Button as MuiButton,
} from "@mui/material";
import Button from "components/Button/Button";
import useSnack from "hooks/useSnack";
import { translation } from "constants/translation";
import FlipIcon from "@mui/icons-material/Flip";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import { deletePortfolioObservation, updatePortfolioObservation } from "api/portfolio-api";

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
const TO_RADIANS = Math.PI / 180;

const DevelopmentalGoalsScreenEditRow = ({

  imgRef,
  setCrop,
  completedCrop,
  setCompletedCrop,
  setPhotoSource,
  photoName,
  setFlipHor,
  setFlipVer,
  handleClose,
  scale,
  rotate,
  flipHor,
  flipVer, selectedPhoto, initializeGallery, setEdit,

}: {

  imgRef: any;
  setCrop: any;
  completedCrop: any;
  setCompletedCrop: any;
  setPhotoSource: any;
  photoName: any;
  setFlipHor: any;
  setFlipVer: any;
  handleClose: any
  scale: any;
  rotate: any;
  flipHor: any;
  flipVer: any; selectedPhoto: any; initializeGallery: any; setEdit: any;

}) => {

  const resetCrop = (width: number, height: number) => {
    setCrop(centerAspectCrop(width, height, width / height));
    setCompletedCrop({
      x: 0,
      y: 0,
      width,
      height,
      unit: "px",
    });
  };

  const iconStyle: SxProps = {
    cursor: "pointer",
    transition: "all 100ms ease-in-out",
    "&:hover": {
      color: "primary.main",
      transition: "all 100ms ease-in-out",
    },
  };
  const openSnack = useSnack();

  const handleRotate = (direction: string) => {
    if (imgRef.current) {
      setPhotoSource(getRotatedImage(imgRef.current, direction !== "left", photoName));
    }
  };

  const handleFlip = (direction: string) => {
    if (imgRef.current) {
      if (direction === "horizontal") {
        setFlipHor((prev: any) => !prev);
      } else {
        setFlipVer((prev: any) => !prev);
      }

      const { width, height } = imgRef.current;
      resetCrop(width, height);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          mr: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Tooltip title={translation.flipHoriz}>
          <FlipIcon fontSize="small" sx={iconStyle} onClick={() => handleFlip("horizontal")} />
        </Tooltip>

        <Tooltip title={translation.flipHoriz}>
          <FlipIcon fontSize="small" sx={iconStyle} onClick={() => handleFlip("horizontal")} />
        </Tooltip>

        <Tooltip title={translation.flipVerti}>
          <FlipIcon
            fontSize="small"
            sx={{
              ...iconStyle,
              transform: "rotate(90deg)",
            }}
            onClick={() => handleFlip("vertical")}
          />
        </Tooltip>

        <Tooltip title={translation.rotateAntiClockwise}>
          <Rotate90DegreesCcwIcon
            fontSize="small"
            sx={iconStyle}
            onClick={() => handleRotate("left")}
          />
        </Tooltip>

        <Tooltip title={translation.rotateClockwise}>
          <Rotate90DegreesCcwIcon
            fontSize="small"
            sx={{
              ...iconStyle,
              transform: "rotate(180deg) scaleY(-1)",
            }}
            onClick={() => handleRotate("right")}
          />
        </Tooltip>
      </Box>



      <Button
        buttonText={translation.cancel || "Cancel"}
        arrow={false}
        style={{
          fontSize: "14px",
          backgroundColor: "transparent",
          color: "var(--txt-secondary)",
          padding: "0",
          marginRight: "20px",
        }}
        onClick={handleClose}
      />

      <Button
        arrow={false}
        style={{
          fontSize: "14px",
          backgroundColor: "var(--txt-secondary)",
        }}
        buttonText={translation.done || "Done"}
        onClick={async () => {
          if (completedCrop === undefined || imgRef.current === null) return;

          const editedImage = (await getEditedImg(
            imgRef.current,
            completedCrop,
            scale,
            rotate,
            flipHor,
            flipVer,
            photoName,
            false
          )) as File;

          const updateImage = await updatePortfolioObservation({
            id: selectedPhoto.id,
            image: editedImage,
          });

          if (typeof updateImage != "string") {
            openSnack("Image Edited!", true);
            // setIsRefreshPortfolioObservation(true);
            initializeGallery();
            setEdit(false);
          } else {
            openSnack("Error updating Image!", false);
          }
        }}
      />
    </Box>)
}


const getRotatedImage = (image: HTMLImageElement, isClockwise: boolean, fileName: string) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  const { naturalWidth, naturalHeight } = image;

  // Calculate new dimensions based on rotation
  const newWidth = isClockwise ? naturalHeight : naturalWidth;
  const newHeight = isClockwise ? naturalWidth : naturalHeight;

  // Set canvas dimensions to match the new rotated dimensions
  canvas.width = newWidth;
  canvas.height = newHeight;

  // Rotate and draw image onto canvas
  ctx.save();
  ctx.translate(newWidth / 2, newHeight / 2);

  // Adjust rotation direction based on clockwise or anticlockwise
  const rotation = isClockwise ? (90 * Math.PI) / 180 : (-90 * Math.PI) / 180;
  ctx.rotate(rotation);

  ctx.drawImage(image, -naturalWidth / 2, -naturalHeight / 2);
  ctx.restore();

  // Convert canvas to base64 data URL
  const fileExtension = fileName.toLowerCase().split(".").pop() || "png";
  const dataUrl = canvas.toDataURL(`image/${fileExtension}`, 1);

  return dataUrl;
};

const getEditedImg = (
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
  flipHor: boolean,
  flipVer: boolean,
  fileName: string,
  toBlob: boolean
) => {
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(flipHor ? -scale : scale, flipVer ? -scale : scale);
  ctx.translate(-centerX, -centerY);

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  let fileExtension = fileName.toLowerCase().split(".").pop();
  if (fileExtension) {
    if (fileExtension === "jpg") {
      fileExtension = "jpeg";
    }
  } else {
    fileExtension = "png";
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob === null) return;

      const result = toBlob ? URL.createObjectURL(blob) : new File([blob], fileName, { type: blob.type });

      resolve(result);
    }, `image/${fileExtension}`);
  });
};

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );


export default DevelopmentalGoalsScreenEditRow
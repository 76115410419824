import { useEffect, useRef, useState } from "react";
import { Editor, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Box, Menu, SxProps, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStore from "store/store";
import {
  WidgetContainer,
  Loading,
  ScrollableContainerStyles,
  useWidgetColors,
} from "./Components";
import { translation } from "constants/translation";
import {
  API_Create_Todo_List,
  API_Todo_List,
} from "types/profile";
import {
  createToDoList,
  getToDoList,
  updateToDoList,
} from "api/profile-api";
import useSnack from "hooks/useSnack";
import CustomEditor from "./CustomEditor";

type Props = {
  setColorIndex: React.Dispatch<React.SetStateAction<number>>;
  setExpandMobile?: (value: string) => void;
  isExpanded: boolean;
}

const TodoList = ({
  isExpanded,
  setExpandMobile,
  setColorIndex,
}: Props) => {
  const openSnack = useSnack();
  const colors = useWidgetColors();

  const { currLanguage, profileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
  }));

  const initialTodoList: API_Todo_List = {
    id: -1,
    profile: profileDetails.id,
    content: JSON.stringify(
      convertToRaw(EditorState.createEmpty().getCurrentContent())
    ),
    color: 0,
  };

  const [todoList, setTodoList] = useState<API_Todo_List>(initialTodoList);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const editorRef = useRef<Editor>(null);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const color = colors[todoList.color % colors.length];

  useEffect(() => {
    if (profileDetails.id === -1) return;

    const fetchTodoList = async () => {
      setIsLoading(true);

      try {
        const todoList = await getToDoList(currLanguage, profileDetails.id);

        const updatedTodoList =
          todoList.id === undefined || todoList.id === null
            ? initialTodoList
            : todoList;

        setTodoList(updatedTodoList);
        handleUpdateEditorState(updatedTodoList);
      } catch (error) {
        setTodoList(initialTodoList);
      }

      setIsLoading(false);
    };

    fetchTodoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails.id]);

  const handleOpenColorMenu = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleUpdate = async (data: API_Create_Todo_List) => {
    if (data.content === todoList.content && data.color === todoList.color)
      return;

    const updatedTodoList =
      todoList.id === -1
        ? await createToDoList(currLanguage, data)
        : await updateToDoList(currLanguage, todoList.id, data);

    if (typeof updatedTodoList === "string") {
      openSnack(translation.notesUpdatedFailed, false);
    } else {
      openSnack(translation.notesUpdatedSuccess, true);
      setTodoList(updatedTodoList);
      handleUpdateEditorState(updatedTodoList);
    }
  };

  const handleUpdateEditorState = (todoList: API_Todo_List) => {
    setColorIndex(todoList.color);

    try {
      const contentState = convertFromRaw(JSON.parse(todoList.content));
      setEditorState(EditorState.createWithContent(contentState));
    } catch (error) {
      setTodoList({ ...todoList, content: initialTodoList.content });
      setEditorState(EditorState.createEmpty());
    }
  };

  return (
    <WidgetContainer
      sx={{
        backgroundColor: color,
        height: {
          xs: !isExpanded ? '48px' : "100%",
          lg: !isExpanded ? '48px' : "100%"
        },
        minHeight: {
          xs: !isExpanded ? '48px' : "150px",
          lg: !isExpanded ? '48px' : "100%"
        },
        transition: "all 100ms ease-in-out",
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 1,
              mb: 1,
            }}
          >
            <Typography>{translation.notes}</Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={iconStyles} onClick={handleOpenColorMenu}>
                <MoreHorizIcon fontSize="small" />
              </Box>
              {!!setExpandMobile && (
                <ExpandMoreIcon
                  onClick={() => setExpandMobile?.(isExpanded ? '' : 'notes')}
                  sx={{
                    cursor: "pointer",  
                    opacity: 0.5,
                    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              ...ScrollableContainerStyles,
              cursor: "text",
              "& .public-DraftEditor-content > div": {
                display: "flex",
                flexDirection: "column",
                gap: 0.25,
                "& .block-todo": {
                  backgroundColor: color,
                },
              },
            }}
            onClick={() => editorRef.current?.focus()}
          >
            <CustomEditor
              editorRef={editorRef}
              editorState={editorState}
              setEditorState={setEditorState}
              onBlur={(editorState: EditorState) =>
                handleUpdate({
                  color: todoList.color,
                  content: JSON.stringify(
                    convertToRaw(editorState.getCurrentContent())
                  ),
                })
              }
            />
          </Box>

          <Typography
            sx={{
              pt: 1,
              fontSize: "0.875rem",
              lineHeight: 1,
              color: "txt.dark2",
              cursor: "text",
            }}
            onClick={() => editorRef.current?.focus()}
          >
            {translation.addTodoInstruction}
          </Typography>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              sx: {
                borderRadius: 0,
                boxShadow: 2,
              },
            }}
            MenuListProps={{
              sx: {
                p: 0,
                display: "grid",
                gridTemplateColumns: `repeat(${colors.length}, 1fr)`,
              },
            }}
          >
            {colors.map((color, index) => (
              <Box
                key={index}
                sx={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: color,
                  cursor: "pointer",
                  pointerEvents: todoList.color === index ? "none" : "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() =>
                  handleUpdate({
                    color: index,
                    content: todoList.content,
                  })
                }
              >
                <CheckIcon
                  fontSize="small"
                  sx={{
                    color: todoList.color === index ? "white" : "transparent",
                  }}
                />
              </Box>
            ))}
          </Menu>
        </>
      )}
    </WidgetContainer>
  );
};

const iconStyles: SxProps = {
  color: "#312F30",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default TodoList;

import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageTwo from "images/children-photo-book/page-2.png";
import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageTwoCaptionInfo,
  photoBookPageTwoCaptionPositionList,
  photoBookPageTwoImageInfo,
  photoBookPageTwoImagePositionList,
} from "../photoBookCommonConstant";

export const PhotoBookPageTwoComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imagePositionList = photoBookPageTwoImagePositionList(images, scale);
  const captionPositionList = photoBookPageTwoCaptionPositionList(
    images,
    scale
  );
  const imageInfo = photoBookPageTwoImageInfo(scale);
  const captionInfo = photoBookPageTwoCaptionInfo(scale);

  return (
    <>
      <Image
        src={PhotoBookPageTwo}
        alt={"photo-book-page-2"}
        objectFit="cover"
        style={photoBookImageStyle}
      />
      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            key={`image-${imagePositionInfo.src}`}
            src={imagePositionInfo.src}
            alt={"photo-book-page-1"}
            objectFit="cover"
            style={{
              position: "absolute",
              top: imageInfo.top,
              left: imagePositionInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
              objectFit: "cover",
            }}
          />
        );
      })}
      {/* <CaptionComponent
        top={190 * scale}
        left={30 * scale}
        width={250 * scale}
        caption={images[0].caption}
        clampLine={4}
        fontSize={13 * scale}
      /> */}
      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionInfo.top}
            left={captionPositionInfo.left}
            width={captionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize * scale}
            textAlign="center"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageTwoComponent;

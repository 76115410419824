import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Container from "./Container";
import AlbumBreadcrumbs from "./AlbumBreadcrumbs";
import Albums from "./Albums";
import ModuleAlbum from "./ModuleAlbum";
import ProjectAlbum from "./ProjectAlbum";

const AlbumsScreen = ({ isParentView = false }: { isParentView?: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [moduleName, setModuleName] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");

  const moduleId = searchParams.get("module");
  const projectId = searchParams.get("project");

  return (
    <Container isParentView={isParentView}>
      <AlbumBreadcrumbs
        moduleName={moduleName}
        setModuleName={setModuleName}
        projectName={projectName}
        setProjectName={setProjectName}
      />

      {moduleId === null ? (
        <Albums
          handleClick={(moduleId: string, moduleName: string) => {
            setModuleName(moduleName);
            setProjectName("");
            setSearchParams({
              module: moduleId,
            });
          }}
        />
      ) : projectId === null ? (
        <ModuleAlbum
          onLoad={(moduleName: string) => {
            setModuleName(moduleName);
            setProjectName("");
          }}
          handleClick={(
            moduleId: string,
            moduleName: string,
            projectId: string,
            projectName: string
          ) => {
            setModuleName(moduleName);
            setProjectName(projectName);
            setSearchParams({
              module: moduleId,
              project: projectId,
            });
          }}
        />
      ) : (
        <ProjectAlbum
          onLoad={(moduleName: string, projectName: string) => {
            setModuleName(moduleName);
            setProjectName(projectName);
          }}
        />
      )}
    </Container>
  );
};

export default AlbumsScreen;

import React, { useEffect, useState } from "react";

import { Box, Fade, Modal, Backdrop, Typography, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Button from "components/Button/Button";
import useStore from "store/store";
import { API_Teacher_Certificate } from "types/teacher";
import {
  API_Profile_Details,
  API_Profile_Certificate,
} from "types/profile";
import { Role } from "containers/ChatPage/component/ChatComponent";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { translation } from "constants/translation";
import { SearchBar } from "containers/ChatPage/component/CommonComponents";
import { getCertifiedProfileList } from "api/teacher-api";

const IndividualCertificateCompletedLearnerModal = ({
  selectedCertificate,
  open,
  handleClose,
}: {
  selectedCertificate: API_Teacher_Certificate;
  open: boolean;
  handleClose: () => void;
}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [profileList, setProfileList] = useState<API_Profile_Certificate[]>([]);
  const [filteredProfileList, setFilteredProfileList] = useState<
    API_Profile_Certificate[]
  >([]);

  const populateProfileList = async () => {
    const res = await getCertifiedProfileList(
      selectedCertificate.id,
      localStorage.getItem("access")!
    );

    if (typeof res !== "string") {
      setProfileList(res);
      setFilteredProfileList(res);
    }
  };

  const updateFilteredProfileList = () => {
    const filteredProfileList = profileList.filter(
      (profile) =>
        profile.preferred_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        profile.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        profile.email!.toLowerCase().includes(searchQuery.toLowerCase()) ||
        searchQuery === ""
    );
    setFilteredProfileList(filteredProfileList);
  };

  useEffect(() => {
    if (searchQuery === null) return;

    const searchTimeout = setTimeout(async () => {
      if (searchQuery !== "") {
        setIsLoaded(false);
      }
      updateFilteredProfileList();
    }, 1200);
    return () => clearTimeout(searchTimeout);
    // eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    if (selectedCertificate.id !== -1) {
      populateProfileList();
      updateFilteredProfileList();
    }
    // eslint-disable-next-line
  }, [selectedCertificate.id]);

  const onCloseModalActions = () => {
    handleClose();
    setSearchQuery("");
    setProfileList([]);
    setFilteredProfileList([]);
  };
  // console.log("profileList: ", profileList);
  // console.log("filteredProfileList: ", filteredProfileList);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        onCloseModalActions();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
            maxWidth: "450px",
            maxHeight: "80%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            overflow: "hidden",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={() => {
              onCloseModalActions();
            }}
          >
            <CloseIcon />
          </Box>

          {/* Content here */}
          {selectedCertificate.id === -1 ? (
            <LoadingIndicator />
          ) : (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                position: "relative",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  mb: 1.5,
                }}
              >
                {selectedCertificate.name}
              </Typography>

              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <ProfileList profiles={filteredProfileList} />
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export const ProfileList = ({
  profiles,
}: {
  profiles: API_Profile_Certificate[];
}) => (
  <Box
    sx={{
      mt: 1.5,
      flex: "1 1 0",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      gap: 1,
    }}
  >
    {profiles.length === 0 ? (
      <Typography
        sx={{
          textAlign: "center",
        }}
      >
        No result found
      </Typography>
    ) : (
      <>
        {profiles.map((profile, index) => (
          <React.Fragment key={profile.id}>
            {/* {(index === 0 ||
              profiles[index - 1].preferred_name[0].toLowerCase() !==
                profile.preferred_name[0].toLowerCase()) && (
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  mt: index === 0 ? 0 : 1.5,
                  textTransform: "uppercase",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                {profile.preferred_name[0]}
              </Typography>
            )} */}

            <ProfileBox profile={profile} />
          </React.Fragment>
        ))}
      </>
    )}
  </Box>
);

const ProfileBox = ({ profile }: { profile: API_Profile_Certificate }) => (
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Box sx={{ display: "flex", gap: 1.5 }}>
      <Avatar
        src={profile.image !== null ? profile.image : "no image"}
        sx={{
          width: "60px",
          height: "60px",
          my: "auto",
        }}
      >
        {profile.preferred_name[0]}
      </Avatar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography sx={{ fontSize: 22 }} noWrap>
            {profile.preferred_name}
          </Typography>
          <Role chatProfiles={[profile] as any[]} />
        </Box>
        <Typography sx={{ fontSize: 15, opacity: 0.7 }}>
          {profile.email}
        </Typography>
        <Typography sx={{ fontSize: 15, opacity: 0.7 }}>
          {translation.acquisitionDate}: {profile.acquisition_date}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default IndividualCertificateCompletedLearnerModal;

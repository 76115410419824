import { Avatar, Box, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useStore from "store/store";

const LearningMomentQuestionNameComponent = ({
  name,
  description,
  milestoneId,
  fundamentalId,
}: {
  name: string;
  description: string;
  milestoneId: number;
  fundamentalId: number;
}) => {
  const CustomChip = () => {
    const { fundamentalDict } = useStore((state) => ({
      fundamentalDict: state.fundamentalDict,
    }));

    if (fundamentalDict[fundamentalId]) {
      const currFundamental = fundamentalDict[fundamentalId];
      const milestones = currFundamental.milestones.filter(
        (milestone) => milestone.id === milestoneId
      );
      return (
        <Box
          sx={{
            alignItem: "center",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Chip
            avatar={
              <Avatar alt={currFundamental.name} src={currFundamental.image} />
            }
            key={`${currFundamental.name}`}
            label={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {currFundamental.name}
                </Typography>
                {milestones.length > 0 && (
                  <Typography sx={{ fontSize: 14 }}>
                    {milestones[0].name}
                  </Typography>
                )}
              </Box>
            }
          />

          <Typography
            sx={{
              textAlign: "left",
            }}
          >
            {description}
          </Typography>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 1,
        paddingTop: { xs: 1, sm: 0 },
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: 18,
          lineHeight: "1.25",
          paddingLeft: { xs: 2.5, sm: 0 },
        }}
      >
        {name}
      </Typography>
      <LightTooltip title={CustomChip ? <CustomChip /> : description}>
        <InfoOutlinedIcon
          sx={{
            width: "20px",
            opacity: "0.5",
          }}
        />
      </LightTooltip>
    </Box>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default LearningMomentQuestionNameComponent;

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { translation } from "constants/translation";
import { UserRoleMapping, UserRoleAbbr } from "types/auth";

const RoleForm = ({
  selectedRole,
  setSelectedRole,
}: {
  selectedRole?: UserRoleAbbr;
  setSelectedRole: (role: UserRoleAbbr) => void;
  schoolListLength: number;
}) => {
  const roles = [
    "Parent",
    "Teacher",
    "Content Editor",
    "Admin",
    "Curiculum Developer",
    "Web Admin",
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedRole(event.target.value as UserRoleAbbr);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth required>
        <InputLabel>{translation.role}</InputLabel>
        <Select
          value={selectedRole}
          label={translation.role}
          onChange={handleChange}
          // disabled={schoolListLength === 0}
        >
          {roles.map((role) => {
            return (
              <MenuItem key={role} value={UserRoleMapping[role]}>
                {role}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RoleForm;

import Image from "../../../Image/Image";
import { Box, SxProps, Typography } from "@mui/material";

interface TitleProps {
  image?: string;
  name?: string;
  scale?: number;
  sx?: SxProps;
}

const Title = ({ image, name, scale = 1, sx }: TitleProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      px: 1.5 * scale,
      py: 0.75 * scale,
      backgroundColor: "#F9F6F1",
      borderRadius: "6px",
      gap: `${8 * scale}px`,
      ...sx,
    }}
  >
    <Typography
      sx={{
        fontFamily: "FuturaMediumBT",
        lineHeight: 1,
        fontSize: 20 * scale,
        py: 0.75 * scale,
      }}
    >
      {name}
    </Typography>

    {image && (
      <Image
        style={{
          width: `${32 * scale}px`,
          height: `${32 * scale}px`,
          marginRight: `${12 * scale}px`,
          objectFit: "contain",
        }}
        src={image}
        alt=""
      />
    )}
  </Box>
);

export default Title;

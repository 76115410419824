import React, { useEffect, useState } from "react";
import * as fflate from "fflate";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  TextField,
  Typography,
  Autocomplete,
  SxProps,
} from "@mui/material";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { API_Project } from "types/project";
import { API_Caption } from "types/gallery";
import EditIcon from "@mui/icons-material/Edit";

import CustomButton from "components/Button/Button";
import { translation } from "constants/translation";
import { API_Child } from "types/portfolio";
import { createCompletedWork } from "api/completed-works-api";
import EditPhoto from "components/PhotoVideoGalleryComponents/Photo/EditPhotoModal";
import { PreviewModalFileHandler } from "./CompletedWorkPreviewModal";
import CompletedWorkChildTagging from "./CompletedWorkChildTagging";
// Date

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { GENERAL_DATE_FORMAT } from "constants/constantInGeneral";

const UploadCompletedWorkModal = ({ project }: { project: API_Project }) => {
  const openSnack = useSnack();

  const { currGroup, currLanguage, setIsRefreshCompletedWork } = useStore(
    (state) => ({
      currGroup: state.currGroup,
      currLanguage: state.currLanguage,
      setIsRefreshCompletedWork: state.setIsRefreshCompletedWork,
    })
  );

  const [completedWorkFile, setCompletedWorkFile] = useState<
    File | undefined
  >();
  const [captions, setCaptions] = useState<API_Caption[]>([]);
  const [isUploadedModalOpen, setIsUploadedModalOpen] =
    useState<boolean>(false);
  const [selectedCaption, setSelectedCaption] = useState<string>("");
  const [selectedChildList, setSelectedChildList] = useState<number[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false); // open edit modal or not
  const [editedPhoto, setEditedPhoto] = useState<File | undefined>(); // final output of edited photo
  const [photoName, setPhotoName] = useState<string>(""); // raw photo name to pass
  const [photoSource, setPhotoSource] = useState<string>(""); // raw source to pass

  const [completedWorkProgressPercentage, setCompletedWorkProgressPercentage] =
    useState<number>(0);
  const [isAddingCompletedWork, setIsAddingCompletedWork] =
    useState<boolean>(false);

  // Date
  const today = new Date().toLocaleDateString("en-ca");
  const [completedOn, setCompletedOn] = useState<string>(
    moment(today).format(GENERAL_DATE_FORMAT)
  );

  const handleClose = (event?: any, reason?: any) => {
    if (reason === "backdropClick" && isAddingCompletedWork) {
      return;
    }
    setSelectedCaption("");
    setSelectedChildList([]);
    setCompletedWorkFile(undefined);
    setIsUploadedModalOpen(false);
  };

  const submit = async () => {
    if (completedWorkFile === undefined) {
      return;
    }
    console.log(completedWorkFile.size);
    const massiveFileBuf = await completedWorkFile.arrayBuffer();
    const massiveFile = new Uint8Array(massiveFileBuf);

    const notSoMassive = fflate.zlibSync(massiveFile, { level: 6 });
    console.log(notSoMassive.length / 1_048_576);

    const completedWorkData: API_Child.CreateCompletedWork = {
      child: selectedChildList,
      group: currGroup.id,
      project: project.id,
      original_type: completedWorkFile.type,
      original_name: completedWorkFile.name,
      file: notSoMassive!,
      completed_at: completedOn,
      caption: selectedCaption === "" ? "" : selectedCaption,
    };
    // console.log("completedWorkData: ", completedWorkData);
    setIsAddingCompletedWork(true);
    const completedWorkRes = await createCompletedWork(
      completedWorkData,
      setIsAddingCompletedWork,
      setCompletedWorkProgressPercentage
    );

    if (typeof completedWorkRes !== "string") {
      openSnack(translation.success_upload, true);
      setIsRefreshCompletedWork(true);
      setSelectedChildList([]);
      handleClose();
    } else {
      openSnack(translation.failed_upload, false);
    }
  };

  //photo edit
  const handleEditPhoto = (completedWork: File) => {
    const reader = new FileReader();

    reader.addEventListener("load", () =>
      setPhotoSource(reader.result?.toString() || "")
    );

    reader.readAsDataURL(completedWork);
    setPhotoName(completedWork.name);
    setOpenEditModal(true);
  };

  const handleCloseEditPhotoModal = () => {
    setPhotoName("");
    setPhotoSource("");
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (editedPhoto === undefined) return;

    setCompletedWorkFile(editedPhoto);
    setEditedPhoto(undefined);
  }, [editedPhoto, completedWorkFile]);

  const iconStyle: SxProps = {
    color: "txt.secondary",
    cursor: "pointer",
    fontSize: "2rem",
    backgroundColor: "primary.light",
    borderRadius: "50%",
    transition: "all 100ms linear",
    "&:hover": {
      backgroundColor: "primary.main",
      transition: "all 100ms linear",
    },
  };

  //

  // console.log("completedWorkFile: ", completedWorkFile);
  return (
    <>
      <input
        // accept="image/*, video/*, audio/*, .ppt, .pptx, .pdf, text/plain"
        accept="
          image/*,
          video/*,
          audio/*,
          .pdf,
          application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.ms-powerpoint,
          application/vnd.openxmlformats-officedocument.presentationml.presentation,
          text/*
          "
        id={`${project.id}-completed-work`}
        type="file"
        capture="environment"
        hidden
        onChange={(event) => {
          const newFiles: File[] = Array.prototype.slice.call(
            event.target.files
          );

          if (newFiles.length === 0) {
            event.target.value = "";
            return;
          }

          setCompletedWorkFile(newFiles[0]);
          setIsUploadedModalOpen(true);

          event.target.value = "";
        }}
      />

      {completedWorkFile && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isUploadedModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            m: "24px",
          }}
        >
          <Fade in={isUploadedModalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: "800px",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: "5px 5px 20px var(--primary-main)",
                p: 4,
                borderRadius: "10px",
                overflow: "auto",
                maxHeight: "100%",
              }}
            >
              <CompletedWorkChildTagging
                completedWorkId={-1}
                selectedChildList={selectedChildList}
                setSelectedChildList={setSelectedChildList}
              />
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {`${project.name}`}
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  maxHeight: "500px",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  flexShrink: 0,
                  flexGrow: 1,
                  "&:hover .overlay": {
                    opacity: 1,
                  },
                }}
              >
                {completedWorkFile.type.includes("image") && (
                  <Box
                    className="overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0, 0, 0, 0.5)",
                      opacity: 0,
                      transition: "all 100ms linear",
                      // zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1.5,
                    }}
                  >
                    <EditIcon
                      sx={{ ...iconStyle, p: "6px" }}
                      onClick={() => handleEditPhoto(completedWorkFile)}
                    />
                  </Box>
                )}
                <PreviewModalFileHandler
                  completedWorkFile={completedWorkFile}
                />
              </Box>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  maxDate={moment(today)}
                  label={translation.completedOn}
                  inputFormat={GENERAL_DATE_FORMAT}
                  value={completedOn}
                  onChange={(newValue: moment.Moment | null) => {
                    if (newValue !== null) {
                      setCompletedOn(
                        newValue.format(GENERAL_DATE_FORMAT).toString()
                      );
                    } else {
                      setCompletedOn(
                        moment(today).format(GENERAL_DATE_FORMAT).toString()
                      );
                    }
                  }}
                  renderInput={(params) => (
                    // sx={{ minWidth: 145 }}
                    <TextField
                      {...params}
                      size="small"
                      sx={{ flex: 0.5, mt: 1 }}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>

              <Autocomplete
                disablePortal
                freeSolo
                forcePopupIcon={true}
                options={captions.map((cap) => cap.caption)}
                value={selectedCaption}
                inputValue={selectedCaption}
                onInputChange={(event, inputString) => {
                  setSelectedCaption(inputString);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={translation.typeCaption} />
                )}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ ml: 0 }} {...props}>
                    {option}
                  </Box>
                )}
                sx={{ mt: "12px" }}
              />

              <Box
                sx={{
                  mt: "20px",
                }}
              >
                <CustomButton
                  buttonText={
                    isAddingCompletedWork
                      ? completedWorkProgressPercentage === 100
                        ? translation.uploadFinalizing || "Upload Finalizing"
                        : `${completedWorkProgressPercentage}%`
                      : translation.upload || "Upload"
                  }
                  onClick={submit}
                  isLoading={isAddingCompletedWork}
                  disabled={isAddingCompletedWork}
                  arrow={false}
                />
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}

      {openEditModal && photoSource !== "" && (
        <EditPhoto
          open={openEditModal}
          handleClose={handleCloseEditPhotoModal}
          imageSrc={photoSource}
          setEditedImage={setEditedPhoto}
          imageName={photoName}
        />
      )}
    </>
  );
};

export default UploadCompletedWorkModal;

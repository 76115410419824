import { Box, Typography } from "@mui/material";
import Button from "components/Button/Button";

type Props = {
    role: "teacher" | "admin";
    handleClose: () => void;
    handleNext: () => void;
};

const WalkthroughIntro = ({ role, handleClose, handleNext }: Props) => { 
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
        gap:"12px"
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Welcome to Little Lab!
      </Typography>
      <Typography sx={{
        textAlign: "center",
      }}>
        {role === "teacher" ? "Let's walk through your teacher tool box!" : "Let's walk through how to create a class"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          pt: 2,
        }}
      >
        <Button
          buttonText="Skip"
          btnType="outlined"
          arrow={false}
          style={{
            width: "100%",
            flex: 1,
            border: "none",
            fontWeight: 450,
            color: "#8C8C8C"
          }}
          onClick={handleClose}
        />
        <Button
          buttonText="Start"
          btnType="filled"
          arrow={false}
          onClick={handleNext}
          style={{
            flex: 1,
          }}
        />
      </Box>
    </Box>
  )
}

export default WalkthroughIntro;
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import GroupIcon from "@mui/icons-material/Group";

import useStore from "store/store";
import NewChatModal from "./NewChatModal";
import { FactoryButton } from "components/Button/Button";
import { translation } from "constants/translation";
import GroupChatModal from "./GroupChatModal";

const ChatHeader = () => {
  return (
    <Box
      sx={{
        mt: 2.75,
        mb: 2,
        px: 2,
      }}
    >
      <Title />

      <Box sx={{ display: "flex", gap: 1.5 }}>
        <AddNewChat />
        <AddNewClassChat />
      </Box>
    </Box>
  );
};

const Title = () => (
  <Typography sx={{ fontWeight: 500, fontSize: "1.25rem", mb: 1.25 }}>
    {translation.messages}
  </Typography>
);

const AddNewChat = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { allContacts } = useStore((state) => ({
    allContacts: state.allContacts,
  }));

  const handleOpenModal = () => setOpenModal(true);

  return (
    <>
      <NewChatButton
        text={translation.newChat}
        handleOpenModal={handleOpenModal}
        icon={<ChatIcon fontSize="small" />}
      />

      <NewChatModal
        open={openModal}
        setOpen={setOpenModal}
        allContacts={allContacts}
      />
    </>
  );
};

const AddNewClassChat = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { allContacts } = useStore((state) => ({
    allContacts: state.allContacts,
  }));

  const handleOpenModal = () => setOpenModal(true);

  return (
    <>
      <NewChatButton
        text={translation.newClassChat}
        handleOpenModal={handleOpenModal}
        icon={<GroupIcon fontSize="small" />}
      />

      <GroupChatModal
        open={openModal}
        setOpen={setOpenModal}
        allContacts={allContacts}
      />
    </>
  );
};

const NewChatButton = ({
  text,
  handleOpenModal,
  icon,
}: {
  text: string;
  handleOpenModal: () => void;
  icon: React.ReactElement;
}) => (
  <FactoryButton
    btnText={text}
    onClick={handleOpenModal}
    style={{
      fontSize: "14px",
      fontWeight: 500,
      backgroundColor: "rgba(209, 228, 227, 1)",
      flexDirection: "row-reverse",
      gap: "0.5rem",
      paddingLeft: "14px",
      paddingRight: "14px",
    }}
    icon={icon}
  />
);

export default ChatHeader;

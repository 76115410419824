import { useParams } from "react-router-dom";

import useStore from "store/store";
import Canvas from "./Canvas";

type Props = {
  assessmentPlanActivity?: boolean;
};

const LessonActivityScreen = ({ assessmentPlanActivity = false }: Props) => {
  const { lessonID, assessmentID, activityID } = useParams();
  const { currentLessons } = useStore((state) => ({
    currentLessons: state.currentLessons.lessons,
  }));

  const entityId = assessmentPlanActivity ? assessmentID : lessonID;
  const currentLessonIndex = currentLessons.findIndex(
    (lesson) => lesson.id.toString() === entityId
  );
  const currentLesson = currentLessons[currentLessonIndex];
  const currentActivityIndex = currentLesson.activities.findIndex(
    (activity) => activity.id.toString() === activityID
  );

  return (
    <Canvas
      lesson={currentLesson}
      activityIndex={currentActivityIndex}
      assessment={assessmentPlanActivity}
    />
  );
};

export default LessonActivityScreen;

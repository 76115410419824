import { useState, useEffect, useMemo } from "react";
import ManageModulesModalContent from "./components/ManageModulesModalContent";
import { useNavigate } from "react-router-dom";

import { useCategoryModules } from "hooks/projects";

import {
  Age_Group,
  API_Create_Project,
  Project_Main_Form_Data,
  API_Checkpoint_Fundamental,
  ProjectCategory,
  API_Project_Unit_Guide,
} from "types/project";

import {
  addOrUpdateProjectUnitGuide,
  createCheckpoint,
  createProject,
  createModule,
  createCategory,
  getProjectCategories,
} from "api/project-api";

import useStore from "store/store";
import useSnack from "hooks/useSnack";
import Backdrop from "@mui/material/Backdrop";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Logo from "images/logo-2-colour.png";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Modal,
  Fade,
} from "@mui/material";

import { EDIT_PROJECT_PAGE, CURLANG } from "constants/url";
import getBase64 from "helper/getBase64";

import {
  ModuleField,
  NameField,
  AgeField,
  DurationField,
  DescriptionField,
  GuidingQuestionField,
  PublishField,
  ProjectObjectiveField,
  AssessmentTagField,
} from "components/ProjectForms/ProjectFormComponents";
import { ImageField } from "components/ProjectForms/LessonFormComponents";
import { initialProjectMainFormData } from "components/ProjectForms/EditProjectForm/EditProjectForm";
import ProjectCheckpointFundamentalField from "components/ProjectForms/ProjectFormComponents/ProjectCheckpointFundamentalField";
import ProjectUnitGuideFieldWithDnd from "components/ProjectForms/ProjectFormComponents/ProjectUnitGuideField";
import Header from "./Header";
import CreateModuleModalContent from "./components/CreateModuleModalContent";
import analytics from "helper/analytics";
import { createECDH } from "crypto";
const CreateProjectPage = () => {
  const [projectCategories, setProjectCategories] = useState<ProjectCategory[]>([]);
  const populateProjectCategories = async () => {
    const data: ProjectCategory[] = await getProjectCategories(currLanguage);
    const filteredData = data.filter((cat) => !cat.assessment);
    setProjectCategories(filteredData);
  };
  const { setCurrentLessons } = useStore((state) => ({
    setCurrentLessons: state.setCurrentLessons,
  }));

  useEffect(() => {
    setCurrentLessons({ id: -1, name: "", lessons: [] });
  }, [setCurrentLessons]);

  const { moduleList } = useCategoryModules();
  const navigate = useNavigate();
  const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const [images, setImages] = useState<File[]>([]);
  const [selectedModule, setSelectedModule] = useState<string>("");
  const [filledRequired, setFilledRequired] = useState<boolean>(false);

  const [mainFormData, setMainFormData] = useState<Project_Main_Form_Data>(initialProjectMainFormData);

  const [age, setAge] = useState<Age_Group>("Sprouts");
  const [assessmentTag, setAssessmentTag] = useState<string>("Assessment 1");
  const [publish, setPublish] = useState<boolean>(false);
  const [checkpointFundamentals, setCheckpointFundamentals] = useState<API_Checkpoint_Fundamental[]>([]);

  //Unit guides
  const [projectUnitGuides, setProjectUnitGuides] = useState<API_Project_Unit_Guide[]>([]);

  const isSelectedModuleAssessment = useMemo(() => {
    if (selectedModule === "") return false;

    const selectedModuleData = moduleList.find((module) => module.id === Number(selectedModule));
    return !!selectedModuleData?.assessment;
  }, [selectedModule, moduleList]);

  useEffect(() => {
    if (
      mainFormData.name !== "" &&
      mainFormData.description !== "" &&
      mainFormData.duration !== "" &&
      selectedModule !== ""
    ) {
      setFilledRequired(true);
    } else {
      setFilledRequired(false);
    }
  }, [mainFormData, images, age, selectedModule, publish]);

  const handleSubmit = async () => {
    if (
      new Set(projectUnitGuides.map((projectUnitGuide) => projectUnitGuide.name)).size !==
      projectUnitGuides.map((projectUnitGuide) => projectUnitGuide.name).length
    ) {
      openSnack("Please ensure the resource guides have unique name!", false);
      return;
    }
    setFilledRequired(false);

    const submitData: API_Create_Project = {
      name: mainFormData.name!,
      description: mainFormData.description,
      checkpoint_fundamentals: [],
      duration: mainFormData.duration,
      objective_fundamentals: mainFormData.objective_fundamentals,
      module: selectedModule,
      is_published: publish,
    };

    if (!isSelectedModuleAssessment) {
      submitData.age = age;
    }

    if (isSelectedModuleAssessment) {
      submitData.assessment_tag = assessmentTag;
    }

    if (mainFormData.created_by !== "") {
      submitData.created_by = mainFormData.created_by;
    }

    if (mainFormData.updated_by !== "") {
      submitData.updated_by = mainFormData.updated_by;
    }

    if (mainFormData.objective !== "") {
      submitData.objective = mainFormData.objective;
    }

    if (mainFormData.guiding_question !== "" && !isSelectedModuleAssessment) {
      submitData.guiding_question = mainFormData.guiding_question;
    }

    if (images.length > 0) {
      const base64Images: { file: string }[] = [];
      for (let i = 0; i < images.length; i++) {
        const base64 = await getBase64(images[i]);
        base64Images.push({ file: base64 });
      }
      submitData.images = base64Images;
    }

    const { slug, id } = await createProject(currLanguage, submitData, localStorage.getItem("access"));

    if (!isSelectedModuleAssessment) {
      checkpointFundamentals.forEach((checkpoint_fundamental) => {
        checkpoint_fundamental.checkpoints.forEach(async (checkpoint_) => {
          const checkpointData = {
            name: checkpoint_.name,
            description: checkpoint_.description,
            fundamental: checkpoint_.fundamental,
            is_required: checkpoint_.is_required,
            project: id,
          };

          if (checkpoint_.milestone && checkpoint_.milestone > 0) {
            Object.assign(checkpointData, {
              milestone: checkpoint_.milestone,
            });
          }

          const res = await createCheckpoint(currLanguage, checkpointData);

          if (typeof res === "string") {
            openSnack(res, false);
          }
        });
      });

      //Update Unit Guide
      projectUnitGuides.forEach((unitGuide) => {
        unitGuide.project = id;
      });

      const projectUnitGuideRes = addOrUpdateProjectUnitGuide(
        currLanguage,
        projectUnitGuides,
        localStorage.getItem("access")
      );
      if (typeof projectUnitGuideRes === "string") {
        openSnack(projectUnitGuideRes, false);
        return;
      }
    }

    openSnack("Project created successfully!", true);

    // log analytics on project creation
    analytics.logEvent({
      category: "Project",
      action: "Create Project",
      label: JSON.stringify({
        title: "Project Created",
        userId: mainFormData.created_by,
        user_email: mainFormData.updated_by,
        slug: slug,
        projectId: id,
        projectTitle: mainFormData.name,
      }),
      value: id,
    });

    // navigate to edit page
    navigate(`${CURLANG(currLanguage)}/${EDIT_PROJECT_PAGE(slug)}`);
  };

  const [createModal, setCreateModal] = useState<boolean>(true);

  const handleCreateModal = () => {
    // console.log(createModal);
    setCreateModal(!createModal);
  };

  return (
    <Box sx={{ p: 3 }}>
      <CreateModal
        open={createModal}
        handleCreateModal={handleCreateModal}
        populateProjectCategories={populateProjectCategories}
        projectCategories={projectCategories}
      />
      <Stack
        component="form"
        sx={{
          margin: "auto",
          // backgroundColor: "pink",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <Header
          filledRequired={filledRequired}
          handleSubmit={handleSubmit}
          isAssessment={isSelectedModuleAssessment}
        />
        <ModuleField
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          moduleList={moduleList}
          handleCreateModal={handleCreateModal}
        />

        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <NameField
            label={isSelectedModuleAssessment ? "Assessment Name" : undefined}
            data={mainFormData}
            setData={setMainFormData}
          />
          <PublishField publish={publish} setPublish={setPublish} />
        </Box>
        {!isSelectedModuleAssessment && <AgeField age={age} setAge={setAge} />}
        <DurationField data={mainFormData} setData={setMainFormData} />
        <DescriptionField
          label={isSelectedModuleAssessment ? "Assessment Description" : undefined}
          data={mainFormData}
          setData={setMainFormData}
        />
        {isSelectedModuleAssessment && (
          <AssessmentTagField
            label={"Assessment Tag"}
            assessmentTag={assessmentTag}
            setAssessmentTag={setAssessmentTag}
          />
        )}
        <ProjectObjectiveField
          label={isSelectedModuleAssessment ? "Assessment Objectives" : undefined}
          project={mainFormData as API_Create_Project}
          setProject={setMainFormData as React.Dispatch<React.SetStateAction<API_Create_Project>>}
        />
        {!isSelectedModuleAssessment && (
          <ProjectCheckpointFundamentalField
            checkpointFundamentals={checkpointFundamentals}
            setCheckpointFundamentals={setCheckpointFundamentals}
          />
        )}
        {!isSelectedModuleAssessment && (
          <GuidingQuestionField data={mainFormData} setData={setMainFormData} />
        )}
        {!isSelectedModuleAssessment && (
          <ProjectUnitGuideFieldWithDnd
            projectUnitGuides={projectUnitGuides}
            setProjectUnitGuides={setProjectUnitGuides}
          />
        )}
        <ImageField images={images} setImages={setImages} multiple />
      </Stack>
    </Box>
  );
};

const CreateModal = ({
  open,
  handleCreateModal,
  populateProjectCategories,
  projectCategories,
}: {
  open: any;
  handleCreateModal: any;
  populateProjectCategories: any;
  projectCategories: any;
}) => {
  const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [activeButton, setActiveButton] = useState<any>("CreateModule");

  const [createModuleOrCategory, setCreateModuleOrCategory] = useState<boolean>(false);

  useEffect(() => {
    populateProjectCategories();
  }, [currLanguage, activeButton]);

  const [createModuleForm, setCreateModuleForm] = useState<any>({
    category: "",
    name: "",
    description: "",
    assessment: false,
    image: "",
  });

  const [isCreatingNewCategoryField, setIsCreatingNewCategoryField] = useState<boolean>(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        handleCreateModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClick={(event) => event.stopPropagation()}
      sx={{}}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            width: "35%",
            minHeight: "75vh",
            maxHeight: "83vh",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 2,
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Stack
            component="form"
            sx={{
              width: "100%",
              minWidth: "250px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                Create and Manage Modules
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                  marginBottom: 2,
                }}
              >
                <Button
                  sx={{
                    borderBottom: activeButton === "CreateModule" ? "2px solid #91C6C5 " : "none",
                    width: "50%",
                    borderRadius: "0px",
                    color: activeButton === "CreateModule" ? "#5C9391" : "#8C8C8C",
                  }}
                  onClick={() => setActiveButton("CreateModule")}
                >
                  Create Module
                </Button>
                <Button
                  sx={{
                    borderBottom: activeButton === "ManageModule" ? "2px solid #91C6C5 " : "none",
                    width: "50%",
                    borderRadius: "0px",
                    color: activeButton === "ManageModule" ? "#5C9391" : "#8C8C8C",
                  }}
                  onClick={() => {
                    setActiveButton("ManageModule");
                  }}
                >
                  Manage Module
                </Button>
              </Box>
            </Box>

            {/* CONTENT */}
            {activeButton === "CreateModule" && (
              <CreateModuleModalContent
                projectCategories={projectCategories}
                createModuleForm={createModuleForm}
                setCreateModuleForm={setCreateModuleForm}
                isCreatingNewCategoryField={isCreatingNewCategoryField}
                setIsCreatingNewCategoryField={setIsCreatingNewCategoryField}
              />
            )}
            {activeButton === "ManageModule" && (
              <Box
                sx={{
                  height: "100%",
                  border: "1px solid black",
                  borderRadius: "8px",
                  overflowY: "auto",
                }}
              >
                <ManageModulesModalContent
                  projectCategories={projectCategories}
                  populateProjectCategories={populateProjectCategories}
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                alignSelf: "flex-end",
              }}
            >
              <Button
                sx={{
                  width: "50%",
                  borderRadius: "0px",
                  textTransform: "none",
                }}
                onClick={() => {
                  handleCreateModal();
                  setCreateModuleForm({
                    category: "",
                    name: "",
                    description: "",
                    assessment: false,
                    image: "",
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "50%",
                  border: "none",
                  color: "white",
                  textTransform: "none",
                }}
                onClick={async () => {
                  setCreateModuleOrCategory(true);
                  let response;

                  if (isCreatingNewCategoryField) {
                    let createModuleResponse;
                    response = await createCategory(
                      currLanguage,
                      createModuleForm,
                      localStorage.getItem("access")
                    );

                    if (response.success) {
                      createModuleResponse = await createModule(
                        currLanguage,
                        {
                          ...createModuleForm,
                          category: response.data.id,
                        },
                        localStorage.getItem("access")
                      );
                      if (response.success) {
                        openSnack("Created category and module successfully", true);
                        handleCreateModal();
                        setCreateModuleOrCategory(false);
                        setCreateModuleForm({
                          category: "",
                          name: "",
                          description: "",
                          image: "",
                        });
                      } else {
                        openSnack("Error while creating module", false);
                      }
                    } else {
                      openSnack("Error while creating Category", false);
                    }
                  } else {
                    response = await createModule(
                      currLanguage,
                      createModuleForm,
                      localStorage.getItem("access")
                    );

                    if (response.success) {
                      openSnack("Create module successfully, please check new module in manage module", true);
                      handleCreateModal();
                    } else {
                      openSnack("Error while creating module", false);
                    }
                    setCreateModuleOrCategory(false);
                    setCreateModuleForm({
                      category: "",
                      name: "",
                      description: "",
                      image: "",
                    });
                  }
                }}
              >
                {createModuleOrCategory ? (
                  <CircularProgress />
                ) : activeButton == "CreateModule" ? (
                  "Create Module"
                ) : (
                  "Save"
                )}
              </Button>
            </Box>

            {/* {formData.project_id !== -1 ? (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {projectTitle}

          {isUpdate && handleOpenDialog && (
            <DeleteScheduleObjectComponent
              handleOpenDialog={handleOpenDialog}
              inModal={true}
            />
          )}

          {isUpdate && projectSlug && (
            <NavigateProjectComponent
              projectSlug={projectSlug}
              inModal={true}
            />
          )}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          Class: {currGroup.name}
        </Typography>
      </Box>

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          inputFormat={DATETIME_FORMAT}
          label={translation.projectStartDate}
          value={startAt}
          onChange={(newValue: Date | null) => {
            setStartAt(newValue);
            setFormData((prev) => ({
              ...prev,
              start_at:
                newValue !== null
                  ? moment(newValue).format(DATETIME_FORMAT)
                  : "",
            }));
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />

        <DatePicker
          inputFormat={DATETIME_FORMAT}
          label={translation.projectEndDate}
          value={endAt}
          onChange={(newValue: Date | null) => {
            setEndAt(newValue);
            setFormData((prev) => ({
              ...prev,
              end_at:
                newValue !== null
                  ? moment(newValue).format(DATETIME_FORMAT)
                  : "",
            }));
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </LocalizationProvider>

      <Button
        buttonText={
          preDefinedStartAt ? translation.update : translation.submit
        }
        disabled={formData.project_id === -1}
        onClick={preDefinedStartAt ? handleUpdate : handleSubmit}
      />
    </>
  ) : (
    <LoadingIndicator />
  )} */}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateProjectPage;

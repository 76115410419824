import React, { useEffect, useState } from "react";
import {
  Box,
  SelectChangeEvent,
  Collapse,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  API_Activity_Instruction,
  API_Lesson_Learning_Moment_Question,
  Fundamental_Milestone,
} from "types/project";

import DevelopmentalGoalWithMilestone from "../DevelopmentalGoalWithMilestoneFIeld";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";
import DashedButton from "components/Button/DashedButton";
import useStore from "store/store";

type Props = {
  index: number;
  evaluationMomentQuestion: API_Lesson_Learning_Moment_Question;
  activityInstructionId: string;
  activityInstructions: API_Activity_Instruction[];
  setActivityInstructions: React.Dispatch<
    React.SetStateAction<API_Activity_Instruction[]>
  >;
};

const EvaluationMomentQuestion = ({
  index,
  evaluationMomentQuestion,
  activityInstructionId,
  activityInstructions,
  setActivityInstructions,
}: Props) => {
  const [evaluationMomentQuestionTextFields, setLearningMomentTextFields] =
    useState({
      name: evaluationMomentQuestion.name,
    });

  const { fundamentalDict } = useStore((state) => state);

  useEffect(() => {
    const inputNameTimeout = setTimeout(async () => {
      const updatedActivityInstructions = activityInstructions.map(
        (instruction) => {
          if (instruction.id.toString() === activityInstructionId) {
            const evaluationMomentQuestions =
              instruction.learning_moment?.questions;
            const updatedQuestions = evaluationMomentQuestions?.map(
              (question) => {
                if (
                  question?.id?.toString() ===
                  evaluationMomentQuestion?.id?.toString()
                ) {
                  return {
                    ...question,
                    name: evaluationMomentQuestionTextFields.name,
                  };
                }
                return question;
              }
            );

            return {
              ...instruction,
              learning_moment: {
                ...instruction.learning_moment,
                questions: updatedQuestions,
              },
            };
          }
          return instruction;
        }
      );

      setActivityInstructions(updatedActivityInstructions);
    }, 500);
    return () => clearTimeout(inputNameTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationMomentQuestionTextFields.name]);

  const handleFundamentalSetChange = (
    type: "fundamental" | "milestone",
    id: number,
    instance: Fundamental_Milestone
  ) => {
    const updatedActivityInstructions = activityInstructions.map(
      (instruction) => {
        if (instruction.id.toString() === activityInstructionId) {
          const evaluationMomentQuestions =
            instruction.learning_moment?.questions;

          const updatedQuestions = evaluationMomentQuestions?.map(
            (question) => {
              if (
                question?.id?.toString() ===
                evaluationMomentQuestion?.id?.toString()
              ) {
                const questionFundamentalSet = question.fundamental_set;

                const updatedFundamentalSet = questionFundamentalSet?.map(
                  (fundamentalSetItem) => {
                    if (
                      fundamentalSetItem?.id?.toString() ===
                      instance?.id?.toString()
                    ) {
                      return {
                        ...fundamentalSetItem,
                        ...(type === "fundamental"
                          ? {
                              fundamental: id,
                            }
                          : {
                              milestone: id,
                            }),
                      };
                    }
                    return fundamentalSetItem;
                  }
                );

                return {
                  ...question,
                  fundamental_set: updatedFundamentalSet,
                };
              }

              return question;
            }
          );

          return {
            ...instruction,
            learning_moment: {
              ...instruction.learning_moment,
              questions: updatedQuestions,
            },
          };
        }
        return instruction;
      }
    );

    setActivityInstructions(updatedActivityInstructions);
  };
  const handleDevelopmentalGoalChange = (
    event: SelectChangeEvent,
    instance: Fundamental_Milestone
  ) => {
    const fundamentalId = Number(event.target.value);
    handleFundamentalSetChange("fundamental", fundamentalId, instance);
  };

  const handleMilestoneChange = (
    event: SelectChangeEvent,
    instance: Fundamental_Milestone
  ) => {
    const milestoneId = Number(event.target.value);
    handleFundamentalSetChange("milestone", milestoneId, instance);
  };

  const handleAddDevelopmentalGoal = () => {
    const updatedActivityInstructions = activityInstructions.map(
      (instruction) => {
        if (instruction.id.toString() === activityInstructionId) {
          const existingQuestions =
            instruction.learning_moment?.questions ?? [];

          const updatedQuestions: API_Lesson_Learning_Moment_Question[] =
            existingQuestions.map((question) => {
              if (
                question.id?.toString() ===
                evaluationMomentQuestion?.id?.toString()
              ) {
                return {
                  ...question,
                  fundamental_set: [
                    ...question.fundamental_set,
                    {
                      id: `question-fundamental-set-${question.fundamental_set.length}`,
                      mode: "create",
                      fundamental:
                        fundamentalDict[Object.keys(fundamentalDict)[0]].id,
                      milestone: -1,
                    },
                  ],
                };
              }

              return question;
            });

          return {
            ...instruction,
            learning_moment: {
              ...instruction.learning_moment,
              questions: updatedQuestions,
            },
          };
        }
        return instruction;
      }
    );

    setActivityInstructions(updatedActivityInstructions);
  };

  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: open ? 2 : 0,
          p: 2,
          backgroundColor: "#fff",
          border: "1px solid",
          borderColor: "neutral.300",
          width: "100%",
        }}
      >
        <CardHeader
          sx={{ gap: 0, p: 0 }}
          onClick={() => setOpen(!open)}
          title={
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Question {index + 1}
            </Typography>
          }
          action={<ArrowDownIcon />}
        />
        <Collapse in={open}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              p: 0,
              pb: "0 !important",
            }}
          >
            <RichCKEditor
              data={evaluationMomentQuestionTextFields.name}
              onChange={(_, editor: any) => {
                const data: string = editor.getData();

                setLearningMomentTextFields((prev) => ({
                  ...prev,
                  name: data,
                }));
              }}
            />
            {evaluationMomentQuestion.fundamental_set?.map(
              (fundamentalSetItem) => (
                <DevelopmentalGoalWithMilestone
                  fundamentalMilestone={fundamentalSetItem}
                  handleMilestoneChange={handleMilestoneChange}
                  handleDevelopmentalGoalChange={handleDevelopmentalGoalChange}
                />
              )
            )}
            <DashedButton
              text="Add Developmental Goal"
              onClick={handleAddDevelopmentalGoal}
            />
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

export default EvaluationMomentQuestion;

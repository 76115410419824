import React, { useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";

import Logo from "images/logo-2-colour.png";
import useStore from "store/store";
import { API_Group_Full } from "types/group";

const Tab = ({
  name,
  avatar,
  active = false,
  onClick,
  checkedStyle,
}: {
  name: string;
  avatar?: string;
  active?: boolean;
  onClick?: () => any;
  checkedStyle?: boolean;
}) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        height: "fit-content",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        background: `${active ? "#fff" : "transparent"}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: "20px 14px 14px 14px",
        userSelect: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          // backgroundColor: '#fff',
          backgroundColor: `${
            checkedStyle && active ? "rgba(209, 228, 227, 0.37)" : "#fff"
          }`,
          borderRadius: "10px",
          alignItems: "center",
          border: `1px solid ${
            checkedStyle && active ? "var(--secondary-bg-color)" : "#F0F0F0"
          }`,
          p: "10px 13px",
          width: "fit-content",
          cursor: "pointer",
        }}
        onClick={() => onClick && onClick()}
      >
        <Avatar
          src={avatar}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        >
          {name.charAt(0).toUpperCase()}
        </Avatar>
        <Typography>{name}</Typography>
      </Box>
    </Box>
  );
};

export interface TabContainerProps {
  tabs: {
    name: string;
    avatar?: string;
    element: React.ReactElement;
  }[];
  groupList?: API_Group_Full[];
  checkedStyle?: boolean;
  checkBox?: boolean;
  initialTabIndex?: number[];
}

const TabContainer = (props: TabContainerProps) => {
  const {  tabs, initialTabIndex } = props;
  const [tabIndex, setTabIndex] = useState<number[]>(
    initialTabIndex ? initialTabIndex : [0]
  );

  const { currGroup, setCurrGroup } = useStore((state) => ({
    currGroup: state.currGroup,
    setCurrGroup: state.setCurrGroup,
  }));

  const AvatarImage = ({ currGroup }: { currGroup: API_Group_Full }) => {
    return (
      <Box sx={{ display: "flex" }}>
        <Avatar
          src={typeof currGroup.image === "string" ? currGroup.image : Logo}
          sx={{
            width: "10vw",
            height: "10vw",
            backgroundColor: "primary.main",
            color: "txt.light",
            border: "2px solid",
            borderColor: "primary.main",
            fontSize: "10vw",
          }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        />
        {/* <Typography variant="h3">{currGroup.name}</Typography> */}
      </Box>
    );
  };

  return (
    <Box>
      {/* ------------ tabs start ------------ */}

      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
        <AvatarImage currGroup={currGroup} />
        {tabs.map((tab, index: number) => (
          <Tab
            name={tab.name}
            avatar={tab.avatar}
            onClick={() => {
              if (checkBox) {
                let updatedTabIndex = [...tabIndex];

                if (updatedTabIndex.includes(index)) {
                  updatedTabIndex = updatedTabIndex.filter((i) => i !== index);
                } else {
                  updatedTabIndex.unshift(index);
                }
                setTabIndex(updatedTabIndex);
              } else {
                setTabIndex([index]);
                if (groupList) {
                  setCurrGroup(groupList[index]);
                }
              }
            }}
            active={tabIndex.filter((tIndex) => tIndex === index).length > 0}
            key={Math.random()}
            checkedStyle={checkedStyle}
          />
        ))}
      </Box> */}
      {/* ------------ tabs end ------------ */}
      {/* ------------ content start ------------ */}

      {tabIndex.map((tIndex) => {
        return tabs[tIndex].element;
      })}
      {/* ------------ content end ------------ */}
    </Box>
  );
};

export default TabContainer;

import React from "react";
import { TextField } from "@mui/material";

import {
  API_Create_Lesson,
} from "types/project";
import { translation } from "constants/translation";

const DurationField = ({
  lesson,
  setLesson,
  onChange,
}: {
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: number) => void;
}) => (
  <TextField
    label={translation.expected_duration_mins}
    value={lesson.duration}
    onChange={(event) => {
      let currVal = Number(event.target.value);

      if (Number.isNaN(currVal)) {
        currVal = lesson.duration || 0;
      } else if (currVal < 0) {
        currVal = 0;
      }

      setLesson((prev) => ({ ...prev, duration: currVal }));
      onChange && onChange(currVal);
    }}
    required
  />
);

export default DurationField;
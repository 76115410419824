import React from "react";
import { Box, Typography } from "@mui/material";

import {
  API_Create_Lesson,
} from "types/project";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";

const ObjectiveField = ({
  lesson,
  setLesson,
  onChange,
}: {
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography
      sx={{ mb: "8px" }}
    >{`${translation.lesson_objective} (legacy)`}</Typography>

    <RichCKEditor
      data={lesson.objective}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setLesson((prev) => ({ ...prev, objective: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

export default ObjectiveField;
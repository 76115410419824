import { useState } from "react";
import { Box } from "@mui/material";
import { translation } from "constants/translation";
import {
  Container,
  Title,
  ProgressBar,
  BottomNavigation,
  Answer,
  SelectionBox,
  SelectAll,
} from "../Components";
import { API_Interest } from "types/prediscoveryquestionnaire";
import { updateGroupInterest } from "api/prediscoveryquestionnaire-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

const ChildInterests = ({
  pageNumber,
  setCurrentPage,
  answer,
  setAnswer,
  interestList,
}: {
  pageNumber: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  answer: Answer;
  setAnswer: React.Dispatch<React.SetStateAction<Answer>>;
  interestList: API_Interest[];
}) => {
  const openSnack = useSnack();

  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));

  const isAllSelected = answer.interests.length === interestList.length;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const res = await updateGroupInterest({
      groupId: currGroup.id,
      interestList: answer.interests,
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setCurrentPage(pageNumber + 1);
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <Title
        title={translation.myChildInterests}
        subTitle={translation.myChildInterestsSub}
      />

      <ProgressBar pageNumber={pageNumber} />

      <SelectAll
        selectedNo={answer.interests.length}
        onChange={() =>
          setAnswer((prev) => ({
            ...prev,
            interests: isAllSelected
              ? []
              : interestList.map((interest) => interest.id),
          }))
        }
        isAllSelected={isAllSelected}
      />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 1,
          flex: "1 1 0",
          overflowY: "auto",
          mr: -3,
          pr: { xs: 2, sm: 3 },
        }}
      >
        {interestList.map((interest, index) => (
          <SelectionBox
            key={index}
            image={interest.image || ""}
            name={interest.name}
            selected={answer.interests.includes(interest.id)}
            onClick={() =>
              setAnswer((prev) => ({
                ...prev,
                interests: prev.interests.includes(interest.id)
                  ? prev.interests.filter(
                      (_interest) => _interest !== interest.id
                    )
                  : [...prev.interests, interest.id],
              }))
            }
          />
        ))}
      </Box>

      <BottomNavigation
        pageNumber={pageNumber}
        setCurrentPage={setCurrentPage}
        disabled={answer.interests.length === 0}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default ChildInterests;

import { useState, useRef, useCallback, useEffect, ReactNode } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
  CollisionDetection,
  pointerWithin,
  rectIntersection,
  getFirstCollision,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { Items } from "./ProjectTreeView";

import { updateGalleryLessonID } from "api/gallery-api";
import { editLesson, editActivity } from "api/project-api";
import { translation } from "constants/translation";

interface MovedActivity {
  activityId: string;
  newLessonId: string;
}

const InitialMovedActivity: MovedActivity = {
  activityId: "-1",
  newLessonId: "-1",
};

const DndContainer = ({
  items,
  setItems,
  containers,
  setContainers,
  setIsSortingContainer,
  children,
  handleClose,
}: {
  items: Items;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  containers: string[];
  setContainers: React.Dispatch<React.SetStateAction<string[]>>;
  setIsSortingContainer: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();
  const { currLanguage, setRefreshProjAndLess } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setRefreshProjAndLess: state.setRefreshProjAndLess,
  }));

  const [activeId, setActiveId] = useState<string | null>(null);
  const [movedActivity, setMovedActivity] =
    useState<MovedActivity>(InitialMovedActivity);
  const lastOverId = useRef<string | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id as string)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId as string;

        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );

  const [clonedItems, setClonedItems] = useState<Items | null>(null);

  const findContainer = (id: string) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const activeId = active.id as string;
    const isSortingContainer = activeId ? containers.includes(activeId) : false;

    setIsSortingContainer(isSortingContainer);
    setActiveId(activeId);
    setClonedItems(items);
    handleClose();
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const overId = over?.id;

    if (overId == null || active.id in items) {
      return;
    }

    const overContainer = findContainer(overId as string);
    const activeContainer = findContainer(active.id as string);

    if (!overContainer || !activeContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      const activeItems = items[activeContainer];
      const overItems = items[overContainer];
      const overIndex = overItems.indexOf(overId as string);
      const activeIndex = activeItems.indexOf(active.id as string);

      let newIndex: number;

      if (overId in items) {
        newIndex = overItems.length + 1;
      } else {
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top > over.rect.top + over.rect.height;

        const modifier = isBelowOverItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      recentlyMovedToNewContainer.current = true;

      const activityId = items[activeContainer][activeIndex];
      const newLessonId = overContainer;

      setMovedActivity({
        activityId,
        newLessonId,
      });

      setItems((items) => ({
        ...items,
        [activeContainer]: items[activeContainer].filter(
          (item) => item !== active.id
        ),
        [overContainer]: [
          ...items[overContainer].slice(0, newIndex),
          activityId,
          ...items[overContainer].slice(newIndex, items[overContainer].length),
        ],
      }));
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    let refresh = false;

    // Reordering Lessons
    if (active.id in items && over?.id) {
      const activeIndex = containers.indexOf(active.id as string);
      const overIndex = containers.indexOf(over.id as string);

      const orderedLessons = arrayMove(containers, activeIndex, overIndex);

      const orderDifference: {
        id: string;
        weight: number;
      }[] = [];

      orderedLessons.forEach((lessonId, index) => {
        if (lessonId !== containers[index]) {
          orderDifference.push({
            id: lessonId,
            weight: index + 1,
          });
        }
      });

      if (orderDifference.length !== 0) {
        for (let i = 0; i < orderDifference.length; i++) {
          const reorder = async () =>
            await editLesson(currLanguage, Number(orderDifference[i].id), {
              weight: orderDifference[i].weight,
            });

          reorder();
        }

        setContainers(orderedLessons);
        openSnack(
          translation.success_reorder_lessons ||
            "Reordered Lessons Successfully",
          true
        );
        refresh = true;
      }
    }

    const activeContainer = findContainer(active.id as string);

    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    const overId = over?.id;

    if (overId === null) {
      setActiveId(null);
      return;
    }

    const overContainer = findContainer(overId as string);

    if (overContainer) {
      const activeIndex = items[activeContainer].indexOf(active.id as string);
      const overIndex = items[overContainer].indexOf(overId as string);

      const orderedActivities = arrayMove(
        items[overContainer],
        activeIndex,
        overIndex
      );

      // Move activity to another lesson
      if (
        movedActivity.activityId !== "-1" &&
        clonedItems &&
        !clonedItems[movedActivity.newLessonId].includes(
          movedActivity.activityId
        )
      ) {
        // Update all weight of the activities in previous lesson
        const oldLessonId = Object.keys(clonedItems).find((key) =>
          clonedItems[key].includes(movedActivity.activityId)
        );

        if (oldLessonId) {
          for (let i = 0; i < items[oldLessonId].length; i++) {
            const reorder = async () =>
              await editActivity(
                currLanguage,
                items[oldLessonId][i],
                {
                  weight: i + 1,
                },
                localStorage.getItem("access")
              );

            reorder();
          }
        }

        // Update all weight of the activities for current lesson
        for (let i = 0; i < orderedActivities.length; i++) {
          const reorder = async () =>
            orderedActivities[i] === movedActivity.activityId
              ? await editActivity(
                  currLanguage,
                  movedActivity.activityId,
                  {
                    lesson: movedActivity.newLessonId,
                    weight: i + 1,
                  },
                  localStorage.getItem("access")
                )
              : await editActivity(
                  currLanguage,
                  orderedActivities[i],
                  {
                    weight: i + 1,
                  },
                  localStorage.getItem("access")
                );

          reorder();
        }

        // Update gallery for moved activity
        const updateActivityGallery = async () => {
          await updateGalleryLessonID(currLanguage, {
            activity: movedActivity.activityId,
            lesson: movedActivity.newLessonId,
          });
        };

        updateActivityGallery();

        setItems((items) => ({
          ...items,
          [overContainer]: orderedActivities,
        }));
        openSnack(translation.success_move_activity, true);
        refresh = true;

        // Reorder activities
      } else if (activeIndex !== overIndex) {
        const orderDifference: {
          id: string;
          weight: number;
        }[] = [];

        orderedActivities.forEach((activityId, index) => {
          if (activityId !== containers[index]) {
            orderDifference.push({
              id: activityId,
              weight: index + 1,
            });
          }
        });

        if (orderDifference.length !== 0) {
          for (let i = 0; i < orderDifference.length; i++) {
            const reorder = async () =>
              await editActivity(
                currLanguage,
                orderDifference[i].id,
                {
                  weight: orderDifference[i].weight,
                },
                localStorage.getItem("access")
              );

            reorder();
          }

          setItems((items) => ({
            ...items,
            [overContainer]: orderedActivities,
          }));
          openSnack(translation.success_reorder_activities, true);
          refresh = true;
        }
      }
    }

    resetState();

    if (refresh) {
      setRefreshProjAndLess(true);
    }
  };

  const handleDragCancel = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }

    resetState();
  };

  const resetState = () => {
    setActiveId(null);
    setClonedItems(null);
    setIsSortingContainer(false);
    setMovedActivity(InitialMovedActivity);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToVerticalAxis]}
    >
      {children}
    </DndContext>
  );
};

export default DndContainer;

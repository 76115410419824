import React from "react";
import Image from "components/Image/Image";
import PhotoBookCoverFront from "images/children-photo-book/photo-book-cover-front.png";
import PhotoBookCoverFrontTop from "images/children-photo-book/photo-book-cover-front-top.png";

import { photoBookImageStyle } from "./constant";
import { Typography } from "@mui/material";

const PhotoBookCoverFrontComponent = ({
  imageSrc,
  scale,
  projectName,
  moduleName,
}: {
  imageSrc: string;
  scale: number;
  projectName: string;
  moduleName: string;
}) => {
  // console.log("scale: ", scale);
  return (
    <>
      <Image
        src={PhotoBookCoverFront}
        alt={"photo-book-cover-front"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      <Image
        src={imageSrc}
        alt={"photo-book-page-1"}
        objectFit="cover"
        style={{
          position: "absolute",
          top: 100 * scale,
          left: 50 * scale,
          width: 210 * scale,
          height: 130 * scale,
        }}
      />
      <Image
        src={PhotoBookCoverFrontTop}
        alt={"photo-book-cover-front-top"}
        objectFit="cover"
        style={{
          ...photoBookImageStyle,
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />

      <Typography
        sx={{
          position: "absolute",
          top: 20 * scale,
          left: 30 * scale,
          width: 250 * scale,
          textAlign: "center",
          zIndex: 2,
          fontSize: 14 * scale,
          display: "-webkit-box",
          WebkitLineClamp: `2`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {moduleName}
      </Typography>

      <Typography
        sx={{
          position: "absolute",
          top: 40 * scale,
          left: 30 * scale,
          width: 250 * scale,
          textAlign: "center",
          zIndex: 2,
          fontSize: 18 * scale,
          display: "-webkit-box",
          WebkitLineClamp: `1`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {projectName}
      </Typography>
    </>
  );
};

export default PhotoBookCoverFrontComponent;

import React from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, SxProps, Typography, Checkbox, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

import { translation } from "constants/translation";
import { API_Project_Access_Control } from "types/project";
import { editProject } from "api/project-api";
import { createAccess, deleteAccess } from "api/access-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  ASSESSMENT_PAGE,
  CURLANG,
  EDIT_PROJECT_PAGE,
  PROJECT_SUMMARY_PAGE,
} from "constants/url";
import { API_School } from "types/school";

type ProjectTableProps = {
  projects: API_Project_Access_Control[];
  setProjects: React.Dispatch<
    React.SetStateAction<API_Project_Access_Control[]>
  >;
  currSchool: API_School;
  accessList: { [projectID: string]: number };
  setAccessList: React.Dispatch<
    React.SetStateAction<{
      [projectID: string]: number;
    }>
  >;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
};

const ProjectsTable = ({
  projects,
  setProjects,
  currSchool,
  accessList,
  setAccessList,
  setSelected,
}: ProjectTableProps) => {
  const openSnack = useSnack();

  const { currLanguage } = useStore((state) => state);

  const handleAccess = async (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    project: API_Project_Access_Control
  ) => {
    e.stopPropagation();
    if (project.id.toString() in accessList) {
      const res = await deleteAccess(
        accessList[project.id.toString()],
        localStorage.getItem("access")
      );
      if (res === "Success") {
        // [project.id.toString()] : abc >> this is equal to passing project.id.toString() as value with the name of abc
        // The following line of code will split out the removed access and set the filtered one only.
        const { [project.id.toString()]: toRemove, ...filtered } = accessList;
        setAccessList(filtered);
        openSnack(
          `Revoked the access of ${project.name} from ${currSchool.name}!`,
          true
        );
      } else {
        openSnack(res, false);
      }
    } else {
      const res = await createAccess(
        currSchool.id.toString(),
        project.id.toString(),
        localStorage.getItem("access")
      );
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        accessList[project.id.toString()] = res.id;
        setAccessList(accessList);
        openSnack(
          `Granted the access of ${project.name} to ${currSchool.name}!`,
          true
        );
      }
    }
  };

  const handlePublish = async (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    project: API_Project_Access_Control
  ) => {
    e.stopPropagation();

    const res = await editProject(currLanguage, project.id, {
      is_published: !project.is_published,
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const updatedProjects = projects.map((project_) => {
        if (project_.id === project.id) {
          return {
            ...project_,
            is_published: !project.is_published,
          };
        }
        return project_;
      });
      setProjects(updatedProjects);
      project.is_published
        ? openSnack(`Unpublished ${project.name}!`, true)
        : openSnack(`Published ${project.name}!`, true);
    }
  };

  const iconStyles: SxProps = {
    color: "#312F30B2",
    cursor: "pointer",
  };

  const columns: GridColumns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => {
        const navigateMethod = params.row.project.assessment ? ASSESSMENT_PAGE : PROJECT_SUMMARY_PAGE;
        const navUrl = `${CURLANG(currLanguage)}/${navigateMethod(params.row.slug)}`;

        return (
          <Link
            style={{
              color: "#407374",
            }}
            to={navUrl}
          >
            <Tooltip title={params.value}>
              <Box
                sx={{
                  maxWidth: params.colDef.computedWidth,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {`${params.row.published ? `` : `(${translation.draft}) `}${
                  params.value
                }`}
              </Box>
            </Tooltip>
          </Link>
        );
      },
    },
    { field: "module", headerName: "Module", maxWidth: 400, minWidth: 150 },
    { field: "level", headerName: "Level", maxWidth: 200 },
    {
      field: "lessons",
      headerName: "No. of Lessons",
      type: "number",
      maxWidth: 150,
    },
    {
      field: "access",
      headerName: "Access",
      sortable: false,
      maxWidth: 120,
      renderCell: (params) =>
        !params.row.published ? (
          <Typography
            sx={{
              width: "20px",
              textAlign: "center",
            }}
          >
            -
          </Typography>
        ) : params.value ? (
          <CheckBoxOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyles, color: "black" }}
            onClick={(e) => handleAccess(e, params.row.project)}
          />
        ) : (
          <CheckBoxOutlineBlankOutlinedIcon
            fontSize="small"
            sx={iconStyles}
            onClick={(e) => handleAccess(e, params.row.project)}
          />
        ),
    },
    {
      field: "published",
      headerName: "Published",
      sortable: true,
      maxWidth: 120,
      renderCell: (params) =>
        params.value ? (
          <CheckBoxOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyles, color: "black" }}
            onClick={(e) => handlePublish(e, params.row.project)}
          />
        ) : (
          <CheckBoxOutlineBlankOutlinedIcon
            fontSize="small"
            sx={iconStyles}
            onClick={(e) => handlePublish(e, params.row.project)}
          />
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      maxWidth: 120,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            to={`${CURLANG(currLanguage)}/${EDIT_PROJECT_PAGE(
              params.row.slug
            )}`}
          >
            <EditIcon fontSize="small" sx={iconStyles} />
          </Link>
        </Box>
      ),
    },
  ];

  const rows =
    projects.length === 0
      ? []
      : projects.map((project) => ({
          project,
          id: project.id,
          title: project.name,
          module: project.module?.name,
          level: project.age,
          lessons: project.no_of_lessons,
          access: project.id.toString() in accessList,
          published: project.is_published,
          slug: project.slug,
        }));

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      columnVisibilityModel={{
        access: currSchool.id !== -1, //show access if school exist
        published: currSchool.id === -1, //show publish if school doesn't exist
      }}
      disableColumnMenu
      checkboxSelection
      components={{
        BaseCheckbox(props) {
          return (
            <Checkbox
              sx={{
                "&:hover": {
                  backgroundColor: "transparent !important",
                },
              }}
              icon={
                <CheckBoxOutlineBlankOutlinedIcon
                  fontSize="small"
                  sx={iconStyles}
                />
              }
              checkedIcon={
                <CheckBoxOutlinedIcon
                  fontSize="small"
                  sx={{ ...iconStyles, color: "black" }}
                />
              }
              indeterminateIcon={
                <IndeterminateCheckBoxOutlinedIcon
                  fontSize="small"
                  sx={iconStyles}
                />
              }
              disableRipple
              {...props}
            />
          );
        },
        NoRowsOverlay: NoProjectFound,
      }}
      loading={false}
      sx={{
        border: "none",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: 0,
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
      }}
      onSelectionModelChange={(ids) => {
        const selectedIds = ids.map((id) => id.toString());
        setSelected(selectedIds);
      }}
    />
  );
};

const NoProjectFound = () => (
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "1.25rem",
      textAlign: "center",
      backgroundColor: "#FBFBFB",
      p: 2,
    }}
  >
    {translation.noProjectFound}
  </Typography>
);

export default ProjectsTable;

import React, { Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";
import {
  API_Teacher_Module,
  API_Teacher_Module_Learner_Progress,
} from "types/teacher";
import useStore from "store/store";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import TeacherDetailCard from "../TeacherPageComponents/TeacherDetailCard/TeacherDetailsCard";

import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import DndGrid from "./DndModule/DndGrid";
import DndCard from "./DndModule/DndCard";

type Props = {
  moduleList: API_Teacher_Module[];
  learnerModuleProgressList: API_Teacher_Module_Learner_Progress[];
  setModuleList: Dispatch<SetStateAction<API_Teacher_Module[]>>;
};

const ContinueLearningSection = ({
  moduleList,
  learnerModuleProgressList,
  setModuleList,
}: Props) => {
  const { profileDetails, teacherModules, isTeacherModuleLoading } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      teacherModules: state.teacherModules,
      isTeacherModuleLoading: state.isTeacherModuleLoading,
    })
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "10px",
        width: "100%",
        maxWidth: "90vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mb: 4,
        }}
      >
        <Box>
          <Typography
            sx={{
              alignSelf: "center",
              color: "#5C9391",
              fontWeight: 600,

              fontSize: 24,
            }}
          >
            Continue Learning
          </Typography>
        </Box>
      </Box>

      <Box>
        {isTeacherModuleLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <LoadingIndicator />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2rem",
              }}
            >
              {isProfileInTTAModuleEditableRoleList(profileDetails) &&
              moduleList.length === teacherModules.length ? (
                <DndGrid moduleList={moduleList} setModuleList={setModuleList}>
                  {moduleList.map((module_, index) => {
                    const progress = learnerModuleProgressList.filter(
                      (learnerModuleProgress) =>
                        learnerModuleProgress.id === module_.id
                    )[0];
                    return (
                      <DndCard key={module_.id} module={module_}>
                        <TeacherDetailCard
                          key={`module-id-${module_.id}`}
                          module={module_}
                          progress={progress}
                        />
                      </DndCard>
                    );
                  })}
                </DndGrid>
              ) : (
                moduleList.map((module_, index) => {
                  const progress = learnerModuleProgressList.filter(
                    (learnerModuleProgress) =>
                      learnerModuleProgress.id === module_.id
                  )[0];
                  return (
                    <TeacherDetailCard
                      key={`module-id-${module_.id}`}
                      module={module_}
                      progress={progress}
                    />
                  );
                })
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ContinueLearningSection;

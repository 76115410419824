import React, { useEffect, useState } from "react";
import { PROFILE_SECTION_EXPERIENCE } from "../constants";
import { slugify } from "helper/slugify";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useStore from "store/store";
import { API_Experience } from "types/profile";
import moment from "moment";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { deleteExperience } from "api/profile-api";
import useSnack from "hooks/useSnack";
import { translation } from "constants/translation";

const ExperienceComponent = ({
  setIsModalOpen,
  setType,
  isOwner,
  setSelectedExperience,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  isOwner: boolean;
  setSelectedExperience: React.Dispatch<React.SetStateAction<API_Experience>>;
}) => {
  const openSnack = useSnack();
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "Delete Experience?",
    confirmDelete: false,
  });

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Experience?",
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  const handleDeleteClick = (experience: API_Experience) => {
    //call delete api
    handleOpenDialog();
    setWarningBody({
      id: experience.id,
      warningTitle: `Delete Experience "${experience.name}"?`,
      confirmDelete: false,
    });
  };

  const handleDelete = (id: number) => {
    //call delete api
    const callDeleteExperience = async () => {
      const res = await deleteExperience(id);
      if (typeof res === "string" && res.length >= 1) {
        //if delete success, it will return ""
        openSnack(res, false);
      } else {
        openSnack(`Delete Experience successfully!`, true);
        const filteredExperience = visitedProfileDetails.experiences?.filter(
          (experience) => experience.id !== id
        );
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          experiences: filteredExperience,
        });
      }
    };

    callDeleteExperience();
  };

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <Card id={slugify(PROFILE_SECTION_EXPERIENCE)} sx={{ width: "100%" }}>
        <CardHeader
          sx={{ backgroundColor: "#d0e3e2bd" }}
          title={(
            <Typography sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 500 }}>
              {translation.profileSectionExperience}
            </Typography>
          )}
          action={
            <IconButton
              aria-label="New"
              onClick={() => {
                setType(slugify(PROFILE_SECTION_EXPERIENCE));

                setIsModalOpen(true);
              }}
            >
              {isOwner && <AddIcon />}
            </IconButton>
          }
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            {visitedProfileDetails.experiences?.map(
              (experience: API_Experience) => {
                return (
                  <Box key={experience.id} sx={{ position: "relative" }}>
                    <IconButton
                      onClick={() => {
                        setIsModalOpen(true);
                        setType(slugify(PROFILE_SECTION_EXPERIENCE));
                        setSelectedExperience(experience);
                      }}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 40,
                      }}
                    >
                      {isOwner && (
                        <EditIcon
                          sx={{
                            cursor: "pointer",
                            color: "txt.secondary",
                            transition: "all 100ms ease-in-out",
                            "&:hover": {
                              color: "primary.main",
                              transition: "all 100ms ease-in-out",
                            },
                          }}
                        />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        handleDeleteClick(experience);
                      }}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      {isOwner && (
                        <DeleteIcon
                          sx={{
                            cursor: "pointer",
                            color: "txt.secondary",
                            transition: "all 100ms ease-in-out",
                            "&:hover": {
                              color: "primary.main",
                              transition: "all 100ms ease-in-out",
                            },
                          }}
                        />
                      )}
                    </IconButton>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h5">{experience.name}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        {moment(experience.start_at).format("MMMM YYYY")}–
                        {experience.end_at
                          ? moment(experience.end_at).format("MMMM YYYY")
                          : "Present"}
                      </Typography>
                      <Typography variant="body1">
                        {experience.description}
                      </Typography>
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ExperienceComponent;

import Image from "components/Image/Image";
import LearningStoriesPageBackground from "images/children-portfolio-v2/learning-stories-background.png";
import Page from "./Page";

const LearningStoriesCover = () => {
  return (
    <Page>
      <Image src={LearningStoriesPageBackground} alt="" />
    </Page>
  );
};

export default LearningStoriesCover;

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import {
  Avatar,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import { API_Profile } from "types/profile";
import {
  ErrorMessage,
  UpdateProfile,
  UpdateProfileResponse,
} from "types/auth";

import { getAccountProfile } from "api/profile-api";
import { updateProfile } from "api/auth-api";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Button from "components/Button/Button";

import Logo from "images/logo-2-colour.png";

interface FormData {
  preferred_name: string;
  full_name: string;
  dob: string;
  image?: File;
}

const UpdateChildPage = () => {
  const { childID } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [child, setChild] = useState<API_Profile>();
  const [childImg, setChildImg] = useState<string>("");
  const [formData, setFormData] = useState<FormData>({
    preferred_name: "",
    full_name: "",
    dob: "",
  });

  useEffect(() => {
    const initialiseChild = async () => {
      if (childID) {
        const data = await getAccountProfile(
          childID,
          localStorage.getItem("access")
        );

        setChild(data);
        setChildImg(data.image);
        setFormData({
          preferred_name: data.preferred_name,
          full_name: data.full_name,
          dob: data.dob,
        });
      }
      setLoading(false);
    };
    initialiseChild();
  }, [childID, loading]);

  return (
    <Box
      sx={{
        minHeight: "71vh",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "71vh",
          }}
        >
          <LoadingIndicator />
        </Box>
      ) : child ? (
        <Stack
          component="form"
          sx={{
            width: "80vw",
            margin: "auto",
            alignItems: "center",
            my: "50px",
          }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <ProfileImage
            image={childImg}
            imageFallbackChar={
              child.preferred_name
                ? child.preferred_name.charAt(0).toUpperCase()
                : ""
            }
            setFormData={setFormData}
          />
          <NameForm formData={formData} setFormData={setFormData} />
          <DateField formData={formData} setFormData={setFormData} />
          <SubmitButton
            formData={formData}
            profileID={child.id.toString()}
            setLoading={setLoading}
          />
        </Stack>
      ) : (
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "500",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "71vh",
          }}
        >
          No profile with id {childID} found!
        </Typography>
      )}
    </Box>
  );
};

const ProfileImage = ({
  image,
  imageFallbackChar,
  setFormData,
}: {
  image: string;
  imageFallbackChar: string;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}) => {
  const [currImage, setCurrImage] = useState<string>(image);

  return (
    <Box sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}>
      <Avatar
        src={currImage}
        sx={{
          width: "20vw",
          height: "20vw",
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "10vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {imageFallbackChar}
      </Avatar>
      <input
        accept="image/*"
        type="file"
        id={`profile-image-button`}
        hidden
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            setCurrImage(URL.createObjectURL(event.target.files[0]));
            setFormData((prev) => ({ ...prev, image: event.target.files![0] }));
          }
        }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20%",
          backgroundColor: "primary.main",
          opacity: 0,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
            transition: "all 150ms linear",
          },
        }}
      >
        <label htmlFor={`profile-image-button`}>
          <IconButton
            component="span"
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: "2.5vw",
                color: "primary.light",
                cursor: "pointer",
                transition: "all 50ms linear",
                "&:hover": {
                  color: "txt.secondary",
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box>
    </Box>
  );
};

const NameForm = ({
  formData,
  setFormData,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
      <TextField
        label="Preferred Name"
        value={formData.preferred_name}
        onChange={(event) => {
          setFormData((prev) => ({
            ...prev,
            preferred_name: event.target.value,
          }));
        }}
      />
      <TextField
        label="Full Name"
        value={formData.full_name}
        onChange={(event) => {
          setFormData((prev) => ({ ...prev, full_name: event.target.value }));
        }}
      />
    </Box>
  );
};

const DateField = ({
  formData,
  setFormData,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}) => {
  const today = new Date().toLocaleDateString("en-ca");

  return (
    <TextField
      label="Date of Birth"
      type="date"
      defaultValue={formData.dob}
      sx={{ width: 220 }}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(event) => {
        setFormData((prev) => ({ ...prev, dob: event.target.value }));
      }}
      inputProps={{
        max: today,
      }}
    />
  );
};

const SubmitButton = ({
  formData,
  profileID,
  setLoading,
}: {
  formData: FormData;
  profileID: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const openSnack = useSnack();
  const { setRefreshGroup } = useStore((state) => ({
    setRefreshGroup: state.setRefreshGroup,
  }));

  const handleClick = async () => {
    const response: UpdateProfileResponse | ErrorMessage = await updateProfile(
      profileID,
      formData as UpdateProfile,
      localStorage.getItem("access")
    );

    if ("error" in response) {
      openSnack(response.error.join(" "), false);
    } else {
      openSnack("Updated successfully!", true);
      setRefreshGroup(true);
      setLoading(true);
    }
  };

  return (
    <Button buttonText="Update Details" arrow={false} onClick={handleClick} />
  );
};

export default UpdateChildPage;

import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import Confetti from "images/confetti.png";
import Image from "components/Image/Image";
import { translation } from "constants/translation";
import { Container, Answer, Title } from "../Components";
import useStore from "store/store";

const ConcludingPage = ({
  answer,
  setOpen,
}: {
  answer: Answer;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setRefreshGroup, setCurrentAgeGroup } = useStore((state) => ({
    setRefreshGroup: state.setRefreshGroup,
    setCurrentAgeGroup: state.setCurrentAgeGroup,
  }));

  const level =
    answer.level === "Blossoms"
      ? {
          name: translation.Blossoms,
          image: BlossomsIcon,
        }
      : answer.level === "Buds"
      ? {
          name: translation.Buds,
          image: BudsIcon,
        }
      : {
          name: translation.Sprouts,
          image: SproutsIcon,
        };

  const handleClose = () => {
    setOpen(false);
    setRefreshGroup(true);
    setCurrentAgeGroup(answer.level);
    localStorage.setItem("currentAgeGroup", answer.level);
  };

  return (
    <Container>
      <CloseIcon
        fontSize="small"
        sx={{
          position: "absolute",
          top: "-8px",
          right: "-8px",
          cursor: "pointer",
        }}
        onClick={handleClose}
      />

      <Title title={translation.quickQuestionsCompleted} />

      <Image
        src={Confetti}
        alt=""
        style={{
          width: "100px",
          height: "100px",
          margin: "0 auto",
          objectFit: "contain",
        }}
      />

      <Typography
        sx={{
          textAlign: "center",
          mb: 1,
        }}
      >
        {translation.formatString(
          translation.concludingTextFirst,
          <Level name={level.name} image={level.image} />
        )}
        <br />
        {translation.concludingTextLast}
      </Typography>
    </Container>
  );
};

const Level = ({ name, image }: { name: string; image: string }) => (
  <Typography
    component="span"
    sx={{
      display: "inline-flex",
      gap: 0.5,
      alignItems: "baseline",
      borderBottom: "1px solid var(--primary-main)",
      mx: 0.5,
    }}
  >
    <Image
      src={image}
      alt={name}
      style={{ width: 24, height: 24, objectFit: "contain", alignSelf: "end" }}
    />

    {name}
  </Typography>
);

export default ConcludingPage;

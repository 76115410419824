import React, { Fragment, useEffect, useState } from "react";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, Typography } from "@mui/material";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import {
  getCheckpointChildCheckList,
  updateLessonCheckpointStatus,
} from "api/project-api";
import { API_Child } from "types/profile";
import { translation } from "constants/translation";

const CheckpointBoxes = ({ checkpointID }: { checkpointID: number }) => {
  const openSnack = useSnack();
  const { childList } = useStore((state) => ({
    childList: state.currGroup.children,
  }));

  const [childCheckpointCheckList, setChildCheckpointCheckList] = useState<
    number[]
  >([]); // a list of child id

  useEffect(() => {
    const getChildCheckpointCheckListRes = async () => {
      const res = await getCheckpointChildCheckList(
        checkpointID,
        childList.map((child) => child.id)
      );
      if (typeof res !== "string") {
        setChildCheckpointCheckList(res.map((r) => r.child));
      } else {
        console.log(res);
      }
    };
    getChildCheckpointCheckListRes();
  }, []);

  const updateChecked = async (children: API_Child[]) => {
    const statuses = children.map((child) =>
      childCheckpointCheckList.includes(child.id)
    );

    const res = await updateLessonCheckpointStatus({
      children: children.map((child) => child.id),
      checkpoint_id: checkpointID,
      statuses: statuses.map((status) => !status), //flip
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      if (statuses[0]) {
        //if already selected, then remove
        if (statuses.length === 1) {
          //single click
          const updatedChildCheckpointCheckList =
            childCheckpointCheckList.filter(
              (childCheckpointCheck) => childCheckpointCheck !== children[0].id
            );
          setChildCheckpointCheckList(updatedChildCheckpointCheckList);
          openSnack(
            `Removed checkpoint from ${children[0].preferred_name}!`,
            true
          );
        } else {
          //>1
          setChildCheckpointCheckList([]);
          openSnack(`Removed checkpoint from all children!`, true);
        }
      } else {
        //not selected, check or select all
        if (statuses.length === 1) {
          setChildCheckpointCheckList([
            ...childCheckpointCheckList,
            children[0].id,
          ]);
          openSnack(`Added checkpoint to ${children[0].preferred_name}!`, true);
        } else {
          setChildCheckpointCheckList(childList.map((child) => child.id));
          openSnack(`Added checkpoint to all children!`, true);
        }
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* Select all Checkbox */}
      <Box
        key={`child-select-all-check-box`}
        sx={{
          minWidth: 90,
          maxWidth: 90,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          // position: "relative",
        }}
        onClick={() => {
          const childListToPass = () => {
            if (childCheckpointCheckList.length === childList.length) {
              //deselect
              return childList;
            } else {
              //select some
              return childList.filter(
                (child) => !childCheckpointCheckList.includes(child.id)
              );
            }
          };

          updateChecked(childListToPass());
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "0.90rem",
            // fontStyle: "italic",
            // position: "absolute",
            // top: "-48px",
            // left: 0,
            // right: 0,
            pointerEvents: "none",
          }}
        >
          {childCheckpointCheckList.length === childList.length
            ? translation.deselectAll
            : translation.selectAll}
        </Typography>

        {childCheckpointCheckList.length === childList.length ? (
          <CheckBoxOutlinedIcon
            key={Math.random()}
            sx={{
              color: "#407374",
              cursor: "pointer",
              fontSize: "1.875rem",
            }}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            key={Math.random()}
            sx={{
              color: "#407374",
              cursor: "pointer",
              fontSize: "1.875rem",
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "100%",
          overflow: "overlay",
        }}
      >
        {/* Child Checkboxes */}
        {childList.map((child, index) => (
          <Box
            key={`child-${child.id}-${index}`}
            sx={{
              minWidth: 120,
              maxWidth: 120,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              // position: "relative",
            }}
            onClick={() => updateChecked([child])}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontStyle: "italic",
                // position: "absolute",
                // top: "-48px",
                // left: 0,
                // right: 0,
                pointerEvents: "none",
              }}
            >
              {child.preferred_name}
            </Typography>

            {childCheckpointCheckList.includes(child.id) ? (
              <CheckBoxOutlinedIcon
                key={Math.random()}
                sx={{
                  color: "#407374",
                  cursor: "pointer",
                  fontSize: "1.875rem",
                }}
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                key={Math.random()}
                sx={{
                  color: "#407374",
                  cursor: "pointer",
                  fontSize: "1.875rem",
                }}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CheckpointBoxes;

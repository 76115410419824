import { useEffect, useState } from "react";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import {
  getLearningMomentChildCheckList,
  updateLessonLearningMomentStatus,
} from "api/project-api";
import { API_Child } from "types/profile";
import LearningMomentCheckboxes from "./LearningMomentCheckboxes";

type Props = {
  learningMomentID: number;
  learningMomentQuestionID: number | string;
};

const EvaluationMomentBoxes = ({
  learningMomentID,
  learningMomentQuestionID,
}: Props) => {
  const { childList, currentProject } = useStore((state) => ({
    ...state,
    childList: state.currGroup.children,
  }));
  const openSnack = useSnack();

  const [childLearningMomentCheckList, setChildLearningMomentCheckList] =
    useState<number[]>([]);

  useEffect(() => {
    const getChildLearningMomentCheckListRes = async () => {
      const res = await getLearningMomentChildCheckList(
        learningMomentID,
        childList.map((child) => child.id),
        learningMomentQuestionID
      );
      if (typeof res !== "string") {
        setChildLearningMomentCheckList(res.map((r) => r.child));
      } else {
        console.log(res);
      }
    };

    getChildLearningMomentCheckListRes();
  }, []);

  const updateChecked = async (children: API_Child[], type?: "bulkAdd") => {
    const statuses = children.map((child) =>
      childLearningMomentCheckList.includes(child.id)
    );

    const res = await updateLessonLearningMomentStatus({
      project_id: currentProject.id,
      children: children.map((child) => child.id),
      learning_moment_id: learningMomentID,
      learning_moment_question_id: learningMomentQuestionID,
      statuses: statuses.map((status) => !status), //flip
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else if (statuses[0]) {
      //if already selected, then remove
      if (statuses.length === 1) {
        //single click
        const updatedChildLearningMomentCheckList =
          childLearningMomentCheckList.filter(
            (childLearningMomentCheck) =>
              childLearningMomentCheck !== children[0].id
          );
        setChildLearningMomentCheckList(updatedChildLearningMomentCheckList);
        openSnack(
          `Removed Evaluation moment from ${children[0].preferred_name}!`,
          true
        );
      } else {
        //>1
        setChildLearningMomentCheckList([]);
        openSnack(`Removed Evaluation moment from all children!`, true);
      }
    } else {
      //not selected, check or select all
      if (statuses.length === 1) {
        setChildLearningMomentCheckList([
          ...childLearningMomentCheckList,
          children[0].id,
        ]);
        openSnack(
          `Added Evaluation moment to ${children[0].preferred_name}!`,
          true
        );
      } else if (type === "bulkAdd") {
        setChildLearningMomentCheckList([
          ...childLearningMomentCheckList,
          ...children.map((child) => child.id),
        ]);
        openSnack(`Added Evaluation moment to selected children!`, true);
      } else {
        setChildLearningMomentCheckList(childList.map((child) => child.id));
        openSnack(`Added Evaluation moment to all children!`, true);
      }
    }
  };

  return (
    <LearningMomentCheckboxes
      updateChecked={updateChecked}
      childLearningMomentCheckList={childLearningMomentCheckList}
    />
  );
};

export default EvaluationMomentBoxes;

import { Document, Font } from "@react-pdf/renderer";
import KaiTi from "fonts/KaiTi.ttf";
import FuturaBkBT from "fonts/FuturaBkBT.ttf";
import FuturaMediumBT from "fonts/FuturaMediumBT.ttf";
import FuturaLtBT from "fonts/FuturaLtBT.ttf";
import TheOnlyException from "fonts/TheOnlyException.ttf";

import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import { styles } from "components/ProjectReportPDF/ProjectReportPDF";
import PhotoBookCoverFrontComponent from "./Templates/PhotoBookPDFComponents/PhotoBookCoverFrontComponent";
import PhotoBookCoverBackComponent from "./Templates/PhotoBookPDFComponents/PhotoBookCoverBackComponent";

import {  View, Page } from "@react-pdf/renderer";
import { photoBookPageSize } from "components/ChildrenPortfolioPDF/Templates/constant";

//Register Font
Font.register({
  family: "Jost",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "medium",
    },
  ],
});

Font.register({
  family: "FuturaLtBT",
  fonts: [
    {
      src: FuturaLtBT,
    },
  ],
});

Font.register({
  family: "FuturaMediumBT",
  fonts: [
    {
      src: FuturaMediumBT,
    },
  ],
});

Font.register({
  family: "FuturaBkBT",
  fonts: [
    {
      src: FuturaBkBT,
    },
  ],
});

Font.register({
  family: "TheOnlyException",
  src: TheOnlyException,
});

Font.register({
  family: "KaiTi",
  src: KaiTi,
});

const ChildrenPhotoBook = ({
  projectName,
  childName,
  moduleName,
  projectImageSrc,
  imageListToRender,
  indexToRender,
  componentTemplateList,
  currLanguage,
}: {
  projectName: string;
  childName: string;
  moduleName: string;
  projectImageSrc: string;
  imageListToRender: ImageType[][];
  indexToRender: number[];
  componentTemplateList: any[];
  currLanguage: string;
}) => {
  const isChinese = currLanguage === "zh";

  if (isChinese) {
    styles.body = { ...styles.body, ...styles.chineseFont };
  } else {
    styles.body = { ...styles.body, ...styles.englishFont };
  }

  Font.registerHyphenationCallback((word: string) => {
    if (word.length === 1 || !isChinese) {
      return [word];
    }

    // Break chinese text
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  });

  return (
    <Document
      key={`${projectName}-${childName}-${imageListToRender.length}`}
      title={`${childName}-${projectName}`}
      language={currLanguage}
    >
      <PhotoBookCoverFrontComponent
        projectImageSrc={projectImageSrc}
        projectName={projectName}
        moduleName={moduleName}
      />
      {imageListToRender.map((imageList, index) => {
        const ComponentToRender = componentTemplateList[indexToRender[index]];
        return (
          <Page
            key={`photo-book-PDF-component-index-of-${projectName}-${childName}-${imageListToRender.length}-${index}`}
            size={photoBookPageSize}
            style={styles.body}
          >
            <View style={styles.main} wrap={false}>
              <ComponentToRender images={imageList} />
            </View>
          </Page>
        );
      })}

      <PhotoBookCoverBackComponent />
    </Document>
  );
};

export default ChildrenPhotoBook;

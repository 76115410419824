import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useStore from "store/store";
import { translation } from "constants/translation";
import { ASSESSMENT_PAGE, CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import BackButton from "components/BackButton";

type Props = {
  isAssessment: boolean;
  handleClose: () => void;
}

const EditProjectTitle = ({ isAssessment, handleClose }: Props) => {
  const navigate = useNavigate();
  const { currLanguage, projSlug } = useStore((state) => ({
    currLanguage: state.currLanguage,
    projSlug: state.currentProject.slug,
  }));

  const handleBack = () => { 
    const navigateMethod = isAssessment ? ASSESSMENT_PAGE : PROJECT_SUMMARY_PAGE;
    navigate(`${CURLANG(currLanguage)}/${navigateMethod(projSlug)}`);
    handleClose();
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BackButton
        variant="custom"
        sx={{
          position: "absolute",
          left: 0,
          color: "cyan.700"
        }}
        onBackClick={handleBack}
        text={translation.back}
      />
      <Typography
        component="h1"
        sx={{
          color: "neutral.900",
          fontSize: "24px",
          fontWeight: 900,
          textAlign: "center",
          lineHeight: 1.25,
        }}
      >
        {isAssessment ? "Edit Assessment" :translation.projectEditor}
      </Typography>
    </Box>
  );
};

export default EditProjectTitle;

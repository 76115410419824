import { useEffect, useRef, useState } from "react";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import HTMLFlipBook from "react-pageflip";
import { Box, Typography } from "@mui/material";
import PhotoBookCoverFrontComponent from "./Templates/PhotoBookComponents/PhotoBookCoverFrontComponent";
import PhotoBookCoverBackComponent from "./Templates/PhotoBookComponents/PhotoBookCoverBackComponent";
import PhotoBookPageOneComponent from "./Templates/PhotoBookComponents/PhotoBookPageOneComponent";
import PhotoBookPageTwoComponent from "./Templates/PhotoBookComponents/PhotoBookPageTwoComponent";
import PhotoBookPageThreeComponent from "./Templates/PhotoBookComponents/PhotoBookPageThreeComponent";
import PhotoBookPageFourComponent from "./Templates/PhotoBookComponents/PhotoBookPageFourComponent";
import PhotoBookPageFiveComponent from "./Templates/PhotoBookComponents/PhotoBookPageFiveComponent";
import PhotoBookPageSixComponent from "./Templates/PhotoBookComponents/PhotoBookPageSixComponent";
import useScale from "hooks/useScale";
import PhotoBookPageElevenComponent from "./Templates/PhotoBookComponents/PhotoBookPageElevenComponent";
import PhotoBookPageTwelveComponent from "./Templates/PhotoBookComponents/PhotoBookPageTwelveComponent";
import PhotoBookPageSevenComponent from "./Templates/PhotoBookComponents/PhotoBookPageSevenComponent ";
import PhotoBookPageEightComponent from "./Templates/PhotoBookComponents/PhotoBookPageEightComponent";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { componentList } from "./Templates/photoBookCommonConstant";

export const photoBookBoxStyle = {
  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.75))",
};

const AlbumPreviewContent = ({
  projectImageSrc,
  squareImages,
  horizontalImages,
  verticalImages,
  projectName,
  moduleName,
}: {
  projectImageSrc: string;
  squareImages: ImageType[];
  horizontalImages: ImageType[];
  verticalImages: ImageType[];
  projectName: string;
  moduleName: string;
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const book = useRef<any>(null);
  const onFlip = (e: any) => {
    // setCurrentPage(Math.ceil((e.data + 2) / 2));
    setCurrentPage(e.data);
  };

  const ref = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(0);
  useScale(ref, setScale, true);

  const [imageListToRender, setImageListToRender] = useState<ImageType[][]>([]);
  const [componentTemplateList, setComponentTemplateList] = useState<any>({
    0: PhotoBookPageOneComponent,
    1: PhotoBookPageTwoComponent,
    2: PhotoBookPageThreeComponent,
    3: PhotoBookPageFourComponent,
    4: PhotoBookPageFiveComponent,
    5: PhotoBookPageSixComponent,
    6: PhotoBookPageSevenComponent,
    7: PhotoBookPageEightComponent,
    8: PhotoBookPageElevenComponent,
    9: PhotoBookPageTwelveComponent,
  });
  const [indexToRender, setIndexToRender] = useState<number[]>([]);
  useEffect(() => {
    if (scale === 0) {
      return;
    }

    const horizontalImagesCopy: ImageType[] = JSON.parse(
      JSON.stringify([...horizontalImages, ...squareImages])
    );
    const verticalImagesCopy: ImageType[] = JSON.parse(
      JSON.stringify(verticalImages)
    );

    const imageList: ImageType[][] = [];
    const indexToRender: number[] = [];
    let flag = true;

    while (
      (horizontalImagesCopy.length > 0 || verticalImagesCopy.length > 0) &&
      flag === true
    ) {
      for (let i = 0; i < componentList.length; i++) {
        if (
          horizontalImagesCopy.length >= componentList[i].horizontal &&
          verticalImagesCopy.length >= componentList[i].vertical
        ) {
          const masterImages = [];
          if (componentList[i].horizontal > 0) {
            for (let j = 0; j < componentList[i].horizontal; j++) {
              const horizontalImage = horizontalImagesCopy.pop()!;
              masterImages.push(horizontalImage!);
            }
          }
          if (componentList[i].vertical > 0) {
            for (let k = 0; k < componentList[i].vertical; k++) {
              const verticalImage = verticalImagesCopy.pop()!;
              masterImages.push(verticalImage!);
            }
          }
          imageList.push(masterImages);
          indexToRender.push(i);
        }
        if (indexToRender.length === 20) {
          flag = false;
          break;
        }
      }
    }
    setIndexToRender(indexToRender);
    setImageListToRender(imageList);
  }, [scale]);

  const isIndexListOdd = indexToRender.length % 2 === 1; // if odd then +1
  const renderBackCover = isIndexListOdd ? [1, 2] : [1];

  return (
    <Box ref={ref}>
      {imageListToRender.length > 0 ? (
        <>
          {/* @ts-ignore */}
          <HTMLFlipBook
            ref={book}
            onFlip={onFlip}
            width={500}
            height={500}
            flippingTime={1000}
            size="stretch"
            showCover={true}
          >
            <Box sx={{ ...photoBookBoxStyle }}>
              {/* 1 horizontal image */}
              <PhotoBookCoverFrontComponent
                projectName={projectName}
                moduleName={moduleName}
                imageSrc={projectImageSrc}
                scale={scale}
              />
            </Box>
            {imageListToRender.map((imageList, index) => {
              const ComponentToRender =
                componentTemplateList[indexToRender[index]];
              return (
                <Box
                  sx={{ ...photoBookBoxStyle }}
                  key={`photo-book-component-index-of-${index}`}
                >
                  <ComponentToRender images={imageList} scale={scale} />
                </Box>
              );
            })}
            {renderBackCover.map((number) => {
              return (
                <Box sx={{ ...photoBookBoxStyle }}>
                  <PhotoBookCoverBackComponent />
                </Box>
              );
            })}
          </HTMLFlipBook>

          <Box sx={{ mt: 1 }}>
            <Typography sx={{ textAlign: "center" }}>
              {`${
                currentPage !== 0
                  ? `${currentPage}/${
                      isIndexListOdd
                        ? indexToRender.length + 2
                        : indexToRender.length + 1
                    }`
                  : `${
                      isIndexListOdd
                        ? indexToRender.length + 2
                        : indexToRender.length + 1
                    } Pages`
              }`}
            </Typography>

            <Typography sx={{ textAlign: "center" }}>
              Click on the book to flip through the template pages
            </Typography>
          </Box>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};

export default AlbumPreviewContent;

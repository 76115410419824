export const SPROUTS = "Sprouts";
export const SPROUTLINGS = "Sproutlings";
export const BUDS = "Buds";
export const BLOSSOMS = "Blossoms";
export const BLOOMS = "Blooms";

export const ageGroupMap: { [age: string]: string } = {
  Sprouts: "18 months - 2 years",
  Sproutlings: "2 years - 3 years",
  Buds: "3 years - 4 years",
  Blossoms: "4 years - 5 years",
  Blooms: "5 years - 6 years",
};

export const ageGroupExternalMap: { [age: string]: string } = {
  Sprouts: "18 months - 2 years",
  Buds: "3 years - 4 years",
  Blossoms: "5 years - 6 years",
};

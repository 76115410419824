import { Box, Typography } from "@mui/material";
import {
  DATE_FORMAT,
  cardStyles,
  labelStyles,
} from "../PhotoAlbumScreenComponents/PhotoAlbumComponents";
import { pdfjs } from "react-pdf";
import moment from "moment";
import ModuleTag from "components/ModuleTag/ModuleTag";
import { Project_Summary_Report } from "types/project";
import { PDFPreviewComponent } from "../CompletedWorkComponents/CompletedWorkCard";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ProjectSummaryReportCard = ({
  projectSummaryReport,
  isChildPortfolio = false,
}: {
  projectSummaryReport: Project_Summary_Report;
  isChildPortfolio?: boolean;
}) => {
  return (
    <a href={projectSummaryReport.file} target="_blank" rel="noreferrer">
      <Box sx={{ ...cardStyles, width: "250px", height: "250px" }}>
        <PDFPreviewComponent
          file={projectSummaryReport.file}
          // style={{ width: 100, height: 100, ...style }}
        />
        {/* Project info start */}
        {isChildPortfolio && (
          <Box
            sx={{
              position: "absolute",
              inset: 0,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              p: 1,
              // background:
              //   "linear-gradient(180deg, rgba(255,255,255,0.75) 30%, rgba(255,255,255,0) 100%)",
            }}
          >
            <Box>
              <ModuleTag
                projModule={projectSummaryReport.project.module}
                styles={{
                  backgroundColor: "rgba(209,228,227,0.75)",
                }}
                moduleIconWidth="16px"
                moduleIconHeight="16px"
                containerPadding="3px 6px"
              />
            </Box>
          </Box>
        )}
        {/* Project info end */}
        <Box
          sx={{
            ...labelStyles,
            bottom: 0,
            width: "100%",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0.75,
          }}
        >
          {projectSummaryReport.created_at && (
            <Typography
              sx={{
                fontSize: 10,
                lineHeight: 1,
                fontWeight: 700,
                color: "#312F30",
              }}
              noWrap
            >
              {moment(projectSummaryReport.created_at).format(DATE_FORMAT)}
            </Typography>
          )}
          {projectSummaryReport.created_by && (
            <Typography
              sx={{
                fontSize: 10,
                lineHeight: 1,
                fontWeight: 700,
                color: "#312F30",
              }}
              noWrap
            >
              {`By: ${projectSummaryReport.created_by}`}
            </Typography>
          )}
        </Box>
      </Box>
    </a>
  );
};

export default ProjectSummaryReportCard;

import React from "react";
import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageSeven from "../../../../../../../images/children-photo-book/page-7.png";
import { CaptionPDFComponent } from "./constant";
import {
  PDFScale,
  photoBookPageSevenCaptionInfo,
  photoBookPageSevenImageInfo,
} from "../photoBookCommonConstant";
import { Image } from "@react-pdf/renderer";
import {
  imageSrc,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";
export const PhotoBookPageSevenComponent = ({
  images,
}: {
  images: ImageType[];
}) => {
  const scale = PDFScale;
  const imageInfo = photoBookPageSevenImageInfo(scale);
  const captionInfo = photoBookPageSevenCaptionInfo(scale);

  return (
    <>
      <Image src={imageSrc(PhotoBookPageSeven)} style={styles.background} />

      <Image
        key={`image-${images[0].link}`}
        src={imageSrc(images[0].link)}
        style={{
          position: "absolute",
          top: imageInfo.top,
          left: imageInfo.left,
          width: imageInfo.width,
          height: imageInfo.height,
          objectFit: "cover",
        }}
      />

      <CaptionPDFComponent
        top={captionInfo.top}
        left={captionInfo.left}
        width={captionInfo.width}
        caption={images[0].caption}
        clampLine={captionInfo.clampLine}
        fontSize={captionInfo.fontSize}
        textAlign="left"
      />
    </>
  );
};

export default PhotoBookPageSevenComponent;

import React, { useState } from "react";

import {
  Box,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import {
  editImageCaption,
  editVideoCaption,
} from "api/gallery-api";

import { API_Update_Caption, GalleryPhotoVideo } from "types/gallery";

import { translation } from "constants/translation";
import {
  isProfileInGalleryAccessRoleList,
} from "helper/helper";

import "components/ProjectSummaryScreen/ReactGridGallery.css";

type Props = {
  editCaption: boolean;
  setEditCaption: React.Dispatch<React.SetStateAction<boolean>>;
  photoVideoGalleryList: GalleryPhotoVideo[];
  galleryIndex: number;
  currPhotoVideoIndex: number;
  initializeGallery: () => Promise<void>;
  selectedPhotoVideoIDs: string[];
}

const PhotoCaption = ({
  editCaption,
  photoVideoGalleryList,
  galleryIndex,
  currPhotoVideoIndex,
  selectedPhotoVideoIDs,
  initializeGallery,
  setEditCaption,
}: Props) => {
  const openSnack = useSnack();
  const [updatedCaption, setUpdatedCaption] = useState<API_Update_Caption>({
    id: "",
    caption: "",
  });

  const {
    currLanguage,
    role
  } = useStore((state) => ({
    ...state,
    role: state.profileDetails.role,
  }));

  const updatePhotoVideoCaption = async (isPhoto: boolean) => {
    let res;
    if (isPhoto) {
      res = await editImageCaption(currLanguage, updatedCaption);
    } else {
      res = await editVideoCaption(currLanguage, updatedCaption);
    }
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.success_update_image, true);
      setEditCaption(false);
      initializeGallery();
    }
  };

  const isPhoto = String(
    photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex].key
  ).includes("photo");
    
  if (editCaption) { 
    return (
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
          justifyContent: "center",
          m: "12px 0 24px",
        }}
      >
        <TextField
          inputProps={{ maxLength: 120 }}
          fullWidth
          multiline
          variant="standard"
          value={updatedCaption.caption}
          onChange={(event) => {
            setUpdatedCaption((prev) => ({
              ...prev,
              caption: event.target.value,
            }));
          }}
        />
        <CheckIcon
          sx={{
            cursor: "pointer",
            color: "green",
          }}
          onClick={() => {
            updatePhotoVideoCaption(isPhoto);
          }}
        />
        <CloseIcon
          sx={{
            cursor: "pointer",
            color: "red",
          }}
          onClick={() => setEditCaption(false)}
        />
      </Box>
    );
  }
    
  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        alignItems: "center",
        justifyContent: "center",
        m: { xs: "8px 0", sm: "12px 0 24px"},
        minHeight: "32px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          p: "4px 0",
          borderBottom: "1px solid transparent",
          fontSize: "1em",
          lineHeight: "1.4375em",
          color:
            isProfileInGalleryAccessRoleList(role) &&
            selectedPhotoVideoIDs.length === 0 &&
            photoVideoGalleryList[galleryIndex].photo_video_list[
            currPhotoVideoIndex
            ].alt === ""
            ? "rgba(0,0,0,0.6)"
            : "currentColor",
        }}
      >
        {isProfileInGalleryAccessRoleList(role) &&
          selectedPhotoVideoIDs.length === 0 &&
          photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex].alt === ""
            ? translation.addCaption
            : photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex].alt
        }
      </Typography>
      {isProfileInGalleryAccessRoleList(role) &&
        selectedPhotoVideoIDs.length === 0 && (
          <EditIcon
            sx={{
              cursor: "pointer",
              color: "txt.secondary",
              transition: "all 100ms ease-in-out",
              "&:hover": {
                color: "primary.main",
                transition: "all 100ms ease-in-out",
              },
              fontSize: "1.25rem",
            }}
            onClick={() => {
              setEditCaption(true);
              setUpdatedCaption({
                id: photoVideoGalleryList[galleryIndex]
                      .photo_video_list[currPhotoVideoIndex]
                      .key!.toString()
                      .split("-")[1],
                caption: photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex].alt
                          ? photoVideoGalleryList[galleryIndex].photo_video_list[currPhotoVideoIndex].alt!
                          : "",
              });
            }}
          />
      )}
    </Box>
  );
};

export default PhotoCaption;
import axios, { noAuthInstance as noAuthAxios } from "api/axiosConfig";

import { AxiosError } from "axios";

import {
  AuthAccountData,
  ChangePassword,
  ChangePasswordResponse,
  LoginAccountResponse,
  LoginError,
  RegisterAccountResponse,
  RegisterEmailError,
  RegisterPasswordError,
  UpdateProfile,
  UpdateProfileResponse,
} from "../types/auth";

export const registerAccount = async (accountData: AuthAccountData) => {
  try {
    const res = await axios.post("en/api/register", accountData);
    console.log("res: ", res);
    const data: RegisterAccountResponse = await res.data;
    return data;
  } catch (error: unknown) {
    console.log("error: ", error);
    let errorResponse: { error: string[] };
    if (error instanceof AxiosError && error.response) {
      const data: RegisterEmailError | RegisterPasswordError = error.response.data;
      if ("email" in data) {
        errorResponse = { error: [data.email] };
      } else {
        errorResponse = { error: data.password };
      }
      return errorResponse;
    }

    return { error: ["Failed"] };
  }
};
export const registerAccountBulk = async (accountsData: AuthAccountData[]) => {
  try {
    const res = await axios.post("en/api/register/bulk", accountsData);
    const data: RegisterAccountResponse[] = res.data;
    return data;
  } catch (error: unknown) {
    console.error("Error during bulk registration:", error);

    let errorResponse: { error: string[] };
    if (error instanceof AxiosError && error.response) {
      const data: RegisterEmailError | RegisterPasswordError = error.response.data;
      if ("email" in data) {
        errorResponse = { error: [data.email] };
      } else {
        errorResponse = { error: data.password };
      }
      return errorResponse;
    }

    return { error: ["Failed"] };
  }
};

export const loginAccount = async (accountData: AuthAccountData) => {
  try {
    const res = await axios.post("en/api/jwt/login", accountData);
    const data: LoginAccountResponse = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const { status, statusText } = error.response;
      return {
        error: { status, statusText },
      };
    } else {
      console.log("error: ", error);
      return {
        error: { status: 500, statusText: "Internal Server Error" },
      };
    }
  }
};

export const updateProfile = async (
  profileID: string,
  updatedProfile: UpdateProfile,
  accessToken: string | null
) => {
  try {
    const res = await axios.patch(`profile/${profileID}/update`, updatedProfile, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: any = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

export const getAllAttendance = async (groupID: number, accessToken: string) => {
  try {
    const res = await axios.get(`profile/attendance/all`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: any = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

export const getAttendanceToday = async (groupID: number, accessToken: string) => {
  try {
    const res = await axios.get(`profile/attendance/today/${groupID}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: any = await res.data;

    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

export const getAttendanceDateRange = async (
  groupID: number,
  accessToken: string,
  startDate: string,
  endDate: string
) => {
  try {
    const dateRange = {
      start_date: startDate,
      end_date: endDate,
    };
    const res = await axios.get(`profile/attendance/range/${groupID}`, {
      params: dateRange,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error: unknown) {
    console.log(error);
  }
};

export const createOrUpdateAttendance = async (absentees: any[], groupID: number, accessToken: string) => {
  try {
    const attendanceData = {
      absentees: absentees,
      groupID: groupID,
    };
    const res = await axios.post(`en/profile/attendance/create-or-update`, attendanceData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch {
    console.log("nothing");
  }
};

export const updateBulkProfileAttendance = async (groupId: any, updates: any, accessToken: string | null) => {
  try {
    const res = await axios.patch(`profile/${groupId}/bulk/update-attendance`, updates, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: any = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

export const updateProfileBulk = async (
  selectionModel: any,
  selectedGroups: any,
  accessToken: string | null
) => {
  try {
    const formData = new FormData();
    formData.append("selectionModel", selectionModel);
    formData.append("selectedGroups", selectedGroups.groups);
    const res = await axios.patch(`profile/bulk/update`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: any = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

export const updateProfileBulkV2 = async (profiles: any[]) => {
  try {
    const res = await axios.put(
      `profile/update/bulk/v2`,
      { profiles },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data: any = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};
export const deleteProfile = async (profileID: string) => {
  try {
    const res = await axios.delete(`profile/${profileID}/delete`);
    const data: UpdateProfileResponse = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    } else {
      return "Error!";
    }
  }
};

export const deleteProfileBulk = async (selectionModel: []) => {
  try {
    const res = await axios.delete(`profile/bulk/delete`, {
      data: { selectionModel },
    });
    const data: any = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    } else {
      return "Error!";
    }
  }
};

export const logoutAccount = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  localStorage.removeItem("profile_id");
  localStorage.removeItem("curr_group_id");
  localStorage.removeItem("school_id");
};

export const changePassword = async (
  profileID: string,
  passwordData: ChangePassword,
  accessToken: string | null
) => {
  try {
    const res = await axios.put(`api/jwt/password/change/${profileID}`, passwordData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: ChangePasswordResponse = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

export const sendResetPasswordEmail = async (email: string) => {
  try {
    const res = await axios.post(`api/users/reset_password/`, {
      email,
    });
    const data: number = await res.status;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

export const resetPassword = async (
  uid: string,
  token: string | null,
  newPassword: string,
  reNewPassword: string
) => {
  try {
    const res = await axios.post(`api/users/reset_password_confirm/`, {
      uid,
      token,
      new_password: newPassword,
      re_new_password: reNewPassword,
    });
    const data: number = await res.status;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const data = error.response.data;
      return {
        error: [data],
      };
    } else {
      return {
        error: [""],
      };
    }
  }
};

import moment from "moment";
import {
  NORMAL_ROLE_LIST,
  UserRoleMapping,
} from "types/auth";

//mui datagrid
import { GridRowModel } from "@mui/x-data-grid";

export const TICKET_STATUS = [
  {
    value: "review",
    label: "Under Review",
  },
  {
    value: "rejected",
    label: "Rejected",
  },
  {
    value: "in_progress",
    label: "In Progress",
  },
  {
    value: "done",
    label: "Done",
  },
];

export const DOB_COLUMN = {
  field: "dob",
  headerName: "Date of Birth",
  type: "date",
  width: 160,
  editable: true,
  valueFormatter: (params: any) => moment(params?.value).format("DD/MM/YYYY"),
};

export const ROLE_COLUMN = (currRole: string) => {
  let roleList = NORMAL_ROLE_LIST;
  if (currRole === "WAD") {
    roleList = [
      "Web Admin",
      "Admin",
      "Content Editor",
      "TTA Content Editor",
      ...roleList,
    ];
  }
  return {
    field: "role",
    headerName: "Role",
    type: "singleSelect",
    width: 170,
    editable: true,

    valueOptions: (params: any) => {
      return roleList.map((role) => ({
        value: UserRoleMapping[role],
        label: role,
      }));
      // return schoolList.map((school) => {
      //   return school.name;
      // });
    },
  };
};

export const convertToDateString = (date: Date) => {
  return moment(date).format("YYYY-MM-DD");
};

//FOR PROFILE ONLY
export const processRowUpdateBody = (newRow: GridRowModel) => {
  //call update api

  // Remove groups since it's handle separately
  let body = Object.keys(newRow)
    .filter((key: string) => key != "groups")
    .reduce((acc: any, key: string) => {
      acc[key] = newRow[key];
      return acc;
    }, {});

  if (typeof body.image === "string") {
    //don't send if is string
    body = Object.keys(body)
      .filter((key: string) => key != "image")
      .reduce((acc: any, key: string) => {
        acc[key] = body[key];
        return acc;
      }, {});
  }

  //remove dob if null
  if (body.dob === null) {
    body = Object.keys(body)
      .filter((key: string) => key != "dob")
      .reduce((acc: any, key: string) => {
        acc[key] = body[key];
        return acc;
      }, {});
  } else if (typeof body.dob !== "string") {
    body.dob = convertToDateString(body.dob);
  }
  // console.log("RETURNED BODY: ", body);

  return body;
};

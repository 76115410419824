import  { useState, useEffect } from "react";

import useStore from "store/store";

import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { CalendarViewOptions } from "types/calendar";
import { generateDurationLabel } from "containers/CalendarPage/constants/helper";

const DateControl = () => {
  const {
    calendarStartDate,
    setCalendarStartDate,
    startAndEndDate,
    currentView,
  } = useStore((state) => ({
    calendarStartDate: state.calendarStartDate,
    setCalendarStartDate: state.setCalendarStartDate,
    startAndEndDate: state.startAndEndDate,
    currentView: Object.keys(state.calendarViews).filter(
      (view) => state.calendarViews[view as CalendarViewOptions] === true
    )[0],
  }));

  const [label, setLabel] = useState<string>("");

  useEffect(() => {
    // console.log("Generating duration label...");
    setLabel(
      generateDurationLabel(currentView as CalendarViewOptions, startAndEndDate) //label beside Chevron
    );
  }, [startAndEndDate, currentView]);

  const handleClick = (right = false) => {
    //when chevron right is clicked.
    let newDate = new Date(calendarStartDate);

    switch (currentView) {
      case "Week":
        {
          right
            ? newDate.setDate(newDate.getDate() + 7)
            : newDate.setDate(newDate.getDate() - 7);
        }
        break;
      case "Month":
        {
          right
            ? (newDate = new Date(
                newDate.getFullYear(),
                newDate.getMonth() + 2,
                1
              ))
            : (newDate = new Date(
                newDate.getFullYear(),
                newDate.getMonth() - 2,
                1
              ));
        }
        break;
      case "Year":
        {
          right
            ? (newDate = new Date(newDate.getFullYear() + 1, 0, 1))
            : (newDate = new Date(newDate.getFullYear() - 1, 0, 1));
        }
        break;
    }
    setCalendarStartDate(newDate);
  };
  // console.log("calendarStartDate: ", calendarStartDate);
  return (
    <Box
      sx={{
        display: "flex",
        p: "10px 20px",
        height: "50px",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: "bold", fontSize: "15px", mr: "10px" }}>
        {label}
      </Typography>
      <ChevronLeftIcon
        sx={{ cursor: "pointer" }}
        onClick={() => handleClick(false)}
      />
      <ChevronRightIcon
        sx={{ cursor: "pointer" }}
        onClick={() => handleClick(true)}
      />
    </Box>
  );
};

export default DateControl;

import React, { useState, useEffect } from "react";

import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Box,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import Button from "components/Button/Button";
import Logo from "images/logo-2.png";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import DeleteIcon from "@mui/icons-material/Delete";

import { API_Profile } from "types/profile";

import { API_Group_Simple, API_Group_Id_Only } from "types/group";

import {
  createGroup,
  updateGroup,
  getGroup,
  getAccountProfile,
  deleteGroup,
} from "api/profile-api";

import { CURLANG, CLASS } from "constants/url";
import { translation } from "constants/translation";
import GroupAvatar from "./GroupAvatar";
import ChildTransferList from "./ChildTransferList";
import TeacherTransferList from "./TeacherTransferList";
import { API_School } from "types/school";
import { getAllSchool } from "api/school-api";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import {
  BLOOMS,
  BLOSSOMS,
  BUDS,
  SPROUTLINGS,
  SPROUTS,
} from "constants/project-lesson-activity";

const CreateGroupPage = ({
  create = false,
  isModal = false,
  handleClose,
  schoolId = -1,
  setFormData,
  setGroupListDisplay,
}: {
  create?: boolean;
  isModal?: boolean;
  handleClose?: () => void;
  schoolId?: number;
  setFormData?: any;
  setGroupListDisplay?: React.Dispatch<
    React.SetStateAction<API_Group_Simple[]>
  >;
}) => {
  const { state }: any = useLocation();
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { groupSlug } = useParams();

  //Warning Dialog
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [selectedChild, setSelectedChild] = useState<number[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<number[]>([]);

  const [currentGroupData, setCurrentGroupData] = useState<API_Group_Id_Only>({
    id: -1,
    name: "",
    description: "",
    slug: "",
    age: "Sproutlings",
    school: schoolId,
    image: null,
    all_children: [],
    children: [],
    children_school: [],
    teachers: [],
    teachers_school: [],
    communication_lvl: "",
  });

  const {
    currLanguage,
    profileID,
    profileDetails,
    setProfileDetails,
    schools,
    setSchools,
    setRefreshGroup,
    refreshGroupTeacherList,
    refreshGroupChildrenList,
    setRefreshGroupTeacherList,
    setRefreshGroupChildrenList,
  } = useStore((state) => state);

  const [defaultSchool, setDefaultSchool] = useState<API_School>();

  const refreshChildList = async () => {
    const data: API_Profile = await getAccountProfile(
      String(profileDetails.id),
      localStorage.getItem("access")
    );
    setProfileDetails(data);
  };

  const refreshCurrentGroupData = async (groupSlug: string) => {
    const res = await getGroup(groupSlug);
    if (typeof res === "string" || !res.children_school) return;

    // if (typeof res === "string") return;

    setCurrentGroupData({
      id: res.id,
      name: res.name,
      description: res.description,
      slug: res.slug,
      age: res.age,
      school: res.school,
      image: res.image,
      all_children: res.children,
      children: res.children.map((child) => {
        return child.id;
      }),
      children_school: res.children_school ? res.children_school : [],
      teachers: res.teachers.map((teacher) => {
        return teacher.id;
      }),
      teachers_school: res.teachers_school ? res.teachers_school : [],
      communication_lvl: res.communication_lvl,
    });
    setRefreshGroupTeacherList(false);
    setRefreshGroupChildrenList(false);
  };

  useEffect(() => {
    const populateData = async () => {
      if (!create && groupSlug) {
        refreshCurrentGroupData(groupSlug);
      }
      setSchools(await getAllSchool());
    };
    populateData();
  }, [groupSlug]);

  useEffect(() => {
    const populateData = async () => {
      if (
        !create &&
        groupSlug &&
        (refreshGroupTeacherList || refreshGroupChildrenList)
      ) {
        refreshCurrentGroupData(groupSlug);
      }
      setSchools(await getAllSchool());
    };
    populateData();
  }, [refreshGroupTeacherList, refreshGroupChildrenList]);

  useEffect(() => {
    setCurrentGroupData((prev) => ({
      ...prev,
      children: selectedChild,
    }));
  }, [selectedChild]);

  useEffect(() => {
    setCurrentGroupData((prev) => ({
      ...prev,
      teachers: selectedTeacher,
    }));
  }, [selectedTeacher]);

  useEffect(() => {
    if (profileDetails.school) {
      setCurrentGroupData((prev) => ({
        ...prev,
        school: profileDetails?.school!.id,
      }));

      setDefaultSchool(
        schools.filter((school) => school.id === profileDetails?.school!.id)[0]
      );
    }
    if (profileDetails.role === "WAD") {
      if (schoolId !== -1) {
        setCurrentGroupData((prev) => ({
          ...prev,
          school: schoolId,
        }));

        setDefaultSchool(schools.filter((school) => school.id === schoolId)[0]);
      } else if (state && state.hasOwnProperty("schoolId")) {
        setCurrentGroupData((prev) => ({
          ...prev,
          school: state.schoolId,
        }));

        setDefaultSchool(
          schools.filter((school) => school.id === state.schoolId)[0]
        );
      }
    }
  }, [schools, profileDetails]);

  const handleSubmit = async () => {
    if (create) {
      const res = await createGroup(currentGroupData);
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        openSnack(`Created ${currentGroupData.name} group successfully!`, true);
        if (setFormData) {
          setFormData((prev: any) => ({
            ...prev,
            groups: [...prev.groups, res.id],
          }));
        }
        if (setGroupListDisplay) {
          setGroupListDisplay((prev) => [...prev, res]);
        }
        if (handleClose) {
          handleClose();
        } else {
          navigate(`${CURLANG(currLanguage)}/${CLASS}/${res.slug}/edit`);
          // navigate(`${CURLANG(currLanguage)}/${CLASS}`);
        }
        setRefreshGroup(true);
      }
    } else {
      //update
      const res = await updateGroup(currentGroupData);
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        openSnack(`Updated ${currentGroupData.name} group successfully!`, true);
        if (handleClose) {
          handleClose();
        } else {
          navigate(`${CURLANG(currLanguage)}/${CLASS}`);
        }
      }
    }
    refreshChildList();
  };

  const handleDelete = async () => {
    const res = await deleteGroup(currentGroupData.id);

    if (typeof res === "string" && res.length >= 1) {
      openSnack(res, false);
    } else {
      openSnack(`Delete Group successfully!`, true);
      refreshChildList();
      navigate(`${CURLANG(currLanguage)}/${CLASS}`);
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete ${currentGroupData.name} Group?`,
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return !!profileID ? (
    <Box sx={{ p: "16px" }}>
      {create ? null : <WarningDialog {...warningBody} />}
      <Stack
        sx={{
          margin: "auto",
          m: { xs: "4px 0 16px 0", sm: "16px 0" },
          alignItems: "center",
        }}
        spacing={{ xs: 1, sm: 2, md: 3 }}
        direction="column"
      >
        {!isModal ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Button
              buttonText={``}
              arrow={true}
              backward={true}
              onClick={() => {
                navigate(`${CURLANG(currLanguage)}/${CLASS}`);
              }}
            />
            <Box>
              <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
                {create ? translation.create_group : translation.edit_group}

                {create ? null : (
                  <DeleteIcon
                    sx={{
                      cursor: "pointer",
                      color: "txt.secondary",
                      transition: "all 100ms ease-in-out",
                      "&:hover": {
                        color: "primary.main",
                        transition: "all 100ms ease-in-out",
                      },
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleOpenDialog();
                    }}
                  />
                )}
              </Typography>
            </Box>
            <Button
              buttonText={``}
              arrow={true}
              backward={true}
              onClick={() => {
                navigate(`${CURLANG(currLanguage)}/${CLASS}`);
              }}
              style={{ background: "transparent" }}
              disabled={true}
            />
          </Box>
        ) : (
          <Typography
            sx={{ fontSize: { xs: "1rem", md: "2rem" }, fontWeight: "500" }}
          >
            {translation.create_group}
          </Typography>
        )}

        <Stack
          sx={{
            margin: "auto",
            my: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
          direction={{ sm: "column", md: "row" }}
        >
          {/* Image */}

          <GroupAvatar
            width={{ xs: 200, md: 350 }}
            height={{ xs: 200, md: 350 }}
            image={
              typeof currentGroupData.image === "string"
                ? currentGroupData.image
                : typeof currentGroupData.image === "object"
                ? currentGroupData.image !== null
                  ? URL.createObjectURL(currentGroupData.image)
                  : Logo
                : Logo
            }
            imageFallbackChar={
              currentGroupData.name
                ? currentGroupData.name.charAt(0).toUpperCase()
                : ""
            }
            setCurrentGroupData={setCurrentGroupData}
          />
          <Stack
            component="form"
            sx={{
              margin: "auto",
              my: "16px",
              alignItems: "center",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
            direction="column"
          >
            <TextField
              required
              sx={{
                width: { xs: "min(400px, calc(100vw - 48px))", sm: "300px" },
              }}
              label={translation.group_name}
              value={currentGroupData.name}
              onChange={(event) => {
                setCurrentGroupData((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
            />
            <TextField
              sx={{
                width: { xs: "min(400px, calc(100vw - 48px))", sm: "300px" },
              }}
              label={translation.description}
              value={currentGroupData.description}
              onChange={(event) => {
                setCurrentGroupData((prev) => ({
                  ...prev,
                  description: event.target.value,
                }));
              }}
            />

            <FormControl required>
              <InputLabel id="demo-simple-select-label">
                {translation.level}
              </InputLabel>
              <Select
                sx={{
                  width: { xs: "min(400px, calc(100vw - 48px))", sm: "300px" },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentGroupData.age}
                label={translation.age}
                onChange={(event) => {
                  setCurrentGroupData((prev) => ({
                    ...prev,
                    age: event.target.value,
                    communication_lvl: event.target.value,
                  }));
                }}
              >
                <MenuItem value={SPROUTS}>
                  {translation.sprouts_with_age}
                </MenuItem>
                <MenuItem value={BUDS}>{translation.buds_with_age}</MenuItem>
                <MenuItem value={BLOSSOMS}>
                  {translation.blossoms_with_age}
                </MenuItem>
              </Select>
            </FormControl>

            {create && (
              <Autocomplete
                sx={{ minWidth: 180, ...TEXT_FIELD_STYLE }}
                id="combo-box-register"
                value={defaultSchool || null}
                onChange={(event: any, school_updated: API_School | null) => {
                  if (school_updated !== null) {
                    setCurrentGroupData((prev) => ({
                      ...prev,
                      school: school_updated.id,
                    }));
                    setDefaultSchool(school_updated);
                  }
                }}
                options={schools}
                getOptionLabel={(school) => school?.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                renderInput={(params) => {
                  return (
                    <TextField {...params} label="Schools" placeholder="" />
                  );
                }}
                disabled={
                  schoolId === -1
                    ? profileDetails.role === "WAD"
                      ? false
                      : true
                    : true
                }
              />
            )}
          </Stack>
        </Stack>

        {!create && (
          <>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: "1.75rem", fontWeight: "500" }}>
                {translation.assignTeacherToGroup}
              </Typography>
            </Box>
            <Box>
              <TeacherTransferList
                selectedTeacher={selectedTeacher}
                setSelectedTeacher={setSelectedTeacher}
                currentGroupData={currentGroupData}
                teacherList={currentGroupData.teachers_school}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  fontSize: "1.75rem",
                  fontWeight: "500",
                  marginTop: { xs: 3, sm: 0 },
                }}
              >
                {translation.assignChildrenToGroup}
              </Typography>
            </Box>
            <Box>
              <ChildTransferList
                setSelectedChild={setSelectedChild}
                currentGroupData={currentGroupData}
                childList={currentGroupData.all_children}
              />
            </Box>
          </>
        )}

        <Button
          buttonText={
            create
              ? translation.submit || "Submit"
              : translation.update || "Update"
          }
          style={{ marginTop: "20px" }}
          onClick={handleSubmit}
          disabled={currentGroupData.name === "" || currentGroupData.age === ""}
        />
      </Stack>
    </Box>
  ) : (
    <Typography
      sx={{
        fontSize: "36px",
        height: "71vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {translation.not_logged_in}
    </Typography>
  );
};

export default CreateGroupPage;
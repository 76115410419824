import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, SxProps, Typography, Checkbox, Tooltip } from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import useStore from "store/store";
import { API_Teacher_Module_Analytic } from "types/teacher";
import {
  CURLANG,
  EDIT_TEACHER_MODULE_PAGE,
  TEACHER_MODULE_PAGE,
} from "constants/url";
import { translation } from "constants/translation";
import IndividualChartModal from "./IndividualChartModal"

const AnalyticModuleListTable = ({
  moduleList,
  setModuleList,
  setSelected,
}: {
  moduleList: API_Teacher_Module_Analytic[];
  setModuleList: React.Dispatch<
    React.SetStateAction<API_Teacher_Module_Analytic[]>
  >;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  // const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const iconStyles: SxProps = {
    color: "#312F30B2",
    cursor: "pointer",
  };

  const getCompletedRate = (params: any) => {
    if (params.row.enrolled_no !== 0) {
      return Math.round(
        (params.row.completed_no / params.row.enrolled_no) * 100
      );
    }
    return 0;
  };

  // Modal
  const [selectedModule, setSelectedModule] =
    useState<API_Teacher_Module_Analytic>({
      id: -1,
      name: "",
      slug: "",
      lesson_no: -1,
      quiz_no: -1,
      enrolled_no: -1,
      active_enrolled_no: -1,
      completed_no: -1,
      rating_no: -1,
      avg_rating: -1,
    });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Title",
      flex: 1,
      minWidth: 400,

      renderCell: (params) => (
        <Link
          style={{
            color: "#407374",
          }}
          to={`${CURLANG(currLanguage)}/${TEACHER_MODULE_PAGE(
            params.row.slug
          )}`}
        >
          <Tooltip title={params.value}>
            <Box
              sx={{
                maxWidth: params.colDef.computedWidth,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params.value}
            </Box>
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "lesson_no",
      headerName: "# of Lessons",
      type: "number",
      maxWidth: 200,
    },
    {
      field: "quiz_no",
      headerName: "# of Quizes",
      type: "number",
      maxWidth: 200,
    },
    {
      field: "enrolled_no",
      headerName: "# of Enrollment",
      type: "number",
      minWidth: 140,
      maxWidth: 200,
    },
    {
      field: "active_enrolled_no",
      headerName: "# of Active Enrollments",
      type: "number",
      minWidth: 170,
      maxWidth: 200,
    },

    // {
    //   field: "completed_no",
    //   headerName: "# of Completion",
    //   type: "number",
    //   maxWidth: 200,
    // },
    {
      field: "completedRate",
      headerName: "Completion Rate (%)",
      minWidth: 150,
      maxWidth: 200,
      type: "number",

      valueGetter: getCompletedRate,
    },
    {
      field: "rating_no",
      headerName: "# of Rating",
      type: "number",
      maxWidth: 200,
    },
    {
      field: "avg_rating",
      headerName: "Avg Rating",
      type: "number",
      maxWidth: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              to={`${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(
                params.row.slug
              )}`}
            >
              <EditIcon fontSize="small" sx={iconStyles} />
            </Link>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                // pointerEvents: "none",
              }}
              onClick={() => {
                setSelectedModule(params.row);
                handleOpen();
              }}
            >
              <AssessmentOutlinedIcon
                fontSize="small"
                sx={{ ...iconStyles, opacity: 1 }}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <IndividualChartModal
        selectedModule={selectedModule}
        open={openModal}
        handleClose={handleClose}
      />
      <DataGrid
        rows={moduleList}
        columns={columns}
        // columnVisibilityModel={{
        //   access: currSchool.id !== -1, //show access if school exist
        //   published: currSchool.id === -1, //show publish if school doesn't exist
        // }}
        disableColumnMenu
        components={{
          BaseCheckbox(props) {
            return (
              <Checkbox
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent !important",
                  },
                }}
                icon={
                  <CheckBoxOutlineBlankOutlinedIcon
                    fontSize="small"
                    sx={iconStyles}
                  />
                }
                checkedIcon={
                  <CheckBoxOutlinedIcon
                    fontSize="small"
                    sx={{ ...iconStyles, color: "black" }}
                  />
                }
                indeterminateIcon={
                  <IndeterminateCheckBoxOutlinedIcon
                    fontSize="small"
                    sx={iconStyles}
                  />
                }
                disableRipple
                {...props}
              />
            );
          },
          NoRowsOverlay: NoModuleFound,
        }}
        loading={false}
        sx={{
          border: "none",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: 0,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        onSelectionModelChange={(ids) => {
          const selectedIds = ids.map((id) => id.toString());
          setSelected(selectedIds);
        }}
      />
    </>
  );
};

const NoModuleFound = () => (
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "1.25rem",
      textAlign: "center",
      backgroundColor: "#FBFBFB",
      p: 2,
    }}
  >
    {translation.noModuleFound}
  </Typography>
);

export default AnalyticModuleListTable;

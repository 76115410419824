import React from "react";

import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  FormControl,
  Menu,
  TextField,
} from "@mui/material";

import { API_Profile_Short } from "types/profile";
import "components/ProjectSummaryScreen/ReactGridGallery.css";

import Logo from "images/logo-2-colour.png";

const ProfileTaggingMenu = ({
  anchorEl,
  isOpenMenu,
  handleCloseMenu,
  selectedProfiles,
  galleryIndex,
  currPhotoVideoIndex,
  handleProfileTagChange,
  profileDict,
}: {
  anchorEl: HTMLElement | null;
  isOpenMenu: boolean;
  handleCloseMenu: () => void;
  selectedProfiles: string[][][];
  galleryIndex: number;
  currPhotoVideoIndex: number;
  handleProfileTagChange: (profileIdList: string[]) => void;
  profileDict: {
    [id: string]: API_Profile_Short;
  };
}) => {
  const profileList = Object.keys(profileDict).map((profileId) => {
    return {
      profile_id: profileId,
      preferred_name: profileDict[profileId].preferred_name,
      image: profileDict[profileId].image,
    };
  });

  const selectedProfileList = selectedProfiles[galleryIndex][
    currPhotoVideoIndex
  ].reduce<{ profile_id: string; preferred_name: string; image: string }[]>(
    (acc, profileId) => {
      if (profileDict[profileId]) {
        acc.push({
          profile_id: profileId,
          preferred_name: profileDict[profileId].preferred_name,
          image: profileDict[profileId].image,
        });
      }
      return acc;
    },
    []
  );
  //   console.log("profileList: ", profileList);
  //   console.log("selectedProfileList: ", selectedProfileList);

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isOpenMenu}
      onClose={handleCloseMenu}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 12,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <FormControl sx={{ m: 1, width: 300, px: 2 }}>
        {/* <InputLabel id="multiple-chip-label">{translation.profiles}</InputLabel> */}
        <Autocomplete
          multiple
          id="tags-standard"
          value={selectedProfileList}
          onChange={(event, values) => {
            const profileIdList = values.map((profile) =>
              profile.profile_id.toString()
            );
            handleProfileTagChange(profileIdList);
          }}
          options={profileList}
          getOptionLabel={(option) => option.preferred_name}
          isOptionEqualToValue={(option, value) =>
            option.profile_id === value.profile_id
          }
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Profiles" />
          )}
          renderTags={(value, getTagProps) => {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {value.map((option, index) => (
                    <Chip
                      key={`chip-of-${option.preferred_name}`}
                      avatar={
                        <Avatar
                          alt={option.preferred_name}
                          src={option.image}
                          imgProps={{
                            onError: (
                              event: React.SyntheticEvent<
                                HTMLImageElement,
                                Event
                              >
                            ) => {
                              event.currentTarget.onerror = null;
                              event.currentTarget.src = Logo;
                            },
                          }}
                        />
                      }
                      // key={`${option.preferred_name}-${option.profile_id}`}
                      label={option.preferred_name}
                      //   {...getTagProps({ index })}
                    />
                  ))}
                </Box>
              </>
            );
          }}
        />
        {/* {/* <Select
          labelId="multiple-chip-label"
          multiple
          value={selectedProfiles[galleryIndex][currPhotoVideoIndex]}
          onChange={handleProfileTagChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selectedProfiles) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {selectedProfiles.map((value) => (
                <Chip
                  avatar={
                    <Avatar
                      alt={profileDict[value].preferred_name[0]}
                      src={profileDict[value].image}
                      imgProps={{
                        onError: (
                          event: React.SyntheticEvent<HTMLImageElement, Event>
                        ) => {
                          event.currentTarget.onerror = null;
                          event.currentTarget.src = Logo;
                        },
                      }}
                    />
                  }
                  key={value}
                  label={profileDict[value].preferred_name}
                />
              ))}
            </Box>
          )}
        > */}
        {/* {Object.keys(profileDict).map((prof) => {
            return (
              <MenuItem
                key={profileDict[prof].id.toString()}
                value={profileDict[prof].id.toString()}
              >
                {profileDict[prof].preferred_name}
              </MenuItem>
            );
          })}
        </Select>  */}
      </FormControl>
    </Menu>
  );
};

export default ProfileTaggingMenu;

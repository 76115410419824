import React from "react";
import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageFour from "../../../../../../../images/children-photo-book/page-4.png";
import PhotoBookPageFourTop from "../../../../../../../images/children-photo-book/page-4-top.png";
import { CaptionPDFComponent } from "./constant";
import {
  PDFScale,
  photoBookPageFourCaptionInfo,
  photoBookPageFourCaptionPositionList,
  photoBookPageFourImagePositionList,
} from "../photoBookCommonConstant";
import { Image } from "@react-pdf/renderer";

import {
  imageSrc,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";

export const PhotoBookPageFourComponent = ({
  images,
}: {
  images: ImageType[];
}) => {
  const scale = PDFScale;
  const imagePositionList = photoBookPageFourImagePositionList(images, scale);
  const captionPositionList = photoBookPageFourCaptionPositionList(
    images,
    scale
  );
  const captionInfo = photoBookPageFourCaptionInfo(scale);

  return (
    <>
      <Image src={imageSrc(PhotoBookPageFour)} style={styles.background} />

      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            key={`image-${imagePositionInfo.src}`}
            src={imageSrc(imagePositionInfo.src)}
            style={{
              position: "absolute",
              top: imagePositionInfo.top,
              left: imagePositionInfo.left,
              width: imagePositionInfo.width,
              height: imagePositionInfo.height,
              objectFit: "cover",
            }}
          />
        );
      })}
      <Image src={imageSrc(PhotoBookPageFourTop)} style={styles.background} />

      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionPDFComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionInfo.top}
            left={captionPositionInfo.left}
            width={captionPositionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize * scale}
            textAlign="center"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageFourComponent;

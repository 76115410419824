import React, { useState } from "react";
import {
  Paper,
  InputBase,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { bulkCreateAccess, bulkDeleteAccess } from "api/access-api";
import { bulkPublishProject } from "api/project-api";
import { API_Project_Access_Control, Query_Data } from "types/project";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_School } from "types/school";
import { ViewMode } from "types/teacher";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  ACCESS_CONTROL,
  INSIGHTS,
  VISUALIZATION,
} from "constants/staticConstant";

const SecondRow = ({
  pageMode,
  projects,
  setProjects,
  queryData,
  setQueryData,
  currSchool,
  accessList,
  setAccessList,
  selected,
}: {
  pageMode: ViewMode;
  projects: API_Project_Access_Control[];
  setProjects: React.Dispatch<
    React.SetStateAction<API_Project_Access_Control[]>
  >;
  queryData: Query_Data;
  setQueryData: (value: React.SetStateAction<Query_Data>) => void;
  currSchool: API_School;
  accessList: { [projectID: string]: number };
  setAccessList: React.Dispatch<
    React.SetStateAction<{
      [projectID: string]: number;
    }>
  >;
  selected: string[];
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      {/* minWidth: 356  */}
      <Box sx={{ display: "flex", gap: 1, minWidth: 336 }}>
        <SearchBar queryData={queryData} setQueryData={setQueryData} />
        {(pageMode === (INSIGHTS as ViewMode) ||
          pageMode === (VISUALIZATION as ViewMode)) && (
          <>
            <StartAtDateField
              queryData={queryData}
              setQueryData={setQueryData}
            />
            <EndAtDateField queryData={queryData} setQueryData={setQueryData} />
          </>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2.75,
        }}
      >
        <DownloadButton />
        {pageMode === (ACCESS_CONTROL as ViewMode) && (
          <BulkAction
            projects={projects}
            setProjects={setProjects}
            currSchool={currSchool}
            accessList={accessList}
            setAccessList={setAccessList}
            selected={selected}
          />
        )}
      </Box>
    </Box>
  );
};

const SearchBar = ({
  queryData,
  setQueryData,
}: {
  queryData: any;
  setQueryData: (value: React.SetStateAction<any>) => void;
}) => {
  const handleChange = (query: string) => {
    setQueryData((prev: any) => ({ ...prev, query: query }));
  };

  return (
    <Paper
      component="form"
      sx={{
        py: 0.5,
        px: 1.5,
        display: "flex",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        borderRadius: "10px",
        border: "1px solid #DFDDDD",
        flex: 1,
      }}
      elevation={0}
    >
      <IconButton sx={{ p: 0, pointerEvents: "none" }} aria-label="search">
        <SearchIcon fontSize="small" />
      </IconButton>

      <InputBase
        sx={{ ml: 1.5, flex: 1 }}
        placeholder={translation.search}
        inputProps={{ "aria-label": "Search" }}
        value={queryData.query === null ? "" : queryData.query}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      />
    </Paper>
  );
};

export const StartAtDateField = ({
  queryData,
  setQueryData,
}: // maxWidth,
{
  queryData: any;
  setQueryData: React.Dispatch<React.SetStateAction<any>>;
  // maxWidth,
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        maxDate={queryData.endDate ? moment(queryData.endDate) : moment(today)}
        label={translation.startDate}
        inputFormat="YYYY-MM-DD"
        value={queryData.startDate}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            setQueryData((prev: any) => ({
              ...prev,
              startDate: newValue.format("YYYY-MM-DD").toString(),
            }));
          } else {
            setQueryData((prev: any) => ({
              ...prev,
              startDate: null,
            }));
          }
        }}
        renderInput={(params) => (
          // sx={{ minWidth: 145 }}
          <TextField
            {...params}
            size="small"
            sx={{
              flex: 1,
              minWidth: 100,
              // maxWidth: {
              //   xs: 9999,
              //   md: 200,
              // },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const EndAtDateField = ({
  queryData,
  setQueryData,
}: {
  queryData: any;
  setQueryData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        minDate={moment(queryData.startDate ? queryData.startDate : null)}
        maxDate={moment(today)}
        label={translation.endDate}
        inputFormat="YYYY-MM-DD"
        value={queryData.endDate ? queryData.endDate : null}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            setQueryData((prev: any) => ({
              ...prev,
              endDate: newValue.format("YYYY-MM-DD").toString(),
            }));
          } else {
            setQueryData((prev: any) => ({
              ...prev,
              endDate: null,
            }));
          }
        }}
        renderInput={(params) => (
          // sx={{ minWidth: 145 }}
          <TextField
            {...params}
            size="small"
            sx={{
              flex: 1,
              minWidth: 100,
              // maxWidth: {
              //   xs: 9999,
              //   md: 200,
              // },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const DownloadButton = () => {
  return (
    <CloudDownloadOutlinedIcon
      sx={{
        color: "#312F30B2",
        cursor: "pointer",
      }}
    />
  );
};

const BulkAction = ({
  projects,
  setProjects,
  currSchool,
  accessList,
  setAccessList,
  selected,
}: {
  projects: API_Project_Access_Control[];
  setProjects: React.Dispatch<
    React.SetStateAction<API_Project_Access_Control[]>
  >;
  currSchool: API_School;
  accessList: { [projectID: string]: number };
  setAccessList: React.Dispatch<
    React.SetStateAction<{
      [projectID: string]: number;
    }>
  >;
  selected: string[];
}) => {
  const openSnack = useSnack();

  const { schools, role } = useStore((state) => ({
    schools: state.schools,
    role: state.profileDetails.role,
  }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePublish = async () => {
    const projectIds = projects
      .filter(
        (project) =>
          selected.includes(project.id.toString()) && !project.is_published
      )
      .map((project) => project.id.toString());

    if (projectIds.length === 0) {
      openSnack("Selected project(s) already published.", false);
      return;
    }

    const res = await bulkPublishProject(
      true,
      projectIds,
      localStorage.getItem("access")
    );

    if (res === "Success") {
      const updatedProjects = projects.map((project_) => {
        if (projectIds.includes(project_.id.toString())) {
          return {
            ...project_,
            is_published: true,
          };
        }
        return project_;
      });
      setProjects(updatedProjects);
      openSnack(
        `${selected.length} project${
          selected.length > 1 ? "s" : ""
        } published!`,
        true
      );
    } else {
      openSnack(res, false);
    }
  };

  const handleUnpublish = async () => {
    const projectIds = projects
      .filter(
        (project) =>
          selected.includes(project.id.toString()) && project.is_published
      )
      .map((project) => project.id.toString());

    if (projectIds.length === 0) {
      openSnack("Selected project(s) already unpublished.", false);
      return;
    }

    const res = await bulkPublishProject(
      false,
      projectIds,
      localStorage.getItem("access")
    );

    if (res === "Success") {
      const updatedProjects = projects.map((project_) => {
        if (projectIds.includes(project_.id.toString())) {
          return {
            ...project_,
            is_published: false,
          };
        }
        return project_;
      });
      setProjects(updatedProjects);
      openSnack(
        `${selected.length} project${
          selected.length > 1 ? "s" : ""
        } unpublished!`,
        true
      );
    } else {
      openSnack(res, false);
    }
  };

  const handleGrantAccess = async () => {
    const projectIds = selected.filter((id) => !(id in accessList));

    if (projectIds.length === 0) {
      openSnack(
        `${currSchool.name} has access to selected project(s) already.`,
        false
      );
      return;
    }

    const res = await bulkCreateAccess(
      currSchool.id.toString(),
      projectIds,
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      for (let i = 0; i < res.length; i++) {
        accessList[res[i].project.toString()] = res[i].id;
      }
      setAccessList(accessList);
      openSnack(
        `Granted the access of ${selected.length} project${
          selected.length > 1 ? "s" : ""
        } to ${currSchool.name}!`,
        true
      );
    }
  };

  const handleRevokeAccess = async () => {
    const projectIds = selected.filter((id) => id in accessList);
    const accessIds = projectIds.map((id) => accessList[id]);

    if (accessIds.length === 0) {
      openSnack(
        `${currSchool.name} didn't have the access to selected project(s) already.`,
        false
      );
      return;
    }

    const res = await bulkDeleteAccess(
      accessIds,
      localStorage.getItem("access")
    );

    if (res === "Success") {
      const newList = { ...accessList };
      projectIds.forEach((id) => delete newList[id]);
      setAccessList(newList);
      openSnack(
        `Revoked the access of ${selected.length} project${
          selected.length > 1 ? "s" : ""
        } from ${currSchool.name}!`,
        true
      );
    } else {
      openSnack(res, false);
    }
  };

  const actions =
    currSchool.id === -1
      ? [
          {
            title: "Publish",
            function: handlePublish,
          },
          {
            title: "Unpublish",
            function: handleUnpublish,
          },
        ]
      : [
          {
            title: "Grant Access",
            function: handleGrantAccess,
          },
          {
            title: "Revoke Access",
            function: handleRevokeAccess,
          },
        ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          cursor: "pointer",
          border: "1px solid #DFDDDD",
          py: 1,
          px: 4,
          borderRadius: "10px",
          opacity: selected.length === 0 ? 0.5 : 1,
          pointerEvents: selected.length === 0 ? "none" : "auto",
          transition: "all 0.2s ease-in-out",
        }}
        onClick={handleClick}
      >
        <Typography>{translation.bulkAction}</Typography>

        <ExpandMoreIcon
          sx={{
            transform: "translateY(-1px)",
            color: "#312F30",
          }}
        />
      </Box>

      <Menu
        elevation={2}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "200px",
          },
        }}
      >
        <Typography
          sx={{
            px: 2,
            py: 1,
          }}
        >
          {`${selected.length} project${
            selected.length > 1 ? "s" : ""
          } selected`}
        </Typography>

        <Divider
          sx={{
            mb: 1,
          }}
        />

        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              action.function();
              handleClose();
            }}
          >
            {action.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SecondRow;

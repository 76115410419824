import { useState } from "react";
import useStore from "store/store";
import SmallLoadingIndicator from "components/LoadingIndicator/SmallLoadingIndicator";
import Backdrop from "@mui/material/Backdrop";
import { updateProfile } from "api/auth-api";
import { getAccountProfile } from "api/profile-api";
import { API_Profile } from "types/profile";

import {
  ErrorMessage,
  UpdateProfile,
  UpdateProfileResponse,
} from "types/auth";

import {
  Box,
  Button,
  Typography,
  Modal,
  Fade,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const TermsAndConditions = () => {
  const [termsLoading, setTermsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const numberedTerms = [
    [
      "Software access and operation are reliant on external technology platforms and services. As such we cannot ensure uninterrupted software access and operation.",
      "Your password is associated with your individual access to Little Lab only. It is your responsibility to protect your password and account information. As such, if you become aware that your password, account or access is compromised in any way that you will take action as soon as possible to notify Little Lab.",
      "In using the software, you will not:",
    ],
    [
      "Little Lab is an educational software platform.",
      'We may use "cookies", where a small data file is sent to your browser to store and track information about you when you enter the Software.',
      "You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. Please note that this may prevent you from taking full advantage of the Software.[AH1]",
      "Use of Data: If you provide us with personal data (as defined in the Singapore Personal Data Protection Act 2012 (No. 26 of 2012)). Where appropriate, we may share necessary data within Companies, entities, employees, and contractors related to and/or employed (“Related Parties”) by Little Lab. In addition, Little Lab or its related parties may provide information and/or data to designated third parties.",
    ],
  ];

  const bulletPoints = [
    [
      "At any time use the software with the purpose of impersonating another user or person;",
      "Use the information made available through the software for any purpose other than in connection with educational usage;",
      "Do anything whatsoever which shall or is likely to impair, interfere with, damage, or cause harm or distress to any person or all or any part of any computer, computer network, telecommunications service or infrastructure;",
      "Conduct any automated or manual activities, to collect data, monitor or copy any part of the Software or any part of the contents of the Software without our prior express written consent; and",
      " Attempt to circumvent data protection schemes or uncover security loopholes;",
    ],
    [
      "To safeguard your personal data, all electronic storage and transmission of personal data is secured with appropriate security technologies.",
      "If you provide us with personally identifiable data:",
      "We may share necessary data with the aforementioned Related Parties and designated third parties unless such sharing is prohibited by law.",
    ],
  ];

  const normalText = [
    "The Little Lab Software is owned and managed by Littles Programme Pte. Ltd. (“Littles”) a company incorporated in Singapore and whose registered office address is 109 North Bridge Road #07-21 Singapore 179097. Trehaus Pte. Ltd. is the parent company of Littles Programme Pte. Ltd. All access to the Software is governed by the terms and conditions below (the “Terms”).",
    "Little Lab is accessible to companies, schools, organisations, administrators, teachers and students attending a School or involved in an organisation using the software (“collectively hereafter referred to as the “Users”).",
    'Please read these Terms very carefully before using the software. You acknowledge and agree that by clicking on "Accept” on behalf of an entity, in an individual capacity or for student use, you agree that you will be bound by these Terms as a User.',
    "You warrant and represent that you have full capacity and authority to enter into these Terms on behalf of the entity, individually or in relation to student use.",
    "If you do not accept these Terms, you will not be able to use the software. These Terms are subject to change periodically and accordingly you are advised to refer back to these Terms regularly.",
  ];
  const { profileDetails, setProfileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
    setProfileDetails: state.setProfileDetails,
  }));

  const TextSection = ({ color, content }: { color: any; content: any }) => {
    return (
      <Typography
        sx={{
          color: `${color}`,
          fontSize: isSm ? "12px" : "16px",
          fontWeight: "450",
          mb: 2,

          minWidth: "230px",
        }}
      >
        {content}
      </Typography>
    );
  };
  const TextSectionNext = ({
    color,
    content,
  }: {
    color: any;
    content: any;
  }) => {
    return (
      <Typography
        sx={{
          color: `${color}`,
          fontSize: isSm ? "12px" : "16px",
          fontWeight: "450",
          mx: 2,
          mb: 2,

          minWidth: "230px",
        }}
      >
        {content}
      </Typography>
    );
  };

  const AcceptTermsAndConditionsButton = () => {
    const formData = {
      email: profileDetails.email,
      mobile_number: profileDetails.mobile_number,
      preferred_name: profileDetails.preferred_name,
      full_name: profileDetails.full_name,
      gender: profileDetails.gender,
      highest_education_qualification:
        profileDetails.highest_education_qualification,
      education_start_date: profileDetails.education_start_date,
      dob: profileDetails.dob,
      terms_agreed: true,
    };
    const handleClick = async () => {
      setTermsLoading(true);
      const response: UpdateProfileResponse | ErrorMessage =
        await updateProfile(
          profileDetails.id.toString(),
          formData as UpdateProfile,
          localStorage.getItem("access")
        );
      const data: API_Profile = await getAccountProfile(
        profileDetails.id.toString(),
        localStorage.getItem("access")
      );
      if ("terms_agreed" in response) {
        if ((data as any).terms_agreed || (response as any).terms_agreed == true) {
          setProfileDetails(data);
        }
      }

      setProfileDetails({ ...profileDetails, terms_agreed: true });
      setTermsLoading(false);
    };
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#91C6C5",
          textTransform: "none",
          color: "white",
          width: "40%",
        }}
        onClick={handleClick}
      >
        {termsLoading ? <SmallLoadingIndicator /> : "Accept"}
      </Button>
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={!profileDetails.terms_agreed}
      onClose={() => {
        // handleCreateModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClick={(event: any) => event.stopPropagation()}
      sx={{}}
    >
      <Fade in={!profileDetails.terms_agreed}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            width: "45%",

            minHeight: "75vh",
            maxHeight: "83vh",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 2,
            borderRadius: "10px",
            display: "flex",
            gap: 3,
            overflow: "auto", // Add this line
            maxWidth: "100%", // Add this line
            minWidth: "280px", // Add this line
            overflowX: "hidden",
          }}
        >
          <Stack
            component="form"
            sx={{
              width: "100%",
              // minWidth: "280px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              overflowX: "hidden",
              // height: "100%",
            }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  display: "flex",
                  justifySelf: "flex-start",
                  mb: 1,
                }}
              >
                Terms Of Use
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: "250px",
                width: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <TextSection
                color={"#79B5B4"}
                content={
                  "Welcome to the Little Lab, an educational software platform, which contains the Trehaus curriculum modules and product platform, the Teaching Tool Box and Teacher Training Academy. The software is accessible through the following website: app.littlelab.com."
                }
              />

              {normalText.map((text: string) => (
                <TextSection color={"black"} content={text} />
              ))}
              {numberedTerms[0].map((text: string, index: number) => (
                <TextSectionNext
                  color={"black"}
                  content={`${index + 1}. ${text}`}
                />
              ))}
              {bulletPoints[0].map((text: string, index: number) => (
                <TextSectionNext
                  color={"black"}
                  content={`• ${text}`}
                  key={index}
                />
              ))}

              <TextSection color={"#79B5B4"} content={"Privacy Statement"} />
              {numberedTerms[1].map((text: string, index: number) => (
                <TextSectionNext
                  color={"black"}
                  content={`${index + 1}. ${text}`}
                />
              ))}
              <TextSectionNext
                color={"black"}
                content={"5. Protection of Data:"}
              />
              {bulletPoints[1].map((text: string, index: number) => (
                <TextSectionNext
                  color={"black"}
                  content={`• ${text}`}
                  key={index}
                />
              ))}
              <TextSectionNext
                color={"black"}
                content={
                  "6. Please contact us at [Insert Email Address or Feedback Link] for any enquiries or feedback on our data protection policies and procedures, and if you need more information on or access to data which you have provided to us in the past."
                }
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AcceptTermsAndConditionsButton />
            </Box>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TermsAndConditions;

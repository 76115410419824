import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { translation } from "constants/translation";

import {
  API_Teacher_Flip_Card_Activity,
  API_Flip_Card,
  API_Teacher_Lesson,
} from "types/teacher";
import { TeacherLessonActivityScreenDoneButton } from "../TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";
import Masonry from "@mui/lab/Masonry/Masonry";

const TeacherFlipCardActivityScreen = ({
  teacherCurrentActivity,
  teacherCurrentLesson,
}: {
  teacherCurrentActivity: API_Teacher_Flip_Card_Activity;
  teacherCurrentLesson: API_Teacher_Lesson;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "primary.main",
          borderRadius: "10px",
          p: 2,
          gap: 1,
          flex: 1,
        }}
      >
        <Typography sx={{ fontSize: 22 }}>{translation.memoryCards}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: "center",
            flex: "1 1 0",
            overflowY: "auto",
          }}
        >
          <Masonry
            columns={{ xs: 1, s: 2, lg: 3 }}
            spacing={2}
            sx={{ width: "100%" }}
            defaultSpacing={2}
          >
            {teacherCurrentActivity.flip_cards ? (
              teacherCurrentActivity.flip_cards.map((flipCard, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    key={`flip-card-text-${index}`}
                  >
                    <FlipCardText flipCard={flipCard} />
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </Masonry>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <TeacherLessonActivityScreenDoneButton
          activityId={teacherCurrentActivity.id}
        />
      </Box>
    </Box>
  );
};

const FlipCardText = ({ flipCard }: { flipCard: API_Flip_Card }) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const handleClick = () => {
    setIsFlipped((prev) => !prev);
  };

  const cardStyle = {
    // border: "1px solid #98C4C5",
    display: "flex",
    minWidth: "15vw",
    minHeight: 150,
    bgcolor: "white",
    alignItems: "center",
    justifyContent: "center",
    p: 2,
    borderRadius: "10px",
  };
  const flipCardTextStyle = {
    // justifyContent: "center",
    // alignItems: "center",
    fontSize: 18,
  };
  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"

    // containerStyle={{}}
    >
      {/* Front */}
      <Box onClick={handleClick} sx={cardStyle}>
        <Typography sx={flipCardTextStyle}>{flipCard.front_text}</Typography>
      </Box>
      {/* back */}
      <Box onClick={handleClick} sx={cardStyle}>
        <Typography sx={flipCardTextStyle}>{flipCard.back_text}</Typography>
      </Box>
    </ReactCardFlip>
  );
};

export default TeacherFlipCardActivityScreen;
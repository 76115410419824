import { StoreSlice } from "./store";

import { API_Profile } from "../types/profile";
import { UserRoleAbbr } from "types/auth";

export interface AuthSlice {
  profileID: string;
  profileDetails: API_Profile;
  visitedProfileDetails: API_Profile;
  schoolID: string;
  setProfileID: (profileID: string) => void;
  setProfileDetails: (profileDetails: API_Profile) => void;
  setVisitedProfileDetails: (visitedProfileDetails: API_Profile) => void;
  setSchoolID: (schoolID: string) => void;
}

export const initialProfileDetails = {
  id: -1,
  user: -1,
  email: "",
  mobile_number: "",
  preferred_name: "",
  full_name: "",
  gender: "",
  highest_education_qualification: "",
  education_start_date: "",
  dob: "",
  role: "" as UserRoleAbbr,
  children: [],
  image: "",
  description: "",
  terms_agreed: undefined,
};

export const createAuthSlice: StoreSlice<AuthSlice> = (set, get) => ({
  profileID: "",
  profileDetails: initialProfileDetails,
  visitedProfileDetails: initialProfileDetails,
  schoolID: "",
  setProfileID: (profileID: string) => {
    set((prev: AuthSlice) => ({
      ...prev,
      profileID: profileID,
    }));
  },
  setProfileDetails: (profileDetails: API_Profile) => {
    set((prev: AuthSlice) => ({
      ...prev,
      profileDetails: profileDetails,
    }));
  },
  setVisitedProfileDetails: (visitedProfileDetails: API_Profile) => {
    set((prev: AuthSlice) => ({
      ...prev,
      visitedProfileDetails: visitedProfileDetails,
    }));
  },

  setSchoolID: (schoolID: string) => {
    set((prev: AuthSlice) => ({
      ...prev,
      schoolID: schoolID,
    }));
  },
});

import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  Fragment,
} from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Image from "components/Image/Image";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Box,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  IconButton,
  Button as MuiButton,
  Avatar,
  Chip,
  useMediaQuery,
  useTheme,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import CreateProfileModal from "containers/SiteAdminPage/ManagementPage/components/CreateProfileModal";
import Button from "components/Button/Button";
import Logo from "images/logo-2.png";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import DeleteIcon from "@mui/icons-material/Delete";

import { API_Profile } from "types/profile";

import { API_Group_Simple, API_Group_Id_Only } from "types/group";

import {
  createGroup,
  updateGroup,
  getGroup,
  getAccountProfile,
  deleteGroup,
} from "api/profile-api";
import CloseIcon from "@mui/icons-material/Close";

import { CURLANG, CLASS } from "constants/url";
import { translation } from "constants/translation";

import GroupAvatar from "./GroupAvatar";
import ChildTransferList from "./ChildTransferList";
import TeacherTransferList from "./TeacherTransferList";
import { API_School } from "types/school";
import { getAllSchool } from "api/school-api";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import {
  BLOOMS,
  BLOSSOMS,
  BUDS,
  SPROUTLINGS,
  SPROUTS,
} from "constants/project-lesson-activity";
import EditGroupDrawer from "./EditGroupDrawer";

const EditGroupPage = ({
  create = false,
  isModal = false,
  handleClose,
  schoolId = -1,
  setFormData,
  setGroupListDisplay,
}: {
  create?: boolean;
  isModal?: boolean;
  handleClose?: () => void;
  schoolId?: number;
  setFormData?: any;
  setGroupListDisplay?: React.Dispatch<
    React.SetStateAction<API_Group_Simple[]>
  >;
}) => {
  const { state }: any = useLocation();
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { groupSlug } = useParams();

  //Warning Dialog
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [selectedChild, setSelectedChild] = useState<number[]>([]);
  const [openModalTeacher, setOpenModalTeacher] = useState<boolean>(false);

  const [currentGroupData, setCurrentGroupData] = useState<API_Group_Id_Only>({
    id: -1,
    name: "",
    description: "",
    slug: "",
    age: "Sproutlings",
    school: schoolId,
    image: null,
    all_children: [],
    children: [],
    children_school: [],
    teachers: [],
    teachers_school: [],
    communication_lvl: "",
  });

  const {
    currLanguage,
    profileDetails,
    setProfileDetails,
    schools,
    setSchools,
    setRefreshGroup,
    refreshGroupTeacherList,
    refreshGroupChildrenList,
    setRefreshGroupTeacherList,
    setRefreshGroupChildrenList,
  } = useStore((state) => state);

  const [defaultSchool, setDefaultSchool] = useState<API_School>();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));
  const mainBoxRef: any = useRef(null);
  const editClassHeaderRef: any = useRef(null);
  const assignChildRef: any = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [childTransferHeight, setChildTransferHeight] = useState(0);
  const [teachersInGroup, setTeachersInGroup]: any[] = useState([]);
  const [teachersInGroupFull, setTeachersInGroupFull]: any[] = useState([]);
  const [teachersNotInGroup, setTeachersNotInGroup]: any[] = useState([]);

  useLayoutEffect(() => {
    if (
      editClassHeaderRef?.current &&
      mainBoxRef?.current &&
      assignChildRef?.current
    ) {
      const mainBoxRect = mainBoxRef.current.getBoundingClientRect();
      const editClassHeaderRect =
        editClassHeaderRef.current.getBoundingClientRect();
      const newContainerHeight =
        mainBoxRect.height - editClassHeaderRect.height;
      setContainerHeight(newContainerHeight);
      const assignChildRect = assignChildRef.current.getBoundingClientRect();
      const style = window.getComputedStyle(assignChildRef.current);
      const marginTop = parseFloat(style.marginTop);
      const marginBottom = parseFloat(style.marginBottom);
      const totalHeight = assignChildRect.height + marginTop + marginBottom;
      const transferChildHeight = newContainerHeight - totalHeight;
      setChildTransferHeight(transferChildHeight);
    }
  }, [editClassHeaderRef.current?.offsetHeight]);
  const refreshChildList = async () => {
    const data: API_Profile = await getAccountProfile(
      String(profileDetails.id),
      localStorage.getItem("access")
    );
    setProfileDetails(data);
  };

  const refreshCurrentGroupData = async (groupSlug: string) => {
    const res = await getGroup(groupSlug);
    if (typeof res === "string" || !res.children_school) return;
    const filteredChildren = res.children_school.filter((child) => {
      return !child.groups || Array.isArray(child.groups);
    });

    setCurrentGroupData({
      id: res.id,
      name: res.name,
      description: res.description,
      slug: res.slug,
      age: res.age,
      school: res.school,
      image: res.image,
      all_children: [...res.children, ...filteredChildren],
      children: res.children.map((child) => {
        return child.id;
      }),
      children_school: filteredChildren,
      teachers: res.teachers.map((teacher) => {
        return teacher.id;
      }),
      teachers_school: res.teachers_school ? res.teachers_school : [],
      communication_lvl: res.communication_lvl,
    });
    setRefreshGroupTeacherList(false);
    setRefreshGroupChildrenList(false);
  };

  useEffect(() => {
    const populateData = async () => {
      if (!create && groupSlug) {
        refreshCurrentGroupData(groupSlug);
      }
      setSchools(await getAllSchool());
    };
    populateData();
  }, [groupSlug]);

  useEffect(() => {
    const populateData = async () => {
      if (
        !create &&
        groupSlug &&
        (refreshGroupTeacherList || refreshGroupChildrenList)
      ) {
        refreshCurrentGroupData(groupSlug);
      }
      setSchools(await getAllSchool());
    };
    populateData();
  }, [refreshGroupTeacherList, refreshGroupChildrenList]);

  useEffect(() => {
    setCurrentGroupData((prev) => ({
      ...prev,
      children: selectedChild,
    }));
  }, [selectedChild]);

  useEffect(() => {
    setCurrentGroupData((prev) => ({
      ...prev,
      teachers: teachersInGroup,
    }));
  }, [teachersInGroup]);

  useEffect(() => {
    if (profileDetails.school) {
      setCurrentGroupData((prev) => ({
        ...prev,
        school: profileDetails?.school!.id,
      }));

      setDefaultSchool(
        schools.filter((school) => school.id === profileDetails?.school!.id)[0]
      );
    }
    if (profileDetails.role === "WAD") {
      if (schoolId !== -1) {
        setCurrentGroupData((prev) => ({
          ...prev,
          school: schoolId,
        }));

        setDefaultSchool(schools.filter((school) => school.id === schoolId)[0]);
      } else if (state && state.hasOwnProperty("schoolId")) {
        setCurrentGroupData((prev) => ({
          ...prev,
          school: state.schoolId,
        }));

        setDefaultSchool(
          schools.filter((school) => school.id === state.schoolId)[0]
        );
      }
    }
  }, [schools, profileDetails]);

  const handleSubmit = async () => {
    setEditLoading(true);
    if (create) {
      const res = await createGroup(currentGroupData);
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        openSnack(`Created ${currentGroupData.name} group successfully!`, true);
        if (setFormData) {
          setFormData((prev: any) => ({
            ...prev,
            groups: [...prev.groups, res.id],
          }));
        }
        if (setGroupListDisplay) {
          setGroupListDisplay((prev) => [...prev, res]);
        }
        if (handleClose) {
          handleClose();
        } else {
          navigate(`${CURLANG(currLanguage)}/${CLASS}/${res.slug}/edit`);
        }
        setRefreshGroup(true);
      }
    } else {
      // update
      const res = await updateGroup(currentGroupData);
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        setEditLoading(false);
        openSnack(`Updated ${currentGroupData.name} group successfully!`, true);
        if (handleClose) {
          handleClose();
        } else {
          navigate(`${CURLANG(currLanguage)}/${CLASS}`);
        }
      }
    }
    refreshChildList();
    setEditLoading(false);
    navigate(`${CURLANG(currLanguage)}/${CLASS}/${groupSlug}`);
  };

  const handleDelete = async () => {
    const res = await deleteGroup(currentGroupData.id);

    if (typeof res === "string" && res.length >= 1) {
      openSnack(res, false);
    } else {
      openSnack(`Delete Group successfully!`, true);
      refreshChildList();
      navigate(`${CURLANG(currLanguage)}/${CLASS}`);
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete ${currentGroupData.name} Group?`,
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  useEffect(() => {
    const initialTeacherList = () => {
      return currentGroupData.teachers;
    };

    const teachersNotInGroupTemp = () => {
      const notSelectedTeachers = () =>
        currentGroupData.teachers_school.filter((teacher) => {
          return !initialTeacherList().includes(teacher.id);
        });

      return notSelectedTeachers().map((teacher) => {
        return teacher.id;
      });
    };
    const result = currentGroupData.teachers_school.filter((teacher: any) =>
      currentGroupData.teachers.includes(teacher.id)
    );
    const noresult = currentGroupData.teachers_school.filter(
      (teacher: any) => !currentGroupData.teachers.includes(teacher.id)
    );

    setTeachersInGroup(initialTeacherList());
    setTeachersNotInGroup(teachersNotInGroupTemp());
    setTeachersInGroupFull(result);
  }, [currentGroupData.teachers_school, currentGroupData.id]);

  const windowHeight = useRef(window.innerHeight);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        height: "100%",
      }}
    >
      {create ? null : <WarningDialog {...warningBody} />}

      <CreateProfileModal
        open={openModalTeacher}
        handleClose={() => {
          setOpenModalTeacher(false);
        }}
        role={"TR"}
        groupId={currentGroupData.id}
      />
      <EditGroupDrawer />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          // px: 2,
          py: "24px",
          // mx: "60px",

          height: "calc(100vh-64px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          ref={mainBoxRef}
        >
          <Box sx={{ display: "flex", width: "100%" }} ref={editClassHeaderRef}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <IconButton
                  onClick={() => {
                    navigate(`${CURLANG(currLanguage)}/${CLASS}/${groupSlug}/`);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                {isXs ? null : (
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "24px",
                      // px: 4
                    }}
                  >
                    {translation.editClass}
                  </Typography>
                )}
              </Box>
              {/* {teachersInGroup.length > 0 &&
                teachersInGroup.map((teacher: any) => {
                  return <TeacherMiniCard teacher={teacher} />;
                })} */}
              <Box
                sx={{
                  display: "flex",
                  //  gap: 2,
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
                <MuiButton
                  sx={{ color: "#FF5D53" }}
                  onClick={() => {
                    handleOpenDialog();
                  }}
                >
                  <DeleteOutlineIcon sx={{ mr: 1 }} />
                  <Typography
                    sx={{
                      color: "#FF5D53",
                      fontSize: "16px",
                      fontWeight: "450",
                      textTransform: "none",
                    }}
                  >
                    Delete Class
                  </Typography>
                </MuiButton>
                {/* </Box> */}
                {/* Delete */}
                <MuiButton
                  variant="contained"
                  onClick={() => handleSubmit()}
                  sx={{
                    backgroundColor: "#91C6C5",
                    color: "white",

                    mr: 2,
                  }}
                >
                  {editLoading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        my: "4px",
                        mx: "20px",
                      }}
                      size={20}
                    />
                  ) : (
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "450",
                        textTransform: "none",
                        py: "4px",
                        px: "16px",
                      }}
                    >
                      {translation.update}
                    </Typography>
                  )}
                </MuiButton>
              </Box>
            </Box>
          </Box>
          {/* BOX CONTAINING CLASSINFO AND CHILDTRANSFER */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isXs ? "column" : "row",
              height: isXs ? "100%" : containerHeight,
              width: "100%",
              px: "24px",
              // overflowY: "hidden",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                width: isXs ? "100%" : "33%",
                height: "100%",
                // minHeight: "100%",
                // backgroundColor: "orange"
              }}
            >
              <Stack
                direction={"column"}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: isXs ? "center" : "flex-start",
                  height: "100%",
                  width: "100%",
                  // overflowY: "hidden",
                  // p: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // backgroundColor: "green",
                    // width: isXs ? "100%" : "30%",
                    width: "100%",
                    // gap: 2,

                    height: isXs ? "auto" : "100%",
                    justifyContent: "flex-start",
                    alignItems: isXs ? "center" : "flex-start",

                    // mr: 20,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: {
                        xs: "20px",
                        sm: "16px",
                        md: "20px",
                      },
                      color: "#79B5B4",
                      mb: "24px",
                    }}
                  >
                    Class Information
                  </Typography>
                  <Stack
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#EBF8F8",
                      minHeight: isXs ? "100%" : childTransferHeight,
                      // height: "100%",
                      width: "100%",
                      border: "1px solid #98C4C5",
                      borderRadius: "10px",
                    }}
                  >
                    {/* Image */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my:
                          windowHeight.current < 800 && !isXs ? "16px" : "24px",
                      }}
                    >
                      <GroupAvatar
                        width={160}
                        height={160}
                        image={
                          typeof currentGroupData.image === "string"
                            ? currentGroupData.image
                            : typeof currentGroupData.image === "object"
                            ? currentGroupData.image !== null
                              ? URL.createObjectURL(currentGroupData.image)
                              : Logo
                            : Logo
                        }
                        imageFallbackChar={
                          currentGroupData.name
                            ? currentGroupData.name.charAt(0).toUpperCase()
                            : ""
                        }
                        setCurrentGroupData={setCurrentGroupData}
                      />
                    </Box>
                    <Stack
                      component="form"
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                      spacing={1.5}
                      noValidate
                      autoComplete="off"
                      direction="column"
                    >
                      <TextField
                        size={
                          windowHeight.current < 800 && !isXs
                            ? "small"
                            : "medium"
                        }
                        required
                        sx={{
                          backgroundColor: "white",
                          maxHeight: "56px",
                          width: "90%",
                        }}
                        label={translation.group_name}
                        value={currentGroupData.name}
                        onChange={(event) => {
                          setCurrentGroupData((prev) => ({
                            ...prev,
                            name: event.target.value,
                          }));
                        }}
                      />
                      <TextField
                        sx={{
                          backgroundColor: "white",
                          maxHeight: "56px",
                          width: "90%",
                        }}
                        size={
                          windowHeight.current < 800 && !isXs
                            ? "small"
                            : "medium"
                        }
                        label={translation.description}
                        value={currentGroupData.description}
                        onChange={(event) => {
                          setCurrentGroupData((prev) => ({
                            ...prev,
                            description: event.target.value,
                          }));
                        }}
                      />

                      <FormControl
                        required
                        sx={{
                          backgroundColor: "white",
                          maxHeight: "56px",
                          width: "90%",
                        }}
                        size={
                          windowHeight.current < 800 && !isXs
                            ? "small"
                            : "medium"
                        }
                      >
                        <InputLabel id="demo-simple-select-label">
                          {translation.level}
                        </InputLabel>
                        <Select
                          sx={{}}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={currentGroupData.age}
                          label={translation.age}
                          onChange={(event) => {
                            setCurrentGroupData((prev) => ({
                              ...prev,
                              age: event.target.value,
                            }));
                          }}
                        >
                          <MenuItem value={SPROUTS}>
                            {translation.sprouts_with_age}
                          </MenuItem>
                          <MenuItem value={BUDS}>
                            {translation.buds_with_age}
                          </MenuItem>
                          <MenuItem value={BLOSSOMS}>
                            {translation.blossoms_with_age}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        required
                        sx={{
                          backgroundColor: "white",
                          maxHeight:
                            windowHeight.current < 800 && !isXs
                              ? "56px"
                              : "128.5px",
                          width: "90%",
                        }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          {translation.teachers}
                        </InputLabel>
                        <Select
                          sx={{
                            // maxHeight: "56px",
                            overflowY: "hidden",
                          }}
                          // width: "90%",
                          multiple
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={teachersInGroupFull}
                          label={translation.age}
                          onChange={(event) => {
                            setCurrentGroupData((prev) => ({
                              ...prev,
                              teachers: event.target.value,
                            }));
                          }}
                          renderValue={(teachers: any) => (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                            >
                              {teachers.map((teacher: any) => {
                                return (
                                  <Chip
                                    key={teacher.id}
                                    size="small"
                                    label={
                                      teacher.preferred_name ||
                                      teacher.full_name
                                    }
                                    color="primary"
                                    variant="outlined"
                                    deleteIcon={<CloseIcon fontSize="small" />}
                                    onMouseDown={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onDelete={() => {
                                      setTeachersInGroup((prevTeachers: any) =>
                                        prevTeachers.filter(
                                          (prevTeacher: any) =>
                                            prevTeacher !== teacher.id
                                        )
                                      );
                                      setTeachersInGroupFull(
                                        (prevTeachers: any) =>
                                          prevTeachers.filter(
                                            (prevTeacher: any) =>
                                              prevTeacher.id !== teacher.id
                                          )
                                      );
                                    }}
                                    onClick={() => {
                                      setTeachersInGroup((prevTeachers: any) =>
                                        prevTeachers.filter(
                                          (prevTeacher: any) =>
                                            prevTeacher !== teacher.id
                                        )
                                      );
                                      setTeachersInGroupFull(
                                        (prevTeachers: any) =>
                                          prevTeachers.filter(
                                            (prevTeacher: any) =>
                                              prevTeacher.id !== teacher.id
                                          )
                                      );
                                    }}
                                    avatar={
                                      <Avatar
                                        src={teacher.image}
                                        alt={`${
                                          teacher.preferred_name ||
                                          teacher.full_name
                                        }'s picture`}
                                      />
                                    }
                                  />
                                );
                              })}
                            </Box>
                          )}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {currentGroupData.teachers_school.map(
                              (teacher: any) => {
                                return (
                                  <MenuItem
                                    key={teacher.id}
                                    disabled={teachersInGroupFull.includes(
                                      teacher
                                    )}
                                    value={
                                      teacher.preferred_name ||
                                      teacher.full_name
                                    }
                                    onClick={() => {
                                      setTeachersInGroup(
                                        (prevTeachers: any) => [
                                          ...prevTeachers,
                                          teacher.id,
                                        ]
                                      );
                                      setTeachersInGroupFull(
                                        (prevTeachers: any) => [
                                          ...prevTeachers,
                                          teacher,
                                        ]
                                      );
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        py: 1,
                                      }}
                                    >
                                      <Image
                                        src={teacher.image || Logo}
                                        alt={`${
                                          teacher.preferred_name ||
                                          teacher.full_name
                                        }'s picture`}
                                        style={{
                                          width: 24,
                                          height: 24,
                                          borderRadius: 12,
                                        }}
                                      />
                                      {teacher.preferred_name ||
                                        teacher.full_name}
                                    </Box>
                                  </MenuItem>
                                );
                              }
                            )}
                          </Box>
                        </Select>
                      </FormControl>

                      {create && (
                        <Autocomplete
                          sx={{ minWidth: 180, ...TEXT_FIELD_STYLE }}
                          id="combo-box-register"
                          value={defaultSchool || null}
                          onChange={(
                            event: any,
                            school_updated: API_School | null
                          ) => {
                            if (school_updated !== null) {
                              setCurrentGroupData((prev) => ({
                                ...prev,
                                school: school_updated.id,
                              }));
                              setDefaultSchool(school_updated);
                            }
                          }}
                          options={schools}
                          getOptionLabel={(school) => school?.name}
                          isOptionEqualToValue={(option, value) =>
                            option.name === value?.name
                          }
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label="Schools"
                                placeholder=""
                                sx={{
                                  width: "90%",
                                }}
                              />
                            );
                          }}
                          disabled={
                            schoolId === -1
                              ? profileDetails.role === "WAD"
                                ? false
                                : true
                              : true
                          }
                        />
                      )}
                    </Stack>
                    <MuiButton
                      sx={{
                        alignSelf: "flex-end",
                        display: "flex",

                        alignItems: "center",
                        mr: 1,
                        my: windowHeight.current < 800 && !isXs ? 1 : 2,
                      }}
                      onClick={() => {
                        setOpenModalTeacher(true);
                      }}
                    >
                      <AddIcon />
                      <Typography
                        sx={{
                          fontWeight: "450",
                          fontSize: "14px",
                          color: "#5C9391",
                          textTransform: "none",
                        }}
                      >
                        New Teacher
                      </Typography>
                    </MuiButton>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: isXs ? "center" : "auto",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: {
                    xs: "20px",
                    sm: "16px",
                    md: "20px",
                  },
                  color: "#79B5B4",
                  mb: "24px",
                }}
                ref={assignChildRef}
              >
                Assign Child To Class
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  height: isXs ? "auto" : childTransferHeight,
                  width: isXs ? "100%" : "auto",
                }}
              >
                <ChildTransferList
                  setSelectedChild={setSelectedChild}
                  currentGroupData={currentGroupData}
                  childList={currentGroupData.all_children}
                />
              </Box>
            </Box>
          </Box>
          {/* END OF BOX CONTAINING CLASSINFO AND CHILDTRANSFER */}
        </Box>
      </Box>
    </Box>
  );
};

export default EditGroupPage;

import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { translation } from "constants/translation";
import { SxProps } from "@mui/material";

type Props = {
  placeholder?: string,
  sx?: SxProps;
  handleChange: (searchVal: string) => void;
}

const SearchBar = ({
  placeholder,
  sx,
  handleChange,
}: Props) => {
  const [searchVal, setSearchVal] = useState<string>("");

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "inherit",
        maxWidth: 400,
        height: 43,
        cornerRadius: 0,
        borderRadius: 0,
        boxShadow: "none",
        border: "1px solid",
        borderColor: "neutral.300",
        ...sx,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder ?? translation.searchForProjects}
        inputProps={{ "aria-label": "search for projects" }}
        value={searchVal}
        onChange={(e) => {
          setSearchVal(e.target.value);
          handleChange(e.target.value);
        }}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      />
      <IconButton sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
import { useState } from "react";
import {
  Typography,
  SxProps,
  TextField,
  Box,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ReplyIcon from "@mui/icons-material/Reply";
import CommentIcon from "@mui/icons-material/Comment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

import useSnack from "hooks/useSnack";

import { translation } from "constants/translation";
import Logo from "images/logo-2-colour.png";
import useStore from "store/store";
import {
  likeLessonDiscussionPost,
  likeLessonDiscussionPostComment,
  editLessonDiscussionPostComment,
  createLessonDiscussionComment,
} from "api/teacher-api";
import {
  likedStyles,
  smallTextStyles,
  textStyles,
  iconStyles,
  smallIconStyles,
} from "components/Review/Components";
import {
  API_Edit_Lesson_Discussion_Post_Comment,
  API_Lesson_Discussion_Post_Comment,
  API_Create_Lesson_Discussion_Post_Comment,
  API_Lesson_Discussion_Post,
} from "types/teacher";

export const Like = ({
  type = "post", // post, comment
  likes,
  objectId,
}: {
  type: string;
  likes: number[];
  objectId: number;
}) => {
  const openSnack = useSnack();

  const { currLanguage, setIsRefreshLessonDiscussionPost, profileID } =
    useStore((state) => ({
      currLanguage: state.currLanguage,
      setIsRefreshLessonDiscussionPost: state.setIsRefreshLessonDiscussionPost,
      profileID: state.profileID,
    }));

  const numOfLikes: number = likes.length;
  const isLiked = !!likes.find((like) => like === Number(profileID));
  // console.log("likes: ", likes);
  // console.log("profileID: ", profileID);
  const handleLike = async () => {
    const res =
      type === "post"
        ? await likeLessonDiscussionPost(currLanguage, objectId)
        : await likeLessonDiscussionPostComment(currLanguage, objectId);

    if (res !== "Error") {
      setIsRefreshLessonDiscussionPost(true);
      openSnack(translation.liked, true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const textStyling: SxProps = isLiked
    ? {
        ...textStyles,
        ...likedStyles,
        ...(type === "post" ? {} : smallTextStyles),
      }
    : {
        ...textStyles,
        ...(type === "post" ? {} : smallTextStyles),
      };

  const iconStyling: SxProps = isLiked
    ? {
        ...iconStyles,
        ...likedStyles,
        ...(type === "post" ? {} : smallIconStyles),
      }
    : {
        ...iconStyles,
        ...(type === "post" ? {} : smallIconStyles),
      };

  return (
    <Typography sx={textStyling} onClick={handleLike}>
      <ThumbUpIcon sx={iconStyling} />
      {numOfLikes !== 0 ? numOfLikes : translation.like}
    </Typography>
  );
};

export const InputBar = ({
  type = "add", // add, edit, reply
  objectId,
  parent,
  message,
  setMessage,
  setShow,
  children,
}: {
  type: string;
  objectId: number;
  parent?: API_Lesson_Discussion_Post_Comment;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}) => {
  const openSnack = useSnack();

  const { profile, currLanguage, profileID, setIsRefreshLessonDiscussionPost } =
    useStore((state) => ({
      profile: state.profileDetails,
      currLanguage: state.currLanguage,
      profileID: state.profileID,
      setIsRefreshLessonDiscussionPost: state.setIsRefreshLessonDiscussionPost,
    }));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addComment = async () => {
    setIsLoading(true);

    const commentData: API_Create_Lesson_Discussion_Post_Comment = {
      lesson_discussion_post: objectId,
      name: message,
    };

    const res = await createLessonDiscussionComment(currLanguage, commentData);

    setIsLoading(false);

    if (typeof res !== "string") {
      reset();
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const editComment = async () => {
    setIsLoading(true);

    const commentData: API_Edit_Lesson_Discussion_Post_Comment = {
      id: objectId,
      name: message,
    };

    const res = await editLessonDiscussionPostComment({
      language: currLanguage,
      editedCommentInfo: commentData,
    });

    setIsLoading(false);

    if (typeof res !== "string") {
      reset();
      openSnack(translation.edited, true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const reset = () => {
    setMessage("");
    setShow(false);
    setIsRefreshLessonDiscussionPost(true);
  };

  const handleSubmit = () => {
    if (message) {
      if (type === "add" || type === "reply") {
        addComment();
      } else if (type === "edit") {
        editComment();
      }
    }
  };

  const helperTextStyles: SxProps = {
    fontSize: "0.75rem",
    lineHeight: 1,
  };

  const helperLinkStyles: SxProps = {
    cursor: "pointer",
    color: "txt.dark",
    textDecoration: "underline",
    transition: "all 100ms ease-in-out",
    "&:hover": {
      color: "txt.secondary",
      transition: "all 100ms ease-in-out",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        opacity: isLoading ? 0.7 : 1,
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      <Avatar
        src={profile.image}
        alt={profile.preferred_name}
        sx={{ width: "32px", height: "32px", mt: 0.5 }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      <Box
        sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Box sx={{ width: "100%" }}>
          <TextField
            autoFocus
            variant="standard"
            placeholder={
              type === "add" || type === "edit"
                ? translation.writeAComment
                : `${translation.formatString(translation.replyTo, {
                    name: parent ? parent.profile.preferred_name : "User",
                  })}`
            }
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyDown={(event) => {
              if (isLoading) {
                event.preventDefault();
              } else if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                handleSubmit();
              } else if (event.key === "Escape") {
                event.preventDefault();
                reset();
              }
            }}
            fullWidth
            multiline
            rows={3}
            InputProps={{
              sx: {
                py: 1,
                px: 1.5,
                backgroundColor: "#FBFBFB",
                borderRadius: "10px",
              },
              disableUnderline: true,
            }}
          />

          <Typography
            sx={{
              ...helperTextStyles,
              mt: 1,
              mx: 1.5,
              color: "txt.dark2",
            }}
          >
            Press "Enter" to{" "}
            <Typography
              component="span"
              sx={{ ...helperTextStyles, ...helperLinkStyles }}
              onClick={handleSubmit}
            >
              submit
            </Typography>
            , "Esc" to{" "}
            <Typography
              component="span"
              sx={{ ...helperTextStyles, ...helperLinkStyles }}
              onClick={reset}
            >
              cancel
            </Typography>
          </Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

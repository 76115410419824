import React, { useState, Fragment } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import useStore from "store/store";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import CustomImage from "components/Image/Image";
import {
  API_Activity_Files,
  API_Files,
  API_Project_Lesson,
  API_Project_Lesson_Activity,
} from "types/project";
import { getLessonActivityResource } from "api/project-api";
import { translation } from "constants/translation";
import { useNavigate } from "react-router-dom";
import useSnack from "hooks/useSnack";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { iconStyle } from "../constant";
import CustomButton from "components/Button/Button";
import LogoBlur from "images/logo-2-colour-blur.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TeachingMaterialsScreen = () => {
  const { currentLessons } = useStore((state) => ({
    currentLessons: state.currentLessons,
  }));

  const isProjectAvailableDownloadMaterial = () => {
    let flag = false;
    currentLessons.lessons.map((lesson) => {
      lesson.activities.map((activity) => {
        const availableFiles = activity.files.filter(
          (file) => !file.file.includes(".mp4")
        );
        if (availableFiles.length > 0) {
          flag = true;
        }
      });
    });
    return flag;
  };

  const isLessonAvailableDownloadMaterial = (lesson: API_Project_Lesson) => {
    let flag = false;
    lesson.activities.map((activity) => {
      const availableFiles = activity.files.filter(
        (file) => !file.file.includes(".mp4")
      );
      if (availableFiles.length > 0) {
        flag = true;
      }
    });
    return flag;
  };

  const handleDownloadAll = () => {
    let urls: string[] = [];
    let names: string[] = [];

    currentLessons.lessons.map((lesson, lessonIndex) => {
      lesson.activities.map((activity, activityIndex) => {
        activity.files.map((file) => {
          if (!file.name.includes(".mp4")) {
            urls = [...urls, file.file];
            names = [
              ...names,
              `L${lessonIndex + 1}A${activityIndex + 1}_${file.name}`,
            ];
          }
        });
        // console.log("tempNames: ", tempNames);
      });
    });

    const zipName = `${currentLessons.name}_Teaching_Materials`;
    if (urls.length > 0) {
      getLessonActivityResource(urls, names, zipName);
    }
  };

  const handleDownloadLesson = (
    lesson: API_Project_Lesson,
    lessonIndex: number
  ) => {
    let urls: string[] = [];
    let names: string[] = [];

    lesson.activities.map((activity, activityIndex) => {
      activity.files.map((file) => {
        if (!file.name.includes(".mp4")) {
          urls = [...urls, file.file];
          names = [
            ...names,
            `L${lessonIndex + 1}A${activityIndex + 1}_${file.name}`,
          ];
        }
      });
    });
    const zipName = `${lesson.name}_Teaching_Materials`;
    if (urls.length > 0) {
      getLessonActivityResource(urls, names, zipName);
    }
  };
  // console.log("currentLessons: ", currentLessons);
  return (
    <Box
      sx={{
        p: "30px",
      }}
    >
      <Box sx={{ pr: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation.teachingMaterials}
          </Typography>
          {/* {isProjectAvailableDownloadMaterial() && (
            <CustomButton
              buttonText={translation.getTeachingMaterials}
              onClick={handleDownloadAll}
              arrow={false}
            />
            // <DownloadForOfflineIcon
            //   sx={iconStyle}
            //   onClick={handleDownloadAll}
            // />
          )} */}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {currentLessons.lessons.map((lesson, index) => {
            return (
              <Fragment key={`lesson-${index}`}>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      fontSize: {
                        xs: 18,
                        sm: 22,
                      },
                    }}
                  >
                    {`${translation.formatString(translation.lessonNo, {
                      number: index + 1,
                    })}: ${lesson.name}`}
                  </Typography>
                  {isLessonAvailableDownloadMaterial(lesson) && (
                    // <CustomButton
                    //   buttonText={"Lesson Teaching Resources"}
                    //   onClick={() => {
                    //     handleDownloadLesson(lesson, index);
                    //   }}
                    //   arrow={false}
                    // />
                    <DownloadForOfflineIcon
                      sx={iconStyle}
                      onClick={() => {
                        handleDownloadLesson(lesson, index);
                      }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    overflow: "auto",
                  }}
                >
                  <ResourceSection key={lesson.id} lesson={lesson} />
                </Box>
              </Fragment>
            );
          })}
        </Box>
      </Box>

      {/* <RelevantCourses /> */}
    </Box>
  );
};

const ResourceSection = ({ lesson }: { lesson: API_Project_Lesson }) => {
  const openSnack = useSnack();

  const isActivityAvailableDownloadMaterial = (
    activity: API_Project_Lesson_Activity
  ) => {
    let flag = false;

    const availableFiles = activity.files.filter(
      (file) => !file.file.includes(".mp4")
    );
    if (availableFiles.length > 0) {
      flag = true;
    }
    return flag;
  };

  const handleDownloadActivity = (activity: API_Project_Lesson_Activity) => {
    const filteredFiles = activity.files.filter(
      (file) => !file.file.includes(".mp4")
    );
    const urls = filteredFiles.map((file) => file.file);
    const names = filteredFiles.map((file) => file.name);
    const zipName = `${activity.name}`;
    if (urls.length > 0) {
      getLessonActivityResource(urls, names, zipName);
    } else {
      openSnack("No materials to be downloaded", false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}
        >
          {lesson.activities.map((activity, index) => {
            return (
              <Box
                key={`lesson-activity-${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // gap: 1,
                  paddingLeft: 2,
                  "&:hover": {
                    bgcolor: "primary.light",
                  },
                }}
              >
                {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: {
                        xs: 16,
                        sm: 20,
                      },
                      width: "100%",
                      color: "#71A1A2",
                    }}
                  >
                    {`${translation.formatString(translation.activityNo, {
                      number: index + 1,
                    })}: ${activity.name}`}
                  </Typography>
                  {/* {isActivityAvailableDownloadMaterial(activity) && (
                    // <CustomButton
                    //   buttonText={"Activity Teaching Resources"}
                    //   onClick={() => {
                    //     handleDownloadActivity(activity);
                    //   }}
                    //   arrow={false}
                    //   // style={{ fontSize: 10 }}
                    // />
                    <DownloadForOfflineIcon
                      sx={iconStyle}
                      onClick={() => {
                        handleDownloadActivity(activity);
                      }}
                    />
                  )} */}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                    justifyContent: "start",
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "60%",
                      },
                    }}
                  >
                    <Box
                      className="ck-content"
                      sx={{
                        fontSize: {
                          xs: 16,
                          sm: 20,
                        },
                        textAlign: "left",
                      }}
                      dangerouslySetInnerHTML={{ __html: activity.material }}
                    />
                  </Box>
                  <ResourceCard activity={activity} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const ResourceCard = ({
  activity,
}: {
  activity: API_Project_Lesson_Activity;
}) => {
  const openSnack = useSnack();
  const navigate = useNavigate();

  const handleDownload = (file: API_Activity_Files) => {
    const urls = [file.file];
    const names = [file.name];
    const zipName = `_`;
    getLessonActivityResource(urls, names, zipName);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        gap: 2,
        width: {
          xs: "100%",
          sm: "40%",
        },
        p: 1,
        // transition: "200ms all ease-in-out",
        // "&:hover": {
        //   transform: "scale(1.05)",
        //   transition: "200ms all ease-in-out",
        // },
      }}
    >
      {/* <div
        className="ck-content"
        style={{ fontSize: "20px" }}
        dangerouslySetInnerHTML={{
          __html: activity.instruction,
        }}
      /> */}
      {activity.files.map((file) => {
        if (file.name.includes(".mp4")) {
          return null;
        }

        return (
          <Box key={`lesson-activity-file-${file.id}`}>
            <Tooltip title={file.name} placement="top">
              <a
                key={file.name}
                href={file.file}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 130,
                    height: 130,
                    borderRadius: 5,
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "primary.main",
                    },
                  }}
                  // onClick={() => {
                  //   handleDownload(file);
                  // }}
                >
                  <FilePreview file={file} noName />

                  {file.name.length > 10
                    ? `...${file.name.slice(-10)}`
                    : file.name}
                </Box>
              </a>
            </Tooltip>
          </Box>
        );
      })}
      {/* <Button
        arrow={false}
        style={{
          padding: "8px 16px",
          width: "fit-content",
          height: "fit-content",
          fontWeight: 500,
          fontSize: "14px",
        }}
        buttonText={
          filteredFiles.length <= 0
            ? translation.noMaterial
            : translation.downloadMaterials
        }
        onClick={handleDownload}
        disabled={filteredFiles.length <= 0}
      /> */}

      {/* <Box sx={{ width: "inherit", height: "220px", mb: "4px" }}>
        <Image
          src={imgSrc}
          alt="resource"
          objectFit="cover"
          style={{
            borderRadius: "10px",
          }}
        />
      </Box> */}
      {/* <Typography>{imgCaption}</Typography> */}
    </Box>
  );
};

export const FilePreview = ({
  file,
  noName = false,
  style = {},
}: {
  file: API_Files;
  noName?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <>
      {file.name.includes(".jpg") || file.name.includes(".png") ? (
        // <ImageIcon sx={iconStyle} />
        <CustomImage
          src={file.file}
          alt={file.name}
          style={{ width: 100, height: 100, ...style }}
        />
      ) : file.name.includes(".pdf") ? (
        <PdfPreviewComponent
          file={file}
          style={{ width: 100, height: 100, ...style }}
        />
      ) : // <PictureAsPdfIcon sx={iconStyle} />
      null}

      {!noName && (
        <Typography sx={{ maxWidth: "100%" }} noWrap>
          {file.name}
        </Typography>
      )}
    </>
  );
};

const PdfPreviewComponent = ({
  file,
  style,
}: {
  file: API_Files;
  style?: React.CSSProperties;
}) => {
  const fileName = file.name.toLowerCase().replace(/\s/g, "").split(".")[0];
  const [loading, setLoading] = useState(true);
  const [pdfAsImageSrc, setPdfAsImageSrc] = useState<string>("");

  const onDocumentLoadSuccess = () => {
    const importPDFCanvas: HTMLCanvasElement = document.querySelector(
      `.import-pdf-page-${fileName} canvas`
    )!;
    setPdfAsImageSrc(importPDFCanvas.toDataURL());
  };
  return (
    <>
      <Box sx={{ ...style, display: "None" }}>
        <Document
          file={file.file}
          onLoadSuccess={() => setLoading(false)}
          onLoadError={console.error}
        >
          {!loading && (
            <Page
              pageNumber={1}
              className={`import-pdf-page-${fileName}`}
              onRenderSuccess={onDocumentLoadSuccess}
            />
          )}
        </Document>
      </Box>
      {pdfAsImageSrc.length > 0 ? (
        <CustomImage src={pdfAsImageSrc} alt={"finished"} style={style} />
      ) : (
        <CustomImage src={LogoBlur} alt={"loading"} style={style} />
      )}
    </>
  );
};

// const RelevantCourses = () => {
//   const [projects, setProjects] = useState<API_Project[]>([]);

//   const { currLanguage } = useStore((state) => ({
//     currLanguage: state.currLanguage,
//   }));

//   useEffect(() => {
//     const updateProjects = async () => {
//       setProjects(
//         await getAllProjects(currLanguage, localStorage.getItem("access"))
//       );
//     };
//     updateProjects();
//   }, [currLanguage]);

//   return (
//     <Box sx={{ maxWidth: "60vw" }}>
//       <ProjectRow projects={projects} title="Relevant Courses" arrow={false} />
//     </Box>
//   );
// };

export default TeachingMaterialsScreen;

import { StoreSlice } from "./store";

import {
  CalendarViews,
  StartAndEndDate,
  GroupProjectBoxProps,
  API_Group_Calendar,
} from "../types/calendar";

export interface CalendarSlice {
  groupCalendarList: API_Group_Calendar[];
  calendarViews: CalendarViews; //weeks months years
  calendarStartDate: Date;
  startAndEndDate: StartAndEndDate; // start&end date
  calendarDates: Date[];
  groupProjectBoxProps: GroupProjectBoxProps;
  refreshProjectBox: boolean;
  isLoadingCalendar: boolean;
  isRefreshBypassLoading: boolean;
  setGroupCalendarList: (groupCalendarList: API_Group_Calendar[]) => void;
  setCalendarViews: (updatedTab: CalendarViews) => void;
  setCalendarStartDate: (calendarStartDate: Date) => void;
  setStartAndEndDate: (startAndEndDate: StartAndEndDate) => void;
  setCalendarDates: (calendarDates: Date[]) => void;
  setGroupProjectBoxProps: (groupProjectBoxProps: GroupProjectBoxProps) => void;
  setRefreshProjectBox: (refreshProjectBox: boolean) => void;
  setIsLoadingCalendar: (isLoadingCalendar: boolean) => void;
  setIsRefreshBypassLoading: (isRefreshBypassLoading: boolean) => void;
}

const today = new Date();

export const createCalendarSlice: StoreSlice<CalendarSlice> = (set, get) => ({
  groupCalendarList: [],
  calendarViews: { Week: false, Month: true, Year: false },
  calendarStartDate: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  ),
  startAndEndDate: { startDate: new Date(), endDate: new Date() },
  calendarDates: [],

  groupProjectBoxProps: {},
  refreshProjectBox: true,
  isLoadingCalendar: true,
  isRefreshBypassLoading: false,
  setGroupCalendarList: (groupCalendarList: API_Group_Calendar[]) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      groupCalendarList: groupCalendarList,
    }));
  },
  setCalendarViews: (updatedTab: CalendarViews) => {
    set((prev: CalendarSlice) => ({ ...prev, calendarViews: updatedTab }));
  },
  setStartAndEndDate: (startAndEndDate: StartAndEndDate) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      startAndEndDate: startAndEndDate,
    }));
  },
  setCalendarDates: (calendarDates: Date[]) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      calendarDates: calendarDates,
    }));
  },
  setCalendarStartDate: (calendarStartDate: Date) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      calendarStartDate: calendarStartDate,
    }));
  },
  setGroupProjectBoxProps: (groupProjectBoxProps: GroupProjectBoxProps) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      groupProjectBoxProps: groupProjectBoxProps,
    }));
  },
  setRefreshProjectBox: (refreshProjectBox: boolean) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      refreshProjectBox: refreshProjectBox,
    }));
  },
  setIsLoadingCalendar: (isLoadingCalendar: boolean) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      isLoadingCalendar: isLoadingCalendar,
    }));
  },
  setIsRefreshBypassLoading: (isRefreshBypassLoading: boolean) => {
    set((prev: CalendarSlice) => ({
      ...prev,
      isRefreshBypassLoading: isRefreshBypassLoading,
    }));
  },
});

import React from "react";
import { useParams } from "react-router-dom";
import {
  PHOTO_ALBUM,
  PROGRESS_REPORT,
  PROJECT_SUMMARY,
} from "containers/ChildrenPortfolioPage/constants";
import TeacherArchiveProgressReportScreen from "./TeacherArchiveProgressReport/TeacherArchiveProgressReportScreen";
import TeacherArchiveCompletedWorkScreen from "./TeacherArchiveCompletedWork/TeacherArchiveCompletedWorkScreen";
import TeacherArchivePhotoAlbumScreen from "./TeacherArchivePhotoAlbum/TeacherArchivePhotoAlbumScreen";
import TeacherArchiveProjectSummaryScreen from "./TeacherArchiveProjectSummary/TeacherArchiveProjectSummaryScreen";
import { slugify } from "helper/slugify";
import { COMPLETED_WORKS } from "containers/ProjectSummaryPage/constant";

const TeacherArchiveLandingPage = () => {
  const { teacherArchiveTab } = useParams();

  return teacherArchiveTab === PROGRESS_REPORT ? (
    <TeacherArchiveProgressReportScreen />
  ) : teacherArchiveTab === slugify(COMPLETED_WORKS) ? (
    <TeacherArchiveCompletedWorkScreen />
  ) : teacherArchiveTab === PHOTO_ALBUM ? (
    <TeacherArchivePhotoAlbumScreen />
  ) : teacherArchiveTab === PROJECT_SUMMARY ? (
    <TeacherArchiveProjectSummaryScreen />
  ) : null;
};

export default TeacherArchiveLandingPage;

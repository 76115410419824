import { Box, Stack, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useStore from "../../../../../store/store";
import LoadingIndicator from "../../../../../components/LoadingIndicator/LoadingIndicator";
import { getActivityById } from "../../../../../api/teacher-api";
import {
  RESOURCE_TYPE_FLIP_CARD,
  RESOURCE_TYPE_TEXT,
  RESOURCE_TYPE_VIDEO,
  RESOURCE_TYPE_MCQ,
  RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY,
  RESOURCE_TYPE_PICTORIAL_OBSERVATION,
  RESOURCE_TYPE_DRAG_AND_DROP,
} from "../../teacherPageConstants";
import EditTextActivityForm from "./ActivityForms/EditTextActivityForm";
import EditVideoActivityForm from "./ActivityForms/EditVideoActivityForm";
import EditFlipCardActivityForm from "./ActivityForms/EditFlipCardActivityForm";
import EditMcqActivityForm from "./ActivityForms/EditMcqActivityForm";
import EditInteractiveDataEntryActivityForm from "./ActivityForms/EditInteractiveDataEntryActivityForm";
import EditPictorialObservationActivityForm from "./ActivityForms/EditPictorialObservationActivityForm";
import EditDragAndDropActivityForm from "./ActivityForms/EditDragAndDropActivityForm";

const EditActivityForm = ({
  activityId,
  lessonId,
  handleClose,
}: {
  activityId: number;
  lessonId: number;
  handleClose: () => void;
}) => {
  // Better to split this into different types of activities to prevent complexity.
  const {
    currLanguage,
    teacherCurrentActivity,
    setTeacherCurrentActivity,
    setIsRefreshTeacherModule,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    teacherCurrentActivity: state.teacherCurrentActivity,
    setTeacherCurrentActivity: state.setTeacherCurrentActivity,
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const initialiseActivity = async () => {
    const activityRes = await getActivityById(currLanguage, activityId);
    if (typeof activityRes === "string") {
      console.log("error getting activity by id: ", activityRes);
    } else {
      setTeacherCurrentActivity(activityRes);
    }
  };

  useEffect(() => {
    const initialise = async () => {
      setIsLoading(true);
      await initialiseActivity();
      setIsLoading(false);
    };
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);
  // console.log("teacherCurrentActivity: ", teacherCurrentActivity);
  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {teacherCurrentActivity.resourcetype === RESOURCE_TYPE_TEXT && (
          <EditTextActivityForm
            activityId={activityId}
            lessonId={lessonId}
            handleClose={handleClose}
          />
        )}
        {teacherCurrentActivity.resourcetype === RESOURCE_TYPE_VIDEO && (
          <EditVideoActivityForm
            activityId={activityId}
            lessonId={lessonId}
            handleClose={handleClose}
          />
        )}
        {teacherCurrentActivity.resourcetype === RESOURCE_TYPE_FLIP_CARD && (
          <EditFlipCardActivityForm
            activityId={activityId}
            lessonId={lessonId}
            handleClose={handleClose}
          />
        )}
        {teacherCurrentActivity.resourcetype ===
          RESOURCE_TYPE_DRAG_AND_DROP && (
          <EditDragAndDropActivityForm
            activityId={activityId}
            lessonId={lessonId}
            handleClose={handleClose}
          />
        )}

        {teacherCurrentActivity.resourcetype === RESOURCE_TYPE_MCQ && (
          <EditMcqActivityForm
            activityId={activityId}
            lessonId={lessonId}
            handleClose={handleClose}
          />
        )}
        {teacherCurrentActivity.resourcetype ===
          RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY && (
          <EditInteractiveDataEntryActivityForm
            activityId={activityId}
            lessonId={lessonId}
            handleClose={handleClose}
          />
        )}
        {teacherCurrentActivity.resourcetype ===
          RESOURCE_TYPE_PICTORIAL_OBSERVATION && (
          <EditPictorialObservationActivityForm
            activityId={activityId}
            lessonId={lessonId}
            handleClose={handleClose}
          />
        )}
      </Box>
    </>
  );
};

export default EditActivityForm;

import React from "react";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageTwelve from "images/children-photo-book/page-12.png";

import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageTwelveCaptionInfo,
  photoBookPageTwelveCaptionPositionList,
  photoBookPageTwelveImageInfo,
  photoBookPageTwelveImagePositionList,
} from "../photoBookCommonConstant";

export const PhotoBookPageTwelveComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imagePositionList = photoBookPageTwelveImagePositionList(images, scale);
  const captionPositionList = photoBookPageTwelveCaptionPositionList(
    images,
    scale
  );
  const imageInfo = photoBookPageTwelveImageInfo(scale);
  const captionInfo = photoBookPageTwelveCaptionInfo(scale);

  return (
    <>
      <Image
        src={PhotoBookPageTwelve}
        alt={"photo-book-page-12"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            key={`image-${imagePositionInfo.src}`}
            src={imagePositionInfo.src}
            alt={"photo-book-page-1"}
            objectFit="cover"
            style={{
              position: "absolute",
              top: imagePositionInfo.top,
              left: imagePositionInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
            }}
          />
        );
      })}

      {/* <CaptionComponent
        top={120 * scale}
        left={30 * scale}
        width={250 * scale}
        caption={images[0].caption}
        clampLine={1}
        fontSize={13 * scale}
        textAlign="center"
      /> */}
      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionPositionInfo.top}
            left={captionPositionInfo.left}
            width={captionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize}
            textAlign="center"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageTwelveComponent;

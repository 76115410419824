import axios from "api/axiosConfig";
import { AxiosError } from "axios";

import {
  API_Child,
  API_Project_Completed_For_Portfolio,
  API_Project_Upcoming_For_Portfolio,
} from "types/portfolio";
import { API_Group_Portfolio } from "types/group";
import { ImageType } from "components/ChildrenPortfolioPDF/Preview/constant";
import { Query_Data } from "types/project";

export const getAllChildPortfolioObservation = async ({
  language,
  childId,
  startDate,
  endDate,
}: {
  language: string;
  childId: number;
  startDate?: string;
  endDate?:   string;
}) => {
  let path = `${language}/lms/portfolio-observation/child/${childId}`;
  if (startDate) {
    path += `?start_date=${startDate}`;
  }
  if (endDate) {
    path += `&end_date=${endDate}`;
  }

  try {
    const res = await axios.get(path);
    const data: API_Child.PortfolioObservation[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getChildPortfolioObservation = async ({
  language,
  childId,
  developmentalGoalTag,
  queryData,
  developmentalGoalId,
  isShowAll,
}: {
  language: string;
  childId: number;
  developmentalGoalTag: string;
  queryData?: Query_Data;
  developmentalGoalId?: number;
  isShowAll?: boolean;
}) => {
  let path = `${language}/lms/portfolio-observation/child/${childId}?developmental_goal_tag=${developmentalGoalTag}`;
  if (developmentalGoalId && isShowAll) {
    path += `&is_show_all=${isShowAll}&developmental_goal=${developmentalGoalId}`;
  }
  if (queryData && queryData.startDate) {
    path += `&start_date=${queryData.startDate}`;
  }
  if (queryData && queryData.endDate) {
    path += `&end_date=${queryData.endDate}`;
  }

  try {
    const res = await axios.get(path);
    const data: API_Child.PortfolioObservation[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getChildLearningMomentList = async (
  language: string,
  childId: number
) => {
  try {
    const res = await axios.get(
      `${language}/lms/child/${childId}/learning-moment-detail-get`
    );
    const data: API_Child.LearningMoment[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getChildCompletedProject = async (
  language: string,
  childId: number,
  groupSlug: string,
  startYearMonthStr: string,
  endYearMonthStr: string,
  isLittleLearner: boolean
) => {
  try {
    const res = await axios.get(
      `${language}/lms/portfolio/child/${childId}/get-child-completed-project?start_year_month=${startYearMonthStr}&end_year_month=${endYearMonthStr}&is_little_learner=${isLittleLearner}&group_slug=${groupSlug}`
    );
    const data: API_Project_Completed_For_Portfolio[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      console.log(JSON.stringify(error.response.data));
    }
    return [];
  }
};
export const getChildUpcomingProject = async (
  language: string,
  childId: number,
  groupSlug: string,
  startYearMonthStr: string,
  endYearMonthStr: string
) => {
  try {
    const res = await axios.get(
      `${language}/lms/portfolio/child/${childId}/get-child-upcoming-project?start_year_month=${startYearMonthStr}&end_year_month=${endYearMonthStr}&group_slug=${groupSlug}`
    );
    const data: API_Project_Upcoming_For_Portfolio[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      console.log(JSON.stringify(error.response.data));
    }
    return [];
  }
};

export const getProfileImages = async (childId: number) => {
  try {
    const res = await axios.get(
      `en/lms/portfolio/child/${childId}/get-child-images`
    );
    const data: ImageType[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getChildGroupPortfolioInfo = async (
  language: string,
  groupSlug: string
) => {
  try {
    const res = await axios.get(
      `${language}/lms/portfolio/group/${groupSlug}/get-child-group-portfolio-info
`
    );
    const data: API_Group_Portfolio = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

//child achieved milestone tracker
export const getChildAchievedMilestoneTracker = async (
  language: string,
  childId: number,
  developmentalGoalId: number
) => {
  try {
    const res = await axios.get(
      `${language}/lms/child-achieved-milestone-tracker/child/list?child_id=${childId}&developmental_goal_id=${developmentalGoalId}`
    );
    const data: API_Child.AchievedMilestoneTracker[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getAllChildAchievedMilestoneTracker = async (
  language: string,
  childId: number,
) => {
  try {
    const res = await axios.get(
      `${language}/lms/child-achieved-milestone-tracker/child/list?child_id=${childId}`
    );
    const data: any[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createChildAchievedMilestoneTracker = async (
  childId: number,
  milestoneId: number, 
  project:any,
) => {
  // console.log("childId: ", childId, "milestoneId", milestoneId);
  try {
    const res = await axios.post(
      "en/lms/child-achieved-milestone-tracker/create",
      {
        child: childId,
        milestone: milestoneId,
        status: true,
        project:project,
      }
    );
    const data: API_Child.AchievedMilestoneTracker = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateChildAchievedMilestoneTracker = async (
  trackerData: API_Child.AchievedMilestoneTracker
) => {
  try {
    const res = await axios.patch(
      `en/lms/child-achieved-milestone-tracker/${trackerData.id}/update`,
      trackerData
    );
    const data: API_Child.AchievedMilestoneTracker = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteChildAchievedMilestoneTracker = async (
  trackerId: number
) => {
  try {
    const res = await axios.delete(
      `en/lms/child-achieved-milestone-tracker/${trackerId}/delete`
    );

    const data: API_Child.AchievedMilestoneTracker = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//     # portfolio observation
export const createPortfolioObservation = async (
  portfolioObservationData: API_Child.CreatePortfolioObservation
) => {
  // console.log("childId: ", childId, "milestoneId", milestoneId);
  // console.log("portfolioObservationData: ", portfolioObservationData);
  try {
    const res = await axios.post(
      "en/lms/portfolio-observation/create",
      portfolioObservationData,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: API_Child.PortfolioObservation = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updatePortfolioObservation = async (
  portfolioObservationData: API_Child.UpdatePortfolioObservation
) => {
  const headers = { "Content-Type": "multipart/form-data" };

  try {
    const res = await axios.patch(
      `en/lms/portfolio-observation/${portfolioObservationData.id}/update`,
      portfolioObservationData,
      { headers }
    );

    const data: API_Child.PortfolioObservation = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deletePortfolioObservation = async (
  portfolioObservationId: number
) => {
  try {
    const res = await axios.delete(
      `en/lms/portfolio-observation/${portfolioObservationId}/delete`
    );

    const data: API_Child.PortfolioObservation = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

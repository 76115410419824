import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSchoolBySlug } from "api/school-api";
import CustomButton from "components/Button/Button";
import Image from "components/Image/Image";
import Loader from "components/LoadingIndicator/LoadingIndicator";
import {
  CURLANG,
  SCHOOL_ACCOUNT_PAGE,
} from "constants/url";
import useStore from "store/store";
import { API_School_Detail } from "types/school";
import CannotAccessPage from "containers/NotFoundPage/CannotAccessPage";

const SchoolDetailPage = () => {
  const { schoolSlug } = useParams();
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const [canAccess, setCanAccess] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [school, setSchool] = useState<API_School_Detail>({
    id: -1,
    name: "",
    slug: "",
    teachers: 0,
    parents: 0,
    children: 0,
    admin: 0,
    total: 0,
  });

  useEffect(() => {
    const populateData = async () => {
      if (schoolSlug) {
        const res = await getSchoolBySlug(schoolSlug);
        if (typeof res !== "string") {
          setSchool(res);
        } else {
          setCanAccess(false);
        }
      }
      setIsLoading(false);
    };

    populateData();
  }, [schoolSlug]);

  return (
    <Box sx={{ p: "30px 50px" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {canAccess ? (
            <>
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: "500",
                  textAlign: "center",
                  mb: "20px",
                }}
              >
                {school.name}
              </Typography>
              <Box
                sx={{
                  width: "300px",
                  height: "300px",
                  mx: "auto",
                  mb: "30px",
                }}
              >
                <Image
                  src={school.image}
                  alt={school.image ? school.image : "No Image"}
                  objectFit="cover"
                />
              </Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Admin</TableCell>
                      <TableCell>Teachers</TableCell>
                      <TableCell>Parents</TableCell>
                      <TableCell>Children</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{school.admin}</TableCell>
                      <TableCell>{school.teachers}</TableCell>
                      <TableCell>{school.parents}</TableCell>
                      <TableCell>{school.children}</TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        {school.total}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <CustomButton
                buttonText="Manage Accounts"
                arrow={false}
                style={{ marginLeft: "auto", marginTop: "30px" }}
                onClick={() => {
                  navigate(
                    `${CURLANG(currLanguage)}/${SCHOOL_ACCOUNT_PAGE(
                      school.slug
                    )}`
                  );
                }}
              />
            </>
          ) : (
            <CannotAccessPage />
          )}
        </>
      )}
    </Box>
  );
};

export default SchoolDetailPage;

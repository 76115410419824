import * as React from "react";
import background from "images/children-portfolio-v2/LittleLearnerBackground.png";
import { Page, Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { pageSize, styles } from "../constant";
import { checkIsChineseChar } from "helper/helper";
import { PDFInfoType } from "../../Preview/constant";
import LittleProjectsHeaderImage from "images/children-portfolio-v2/LittleProjectsHeaderImage.png";
import LittleLearnersHeaderImage from "images/children-portfolio-v2/LittleLearnersHeaderImage.png";
import LittleAcademicHeaderImage from "images/children-portfolio-v2/LittleAcademicHeaderImage.png";
// Import your image structure components
import ImageStructure from "./ImageStructure";
import ImageStructureForThree from "./ImageStructureForThree";
import ImageStructureForFour from "./ImageStructureForFour";
import ImageStructureForOne from "./ImageStructureForOne";

interface CategoryImages {
  "Little Projects": string;
  "Little Learners": string;
  "Little Academic": string;
}
const images: CategoryImages = {
  "Little Projects": LittleProjectsHeaderImage,
  "Little Learners": LittleLearnersHeaderImage,
  "Little Academic": LittleAcademicHeaderImage,
};

const templateStyle = StyleSheet.create({
  contentContainer: {
    position: "absolute",
    top: 50,
    left: 0,
    width: "100%",
    paddingRight: 20,
    paddingLeft: 40,
    display: "flex",
    flexDirection: "column",
    height: "auto",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: "10px",
  },
  title: {
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "normal",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
});

const ModulePages = ({ PDFInfo }: { PDFInfo: PDFInfoType }) => {
  return (
    <>
      {PDFInfo.categories.map((module, index) => {
        const projects = module.projects.slice(0, 4); // Ensure a maximum of 4 projects
        return (
          <Page key={index} size={pageSize} style={styles.body}>
            <Image src={background} style={templateStyle.backgroundImage} />
            <View style={templateStyle.contentContainer}>
              <View style={templateStyle.titleContainer}>
                <Image
                  src={images[module.category_title as keyof typeof images]}
                  style={{
                    width:
                      module.category_title == "Little Academic"
                        ? "80px"
                        : "40px",
                    height:
                      module.category_title == "Little Projects"
                        ? "40px"
                        : "50px",
                  }}
                />
                <Text
                  style={[
                    templateStyle.title,
                    {
                      fontFamily: checkIsChineseChar(module.category_title)
                        ? "KaiTi"
                        : "TheOnlyException",
                    },
                  ]}
                >
                  {module.category_title}
                </Text>
              </View>
              <View>
                {projects.length === 1 ? (
                  <ImageStructureForOne projects={projects} />
                ) : projects.length === 2 ? (
                  <ImageStructure projects={projects} />
                ) : projects.length === 3 ? (
                  <ImageStructureForThree projects={projects} />
                ) : projects.length === 4 ? (
                  <ImageStructureForFour projects={projects} />
                ) : null}
              </View>
            </View>
          </Page>
        );
      })}
    </>
  );
};

export default ModulePages;

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteObjective,
  editProjectFundamental,
  getAllFundamentals,
  getAllFundamentalsbyProject,
} from "api/fundamental-api";
import Button from "components/Button/Button";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  API_Fundamental,
  API_Project_Fundamental_Legacy,
  API_Update_Project_Fundamental,
} from "types/project";
import { translation } from "constants/translation";

import ClearIcon from "@mui/icons-material/Clear";

const EditObjectivePage = () => {
  return (
    <Box sx={{ alignItems: "top" }}>
      <Title />
      <Form />
    </Box>
  );
};

const Title = () => {
  const title = useStore((state) => state.currentProject.name);

  return (
    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
        mb: "20px",
      }}
    >
      {translation.editing_curriculum_fundamental_of} {title}
    </Typography>
  );
};

const Form = () => {
  const { projectID, projectSlug, currLanguage } = useStore((state) => ({
    projectID: state.currentProject.id,
    projectSlug: state.currentProject.slug,
    currLanguage: state.currLanguage,
  }));

  const openSnack = useSnack();
  const navigate = useNavigate();

  const [fundamentalList, setFundamentalList] = useState<API_Fundamental[]>([]);
  const [currObjectives, setCurrObjectives] = useState<
    API_Project_Fundamental_Legacy[]
  >([]);
  const [objectives, setObjectives] = useState<
    API_Update_Project_Fundamental[]
  >([]);
  const [delFundamental, setDelFundamental] = useState<boolean[]>([]);

  useEffect(() => {
    const updateFundamentals = async () => {
      const updatedAllFundamental: API_Fundamental[] = await getAllFundamentals(
        currLanguage
      );
      const updatedFundamentals: API_Project_Fundamental_Legacy[] =
        await getAllFundamentalsbyProject(currLanguage, projectID.toString());
      setFundamentalList(updatedAllFundamental);
      setCurrObjectives(updatedFundamentals);
      const updatedObjectives: API_Update_Project_Fundamental[] =
        updatedFundamentals.map((fun) => ({
          fundamental: fun.funid.toString(),
          objective: fun.objective,
          project: projectID.toString(),
        }));
      setObjectives(updatedObjectives);
      setDelFundamental(Array(updatedFundamentals.length).fill(false));
    };

    updateFundamentals();
  }, [currLanguage, projectID]);

  const SubmitForm = async () => {
    let editFailed = false;

    for (let index = 0; index < objectives.length; index++) {
      const currObj = currObjectives[index];
      const obj = objectives[index];
      const objData: API_Update_Project_Fundamental = {};

      if (delFundamental[index]) {
        const res = await deleteObjective(currObj.id.toString());
        if (res !== "success") {
          openSnack(res, false);
          editFailed = true;
        }
      } else {
        if (currObj.funid.toString() !== obj.fundamental)
          objData.fundamental = obj.fundamental;
        if (currObj.objective !== obj.objective)
          objData.objective = obj.objective;

        if (Object.keys(objData).length > 0) {
          const res = await editProjectFundamental(
            currLanguage,
            currObj.id.toString(),
            objData
          );
          if (typeof res === "string") {
            openSnack("Failed to update fundamental!", false);
            editFailed = true;
          }
        }
      }
    }

    if (!editFailed) {
      openSnack("Updated Fundamentals successfully!", true);
      navigate(`${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projectSlug)}`);
    }
  };

  return (
    <>
      <Box
        sx={{
          mb: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
            justifyContent: "center",
          }}
        >
          {objectives.map((fun, index) => {
            return (
              <FundamentalEditCard
                index={index}
                objectives={objectives}
                setObjectives={setObjectives}
                fundamentalList={fundamentalList}
                delFundamental={delFundamental}
                setDelFundamental={setDelFundamental}
              />
            );
          })}
        </Box>
      </Box>
      <Button
        buttonText={translation.update || "Update"}
        style={{
          fontSize: "14px",
          backgroundColor: "var(--txt-secondary)",
          position: "fixed",
          bottom: 30,
          right: 40,
        }}
        onClick={SubmitForm}
      />
    </>
  );
};

const FundamentalEditCard = ({
  index,
  objectives,
  setObjectives,
  fundamentalList,
  delFundamental,
  setDelFundamental,
}: {
  index: number;
  objectives: API_Update_Project_Fundamental[];
  setObjectives: React.Dispatch<
    React.SetStateAction<API_Update_Project_Fundamental[]>
  >;
  fundamentalList: API_Fundamental[];
  delFundamental: boolean[];
  setDelFundamental: React.Dispatch<React.SetStateAction<boolean[]>>;
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    const updatedObjectives = objectives.map((o) => ({ ...o }));
    updatedObjectives[index].fundamental = event.target.value;
    setObjectives(updatedObjectives);
  };

  return (
    <Card
      sx={{
        minWidth: 250,
        maxWidth: 250,
        border: `${delFundamental[index] ? "2px solid red" : "2px solid"}`,
        borderColor: `${delFundamental[index] ? "red" : "primary.main"}`,
        borderRadius: "10px",
        backgroundColor: "primary.light",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        transition: "200ms all ease-in-out",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: "25px",
      }}
    >
      <CardHeader
        action={
          <IconButton title={translation.delete_fundamental}>
            <ClearIcon
              onClick={() => {
                setDelFundamental((prev) => {
                  const temp = JSON.parse(JSON.stringify(prev));
                  temp[index] = !temp[index];
                  return temp;
                });
              }}
            />
          </IconButton>
        }
        subheader={`${translation.fundamental_str} ${index + 1}`}
      />
      <CardContent
        sx={{
          width: "200px",
        }}
      >
        <Box>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              {translation.fundamental_str}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={objectives[index].fundamental}
              label="Fundamental"
              onChange={handleChange}
            >
              {fundamentalList.map((fun) => {
                return (
                  <MenuItem key={fun.id} value={fun.id}>
                    {fun.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label={translation.objective_str}
            value={objectives[index].objective}
            style={{ marginTop: "5px" }}
            onChange={(event) => {
              const updatedObjectives = objectives.map((o) => ({ ...o }));
              updatedObjectives[index].objective = event.target.value;
              setObjectives(updatedObjectives);
            }}
            key={"name"}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default EditObjectivePage;

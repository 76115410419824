import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { getCustomTimetableByProfile } from "api/timetable-api";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useStore from "store/store";
import { translation } from "constants/translation";
import { UploadTimetableBox } from "./components/CustomTimetableComponents";
import { API_Custom_Timetable } from "types/timetable";
import ViewCustomTimetable from "./components/ViewCustomTimetable";

const CustomTimetable = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timetable, setTimetable] = useState<API_Custom_Timetable | null>(null);

  const { profileId } = useParams();

  const { currLanguage, currProfileId } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currProfileId: state.profileDetails.id,
  }));

  const isViewOnly = profileId !== currProfileId.toString();

  useEffect(() => {
    if (!profileId) return;

    const fetchCustomTimetable = async () => {
      setIsLoading(true);

      try {
        const customTimetable = await getCustomTimetableByProfile(
          currLanguage,
          profileId
        );

        setTimetable(customTimetable);
      } catch (error) {
        setTimetable(null);
      }

      setIsLoading(false);
    };

    fetchCustomTimetable();
  }, [currLanguage, profileId]);

  return isLoading ? (
    <Box
      sx={{
        p: 3,
      }}
    >
      <LoadingIndicator />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        mx: "auto",
        width: "100%",
        maxWidth: {
          lg: "1000px",
          xs: "100%",
        },
      }}
    >
      {timetable && timetable.file !== null ? (
        <ViewCustomTimetable
          timetable={timetable}
          setTimetable={setTimetable}
          isViewOnly={isViewOnly}
        />
      ) : isViewOnly ? (
        <Typography
          sx={{
            p: 3,
            textAlign: "center",
          }}
        >
          {translation.noUploadedTimetable}
        </Typography>
      ) : (
        <UploadTimetableBox setTimetable={setTimetable} />
      )}
    </Box>
  );
};

export default CustomTimetable;

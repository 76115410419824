import React from "react";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageSix from "images/children-photo-book/page-6.png";
import PhotoBookPageSixTop from "images/children-photo-book/page-6-top.png";
import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageSixCaptionInfo,
  photoBookPageSixCaptionPositionList,
  photoBookPageSixImageInfo,
  photoBookPageSixImagePositionList,
} from "../photoBookCommonConstant";

export const PhotoBookPageSixComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imagePositionList = photoBookPageSixImagePositionList(images, scale);
  const captionPositionList = photoBookPageSixCaptionPositionList(
    images,
    scale
  );
  const imageInfo = photoBookPageSixImageInfo(scale);
  const captionInfo = photoBookPageSixCaptionInfo(scale);
  return (
    <>
      <Image
        src={PhotoBookPageSix}
        alt={"photo-book-page-6"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            src={imagePositionInfo.src}
            alt={"photo-book-page-1"}
            objectFit="cover"
            style={{
              position: "absolute",
              top: imageInfo.top,
              left: imagePositionInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
            }}
          />
        );
      })}
      <Image
        src={PhotoBookPageSixTop}
        alt={"photo-book-page-6-top"}
        objectFit="cover"
        style={{
          ...photoBookImageStyle,
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionComponent
            top={captionInfo.top}
            left={captionPositionInfo.left}
            width={captionPositionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize}
            textAlign="center"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageSixComponent;

import { useRef, useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import useStore from "store/store";

import moment from "moment";

import { Chip, Typography, Box } from "@mui/material";
import SearchBar from "components/SearchBar/SearchBar";
import { API_Profile_Short } from "types/profile";
import AttendanceDetailModal from "./AttendanceDetailModal";
import { getAttendanceToday } from "api/auth-api";
import AttendanceToday from "./AttendanceToday";
import AttendanceWeekMonthOther from "./AttendanceWeekMonthOther";
import CustomDatePicker from "./CustomDatePicker";

const AttendancePage = () => {
  const { groupSlug } = useParams();
  const [view, setView] = useState<string>("today");

  const [mainPageHeight, setMainPageHeight] = useState<number>(0);
  const [modalText, setModalText] = useState<string>("");
  const [modalAbsentee, setModalAbsentee] = useState<any>(null);
  const { currGroup, groupList } = useStore((state) => ({
    currGroup: state.currGroup,
    groupList: state.groupList,
  }));
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [attendances, setAttendances] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const today = new Date();

  function padTo2Digits(num: any) {
    return num.toString().padStart(2, "0");
  }

  function minifyToday(date: any) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear().toString().substr(-2),
    ].join("");
  }
  const dateKey = minifyToday(today);

  function formatTodayDate(date: any) {
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `Today: ${day} ${month} ${year}`;
  }

  function getWeekRange(date: any) {
    const start = new Date(date);
    start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
    const end = new Date(start);
    end.setDate(end.getDate() + 4);

    const startDay = start.getDate();
    const endDay = end.getDate();
    const startMonth = start.toLocaleString("default", { month: "short" });
    const endMonth = end.toLocaleString("default", { month: "short" });

    return `This Week: ${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  }
  today.setHours(today.getHours() + today.getTimezoneOffset() / 60 + 8);
  const weekRange = getWeekRange(today);

  const formattedDateToday = formatTodayDate(today);

  const selectedGroup = groupList.filter((group) => group.slug === groupSlug);
  let filteredChildren: API_Profile_Short[] = [];
  if (selectedGroup.length > 0) {
    let children = selectedGroup[0].children as API_Profile_Short[];

    children = children.sort((a, b) =>
      sortAscending ? a.full_name.localeCompare(b.full_name) : b.full_name.localeCompare(a.full_name)
    );

    filteredChildren = children.filter((child) =>
      child.preferred_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  useEffect(() => {
    if (currGroup.id !== -1) {
      const fetchAttendance = async () => {
        const access = localStorage.getItem("access");
        if (access) {
          const response = await getAttendanceToday(currGroup.id, access);
          setAttendances(response);
        }
      };
      fetchAttendance();
    }
  }, [currGroup]);

  const mainPageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mainPageRef.current) {
      const height = mainPageRef.current.offsetHeight;
      setMainPageHeight(height);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        margin: { xs: 5, sm: 3, md: 5 },
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
      ref={mainPageRef}
    >
      {modalAbsentee && (
        <AttendanceDetailModal
          open={modalAbsentee}
          handleClose={() => {
            setModalText("");
            setModalAbsentee(null);
          }}
          modalAbsentee={modalAbsentee}
          setModalAbsentee={setModalAbsentee}
          modalText={modalText}
          setModalText={setModalText}
          dateKey={dateKey}
          view={view}
        />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Typography, Chips, SearchBar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* split attendance, search, THEN chips, export) */}
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            {" "}
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "24px",
                color: "#323031",
                mb: 1,
              }}
            >
              Attendance
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <SearchBar
                placeholder={"Enter Name"}
                handleChange={(query: string) => setSearchQuery(query)}
                sx={{ width: 250 }}
              />
            </Box>
          </Box>

          {/* Next chips and export */}
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                overflow: "hidden",
                mr: 1,
                gap: 1,
              }}
            >
              <Chip
                label={formattedDateToday}
                sx={{
                  height: "32px",
                  borderColor: "#79B5B4",
                  backgroundColor: view === "today" ? "#79B5B4" : "white",
                  color: view === "today" ? "white" : "#79B5B4",
                }}
                variant="outlined"
                onClick={() => {
                  setView("today");
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
              <Chip
                sx={{
                  borderColor: "#79B5B4",
                  backgroundColor: view === "week" ? "#79B5B4" : "white",
                  color: view === "week" ? "white" : "#79B5B4",
                }}
                label={weekRange}
                variant="outlined"
                onClick={() => {
                  setView("week");
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
              <Chip
                sx={{
                  borderColor: "#79B5B4",
                  backgroundColor: view === "month" ? "#79B5B4" : "white",
                  color: view === "month" ? "white" : "#79B5B4",
                }}
                label="This Month"
                variant="outlined"
                onClick={() => {
                  setView("month");
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
              <CustomDatePicker
                view={view}
                setView={setView}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {view == "today" && (
        <Box sx={{ height: mainPageHeight * 0.9 }}>
          <AttendanceToday
            filteredChildren={filteredChildren}
            modalText={modalText}
            setModalText={setModalText}
            modalAbsentee={modalAbsentee}
            setModalAbsentee={setModalAbsentee}
            attendances={attendances}
          />
        </Box>
      )}
      {view != "today" && (
        <Box sx={{ height: mainPageHeight * 0.85 }}>
          <AttendanceWeekMonthOther
            filteredChildren={filteredChildren}
            view={view}
            setModalText={setModalText}
            setModalAbsentee={setModalAbsentee}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
      )}
    </Box>
  );
};

export default AttendancePage;

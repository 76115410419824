import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Avatar,
  TextField,
  Tooltip,
  Modal,
  Backdrop,
  Fade,
  SxProps,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import Image from "components/Image/Image";
import { translation } from "constants/translation";
import Button, {
  AddButton,
  FactoryButton,
} from "components/Button/Button";
import Logo from "images/logo-2-colour.png";
import { API_School } from "types/school";
import { editSchool } from "api/school-api";
import useSnack from "hooks/useSnack";
import { RefreshInterface } from "./ProfilePage";
import EditPhoto from "components/PhotoVideoGalleryComponents/Photo/EditPhotoModal";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import { updateProfile } from "api/auth-api";
import { API_Update_Profile } from "types/profile";
import useStore from "store/store";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { useNavigate } from "react-router-dom";
import DetailCard from "components/DetailCard/DetailCard";
import { API_Project } from "types/project";

export const AddBackgroundButton = ({
  haveImage,
  profileId,
  setRefresh,
}: {
  haveImage: boolean;
  profileId: string;
  setRefresh: React.Dispatch<React.SetStateAction<RefreshInterface>>;
}) => {
  const openSnack = useSnack();

  const buttonStyles: React.CSSProperties = {
    flexDirection: "row-reverse",
    backgroundColor: "white",
    color: "#98C4C5",
    border: "1px solid #98C4C5",
    padding: "10px 16px",
    fontSize: "14px",
    fontWeight: 500,
  };

  const handleDelete = async () => {
    const res: API_School | string = await editSchool(
      profileId,
      {
        background_image: "" as unknown as undefined,
      },
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      setRefresh({
        refresh: true,
        callback: () => {},
      });
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `${translation.removeBackgroundPhoto}?`,
    warningContext: translation.irreversibleAction,
    handleDelete,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 1,
      }}
    >
      <WarningDialog {...warningBody} />

      <Box component="label" htmlFor="upload-background-image">
        {haveImage ? (
          <FactoryButton
            btnText={
              translation.updateBackgroundPhoto || "Update Background Photo"
            }
            style={buttonStyles}
          />
        ) : (
          <AddButton
            btnText={translation.addBackgroundPhoto || "Add Background Photo"}
            style={{ ...buttonStyles, padding: "8px 16px" }}
          />
        )}
      </Box>

      {haveImage && (
        <Button
          arrow={false}
          buttonText={
            translation.removeBackgroundPhoto || "Remove Background Photo"
          }
          style={{
            ...buttonStyles,
            pointerEvents: "auto",
            color: "#FF0000",
            border: "1px solid #FF0000",
          }}
          onClick={handleOpenDialog}
        />
      )}

      <UploadImage
        profileId={profileId}
        isBackgroundImage={true}
        setRefresh={setRefresh}
      />
    </Box>
  );
};

export const ProfileImage = ({
  image,
  imageFallbackChar,
  boxSx = {
    position: "relative",
    width: "17vw",
    height: "17vw",
    mx: "auto",
    borderRadius: "50%",
    overflow: "hidden",
  },
}: {
  image: string;
  imageFallbackChar: string;
  boxSx?: SxProps;
}) => {
  const [currImage, setCurrImage] = useState<string>("");

  useEffect(() => {
    setCurrImage(image);
  }, [image]);

  return (
    <Box sx={boxSx}>
      <Avatar
        src={currImage}
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "10vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {imageFallbackChar}
      </Avatar>
    </Box>
  );
};

export const BackgroundImage = ({ image }: { image?: string }) =>
  image ? (
    <Image
      src={image}
      alt="background"
      objectFit="cover"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
        opacity: 0.5,
      }}
    />
  ) : null;

export const SchoolImage = ({
  image,
  name,
  profileId,
  setRefresh,
}: {
  image?: string;
  name: string;
  profileId: string;
  setRefresh: React.Dispatch<React.SetStateAction<RefreshInterface>>;
}) => {
  const openSnack = useSnack();

  const iconStyles: SxProps = {
    fontSize: "2.5vw",
    color: "white",
    opacity: 0.5,
    transition: "all 150ms linear",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  };

  const handleDelete = async () => {
    const res: API_School | string = await editSchool(
      profileId,
      {
        image: "" as unknown as undefined,
      },
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      setRefresh({
        refresh: true,
        callback: () => {},
      });
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `${translation.removePhoto}?`,
    warningContext: translation.irreversibleAction,
    handleDelete,
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "17vw",
          height: "17vw",
          mx: "auto",
          borderRadius: "50%",
          overflow: "hidden",
        }}
      >
        <WarningDialog {...warningBody} />

        <Avatar
          src={image}
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "primary.main",
            color: "txt.light",
            border: "2px solid",
            borderColor: "primary.main",
            fontSize: "10vw",
          }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        >
          {name ? name.charAt(0).toUpperCase() : ""}
        </Avatar>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            opacity: 0,
            transition: "all 150ms linear",
            "&:hover": {
              opacity: 1,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              mt: 0.25,
            }}
            component="label"
            htmlFor="upload-image"
          >
            <PhotoCameraIcon sx={iconStyles} />
          </Box>

          {image !== null && (
            <DeleteIcon sx={iconStyles} onClick={handleOpenDialog} />
          )}
        </Box>
      </Box>

      <UploadImage
        profileId={profileId}
        isBackgroundImage={false}
        setRefresh={setRefresh}
      />
    </>
  );
};

export const SchoolName = ({
  profileId,
  name,
  setRefresh,
}: {
  profileId: string;
  name: string;
  setRefresh: React.Dispatch<React.SetStateAction<RefreshInterface>>;
}) => {
  const openSnack = useSnack();

  const [newName, setNewName] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const hiddenText = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hiddenText.current) {
      setWidth(hiddenText.current.offsetWidth);
    }
  }, [newName]);

  const handleEdit = () => {
    setNewName(name);
    setEdit(true);
  };

  const handleCancel = () => {
    setNewName("");
    setEdit(false);
  };

  const handleSubmit = async () => {
    const res: API_School | string = await editSchool(
      profileId,
      {
        name: newName,
      },
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      setRefresh({
        refresh: true,
        callback: handleCancel,
      });
    }
  };

  return (
    <Box
      sx={{
        alignSelf: "center",
        display: "flex",
        gap: "12px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        ref={hiddenText}
        sx={{
          fontSize: "1.5rem",
          fontWeight: 500,
          position: "absolute",
          opacity: 0,
          pointerEvents: "none",
        }}
      >
        {edit ? newName : name}
      </Typography>

      <TextField
        placeholder={translation.name}
        variant="standard"
        value={edit ? newName : name}
        onChange={(e) => setNewName(e.target.value)}
        InputProps={{
          readOnly: !edit,
          disableUnderline: !edit,
          sx: { fontSize: "1.5rem", fontWeight: 500 },
        }}
        inputProps={{
          style: {
            height: "auto",
            padding: 0,
            width,
            minWidth: edit ? "65px" : 0,
            maxWidth: edit ? "300px" : "none",
          },
        }}
      />

      <Action
        edit={edit}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleEdit={handleEdit}
      />
    </Box>
  );
};

export const Description = ({
  isOwner,
  setRefresh,
}: {
  isOwner: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<RefreshInterface>>;
}) => {
  const openSnack = useSnack();
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );
  const profileId = visitedProfileDetails.id.toString();
  const description = visitedProfileDetails.description;

  const [newDescription, setNewDescription] = useState<string | undefined>("");
  const [edit, setEdit] = useState<boolean>(false);

  const handleEdit = () => {
    setNewDescription(description);
    setEdit(true);
  };

  const handleCancel = () => {
    setNewDescription("");
    setEdit(false);
  };

  const handleSubmit = async () => {
    const res: API_Update_Profile | string = await updateProfile(
      profileId,
      {
        description: newDescription,
      },
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setVisitedProfileDetails({
        ...visitedProfileDetails,
        description: res.description,
      });
      openSnack(translation.updateSuccess, true);
      setRefresh({
        refresh: true,
        callback: handleCancel,
      });
    }
  };

  return (
    <Box
      sx={{
        alignSelf: "center",
        width: "100%",
        position: "relative",
      }}
    >
      <TextField
        fullWidth
        multiline
        rows={8}
        placeholder={isOwner ? translation.addDescription : ""}
        variant="outlined"
        value={edit ? newDescription : description}
        onChange={(e) => setNewDescription(e.target.value)}
        InputProps={{
          readOnly: !edit,
          sx: {
            backgroundColor: "white",
            pointerEvents: edit ? "auto" : "none",
          },
        }}
        inputProps={{
          style: {
            marginRight: "30px",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          position: "absolute",
          top: 12,
          right: 12,
          zIndex: 1,
        }}
      >
        {isOwner && (
          <Action
            edit={edit}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleEdit={handleEdit}
            vertical={true}
          />
        )}
      </Box>
    </Box>
  );
};

const UploadImage = ({
  profileId,
  isBackgroundImage,
  setRefresh,
}: {
  profileId: string;
  isBackgroundImage: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<RefreshInterface>>;
}) => {
  const openSnack = useSnack();

  const [open, setOpen] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<File | undefined>();

  const handleCancel = () => {
    setOpen(false);
    setUploadedImage(undefined);
  };

  const handleSubmit = async () => {
    const res: API_School | string = await editSchool(
      profileId,
      isBackgroundImage
        ? {
            background_image: uploadedImage,
          }
        : {
            image: uploadedImage,
          },
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      setRefresh({
        refresh: true,
        callback: handleCancel,
      });
    }
  };

  return (
    <>
      <input
        accept="image/*"
        id={isBackgroundImage ? "upload-background-image" : "upload-image"}
        type="file"
        capture="environment"
        hidden
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            setUploadedImage(event.target.files[0]);
            setOpen(true);
          }

          event.target.value = "";
        }}
      />

      {open && uploadedImage !== undefined && (
        <UploadImageModal
          open={open}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

const UploadImageModal = ({
  open,
  uploadedImage,
  setUploadedImage,
  handleSubmit,
  handleCancel,
}: {
  open: boolean;
  uploadedImage: File;
  setUploadedImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  handleSubmit: () => void;
  handleCancel: () => void;
}) => {
  const iconStyle: SxProps = {
    color: "txt.secondary",
    cursor: "pointer",
    fontSize: "2rem",
    backgroundColor: "primary.light",
    borderRadius: "50%",
    transition: "all 100ms linear",
    "&:hover": {
      backgroundColor: "primary.main",
      transition: "all 100ms linear",
    },
  };

  const [photoSource, setPhotoSource] = useState<string>("");
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const handleEditPhoto = (photo: File) => {
    const reader = new FileReader();

    reader.addEventListener("load", () =>
      setPhotoSource(reader.result?.toString() || "")
    );

    reader.readAsDataURL(photo);

    setOpenEditModal(true);
  };

  const handleCloseEditPhotoModal = () => {
    setPhotoSource("");
    setOpenEditModal(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          m: "24px",
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "800px",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              p: 4,
              borderRadius: "10px",
              overflow: "auto",
              maxHeight: "100%",
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
              }}
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              {translation.uploadPhoto}
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: "12px",
                maxHeight: "412px",
                overflow: "auto",
                mt: "12px",
                mb: "24px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  flexShrink: 0,
                  flexGrow: 1,
                  "&:hover .overlay": {
                    opacity: 1,
                  },
                }}
              >
                <Box
                  className="overlay"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    opacity: 0,
                    transition: "all 100ms linear",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1.5,
                  }}
                >
                  <EditIcon
                    sx={{ ...iconStyle, p: "6px" }}
                    onClick={() => {
                      handleEditPhoto(uploadedImage);
                    }}
                  />

                  <ClearIcon
                    sx={{ ...iconStyle, p: "4px", zIndex: 1 }}
                    onClick={handleCancel}
                  />
                </Box>

                <Box sx={{ width: "inherit", height: "inherit" }}>
                  <Image
                    src={URL.createObjectURL(uploadedImage)}
                    alt=""
                    objectFit="cover"
                  />
                </Box>
              </Box>
            </Box>

            <Button
              arrow={false}
              style={{
                marginTop: "20px",
                fontSize: "14px",
                backgroundColor: "var(--primary-main)",
                width: "100%",
                maxWidth: "175px",
                textAlign: "center",
              }}
              buttonText={translation.upload || "Upload"}
              onClick={handleSubmit}
            />
          </Box>
        </Fade>
      </Modal>

      {openEditModal && photoSource !== "" && (
        <EditPhoto
          open={openEditModal}
          handleClose={handleCloseEditPhotoModal}
          imageSrc={photoSource}
          setEditedImage={setUploadedImage}
          imageName={uploadedImage.name}
        />
      )}
    </>
  );
};

export const PreferredName = ({
  profileId,
  preferredName,
  setRefresh,
}: {
  profileId: string;
  preferredName: string;
  setRefresh: React.Dispatch<React.SetStateAction<RefreshInterface>>;
}) => {
  const { profileDetails, setProfileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
    setProfileDetails: state.setProfileDetails,
  }));

  const openSnack = useSnack();

  const [newPreferredName, setNewPreferredName] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const hiddenText = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hiddenText.current) {
      setWidth(hiddenText.current.offsetWidth);
    }
  }, [newPreferredName]);

  const handleEdit = () => {
    setNewPreferredName(preferredName);
    setEdit(true);
  };

  const handleCancel = () => {
    setNewPreferredName("");
    setEdit(false);
  };

  const handleSubmit = async () => {
    const res: any = await updateProfile(
      profileId,
      {
        preferred_name: newPreferredName,
      },
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      setProfileDetails({
        ...profileDetails,
        preferred_name: newPreferredName,
      });
      setRefresh({
        refresh: true,
        callback: handleCancel,
      });
    }
  };

  return (
    //  <Box
    //   sx={{
    //     alignSelf: "center",
    //     display: "flex",
    //     gap: "12px",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     marginTop: 0,
    //   }}
    // >
    //   <Typography
    //     ref={hiddenText}
    //     sx={{
    //       fontSize: "1.5rem",
    //       fontWeight: 500,
    //       position: "absolute",
    //       opacity: 0,
    //       pointerEvents: "none",
    //     }}
    //   >
    //     {edit ? newPreferredName : preferredName}
    //   </Typography>
    <Typography
      sx={{
        fontSize: "1.5rem",
        fontWeight: 500,
        marginTop: { xs: "8px !important", sm: "16px" },
      }}
    >
      {preferredName}
    </Typography>
    //   <TextField
    //     placeholder={translation.name}
    //     variant="standard"
    //     value={edit ? newPreferredName : preferredName}
    //     onChange={(e) => setNewPreferredName(e.target.value)}
    //     InputProps={{
    //       readOnly: !edit,
    //       disableUnderline: !edit,
    //       sx: { fontSize: "1.5rem", fontWeight: 500 },
    //     }}
    //     inputProps={{
    //       style: {
    //         height: "auto",
    //         padding: 0,
    //         width,
    //         minWidth: edit ? "65px" : 0,
    //         maxWidth: edit ? "300px" : "none",
    //       },
    //     }}
    //   />

    //    <Action
    //     edit={edit}
    //     handleSubmit={handleSubmit}
    //     handleCancel={handleCancel}
    //     handleEdit={handleEdit}
    //   />
    // </Box>
  );
};

const Action = ({
  edit,
  handleSubmit,
  handleCancel,
  handleEdit,
  vertical,
}: {
  edit: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  handleEdit: () => void;
  vertical?: boolean;
}) =>
  edit ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: vertical ? "column" : "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Tooltip title={translation.update}>
        <CheckIcon
          sx={{
            cursor: "pointer",
            color: "green",
          }}
          onClick={handleSubmit}
        />
      </Tooltip>

      <Tooltip title={translation.cancel}>
        <CloseIcon
          sx={{
            cursor: "pointer",
            color: "red",
          }}
          onClick={handleCancel}
        />
      </Tooltip>
    </Box>
  ) : (
    <Tooltip title={translation.edit}>
      <EditIcon
        sx={{ cursor: "pointer", color: "txt.secondary" }}
        onClick={handleEdit}
      />
    </Tooltip>
  );

export const PastProjectCards = ({ projects }: { projects: API_Project[] }) => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box sx={{ display: "flex", gap: 2, overflow: "auto" }}>
      {projects.map((project) => {
        return (
          <DetailCard
            project={project}
            onClick={() => {
              navigate(
                `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
                  project.slug!
                )}`
              );
            }}
            isForDisplay
          />
        );
      })}
    </Box>
  );
};

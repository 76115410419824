import { Box, Typography } from "@mui/material";
import Image from "components/Image/Image";
import { Selected, Type } from "./types";
import { API_Project } from "types/project";

type Props = {
    project: API_Project;
    type: Type;
  handleOpen: (selected: Selected) => void;
}

export const Project = ({ project, type, handleOpen }: Props) => {
  const handleClick = () => { 
    handleOpen({
      id: project.id.toString(),
      index: 0,
      type: type,
      assessment: project.assessment,
    })
  }
    
    const imagePath = project.images?.[0]?.file ?? '';

  return (
    <Box
      onClick={handleClick}
      sx={{
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: "#EBF8F8",
        display: "flex",
        gap: 2,
        p: 1,
      }}
    >
      {imagePath && <Image style={{ width: "150px" }} src={imagePath} alt="module-image" />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "neutral.900",
            fontSize: "24px",
            lineHeight: 1.33,
            fontWeight: 600,
          }}
        >
          {project.name}
        </Typography>
        <Typography
          sx={{
            color: "neutral.900",
            lineHeight: 1.5,
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: project.description ?? '',
            }}
          />
        </Typography>
      </Box>
    </Box>
  )
};

export default Project;
import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import {
  API_Mcq_Question,
  API_Mcq_Option,
} from "types/teacher";
import McqOption from "./McqOption";
import { translation } from "constants/translation";

export const iconButtonStyle = {
  color: "primary.main",
};

type Props = {
  isActivityDone: boolean;
  mcqQuestion: API_Mcq_Question;
  currMcqQuestionIndex: number;
  selectedOptions: API_Mcq_Option[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<API_Mcq_Option[]>>;
}

const McqQuestion = ({
  isActivityDone,
  mcqQuestion,
  currMcqQuestionIndex,
  selectedOptions,
  setSelectedOptions,
}: Props) => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      borderRadius: "8px",
      border: "1px solid #91C6C5",
    }}>
      <Box sx={{
        display: "flex",
        p: "12px 24px",
        backgroundColor: "#EBF8F8",
      }}>
        <Typography>
          {translation.question} {currMcqQuestionIndex + 1}
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        p: "16px 24px",
        gap: 1,
      }}>
        <Typography>
          {mcqQuestion.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // flexWrap: "wrap",
            justifyContent: "center",
            gap: 1.5,
            mb: 1,
          }}
        >
          {mcqQuestion.mcq_options.map((mcqOption, mcqOptionIndex) => {
            return (
              <Fragment
                key={`${mcqOptionIndex}-${mcqQuestion.name}-${mcqOption.name}`}
              >
                <McqOption
                  isActivityDone={isActivityDone}
                  mcqOption={mcqOption}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default McqQuestion;

import React, { useState, useEffect } from "react";

import useStore from "store/store";
import Image from "components/Image/Image";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { API_Fundamental } from "types/project";
import { getAllFundamentals } from "api/fundamental-api";
import { translation } from "constants/translation";
import { TabPanel } from "containers/ProjectPage/LessonScreens/CanvasComponents/TabPanel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ConceptScreenDrawer from "./ConceptScreenDrawer";

export const staticTabStyle = {
  textTransform: "none",
  color: `#000000 !important`,
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  border: `2px solid var(--primary-main)`,
  borderBottom: `0px`,
};

interface ProjectCardInterface {
  name: string;
  subFundamentals?: Array<object>;
  image: string;
  objectives: Array<string>;
}
const ConceptScreen = () => {
  const [allDevelopmentalGoals, setAllDevelopmentalGoals] = useState<
    API_Fundamental[]
  >([]);

  const { currentProject, currLanguage, fundamentalDict } = useStore(
    (state) => ({
      currentProject: state.currentProject,
      currLanguage: state.currLanguage,
      fundamentalDict: state.fundamentalDict,
    })
  );
  const populateFundamentals = async () => {
    const res = await getAllFundamentals(currLanguage);
    if (typeof res !== "string") {
      setAllDevelopmentalGoals(res);
    }
  };

  useEffect(() => {
    populateFundamentals();
  }, []);

  const [value, setValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [developmentIndex, setDevelopmentIndex] = useState<number>(Infinity);

  const handleOpen = () => {
    if (isDrawerOpen) {
      handleClose();
      return;
    }
    document.body.classList.add("drawer-open");
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    if (!isDrawerOpen) return;
    document.body.classList.remove("drawer-open");
    setDevelopmentIndex(Infinity);
    setIsDrawerOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isPopulateGuidingQuestion = currentProject.guiding_question !== "";

  let projectCards: any = [];

  const flattenStructure = (items: any[]): any[] => {
    const seenItems = new Set();
    return items.reduce((acc, item) => {
      const shouldAddItem = !seenItems.has(item.name) && (!item.subFundamentals || !seenItems.has(item.subFundamentals.some((sub: any) => sub.name)));

      if (shouldAddItem) {
        acc.push(item);
        seenItems.add(item.name);
        if (item.subFundamentals) {
          item.subFundamentals.forEach((sub: any) => seenItems.add(sub.name));
        }
      }

      return acc;
    }, []);
  };

  const sortByPriority = (items: any[], comparator?: (a: any, b: any) => number): any[] => {
    return items.sort(comparator || ((a: any, b: any) => a.priority - b.priority));
  };
  const heart: any = [];
  const head: any = [];
  const hands: any = [];
  let targetArray: any[];
  currentProject.objective_fundamentals.forEach((devGoal: any) => {
    const filteredMilestones = fundamentalDict[
      devGoal.fundamental
    ].milestones.filter((milestone) => milestone.id === devGoal.milestone);
    const fundamentalCard = fundamentalDict[filteredMilestones[0].fundamental]
    const objectiveFundamentalMilestone = filteredMilestones[0]
    const objectiveFundamentalMilestoneName =
      filteredMilestones.length > 0 ? filteredMilestones[0].name : "";
    if (fundamentalCard.main_fundamental == null) {

      switch (fundamentalCard.tag) {
        case 'heart':
          targetArray = heart;
          break;
        case 'head':
          targetArray = head;
          break;
        case 'hands':
          targetArray = hands;
          break;
        default:
          console.error("Unknown tag:", fundamentalCard.tag);
          return;
      }

      const projectCard = targetArray.find((project: any) => project.name == fundamentalCard?.name)

      if (!projectCard) {
        targetArray.push({
          name: fundamentalCard?.name,
          image: fundamentalCard?.image,
          objectives: {
            [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
          },
          priority: fundamentalCard?.priority,
          tag: fundamentalCard?.tag
        });
      }
      else if (
        !projectCard.objectives.hasOwnProperty(objectiveFundamentalMilestone.name)
      ) {
        projectCard.objectives[objectiveFundamentalMilestone.name] = objectiveFundamentalMilestone.weight;

      }
    } else if (fundamentalCard.main_fundamental) {

      if (fundamentalCard.main_fundamental) {
        const mainFundamentalCard = fundamentalDict[fundamentalCard.main_fundamental]
        switch (fundamentalCard.tag) {
          case 'heart':
            targetArray = heart;
            break;
          case 'head':
            targetArray = head;
            break;
          case 'hands':
            targetArray = hands;
            break;
          default:
            console.error("Unknown tag:", fundamentalCard?.tag);
            return;
        }
        const projectCard = targetArray.find((project: any) => project.name == mainFundamentalCard?.name)
        if (!projectCard) {
          targetArray.push({
            name: mainFundamentalCard?.name,
            image: mainFundamentalCard?.image,
            priority: mainFundamentalCard?.priority,
            tag: mainFundamentalCard?.tag,

            subFundamentals: [
              {
                name: devGoal.name,
                objectives: {
                  [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
                },
                priority: fundamentalDict[devGoal.fundamental].priority
              },
            ],
          });
        } else if (
          !projectCard.subFundamentals.find(
            (sub: any) => sub.name == devGoal.name
          )
        ) {
          projectCard.subFundamentals.push({
            name: devGoal.name,
            objectives: {
              [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
            },
            priority: fundamentalDict[devGoal.fundamental].priority

          });
        }
        else if (
          !projectCard.subFundamentals.find((sub: any) =>
            sub.objectives.hasOwnProperty(objectiveFundamentalMilestone.name)
          )
        ) {
          projectCard.subFundamentals
            .find((sub: any) => sub.name == devGoal.name)
            .objectives[objectiveFundamentalMilestone.name] = objectiveFundamentalMilestone.weight;
        }
      }

    }
  });
  const sortedHeart = sortByPriority(flattenStructure(heart));
  const sortedHead = sortByPriority(flattenStructure(head));
  const sortedHands = sortByPriority(flattenStructure(hands));

  projectCards = [
    ...sortedHeart.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] })),
    ...sortedHead.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] })),
    ...sortedHands.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] }))
  ].flat();
  return (
    <Box
      sx={{
        px: { xs: 1, sm: "30px" },
        pb: 1,
        // backgroundColor: 'pink'
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        <Tab
          label={translation.learningObjectives}
          sx={{
            ...staticTabStyle,
            backgroundColor: value === 0 ? "primary.light" : "transparent",
            mr: isPopulateGuidingQuestion ? 1 : 0,
          }}
        />
        {isPopulateGuidingQuestion && (
          <Tab
            label={translation.guidingQuestions}
            sx={{
              ...staticTabStyle,
              backgroundColor: value === 1 ? "primary.light" : "transparent",
            }}
          />
        )}
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        sx={{
          border: "2px solid var(--primary-main)",
          overflowY: "scroll",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        {currentProject.objective_fundamentals.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: { xs: 1.5, sm: "24px" },
            }}
          >
            {projectCards.map(
              (projectCard: ProjectCardInterface, index: number) => {
                return (
                  <>
                    <ProjectCard
                      projectCard={projectCard}
                      index={index}
                      handleOpen={handleOpen}
                      setDevelopmentIndex={setDevelopmentIndex}
                      key={index}
                    />
                  </>
                );
              }
            )}
            <ConceptScreenDrawer
              isDrawerOpen={isDrawerOpen}
              handleClose={handleClose}
              projectCards={projectCards}
              developmentIndex={developmentIndex}
              setDevelopmentIndex={setDevelopmentIndex}
            />
          </Box>
        ) : (
          <Typography sx={{ textAlign: "center" }}>
            {translation.noFundamental}
          </Typography>
        )}
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        sx={{
          border: "2px solid var(--primary-main)",
          minHeight: 150,
        }}
      >
        <div
          style={{ fontSize: 16, lineHeight: "1.5" }}
          dangerouslySetInnerHTML={{ __html: currentProject.guiding_question }}
        />
      </TabPanel>
    </Box>
  );
};

export const ProjectCard = ({
  projectCard,
  index,
  handleOpen,
  setDevelopmentIndex,
}: {
  projectCard: any;
  index: number;
  handleOpen: () => void;
  setDevelopmentIndex: any;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const developmentalGoalName = projectCard?.name;
  const imgSrc = projectCard?.image;
  const subFundamentals = projectCard?.subFundamentals;
  const objectives = projectCard?.objectives;
  let totalLines = 0;
  // if (!developmentalGoalName) {
  //   return null;
  // }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        border: "1px solid #91C6C5",
        borderRadius: "8px",
        p: isXs ? 0 : 2,
        pb: 0,
        pr: 0,
      }}
      key={index}
    >
      {/* img and developmentalGoalname */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isXs ? "column" : "row",
          alignItems: "center",
          justifyContent: isXs ? "space-between" : "flex-start",
          mb: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            width: 66,
            height: 66,
            backgroundColor: "#C4E4DF",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: isXs ? 2 : 0,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Image src={imgSrc} alt={developmentalGoalName} />
          </Box>
        </Box>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: isXs ? "14px" : "16px",
            ml: isXs ? 0 : 2,
            color: "#5C9391",
            padding: isXs ? "3px 5px" : "4px 12px",
            borderRadius: "100px",
            border: "1px solid #91C6C5",
            backgroundColor: "#EBF8F8",
            textAlign: "center",
            mx: 1,
          }}
        >
          {developmentalGoalName}
        </Typography>
      </Box>
      {/* end of image and dev name */}

      {/* if objectives or subFundamentals */}

      {/* subfundamentals */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "flex-start",
            pb: 2,
          }}
        >
          {subFundamentals &&
            subFundamentals.map(
              (subFundamental: any, index: number) => {
                totalLines++;
                return (
                  <Box
                    key={index}
                    sx={{
                      color: "#5C9391",
                      pl: isXs ? 2 : 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: isXs ? "14px" : "16px",
                        fontWeight: 450,
                      }}
                    >
                      {totalLines == 5
                        ? subFundamental.name + "..."
                        : totalLines > 5
                          ? null
                          : subFundamental.name}
                    </Typography>

                    {subFundamental.objectives && Object.keys(subFundamental.objectives).map((objective, objectiveIndex) => {
                      totalLines++;
                      return (
                        <Box sx={{ display: "flex" }} key={index}>
                          {totalLines == 5 ? (
                            <>
                              <Typography
                                sx={{
                                  fontSize: isXs ? "14px " : "16px",
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                •
                              </Typography>
                              <Typography
                                key={objectiveIndex}
                                sx={{
                                  fontSize: isXs ? "14px " : "16px",
                                  fontWeight: 400,
                                  color: "black",
                                }}
                              >
                                {objective + "..."}
                              </Typography>
                            </>
                          ) : totalLines > 5 ? null : (
                            <>
                              <Typography
                                sx={{
                                  fontSize: isXs ? "14px " : "16px",
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                •
                              </Typography>
                              <Typography
                                key={objectiveIndex}
                                sx={{
                                  fontSize: isXs ? "14px " : "16px",
                                  fontWeight: 400,
                                  color: "black",
                                }}
                              >
                                {objective}
                              </Typography>
                            </>
                          )}
                        </Box>
                      );
                    }
                    )}
                  </Box>
                );
              }
            )
          }
          {!subFundamentals || (subFundamentals?.length == 0 && null)}

          {objectives && (
            <Box>
              {Object.keys(objectives).map((objective, index) => {
                totalLines++;
                // const words = objective.split(" ");
                const words =
                  typeof objective === "string" ? objective.split(" ") : [];

                return (
                  <Box sx={{ display: "flex", pl: isXs ? 2 : 1 }} key={index}>
                    {totalLines == 5 ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: isXs ? "14px " : "16px",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          •
                        </Typography>
                        <Typography
                          key={index}
                          sx={{
                            fontSize: isXs ? "14px " : "16px",
                            fontWeight: 400,
                            color: "black",
                          }}
                        >
                          {words.length > 4
                            ? words.slice(0, 4).join(" ") + "..."
                            : objective}
                        </Typography>
                      </>
                    ) : totalLines > 5 ? null : (
                      <>
                        <Typography
                          sx={{
                            fontSize: isXs ? "14px " : "16px",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          •
                        </Typography>
                        <Typography
                          key={index}
                          sx={{
                            fontSize: isXs ? "14px " : "16px",
                            fontWeight: 400,
                            color: "black",
                          }}
                        >
                          {objective}
                        </Typography>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
          {!objectives ||
            objectives?.length == 0 ||
            (objectives[0] == "" && null)}
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={() => {
              setDevelopmentIndex(index);
              handleOpen();
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
// Change in UI to non expandable
const ExpandableBox = ({
  title,
  tags,
  category,
  expandedContent,
}: {
  title: string;
  tags: { name: string; color: string }[];
  category: string;
  expandedContent?: React.ReactElement;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Box
      sx={{
        p: "12px 42px",
        backgroundColor: "#FFF",
        border: "1px solid #F0F0F0",
        borderRadius: "10px",
        width: "100%",
        position: "relative",
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={() => setExpanded((prev) => !prev)}
    >
      <KeyboardArrowDownIcon
        sx={{
          position: "absolute",
          top: "14px",
          left: "12px",
          transform: `${expanded ? "rotate(180deg)" : "rotate(0deg)"}`,
          transition: "all 200ms ease-in-out",
        }}
      />
      <Content title={title} tags={tags} category={category} />
      <ExpandedContent expandedContent={expandedContent} expanded={expanded} />
    </Box>
  );
};

const ExpandedContent = ({
  expandedContent,
  expanded,
}: {
  expandedContent?: React.ReactElement;
  expanded: boolean;
}) => {
  return (
    <Box
      sx={{
        width: "inherit",
        height: `${expanded ? "100px" : "0px"}`,
        transition: "all 200ms ease-in-out",
        m: "10px 0",
      }}
    >
      {expandedContent}
    </Box>
  );
};

const Content = ({
  title,
  tags,
  category,
}: {
  title: string;
  tags: { name: string; color: string }[];
  category: string;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      <Typography sx={{ color: "#6D6D6D", fontSize: "8px" }}>
        {category.charAt(0).toUpperCase() + category.slice(1)}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
        {tags.map((tag: any) => {
          return (
            <Box
              sx={{
                backgroundColor: tag.color,
                p: "6px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
              key={tag.name}
            >
              {tag.name}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const NoneExpandableBox = ({
  title,
  tags,
  category,
}: {
  title: string;
  tags: { name: string; color: string }[];
  category: string;
}) => {
  return (
    <Box
      sx={{
        p: "12px 42px",
        backgroundColor: "#FFF",
        border: "1px solid #F0F0F0",
        borderRadius: "10px",
        width: "100%",
        position: "relative",
        userSelect: "none",
      }}
    >
      <Content title={title} tags={tags} category={category} />
    </Box>
  );
};

export default ConceptScreen;

import { Outlet, useParams } from "react-router-dom";

import {
  Box,
  Container,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  Popover,
} from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import useStore from "store/store";

import Image from "components/Image/Image";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import "./LessonActivityPage.css"
// import "./LessonScreens/flipBook.css"
import CloseIcon from "@mui/icons-material/Close";

import { translation } from "constants/translation";
import { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { CSSTransition } from "react-transition-group";
import LessonToolboxContent from "./LessonToolboxContentComponents/LessonToolboxContent";
import LessonToolboxImage from "images/lesson-toolbox.png";
import {
  buttonTextFontSize,
  buttonTextMobileFontSize,
  contentMobileFontSize,
  titleFontSize,
  titleMobileFontSize,
} from "constants/constantInGeneral";
import { contentFontSize } from "./LessonScreens/learningMomentConstant"
import LessonPlanPDFButton from "components/LessonPlanPDF/LessonPlanPDFButton";
import LessonMenuSelection from "./LessonMenuSelection"
import { API_Project_Lesson } from "types/project";
import { languageOptions } from "types/language";
import { getLessonByID } from "api/project-api";

type Props = {
  assessment?: boolean;
};

const LessonActivityPage = ({ assessment = false }: Props) => {
  const { lessonID, assessmentID, activityID } = useParams();
  const theme = useTheme();
  const {
    isProjectLoading,
    projectID,
    projImage,
    currentLessons,
    isFullscreen,

    setCurrLesson,
  } = useStore((state) => ({
    isProjectLoading: state.isProjectLoading,
    projectID: state.currentLessons.id,
    projImage: state.currentProject
      ? state.currentProject.images.length > 0
        ? state.currentProject.images[0].file
        : null
      : null,
    currentLessons: state.currentLessons.lessons,
    isFullscreen: state.isFullscreen,
    setCurrLesson: state.setCurrLesson,
  }));

  // Local state to manage current lesson
  const [currentLesson, setCurrentLesson] = useState<API_Project_Lesson | null>(
    null
  );

  const entityId = assessment ? assessmentID : lessonID;
  const currentLessonIndex = currentLessons.findIndex(
    (lesson) => lesson.id.toString() === entityId
  );

  const haveLessonImage =
    currentLessons[currentLessonIndex] &&
    currentLessons[currentLessonIndex].image !== null;

  // const currentLesson = currentLessons[currentLessonIndex];

  // Drawer
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Text animating
  const [showText, setShowText] = useState<boolean>(false);
  useEffect(() => {
    // Show the text when the component is first mounted
    setShowText(true);
  }, []);

  // PDF Menu
  const [pdfMenuAnchorEl, setPdfMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedLessons, setSelectedLessons] = useState<API_Project_Lesson[]>(
    []
  );

  const handleClosePdfMenu = () => {
    setPdfMenuAnchorEl(null);
  };

  const handleDownloadPDF = (event: React.MouseEvent<HTMLElement>) => {
    setPdfMenuAnchorEl(event.currentTarget);
  };

  const handleSubmit = () => {
    // console.log("test");
  };

  // Define supported languages
  const supportedLanguages = Object.keys(languageOptions).map((lang) => ({
    language: lang as keyof typeof languageOptions,
    name: languageOptions[lang as keyof typeof languageOptions],
  }));

  // State to store the selected language
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    supportedLanguages[0].language // Set the default language
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!entityId || !selectedLanguage) {
        // entityId or selectedLanguage is undefined, exit early
        return;
      }

      try {
        // Fetch the lesson with the new language
        const newLesson = await getLessonByID(selectedLanguage, entityId);

        if (newLesson !== null && typeof newLesson !== "string") {
          // Update local state
          setCurrentLesson(newLesson);

          // Use the setCurrentLesson method from your store
          setCurrLesson(newLesson);
        }
      } catch (error) {
        console.error("Error fetching lesson:", error);
        // Handle error appropriately
      }
    };

    fetchData(); // Invoke the function

    // Include selectedLanguage in the dependency array if it's needed
  }, [entityId, selectedLanguage, setCurrLesson]);

  const handleLanguageChange = async (event: any) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
  };

  const isBelowLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      {projectID !== -1 ? (
        currentLessonIndex !== -1 ? (
          <>
            {isProjectLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LoadingIndicator />
              </Box>
            ) : (
              <main
                id="lesson-activity-page-main-content"
                style={{
                  flex: "1 1 0",
                  minWidth: 0,
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "hidden",
                  marginTop: isBelowLg ? 16 : 0,
                }}
              >
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#FBFBFB",
                    borderRadius: "16px",
                    position: "relative",
                    zIndex: 0,
                    overflow: "hidden",
                    flex: 1,
                    paddingLeft: { xs: "4px", sm: "16px" },
                    paddingRight: { xs: "4px", sm: "16px" },
                    py: { xs: 0.5, sm: 1 },
                  }}
                  maxWidth={false}
                >
                  {activityID !== undefined &&
                    (haveLessonImage || projImage) && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          zIndex: -1,
                          opacity: "0.1",
                          m: "0",
                        }}
                      >
                        <Image
                          alt="background"
                          src={
                            haveLessonImage
                              ? currentLessons[currentLessonIndex].image
                              : projImage
                              ? projImage
                              : ""
                          }
                          objectFit="cover"
                        />
                      </Box>
                    )}

                  {/* <NextPrevIcon /> */}
                  <Box>
                    <Box
                      key={`${isFullscreen}-container-title`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        ml: isFullscreen ? { xs: 0, sm: 6 } : 0,
                        mr: isFullscreen ? { xs: 0, sm: 6 } : 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1.25,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            maxHeight: "200px",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: {
                                xs: titleMobileFontSize,
                                sm: titleFontSize,
                              },
                              fontWeight: 600,
                            }}
                          >
                            {currentLesson &&
                              `${translation.formatString(
                                assessment
                                  ? translation.assessmentPlanNo
                                  : translation.lessonNo,
                                {
                                  number: currentLessonIndex + 1,
                                }
                              )}: ${currentLesson?.name}`}
                          </Typography>
                          <LessonPlanPDFButton onClick={handleDownloadPDF} />
                          <Popover
                            open={Boolean(pdfMenuAnchorEl)}
                            anchorEl={pdfMenuAnchorEl}
                            onClose={handleClosePdfMenu}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            {currentLesson && (
                              <LessonMenuSelection
                                lessons={currentLessons}
                                selectedLessons={selectedLessons}
                                setSelectedLessons={setSelectedLessons}
                                onSubmit={handleSubmit}
                                currentLesson={currentLesson}
                              />
                            )}
                          </Popover>
                        </Box>
                        {currentLesson && assessment ? (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: currentLesson.description,
                            }}
                          />
                        ) : (
                          <Typography>
                            {currentLesson && `${currentLesson.description}`}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mr: 2,
                          }}
                        >
                          <AccessTimeIcon />
                          {currentLesson && (
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: buttonTextMobileFontSize,
                                  sm: buttonTextFontSize,
                                },
                              }}
                            >
                              {`${
                                currentLesson.duration
                                  ? currentLesson.duration
                                  : 0
                              } ${translation.min}`}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                          onClick={handleOpen}
                        >
                          <Typography>
                            {" "}
                            {translation.translateThisLessonTo}
                          </Typography>
                          <Select
                            value={selectedLanguage}
                            onChange={handleLanguageChange}
                            sx={{ ml: 2 }}
                          >
                            {supportedLanguages.map((lang) => (
                              <MenuItem
                                key={lang.language}
                                value={lang.language}
                              >
                                {lang.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <Image
                            src={LessonToolboxImage}
                            alt="lesson-toolbox"
                            style={{
                              width: 40,
                              height: 40,
                              marginTop: "-5px",
                            }}
                          />

                          <CSSTransition
                            in={showText}
                            timeout={500}
                            classNames="animated-text"
                            unmountOnExit
                          >
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: contentMobileFontSize,
                                  sm: contentFontSize,
                                },
                                fontWeight: 500,
                                color: theme.palette.custom.grey,
                              }}
                            >
                              {translation.lessonToolbox}
                            </Typography>
                          </CSSTransition>
                          <KeyboardArrowLeftIcon sx={{ mr: "-10px" }} />
                        </Box>
                      </Box>

                      {/* Lesson toolbox section start */}
                      <Drawer
                        disablePortal={true}
                        anchor="right"
                        open={open}
                        onClose={handleClose}
                        sx={{
                          "& .MuiDrawer-paper": {
                            minWidth: 200,
                            backgroundColor: "#F8F8F8",
                            p: 2,
                            pb: isFullscreen
                              ? "0px"
                              : {
                                  xs: `${56 + 64}px`,
                                  sm: `${64 + 64}px`,
                                  lg: `64px`,
                                },
                            mt: isFullscreen
                              ? "0px"
                              : {
                                  xs: `${56 + 64}px`,
                                  sm: `${64 + 64}px`,
                                  lg: `64px`,
                                },
                            // marginRight: isFullscreen ? "0px" : "10px",
                          },
                        }}
                      >
                        <CloseIcon
                          sx={{
                            cursor: "pointer",
                            borderRadius: "4px",
                            ml: "auto",
                            transition: "all 200ms ease-in-out",
                            color: "#444",
                            "&:hover": {
                              backgroundColor: "rgba(0,0,0,0.05)",
                              transition: "all 200ms ease-in-out",
                            },
                          }}
                          onClick={handleClose}
                        />
                        <Box sx={{ m: 2, maxWidth: 300 }}>
                          {currentLesson && (
                            <LessonToolboxContent
                              currentLesson={currentLesson}
                            />
                          )}
                        </Box>
                      </Drawer>
                      {/* Lesson toolbox section end */}

                      {/* Lesson Intetion Row */}
                      {/* <LessonIntentionRow currentLesson={currentLesson} /> */}
                    </Box>
                  </Box>

                  <Outlet />
                </Container>
              </main>
            )}
          </>
        ) : // <NotFoundPage />
        null
      ) : null}
    </>
  );
};

export default LessonActivityPage;

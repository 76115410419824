import axios from "api/axiosConfig";
import { AxiosError } from "axios";

import {
  API_ModuleInterest_Response,
  API_GroupInterest_Response,
  API_FundamentalFocus_Response,
  API_Interest,
  API_Create_Interest,
  API_Question,
  API_Create_Question,
  API_Answer,
  API_Create_Answer,
  API_Language,
} from "types/prediscoveryquestionnaire";

// Interest CRUD
export const getInterestList = async () => {
  const res = await axios.get("en/profile/interest/list");
  const data: API_Interest[] = await res.data;
  return data;
};

export const createInterest = async (interestData: API_Create_Interest) => {
  const body = {
    name: interestData.name,
  };

  if (typeof interestData.image !== "string" || interestData.image === null) {
    Object.assign(body, { image: interestData.image });
  }

  try {
    const res = await axios.post("en/profile/interest/create", body, {
      headers: { "content-type": "multipart/form-data" },
    });
    const data: API_Create_Interest = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateInterest = async (interestData: API_Interest) => {
  const body = {
    name: interestData.name,
  };

  if (typeof interestData.image !== "string" || interestData.image === null) {
    Object.assign(body, { image: interestData.image });
  }

  try {
    const res = await axios.patch(
      `en/profile/interest/${interestData.id}/update`,
      body,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: API_Interest = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteInterest = async (interestId: number) => {
  try {
    const res = await axios.delete(`en/profile/interest/${interestId}/delete`);
    const data: API_Interest = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Language

export const getLanguageList = async () => {
  const res = await axios.get("en/pre-discovery-questionnaire/language/list");
  const data: API_Language[] = await res.data;
  return data;
};

// Question CRUD

export const getQuestionList = async () => {
  const res = await axios.get("en/pre-discovery-questionnaire/question/list");
  const data: API_Question[] = await res.data;
  return data;
};

export const createQuestion = async (questionData: API_Create_Question) => {
  const body = {
    name: questionData.name,
  };

  // if (typeof interestData.image !== "string" || interestData.image === null) {
  //   Object.assign(body, { image: interestData.image });
  // }

  try {
    const res = await axios.post(
      "en/pre-discovery-questionnaire/question/create",
      body
      // {
      //   headers: { "content-type": "multipart/form-data" },
      // }
    );
    const data: API_Create_Question = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateQuestion = async (questionData: API_Question) => {
  const body = {
    name: questionData.name,
    weight: questionData.weight,
  };

  try {
    const res = await axios.patch(
      `en/pre-discovery-questionnaire/question/${questionData.id}/update`,
      body
    );
    const data: API_Question = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteQuestion = async (questionId: number) => {
  try {
    const res = await axios.delete(
      `en/pre-discovery-questionnaire/question/${questionId}/delete`
    );
    const data: API_Question = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// Answer CRUD
export const getAnswerList = async (questionId: number) => {
  if (questionId === -1) {
    const res = await axios.get("en/pre-discovery-questionnaire/answer/list");
    const data: API_Answer[] = await res.data;
    return data;
  }

  const res = await axios.get("en/pre-discovery-questionnaire/answer/list", {
    params: { question: questionId },
  });
  const data: API_Answer[] = await res.data;
  return data;
};

export const createAnswer = async (answerData: API_Create_Answer) => {
  const body = {
    question: answerData.question,
    name: answerData.name,
  };

  // if (typeof interestData.image !== "string" || interestData.image === null) {
  //   Object.assign(body, { image: interestData.image });
  // }

  try {
    const res = await axios.post(
      "en/pre-discovery-questionnaire/answer/create",
      body
      // {
      //   headers: { "content-type": "multipart/form-data" },
      // }
    );
    const data: API_Answer = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateAnswer = async (answerData: API_Answer) => {
  const body = {
    question: answerData.question,
    name: answerData.name,
    weight: answerData.weight,
  };

  try {
    const res = await axios.patch(
      `en/pre-discovery-questionnaire/answer/${answerData.id}/update`,
      body
    );
    const data: API_Answer = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteAnswer = async (answerId: number) => {
  try {
    const res = await axios.delete(
      `en/pre-discovery-questionnaire/answer/${answerId}/delete`
    );
    const data: API_Answer = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Module Interest
export const createModuleInterest = async ({
  profileID,
  moduleList,
}: {
  profileID: number;
  moduleList: number[];
}) => {
  const body = {
    profileId: profileID,
    moduleList: moduleList,
  };

  try {
    const res = await axios.post(`/en/profile/moduleinterest/create`, body);
    const data: API_ModuleInterest_Response[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//update group stuff
export const updateGroupInterest = async ({
  groupId,
  interestList,
}: {
  groupId: number;
  interestList: number[];
}) => {
  const body = {
    groupId: groupId,
    interestList: interestList,
  };
  try {
    const res = await axios.patch(
      `/en/profile/group/${groupId}/updategroupinterest`,
      body
    );
    const data: API_GroupInterest_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateGroupFundamental = async ({
  groupId,
  fundamentalList,
}: {
  groupId: number;
  fundamentalList: number[];
}) => {
  const body = {
    groupId: groupId,
    fundamentalList: fundamentalList,
  };
  try {
    const res = await axios.patch(
      `/en/profile/group/${groupId}/updategroupfundamental`,
      body
    );
    const data: API_FundamentalFocus_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateGroupAnswer = async ({
  groupId,
  answerList,
}: {
  groupId: number;
  answerList: number[];
}) => {
  const body = {
    groupId: groupId,
    answerList: answerList,
  };
  try {
    const res = await axios.patch(
      `/en/profile/group/${groupId}/updategroupanswer`,
      body
    );
    const data: API_FundamentalFocus_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//firstLanguageList: true, secondLanguageList
//respondInstructionLevel, socialAwareLevel
export const updateGroupLanguage = async ({
  groupId,
  languageList,
  isFirstLanguage,
}: {
  groupId: number;
  languageList: number[];
  isFirstLanguage: boolean;
}) => {
  let body;
  if (isFirstLanguage) {
    body = {
      groupId: groupId,
      firstLanguageList: languageList,
    };
  } else {
    body = {
      groupId: groupId,
      secondLanguageList: languageList,
    };
  }

  try {
    const res = await axios.patch(
      `/en/profile/group/${groupId}/updategrouplanguage`,
      body
    );
    const data: API_Language = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// No use
export const updateGroupBehavior = async ({
  groupId,
  level,
  isRespondInstruction,
}: {
  groupId: number;
  level: string; //Sproutlings ...
  isRespondInstruction: boolean;
}) => {
  let body;

  if (isRespondInstruction) {
    body = {
      groupId: groupId,
      respondInstructionLevel: level,
    };
  } else {
    body = {
      groupId: groupId,
      socialAwareLevel: level,
    };
  }

  try {
    const res = await axios.patch(
      `/en/profile/group/${groupId}/updategroupbehavior`,
      body
    );
    const data: string = await res.data;
    return "Success!";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateGroupLevel = async ({
  groupId,
  level,
}: {
  groupId: number;
  level: string;
}) => {
  try {
    const res = await axios.patch(
      `/en/profile/group/${groupId}/updategroupbehavior`,
      {
        groupId: groupId,
        communication_lvl: level,
      }
    );
    const data: string = await res.data;
    return "Success!";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

import { useNavigate } from "react-router-dom";
import { Avatar, Box, Typography } from "@mui/material";
import { translation } from "constants/translation";
import { API_Child } from "types/profile";
import Logo from "images/logo-2-colour.png";
import { PROFILE } from "constants/url";
import Button from "components/Button/Button";

const ChildCard = ({ child }: { child: API_Child }) => {
  const navigate = useNavigate();

  const handleClick = () => navigate(`${child.id}/${PROFILE}`);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 320,
        borderRadius: "10px",
        backgroundColor: "#F0F0F0",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        p: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Avatar
        src={child.image ? child.image : Logo}
        sx={{
          width: 160,
          height: 160,
          mx: "auto",
          backgroundColor: "primary.main",
          mb: 3,
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      <Typography
        sx={{
          fontWeight: 500,
          textAlign: "center",
          fontSize: "1.25rem",
          lineHeight: "1.25",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: 2,
          lineClamp: 2,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
        }}
      >
        {child.preferred_name}
      </Typography>

      {child.groups !== undefined && child.groups.length > 0 && (
        <Typography
          sx={{
            color: "#312F30",
            textAlign: "center",
            mt: 1,
          }}
        >
          {child.groups.map((group) => group.name).join(", ")}
        </Typography>
      )}

      <Button
        buttonText={translation.viewPortfolio || "View Portfolio"}
        onClick={handleClick}
        arrow={false}
        style={{
          marginTop: "24px",
          width: "100%",
        }}
      />
    </Box>
  );
};

export default ChildCard;

import React from "react";

import useStore from "store/store";

import { Alert, Snackbar } from "@mui/material";

const Toast = () => {
  const {
    snackOpen,
    setSnackOpen,
    snackSuccess,
    setSnackSuccess,
    snackMessage,
  } = useStore((state) => ({
    snackOpen: state.snackOpen,
    setSnackOpen: state.setSnackOpen,
    snackSuccess: state.snackSuccess,
    setSnackSuccess: state.setSnackSuccess,
    snackMessage: state.snackMessage,
  }));

  const handleSnackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
    setSnackSuccess(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackOpen}
      autoHideDuration={3000}
      onClose={handleSnackClose}
    >
      <Alert
        onClose={handleSnackClose}
        severity={snackSuccess ? "success" : "error"}
        sx={{
          width: "100%",
          maxHeight: "100px",
          maxWidth: "95vw",
          overflow: "hidden",
        }}
      >
        {snackMessage}
      </Alert>
    </Snackbar>
  );
};

export default Toast;

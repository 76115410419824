import { Box, Chip, Typography } from "@mui/material";
import Button from "components/Button/Button";
import { Fragment} from "react";
import {
  createCheckoutSession,
} from "api/payment-api";
import useSnack from "hooks/useSnack";
import { PaymentProduct } from "types/payment";
import Logo from "images/logo-2-colour.png";
import Image from "components/Image/Image";
import useStore from "store/store";
import { translation } from "constants/translation";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  convertCentsToString,
  convertCurrencyStrToSymbol,
  convertDateToUnixTimestamp,
  convertToUpperCase,
  convertUnixTimestampToStrDate,
} from "./constants/helper";
import "./constants/payment.css";
import { Stack } from "@mui/system";

const PaymentProductComponent = ({
  product,
  isEligibleToTrial,
  isSubscribed,
  haveSubscribed,
  isLoading,
  handleCustomerPortal,
}: {
  product: PaymentProduct;
  isEligibleToTrial: boolean | undefined;
  isSubscribed: boolean;
  haveSubscribed: boolean;
  isLoading: boolean;
  handleCustomerPortal: () => Promise<void>;
}) => {
  const openSnack = useSnack();
  const { currLanguage, profileDetails, customerInfo, numberOfActiveUser } =
    useStore((state) => ({
      currLanguage: state.currLanguage,
      profileDetails: state.profileDetails,
      customerInfo: state.customerInfo,
      numberOfActiveUser: state.numberOfActiveUser,
    }));

  const handleSubmit = async (
    price_id: string,
    tiers_mode: string | undefined,
    canTrial: boolean
  ) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // console.log("Today: ", today);
    const trialEnd = canTrial
      ? isEligibleToTrial
        ? convertDateToUnixTimestamp(today, 7 + 1)
        : 0
      : 0;
    const res = await createCheckoutSession({
      price_id: price_id,
      customer: customerInfo.customer.id || "",
      customerEmail: customerInfo.customer.id ? "" : profileDetails.email,
      successUrl: `${window.location.href.split("?")[0]}`,
      cancelUrl: `${window.location.href.split("?")[0]}`,
      trialEnd: trialEnd,
      metadata: {
        school: profileDetails.school ? profileDetails.school.id : -1,
        profile: profileDetails.id,
        number_of_active_user: numberOfActiveUser,
      },
      tiersMode: tiers_mode ? tiers_mode : "",
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      // console.log("res: ", res);
      window.location.href = res.url;
    }
  };

  const { subscription, upcoming_invoice } = customerInfo;
  const buttonText = haveSubscribed
    ? translation.manageOrUpdatePlan
      ? translation.manageOrUpdatePlan
      : "Manage or update plan"
    : isEligibleToTrial
    ? translation.startFreeTrial
      ? translation.startFreeTrial
      : "Start free 7-day trial"
    : translation.selectPlan
    ? translation.selectPlan
    : "Select plan";

  // console.log("product:", product);
  // if (product.metadata.feature_list) {
  //   console.log(
  //     "product.metadata.feature_list: ",
  //     product.metadata.feature_list.split(",")
  //   );
  // }
  // console.log("isEligibleToTrial: ", isEligibleToTrial);
  // console.log("customerInfo", customerInfo);

  const highestEducatorCount = customerInfo.upcoming_invoice.lines?.data.reduce(
    (accumulator, data_) => {
      return accumulator + data_.quantity;
    },
    0
  );
  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Box
          sx={{
            width: "26vw",
            height: "550px",
            backgroundColor: "primary.light",
            clipPath: "polygon(50% 0%, 100% 20%, 100% 100%, 0% 100%, 0% 20%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                paddingY: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
                textAlign: "center",
                marginTop: 4,
                height: "550px",
              }}
            >
              <Box>
                <Image
                  style={{
                    maxHeight: "80px",
                    maxWidth: "120px",
                    width: "100%",
                  }}
                  src={product.images[0] ? product.images[0] : Logo}
                  alt=""
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: 700 }}>
                    {`${convertCurrencyStrToSymbol(
                      product.default_price.currency
                    )}${convertCentsToString(
                      product.default_price.unit_amount
                        ? product.default_price.unit_amount
                        : product.default_price?.tiers
                        ? product.default_price?.tiers[0].flat_amount
                        : -1
                    )}`}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {`/${product.default_price.recurring.interval}`}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {isSubscribed ? (
                  <Stack direction="row" spacing={1}>
                    <Chip
                      size="small"
                      label={`${translation.subscribed}`}
                      sx={{
                        height: 24,
                        color: "primary",
                      }}
                      color="success"
                    />
                    <Chip
                      size="small"
                      label={`${convertToUpperCase(subscription.status)}`}
                      sx={{
                        height: 24,
                      }}
                      color={
                        subscription.status === "active"
                          ? `success`
                          : subscription.status === "canceled"
                          ? `error`
                          : "default"
                      }
                    />
                    {subscription.cancel_at_period_end ? (
                      <Chip
                        size="small"
                        label={`${translation.noRenew}`}
                        sx={{
                          height: 24,
                        }}
                        color="error"
                      />
                    ) : null}
                  </Stack>
                ) : null}
              </Box>

              <Box
                sx={{
                  justifyContent: "center",
                }}
              >
                <Box
                  className="paymentProductList"
                  sx={{ marginTop: 1, height: 170 }}
                >
                  <ul>
                    {product.metadata.feature_list &&
                      product.metadata.feature_list
                        .split(",")
                        .map((feature: string) => {
                          return (
                            <Fragment key={feature}>
                              <li>{feature}</li>
                            </Fragment>
                          );
                        })}
                  </ul>
                </Box>
              </Box>
              <Box>{`Active Educator Count: ${numberOfActiveUser}`}</Box>
              <Box>
                {`Highest Educator Count: ${highestEducatorCount || -1}`}
              </Box>
              {/* max(0, #-10) */}
              <Box>{`Chargable Educator Count: ${Math.max(
                0,
                (highestEducatorCount || -1) - 10
              )}`}</Box>
              {isSubscribed ? (
                <>
                  <Box sx={{ marginTop: 1 }}>
                    <Box>{/* <h3>{product.name}</h3> */}</Box>

                    <Box>
                      {/* {convertUnixTimestampToStrDate(
                    subscription.current_period_start
                  )}{" "} */}

                      {`${convertUnixTimestampToStrDate(
                        subscription.current_period_start
                      )} - ${convertUnixTimestampToStrDate(
                        subscription.current_period_end
                      )}`}
                    </Box>
                    {/* <Box>
                  Canceled: {subscription.cancel_at_period_end.toString()}
                </Box> */}
                    {upcoming_invoice.currency ? (
                      <Box>
                        {`Upcoming bills: ${convertCurrencyStrToSymbol(
                          upcoming_invoice.currency
                        )}${convertCentsToString(upcoming_invoice.total)}`}
                        {/* {`Upcoming bills: ${convertCurrencyStrToSymbol(
                    upcoming_invoice.currency
                  )}${convertCentsToString(
                    upcoming_invoice.total
                  )}-${convertUnixTimestampToStrDate(
                    upcoming_invoice.next_payment_attempt
                  )}-${upcoming_invoice.collection_method}`} */}
                      </Box>
                    ) : (
                      <Box>{`Upcoming bills: N/A`}</Box>
                    )}
                  </Box>
                </>
              ) : null}
              <Box sx={{ marginTop: 2, width: "100%" }}>
                <Button
                  buttonText={buttonText}
                  style={{
                    fontSize: "16px",
                    backgroundColor: "var(--primary-main)",
                    height: "auto",
                    width: "100%",
                  }}
                  onClick={() => {
                    haveSubscribed
                      ? handleCustomerPortal()
                      : handleSubmit(
                          product.default_price.id,
                          product.default_price.tiers_mode,
                          true
                        );
                  }}
                  arrow={false}
                  disabled={profileDetails.role === "WAD"}
                />

                {/* <MuiButton
            fullWidth
            onClick={() => {
              handleSubmit(product.default_price.id);
            }}
            sx={{
              border: "1px solid #98C4C5",
              hover: {
                color: "#FFFFFF",
                backgroundColor: "#98C4C5",
              },
              fontSize: 15,
            }}
          >
            Start your free trial
          </MuiButton> */}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  {!haveSubscribed && isEligibleToTrial && (
                    <>
                      <Typography variant="body1">{`or`}</Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          marginLeft: 1,
                          fontWeight: 600,
                          cursor: "pointer",
                          "&:hover": { textDecoration: "underline" },
                        }}
                        onClick={() => {
                          if (profileDetails.role !== "WAD") {
                            handleSubmit(
                              product.default_price.id,
                              product.default_price.tiers_mode,
                              false
                            );
                          } else {
                            openSnack(
                              "Please login as a School Admin to proceed.",
                              false
                            );
                          }
                        }}
                      >
                        {`Buy Now`}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default PaymentProductComponent;

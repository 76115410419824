import { AxiosError } from "axios";
import {
  API_Chat,
  API_Chat_Create,
  API_Chat_Participant,
  API_Message,
  API_Message_Pagination,
  API_Unseen_Message_Count,
} from "types/chat";
import { API_Profile_Details } from "types/profile";
import axios from "api/axiosConfig";

export const getAllChat = async (accessToken: string | null) => {
  const res = await axios.get("/en/chat/list", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Chat[] = await res.data;
  return data;
};

export const getMessage = async (chatID: string, page: number) => {
  try {
    const res = await axios.get(
      `/en/chat/message/${chatID}?page=${page}&page_size=10`
    );
    const data: API_Message_Pagination = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getChat = async (chatID: string) => {
  const res = await axios.get(`/en/chat/${chatID}`);
  const data: API_Chat = await res.data;
  return data;
};

export const getUnseenChatCount = async () => {
  const accessToken = localStorage.getItem("access");

  if (!accessToken) {
    return { unseen_chat_count: 0 };
  }

  const res = await axios.get(`/en/chat/get-unseen-chat-count`);
  const data: API_Unseen_Message_Count = await res.data;
  return data;
};

export const getUnseenChat = async () => {
  const res = await axios.get(`/en/chat/get-unseen-chat`);
  const data: API_Message[] = await res.data;
  return data;
};

export const getAllContacts = async (accessToken: string | null) => {
  const res = await axios.get("/en/chat/contact", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Profile_Details[] = await res.data;
  return data;
};

export const createChat = async (
  contactId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      "/en/chat/create",
      {
        profile: [contactId], //Array here, backend can add in requested user profile
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Chat = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createGroupChat = async (
  chatData: API_Chat_Create,
  accessToken: string | null
) => {
  try {
    const res = await axios.post("/en/chat/group/create", chatData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Chat = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteChat = async (chatID: number | string) => {
  try {
    const res = await axios.delete(`/en/chat/${chatID}/delete`);
    return res;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLastSeenMessage = async ({
  chatID,
  profileID,
}: {
  chatID: string;
  profileID: string;
}) => {
  try {
    const body = {
      chat: chatID,
      profile: profileID,
    };
    const res = await axios.post("/en/chat/seen", body);
    const data: API_Chat_Participant = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

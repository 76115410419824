import React, { useState } from "react";
import { Box, Drawer } from "@mui/material";
import { Selected } from "./types";
import ProjectForm from "./ProjectForm";
import Backdrop from "components/Backdrop";
import { Resizer } from "components/Resizer";

type Props = {
  selected: Selected | null;
  openDrawer: boolean;
  handleClose: () => void;
};

const ResizableDrawer = ({
  selected,
  openDrawer,
  handleClose,
}: Props) => {
  const defaultDrawerWidth = localStorage.getItem("drawerWidth")
    ? localStorage.getItem("drawerWidth")!
    : "65vw";

  const [drawerWidth, setDrawerWidth] = useState<string>(defaultDrawerWidth);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="right"
        open={openDrawer}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: drawerWidth,
            minWidth: "600px",
            maxWidth: "90vw",
          },
          sx: {
            boxShadow: 3,
          },
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {selected && (
          <Box
            sx={{
              height: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              p: 3,
              gap: 3,
            }}
          >
            <Resizer setDrawerWidth={setDrawerWidth} />
            <ProjectForm selected={selected} handleClose={handleClose} />
          </Box>
        )}
      </Drawer>
      <Backdrop isHovering={openDrawer && isHovering} />
    </>
  );
};

export default ResizableDrawer;

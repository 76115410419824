import { useState, useEffect } from "react";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  getProjectByProjectID,
  getProjectLessons,
} from "api/project-api";
import useStore from "store/store";
import { API_Project, API_Project_Lesson } from "types/project";
import {
  CustomModal,
  ScheduleProjectButton,
  ViewProjectButton,
  BackpackButton,
} from "./components/CommonComponents";

import ProjectView from "./components/ProjectView";
import { Box } from "@mui/material";

type ProjectModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: number;
  setProjectId: React.Dispatch<React.SetStateAction<number>>;
  isParentView?: boolean;
};

const ProjectModal = ({
  open,
  setOpen,
  projectId,
  setProjectId,
  isParentView = false,
}: ProjectModalProps) => {
  const [project, setProject] = useState<API_Project>();
  const [lessons, setLessons] = useState<API_Project_Lesson[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { currLanguage } = useStore((state) => state);

  useEffect(() => {
    if (projectId === -1) return;

    const initializeProjectAndLessons = async () => {
      const project = await getProjectByProjectID(
        currLanguage,
        projectId.toString(),
        localStorage.getItem("access")
      );

      if (typeof project !== "string") {
        setProject(project);
      }

      const projectLessons = await getProjectLessons(
        currLanguage,
        projectId.toString()
      );

      if (typeof projectLessons !== "string") {
        setLessons(projectLessons.lessons);
      }

      setIsLoading(false);
    };

    initializeProjectAndLessons();
  }, [projectId, currLanguage]);

  const handleClose = () => {
    setOpen(false);
    setProjectId(-1);
  };

  return (
    <CustomModal open={open} handleClose={handleClose}>
      {!isLoading && project ? (
        <>
          <ProjectView
            project={project}
            lessons={lessons}
            isParentView={isParentView}
          />
          <Box
            sx={{
              mt: 2,
            }}
          >
            {!isParentView && <ActionButton project={project} />}
          </Box>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </CustomModal>
  );
};

export const ActionButton = ({ project }: { project: API_Project }) => {
  const { currGroup, currGroupBackpack, groupProjectBoxProps, profileDetails } = useStore((state) => state);

  const isInBag =
    currGroupBackpack.projects.filter(
      (backpackProject) => backpackProject.id === project.id
    ).length !== 0;

  const isInSchedule =
    groupProjectBoxProps[currGroup.id] &&
    groupProjectBoxProps[currGroup.id].filter(
      (_project) => _project.projectId === project.id
    ).length !== 0;

  return profileDetails.role === "CE" ? (
    <ViewProjectButton slug={project.slug} assessment={project.assessment} />
  ) : isInBag ? (
    isInSchedule ? (
      <ViewProjectButton slug={project.slug} assessment={project.assessment} />
    ) : (
      <ScheduleProjectButton project={project} />
    )
  ) : (
    <BackpackButton groupID={currGroup.id} projectID={project.id} />
  );
};

export default ProjectModal;

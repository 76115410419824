import { Avatar, Box, Chip, Typography } from "@mui/material";
import useStore from "store/store";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { API_Milestone, Fundamental_Milestone } from "types/project";
import { useMemo } from "react";

type ChipProps = {
  fundamentalSet: Fundamental_Milestone[];
};

type Props = ChipProps & {
  index: number;
  name: string;
};

const QuestionFundamentalSetChip = ({ fundamentalSet }: ChipProps) => {
  const { fundamentalDict } = useStore((state) => ({
    fundamentalDict: state.fundamentalDict,
  }));

  const groupedUserFundamentalSetDetails = useMemo(() => {
    const groupedData: {
      [key: string]: {
        id: string | number;
        name: string;
        image: string;
        milestones: API_Milestone[];
      };
    } = {};

    fundamentalSet.forEach((setItem) => {
      if (setItem.fundamental) {
        const devGoalsFundamental = fundamentalDict[setItem.fundamental];

        if (!groupedData[setItem.fundamental]) {
          groupedData[setItem.fundamental] = {
            id: setItem.fundamental,
            name: devGoalsFundamental.name,
            image: devGoalsFundamental.image,
            milestones: [],
          };
        }

        const milestone = devGoalsFundamental.milestones.find(
          (milestone) => milestone.id === setItem.milestone
        );

        if (milestone) {
          groupedData[setItem.fundamental].milestones.push(milestone);
        }
      }
    });

    return Object.values(groupedData);
  }, [fundamentalSet, fundamentalDict]);

  if (!groupedUserFundamentalSetDetails.length) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "340px",
        minWidth: "340px",
        backgroundColor: "#fff",
        p: 1,
        boxShadow: "0px 8px 16px 0px rgba(45, 55, 72, 0.15)",
        borderRadius: "8px",
      }}
    >
      {groupedUserFundamentalSetDetails.map((set) => (
        <Chip
          key={set.id}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 1.5,
            backgroundColor: "neutral.100",
            p: 1,
          }}
          avatar={
            <Avatar
              alt={set.name}
              src={set.image}
              sx={{ width: "32px", height: "32px" }}
            />
          }
          label={
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "cyan.700",
                  lineHeight: 1.5,
                  fontSize: "16px",
                }}
              >
                {set.name}
              </Typography>
              <Box sx={{ ml: -2 }}>
                <ul>
                  {set.milestones.map((milestone) => (
                    <li
                      key={milestone.id}
                      style={{
                        width: "100%",
                        padding: 1,
                        fontWeight: 400,
                        color: "cyan.700",
                        lineHeight: 1.5,
                        fontSize: "16px",
                      }}
                    >
                      {milestone.name}
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          }
        />
      ))}
    </Box>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    width: "340px",
  },
}));

const EvaluationMomentQuestionName = ({
  name,
  index,
  fundamentalSet,
}: Props) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: 1,
      alignItems: "center",
    }}
  >
    <Typography
      sx={{
        display: "flex",
        fontSize: 16,
        lineHeight: "1.5",
        paddingLeft: { xs: 2.5, sm: 0 },
      }}
    >
      Q{index + 1}: <span dangerouslySetInnerHTML={{ __html: name }} />
    </Typography>
    <LightTooltip
      sx={{
        maxWidth: "auto",
      }}
      title={<QuestionFundamentalSetChip fundamentalSet={fundamentalSet} />}
    >
      <InfoOutlinedIcon
        sx={{
          width: "20px",
          opacity: "0.5",
        }}
      />
    </LightTooltip>
  </Box>
);


export default EvaluationMomentQuestionName;

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllSchool, getSchoolBySlug } from "api/school-api";

import Logo from "images/logo-2.png";
import useStore from "store/store";
import CannotAccessPage from "containers/NotFoundPage/CannotAccessPage";

import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/Button/Button";
import {
  SCHOOL_DETAIL,
  CREATE_SCHOOL,
  EDIT_SCHOOL,
  CURLANG,
} from "constants/url";

const SchoolPage = () => {
  const { profileRole, setCurrSchool } = useStore((state) => ({
    profileRole: state.profileDetails.role,
    setCurrSchool: state.setCurrSchool,
  }));
  const navigate = useNavigate();

  const CreateButton = () => {
    const { currLanguage } = useStore((state) => ({
      currLanguage: state.currLanguage,
    }));
    return (
      <CustomButton
        buttonText="Add a new school"
        arrow={true}
        onClick={() => {
          setCurrSchool({
            id: -1,
            name: "",
            slug: "",
            teachers: 0,
            parents: 0,
            children: 0,
            admin: 0,
            total: 0,
          });
          navigate(`${CURLANG(currLanguage)}/${CREATE_SCHOOL}`);
        }}
      />
    );
  };

  return (
    <Box sx={{ p: "30px 50px", display: "flex", flexDirection: "column" }}>
      {profileRole == "WAD" || profileRole == "AD" ? (
        <>
          <Title />
          <Schools />
          {profileRole == "WAD" && (
            <Box sx={{ ml: "auto", mt: "20px" }}>
              <CreateButton />
            </Box>
          )}
        </>
      ) : (
        <CannotAccessPage />
      )}
    </Box>
  );
};

const Title = () => {
  return (
    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
        mb: "20px",
      }}
    >
      All Registered Schools
    </Typography>
  );
};

const Schools = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  // const [schools, setSchools] = useState<API_School[]>([]);

  const { currLanguage, schools, setSchools, setCurrSchool } = useStore(
    (state) => ({
      currLanguage: state.currLanguage,
      schools: state.schools,
      setSchools: state.setSchools,
      setCurrSchool: state.setCurrSchool,
    })
  );

  const navigate = useNavigate();

  useEffect(() => {
    const populateData = async () => {
      setSchools(await getAllSchool());
      setIsLoaded(true);
    };

    populateData();
  }, []);

  const updateSchool = async (slug: string) => {
    const res = await getSchoolBySlug(slug);
    if (typeof res !== "string") {
      setCurrSchool(res);
    }
    navigate(`${CURLANG(currLanguage)}/${EDIT_SCHOOL(slug)}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "2rem",
        justifyContent: "center",
      }}
    >
      {schools.map((school) => {
        return (
          <Card
            sx={{
              minWidth: 267,
              maxWidth: 267,
              borderRadius: "10px",
              backgroundColor: "#F0F0F0",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              cursor: "pointer",
              transition: "200ms all ease-in-out",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => {
              navigate(
                `${CURLANG(currLanguage)}/${SCHOOL_DETAIL(school.slug)}`
              );
            }}
          >
            <CardMedia
              component="img"
              alt={school.image ? school.image : "No Image"}
              height="206"
              image={school.image ? school.image : Logo}
              sx={{
                backgroundColor: "primary.light",
                "&::before": { display: "none" },
              }}
              onError={(
                event: React.SyntheticEvent<HTMLImageElement, Event>
              ) => {
                event.currentTarget.onerror = null;
                event.currentTarget.src = Logo;
              }}
            />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: "13px 18px",
                flexGrow: 1,
                pb: "0px !important",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  mb: "20px",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                  {school.name}
                </Typography>
                <EditIcon
                  sx={{
                    cursor: "pointer",
                    color: "txt.secondary",
                    transition: "all 100ms ease-in-out",
                    "&:hover": {
                      color: "primary.main",
                      transition: "all 100ms ease-in-out",
                    },
                    ml: "auto",
                  }}
                  onClick={() => {
                    updateSchool(school.slug);
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default SchoolPage;

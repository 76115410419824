import { Page, Text, View, Image } from "@react-pdf/renderer";

import { API_Project_Module } from "types/project";

import ttb_logo from "images/ttb-logo.png";
import watermark from "images/ttb-watermark-pdf.png";

import { removeHtmlTagsAndStyles } from "./utils";
import { styles } from "./styles";
import LessonHeader from "./LessonHeader";

let contributingQuestionsMain: any;
let focusQuestionsMain: any;
function splitAndFormatGuidingQuestions(text: string): any[] {
  const lines = text.split("?").map((line) => line.trim());

  const formattedLines = [];
  let heading = "";
  for (const line of lines) {
    if (line.startsWith("Guiding Question:")) {
      heading = "Focus Questions:";
      formattedLines.push(heading);
      formattedLines.push(
        line
          .replace("Guiding Question:", "")
          .replace(/\(.*?\)/g, "")
          .trim()
      );
    } else if (line.startsWith("Contributing Questions:")) {
      heading = "Contributing Questions:";
      formattedLines.push(heading);
      continue; // Skip this line, as it's just the heading
    } else {
      if (line.trim() !== "") {
        formattedLines.push(`• ${line.replace(/\(.*?\)/g, "").trim()}`);
      }
    }
  }

  const contributingQuestionsIndex = formattedLines.indexOf(
    "Contributing Questions:"
  );

  if (contributingQuestionsIndex != -1) {
    const focusQuestions = formattedLines.slice(0, contributingQuestionsIndex);
    const contributingQuestions = formattedLines.slice(
      contributingQuestionsIndex + 1
    );

    contributingQuestionsMain = contributingQuestions.join("\n");
    focusQuestionsMain = focusQuestions.join("\n");
  } else {
    console.log("Item not found");
  }
  return [focusQuestionsMain, contributingQuestionsMain];
}

interface Props {
  projModule: API_Project_Module;
  currentProject: any;
}

const FocusAndGuidingQuestionsPage = ({
  projModule,
  currentProject,
}: Props) => (
  <Page style={styles.page} wrap>
    <View style={styles.watermarkContainer}>
      <Image style={styles.watermarkImage} src={watermark} />
    </View>
    <Image src={ttb_logo} style={styles.logoContainer} />
    <LessonHeader currentProject={currentProject} projModule={projModule} />
    {currentProject.guiding_question && (
      <View style={styles.regularSection}>
        <Text style={{ fontSize: "16px", fontFamily: "FuturaPT600" }}>
          Focus Question
        </Text>
        <View style={styles.regularSection}>
          <Text style={styles.miniHeaderText}>Guiding question:</Text>
          <Text style={styles.regularSectionText}>
            {
              splitAndFormatGuidingQuestions(
                removeHtmlTagsAndStyles(currentProject.guiding_question)
              )[0]
            }
          </Text>
        </View>

        <Text style={styles.miniHeaderText}>Contributing questions:</Text>

        <Text style={styles.regularSectionText}>
          {
            splitAndFormatGuidingQuestions(
              removeHtmlTagsAndStyles(currentProject.guiding_question)
            )[1]
          }
        </Text>
      </View>
    )}
  </Page>
);

export default FocusAndGuidingQuestionsPage;

export const FETCH_MESSAGE_TYPE = "fetch_message";
export const CHAT_MESSAGE_TYPE = "chat_message";
export const SEEN_MESSAGE_TYPE = "seen_message";

export const chatModalStyle = {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "100%",
  maxWidth: "450px",
  maxHeight: "80%",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "10px",
  overflow: "hidden",
  "&:focus-visible": {
    outline: "none",
  },
};

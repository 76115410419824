import { Box, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";

//Prompt user when leaving without saving
import { translation } from "constants/translation";
import EditLessonForm from "components/ProjectForms/EditLessonForm";

const EditLessonPage = () => {
  const { projectSlug, lessonID } = useParams();
  const { currLanguage, isAssessment } = useStore((state) => ({
    currLanguage: state.currLanguage,
    isAssessment: state.currentProject?.assessment,
  }));
  const navigate = useNavigate();
  const handleCloseNavigate = () => {
    navigate(
      `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
        projectSlug!
      )}/lesson-plans`
    );
  };

  return (
    <Box sx={{ p: "30px 50px" }}>
      <Title />
      <EditLessonForm
        assessment={isAssessment}
        lessonID={lessonID!}
        handleClose={handleCloseNavigate}
        isEditLessonPage={true}
      />
    </Box>
  );
};

const Title = () => {
  const currLesson = useStore((state) => state.currLesson);
  const currLessons = useStore((state) => state.currentLessons);

  const lessonIndex =
    currLessons.lessons.length === 0
      ? 0
      : currLessons.lessons.findIndex((lesson) => lesson.id === currLesson.id) +
        1;

  return (
    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
        mb: "20px",
      }}
    >
      {lessonIndex !== 0 && currLesson.name !== ""
        ? `${translation.editing} Lesson ${lessonIndex}: ${currLesson.name}`
        : "Editing Lesson"}
    </Typography>
  );
};

export default EditLessonPage;

import React from "react";
import { TextField } from "@mui/material";
import {
  API_Create_Activity,
} from "types/project";
import { translation } from "constants/translation";

export const NameField = ({
  activity,
  setActivity,
  onChange,
}: {
  activity: API_Create_Activity;
  setActivity: React.Dispatch<React.SetStateAction<API_Create_Activity>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    sx={{
      width: "100%",
    }}
    label={translation.activity_name}
    value={activity.name}
    onChange={(event) => {
      setActivity((prev) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

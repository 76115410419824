import { useEffect, useState } from "react";
import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { tabs } from "./constants";
import { getChildProfile, getGroup } from "api/profile-api";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import { API_Group_Full } from "types/group";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useStore from "store/store";
import { CHILDREN_PORTFOLIO_PAGE, CURLANG } from "constants/url";

import ChildPortfolioMiniDrawer from "./ChildPortfolioMiniDrawer";
import ChildPortfolioMiniDrawerMobile from "./ChildPortfolioMiniDrawerMobile";
import { API_Child } from "types/profile";
import {
  H3_TABS,
  H3_TAB_TYPE,
  HEART_TEXT,
} from "containers/PortfolioPage/portfolioPageConstants";

type ContextType = {
  classDetails: API_Group_Full;
  childDetails: API_Child;
};

const ChildrenPortfolioPage = () => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);
  const [childDetails, setChildDetails] = useState<API_Child>();
  const [classDetails, setClassDetails] = useState<API_Group_Full>();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const { groupSlug, childID, h3TabSlug } = useParams();

  useEffect(() => {
    const getChildDetails = async (childID: string) => {
      const childDetails = await getChildProfile(
        childID,
        localStorage.getItem("access")
      );

      if (typeof childDetails === "string") return;
      setChildDetails(childDetails);
    };

    const getClassDetails = async (groupSlug: string) => {
      const classDetails = await getGroup(groupSlug);

      if (typeof classDetails === "string") return;

      setClassDetails(classDetails);
    };

    if (
      childID !== undefined &&
      childID !== childDetails?.id?.toString() &&
      groupSlug !== undefined &&
      groupSlug !== "" &&
      groupSlug !== classDetails?.slug
    ) {
      getChildDetails(childID);
      getClassDetails(groupSlug);
    }
  }, [childID, groupSlug, childDetails, classDetails]);

  useEffect(() => {
    const landingScreen = decodeURIComponent(pathname).split("/").slice(-1)[0];

    if (pathname.includes(`/progress-report/create`)) {
      setCurrentTab("progress-report");
      return;
    }

    if (pathname.includes("photo-album")) {
      setCurrentTab("photo-album");
      return;
    }

    if (!h3TabSlug && landingScreen === "developmental-goals") {
      navigate(
        `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
          groupSlug!,
          childID!
        )}/developmental-goals/${HEART_TEXT}`
      );
      return;
    }

    if (H3_TABS.includes(landingScreen as H3_TAB_TYPE)) {
      setCurrentTab("developmental-goals");
      return;
    }

    if (!tabs.includes(landingScreen)) {
      if (pathname.includes("developmental-goals")) {
        setCurrentTab("developmental-goals");
      } else {
        navigate(
          `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
            groupSlug!,
            childID!
          )}/${tabs[0]}`
        );
      }
    } else {
      setCurrentTab(landingScreen);
    }

    setCurrentTab(landingScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navigate]);

  const isChildBelongsToClass =
    classDetails !== undefined && childDetails !== undefined
      ? classDetails.children.map((child) => child.id).includes(Number(childID))
      : false;

  if (classDetails === undefined || childDetails === undefined) {
    return (
      <Box sx={containerStyles}>
        <LoadingIndicator />
      </Box>
    );
  }

  if (!isChildBelongsToClass) {
    return (
      <Box sx={containerStyles}>
        <NotFoundPage />
      </Box>
    );
  }

  return isLg ? (
    <ChildrenPortfolioPageContent
      childName={childDetails.preferred_name}
      currentTab={currentTab}
      classDetails={classDetails}
      childDetails={childDetails}
    />
  ) : (
    <ChildrenPortfolioPageMobileContent
      childName={childDetails.preferred_name}
      currentTab={currentTab}
      classDetails={classDetails}
      childDetails={childDetails}
    />
  );
};

const headerDisplay = (header: string) => {
  const noHyphens = header.replace(/-/g, " ");

  // Split into words
  const words = noHyphens.split(" ");

  // Capitalize each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join words back into a string
  return capitalizedWords.join(" ");
};

const ChildrenPortfolioPageContent = ({
  childName,
  currentTab,
  classDetails,
  childDetails,
}: {
  childName: string;
  currentTab: string;
  classDetails: any;
  childDetails: any;
}) => {
  const { pathname } = useLocation();
  const { groupSlug, childID, h3TabSlug, devGoal } = useParams();

  const landingScreen = decodeURIComponent(pathname).split("/").slice(-1)[0];
  const navigate = useNavigate();

  const { currLanguage, developmentalGoals } = useStore((state) => ({
    currLanguage: state.currLanguage,
    developmentalGoals: state.developmentalGoals,
  }));


  const isDevelopmentalGoalDetail = h3TabSlug && devGoal;
  const devGoalDetail = developmentalGoals.find((goal) => goal.slug === devGoal);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <ChildPortfolioMiniDrawer childName={childName} currentTab={currentTab} />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          py: 4,
          mx: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, px: "12px" }}>
          {isDevelopmentalGoalDetail && (
            <IconButton
              onClick={() => {
                navigate(
                  `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
                    groupSlug!,
                    childID!
                  )}/developmental-goals/${h3TabSlug}`
                );
              }}
            >
              <ArrowBackIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          )}
          <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
            {isDevelopmentalGoalDetail && devGoalDetail?.names
              ? devGoalDetail.names[currLanguage]
              : headerDisplay(currentTab)}
          </Typography>
        </Box>

        <Box sx={{ p: 3, height: "100%" }}>
          <Outlet context={{ classDetails, childDetails }} />
        </Box>
      </Box>
    </Box>
  );
};

const ChildrenPortfolioPageMobileContent = ({
  childName,
  currentTab,
  classDetails,
  childDetails,
}: {
  childName: string;
  currentTab: string;
  classDetails: any;
  childDetails: any;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          backgroundColor: `white`,
          width: "100%",
          position: "sticky",
          top: { xs: 56, sm: 64 },
          zIndex: 2,
          flexDirection: "row",
          overflowX: "auto",
          overflowY: "visible",
          "::-webkit-scrollbar": {
            width: 5,
            height: 5,
          },
          gap: 1,
          p: 0.5,
          py: 1,
          mb: 0.5,
        }}
      >
        <ChildPortfolioMiniDrawerMobile
          childName={childName}
          currentTab={currentTab}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          py: 4,
          mx: "20px",
        }}
      >
        <Outlet context={{ classDetails, childDetails }} />
      </Box>
    </Box>
  );
};

const containerStyles: SxProps = {
  px: 3,
  pt: 4,
  mx: {
    lg: "60px",
  },
};

export const usePortfolioContext = () => useOutletContext<ContextType>();

export default ChildrenPortfolioPage;
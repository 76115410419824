import { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";

import { translation } from "constants/translation";
import "./Actions.module.css"


const sortOptions = [
  { key: "mostLiked", label: translation.mostLiked },
  { key: "mostRecent", label: translation.mostRecent },
]

type Props = {
  setIsMostLike: (value: boolean) => void;
  isMostLike: boolean;
}

const Actions = ({ isMostLike, setIsMostLike }: Props) => {
  const [selected, setSelected] = useState(sortOptions[0].key);

  return (
    <FormControl sx={{ width: "140px", mr: 2 }} id="sort-by-project-reviews-id">
      <InputLabel id="sort-by-project-reviews">
        Sort By
      </InputLabel>
      <Select
        labelId="sort-by-project-reviews"
        value={
          isMostLike
            ? translation.mostLiked
            : translation.mostRecent
        }
        onChange={(event) => {
            setSelected(event.target.value)
            setIsMostLike(event.target.value === sortOptions[0].key)
        }}
        input={
          <OutlinedInput
            id="select-sort-by-project-reviews"
            label="Sort By"
          />
        }
        renderValue={(value) => (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
            }}
          >
            {value}
          </Box>
        )}
      >
        {sortOptions.map(({ key, label }) => (
          <MenuItem
            sx={{ bgcolor: selected === key ? '#c5e7e9' : ''}}
            key={key}
            value={key}
          >
            {label}
          </MenuItem>        
        ))}
      </Select>
    </FormControl>
  );
};

export default Actions;

import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import Image from "components/Image/Image";
import ModuleTag from "components/ModuleTag/ModuleTag";
import { updateCompletedWork } from "api/completed-works-api";
import useStore from "store/store";
import { API_Child } from "types/portfolio";
import { DATE_FORMAT } from "../PhotoAlbumScreenComponents/PhotoAlbumComponents";
import {
  FileType,
  getFileTypeFromExtention,
  getFileTypeFromFile,
  getFileTypeFromType,
} from "./CompletedWorkCard";

import { deleteCompletedWork } from "api/completed-works-api";
import useSnack from "hooks/useSnack";
import { translation } from "constants/translation";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";

import ArticleIcon from "@mui/icons-material/Article";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

import CompletedWorkChildTagging from "./CompletedWorkChildTagging";

export const CompletedWorkPreviewModal = ({
  open,
  completedWork,
  setCompletedWork,
  isChildPortfolio = false,
  handleClose,
  isParentView = false,
}: {
  open: boolean;
  completedWork: API_Child.CompletedWork;
  setCompletedWork?: React.Dispatch<
    React.SetStateAction<API_Child.CompletedWork | undefined>
  >;
  isChildPortfolio?: boolean;
  handleClose: () => void;
  isParentView?: boolean;
}) => {
  const openSnack = useSnack();
  const [selectedChildList, setSelectedChildList] = useState<number[]>([]);
  useEffect(() => {
    setSelectedChildList(completedWork.child);
  }, [completedWork.child]);

  const { setIsRefreshCompletedWork } = useStore((state) => ({
    setIsRefreshCompletedWork: state.setIsRefreshCompletedWork,
  }));

  const date = moment(completedWork.completed_at).format(DATE_FORMAT);

  const handleDownload = async () => {
    if (typeof completedWork.original_type == "string") {
      const link = document.createElement("a");
      const nameSplit = completedWork.file.split("/");
      const name = completedWork.original_name || "completedWork.png";
      link.href = completedWork.file;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      await axios({
        url: completedWork.file + "?add-this-to-prevent-read-from-cache",
        method: "GET",
        responseType: "blob",
      }).then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const nameSplit = completedWork.file.split("/");
        const name = nameSplit.pop();
        link.href = url;
        link.setAttribute("download", name ? name : "completedWork.png");
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  const handleDelete = async () => {
    const res = await deleteCompletedWork(completedWork.id);

    if (typeof res !== "string") {
      openSnack(translation.deleteSuccess, true);
      setIsRefreshCompletedWork(true);
      handleClose();
    } else {
      openSnack(translation.deleteFailed, false);
    }
  };
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `${translation.delete} Little Work with caption "${completedWork.caption}"?`,
    warningContext: translation.irreversibleAction,
    handleDelete,
  };

  //EDIT CAPTION
  const [isEditCaption, setIsEditCaption] = useState<boolean>(false);
  const [updatedCaption, setUpdatedCaption] = useState<string>(
    completedWork.caption
  );

  const updatePhotoCaption = async () => {
    const res = await updateCompletedWork({
      id: completedWork.id,
      caption: updatedCaption,
    });
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(translation.updateSuccess, true);
      setIsEditCaption(false);
      setCompletedWork &&
        setCompletedWork({ ...completedWork, caption: updatedCaption });
      setIsRefreshCompletedWork(true);
    }
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setSelectedChildList([]);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          m: "24px",
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "800px",
              bgcolor: "background.paper",
              border: "1px solid var(--primary-main)",
              borderRadius: "10px",
              overflow: "auto",
              maxHeight: "100%",
              "&:focus-visible": {
                outline: "none",
              },
            }}
          >
            {!isChildPortfolio && (
              <CompletedWorkChildTagging
                completedWorkId={completedWork.id}
                selectedChildList={selectedChildList}
                setSelectedChildList={setSelectedChildList}
              />
            )}

            <Typography
              sx={{
                fontSize: "1.125rem",
                px: 3,
                py: 1.5,
                textAlign: "center",
                borderBottom: "1px solid var(--primary-main)",
              }}
            >
              {date}
            </Typography>

            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Box>
                  {completedWork.project.module && (
                    <ModuleTag
                      projModule={completedWork.project.module}
                      styles={{ mb: 0.5 }}
                    />
                  )}
                </Box>
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  <DownloadIcon
                    sx={{
                      cursor: "pointer",
                      transition: "all 100ms ease-in-out",
                      "&:hover": {
                        color: "var(--primary-main)",
                        transition: "all 100ms ease-in-out",
                      },
                    }}
                    onClick={handleDownload}
                  />
                  {!isParentView && (
                    <DeleteIcon
                      sx={{
                        cursor: "pointer",
                        transition: "all 100ms ease-in-out",
                        "&:hover": {
                          color: "var(--primary-main)",
                          transition: "all 100ms ease-in-out",
                        },
                      }}
                      onClick={handleOpenDialog}
                    />
                  )}
                </Box>
              </Box>

              <PreviewModalFileHandler
                completedWorkFile={completedWork.file}
                completedWorkFileMain={completedWork}
              />

              {!isParentView && (
                <>
                  {isEditCaption ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 1,
                      }}
                    >
                      <TextField
                        fullWidth
                        multiline
                        variant="standard"
                        value={updatedCaption}
                        onChange={(event) => {
                          setUpdatedCaption(event.target.value);
                        }}
                      />

                      <CheckIcon
                        sx={{
                          cursor: "pointer",
                          color: "green",
                        }}
                        onClick={() => {
                          updatePhotoCaption();
                        }}
                      />

                      <CloseIcon
                        sx={{
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => {
                          setUpdatedCaption(completedWork.caption);
                          setIsEditCaption(false);
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "12px",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "32px",
                        marginTop: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          p: "4px 0",
                          borderBottom: "1px solid transparent",
                          fontSize: "1em",
                          lineHeight: "1.4375em",
                        }}
                      >
                        {updatedCaption}
                      </Typography>
                      <EditIcon
                        sx={{
                          cursor: "pointer",
                          color: "txt.secondary",
                          transition: "all 100ms ease-in-out",
                          "&:hover": {
                            color: "primary.main",
                            transition: "all 100ms ease-in-out",
                          },
                          fontSize: "1.25rem",
                        }}
                        onClick={() => {
                          setIsEditCaption(true);
                          setUpdatedCaption(completedWork.caption);
                        }}
                      />
                    </Box>
                  )}
                </>
              )}

              <Typography
                sx={{
                  fontWeight: 700,
                  marginTop: 1,
                }}
              >
                {date}
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export const PreviewModalFileHandler = ({
  completedWorkFile,
  completedWorkFileMain,
}: {
  completedWorkFile: File | string;
  completedWorkFileMain?: any;
}) => {
  const completedWorkFileURL =
    typeof completedWorkFile === "string"
      ? completedWorkFile
      : URL.createObjectURL(completedWorkFile);

  const fileType: FileType =
    typeof completedWorkFile === "string"
      ? completedWorkFileMain.original_type == null
        ? getFileTypeFromExtention(completedWorkFileURL)
        : getFileTypeFromType(completedWorkFileMain.original_type)
      : getFileTypeFromFile(completedWorkFile);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        maxHeight: "500px",
        backgroundColor: "#F4F4F4",
        mt: 1,
      }}
    >
      {fileType === "Video" ? (
        <video
          style={{
            height: "100%",
            width: "100%",
            maxHeight: "500px",
            objectFit: "contain",
          }}
          controls
          controlsList="nodownload"
          preload="metadata"
          // style={{ border: "5px solid var(--primary-main)" }}
        >
          <source src={completedWorkFileURL} type="video/mp4" />
        </video>
      ) : fileType === "Audio" ? (
        <audio controls style={{ width: "100%" }}>
          <source src={completedWorkFileURL} type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
      ) : fileType === "PDF" ? (
        typeof completedWorkFile === "string" ? (
          <iframe
            title="iframe"
            src={completedWorkFileURL}
            width="100%"
            height="400px"
            style={{
              border: 0,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PictureAsPdfIcon
              sx={{ color: "primary.main", width: 150, height: 150 }}
            />
            <Typography>{completedWorkFile.name}</Typography>
          </Box>
        )
      ) : fileType === "Image" ? (
        <Image
          src={completedWorkFileURL}
          alt={"completed-work-image"}
          objectFit="cover"
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "400px",
            maxHeight: "400px",
            height: "auto",
          }}
        />
      ) : typeof completedWorkFile === "string" ? (
        <iframe
          title="iframe"
          sandbox="allow-scripts allow-same-origin allow-forms"
          width="100%"
          height="400px"
          src={`https://docs.google.com/gview?url=${completedWorkFileURL}&embedded=true`}
          style={{
            border: 0,
          }}
        ></iframe>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArticleIcon
            sx={{ color: "primary.main", width: 150, height: 150 }}
          />
          <Typography>{completedWorkFile.name}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CompletedWorkPreviewModal;

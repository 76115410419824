import React from "react";
import {
  Box,
  TextField, Typography,
} from "@mui/material";



import {
  API_Create_Lesson,
} from "types/project";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";

const DescriptionField = ({
  richText,
  lesson,
  setLesson,
  onChange,
}: {
  richText?: boolean;
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: string) => void;
}) => {
  if (!richText) {
    return (
      <TextField
        label={translation.lesson_description}
        value={lesson.description ? lesson.description : ""}
        onChange={(event) => {
          const data = event.target.value;

          setLesson((prev) => ({ ...prev, description: data }));
          onChange && onChange(data);
        }}
      />
    )
  }

  return (
    <Box>
      <Typography sx={{ mb: "8px" }}>
        {translation.description}
      </Typography>
      <RichCKEditor
        data={lesson.description}
        onChange={(_, editor: any) => {
          const data: string = editor.getData();

          setLesson((prev) => ({ ...prev, description: data }));
          onChange && onChange(data);
        }}
      />
    </Box>
  );
};


export default DescriptionField;
import { useState, MouseEvent } from "react";
import { Box, Typography, Popover } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { tabs } from "./constants";
import { translation } from "constants/translation";
import ProfileTab from "./Components/ProfileTab";

const ProfilePageMiniDrawer = ({ currentTab }: { currentTab: string }) => {
  const [open, setOpen] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen((prev) => !prev);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(246, 246, 246)",
        maxWidth: { xs: "100vw", sm: "300px" },
        width: { xs: "100vw", sm: "auto" },
        flex: open ? "1 0 auto" : "0 0 auto",
        p: { xs: 0, sm: 2 },
        pt: "21px",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
        alignSelf: "flex-start",
        position: "sticky",
        top: "55px",
        height: { xs: "auto", sm: "calc(100vh - 64px)" },
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: {xs: "none", sm: "flex"},
          alignItems: "center",
          justifyContent: "flex-end",
          mb: 1,
        }}
      >
        {open && (
          <Typography
            sx={{
              fontWeight: "bold",
              lineHeight: 1,
              mr: "auto",
            }}
          >
            {translation.profile}
          </Typography>
        )}

        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            m: 0.5,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
            },
          }}
          onClick={handleDrawer}
        >
          {open ? (
            <CloseIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", sm: "auto" },
          display: { xs: "-webkit-box", sm: "flex" },
          padding: { xs: 2, sm: 0 },
          flexDirection: { xs: "row", sm: "column" },
          overflowX: { xs: "auto", sm: "hidden" },
          gap: 1.5,
          justifyContent: "center",
        }}
      >
        {tabs.map((tab, index) => (
          <ProfileTab
            key={index}
            tab={tab}
            currentTab={currentTab}
            open={open}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        ))}
      </Box>

      {!open && name !== "" && (
        <Popover
          sx={{
            pointerEvents: "none",
            borderRadius: "10px",
            ml: 1,
          }}
          elevation={2}
          open={openPopOver}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              color: "#444",
              p: 1.5,
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
        </Popover>
      )}
    </Box>
  );
};


export default ProfilePageMiniDrawer;

import React, { PropsWithChildren } from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box, Typography } from "@mui/material";
import { API_Drag_And_Drop_Answer } from "types/teacher";
import { dndCardStyle, dndCardTextStyle } from "./DraggableCard";

export interface Props {
  dndAnswer: API_Drag_And_Drop_Answer;
}

const DroppableCard = ({ children, dndAnswer }: PropsWithChildren<Props>) => {
  const { isOver, setNodeRef } = useDroppable({
    id: dndAnswer.id,
    disabled: dndAnswer.isPutCorrectly,
  });

  return (
    <Box ref={setNodeRef} style={{ width: "100%" }}>
      <Box
        sx={{
          ...dndCardStyle,
          backgroundColor: isOver
            ? dndAnswer.isPutCorrectly
              ? "default"
              : "var(--primary-light)"
            : "#f3f3f3",
          opacity: dndAnswer.isPutCorrectly ? 0.6 : 1,
        }}
      >
        <Typography sx={dndCardTextStyle}>{dndAnswer.answer}</Typography>
      </Box>
    </Box>
  );
};

export default DroppableCard;

import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  createProhibitedWord,
  deleteProhibitedWord,
  getProhibitedWordList,
  updateProhibitedWord,
} from "api/review-api";
import useStore from "store/store";
import { API_Prohibited_Word } from "types/review";
import Button from "@mui/material/Button";
import useSnack from "hooks/useSnack";
//icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

//mui datagrid
import {
  GridColumns,
  DataGrid,
  GridRowId,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridRowModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { ID_COLUMN } from "containers/SiteAdminPage/ManagementPage/constants/gridColumnHelper";
import { DATA_GRID_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import { translation } from "constants/translation";

const ManageProhibitedWordPage = () => {
  const openSnack = useSnack();
  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));
  const [prohibitedWordList, setProhibitedWordList] = useState<
    API_Prohibited_Word[]
  >([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [rows, setRows] = React.useState(prohibitedWordList);

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "",
    confirmDelete: false,
  });
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [prohibitedWordId, setProhibitedId] = useState<number>(-1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setProhibitedId(-1);
    setOpenModal(false);
  };
  const handleOpenModal = (prohibitedWordId_: number) => {
    setProhibitedId(prohibitedWordId_);
    setOpenModal(true);
  };

  //handling clicks
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = (id: GridRowId) => {
    //call delete api
    const callDeleteProhibitedWord = async () => {
      const res = await deleteProhibitedWord(
        currLanguage,
        Number(id),
        localStorage.getItem("access")
      );
      if (typeof res === "string" && res.length >= 1) {
        openSnack(res, false);
      } else {
        openSnack(`Delete Prohibited Word successfully!`, true);
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    callDeleteProhibitedWord();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    //double function loop here to prevent it's initialiated once passing
    //call delete api
    const name = rows.filter((row) => row.id === id)[0]?.name;
    handleOpenDialog();
    setWarningBody({
      id: Number(id),
      warningTitle: `Delete ProhibitedWord "${name}"?`,
      confirmDelete: false,
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    console.log("newRow: ", newRow);
    const updatedRow = JSON.parse(JSON.stringify(newRow));
    if (newRow.id === -1) {
      const createResponse: API_Prohibited_Word | string =
        await createProhibitedWord(
          currLanguage,
          { name: newRow.name } as API_Prohibited_Word,
          localStorage.getItem("access")
        );
      // console.log("Update res: ", updateResponse);
      if (typeof createResponse === "string") {
        openSnack(createResponse, false);
        return createResponse;
      } else {
        openSnack("Prohibited Word is created!", true);
        updatedRow.id = createResponse.id;
        updatedRow.name = createResponse.name;
      }
    } else {
      // console.log("body: ", body);
      const updateResponse: API_Prohibited_Word | string =
        await updateProhibitedWord(
          currLanguage,
          newRow as API_Prohibited_Word,
          localStorage.getItem("access")
        );
      // console.log("Update res: ", updateResponse);
      if (typeof updateResponse === "string") {
        openSnack(updateResponse, false);
        return updateResponse;
      } else {
        openSnack("Prohibited Word is updated!", true);
      }
    }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return newRow;
  };
  console.log("rows: ", rows);
  //add record
  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const randomId = -1;
      setRows((oldRows) => [...oldRows, { id: randomId, name: "" }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [randomId]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button
          disabled={rows.filter((row) => row.id === -1).length > 0}
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          {translation.addProhibitedWords}
        </Button>
      </GridToolbarContainer>
    );
  }

  const columns: GridColumns = [
    {
      ...ID_COLUMN,
    },
    // {
    //   field: "created_at",
    //   type: "date",
    //   headerName: "Created At",
    //   width: 120,
    //   editable: false,
    //   valueFormatter: (params: any) =>
    //     moment(params?.value).format("DD/MM/YYYY"),
    // },
    {
      field: "name",
      headerName: "Prohibited Words",
      // width: 800,
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 18 }} noWrap>
            {params.value}
          </Typography>
        );
      },
    },
    // {
    //   field: "created_by_name",
    //   headerName: "Created By",
    //   width: 200,
    //   editable: false,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  //useEffect
  useEffect(() => {
    const populateProhibitedWord = async () => {
      const res = await getProhibitedWordList(currLanguage);
      if (typeof res !== "string") {
        setProhibitedWordList(res);
        setRows(res);
      } else {
        setProhibitedWordList([]);
        setRows([]);
      }
    };

    populateProhibitedWord();
    console.log("Rows: ", rows);
  }, [currLanguage]);

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <Box>
        <div style={DATA_GRID_STYLE}>
          <DataGrid
            getRowHeight={() => "auto"}
            sx={{ fontSize: 18 }}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Box>
    </>
  );
};

export default ManageProhibitedWordPage;

import { Box } from "@mui/material";
import React from "react";
import { LEFT_COLUMN_WIDTH } from "containers/CalendarPage/constants/constants";
import EmptyCell from "./EmptyCell";

const LeftColumnHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: `${LEFT_COLUMN_WIDTH}vw`,
      }}
    >
      <EmptyCell />
    </Box>
  );
};

export default LeftColumnHeader;

import { useLocation, useNavigate, useParams } from "react-router-dom";
import useStore from "store/store";
import Button from "components/Button/Button";
import { translation } from "constants/translation";
import {
  CURLANG,
  TEACHER_LESSON_ACTIVITY_PAGE,
  TEACHER_LESSON_DISCUSSION_PAGE,
} from "constants/url";
import useSnack from "hooks/useSnack";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import { updateLearnerActivityProgressFunctionCall } from "../TeacherPageHelper";

export const TeacherLessonActivityScreenNextButton = ({
  isNavigatePosition = false,
}: {
  isNavigatePosition?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const openSnack = useSnack();
  const { lessonSlug, activitySlug } = useParams();
  const {
    currLanguage,
    teacherCurrentModule,
    teacherCurrentModuleReview,
    setIsOpenTeacherModuleReviewModal,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    teacherCurrentModule: state.teacherCurrentModule,
    teacherCurrentModuleReview: state.teacherCurrentModuleReview,
    setIsOpenTeacherModuleReviewModal: state.setIsOpenTeacherModuleReviewModal,
  }));
  const isInDiscussionPage = location.pathname
    .toString()
    .includes("/discussion/");

  const nextActivityPath = () => {
    const currLessonIndex = teacherCurrentModule.lessons.findIndex(
      (lesson) => lesson.slug === lessonSlug
    );
    // No valid lesson

    if (currLessonIndex === -1) {
      return [``, true];
    }
    const currActivityIndex = teacherCurrentModule.lessons[
      currLessonIndex
    ].activities.findIndex((activity) => activity.slug === activitySlug);

    // No valid Activity
    if (currActivityIndex === -1 && !isInDiscussionPage) {
      return [``, true];
    }

    const lessonLength = teacherCurrentModule.lessons.length;
    const activityLength =
      teacherCurrentModule.lessons[currLessonIndex].activities.length;

    //There's next activity and it's not in disucssion page
    if (!isInDiscussionPage && currActivityIndex < activityLength - 1) {
      const currentLesson = teacherCurrentModule.lessons[currLessonIndex];
      const currentLessonSlug = currentLesson.slug;
      const nextActivitySlug =
        currentLesson.activities[currActivityIndex + 1].slug;
      return [
        `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
          teacherCurrentModule.slug,
          currentLessonSlug,
          nextActivitySlug
        )}`,
        false,
      ];
    } else {
      // There's no activity, then it's either discussion or next lesson activity

      //if it's not in discussion page and there is discussion
      if (
        !isInDiscussionPage &&
        teacherCurrentModule.lessons[currLessonIndex].discussion_topic !== ""
      ) {
        return [
          `${CURLANG(currLanguage)}/${TEACHER_LESSON_DISCUSSION_PAGE(
            teacherCurrentModule.slug,
            teacherCurrentModule.lessons[currLessonIndex].slug
          )}`,
          false,
        ];
      }

      try {
        // if there's next lesson + next activity
        if (currLessonIndex < lessonLength - 1) {
          const teacherNextLesson =
            teacherCurrentModule.lessons[currLessonIndex + 1];
          const nextLessonSlug = teacherNextLesson.slug;
          const nextActivitySlug = teacherNextLesson.activities[0].slug;
          return [
            `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
              teacherCurrentModule.slug,
              nextLessonSlug,
              nextActivitySlug
            )}`,
            false,
          ];
        }
      } catch { }
    }
    return [``, true];
  };
  const [nextPath, isNextPathDisabled] = nextActivityPath();

  return (
    <Button
      style={{
        display: isNavigatePosition && isNextPathDisabled ? "none" : "flex",
        backgroundColor: "#91C6C5",
        border: "#91C6C5",
        fontWeight: "500",
        padding: "8px 50px",
        borderRadius: "4px",
      }}
      buttonText={
        isNavigatePosition || !isNextPathDisabled
          ? translation.next
          : translation.done
      }
      btnType="filled"
      onClick={async () => {
        if (!isNextPathDisabled) {
          const currLessonIndex = teacherCurrentModule.lessons.findIndex(
            (lesson) => lesson.slug === lessonSlug
          );
          navigate(nextPath.toString());
          if (
            nextPath.toString().includes("/discussion/") &&
            currLessonIndex === teacherCurrentModule.lessons.length - 1
          ) {
            teacherCurrentModuleReview.id === -1 &&
              setIsOpenTeacherModuleReviewModal(true);
          }
        } else {
          teacherCurrentModuleReview.id === -1
            ? setIsOpenTeacherModuleReviewModal(true)
            : openSnack("Stay tuned for the certificate!", true);
        }
      }}
    />
  );
};

export const TeacherLessonActivityScreenPrevButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const openSnack = useSnack();
  const { lessonSlug, activitySlug } = useParams();
  const { currLanguage, teacherCurrentModule } = useStore((state) => ({
    currLanguage: state.currLanguage,
    teacherCurrentModule: state.teacherCurrentModule,
  }));
  const isInDiscussionPage = location.pathname
    .toString()
    .includes("/discussion/");
  const prevActivityPath = () => {
    //If lesson is not valid
    const currLessonIndex = teacherCurrentModule.lessons.findIndex(
      (lesson) => lesson.slug === lessonSlug
    );
    if (currLessonIndex === -1) {
      return [``, true];
    }

    //If activity is not valid and it's not in discussion page-
    const currActivityIndex = teacherCurrentModule.lessons[
      currLessonIndex
    ].activities.findIndex((activity) => activity.slug === activitySlug);
    if (currActivityIndex === -1 && !isInDiscussionPage) {
      return [``, true];
    }

    //If in discussion page
    if (isInDiscussionPage) {
      const currentLesson = teacherCurrentModule.lessons[currLessonIndex];
      const currentLessonSlug = currentLesson.slug;
      const prevActivitySlug =
        currentLesson.activities[currentLesson.activities.length - 1].slug;
      return [
        `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
          teacherCurrentModule.slug,
          currentLessonSlug,
          prevActivitySlug
        )}`,
        false,
      ];
    }

    //If prev activity slug exist
    if (currActivityIndex > 0) {
      const currentLesson = teacherCurrentModule.lessons[currLessonIndex];
      const currentLessonSlug = currentLesson.slug;
      const prevActivitySlug =
        currentLesson.activities[currActivityIndex - 1].slug;
      return [
        `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
          teacherCurrentModule.slug,
          currentLessonSlug,
          prevActivitySlug
        )}`,
        false,
      ];
    }

    //if have to go to previous lesson
    if (currLessonIndex > 0) {
      const prevLesson = teacherCurrentModule.lessons[currLessonIndex - 1];
      const prevLessonSlug = prevLesson.slug;
      const prevLessonActivityNumber = prevLesson.activities.length;

      //if there's discussion topic to go
      if (prevLesson.discussion_topic !== "") {
        return [
          `${CURLANG(currLanguage)}/${TEACHER_LESSON_DISCUSSION_PAGE(
            teacherCurrentModule.slug,
            prevLessonSlug
          )}`,
          false,
        ];
      }

      if (prevLessonActivityNumber > 0) {
        const prevActivitySlug =
          prevLesson.activities[prevLessonActivityNumber - 1].slug;
        return [
          `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
            teacherCurrentModule.slug,
            prevLessonSlug,
            prevActivitySlug
          )}`,
          false,
        ];
      }
    }

    return [``, true];
  };

  const [prevPath, isPrevPathDisabled] = prevActivityPath();

  return (
    <>
      <Button
        style={{ display: isPrevPathDisabled ? "none" : "flex" }}
        buttonText={translation.previous}
        btnType="outlined"
        backward
        // style={{ backgroundColor: "var(--txt-secondary)" }}
        onClick={() => {
          navigate(String(prevPath));
        }}
        disabled={!!isPrevPathDisabled}
      />
    </>
  );
};

export const TeacherLessonActivityScreenDoneButton = ({
  activityId,
  isDisplayButton = true,
  currTextActivityComponentIndex,
}: {
  activityId: number;
  isDisplayButton?: boolean;
  currTextActivityComponentIndex?: any;
}) => {
  const openSnack = useSnack();
  const { lessonSlug } = useParams();
  const {
    currLanguage,
    profileDetails,
    teacherCurrentActivity,
    learnerLessonProgressList,
    setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo,
    fullScreen,
    setFullScreen,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    teacherCurrentActivity: state.teacherCurrentActivity,
    learnerLessonProgressList: state.learnerLessonProgressList,
    setLearnerLessonProgressList: state.setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo:
      state.setDisplayObtainedCertificateModalInfo,
    fullScreen: state.fullScreen,
    setFullScreen: state.setFullScreen,
  }));

  const currLessonIndex = learnerLessonProgressList.findIndex(
    (learnerLessonProgress) => learnerLessonProgress.lesson_slug === lessonSlug
  );
  const isActivityDone = learnerLessonProgressList[currLessonIndex]
    ? learnerLessonProgressList[currLessonIndex].activity_done_list.includes(
      activityId
    )
    : false;

  return (
    <Box sx={{ mt: 1, display: "flex" }}>
      {isActivityDone ? (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <CircleCheckedFilled sx={{ color: "primary.main" }} />
            <Typography>{translation.completed}</Typography>
          </Box>

          <TeacherLessonActivityScreenNextButton />
        </Box>
      ) : isDisplayButton ? (
        <Button
          arrow={false}
          style={{
            display: "flex",

            backgroundColor: "#91C6C5",
            border: "#91C6C5",
            fontWeight: "500",
            padding: "8px 50px",
            borderRadius: "4px",
          }}
          buttonText={translation.done}
          btnType="filled"
          disabled={currTextActivityComponentIndex == 0}
          onClick={async () => {
            const isUpdateSuccess =
              await updateLearnerActivityProgressFunctionCall({
                profileDetails: profileDetails,
                currLanguage: currLanguage,
                teacherCurrentActivityId: teacherCurrentActivity.id,
                accessToken: localStorage.getItem("access")!,
                learnerLessonProgressList,
                currLessonIndex,
                setLearnerLessonProgressList,
                setDisplayObtainedCertificateModalInfo,
              });

            if (isUpdateSuccess) {
              openSnack(translation.done, true);
            } else {
              openSnack(translation.tryAgainLater, false);
            }
          }}
        />
      ) : null}
    </Box>
  );
};
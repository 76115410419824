import React, { useState, useRef } from "react";
import * as Icons from "./Faces";

import Image from "components/Image/Image";
import ClipBoard from "./ClipboardImage.png";
import useSnack from "hooks/useSnack";

import FeedbackPhotosGalleryModal from "./FeedbackPhotosGalleryModal";
import { teachertoolbox, teachertrainingacademy, parentportal } from "./TopicsQuestionsAnswers";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  Radio,
  RadioGroup,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import { useNavigate, useParams } from "react-router-dom";

const aboutFAQsText = [
  "Welcome to the Knowledge Base: FAQs section, your trusted guide to mastering the Teaching Toolbox (TTB), Teaching Training Academy (TTA), and Parent Portal created by Trehaus. Whether you're an educator or administrator, this knowledge base provides quick answers and step-by-step guidance for navigating these platforms with ease. ",
  "Our FAQs cover common questions and offer insights and best practices to enhance your experience. From getting started to advanced tips, find the information you need to create engaging and meaningful lessons.",
  "If you can't locate what you're looking for, our support team is here to assist.",
];
const aboutTopics = [
  "Create Accounts (Teacher, Child, and Parent)",
  "Create Class & Add Child to Class",
  "Scheduler & Visual Timetable",
  "Child’s Progress, Completed Projects, & Work",
  "Announcement",
];

const HelpPage = () => {
  const navigate = useNavigate();
  const openSnack = useSnack();

  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [openGalleryModal, setOpenGalleryModal] = useState<boolean>(false);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState("Provide Feedback and Suggestions");

  const [displayImageIndex, setDisplayImageIndex] = useState<number>(-1);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedSvg, setSelectedSvg] = useState<string>("");

  const { helpSlug } = useParams();
  const reversedLocation = helpSlug
    ?.split("-")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const handleFeedbackSubmit = async () => {
    const formData = new FormData();
    formData.append("isChecked", isChecked.toString());
    formData.append("selectedOption", selectedOption);
    formData.append("feedbackText", feedbackText);
    selectedImages.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });
  };

  const AboutFAQsText = ({ topic }: any) => {
    return (
      <Box sx={{ color: "black", display: "flex", gap: 1, marginBottom: 2 }}>
        <QuestionAnswerIcon />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "450",
          }}
          onClick={() => {
            navigate("/en/help/teacher-toolbox");
            document.getElementById(topic)?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          {topic}
        </Typography>
      </Box>
    );
  };

  const ImageUpload = () => {
    const fileInput = useRef<HTMLInputElement>(null);

    return (
      <Box
        sx={{
          paddingX: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => {
            if (fileInput.current !== null) {
              fileInput.current.click();
            }
          }}
          sx={{
            // width: "100%",
            maxWidth: selectedImages.length > 0 ? "125px" : "100%",
            flex: 1,

            height: selectedImages.length > 0 ? "72px" : "40px",
            // padding: "16px",
            padding: "8px",
            border: "1px dashed var(--Neutral-500, #8C8C8C);",
            borderRadius: "4px",
          }}
        >
          <input
            accept="video/*,image/*"
            type="file"
            id={"raised-button-file"}
            ref={fileInput}
            capture="environment"
            multiple
            hidden
            onChange={(event) => {
              const newFiles: File[] = Array.prototype.slice.call(event.target.files);

              if (newFiles.length === 0) {
                event.target.value = "";
                return;
              }
              setSelectedImages(newFiles);
              event.target.value = "";
            }}
          />
          <label>
            <Typography sx={{ textTransform: "none" }}>Upload Images</Typography>
          </label>
        </Button>
        {selectedImages.length > 0 && (
          <>
            {selectedImages.slice(0, 3).map((image: any, index: number) => (
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  alt=""
                  style={{
                    height: "72px",
                    width: "116px",
                    objectFit: "cover",
                    filter: index === 2 ? "grayscale(100%)" : "none",
                  }}
                  onClick={() => {
                    setDisplayImageIndex(index);
                    setOpenGalleryModal(true);
                  }}
                />
                {selectedImages.length > 3 && index === 2 && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      bgcolor: "#fff",
                      padding: "2px 5px",
                    }}
                  >
                    {selectedImages.length - 2}
                  </Box>
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
    );
  };

  if (helpSlug == "about-faqs") {
    return (
      <Box
        sx={{
          padding: "59px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "50%", marginRight: "101px", padding: "59px" }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "600", marginBottom: 2 }}>
            Knowledge Base: Frequently Asked Questions (FAQs)
          </Typography>
          {aboutFAQsText.map((text: any) => {
            return (
              <Typography sx={{ fontSize: "16px", fontWeight: "400", marginBottom: 2 }}>{text}</Typography>
            );
          })}
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${ClipBoard})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "60%",
            position: "relative",
            color: "#fff",
          }}
        >
          <Box
            sx={{
              position: "absolute",

              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingX: "30px",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              Popular Topics
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {aboutTopics.map((topic: any) => {
                return <AboutFAQsText topic={topic} />;
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else if (helpSlug == "feedback") {
    return (
      <Box
        sx={{
          display: "flex",
          // backgroundColor: "purple",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FeedbackPhotosGalleryModal
          open={openGalleryModal}
          setOpen={setOpenGalleryModal}
          displayImageIndex={displayImageIndex}
          setDisplayImageIndex={setDisplayImageIndex}
          images={selectedImages}
        />
        <Box
          sx={{
            // backgroundColor: "lightcoral",

            // padding: "81px 290px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 1,
            width: "528px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#D8F2EE",
              padding: "5px 16px",
              borderRadius: "8px",
            }}
          >
            {" "}
            <Typography sx={{ fontSize: "32px", fontWeight: "600", color: "#5C9391" }}>
              Send Us Your FeedBack
            </Typography>
            <Typography>Do you have any suggestion or found some bug?</Typography>
            <Typography>Let us know in the field below </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>How was your experience?</Typography>
            <FormGroup
              sx={{
                borderRadius: "52px",
                backgroundColor: "#F2F2F2",
                paddingLeft: "8px",
              }}
            >
              <FormControlLabel
                control={<Switch />}
                label="Submit anonymously"
                onChange={(event: any) => setIsChecked(event.target.checked)}
              />
            </FormGroup>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            {selectedSvg == "1" ? (
              <Icons.WorstBrightSvg />
            ) : (
              <Icons.WorstSvg onClick={() => setSelectedSvg("1")} />
            )}
            {selectedSvg == "2" ? (
              <Icons.BadBrightSvg />
            ) : (
              <Icons.BadSvg onClick={() => setSelectedSvg("2")} />
            )}
            {selectedSvg == "3" ? (
              <Icons.NeutralBrightSvg />
            ) : (
              <Icons.NeutralSvg onClick={() => setSelectedSvg("3")} />
            )}
            {selectedSvg == "4" ? (
              <Icons.BetterBrightSvg />
            ) : (
              <Icons.BetterSvg onClick={() => setSelectedSvg("4")} />
            )}
            {selectedSvg == "5" ? (
              <Icons.BestBrightSvg />
            ) : (
              <Icons.BestSvg onClick={() => setSelectedSvg("5")} />
            )}
          </Box>

          <TextField
            id="outlined-multiline-flexible"
            placeholder="Describe your experiences here"
            sx={{ width: "auto", margin: "16px" }}
            multiline
            maxRows={4}
            minRows={4}
            value={feedbackText}
            onChange={(event) => {
              setFeedbackText(event.target.value);
            }}
          />

          <ImageUpload />

          <FormControl
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingX: "16px",
            }}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedOption}
              onChange={(event) => setSelectedOption(event.target.value)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FormControlLabel
                sx={{ fontSize: "10px" }}
                value="Provide Feedback and Suggestions"
                control={<Radio />}
                label="Provide Feedback and Suggestions"
              />
              <FormControlLabel
                sx={{ fontSize: "10px" }}
                value="Report Issues"
                control={<Radio />}
                label="Report Issues"
              />
            </RadioGroup>
          </FormControl>
          <Button
            sx={{
              width: "100%",
              backgroundColor: "#91C6C5",
              color: "white",
              textTransform: "none",
            }}
            onClick={() => {
              if (selectedSvg == "") {
                openSnack("Please select a rating!", false);
                return;
              }
              if (feedbackText == "") {
                openSnack("Please enter your feedback!", false);
                return;
              }
              handleFeedbackSubmit();
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        padding: "24px 64px",
        overflowY: "auto",
      }}
    >
      {helpSlug != "about-faqs" && helpSlug != "feedback" && (
        <Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>{reversedLocation}</Typography>
          <Typography sx={{ fontSize: "20px", fontWeight: "700", marginBottom: 4 }}>
            Frequently Asked Questions
          </Typography>
        </Box>
      )}
      {(helpSlug == "teacher-toolbox" ||
        helpSlug == "teacher-training-academy" ||
        helpSlug == "parent-portal") && <QuestionAnswer helpSlug={helpSlug} />}
    </Box>
  );
};

const QuestionAnswer = ({ helpSlug }: any) => {
  const questionSource = helpSlug
    .split("-")
    .map((part: any) => part.toLowerCase())
    .join("");

  let matchedData = null;

  switch (questionSource) {
    case "teachertoolbox":
      matchedData = teachertoolbox;
      break;
    case "teachertrainingacademy":
      matchedData = teachertrainingacademy;
      break;
    case "parentportal":
      matchedData = parentportal;
      break;
    default:
      console.log("No matching data found");
  }

  return (
    <Box>
      {matchedData?.map((topic: any, index: any) => (
        <Topic topic={topic} />
      ))}
    </Box>
  );
};

const QuestionAccordion = ({ questionAnswer }: any) => {
  const question = Object.keys(questionAnswer)[0];
  const answers = Object.values(questionAnswer);
  function isFirstCharNumber(str: string): boolean {
    const firstCharCode = str.charCodeAt(0);
    return firstCharCode >= "0".charCodeAt(0) && firstCharCode <= "9".charCodeAt(0);
  }
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <Accordion
      disableGutters={true}
      sx={{
        border: 0,
        borderTop: 0,
        boxShadow: "none",
        marginBottom: 1,
        "&:before": {
          display: "none",
        },
      }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          "& .MuiAccordionSummary-contentGutters": {
            padding: "0 !important",
            margin: "0 !important",
          },
          "& .MuiAccordionSummary-content": {
            padding: "0 !important",
            margin: "0 !important",
          },
          "& .MuiAccordionSummary-root": {
            border: "0 !important",
          },
          height: "fit-content !important",
          minHeight: "fit-content !important",
          maxHeight: "fit-content !important",
          padding: 0,
          margin: 0,
          border: 0,
          boxShadow: "none",
        }}
      >
        <Typography sx={{ fontWeight: "450", fontSize: "14px", border: 0 }}>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ border: 0, boxShadow: "none" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {answers.flat().map((answer: any) => {
            if (isFirstCharNumber(answer[0])) {
              return (
                <Typography
                  sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    fontWeight: "450",
                    fontSize: "14px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {answer}
                </Typography>
              );
            } else if (answer.includes("Alternatively") || answer.includes("Option")) {
              return <Typography sx={{ fontWeight: "450", fontSize: "14px" }}>{answer}</Typography>;
            } else {
              return <Image src={answer} alt={answer} />;
            }
          })}
        </Box>

        <Typography
          sx={{
            color: "#5C9391",
            textAlign: "right",
            textDecoration: " underline",
          }}
          onClick={() => setExpanded(false)}
        >
          Close Section
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const Topic = ({ topic }: { topic: Record<string, unknown[]> }) => {
  const entries = Object.entries(topic);
  return (
    <Box sx={{}}>
      {entries.map(([key, value]: [string, unknown[]]) => (
        <Box
          sx={{
            height: "24px",
            backgroundColor: "#D8F2EE",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "4px",
            marginBottom: 0.5,
          }}
          key={key}
          id={key}
        >
          <QuestionAnswerIcon />
          <Typography sx={{ marginLeft: "8px", fontSize: "16px", fontWeight: "600" }}>{key}</Typography>
        </Box>
      ))}
      {entries.map(([_, value]: [string, unknown[]]) =>
        value.map((val: any, i: any) => {
          return <QuestionAccordion questionAnswer={val} />;
        })
      )}
    </Box>
  );
};

export default HelpPage;

import React, { useState } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStore from "store/store";
import { translation } from "constants/translation";
import EditDevelopmentalGoalForm from "./EditDevelopmentalGoalForm";
import CreateDevelopmentalGoalForm from "./CreateDevelopmentalGoalForm";
import { CapitalizeFirstChar } from "helper/helper";
import Backdrop from "components/Backdrop";
import { Resizer } from "components/Resizer";

const DevelopmentalGoalResizableDrawer = ({
  isDrawerOpen,
  handleClose,
}: {
  isDrawerOpen: boolean;
  handleClose: () => void;
}) => {
  const {
    developmentalGoals,
    currDevelopmentalGoal,
    currDevelopmentalGoalTag,
  } = useStore((state) => ({
    developmentalGoals: state.developmentalGoals,
    currDevelopmentalGoal: state.currDevelopmentalGoal,
    currDevelopmentalGoalTag: state.currDevelopmentalGoalTag,
  }));
  const defaultDrawerWidth = localStorage.getItem("drawerWidth")
    ? localStorage.getItem("drawerWidth")!
    : "65vw";

  const [drawerWidth, setDrawerWidth] = useState<string>(defaultDrawerWidth);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const isNewDevelopmentalGoal = currDevelopmentalGoal.id < 0;
  const isSubDevelopmentalGoal = !!currDevelopmentalGoal.main_fundamental;

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="right"
        open={isDrawerOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: drawerWidth,
            minWidth: "600px",
            maxWidth: "90vw",
          },
          sx: {
            boxShadow: 3,
          },
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {currDevelopmentalGoal.id && (
          <Box
            sx={{
              height: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Resizer setDrawerWidth={setDrawerWidth} />
            <Box
              sx={{
                p: 3,
                pb: 1.5,
              }}
            >
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  borderRadius: "4px",
                  fontSize: "1.25rem",
                  mb: 0.75,
                  transition: "all 200ms ease-in-out",
                  color: "#444",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                    transition: "all 200ms ease-in-out",
                  },
                }}
                onClick={handleClose}
              />

              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: "#444",
                }}
              >
                {isSubDevelopmentalGoal
                  ? translation.subDevelopmentalGoal
                  : translation.developmentalGoal}
              </Typography>

              {isNewDevelopmentalGoal ? (
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  {translation.formatString(
                    translation.addSubDevelopmentalGoal,
                    {
                      tab: isSubDevelopmentalGoal
                        ? developmentalGoals.filter(
                            (devGoal) =>
                              devGoal.id ===
                              currDevelopmentalGoal.main_fundamental
                          )[0].name
                        : CapitalizeFirstChar(currDevelopmentalGoalTag),
                    }
                  )}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  {currDevelopmentalGoal.name}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                flex: 1,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  overflowX: "hidden",
                  overflowY: "auto",
                  p: 3,
                  pt: 3.5,
                  borderTop: "1px solid rgba(0,0,0,0.2)",
                }}
              >
                {isNewDevelopmentalGoal ? (
                  <CreateDevelopmentalGoalForm handleClose={handleClose} />
                ) : (
                  <EditDevelopmentalGoalForm handleClose={handleClose} />
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Drawer>
      <Backdrop isHovering={isDrawerOpen && isHovering} />
    </>
  );
};

export default DevelopmentalGoalResizableDrawer;

import { useMemo } from "react";
import moment from "moment";
import { Box } from "@mui/material";
import { ImageType, containerStyles, ImageCard } from "./PhotoAlbumComponents";

interface YearGroup {
  [year: string]: ImageType[];
}

const YearView = ({
  images,
  handleClick,
}: {
  images: ImageType[];
  handleClick: (year: string) => void;
}) => {
  const yearGroup: YearGroup = useMemo(
    () =>
      images.reduce((group: YearGroup, image: ImageType) => {
        const year = moment(image.created_at).year().toString();

        if (!group[year]) {
          group[year] = [image];
        } else {
          group[year].push(image);
        }

        return group;
      }, {}),
    [images]
  );

  return (
    <Box sx={containerStyles}>
      {Object.entries(yearGroup)
        .sort((a, b) => parseInt(b[0], 10) - parseInt(a[0], 10))
        .map(([year, _images], index) =>
          _images.length !== 0 ? (
            <ImageCard
              key={index}
              src={_images[0].link}
              label={year}
              onClick={() => handleClick(year)}
            />
          ) : null
        )}
    </Box>
  );
};

export default YearView;

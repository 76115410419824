import { Box, SxProps, Typography } from "@mui/material";
import Button from "components/Button/Button";
import StepNavigationDots from "../StepNavigationDots";
import PointerIcon from "images/Pointer.svg"

type Props = {
  step: number;
  steps: number;
  html?: string;
  image?: string;
  description: string;
  furtherInfo?: string;
  handleBack?: () => void;
  handleNext: () => void;
  wrapperStyles?: SxProps;
  pointerWrapperStyles?: SxProps;
};

const WalkthroughStep = ({
  handleBack,
  handleNext,
  step,
  steps,
  html,
  image,
  description,
  furtherInfo,
  wrapperStyles,
  pointerWrapperStyles
}: Props) => { 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        width: "100%",
        gap:"12px"
      }}
    >
      {(html || image) && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1.5,
              bgcolor: "#EBF8F8",
              width: "100%",
              height: { xs: "72px", lg: "166px" },
              overflow: "hidden",
              "div > div": {
                width: "220px",
                height: "44px",
                backgroundColor: "#fff",
              }
            }}
          >
            {html ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ...wrapperStyles
                }}
                dangerouslySetInnerHTML={{ __html: html ?? '' }}
              />
            ) : 
              <img src={image} alt="step-icon" width="167.89px" height="268px" />
            } 
          </Box>
          <Box sx={{ position: "absolute", top: "108px", left: "173px", ...pointerWrapperStyles }}>
            <img src={PointerIcon} alt="" height="34.91px" width="22.99px" />
          </Box>
        </>
      )}
      <Typography sx={{ textAlign: "center" }}>
        {description}
      </Typography>
      {!!furtherInfo && (
        <>
          <Box sx={{ width: "60%", borderTop: "1px solid #DDD" }} />
          <Typography>
            {furtherInfo}
          </Typography>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          pt: 0.5,
        }}
      >
        {steps > 1 && (
          <Button
            buttonText="Back"
            btnType="outlined"
            arrow={false}
            style={{
              width: "100%",
              flex: 1,
              border: "none",
              fontWeight: 450,
              color: "#8C8C8C"
            }}
            onClick={handleBack}
          />
        )}
        <Button
          buttonText={step === steps ? "Got it" : "Next"}
          btnType="filled"
          arrow={false}
          onClick={handleNext}
          style={{
              flex: 1,
          }}
        />
      </Box>
      {steps > 1 && (
        <StepNavigationDots count={steps} step={step - 1} />
      )}
    </Box>
  )
}

export default WalkthroughStep;
import React from "react";
import { Box, Typography } from "@mui/material";

import Background from "images/end-of-project-report/developmental-goals-bg.png";
import Image from "components/Image/Image";

import { PDFInfoType, styles } from "./constant";

const DevelopmentalGoals = ({
  scale,
  PDFInfo,
  setPDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
}) => {
  const uniqueFundamentals = PDFInfo.fundamentals
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    )
    .map((item) => item);
  return (
    <Box sx={styles.body}>
      <Image src={Background} alt="" />

      <Typography
        sx={{
          position: "absolute",
          top: `${120 * scale}px`,
          left: `${400 * scale}px`,
          right: `${400 * scale}px`,
          textAlign: "center",
          fontSize: 40 * scale,
          fontWeight: 500,
          lineHeight: 1.25,
        }}
      >
        {PDFInfo.fundamentalsTitle}
      </Typography>

      <Box
        sx={{
          ...styles.main,
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignContent: "flex-start",
          flex: 1,
          mt: `${280 * scale}px`,
          mb: `${50 * scale}px`,
          mx: `${50 * scale}px`,
          overflow: "hidden",
        }}
      >
        {uniqueFundamentals.map((fundamental, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minWidth: `${200 * scale}px`,
              maxWidth: `${280 * scale}px`,
              border: `${8 * scale}px solid`,
              borderColor: "#fff",
              borderTopRightRadius: `${16 * scale}px`,
              borderBottomRightRadius: `${16 * scale}px`,
              pl: `${65 * scale}px`,
              pr: `${20 * scale}px`,
              height: `${100 * scale}px`,
              ml: `${60 * scale}px`,
              my: `${10 * scale}px`,
              mr: `${10 * scale}px`,
              backgroundColor: "#DFEBEB",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: `${-50 * scale}px`,
                top: `${-8 * scale}px`,
                backgroundColor: "#fff",
                borderRadius: "100%",
                width: `${100 * scale}px`,
                height: `${100 * scale}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                style={{
                  width: `${50 * scale}px`,
                  height: `${50 * scale}px`,
                }}
                src={fundamental.image}
                alt=""
              />
            </Box>

            <Typography
              sx={{
                fontSize: `${18 * scale}px`,
                maxWidth: `${190 * scale}px`,
                textAlign: "center",
              }}
            >
              {fundamental.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DevelopmentalGoals;

import { StyleSheet } from "@react-pdf/renderer";

type HTTPMethod = "GET" | "HEAD" | "POST" | "PUT" | "DELETE" | "PATCH";

type SourceObject = {
  uri: string;
  method: HTTPMethod;
  body: any;
  headers: any;
};

export const imageSrc = (src: string): SourceObject => ({
  uri: src,
  method: "GET",
  headers: { "Cache-Control": "no-cache" },
  body: "",
});

// A4 size in pt
export const pageSize = {
  width: 595,
  height: 842,
};

// photo book size
export const photoBookPageSize = {
  width: 475,
  height: 475,
};

export const styles = StyleSheet.create({
  body: {
    fontSize: 24,
    lineHeight: 1.5,
    color: "#312F30",
    backgroundColor: "white",
  },

  englishFont: {
    fontFamily: "Jost",
    // fontFamily: "Futura PT",
  },

  chineseFont: {
    fontFamily: "KaiTi",
  },

  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  background: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    objectFit: "contain",
  },

  backgroundReverse: {
    transform: "scaleX(-1)",
  },

  pageTitle: {
    fontSize: 40,
    fontWeight: 500,
    lineHeight: 1.25,
    textAlign: "center",
  },

  textCenter: {
    textAlign: "center",
  },
});

import {
  Box,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Chip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { API_Child } from "types/profile";
import Image from "components/Image/Image";

type Props = {
  childLearningMomentCheckList: number[];
  childList: API_Child[];
  updateChild: (child: API_Child) => Promise<void>;
}

const AddChildrenFromMenu = ({
  childLearningMomentCheckList,
  childList,
  updateChild,
}: Props) => {
  const checkedChildren = childList.filter((child) => childLearningMomentCheckList.includes(child.id));
  const unCheckedChildren = childList.filter((child) => !childLearningMomentCheckList.includes(child.id));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
        overflow: "overlay",
      }}
    >
      <Typography sx={{ fontSize: "14px" }}>
        Enter names of children who attained the milestone
      </Typography>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="sort-by-project-reviews">Enter child name</InputLabel>
        <Select
          multiple
          labelId="sort-by-project-reviews"
          value={checkedChildren}
          input={
            <OutlinedInput
              id="select-sort-by-project-reviews"
              label="Enter child name"
            />
          }
          renderValue={(children) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {children.map((child) => (
                <Chip
                  key={child.id}
                  size="small"
                  label={child.preferred_name}
                  color="primary"
                  variant="outlined"
                  deleteIcon={<CloseIcon fontSize="small" />}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onDelete={() => {
                    updateChild(child);
                  }}
                  onClick={() => {
                    updateChild(child);
                  }}
                  avatar={
                    <Avatar
                      src={child.image}
                      alt={`${child.preferred_name}'s picture`}
                    />
                  }
                />
              ))}
            </Box>
          )}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {unCheckedChildren.map((child) => (
              <MenuItem
                key={child.id}
                value={child.preferred_name}
                onClick={() => updateChild(child)}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    py: 1,
                  }}
                >
                  <Image
                    src={child.image}
                    alt={`${child.preferred_name}'s picture`}
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 12,
                    }}
                  />
                  {child.preferred_name}
                </Box>
              </MenuItem>
            ))}
          </Box>
        </Select>
      </FormControl>
    </Box>
  );
};

export default AddChildrenFromMenu;

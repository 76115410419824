import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  ACTION_REQUIRED,
  ANNOUNCEMENTS,
  CURLANG,
  DOCUMENTS,
  NOTICE_PAGE,
  PARENT_NOTICE_PAGE,
} from "constants/url";
import useStore from "store/store";
import AnnouncementsPage from "containers/AnnouncementsPage/AnnouncementsPage";
import DocumentsPage from "containers/AnnouncementsPage/DocumentsPage";
import { unSlugify } from "helper/slugify";

import AnnouncementIcon from "images/announcement-icon.png";
import DocumentIcon from "images/document.png";
import ActionRequiredIcon from "images/tick.png";
import Image from "components/Image/Image";
import {
  contentFontSize,
  contentMobileFontSize,
} from "constants/constantInGeneral";

const TabSelectorPage = () => {
  const navigate = useNavigate();
  const { childID, schoolId, noticesPageSelectedTab } = useParams();
  const { currLanguage, profileDetails, announcementList } = useStore(
    (state) => ({
      currLanguage: state.currLanguage,
      profileDetails: state.profileDetails,
      announcementList: state.announcementList,
    })
  );
  const isParentView = profileDetails.role === "PR";
  const NOTICES_TABS = [
    [ANNOUNCEMENTS, AnnouncementIcon],
    [DOCUMENTS, DocumentIcon],
    [ACTION_REQUIRED, ActionRequiredIcon],
  ];

  const unRespondedCount = announcementList.filter((announcement) => {
    return (
      announcement.is_responded === false &&
      announcement.announcement_response_information.id !== -1
    );
  }).length;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        paddingTop: 6,
        px: { xs: 1, sm: 6 },
        paddingBottom: 6,
      }}
    >
      {/* profileDetails.role is CH when not initialize (impossible scenario) */}
      {profileDetails.role !== "CH" && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            // gap: 2,
            justifyContent: "center",
            paddingBottom: 2,
          }}
        >
          {NOTICES_TABS.map((noticesTab, index) => {
            return (
              <Box
                key={`selection-notices-tabs-${noticesTab[0]}`}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  flex: 0.75,
                  "&:hover": {
                    backgroundColor: `primary.light`,
                  },
                  // borderRadius: `10px`,
                  backgroundColor: `${
                    noticesPageSelectedTab === noticesTab[0]
                      ? "primary.light"
                      : ""
                  }`,
                  border: `2px solid var(--primary-main)`,
                  borderLeft: `${
                    index !== 0 ? "0" : "2"
                  }px solid var(--primary-main)`,
                  fontStyle:
                    noticesPageSelectedTab === noticesTab[0] ? "bold" : "none",

                  cursor: "pointer",
                  // border: "1px solid var(--primary-main)",
                  height: { xs: 80, sm: 64 },
                }}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  if (isParentView) {
                    navigate(
                      `${CURLANG(currLanguage)}/${PARENT_NOTICE_PAGE(
                        childID!,
                        noticesTab[0]
                      )}`
                    );
                  } else {
                    navigate(
                      `${CURLANG(currLanguage)}/${NOTICE_PAGE(
                        schoolId!,
                        noticesTab[0]
                      )}`
                    );
                  }
                }}
              >
                <Image
                  style={{
                    width: isXs ? 30 : 50,
                    height: isXs ? 30 : 50,
                    marginRight: 8,
                  }}
                  src={noticesTab[1]}
                  alt={noticesTab[0]}
                />
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight:
                      noticesPageSelectedTab === noticesTab[0] ? 500 : 400,
                    fontSize: {
                      xs: contentMobileFontSize,
                      sm: contentFontSize,
                    },
                    textDecoration:
                      noticesPageSelectedTab === noticesTab[0]
                        ? "underline"
                        : "none",
                  }}
                >
                  {unSlugify(noticesTab[0])}
                </Typography>
                {isParentView &&
                  noticesTab[0] === ACTION_REQUIRED &&
                  unRespondedCount > 0 && (
                    <Typography
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "#f0f4d2",
                        width: 24,
                        height: 24,
                        ml: 1,
                        textAlign: "center",
                      }}
                    >
                      {unRespondedCount}
                    </Typography>
                  )}
              </Box>
            );
          })}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 0",
          width: "100%",
          overflow: "auto",
          alignSelf: "center",
        }}
      >
        {noticesPageSelectedTab === ANNOUNCEMENTS ? (
          <AnnouncementsPage />
        ) : noticesPageSelectedTab === DOCUMENTS ? (
          <DocumentsPage />
        ) : (
          <AnnouncementsPage isActionRequiredPage />
        )}
      </Box>
    </Box>
  );
};

export default TabSelectorPage;

import { StyleSheet, Font } from "@react-pdf/renderer";

import FuturaPT500 from "fonts/FuturaCyrillicBook.ttf";
import FuturaPT600 from "fonts/FuturaCyrillicDemi.ttf";
import FuturaPT700 from "fonts/FuturaCyrillicBold.ttf";
import FuturaPT800 from "fonts/FuturaCyrillicExtraBold.ttf";

Font.register({ family: "FuturaPT500", src: FuturaPT500 });
Font.register({ family: "FuturaPT600", src: FuturaPT600 });
Font.register({ family: "FuturaPT700", src: FuturaPT700 });
Font.register({ family: "FuturaPT800", src: FuturaPT800 });

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "25px 35px",
    fontFamily: "Jost",
    position: "relative",
    backgroundImage: "url(watermark)",
  },
  watermarkContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
  },
  watermarkImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  logoContainer: {
    width: 50,
    height: 16,
  },
  regularSection: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  regularSectionText: {
    marginTop: "10px",
    marginBottom: "10px",
    fontSize: "12px",
    display: "flex",
    flexWrap: "wrap",
  },
  headerSectionText: {
    marginTop: "5px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 100,
  },
  miniHeaderText: {
    marginTop: "5px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  activityHeaderText: {
    fontSize: "16px",
    fontStyle: "normal",
  },
  moduleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  moduleBox: {
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #79B5B4;",
    paddingLeft: "4px",
    paddingRight: "4px",
    marginRight: "10px",
  },
  moduleText: {
    fontSize: "10px",
  },
  titleSectionText: {
    fontSize: "24px",
    fontStyle: "normal",
  },
  questionBox: {
    backgroundColor: "#F2F2F2",
    height: "100px",
    width: "100%",
    borderRadius: "4px",
    marginTop: "8px",
  },
  questionNameWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: "12px",
  },
  devGoalWrapper: {
    height: "36px",
    display: "flex",
    flexWrap: "nowrap",
    backgroundColor: "#91C6C5",
    color: "#fff",
    borderRadius: "50%",
  },
  devGoalText: {
    margin: 0,
    fontSize: "12px",
    fontFamily: "FuturaPT500",
    display: "flex",
    flexWrap: "wrap",
  },
  englishTextTitle: {
    fontFamily: "FuturaPT700",
  },
  englishTextHeader: {
    fontFamily: "FuturaPT600",
  },
  englishTextSection: {
    fontFamily: "FuturaPT500",
  },
  chineseText: {
    fontFamily: "KaiTi",
  },
});

import React from "react";

import { PaymentHistory, PaymentProduct } from "types/payment";

import useStore from "store/store";
import "./constants/payment.css";
import PaymentProductComponent from "./PaymentProductComponent";
import { Box, Chip, Typography } from "@mui/material";
import { translation } from "constants/translation";

//mui datagrid
import { GridColumns, DataGrid } from "@mui/x-data-grid";
import {
  convertCentsToString,
  convertCurrencyStrToSymbol,
  convertToUpperCase,
  convertUnixTimestampToStrDate,
} from "./constants/helper";
import { DATA_GRID_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";

const PaymentHistoryComponent = () => {
  const { currLanguage, profileDetails, customerInfo } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    customerInfo: state.customerInfo,
  }));
  const [rows, setRows] = React.useState<PaymentHistory[]>(
    customerInfo.payment_history
  );
  // console.log("customerInfo: ", customerInfo);
  const columns: GridColumns = [
    {
      field: "",
      headerName: "SN",
      width: 50,
      filterable: false,
      sortable: false,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "created",
      type: "date",
      headerName: "Created At",
      width: 120,
      editable: false,
      valueFormatter: (params: any) =>
        convertUnixTimestampToStrDate(params?.value),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return (
          <Typography noWrap>
            {convertCurrencyStrToSymbol(params.row.currency)}
            {convertCentsToString(params.value)}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      editable: false,
      renderCell: (params: any) => (
        <Chip
          size="small"
          label={
            params?.value === "succeeded"
              ? "Paid"
              : `${convertToUpperCase(params?.value)}`
          }
          sx={{
            height: 24,
          }}
          color={
            params?.value === "succeeded"
              ? `success`
              : params?.value === "processing"
              ? `default`
              : "error"
          }
        />
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: false,
    },
  ];

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignSelf: "center",
        marginTop: 2,
      }}
    >
      {/* <Box>
        <Typography
          variant="h5"
          sx={{ fontWeight: 500 }}
        >{`Payments`}</Typography>
      </Box> */}
      <div style={DATA_GRID_STYLE}>
        <DataGrid
          // getRowHeight={() => "auto"}
          sx={{ fontSize: 18 }}
          rows={rows}
          columns={columns}
        />
      </div>
    </Box>
  );
};

export default PaymentHistoryComponent;

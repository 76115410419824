import { Box, Typography } from "@mui/material";
import { translation } from "constants/translation";
import useStore from "store/store";
import CheckpointQuestionScreen from "./CheckpointQuestionScreen";

// This is milestoneCheck
const CheckpointScreen = () => {
  const { currentProject } = useStore((state) => ({
    currentProject: state.currentProject,
  }));
  return (
    <Box sx={{ p: { xs: 0.5, sm: 2 } }}>
      <Typography
        sx={{
          // textDecoration: "underline",
          fontSize: 24,
          mb: 1,
          fontWeight: "500",
        }}
      >
        {translation.milestoneCheck}
      </Typography>
      <Typography sx={{ mt: "-8px" }}>
        {translation.milestoneCheckDescription}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {currentProject.checkpoint_fundamentals.map(
          (checkpoint_fundamental) => {
            return checkpoint_fundamental.checkpoints.map((checkpoint) => {
              return (
                <CheckpointQuestionScreen
                  key={`checkpoint-question-${checkpoint.name}`}
                  checkpointObject={checkpoint}
                />
              );
            });
          }
        )}
      </Box>
    </Box>
  );
};

export default CheckpointScreen;

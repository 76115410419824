import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { Button, Typography } from "@mui/material";
const EditOrSaveDraftButton = ({
  PDFInfo,
  draftPDFInfo,
  setSaveDraftModal,
}: {
  PDFInfo: any;
  draftPDFInfo: any;
  setSaveDraftModal: any;
}) => {
  return (
    <Button
      sx={{
        width: "100%",
        gap: 1,
        color: "#5C9391",
        border: "1px solid #5C9391",
      }}
      disabled={PDFInfo == draftPDFInfo}
      variant="outlined"
      onClick={() => {
        setSaveDraftModal(true);
      }}
    >
      <SaveOutlinedIcon sx={{ fontSize: "20px" }} />
      <Typography sx={{ textTransform: "none" }}>Save As Draft</Typography>
    </Button>
  );
};

export default EditOrSaveDraftButton;

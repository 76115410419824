import { AxiosError } from "axios";
import { API_Group_Project, SchoolProjectAccess } from "types/access";
import {
  API_Project_Access_Control,
  API_Project_Analytic,
  Query_Data,
} from "types/project";
import axios from "api/axiosConfig";
import { ViewMode } from "types/teacher";
import {
  ACCESS_CONTROL,
  INSIGHTS,
  VISUALIZATION,
} from "constants/staticConstant";

export const getProjectBySchoolID = async (
  schoolID: number,
  accessToken: string | null
) => {
  const res = await axios.get(`en/profile/access/school/${schoolID}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: { [projectID: string]: number } = await res.data;
  return data;
};

export const getFilteredProjectList = async ({
  pageMode,
  language,
  schoolID,
  queryData,
  recommendationFilters,
  moduleFilters,
  accessToken,
}: {
  pageMode: ViewMode;
  language: string;
  schoolID: number;
  queryData: Query_Data;
  recommendationFilters: string;
  moduleFilters: string;
  accessToken: string | null;
}) => {
  const apiPathType =
    pageMode === (INSIGHTS as ViewMode)
      ? "analytic"
      : pageMode === (ACCESS_CONTROL as ViewMode)
      ? "access-control"
      : pageMode === (VISUALIZATION as ViewMode)
      ? "chart"
      : "access-control";
  // console.log("moduleSlug: ", moduleFilters);
  const path =
    `${language}/cms/project/list/${apiPathType}?` +
    `recommendation_filters=${recommendationFilters}&` +
    `module_filters=${moduleFilters}&` +
    `school=${schoolID}&` +
    `search=${queryData.query || ""}` +
    (queryData.startDate ? `&start_date=${queryData.startDate}` : "") +
    (queryData.endDate ? `&end_date=${queryData.endDate}` : "");

  // console.log("path: ", path);
  const res = await axios.get(path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data: API_Project_Analytic[] | API_Project_Access_Control[] =
    await res.data;
  return data;
};

export const createAccess = async (
  schoolID: string,
  projectID: string,
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      `en/profile/access/create`,
      {
        school: schoolID,
        project: projectID,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: SchoolProjectAccess = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const bulkCreateAccess = async (
  schoolID: string,
  projectIDs: string[],
  accessToken: string | null
) => {
  try {
    const res = await axios.post(
      `en/profile/access/create/bulk`,
      {
        school: schoolID,
        projects: projectIDs,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: SchoolProjectAccess[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteAccess = async (
  accessID: number,
  accessToken: string | null
) => {
  try {
    await axios.delete(`en/profile/access/delete/${accessID}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const bulkDeleteAccess = async (
  accessIDs: number[],
  accessToken: string | null
) => {
  try {
    await axios.post(
      `en/profile/access/delete/bulk`,
      {
        accessIDs,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getProjectsBySchool = async (
  language: string,
  schoolID: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(
      `${language}/cms/project/getbyschool/${schoolID}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Group_Project[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

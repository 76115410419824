

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import CloseIcon from "@mui/icons-material/Close";
import CreateProfilePage from "containers/CreateProfilePage/CreateProfilePage";
import CreateChildPage from "containers/CreateProfilePage/CreateChildPage";
import { UserRoleAbbr } from "types/auth";

import { GridRowsProp } from "@mui/x-data-grid";

const CreateProfileModal = ({
  open,
  handleClose,
  schoolId = -1,
  groupId = -1,
  role,
  setRows,
  setChildFormData,
}: {
  open: boolean;
  handleClose: () => void;
  schoolId?: number;
  groupId?: number;
  role: UserRoleAbbr;
  setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setChildFormData?: any;
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            paddingY: 0.5,
            borderRadius: "10px",
            height: "90%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          {(role === "WAD" ||
            role === "AD" ||
            role === "TCS" ||
            role === "CE" ||
            role === "TTACE" ||
            role === "TR" ||
            role === "PR") && (
            <CreateProfilePage
              role={role}
              schoolId={schoolId}
              groupId={groupId}
              setRows={setRows}
              setChildFormData={setChildFormData}
              handleClose={handleClose}
            />
          )}

          {role === "CH" && (
            <CreateChildPage
              setRows={setRows}
              handleClose={handleClose}
              groupId={groupId}
            />
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateProfileModal;

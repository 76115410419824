import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Tooltip } from "@mui/material";


import {
  Age_Group,
} from "types/project";

import {
  deleteProject,
  projectLessonActivityNumber,
} from "api/project-api";

import {
  MARKETPLACE,
  CURLANG,
  PROJECT_MANAGE_PAGE,
} from "constants/url";
import { translation } from "constants/translation";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";

import { SPROUTS } from "constants/project-lesson-activity";
import MaterialButton from "components/Button/MaterialButton";

export const initialProjectMainFormData = {
  name: "",
  description: "",
  tags: "",
  objective: "",
  guiding_question: "",
  created_by: "",
  updated_by: "",
  duration: "", // in days
  age: SPROUTS as Age_Group,
  objective_fundamentals: [],
};

const DeleteProjectButton = ({
  handleClose,
  setShowDialog,
}: {
  handleClose: () => void;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const openSnack = useSnack();
  const navigate = useNavigate();

  const { profileDetails, currentProject, currLanguage } = useStore((state) => state);
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningContext, setWarningContext] = useState<string>("");
  const [lessonNumber, setLessonNumber] = useState<number>(-1);

  const handleDelete = async () => {
    setShowDialog(false);
    const res = await deleteProject(
      currentProject.id,
      localStorage.getItem("access")
    );
    if (res !== "success") {
      openSnack(res, false);
    } else {
      openSnack(translation.success_remove_project, true);

      if (profileDetails.role === "CE") {
        navigate(`${CURLANG(currLanguage)}/${PROJECT_MANAGE_PAGE}`);
      } else {
        navigate(`${CURLANG(currLanguage)}/${MARKETPLACE}`);
      }
      handleClose();
    }
  };

  useEffect(() => {
    const initialiseContext = async () => {
      const res = await projectLessonActivityNumber(
        currentProject.id,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        setLessonNumber(res.lessons);
        setWarningContext(
          `This action is irreversable! ${res.lessons} lesson${
            res.lessons > 1 ? "s" : ""
          } and ${res.activities} activit${
            res.activities > 1 ? "ies" : "y"
          } will be deleted as well.`
        );
      }
    };

    initialiseContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Project?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Tooltip
        title={"Please delete all the lessons before deleting the project!"}
      >
        <span>
          <MaterialButton
            buttonText="Delete"
            variant="text"
            sx={{
              opacity: lessonNumber === 0 ? 1 : 0.5,
            }}
            onClick={handleOpenDialog}
            disabled={lessonNumber !== 0}
          />
        </span>
      </Tooltip>
    </>
  );
};

export default DeleteProjectButton;

import React, { useEffect, useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import ScheduleGroupPageModalMobile from "./ScheduleGroupPageModalMobile";
import useModal from "hooks/useModal";
import useDialog from "hooks/useDialog";
import useSnack from "hooks/useSnack";
import WarningDialog from "components/Dialog/WarningDialog";
import { Box, Typography, List, ListItem, Avatar } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Logo from "images/logo-2-colour.png";

import useStore from "store/store";

import BackpackSideMenuMobile from "./BackpackSideMenu/BackpackSideMenuMobile";

import { deleteScheduleDatetime } from "api/calendar-api";
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const CalendarPageClassMobile = () => {
  const {
    currGroup,
    setCurrGroup,
    groupList,
    groupProjectBoxProps,
    setRefreshGroupBackpack,
  } = useStore((state) => ({
    currGroup: state.currGroup,
    setCurrGroup: state.setCurrGroup,
    groupList: state.groupList,
    groupProjectBoxProps: state.groupProjectBoxProps,
    setRefreshGroupBackpack: state.setRefreshGroupBackpack,
  }));
  const openSnack = useSnack();

  const { open, handleOpen, handleClose } = useModal();
  const [isUpdate, setIsUpdate] = useState<boolean>(true);
  const [backpackOpen, setBackpackOpen] = useState<boolean>(false);
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const { pathname } = useLocation();
  const groupSlug = pathname.split("/").pop();

  const groupMobile = groupList.filter((group) => group.slug === groupSlug)[0];
  useEffect(() => {
    if (currGroup.id != -1) {
      setRefreshGroupBackpack(true);
      if (currGroup.slug != groupSlug) {
        setCurrGroup(groupMobile);
      }
    }
  }, [currGroup]);
  const dateConversion = (date: string) => {
    const dateToConvert = new Date(date);
    const day = dateToConvert.getDate();
    const month = dateToConvert.getMonth();
    const year = dateToConvert.getFullYear();
    const monthName = monthNames[month];
    const formattedDate = `${day} ${monthName} ${year}`;
    return formattedDate;
  };

  const getProjectBoxes = () => {
    if (Object.keys(groupProjectBoxProps).length === 0) {
      return [];
    }
    const projects: any[] = groupProjectBoxProps[groupMobile?.id.toString()];
    projects.sort(
      (a: any, b: any) =>
        new Date(a.startAt).getTime() - new Date(b.startAt).getTime()
    );
    projects.forEach((project: any) => {
      project.startAtDisplay = dateConversion(project.startAt);
      project.endAtDisplay = dateConversion(project.endAt);
    });
    return projects;
  };

  const [projects, setProjects] = useState<any[]>(getProjectBoxes());

  const [projectCard, setProjectCard] = useState<any>({});

  const handleDelete = (id: number) => async () => {
    const res = await deleteScheduleDatetime(id);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack("The schedule has been deleted successfully!", true);
      const updatedProjects = projects.filter(
        (project: any) => project.id !== id
      );
      setProjects(updatedProjects);
    }
    handleClose();
  };
  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete Schedule for ${projectCard.projectTitle} from ${projectCard.startAt} to ${projectCard.endAt}?`,
    warningContext: "The action is irreversable!",
    handleDelete: handleDelete(projectCard.id),
  };

  useEffect(() => {
    setProjects(getProjectBoxes());
  }, [groupProjectBoxProps, groupMobile]);
  useEffect(() => {
    setProjects(getProjectBoxes());
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // backgroundColor: "lightblue",
        alignItems: "center",
      }}
    >
      <WarningDialog {...warningBody} />

      {/* MODAL HERE IS TO UPDATE */}
      {groupMobile && (
        <ScheduleGroupPageModalMobile
          open={open}
          setBackpackOpen={setBackpackOpen}
          handleClose={handleClose}
          projectId={projectCard.id}
          projectTitle={projectCard.projectTitle}
          groupId={groupMobile?.id}
          projectSlug={projectCard.projectSlug}
          projectModuleName={projectCard.moduleName}
          projectImage={projectCard.image}
          scheduleId={projectCard.scheduleId}
          scheduleDatetimeId={projectCard.id}
          startAt={projectCard.startAt}
          endAt={projectCard.endAt}
          isUpdate={isUpdate}
          setProjectCard={setProjectCard}
          handleOpenDialog={handleOpenDialog}
        />
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          p: "16px",
          gap: "8px",
        }}
      >
        <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
          {currGroup?.name}
        </Typography>
      </Box>
      {projects.length > 0 && (
        <>
          <Box
            sx={{
              // backgroundColor: "pink",

              display: "flex",
              justifyContent: "flex-start",
              width: "100%",

              px: "16px",
              pb: "16px",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#C4E4DF",
                borderRadius: "100px",
                py: "2px",
                px: "4px",
              }}
            >
              <Typography sx={{ fontWeight: "400", fontSize: "10px" }}>
                Little Projects
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#ECEA5C",
                borderRadius: "100px",
                py: "2px",
                px: "4px",
              }}
            >
              <Typography sx={{ fontWeight: "400", fontSize: "10px" }}>
                Little Learners
              </Typography>
            </Box>
          </Box>
          <List
            sx={{
              m: 0,
              p: 0,
              display: "flex",
              width: "100%",
              flexDirection: "column",
              // backgroundColor: "pink",
              gap: 1,
            }}
          >
            {projects.map((project: any, index: number) => {
              // console.log(project);
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                  onClick={() => {
                    setProjectCard(project);
                    setIsUpdate(true);
                    handleOpen();
                  }}
                >
                  <ListItem
                    sx={{
                      m: 0,
                      p: 0,
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          project.moduleCategoryName == "Little Projects"
                            ? "#F8FDFD"
                            : "#FFFFEF",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                        mx: "16px",

                        border: `1px solid ${
                          project.moduleCategoryName === "Little Projects"
                            ? "#91C6C5"
                            : "#ECEA5C"
                        }`,

                        borderLeft: `4px solid ${
                          project.moduleCategoryName === "Little Projects"
                            ? "#91C6C5"
                            : "#ECEA5C"
                        }`,
                        borderRadius: "4px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          m: "8px",
                        }}
                      >
                        <Avatar
                          src={project.image ? project.image : Logo}
                          sx={{
                            width: "40px",
                            height: "40px",

                            display: "flex",
                          }}
                          imgProps={{
                            onError: (
                              event: React.SyntheticEvent<
                                HTMLImageElement,
                                Event
                              >
                            ) => {
                              event.currentTarget.onerror = null;
                              event.currentTarget.src = Logo;
                            },
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            // backgroundColor: "pink",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "450", fontSize: "14px" }}
                          >
                            {project.moduleName}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "400", fontSize: "12px" }}
                          >
                            {project.projectTitle}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          mr: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          justifyContent: "space-between",
                          m: "8px",
                        }}
                      >
                        <EventIcon sx={{ fontSize: "16px", mb: 1 }} />
                        <Typography
                          sx={{
                            fontWeight: "400",
                            fontSize: "10px",
                            wrap: "nowrap",
                          }}
                        >
                          {project.startAtDisplay} - {project.endAtDisplay}
                        </Typography>
                        {/* <ScheduleProjectButton project={project} /> */}
                      </Box>
                    </Box>
                  </ListItem>
                  {/* {index !== projects.length - 1 && <Divider />} */}
                </Box>
              );
            })}
          </List>
        </>
      )}
      {projects.length == 0 && (
        <Typography>Add some Projects from BackPack!</Typography>
      )}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BackpackSideMenuMobile
          setProjectCard={setProjectCard}
          handleOpen={handleOpen}
          setIsUpdate={setIsUpdate}
          open={backpackOpen}
          setOpen={setBackpackOpen}
        />
      </Box>
    </Box>
  );
};

export default CalendarPageClassMobile;

import React from "react";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ExitFullscreenIcon from "@mui/icons-material/FullscreenExit";
import useStore from "store/store";

export const FullscreenButton = () => {
  const iconStyle = {
    cursor: "pointer",
    color: "txt.dark2",
    transition: "all 100ms ease-in-out",
    "&:hover": {
      color: "primary.main",
      transition: "all 100ms ease-in-out",
    },
  };

  const { setIsFullscreen } = useStore((state) => ({
    setIsFullscreen: state.setIsFullscreen,
  }));
  return document.fullscreenElement ? (
    <ExitFullscreenIcon
      fontSize="large"
      sx={iconStyle}
      onClick={() => {
        document.exitFullscreen();
        setIsFullscreen(false);
      }}
    />
  ) : (
    <FullscreenIcon
      fontSize="large"
      sx={iconStyle}
      onClick={() => {
        document
          .getElementById("lesson-activity-page-main-content")
          ?.requestFullscreen();
        setIsFullscreen(true);
      }}
    />
  );
};

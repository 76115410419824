import { useTheme } from "@mui/material";

const useColors = () => {
  const theme = useTheme();

  const colors = [
    theme.palette.custom.lightBlue,
    theme.palette.custom.lightOrange,
    theme.palette.custom.blue,
    theme.palette.custom.lime,
    theme.palette.custom.lightPink,
    // theme.palette.custom.grey,
    // theme.palette.custom.yellow,
  ];

  return colors;
};

export default useColors;

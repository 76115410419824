import { Box, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getCertificateByCredentialId } from "api/profile-api";
import { API_Certification } from "types/profile";
import { translation } from "constants/translation";
import { PDFPreviewComponent } from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkCard";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { dataInitialCertification } from "containers/ProfilePage/constants";

// Share buttons
import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

const TeacherCertificateLandingPagev2 = () => {
  const { credentialId } = useParams();
  const [certificate, setCertificate] = useState<API_Certification>(
    dataInitialCertification("-1")
  );

  useEffect(() => {
    if (credentialId === undefined) return;

    const populateCertificate = async () => {
      const res = await getCertificateByCredentialId(
        credentialId,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        setCertificate(res);
      }
    };

    populateCertificate();
  }, [credentialId]);

  return (
    <Box sx={{ px: 10, py: 6, mx: "auto" }}>
      <Typography
        sx={{
          fontSize: 36,
          fontWeight: 500,
          lineHeight: 1.25,
          mb: 1,
          textAlign: { lg: "left", xs: "center" },
        }}
      >
        {translation.formatString(
          translation.profileCertificate || "{profileName}'s {certificateName}",
          {
            profileName: certificate.profile.preferred_name,
            certificateName: "Trehaus Certificate",
          }
        )}
      </Typography>
      <Box
        sx={{
          // flex: 1,
          display: "flex",
          flexDirection: { lg: "row", xs: "column-reverse" },
          width: "100%",
          // maxWidth: "1000px",
          // display: "grid",
          // gridTemplateColumns: {
          //   xs: "1fr",
          //   lg: "repeat(2, 1fr)",
          // },
        }}
      >
        {certificate.id === -1 ? (
          <LoadingIndicator />
        ) : (
          <>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: 18, lineHeight: 1.5, mb: 3.5 }}>
                {translation.formatString(
                  translation.profileCertificateSubtitle ||
                    "The certificate below verifies that {name} has completed all of the modules in Trehaus Teacher Academy on {date}.",
                  {
                    name: (
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 500,
                          lineHeight: 1.5,
                        }}
                      >
                        {certificate.profile.preferred_name}
                      </Typography>
                    ),
                    date: (
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 500,
                          lineHeight: 1.5,
                        }}
                      >
                        {moment(certificate.issue_date).format("DD MMM YYYY")}
                      </Typography>
                    ),
                  }
                )}
              </Typography>

              {certificate.description !== "" && (
                <>
                  <Typography
                    sx={{
                      fontSize: 18,
                      lineHeight: 1.5,
                      fontWeight: 500,
                      mb: 1,
                    }}
                  >
                    {translation.formatString(
                      translation.mastered || "{name} has mastered how to:",
                      {
                        name: certificate.profile.preferred_name,
                      }
                    )}
                  </Typography>

                  <Box
                    className="ck-content"
                    sx={{ fontSize: 18, lineHeight: 1.5 }}
                    dangerouslySetInnerHTML={{
                      __html: certificate.description,
                    }}
                  />
                </>
              )}
              {certificate.certificate && (
                <Box sx={{ marginTop: 2, width: "100%" }}>
                  <a
                    href={certificate.certificate!}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      sx={{ color: "white" }}
                      // style={{ width: "60%" }}
                    >
                      Download Certificate
                    </Button>
                  </a>
                </Box>
              )}
            </Box>

            {certificate.certificate && (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component="a"
                  href={certificate.certificate}
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    display: "flex",
                    borderRadius: "10px",
                    overflow: "hidden",
                    boxShadow: 3,
                    mb: 4,
                    width: "100%",
                    maxWidth: "600px",
                  }}
                >
                  <PDFPreviewComponent file={certificate.certificate} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 2,
                  }}
                >
                  <FacebookShareButton
                    url={
                      window.location.href.toString().includes("localhost:")
                        ? certificate.certificate
                        : window.location.href
                    }
                    // quote={
                    //   "I'm thrilled to announce that I've just obtained my Trehaus certificate, showcasing my dedication to continued learning and professional development!"
                    // }
                    hashtag="#trehaus"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={
                      window.location.href.toString().includes("localhost:")
                        ? "https://" + certificate.certificate
                        : window.location.href
                    }
                    title={
                      "I'm thrilled to announce that I've just obtained my Trehaus certificate, showcasing my dedication to continued learning and professional development!"
                    }
                    hashtags={["#trehaus"]}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={
                      window.location.href.toString().includes("localhost:")
                        ? certificate.certificate
                        : window.location.href
                    }
                    // summary={
                    //   "I'm thrilled to announce that I've just obtained my Trehaus certificate, showcasing my dedication to continued learning and professional development!"
                    // }
                    // hashtag="#trehaus"
                    // description={""}
                    // source={""}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TeacherCertificateLandingPagev2;

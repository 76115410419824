import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMediaQuery, useTheme, Button, ButtonProps } from "@mui/material";

type Props = Pick<ButtonProps,
    "size" |
    "onClick" |
    "children" |
    "endIcon" |
    "startIcon" |
    "variant" |
    "type" |
    "sx" |
    "disabled"
    > & {
    arrowForward?: boolean;
    buttonText: string;
    isLoading?: boolean;
    clampText?: boolean;
}

const MaterialButton = ({
    buttonText,
    endIcon,
    startIcon,
    sx,
    size,
    variant = "contained",
    isLoading = false,
    disabled = false,
    clampText = false,
    arrowForward = false,
    type = "submit",
    onClick,
}: Props) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("sm"));
    const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const clampTextLength = () => {
        if (isXs) return 10;
        if (isSm) return 20;
        if (isMd) return 30;
        if (isLg) return 40;
        return buttonText.length;
    };
    const clampedButtonText = clampText
        ? `${buttonText.slice(0, clampTextLength())}${
                buttonText.length > clampTextLength() ? "..." : ""
            }`
        : buttonText;
        
    const arrowColor = variant === "contained" ? "#fff" : "cyan.700";
    
    
    return (
        <Button
            sx={{
                padding: "8px 22px",
                color: variant === "contained" ? "#fff" : "cyan.700",
                fontSize: "16px",
                fontWeight: "500",
                backgroundColor: variant === "contained" ? "cyan.500" : "transparent",
                lineHeight: 1.5,
                textTransform: "none",
                ...sx,
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.preventDefault();
                !disabled && onClick && onClick(event);
            }}
            type={type}
            disabled={disabled}
            variant={variant}
            endIcon={arrowForward ? <ArrowForwardIcon sx={{color: disabled ? "" : arrowColor}} /> : endIcon}
            startIcon={startIcon}
        >
            {clampedButtonText}
        </Button>
    );
};

export default MaterialButton;

import React from "react";
import { useEffect } from "react";
import MarketPlaceSearch from "./MarketPlaceSearch/MarketPlaceSearch";
import ReactGA from "react-ga4";
const MarketPlacePage = () => {
  // google analytics page view for lesson library
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/library",
      title: "Little Lab | Lesson Plan Library",
    });
  }, []);

  return (
    <div>
      {/* <MarketPlaceMain /> */}
      <MarketPlaceSearch />
    </div>
  );
};

export default MarketPlacePage;

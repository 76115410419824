import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, Stack, Divider } from "@mui/material";

import Button from "components/Button/Button";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  CURLANG,
  TEACHER,
  EDIT_TEACHER_MODULE_PAGE,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";

import { translation } from "constants/translation";

//Prompt user when leaving without saving
import BlockLeavingDialog from "components/Dialog/BlockLeavingDialog";
import useCallbackPrompt from "hooks/useCallbackPrompt";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";

import {
  NameField,
  DescriptionField,
  ImageField,
  PublishField,
} from "../TeacherFormComponents/ModuleFormComponents";

import useInitialiseCurrModule from "hooks/useInitialiseCurrModule";
import {
  deleteTeacherModule,
  editTeacherModule,
} from "api/teacher-api";
import {
  API_Teacher_Module_Create,
  API_Teacher_Module_Edit,
} from "types/teacher";

const EditModuleForm = ({
  moduleId,
  handleClose,
}: {
  moduleId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();
  const { moduleSlug } = useParams();
  const navigate = useNavigate();

  const {
    teacherCurrentModule,
    currLanguage,
    isTeacherModuleLoading,
    setIsRefreshTeacherModule,
  } = useStore((state) => ({
    teacherCurrentModule: state.teacherCurrentModule,
    currLanguage: state.currLanguage,
    isTeacherModuleLoading: state.isTeacherModuleLoading,
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));

  useInitialiseCurrModule(true);

  const [image, setImage] = useState<File | string>(
    teacherCurrentModule.image ? teacherCurrentModule.image : ""
  );
  // const [filledRequired, setFilledRequired] = useState<boolean>(false);
  const [updatedModuleInfo, setUpdatedModuleInfo] =
    useState<API_Teacher_Module_Create>({
      name: teacherCurrentModule.name,
      description: teacherCurrentModule.description,
      is_published: teacherCurrentModule.is_published,
    });

  // useEffect(() => {
  //   if (updatedModuleInfo.name !== "" && updatedModuleInfo.description !== "") {
  //     setFilledRequired(true);
  //   } else {
  //     setFilledRequired(false);
  //   }
  // }, [updatedModuleInfo, image]);

  const handleSubmit = async () => {
    if (updatedModuleInfo.name === "") {
      //filled
      openSnack("Module name is required!", false);
      return;
    }
    const editData: API_Teacher_Module_Edit = {
      id: moduleId,
    };

    if (updatedModuleInfo.name !== teacherCurrentModule.name)
      editData.name = updatedModuleInfo.name;
    if (updatedModuleInfo.description !== teacherCurrentModule.description)
      editData.description = updatedModuleInfo.description;
    if (updatedModuleInfo.is_published !== teacherCurrentModule.is_published)
      editData.is_published = updatedModuleInfo.is_published;
    if (image !== teacherCurrentModule.image) editData.image = image;
    // console.log("updatedModuleInfo: ", updatedModuleInfo);
    // console.log("teacherCurrentModule: ", teacherCurrentModule);
    // console.log("editData: ", editData);
    //Update module
    const res = await editTeacherModule(currLanguage, editData);

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      // console.log("res: ", res);
      // console.log("moduleSlug: ", moduleSlug);
      // console.log("res.slug === moduleSlug: ", res.slug === moduleSlug);
      // console.log(
      //   "`${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(res.slug)}`: ",
      //   `${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(res.slug)}`
      // );
      openSnack("Edited module successfully!", true);
      setIsRefreshTeacherModule(true);
      handleClose();
      if (res.slug === moduleSlug) {
      } else {
        navigate(
          `${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(res.slug)}`
        );
      }
    }
  };
  const [showDialog, setShowDialog] = useState<boolean>(false);
  // const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
  //   useCallbackPrompt(showDialog);

  // const dialogBody = {
  //   showDialog: showPrompt,
  //   confirmNavigation,
  //   cancelNavigation,
  //   handleAction: handleSubmit,
  // };

  return isTeacherModuleLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      {/* <BlockLeavingDialog {...dialogBody} /> */}

      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <PublishField
          data={updatedModuleInfo}
          setData={setUpdatedModuleInfo}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />
        <NameField
          data={updatedModuleInfo}
          setData={setUpdatedModuleInfo}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <DescriptionField
          data={updatedModuleInfo}
          setData={setUpdatedModuleInfo}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <ImageField image={image} setImage={setImage} />

        <Divider />

        <DeleteModuleButton moduleId={moduleId} handleClose={handleClose} />

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          <Button
            buttonText={translation.update || "Update"}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
              height: "auto",
            }}
            onClick={() => {
              handleSubmit();
            }}
            // disabled={!filledRequired}
          />
        </Box>
      </Stack>
    </>
  );
};

const DeleteModuleButton = ({
  moduleId,
  handleClose,
}: {
  moduleId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();
  const navigate = useNavigate();

  const { profileDetails, teacherCurrentModule, currLanguage } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      teacherCurrentModule: state.teacherCurrentModule,
      currLanguage: state.currLanguage,
    })
  );
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteTeacherModule(
      moduleId,
      localStorage.getItem("access")!
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(
        translation.successDeleteModule || "Module Deleted Successfully",
        true
      );

      handleClose();
      navigate(`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`);
    }
  };

  useEffect(() => {
    const initialiseContext = async () => {
      setWarningContext(
        `This action is irreversable! Everything in this module will be deleted!`
      );
      // const res = await projectLessonActivityNumber(
      //   teacherCurrentModule.id,
      //   localStorage.getItem("access")
      // );

      // if (typeof res !== "string") {
      //   setWarningContext(
      //     `This action is irreversable! ${res.lessons} lesson${
      //       res.lessons > 1 ? "s" : ""
      //     } and ${res.activities} activit${
      //       res.activities > 1 ? "ies" : "y"
      //     } will be deleted as well.`
      //   );
      // }
    };

    initialiseContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Module?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <Button
        arrow={false}
        buttonText={"Delete Module"}
        btnType="outlined"
        style={{
          fontSize: "14px",
          marginTop: 0,
        }}
        onClick={handleOpenDialog}
      />
    </>
  );
};

export default EditModuleForm;

import { translation } from "constants/translation";
import { DEVELOPMENTAL_GOALS, PROFILE } from "constants/url";
import { slugify } from "helper/slugify";

export const PROGRESS_REPORT = "progress-report";
export const PHOTO_ALBUM = "photo-album";
export const ALL_PHOTOS = "all-photos";
export const ALBUMS = "albums";
export const COMPLETED_WORKS = "little-works";
export const PROJECT_SUMMARY = "project-summary";
export const LEARNING_STORIES = "learning-stories";

export const tabs: string[] = [
  PROFILE,
  DEVELOPMENTAL_GOALS,
  PROGRESS_REPORT,
  COMPLETED_WORKS,
  LEARNING_STORIES,
  PHOTO_ALBUM,
  PROJECT_SUMMARY,
];

export const translatedTabs: { [key: string]: string } = {
  [PROFILE]: translation.profile,
  [DEVELOPMENTAL_GOALS]: translation.developmentalGoals,
  [PROGRESS_REPORT]: translation.progressReport,
  [COMPLETED_WORKS]: translation.completedWorks,
  [PHOTO_ALBUM]: translation.photoAlbum,
  [ALL_PHOTOS]: translation.allPhotos,
  [LEARNING_STORIES]: translation.learningStories,
  [ALBUMS]: translation.albums,
  [PROJECT_SUMMARY]: translation.projectSummary,
};

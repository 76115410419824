import React from 'react';

import { Link } from 'react-router-dom';

import { LOGIN } from 'constants/url';

const ProtectedLink = ({
  isAuth,
  children,
}: {
  isAuth?: boolean;
  children: React.ReactElement;
}) => {
  if (isAuth) {
    return children;
  } else {
    return <Link to={LOGIN} />;
  }
};

export default ProtectedLink;

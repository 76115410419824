import useStore from "store/store";

export const useInsights = () => {
  const {
    currGroup,
    currentProject,
    currGroupBackpack,
    groupProjectBoxProps,
  } = useStore((state) => state);

  const isInBag = () => {
    return (
      currGroupBackpack &&
      currGroupBackpack.projects.filter(
        (project) => project.id === currentProject.id
      ).length !== 0
    );
  };

  const isInSchedule = () => {
    return (
      groupProjectBoxProps[currGroup.id] &&
      groupProjectBoxProps[currGroup.id].filter(
        (project) => project.projectSlug === currentProject.slug
      ).length !== 0
    );
  };
     
  return { isInBag, isInSchedule };
}
import React from "react";
import { Box, IconButton, SxProps, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import useStore from "store/store";
import {
  CURLANG,
  TEACHER_MODULE_MANAGE_PAGE,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";
import { ViewMode } from "types/teacher";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import {
  ACCESS_CONTROL,
  INSIGHTS,
  VISUALIZATION,
} from "constants/staticConstant";

const FirstRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2.5,
      }}
    >
      <BreadCrumbs />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2.75,
        }}
      >
        {children}

        <TTAIcons />
      </Box>
    </Box>
  );
};

const BreadCrumbs = () => {
  const navigate = useNavigate();
  const { currLanguage, profileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      <Typography
        sx={{
          cursor: "pointer",
          lineHeight: 1,
        }}
        onClick={() => {
          navigate(`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`);
        }}
      >
        Module Library
      </Typography>

      <NavigateNextIcon fontSize="small" />

      <Typography sx={{ fontWeight: "bold", color: "#407374", lineHeight: 1 }}>
        Manage Modules
      </Typography>
    </Box>
  );
};

export const TTAIcons = () => {
  const { pageModeSlug } = useParams();
  const navigate = useNavigate();
  const iconStyle: SxProps = {
    color: "#312F30",
    cursor: "pointer",
  };

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      <IconButton
        aria-label="access-control-icon-button"
        onClick={() => {
          navigate(
            `${CURLANG(
              currLanguage
            )}/${TEACHER_MODULE_MANAGE_PAGE}/${ACCESS_CONTROL}`
          );
        }}
      >
        <LockOutlinedIcon
          fontSize="small"
          sx={{
            ...iconStyle,
            color: pageModeSlug === ACCESS_CONTROL ? "#407374" : "default",
            opacity: pageModeSlug === ACCESS_CONTROL ? 1 : 0.5,
            pointerEvents: "none",
          }}
        />
      </IconButton>
      <IconButton
        aria-label="analytic-icon-button"
        onClick={() => {
          navigate(
            `${CURLANG(currLanguage)}/${TEACHER_MODULE_MANAGE_PAGE}/${INSIGHTS}`
          );
          // setPageMode(INSIGHTS as ViewMode);
        }}
      >
        <TableChartOutlinedIcon
          fontSize="small"
          sx={{
            ...iconStyle,
            pointerEvents: "none",
            opacity: pageModeSlug === INSIGHTS ? 1.0 : 0.5,
            color: pageModeSlug === INSIGHTS ? "#407374" : "default",
          }}
        />
      </IconButton>
      <IconButton
        aria-label="chart-icon-button"
        onClick={() => {
          navigate(
            `${CURLANG(
              currLanguage
            )}/${TEACHER_MODULE_MANAGE_PAGE}/${VISUALIZATION}`
          );
        }}
      >
        <AssessmentOutlinedIcon
          fontSize="small"
          sx={{
            ...iconStyle,
            pointerEvents: "none",
            opacity: pageModeSlug === VISUALIZATION ? 1.0 : 0.5,
            color: pageModeSlug === VISUALIZATION ? "#407374" : "default",
          }}
        />
      </IconButton>
    </Box>
  );
};

export default FirstRow;

import React from "react";
import { Box } from "@mui/material";
import { API_Custom_Timetable } from "types/timetable";
import Image from "components/Image/Image";
import {
  UploadNewTimetableButton,
  RemoveTimetableButton,
} from "./CustomTimetableComponents";

const ViewCustomTimetable = ({
  timetable,
  setTimetable,
  isViewOnly,
}: {
  timetable: API_Custom_Timetable;
  setTimetable: React.Dispatch<
    React.SetStateAction<API_Custom_Timetable | null>
  >;
  isViewOnly: boolean;
}) => {
  const isPDF = timetable.file.endsWith(".pdf");

  return (
    <>
      {isPDF ? (
        <object
          data={timetable.file}
          type="application/pdf"
          style={{
            flex: 1,
          }}
        >
          <embed
            src={timetable.file}
            type="application/pdf"
            style={{
              flex: 1,
            }}
          />
        </object>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Image
            src={timetable.file}
            alt=""
            style={{
              maxWidth: "100%", 
              maxHeight: "100%",
              objectFit: "contain", 

            }}
          />
        </Box>
      )}

      {!isViewOnly && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <UploadNewTimetableButton
            timetable={timetable}
            setTimetable={setTimetable}
          />
          <RemoveTimetableButton
            timetable={timetable}
            setTimetable={setTimetable}
          />
        </Box>
      )}
    </>
  );
};

export default ViewCustomTimetable;

import React, { useEffect, useState } from "react";

import { Box, Tooltip, IconButton } from "@mui/material";

import useStore from "store/store";

import { API_Profile_Short } from "types/profile";

import "components/ProjectSummaryScreen/ReactGridGallery.css";
import { translation } from "constants/translation";
import { isProfileInGalleryAccessRoleList } from "helper/helper";
import TagIcon from "@mui/icons-material/Tag";
import CompletedWorkProfileTaggingMenu from "./CompletedWorkChildTaggingMenu";
import { tagProfilesToCompletedWork } from "api/completed-works-api";

const CompletedWorkChildTagging = ({
  completedWorkId,
  selectedChildList,
  setSelectedChildList,
}: {
  completedWorkId: number;
  selectedChildList: number[];
  setSelectedChildList: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { currGroup, profileDetails, currLanguage, setIsRefreshCompletedWork } =
    useStore((state) => ({
      currGroup: state.currGroup,
      profileDetails: state.profileDetails,
      currLanguage: state.currLanguage,
      setIsRefreshCompletedWork: state.setIsRefreshCompletedWork,
    }));

  const [profileDict, setProfileDict] = useState<{
    [id: string]: API_Profile_Short;
  }>({});

  useEffect(() => {
    if (currGroup.id === -1) return;

    const initializeProfiles = async () => {
      const profileList = currGroup.children.reduce((acc, child) => {
        acc[child.id.toString()] = {
          id: child.id,
          image: child.image,
          preferred_name: child.preferred_name,
          full_name: child.full_name ? child.full_name : "",
          dob: child.dob,
          school: child.school,
        };
        return acc;
      }, {} as { [id: string]: API_Profile_Short });
      setProfileDict(profileList);
    };
    initializeProfiles();

    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLanguage, currGroup]);

  // MENU
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = async () => {
    setAnchorEl(null);
    if (completedWorkId !== -1) {
      await tagProfilesToCompletedWork(completedWorkId, selectedChildList);

      setTimeout(() => setIsRefreshCompletedWork(true), 1000);
    }
  };

  return (
    <Box>
      {isProfileInGalleryAccessRoleList(profileDetails.role) && (
        <>
          {Object.keys(profileDict).length !== 0 && (
            <Tooltip title={translation.tagChildren}>
              <IconButton
                onClick={handleOpenMenu}
                size="small"
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 17,
                }}
                aria-controls={"account-menu"}
                aria-haspopup="true"
                aria-expanded={"true"}
              >
                <TagIcon
                  sx={{
                    color: "txt.dark",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <CompletedWorkProfileTaggingMenu
        anchorEl={anchorEl}
        isOpenMenu={isOpenMenu}
        handleCloseMenu={handleCloseMenu}
        selectedChildList={selectedChildList}
        setSelectedChildList={setSelectedChildList}
        profileDict={profileDict}
      />
    </Box>
  );
};

export default CompletedWorkChildTagging;

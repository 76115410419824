import { useState } from "react";
import {
  Typography,
  SxProps,
  TextField,
  Box,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ReplyIcon from "@mui/icons-material/Reply";
import CommentIcon from "@mui/icons-material/Comment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import {
  API_Comment_Like,
  API_Create_Comment_Like,
  API_Create_Review_Comment,
  API_Create_Review_Like,
  API_Review_Comment,
  API_Review_Like,
  API_Update_Review_Comment,
} from "types/review";
import {
  likeReview,
  unlikeReview,
  createComment,
  likeComment,
  unlikeComment,
  updateComment,
} from "api/review-api";
import { translation } from "constants/translation";
import Logo from "images/logo-2-colour.png";

export const textStyles: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: 0.75,
  fontSize: "0.875rem",
  lineHeight: 1,
  cursor: "pointer",
};

export const iconStyles: SxProps = {
  fontSize: "1rem",
  color: "#C4C4C4",
};

export const likedStyles: SxProps = {
  color: "primary.main",
};

export const smallTextStyles: SxProps = {
  fontSize: "0.75rem",
};

export const smallIconStyles: SxProps = {
  fontSize: "0.875rem",
};

export const Profile = ({
  name,
  role,
  school,
}: {
  name: string;
  role: string;
  school: string;
}) => (
  <Box>
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "1.25",
      }}
    >
      {name}
    </Typography>

    <Typography sx={{ fontSize: "0.875rem", color: "#444" }}>
      {`${role}${school ? `, ${school}` : ""}`}
    </Typography>
  </Box>
);

export const MoreButton = ({
  type = "review", // review, comment
  handleEdit,
  handleDelete,
  isDeleteOnly,
}: {
  type: string;
  handleEdit: () => void;
  handleDelete: () => void;
  isDeleteOnly: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        sx={{ p: 0, mr: -1 }}
        onClick={handleClick}
        disableRipple
      >
        <MoreVertIcon
          sx={{ fontSize: type === "review" ? "1.25rem" : "1.125rem" }}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "150px",
          },
        }}
      >
        {!isDeleteOnly && (
          <MenuItem
            onClick={() => {
              handleEdit();
              handleClose();
            }}
          >
            {translation.edit}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleDelete();
            handleClose();
          }}
        >
          {translation.delete}
        </MenuItem>
      </Menu>
    </>
  );
};

const CONFIG_ORIG = {
  future: "in %s",
  past: "%s ago",
  s: "a few seconds",
  ss: "%d seconds",
  m: "1 minute",
  mm: "%d minutes",
  h: "1 hour",
  hh: "%d hours",
  d: "1 day",
  dd: "%d days",
  w: "1 week",
  ww: "%d weeks",
  M: "1 month",
  MM: "%d months",
  y: "1 year",
  yy: "%d years",
};

const CONFIG_NEW = {
  future: "in %s",
  past: "%s ago",
  s: "%ds",
  ss: "%ds",
  m: "1m",
  mm: "%dm",
  h: "1h",
  hh: "%dh",
  d: "1d",
  dd: "%dd",
  w: "1w",
  ww: "%dw",
  M: "1mth",
  MM: "%dmth",
  y: "1y",
  yy: "%dy",
};

export const Time = ({
  type = "review", // review, comment
  created_at,
  updated_at,
}: {
  type: string;
  created_at: string;
  updated_at: string;
}) => {
  const shortTimeFormat = type !== "review";

  if (shortTimeFormat) moment.updateLocale("en", { relativeTime: CONFIG_NEW });
  const fromNow = moment(updated_at).fromNow(shortTimeFormat);
  if (shortTimeFormat) moment.updateLocale("en", { relativeTime: CONFIG_ORIG });

  const formattedDate = moment(updated_at).format(
    "dddd, MMMM D, YYYY [at] h:mm A"
  );

  const isEdited = !moment(created_at).isSame(updated_at);

  return (
    <Tooltip title={formattedDate}>
      <Typography
        sx={{
          fontSize: "0.75rem",
          lineHeight: 1,
          color: "#444",
          ml: type === "review" ? 1 : 0,
        }}
      >
        {fromNow}
        {/* {isEdited && (
          <span
            style={{
              marginLeft: "4px",
              textTransform: type === "review" ? "none" : "capitalize",
            }}
          >
            - {translation.edited}
          </span>
        )} */}
      </Typography>
    </Tooltip>
  );
};

export const Like = ({
  type = "review", // review, comment
  likes,
  id,
}: {
  type: string;
  likes: API_Review_Like[] | API_Comment_Like[];
  id: number;
}) => {
  const openSnack = useSnack();

  const { currLanguage, setRefreshProjAndLess, profileID } = useStore(
    (state) => ({
      currLanguage: state.currLanguage,
      setRefreshProjAndLess: state.setRefreshProjAndLess,
      profileID: state.profileID,
    })
  );

  const numOfLikes: number = likes.length;
  const myLike = likes.find(({ profile }) => profile.toString() === profileID);
  const isLiked: boolean = myLike ? true : false;

  const handleLike = async () => {
    const likeData: API_Create_Review_Like | API_Create_Comment_Like =
      type === "review"
        ? {
            review: id,
            profile: profileID,
          }
        : {
            comment: id,
            profile: profileID,
          };

    const res =
      type === "review"
        ? await likeReview(
            currLanguage,
            likeData as API_Create_Review_Like,
            localStorage.getItem("access")
          )
        : await likeComment(
            currLanguage,
            likeData as API_Create_Comment_Like,
            localStorage.getItem("access")
          );

    if (res !== "Error") {
      setRefreshProjAndLess(true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const handleUnlike = async () => {
    if (!myLike) return;

    const res =
      type === "review"
        ? await unlikeReview(
            currLanguage,
            myLike.id,
            localStorage.getItem("access")
          )
        : await unlikeComment(
            currLanguage,
            myLike.id,
            localStorage.getItem("access")
          );

    if (res !== "Error") {
      setRefreshProjAndLess(true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const textStyling: SxProps = isLiked
    ? {
        ...textStyles,
        ...likedStyles,
        ...(type === "review" ? {} : smallTextStyles),
      }
    : {
        ...textStyles,
        ...(type === "review" ? {} : smallTextStyles),
      };

  const iconStyling: SxProps = isLiked
    ? {
        ...iconStyles,
        ...likedStyles,
        ...(type === "review" ? {} : smallIconStyles),
      }
    : {
        ...iconStyles,
        ...(type === "review" ? {} : smallIconStyles),
      };

  return (
    <Typography sx={textStyling} onClick={isLiked ? handleUnlike : handleLike}>
      <ThumbUpIcon sx={iconStyling} />
      {numOfLikes !== 0 ? numOfLikes : translation.like}
    </Typography>
  );
};

export const CommentOrReply = ({
  type = "comment", // comment, reply
  onClick,
}: {
  type: string;
  onClick: () => void;
}) => (
  <Typography
    sx={{
      ...textStyles,
      ...(type === "comment" ? {} : smallTextStyles),
    }}
    onClick={onClick}
  >
    {type === "comment" ? (
      <>
        <CommentIcon
          sx={{
            ...iconStyles,
            ...(type === "comment" ? {} : smallIconStyles),
          }}
        />
        {translation.comment}
      </>
    ) : (
      <>
        <ReplyIcon
          sx={{
            ...iconStyles,
            ...(type === "comment" ? {} : smallIconStyles),
            transform: "scale(1.125)",
            mr: -0.125,
          }}
        />
        {translation.reply}
      </>
    )}
  </Typography>
);

export const InputBar = ({
  type = "add", // add, edit, reply
  id,
  parent,
  message,
  setMessage,
  setShow,
  children,
}: {
  type: string;
  id: number;
  parent?: API_Review_Comment;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}) => {
  const openSnack = useSnack();

  const { profile, currLanguage, setRefreshProjAndLess, profileID } = useStore(
    (state) => ({
      profile: state.profileDetails,
      currLanguage: state.currLanguage,
      setRefreshProjAndLess: state.setRefreshProjAndLess,
      profileID: state.profileID,
    })
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addComment = async () => {
    setIsLoading(true);

    const commentData: API_Create_Review_Comment = {
      review: id,
      profile: profileID,
      message,
    };

    if (type === "reply" && parent) {
      commentData["parent"] = parent.id;
    }

    const res = await createComment(
      currLanguage,
      commentData,
      localStorage.getItem("access")
    );

    setIsLoading(false);

    if (res !== "Error") {
      reset();
      setRefreshProjAndLess(true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const editComment = async () => {
    setIsLoading(true);

    const commentData: API_Update_Review_Comment = {
      message,
    };

    const res = await updateComment(
      currLanguage,
      id,
      commentData,
      localStorage.getItem("access")
    );

    setIsLoading(false);

    if (res !== "Error") {
      reset();
      setRefreshProjAndLess(true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const reset = () => {
    setMessage("");
    setShow(false);
  };

  const handleSubmit = () => {
    if (message) {
      if (type === "add" || type === "reply") {
        addComment();
      } else if (type === "edit") {
        editComment();
      }
    }
  };

  const helperTextStyles: SxProps = {
    fontSize: "0.75rem",
    lineHeight: 1,
  };

  const helperLinkStyles: SxProps = {
    cursor: "pointer",
    color: "txt.dark",
    textDecoration: "underline",
    transition: "all 100ms ease-in-out",
    "&:hover": {
      color: "txt.secondary",
      transition: "all 100ms ease-in-out",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        opacity: isLoading ? 0.7 : 1,
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      <Avatar
        src={profile.image}
        alt={profile.preferred_name}
        sx={{ width: "32px", height: "32px", mt: 0.5 }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      <Box
        sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Box sx={{ width: "100%" }}>
          <TextField
            autoFocus
            variant="standard"
            placeholder={
              type === "add" || type === "edit"
                ? translation.writeAComment
                : `${translation.formatString(translation.replyTo, {
                    name: parent ? parent.profile.preferred_name : "User",
                  })}`
            }
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyDown={(event) => {
              if (isLoading) {
                event.preventDefault();
              } else if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                handleSubmit();
              } else if (event.key === "Escape") {
                event.preventDefault();
                reset();
              }
            }}
            fullWidth
            multiline
            rows={3}
            InputProps={{
              sx: {
                py: 1,
                px: 1.5,
                backgroundColor: "#FBFBFB",
                borderRadius: "10px",
              },
              disableUnderline: true,
            }}
          />

          <Typography
            sx={{
              ...helperTextStyles,
              mt: 1,
              mx: 1.5,
              color: "txt.dark2",
            }}
          >
            Press "Enter" to{" "}
            <Typography
              component="span"
              sx={{ ...helperTextStyles, ...helperLinkStyles }}
              onClick={handleSubmit}
            >
              submit
            </Typography>
            , "Esc" to{" "}
            <Typography
              component="span"
              sx={{ ...helperTextStyles, ...helperLinkStyles }}
              onClick={reset}
            >
              cancel
            </Typography>
          </Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
};


import useStore from "store/store";



import { Box } from "@mui/material";


import {
  GroupProjectBoxProps,
} from "types/calendar";

import {
  LITTLE_LEARNERS,
  RIGHT_COLUMN_WIDTH,
} from "containers/CalendarPage/constants/constants";
import GridRow from "./GridRow";
import { API_Group_Full } from "types/group";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const RightColumnBody = ({
  projectBoxes,
  groupList,
}: {
  projectBoxes: GroupProjectBoxProps;
  groupList: API_Group_Full[];
}) => {
  const {
    // groupList,
    setStartAndEndDate,
    calendarViews,
    calendarStartDate,
    isLoadingCalendar,
  } = useStore((state) => ({
    // groupList: state.groupList,
    setStartAndEndDate: state.setStartAndEndDate,
    calendarViews: state.calendarViews,
    calendarStartDate: state.calendarStartDate,
    isLoadingCalendar: state.isLoadingCalendar,
  }));

  // useEffect(() => {
  //   // console.log("Getting calendar start date...");
  //   // get current view: week / month / 3-MONTHS
  //   const currentView: CalendarViewOptions = Object.keys(calendarViews).filter(
  //     (view) => calendarViews[view as CalendarViewOptions] === true
  //   )[0] as CalendarViewOptions;
  //   // populate the days state with calculated days
  //   if (currentView === "Month") {
  //     setStartAndEndDate(computeMonthDates(calendarStartDate));
  //   } else if (currentView === "Week") {
  //     setStartAndEndDate(computeWeekDates(calendarStartDate));
  //   } else if (currentView === "Year") {
  //     setStartAndEndDate(computeYearDates(calendarStartDate));
  //   }
  // }, [calendarStartDate, calendarViews]);
  // console.log("group list: ", groupList);
  // console.log(
  //   "group list: ",
  //   groupList.indexOf(groupList.filter((group) => group.id === currGroup.id)[0])
  // );

  // return (
  //   <Box
  //     sx={{
  //       display: "flex",
  //       flexDirection: "column",
  //       flexGrow: 1,
  //       width: `${RIGHT_COLUMN_WIDTH}vw`,
  //     }}
  //   >
  //     {groupList &&
  //       groupList.map((group) => (
  //         <GridRow
  //           key={`${group.id}`}
  //           groupId={group.id}
  //           projectBoxes={
  //             projectBoxes && projectBoxes[group.id.toString()]?.length > 0
  //               ? projectBoxes[group.id.toString()].sort(
  //                   (a, b) =>
  //                     Number(a.moduleCategoryName === LITTLE_LEARNERS) -
  //                     Number(b.moduleCategoryName === LITTLE_LEARNERS)
  //                 )
  //               : []
  //           }
  //         />
  //       ))}
  //   </Box>
  // );
  return isLoadingCalendar ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: `${RIGHT_COLUMN_WIDTH}vw`,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingIndicator />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: `${RIGHT_COLUMN_WIDTH}vw`,
      }}
    >
      {groupList &&
        groupList.map((group) => (
          <GridRow
            key={`${group.id}`}
            groupId={group.id}
            projectBoxes={
              projectBoxes && projectBoxes[group.id.toString()]?.length > 0
                ? projectBoxes[group.id.toString()].sort(
                    (a, b) =>
                      Number(a.moduleCategoryName === LITTLE_LEARNERS) -
                      Number(b.moduleCategoryName === LITTLE_LEARNERS)
                  )
                : []
            }
          />
        ))}
    </Box>
  );
};

export default RightColumnBody;

import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Chip, Tooltip, SxProps } from "@mui/material";

import { API_Project } from "types/project";

import Logo from "images/logo-2.png";
import { translation } from "constants/translation";
import { Stack } from "@mui/system";
import Image from "components/Image/Image";

//age
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import {
  BLOOMS,
  BLOSSOMS,
  BUDS,
  SPROUTLINGS,
  SPROUTS,
} from "constants/project-lesson-activity";

import ModuleTag from "components/ModuleTag/ModuleTag";

const DetailCardSimplified = ({
  project,
  onClick,
  sx = {},
}: {
  project: API_Project;
  onClick: () => any;
  sx?: SxProps;
}) => {
  let AgeGroupImgSrc = "";
  if (project.age === BLOSSOMS || project.age === BLOOMS) {
    AgeGroupImgSrc = BlossomsIcon;
  } else if (project.age === BUDS) {
    AgeGroupImgSrc = BudsIcon;
  } else if (project.age === SPROUTS || project.age === SPROUTLINGS) {
    AgeGroupImgSrc = SproutsIcon;
  }

  return (
    <>
      <Card
        sx={{
          // maxHeight: 400,
          // width: "100%",
          minWidth: 230,
          maxWidth: 230,
          borderRadius: "10px",
          backgroundColor: "#F0F0F0",
          filter: project.is_published
            ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            : "none",
          // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          cursor: "pointer",
          transition: "200ms all ease-in-out",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          opacity: project.is_published && project.is_granted_access ? 1 : 0.7,
          ...sx,
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            width: "100%",
            height: "35px",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            pt: 1,
            pl: 1,
          }}
        >
          {project.is_recommended && (
            <Chip
              size="small"
              label="Recommended"
              color="primary"
              sx={{ cursor: "pointer", color: "txt.light" }}
            />
          )}
        </Box>
        <CardMedia
          component="img"
          alt={project.images.length > 0 ? project.images[0].file : "No Image"}
          height="100"
          image={project.images.length > 0 ? project.images[0].file : Logo}
          sx={{
            backgroundColor: "primary.light",
            "&::before": { display: "none" },
          }}
          onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          }}
        />

        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "13px 18px",
            gap: "0.5rem",
            flexGrow: 1,
            pb: "0px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            {/* <Stack direction="row" spacing={1}>
              <Rating rating={project.average_rating} />
            </Stack> */}
            <Stack direction="column" spacing={0.5}>
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Box sx={{ mb: "-10px" }}>
                  <ModuleTag
                    projModule={project.module}
                    styles={{
                      backgroundColor: "rgba(209, 228, 227, 1)",
                    }}
                    fontSize="0.6rem"
                  />
                </Box>
                <Tooltip title={<AgeLabel ageCategory={project.age} />}>
                  <Box
                    sx={{
                      width: "32px",
                      height: "32px",
                      overflow: "hidden",
                      // border: "2px solid var(--primary-main)",
                      border: "1px solid #b9b9b9",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{ width: "20px", height: "20px" }}
                      src={AgeGroupImgSrc}
                      alt="Level"
                    />
                  </Box>
                </Tooltip>
              </Stack>
              <Title title={project.name} />
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

const AgeLabel = ({ ageCategory }: { ageCategory: string }) => {
  const italicBoldStyle = {
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "Italic",
  };

  const LabelComponent = ({
    title,
    descPartOne,
    descPartTwo,
  }: {
    title: string;
    descPartOne: string;
    descPartTwo: string;
  }) => {
    return (
      <>
        <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
        <Typography>
          <Typography sx={italicBoldStyle} component="span">
            {descPartOne}
          </Typography>
          {descPartTwo}
        </Typography>
      </>
    );
  };

  return (
    <Box sx={{}}>
      {ageCategory === SPROUTS || ageCategory === SPROUTLINGS ? (
        <LabelComponent
          title={SPROUTS}
          descPartOne={translation.sproutsExplainationPartOne}
          descPartTwo={translation.sproutsExplainationPartTwo}
        />
      ) : ageCategory === BUDS ? (
        <LabelComponent
          title={BUDS}
          descPartOne={translation.budsExplainationPartOne}
          descPartTwo={translation.budsExplainationPartTwo}
        />
      ) : ageCategory === BLOSSOMS || ageCategory === BLOOMS ? (
        <LabelComponent
          title={BLOSSOMS}
          descPartOne={translation.blossomsExplainationPartOne}
          descPartTwo={translation.blossomsExplainationPartTwo}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

// const Rating = ({ rating }: { rating?: number }) => {
//   return (
//     <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
//       <StarIcon sx={{ color: `${rating !== null ? "#ffc107" : "#C4C4C4"}` }} />
//       <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
//         {rating !== null
//           ? `${Number(rating).toFixed(1)} / 5.0`
//           : translation.noRating}
//       </Typography>
//     </Box>
//   );
// };

const Title = ({ title }: { title?: string }) => {
  return (
    <Typography sx={{ fontWeight: "bold", fontSize: "0.75rem" }}>
      {title}
    </Typography>
  );
};

// const Desc = ({ desc }: { desc?: string }) => {
//   return (
//     <Box
//       color="txt.dark2"
//       sx={{
//         fontSize: ".75rem",
//         width: "100%",
//         maxHeight: "50px",
//         overflow: "hidden",
//       }}
//       dangerouslySetInnerHTML={{ __html: desc ? desc : "" }}
//     ></Box>
//   );
// };

export default DetailCardSimplified;

import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Typography,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
  Divider,
  Chip,
  useTheme,
  SxProps,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "components/Button/Button";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  API_Announcement,
  API_Announcement_File_Update,
  API_Announcement_Response_Information_Option,
  API_Create_Announcement,
} from "types/announcement";
import useStore from "store/store";
import {
  addAnnouncementFile,
  createAnnouncement,
  createAnnouncementResponse,
  deleteAnnouncement,
  deleteAnnouncementFile,
  deleteAnnouncementResponse,
  updateAnnouncement,
  updateAnnouncementFile,
  updateAnnouncementResponse,
} from "api/announcement-api";
import { translation } from "constants/translation";
import DashboardAnnouncementCard from "containers/WelcomeBackPage/components/ParentDashboardComponents/DashboardAnnouncementCard";
import useSnack from "../../hooks/useSnack";
import { updateProfile } from "../../api/auth-api";
import { ErrorMessage, UpdateProfileResponse } from "../../types/auth";
import Image from "../../components/Image/Image";
import { useLocation, useParams } from "react-router-dom";
import { API_Group_Full } from "../../types/group";
import RichCKEditor from "../../components/RichCKEditor/RichCKEditor";
import {
  ACKNOWLEDGE,
  CHECK_BOXES,
  DECISION,
  MULTIPLE_CHOICE,
  RESPONSE_TYPE,
} from "./announcementConstant";
import { SubtitleWithAddIcon } from "../teacher/TeacherPage/TeacherEdit/TeacherFormComponents/ActivityFormComponents";
import { initialAnnouncementResponseInformation } from "./AnnouncementsPage";
import { ACTION_REQUIRED } from "../../constants/url";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AnnouncementComponentFileField from "./AnnouncementComponentFileField";
import useDialog from "../../hooks/useDialog";
import WarningDialog from "../../components/Dialog/WarningDialog";

export const DATE_FORMAT = "DD MMM YYYY";
export const INPUT_FORMAT = "DD/MM/YYYY h:mm a";

export const CustomModal = ({
  open,
  handleClose,
  children,
  sx = {},
}: {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  sx?: SxProps;
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      sx: {
        backgroundColor: "rgba(49,47,48,0.4)",
      },
    }}
    sx={{
      m: "24px",
    }}
  >
    <Fade in={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "600px",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: "10px",
          overflow: "auto",
          maxHeight: "90%",
          color: "#312F30",
          "&:focus-visible": {
            outline: "none",
          },
          ...sx,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </Box>

        {children}
      </Box>
    </Fade>
  </Modal>
);

export const AnnouncementModal = ({
  open,
  handleClose,
  announcement,
}: {
  open: boolean;
  handleClose: () => void;
  announcement: API_Announcement;
}) => {
  const location = useLocation();
  const openSnack = useSnack();
  const { schoolId } = useParams();

  const { groupList, announcementList, setAnnouncementList } = useStore(
    (state) => ({
      groupList: state.groupList,
      announcementList: state.announcementList,
      setAnnouncementList: state.setAnnouncementList,
    })
  );

  const classList = groupList.filter(
    (group) => group.school.toString() === schoolId
  );

  const classIds = classList.map((group) => group.id);

  const getClassIds = (): number[] => {
    const classIds: number[] = [];

    classList.forEach((group) => {
      const childrenIds = group.children.map((child) => child.id);

      if (
        childrenIds.length > 0 &&
        childrenIds.every((profileId) =>
          announcement.profiles.includes(profileId)
        )
      ) {
        classIds.push(group.id);
      }
    });

    return classIds;
  };

  const [formData, setFormData] = useState<API_Announcement>(announcement);
  const [isResponseRequired, setIsResponseRequired] = useState<boolean>(
    announcement.announcement_response_information.announcement !== -1 ||
      location.pathname.includes(ACTION_REQUIRED)
  );
  const [selectedClassIds, setSelectedClassIds] = useState<number[]>(
    announcement.id === -1 ? classIds : getClassIds()
  );
  const [files, setFiles] = useState<File[]>([]);
  const [fileNameToCopyList, setFileNameToCopyList] = useState<string[]>([]); //for new files
  const [
    existingAnnouncementFileUpdateList,
    setExistingAnnouncementFileUpdateList,
  ] = useState<API_Announcement_File_Update[]>(
    announcement.files.map((file) => ({
      id: file.id,
      is_display_in_document_tab: file.is_display_in_document_tab,
    }))
  ); //for existing files
  const [deleteFileIds, setDeleteFileIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveAsDraftLoading, setIsSaveAsDraftLoading] =
    useState<boolean>(false);

  const isCreate = announcement.id === -1;

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const getProfiles = () =>
    Array.from(
      new Set(
        classList
          .filter((group) => selectedClassIds.includes(group.id))
          .flatMap((group) => group.children)
          .map((child) => child.id)
      )
    );

  const submitCheck = () => {
    let flag = true;
    if (isResponseRequired) {
      if (formData.announcement_response_information.response_question === "") {
        openSnack("Please input your response question.", false);
        flag = false;
        return;
      }
      if (formData.announcement_response_information.response_type === "") {
        openSnack("Please choose your response type.", false);
        flag = false;
        return;
      }
      if (
        (formData.announcement_response_information.response_type ===
          CHECK_BOXES ||
          formData.announcement_response_information.response_type ===
            MULTIPLE_CHOICE) &&
        formData.announcement_response_information.response_options
          .filter((option) => option.is_delete === false)
          .map((option) => option.name)
          .join(";").length === 0
      ) {
        openSnack("Please provide at least 1 option.", false);
        flag = false;
        return;
      }
      // if (
      //   formData.announcement_response_information.is_allow_text &&
      //   formData.announcement_response_information.text_instruction === ""
      // ) {
      //   openSnack("Please input your text instruction.", false);
      //   flag = false;
      //   return;
      // }
      // if (
      //   formData.announcement_response_information.is_allow_attachment &&
      //   formData.announcement_response_information.attachment_instruction === ""
      // ) {
      //   openSnack("Please input your attachement instruction.", false);
      //   flag = false;
      //   return;
      // }
    }
    return flag;
  };

  const handleCreate = async (isPublish?: boolean) => {
    if (!submitCheck()) {
      return;
    }

    if (isPublish) {
      setIsLoading(true);
    } else {
      setIsSaveAsDraftLoading(true);
    }

    const profiles = getProfiles();

    const data: API_Create_Announcement = {
      school: formData.school,
      name: formData.name,
      description: formData.description,
      files,
      start_at: formData.start_at,
      // end_at: formData.end_at,
      profiles,
      is_pinned: formData.is_pinned,
      is_published: !!isPublish,
    };

    const announcement = await createAnnouncement(
      currLanguage,
      data,
      fileNameToCopyList
    );
    if (typeof announcement !== "string") {
      if (isResponseRequired) {
        const announcementResponseInformationData = {
          ...formData.announcement_response_information,
          response_options:
            formData.announcement_response_information.response_options
              .map((option) => option.name)
              .join(";"),
          announcement: announcement.id,
        };
        const announcementResponseRes = await createAnnouncementResponse(
          currLanguage,
          announcementResponseInformationData
        );
        if (typeof announcementResponseRes !== "string") {
          openSnack(translation.announcementCreatedSuccess, true);
          setAnnouncementList([announcementResponseRes, ...announcementList]);
          handleClose();
        } else {
          openSnack(translation.announcementCreatedFailed, false);
        }
      } else {
        openSnack(translation.announcementCreatedSuccess, true);
        setAnnouncementList([announcement, ...announcementList]);
        handleClose();
      }
    } else {
      openSnack(translation.announcementCreatedFailed, false);
    }

    setIsLoading(false);
    setIsSaveAsDraftLoading(false);
  };

  const handleUpdate = async (isPublish?: boolean) => {
    if (!submitCheck()) {
      return;
    }
    if (isPublish) {
      setIsLoading(true);
    } else {
      setIsSaveAsDraftLoading(true);
    }

    // Handle announcement files
    if (files.length > 0) {
      await addAnnouncementFile(
        currLanguage,
        formData.id,
        files,
        fileNameToCopyList
      );
    }

    // // update file display in doc or not

    await updateAnnouncementFile(
      currLanguage,
      existingAnnouncementFileUpdateList.filter(
        (file) => !deleteFileIds.includes(file.id)
      )
    );

    // delete files
    deleteFileIds.forEach(async (fileId) => {
      await deleteAnnouncementFile(currLanguage, fileId);
    });
    // for (let i = 0; i < deleteFileIds.length; i++) {
    //   if (deleteFileIds[i]) {
    //     await deleteAnnouncementFile(currLanguage, formData.files[i].id);
    //   }
    // }

    const profiles = getProfiles();
    // Update Announcements
    const updatedAnnouncement = await updateAnnouncement(
      currLanguage,
      formData.id,
      {
        ...formData,
        profiles,
        is_published: !!isPublish,
        is_edited: formData.is_edited // To check whether it exist already
          ? formData.is_edited
          : formData.is_published
          ? true
          : false,
      }
    );

    if (typeof updatedAnnouncement !== "string") {
      // If there's response to update
      if (isResponseRequired) {
        let announcementResponseRes: API_Announcement | string;
        const announcementResponseInformationData = {
          ...formData.announcement_response_information,
          response_options:
            formData.announcement_response_information.response_options
              .filter((option) => option.is_delete === false)
              .map((option) => option.name)
              .join(";"),
          announcement: updatedAnnouncement.id,
        };
        if (formData.announcement_response_information.id === -1) {
          announcementResponseRes = await createAnnouncementResponse(
            currLanguage,
            announcementResponseInformationData
          );
        } else {
          announcementResponseRes = await updateAnnouncementResponse(
            currLanguage,
            announcementResponseInformationData
          );
        }
        if (typeof announcementResponseRes !== "string") {
          openSnack(translation.announcementUpdatedSuccess, true);
          const updatedAnnouncementList = announcementList.map((announcement) =>
            announcement.id === (announcementResponseRes as API_Announcement).id
              ? (announcementResponseRes as API_Announcement)
              : announcement
          );
          setAnnouncementList(updatedAnnouncementList);
          handleClose();
        } else {
          openSnack(translation.announcementUpdatedFailed, false);
        }
      } else {
        // send delete response information request if exist during
        if (formData.announcement_response_information.id !== -1) {
          const deleteRes = await deleteAnnouncementResponse(
            formData.announcement_response_information.id
          );
          if (deleteRes === "Success") {
            const updatedAnnouncementList = announcementList.map(
              (announcement) =>
                announcement.id === updatedAnnouncement.id
                  ? {
                      ...updatedAnnouncement,
                      announcement_response_information:
                        initialAnnouncementResponseInformation,
                    }
                  : announcement
            );
            setAnnouncementList(updatedAnnouncementList);
            openSnack(translation.announcementUpdatedSuccess, true);
            handleClose();
          }
        } else {
          openSnack(translation.announcementUpdatedSuccess, true);
          const updatedAnnouncementList = announcementList.map((announcement) =>
            announcement.id === (updatedAnnouncement as API_Announcement).id
              ? (updatedAnnouncement as API_Announcement)
              : announcement
          );
          setAnnouncementList(updatedAnnouncementList);
          handleClose();
        }
      }
    } else {
      openSnack(translation.announcementUpdatedFailed, false);
    }

    setIsLoading(false);
    setIsSaveAsDraftLoading(false);
  };

  const handleRecall = async () => {
    setIsLoading(true);
    // Update Announcements
    const updatedAnnouncement = await updateAnnouncement(
      currLanguage,
      formData.id,
      {
        is_published: false,
        prev_name: formData.name,
      }
    );

    if (typeof updatedAnnouncement !== "string") {
      openSnack(translation.announcementRecalledSuccess, true);
      const updatedAnnouncementList = announcementList.map((announcement) =>
        announcement.id === (updatedAnnouncement as API_Announcement).id
          ? (updatedAnnouncement as API_Announcement)
          : announcement
      );
      setAnnouncementList(updatedAnnouncementList);
      handleClose();
    } else {
      openSnack(translation.announcementRecalledFail, false);
    }

    setIsLoading(false);
    setIsSaveAsDraftLoading(false);
  };

  // console.log("deleteFileIds: ", deleteFileIds);
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Typography
        sx={{
          fontSize: "1.25rem",
          fontWeight: 500,
          mb: 2,
        }}
      >
        {isCreate
          ? translation.createAnnouncement
          : translation.editAnnouncement}
      </Typography>

      <AnnouncementForm
        formData={formData}
        setFormData={setFormData}
        isResponseRequired={isResponseRequired}
        setIsResponseRequired={setIsResponseRequired}
        handleSubmit={isCreate ? handleCreate : handleUpdate}
        handleRecall={handleRecall}
        handleClose={handleClose}
        isCreate={isCreate}
        isLoading={isLoading}
        isSaveAsDraftLoading={isSaveAsDraftLoading}
        fileField={
          <AnnouncementComponentFileField
            files={files}
            setFiles={setFiles}
            fileNameToCopyList={fileNameToCopyList}
            setFileNameToCopyList={setFileNameToCopyList}
            existingAnnouncementFileUpdateList={
              existingAnnouncementFileUpdateList
            }
            setExistingAnnouncementFileUpdateList={
              setExistingAnnouncementFileUpdateList
            }
            oldFiles={announcement}
            deleteFileIds={deleteFileIds}
            setDeleteFileIds={setDeleteFileIds}
            language={currLanguage}
            multiple
          />
        }
        classSelectionField={
          <ClassSelectionField
            classList={classList}
            classIds={classIds}
            selectedClassIds={selectedClassIds}
            setSelectedClassIds={setSelectedClassIds}
          />
        }
      />
    </CustomModal>
  );
};

const AnnouncementForm = ({
  formData,
  setFormData,
  isResponseRequired,
  setIsResponseRequired,
  handleSubmit,
  handleRecall,
  handleClose,
  isCreate,
  isLoading,
  isSaveAsDraftLoading,
  fileField,
  classSelectionField,
}: {
  formData: API_Announcement;
  setFormData: React.Dispatch<React.SetStateAction<API_Announcement>>;
  isResponseRequired: boolean;
  setIsResponseRequired: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (isPublish?: boolean) => Promise<void>;
  handleRecall: () => Promise<void>;
  handleClose: () => void;
  isCreate: boolean;
  isLoading: boolean;
  isSaveAsDraftLoading: boolean;
  fileField: React.ReactNode;
  classSelectionField: React.ReactNode;
}) => {
  const { currLanguage, announcementList, setAnnouncementList } = useStore(
    (state) => ({
      currLanguage: state.currLanguage,
      announcementList: state.announcementList,
      setAnnouncementList: state.setAnnouncementList,
    })
  );
  const openSnack = useSnack();

  const handleDelete = async () => {
    const response = await deleteAnnouncement(currLanguage, formData.id);

    if (response === "Success") {
      openSnack(translation.announcementDeletedSuccess, true);
      setAnnouncementList(announcementList.filter((a) => a.id !== formData.id));
      handleClose();
    } else {
      openSnack(translation.announcementDeletedFailed, false);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: translation.deleteAnnouncement + "?",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  const handleDeleteClick = () => {
    handleOpenDialog();
  };

  return (
    <Stack spacing={2}>
      <WarningDialog {...warningBody} />

      <Stack spacing={1.5} direction="column">
        <TextField
          autoComplete="off"
          fullWidth
          required
          label={translation.title}
          value={formData.name}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            sx={{
              "&.Mui-checked": {
                color: "primary",
              },
            }}
            checked={formData.is_pinned}
            onChange={() => {
              setFormData((prev) => ({
                ...prev,
                is_pinned: !prev.is_pinned,
              }));
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography>{translation.pinAnnouncement}</Typography>
        </Box>

        <Box
          sx={{
            "& .ck-editor__editable_inline": {
              maxHeight: "300px",
            },
          }}
        >
          <Typography sx={{ mb: 0.25 }}>
            {translation.description}
            <Typography component="span" sx={{ color: "red", ml: "2px" }}>
              *
            </Typography>
          </Typography>

          <RichCKEditor
            data={formData.description}
            onChange={(event: any, editor: any) => {
              const data: string = editor.getData();
              setFormData((prev) => ({
                ...prev,
                description: data,
              }));
            }}
          />
        </Box>

        {classSelectionField}

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            inputFormat={INPUT_FORMAT}
            disableMaskedInput
            label={translation.announcementStartAt}
            value={moment(formData.start_at)}
            onChange={(value: Date | null) => {
              if (value !== null) {
                setFormData((prev) => ({
                  ...prev,
                  start_at: value.toISOString(),
                }));
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>

        {fileField}
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "0px !important",
            opacity: !isCreate && formData.is_published ? 0.5 : 1,
          }}
        >
          <Checkbox
            sx={{
              "&.Mui-checked": {
                color: "primary",
              },
            }}
            disabled={!isCreate && formData.is_published}
            checked={isResponseRequired}
            onChange={() => {
              setIsResponseRequired((prev) => !prev);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography>{translation.anyResponseRequired}</Typography>
        </Box>
        {isResponseRequired && (
          <ActionRequiredField
            isCreate={isCreate}
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </Stack>

      <Stack spacing={1.5} direction="row" justifyContent="flex-end">
        {!isCreate && !formData.is_published && (
          <Button
            arrow={false}
            buttonText={translation.delete || "Delete"}
            style={{
              opacity: isSaveAsDraftLoading || isLoading ? 0.5 : 1,
              minHeight: "45px",
            }}
            onClick={() => {
              handleDeleteClick();
            }}
            disabled={isSaveAsDraftLoading || isLoading}
            isLoading={isLoading}
          />
        )}

        {!formData.is_published && (
          <Button
            arrow={false}
            buttonText={
              isSaveAsDraftLoading
                ? translation.loading || "Loading"
                : translation.saveAsDraft || "Save as Draft"
            }
            style={{
              opacity: isSaveAsDraftLoading || isLoading ? 0.5 : 1,
              minHeight: "45px",
            }}
            onClick={() => {
              handleSubmit(false);
            }}
            disabled={isSaveAsDraftLoading || isLoading}
            isLoading={isSaveAsDraftLoading}
          />
        )}
        {formData.is_published && (
          <Button
            arrow={false}
            buttonText={translation.recall || "Recall"}
            style={{
              opacity: isSaveAsDraftLoading || isLoading ? 0.5 : 1,
              minHeight: "45px",
            }}
            onClick={() => {
              handleRecall();
            }}
            disabled={isSaveAsDraftLoading || isLoading}
            isLoading={isSaveAsDraftLoading}
          />
        )}

        <Button
          arrow={!isLoading}
          buttonText={
            isLoading
              ? translation.loading || "Loading"
              : !formData.is_published
              ? translation.publish || "Publish"
              : translation.update || "Update"
          }
          style={{
            opacity: isSaveAsDraftLoading || isLoading ? 0.5 : 1,
            minHeight: "45px",
          }}
          onClick={() => {
            handleSubmit(true);
          }}
          disabled={isSaveAsDraftLoading || isLoading}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};

const ActionRequiredField = ({
  isCreate,
  formData,
  setFormData,
}: {
  isCreate: boolean;
  formData: API_Announcement;
  setFormData: React.Dispatch<React.SetStateAction<API_Announcement>>;
}) => {
  return (
    <Stack spacing={1.5} direction="column">
      <TextField
        autoComplete="off"
        fullWidth
        required
        label={translation.responseQuestion}
        value={formData.announcement_response_information.response_question}
        onChange={(event) => {
          setFormData((prev) => ({
            ...prev,
            announcement_response_information: {
              ...prev.announcement_response_information,
              response_question: event.target.value,
            },
          }));
        }}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          inputFormat={INPUT_FORMAT}
          disableMaskedInput
          label={translation.respondBy}
          value={moment(formData.announcement_response_information.end_at)}
          onChange={(value: Date | null) => {
            if (value !== null) {
              setFormData((prev) => ({
                ...prev,
                announcement_response_information: {
                  ...prev.announcement_response_information,
                  end_at: value.toISOString(),
                },
              }));
            }
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </LocalizationProvider>
      <FormControl fullWidth required>
        <InputLabel id="demo-simple-select-label">
          {translation.responseType}
        </InputLabel>
        <Select
          disabled={!isCreate && formData.is_published}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formData.announcement_response_information.response_type}
          label={translation.programme}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              announcement_response_information: {
                ...prev.announcement_response_information,
                response_type: event.target.value,
              },
            }));
          }}
        >
          {RESPONSE_TYPE.map((responseType) => (
            <MenuItem key={`response-${responseType}`} value={responseType}>
              {responseType === DECISION ? "Yes/No" : responseType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {formData.announcement_response_information.response_type && (
        <AnnouncementResponseType
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "0px !important",
        }}
      >
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "primary",
            },
          }}
          checked={formData.announcement_response_information.is_allow_text}
          onChange={() => {
            setFormData((prev) => ({
              ...prev,
              announcement_response_information: {
                ...prev.announcement_response_information,
                is_allow_text:
                  !prev.announcement_response_information.is_allow_text,
              },
            }));
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>{translation.allowTextResponse}</Typography>
      </Box>

      {formData.announcement_response_information.is_allow_text && (
        <TextField
          fullWidth
          // required
          label={translation.textResponseInstruction}
          value={formData.announcement_response_information.text_instruction}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              announcement_response_information: {
                ...prev.announcement_response_information,
                text_instruction: event.target.value,
              },
            }));
          }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "0px !important",
        }}
      >
        <Checkbox
          sx={{
            "&.Mui-checked": {
              color: "primary",
            },
          }}
          checked={
            formData.announcement_response_information.is_allow_attachment
          }
          onChange={() => {
            setFormData((prev) => ({
              ...prev,
              announcement_response_information: {
                ...prev.announcement_response_information,
                is_allow_attachment:
                  !prev.announcement_response_information.is_allow_attachment,
              },
            }));
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>{translation.allowAttachmentUpload}</Typography>
      </Box>

      {formData.announcement_response_information.is_allow_attachment && (
        <TextField
          fullWidth
          // required
          label={translation.attachmentInstruction}
          value={
            formData.announcement_response_information.attachment_instruction
          }
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              announcement_response_information: {
                ...prev.announcement_response_information,
                attachment_instruction: event.target.value,
              },
            }));
          }}
        />
      )}

      {/* {fileField} */}
    </Stack>
  );
};

const AnnouncementResponseType = ({
  formData,
  setFormData,
}: {
  formData: API_Announcement;
  setFormData: React.Dispatch<React.SetStateAction<API_Announcement>>;
}) => {
  const fontStyle = { mt: "0px !important", fontSize: 12 };
  const handleAdd = () => {
    const updatedResponseOptions: API_Announcement_Response_Information_Option[] =
      [
        ...formData.announcement_response_information.response_options,
        {
          name: "",
          is_delete: false,
          is_new: true,
        },
      ];
    setFormData({
      ...formData,
      announcement_response_information: {
        ...formData.announcement_response_information,
        response_options: updatedResponseOptions,
      },
    });
  };

  if (
    formData.announcement_response_information.response_type ===
      MULTIPLE_CHOICE ||
    formData.announcement_response_information.response_type === CHECK_BOXES
  ) {
    return (
      <>
        <Typography component="span" sx={fontStyle}>
          {`Parent will be able to select ${
            formData.announcement_response_information.response_type ===
            MULTIPLE_CHOICE
              ? "only 1"
              : "more than 1"
          } option${
            formData.announcement_response_information.response_type ===
            MULTIPLE_CHOICE
              ? ""
              : "s"
          }`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            ml: "20px !important",
          }}
        >
          {formData.announcement_response_information.response_options.map(
            (option, index) => {
              return (
                <AnnouncementResponseOption
                  key={`announcement-response-option-${index}-${option.name}`}
                  option={option}
                  index={index}
                  formData={formData}
                  setFormData={setFormData}
                />
              );
            }
          )}
          <Box sx={{ mb: 2 }}>
            <SubtitleWithAddIcon
              subtitle={translation.addResponseOption}
              handleAdd={handleAdd}
            />
          </Box>
        </Box>
      </>
    );
  } else if (
    formData.announcement_response_information.response_type === ACKNOWLEDGE
  ) {
    return (
      <Typography component="span" sx={fontStyle}>
        Parent will be able to acknowledge the announcement
      </Typography>
    );
  } else if (
    formData.announcement_response_information.response_type === DECISION
  ) {
    return (
      <Typography component="span" sx={fontStyle}>
        Parent will need to click on "Yes" or "No"
      </Typography>
    );
  } else {
    return null;
  }
};

const AnnouncementResponseOption = ({
  option,
  index,
  formData,
  setFormData,
}: {
  option: API_Announcement_Response_Information_Option;
  index: number;
  formData: API_Announcement;

  setFormData: React.Dispatch<React.SetStateAction<API_Announcement>>;
}) => {
  const [optionTemp, setOptionTemp] = useState(option);

  useEffect(() => {
    const updatedOptions: API_Announcement_Response_Information_Option[] =
      JSON.parse(
        JSON.stringify(
          formData.announcement_response_information.response_options
        )
      );
    updatedOptions[index].is_delete = optionTemp.is_delete;
    setFormData((prev) => ({
      ...prev,
      announcement_response_information: {
        ...prev.announcement_response_information,
        response_options: updatedOptions,
      },
    }));
    // eslint-disable-next-line
  }, [optionTemp.is_delete]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      }}
    >
      <TextField
        disabled={option.is_delete}
        sx={{
          width: "100%",
          textDecoration: option.is_delete ? "line-through" : "none",
        }}
        size="small"
        fullWidth
        required
        label={translation.responseOption}
        value={optionTemp.name}
        onChange={(event) => {
          setOptionTemp((prev) => ({ ...prev, name: event.target.value }));
        }}
        onBlur={(event) => {
          const updatedOptions: API_Announcement_Response_Information_Option[] =
            JSON.parse(
              JSON.stringify(
                formData.announcement_response_information.response_options
              )
            );
          updatedOptions[index].name = event.target.value;
          setFormData((prev) => ({
            ...prev,
            announcement_response_information: {
              ...prev.announcement_response_information,
              response_options: updatedOptions,
            },
          }));
        }}
      />
      <ClearIcon
        sx={{
          color: "red",
          cursor: "pointer",
          transition: "all 100ms linear",
          "&:hover": {
            color: "darkred",
            transition: "all 100ms linear",
          },
        }}
        onClick={() => {
          if (optionTemp.is_new) {
            setFormData((prev) => ({
              ...prev,
              announcement_response_information: {
                ...prev.announcement_response_information,
                response_options:
                  prev.announcement_response_information.response_options.filter(
                    (option, index_) => index_ !== index
                  ),
              },
            }));
          } else {
            setOptionTemp((prev) => ({
              ...prev,
              is_delete: !prev.is_delete,
            }));
          }
        }}
      />
    </Box>
  );
};

const ClassSelectionField = ({
  classList,
  classIds,
  selectedClassIds,
  setSelectedClassIds,
}: {
  classList: API_Group_Full[];
  classIds: number[];
  selectedClassIds: number[];
  setSelectedClassIds: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedClassIds>) => {
    const {
      target: { value },
    } = event;

    if ((value as (string | number)[]).includes("all")) {
      if (selectedClassIds.length === classIds.length) {
        setSelectedClassIds([]);
      } else {
        setSelectedClassIds(classIds);
      }
    } else {
      setSelectedClassIds(
        typeof value === "string"
          ? value.split(",").map((v) => Number(v))
          : value
      );
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="class-selector">{translation.group}</InputLabel>
      <Select
        labelId="class-selector"
        label={translation.group}
        multiple
        value={selectedClassIds}
        onChange={handleChange}
        input={<OutlinedInput label={translation.group} />}
        renderValue={(selected) =>
          selectedClassIds.length === classIds.length
            ? "All Classes"
            : selectedClassIds.length === 0
            ? "No Class"
            : classList
                .filter((group) => selected.includes(group.id))
                .map((group) => group.name)
                .join(", ")
        }
        MenuProps={{
          autoFocus: false,
          sx: {
            maxHeight: "300px",
          },
        }}
      >
        <MenuItem value="all">
          <ListItemText primary="Select All" />
          <Checkbox checked={selectedClassIds.length === classIds.length} />
        </MenuItem>

        {classList.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            <Image
              src={group.image ? group.image : ""}
              style={{ width: 20, height: 20, marginRight: 8 }}
              alt={group.name}
            />
            <ListItemText
              primary={group.name}
              primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            />
            <Checkbox checked={selectedClassIds.indexOf(group.id) > -1} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const AnnouncementCard = ({
  announcement,
  handleChartClick,
  handleClickEdit,
  handleClickDelete,
  isParentView = true,
  isEditable = true,
}: {
  announcement: API_Announcement;
  handleChartClick: () => void;
  handleClickEdit: () => void;
  handleClickDelete: () => void;
  isParentView?: boolean;
  isEditable?: boolean;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <DashboardAnnouncementCard
      announcement={announcement}
      sx={{
        position: "relative",

        // borderRadius: "10px",
        // border: "1px solid #C4C4C4",
        p: 2,
      }}
      isParentView={isParentView}
    >
      {!isParentView && isEditable && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 8,
            zIndex: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {isXs ? null : (
                <>
                  <AnnouncementChipIcons
                    announcement={announcement}
                    handleChartClick={handleChartClick}
                  />
                </>
              )}
              <IconButton onClick={handleClickEdit} size="small">
                <EditIcon
                  fontSize="small"
                  sx={{
                    color: "txt.secondary",
                  }}
                />
              </IconButton>

              {/* <IconButton onClick={handleClickDelete} size="small">
                <DeleteIcon
                  fontSize="small"
                  sx={{
                    color: "txt.secondary",
                  }}
                />
              </IconButton> */}
              {announcement.announcement_response_information.id !== -1 && (
                <IconButton onClick={handleChartClick} size="small">
                  <AssessmentOutlinedIcon
                    fontSize="small"
                    sx={{
                      color: "txt.secondary",
                    }}
                  />
                </IconButton>
              )}
            </Box>
            {isXs ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 70,
                  flexWrap: "wrap",
                  gap: 0.5,
                  justifyContent: "flex-end",
                }}
              >
                <AnnouncementChipIcons
                  announcement={announcement}
                  handleChartClick={handleChartClick}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
    </DashboardAnnouncementCard>
  );
};

const AnnouncementChipIcons = ({
  announcement,
  handleChartClick,
}: {
  announcement: API_Announcement;
  handleChartClick: () => void;
}) => {
  const theme = useTheme();

  return (
    <>
      {announcement.is_edited && (
        <Chip
          size="small"
          label={translation.updated}
          sx={{
            color: "txt.light",
            backgroundColor: theme.palette.custom.lightBlue,
            mr: { xs: 0, sm: 1 },
            opacity: { xs: 0.75, sm: 1 },
          }}
        />
      )}
      {announcement.announcement_response_information.id !== -1 && (
        <Chip
          size="small"
          label={translation.responseRequired}
          sx={{
            color: "txt.light",
            backgroundColor: theme.palette.custom.lightOrange,
            mr: { xs: 0, sm: 1 },
            opacity: { xs: 0.75, sm: 1 },
          }}
        />
      )}

      <Chip
        size="small"
        label={
          announcement.is_published
            ? translation.published
            : announcement.published_at
            ? translation.recalled
            : translation.draft
        }
        sx={{
          color: "txt.light",
          backgroundColor: announcement.is_published
            ? theme.palette.primary.main
            : announcement.published_at
            ? theme.palette.custom.lightPink
            : theme.palette.custom.lightGrey,
          opacity: { xs: 0.75, sm: 1 },
        }}
      />
    </>
  );
};

export const UpdateLastSeenAnnouncementTime = () => {
  const { profileDetails, setProfileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    setProfileDetails: state.setProfileDetails,
  }));

  useEffect(() => {
    if (profileDetails.id === -1) return;

    const updateLastSeen = async () => {
      const response: UpdateProfileResponse | ErrorMessage =
        await updateProfile(
          profileDetails.id.toString(),
          {
            last_seen_announcement_at: new Date().toISOString(),
          },
          localStorage.getItem("access")
        );

      if ("error" in response) return;

      setProfileDetails({
        ...profileDetails,
        last_seen_announcement_at: response.last_seen_announcement_at,
      });
    };

    updateLastSeen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails.id]);

  return null;
};

import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import TimetableHeader from "./components/TimetableHeader";
import ViewTimetable from "./components/ViewTimetable";
import EditTimetable from "./components/EditTimetable";
import { API_Timetable_Event_Info } from "types/timetable";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { getTimetableEventList } from "api/timetable-api";
import { getProjectCategories } from "api/project-api";
import { ProjectCategoryModule, ProjectCategory } from "types/project";
import useStore from "store/store";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import CustomTimetable from "./CustomTimetable";

const TimetablePage = () => {
  const { profileId } = useParams();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const isCustomTimetableTab = searchParams.get("isCustomTimetableTab");

  const { currLanguage, currProfileId } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currProfileId: state.profileDetails.id,
  }));

  const isViewOnly = profileId !== currProfileId.toString();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [onlyToday, setOnlyToday] = useState<boolean>(false);
  const [events, setEvents] = useState<API_Timetable_Event_Info[]>();
  const [modules, setModules] = useState<string[]>([]);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [isCustomTimetable, setIsCustomTimetable] = useState<boolean>(
    isCustomTimetableTab === "true" ? true : false
  );

  useEffect(() => {
    if (!profileId) return;

    const fetchEvents = async () => {
      try {
        const allEvents = await getTimetableEventList(currLanguage, profileId);
        setEvents(allEvents);
        setIsNotFound(false);
      } catch (error) {
        setIsNotFound(true);
      }
    };

    const fetchModules = async () => {
      const allModules: ProjectCategoryModule[] = (
        await getProjectCategories(currLanguage)
      ).reduce(
        (arr: ProjectCategoryModule[], curr: ProjectCategory) => [
          ...arr,
          ...curr.modules,
        ],
        []
      );

      setModules(allModules.map((module) => module.name));
    };

    fetchEvents();
    fetchModules();
  }, [currLanguage, profileId]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        pl: 1,
        flexDirection: "column",
        pr: isXs ? 2 : 6,
        py: 4,

        height: "100%",
      }}
    >
      {isNotFound || !profileId ? (
        <NotFoundPage />
      ) : !events || currProfileId === -1 ? (
        <LoadingIndicator />
      ) : (
        <>
          <TimetableHeader
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            onlyToday={onlyToday}
            setOnlyToday={setOnlyToday}
            events={events}
            setEvents={setEvents}
            modules={modules}
            isViewOnly={isViewOnly}
            isCustomTimetable={isCustomTimetable}
            setIsCustomTimetable={setIsCustomTimetable}
          />

          <Box
            sx={{
              pl: 2,

              marginLeft: isXs ? "35px" : "45px",
              marginRight: isXs ? "5px" : "20px",
            }}
          >
            {isCustomTimetable ? (
              <CustomTimetable />
            ) : isEdit ? (
              <EditTimetable
                events={events}
                setEvents={setEvents}
                modules={modules}
              />
            ) : (
              <ViewTimetable
                events={events}
                onlyToday={onlyToday}
                setOnlyToday={setOnlyToday}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default TimetablePage;

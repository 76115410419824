import axios from "api/axiosConfig";

import { AxiosError } from "axios";

import {
  API_Group_Calendar,
  API_Create_Schedule,
  API_Schedule_Datetime,
} from "types/calendar";
import { stringOrDate } from "react-big-calendar";
import moment from "moment";
import { DATETIME_FORMAT } from "containers/CalendarPage/constants/constants";

export const getChildrenCalendarByParentID = async (
  parentID: number | string
) => {
  try {
    const res = await axios.get(`en/profile/calendar/list?parent=${parentID}`);
    const data: API_Group_Calendar[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createSchedule = async (formData: API_Create_Schedule) => {
  const body = {
    project: formData.project_id,
    group: formData.group_id,
    start_at: formData.start_at,
    end_at: formData.end_at,
  };
  // console.log("Body: ", body);
  try {
    const res = await axios.post(`en/profile/schedule/create`, body);
    const data: API_Group_Calendar = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateScheduleDatetime = async (
  scheduleDatetimeId: number,
  scheduleObjId: number,
  start: stringOrDate,
  end: stringOrDate
) => {
  // const start_ = () => {
  //   if (typeof start === "string") {
  //     return start;
  //   } else {
  //     return `${start.getFullYear()}/${start.getMonth()}/${start.getDay()}`;
  //   }
  // };
  const body = {
    schedule: scheduleObjId,
    start_at: moment(start).format(DATETIME_FORMAT),
    end_at: moment(end).format(DATETIME_FORMAT),
  };

  // console.log("Body: ", body);
  try {
    const res = await axios.patch(
      `en/profile/schedule/${scheduleDatetimeId}/update-datetime`,
      body
    );
    const data: API_Schedule_Datetime = await res.data;
    // console.log(" res: ", res);
    return data;
  } catch (error: unknown) {
    console.log("error: ", error);
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteScheduleDatetime = async (id: number) => {
  try {
    const res = await axios.delete(`en/profile/schedule/datetime/${id}/delete`);
    const data: API_Schedule_Datetime = await res.data;
    // console.log("Data: ", data);
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// path('calendar/group/list', ScheduleView.GetGroupCalendarList.as_view()),
//     path('calendar/group/<int:pk>', ScheduleView.GetGroupCalendar.as_view()),
export const getGroupCalendar = async (groupId: number) => {
  try {
    const res = await axios.get(`en/profile/calendar/group/${groupId}`);
    const data: API_Group_Calendar = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getGroupCalendarList = async () => {
  try {
    const res = await axios.get(`en/profile/calendar/group/list/v2`);
    const data: API_Group_Calendar[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

// import React, { useState } from "react";
// import TeacherArchiveCompletedWorkScreenByProject from "./TeacherArchiveCompletedWorkScreenByProject";
// import AlbumBreadcrumbs from "../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/AlbumBreadcrumbs";
// import Albums from "../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/Albums";
// import ModuleAlbum from "../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/ModuleAlbum";
// import ProjectAlbum from "../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/ProjectAlbum";
// import { useSearchParams } from "react-router-dom";

const CompletedWorkByProject = () => {
  return null;
};

// const CompletedWorkByProject = () => {
//   const [searchParams, setSearchParams] = useSearchParams();

//   const [moduleName, setModuleName] = useState<string>("");
//   const [projectName, setProjectName] = useState<string>("");

//   const moduleId = searchParams.get("module");
//   const projectId = searchParams.get("project");

//   return (
//     <>
//       <AlbumBreadcrumbs
//         moduleName={moduleName}
//         setModuleName={setModuleName}
//         projectName={projectName}
//         setProjectName={setProjectName}
//       />

//       {moduleId === null ? (
//         <Albums
//           handleClick={(moduleId: string, moduleName: string) => {
//             setModuleName(moduleName);
//             setProjectName("");
//             setSearchParams({
//               module: moduleId,
//             });
//           }}
//         />
//       ) : projectId === null ? (
//         <ModuleAlbum
//           onLoad={(moduleName: string) => {
//             setModuleName(moduleName);
//             setProjectName("");
//           }}
//           handleClick={(
//             moduleId: string,
//             moduleName: string,
//             projectId: string,
//             projectName: string
//           ) => {
//             setModuleName(moduleName);
//             setProjectName(projectName);
//             setSearchParams({
//               module: moduleId,
//               project: projectId,
//             });
//           }}
//         />
//       ) : (
//         <ProjectAlbum
//           onLoad={(moduleName: string, projectName: string) => {
//             setModuleName(moduleName);
//             setProjectName(projectName);
//           }}
//         />
//       )}
//     </>
//   );
// };

export default CompletedWorkByProject;

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { useParams } from "react-router-dom";

import useStore from "store/store";
import { API_Teacher_Certificate, ViewMode } from "types/teacher";
import { ACCESS_CONTROL } from "constants/staticConstant";

import { Query_Data } from "types/project";
import { getAllCertificateForManagePage } from "api/teacher-api";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import ManageCertificateListTable from "./ManageCertificateListTable";

const ManageTeacherCertificatePage = () => {
  const { pageModeSlug } = useParams();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const [pageMode, setPageMode] = useState<ViewMode>(
    ACCESS_CONTROL as ViewMode
  );
  const [certificateList, setCertificateList] = useState<
    API_Teacher_Certificate[]
  >([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });

  const [selected, setSelected] = useState<string[]>([]);

  const updateData = async () => {
    setIsLoaded(false);

    const updatedCertificateList = await getAllCertificateForManagePage(
      currLanguage,
      pageMode,
      queryData
    );

    if (typeof updatedCertificateList !== "string") {
      setCertificateList(updatedCertificateList ? updatedCertificateList : []);
    }
    setIsLoaded(true);
  };

  useEffect(() => {
    if (queryData.query === null) return;

    const searchTimeout = setTimeout(async () => {
      if (queryData.query !== "") {
        setIsLoaded(false);
      }
      updateData();
    }, 1200);

    return () => clearTimeout(searchTimeout);
  }, [queryData.query]);

  useEffect(() => {
    pageModeSlug && setPageMode(pageModeSlug as ViewMode);
  }, [pageModeSlug]);

  useEffect(() => {
    updateData();
  }, [pageMode, queryData.startDate, queryData.endDate]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          px: 3,
          py: 4,
          mx: "60px",
        }}
      >
        <FirstRow pageMode={pageMode} setPageMode={setPageMode}>
          {null}
        </FirstRow>

        {/* Search + Create  */}
        <SecondRow
          pageMode={pageMode}
          queryData={queryData}
          setQueryData={setQueryData}
        />

        {!isLoaded ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIndicator />
          </Box>
        ) : pageMode === (ACCESS_CONTROL as ViewMode) ? (
          <ManageCertificateListTable
            certificateList={certificateList as API_Teacher_Certificate[]}
            setCertificateList={
              setCertificateList as React.Dispatch<
                React.SetStateAction<API_Teacher_Certificate[]>
              >
            }
            setSelected={setSelected}
          />
        ) : //   <AnalyticModuleListTable
        //     certificateList={certificateList as API_Teacher_Module_Analytic[]}
        //     setCertificateList={
        //       setCertificateList as React.Dispatch<
        //         React.SetStateAction<API_Teacher_Module_Analytic[]>
        //       >
        //     }
        //     setSelected={setSelected}
        //   />
        null}
      </Box>
    </Box>
  );
};

export default ManageTeacherCertificatePage;

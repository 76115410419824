import React, { useState } from "react";

import { Box, Chip } from "@mui/material";

import Image from "components/Image/Image";

import useStore from "store/store";

const LandingScreen = () => {
  const [imgIndex, setImgIndex] = useState<number>(0);

  const { currentProject, imgs, description } = useStore((state) => ({
    currentProject: state.currentProject,
    imgs: state.currentProject.images.reduce((res, curr) => {
      res.push(curr.file);
      return res;
    }, [] as string[]),
    description: state.currentProject.description,
  }));

  return imgs.length > 0 ? (
    <Box
      sx={{
        p: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        maxWidth: "1000px",
        m: "0 auto",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "center", sm: "start" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "300px",
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            minWidth: "300px",
            minHeight: "300px",
            width: "300px",
            height: "300px",
            borderRadius: "40px",
            overflow: "hidden",
          }}
        >
          <Image src={imgs[imgIndex]} objectFit="cover" alt="main" />
        </Box>

        <Box
          sx={{ display: "flex", gap: "12px", flexWrap: "wrap", mt: "20px" }}
        >
          {imgs.map((img, index) => {
            return (
              <Box
                key={index}
                sx={{
                  width: "66px",
                  height: "44px",
                  overflow: "hidden",
                  cursor: "pointer",
                  opacity: "0.7",
                  transition: "100ms all ease-in-out",
                  "&:hover": {
                    opacity: 1,
                    transition: "100ms all ease-in-out",
                  },
                }}
                onClick={() => setImgIndex(index)}
              >
                <Image
                  src={img}
                  objectFit="cover"
                  style={{
                    borderRadius: "6px",
                  }}
                  alt="secondary"
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          mt: "16px",
        }}
      >
        <div
          style={{ fontSize: "20px", lineHeight: "1.5" }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {currentProject.tags !== "" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              mt: 1,
            }}
          >
            {/* <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
              {translation.tags}
            </Typography> */}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {currentProject.tags.split(",").map((vocab) => {
                return (
                  <Chip
                    key={`vocabulary-${vocab}`}
                    // color={"primary"}
                    label={vocab}
                    variant="outlined"
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={{ p: "30px", maxWidth: "1000px", m: "0 auto" }}>
      <div
        style={{ fontSize: "20px", lineHeight: "1.5" }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Box>
  );
};

export default LandingScreen;

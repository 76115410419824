import React from "react";
import { Box, IconButton, SxProps, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import {
  CURLANG,
  TEACHER_MODULE_MANAGE_PAGE,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";
import { ViewMode } from "types/teacher";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { ACCESS_CONTROL, INSIGHTS } from "constants/staticConstant";

const FirstRow = ({
  pageMode,
  setPageMode,
  children,
}: {
  pageMode: ViewMode;
  setPageMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2.5,
      }}
    >
      <BreadCrumbs />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2.75,
        }}
      >
        {children}

        {/* <TTAIcons pageMode={pageMode} setPageMode={setPageMode} /> */}
      </Box>
    </Box>
  );
};

const BreadCrumbs = () => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      <Typography
        sx={{
          cursor: "pointer",
          lineHeight: 1,
        }}
        onClick={() => {
          navigate(`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`);
        }}
      >
        Certificate Library
      </Typography>

      <NavigateNextIcon fontSize="small" />

      <Typography sx={{ fontWeight: "bold", color: "#407374", lineHeight: 1 }}>
        Manage Certificates
      </Typography>
    </Box>
  );
};

export const TTAIcons = ({
  pageMode,
  setPageMode,
}: {
  pageMode: ViewMode;
  setPageMode: React.Dispatch<React.SetStateAction<ViewMode>>;
}) => {
  const navigate = useNavigate();
  const iconStyle: SxProps = {
    color: "#312F30",
    cursor: "pointer",
  };

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      <IconButton
        aria-label="access-control-icon-button"
        onClick={() => {
          // setPageMode(ACCESS_CONTROL);
          navigate(
            `${CURLANG(
              currLanguage
            )}/${TEACHER_MODULE_MANAGE_PAGE}/${ACCESS_CONTROL}`
          );
        }}
      >
        <LockOutlinedIcon
          fontSize="small"
          sx={{
            ...iconStyle,
            color:
              pageMode === (ACCESS_CONTROL as ViewMode) ? "#407374" : "default",
            opacity: pageMode === (ACCESS_CONTROL as ViewMode) ? 1 : 0.5,
            pointerEvents: "none",
          }}
        />
      </IconButton>
      <IconButton
        aria-label="analytic-icon-button"
        onClick={() => {
          navigate(
            `${CURLANG(currLanguage)}/${TEACHER_MODULE_MANAGE_PAGE}/${INSIGHTS}`
          );
          // setPageMode(INSIGHTS as ViewMode);
        }}
      >
        <TableChartOutlinedIcon
          fontSize="small"
          sx={{
            ...iconStyle,
            pointerEvents: "none",
            opacity: pageMode === (INSIGHTS as ViewMode) ? 1.0 : 0.5,
            color: pageMode === (INSIGHTS as ViewMode) ? "#407374" : "default",
          }}
        />
      </IconButton>
      <IconButton
        aria-label="chart-icon-button"
        onClick={() => {
          // setPageMode(ACCESS_CONTROL);
          navigate(
            `${CURLANG(
              currLanguage
            )}/${TEACHER_MODULE_MANAGE_PAGE}/${ACCESS_CONTROL}`
          );
        }}
        disabled
      >
        <AssessmentOutlinedIcon
          fontSize="small"
          sx={{ ...iconStyle, pointerEvents: "none", opacity: 0.5 }}
        />
      </IconButton>
    </Box>
  );
};

export default FirstRow;

import React, { ChangeEvent } from "react";
import { Box, Typography } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import PhotoLibraryIcon from "images/photoLibraryIcon";

import { API_Project_Lesson_Image, API_Project_Image } from "types/project";
import Image from "components/Image/Image";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
const MAX_FILE_SIZE_MB = 5; // Maximum allowed file size in megabytes

const ImageField = ({
  images,
  setImages,
  multiple = false,
  oldImages,
  isDeleteImageList,
  setIsDeleteImageList,
}: {
  images: File[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  multiple?: boolean;
  oldImages?: API_Project_Lesson_Image[] | API_Project_Image[];
  isDeleteImageList?: boolean[];
  setIsDeleteImageList?: React.Dispatch<React.SetStateAction<boolean[]>>;
}) => {
  const openSnack = useSnack();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newImages: File[] = Array.prototype.slice.call(event.target.files);

    if (newImages.length === 0) {
      event.target.value = "";
      return;
    }

    if (newImages.some((file) => file.size > MAX_FILE_SIZE_MB * 1024 * 1024)) {
      // Handle oversized files
      openSnack(
        `Cannot upload images larger than ${MAX_FILE_SIZE_MB}MB.`,
        false
      );
    } else {
      setImages((prev: File[]) => [...prev, ...newImages]);
    }

    event.target.value = "";
  };

  return (
    <Box>
      <input
        accept="image/*"
        type="file"
        id="image-button"
        multiple={multiple}
        hidden
        onChange={handleOnChange}
      />
      <label htmlFor="image-button" style={{ width: "fit-content" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            border: "1px dashed #8C8C8C",
            padding: "24px 12px",
            gap: 1.5,
            width: "fit-content",
            cursor: "pointer",
          }}
        >
          <PhotoLibraryIcon />
          <Typography>{translation.selectDropLesson}</Typography>
        </Box>
      </label>
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}>
        {oldImages &&
          isDeleteImageList &&
          setIsDeleteImageList &&
          oldImages.map((img, index) => (
            <Box
              sx={{
                width: "100px",
                height: "100px",
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
                border: `${isDeleteImageList[index] ? "2px solid red" : ""}`,
              }}
              key={index}
            >
              {isDeleteImageList[index] && (
                <Box
                  sx={{
                    position: "absolute",
                    backgroundColor: "#fc6f6fb3",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                />
              )}

              <Image src={img.file} alt={img.file} />

              <ClearIcon
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  zIndex: 3,
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setIsDeleteImageList((prev) => {
                    const temp = JSON.parse(JSON.stringify(prev));
                    temp[index] = !temp[index];
                    return temp;
                  });
                }}
              />
            </Box>
          ))}

        {images.map((img, index) => (
          <Box
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "10px",
              overflow: "hidden",
              position: "relative",
            }}
            key={index}
          >
            <Image src={URL.createObjectURL(img)} alt={img.name} />

            <ClearIcon
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                color: "txt.secondary",
                cursor: "pointer",
                backgroundColor: "primary.light",
                borderRadius: "50%",
                p: "3px",
                transition: "all 100ms linear",
                "&:hover": {
                  p: "4px",
                  backgroundColor: "primary.main",
                  transition: "all 100ms linear",
                },
              }}
              onClick={() => {
                setImages((prev) => {
                  const temp = [...prev];
                  temp.splice(index, 1);
                  return temp;
                });
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageField;

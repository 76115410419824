import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { translation } from "constants/translation";

type Props = {
  handleChange: (searchVal: string) => void;
}

const SearchBar = ({ handleChange }: Props) => {
  const [searchVal, setSearchVal] = useState<string>("");

  return (
    <Paper
      component="form"
      sx={{
        boxShadow: "none",
        border: "1px solid #C9C9C9",
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "inherit",
        maxWidth: 400,
        height: 43,
        borderRadius: "4px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={`${translation.search} Name`}
        inputProps={{ "aria-label": "search for name" }}
        value={searchVal}
        onChange={(e) => {
          setSearchVal(e.target.value);
          handleChange(e.target.value);
        }}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      />
      <IconButton sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
import { useRef } from "react"
import { Box } from '@mui/material';
import type { Image as ImageType } from "./types"
import Image from "./Image";

const calculateMaxImagesToShow = (maxRows: number, images: ImageType[], parentElement: HTMLElement) => {
  const imageWidth = 120 + 8;
  const parentWidth = parentElement.clientWidth - 120;
  const maxImagesPerRow = Math.floor(parentWidth / imageWidth);
  const maxImages = maxRows * (maxImagesPerRow);
  return images.slice(0, maxImages);
};

type Props = {
  images: ImageType[];
  margin: number;
  maxRows: number;
  isForLearningMomentTab: boolean;
  rowHeight: number;
  onClick: (imageIndex: number) => void;
  addPhoto: JSX.Element | false;
};

const Gallery = ({ images, maxRows, onClick, isForLearningMomentTab, margin, addPhoto }: Props) => {
  const parentRef = useRef<HTMLElement>();
  const maxImagesToShow = parentRef.current ? calculateMaxImagesToShow(maxRows, images, parentRef.current) : [];
  const restOfTheImagesLength = images.length - maxImagesToShow.length;

  const handleClick = (index: number) => {
    if (index === maxImagesToShow.length - 1 && restOfTheImagesLength > 0) {
      onClick(-1)
    } else {
      onClick(index)
    }
  }

  return (
    <Box
      ref={parentRef}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        alignItems: "center",
        gap: margin / 2,
        paddingRight: margin + 1,
      }}
      onClick={() => {

      }}
    >
      {addPhoto}
      {maxImagesToShow.map((image, index) => (
        <Image
          key={image.key}
          image={image}
          onClick={() => handleClick(index)}
          isForLearningMomentTab={isForLearningMomentTab}
          textOverlay={(index === maxImagesToShow.length - 1 && restOfTheImagesLength > 0)
            ? `+${restOfTheImagesLength}`
            : undefined
          }
        />
      ))}
    </Box>
  );
};

export default Gallery;
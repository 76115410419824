import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Backdrop, Box, Fade, Modal, SxProps, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import Button from "components/Button/Button";
import { translation } from "constants/translation";
import Image from "components/Image/Image";
import ModuleTag from "components/ModuleTag/ModuleTag";
import { getImageById } from "api/gallery-api";
import useStore from "store/store";
import { API_Project_Module, Age_Group } from "types/project";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import {
  BLOOMS,
  BLOSSOMS,
  BUDS,
  SPROUTS,
  SPROUTLINGS,
} from "constants/project-lesson-activity";
import PhotoBookCoverFrontTop from "images/children-photo-book/photo-book-cover-front-top.png";
import { photoBookBoxStyle } from "containers/WelcomeBackPage/components/ParentDashboardComponents/ChildrenPhotoComponents/AlbumPreviewContent";
import { photoBookImageStyle } from "containers/WelcomeBackPage/components/ParentDashboardComponents/ChildrenPhotoComponents/Templates/PhotoBookComponents/constant";
import Logo from "images/logo-2.png";

export interface ImageType {
  id: number;
  link: string;
  caption: string;
  module?: API_Project_Module;
  created_at: string;
  is_horizontal?: boolean;
  is_vertical?: boolean;
}

export interface Album extends ImageType {
  project: {
    id: number;
    name: string;
    age: Age_Group;
    module: API_Project_Module;
    lessons: number[];
    image?: string;
  };
  lesson_id: number;
  lesson_name: string;
}

export const DATE_FORMAT = "DD MMM YYYY";

export const ToggleButton = ({
  label,
  isActive,
  onClick,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
}) => (
  <Button
    arrow={false}
    buttonText={label}
    style={{
      width: "100%",
      maxWidth: "90px",
      padding: "4px 16px",
      fontSize: "14px",
      fontWeight: 500,
      color: isActive ? "white" : "var(--primary-main)",
      borderRadius: "6px",
      border: "1px solid",
      borderColor: "var(--primary-main)",
      backgroundColor: isActive ? "var(--primary-main)" : "white",
      pointerEvents: isActive ? "none" : "auto",
      textTransform: "capitalize",
    }}
    onClick={onClick}
  />
);

export const NoPhotoYet = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "80px",
    }}
  >
    <Typography>{translation.noPhoto}</Typography>
  </Box>
);

export const cardStyles: SxProps = {
  position: "relative",
  cursor: "pointer",
  borderRadius: "8px",
  width: "100%",
  height: "200px",
  overflow: "hidden",
  boxShadow: 5,
  backgroundColor: "#F4F4F4",
  transition: "all 100ms ease",
  "&:hover": {
    boxShadow: 7,
    transition: "all 100ms ease",
  },
};

export const containerStyles: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  gap: 2,
};

export const labelStyles: SxProps = {
  position: "absolute",
  lineHeight: 1,
  backgroundColor: "rgba(255, 255, 255, 0.75)",
  fontSize: 14,
};

export const reportStyles: SxProps = {
  position: "absolute",
  lineHeight: 1,
  backgroundColor: "#F8FDFD",
  fontSize: 14,
};

export const ImageCard = ({
  src,
  label,
  caption,
  module,
  fromNow,
  onClick,
  sx = {},
}: {
  src: string;
  label?: string;
  caption?: string;
  module?: API_Project_Module;
  fromNow?: string;
  onClick: () => void;
  sx?: SxProps;
}) => (
  <Box sx={{ ...cardStyles, ...sx }} onClick={onClick}>
    {label && (
      <Typography
        sx={{
          ...labelStyles,
          top: 0,
          left: 0,
          fontWeight: 500,
          py: 0.75,
          px: 1.25,
          borderBottomRightRadius: "8px",
        }}
      >
        {label}
      </Typography>
    )}

    {module && (
      <ModuleTag
        projModule={module}
        styles={{
          position: "absolute",
          top: "8px",
          left: "8px",
          mb: 0,
          backgroundColor: "rgba(209,228,227,0.75)",
        }}
      />
    )}

    <Image
      src={src}
      alt={caption ? caption : ""}
      objectFit="cover"
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    />

    {(caption || fromNow) && (
      <Box
        sx={{
          ...labelStyles,
          bottom: 0,
          width: "100%",
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 0.75,
        }}
      >
        {caption && (
          <Typography sx={{ fontSize: 14, lineHeight: 1 }} noWrap>
            {caption}
          </Typography>
        )}

        {fromNow && (
          <Typography
            sx={{
              fontSize: 10,
              lineHeight: 1,
              fontWeight: 700,
              color: "#312F30",
            }}
            noWrap
          >
            {fromNow}
          </Typography>
        )}
      </Box>
    )}
  </Box>
);

export const ProjectCard = ({
  image,
  onClick,
  sx = {},
}: {
  image: Album;
  onClick: () => void;
  sx?: SxProps;
}) => {
  const age = image.project.age;

  let ageGroupImgSrc = "";
  let translatedAge = "";

  if (age === BLOSSOMS || age === BLOOMS) {
    ageGroupImgSrc = BlossomsIcon;
    translatedAge = translation.Blossoms;
  } else if (age === BUDS) {
    ageGroupImgSrc = BudsIcon;
    translatedAge = translation.Buds;
  } else if (age === SPROUTS || age === SPROUTLINGS) {
    ageGroupImgSrc = SproutsIcon;
    translatedAge = translation.Sprouts;
  }

  return (
    <Box sx={{ ...cardStyles, ...sx }} onClick={onClick}>
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 1,
          background:
            "linear-gradient(180deg, rgba(255,255,255,0.75) 30%, rgba(255,255,255,0) 100%)",
        }}
      >
        <Box>
          <Typography noWrap sx={{ fontSize: 14 }}>
            {image.project.name}
          </Typography>

          {ageGroupImgSrc !== "" && translatedAge !== "" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.75,
                my: "1px",
              }}
            >
              <Image
                src={ageGroupImgSrc}
                alt={translatedAge}
                objectFit="contain"
                style={{
                  display: "flex",
                  width: "20px",
                  height: "20px",
                }}
              />

              <Typography sx={{ fontSize: 12, textTransform: "uppercase" }}>
                {translatedAge}
              </Typography>
            </Box>
          )}

          <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
            {moment(image.created_at).fromNow()}
          </Typography>
        </Box>

        <ModuleTag
          projModule={image.project.module}
          styles={{
            mt: "auto",
            mb: 0,
            alignSelf: "flex-start",
            backgroundColor: "rgba(209,228,227,0.75)",
          }}
        />
      </Box>

      <Image
        src={image.link}
        alt={image.caption}
        objectFit="cover"
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
};

export const ChildPhotoProjectCard = ({
  image,
  onClick,
  sx = {},
}: {
  image: Album;
  onClick: () => void;
  sx?: SxProps;
}) => {
  return (
    <Box sx={{ ...cardStyles, ...sx, ...photoBookBoxStyle }} onClick={onClick}>
      <Image
        src={image.project?.image || Logo}
        alt={"photo-book-page-1"}
        objectFit="cover"
        style={{
          position: "absolute",
          top: 50,
          left: 30,
          width: 180,
          height: 130,
        }}
      />
      <Image
        src={PhotoBookCoverFrontTop}
        alt={"photo-book-cover-front-top"}
        objectFit="cover"
        style={{
          ...photoBookImageStyle,
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />

      <Typography
        sx={{
          position: "absolute",
          top: 10,
          left: 15,
          width: 200,
          textAlign: "center",
          zIndex: 2,
          fontSize: 10,
          display: "-webkit-box",
          WebkitLineClamp: `2`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {image.project.module.name}
      </Typography>

      <Typography
        sx={{
          position: "absolute",
          top: 20,
          left: 15,
          width: 200,
          textAlign: "center",
          zIndex: 2,
          fontSize: 14,
          display: "-webkit-box",
          WebkitLineClamp: `1`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {image.project.name}
      </Typography>
    </Box>
  );
};

export const ImagePreviewModal = ({
  open,
  image,
  handleClose,
}: {
  open: boolean;
  image: ImageType;
  handleClose: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [module, setModule] = useState<API_Project_Module>();
  const [lessonTitle, setLessonTitle] = useState<string>("");

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  useEffect(() => {
    const getGalleryData = async () => {
      setIsLoading(true);

      const res = await getImageById(
        currLanguage,
        image.id.toString(),
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        setModule((res as any).module);
        setLessonTitle((res as any).lesson_title);
      }

      setIsLoading(false);
    };

    getGalleryData();
  }, [image, currLanguage]);

  const date = moment(image.created_at).format(DATE_FORMAT);

  const handleDownload = async () => {
    await axios({
      url: image.link + "?add-this-to-prevent-read-from-cache",
      method: "GET",
      responseType: "blob",
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const nameSplit = image.link.split("/");
      const name = nameSplit.pop();
      link.href = url;
      link.setAttribute("download", name ? name : "image.png");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            border: "1px solid var(--primary-main)",
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                p: 4,
              }}
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  px: 3,
                  py: 1.5,
                  textAlign: "center",
                  borderBottom: "1px solid var(--primary-main)",
                }}
              >
                {date}
              </Typography>

              <Box sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Box>
                    {module && (
                      <ModuleTag projModule={module} styles={{ mb: 0.5 }} />
                    )}

                    {lessonTitle !== "" && (
                      <Typography sx={{ fontWeight: 700 }}>
                        {lessonTitle}
                      </Typography>
                    )}
                  </Box>

                  <DownloadIcon
                    sx={{
                      cursor: "pointer",
                      transition: "all 100ms ease-in-out",
                      "&:hover": {
                        color: "var(--primary-main)",
                        transition: "all 100ms ease-in-out",
                      },
                    }}
                    onClick={handleDownload}
                  />
                </Box>

                <Image
                  src={image.link}
                  alt={image.caption}
                  objectFit="contain"
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    maxHeight: "500px",
                    backgroundColor: "#F4F4F4",
                    margin: "20px 0",
                  }}
                />

                <Typography>{image.caption}</Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {date}
                </Typography>
                <Box sx={{mt:2}}>
                <Button
                  buttonText= "Create Learning Story"
                  btnType="outlined"
                  arrow={false}
                  onClick={() => {
                    handleClose()
                  }}
                />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export const AddAlbum = ({ onClick }: { onClick: () => void }) => (
  <Box
    sx={{
      width: "200px",
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 0.5,
      padding: "16px",
      borderRadius: "10px",
      color: "#444",
      border: "1px dashed",
      borderColor: "#444",
      transition: "all 100ms ease-in-out",
      cursor: "pointer",
      "&:hover": {
        color: "primary.main",
        borderColor: "primary.main",
        backgroundColor: "rgba(209, 228, 227, 0.37)",
        transition: "all 100ms ease-in-out",
      },
    }}
    onClick={onClick}
  >
    <AddIcon />

    <Typography
      sx={{
        fontSize: 14,
      }}
    >
      {translation.addNewAlbum}
    </Typography>
  </Box>
);

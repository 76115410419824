import { Page, Image, View } from "@react-pdf/renderer";
import { imageSrc, pageSize, styles } from "./constant";
import DevelopmentalGoalsAndMilestonesPageBackground from "images/children-portfolio-v2/developmental-goals-and-milestone-background.png";

const DevelopmentalGoalsAndMilestonesPage = () => {
  return (
    <Page size={pageSize} style={styles.body} wrap={false}>
      <View style={styles.main} wrap={false}>
        <Image
          src={imageSrc(DevelopmentalGoalsAndMilestonesPageBackground)}
          style={styles.background}
        />
      </View>
    </Page>
  );
};

export default DevelopmentalGoalsAndMilestonesPage;

import React from "react";
import {
  editableStyle as ProjectReportPDFEditableStyles,
  styles as ProjectReportPDFStyles,
} from "../../ProjectReportPDF/Preview/constant";
import { Overlay as ProjectReportOverlay } from "../../ProjectReportPDF/Preview/Preview";
import { ImageType as ProjectReportPDFImageType } from "../../ProjectReportPDF/Preview/constant";
import { SxProps } from "@mui/material";
import {
  API_Child,
  API_Project_Completed_For_Portfolio,
  API_Project_Modules_For_Portfolio,
  API_LEARNING_STORIES_FOR_PORTFOLIO,
} from "types/portfolio";
import { API_Group_Portfolio } from "types/group";
import { API_Fundamental } from "types/project";

export interface PDFInfoType {
  profileName: string;
  startMonth: string;
  endMonth: string;
  profileImage: string;
  introductionText: string;
  completedLittleProjectList: API_Project_Completed_For_Portfolio[];
  completedLittleLearnerList: API_Project_Completed_For_Portfolio[];
  concludingRemarkText: string;
  concludingRemarkImage: string;
  currentGroup: API_Group_Portfolio;
  childImages: ImageType[];
  photoGalleryTemplateNumber: number;
  developmentalGoalList: API_Fundamental[];
  observationList: API_Child.PortfolioObservation[];
  completedWorkImages: ImageType[];
  completedWorkTemplateNumber: number;
  categories: API_Project_Modules_For_Portfolio[];
  childAllAchievedMilestoneTrackers: any[];
  learningStories: API_LEARNING_STORIES_FOR_PORTFOLIO[];
}

export const MONTH_FORMAT = "MMM YYYY";

export const editableStyle: SxProps = {
  ...ProjectReportPDFEditableStyles,
};

export const styles: {
  body: SxProps;
  main: SxProps;
  staticImage: React.CSSProperties;
} = {
  body: {
    ...ProjectReportPDFStyles.body,
    backgroundColor: "white",
  },
  main: {
    ...ProjectReportPDFStyles.main,
  },
  staticImage: {
    ...ProjectReportPDFStyles.staticImage,
  },
};

export const Overlay = ProjectReportOverlay;

export type ImageType = ProjectReportPDFImageType;

// Project accomplished:
export const littleProjectValueCommon = {
  textTopMarginPx: 79,
  textLeftMarginPx: 40,
  textFontsize: 20,
};

export const littleProjectValue3x4x = {
  topMarginPxForFirstRow: 160, // Top Left
  topMarginPxForSecondRow: 492, // Bottom Left
  leftMarginPxForFirstColumn: 90, //Top left
  leftMarginPxForSecondColumn: 340, // Top Right
  imageWidth: 200,
  imageHeight: 155,
};

export const littleProjectValue2x = {
  topMarginPxForFirstRow: 160, // Top Left
  topMarginPxForSecond: 492, // Bottom Left
  leftMargin: 206,
  imageWidth: 200,
  imageHeight: 155,
};

export const littleProjectValue1x = {
  topMargin: 180,
  leftMargin: 168,
  imageWidth: 297,
  imageHeight: 225,
};

export const littleLearnerValue3x4x = {
  topMarginPxForFirstRow: 185, // Top Left
  topMarginPxForSecondRow: 477, // Bottom Left
  leftMarginPxForFirstColumn: 88, //Top left
  leftMarginPxForSecondColumn: 343, // Top Right
  imageWidth: 200,
  imageHeight: 155,
  topMarginPxModuleTagForFirstRow: 145,
  topMarginPxModuleTagForSecondRow: 437,
};

export const littleLearnerValue2x = {
  topMarginPxForFirstRow: 190, // Top Left
  topMarginPxForSecond: 479, // Bottom Left
  leftMargin: 208,
  imageWidth: 201,
  imageHeight: 155,
  topMarginPxModuleTagForFirstRow: 153,
  topMarginPxModuleTagForSecondRow: 443,
};

export const littleLearnerValue1x = {
  topMargin: 200,
  leftMargin: 169,
  imageWidth: 294,
  imageHeight: 225,
  topMarginModule: 150,
};

export const titleTextStyle = {
  fontWeight: 500,
  fontSize: 25.2,
  fontFamily: "TheOnlyException",
  lineHeight: 1.25,
  textAlign: "center",
  position: "absolute",
  py: 0.5,
  left: 0,
  right: 0,
  zIndex: 2,
};

export const projectValueCommon = {
  textTopMarginPx: 100,
  textLeftMarginPx: 20,
};

export const projectValue5x6x = {
  topMarginPxForFirstRow: 190,
  topMarginPxForSecondRow: 405,
  topMarginPxForThirdRow: 610,
  leftMarginPxForFirstColumn: 80,
  leftMarginPxForSecondColumn: 335,
  leftMarginPxForOneHalfColumn: 208,
  imageWidth: 180,
  imageHeight: 130,
  topMarginPxModuleTagForFirstRow: 155,
  topMarginPxModuleTagForSecondRow: 365,
  topMarginPxModuleTagForThirdRow: 575,
  projectNameFontSize: 13,
  projectNameTopIncrementPx: 135,
  moduleNameFontSize: 9,
};

export const projectValue3x4x = {
  topMarginPxForFirstRow: 240,
  topMarginPxForSecondRow: 470,
  leftMarginPxForFirstColumn: 97,
  leftMarginPxForSecondColumn: 347,
  leftMarginPxForOneHalfColumn: 218,
  imageWidth: 180,
  imageHeight: 130,
  topMarginPxModuleTagForFirstRow: 200,
  topMarginPxModuleTagForSecondRow: 430,
  projectNameFontSize: 13,
};

export const projectValue2x = {
  topMarginPxForFirstRow: 225,
  topMarginPxForSecond: 525,
  leftMargin: 208,
  imageWidth: 201,
  imageHeight: 150,
  topMarginPxModuleTagForFirstRow: 180,
  topMarginPxModuleTagForSecondRow: 480,
  projectNameFontSize: 16,
};

export const projectValue1x = {
  topMargin: 270,
  leftMargin: 155,
  imageWidth: 300,
  imageHeight: 200,
  topMarginModule: 210,
  projectNameFontSize: 24,
};

export const textStyleForIndividualBlock = {
  fontWeight: 500,
  fontFamily: "FuturaLtBT",
  lineHeight: 1.25,
};
import { useEffect, useRef, useState } from "react";
import { Editor, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Box, Menu, SxProps, Typography, useTheme, useMediaQuery } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStore from "../../../../../store/store";
import TeacherDashboardSun from "./TeacherDashboardSun.svg";
import Image from "components/Image/Image";

import { WidgetContainer, Loading, useWidgetColors } from "./Components";
import { translation } from "../../../../../constants/translation";
import { API_Create_Todo_List, API_Todo_List } from "../../../../../types/profile";
import { createToDoList, getToDoList, updateToDoList } from "../../../../../api/profile-api";
import useSnack from "../../../../../hooks/useSnack";

type Props = {
    setExpandMobile?: (value: string) => void;
    isExpanded: boolean;
};

const Help = ({ isExpanded, setExpandMobile }: Props) => {
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const openSnack = useSnack();
    const colors = useWidgetColors();

    const { currLanguage, profileDetails } = useStore((state) => ({
        currLanguage: state.currLanguage,
        profileDetails: state.profileDetails,
    }));

    const initialTodoList: API_Todo_List = {
        id: -1,
        profile: profileDetails.id,
        content: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
        color: 0,
    };

    const [todoList, setTodoList] = useState<API_Todo_List>(initialTodoList);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const editorRef = useRef<Editor>(null);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

    const color = colors[todoList.color % colors.length];

    useEffect(() => {
        if (profileDetails.id === -1) return;

        const fetchTodoList = async () => {
            setIsLoading(true);

            try {
                const todoList = await getToDoList(currLanguage, profileDetails.id);

                const updatedTodoList =
                    todoList.id === undefined || todoList.id === null ? initialTodoList : todoList;

                setTodoList(updatedTodoList);
                handleUpdateEditorState(updatedTodoList);
            } catch (error) {
                setTodoList(initialTodoList);
            }

            setIsLoading(false);
        };

        fetchTodoList();
    }, [profileDetails.id]);

    const handleOpenColorMenu = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleUpdate = async (data: API_Create_Todo_List) => {
        if (data.content === todoList.content && data.color === todoList.color) return;

        const updatedTodoList =
            todoList.id === -1
                ? await createToDoList(currLanguage, data)
                : await updateToDoList(currLanguage, todoList.id, data);

        if (typeof updatedTodoList === "string") {
            openSnack(translation.notesUpdatedFailed, false);
        } else {
            openSnack(translation.notesUpdatedSuccess, true);
            setTodoList(updatedTodoList);
            handleUpdateEditorState(updatedTodoList);
        }
    };

    const handleUpdateEditorState = (todoList: API_Todo_List) => {
        try {
            const contentState = convertFromRaw(JSON.parse(todoList.content));
            setEditorState(EditorState.createWithContent(contentState));
        } catch (error) {
            setTodoList({ ...todoList, content: initialTodoList.content });
            setEditorState(EditorState.createEmpty());
        }
    };
    const isSmallScreen = window.matchMedia('(max-width: 1450px)').matches;


    const helpLinks = [
        "Tracking Children Development and Create Report",
        "Choosing lessons for your classes",
        "Little Evaluator Assessments",
        "Announcement",
    ];
    return (
        <WidgetContainer
            sx={{
                backgroundColor: "white",
                height: {
                    xs: !isExpanded ? "48px" : "100%",
                    lg: !isExpanded ? "48px" : "100%",
                },
                minHeight: {
                    xs: !isExpanded ? "48px" : "150px",
                    lg: !isExpanded ? "48px" : "100%",
                },
                transition: "all 100ms ease-in-out",
            }}
        >
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: isExpanded ? "flex-end" : "space-between",
                            width: "100%",
                        }}
                    >
                        {!isExpanded && (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyItems: "center",
                                        gap: 1,
                                    }}
                                >
                                    {" "}
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                        }}
                                    >
                                        Need more help?{" "}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "#79B5B4",
                                            fontWeight: "400",
                                        }}
                                    >
                                        Visit our Help Center
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Image
                                            src={TeacherDashboardSun}
                                            alt="background"
                                            objectFit="contain"
                                            style={{
                                                position: "relative",
                                                top: "-10px",
                                                width: "71px",
                                                height: "50px",
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-start",
                            }}
                        >
                            {!!setExpandMobile && (
                                <ExpandMoreIcon
                                    onClick={() => setExpandMobile?.(isExpanded ? "" : "helpcenter")}
                                    sx={{
                                        cursor: "pointer",
                                        opacity: 0.5,
                                        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                                        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                                    }}
                                />
                            )}
                        </Box>
                    </Box>

                    {isExpanded && (
                        <>
                            <Box
                                sx={{

                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundImage: `url(${TeacherDashboardSun})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: isSmallScreen ? "calc(100%) calc(0% - 5px)" : "calc(90%) calc(0% + 10px)",
                                    backgroundSize: "107px 97px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        color: "#79B5B4",
                                        m: 1,
                                    }}
                                >
                                    Hello!
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#8C8C8C",
                                        m: 1,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            "Welcome to Trehaus Teaching Tool Box.<br/> Here are some resources to help you get started.",
                                    }}
                                />

                                {helpLinks.map((link: any) => {
                                    return (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                mb: 1,
                                            }}
                                        >
                                            <ArrowOutwardIcon sx={{ mr: "12px", color: "#91C6C5" }} />
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                {link}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 1 }}>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: "450",
                                        }}
                                    >
                                        Need more help?
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "#79B5B4",
                                            fontWeight: "450",
                                        }}
                                    >
                                        Visit our Help Center
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}
        </WidgetContainer>
    );
};

const iconStyles: SxProps = {
    color: "#312F30",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export default Help;


import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { API_Teacher_Module_Visualization_Demographic_Info } from "types/teacher";
import { responsiveContainerStyle } from "./VisualizationPage";

interface GenderCount {
  name: string;
  Male: number;
  Female: number;
}

const AgeGenderBarChart = ({
  data,
}: {
  data: API_Teacher_Module_Visualization_Demographic_Info[];
}) => {
  const ageBucketLabels: Record<string, string> = {
    g00: "0-9 yrs",
    g01: "10-19 yrs",
    g02: "20-29 yrs",
    g03: "30-39 yrs",
    g04: "40-49 yrs",
    g05: "50-59 yrs",
    g06: "60-69 yrs",
    g07: "70-79 yrs",
    g08: "80-89 yrs",
    g09: "90++ yrs",
  };

  let processedData: GenderCount[] = data.reduce(
    (
      acc: GenderCount[],
      item: API_Teacher_Module_Visualization_Demographic_Info
    ) => {
      const ageBucket: number = Math.floor(item.age / 10);
      const ageBucketLabel =
        ageBucketLabels[`g${ageBucket.toString().padStart(2, "0")}`];
      let genderCount: GenderCount | undefined = acc.find(
        (d: GenderCount) => d.name === ageBucketLabel
      );
      if (!genderCount) {
        genderCount = { name: ageBucketLabel, Male: 0, Female: 0 };
        acc.push(genderCount);
      }
      if (item.gender === "M") {
        genderCount.Male += 1;
      } else if (item.gender === "F") {
        genderCount.Female += 1;
      }
      return acc;
    },
    []
  );

  processedData = processedData.sort((a, b) => (a.name < b.name ? -1 : 1));

  return (
    <ResponsiveContainer {...responsiveContainerStyle}>
      <BarChart
        layout="horizontal"
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" dx={5}>
          {/* <Label
            value="Learner's Age Range"
            offset={-5}
            position="insideBottom"
          /> */}
        </XAxis>
        <YAxis />
        <Tooltip />
        <Bar dataKey="Male" fill="#73ccff" />
        <Bar dataKey="Female" fill="#FFC0CB" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AgeGenderBarChart;

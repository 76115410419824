import React, { useEffect, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import { API_Ticket } from "types/ticketingSystem";
import { getTicket } from "api/ticketingSystem-api";

const TicketDetailModal = ({
  open,
  handleClose,
  ticketId,
}: {
  open: boolean;
  handleClose: () => void;
  ticketId: number;
}) => {
  // console.log("ticketId: ", ticketId);
  const navigate = useNavigate();
  const { currLanguage, profileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
  }));

  const initialTicket = {
    id: -1,
    category: "",
    name: "",
    description: "",
    status: "",
    remark: "",
    created_by: -1,
    created_at: new Date(),
    created_by_name: "",
  };

  const [ticket, setTicket] = useState<API_Ticket>(initialTicket);
  //useEffect
  useEffect(() => {
    const populateData = async () => {
      if (ticketId !== -1) {
        const ticketRes = await getTicket(currLanguage, ticketId);
        setTicket(ticketRes);
      } else {
        setTicket(initialTicket);
      }
    };
    populateData();
  }, [ticketId]);

  const Close = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 15,
          right: 15,
          borderRadius: "50%",
          backgroundColor: "txt.secondary",
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <CloseIcon sx={{ color: "white" }} />
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableAutoFocus={true}
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: "2",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70vw",
          height: "70vh",
          borderRadius: "10px",
          p: "24px 48px",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: "2px 2px 20px var(--primary-main)",
          overflow: "auto",
        }}
      >
        <Close />
        <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
          {ticket.name}
        </Typography>
        <Box
          className="ck-content"
          style={{ fontSize: "18px" }}
          dangerouslySetInnerHTML={{ __html: ticket.description }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            my: "20px",
            gap: "50px",
          }}
        ></Box>
      </Box>
    </Modal>
  );
};

export default TicketDetailModal;

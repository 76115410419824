import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { getProjectByProjectSlug, getProjectLessons } from "../api/project-api";
// import { getTeacherLessons, getTeacherProjectBySlug } from '../api/teacher-api';

import useStore from "../store/store";
import useSnack from "./useSnack";

const useInitialiseCurrProjAndLess = () => {
  const { projectSlug } = useParams();
  const openSnack = useSnack();

  const {
    setCurrentLessons,
    setCurrentProject,
    refreshProjAndLess,
    setRefreshProjAndLess,
    currLanguage,
    setIsProjectLoading,
  } = useStore((state) => ({
    setCurrentLessons: state.setCurrentLessons,
    setCurrentProject: state.setCurrentProject,
    refreshProjAndLess: state.refreshProjAndLess,
    setRefreshProjAndLess: state.setRefreshProjAndLess,
    currLanguage: state.currLanguage,
    setIsProjectLoading: state.setIsProjectLoading,
  }));

  const initialiseProj = async (isShowLoading = false) => {
    if (isShowLoading) {
      setIsProjectLoading(true);
    }
    if (projectSlug) {
      const proj = await getProjectByProjectSlug(currLanguage, projectSlug);
      // console.log("proj: ", proj);
      if (typeof proj === "string") {
        openSnack(proj, false);
      } else {
        setCurrentProject(proj);
        const less = await getProjectLessons(currLanguage, proj.id.toString());
        setCurrentLessons(less);
      }
      setIsProjectLoading(false);
      setRefreshProjAndLess(false);
    }
  };

  useEffect(() => {
    // console.log("refreshing proj less");
    refreshProjAndLess && initialiseProj();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshProjAndLess]);

  useEffect(() => {
    // console.log("refreshing proj due to language");
    projectSlug && initialiseProj(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLanguage, projectSlug]);
};

export default useInitialiseCurrProjAndLess;

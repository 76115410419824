import React from "react";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageEleven from "images/children-photo-book/page-11.png";
import PhotoBookPageElevenTop from "images/children-photo-book/page-11-top.png";

import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageElevenCaptionInfo,
  photoBookPageElevenCaptionPositionList,
  photoBookPageElevenImageInfo,
  photoBookPageElevenImagePositionList,
} from "../photoBookCommonConstant";

export const PhotoBookPageElevenComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imagePositionList = photoBookPageElevenImagePositionList(images, scale);
  const captionPositionList = photoBookPageElevenCaptionPositionList(
    images,
    scale
  );
  const imageInfo = photoBookPageElevenImageInfo(scale);
  const captionInfo = photoBookPageElevenCaptionInfo(scale);

  return (
    <>
      <Image
        src={PhotoBookPageEleven}
        alt={"photo-book-page-11"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            key={`image-eleven-${imagePositionInfo.src}`}
            src={imagePositionInfo.src}
            alt={"photo-book-page-1"}
            objectFit="cover"
            style={{
              position: "absolute",
              top: imagePositionInfo.top,
              left: imageInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
            }}
          />
        );
      })}
      <Image
        src={PhotoBookPageElevenTop}
        alt={"photo-book-page-11-top"}
        objectFit="cover"
        style={{
          ...photoBookImageStyle,
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionPositionInfo.top}
            left={captionInfo.left}
            width={captionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize}
            textAlign="left"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageElevenComponent;

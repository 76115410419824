import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { slugify } from "helper/slugify";
import { Typography } from "@mui/material";
import ActivityList from "containers/ProjectPage/LessonActivityPageComponents/FullLessonActivityList";
import useStore from "store/store";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import {
  LESSON_PLANS,
  LEARNING_MOMENTS,
  translatedProjectLessonActivityTabs,
  UNIT_GUIDE,
  MILESTONE_CHECK,
  translatedProjectLessonActivityTabIcons,
} from "../constant";
import Image from "components/Image/Image";

const TabsContainerCopy = ({
  projectLessonActivityTabs,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}: {
  projectLessonActivityTabs: string[];
  open: any;
  handlePopoverOpen: any;
  handlePopoverClose: any;
}) => {
  const { currentProject } = useStore((state) => ({
    currentProject: state.currentProject,
  }));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        mt: 1.5,
      }}
    >
      {projectLessonActivityTabs.map((text) => {
        if (text === UNIT_GUIDE && currentProject.unit_guides.length <= 0) {
          return null;
        }
        if (
          text === MILESTONE_CHECK &&
          currentProject.checkpoint_fundamentals.length <= 0
        ) {
          return null;
        }

        return (
          <Tabs
            text={text}
            key={`${text}-tab`}
            open={open}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        );
      })}
    </Box>
  );
};

const Tabs = ({
  text,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}: {
  text: string;
  open: any;
  handlePopoverOpen: any;
  handlePopoverClose: any;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lessonID } = useParams();

  const {
    currentProject,
    currentLessons,
    currLanguage,
    lessonLearningMomentCheckpointCount,
    currentProjectLessonActivityTab,
  } = useStore((state) => ({
    currentProject: state.currentProject,
    currentLessons: state.currentLessons,
    currLanguage: state.currLanguage,
    currScheduleObj: state.currScheduleObj,
    lessonLearningMomentCheckpointCount:
      state.lessonLearningMomentCheckpointCount,
    currentProjectLessonActivityTab: state.currentProjectLessonActivityTab,
    setCurrentProjectLessonActivityTab:
      state.setCurrentProjectLessonActivityTab,
  }));

  const currLessonIndex = currentLessons.lessons.findIndex(
    (currentLesson) => currentLesson.id.toString() === lessonID
  );

  const [openLessonPlans, setOpenLessonPlans] = useState<boolean>(
    !pathname.includes("/summary")
  );

  const handleExpandLessonPlanClick = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    // setTracker((prev) => prev + 1);
    setOpenLessonPlans((prev) => !prev);
  };

  useEffect(() => {
    if (open) {
      setOpenLessonPlans(true);
    } else if (!open) {
      setOpenLessonPlans(false);
    }
  }, [open]);

  return (
    <>
      <Box
        id={`lesson-plan-tab-${text.toLowerCase().split(" ").join("-")}`}
        sx={{
          backgroundColor: `${
            currentProjectLessonActivityTab === text
              ? "rgba(209, 228, 227, 0.37)"
              : "#fff"
          }`,
          border: "1px solid #98C4C5",
          borderRadius: "10px",
          p: open ? "0px 16px" : "0px 8px",
          height: "40px",

          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: open ? "12px" : "0px",
          cursor: "pointer",
          transition: "all 100ms linear",
          "&:hover": {
            backgroundColor: "rgba(209, 228, 227, 0.37)",
            transition: "all 100ms linear",
          },
        }}
        onMouseEnter={(e) => {
          handlePopoverOpen(e, text === "End" ? "Project Summary" : text);
        }}
        onMouseLeave={() => {
          handlePopoverClose();
        }}
        onClick={() => {
          navigate(
            `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
              currentProject.slug
            )}/${slugify(text)}`
          );
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {/* <PlaylistAddCheckIcon
            sx={{
              background: "white",
              borderRadius: "999px",
              p: "4px",
            }}
          /> */}
          <Image
            src={translatedProjectLessonActivityTabIcons[text]}
            alt={text}
            style={{ width: 24, height: 24 }}
          />
          {open && (
            <Typography sx={{ fontSize: "16px", color: "txt.dark2" }}>
              {translatedProjectLessonActivityTabs[text]}
            </Typography>
          )}
        </Box>

        {/* Learning Moment */}
        {text === LEARNING_MOMENTS && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "primary.main",
              }}
            >
              {open && (
                <Typography
                  variant="body2"
                  sx={{
                    pr: "3",
                  }}
                >{`${lessonLearningMomentCheckpointCount.learning_moment_done}/${lessonLearningMomentCheckpointCount.learning_moment_total}`}</Typography>
              )}
            </Box>
          </>
        )}

        {/* Lesson plans */}
        {text === LESSON_PLANS && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "primary.main",
            }}
          >
            {open && (
              <Typography variant="body2">{`${lessonLearningMomentCheckpointCount.lesson_done}/${lessonLearningMomentCheckpointCount.lesson_total}`}</Typography>
            )}
            {open && (
              <>
                {openLessonPlans ? (
                  <>
                    <ExpandLess
                      color="primary"
                      onClick={handleExpandLessonPlanClick}
                    />
                  </>
                ) : (
                  <ExpandMore
                    color="primary"
                    onClick={handleExpandLessonPlanClick}
                  />
                )}
              </>
            )}
          </Box>
        )}
      </Box>

      {text === LESSON_PLANS && openLessonPlans && (
        <Box
          sx={{
            // maxHeight: "30vh",
            overflow: "auto",
            width: "100%",
          }}
        >
          <ActivityList currLessonIndex={currLessonIndex} />
        </Box>
      )}
    </>
  );
};
export default TabsContainerCopy;

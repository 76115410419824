import { MouseEvent, useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import useStore from "store/store";
import { NOTICE_PAGE, CURLANG } from "constants/url";
import { translation } from "constants/translation";
import { DrawerButton } from "containers/SchoolAccessControlPage/MiniDrawer";
import { API_School } from "types/school";

const Drawer = ({
  schools,
  currSchool,
}: {
  schools: API_School[];
  currSchool: API_School;
}) => {
  const navigate = useNavigate();
  const { noticesPageSelectedTab } = useParams();
  const [open, setOpen] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen((prev) => !prev);

  const handleChangeSchool = (schoolIndex: number) =>
    navigate(
      `${CURLANG(currLanguage)}/${NOTICE_PAGE(
        schoolIndex !== -1
          ? schools[schoolIndex].id.toString()
          : schools[0].id.toString(),
        noticesPageSelectedTab!
      )}`
    );

  return (
    <Box
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        maxWidth: "300px",
        flex: open ? "1 0 auto" : "0 0 auto",
        p: 2,
        pt: "21px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
        alignSelf: "flex-start",
        position: "sticky",
        top: "64px",
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mb: 1,
        }}
      >
        {open && (
          <Typography
            sx={{
              fontWeight: "bold",
              lineHeight: 1,
              mr: "auto",
            }}
          >
            {translation.schools}
          </Typography>
        )}

        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            m: 0.5,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
            },
          }}
          onClick={handleDrawer}
        >
          {open ? (
            <CloseIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {schools.map((school, index) => (
          <DrawerButton
            key={`${index} - ${school.name}`}
            index={index}
            currSchool={currSchool}
            school={school}
            handleChangeSchool={handleChangeSchool}
            open={open}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        ))}
      </Box>

      {!open && name !== "" && (
        <Popover
          sx={{
            pointerEvents: "none",
            borderRadius: "10px",
            ml: 1,
          }}
          elevation={2}
          open={openPopOver}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              color: "#444",
              p: 1.5,
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
        </Popover>
      )}
    </Box>
  );
};

export default Drawer;

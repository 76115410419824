import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useStore from "store/store";
import { getCompletedWorkList } from "api/completed-works-api";
import { API_Child } from "types/portfolio";
import moment from "moment";
import CompletedWorkCard from "./CompletedWorkComponents/CompletedWorkCard";
import CompletedWorkPreviewModal from "./CompletedWorkComponents/CompletedWorkPreviewModal";
import { useParams } from "react-router-dom";
import { DATE_FORMAT } from "./PhotoAlbumScreenComponents/PhotoAlbumComponents";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import { Query_Data } from "types/project";
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import ModuleFilterButton from "./CompletedWorkComponents/ModuleFilterButton";

const ChildCompletedWorksScreen = ({
  isParentView = false,
}: {
  isParentView?: boolean;
}) => {
  const { childID } = useParams();

  const {
    currGroup,
    currLanguage,
    isRefreshCompletedWork,
    setIsRefreshCompletedWork,
    completedWorkList,
    setCompletedWorkList,
  } = useStore((state) => ({
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
    isRefreshCompletedWork: state.isRefreshCompletedWork,
    setIsRefreshCompletedWork: state.setIsRefreshCompletedWork,
    completedWorkList: state.completedWorkList,
    setCompletedWorkList: state.setCompletedWorkList,
  }));

  // Search
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });
  const [selectedModuleSlugs, setSelectedModuleSlugs] = useState<string[]>([]);

  const populateCompletedWorks = async () => {
    const res = await getCompletedWorkList({
      language: currLanguage,
      childId: Number(childID),
      groupId: currGroup.id,
      queryData: queryData,
      selectedModuleSlugs: selectedModuleSlugs,
    });

    if (typeof res !== "string") {
      setCompletedWorkList(res);
    }
    setIsRefreshCompletedWork(false);
  };

  useEffect(() => {
    isRefreshCompletedWork && populateCompletedWorks();
  }, [isRefreshCompletedWork]);

  useEffect(() => {
    populateCompletedWorks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currGroup.id,
    queryData.query,
    queryData.startDate,
    queryData.endDate,
    selectedModuleSlugs.length,
  ]);

  // Preview
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCompletedWork, setSelectedCompletedWork] =
    useState<API_Child.CompletedWork>();

  const handleOpen = (completedWork: API_Child.CompletedWork) => {
    setOpen(true);
    setSelectedCompletedWork(completedWork);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompletedWork(undefined);
  };

  return (
    <>
      {open && selectedCompletedWork && (
        <CompletedWorkPreviewModal
          open={open}
          completedWork={selectedCompletedWork}
          setCompletedWork={setSelectedCompletedWork}
          isChildPortfolio
          handleClose={handleClose}
          isParentView={isParentView}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: {
            xs: "100%",
            lg: "70%",
          },
        }}
      >
        <SearchBar
          queryData={queryData}
          setQueryData={setQueryData}
          maxWidth={9999}
        />

        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            gap: 2,
          }}
        >
          <StartAtDateField queryData={queryData} setQueryData={setQueryData} />

          <EndAtDateField queryData={queryData} setQueryData={setQueryData} />

          <ModuleFilterButton
            selectedModuleSlugs={selectedModuleSlugs}
            setSelectedModuleSlugs={setSelectedModuleSlugs}
            sx={{
              flex: { xs: "1 0 100%", sm: "auto" },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: { xs: "center", sm: "left" },
            gap: 2,
          }}
        >
          {completedWorkList.map((completedWork) => (
            <CompletedWorkCard
              key={`completed-work-${completedWork.id}`}
              completedWork={completedWork}
              fromNow={moment(completedWork.completed_at).format(DATE_FORMAT)}
              isChildPortfolio
              onClick={() => handleOpen(completedWork)}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ChildCompletedWorksScreen;

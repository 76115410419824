import React, { useEffect, useState } from "react";

import useStore from "store/store";

import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "components/Button/Button";

import { updateProfile } from "api/auth-api";
import { ErrorMessage, UpdateProfile, UpdateProfileResponse } from "types/auth";
import { API_Profile } from "types/profile";
import { getAccountProfile } from "api/profile-api";
import { useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD, CURLANG } from "constants/url";

import Logo from "images/logo-2-colour.png";
import useSnack from "hooks/useSnack";
import { translation } from "constants/translation";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MONTH_FORMAT } from "components/ChildrenPortfolioPDF/Preview/constant";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import { EDUCATION_LIST, GENDER_LIST } from "containers/ProfilePage/constants";

interface FormData {
  email: string;
  mobile_number: string;
  preferred_name: string;
  full_name: string;
  gender: string;
  highest_education_qualification: string;
  education_start_date: string;
  dob?: string;
  image?: File;
}

const AccountPage = () => {
  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));

  const [formData, setFormData] = useState<FormData>({
    email: profileDetails.email,
    mobile_number: profileDetails.mobile_number,
    preferred_name: profileDetails.preferred_name,
    full_name: profileDetails.full_name,
    gender: profileDetails.gender,
    highest_education_qualification:
      profileDetails.highest_education_qualification,
    education_start_date: profileDetails.education_start_date,
    dob: profileDetails.dob,
  });

  useEffect(() => {
    if (profileDetails.email !== "")
      setFormData({
        email: profileDetails.email,
        mobile_number: profileDetails.mobile_number,
        preferred_name: profileDetails.preferred_name,
        full_name: profileDetails.full_name,
        gender: profileDetails.gender,
        highest_education_qualification:
          profileDetails.highest_education_qualification,
        education_start_date: profileDetails.education_start_date,
        dob: profileDetails.dob,
      });
  }, [profileDetails.email]);
  return formData.email === "" ? (
    <LoadingIndicator />
  ) : (
    <Stack
      component="form"
      sx={{ width: "80vw", margin: "auto", alignItems: "center", my: "16px" }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "2rem",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          {profileDetails.full_name}
        </Typography>
        <Typography>{profileDetails.email}</Typography>
      </Box>
      <ProfileImage
        image={profileDetails.image}
        imageFallbackChar={
          profileDetails.preferred_name
            ? profileDetails.preferred_name.charAt(0).toUpperCase()
            : ""
        }
        setFormData={setFormData}
      />
      <Typography sx={{ fontWeight: "500" }}>Edit Details</Typography>

      <NameForm formData={formData} setFormData={setFormData} />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <GenderForm formData={formData} setFormData={setFormData} />
        <HighestEducationQualificationForm
          formData={formData}
          setFormData={setFormData}
        />
      </Box>
      <MobileNumberForm formData={formData} setFormData={setFormData} />

      <Box sx={{ display: "flex", gap: 1 }}>
        <DobField formData={formData} setFormData={setFormData} />
        {/* <EducationStartDateField
          formData={formData}
          setFormData={setFormData}
        /> */}
      </Box>
      <SubmitButton
        formData={formData}
        profileID={profileDetails.id.toString()}
      />
      <ChangePasswordButton />
    </Stack>
  );
};

const ProfileImage = ({
  image,
  imageFallbackChar,
  setFormData,
}: {
  image: string;
  imageFallbackChar: string;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}) => {
  const [currImage, setCurrImage] = useState<string>("");
  const openSnack = useSnack();

  useEffect(() => {
    setCurrImage(image);
  }, [image]);

  const iconStyles: SxProps = {
    fontSize: "2.5vw",
    color: "white",
    opacity: 0.5,
    transition: "all 150ms linear",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];

    if (newFile) {
      const fileSizeLimit = 5 * 1024 * 1024; // 5MB limit

      if (newFile.size > fileSizeLimit) {
        // Display a snack to inform the user
        openSnack("Image should be less than 5MB or compressed", false);
        return;
      }

      setCurrImage(URL.createObjectURL(newFile));
      setFormData((prev) => ({ ...prev, image: newFile }));
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "17vw",
        height: "17vw",
        mx: "auto",
        borderRadius: "50%",
        overflow: "hidden",
      }}
    >
      <Avatar
        src={currImage}
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "10vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {imageFallbackChar}
      </Avatar>

      <input
        accept="image/*"
        type="file"
        id="profile-image-button"
        hidden
        onChange={handleFileChange}
      />

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          opacity: 0,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            mt: 0.25,
          }}
          component="label"
          htmlFor="profile-image-button"
        >
          <PhotoCameraIcon sx={iconStyles} />
        </Box>

        {currImage !== "" && currImage !== null && (
          <DeleteIcon
            sx={iconStyles}
            onClick={() => {
              setCurrImage("");
              setFormData((prev) => ({
                ...prev,
                image: "" as unknown as undefined,
              }));
            }}
          />
        )}
      </Box>
    </Box>
  );
};

const NameForm = ({
  formData,
  setFormData,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "10px" }}>
      <TextField
        label="Preferred Name"
        value={formData.preferred_name}
        onChange={(event) => {
          setFormData((prev) => ({
            ...prev,
            preferred_name: event.target.value,
          }));
        }}
        sx={{ width: { xs: "100%", sm: 300 } }}
      />
      <TextField
        label="Full Name"
        value={formData.full_name}
        onChange={(event) => {
          setFormData((prev) => ({ ...prev, full_name: event.target.value }));
        }}
        sx={{ width: { xs: "100%", sm: 300 } }}
      />
    </Box>
  );
};

export const GenderForm = ({
  formData,
  setFormData,
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  return (
    <FormControl>
      <InputLabel id="gender-select-label">{translation.gender}</InputLabel>
      <Select
        labelId="gender-simple-select-label"
        id="gender-simple-select"
        sx={TEXT_FIELD_STYLE}
        value={formData.gender}
        label={translation.gender}
        onChange={(event) => {
          setFormData((prev: any) => ({
            ...prev,
            gender: event.target.value,
          }));
        }}
      >
        {Object.keys(GENDER_LIST).map((genderKey) => {
          return (
            <MenuItem key={GENDER_LIST[genderKey]} value={genderKey}>
              {GENDER_LIST[genderKey]}
            </MenuItem>
          );
        })}
        {/* <MenuItem value={SPROUTS}>{translation.sprouts_with_age}</MenuItem>
        <MenuItem value={SPROUTLINGS}>
          {translation.sproutlings_with_age}
        </MenuItem>

        <MenuItem value={BUDS}>{translation.buds_with_age}</MenuItem>
        <MenuItem value={BLOSSOMS}>{translation.blossoms_with_age}</MenuItem>
        <MenuItem value={BLOOMS}>{translation.blooms_with_age}</MenuItem> */}
      </Select>
    </FormControl>
  );
};

export const HighestEducationQualificationForm = ({
  formData,
  setFormData,
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  return (
    <FormControl>
      <InputLabel id="highest-education-select-label">
        {translation.highestEducationQualification}
      </InputLabel>
      <Select
        labelId="highest-education-simple-select-label"
        id="highest-education-simple-select"
        sx={TEXT_FIELD_STYLE}
        value={formData.highest_education_qualification}
        label={translation.highestEducationQualification}
        onChange={(event) => {
          setFormData((prev: any) => ({
            ...prev,
            highest_education_qualification: event.target.value,
          }));
        }}
      >
        {Object.keys(EDUCATION_LIST).map((educationKey) => {
          return (
            <MenuItem key={EDUCATION_LIST[educationKey]} value={educationKey}>
              {EDUCATION_LIST[educationKey]}
            </MenuItem>
          );
        })}
        {/* <MenuItem value={SPROUTS}>{translation.sprouts_with_age}</MenuItem>
        <MenuItem value={SPROUTLINGS}>
          {translation.sproutlings_with_age}
        </MenuItem>

        <MenuItem value={BUDS}>{translation.buds_with_age}</MenuItem>
        <MenuItem value={BLOSSOMS}>{translation.blossoms_with_age}</MenuItem>
        <MenuItem value={BLOOMS}>{translation.blooms_with_age}</MenuItem> */}
      </Select>
    </FormControl>
  );
};

export const MobileNumberForm = ({
  formData,
  setFormData,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
      <PhoneInput
        country={"sg"}
        value={formData.mobile_number}
        onChange={(value) => {
          setFormData((prev) => ({
            ...prev,
            mobile_number: value,
          }));
        }}
        placeholder="Mobile Number"
      />
    </Box>
  );
};

export const EducationStartDateField = ({
  formData,
  setFormData,
}: {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        maxDate={moment(today)}
        views={["year", "month"]}
        label="Education Start Date"
        inputFormat={MONTH_FORMAT}
        value={formData.education_start_date || null}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            setFormData((prev: any) => ({
              ...prev,
              education_start_date: newValue
                .clone()
                .startOf("month")
                .format("YYYY-MM-DD")
                .toString(),
            }));
          }
        }}
        renderInput={(params) => <TextField {...params} sx={{ flex: 1 }} />}
      />
      {/* 
      <DatePicker
              minDate={moment(childDetails.create_at!)}
              maxDate={moment(endAt!)}
              views={["year", "month"]}
              inputFormat={MONTH_FORMAT}
              label="Start Date"
              value={startAt}
              onChange={(newValue: moment.Moment | null) => {
                if (newValue !== null) {
                  setStartAt(newValue.toDate());
                }
              }}
              openTo="year"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"

                  // inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              disabled
            />
      */}
    </LocalizationProvider>
  );
};

const DobField = ({
  formData,
  setFormData,
}: {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        maxDate={moment(today)}
        label="Date of Birth"
        inputFormat="YYYY-MM-DD"
        value={formData.dob}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            setFormData((prev) => ({
              ...prev,
              dob: newValue.format("YYYY-MM-DD").toString(),
            }));
          }
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

const SubmitButton = ({
  formData,
  profileID,
}: {
  formData: FormData;
  profileID: string;
}) => {
  const { setProfileDetails } = useStore((state) => ({
    setProfileDetails: state.setProfileDetails,
  }));

  const openSnack = useSnack();

  const handleClick = async () => {
    // console.log("formData: ", formData);
    const response: UpdateProfileResponse | ErrorMessage = await updateProfile(
      profileID,
      formData as UpdateProfile,
      localStorage.getItem("access")
    );

    if ("error" in response) {
      openSnack(response.error.join(" "), false);
    } else {
      const data: API_Profile = await getAccountProfile(
        profileID,
        localStorage.getItem("access")
      );
      setProfileDetails(data);
      openSnack(translation.updateSuccess, true);
    }
  };

  return (
    <Button buttonText="Update Details" arrow={false} onClick={handleClick} />
  );
};

const ChangePasswordButton = () => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  return (
    <Button
      buttonText="Change Password"
      arrow={false}
      onClick={() => {
        navigate(`${CURLANG(currLanguage)}/${CHANGE_PASSWORD}`);
      }}
    />
  );
};

export default AccountPage;

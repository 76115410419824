import axios from 'api/axiosConfig';

import { AxiosError } from 'axios';

import { API_Element, Element } from 'types/drawing';

export const createAndUpdateDrawing = async (
  drawing: Element[],
  profileID: number,
  activityID: number
) => {
  try {
    await axios.post(`/en/drawing/create`, {
      drawing: drawing,
      profile: profileID,
      activity: activityID,
    });
    return { profileID, activityID };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return 'Error!';
  }
};

export const getDrawingsByID = async (
  profileID: number,
  activityID: number
) => {
  try {
    const res = await axios.get(`en/drawing/list`);
    const data: API_Element[] = await res.data;
    return data.filter(
      (d) => d.profile === profileID && d.activity === activityID
    );
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return 'Error!';
  }
};

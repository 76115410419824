import React from "react";
import { Box, Typography, IconButton, Checkbox } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";
import {
  API_Announcement,
  API_Announcement_File_Update,
} from "types/announcement";

import { translation } from "constants/translation";

const AnnouncementComponentFileField = ({
  files,
  setFiles,
  fileNameToCopyList,
  setFileNameToCopyList,
  existingAnnouncementFileUpdateList,
  setExistingAnnouncementFileUpdateList,
  multiple = false,
  oldFiles,
  deleteFileIds,
  setDeleteFileIds,
  language,
}: {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  fileNameToCopyList: string[];
  setFileNameToCopyList: React.Dispatch<React.SetStateAction<string[]>>;
  existingAnnouncementFileUpdateList: API_Announcement_File_Update[];
  setExistingAnnouncementFileUpdateList: React.Dispatch<
    React.SetStateAction<API_Announcement_File_Update[]>
  >;
  multiple?: boolean;
  oldFiles?: API_Announcement;
  deleteFileIds?: number[];
  setDeleteFileIds?: React.Dispatch<React.SetStateAction<number[]>>;
  language?: string;
}) => {
  const fileDisplayStyle = {
    display: "flex",
    alignItems: "center",
    gap: 0.5,
    justifyContent: "space-between",
  };
  const clearIconStyle = {
    color: "txt.secondary",
    cursor: "pointer",
    backgroundColor: "primary.light",
    borderRadius: "50%",
    p: "3px",
    transition: "all 100ms linear",
    "&:hover": {
      p: "4px",
      backgroundColor: "primary.main",
      transition: "all 100ms linear",
    },
  };

  const boxGroupStyle = { display: "flex", gap: 1, alignItems: "center" };
  return (
    <Box>
      <Typography sx={{ mb: "8px" }}>{translation.attachments}</Typography>

      <input
        type="file"
        id="files-button"
        multiple={multiple}
        hidden
        onChange={(event) => {
          const files: File[] = Array.prototype.slice.call(event.target.files);

          if (files.length === 0) {
            event.target.value = "";
            return;
          }

          setFiles((prev: File[]) => [...prev, ...files]);
          setFileNameToCopyList((prev: string[]) => {
            return [...prev, ...files.map((file) => file.name)];
          });
          event.target.value = "";
        }}
      />

      <label htmlFor="files-button">
        <IconButton
          component="span"
          sx={{
            borderRadius: "8px",
            backgroundColor: "primary.light",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
        >
          <ImageIcon sx={{ color: "txt.secondary" }} />
        </IconButton>
      </label>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 0.75,
          columnGap: 1.5,
          my: 1,
        }}
      >
        {oldFiles &&
          deleteFileIds &&
          setDeleteFileIds &&
          language &&
          oldFiles.files.map((oldFile, index) => (
            <Box sx={fileDisplayStyle} key={index}>
              <Box sx={boxGroupStyle}>
                <a
                  style={{
                    textDecoration: `underline${
                      deleteFileIds.includes(oldFile.id) ? " line-through" : ""
                    }`,
                  }}
                  href={oldFile.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  {oldFile.name}
                </a>
                <ClearIcon
                  sx={clearIconStyle}
                  onClick={() => {
                    setDeleteFileIds((prev) => {
                      if (prev.includes(oldFile.id)) {
                        return prev.filter((fileId) => fileId !== oldFile.id);
                      } else {
                        return [...prev, oldFile.id];
                      }
                    });
                  }}
                />
              </Box>
              <Box sx={boxGroupStyle}>
                <Typography>{translation.add_attachement_into_doc}</Typography>
                <Checkbox
                  checked={
                    existingAnnouncementFileUpdateList.filter(
                      (file) => file.id === oldFile.id
                    )[0].is_display_in_document_tab
                  }
                  sx={{ cursor: "pointer", color: "txt.secondary" }}
                  onClick={() => {
                    setExistingAnnouncementFileUpdateList((prev) => {
                      const finalList = prev.map((file) => {
                        if (file.id === oldFile.id) {
                          return {
                            ...file,
                            is_display_in_document_tab:
                              !file.is_display_in_document_tab,
                          };
                        }
                        return file;
                      });

                      return finalList;
                    });
                  }}
                />
              </Box>
            </Box>
          ))}

        {files.map((file, index) => (
          <Box key={index} sx={fileDisplayStyle}>
            <Box sx={boxGroupStyle}>
              <a
                href={URL.createObjectURL(file)}
                target="_blank"
                rel="noreferrer"
              >
                {file.name}
              </a>

              <ClearIcon
                sx={clearIconStyle}
                onClick={() => {
                  setFiles((prev) => {
                    const temp = [...prev];
                    temp.splice(index, 1);
                    return temp;
                  });

                  setFileNameToCopyList((prev) => {
                    const updatedFileName = prev.filter(
                      (fileName) => fileName !== file.name
                    );
                    return updatedFileName;
                  });
                }}
              />
            </Box>
            <Box sx={boxGroupStyle}>
              <Typography>{translation.add_attachement_into_doc}</Typography>
              <Checkbox
                checked={fileNameToCopyList.includes(file.name)}
                sx={{ cursor: "pointer", color: "txt.secondary" }}
                onClick={() => {
                  setFileNameToCopyList((prev) => {
                    let updatedFileName = [];
                    if (prev.includes(file.name)) {
                      updatedFileName = prev.filter(
                        (fileName) => fileName !== file.name
                      );
                    } else {
                      updatedFileName = [...prev, file.name];
                    }
                    return updatedFileName;
                  });
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default AnnouncementComponentFileField;

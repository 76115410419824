import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "../Button/Button";

const WarningDialog = ({
  openDialog,
  handleCloseDialog,
  warningTitle,
  warningContext,
  handleDelete,
}: {
  openDialog: boolean;
  handleCloseDialog: (e?: any) => void;
  warningTitle: string;
  warningContext: string;
  handleDelete: (e?: any) => void;
}) => {
  return (
    <Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="warning-dialog-title"
        aria-describedby="warning-dialog-description"
      >
        <DialogTitle id="warning-dialog-title">{warningTitle}</DialogTitle>
        <DialogContent
          sx={{
            minWidth: "320px",
          }}
        >
          <DialogContentText id="warning-dialog-description">
            {warningContext}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "20px" }}>
          <Button
            buttonText="Cancel"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "var(--txt-secondary)",
              padding: "0",
              marginRight: "20px",
            }}
            onClick={handleCloseDialog}
          />

          <Button
            buttonText="Delete"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
            }}
            onClick={(e) => {
              handleDelete();
              handleCloseDialog(e);
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WarningDialog;

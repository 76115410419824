import { useState } from "react";
import { Box } from "@mui/material";

import useStore from "store/store";
import { TITLE } from "constants/title";
import useInitialiseCurrProjAndLess from "hooks/useInitialiseCurrProjAndLess";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import ProjectTreeView from "./ProjectTreeView";
import ResizableDrawer from "./ResizableDrawer";
import "./EditProjectPage.css";
import EditProjectTitle from "./EditProjectTitle";
import { Selected } from "./types";


const EditProjectPage = () => {
  document.title = TITLE;
  useInitialiseCurrProjAndLess();
  const { isCurrentProjectAssessment, isProjectLoading } = useStore((state) => ({
    isProjectLoading: state.isProjectLoading,
    isCurrentProjectAssessment: state.currentProject.assessment,
  }));

  const [selected, setSelected] = useState<Selected | null>(null);
  const [prevSelected, setPrevSelected] = useState<Selected | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleOpen = (selectedOption: Selected) => {
    if (
      openDrawer &&
      selected &&
      JSON.stringify(selectedOption) === JSON.stringify(selected)
    ) {
      handleClose();
      return;
    }

    document.body.classList.add("drawer-open");
    setSelected(selectedOption);
    setPrevSelected(selectedOption);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    if (!openDrawer) return;

    document.body.classList.remove("drawer-open");
    setSelected(null);
    setOpenDrawer(false);
  };

  return (
    <Box
      className="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: "24px",
        my: 3,
        gap: 2,
      }}
    >
      <EditProjectTitle isAssessment={isCurrentProjectAssessment} handleClose={handleClose} />
        {isProjectLoading ? (
          <LoadingIndicator />
        ) : (
          <ProjectTreeView
            selected={prevSelected}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        )}
      <ResizableDrawer
        selected={selected}
        openDrawer={openDrawer}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default EditProjectPage;

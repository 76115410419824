import React, { useEffect, useState } from "react";

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { CURLANG, LOGIN } from "constants/url";

const SiteAdminOnlyRoute = ({
  isAuth,
  children,
}: {
  isAuth?: boolean;
  children?: React.ReactElement;
}) => {
  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));
  const [canRender, setCanRender] = useState<boolean>(false);

  useEffect(() => {
    if (profileDetails.id !== -1) {
      setCanRender(true);
    }
  }, [profileDetails]);

  if (canRender && profileDetails.role === "WAD") {
    return children ? children : <Outlet />;
  } else {
    if (canRender) {
      return <Navigate to={`${CURLANG(currLanguage)}/cannot-access`} replace />;
    } else {
    }
    return <LoadingIndicator />;
  }
};

export default SiteAdminOnlyRoute;

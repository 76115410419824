
import { Box, Container, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button, {
  EditButton,
  DeleteButton,
} from "components/Button/Button";
import useStore from "store/store";
import {
  CURLANG,
  PRE_DISCOVERY_QUESTIONNAIRE,
} from "constants/url";
import useInitialiseEvaluationQuestionnaires from "hooks/useInitialiseEvaluationQuestionnaires";

const EvaluationPage = () => {
  const navigate = useNavigate();
  //RENAME QUESTIONNAIRE TO EVALUATION
  const {
    currLanguage,
    evaluationQuestionnaires,
    currentEvaluationQuestionnaire,
    setCurrentEvaluationQuestionnaire,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    evaluationQuestionnaires: state.evaluationQuestionnaires,
    currentEvaluationQuestionnaire: state.currentEvaluationQuestionnaire,
    setCurrentEvaluationQuestionnaire: state.setCurrentEvaluationQuestionnaire,
  }));

  useInitialiseEvaluationQuestionnaires();

  // console.log("evaluationQuestionnaires: ", evaluationQuestionnaires);

  return (
    <>
      <Box sx={{ display: "flex", mx: "60px", gap: "32px" }}>
        <Container
          sx={{
            backgroundColor: "#FBFBFB",
            borderRadius: "16px",
            flex: 1,
            alignSelf: "start",
            my: 3,
          }}
          maxWidth={false}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "30px",
              pb: "30px",
            }}
          >
            <Button
              buttonText={``}
              arrow={true}
              backward={true}
              onClick={() => {
                // navigate(TEACHER);
              }}
            />
            <Typography
              sx={{
                display: "flex",
                fontWeight: "bold",
                fontSize: "2.5rem",
                textAlign: "center",
                justifyContent: "center",
                width: "50vw",
              }}
            >
              <Box
                sx={{
                  marginLeft: 5,
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {/* <EditButton
                  style={{ marginLeft: 5, marginRight: 5 }}
                  btnText=""
                  onClick={() => {
                    // navigate();
                    //   EDIT_TEACHER_MODULE_PAGE(teacherCurrentModule.slug)
                  }}
                /> */}
                <DeleteButton
                  style={{ backgroundColor: "red" }}
                  btnText=""
                  onClick={() => {
                    // handleOpenDialog();
                  }}
                />
              </Box>
            </Typography>
            <Box></Box>
          </Box>
          <Box>
            {evaluationQuestionnaires.map((evaluationQuestionnaire) => {
              return (
                <>
                  <Link
                    component="button"
                    onClick={() => {
                      navigate(
                        `${CURLANG(currLanguage)}/${PRE_DISCOVERY_QUESTIONNAIRE(
                          evaluationQuestionnaire.slug
                        )}`
                      );
                    }}
                  >
                    {evaluationQuestionnaire.name}
                  </Link>

                  <EditButton
                    style={{ marginLeft: 5, marginRight: 5 }}
                    btnText=""
                    onClick={() => {
                      navigate(
                        `${CURLANG(currLanguage)}/${PRE_DISCOVERY_QUESTIONNAIRE(
                          evaluationQuestionnaire.slug
                        )}`
                      );
                    }}
                  />
                </>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EvaluationPage;

import axios from "api/axiosConfig";
import { AxiosError } from "axios";

import {
  API_Evaluation_Questionnaire,
  API_Evaluation_Questionnaire_Detail,
  API_Question,
  API_Answer,
} from "types/evaluationquestionnaire";

export const getEvaluationQuestionnaires = async () => {
  try {
    const res = await axios.get(
      `en/evaluation-questionnaire/questionnaire/list`
    );
    const data: API_Evaluation_Questionnaire[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getEvaluationQuestionnaire = async (
  evaluationQuestionnaireSlug: string
) => {
  try {
    const res = await axios.get(
      `en/evaluation-questionnaire/questionnaire/slug/${evaluationQuestionnaireSlug}`
    );
    const data: API_Evaluation_Questionnaire_Detail = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

//Update
export const updateEvaluationQuestionnaire = async ({
  id,
  name,
  description,
  is_published,
  weight,
}: {
  id: number;
  name: string;
  description: string;
  is_published: boolean;
  weight: number;
}) => {
  const body = {
    name: name,
    description: description,
    is_published: is_published,
    weight: weight,
  };

  try {
    const res = await axios.patch(
      `/en/evaluation-questionnaire/questionnaire/update/${id}`,
      body
    );
    const data: API_Evaluation_Questionnaire = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateQuestion = async ({
  id,
  name,
  questionnaireId,
  fundamentalIdList,
  weight,
}: {
  id: number;
  name: string;
  questionnaireId: number;
  fundamentalIdList: number[];
  weight: number;
}) => {
  const body = {
    name: name,
    questionnaire: questionnaireId,
    fundamentalIdList: fundamentalIdList,
    weight: weight,
  };
  try {
    const res = await axios.patch(
      `/en/evaluation-questionnaire/question/update/${id}`,
      body
    );
    const data: API_Question = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateAnswer = async ({
  id,
  name,
  milestoneId,
  weight,
}: {
  id: number;
  name: string;
  milestoneId: number;
  weight: number;
}) => {
  const body = {
    name: name,
    milestone: milestoneId,
    weight: weight,
  };
  try {
    const res = await axios.patch(
      `/en/evaluation-questionnaire/answer/update/${id}`,
      body
    );
    const data: API_Answer = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Create
export const createEvaluationQuestionnaire = async ({
  name,
  description,
  is_published,
  weight,
}: {
  name: string;
  description: string;
  is_published: boolean;
  weight: number;
}) => {
  const body = {
    name: name,
    description: description,
    is_publish: is_published,
    weight: weight,
  };

  try {
    const res = await axios.post(
      `/en/evaluation-questionnaire/questionnaire/create`,
      body
    );
    const data: API_Evaluation_Questionnaire = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createQuestion = async ({
  name,
  questionnaireId,
  fundamentalIdList,
  weight,
}: {
  name: string;
  questionnaireId: number;
  fundamentalIdList: number[];
  weight: number;
}) => {
  const body = {
    name: name,
    questionnaire: questionnaireId,
    fundamental_id_list: fundamentalIdList, //handle in backend
    weight: weight,
  };

  try {
    const res = await axios.post(
      `/en/evaluation-questionnaire/question/create`,
      body
    );
    const data: API_Question = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createAnswer = async ({
  name,
  milestoneId,
  weight,
}: {
  name: string;
  milestoneId: number;
  weight: number;
}) => {
  const body = {
    name: name,
    milestone: milestoneId,
    weight: weight,
  };

  try {
    const res = await axios.post(
      `/en/evaluation-questionnaire/answer/create`,
      body
    );
    const data: API_Question = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//Delete
export const deleteQuestionnaire = async ({ id }: { id: number }) => {
  try {
    const res = await axios.delete(`/en/teacher/lesson/delquestionnaire/${id}`);
    const data: API_Evaluation_Questionnaire = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteQuestion = async ({ id }: { id: number }) => {
  try {
    const res = await axios.delete(
      `/en/evaluation-questionnaire/question/delquestion/${id}`
    );
    const data: API_Question = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteAnswer = async ({ id }: { id: number }) => {
  try {
    const res = await axios.delete(
      `/en/evaluation-questionnaire/answer/delanswer/${id}`
    );
    const data: API_Answer = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

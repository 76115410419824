import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAlbum } from "api/gallery-api";
import { NoPhotoYet, Album } from "./PhotoAlbumComponents"
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { Box } from "@mui/material";
import { containerStyles, ImageCard, AddAlbum } from "./PhotoAlbumComponents";

const Albums = ({
  handleClick,
}: {
  handleClick: (moduleId: string, moduleName: string) => void;
}) => {
  const { childID } = useParams();

  const [album, setAlbum] = useState<Album[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!childID) return;

    const initializeChildImages = async () => {
      setIsLoading(true);

      const album = await getAlbum(
        Number(childID),
        localStorage.getItem("access")
      );

      if (typeof album !== "string") {
        setAlbum(album);
      }

      setIsLoading(false);
    };

    initializeChildImages();
  }, [childID]);

  return isLoading ? (
    <LoadingIndicator />
  ) : album.length === 0 ? (
    <NoPhotoYet />
  ) : (
    <Box sx={containerStyles}>
      {/* <AddAlbum onClick={() => {}} /> */}

      {album.map((image, index) => (
        <ImageCard
          key={index}
          src={image.link}
          module={image.project.module}
          onClick={() =>
            handleClick(
              image.project.module.id.toString(),
              image.project.module.name
            )
          }
        />
      ))}
    </Box>
  );
};

export default Albums;

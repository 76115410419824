import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { slugify } from "helper/slugify";
import { Menu, Typography } from "@mui/material";
import useStore from "store/store";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import {
  LESSON_PLANS,
  LEARNING_MOMENTS,
  translatedProjectLessonActivityTabs,
  UNIT_GUIDE,
  MILESTONE_CHECK,
  translatedProjectLessonActivityTabIcons,
} from "../constant";
import FullLessonActivityList from "containers/ProjectPage/LessonActivityPageComponents/FullLessonActivityList";
import Image from "components/Image/Image";

const TabsContainerMobile = ({
  projectLessonActivityTabs,
  isInBag,
  isInSchedule,
}: {
  projectLessonActivityTabs: string[];
  isInBag: boolean;
  isInSchedule: boolean;
}) => {
  const { currentProject } = useStore((state) => ({
    currentProject: state.currentProject,
  }));
  return (
    <>
      {projectLessonActivityTabs.map((text) => {
        if (text === UNIT_GUIDE && currentProject.unit_guides.length <= 0) {
          return null;
        }
        if (
          text === MILESTONE_CHECK &&
          currentProject.checkpoint_fundamentals.length <= 0
        ) {
          return null;
        }

        return <Tabs text={text} key={`${text}-tab`} />;
      })}
    </>
  );
};

const Tabs = ({ text }: { text: string }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lessonID } = useParams();

  const {
    currentProject,
    currentLessons,
    currLanguage,
    lessonLearningMomentCheckpointCount,
    currentProjectLessonActivityTab,
    setCurrentProjectLessonActivityTab,
  } = useStore((state) => ({
    currentProject: state.currentProject,
    currentLessons: state.currentLessons,
    currLanguage: state.currLanguage,
    lessonLearningMomentCheckpointCount:
      state.lessonLearningMomentCheckpointCount,
    currentProjectLessonActivityTab: state.currentProjectLessonActivityTab,
    setCurrentProjectLessonActivityTab:
      state.setCurrentProjectLessonActivityTab,
  }));

  const currLessonIndex = currentLessons.lessons.findIndex(
    (currentLesson) => currentLesson.id.toString() === lessonID
  );

  // const [tracker, setTracker] = useState<number>(0);
  // const [openLessonPlans, setOpenLessonPlans] = useState<boolean>(
  //   !pathname.includes("/summary")
  // );

  // const handleExpandLessonPlanClick = (
  //   e: React.MouseEvent<SVGSVGElement, MouseEvent>
  // ) => {
  //   e.stopPropagation();
  //   // setTracker((prev) => prev + 1);
  //   setOpenLessonPlans((prev) => !prev);
  // };

  // console.log("currentTab: ", currentTab);
  // console.log("text: ", text);
  // console.log("currentLessons.lessons: ", currentLessons.lessons);
  // console.log("lessonLearningMomentCheckpointCount: ", lessonLearningMomentCheckpointCount);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openLessonPlans = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        id={`lesson-plan-tab-${text.toLowerCase().split(" ").join("-")}`}
        sx={{
          backgroundColor: `${
            currentProjectLessonActivityTab === text
              ? "rgba(209, 228, 227, 0.37)"
              : "#fff"
          }`,
          border: "1px solid #98C4C5",
          borderRadius: "10px",
          p: "4px 8px",
          paddingRight: 1,
          // minWidth: 100,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 100ms linear",
          "&:hover": {
            backgroundColor: "rgba(209, 228, 227, 0.37)",
            transition: "all 100ms linear",
          },
          whiteSpace: "nowrap",
          position: "relative",
        }}
        onClick={() => {
          navigate(
            `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
              currentProject.slug
            )}/${slugify(text)}`
          );
        }}
      >
        {text === LESSON_PLANS && openLessonPlans && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openLessonPlans}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <FullLessonActivityList
              currLessonIndex={currLessonIndex}
              handleClose={handleClose}
            />
          </Menu>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <PlaylistAddCheckIcon
            sx={{
              background: "white",
              borderRadius: "999px",
              p: "4px",
            }}
          /> */}
          <Image
            src={translatedProjectLessonActivityTabIcons[text]}
            alt={text}
            style={{ width: 24, height: 24 }}
          />
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography sx={{ fontSize: "13px", color: "txt.dark2" }}>
              {translatedProjectLessonActivityTabs[text]}
            </Typography>
            {/* Learning Moment */}
            {text === LEARNING_MOMENTS && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "primary.main",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 12 }}
                  >{`${lessonLearningMomentCheckpointCount.learning_moment_done}/${lessonLearningMomentCheckpointCount.learning_moment_total}`}</Typography>
                </Box>
              </>
            )}

            {/* Lesson plans */}
            {text === LESSON_PLANS && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "primary.main",
                  }}
                  onClick={handleClick}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 12 }}
                  >{`${lessonLearningMomentCheckpointCount.lesson_done}/${lessonLearningMomentCheckpointCount.lesson_total}`}</Typography>
                  <>
                    {openLessonPlans ? (
                      <>
                        <ExpandLess color="primary" />
                      </>
                    ) : (
                      <ExpandMore color="primary" />
                    )}
                  </>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default TabsContainerMobile;

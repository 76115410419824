import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  createCustomerPortal,
  getStripePaymentProductList,
} from "api/payment-api";
import useSnack from "hooks/useSnack";
import { PaymentProduct } from "types/payment";
import useStore from "store/store";
import { profile } from "console";
import { translation } from "constants/translation";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import SubscribedDisplay from "./SubscribedDisplay";
import PaymentProductDisplay from "./PaymentProductDisplay";

const CheckoutSession = () => {
  const openSnack = useSnack();

  const { currLanguage, profileDetails, customerInfo } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    customerInfo: state.customerInfo,
  }));

  // const noProductState = {
  //   id: "-1",
  //   name: "",
  //   images: [],
  //   active: false,
  //   description: "",
  //   extra_description: "",
  //   product_id: "",
  //   metadata: {},
  //   default_price: {
  //     id: "",
  //     active: false,
  //     unit_amount: -1,
  //     currency: "",
  //     recurring: {
  //       interval: "",
  //     },
  //   },
  // };

  const [productList, setProductList] = useState<PaymentProduct[]>([]);
  const [subscribedProductList, setSubscribedProductList] =
    useState<PaymentProduct[]>();
  // const [isOfferedPlan, setIsOfferedPlan] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleCustomerPortal = async () => {
    const res = await createCustomerPortal({
      customer: customerInfo.customer.id,
      returnUrl: `${window.location.href.split("?")[0]}`,
    });
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      window.location.href = res.url;
    }
  };

  useEffect(() => {
    const populateData = async () => {
      const res = await getStripePaymentProductList();
      if (typeof res === "string") {
        openSnack(res, false);
        setProductList([]);
      } else {
        if (profileDetails.role === "AD" || profileDetails.role === "WAD") {
          setProductList(
            res.filter((product) => product.metadata.is_school === "true")
          );
        } else {
          setProductList(
            res.filter((product) => product.metadata.is_school === "false")
          );
        }
        // console.log("res: ", res);
        // res = res.sort(
        //   (a, b) => a.default_price.unit_amount - b.default_price.unit_amount
        // );
        // console.log("res: af ", res);
      }

      return res;
    };
    if (profileDetails.id !== -1) {
      populateData();
    }
  }, [profileDetails]);

  useEffect(() => {
    if (productList !== undefined && customerInfo.product.id !== "-1") {
      const filteredProduct = productList.filter(
        (product) => product.id === customerInfo.product.id
      );

      if (filteredProduct) {
        setSubscribedProductList(filteredProduct);
      } else {
        setSubscribedProductList([]);
      }
    }
  }, [customerInfo, productList]);

  useEffect(() => {
    const loadTimeout = setTimeout(async () => {
      setIsLoading(
        profileDetails.id === -1 || subscribedProductList === undefined
      );
    }, 500);
    return () => clearTimeout(loadTimeout);
  }, [profileDetails, subscribedProductList]);

  // console.log("customerInfo: ", customerInfo);
  // console.log("profileDetails: ", profileDetails);
  // console.log("customerInfo.product.id: ", customerInfo.product.id);
  // console.log("productList:", productList);
  // console.log("subscribedProductList: ", subscribedProductList);
  // console.log("isLoading: ", isLoading);
  return (
    <>
      {isLoading ? (
        <>
          <LoadingIndicator />
        </>
      ) : (
        <>
          {profileDetails.role !== "AD" && profileDetails.role !== "WAD" ? (
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                marginTop: 4,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                {translation.onlySchoolAdmin}
              </Typography>
            </Box>
          ) : (
            <>
              {/* ---- */}
              {/* <Container
                sx={{
                  backgroundColor: "#FBFBFB",
                  borderRadius: "10px",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
                maxWidth={false}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    overflowX: "auto",
                    mx: "auto",
                    pb: "8px",
                  }}
                >
                  <Box
                    className="selectionBox"
                    sx={selectionBoxStyle(isOfferedPlan)}
                    onClick={() => {
                      setIsOfferedPlan(true);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {`Offered Plans`}
                    </Typography>
                  </Box>
                  <Box
                    className="selectionBox"
                    sx={selectionBoxStyle(!isOfferedPlan)}
                    onClick={() => {
                      setIsOfferedPlan(false);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {`Subscribed Plans`}
                    </Typography>
                  </Box>
                </Box>
              </Container> */}

              {/* ---- */}

              <Box
                sx={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: 3,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    fontSize: "26px",
                  }}
                >
                  {productList.length > 0
                    ? translation.paymentTitleSchool
                    : translation.noProduct}
                  {/* {profileDetails.role === "AD"
                    ? translation.paymentTitleSchool
                    : profileDetails.role === "PR"
                    ? translation.paymentTitleParent
                    : translation.paymentTitle} */}
                  {/* {isOfferedPlan
                    ? profileDetails.role === "AD"
                      ? translation.paymentTitleSchool
                      : profileDetails.role === "PR"
                      ? translation.paymentTitleParent
                      : translation.paymentTitle
                    : translation.subscribedPlan
                    ? translation.subscribedPlan
                    : "Subscribed Plan"} */}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                  marginY: 3,
                  paddingX: 10,
                  alignItems: "center",
                }}
              >
                <PaymentProductDisplay
                  productList={productList}
                  handleCustomerPortal={handleCustomerPortal}
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutSession;

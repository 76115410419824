import * as createTeacherAccountImages from "containers/HelpPage/AnswerPictures/teachertoolbox/createTeacherAccount";
import * as createChildAccountImages from "containers/HelpPage/AnswerPictures/teachertoolbox/createChildAccount";
import * as createParentAccountImages from "containers/HelpPage/AnswerPictures/teachertoolbox/createParentAccount";
import * as createClassAndAddChildToClassImages from "containers/HelpPage/AnswerPictures/teachertoolbox/createClassAndAddChildToClass";
import * as addChildToClassImages from "containers/HelpPage/AnswerPictures/teachertoolbox/addChildToClass";
import * as schedulerImages from "containers/HelpPage/AnswerPictures/teachertoolbox/scheduler";
import * as assessmentToolsOrFeaturesImages from "containers/HelpPage/AnswerPictures/teachertoolbox/assessmentToolsOrFeatures";
import * as reviewAndUpdateStudentProgressImages from "containers/HelpPage/AnswerPictures/teachertoolbox/reviewAndUpdateStudentProgress";
import * as downloadChildrenProgressReportImages from "containers/HelpPage/AnswerPictures/teachertoolbox/downloadChildrenProgressReport";
import * as platformSuggestLessonPlansImages from "containers/HelpPage/AnswerPictures/teachertoolbox/platformSuggestLessonPlans";
import * as SaveProjectsSuitableForClassImages from "containers/HelpPage/AnswerPictures/teachertoolbox/SaveProjectsSuitableForClass";
import * as viewLessonDetailsImages from "containers/HelpPage/AnswerPictures/teachertoolbox/viewLessonDetails";
import * as teachersArchiveImages from "containers/HelpPage/AnswerPictures/teachertoolbox/teachersArchive";
import * as viewAndAccessAnnouncementPageImages from "containers/HelpPage/AnswerPictures/teachertoolbox/viewAndAccessAnnouncementPage";
import * as createAnnouncementImages from "containers/HelpPage/AnswerPictures/teachertoolbox/createAnnouncement";
import * as editPublishedAnnouncementImages from "containers/HelpPage/AnswerPictures/teachertoolbox/editPublishedAnnouncement";
import * as recallPublishedAnnouncementImages from "containers/HelpPage/AnswerPictures/teachertoolbox/recallPublishedAnnouncement";
import * as accessLittleEvaluatorAssessmentsImages from "containers/HelpPage/AnswerPictures/teachertoolbox/accessLittleEvaluatorAssessments";
import * as accessContentAssessmentUnitImages from "containers/HelpPage/AnswerPictures/teachertoolbox/accessContentAssessmentUnit";
import * as fillOutEvaluationMomentsImages from "containers/HelpPage/AnswerPictures/teachertoolbox/fillOutEvaluationMoments";

import * as viewAnnouncementsAndImportantDocumentsImages from "containers/HelpPage/AnswerPictures/parentPortal/viewAnnouncementsAndImportantDocuments";
import * as accessImportantDocumentsInAnnouncementImages from "containers/HelpPage/AnswerPictures/parentPortal/accessImportantDocumentsInAnnouncement";
import * as downloadPhotosChildImages from "containers/HelpPage/AnswerPictures/parentPortal/downloadPhotosChild";
import * as viewChildCompletedProjectsImages from "containers/HelpPage/AnswerPictures/parentPortal/viewChildCompletedProjects";
import * as viewChildsProgressImages from "containers/HelpPage/AnswerPictures/parentPortal/viewChildsProgress";
import * as leaveReviewFeedbackImages from "containers/HelpPage/AnswerPictures/parentPortal/leaveReviewFeedback";

export const teachertoolbox = [
  {
    "Create Accounts (Teacher, Child, Parent)": [
      {
        "Q: How do I create a teacher's account?": [
          "1. Click on your profile avatar > click on ‘Admin Page’",
          "2. Click on ‘Teacher’ tab > click on ‘Add Teacher’",

          createTeacherAccountImages.img1,
          "3. Fill in teacher’s information and create a password for the teacher",
          "4. Click ‘Submit",
          createTeacherAccountImages.img2,
          "5. The new teacher is now added to the list",
          createTeacherAccountImages.img3,
        ],
      },
      {
        "Q: How do I create a child's account?": [
          "1. Click on your profile avatar > click on ‘Add New Child’",
          createChildAccountImages.img1,
          "2. Click on ‘Child’ tab > click on ‘Add Child’",
          createChildAccountImages.img2,
          "3. Fill in child’s information",
          "4. Click ‘Submit’",
          createChildAccountImages.img3,
          "5. The registered child will be added to the list ",
          createChildAccountImages.img4,
          "Alternatively, you can also add a new child while editing class details.",
          "1.  ‘Edit Class’page >  click on the ‘+' button in the 'Children To Be Assigned’ section",
          createChildAccountImages.img5,
          "2. Fill in child’s information and click ‘Submit’ ",
          createChildAccountImages.img6,
          "3. The registered child will be added to the list of ‘Children To Be Assigned,’ and you can assign the children to a class ",
        ],
      },
      {
        "Q: How do I create a parent's account?": [
          "1. Click on your profile avatar > click on ‘Admin Page’",
          "2. Click on ‘Parent’ tab > click on ‘Add Parent’",
          createParentAccountImages.img1,
          "3. Fill in parent’s information, link the parent with the child, and create a password for the parent",
          "4. Click ‘Submit’",
          createParentAccountImages.img2,
          "5. The new parent is now added to the list",
          createParentAccountImages.img3,
          "Alternatively, you can also create a parent’s account while adding a child.",
          "1. When adding child’s information, click on the ‘+’ button beside ‘Parent’ ",
          createParentAccountImages.img4,
          "2. Fill in parent’s information and create a password for the parent",
          "3. Click ‘Submit’",
          createParentAccountImages.img5,
          "4. A parent account is now created and the parent is added to the appropriate child ",
          createParentAccountImages.img6,
          "5. Click ‘Submit’ after filling out the child’s information",
          "6. A parent account is now created and the parent is added to the appropriate child ",
        ],
      },
      // { "Q: How do I create a child's account?": "answer2" },
      // { "Q: How do I create a parent's account?": "answer2" },
      // { "QUESTIONPLACEMENT": "ANSWERPALCEMENT" },
    ],
  },
  {
    "Create Class & Add Child to Class": [
      {
        "Q: How do I create a class?": [
          "Option 1: Via school admin dashboard",
          "1. Log into ‘school admin account’ and find the ‘+’ button next to ‘My Classes’  ",
          createClassAndAddChildToClassImages.img1,
          "2. Click on the ‘+’ button and you will be redirected to the ‘Create Class’ page ",
          createClassAndAddChildToClassImages.img2,
          "3. Fill out the class details",
          "4. Click ‘Submit’ and now the class is created",
          createClassAndAddChildToClassImages.img3,
          "Option 2: Via profile > Create Class tab",
          "1. Click on the profile avatar > click on ‘Create New Class’ tab",
          createClassAndAddChildToClassImages.img4,
          "2. You will be redirected to the ‘Create Class’ page ",
          createClassAndAddChildToClassImages.img5,
          "3. Fill out the class details",
          "4. Click ‘Submit’ and now the class is created",
          createClassAndAddChildToClassImages.img6,
        ],
      },
      {
        "Q: How do I add children into a class?": [
          "1. In the class editor, select the child(ren) you would like to assign to the class",
          "2. Move the selected child(ren) into the class by clicking the button pointing towards ‘Children in the class’",
          "3. Click ‘Update’ and the child(ren) will be assigned to that class",
          addChildToClassImages.img1,
        ],
      },
    ],
  },
  {
    "Scheduler & Visual Timetable": [
      {
        "Q: How do I schedule a project for my class?": [
          "1. Go to ‘Calendar’ > ‘Scheduler’",
          schedulerImages.img1,
          "2. Click on the ‘Backpack’ tab found on the bottom of the screen",
          schedulerImages.img2,
          "3. Drag and drop the desired project to the date you’d like to plan the project",
          "4a. You may adjust the start and end date of the project by dragging the project card ",
          schedulerImages.img3,
          "4b. You can also click on the planned project card and type in the start and end dates",
          "5. Your project is now scheduled",
          schedulerImages.img4,
          schedulerImages.img5,
          "Alternatively, you can add a planned project to your backpack via the ‘Lesson Plan Library’ page",
          "1. Find a project you want to schedule ",
          "2. Click on ‘Add to Backpack’",
          "3. Click on ‘Schedule Project’ ",
          "4. Fill in start and end dates",
          "5. The project is now scheduled",
        ],
      },
    ],
  },
  {
    "Learning Moment, Developmental Goals, Progress Report": [
      {
        "Q: Are there assessment tools or features available to track student progress and identify areas of improvement?":
          [
            "1. Yes, there are Learning Moments throughout the lessons, guided by the 21 developmental goals for users to record and track the child’s progress with a few clicks! ",
            "2. Learning moment:  Record children milestone as the lessons progress. Recorded milestones are synced to child’s portfolio.",
            assessmentToolsOrFeaturesImages.img1,
          ],
      },
      {
        "Q: Where can I review and update student progress?": [
          "1. You can review and update student progress via ‘Developmental Goals’ in Children Portfolio",
          "2. To access and update developmental goals, click on ‘Portfolios’ > ‘Children Portfolio’ > Select the child > ‘Developmental goals’",
          "3. In each developmental goal, you will see the latest milestone attained by the student, photos uploaded in learning moment when the milestone was attained, and what is the next milestone",
          reviewAndUpdateStudentProgressImages.img1,
        ],
      },
      {
        "Q: How to download children’s progress report?": [
          "1. After updating the child’s milestone attained in ‘Developmental goals’, you can download the progress report in ‘Portfolios’ > ‘Children Portfolio’> Select the child > “Progress Report”",
          downloadChildrenProgressReportImages.img1,
          "2. To download a report, click on ‘Create New” > select report start and end date > ‘Submit’",
          "3. You will see a report preview and it will be downloaded to your device once you click ‘Download Report’",
        ],
      },
    ],
  },
  {
    "Lesson Plan Library, Backpack & Favorites, Lesson Toolbox, Project Summary":
      [
        {
          "Q: Can the platform suggest lesson plans that are suitable for my class?":
            [
              "1. Yes! Click on ‘Lesson Plan Library’ within the selected class and you’ll see a ‘Quick Questions’ pop-up",
              platformSuggestLessonPlansImages.img1,
              "2. After answering the quick questions, the system will recommend projects according to the preferences and goals of your class",
              platformSuggestLessonPlansImages.img2,

              ,
            ],
        },
        {
          "Q: Can I save projects that might be suitable for my class and decide when to plan them later? ":
            [
              "1. Yes! When browsing for projects, you can click the ‘Like’ button on the project cards or click 'Add to backpack’ to save projects ",
              SaveProjectsSuitableForClassImages.img1,
              "2. To access the projects you ‘Like’ or ‘Added to Backpack,’ go to ‘Curriculum’ > ‘Backpack & Favourites’",
              SaveProjectsSuitableForClassImages.img2,
            ],
        },
        {
          "Q: Where can I view lesson details like lesson intention, lesson’s objectives, materials list, key vocabulary, and attachments? ":
            [
              "1. You can find lesson details like lesson intention, lesson’s objectives, materials list, key vocabulary, and attachments in the ‘Lesson Toolbox’",
              viewLessonDetailsImages.img1,
              "2. The toolbox will open up when you click on it and you can dive into the details you’re looking for",
              viewLessonDetailsImages.img2,
            ],
        },

        {
          "Q: Can I download a project summary after completing a project?": [
            "1. Yes! Users are able to edit and download a project summary for documentation or teacher-parent communication purposes",
            "2. In a Completed Project: \n \t a. Click on the ‘Project Summary’ tab on the left navigation bar \n \t b. Scroll to the bottom of the page and click on ‘Generate Project Summary Report’ button \n \t c. Edit the template or text as needed \n \t d. Click ‘Download’ when it is ready",
          ],
        },
      ],
  },
  {
    "Teacher's Archive": [
      {
        "Q: Where can I access all my student’s previous project reports, little works, progress reports and photos? ":
          [
            "1. You can access this information through ‘Teacher’s Archive.’ It is designed for teachers to search for any previous work and documents via keywords and a few clicks!",
            "2. Click on ‘Portfolios’ > ‘Teacher’s Archive’",
            teachersArchiveImages.img1,
          ],
      },
    ],
  },
  {
    Announcement: [
      {
        "Q: How do I view and access the announcement page?": [
          "1. Click the ‘Bell’ icon on the navigation bar and click on ‘View all announcement’",
          viewAndAccessAnnouncementPageImages.img1,
        ],
      },
      {
        "Q: How do I create an announcement? ": [
          "1. From the announcement page, select 'Add New Announcement’",
          createAnnouncementImages.img1,
          "2. Fill in the appropriate fields",
          createAnnouncementImages.img2,
          "3. Note: If a response from parents is required, check the box for 'Any Response Required' and complete the necessary fields",
          "4. Click 'Publish' when you're ready to share the announcement",
          createAnnouncementImages.img3,
          "5. The announcement is now shared with the selected parents and teachers",
          createAnnouncementImages.img4,
        ],
      },
      {
        "Q: Can I edit an published announcement? ": [
          "1. Yes, you can! Click on the 'Edit' button for the announcement you would like to modify",
          editPublishedAnnouncementImages.img1,
          "2. Make the necessary edits to the content and click 'Update' when you are finished ",
          editPublishedAnnouncementImages.img2,
          "3. Edits are now updated to the announcement ",
        ],
      },
      {
        "Q: How do I recall a published announcement? ": [
          "1. Click on the 'Edit' button for the announcement you would like to recall.",
          recallPublishedAnnouncementImages.img1,
          "2. Scroll to the bottom of the editor and click ‘Recall’ ",
          recallPublishedAnnouncementImages.img2,
          "3. The announcement is now recalled",
          recallPublishedAnnouncementImages.img3,
        ],
      },
    ],
  },
  {
    "Little Evaluator Assessments": [
      {
        "Q: How do I access a Little Evaluator assessment from the Dashboard?":
          [
            "1. Click on the ‘Little Evaluator’ button in the navigation bar ",
            accessLittleEvaluatorAssessmentsImages.img1,
            "2. Browse for a specific unit or filter the units by developmental goals ",
            accessLittleEvaluatorAssessmentsImages.img2,
          ],
      },
      {
        "Q: How do I access the content of an assessment unit? ": [
          "1. View unit by clicking on the unit card",
          "2. Add a unit to your backpack by clicking ‘Add to Backpack’ ",
          accessContentAssessmentUnitImages.img1,
          "3. Schedule an assessment by clicking on ‘Schedule Assessment’",
          accessContentAssessmentUnitImages.img2,
          "4. Fill in the project start date and project end date and click ‘Submit’ ",
          accessContentAssessmentUnitImages.img3,
          "5. View the unit overview, assessment plan, and evaluation moments and click on ‘Let’s Begin!’ to start the assessment process",
          accessContentAssessmentUnitImages.img4,
        ],
      },
      {
        "Q: How do I fill out the Evaluation Moments? ": [
          "1. Once you access the Assessment Plan, scroll through the step-by-step instructions and answer the Evaluation Moment questions when requested",
          fillOutEvaluationMomentsImages.img1,
          "2. If all of the children attained the milestone, click ‘yes, they all did’ OR if not all of the children attained the milestone click ‘no, not all of them.’ if you answer no, enter the names of the children who attained the milestone. ",
          fillOutEvaluationMomentsImages.img2,
          "3. Click ‘Done’",
          "4. Optional: Upload children’s completed works by clicking on ‘Add Little Work’ ",
          fillOutEvaluationMomentsImages.img3,
          "5. Optional: Upload photo by clicking on the camera button, choose date it was completed, and add a caption describing the activity and click ‘Upload ",
          fillOutEvaluationMomentsImages.img4,
          "6. Click ‘Done’ ",
          fillOutEvaluationMomentsImages.img5,
        ],
      },
    ],
  },
];

export const parentportal = [
  {
    "Announcements & Important Documents": [
      {
        "Q: How do I view announcements? ": [
          "1. The announcement previews are displayed on the dashboard ",
          viewAnnouncementsAndImportantDocumentsImages.img1,
          "2. To view the full content, click the ‘Read more’ button in the announcement preview or the ‘Notices’ button on the navigation bar",
          "3. You will be redirected to the announements page",
          viewAnnouncementsAndImportantDocumentsImages.img2,
        ],
      },
      {
        "Q: How do I access all important documents shared in the announcement? ":
          [
            "1. Go to the announcement page by clicking on ‘Notices’ > ‘Documents’",
            accessImportantDocumentsInAnnouncementImages.img1,
          ],
      },
    ],
  },
  {
    "Child's Photo": [
      {
        "Q: Can I download photos of my child? ": [
          "1. Yes, you can download the Little Albums on the dashboard \n \ta. Click on an album on the dashboard ",
          downloadPhotosChildImages.img1,
          "2. Click on the download button to download the album",
          downloadPhotosChildImages.img2,
          "3. The album will download to your device",
          "Alternatively, you can view photos by clicking ‘Photos’ on the navigation bar ",
          downloadPhotosChildImages.img3,
        ],
      },
    ],
  },
  {
    "Child's Progress, Completed Projects & Work": [
      {
        "Q: Can I view my child’s completed projects and work on the platform? ":
          [
            "1. Yes, you can view the published project summaries on the ‘Project Summary’ page, and your child's little works can be found on the Little Works page. \n \t a. ‘Project Summaries’ Page",
            viewChildCompletedProjectsImages.img1,
            "\t b. ‘Little Works’ Page",
            viewChildCompletedProjectsImages.img2,
          ],
      },
      {
        "Q: How do I view my child’s progress? ": [
          "1. You can review your child’s progress by exploring the ‘Developmental Goals’ page",
          viewChildsProgressImages.img1,
          "2. Alternatively, if the teacher has published a progress report, you may also access it on the ‘Progress Report’ page",
          viewChildsProgressImages.img2,
        ],
      },
    ],
  },

  {
    "Other General Questions": [
      {
        "Q: Where can I leave a review/feedback about the platform? ": [
          "1. The Little Lab team would greatly appreciate your feedback on how can we make the experience better for you and your school \n \t a. To leave feedback go to ‘Profile’ > ‘Provide Feedback’ \n \t b. Fill in the details \n \t c. Click ‘Submit’ and feedback will be sent to us",
          leaveReviewFeedbackImages.img1,
        ],
      },
      {
        "Q: Is your platform accessible on different devices and operating systems?":
          [
            "1. Yes! You can access the platform through any browsers on mobile, tablet, or laptop by logging in to app.littlelab.com.",
          ],
      },
    ],
  },
];
export const teachertrainingacademy = [
  {
    topicteachertrainingacademy1: [
      { questionteachertrainingacademy1: "answerteachertrainingacademy1" },
      { question2: "answer2" },
    ],
  },
  {
    topicteachertrainingacademy2: [
      { questionteachertrainingacademy1: "answerteachertrainingacademy1" },
      { question2: "answer2" },
    ],
  },
];

export const EMAIL_WIDTH = 300;
export const MOBILE_NUMBER_WIDTH = 150;
export const IMAGE_WIDTH = 100;
export const TEXT_FIELD_STYLE = {
  width: 300,
};

export const DATA_GRID_STYLE = {
  height: "75vh",
  width: "100%",
};

export const BUTTON_TEXT_STYLE = {};

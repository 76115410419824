import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CURLANG,
  LEARNING_MOMENT_PHOTO,
  LESSON_ACTIVITY_PAGE,
} from "constants/url";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import Image from "components/Image/Image";
import { translation } from "constants/translation";
import CaravanImg from "images/caravan.png";
import CalendarImg from "images/calendar.png";
import BackpackImg from "images/bag.png";
import { createBackpackItem } from "api/backpack-api";
import { API_Project } from "types/project";
import useModal from "hooks/useModal";
import ScheduleGroupPageModal from "containers/CalendarPage/ScheduleGroupPageModal";
import { WHAT_PEOPLE_LIKE } from "../constant";
import { slugify } from "helper/slugify";
import { usePopulateLessonsInfo } from "hooks/lessons";

const NextActionButton = ({
  currentProject,
  isInBag,
  isInSchedule,
  sx = {},
}: {
  isInBag: boolean;
  isInSchedule: boolean;
  currentProject: API_Project;
  sx?: SxProps;
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const { pathname } = useLocation();
  const isEndOfProjectOrSharing =
    pathname.endsWith("/end") ||
    pathname.endsWith("/report") ||
    pathname.endsWith(`/${slugify(WHAT_PEOPLE_LIKE)}`);
  const isLessonActivity =
    pathname.includes("/lesson/") && pathname.includes("/activity/");
  const {
    currGroup,
    currLanguage,
    currentLessons,
    groupLessonInfoList,
    setRefreshGroupBackpack,
  } = useStore((state) => ({
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
    currentLessons: state.currentLessons.lessons,
    groupLessonInfoList: state.groupLessonInfoList,
    setRefreshGroupBackpack: state.setRefreshGroupBackpack,
  }));
  usePopulateLessonsInfo();
  const { open, handleOpen, handleClose } = useModal();
  // const [enrolledChildren, setEnrolledChildren] = useState<number[]>([]);

  const handleAddToBackpack = () => {
    const addProject = async () => {
      const res = await createBackpackItem(currentProject.id, currGroup.id);
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        setRefreshGroupBackpack(true);
        openSnack("Added to backpack!", true);
      }
    };
    addProject();
  };

  //when click let's begin, handle enrollment of chilren in current group

  const getCurrGroupLessonId = () => {
    if (currentLessons.length > 0) {
      const currGroupLessonInfo = groupLessonInfoList.filter(
        (group) => group.id === currGroup.id
      );

      if (currGroupLessonInfo.length > 0) {
        const currGroupLesson = currGroupLessonInfo[0];
        const currentLessonsIds = currentLessons.map(
          (currentLesson) => currentLesson.id
        );
        const matchedLesson = currGroupLesson.current_lessons.filter(
          (currLesson) => {
            return currentLessonsIds.includes(currLesson.id);
          }
        );
        // console.log("matchedLesson: ", matchedLesson);
        if (matchedLesson.length > 0) {
          return [
            matchedLesson[0].id === currentLessons[0].id,
            matchedLesson[0].id,
          ];
        }
      }
      return [true, currentLessons[0].id]; //if no match, return the first lesson
    }
    return [true, -1];
  };

  const handleEnrollment = async () => {
    // const children = currGroup.children.map((child) => child.id);
    // const notEnrolledChildren = children.filter(
    //   (child) => !enrolledChildren.includes(child)
    // );
    // // console.log("notEnrolledChildren: ", notEnrolledChildren);
    // // console.log("Currgroup child: ", currGroup.children);
    // if (notEnrolledChildren.length === 0) {
    // } else {
    //   const childrenSet = new Set([...notEnrolledChildren, ...children]);
    //   if (
    //     childrenSet.size === notEnrolledChildren.length &&
    //     childrenSet.size === children.length
    //   ) {
    //     //if same size means all same
    //     const res = await enrollChildren({
    //       project: currentProject.id,
    //       children: children,
    //       progress: "In Progress",
    //     });

    //     if (typeof res === "string") {
    //       openSnack(res, false);
    //     } else {
    //       openSnack(
    //         `The children in ${currGroup.name} are all enrolled to project "${currentProject.name}"!`,
    //         true
    //       );
    //     }
    //   } else {
    //     const enrolledChildrenName = currGroup.children
    //       .filter((child) => !notEnrolledChildren.includes(child.id))
    //       .map((child) => child.preferred_name)
    //       .join(", ");

    //     const notEnrolledChildrenName = currGroup.children
    //       .filter((child) => notEnrolledChildren.includes(child.id))
    //       .map((child) => child.preferred_name)
    //       .join(", ");

    //     // openSnack(
    //     //   `The children: ${enrolledChildrenName} are already enrolled in this project, while the children: ${notEnrolledChildrenName} are not enrolled yet. Enrolling the remaining children now...`,
    //     //   true
    //     // );
    //     const res = await enrollChildren({
    //       project: currentProject.id,
    //       children: notEnrolledChildren,
    //       progress: "In Progress",
    //     });

    //     if (typeof res === "string") {
    //       openSnack(res, false);
    //     } else {
    //       openSnack(
    //         `Enrolled ${notEnrolledChildrenName} to project "${currentProject.name}"!`,
    //         true
    //       );
    //     }
    //   }
    // }

    const [_, currGroupLessonId] = getCurrGroupLessonId();

    const activitySlug = () => {
      //There's a match between all lessons in that project and current lessons in group
      if (currGroupLessonId !== -1) {
        const current_lesson = currentLessons.filter(
          (currentLesson) => currentLesson.id === currGroupLessonId
        )[0]; //confirm match

        try {
          return current_lesson.activities[0].id;
        } catch {
          console.log("No activity found in this lesson.");
        }
      } else {
        try {
          return currentLessons[0].activities[0].id;
        } catch {
          console.log("No activity found in the first lesson.");
        }
      }
      return LEARNING_MOMENT_PHOTO;
    };
    navigate(
      `${CURLANG(currLanguage)}/${LESSON_ACTIVITY_PAGE(
        currentProject.slug,
        currGroupLessonId.toString()
      )}/activity/${activitySlug()}`
    );
  };

  // useEffect(() => {
  //   const populateData = async () => {
  //     const res = await getEnrollmentChildren(currentProject.id);
  //     if (typeof res === "string") {
  //       openSnack(res, false);
  //     } else {
  //       // console.log("res: ", res);
  //       const enrolledChildren_ = res.map((r) => r.child_or_teacher.id);
  //       setEnrolledChildren(enrolledChildren_);
  //     }
  //   };
  //   currentProject && populateData();
  // }, [currentProject]);

  const [isLessonOne, currGroupLessonId] = getCurrGroupLessonId();
  // console.log("isinBag: ", isInBag);
  // console.log("groupProjectBoxProps: ", groupProjectBoxProps[currGroup.id]);
  // console.log("isinSchedule: ", isInSchedule);
  // console.log("currentProject: ", currentProject);
  // console.log("currentLessons: ", currentLessons);
  // console.log("currGroupLessonId: ", currGroupLessonId);

  const isRenderNoLesson =
    isEndOfProjectOrSharing || currentLessons.length === 0 || isLessonActivity;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 1,
        maxWidth: 300,
        ...sx,
      }}
    >
      {isRenderNoLesson ? (
        <>
          {isEndOfProjectOrSharing || isLessonActivity ? null : (
            <Box
              sx={{
                width: "70%",
                height: "50px",
                margin: "2rem auto 0",
                backgroundColor: "primary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                cursor: "not-allowed",
              }}
            >
              <Typography
                sx={{
                  color: "txt.light",
                  fontSize: "clamp(16px, 2vw, 18px)",
                }}
              >
                {translation.noLesson}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          <ScheduleGroupPageModal
            open={open}
            handleClose={handleClose}
            projectId={currentProject.id}
            projectTitle={currentProject.name}
            assessment={currentProject.assessment}
            groupId={currGroup.id}
          />
          <Box
            sx={{
              width: "70%",
              height: "50px",
              backgroundColor: "primary.main",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              cursor: "pointer",
              transition: "100ms all ease-in-out",
              "&:hover": {
                transition: "100ms all ease-in-out",
                transform: "translateX(6px)",
              },
            }}
            onClick={() => {
              if (isInBag) {
                if (isInSchedule) {
                  handleEnrollment();
                } else {
                  handleOpen();
                }
              } else {
                if (currGroup.id !== -1) {
                  handleAddToBackpack();
                } else {
                  openSnack(translation.noGroupAssigned, false);
                }
              }
            }}
          >
            <Typography
              sx={{
                color: "txt.light",
                fontSize: "clamp(16px, 2vw, 18px)",
              }}
            >
              {isInBag
                ? isInSchedule
                  ? isLessonOne
                    ? translation.begin
                    : translation.resume
                  : translation.scheduleProject
                : translation.addToBackpack}
            </Typography>

            <Box sx={{ width: "20%", ml: "4px", mt: "4px" }}>
              <Image
                src={
                  isInBag
                    ? isInSchedule
                      ? CaravanImg
                      : CalendarImg
                    : BackpackImg
                }
                alt="caravan"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NextActionButton;

import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import TeacherOurStoryBackgroundImage from "images/teacher-landing-page/our-story-background.png";
import BackgroundFive from "images/teacher-landing-page/background-5.png";
import Logo from "images/logo-2.png";

import Image from "components/Image/Image";
const OurStory = () => {
  const commonTypographyStyle = { mb: 1, fontSize: "1.35vw" };

  const ourTeamList = [
    {
      name: "Elizabeth Wu",
      title: "Head of TTA",
    },
    {
      name: "Dr Natalie Alexandra Tse",
      title: "Asst. Head of TTA",
    },
    {
      name: "Jen Wee",
      title: "Content Contributor",
    },
    {
      name: "Helen Clare Rozario",
      title: "Content Producer",
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${TeacherOurStoryBackgroundImage})`,
          position: "relative",
          maxHeight: window.innerHeight - 64,
          objectFit: "cover",
          zIndex: 1,
        }}
      >
        <Image
          src={TeacherOurStoryBackgroundImage}
          alt={"teacher-landing-background"}
          style={{
            maxHeight: window.innerHeight - 64,
            objectFit: "cover",
            zIndex: 1,
            position: "relative",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            left: 40,
            top: 20,
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            flexDirection: "column",
            zIndex: 3,
            width: "50%",
          }}
        >
          <Typography
            sx={{
              fontSize: "3vw",
              fontWeight: 600,
              color: "primary.main",
            }}
          >
            The Trehaus Story
          </Typography>
          <Typography
            sx={{
              mt: "-8px",
              mb: 2,
              fontSize: "2.5vw",
              fontWeight: 450,
              color: "primary.main",
            }}
          >
            Change-makers Raising Change-makers
          </Typography>
          <Typography
            sx={{
              fontSize: "1.35vw",
              width: "80%",
              mb: 2,
            }}
          >
            Trehaus was created by parents to connect and be with their children
            during a very important time in their lives – you see, a place like
            Trehaus did not exist, so we invented it.
          </Typography>
          <Box>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                borderRadius: "35px",
              }}
              variant="contained"
              onClick={() => {}}
            >
              Read More
            </Button>
          </Box>
        </Box>

        <Image
          src={BackgroundFive}
          alt={"background-5"}
          style={{
            objectFit: "contain",
            objectPosition: "left",
            position: "absolute",
            zIndex: 2,
            left: 0,
            top: 0,
            transform: "scale(0.85)",
            transformOrigin: "left top",
          }}
        />
      </Box>
      <Box
        sx={{
          padding: 2,
          px: 20,
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Typography
          sx={{
            fontSize: "2.5vw",
            fontWeight: 500,
            color: "white",
            textAlign: "center",
          }}
        >
          OUR STORY
        </Typography>

        <Typography sx={commonTypographyStyle}>
          Trehaus was founded by parents seeking to connect with their children
          during a critical phase in their lives. In 2016, we revolutionized the
          traditional work, school, and play model, creating an innovative space
          in Singapore where a preschool-childcare and coworking space
          coexisted. This visionary concept attracted like-minded parents,
          professionals, and children, forming a progressive community.
        </Typography>
        <Typography sx={commonTypographyStyle}>
          We strongly believe that early education must evolve beyond knowledge
          acquisition. At Trehaus, we aim to cultivate integrity, character,
          creativity, empathy, and resilience in children, preparing them to be
          active contributors in a rapidly changing world.
        </Typography>
        <Typography sx={commonTypographyStyle}>
          In 2019, we launched our Littles' Programme Curriculum, combining
          future-ready and skills with a bilingual core program. Our holistic
          approach focuses on each child's self-discovery journey, offering a
          child-led, play-based curriculum tailored to their unique needs, while
          incorporating research-based pedagogies.
        </Typography>
        {/* <Typography sx={commonTypographyStyle}>
          We envision children to be resilient, curious, kind, conscientious,
          civic-minded, creative, adaptable, good communicators and critical
          thinkers AND we know it takes a special breed of teachers who would
          comfortably step into the role as a second-parent to make that a
          reality. You, as an educator, have embraced a high calling, and so
          much respect needs to be accorded to you for this wonderful choice you
          have made. We hope that these skills will equip you and help you grow
          and develop as a person and as a professional. It is our hope that
          every educator will always speak the language of acceptance to
          children under her charge, and that you will find this journey of
          early years rewarding and fulfilling.
        </Typography> */}
      </Box>
      <Box
        sx={{
          padding: 2,
          paddingBottom: 5,
          px: 15,
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "2.5vw",
            fontWeight: 500,
            color: "primary.main",
            textAlign: "center",
          }}
        >
          MEET OUR TEAM
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {ourTeamList.map((member) => {
            return (
              <Card
                sx={{
                  minWidth: 235,
                  maxWidth: 235,
                  borderRadius: "10px",
                  backgroundColor: "#F0F0F0",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  transition: "200ms all ease-in-out",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  alt={"No Image"}
                  height="206"
                  image={Logo}
                  sx={{
                    backgroundColor: "primary.light",
                    "&::before": { display: "none" },
                  }}
                  onError={(
                    event: React.SyntheticEvent<HTMLImageElement, Event>
                  ) => {
                    event.currentTarget.onerror = null;
                    event.currentTarget.src = Logo;
                  }}
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "13px 18px",
                    gap: "0.5rem",
                    flexGrow: 1,
                    pb: "0px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Stack direction="column" spacing={0.5}>
                      <Typography sx={{ fontWeight: 500 }}>
                        {member.name}
                      </Typography>
                      <Typography>{member.title}</Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  ></Box>
                </CardContent>
              </Card>
            );
          })}{" "}
        </Box>
      </Box>

      <Box
        sx={{
          height: "10vh",
          width: "100%",
          backgroundColor: "primary.main",
        }}
      ></Box>
    </>
  );
};

export default OurStory;

import React, { useState, useEffect, useMemo } from 'react'
import { Chip, IconButton, Typography, Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import Image from "components/Image/Image";
import Logo from "images/logo-2-colour.png";


import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { createOrUpdateAttendance } from 'api/auth-api';
import { API_Profile_Short } from 'types/profile';




const AttendanceToday = ({
    filteredChildren,
    modalText,
    setModalText,
    modalAbsentee,
    setModalAbsentee,
    attendances,
}: {
    filteredChildren: API_Profile_Short[];
    modalText: any;
    setModalText: any;
    modalAbsentee: any;
    setModalAbsentee: any;
    attendances: any;
}) => {


    const groupedPeople = useMemo(() => filteredChildren.reduce((groups: any, person: any) => {
        const firstLetter = person.full_name[0].toUpperCase();
        if (!groups[firstLetter]) {
            groups[firstLetter] = [];
        }
        groups[firstLetter].push(person);
        return groups;
    }, {}), [filteredChildren]);
    const letters = useMemo(() => Object.keys((groupedPeople as any) || {}).sort(), [groupedPeople]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
        >
            {letters.map((letter: any, index: number) => {
                return (
                    <LetterContainer
                        key={index}
                        letter={letter}
                        id={index}
                        lengthLetters={groupedPeople.length}
                        groupedPeople={groupedPeople}
                        modalText={modalText}
                        setModalText={setModalText}
                        modalAbsentee={modalAbsentee}
                        setModalAbsentee={setModalAbsentee}
                        attendances={attendances}


                    />
                );
            })}
        </Box>
    )
}


export default AttendanceToday


const LetterContainer = ({
    letter,
    id,
    lengthLetters,
    groupedPeople,
    modalText,
    setModalText,
    modalAbsentee,
    setModalAbsentee,
    attendances,
}: {
    letter: any;
    id: any;
    lengthLetters: any;
    groupedPeople: any;
    modalText: any;
    setModalText: any;
    modalAbsentee: any;
    setModalAbsentee: any;
    attendances: any;
}) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flexStart",
                    gap: 1,
                    width: "100%",
                }}
                key={letter}
            >
                <Typography
                    sx={{
                        fontWeight: "600",
                        fontSize: "24px",
                        color: "#91C6C5",
                        ml: 2,
                        display: "flex",
                        justifyContent: "flexStart",
                        minWidth: 50,
                        alignItems: "center",
                    }}
                >
                    {letter}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        gap: "16px",
                        width: "1300px",
                    }}
                >
                    {groupedPeople[letter].map((person: any, index: any) => {
                        return (
                            <Person
                                person={person}
                                key={index}
                                setModalText={setModalText}
                                setModalAbsentee={setModalAbsentee}
                                attendances={attendances}
                            />
                        );
                    })}
                </Box>
            </Box>
        </>
    );
};


const Person = ({
    person,
    setModalText,
    setModalAbsentee,
    attendances,
}: {
    person: any;
    setModalText: any;
    setModalAbsentee: any;
    attendances: any;
}) => {


    const today = new Date();
    const [absentee, setAbsentee] = useState<any>({});
    const [reasonText, setReasonText] = useState<any>("");
    const { currGroup, } = useStore((state) => ({
        currGroup: state.currGroup,
    }));
    const openSnack = useSnack();








    useEffect(() => {
        if (currGroup.id != -1) {
            attendances.map((attendance: any) => {
                if (attendance.profile == person.id) {
                    console.log(person)
                    setAbsentee(person);
                    setReasonText(attendance.absent_reason)
                }
            })
        }
    }, [currGroup, attendances])
    return (
        <Box
            sx={{
                borderRadius: "8px",
                display: "flex",
                width: "212px",
                padding: "12px",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                flexShrink: "0",
                backgroundColor: "#F2F2F2",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    overflow: "hidden",
                    position: "relative",
                    width: 60,
                    height: 60,
                }}
            >
                <Box
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        paddingTop: "100%",
                    }}
                >
                    <Image
                        src={person.image || Logo}
                        alt={person.full_name}
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                    />
                </Box>
            </Box>
            <Typography>{person.full_name}</Typography>
            <Box
                sx={{
                    display: "flex",
                    gap: 1,
                }}
            >
                <Box
                    sx={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        padding: 2,
                        // absent: c9, present: green
                        backgroundColor: absentee.id == person.id ? "#C9C9C9" : "#5CCA9C",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        cursor: absentee.id == person.id ? "pointer" : "default",
                    }}
                >
                    <IconButton
                        sx={{ color: "white !important", }}
                        disabled={absentee.id != person.id}
                        onClick={() => {
                            console.log(reasonText)
                            // set as absent initially, changing to present now
                            if (reasonText.length > 0) {
                                const updateSingleAbsentee = async () => {
                                    const access = localStorage.getItem("access")
                                    if (access) {
                                        const response = await createOrUpdateAttendance([{ profile: person.id, absent_reason: "" }], currGroup.id, access)
                                        console.log(response)
                                        if (response?.data.length == 0 && response.status == 200) {
                                            openSnack("Child is Present", true)
                                        }
                                        else if (response?.status != 200) {
                                            openSnack("Error setting Attendance", false)
                                        }
                                    }
                                }
                                updateSingleAbsentee()




                            }
                            setAbsentee({})


                        }}


                    >
                        <DoneIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        padding: 2,
                        backgroundColor: absentee.id == person.id ? "#FF5D53" : "#C9C9C9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        cursor: absentee.id == person.id && reasonText == '' ? "pointer" : "default",


                    }}


                >
                    <IconButton
                        disabled={absentee.id == person.id && reasonText != ''}
                        sx={{ color: "white !important", }}
                        onClick={() => {
                            if (absentee.id == person.id && reasonText == '') {
                                setAbsentee({});
                            } else {
                                setAbsentee(person);
                            }
                        }}
                    >
                        <CloseIcon />


                    </IconButton>
                </Box>


                {absentee.id == person.id && (
                    <Box
                        sx={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            padding: 2,


                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setModalText(reasonText);
                            setModalAbsentee(person);
                        }}
                    >
                        <StickyNote2OutlinedIcon />
                    </Box>
                )}
            </Box>
        </Box>
    );
};




import { useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

import Button from "components/Button/Button";
import {
  API_Evaluation_Questionnaire,
} from "types/evaluationquestionnaire";

import {
  createEvaluationQuestionnaire,
  updateEvaluationQuestionnaire,
} from "api/evaluationquestionnaire-api";

import {
  CURLANG,
  PRE_DISCOVERY_QUESTIONNAIRES,
  PRE_DISCOVERY_QUESTIONNAIRE,
} from "constants/url";



import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { useNavigate, useParams } from "react-router-dom";
import { translation } from "constants/translation";

interface Props {
  create?: boolean;
}

const EditQuestionnaire = ({ create = false }: Props) => {
  const {
    currLanguage,
    currentEvaluationQuestionnaire,
    setCurrentEvaluationQuestionnaire,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currentEvaluationQuestionnaire: state.currentEvaluationQuestionnaire,
    setCurrentEvaluationQuestionnaire: state.setCurrentEvaluationQuestionnaire,
  }));

  const navigate = useNavigate();
  const openSnack = useSnack();
  const { moduleSlug } = useParams();

  const [evaluationQuestionnaireInfo, setEvaluationQuestionnaireInfo] =
    useState<API_Evaluation_Questionnaire>(currentEvaluationQuestionnaire);

  //   const [imagePreview, setImagePreview] = useState<string | null>(null);

  //   useEffect(() => {
  //     const populateData = async () => {
  //       if (typeof moduleInfo.image === "string") {
  //         setImagePreview(moduleInfo.image);
  //       }

  //       // setCurrentVideoActivity(await getVideoActivityBySlug(activitySlug))
  //     };

  //     populateData();
  //   }, []);

  useEffect(() => {
    if (create) {
      setEvaluationQuestionnaireInfo((prev) => ({
        ...prev,
        id: -1,
        name: "",
        description: "",
        isPublish: false,
        weight: -1,
      }));
      //   setImagePreview(null);
    }
  }, []);

  const handleSubmit = async () => {
    const res = await updateEvaluationQuestionnaire({
      id: evaluationQuestionnaireInfo.id,
      name: evaluationQuestionnaireInfo.name,
      description: evaluationQuestionnaireInfo.description,
      is_published: evaluationQuestionnaireInfo.is_published,
      weight: evaluationQuestionnaireInfo.weight,
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      //   setModuleInfo(res);
      openSnack(`Update Evaluation Questionnaire successfully!`, true);
      navigate(`${CURLANG(currLanguage)}/${PRE_DISCOVERY_QUESTIONNAIRES}`);
    }
  };

  const handleCreate = async () => {
    const res = await createEvaluationQuestionnaire({
      name: evaluationQuestionnaireInfo.name,
      description: evaluationQuestionnaireInfo.description,
      is_published: evaluationQuestionnaireInfo.is_published,
      weight: evaluationQuestionnaireInfo.weight,
    });
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      //   setModuleInfo(res);
      openSnack(`Create Evaluation Questionnaire successfully!`, true);
      navigate(
        `${CURLANG(currLanguage)}/${PRE_DISCOVERY_QUESTIONNAIRE(res.slug)}`
      );
    }
  };

  return (
    <Box sx={{ display: "flex", mx: "60px", gap: "32px", flexFlow: "wrap" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "30px",
          pb: "30px",
        }}
      >
        <Button
          buttonText={``}
          arrow={true}
          backward={true}
          onClick={() => {
            navigate(
              `${CURLANG(currLanguage)}/${PRE_DISCOVERY_QUESTIONNAIRES}`
            );
          }}
        />
        <Box></Box>
        <Box></Box>
      </Box>
      <Box sx={{ width: "100%" }}></Box>
      <Stack
        component="form"
        sx={{
          display: "flex",
          width: "80vw",
          margin: "auto",
        }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Questionnaire Name"
          value={evaluationQuestionnaireInfo.name}
          onChange={(e) => {
            setEvaluationQuestionnaireInfo({
              ...evaluationQuestionnaireInfo,
              name: e.target.value,
            });
          }}
          required
        />

        <TextField
          label="Module Description"
          value={evaluationQuestionnaireInfo.description}
          onChange={(e) => {
            setEvaluationQuestionnaireInfo({
              ...evaluationQuestionnaireInfo,
              description: e.target.value,
            });
          }}
          multiline
          rows={4}
          required
        />

        <Typography sx={{ mb: "8px" }}>{translation.publish}</Typography>
        {evaluationQuestionnaireInfo.is_published ? (
          <CheckBox
            sx={{ cursor: "pointer", color: "txt.secondary" }}
            onClick={() => {
              setEvaluationQuestionnaireInfo({
                ...evaluationQuestionnaireInfo,
                is_published: !evaluationQuestionnaireInfo.is_published,
              });
            }}
          />
        ) : (
          <CheckBoxOutlineBlank
            sx={{ cursor: "pointer", color: "txt.secondary" }}
            onClick={() => {
              setEvaluationQuestionnaireInfo({
                ...evaluationQuestionnaireInfo,
                is_published: !evaluationQuestionnaireInfo.is_published,
              });
            }}
          />
        )}

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography>Module Image:</Typography>
          <input
            accept="image/*"
            type="file"
            id={"thumbnail-button"}
            hidden
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0) {
                setQuestionnaireInfo({
                  ...questionnaireInfo,
                  image: event.target.files[0],
                });
                setImagePreview(URL.createObjectURL(event.target.files[0]));
              }
            }}
          />
          <label htmlFor={"thumbnail-button"}>
            <IconButton
              component="span"
              sx={{
                borderRadius: "8px",
                backgroundColor: "primary.light",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              <ImageIcon sx={{ color: "txt.secondary" }} />
            </IconButton>
          </label>
          {imagePreview !== null && (
            <Box
              sx={{
                width: "200px",
                height: "200px",
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Image src={imagePreview} alt="thumbnail" />
              <ClearIcon
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setModuleInfo({
                    ...moduleInfo,
                    image: null,
                  });
                  setImagePreview(null);
                }}
              />
            </Box>
          )}
        </Box> */}

        <Button
          buttonText={create ? "Create" : "Submit"}
          onClick={create ? handleCreate : handleSubmit}
          style={{ position: "fixed", bottom: 30, right: 40 }}
        />
      </Stack>
    </Box>
  );
};

export default EditQuestionnaire;

import { deleteTeacherActivity } from "../../../../../../api/teacher-api";
import WarningDialog from "../../../../../../components/Dialog/WarningDialog";
import { translation } from "../../../../../../constants/translation";
import useDialog from "../../../../../../hooks/useDialog";
import useSnack from "../../../../../../hooks/useSnack";
import useStore from "../../../../../../store/store";
import Button from "../../../../../../components/Button/Button";

export const DeleteActivityButton = ({
  activityId,
  handleClose,
}: {
  activityId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const { setIsRefreshTeacherModule } = useStore((state) => ({
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));

  const handleDelete = async () => {
    const res = await deleteTeacherActivity(activityId);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setIsRefreshTeacherModule(true);
      openSnack(translation.success_remove_activity, true);
      handleClose();
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Activity?",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <Button
        arrow={false}
        buttonText={translation.delete_activity || "Delete Activity"}
        btnType="outlined"
        style={{
          fontSize: "14px",
          marginTop: 0,
        }}
        onClick={handleOpenDialog}
      />
    </>
  );
};

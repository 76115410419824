import  { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";

import SideTabs from "components/SideTabs/SideTabs";

import {
  ProjectCategory,
  ProjectCategoryModule,
} from "types/project";
import Image from "components/Image/Image";

import { API_Project } from "types/project";
import { translation } from "constants/translation";
import useStore from "store/store";
import { getProjectCategories } from "api/project-api";

const ModuleSection = ({}: {}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [projectCategories, setProjectCategories] = useState<ProjectCategory[]>(
    []
  );
  useEffect(() => {
    const populateProjectCategories = async () => {
      const data: ProjectCategory[] = await getProjectCategories(currLanguage)
      const filteredData = data.filter((cat) => !cat.assessment);
      setProjectCategories(filteredData);
    };
    populateProjectCategories();
  }, [currLanguage]);

  return (
    <>
      <Typography sx={{ mt: "2.3rem", mb: 1 }}>
        {translation.byModule}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "28px" }}>
        {projectCategories.map((cat) => {
          if (cat.modules.length > 0) {
            return (
              <Box sx={{ mb: "12px" }} key={cat.id}>
                <Typography
                  sx={{ fontSize: "11px", fontWeight: "bold", mb: "9px" }}
                >
                  {cat.name}
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  {cat.modules.map((proj: ProjectCategoryModule) => {
                    return (
                      <SideTabs
                        title={proj.name}
                        slug={proj.slug}
                        subtitle={proj.description}
                        icon={
                          proj.image !== "" && proj.image !== null ? (
                            <Box
                              sx={{
                                width: "24px",
                                height: "24px",
                                overflow: "hidden",
                                mr: "12px",
                              }}
                            >
                              <Image
                                src={proj.image}
                                alt={proj.name}
                                objectFit="contain"
                              />
                            </Box>
                          ) : (
                            <AccessibilityNewIcon
                              sx={{
                                width: "24px",
                                height: "24px",
                                overflow: "hidden",
                                mr: "12px",
                              }}
                            />
                          )
                        }
                        key={`${proj.name}-${proj.id}`}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          } else {
            return <div key={cat.id} />;
          }
        })}
      </Box>
    </>
  );
};

export default ModuleSection;


import { Box } from "@mui/system";
import useStore from "store/store";
import {
  CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
  CALENDAR_BORDER_BOLD_STYLE,
  CALENDAR_BORDER_STYLE,
  CALENDAR_SELECTED_CELL_BACKGROUND_COLOR,
  DATETIME_FORMAT,
  PROJECT_BOX,
  RIGHT_COLUMN_WIDTH,
  ROW_HEIGHT,
  WEEKEND_COLOR,
} from "containers/CalendarPage/constants/constants";
import { useDrop } from "react-dnd";
import ProjectBox from "./ProjectBox";
import moment from "moment";
import {
  CalendarViewOptions,
  ProjectBoxProps,
  GroupProjectBoxProps,
} from "types/calendar";
import { createSchedule } from "api/calendar-api";
import useSnack from "hooks/useSnack";
import { getProjectBoxObjects } from "../../../constants/helper";
import { getProjectLessons } from "api/project-api";
import { Alert, Snackbar } from "@mui/material";

const GridRow = ({
  projectBoxes,
  groupId,
}: {
  projectBoxes: ProjectBoxProps[];
  groupId: number;
}) => {
  //  -----------------------
  //  |  E  |  Overview Row |
  //  |  M  |----------------
  //  |  P  | DR | DR  | DR |
  //  -----------------------
  //  | Left| Right/GridRow |
  //  | Left| Right/GridRow |
  //  | Left| Right/GridRow |

  const { startAndEndDate, calendarDates } = useStore((state) => ({
    startAndEndDate: state.startAndEndDate,
    calendarDates: state.calendarDates,
  }));

  const initialGridPosX = (currDateStr: string) => {
    // console.log("startDate: ", startDate);
    const endAt = moment(currDateStr);
    const startAt = moment(startAndEndDate.startDate);
    const diffDays = endAt.diff(startAt, "days");
    // console.log("initialGridPos: ", diffDays);
    return diffDays;
  };
  return (
    <Box
      sx={{
        height: "inherit",
        display: "flex",
        position: "relative",
      }}
    >
      {calendarDates.map((calendarDate, index) => {
        return (
          <GridCell
            key={`${calendarDate}`}
            index_={index}
            boxCount={calendarDates.length}
            groupId={groupId}
            projectNumber={projectBoxes?.length}
            date={calendarDate}
          />
        );
      })}
      {projectBoxes &&
        projectBoxes.map((projectBox, index) => (
          <ProjectBox
            key={`${projectBox.id}-${groupId}-${projectBox.startAt}-${
              projectBox.endAt
            }-${initialGridPosX(projectBox.startAt)}-${index}-${
              calendarDates[0]
            }`}
            id={projectBox.id}
            scheduleId={projectBox.scheduleId}
            projectId={projectBox.projectId}
            groupId={groupId}
            projectTitle={projectBox.projectTitle}
            projectDescription={projectBox.projectDescription}
            projectSlug={projectBox.projectSlug}
            moduleCategoryName={projectBox.moduleCategoryName}
            moduleName={projectBox.moduleName}
            image={projectBox.image}
            bgColor={projectBox.bgColor}
            width={projectBox.width}
            height={projectBox.height}
            initialGridPosXMultiplier={initialGridPosX(projectBox.startAt)}
            initialGridPosYMultiplier={index}
            startAt={projectBox.startAt}
            endAt={projectBox.endAt}
          />
        ))}
    </Box>
  );
};

const GridCell = ({
  boxCount,
  groupId,
  projectNumber,
  date,
}: {
  index_: number;
  boxCount: number;
  groupId: number;
  projectNumber: number;
  date: Date;
}) => {
  const openSnack = useSnack();
  const {
    currGroup,
    currLanguage,
    calendarDates,
    setGroupProjectBoxProps,
    currentView,
    setRefreshGroupLessonInfoList,
    setRefreshGroup,
    setIsRefreshBypassLoading,
  } = useStore((state) => ({
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
    calendarDates: state.calendarDates,
    groupProjectBoxProps: state.groupProjectBoxProps,
    setGroupProjectBoxProps: state.setGroupProjectBoxProps,
    currentView: Object.keys(state.calendarViews).filter(
      (view) => state.calendarViews[view as CalendarViewOptions] === true
    )[0] as CalendarViewOptions,
    setRefreshGroupLessonInfoList: state.setRefreshGroupLessonInfoList,
    setRefreshGroup: state.setRefreshGroup,
    setIsRefreshBypassLoading: state.setIsRefreshBypassLoading,
  }));

  const createScheduleFunction = async (
    project_id: number,
    group_id: number,
    start_at: string,
    end_at: string,
    groupProjectBoxPropsFromDrop: GroupProjectBoxProps
  ) => {
    const res = await createSchedule({
      project_id,
      group_id,
      start_at,
      end_at,
    });
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      // console.log("res: ", res);

      let updatedProjectBoxes: ProjectBoxProps[] = [];
      res.schedules.map((schedule) => {
        const temp_schedules = getProjectBoxObjects(schedule, calendarDates);
        updatedProjectBoxes = [...updatedProjectBoxes, ...temp_schedules];
      });

      setGroupProjectBoxProps({
        ...groupProjectBoxPropsFromDrop,
        [group_id]: updatedProjectBoxes,
      });
      setIsRefreshBypassLoading(true);
      setRefreshGroupLessonInfoList(true);
      setRefreshGroup(true);
      openSnack("Created! ", true);
    }
    return res;
  };

  const isWeekend = date.getDay() === 6 || date.getDay() === 0;

  // console.log("groupProjectBoxProps After: ", groupProjectBoxProps);
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: PROJECT_BOX,
      drop: async (item: any, monitor) => {
        const { projectId, groupId, groupProjectBoxPropsFromDrop } = item;

        // console.log(
        //   "groupProjectBoxPropsFromDrop: ",
        //   groupProjectBoxPropsFromDrop
        // );
        let projectLessonLength = 0;
        const projectLessonRes = await getProjectLessons(
          currLanguage,
          String(projectId)
        );
        if (typeof projectLessonRes === "string") {
          openSnack(projectLessonRes, false);
        } else {
          projectLessonLength = projectLessonRes.lessons.length;
        }

        const startAt = moment(date).format(DATETIME_FORMAT);
        const endAt = moment(date)
          .add(projectLessonLength, "days")
          .format(DATETIME_FORMAT);
        // console.log("inside: ", startAt, endAt);
        // setFormData({
        //   project_id: projectId,
        //   group_id: groupId,
        //   start_at: startAt,
        //   end_at: endAt,
        // });
        // console.log("date: ", date);
        // console.log("projectLessonLength: ", projectLessonLength);
        createScheduleFunction(
          projectId,
          groupId,
          startAt,
          endAt,
          groupProjectBoxPropsFromDrop
        );
        setRefreshGroup(true);
      },
      canDrop: (item: any, monitor) => {
        return item.groupId === groupId;
      },
      collect: (monitor) => {
        return {
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        };
      },
    }),
    []
  );

  const isLastDay =
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime() ===
    date.getTime();

  const isEvenMonth = (date.getMonth() + 1) % 2 === 0;
  // console.log("currItem: ", currItem);
  // console.log("canDrop: ", canDrop);
  return (
    <>
      <Box
        sx={{
          height: `${Math.max(ROW_HEIGHT * projectNumber, ROW_HEIGHT)}px`,
          width: `${RIGHT_COLUMN_WIDTH / boxCount}vw`,
          borderBottom: CALENDAR_BORDER_BOLD_STYLE,
          borderRight:
            currentView === "Year" &&
            // (index_ === 0 || index_ % 7 !== 0) &&
            !isLastDay
              ? ""
              : isLastDay
              ? currentView !== "Year"
                ? CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE
                : CALENDAR_BORDER_STYLE
              : "",
          backgroundColor: canDrop
            ? isOver
              ? "primary.main"
              : CALENDAR_SELECTED_CELL_BACKGROUND_COLOR
            : isOver
            ? "red"
            : currentView !== "Year"
            ? isWeekend
              ? WEEKEND_COLOR
              : ""
            : isEvenMonth
            ? WEEKEND_COLOR
            : "",
        }}
        ref={drop}
      />
      <Snackbar open={!canDrop && isOver}>
        <Alert
          severity={"error"}
          sx={{
            width: "100%",
            maxHeight: "100px",
            maxWidth: "95vw",
            overflow: "hidden",
          }}
        >
          {`Please drop to the ${currGroup.name} group!`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GridRow;

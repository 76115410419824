import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  IconButton,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
const FeedbackPhotosGalleryModal = ({
  open,
  setOpen,
  displayImageIndex,
  setDisplayImageIndex,
  images,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayImageIndex: number | null;
  setDisplayImageIndex: React.Dispatch<React.SetStateAction<number>>;
  images: any;
}) => {
  const handleClose = () => {
    setDisplayImageIndex(-1);
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ArrowBackIcon
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton
              onClick={() => {
                if (displayImageIndex !== null && displayImageIndex > 0) {
                  setDisplayImageIndex(displayImageIndex - 1);
                }
              }}
              disabled={displayImageIndex === null || displayImageIndex == 0}
            >
              <ArrowBackIosIcon />
            </IconButton>
            {displayImageIndex !== null &&
              images[displayImageIndex] instanceof File && (
                <img
                  key={displayImageIndex}
                  src={URL.createObjectURL(images[displayImageIndex])}
                  alt=""
                  style={{
                    minWidth: "556px",
                    maxWidth: "556px",
                    minHeight: "346px",
                    maxHeight: "346px",
                    objectFit: "contain",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              )}
            <IconButton
              disabled={
                displayImageIndex === null ||
                displayImageIndex == images.length - 1
              }
              onClick={() => {
                if (
                  displayImageIndex !== null &&
                  displayImageIndex < images.length - 1
                ) {
                  setDisplayImageIndex(displayImageIndex + 1);
                }
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              overflowX: "auto",
              width: "100%",
            }}
          >
            {images.map((image: any, index: number) => (
              <img
                key={index}
                src={URL.createObjectURL(image)}
                alt=""
                style={{
                  height: "72px",
                  width: "116px",
                  objectFit: "cover",
                  filter: index === 2 ? "grayscale(100%)" : "none",
                }}
                onClick={() => {
                  setDisplayImageIndex(index);
                }}
              />
            ))}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FeedbackPhotosGalleryModal;

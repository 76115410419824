import { useState} from "react";

import useStore from "store/store";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { BrowseProjectsButton } from "components/Button/Button";

import { CURLANG, MARKETPLACE } from "constants/url";
import { BACKPACK_HEIGHT } from "../constants/constants";

import BackpackToggleButton from "./BackpackSideMenuComponents/BackpackToggleButton";
import BackpackTitle from "./BackpackSideMenuComponents/BackpackTitle";
import BackpackContent from "./BackpackSideMenuComponents/BackpackContent";

const BackpackSideMenu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { currGroup, currGroupBackpack, groupCalendarList } = useStore(
    (state) => ({
      currGroup: state.currGroup,
      currGroupBackpack: state.currGroupBackpack,
      groupCalendarList: state.groupCalendarList,
    })
  );

  return (
    <>
      <BackpackToggleButton open={open} setOpen={setOpen} />
      <Box
        sx={{
          height: open ? `${BACKPACK_HEIGHT}px` : "0px",
          width: "100%",
          backgroundColor: "primary.light",
          transform: open ? "translateX(0%)" : "translateX(-100%)",
          transition: "all 200ms ease-in-out",
          position: "absolute",
          bottom: 0,
          // bottom: `${BACKPACK_BOTTOM_PERCENT}%`,
          overflowX: "auto",
          scrollbarWidth: "none", // for firefox browsers
          "&::-webkit-scrollbar": {
            display: "none", // for chromuim browsers
          },
          zIndex: 5,
        }}
      >
        <BackpackTitle />
        <Box sx={{ mt: 1 }} key={Math.random()}>
          {currGroupBackpack.projects.length > 0 ? (
            <BackpackContent
              projects={currGroupBackpack.projects}
              groupId={currGroup.id}
            />
          ) : (
            <EmptyBackpack groupName={currGroup.name} />
          )}
        </Box>
      </Box>
    </>
  );
};

const EmptyBackpack = ({ groupName }: { groupName: string }) => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        width: "100%",
        // height: `${BACKPACK_HEIGHT - (BACKPACK_HEIGHT / 100) * 30}px`,
        // border: CALENDAR_BORDER_STYLE,
        borderRadius: "10px",
        textAlign: "center",
      }}
    >
      <Typography>
        You will need to add some projects into {groupName}'s backpack before
        adding it to the calendar
      </Typography>
      <BrowseProjectsButton
        style={{ margin: "auto" }}
        onClick={() => navigate(`${CURLANG(currLanguage)}/${MARKETPLACE}`)}
      />
    </Box>
  );
};
export default BackpackSideMenu;

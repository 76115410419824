import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import  { useEffect } from "react";
import useStore from "store/store";
import {
  CalendarLabel,
  CalendarViewOptions,
  MONTHS_TO_INDEX,
} from "types/calendar";
import {
  CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
  CALENDAR_BORDER_STYLE,
  CALENDAR_CELL_BACKGROUND_COLOR,
  FONT_SIZE,
  MONTH_VIEW_LABEL_DISPLAY,
  RIGHT_COLUMN_WIDTH,
} from "containers/CalendarPage/constants/constants";
import {
  generateDayLabels,
  generateMonthDates,
  generateMonthLabels,
  generateWeekDates,
  generateYearDates,
  generateYearLabels,
  getWeekNumber,
  translateMonth,
} from "containers/CalendarPage/constants/helper";
import OverviewRow from "./OverviewRow";

const DateRow = () => {
  const {
    calendarStartDate,
    startAndEndDate,
    currentView,
    setCalendarDates,
    calendarDates,
    setRefreshProjectBox,
  } = useStore((state) => ({
    calendarStartDate: state.calendarStartDate,
    startAndEndDate: state.startAndEndDate,
    currentView: Object.keys(state.calendarViews).filter(
      (view) => state.calendarViews[view as CalendarViewOptions] === true
    )[0] as CalendarViewOptions,
    setCalendarDates: state.setCalendarDates,
    calendarDates: state.calendarDates,
    setRefreshProjectBox: state.setRefreshProjectBox,
  }));

  useEffect(() => {
    // console.log("Set start and end date...");
    let dates: Date[];
    switch (currentView) {
      case "Week":
        dates = generateWeekDates(startAndEndDate);
        // generateDayLabels(dates);
        break;
      case "Month":
        dates = generateMonthDates(startAndEndDate);
        break;
      case "Year":
        dates = generateYearDates(startAndEndDate);
        break;
      default:
        dates = [];
    }
    setCalendarDates(dates);
  }, [startAndEndDate, currentView]);

  useEffect(() => {
    setRefreshProjectBox(true);
  }, [calendarDates]);

  const generateCalendarLabels = (): CalendarLabel[] => {
    switch (currentView) {
      case "Week":
        return generateDayLabels(calendarDates); //the 1 and the 'Thu'

      case "Month":
        // console.log(calendarDates);
        return generateMonthLabels(calendarDates); //the 1 and the 'Thu'
      case "Year":
        return generateYearLabels(); // the 1 and 'Jan'
      default:
        return [];
    }
  };

  const labels = calendarDates ? generateCalendarLabels() : [];

  const isToday = (dateObject: string) => {
    const temp = new Date();
    const today = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate());
    //  new Date(
    //   today.getFullYear(),
    //   today.getMonth(),
    //   today.getDate()
    // ).getTime() === date.getTime();
    if (currentView === "Week") {
      const labelDate = new Date(
        calendarStartDate.getFullYear(),
        calendarStartDate.getMonth(),
        Number(dateObject)
      );
      return today.getTime() === labelDate.getTime();
    } else if (currentView === "Month") {
      const todayWeek = Number(getWeekNumber(today));
      const labelWeek = Number(dateObject.split(" ").pop());
      return (
        todayWeek === labelWeek &&
        calendarStartDate.getFullYear() === today.getFullYear()
      );
    } else {
      const todayMonth = today.getMonth();
      const labelMonth = MONTHS_TO_INDEX[dateObject];
      return (
        todayMonth === labelMonth &&
        calendarStartDate.getFullYear() === today.getFullYear()
      );
    }
  };
  // console.log("labels: ", labels);
  return (
    <>
      <OverviewRow />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
          height: "48px",
          // width: "fit-content",
          backgroundColor: CALENDAR_CELL_BACKGROUND_COLOR,
        }}
      >
        {labels.map((label, index) => {
          return (
            <Box
              key={`${label}-${index}`}
              sx={{
                width:
                  currentView === "Year"
                    ? label.endDay &&
                      `${
                        (RIGHT_COLUMN_WIDTH / calendarDates.length) *
                        label.endDay
                      }vw`
                    : label.size
                    ? `${
                        (RIGHT_COLUMN_WIDTH / calendarDates.length) * label.size
                      }vw`
                    : `${
                        (RIGHT_COLUMN_WIDTH / calendarDates.length) *
                        MONTH_VIEW_LABEL_DISPLAY
                      }vw`,
                borderRight: CALENDAR_BORDER_STYLE,
                borderBottom: `1px solid var(--primary-main)`,
                height: "100%",
                display: currentView === "Year" ? "flex" : "block",
                alignItems: currentView === "Year" ? "center" : "",
                justifyContent: currentView === "Year" ? "center" : "",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  borderBottom: `5px solid var(--primary-${
                    isToday(label.title) ? "contrast" : "transparent"
                  })`,
                }}
              >
                <Box
                  key={label.title}
                  sx={{
                    height: label.subtitle !== "" ? "25px" : "47px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: `${FONT_SIZE}px`,
                      fontWeight: isToday(label.title) ? 500 : 400,
                      color: isToday(label.title)
                        ? "var(--primary-contrast)"
                        : "inherit",
                      textAlign: "center",
                    }}
                  >
                    {currentView === "Year"
                      ? translateMonth(label.title)
                      : label.title}
                  </Typography>
                </Box>
                {label.subtitle !== "" && (
                  <Typography
                    sx={{
                      fontSize: `${FONT_SIZE}px`,
                      fontWeight: isToday(label.title) ? 500 : 400,
                      color: isToday(label.title)
                        ? "var(--primary-contrast)"
                        : "inherit",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {label.subtitle}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default DateRow;

import { Document } from "@react-pdf/renderer";

import { API_Project_Module } from "types/project";

import React from "react";
import DevelopmentalGoalsPage from "./DevelopmentalGoalsPage";
import FocusAndGuidingQuestionsPage from "./FocusAndGuidingQuestionsPage";
import SelectedLessonPagePDF from "./SelectedLessonPagePDF";
import LessonActivitiesPage from "./LessonActivitiesPage";

interface PdfComponentProps {
  selectedLessonData: any;
  projModule: API_Project_Module;
  currentProject: any;
  projectCards: any[];
  fundamentalDict: Record<string, any>;
}

const LessonSelectionPDF = ({
  selectedLessonData,
  projModule,
  currentProject,
  projectCards,
  fundamentalDict,
}: PdfComponentProps) => {
  return (
    <Document>
      <DevelopmentalGoalsPage
        projModule={projModule}
        currentProject={currentProject}
        projectCards={projectCards}
      />
      {!currentProject.assessment && (
        <FocusAndGuidingQuestionsPage
          projModule={projModule}
          currentProject={currentProject}
        />
      )}
      {selectedLessonData.map((lesson: any, lessonIndex: any) => (
        <React.Fragment key={lessonIndex}>
          <SelectedLessonPagePDF
            projModule={projModule}
            currentProject={currentProject}
            lesson={lesson}
            lessonIndex={lessonIndex}
          />
          <LessonActivitiesPage
            fundamentalDict={fundamentalDict}
            currentProject={currentProject}
            lesson={lesson}
          />
        </React.Fragment>
      ))}
    </Document>
  );
};

export default LessonSelectionPDF;

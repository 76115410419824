import { Box } from "@mui/material";
import useStore from "store/store";
import { isProfileInGalleryAccessRoleList } from "helper/helper";
import AddIcon from "@mui/icons-material/Add";
import UploadCompletedWorkModal from "./UploadCompletedWorkModal";

const AddCompletedWorkButton = () => {
  const { profileDetails, role, currentProject } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      role: state.profileDetails.role,
      currentProject: state.currentProject,
      currLanguage: state.currLanguage,
    })
  );

  return isProfileInGalleryAccessRoleList(profileDetails.role) ? (
    <>
      <Box
        sx={{
          //   order: 1,
          display: "flex",
          margin: "4px",
          width: {
            xs: 150,
            sm: 200,
          },
          height: {
            xs: 150,
            sm: 200,
          },
        }}
      >
        <label htmlFor={`${currentProject.id}-completed-work`}>
          <Box
            sx={{
              width: {
                xs: 150,
                sm: 200,
              },
              height: {
                xs: 150,
                sm: 200,
              },
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
              borderRadius: "10px",
              color: "#444",
              border: "1px dashed",
              borderColor: "#444",
              transition: "all 100ms ease-in-out",
              cursor: "pointer",
              "&:hover": {
                color: "primary.main",
                borderColor: "primary.main",
                backgroundColor: "rgba(209, 228, 227, 0.37)",
                transition: "all 100ms ease-in-out",
              },
            }}
          >
            <AddIcon />
          </Box>
        </label>
      </Box>

      <UploadCompletedWorkModal project={currentProject} />
    </>
  ) : null;
};

export default AddCompletedWorkButton;

import React from "react";

interface Props {
  create?: boolean;
}

const EditAnswer = ({ create = false }: Props) => {
  return <div>EditAnswer</div>;
};

export default EditAnswer;

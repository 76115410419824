import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

import { translation } from "constants/translation";
import "./ticketPage.css";
import TicketManagePage from "containers/SiteAdminPage/ManagementPage/TicketManagePage";

const ViewTicketPage = () => {
  return (
    <Box
      sx={{
        minHeight: "71vh",
        p: "16px",
        mx: "60px",
        my: "16px",
      }}
    >
      <Typography variant="h3" sx={{ mb: "8px" }}>
        {translation.feedback}
      </Typography>

      <TicketManagePage />
    </Box>
  );
};

export default ViewTicketPage;

import React, { useEffect, useState } from "react";
import { PROFILE_SECTION_TESTIMONIALS } from "../constants";
import { slugify } from "helper/slugify";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProfileImage } from "../ProfileComponents"
import useStore from "store/store";
import { API_Testimonial } from "types/profile";
import moment from "moment";
import { translation } from "constants/translation";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { deleteTestimonial, updateTestimonial } from "api/profile-api";
import useSnack from "hooks/useSnack";

const TestimonialComponent = ({
  setIsModalOpen,
  setType,
  isOwner,
  setSelectedTestimonial,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  isOwner: boolean;
  setSelectedTestimonial: React.Dispatch<React.SetStateAction<API_Testimonial>>;
}) => {
  const openSnack = useSnack();
  const { profileDetails, visitedProfileDetails, setVisitedProfileDetails } =
    useStore((state) => ({
      profileDetails: state.profileDetails,
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    }));

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "Delete Testimonial?",
    confirmDelete: false,
  });

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Testimonial?",
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  const handleDeleteClick = (testimonial: API_Testimonial) => {
    //call delete api
    handleOpenDialog();
    setWarningBody({
      id: testimonial.id,
      warningTitle: `Delete Testimonial "${testimonial.name}"?`,
      confirmDelete: false,
    });
  };

  const handleDelete = (id: number) => {
    //call delete api
    const callDeleteTestimonial = async () => {
      const res = await deleteTestimonial(id);
      if (typeof res === "string" && res.length >= 1) {
        //if delete success, it will return ""
        openSnack(res, false);
      } else {
        openSnack(`Delete Testimonial successfully!`, true);
        const filteredTestimonial = visitedProfileDetails.testimonials?.filter(
          (testimonial) => testimonial.id !== id
        );
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          testimonials: filteredTestimonial,
        });
      }
    };

    callDeleteTestimonial();
  };

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  const handleVisibilityChange = async (testimonial: API_Testimonial) => {
    const res = await updateTestimonial({
      id: testimonial.id,
      is_published: !testimonial.is_published,
    });

    if (typeof res !== "string") {
      const filteredTestimonial = visitedProfileDetails.testimonials!.map(
        (testimonial) => {
          if (testimonial.id === res.id) {
            return res;
          } else {
            return testimonial;
          }
        }
      );
      setVisitedProfileDetails({
        ...visitedProfileDetails,
        testimonials: filteredTestimonial,
      });
      openSnack(
        res.is_published
          ? `${translation.published}!`
          : `${translation.unPublished}!`,
        true
      );
    }
  };

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <Card id={slugify(PROFILE_SECTION_TESTIMONIALS)} sx={{ width: "100%" }}>
        <CardHeader
          sx={{ backgroundColor: "#d0e3e2bd" }}
          title={(
            <Typography sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 500 }}>
              {translation.profileSectionTestimonials}
            </Typography>)
          }
          action={
            <IconButton
              aria-label="New"
              onClick={() => {
                setType(slugify(PROFILE_SECTION_TESTIMONIALS));
                setIsModalOpen(true);
              }}
            >
              {!isOwner && <AddIcon />}
            </IconButton>
          }
        />
        <CardContent>
          <Box>
            {!!visitedProfileDetails.testimonials?.length ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  flexDirection: "column",
                  maxHeight: 500,
                  overflow: "auto",
                }}
              >
                {visitedProfileDetails.testimonials.map(
                  (testimonial: API_Testimonial) => {
                    const isShow =
                      isOwner ||
                      testimonial.is_published ||
                      testimonial.author.id === profileDetails.id;
                    if (!isShow) {
                      return;
                    }

                    return (
                      <Box
                        key={testimonial.id}
                        sx={{
                          position: "relative",
                          opacity: testimonial.is_published ? 1.0 : 0.6,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setIsModalOpen(true);
                            setType(slugify(PROFILE_SECTION_TESTIMONIALS));
                            setSelectedTestimonial(testimonial);
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 40,
                          }}
                        >
                          {profileDetails.id === testimonial.author.id && (
                            <EditIcon
                              sx={{
                                cursor: "pointer",
                                color: "txt.secondary",
                                transition: "all 100ms ease-in-out",
                                "&:hover": {
                                  color: "primary.main",
                                  transition: "all 100ms ease-in-out",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleDeleteClick(testimonial);
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                          }}
                        >
                          {profileDetails.id === testimonial.author.id && (
                            <DeleteIcon
                              sx={{
                                cursor: "pointer",
                                color: "txt.secondary",
                                transition: "all 100ms ease-in-out",
                                "&:hover": {
                                  color: "primary.main",
                                  transition: "all 100ms ease-in-out",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                        {/* Visibility */}
                        {isOwner && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={testimonial.is_published}
                                  onChange={() => {
                                    handleVisibilityChange(testimonial);
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label={
                                testimonial.is_published
                                  ? translation.visible
                                  : translation.hidden
                              }
                            />
                          </Box>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 0.5,
                              alignItems: "center",
                            }}
                          >
                            <ProfileImage
                              boxSx={{
                                height: "3.5vw",
                                width: "3.5vw",
                                borderRadius: "50%",
                                overflow: "hidden",
                              }}
                              image={visitedProfileDetails.image}
                              imageFallbackChar={
                                visitedProfileDetails.preferred_name
                                  ? visitedProfileDetails.preferred_name
                                      .charAt(0)
                                      .toUpperCase()
                                  : ""
                              }
                            />
                            <Typography variant="h5">
                              {testimonial.author.preferred_name}
                            </Typography>
                          </Box>
                          <Typography variant="h6">
                            {testimonial.name}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {moment(testimonial.created_at).format(
                              "DD MMMM YYYY"
                            )}
                          </Typography>
                          <Typography variant="body1">
                            {testimonial.description}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
            ) : isOwner ? (
              translation.noTestimonials
            ) : (
              translation.noTestimonialsVisitor
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default TestimonialComponent;

import { Stack, TextField } from "@mui/material";
import Button from "components/Button/Button";
import { useEffect, useState } from "react";
import { createExperience, updateExperience } from "api/profile-api";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_Experience } from "types/profile";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const ExperienceForm = ({
  experience,
  handleClose,
}: {
  experience: API_Experience;
  handleClose: () => void;
}) => {
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );

  const openSnack = useSnack();

  const [formData, setFormData] = useState<API_Experience>(experience);
  const [isCreate, setIsCreate] = useState<boolean>(experience.id === -1);

  useEffect(() => {
    setFormData(experience);
    setIsCreate(experience.id === -1);
  }, [experience]);

  const handleSubmit = async () => {
    //Register auth account
    const response: API_Experience | string = isCreate
      ? await createExperience(formData)
      : await updateExperience(formData);
    if (typeof response === "string") {
      openSnack(response, false);
    } else {
      if (isCreate) {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          experiences: [response, ...visitedProfileDetails.experiences!],
        });
      } else {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          experiences: visitedProfileDetails.experiences?.map(
            (exp: API_Experience) => {
              return exp.id === experience.id ? response : exp;
            }
          ),
        });
      }
      openSnack(
        isCreate ? translation.createSuccess : translation.updateSuccess,
        true
      );
      handleClose();
    }
  };
  // console.log("formData: ", formData);
  return (
    <Stack
      component="form"
      sx={{ margin: "auto", my: "16px", alignItems: "center" }}
      spacing={1}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2} direction="column">
        <TextField
          fullWidth
          required
          label={translation.title}
          value={formData.name}
          onChange={(event) => {
            setFormData((prev: API_Experience) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />
        <TextField
          multiline
          fullWidth
          rows={4}
          required
          label={translation.description}
          defaultValue={formData.description}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              description: event.target.value,
            }));
          }}
        />
        <Stack spacing={1} direction="row">
          <StartAtDateField
            formData={formData}
            setFormData={setFormData}
            label="Start Date"
          />
          <EndAtDateField
            formData={formData}
            setFormData={setFormData}
            label="End Date (Present if blank)"
          />
        </Stack>
      </Stack>

      <Button
        buttonText={
          isCreate
            ? translation.submit || "Submit"
            : translation.update || "Update"
        }
        style={{ marginTop: "20px" }}
        onClick={handleSubmit}
        // disabled={disabledCE}
      />
    </Stack>
  );
};

const StartAtDateField = ({
  formData,
  setFormData,
  label,
}: {
  formData: API_Experience;
  setFormData: React.Dispatch<React.SetStateAction<API_Experience>>;
  label: string;
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        maxDate={moment(today)}
        label={label}
        inputFormat="YYYY-MM-DD"
        value={formData.start_at}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            setFormData((prev) => ({
              ...prev,
              start_at: newValue.format("YYYY-MM-DD").toString(),
            }));
          }
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

const EndAtDateField = ({
  formData,
  setFormData,
  label,
}: {
  formData: API_Experience;
  setFormData: React.Dispatch<React.SetStateAction<API_Experience>>;
  label: string;
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        maxDate={moment(today)}
        label={label}
        inputFormat="YYYY-MM-DD"
        value={formData.end_at ? formData.end_at : null}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            // TODO: allow FE to remove end date
            setFormData((prev) => ({
              ...prev,
              end_at: newValue.format("YYYY-MM-DD").toString(),
            }));
          }
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default ExperienceForm;

import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ChildContainer } from "containers/WelcomeBackPage/components/ParentDashboardComponents/MyChildrenSection";
import useStore from "store/store";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ANNOUNCEMENTS,
  CURLANG,
  DOCUMENTS,
  NOTICE_PAGE,
  PARENT_NOTICE_PAGE,
} from "constants/url";
import Drawer from "containers/AnnouncementsPage/Drawer";
import { getAllSchool } from "api/school-api";
import { API_School } from "types/school";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";

const NoticesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const {
    childrenInSession,
    currLanguage,
    profileDetails,
    schools,
    setSchools,
  } = useStore((state) => ({
    childrenInSession: state.childrenInSession,
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    schools: state.schools,
    setSchools: state.setSchools,
  }));
  const isParentView = profileDetails.role === "PR";

  const { childID, schoolId } = useParams();
  const [currSchool, setCurrSchool] = useState<API_School>();

  useEffect(() => {
    if (profileDetails.role !== "WAD") return;

    const getAllSchools = async () => {
      const schoolList = await getAllSchool();
      setSchools(schoolList);

      if (
        schoolList.length > 0 &&
        !schoolList.map((school) => school.id).includes(Number(schoolId))
      ) {
        setCurrSchool(schoolList[0]);
        navigate(
          `${CURLANG(currLanguage)}/${NOTICE_PAGE(
            schoolList[0].id.toString(),
            ANNOUNCEMENTS
          )}`
        );
      } else {
        setCurrSchool(
          schoolList.find((school) => school.id === Number(schoolId))
        );
      }
    };

    getAllSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails.role, schoolId]);

  // Parent side bar
  const handleProfileClick = (childId: number) => {
    let navigateTo;
    if (path.includes(ANNOUNCEMENTS)) {
      navigateTo = `${CURLANG(currLanguage)}/${PARENT_NOTICE_PAGE(
        childId,
        ANNOUNCEMENTS
      )}`;
    } else if (path.includes(DOCUMENTS)) {
      navigateTo = `${CURLANG(currLanguage)}/${PARENT_NOTICE_PAGE(
        childId,
        DOCUMENTS
      )}`;
    } else {
      navigateTo = `${CURLANG(currLanguage)}/${PARENT_NOTICE_PAGE(
        childId,
        ANNOUNCEMENTS
      )}`;
    }
    navigate(navigateTo);
  };

  useEffect(() => {
    if (profileDetails.role === "CH") {
      return;
    }
    if (isParentView) {
      if (!childID && childrenInSession.length > 0) {
        navigate(
          `${CURLANG(currLanguage)}/${PARENT_NOTICE_PAGE(
            childrenInSession[0].id,
            ANNOUNCEMENTS
          )}`
        );
      }
    } else {
      if (!schoolId) {
        let latestSchoolId = -1;
        if (profileDetails.school) {
          latestSchoolId = profileDetails.school.id;
        } else if (schools.length > 0) {
          latestSchoolId = schools[0].id;
        }
        navigate(
          `${CURLANG(currLanguage)}/${NOTICE_PAGE(
            latestSchoolId,
            ANNOUNCEMENTS
          )}`
        );
      }
    }
    //eslint-disable-next-line
  }, []);

  const [notFound, setNotFound] = useState<boolean>(false);
  useEffect(() => {
    if (schoolId === undefined || profileDetails.id === -1) return;

    if (
      Number(schoolId) !== Number(profileDetails.school?.id) &&
      profileDetails.role !== "WAD"
    ) {
      setNotFound(true);
    } else {
      setNotFound(false);
    }
  }, [
    schoolId,
    profileDetails.school?.id,
    profileDetails.role,
    profileDetails.id,
  ]);
  return (
    <Box sx={{ display: "flex", flex: "1 1 0" }}>
      {isParentView ? (
        <Box
          sx={{
            maxWidth: 100,
            backgroundColor: "rgba(240, 240, 240, 0.5)",
            height: "calc(100vh - 64px)",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: 1,
              mb: 1,
            }}
          >
            {childrenInSession.map((child, index) => (
              <ChildContainer
                key={index}
                child={child}
                selectedChildId={Number(childID)}
                onClick={() => {
                  handleProfileClick(child.id);
                }}
                width={30}
                height={30}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <>
          {profileDetails.role === "WAD" && currSchool !== undefined && (
            <Drawer schools={schools} currSchool={currSchool} />
          )}
        </>
      )}
      {notFound ? <NotFoundPage /> : <Outlet />}
    </Box>
  );
};

export default NoticesPage;

import React, { useEffect, useState } from "react";

import { ListSubheader, SxProps } from "@mui/material";

import { ProjectCategory } from "types/project";
import Image from "components/Image/Image";
import { getProjectCategories } from "api/project-api";
import useStore from "store/store";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { unSlugify } from "helper/slugify";

const ModuleFilterButton = ({
  selectedModuleSlugs,
  setSelectedModuleSlugs,
  sx = {},
}: {
  selectedModuleSlugs: string[];
  setSelectedModuleSlugs: React.Dispatch<React.SetStateAction<string[]>>;
  sx?: SxProps;
}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [projectCategories, setProjectCategories] = useState<ProjectCategory[]>(
    []
  );
  const [moduleCategorySlugs, setModuleCategorySlugs] = useState<string[]>([]);
  useEffect(() => {
    const populateProjectCategories = async () => {
      const res = await getProjectCategories(currLanguage);
      const moduleCategorySlugs = res
        .map((cat) => {
          return cat.modules.map((module) => module.slug);
        })
        .flat();
      setSelectedModuleSlugs(moduleCategorySlugs);
      setModuleCategorySlugs(moduleCategorySlugs);
      setProjectCategories(res);
    };
    populateProjectCategories();
  }, [currLanguage]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedModuleSlugs>
  ) => {
    // console.log("event:", event);
    if (event.target.value.includes("all") as boolean) {
      if (selectedModuleSlugs.length === moduleCategorySlugs.length) {
        setSelectedModuleSlugs([]);
      } else {
        setSelectedModuleSlugs(moduleCategorySlugs);
      }
    } else {
      setSelectedModuleSlugs(event.target.value as unknown as string[]);
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
  };
  return (
    <FormControl
      sx={{
        display: "flex",

        ...sx,
      }}
      size="small"
    >
      <InputLabel id="module-selector">Module</InputLabel>
      <Select
        labelId="module-selector"
        label="Module"
        multiple
        value={selectedModuleSlugs}
        onChange={handleChange}
        input={<OutlinedInput label="Module" />}
        renderValue={(selected) =>
          selectedModuleSlugs.length === moduleCategorySlugs.length
            ? "All Modules"
            : selectedModuleSlugs.length === 0
            ? "No Module"
            : selected.map((select) => unSlugify(select)).join(", ")
        }
        MenuProps={MenuProps}
      >
        <MenuItem value="all">
          <ListItemText primary="Select All" />
          <Checkbox
            checked={selectedModuleSlugs.length === moduleCategorySlugs.length}
          />
        </MenuItem>
        {projectCategories.map((projectCategory) => [
          <ListSubheader
            sx={{ ml: 0, paddingLeft: "8px" }}
            key={`${projectCategory.slug}-sub-header`}
            disableGutters
          >
            {projectCategory.name}
          </ListSubheader>,
          projectCategory.modules.map((module) => {
            return (
              <MenuItem key={module.slug} value={module.slug}>
                <Image
                  src={module.image}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                  alt={module.name}
                />
                <ListItemText primary={module.name} />
                <Checkbox
                  checked={selectedModuleSlugs.indexOf(module.slug) > -1}
                />
              </MenuItem>
            );
          }),
        ])}
      </Select>
    </FormControl>
  );
};

export default ModuleFilterButton;

import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Divider, Popover, Typography, useTheme } from "@mui/material";

import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";

import useStore from "store/store";
import { ASSESSMENT_PLAN_PAGE, CURLANG, LESSON_ACTIVITY_PAGE } from "constants/url";
import { translation } from "constants/translation";
import {
  API_Lesson_Learning_Moment,
  API_Project_Lesson,
  API_Project_Lesson_Activity,
} from "types/project";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { LITTLE_LEARNERS } from "containers/CalendarPage/constants/constants";
import { getIsActivityLearningMomentDone } from "api/lesson-activity-api";

import Image from "components/Image/Image";

import LearningMomentDoneImage from "images/learning-moment-done.png";
import LearningMomentNotDoneImage from "images/learning-moment-not-done.png";

type Props = {
  assessment?: boolean;
  currLessonIndex: number;
  handleClose?: (event: React.MouseEvent<HTMLElement>) => void;
}

const FullLessonActivityList = ({
  assessment,
  currLessonIndex,
  handleClose,
}: Props) => {
  const { lessonID, activityID, assessmentID } = useParams();
  const { projectSlug, currentLessons, isProjectLoading } = useStore(
    (state) => ({
      projectSlug: state.currentProject.slug,
      currentLessons: state.currentLessons,
      isProjectLoading: state.isProjectLoading,
    })
  );

  const [isOpenLesson, setIsOpenLesson] = useState<boolean[]>(
    currentLessons.lessons.map((lesson) => {
      try {
        return lesson.id === currentLessons.lessons[currLessonIndex].id;
      } catch {
        return false;
      }
    })
  );
  const [tracker, setTracker] = useState<number>(0);

  const currActivityIndex = isProjectLoading
    ? -1
    : currentLessons.lessons.length > 0
    ? currentLessons.lessons[
        currLessonIndex !== -1 ? currLessonIndex : 0
      ].activities.findIndex(
        (currentActivity) => currentActivity.id.toString() === activityID
      )
    : -1;

  const currentLessonRef = useRef<HTMLDivElement>();
  const currentActivityRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (currentLessonRef && currentLessonRef.current) {
      if (currentActivityRef && currentActivityRef.current) {
        currentActivityRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      } else {
        currentLessonRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    }
  }, [lessonID, activityID, assessmentID]);

  return (
    <>
      {isProjectLoading ? (
        <LoadingIndicator />
      ) : (
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 360,
            height: "100%",
            overflowY: "auto",
            maxHeight: "180px",
            padding: { xs: "0px 8px", lg: "0px 8px 0px 0px" },
            gap: 0.5,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {currentLessons.lessons.map((lesson, lessonIndex) => {
            const handleExpandClick = (
              e: React.MouseEvent<SVGSVGElement, MouseEvent>
            ) => {
              e.stopPropagation();
              setTracker((prev) => prev + 1);
              const openLessonTemp = isOpenLesson;
              openLessonTemp[lessonIndex] = !openLessonTemp[lessonIndex];
              setIsOpenLesson(openLessonTemp);
            };

            let lessonLearningMomentList: API_Lesson_Learning_Moment[] = [];
            lesson.activities.forEach((activity) => {
              activity.instructions.forEach((instruction) => {
                if (
                  instruction.learning_moment &&
                  instruction.learning_moment !== null
                ) {
                  lessonLearningMomentList = [
                    ...lessonLearningMomentList,
                    instruction.learning_moment,
                  ];
                }
              });
            });
            return (
              <LessonRowComponent
                assessment={!!assessment}
                key={`list-lesson-id-${lesson.id}`}
                projectSlug={projectSlug}
                lesson={lesson}
                lessonID={lessonID ?? assessmentID}
                lessonIndex={lessonIndex}
                currLessonIndex={currLessonIndex}
                isOpenLesson={isOpenLesson}
                currentLessonRef={currentLessonRef}
                activityID={activityID}
                currActivityIndex={currActivityIndex}
                currentActivityRef={currentActivityRef}
                lessonLearningMomentList={lessonLearningMomentList}
                handleExpandClick={handleExpandClick}
                handleClose={handleClose}
              />
            );
          })}
        </List>
      )}
    </>
  );
};

const LessonRowComponent = ({
  assessment,
  projectSlug,
  lesson,
  lessonID,
  lessonIndex,
  currentLessonRef,
  lessonLearningMomentList,
  handleExpandClick,
  handleClose,
}: {
  assessment: boolean;
  projectSlug: string;
  lesson: API_Project_Lesson;
  lessonID: string | undefined;
  lessonIndex: number;
  currLessonIndex: number;
  isOpenLesson: boolean[];
  currentLessonRef: React.MutableRefObject<HTMLDivElement | undefined>;
  activityID: string | undefined;
  currActivityIndex: number;
  currentActivityRef: React.MutableRefObject<HTMLDivElement | undefined>;
  lessonLearningMomentList: API_Lesson_Learning_Moment[];
  handleExpandClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  handleClose?: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  const navigate = useNavigate();
  const { currLanguage, currScheduleObj, isProjectLoading } = useStore((state) => state);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = !!anchorEl;

  const isLearningMomentDone =
    lessonLearningMomentList.length === 0
      ? true
      : currScheduleObj.learning_moments.length > 0
      ? lesson.module_category === LITTLE_LEARNERS
        ? lessonLearningMomentList.every((learning_moment) =>
            currScheduleObj.learning_moments.includes(learning_moment.id!)
          )
        : lessonLearningMomentList.some((learning_moment) =>
            currScheduleObj.learning_moments.includes(learning_moment.id!)
          )
      : false;

  const isLessonDone = () => {
    if (isProjectLoading) {
      return false;
    }
    const lessonActivityIdList = lesson.activities.map(
      (activity) => activity.id
    );
    return currScheduleObj.activities.length > 0
      ? lesson.module_category === LITTLE_LEARNERS
        ? lessonActivityIdList.every((activityId) =>
            currScheduleObj.activities.includes(activityId)
          )
        : lessonActivityIdList.some((activityId) =>
            currScheduleObj.activities.includes(activityId)
          )
      : false;
  };
  // console.log("currScheduleObj.activities: ", currScheduleObj.activities);
  // console.log("lessonActivityIdList: ", lessonActivityIdList);
  // console.log("isLessonDone: ", isLessonDone());
  // console.log("isLearningMomentDone: ", lesson.id, isLearningMomentDone);
  return (
    <>
      <Popover
        id={`mouse-over-popover-${lessonIndex}`}
        sx={{
          display: { xs: "none", lg: "flex" },
          pointerEvents: "none",
          borderRadius: 10,
        }}
        open={openPopOver}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopOverContent lesson={lesson} lessonIndex={lessonIndex} />
      </Popover>
      <Box
        ref={lesson.id.toString() === lessonID ? currentLessonRef : null}
        aria-owns={
          openPopOver ? `mouse-over-popover-${lessonIndex}` : undefined
        }
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ListItemButton
          sx={{
            p: "4px 10px",
            borderLeft:
              lesson.id.toString() === lessonID
                ? "2px solid var(--primary-main)"
                : "",
          }}
          onClick={(event: any) => {
            event.stopPropagation();
            const activitySlug =
              lesson.activities.length > 0 ? lesson.activities[0].id : "";
            
            const pathEvaluationMethod = assessment ? ASSESSMENT_PLAN_PAGE : LESSON_ACTIVITY_PAGE;
            navigate(
              `${CURLANG(currLanguage)}/${pathEvaluationMethod(
                projectSlug,
                lesson.id.toString()
              )}/${activitySlug === "" ? "" : "activity/"}${activitySlug}`
            );

            handleClose && handleClose(event);
          }}
        >
          <ListItemIcon>
            {isLessonDone() ? <CircleCheckedFilled /> : <CircleUnchecked />}
          </ListItemIcon>
          <ListItemText
            primary={
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography>
                  {translation.formatString(assessment ? translation.assessmentPlanNo : translation.lessonNo, {
                    number: lessonIndex + 1,
                  })}
                </Typography>
                {!assessment && lessonLearningMomentList.length > 0 && (
                  <Image
                    alt={"icon"}
                    style={{ width: 20, height: 20 }}
                    src={
                      isLessonDone() && isLearningMomentDone
                        ? LearningMomentDoneImage
                        : isLessonDone() && !isLearningMomentDone
                        ? LearningMomentNotDoneImage
                        : LearningMomentNotDoneImage
                    }
                  />
                )}
              </Box>
            }
          />
        </ListItemButton>
      </Box>
    </>
  );
};

const PopOverContent = ({
  lesson,
  lessonIndex,
}: {
  lesson: API_Project_Lesson;
  lessonIndex: number;
}) => {
  let learningMomentList: API_Lesson_Learning_Moment[] = [];
  lesson.activities.forEach((activity) => {
    activity.instructions.forEach((instruction) => {
      if (instruction.learning_moment) {
        learningMomentList = [
          ...learningMomentList,
          instruction.learning_moment,
        ];
      }
    });
  });

  const isActivityCompulsory =
    lesson.activities.filter((activity) => activity.is_compulsory === true)
      .length > 1;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        p: 2,
        borderRadius: 10,
        maxWidth: 400,
        minWidth: 300,
      }}
    >
      <Box sx={{ display: "flex", gap: 0, flexDirection: "column" }}>
        <Typography variant="h6">
          {`${translation.formatString(lesson.assessment ? translation.assessmentPlanNo : translation.lessonNo, {
            number: lessonIndex + 1,
          })}: ${lesson.name}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        {lesson.activities.map((activity, index) => {
          return (
            <Fragment key={`individual-activity-pop-over-${activity.id}`}>
              <IndividualActivityPopover
                lesson={lesson}
                activity={activity}
                activityIndex={index}
                isActivityCompulsory={isActivityCompulsory}
              />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

const IndividualActivityPopover = ({
  lesson,
  activity,
  activityIndex,
  isActivityCompulsory,
}: {
  lesson: API_Project_Lesson;
  activity: API_Project_Lesson_Activity;
  activityIndex: number;
  isActivityCompulsory: boolean;
}) => {
  const theme = useTheme();
  const { currLanguage, currGroup, currScheduleObj } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currGroup: state.currGroup,
    currScheduleObj: state.currScheduleObj,
  }));
  const [isActivityDone, setIsActivityDone] = useState<boolean>(
    currScheduleObj.activities.includes(activity.id)
  );
  const [isActivityLearningMomentDone, setIsActivityLearningMomentDone] =
    useState<boolean>(false);
  const populateIsActivityLearningMomentDone = async () => {
    const isActivityLearningMomentDoneRes =
      await getIsActivityLearningMomentDone(
        currLanguage,
        localStorage.getItem("access"),
        activity.id,
        currGroup.id,
        lesson.project_id
      );
    if (typeof isActivityLearningMomentDoneRes === "string") {
      console.log("error: ", isActivityLearningMomentDoneRes);
    } else {
      setIsActivityLearningMomentDone(isActivityLearningMomentDoneRes);
    }
  };

  let isLearningMomentExist = false;
  activity.instructions.every((instruction) => {
    if (instruction.learning_moment) {
      isLearningMomentExist = true;
    }
    return !isLearningMomentExist;
  });

  useEffect(() => {
    populateIsActivityLearningMomentDone();
  }, []);

  return (
    <>
      <Box
        key={`activity-pop-over-${activity.name}`}
        sx={{
          display:
            isLearningMomentExist || lesson.activities.length > 1
              ? "flex"
              : "none",
          alignItems: "center",
          gap: 1,
          p: 1,
          border: "1px solid var(--primary-main)",
          borderRadius: "10px",
        }}
      >
        {isActivityDone ? (
          <CircleCheckedFilled color={"primary"} />
        ) : (
          <CircleUnchecked color={"primary"} />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Typography>
            {lesson.activities.length > 1
              ? lesson.module_category === LITTLE_LEARNERS ||
                isActivityCompulsory
                ? `${translation.formatString(translation.activityNo, {
                    number: activityIndex + 1,
                  })}: `
                : `${translation.formatString(translation.optionNo, {
                    number: activityIndex + 1,
                  })}: `
              : ""}
            {lesson.activities.length > 1 ? activity.name : ""}
          </Typography>

          {activity.instructions.map((instruction) => {
            if (instruction.learning_moment) {
              return (
                <Fragment key={`instruction-id-${instruction.id}`}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.2,
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
                    >
                      {!lesson.assessment && (
                        <Image
                          alt={"icon"}
                          style={{ width: 20, height: 20 }}
                          src={
                            isActivityDone && isActivityLearningMomentDone
                              ? LearningMomentDoneImage
                              : isActivityDone && !isActivityLearningMomentDone
                              ? LearningMomentNotDoneImage
                              : LearningMomentNotDoneImage
                          }
                        />
                      )}
                      <Typography>
                        {lesson.assessment ? translation.evaluationMoment : translation.learningMoment}
                      </Typography>
                    </Box>
                    {!lesson.assessment && (
                      <Typography sx={{ fontSize: 14 }}>
                        {`${instruction.learning_moment.photo_instruction}`}
                      </Typography>
                    )}
                  </Box>
                </Fragment>
              );
            }
            return null;
          })}
        </Box>
      </Box>

      {lesson.activities.length !== activityIndex + 1 &&
        lesson.module_category === LITTLE_LEARNERS && (
          <Box
            sx={{
              ml: 2.5,
              // mt: -1.5,
              width: "4px",
              height: "20px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Divider
              orientation="vertical"
              color="#98C4C5"
              sx={{
                width: "100%",
              }}
            />
          </Box>
        )}
    </>
  );
};


export default FullLessonActivityList;

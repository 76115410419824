import React, { useEffect, useState } from "react";
import Image from "components/Image/Image";
import useStore from "store/store";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { API_Child } from "types/profile";

const ChildFilterButton = ({
  selectedClassSlugs,
  selectedChildIds,
  setSelectedChildIds,
}: {
  selectedClassSlugs: string[];
  selectedChildIds: number[];
  setSelectedChildIds: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { currLanguage, groupList } = useStore((state) => ({
    currLanguage: state.currLanguage,
    groupList: state.groupList,
  }));
  const [childListInfo, setChildListInfo] = useState<API_Child[]>([]);
  const [childListInfoPreferredName, setChildListInfoPreferredName] = useState<
    string[]
  >([]);

  useEffect(() => {
    const populateChildListInfo = async () => {
      let childListInfo = groupList
        .filter((group) => selectedClassSlugs.includes(group.slug))
        .map((group) => group.children)
        .flat();

      const seen = new Set();
      childListInfo = childListInfo.filter((child) => {
        const id = child.id;
        return seen.has(id) ? false : seen.add(id);
      });

      const childListInfoIds = childListInfo.map((child) => {
        return child.id;
      });
      const childListInfoPreferredName = childListInfo.map((child) => {
        return child.preferred_name;
      });

      setSelectedChildIds(childListInfoIds);
      setChildListInfoPreferredName(childListInfoPreferredName);
      setChildListInfo(childListInfo);
    };
    populateChildListInfo();
  }, [selectedClassSlugs]);

  const handleChange = (event: SelectChangeEvent<typeof selectedChildIds>) => {
    console.log("event.target.value:", event.target.value);
    // @ts-ignore
    if (event.target.value.includes(-99) as boolean) {
      if (selectedChildIds.length === childListInfoPreferredName.length) {
        setSelectedChildIds([]);
      } else {
        setSelectedChildIds(childListInfo.map((child) => child.id));
      }
    } else {
      setSelectedChildIds(event.target.value as unknown as number[]);
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return childListInfo.length > 0 ? (
    <FormControl
      sx={{
        flex: 1,
        minWidth: 100,
      }}
      size="small"
    >
      <InputLabel id="child-selector">Child</InputLabel>
      <Select
        labelId="child-selector"
        label="Child"
        multiple
        value={selectedChildIds}
        onChange={handleChange}
        input={<OutlinedInput label="Child" />}
        renderValue={(selected) =>
          selectedChildIds.length === childListInfoPreferredName.length
            ? "All Children"
            : selectedChildIds.length === 0
            ? "No Child"
            : childListInfo
                .filter((child) => selected.includes(child.id))
                .map((child) => child.preferred_name)
                .join(", ")
        }
        MenuProps={MenuProps}
      >
        <MenuItem value={-99}>
          <ListItemText primary="Select All" />
          <Checkbox
            checked={
              selectedChildIds.length === childListInfoPreferredName.length
            }
          />
        </MenuItem>
        {childListInfo.map((childInfo) => {
          return (
            <MenuItem
              key={`child-info-id-${childInfo.id}`}
              value={childInfo.id}
            >
              <Image
                src={childInfo.image}
                style={{ width: 20, height: 20, marginRight: 8 }}
                alt={childInfo.preferred_name}
              />
              <ListItemText primary={childInfo.preferred_name} />
              <Checkbox checked={selectedChildIds.indexOf(childInfo.id) > -1} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : null;
};

export default ChildFilterButton;

import { Box, SxProps, Typography } from "@mui/material";
import moment from "moment";
import { DATE_FORMAT } from "containers/AnnouncementsPage/AnnouncementComponents";
import { StyleSheet, Text } from "@react-pdf/renderer";

export const photoBookImageStyle = {
  position: "relative",
  display: "flex",
  width: "100%",
  maxWidth: 500,
  backgroundColor: "#F4F4F4",
  zIndex: -1,
};

export const CaptionComponent = ({
  top,
  left,
  width,
  caption,
  clampLine,
  height,
  fontSize = 13,
  textAlign = "center",
}: {
  top: number;
  left: number;
  width: number;
  caption: string;
  clampLine: number;
  height?: number;
  fontSize?: number;
  textAlign?: string;
}) => {
  let boxStyle: SxProps = {
    position: "absolute",
    top: top,
    left: left,
    width: width,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: textAlign,
    zIndex: 2,
  };

  if (height) {
    boxStyle = { ...boxStyle, height: height };
  }

  return (
    <Box sx={boxStyle}>
      <Typography
        sx={{
          fontSize: fontSize,
          textAlign: textAlign,
          display: "-webkit-box",
          WebkitLineClamp: `${clampLine}`,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {caption}
      </Typography>
    </Box>
  );
};

export const CaptionPDFComponent = ({
  top,
  left,
  width,
  caption,
  clampLine,
  height,
  fontSize = 13 * 1.6,
  textAlign = "center",
}: {
  top: number;
  left: number;
  width: number;
  caption: string;
  clampLine: number;
  height?: number;
  fontSize?: number;
  textAlign?: string;
}) => {
  const templateStyles = StyleSheet.create({
    text: {
      position: "absolute",
      top: top,
      left: left,
      width: width,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: textAlign as any,
      height: height ? height : "auto",
    },
  });

  return (
    <Text
      style={[
        templateStyles.text,
        {
          fontSize: fontSize * 0.85,
          textOverflow: "ellipsis",
          maxLines: clampLine,
        },
      ]}
    >
      {caption}
    </Text>
  );
};

export const DateComponent = ({ created_at }: { created_at: string }) => {
  return (
    <Typography
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        fontWeight: 500,
      }}
    >
      {moment(created_at).format(DATE_FORMAT)}
    </Typography>
  );
};

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
//icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

//mui datagrid
import {
  GridColumns,
  DataGrid,
  GridRowId,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridRowModel,
  GridColumnVisibilityModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { TICKET_STATUS } from "./constants/gridColumn";
import { DATA_GRID_STYLE } from "./constants/styling";
import {
  deleteTicket,
  getAllTickets,
  updateTicket,
} from "api/ticketingSystem-api";
import { API_Ticket } from "types/ticketingSystem";
import moment from "moment";
import TicketDetailModal from "containers/TicketingSystemPage/TicketDetailModal";
import { Typography } from "@mui/material";
import { ID_COLUMN } from "./constants/gridColumnHelper";

const TicketManagePage = () => {
  const openSnack = useSnack();
  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));
  const [adminList, setTicketList] = useState<API_Ticket[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [rows, setRows] = React.useState(adminList);

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "",
    confirmDelete: false,
  });
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [ticketId, setTicketId] = useState<number>(-1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setTicketId(-1);
    setOpenModal(false);
  };
  const handleOpenModal = (ticketId_: number) => {
    setTicketId(ticketId_);
    setOpenModal(true);
  };

  //handling clicks
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  // const handleCellEditCommit = (params: any) => {
  //   console.log("params: ", params);
  // };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = (id: GridRowId) => {
    //call delete api
    const callDeleteTicket = async () => {
      const res = await deleteTicket(String(id));
      if (typeof res === "string" && res.length >= 1) {
        openSnack(res, false);
      } else {
        openSnack(`Delete Ticket successfully!`, true);
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    callDeleteTicket();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    //double function loop here to prevent it's initialiated once passing
    //call delete api
    const name = rows.filter((row) => row.id === id)[0]?.name;
    handleOpenDialog();
    setWarningBody({
      id: Number(id),
      warningTitle: `Delete Ticket "${name}"?`,
      confirmDelete: false,
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    // console.log("body: ", body);
    const updateResponse: API_Ticket | string = await updateTicket(
      newRow as API_Ticket
    );
    // console.log("Update res: ", updateResponse);
    if (typeof updateResponse === "string") {
      openSnack(updateResponse, false);
      return updateResponse;
    } else {
      openSnack("Ticket is updated!", true);
    }

    return newRow;
  };

  const columns: GridColumns = [
    {
      ...ID_COLUMN,
    },
    {
      field: "created_at",
      type: "date",
      headerName: "Created At",
      width: 120,
      editable: false,
      valueFormatter: (params: any) =>
        moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "name",
      headerName: "Subject",
      width: 250,
      editable: false,
      renderCell: (params) => {
        return <Typography noWrap>{params.value}</Typography>;
      },
    },
    {
      field: "created_by_name",
      headerName: "Created By",
      width: 200,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: (params) => TICKET_STATUS,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return (
          TICKET_STATUS?.find((status) => status?.value === value)?.label ?? ""
        );
      },
      width: 180,
      editable: true,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 180,
      editable: true,
    },
    {
      field: "id",
      headerName: "Details",
      width: 80,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            sx={{ marginY: 1 }}
            onClick={() => handleOpenModal(params.value)}
          >
            View
          </Button>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  //useEffect
  useEffect(() => {
    const populateData = async () => {
      const ticketListRes = await getAllTickets(currLanguage);
      setTicketList(ticketListRes);
      setRows(ticketListRes);
    };

    populateData();
  }, []);

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  const [columnVisibility, setColumnVisibility] = useState<any>(() => {
    //Return {name: true, description: true, xxx:true ......}
    const finalObj = {};
    columns.forEach((column) => {
      Object.assign(finalObj, { [column.field]: true });
    });
    return finalObj;
  });

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <TicketDetailModal
        open={openModal}
        handleClose={handleCloseModal}
        ticketId={ticketId}
      />
      <Box>
        <div style={DATA_GRID_STYLE}>
          <DataGrid
            getRowHeight={() => "auto"}
            sx={{ fontSize: 18 }}
            rows={rows}
            columns={columns}
            columnVisibilityModel={columnVisibility}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onColumnVisibilityModelChange={(
              model: GridColumnVisibilityModel,
              details: GridCallbackDetails
            ) => {
              setColumnVisibility(model);
            }}
            // components={{
            //   Toolbar: EditToolbar,
            // }}
            // componentsProps={{
            //   toolbar: { setRows, setRowModesModel },
            // }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Box>
    </>
  );
};

export default TicketManagePage;

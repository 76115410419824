import React from 'react'
import { API_Group_Full } from "types/group";
import useStore from "store/store";
import {
    BLOSSOMS,
    BUDS,
    SPROUTS,
} from "constants/project-lesson-activity";
import Logo from "images/logo-2-colour.png";
import Image from "components/Image/Image";

import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import {
    Box,
    Typography,
    Avatar,
    useMediaQuery,
    useTheme,
} from "@mui/material";
const DrawerButton = ({
    group,
    index,
}: {
    group: API_Group_Full;
    index: number;
}) => {
    const ageCategories = [
        {
            name: BLOSSOMS,
            src: BlossomsIcon,
        },
        {
            name: BUDS,
            src: BudsIcon,
        },
        {
            name: SPROUTS,
            src: SproutsIcon,
        },
    ];
    const theme = useTheme();

    const isSm = useMediaQuery(theme.breakpoints.down("sm"));
    const isLg = useMediaQuery(theme.breakpoints.down("lg"));

    const { currLanguage, currGroup, setCurrGroup, } =
        useStore((state) => ({
            currLanguage: state.currLanguage,
            currGroup: state.currGroup,
            setCurrGroup: state.setCurrGroup,
        }));

    const imageSrc = ageCategories.find(
        (obj: any) => obj.name == group.communication_lvl
    )?.src;

    if (group.age == "Sproutlings" || group.communication_lvl == "Sproutlings") {
        group.age = "Sprouts";
        group.communication_lvl = "Sprouts";
    } else if (group.age == "Blooms" || group.communication_lvl == "Blooms") {
        group.age = "Blossoms";
        group.communication_lvl = "Blossoms";
    }

    return isLg ? (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
            }}
            onClick={() => {
                // navigate(`/en/my-classes/${group.slug}`);
                setCurrGroup(group);
            }}
            key={index}
        >
            <Avatar
                src={group.image ? group.image : Logo}
                sx={{
                    width: "40px",
                    height: "40px",
                    border: "4px solid",
                    borderColor: currGroup.name == group.name ? "#98C4C5" : "#F0F0F0",
                    backgroundColor:
                        currGroup.name == group.name ? "primary.light" : "var(--txt-light)",
                    display: "flex",
                }}
                imgProps={{
                    onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        event.currentTarget.onerror = null;
                        event.currentTarget.src = Logo;
                    },
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px",
                    width: "108px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: isSm ? "12px" : "14px",
                        fontWeight: currGroup.name == group.name ? "bold" : "normal",
                        color: currGroup.name == group.name ? "primary.main" : "#444",
                        flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                    }}
                >
                    {group.name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: isSm ? "10px" : "12px",
                        fontWeight: 400,
                        color: "#323031",
                        textAlign: "center",
                    }}
                >
                    The {group.communication_lvl} group
                </Typography>
            </Box>
        </Box>
    ) : (
        <Box
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
                cursor: currGroup.name == group.name ? "default" : "pointer",
                textDecoration: "none",
                color: "#444",
                marginTop: "12px",
            }}
            onClick={() => {
                setCurrGroup(group);
            }}
            key={index}
        >
            <Avatar
                src={group.image ? group.image : Logo}
                sx={{
                    width: "40px",
                    height: "40px",
                    border: "4px solid",
                    borderColor: currGroup.name == group.name ? "#98C4C5" : "#F0F0F0",
                    backgroundColor:
                        currGroup.name == group.name ? "primary.light" : "var(--txt-light)",
                    display: "flex",
                }}
                imgProps={{
                    onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        event.currentTarget.onerror = null;
                        event.currentTarget.src = Logo;
                    },
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: currGroup.name == group.name ? "bold" : "normal",
                        color: currGroup.name == group.name ? "primary.main" : "#444",
                        flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: "16px",
                    }}
                >
                    {group.name}
                </Typography>

                <Box sx={{ display: "flex" }}>
                    {group.communication_lvl ? (
                        <>
                            <Typography sx={{ mr: 1, fontSize: "12px" }}>
                                {group.communication_lvl}
                            </Typography>
                            <Box
                                sx={{
                                    width: "20px",
                                    height: "20px",
                                    overflow: "hidden",
                                    mr: 1,
                                }}
                            >
                                <Image
                                    src={imageSrc}
                                    alt={group.communication_lvl}
                                    objectFit="contain"
                                />
                            </Box>
                        </>
                    ) : (
                        <Typography sx={{ fontSize: "12px" }}>No Level Set!</Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};


export default DrawerButton
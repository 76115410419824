import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import {
  addOrUpdateActivityInstructions,
  createActivity,
} from "api/project-api";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  API_Activity_Instruction,
  API_Create_Activity,
} from "types/project";
import { createCaption, createGallery } from "api/gallery-api";
import { API_Caption } from "types/gallery";

import { translation } from "constants/translation";
import {
  NameField,
  DurationField,
  CompulsoryField,
  FileField,
  MaterialField,
  ImageField,
  InstructionsFieldWithDnd,
} from "./ActivityFormComponents";
import DrawerHeader from "containers/EditProjectPage/DrawerHeader";
import MaterialButton from "components/Button/MaterialButton";

type Props = {
  assessment: boolean;
  lessonID: string;
  handleClose: () => void;
};

const CreateActivityForm = ({ assessment, lessonID, handleClose }: Props) => {
  const openSnack = useSnack();

  const [captions, setCaptions] = useState<API_Caption[]>([]);
  const [image, setImage] = useState<File | string>("");
  const [files, setFiles] = useState<File[]>([]);
  const [filledRequired, setFilledRequired] = useState<boolean>(false);

  const { currLanguage, setRefreshProjAndLess } = useStore((state) => state);

  const [activity, setActivity] = useState<API_Create_Activity>({
    lesson: lessonID,
    files: [],
    name: "",
    description: "",
    duration: 0,
    instruction: "", //legacy
    remark: "",
    is_compulsory: true,
  });
  const [activityInstructions, setActivityInstructions] = useState<
    API_Activity_Instruction[]
  >([]);

  const activityRef = useRef(activity);
  activityRef.current = activity;

  useEffect(() => {
    if (activity.name !== "") {
      setFilledRequired(true);
    } else {
      setFilledRequired(false);
    }
  }, [activity]);

  const submitForm = async () => {
    setFilledRequired(false);

    const submitData: API_Create_Activity = {
      lesson: lessonID,
      files: [],
      name: activity.name,
      description: activity.description,
      duration: activity.duration,
      instruction: activity.instruction,
      material: activity.material,
      remark: activity.remark,
      is_compulsory: activity.is_compulsory,
    };

    if (image === "" || typeof image === "object") {
      submitData["image"] = image;
    }

    console.log("submitData: ", submitData);

    if (files.length > 0) {
      submitData.files = files;
    }

    const { id: activityId } = await createActivity(
      currLanguage,
      submitData,
      localStorage.getItem("access")
    );

    if (activityInstructions && activityInstructions.length > 0) {
      const activityInstructionsBody = activityInstructions.map(
        (instruction) => {
          const updatedLearningMoment = !instruction.learning_moment
            ? undefined
            : {
                ...instruction.learning_moment,
                activity: activityId,
              };

          return {
            ...instruction,
            learning_moment: updatedLearningMoment,
            activity: activityId,
          };
        }
      );

      await addOrUpdateActivityInstructions(
        currLanguage,
        activityInstructionsBody,
        localStorage.getItem("access")
      );
    }

    const gallery = await createGallery(currLanguage, {
      activity: activityId.toString(),
    });

    if (typeof gallery !== "string") {
      for (let i = 0; i < captions.length; i++) {
        if (captions[i].caption) {
          await createCaption(
            currLanguage,
            captions[i].caption,
            gallery.id.toString()
          );
        }
      }
    }
    setRefreshProjAndLess(true);
    openSnack("Activity created successfully!", true);
    handleClose();
  };

  return (
    <Stack
      component="form"
      sx={{
        width: "100%",
        mb: 8,
      }}
      spacing={3}
      noValidate
      autoComplete="off"
    >
      <DrawerHeader
        type="Activity"
        mode="Add"
        assessment={assessment}
        handleClose={handleClose}
      >
        <MaterialButton
          buttonText={translation.add_activity || "Add Activity"}
          onClick={() => filledRequired && submitForm()}
          disabled={!filledRequired}
        />
      </DrawerHeader>
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <NameField activity={activity} setActivity={setActivity} />
        <CompulsoryField activity={activity} setActivity={setActivity} />
      </Box>

      <DurationField activity={activity} setActivity={setActivity} />
      <MaterialField activity={activity} setActivity={setActivity} />
      <InstructionsFieldWithDnd
        assessment={assessment}
        activityInstructions={activityInstructions}
        setActivityInstructions={setActivityInstructions}
      />
      <FileField files={files} setFiles={setFiles} multiple />
      <ImageField image={image} setImage={setImage} />
    </Stack>
  );
};

export default CreateActivityForm;

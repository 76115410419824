
import { Box, Typography } from "@mui/material";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SideTabs from "components/SideTabs/SideTabs";
import { translation } from "constants/translation";

const RecommendationSection = () => {
  const iconStyle = {
    width: "24px",
    height: "24px",
    overflow: "hidden",
    mr: "12px",
  };

  const sideTabInfos = [
    {
      title: translation.specially,
      subtitle: translation.specialDesc,
      slug: "specially-for-you",
      icon: <AccessibilityNewIcon sx={iconStyle} />,
    },
    {
      title: translation.trending,
      subtitle: translation.trendDesc,
      slug: "trending-projects",
      icon: <TrendingUpIcon sx={iconStyle} />,
    },
    {
      title: translation.new,
      subtitle: translation.newDesc,
      slug: "new-in",
      icon: <ElectricBoltIcon sx={iconStyle} />,
    },
  ];

  return (
    <>
      <Typography sx={{ mt: "2.3rem", mb: 1 }}>
        {translation.recommendations}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {sideTabInfos.map((sideTabInfo, index) => {
          return (
            <SideTabs
              key={`sideTab${index}`}
              isReco={true}
              title={sideTabInfo.title}
              slug={sideTabInfo.slug}
              subtitle={sideTabInfo.subtitle}
              icon={sideTabInfo.icon}
            />
          );
        })}
      </Box>
    </>
  );
};

export default RecommendationSection;

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

//mui datagrid
import {
  GridColumns,
  DataGrid,
  GridRowId,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridRowModel,
  GridColumnVisibilityModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";

//School
import { getAllSchool } from "api/school-api";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";

import { DATA_GRID_STYLE } from "./constants/styling";

import {
  ACTIONS_COLUMN,
  EditToolbarPaymentRecord,
  ID_COLUMN,
} from "./constants/gridColumnHelper";
import { PaymentRecord } from "types/payment";
import {
  deletePaymentRecord,
  getPaymentRecordList,
  updatePaymentRecord,
} from "api/payment-api";
import moment from "moment";

const PaymentRecordManagePage = () => {
  const openSnack = useSnack();
  const { schools, setSchools, currLanguage } = useStore((state) => ({
    schools: state.schools,
    setSchools: state.setSchools,
    currLanguage: state.currLanguage,
  }));

  const [paymentRecordList, setPaymentRecordList] = useState<PaymentRecord[]>(
    []
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const [rows, setRows] = React.useState(paymentRecordList);

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "",
    confirmDelete: false,
  });
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  //handling clicks
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = (id: GridRowId) => {
    //call delete api
    const callDeletePaymentRecord = async () => {
      const res = await deletePaymentRecord(Number(id));
      if (typeof res === "string" && res.length >= 1) {
        //if delete success, it will return ""
        openSnack(res, false);
      } else {
        openSnack(`Delete Payment Record successfully!`, true);
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    callDeletePaymentRecord();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    //double function loop here to prevent it's initialiated once passing
    //call delete api
    const customerId = rows.filter((row) => row.id === id)[0].customer_id;
    handleOpenDialog();
    setWarningBody({
      id: Number(id),
      warningTitle: `Delete Payment Record with Customer Id: "${customerId}"?`,
      confirmDelete: false,
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updateResponse = await updatePaymentRecord({
      paymentRecordId: newRow.id,
      schoolId: newRow.school,
      customerId: newRow.customer_id,
    });

    if (typeof updateResponse === "string") {
      openSnack(updateResponse, false);
      return updateResponse;
    } else {
      openSnack("Payment Record is updated!", true);
    }
    return newRow;
  };

  const columns: GridColumns = [
    {
      ...ID_COLUMN,
    },
    {
      field: "created_at",
      headerName: "Created at",
      width: 160,
      editable: false,
      valueFormatter: (params: any) =>
        moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "updated_at",
      headerName: "Updated at",
      width: 160,
      editable: false,
      valueFormatter: (params: any) =>
        moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "school",
      headerName: "School Name",
      width: 180,
      editable: false,
      valueFormatter: (params: any) =>
        schools.filter((school) => school.id === params?.value)[0]?.name,
    },
    {
      field: "customer_id",
      headerName: "Stripe Customer ID",
      width: 250,
      editable: true,
    },
    {
      ...ACTIONS_COLUMN({
        rowModesModel,
        handleSaveClick,
        handleCancelClick,
        handleEditClick,
        handleDeleteClick,
      }),
    },
  ];
  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  //use Effect
  useEffect(() => {
    const populateData = async () => {
      const paymentRecordListRes = await getPaymentRecordList();
      setPaymentRecordList(paymentRecordListRes);
      setRows(paymentRecordListRes);
      const schoolRes = await getAllSchool();
      setSchools(schoolRes);
    };
    populateData();
  }, []);

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  const [columnVisibility, setColumnVisibility] = useState<any>(() => {
    //Return {name: true, description: true, xxx:true ......}
    const finalObj = {};
    columns.forEach((column) => {
      Object.assign(finalObj, { [column.field]: true });
    });
    return finalObj;
  });

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <Box>
        <div style={DATA_GRID_STYLE}>
          <DataGrid
            sx={{ fontSize: 18 }}
            rows={rows}
            columns={columns}
            columnVisibilityModel={columnVisibility}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onColumnVisibilityModelChange={(
              model: GridColumnVisibilityModel,
              details: GridCallbackDetails
            ) => {
              setColumnVisibility(model);
            }}
            components={{
              Toolbar: EditToolbarPaymentRecord,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Box>
    </>
  );
};

export default PaymentRecordManagePage;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, SxProps, Typography, Checkbox } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import useStore from "store/store";
import { API_Teacher_Certificate } from "types/teacher";
import { CURLANG, TEACHER_CERTIFICATE_EDIT_PAGE } from "constants/url";
import { translation } from "constants/translation";
import IndividualCertificateCompletedLearnerModal from "./IndividualCertificateCompletedLearnerModal";
import IndividualCertificateChartModal from "./IndividualCertificateChartModal";

const ManageCertificateListTable = ({
  certificateList,
  setCertificateList,
  setSelected,
}: {
  certificateList: API_Teacher_Certificate[];
  setCertificateList: React.Dispatch<
    React.SetStateAction<API_Teacher_Certificate[]>
  >;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const initialSelectedCertificate = {
    id: -1,
    name: "",
    description: "",
    slug: "",
    image: "",
    profiles: [],
    modules: [],
    created_at: "",
  };
  // Modal
  const [selectedCertificate, setSelectedCertificate] =
    useState<API_Teacher_Certificate>(initialSelectedCertificate);

  // completed learner
  const [openCompletedLearnerModal, setOpenCompletedLearnerModal] =
    useState<boolean>(false);
  const handleCompletedLearnerModalClose = () => {
    setOpenCompletedLearnerModal(false);
    setSelectedCertificate(initialSelectedCertificate);
  };
  const handleCompletedLearnerModalOpen = () => {
    setOpenCompletedLearnerModal(true);
  };

  //Chart
  const [openChartModal, setOpenChartModal] = useState<boolean>(false);
  const handleChartModalClose = () => {
    setOpenChartModal(false);
    setSelectedCertificate(initialSelectedCertificate);
  };
  const handleChartModalOpen = () => {
    setOpenChartModal(true);
  };

  const iconStyles: SxProps = {
    color: "#312F30B2",
    cursor: "pointer",
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Title",
      flex: 1,
      minWidth: 400,
      // renderCell: (params) => (
      //   <Link
      //     style={{
      //       color: "#407374",
      //     }}
      //     to={`${CURLANG(currLanguage)}/${TEACHER_MODULE_PAGE(
      //       params.row.certificate.slug
      //     )}`}
      //   >
      //     {params.value}
      //   </Link>
      // ),
    },
    {
      field: "profiles",
      headerName: "# Completed Learner",
      type: "number",
      // maxWidth: 200,
      minWidth: 150,
      renderCell: (params) => {
        // console.log("Params: ", params);
        return params.row.profiles.length === 0 ? (
          <Box>{params.row.profiles.length}</Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
              color: "primary.main",
              textDecoration: "underline",
            }}
            onClick={() => {
              setSelectedCertificate(params.row);
              handleCompletedLearnerModalOpen();
            }}
          >
            {params.row.profiles.length}
          </Box>
        );
      },
    },
    {
      field: "modules",
      headerName: "# Module Required",
      type: "number",
      // maxWidth: 200,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.modules.length;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              to={`${CURLANG(currLanguage)}/${TEACHER_CERTIFICATE_EDIT_PAGE(
                params.row.slug
              )}`}
            >
              <EditIcon fontSize="small" sx={iconStyles} />
            </Link>

            <AssessmentOutlinedIcon
              fontSize="small"
              sx={{ ...iconStyles }}
              onClick={() => {
                setSelectedCertificate(params.row);
                handleChartModalOpen();
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <IndividualCertificateChartModal
        selectedCertificate={selectedCertificate}
        open={openChartModal}
        handleClose={handleChartModalClose}
      />
      <IndividualCertificateCompletedLearnerModal
        selectedCertificate={selectedCertificate}
        open={openCompletedLearnerModal}
        handleClose={handleCompletedLearnerModalClose}
      />
      <DataGrid
        rows={certificateList}
        columns={columns}
        // columnVisibilityModel={{
        //   access: currSchool.id !== -1, //show access if school exist
        //   published: currSchool.id === -1, //show publish if school doesn't exist
        // }}
        disableColumnMenu
        // checkboxSelection
        components={{
          BaseCheckbox(props) {
            return (
              <Checkbox
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent !important",
                  },
                }}
                icon={
                  <CheckBoxOutlineBlankOutlinedIcon
                    fontSize="small"
                    sx={iconStyles}
                  />
                }
                checkedIcon={
                  <CheckBoxOutlinedIcon
                    fontSize="small"
                    sx={{ ...iconStyles, color: "black" }}
                  />
                }
                indeterminateIcon={
                  <IndeterminateCheckBoxOutlinedIcon
                    fontSize="small"
                    sx={iconStyles}
                  />
                }
                disableRipple
                {...props}
              />
            );
          },
          NoRowsOverlay: NoCertificateFound,
        }}
        loading={false}
        sx={{
          border: "none",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: 0,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        onSelectionModelChange={(ids) => {
          const selectedIds = ids.map((id) => id.toString());
          setSelected(selectedIds);
        }}
      />
    </>
  );
};

const NoCertificateFound = () => (
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "1.25rem",
      textAlign: "center",
      backgroundColor: "#FBFBFB",
      p: 2,
    }}
  >
    {translation.noCertificateFound}
  </Typography>
);

export default ManageCertificateListTable;

import React, { Fragment, useEffect } from "react";
import useStore from "store/store";

import ProtectedLink from "components/ProtectedLink/ProtectedLink";

import {
  CURLANG,
  HOME,
  MARKETPLACE,
  CALENDAR,
  CLASS,
  PROJECT_MANAGE_PAGE,
  BASE_PORTFOLIO,
  MY_CHILDREN_PORTFOLIO,
  NOTICES,
  PROFILE,
  LITTLE_EVALUATOR,
} from "constants/url";

import { translation } from "constants/translation";

import NavbarMenuBlock from "./NavbarMenuBlock";
import {
  PORTFOLIOS_TEXT,
  CALENDAR_TEXT,
  DASHBOARD_TEXT,
  LESSON_LIBRARY_TEXT,
  MY_CLASSES_TEXT,
  MY_CHILDREN_TEXT,
  NOTICES_TEXT,
  PHOTOS_TEXT,
  PROFILE_TEXT,
  LITTLE_EVALUATOR_TEXT,
} from "constants/navbar";
import { icons } from "../Navbar";
import { PHOTO_ALBUM } from "containers/ChildrenPortfolioPage/constants";

const TeachMenuList = ({
  handleClose,
  isMobileView = false,
}: {
  handleClose?: () => void;
  isMobileView?: boolean;
}) => {
  const { profileDetails, currLanguage, childrenInSession, profileID } = useStore(
    (state) => state
  );
  const [text, setText] = React.useState<{ [item: string]: string }>();

  useEffect(() => {
    setText({
      [DASHBOARD_TEXT]: translation.dashboard,
      [LESSON_LIBRARY_TEXT]: translation.lessonLibrary,
      [PORTFOLIOS_TEXT]: translation.portfolios,
      [CALENDAR_TEXT]: translation.calendar,
      [LITTLE_EVALUATOR_TEXT]: translation.littleEvaluator,
      [MY_CLASSES_TEXT]: translation.myClasses,
      [MY_CHILDREN_TEXT]: translation.childrenPortfolio,
      [PROFILE_TEXT]: translation.profile,
      [PHOTOS_TEXT]: translation.photos,
      [NOTICES_TEXT]: translation.notices,
    });
  }, [currLanguage]);

  let pages: { [item: string]: string };
  if (profileDetails.role === "CE") {
    pages = {
      [LESSON_LIBRARY_TEXT]: `${CURLANG(currLanguage)}/${PROJECT_MANAGE_PAGE}`,
    };
  } else if (profileDetails.role === "PR") {
    pages = {
      [DASHBOARD_TEXT]: `${CURLANG(currLanguage)}/${HOME}`,
      [PROFILE_TEXT]: `${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${
        childrenInSession[0]?.id
      }/${PROFILE}`,
      [PHOTOS_TEXT]: `${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${
        childrenInSession[0]?.id
      }/${PHOTO_ALBUM}`,
      [PORTFOLIOS_TEXT]: `${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}`,
      [NOTICES_TEXT]: `${CURLANG(currLanguage)}/${NOTICES}`,
    };
  } else {
    pages = {
      [DASHBOARD_TEXT]: `${CURLANG(currLanguage)}/${HOME}`,
      [LESSON_LIBRARY_TEXT]: `${CURLANG(currLanguage)}/${MARKETPLACE}`,
      [MY_CLASSES_TEXT]: `${CURLANG(currLanguage)}/${CLASS}`,
      [PORTFOLIOS_TEXT]: `${CURLANG(currLanguage)}/${BASE_PORTFOLIO}`,
      [LITTLE_EVALUATOR_TEXT]: `${CURLANG(currLanguage)}/${LITTLE_EVALUATOR}`,
      [CALENDAR_TEXT]: `${CURLANG(currLanguage)}/${CALENDAR}`,
    };
  }

  return (
    <>
      {Object.keys(pages).map((page) =>
        !!profileID && text ? (
          <ProtectedLink key={`${page}`} isAuth={!!profileID}>
            <NavbarMenuBlock
              key={`navbar-menu-block-${page}`}
              isMobileView={isMobileView}
              pages={pages}
              page={page}
              handleClose={handleClose}
              icons={icons}
              text={text}
            />
          </ProtectedLink>
        ) : (
          <Fragment key={`navbar-menu-block-${page}`}> {null}</Fragment>
        )
      )}
    </>
  );
};
export default TeachMenuList;
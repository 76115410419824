import React from "react";

import useStore from "store/store";
import SideTabView from "./SideTabView";

const SideTabs = ({
  isReco = false,
  title,
  slug,
  icon,
}: {
  isReco?: boolean;
  title: string;
  slug: string;
  subtitle: string;
  icon: React.ReactElement;
}) => {
  const {
    isFilterSelected,
    isModuleSelected,
    isRecommendationSelected,
    recommendationFilters,
    setRecommendationFilters,
    moduleFilters,
    setModuleFilters,
  } = useStore((state) => ({
    isFilterSelected:
      state.ageFilters.length !== 0 ||
      state.recommendationFilters.length !== 0 ||
      state.moduleFilters.length !== 0,
    isModuleSelected: state.moduleFilters.includes(slug),
    isRecommendationSelected: state.recommendationFilters.includes(slug),
    recommendationFilters: state.recommendationFilters,
    setRecommendationFilters: state.setRecommendationFilters,
    moduleFilters: state.moduleFilters,
    setModuleFilters: state.setModuleFilters,
  }));

  const handleSelected = () => {
    if (isReco) {
      if (isRecommendationSelected) {
        const temp = [...recommendationFilters];
        const index = temp.findIndex((elem) => elem === slug);
        temp.splice(index, 1);
        setRecommendationFilters(temp);
      } else {
        setRecommendationFilters([...recommendationFilters, slug]);
      }
    } else {
      if (isModuleSelected) {
        const temp = [...moduleFilters];
        const index = temp.findIndex((elem) => elem === slug);
        temp.splice(index, 1);
        setModuleFilters(temp);
      } else {
        setModuleFilters([...moduleFilters, slug]);
      }
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <SideTabView
      isSelected={isModuleSelected || isRecommendationSelected}
      isFilterSelected={isFilterSelected}
      title={title}
      icon={icon}
      handleSelected={handleSelected}
    />
  );
};

export default SideTabs;

import { Box } from "@mui/material";

import RegistrationForm from "components/AuthForm/RegistrationForm";

const RegisterPage = () => {
  return (
    <Box sx={{ p: "30px 20px", flexGrow: "1" }}>
      <RegistrationForm type="register" />
    </Box>
  );
};

export default RegisterPage;

import React, { Fragment, useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Image } from "react-grid-gallery";

import useStore from "store/store";

import { getGalleryByProject } from "api/gallery-api";

import { API_Profile_Short } from "types/profile";

import "components/ProjectSummaryScreen/ReactGridGallery.css";
import { translation } from "constants/translation";
import { GalleryPhotoVideo } from "types/gallery";
import PhotoVideoGalleryComponent from "components/PhotoVideoGalleryComponents/PhotoVideoGalleryComponent";
import PhotoVideoGalleryModal from "components/PhotoVideoGalleryComponents/PhotoVideoGalleryModalV2";

export interface API_Gallery_Photo_Video_Custom {
  id: number;
  caption: string;
  image?: string;
  video?: string;
  learning_moment: number | null;
  gallery: number;
  profile: API_Profile_Short[];
  width: number;
  height: number;
  created_at: string;
}

const ProjectPhotoAlbumScreen = () => {
  const { currGroup, projID, lessons, currLanguage } = useStore((state) => ({
    currGroup: state.currGroup,
    projID: state.currentLessons.id,
    lessons: state.currentLessons.lessons,
    currLanguage: state.currLanguage,
  }));

  //combined
  const [photoVideoGalleryList, setPhotoVideoGallery] = useState<
    GalleryPhotoVideo[]
  >([]);
  const [openGalleryModal, setOpenGalleryModal] = useState<boolean>(false);
  const [currPhotoVideoIndex, setCurrPhotoVideoIndex] = useState<number>(0);
  const [selectedMediaLessonName, setSelectedMediaLessonName] = useState<string>('');
  const [havePhotoVideo, setHavePhotoVideo] = useState<boolean>(false);

  //general
  const [galleryIndex, setGalleryIndex] = useState<number>(0);
  const [selectedProfiles, setSelectedProfiles] = useState<string[][][]>([]);
  const [profileDict, setProfileDict] = useState<{
    [id: string]: API_Profile_Short;
  }>({});

  const initializeGallery = async () => {
    const galleryResData = await getGalleryByProject(
      currLanguage,
      projID.toString(),
      localStorage.getItem("access"),
      localStorage.getItem("curr_group_id")
    );
    // console.log("galleryResData; ", galleryResData);
    if (typeof galleryResData !== "string") {
      const newImageVideoList: GalleryPhotoVideo[] = [];
      const newProfileList: string[][][] = [];
      let lessonIndex = 0;
      const lessonList: string[] = [];
      let lessonName = "";

      galleryResData.forEach((data) => {
        if (
          data.lesson === undefined ||
          data.lesson === "" ||
          data.activity === null
        )
          return;

        lessonName = data.lesson!;
        lessonIndex = lessonList.indexOf(lessonName);

        //create new one if doesn't exist
        if (lessonIndex === -1) {
          lessonList.push(lessonName);
          newImageVideoList.push({
            lesson: lessonName,
            photo_video_list: [],
          });
          lessonIndex = newImageVideoList.length - 1;
          newProfileList.push([]);
        }

        if (data.videos.length > 0 || data.images.length > 0) {
          setHavePhotoVideo(true);
        }

        const photoVideoFullGalleryList = [...data.images, ...data.videos];
        // console.log(
        //   "photoVideoFullGalleryList before: ",
        //   photoVideoFullGalleryList
        // );

        photoVideoFullGalleryList.sort((a, b) => {
          const first = a.created_at
            .replace(/[^0-9]/g, "")
            .split("T")[0]
            .split("-")
            .join("");
          const second = b.created_at
            .replace(/[^0-9]/g, "")
            .split("T")[0]
            .split("-")
            .join("");
          return first > second ? 1 : first < second ? -1 : 0;
        });
        // console.log(
        //   "photoVideoFullGalleryList after: ",
        //   photoVideoFullGalleryList
        // );
        photoVideoFullGalleryList.forEach(
          (photoVideo: API_Gallery_Photo_Video_Custom) => {
            const isPhoto = photoVideo.video ? false : true;
            const isLearningMoment = photoVideo.learning_moment !== null;
            const imageData: Image = {
              key: `${isPhoto ? "photo" : "video"}-${photoVideo.id}${
                isLearningMoment ? "-learning-moment" : ""
              }`,
              src: isPhoto ? photoVideo.image! : photoVideo.video!,
              width: photoVideo.width,
              height: photoVideo.height,
              alt: photoVideo.caption,
              customOverlay: (
                <>
                  {newImageVideoList[lessonIndex].photo_video_list.length !==
                    0 && (
                    <div className="number-overlay">
                      <p>
                        +
                        {newImageVideoList[lessonIndex].photo_video_list
                          .length + 1}
                      </p>
                    </div>
                  )}
                  <div className="custom-overlay">
                    <p>{photoVideo.caption}</p>
                  </div>
                </>
              ),
            };

            newImageVideoList[lessonIndex].photo_video_list.unshift(imageData);
            newProfileList[lessonIndex].unshift(
              photoVideo.profile.map((prof) => prof.id.toString())
            );
          }
        );
      });

      setPhotoVideoGallery(newImageVideoList);
      setSelectedProfiles(newProfileList);
    }
  };

  const initializeProfiles = async () => {
    const profileList = currGroup.children.reduce((acc, child) => {
      acc[child.id.toString()] = {
        id: child.id,
        image: child.image,
        preferred_name: child.preferred_name,
        full_name: child.full_name ? child.full_name : "",
        dob: child.dob,
        school: child.school,
      };
      return acc;
    }, {} as { [id: string]: API_Profile_Short });
    setProfileDict(profileList);
  };

  useEffect(() => {
    if (projID === -1 || currGroup.id === -1) return;

    initializeGallery();
    initializeProfiles();

    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projID, currLanguage, currGroup]);

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 3.75 }, pt: { xs: 3.75, sm: 0 }, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        {translation.photoAlbum}
      </Typography>

      {lessons.length !== 0 &&
        lessons.map((lesson, index) => (
          <Fragment key={`${lesson.id}-index`}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              {`${translation.formatString(translation.lessonNo, {
                number: index + 1,
              })}: ${lesson.name}`}
            </Typography>
            <PhotoVideoGalleryComponent
              key={`photo-video-${lesson.id}`}
              lesson={lesson}
              lessonNo={index + 1}
              photoVideoGalleryList={photoVideoGalleryList}
              setGalleryIndex={setGalleryIndex}
              setCurrPhotoVideoIndex={(index) => {
                setCurrPhotoVideoIndex(index);
                setSelectedMediaLessonName(lesson.name);
              }}
              setOpen={setOpenGalleryModal}
              initializeGallery={initializeGallery}
            />
          </Fragment>
        ))}
      {photoVideoGalleryList.length > 0 &&
        havePhotoVideo &&
        photoVideoGalleryList[galleryIndex].photo_video_list.length !== 0 && (
          <PhotoVideoGalleryModal
            open={openGalleryModal}
            setOpen={setOpenGalleryModal}
            lessonName={selectedMediaLessonName}
            photoVideoGalleryList={photoVideoGalleryList}
            galleryIndex={galleryIndex}
            currPhotoVideoIndex={currPhotoVideoIndex}
            setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
            profileDict={profileDict}
            selectedProfiles={selectedProfiles}
            setSelectedProfiles={setSelectedProfiles}
            initializeGallery={initializeGallery}
          />
        )}
    </Box>
  );
};

export default ProjectPhotoAlbumScreen;

import { Text, View } from "@react-pdf/renderer";
import { checkIsChineseChar } from "helper/helper";

const MilestoneSection = ({ descriptions }: { descriptions: string }) => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      gap: 0,
      justifyContent: "flex-start",
    }}
  >
    <Text
      style={{
        color: "#000",
        marginRight: "12px",
        marginLeft: "8px",
        fontFamily: "FuturaBkBT",
        fontSize: "16px",
        lineHeight: 1,
        letterSpacing: "1.2px",
      }}
    >
      &#8226;
    </Text>
    <Text
      style={{
        fontFamily: checkIsChineseChar(descriptions)
          ? "NotoSansSCLight"
          : "FuturaLtBT",
        fontSize: 12,
        lineHeight: 1.5,
        flex: 1,
      }}
    >
      {descriptions}
    </Text>
  </View>
);

export default MilestoneSection;

import { useEffect, useState } from "react";

import { Box, Fade, Modal, Backdrop, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LineChart,
  BarChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from "recharts";

import Button from "components/Button/Button";
import { AnalyticPeriodType } from "types/analytic";
import {
  GENERAL_DATE_FORMAT,
  ANALYTIC_PERIOD_LIST,
  ONE_YEAR,
  PERIOD_MAPPING,
  SEVEN_DAYS,
  THIRTY_DAYS,
} from "constants/constantInGeneral";
import {
  API_Project_Analytic,
  API_Project_Chart_Info,
  API_Project_Rating,
  API_Project_Rating_Count,
  API_Project_Sentiment_Score_Count,
  API_Project_Sentiment_Score,
} from "types/project";
import { getProjectChartInfo } from "api/project-api";
import { API_School } from "types/school";
import useStore from "store/store";
import { getStartEndDate } from "helper/helper";
import moment from "moment";
import useColors from "hooks/useColors";

interface WeekBucket {
  count: number;
  sum: number;
  avg: number;
  weekEnd: string;
}

const preprocessData = (
  data: API_Project_Rating[],
  interval: AnalyticPeriodType
): API_Project_Rating[] => {
  let intervalNum = 7; //7 days default
  let bucketSize = 1; // default to daily buckets
  if (interval === ONE_YEAR) {
    intervalNum = 364;
    bucketSize = 7; // use weekly buckets for 364-day interval
  } else if (interval === THIRTY_DAYS) {
    intervalNum = 30;
  }

  const startDate = moment().subtract(intervalNum, "days");
  const bucketCount = intervalNum === 364 ? 52 : intervalNum;
  const buckets: Record<number, WeekBucket> = {};

  // Create empty buckets for all time periods
  for (let i = 0; i < bucketCount; i++) {
    const weekEnd = startDate.clone().add(i * bucketSize + bucketSize, "days");
    buckets[i] = {
      count: 0,
      sum: 0,
      avg: 0,
      weekEnd: weekEnd.toISOString(),
    };
  }

  data.forEach((item) => {
    const createdAt = moment(item.created_at);
    const bucketNumber = Math.floor(
      createdAt.diff(startDate, "days") / bucketSize
    );

    if (bucketNumber >= 0 && bucketNumber < bucketCount) {
      buckets[bucketNumber].count++;
      buckets[bucketNumber].sum += item.rating;
      buckets[bucketNumber].avg =
        buckets[bucketNumber].sum / buckets[bucketNumber].count;
    }
  });

  const values = Object.values(buckets);
  const finalData = values.map((value) => {
    return {
      created_at: moment(value.weekEnd).format(GENERAL_DATE_FORMAT),
      rating: parseFloat(value.avg.toFixed(2)),
    };
  });
  return finalData;
};

const IndividualChartModal = ({
  currSchool,
  selectedProject,
  open,
  handleClose,
}: {
  currSchool: API_School;
  selectedProject: API_Project_Analytic;
  open: boolean;
  handleClose: () => void;
}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [interval, setInterval] = useState<AnalyticPeriodType>(SEVEN_DAYS);

  const [projectChartInfo, setProjectChartInfo] =
    useState<API_Project_Chart_Info>({
      id: -1,
      name: "",
      slug: "",
      rating_list: [],
      rating_count: {
        rating_1: 0,
        rating_2: 0,
        rating_3: 0,
        rating_4: 0,
        rating_5: 0,
      },
      sentiment_score: {
        negative: 0,
        positive: 0,
        neutral: 0,
      },
      module_sentiment_score: {
        negative: 0,
        positive: 0,
        neutral: 0,
      },
      sentiment_score_list: [],
      module_sentiment_score_list: [],
    });

  useEffect(() => {
    const populateProjectChartInfo = async () => {
      const [startDate, endDate] = getStartEndDate(interval);

      const res = await getProjectChartInfo(
        currLanguage,
        currSchool.id,
        selectedProject.id,
        startDate,
        endDate,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        const processedRatingList = preprocessData(res.rating_list, interval);
        const cleanedProjectChartInfo = {
          ...res,
          rating_list: processedRatingList,
        };
        setProjectChartInfo(cleanedProjectChartInfo);
      }
    };
    selectedProject.id !== -1 && populateProjectChartInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, selectedProject.id]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            paddingY: 0.5,
            borderRadius: "10px",
            width: "60vw",
            height: "80vh",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          {/* Content here */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              position: "relative",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 1,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "45%",
                  marginRight: 1,
                }}
              >
                {selectedProject.name}
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mr: 2, flex: 1 }}>
                {ANALYTIC_PERIOD_LIST.map((period) => (
                  <Button
                    key={`${period}-key`}
                    btnType={period === interval ? "filled" : "outlined"}
                    arrow={false}
                    style={{
                      fontSize: 12,
                      width: "100%",
                      flex: 1,
                      textAlign: "center",
                      display: "flex",
                    }}
                    buttonText={PERIOD_MAPPING[period]}
                    onClick={() => {
                      setInterval(period as AnalyticPeriodType);
                    }}
                  />
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2, 1fr)",
                gap: 4,
                p: 3,
                minWidth: 0,
                minHeight: 0,
              }}
            >
              <LineChartComponent data={projectChartInfo.rating_list} />

              <BarChartComponent data={projectChartInfo.rating_count} />

              <SentimentScoreLineChart
                sentimentScoreList={projectChartInfo.sentiment_score_list}
                moduleSentimentScoreList={
                  projectChartInfo.module_sentiment_score_list
                }
                interval={interval}
              />

              <SentimentScoreBarChart
                sentimentScoreCount={projectChartInfo.sentiment_score}
                moduleSentimentScoreCount={
                  projectChartInfo.module_sentiment_score
                }
              />
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const LineChartComponent = ({ data }: { data: API_Project_Rating[] }) => {
  const colors = useColors();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" />
        <YAxis dataKey="rating" domain={[0, 5]} tickCount={6} interval={0} />
        <Tooltip />
        <Legend
          formatter={(value, entry, index) => (
            <span style={{ color: entry.color }}>Rating</span>
          )}
        />
        <Line
          type="monotone"
          dataKey="rating"
          stroke={colors[2 % colors.length]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const BarChartComponent = ({ data }: { data: API_Project_Rating_Count }) => {
  const colors = useColors();

  const dataArray = Object.entries(data).map(([rating, count]) => ({
    rating,
    count,
  }));

  const transformLabel = (label: string) => {
    const ratingNumber = label.split("_")[1];
    return `${ratingNumber} Star${ratingNumber === "1" ? "" : "s"}`;
  };

  interface CustomXTickProps {
    x: number;
    y: number;
    payload: {
      value: string;
    };
  }

  const CustomXTick = (props: CustomXTickProps) => {
    const { x, y, payload } = props;
    const labelText = transformLabel(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {labelText}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={dataArray}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="rating" tick={CustomXTick} />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend
          formatter={(value, entry, index) => (
            <span style={{ color: entry.color }}>Number of rating</span>
          )}
        />
        <Bar dataKey="count" fill={colors[2 % colors.length]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

const SentimentScoreLineChart = ({
  sentimentScoreList,
  moduleSentimentScoreList,
  interval,
}: {
  sentimentScoreList: API_Project_Sentiment_Score[];
  moduleSentimentScoreList: API_Project_Sentiment_Score[];
  interval: AnalyticPeriodType;
}) => {
  const colors = useColors();

  let intervalNum = 7; //7 days default
  let bucketSize = 1; // default to daily buckets
  if (interval === ONE_YEAR) {
    intervalNum = 364;
    bucketSize = 7; // use weekly buckets for 364-day interval
  } else if (interval === THIRTY_DAYS) {
    intervalNum = 30;
  }

  const startDate = moment().subtract(intervalNum, "days");
  const bucketCount = intervalNum === 364 ? 52 : intervalNum;
  const projectBuckets: Record<number, WeekBucket> = {};
  const moduleBuckets: Record<number, WeekBucket> = {};

  for (let i = 0; i < bucketCount; i++) {
    const weekEnd = startDate.clone().add(i * bucketSize + bucketSize, "days");

    projectBuckets[i] = {
      count: 0,
      sum: 0,
      avg: 0,
      weekEnd: weekEnd.toISOString(),
    };

    moduleBuckets[i] = {
      count: 0,
      sum: 0,
      avg: 0,
      weekEnd: weekEnd.toISOString(),
    };
  }

  sentimentScoreList.forEach((item) => {
    const createdAt = moment(item.created_at);
    const bucketNumber = Math.floor(
      createdAt.diff(startDate, "days") / bucketSize
    );

    if (bucketNumber >= 0 && bucketNumber < bucketCount) {
      projectBuckets[bucketNumber].count++;
      projectBuckets[bucketNumber].sum += +item.sentiment_score;
      projectBuckets[bucketNumber].avg =
        projectBuckets[bucketNumber].sum / projectBuckets[bucketNumber].count;
    }
  });

  moduleSentimentScoreList.forEach((item) => {
    const createdAt = moment(item.created_at);
    const bucketNumber = Math.floor(
      createdAt.diff(startDate, "days") / bucketSize
    );

    if (bucketNumber >= 0 && bucketNumber < bucketCount) {
      moduleBuckets[bucketNumber].count++;
      moduleBuckets[bucketNumber].sum += +item.sentiment_score;
      moduleBuckets[bucketNumber].avg =
        moduleBuckets[bucketNumber].sum / moduleBuckets[bucketNumber].count;
    }
  });

  const projects = Object.values(projectBuckets).map((value) => ({
    created_at: moment(value.weekEnd).format(GENERAL_DATE_FORMAT),
    project: parseFloat(value.avg.toFixed(2)),
  }));

  const modules = Object.values(moduleBuckets).map((value) => ({
    created_at: moment(value.weekEnd).format(GENERAL_DATE_FORMAT),
    module: parseFloat(value.avg.toFixed(2)),
  }));

  const data = [];

  for (let j = 0; j < projects.length; j++) {
    data.push({
      ...projects[j],
      ...modules.find((item) => item.created_at === projects[j].created_at),
    });
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" />
        <YAxis dataKey="project" domain={[-1, 1]} tickCount={5} interval={0} />
        <Tooltip />
        <Legend
          formatter={(value, entry, index) => (
            <span style={{ color: entry.color, textTransform: "capitalize" }}>
              {value}
            </span>
          )}
        />
        <Line
          type="monotone"
          dataKey="project"
          stroke={colors[2 % colors.length]}
        />
        <Line
          type="monotone"
          dataKey="module"
          stroke={colors[1 % colors.length]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const SentimentScoreBarChart = ({
  sentimentScoreCount,
  moduleSentimentScoreCount,
}: {
  sentimentScoreCount: API_Project_Sentiment_Score_Count;
  moduleSentimentScoreCount: API_Project_Sentiment_Score_Count;
}) => {
  const colors = useColors();

  const data = Object.keys(sentimentScoreCount).map((key) => ({
    label: key,
    project:
      sentimentScoreCount[key as keyof API_Project_Sentiment_Score_Count],
    module:
      moduleSentimentScoreCount[key as keyof API_Project_Sentiment_Score_Count],
  }));

  interface CustomXTickProps {
    x: number;
    y: number;
    payload: {
      value: string;
    };
  }

  const CustomXTick = (props: CustomXTickProps) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          style={{ textTransform: "capitalize" }}
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" tick={CustomXTick} />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Legend
          formatter={(value, entry) => (
            <span style={{ color: entry.color, textTransform: "capitalize" }}>
              {value}
            </span>
          )}
        />
        <Bar dataKey="project" fill={colors[2 % colors.length]} />
        <Bar dataKey="module" fill={colors[1 % colors.length]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default IndividualChartModal;

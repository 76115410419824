import { Page, Text, Image, View, StyleSheet } from "@react-pdf/renderer";

import { API_Project_Fundamental_Legacy } from "types/project";
import { pageSize, imageSrc, styles } from "../ProjectReportPDF";

import Background from "images/end-of-project-report/developmental-goals-bg.png";

const templateStyles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    flex: 1,
    marginTop: 280,
    marginBottom: 50,
    marginHorizontal: 50,
    overflow: "hidden",
  },
  title: {
    position: "absolute",
    top: 120,
    left: 400,
    right: 400,
  },
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 200,
    maxWidth: 280,
    border: 8,
    borderColor: "#fff",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    paddingLeft: 70,
    paddingRight: 20,
    height: 100,
    marginLeft: 60,
    marginVertical: 10,
    marginRight: 10,
    backgroundColor: "#DFEBEB",
  },
  imageWrapper: {
    position: "absolute",
    left: -50,
    top: -8,
    backgroundColor: "#fff",
    borderRadius: "100%",
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
  text: {
    fontSize: 18,
    maxWidth: 190,
  },
});

const DevelopmentalGoals = ({
  fundamentals,
}: {
  fundamentals: API_Project_Fundamental_Legacy[];
}) => {
  const breakText = (text: string): string[] => [text];
  const uniqueFundamentals = fundamentals
    .filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    )
    .map((item) => item);

  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Background} style={styles.background} />

        <Text style={[styles.pageTitle, templateStyles.title]}>
          Developmental Goals
        </Text>

        <View style={templateStyles.wrapper}>
          {uniqueFundamentals.map((fundamental, index) => {
            return (
              <View key={index} style={templateStyles.card}>
                <View style={templateStyles.imageWrapper}>
                  <Image
                    src={imageSrc(fundamental.image)}
                    style={templateStyles.image}
                  />
                </View>

                <Text
                  hyphenationCallback={(e) => breakText(e)}
                  style={[styles.textCenter, templateStyles.text]}
                >
                  {fundamental.name}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </Page>
  );
};

export default DevelopmentalGoals;

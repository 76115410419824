import  { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import Button from "components/Button/Button";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  API_Create_Child,
  API_Profile_Short,
} from "types/profile";
import {
  createChild,
  getChildRegisterParentProfile,
  getGroupListBySchool,
} from "api/profile-api";
import { translation } from "constants/translation";
import { API_School_Simple } from "types/school";
import { getAllSchoolForRegister } from "api/school-api";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import { GridRowsProp } from "@mui/x-data-grid";
import { API_Group_Simple } from "types/group";
import ImageForm from "./ImageForm";
import CreateGroupModal from "../SiteAdminPage/ManagementPage/components/CreateGroupModal";
import { addButtonStyle } from "./addButtonStyle";
import CreateProfileModal from "../SiteAdminPage/ManagementPage/components/CreateProfileModal";

const CreateChildPage = ({
  handleClose,
  setRows,
  groupId = -1,
}: {
  handleClose?: () => void;
  setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  groupId?: number;
}) => {
  const openSnack = useSnack();

  const [formData, setFormData] = useState<API_Create_Child>({
    preferred_name: "",
    full_name: "",
    dob: "",
    role: "CH",
    school: -1,
    parent: -11,
    groups: [],
  });

  const [schoolList, setSchoolList] = useState<API_School_Simple[]>([]);
  const [previewImg, setPreviewImg] = useState<string>("");
  const [groupListDisplay, setGroupListDisplay] = useState<API_Group_Simple[]>(
    []
  );
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [openParentModal, setOpenParentModal] = useState<boolean>(false);

  const {
    profileDetails,
    profileID,
    adminPageParentList,
    setAdminPageParentList,
    setRefreshGroupChildrenList,
  } = useStore((state) => state);

  // Get school list
  useEffect(() => {
    const populateData = async () => {
      const res = await getAllSchoolForRegister();
      setSchoolList(res);
    };
    populateData();
  }, []);

  useEffect(() => {
    if (profileDetails && profileDetails.role !== "WAD") {
      setFormData((prev) => ({
        ...prev,
        school: profileDetails?.school ? profileDetails.school.id : -1,
      }));
    }
  }, [profileDetails]);

  useEffect(() => {
    const populateData = async () => {
      if (formData.school && formData.school !== -1) {
        const res = await getChildRegisterParentProfile(formData.school);
        setAdminPageParentList(res);
        const resGroup = await getGroupListBySchool(formData.school);
        setGroupListDisplay(resGroup);
      }
    };
    setFormData((prev) => ({
      ...prev,
      parent: -11,
      groups: [],
    }));
    populateData();
    if (groupId !== -1) {
      setFormData((prev) => ({
        ...prev,
        groups: [...prev.groups, groupId],
      }));
    }
  }, [formData.school]);

  const handleSubmit = async () => {
    const body = formData;
    if (formData.parent === -11) {
      body.parent = -1;
    }
    const res = await createChild(body);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      // console.log("RES: ", res);
      openSnack(`Registered ${formData.preferred_name} successfully!`, true);
      if (setRows) {
        setRows((prev: any) => {
          return [...prev, res];
        });
      }
      if (handleClose) {
        handleClose();
      }
      setRefreshGroupChildrenList(true);
    }
  };

  const disabled = formData.school === -1;
  // console.log("formData: ", formData);
  // console.log("adminPageParentList: ", adminPageParentList);

  const today = new Date().toLocaleDateString("en-ca");

  return !!profileID ? (
    <Box sx={{ p: "16px" }}>
      <CreateGroupModal
        open={openGroupModal}
        handleClose={() => setOpenGroupModal(false)}
        schoolId={formData.school ? formData.school : -1}
        setFormData={setFormData}
        setGroupListDisplay={setGroupListDisplay}
      />
      <CreateProfileModal
        open={openParentModal}
        handleClose={() => setOpenParentModal(false)}
        schoolId={formData.school ? formData.school : -1}
        role={"PR"}
        setChildFormData={setFormData}
      />
      <Stack
        component="form"
        sx={{ margin: "auto", my: "16px", alignItems: "center" }}
        spacing={1}
        noValidate
        autoComplete="off"
      >
        <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
          {translation.registerChild}
        </Typography>
        <ImageForm
          setFormData={setFormData}
          role={formData.role}
          image={
            typeof formData.image === "string"
              ? formData.image
              : typeof formData.image === "object"
              ? formData.image !== null
                ? URL.createObjectURL(formData.image)
                : ""
              : ""
          }
        />
        {`Profile Image`}
        <Stack
          component="form"
          spacing={1}
          direction="row"
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            sx={TEXT_FIELD_STYLE}
            label={translation.full_name}
            value={formData.full_name}
            onChange={(event) => {
              setFormData((prev) => ({
                ...prev,
                full_name: event.target.value,
              }));
            }}
          />
          <TextField
            sx={TEXT_FIELD_STYLE}
            required
            label={translation.preferred_name}
            value={formData.preferred_name}
            onChange={(event) => {
              setFormData((prev) => ({
                ...prev,
                preferred_name: event.target.value,
              }));
            }}
          />
        </Stack>
        <TextField
          required
          sx={TEXT_FIELD_STYLE}
          label={translation.dob}
          type="date"
          defaultValue={formData.dob}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setFormData((prev) => ({ ...prev, dob: event.target.value }));
          }}
          inputProps={{
            max: today,
          }}
        />
        {profileDetails.role !== "WAD" ? null : (
          <Autocomplete
            key={schoolList.map((school) => school.id).join("-")}
            sx={TEXT_FIELD_STYLE}
            disablePortal
            id="combo-box-register"
            onChange={(
              event: any,
              school_updated: API_School_Simple | null
            ) => {
              if (school_updated !== null) {
                setFormData((prev) => ({
                  ...prev,
                  school: school_updated.id,
                }));
              }
            }}
            value={
              schoolList.filter((school) => school.id === formData.school)[0] ||
              null
            }
            options={schoolList}
            getOptionLabel={(school) => school.name}
            // renderOption={(props, option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Schools"
                  placeholder=""
                  required
                />
              );
            }}
          />
        )}
        <Box sx={{ display: "flex" }}>
          <Autocomplete
            key={adminPageParentList.map((parent) => parent.id).join("-")}
            sx={TEXT_FIELD_STYLE}
            disabled={disabled}
            disablePortal
            id="combo-box-parent"
            onChange={(
              event: any,
              parent_updated: API_Profile_Short | null
            ) => {
              if (parent_updated !== null) {
                setFormData((prev) => ({
                  ...prev,
                  parent: parent_updated.id,
                }));
              }
            }}
            options={adminPageParentList}
            getOptionLabel={(parent) => parent.preferred_name}
            // renderOption={(props, option) => option.name}
            value={
              adminPageParentList.filter(
                (parent) => parent.id === formData.parent
              )[0] || null
            }
            isOptionEqualToValue={(option, value) =>
              option.preferred_name === value.preferred_name
            }
            renderInput={(params) => {
              return <TextField {...params} label="Parents" placeholder="" />;
            }}
          />
          <Button
            style={{ ...addButtonStyle, position: "absolute" }}
            buttonText={`+`}
            onClick={() => {
              setOpenParentModal(true);
            }}
            arrow={false}
            disabled={disabled}
          />
        </Box>

        <Box sx={{ display: "flex" }}>
          <Autocomplete
            disabled={disabled}
            multiple
            key={groupListDisplay.map((group) => group.id).join("-")}
            sx={TEXT_FIELD_STYLE}
            disablePortal
            id="combo-box-groups"
            onChange={(
              event: any,
              groups_updated: API_Group_Simple[] | null
            ) => {
              if (groups_updated !== null) {
                setFormData((prev) => ({
                  ...prev,
                  groups: groups_updated?.map((group) => group.id),
                }));
              }
            }}
            options={groupListDisplay}
            getOptionLabel={(group) => group.name}
            // renderOption={(props, option) => option.name}
            value={groupListDisplay.filter(
              (group) => formData!.groups.includes(group.id) || null
            )}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => {
              return <TextField {...params} label="Groups" placeholder="" />;
            }}
          />
          <Button
            style={{ ...addButtonStyle, position: "absolute" }}
            buttonText={`+`}
            onClick={() => {
              setOpenGroupModal(true);
            }}
            disabled={disabled}
          />
        </Box>

        <Button
          buttonText={translation.submit || "Submit"}
          style={{ marginTop: "20px" }}
          onClick={handleSubmit}
          disabled={
            formData.full_name === "" ||
            formData.preferred_name === "" ||
            formData.dob === "" ||
            // !formData.image ||
            formData.school === -1
          }
        />
      </Stack>
    </Box>
  ) : (
    <Typography
      sx={{
        fontSize: "36px",
        height: "71vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {translation.not_logged_in}
    </Typography>
  );
};

export default CreateChildPage;

import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "./Button.module.css";

const CustomButton = ({
  buttonText,
  icon,
  style,
  btnType = "filled",
  isLoading = false,
  arrow = true,
  backward = false,
  disabled = false,
  clampText = false,
  type = "submit",
  onClick,
}: {
  buttonText: string;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  btnType?: "outlined" | "filled" | "filled-light";
  isLoading?: boolean;
  arrow?: boolean;
  backward?: boolean;
  disabled?: boolean;
  clampText?: boolean;
  type?: "submit" | "button" | "reset";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const clampTextLength = () => {
    if (isXs) return 10;
    if (isSm) return 20;
    if (isMd) return 30;
    if (isLg) return 40;
    return buttonText.length;
  };
  const clampedButtonText = clampText
    ? `${buttonText.slice(0, clampTextLength())}${
        buttonText.length > clampTextLength() ? "..." : ""
      }`
    : buttonText;
  const arrowColor =
    btnType === "outlined"
      ? {
          color: "txt.secondary",
        }
      : btnType === "filled"
      ? {
          color: "txt.light",
        }
      : {
          color: "txt.dark2",
        };
    
  return (
    <button
      className={`${styles.btn} ${
        btnType === "outlined"
          ? styles.outlined
          : btnType === "filled"
          ? styles.filled
          : styles.filledLight
      } ${disabled ? styles.disabled : ""}`}
      style={style}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        !disabled && onClick && onClick(event);
      }}
      type={type}
    >
      {isLoading ? (
        <CircularProgress
          sx={{ marginRight: 1 }}
          size="1em"
          color={"secondary"}
        />
      ) : icon ? (
        icon
      ) : null}

      {arrow ? (
        backward ? (
          <Box sx={{ display: "flex", gap: 1 }}>
            <ArrowBackIcon sx={arrowColor} />
            {buttonText}
          </Box>
        ) : (
          <>
            {buttonText}
            <ArrowForwardIcon sx={arrowColor} />
          </>
        )
      ) : (
        <Box>{clampedButtonText}</Box>
      )}
    </button>
  );
};

export default CustomButton;

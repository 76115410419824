import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import Button from "components/Button/Button";

import { API_Project_Lesson } from "types/project";
import {
  ASSESSMENT_PAGE,
  ASSESSMENT_PLAN_PAGE,
  CURLANG,
  LESSON_ACTIVITY_PAGE,
  LITTLE_EVALUATOR,
  PROJECT_SUMMARY_PAGE,
} from "constants/url";
import { translation } from "constants/translation";
import { updateScheduleActivityProgress } from "api/profile-api";
import { populateLessonLearningMomentCheckpointCount } from "helper/project";

import "../flipBook.css";

import UploadCompletedWorkPromptModal from "containers/ProjectPage/LessonActivityPageComponents/UploadCompletedWorkPromptModal";
import { getCompletedWorkList } from "api/completed-works-api";
import UploadLearningMomentPromptModal from "containers/ProjectPage/LessonActivityPageComponents/UploadLearningMomentPromptModal";
import { getLessonIdIncompleteLearningMomentList } from "api/lesson-activity-api";
import {
  buttonTextFontSize,
  buttonTextMobileFontSize,
} from "constants/constantInGeneral";

type Props = {
  lesson: API_Project_Lesson;
  activityIndex: number;
};

const ActivityAction = ({ lesson, activityIndex }: Props) => {
  const { lessonID, assessmentID, activityID } = useParams();
  const openSnack = useSnack();
  const navigate = useNavigate();

  const {
    currScheduleObj,
    setCurrScheduleObj,
    currLanguage,
    projectSlug,
    currentLessons,
    currGroup,
    currentProject,
    setRefreshGroupLessonInfoList,
    setLessonLearningMomentCheckpointCount,
  } = useStore((state) => ({
    ...state,
    projectSlug: state.currentProject.slug,
  }));

  const entityID = assessmentID ? assessmentID : lessonID;
  const currentLessonIndex = currentLessons.lessons.findIndex(
    (lesson) => lesson.id.toString() === entityID
  );

  const isActivityDone = currScheduleObj.activities.includes(
    Number(activityID)
  );

  const [isCompletedWorkExist, setIsCompletedWorkExist] =
    useState<boolean>(false);

  const [
    lessonIdIncompleteLearningMomentList,
    setLessonIdIncompleteLearningMomentList,
  ] = useState<number[]>([]);

  // Handle modal open to prompt user upload
  const [isOpenUploadCompletedWorkPrompt, setIsOpenUploadCompletedWorkPrompt] =
    useState<boolean>(false);
  const [
    isOpenUploadLearningMomentPrompt,
    setIsOpenUploadLearningMomentPrompt,
  ] = useState<boolean>(false);

  useEffect(() => {
    const populateCompletedWorks = async () => {
      const res = await getCompletedWorkList({
        language: currLanguage,
        groupId: currGroup.id,
        projectId: currentProject.id,
      });

      if (typeof res !== "string" && res.length > 0) {
        setIsCompletedWorkExist(true);
      }
    };
    currGroup.id !== -1 && currentProject.id !== -1 && populateCompletedWorks();
  }, [currGroup.id]);

  const handleDone = async () => {
    if (!isActivityDone) {
      const res = await updateScheduleActivityProgress(
        currLanguage,
        localStorage.getItem("access"),
        currScheduleObj.id,
        Number(activityID)
      );
      if (typeof res !== "string") {
        setRefreshGroupLessonInfoList(true);
        setCurrScheduleObj(res);
        populateLessonLearningMomentCheckpointCount(
          currLanguage,
          localStorage.getItem("access")!,
          currentLessons.id,
          res.group,
          setLessonLearningMomentCheckpointCount
        );
        openSnack(`Activity is ${translation.done}!`, true);
      } else {
        openSnack(translation.pleaseSchedule, false);
        return;
      }
    }

    // Learning MomentModal thingy
    const lessonIdIncompleteLearningMomentListRes =
      await getLessonIdIncompleteLearningMomentList({
        language: currLanguage,
        groupId: currGroup.id,
        projectId: currentProject.id,
      });

    if (typeof lessonIdIncompleteLearningMomentListRes !== "string") {
      setLessonIdIncompleteLearningMomentList(
        lessonIdIncompleteLearningMomentListRes
      );
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    if (
      activityIndex <
      lesson.activities.length - 1
      // && lesson.module_category === LITTLE_LEARNERS
    ) {
      const navigateMethod = lesson.assessment
        ? ASSESSMENT_PLAN_PAGE
        : LESSON_ACTIVITY_PAGE;
      navigate(
        `${CURLANG(currLanguage)}/${navigateMethod(
          projectSlug,
          lesson.assessment ? assessmentID! : lessonID!
        )}/activity/${lesson.activities[activityIndex + 1].id.toString()}`
      );
    } else {
      const nextLesson = currentLessons.lessons[currentLessonIndex + 1];
      const nextFirstActivitySlug =
        nextLesson && nextLesson.activities.length > 0
          ? `activity/${nextLesson.activities[0].id}`
          : "";

      if (currentLessonIndex === currentLessons.lessons.length - 1) {
        if (
          isCompletedWorkExist &&
          lessonIdIncompleteLearningMomentListRes.length === 0
        ) {
          const navigateMethod = lesson.assessment
            ? ASSESSMENT_PAGE
            : PROJECT_SUMMARY_PAGE;
          navigate(
            `${CURLANG(currLanguage)}/${navigateMethod(projectSlug)}/end`
          );
        } else if (!lesson.assessment) {
          !isCompletedWorkExist && setIsOpenUploadCompletedWorkPrompt(true);

          lessonIdIncompleteLearningMomentList.length !== 0 &&
            setIsOpenUploadLearningMomentPrompt(true);
        } else {
          navigate(`${CURLANG(currLanguage)}/${LITTLE_EVALUATOR}`);
        }
      } else {
        const navigateMethod = lesson.assessment
          ? ASSESSMENT_PLAN_PAGE
          : LESSON_ACTIVITY_PAGE;
        navigate(
          `${CURLANG(currLanguage)}/${navigateMethod(
            projectSlug,
            nextLesson.id.toString()
          )}/${nextFirstActivitySlug}`
        );
      }
    }
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const buttonText = isActivityDone
    ? translation.activityCompleted
    : translation.done;

  const handleClick = (e: React.SyntheticEvent) => {
    if (currScheduleObj.id !== -1) {
      //exist then mark as done/undone
      handleDone();
    } else {
      openSnack(translation.pleaseSchedule, false);
    }
  };

  return (
    <>
      <UploadCompletedWorkPromptModal
        open={isOpenUploadCompletedWorkPrompt}
        setOpen={setIsOpenUploadCompletedWorkPrompt}
        isOpenUploadLearningMomentPrompt={isOpenUploadLearningMomentPrompt}
      />
      <UploadLearningMomentPromptModal
        open={
          isOpenUploadCompletedWorkPrompt
            ? false
            : isOpenUploadLearningMomentPrompt
        }
        setOpen={setIsOpenUploadLearningMomentPrompt}
        lessonIdIncompleteLearningMomentList={
          lessonIdIncompleteLearningMomentList
        }
      />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", zIndex: 10 }}
      >
        <Box />
        <Button
          style={{
            width: isSm ? "50%" : "20%",
            backgroundColor: "default",
            fontSize: isXs ? buttonTextMobileFontSize : buttonTextFontSize,
          }}
          buttonText={buttonText}
          arrow={false}
          onClick={handleClick}
          btnType={isActivityDone ? "outlined" : "filled"}
        />
        <Box />
      </Box>
    </>
  );
};

export default ActivityAction;

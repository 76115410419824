import React, { useEffect, useState } from "react";

import { Typography, Backdrop, Box, Modal, Fade } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CreateGroupPage from "containers/GroupPage/CreateGroupPage";
import {
  API_Profile_Details,
  API_Profile_Short,
  API_Update_Profile,
} from "types/profile";
import { API_Group_Full, API_Group_Simple } from "types/group";
import { Autocomplete, Stack, TextField } from "@mui/material";
import {
  getAccountProfile,
  getGroupListBySchool,
} from "api/profile-api";
import { TEXT_FIELD_STYLE } from "../constants/styling";
import Button from "components/Button/Button";
import { addButtonStyle } from "containers/CreateProfilePage/addButtonStyle";
import CreateGroupModal from "./CreateGroupModal";
import { translation } from "constants/translation";
import { updateProfile, updateProfileBulk } from "api/auth-api";
import {
  ErrorMessage,
  UpdateProfile,
  UpdateProfileResponse,
} from "types/auth";

import useSnack from "hooks/useSnack";
import { initialProfileDetails } from "store/auth-slice";

const EditGroupModal = ({
  open,
  handleClose,
  profileId,
  setRows,
  selectionModel,
  setSelectionModel,
  bulkUpdateClasses,
  names,
  setBulkUpdateLoading,
}: {
  open: boolean;
  handleClose: () => void;
  profileId: number;
  setRows: React.Dispatch<React.SetStateAction<API_Profile_Short[]>>;
  selectionModel?: any[];
  setSelectionModel?: React.Dispatch<React.SetStateAction<any[]>>;
  bulkUpdateClasses?: any[];
  names?: string[];
  setBulkUpdateLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setBulkUpdateClasses?: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const openSnack = useSnack();
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [profileDetails, setProfileDetails] = useState<API_Profile_Details>({
    ...initialProfileDetails,
    school: {
      id: -1,
      is_bypass_payment: false,
      is_demo_school: false,
      name: "",
      slug: "",
    },
  });
  const [groupListDisplay, setGroupListDisplay] = useState<API_Group_Simple[]>(
    []
  );
  interface Groups {
    groups: number[];
  }

  const [selectedGroups, setSelectedGroups] = useState<Groups>({
    groups: [],
  });
  useEffect(() => {
    const populateData = async () => {
      if (profileId !== -1) {
        const resProfile = await getAccountProfile(
          String(profileId),
          localStorage.accessToken
        );

        if (typeof resProfile !== "string" && resProfile.school !== undefined) {
          setProfileDetails(resProfile);
          if (
            resProfile.groups !== undefined &&
            bulkUpdateClasses?.length == 0
          ) {
            setSelectedGroups({
              groups: resProfile.groups.map((group) => group.id),
            });
          } else {
            if (bulkUpdateClasses) {
              if (
                resProfile.groups !== undefined &&
                bulkUpdateClasses?.length > 0
              ) {
                setSelectedGroups({
                  groups: bulkUpdateClasses,
                });
              }
            }
          }

          const resGroup = await getGroupListBySchool(resProfile.school.id);
          if (typeof resGroup !== "string") {
            console.log(resGroup);
            setGroupListDisplay(resGroup);
          }
        }
      }
    };
    populateData();
  }, [profileId]);

  const handleUpdate = async () => {
    if (selectedGroups.groups.length === 0) {
      selectedGroups.groups = [-1];
    }
    if (selectionModel?.length == 0 || selectionModel?.length == 1) {
      console.log("bulkUpdateClasses: 0");
      const updateResponse: UpdateProfileResponse | ErrorMessage =
        await updateProfile(
          profileId.toString(),
          selectedGroups as UpdateProfile,
          localStorage.getItem("access")
        );

      if ("error" in updateResponse) {
        openSnack(updateResponse.error.join(" "), false);
      } else {
        openSnack(`${profileDetails.preferred_name}'s group is updated!`, true);
        setRows((prev) => {
          const prev_copy: API_Profile_Short[] = JSON.parse(
            JSON.stringify(prev)
          );
          prev_copy[
            prev_copy.indexOf(
              prev_copy.filter((p) => p.id === updateResponse.id)[0]
            )
          ] = {
            ...updateResponse,
            groups: groupListDisplay.filter((group) =>
              selectedGroups.groups.includes(group.id)
            ),
            school: updateResponse.school,
          };
          return [...prev_copy];
        });
        handleClose();
      }
    } else {
      if (setBulkUpdateLoading) {
        setBulkUpdateLoading(true);
      }

      const updateBulkResponse: any = await updateProfileBulk(
        selectionModel,
        selectedGroups as UpdateProfile,
        localStorage.getItem("access")
      );
      console.log(updateBulkResponse);
      if ("error" in updateBulkResponse) {
        openSnack(updateBulkResponse.error.join(" "), false);
      } else {
        openSnack("Groups updated!", true);
      }
      handleClose();
      if (setSelectionModel) {
        setSelectionModel([]);
      }
      if (setBulkUpdateLoading) {
        setBulkUpdateLoading(false);
      }
    }
  };

  return (
    <>
      <CreateGroupModal
        open={openGroupModal}
        handleClose={() => setOpenGroupModal(false)}
        schoolId={profileDetails.school ? profileDetails.school.id : -1}
        setFormData={setSelectedGroups}
        setGroupListDisplay={setGroupListDisplay}
      />
      <Modal
        aria-labelledby="transition-modal-create-group"
        aria-describedby="transition-modal-create-group-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              p: 4,
              borderRadius: "10px",
            }}
          >
            {names && names.length > 0 ? (
              <>
                <Typography sx={{ fontWeight: "450", fontSize: "20px", mb: 1 }}>
                  Adding:{" "}
                </Typography>
                <Box
                  sx={{
                    maxHeight: "108px",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                  }}
                >
                  {names.map((name: any) => {
                    return <Typography>{name}</Typography>;
                  })}
                </Box>

                <Typography sx={{ fontWeight: "450", fontSize: "20px", mt: 1 }}>
                  To:{" "}
                </Typography>
              </>
            ) : null}
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Box>

            <Stack
              component="form"
              sx={{ margin: "auto", my: "16px", alignItems: "center" }}
              spacing={1}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  multiple
                  key={`${groupListDisplay
                    .map((group) => group.id)
                    .join("-")} + ${selectedGroups.groups.join("-")}`}
                  sx={{ ...TEXT_FIELD_STYLE }}
                  disablePortal
                  id="combo-box-groups"
                  onChange={(
                    event: any,
                    groups_updated: API_Group_Simple[] | null
                  ) => {
                    if (groups_updated !== null) {
                      setSelectedGroups({
                        groups: groups_updated?.map((group) => group.id),
                      });
                    }
                  }}
                  options={groupListDisplay}
                  getOptionLabel={(group) => group.name}
                  // renderOption={(props, option) => option.name}
                  value={groupListDisplay.filter((group) =>
                    selectedGroups.groups.includes(group.id)
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  renderInput={(params) => {
                    return (
                      <TextField {...params} label="Groups" placeholder="" />
                    );
                  }}
                />
                <Button
                  style={{
                    ...addButtonStyle,
                    marginLeft: 0,
                  }}
                  arrow={false}
                  buttonText={`+`}
                  onClick={() => {
                    setOpenGroupModal(true);
                  }}
                />
              </Box>

              <Button
                buttonText={translation.update ? translation.update : "Update"}
                style={{ marginTop: "20px" }}
                onClick={handleUpdate}
              />
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EditGroupModal;

import React, { useEffect, useState } from "react";
import { PROFILE_SECTION_CERTIFICATIONS } from "../constants";
import { slugify } from "helper/slugify";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useStore from "store/store";
import moment from "moment";
import { translation } from "constants/translation";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { deleteCertification } from "api/profile-api";
import useSnack from "hooks/useSnack";
import { API_Certification } from "types/profile";
import CustomButton from "components/Button/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";
import { CERTIFICATE } from "constants/url";

const CertificationComponent = ({
  setIsModalOpen,
  setType,
  isOwner,
  setSelectedCertification,
}: {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  isOwner: boolean;
  setSelectedCertification: React.Dispatch<
    React.SetStateAction<API_Certification>
  >;
}) => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "Delete Certification?",
    confirmDelete: false,
  });

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Certification?",
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  const handleDeleteClick = (experience: API_Certification) => {
    //call delete api
    handleOpenDialog();
    setWarningBody({
      id: experience.id,
      warningTitle: `Delete Certification "${experience.name}"?`,
      confirmDelete: false,
    });
  };

  const handleDelete = (id: number) => {
    //call delete api
    const callDeleteCertification = async () => {
      const res = await deleteCertification(id);
      if (typeof res === "string" && res.length >= 1) {
        //if delete success, it will return ""
        openSnack(res, false);
      } else {
        openSnack(`Delete Certification successfully!`, true);
        const filteredCertification =
          visitedProfileDetails.certifications?.filter(
            (experience) => experience.id !== id
          );
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          certifications: filteredCertification,
        });
      }
    };

    callDeleteCertification();
  };

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <Card id={slugify(PROFILE_SECTION_CERTIFICATIONS)} sx={{ width: "100%" }}>
        <CardHeader
          sx={{ backgroundColor: "#d0e3e2bd" }}
          title={
            <Typography
              sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 500 }}
            >
              {translation.profileSectionCertifications}
            </Typography>
          }
          action={
            <IconButton
              aria-label="New"
              onClick={() => {
                setType(slugify(PROFILE_SECTION_CERTIFICATIONS));
                setIsModalOpen(true);
              }}
            >
              {isOwner && <AddIcon />}
            </IconButton>
          }
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            {!!visitedProfileDetails.certifications?.length
              ? visitedProfileDetails.certifications.map(
                  (certification: API_Certification) => {
                    const isURLExist =
                      certification.credential_url || certification.certificate;

                    return (
                      <Box key={certification.id} sx={{ position: "relative" }}>
                        <IconButton
                          onClick={() => {
                            setIsModalOpen(true);
                            setType(slugify(PROFILE_SECTION_CERTIFICATIONS));
                            setSelectedCertification(certification);
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 40,
                          }}
                        >
                          {isOwner && !certification.certificate && (
                            <EditIcon
                              sx={{
                                cursor: "pointer",
                                color: "txt.secondary",
                                transition: "all 100ms ease-in-out",
                                "&:hover": {
                                  color: "primary.main",
                                  transition: "all 100ms ease-in-out",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleDeleteClick(certification);
                          }}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                          }}
                        >
                          {isOwner && !certification.certificate && (
                            <DeleteIcon
                              sx={{
                                cursor: "pointer",
                                color: "txt.secondary",
                                transition: "all 100ms ease-in-out",
                                "&:hover": {
                                  color: "primary.main",
                                  transition: "all 100ms ease-in-out",
                                },
                              }}
                            />
                          )}
                        </IconButton>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h5">
                            {certification.name}
                          </Typography>
                          <Typography variant="body1">
                            {`${certification.issuing_organization}`}
                          </Typography>
                          {certification.issue_date && (
                            <Typography variant="body1" color="text.secondary">
                              {`Issued ${moment(
                                certification.issue_date
                              ).format("MMM YYYY")}`}
                            </Typography>
                          )}

                          <Typography variant="body1" color="text.secondary">
                            {certification.credential_id}
                          </Typography>
                          {isURLExist && (
                            <>
                              {!!certification.certificate ? (
                                <CustomButton
                                  buttonText={
                                    translation.showCredential ||
                                    "Show Credential"
                                  }
                                  icon={<LaunchIcon />}
                                  arrow={false}
                                  style={{
                                    maxHeight: "35px",
                                    marginTop: 0.5,
                                    flexDirection: "row-reverse",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `${CERTIFICATE}/${certification.credential_id}`
                                    );
                                  }}
                                />
                              ) : (
                                <Box
                                  component="a"
                                  href={
                                    certification.credential_url ||
                                    certification.certificate!
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  sx={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    alignSelf: "flex-start",
                                    "&:hover": {
                                      transform: " translateX(3px)",
                                      transition: "all 100ms ease-in-out",
                                    },
                                    transition: "all 100ms ease-in-out",
                                  }}
                                >
                                  <CustomButton
                                    buttonText={
                                      translation.showCredential ||
                                      "Show Credential"
                                    }
                                    icon={<LaunchIcon />}
                                    arrow={false}
                                    style={{
                                      maxHeight: "35px",
                                      marginTop: 0.5,
                                      flexDirection: "row-reverse",
                                      pointerEvents: "none",
                                    }}
                                  />
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    );
                  }
                )
              : isOwner
              ? translation.noCertifications
              : ""}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CertificationComponent;

import { Page, Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { pageSize, styles, imageSrc } from "./constant";
import { PDFInfoType } from "../Preview/constant";
import Background from "images/children-portfolio-v2/front-page-bg.png";

const templateStyles = StyleSheet.create({
  text: {
    fontWeight: 500,
    lineHeight: 1.25,
    textAlign: "center",
    position: "absolute",
    fontFamily: "FuturaMediumBT",
    left: 0,
    right: 0,
  },
});

const FrontPage = ({ PDFInfo }: { PDFInfo: PDFInfoType }) => {
  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image
          src={imageSrc(PDFInfo.profileImage)}
          style={{
            position: "absolute",
            top: 216,
            left: 72,
            width: 463,
            height: 463,
            objectFit: "cover",
          }}
        />

        <Image src={imageSrc(Background)} style={styles.background} />

        <Text
          style={[
            templateStyles.text,
            {
              fontSize: 24,
              bottom: 100,
              marginHorizontal: 40,
            },
          ]}
        >
          {PDFInfo.profileName}
        </Text>

        <Text
          style={[
            templateStyles.text,
            {
              fontSize: 16,
              bottom: 75,
              color: "#98C4C5",
            },
          ]}
        >
          {PDFInfo.startMonth} - {PDFInfo.endMonth}
        </Text>
      </View>
    </Page>
  );
};

export default FrontPage;

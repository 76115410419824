import { Avatar, Box } from "@mui/material";
import Logo from "images/logo-2-colour.png";
import React from "react";

const AvatarComponent = ({
  imgsrc,
  letter,
}: {
  imgsrc: string;
  letter: string;
}) => {
  return (
    <Box sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}>
      <Avatar
        src={imgsrc}
        sx={{
          width: 50,
          height: 50,
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "1px solid",
          borderColor: "primary.main",
          fontSize: "2vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {/* {letter} */}
      </Avatar>
 
    </Box>
  );
};

export default AvatarComponent;

     {/* To be improved in future to link the id to the backend */}
      {/* <input
        accept="image/*"
        type="file"
        id={`image-button`}
        hidden
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            // setCurrentGroupData((prev) => ({
            //   ...prev,
            //   image: event.target.files![0],
            // }));
          }
        }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "primary.main",
          opacity: 0,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
            transition: "all 150ms linear",
          },
        }}
      >
        <label htmlFor={`image-button`}>
          <IconButton
            component="span"
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: "1vw",
                color: "primary.light",
                cursor: "pointer",
                transition: "all 50ms linear",
                "&:hover": {
                  color: "txt.secondary",
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box> */}
import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import Frame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";
import { checkIsChineseChar } from "helper/helper";

interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "24px",
  },
  projectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:'center',
    height: 600,
    width: 400,
    marginBottom: 20,
  },
  imageFrame: {
    position: "absolute",
    top: 80, 
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  projectImage: {
    top:-95, 
    left: -4,
    width: "86%",
    height: "34%",

    objectFit: "cover",
  },
  frameImage: {
    justifySelf: "center",
    position: "absolute",
    width: "70%",
    height: "69%",
    top: -2,
    left: "15%",
    transform: "rotate(90deg)"

  },
  captionContainer: {
    top: 140,
    width: "100%",
    height: 50,
    backgroundColor: "#F9F6F1",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingTop: 5,
  },
  categoryText: {
    fontSize: 12,
    color: "#000",
    fontWeight: 400,
  },
  titleText: {
    fontSize: 10,
    color: "#000",
    fontWeight: 300,
  },
});

export const ImageStructureForOne: React.FC<ImageStructureProps> = ({
  projects,
}) => {
  return (
    <View style={styles.container}>
      {projects.map((project, index) => {
        const isChineseCategory = checkIsChineseChar(project.category);
        const isChineseTitle = checkIsChineseChar(project.title);

        return (
          <View key={index} style={styles.projectContainer}>
            <View style={styles.imageFrame}>
              <Image
                style={styles.projectImage}
                src={{
                  uri: project.image_url,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
              <Image style={styles.frameImage} src={Frame} />
            </View>
            <View style={styles.captionContainer}>
              <Text
                style={[
                  styles.categoryText,
                  {
                    fontFamily: isChineseCategory ? "KaiTi" : "FuturaBkBT",
                  },
                ]}
              >
                {project.category}
              </Text>
              <Text
                style={[
                  styles.titleText,
                  {
                    fontFamily: isChineseTitle ? "KaiTi" : "FuturaBkBT",
                  },
                ]}
              >
                {project.title}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default ImageStructureForOne;

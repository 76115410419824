import React, { useState } from "react";
import { API_Teacher_Module_Visualization } from "types/teacher";

import { Box, Typography } from "@mui/material";
import AgeGenderBarChart from "./AgeGenderBarChart";
import HighestEducationQualificationChart from "./HighestEducationQualificationChart";
import YearsOfExperienceChart from "./YearsOfExperienceChart";
import MapChart from "./MapChart";
import ModuleRatingChart from "./ModuleRatingChart";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

export const responsiveContainerStyle = {
  width: "100%",
  height: "100%",
  minHeight: 350,
};

const VisualizationPage = ({
  moduleVisualizationInfo,
}: {
  moduleVisualizationInfo: API_Teacher_Module_Visualization;
}) => {
  const gridItemStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 350,
    minWidth: 0,
  };
  const [isExpandMap, setIsExpandMap] = useState<boolean>(false);
  // console.log("moduleVisualizationInfo", moduleVisualizationInfo);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: {
          lg: "repeat(2, 1fr)",
          xs: "1fr",
        },
        gridRowGap: 32,
        gridColumnGap: 0,
      }}
    >
      <Box
        sx={{
          ...gridItemStyle,
          gridColumn: {
            xs: 1,
            lg: "span 2",
          },

          maxHeight: isExpandMap ? 500 : 300,
          position: "relative",
          mb: 1,
        }}
      >
        <Typography sx={{}}>Learner's Distribution</Typography>
        <MapChart
          data={moduleVisualizationInfo.demographic_info.filter(
            (info) => !!info.country
          )}
        />
        <Box sx={{ position: "absolute", top: 0, right: 0 }}>
          {isExpandMap ? (
            <CloseFullscreenIcon
              onClick={() => setIsExpandMap(!isExpandMap)}
              sx={{ color: "primary.main", cursor: "pointer" }}
            />
          ) : (
            <OpenInFullIcon
              onClick={() => setIsExpandMap(!isExpandMap)}
              sx={{ color: "primary.main", cursor: "pointer" }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          ...gridItemStyle,
        }}
      >
        <Typography sx={{}}>Highest Rated Module All Time</Typography>
        <ModuleRatingChart
          data={moduleVisualizationInfo.module_avg_rating_list}
        />
      </Box>
      <Box
        sx={{
          ...gridItemStyle,
        }}
      >
        <Typography sx={{}}>Learner's Age Distribution</Typography>
        <AgeGenderBarChart data={moduleVisualizationInfo.demographic_info} />
      </Box>
      <Box
        sx={{
          ...gridItemStyle,
        }}
      >
        <Typography sx={{}}>Highest Education Qualification</Typography>
        <HighestEducationQualificationChart
          data={moduleVisualizationInfo.demographic_info}
        />
      </Box>
      <Box
        sx={{
          ...gridItemStyle,
        }}
      >
        <Typography sx={{}}>Years of experience</Typography>
        <YearsOfExperienceChart
          data={moduleVisualizationInfo.demographic_info}
        />
      </Box>
    </Box>
  );
};

export default VisualizationPage;

import { Document, Font } from "@react-pdf/renderer";
import KaiTi from "fonts/KaiTi.ttf";
import FuturaBkBT from "fonts/FuturaBkBT.ttf";
import FuturaMediumBT from "fonts/FuturaMediumBT.ttf";
import FuturaLtBT from "fonts/FuturaLtBT.ttf";
import TheOnlyException from "fonts/TheOnlyException.ttf";

import { PDFInfoType } from "./Preview/constant";

import FrontPage from "./Templates/FrontPage";

//Register Font
Font.register({
  family: "Jost",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "medium",
    },
  ],
});

Font.register({
  family: "FuturaLtBT",
  fonts: [
    {
      src: FuturaLtBT,
    },
  ],
});

Font.register({
  family: "FuturaMediumBT",
  fonts: [
    {
      src: FuturaMediumBT,
    },
  ],
});

Font.register({
  family: "FuturaBkBT",
  fonts: [
    {
      src: FuturaBkBT,
    },
  ],
});

Font.register({
  family: "TheOnlyException",
  src: TheOnlyException,
});

Font.register({
  family: "KaiTi",
  src: KaiTi,
});

const DraftChildrenPortfolioPDF = ({
  PDFInfo,
  currentLanguage,
}: {
  PDFInfo: PDFInfoType;
  currentLanguage: string;
}) => {
  return (
    <Document title={PDFInfo.profileName} language={currentLanguage}>
      <FrontPage PDFInfo={PDFInfo} />
    </Document>
  );
};

export default DraftChildrenPortfolioPDF;

import { Badge, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import useStore from "store/store";
import { CURLANG, CHATHOME } from "constants/url";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

const ChatButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isChatPage = location.pathname.includes(`/${CHATHOME}`);

  const { currLanguage, unseenChatCount } = useStore((state) => state);

  return (
    <Box
      onClick={() => navigate(`${CURLANG(currLanguage)}/${CHATHOME}`)}
      sx={{
        display: "flex",
        mr: { xs: 0, lg: "10px" },
        cursor: isChatPage ? "default" : "pointer",
        pointerEvents: isChatPage ? "none" : "auto",
        alignItems: "center",
        backgroundColor: isChatPage ? "white" : "#DFDDDD",
        p: 1,
        borderRadius: `10px`,
        "&:hover": {
          backgroundColor: `white`,
        },
      }}
    >
      <Badge color="info" badgeContent={unseenChatCount} max={9}>
        <QuestionAnswerOutlinedIcon fontSize="small" />
      </Badge>
    </Box>
  );
};

export default ChatButton;

import { Box, Typography } from "@mui/material";
import Image from "components/Image/Image";
import {
  cardStyles,
  labelStyles,
} from "../PhotoAlbumScreenComponents/PhotoAlbumComponents";
import { API_Child } from "types/portfolio";
import VideoDurationDisplay from "components/PhotoVideoGalleryComponents/VideoDurationDisplay";
import { secondToMinuteAndSecondString } from "helper/helper";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CustomImage from "components/Image/Image";
import Logo from "images/logo-2.png";

import ArticleIcon from "@mui/icons-material/Article";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ImageIcon from "@mui/icons-material/Image";
import ModuleTag from "components/ModuleTag/ModuleTag";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type FileType =
  | "Image"
  | "Video"
  | "Audio"
  | "PDF"
  | "Document"
  | "Unknown";

export function getFileTypeFromExtention(url: string): FileType {
  // Extract the file extension
  const extension = url.split(".").pop()!.toLowerCase();

  // Define the file types
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const videoExtensions = ["mp4", "webm", "ogg"];
  const audioExtensions = ["mp3", "wav", "ogg"];
  const pdfExtensions = ["pdf"];
  const documentExtensions = ["pdf", "doc", "docx", "ppt", "pptx", "txt"];

  // Check the extension against the file types
  if (imageExtensions.includes(extension)) {
    return "Image";
  } else if (videoExtensions.includes(extension)) {
    return "Video";
  } else if (audioExtensions.includes(extension)) {
    return "Audio";
  } else if (pdfExtensions.includes(extension)) {
    return "PDF";
  } else if (documentExtensions.includes(extension)) {
    return "Document";
  } else {
    return "Unknown";
  }
}

export function getFileTypeFromFile(file: File): FileType {
  const fileType = file.type;
  // Check the extension against the file types
  if (fileType.includes("image")) {
    return "Image";
  } else if (fileType.includes("video")) {
    return "Video";
  } else if (fileType.includes("audio")) {
    return "Audio";
  } else if (fileType.includes("pdf")) {
    return "PDF";
  } else if (fileType.includes("officedocument") || fileType.includes("text")) {
    return "Document";
  } else {
    return "Unknown";
  }
}
export function getFileTypeFromType(fileType: string) {
  // Check the extension against the file types
  if (fileType.includes("image")) {
    return "Image";
  } else if (fileType.includes("video")) {
    return "Video";
  } else if (fileType.includes("audio")) {
    return "Audio";
  } else if (fileType.includes("pdf")) {
    return "PDF";
  } else if (fileType.includes("officedocument") || fileType.includes("text")) {
    return "Document";
  } else {
    return "Unknown";
  }
}

const CompletedWorkCard = ({
  completedWork,
  fromNow,
  isChildPortfolio = false,
  onClick,
}: {
  completedWork: API_Child.CompletedWork;
  fromNow?: string;
  isChildPortfolio?: boolean;
  onClick: () => void;
}) => {
  const fileType: FileType = getFileTypeFromExtention(completedWork.file);

  const videoDuration: any = document.getElementById(
    `video-${completedWork.id}`
  );
  const iconStyle = {
    color: "primary.main",
  };

  return (
    <Box
      sx={{
        ...cardStyles,
        width: {
          xs: 150,
          sm: 200,
        },
        height: {
          xs: 150,
          sm: 200,
        },
      }}
      onClick={onClick}
    >
      <CardFileHandler completedWork={completedWork} />
      {/* Project info start */}
      {isChildPortfolio && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 1,
          }}
        >
          <Box>
            <ModuleTag
              projModule={completedWork.project.module}
              styles={{
                backgroundColor: "rgba(209,228,227,0.75)",
              }}
              moduleIconWidth="16px"
              moduleIconHeight="16px"
              containerPadding="3px 6px"
            />
          </Box>
        </Box>
      )}
      {/* Project info end */}
      <Box sx={{ position: "absolute", top: 8, right: 8 }}>
        {fileType === "Image" ? (
          <ImageIcon sx={iconStyle} />
        ) : fileType === "Video" ? (
          videoDuration ? (
            <VideoDurationDisplay
              duration={
                isNaN(videoDuration.duration)
                  ? ""
                  : secondToMinuteAndSecondString(videoDuration.duration)
              }
            />
          ) : (
            <PlayCircleIcon sx={iconStyle} />
          )
        ) : fileType === "Audio" ? (
          <AudioFileIcon sx={iconStyle} />
        ) : fileType === "PDF" ? (
          <PictureAsPdfIcon sx={iconStyle} />
        ) : fileType === "Document" ? (
          <ArticleIcon sx={iconStyle} />
        ) : (
          <ArticleIcon sx={iconStyle} />
        )}
      </Box>
      {(completedWork.caption || fromNow) && (
        <Box
          sx={{
            ...labelStyles,
            bottom: 0,
            width: "100%",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0.75,
          }}
        >
          <Typography sx={{ fontSize: 14, lineHeight: 1 }} noWrap>
            {isChildPortfolio
              ? completedWork.project.name
              : completedWork.caption
              ? completedWork.caption
              : ""}
          </Typography>

          {fromNow && (
            <Typography
              sx={{
                fontSize: 10,
                lineHeight: 1,
                fontWeight: 700,
                color: "#312F30",
              }}
              noWrap
            >
              {fromNow}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

const CardFileHandler = ({
  completedWork,
}: {
  completedWork: API_Child.CompletedWork;
}) => {
  let fileType: FileType = getFileTypeFromExtention(completedWork.file);
  if (fileType == "Unknown") {
    fileType = getFileTypeFromType(completedWork.original_type);
  }

  return (
    <>
      {fileType === "Video" ? (
        <video
          id={`video-${completedWork.id}`}
          width="100%"
          height="100%"
          preload="metadata"
          style={{
            objectFit: "cover",
            borderRadius: "10px",
          }}
        >
          <source src={completedWork.file} type="video/mp4" />
        </video>
      ) : fileType === "Audio" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AudioFileIcon
            sx={{ color: "primary.main", width: 150, height: 150 }}
          />
        </Box>
      ) : fileType === "PDF" ? (
        <PDFPreviewComponent
          file={completedWork.file}
          // style={{ width: 100, height: 100, ...style }}
        />
      ) : fileType === "Image" ? (
        <Image
          src={completedWork.file}
          alt={completedWork.caption ? completedWork.caption : ""}
          objectFit="cover"
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArticleIcon
            sx={{ color: "primary.main", width: 150, height: 150 }}
          />
        </Box>

        // <iframe
        //   src={`https://docs.google.com/gview?url=${completedWork.file}&embedded=true`}
        // ></iframe>
      )}
    </>
  );
};

export const PDFPreviewComponent = ({ file }: { file: string }) => {
  const fileName = decodeURIComponent(
    file.split("/").pop()!.toLowerCase().replace(/\s/g, "").split(".")[0]
  );
  const [loading, setLoading] = useState(true);
  const [pdfAsImageSrc, setPdfAsImageSrc] = useState<string>("");

  const onDocumentLoadSuccess = () => {
    const importPDFCanvas: HTMLCanvasElement = document.querySelector(
      `.import-pdf-page-${fileName} canvas`
    )!;
    setPdfAsImageSrc(importPDFCanvas.toDataURL());
  };
  return (
    <>
      <Box sx={{ display: "None" }}>
        <Document
          file={file}
          onLoadSuccess={() => setLoading(false)}
          onLoadError={console.error}
        >
          {!loading && (
            <Page
              pageNumber={1}
              className={`import-pdf-page-${fileName}`}
              onRenderSuccess={() => {
                onDocumentLoadSuccess();
              }}
            />
          )}
        </Document>
      </Box>

      {pdfAsImageSrc.length > 0 ? (
        <Box
          sx={{ marginTop: "-20px", display: "flex", justifyContent: "center" }}
        >
          {" "}
          <CustomImage
            src={pdfAsImageSrc}
            alt={"success"}
            style={{
              objectFit: "cover",
              maxWidth: "120%",
              maxHeight: "120%",
              width: "auto",
              height: "auto",
            }}
          />
        </Box>
      ) : (
        <CustomImage
          src={Logo}
          alt={"error"}
          style={{ backgroundSize: "0%" }}
        />
      )}
    </>
  );
};

export default CompletedWorkCard;

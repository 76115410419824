import React, { useState, MouseEvent, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Popover } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

import { API_School } from "types/school";
import Image from "components/Image/Image";
import {
  PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG,
  CURLANG,
} from "constants/url";
import useStore from "store/store";
import { translation } from "constants/translation";
import { overviewSchool } from "./ManageSchoolProjectPage";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Query_Data } from "types/project";
import { ACCESS_CONTROL } from "constants/staticConstant";

const MiniDrawer = ({
  schools,
  currSchool,
  setQueryData,
  setSelected,
}: {
  schools: API_School[];
  currSchool: API_School;
  setQueryData: (value: React.SetStateAction<Query_Data>) => void;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { pageModeSlug } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen((prev) => !prev);

  const handleChangeSchool = (schindex: number) => {
    setQueryData((prev) => ({ ...prev, query: null }));
    setSelected([]);
    if (schindex === -1) {
      navigate(
        `${CURLANG(currLanguage)}/${PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG(
          overviewSchool.slug
        )}/${pageModeSlug ? pageModeSlug : ACCESS_CONTROL}`
      );
    } else {
      navigate(
        `${CURLANG(currLanguage)}/${PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG(
          schools[schindex].slug
        )}/${pageModeSlug ? pageModeSlug : ACCESS_CONTROL}`
      );
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        maxWidth: "300px",
        flex: open ? "1 0 auto" : "0 0 auto",
        p: 2,
        pt: "21px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
        alignSelf: "flex-start",
        position: "sticky",
        top: "64px",
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mb: 1,
        }}
      >
        {open && (
          <Typography
            sx={{
              fontWeight: "bold",
              lineHeight: 1,
              mr: "auto",
            }}
          >
            {translation.schools}
          </Typography>
        )}

        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            m: 0.5,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
            },
          }}
          onClick={handleDrawer}
        >
          {open ? (
            <CloseIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {/* Overall button */}
        <DrawerButton
          index={-1}
          currSchool={currSchool}
          school={overviewSchool}
          handleChangeSchool={handleChangeSchool}
          open={open}
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
        />

        {schools.map((school, index) => (
          <Fragment key={`${index} - ${school.name}`}>
            <DrawerButton
              index={index}
              currSchool={currSchool}
              school={school}
              handleChangeSchool={handleChangeSchool}
              open={open}
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
            />
          </Fragment>
        ))}
      </Box>

      {!open && name !== "" && (
        <Popover
          sx={{
            pointerEvents: "none",
            borderRadius: "10px",
            ml: 1,
          }}
          elevation={2}
          open={openPopOver}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              color: "#444",
              p: 1.5,
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
        </Popover>
      )}
    </Box>
  );
};

export const DrawerButton = ({
  index,
  currSchool,
  school,
  handleChangeSchool,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}: {
  index: number;
  currSchool: API_School;
  school: API_School;
  handleChangeSchool: (schindex: number) => void;
  open: boolean;
  handlePopoverOpen: (event: MouseEvent<HTMLElement>, name: string) => void;
  handlePopoverClose: () => void;
}) => {
  return (
    <Box
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 1,
        cursor: currSchool.id === school.id ? "default" : "pointer",
      }}
      onClick={() =>
        currSchool.id === school.id ? {} : handleChangeSchool(index)
      }
      onMouseEnter={(e) => handlePopoverOpen(e, school.name)}
      onMouseLeave={() => handlePopoverClose()}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          p: 0.5,
          width: "40px",
          height: "40px",
          borderRadius: "999px",
          border: "1px solid",
          borderColor: currSchool.id === school.id ? "#98C4C5" : "#F0F0F0",
          backgroundColor:
            currSchool.id === school.id ? "primary.light" : "var(--txt-light)",
        }}
      >
        {index === -1 ? (
          <FolderOpenIcon
            sx={{
              color: currSchool.id === school.id ? "white" : "primary.main",
              width: "25px",
              height: "25px",
            }}
          />
        ) : (
          <Image
            src={school.image ? school.image : undefined}
            alt={school.name}
            objectFit="cover"
            style={{
              overflow: "hidden",
              borderRadius: "999px",
            }}
          />
        )}
      </Box>

      {open && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: currSchool.id === school.id ? "bold" : "normal",
            color: currSchool.id === school.id ? "primary.main" : "#444",
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {school.name}
        </Typography>
      )}
    </Box>
  );
};

export default MiniDrawer;

import React, { useState } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import Button from "components/Button/Button";

import RichCKEditor from "components/RichCKEditor/RichCKEditor";
import { translation } from "constants/translation";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import { API_Create_Ticket } from "types/ticketingSystem";
import { createTicket } from "api/ticketingSystem-api";
import useSnack from "hooks/useSnack";
import { useNavigate } from "react-router-dom";
import "./ticketPage.css";

const CreateTicketPage = () => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<API_Create_Ticket>({
    category: "IT",
    name: "",
    description: "",
  });

  const handleSubmit = async () => {
    const res = await createTicket(formData);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(`Thank you for your feedback!`, true);
      navigate(-1);
    }
  };

  // console.log("formData: ", formData);

  return (
    <>
      <Box sx={{ minHeight: "71vh", p: "16px" }}>
        <Stack
          component="form"
          sx={{
            width: "80vw",
            margin: "auto",
            my: "16px",
            alignItems: "left",
          }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h3" sx={{ mb: "8px" }}>
            {translation.itSupportFeedback}
          </Typography>
          <TextField
            fullWidth
            required
            label={translation.subject}
            value={formData.name}
            onChange={(event) => {
              setFormData((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
          />
          <div id="ticket-ckeditor" key={`description`}>
            <Typography sx={{ mb: "8px" }}>
              {translation.description}
            </Typography>
            <RichCKEditor
              data={formData.description}
              onChange={(event: any, editor: any) => {
                const data: string = editor.getData();
                setFormData((prev) => ({
                  ...prev,
                  description: data,
                }));
              }}
            />
          </div>
          <Button
            buttonText={translation.submit}
            style={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={
              formData.name === "" ||
              formData.description === "" ||
              formData.category === ""
            }
          />
        </Stack>
      </Box>
    </>
  );
};

export default CreateTicketPage;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "store/store";

import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";

import { translation } from "constants/translation";
import { Box } from "@mui/system";
import ModuleTag from "components/ModuleTag/ModuleTag";

import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Tooltip, Typography } from "@mui/material";
import { CURLANG, EDIT_PROJECT_PAGE } from "constants/url";

import Image from "components/Image/Image";
import { isProfileInProjectEditableRoleList } from "helper/helper";
import {
  BLOOMS,
  BLOSSOMS,
  BUDS,
  SPROUTS,
  ageGroupExternalMap,
} from "constants/project-lesson-activity";
import { SPROUTLINGS } from "constants/project-lesson-activity";

const Title = () => {
  const {
    currLanguage,
    projModule,
    title,
    age,
    noLessons,
    projectSlug,
    profileDetails,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    projModule: state.currentProject.module,
    title: state.currentProject.name,
    age: state.currentProject.age,
    noLessons: state.currentLessons.lessons.length,
    projectSlug: state.currentProject.slug,
    profileDetails: state.profileDetails,
  }));

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isEndOfProject =
    pathname.endsWith("/summary/end") || pathname.endsWith("/summary/report");

  return (
    <Box>
      <Box sx={{ mb: "12px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ModuleTag projModule={projModule} />

          {!isEndOfProject &&
            isProfileInProjectEditableRoleList(profileDetails) && (
              <Tooltip title="Edit Project">
                <EditIcon
                  sx={{
                    cursor: "pointer",
                    color: "txt.secondary",
                    ml: "16px",
                    mb: "10px",
                    transition: "all 100ms ease-in-out",
                    "&:hover": {
                      color: "primary.main",
                      transition: "all 100ms ease-in-out",
                    },
                  }}
                  onClick={() => {
                    navigate(
                      `${CURLANG(currLanguage)}/${EDIT_PROJECT_PAGE(
                        projectSlug
                      )}`
                    );
                  }}
                />
              </Tooltip>
            )}
        </Box>

        <Typography
          sx={{
            fontWeight: "500",
            whiteSpace: "normal",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "12px",
          my: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
          }}
        >
          <Tooltip title={translation.totalNumberLessons}>
            <AccessTimeIcon
              sx={{
                fontSize: "2rem",
              }}
            />
          </Tooltip>
          <Typography
            sx={{
              lineHeight: 1.1,
              fontSize: 14,
              textAlign: "center",
            }}
          >
            {noLessons}
            <br />
            {translation.lessons}
          </Typography>
        </Box>

        <Box
          sx={{
            borderLeft: "1px solid black",
          }}
        ></Box>
        <AgeDisplayComponent age={age} />
      </Box>
    </Box>
  );
};

export const AgeDisplayComponent = ({ age }: { age: string }) => {
  let ageGroupImgSrc = "";
  let translatedAge = "";

  if (age === BLOSSOMS || age === BLOOMS) {
    ageGroupImgSrc = BlossomsIcon;
    translatedAge = translation.Blossoms;
  } else if (age === BUDS) {
    ageGroupImgSrc = BudsIcon;
    translatedAge = translation.Buds;
  } else if (age === SPROUTS || age === SPROUTLINGS) {
    ageGroupImgSrc = SproutsIcon;
    translatedAge = translation.Sprouts;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
      }}
    >
      <Tooltip title="Level">
        <Box sx={{ mt: "2px" }}>
          <Image
            style={{
              width: "32px",
              height: "32px",
            }}
            src={ageGroupImgSrc}
            alt="Level"
          />
        </Box>
      </Tooltip>
      <Typography
        sx={{
          lineHeight: 1.1,
          fontSize: 14,
          textAlign: "center",
        }}
      >
        {age ? (
          <>
            {translatedAge}
            <br />
            {ageGroupExternalMap[translatedAge]}
          </>
        ) : (
          <>
            No <br /> Level
          </>
        )}
      </Typography>
    </Box>
  );
};

export default Title;

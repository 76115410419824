import React from "react";
import { Box, Typography, SelectChangeEvent } from "@mui/material";

import { API_Create_Lesson } from "types/project";
import { translation } from "constants/translation";
import useStore from "store/store";
import LessonObjectiveFundamentalField from "./LessonObjectiveFundamentalField";
import DashedButton from "components/Button/DashedButton";

type Props = {
  label?: string;
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: string) => void;
};

const LessonObjectiveField = ({
  label,
  lesson,
  setLesson,
  onChange,
}: Props) => {
  const { fundamentalDict } = useStore((state) => state);

  const handleAdd = () => {
    const updatedLesson: API_Create_Lesson = JSON.parse(JSON.stringify(lesson));
    updatedLesson.objective_fundamentals = [
      ...updatedLesson.objective_fundamentals,
      {
        id: `unassigned-id-${updatedLesson.objective_fundamentals.length}`,
        lesson: -1,
        fundamental: fundamentalDict[Object.keys(fundamentalDict)[0]].id,
        milestone:
          Number(
            fundamentalDict[Object.keys(fundamentalDict)[0]].milestones[0].id
          ) || -1,
      },
    ];

    setLesson(updatedLesson);
  };

  const handleDevelopmentalGoalChange = (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => {
    const fundamentalId = Number(event.target.value);
    const updatedLesson: API_Create_Lesson = JSON.parse(JSON.stringify(lesson));
    updatedLesson.objective_fundamentals[
      objectiveFundamentalIndex
    ].fundamental = fundamentalId;
    updatedLesson.objective_fundamentals[objectiveFundamentalIndex].milestone =
      Number(fundamentalDict[fundamentalId].milestones[0].id) || -1;

    setLesson(updatedLesson);
  };

  const handleMilestoneChange = (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => {
    const milestoneId = Number(event.target.value);
    const updatedLesson: API_Create_Lesson = JSON.parse(JSON.stringify(lesson));
    updatedLesson.objective_fundamentals[objectiveFundamentalIndex].milestone =
      milestoneId;
    setLesson(updatedLesson);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        alignItems: "left",
      }}
    >
      <Typography sx={{}}>{label ?? translation.lesson_objectives} </Typography>
      {lesson.objective_fundamentals.map((objectiveFundamental, index) => {
        return (
          <LessonObjectiveFundamentalField
            key={`objective-fundamental-id-${objectiveFundamental.id}`}
            objectiveFundamental={objectiveFundamental}
            index={index}
            handleDevelopmentalGoalChange={handleDevelopmentalGoalChange}
            handleMilestoneChange={handleMilestoneChange}
            lesson={lesson}
            setLesson={setLesson}
          />
        );
      })}
      <DashedButton sx={{ mt: 1 }} onClick={handleAdd} text="Add Objective" />
    </Box>
  );
};

export default LessonObjectiveField;
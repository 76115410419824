import { useRef, useState } from "react";

import { Box } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

import { GalleryImage } from "../../ProjectSummaryScreen/ProjectSummaryScreen";
import Logo from "images/end-of-project-report/trehaus-school-logo.png";
import useStore from "store/store";

import FrontPage from "./FrontPage";
import Overview from "./Overview";
import DevelopmentalGoals from "./DevelopmentalGoals";
import LessonPage from "./LessonPage";
import LastPage from "./LastPage";

import { pdf } from "@react-pdf/renderer";
// import { saveAs } from "file-saver";
import ProjectReportPDF from "../ProjectReportPDF";

import Button, { DownloadButton } from "../../Button/Button";
import { translation } from "constants/translation";
import { PDFInfoType } from "./constant";
import useScale from "hooks/useScale";
import { createProjectSummaryReport } from "api/report-api";
import useSnack from "hooks/useSnack";
import { useNavigate, useParams } from "react-router-dom";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";

const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const Preview = ({ gallery }: { gallery: GalleryImage[] }) => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { projectSlug } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(0);

  useScale(ref, setScale);

  const { currLanguage,currGroup, project,lessons, schoolName } = useStore(
    (state) => ({
      ...state,
      project: state.currentProject,
      lessons: state.currentLessons.lessons,
      schoolName: state.profileDetails.school?.name,
    })
  );

  const sortedGallery: GalleryImage[] = gallery
    .filter((gallery_) => gallery_.images.length !== 0)
    .sort(
      (a, b) =>
        lessons.findIndex((l) => l.name === a.lesson) -
        lessons.findIndex((l) => l.name === b.lesson)
    );

  const lessonsImages = lessons.map((lesson) => {
    if (lesson.images.length >= 1) {
      return lesson.images[0].file;
    } else {
      return Logo;
    }
  });

  const today = new Date();

  const initialInfo: PDFInfoType = {
    name: project.name,
    presentedBy: translation.presentedBy,
    group: `${currGroup.name} Class`,
    school: `${schoolName ? schoolName + " " : ""}${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${today.getFullYear()}`,
    overviewTitle: translation.projectOverview,
    overview: project.description,
    overviewImage:
      project.images.length !== 0
        ? {
            id: project.images[0].id,
            file: project.images[0].file,
          }
        : null,
    fundamentalsTitle: translation.fundamental,
    fundamentals:
      project.fundamentals.length !== 0
        ? project.fundamentals.map((fundamental) => ({
            name: fundamental.name,
            image: fundamental.image,
          }))
        : [],
    lessons: sortedGallery.map((gallery_) => ({
      templateNo:
        gallery_.images.length >= 5
          ? getRandomNumber(1, 5)
          : getRandomNumber(1, gallery_.images.length),
      title: gallery_.lesson,
      images: gallery_.images.map((image) => ({
        id: image.key as number,
        file: image.src,
        caption: image.alt,
      })),
      caption: gallery_.images[0].alt ? gallery_.images[0].alt : "",
    })),
    congratsTitle: translation.congrats,
    congratsText: translation.congratsText,
    congratsImages: lessonsImages,
  };

  const [PDFInfo, setPDFInfo] = useState<PDFInfoType>(initialInfo);

  const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0); //Progress is useless here as it's generating PDF that takes time, but no way to track
  const [isCreatingReport, setIsCreatingReport] = useState<boolean>(false);

  const downloadPDF = async () => {
    if (isGeneratingPDF) return;

    setIsGeneratingPDF(true);

    const blob = await pdf(
      <ProjectReportPDF
        PDFInfo={PDFInfo}
        lessons={lessons}
        currentProject={project}
        gallery={gallery}
        currentLanguage={currLanguage}
        projectTitle={PDFInfo.name}
      />
    ).toBlob();
    // saveAs(blob, `${PDFInfo.name}_Report`);

    // Save to backend
    const savePDFFile = async () => {
      setIsCreatingReport(true);
      const res = await createProjectSummaryReport(
        blob,
        `${PDFInfo.name}_Report`,
        project.id,
        currGroup.id,
        setIsCreatingReport,
        setProgressPercentage
      );
      if (typeof res !== "string") {
        // saveAs(blob, `${PDFInfo.name}_Report`);
        navigate(
          `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projectSlug!)}/end`
        );
        openSnack("Project Summary Report is generated!", true);
      }
    };
    savePDFFile();
    setIsGeneratingPDF(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: "30px",
          gap: "30px",
          backgroundColor: "rgb(75, 75, 75)",
          maxHeight: "calc(100vh - 80px - 48px - 60px - 66px)",
          overflowY: "auto",
        }}
      >
        <Box
          ref={ref}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            width: "100%",
            maxWidth: "1024px",
          }}
        >
          <FrontPage scale={scale} PDFInfo={PDFInfo} setPDFInfo={setPDFInfo} />

          <Overview scale={scale} PDFInfo={PDFInfo} setPDFInfo={setPDFInfo} />

          {PDFInfo.fundamentals.length !== 0 && (
            <DevelopmentalGoals
              scale={scale}
              PDFInfo={PDFInfo}
              setPDFInfo={setPDFInfo}
            />
          )}

          {PDFInfo.lessons.map((lesson, index) => (
            <LessonPage
              key={index}
              gallery={sortedGallery}
              scale={scale}
              lesson={lesson}
              lessonIndex={index}
              setPDFInfo={setPDFInfo}
            />
          ))}

          <LastPage scale={scale} PDFInfo={PDFInfo} setPDFInfo={setPDFInfo} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: "30px",
        }}
      >
        <Box onClick={downloadPDF}>
          {!isGeneratingPDF ? (
            <DownloadButton btnText={translation.downloadReport} />
          ) : (
            <Button
              arrow={false}
              // buttonText={`${translation.generatingReport} ${progressPercentage}%...`}
              buttonText={`${
                translation.generatingReport || "Generating Report"
              }...`}
              style={{
                padding: "8px 16px",
                width: "fit-content",
                height: "fit-content",
                fontWeight: 500,
                fontSize: "14px",
              }}
              disabled
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export const Overlay = () => (
  <Box
    className="overlay"
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      opacity: 0,
      transition: "all 100ms linear",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <ImageIcon
      sx={{
        color: "txt.secondary",
        fontSize: "2rem",
        backgroundColor: "primary.light",
        borderRadius: "50%",
        p: "7px",
      }}
    />
  </Box>
);

export default Preview;

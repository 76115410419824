import { API_Child } from "types/profile";


export type ChildrenGroup = {
  letter: string;
  children: API_Child[];
}

const groupChildrenAlphabetically = (children: API_Child[]): ChildrenGroup[] => {
  // Create an object to store the groups
  const groupedChildren: Record<string, API_Child[]> = {};

  // Iterate through the children and group them
  children.forEach((child) => {
    const firstLetter = child.preferred_name[0].toUpperCase(); // Get the first letter and make it uppercase
    if (!groupedChildren.hasOwnProperty(firstLetter)) {
      groupedChildren[firstLetter] = [];
    }
    groupedChildren[firstLetter].push(child);
  });

  // Sort each group alphabetically
  for (const group in groupedChildren) {
    groupedChildren[group].sort((a, b) => a.preferred_name.localeCompare(b.preferred_name));
  }

  // Sort the groups by the first letter
  const sortedGroups = Object.keys(groupedChildren).sort();

  // Create the final sorted and grouped list
  const childrenGroups: ChildrenGroup[] = [];
  sortedGroups.forEach((group) => {
    childrenGroups.push({
      letter: group,
      children: groupedChildren[group],
    });
  });

  return childrenGroups;
}

export default groupChildrenAlphabetically;
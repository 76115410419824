import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Box,
  Collapse,
  Typography,
} from "@mui/material";

import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import useStore from "store/store";
import {
  CURLANG,
  TEACHER_LESSON_ACTIVITY_PAGE,
  TEACHER_LESSON_DISCUSSION_PAGE,
} from "constants/url";
import { translation } from "constants/translation";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  API_Teacher_Activity,
  API_Teacher_Lesson,
} from "types/teacher";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { getLearnerProgress } from "api/teacher-api";

const TeacherLessonActivityList = () => {
  const { lessonSlug, activitySlug } = useParams();
  const {
    currLanguage,
    profileDetails,
    teacherCurrentModule,
    // teacherCurrentLesson,
    isTeacherModuleLoading,
    setIsTeacherModuleLoading,
    learnerLessonProgressList,
    setLearnerLessonProgressList,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    teacherCurrentModule: state.teacherCurrentModule,
    // teacherCurrentLesson: state.teacherCurrentLesson,
    isTeacherModuleLoading: state.isTeacherModuleLoading,
    setIsTeacherModuleLoading: state.setIsTeacherModuleLoading,
    learnerLessonProgressList: state.learnerLessonProgressList,
    setLearnerLessonProgressList: state.setLearnerLessonProgressList,
  }));

  const [isOpenLesson, setIsOpenLesson] = useState<boolean[]>(
    teacherCurrentModule.lessons.map((lesson) => {
      try {
        return lessonSlug === lesson.slug;
      } catch {
        return false;
      }
    })
  );
  const [tracker, setTracker] = useState<number>(0);

  const currentLessonRef = useRef<HTMLDivElement>();
  const currentActivityRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (currentLessonRef && currentLessonRef.current) {
      if (currentActivityRef && currentActivityRef.current) {
        currentActivityRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      } else {
        currentLessonRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    }
  }, [lessonSlug, activitySlug]);

  useEffect(() => {
    const populateLearnerProgress = async () => {
      const res = await getLearnerProgress(
        teacherCurrentModule.id,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        setLearnerLessonProgressList(res);
      }
    };
    teacherCurrentModule.id !== -1 &&
      profileDetails.id !== -1 &&
      populateLearnerProgress();
  }, [teacherCurrentModule.id, profileDetails.id]);

  // console.log("learner progress:", learnerLessonProgressList);
  return (
    <>
      {isTeacherModuleLoading ? (
        <LoadingIndicator />
      ) : (
        <Box sx={{ width: "100%" }}>
          <>
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                height: "100%",
                overflow: "auto",
                p: 0,
                gap: 1,
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {teacherCurrentModule.lessons.map((lesson, lessonIndex) => {
                const handleExpandClick = (
                  e: React.MouseEvent<SVGSVGElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  setTracker((prev) => prev + 1);
                  const openLessonTemp: boolean[] = JSON.parse(
                    JSON.stringify(isOpenLesson)
                  );
                  openLessonTemp[lessonIndex] = !openLessonTemp[lessonIndex];
                  setIsOpenLesson(openLessonTemp);
                };

                return (
                  <Fragment key={`list-lesson-id-${lesson.id}`}>
                    <LessonRowComponent
                      lesson={lesson}
                      lessonIndex={lessonIndex}
                      isOpenLesson={isOpenLesson}
                      currentLessonRef={currentLessonRef}
                      currentActivityRef={currentActivityRef}
                      handleExpandClick={handleExpandClick}
                      setIsOpenLesson={setIsOpenLesson}
                    />
                  </Fragment>
                );
              })}
            </List>
          </>
        </Box>
      )}
    </>
  );
};

const LessonRowComponent = ({
  lesson,
  lessonIndex,
  isOpenLesson,
  currentLessonRef,
  currentActivityRef,
  handleExpandClick,
  setIsOpenLesson,
}: {
  lesson: API_Teacher_Lesson;
  lessonIndex: number;
  isOpenLesson: boolean[];
  currentLessonRef: React.MutableRefObject<HTMLDivElement | undefined>;
  currentActivityRef: React.MutableRefObject<HTMLDivElement | undefined>;
  handleExpandClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  setIsOpenLesson: React.Dispatch<React.SetStateAction<boolean[]>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { moduleSlug, lessonSlug, activitySlug } = useParams();

  const {
    currLanguage,
    teacherCurrentModule,
    // teacherCurrentLesson,
    learnerLessonProgressList,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    teacherCurrentModule: state.teacherCurrentModule,
    // teacherCurrentLesson: state.teacherCurrentLesson,
    learnerLessonProgressList: state.learnerLessonProgressList,
  }));

  useEffect(() => {
    if (lessonSlug === lesson.slug) {
      const isOpenLessonTemp: boolean[] = JSON.parse(
        JSON.stringify(isOpenLesson)
      );
      isOpenLessonTemp[lessonIndex] = true;
      setIsOpenLesson(isOpenLessonTemp);
    }
  }, [lessonSlug]);

  const isLessonDiscussionPage = location.pathname.includes("discussion");

  const currLessonProgressIndex = learnerLessonProgressList.findIndex(
    (learnerLessonProgress) => learnerLessonProgress.lesson_slug === lesson.slug
  );

  const handleClick = () => {
    const activitySlugTemp =
      lesson.activities.length > 0 ? lesson.activities[0].slug : "";
    if (activitySlugTemp !== "") {
      const isOpenLessonTemp: boolean[] = Array(isOpenLesson.length).fill(
        false
      );
      isOpenLessonTemp[lessonIndex] = true;
      setIsOpenLesson(isOpenLessonTemp);
      navigate(
        `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
          moduleSlug!,
          lesson.slug,
          activitySlugTemp
        )}`
      );
    }
  }

  return (
    <>
      <Box ref={lesson.slug === lessonSlug ? currentLessonRef : null}>
        <ListItemButton
          sx={{
            p: "12px 10px",
            border: "1px solid #91C6C5",
            backgroundColor:
              lesson.slug === lessonSlug ? "#EBF8F8" : "",
            borderRadius: 2,
          }}
          onClick={handleClick}
        >
          <ListItemText
            sx={{ pl: 1 }}
            primary={
              <Typography>
                {translation.formatString(translation.lessonNo, {
                  number: lessonIndex + 1,
                })}
              </Typography>
            }
          />
          {lesson.activities.length > 0 ? (
            <>
              <Typography sx={{ color: "primary.main", fontSize: 14 }}>
                {`${
                  learnerLessonProgressList[currLessonProgressIndex]
                    ? learnerLessonProgressList[currLessonProgressIndex]
                        .activity_done_list.length
                    : 0
                }/${lesson.activities.length}`}
              </Typography>
              {isOpenLesson[lessonIndex] ? (
                <ExpandLess onClick={handleExpandClick} />
              ) : (
                <ExpandMore onClick={handleExpandClick} />
              )}
            </>
          ) : null}
        </ListItemButton>
      </Box>

      <Collapse in={isOpenLesson[lessonIndex]} timeout="auto" unmountOnExit>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: 0.5,
          }}
          component="div"
          disablePadding
        >
          {lesson.activities.map((activity, activityIndex) => {
            const handleActivityClick = () => {
              navigate(
                `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
                  teacherCurrentModule.slug,
                  lesson.slug,
                  activity.slug
                )}`
              );
            };

            return (
              <Fragment key={`${lesson.id}-${activity.id}`}>
                <ActivityRowComponent
                  activity={activity}
                  activityIndex={activityIndex}
                  currentActivityRef={currentActivityRef}
                  handleActivityClick={handleActivityClick}
                  lesson={lesson}
                  lessonIndex={lessonIndex}
                  moduleSlug={teacherCurrentModule.slug}
                />
              </Fragment>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const ActivityRowComponent = ({
  activity,
  activityIndex,
  currentActivityRef,
  handleActivityClick,
  lesson,
  lessonIndex,
  moduleSlug,
}: {
  activity: API_Teacher_Activity;
  activityIndex: number;
  currentActivityRef: React.MutableRefObject<HTMLDivElement | undefined>;
  handleActivityClick: () => void;
  lesson: API_Teacher_Lesson;
  lessonIndex: number;
  moduleSlug: string;
}) => {
  const navigate = useNavigate();

  const { lessonSlug, activitySlug } = useParams();
  const { currLanguage, learnerLessonProgressList } = useStore((state) => ({
    currLanguage: state.currLanguage,
    learnerLessonProgressList: state.learnerLessonProgressList,
  }));

  const isActivityDone = () => {
    const currIndex = learnerLessonProgressList.findIndex(
      (learnerLessonProgress) =>
        learnerLessonProgress.lesson_slug === lesson.slug
    );
    return learnerLessonProgressList[currIndex]
      ? learnerLessonProgressList[currIndex].activity_done_list.includes(
          activity.id
        )
      : false;
  };

  return (
    <>
      <Box ref={activity.slug === activitySlug ? currentActivityRef : null}>
        <ListItemButton
          sx={{
            p: "6px 10px",
            borderLeft:
              activity.slug === activitySlug
                ? "2px solid var(--primary-main)"
                : "",
            backgroundColor: activity.slug === activitySlug
              ? "#F2F2F2"
              : ""
          }}
          onClick={handleActivityClick}
        >
          {isActivityDone() ? <CircleCheckedFilled /> : <CircleUnchecked />}
          <ListItemText
            sx={{ pl: 1 }}
            primary={
              <Typography
                sx={{
                  fontSize: "0.95rem",
                }}
              >
                {activity.name}
              </Typography>
            }
          />
        </ListItemButton>
      </Box>
    </>
  );
};

export default TeacherLessonActivityList;

import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Typography,
  Stack,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";

import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";

import Image from "components/Image/Image";
import Button from "components/Button/Button";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { API_Create_Interest } from "types/prediscoveryquestionnaire";

import { translation } from "constants/translation";
import { TEXT_FIELD_STYLE } from "../ManagementPage/constants/styling";
import { createInterest } from "api/prediscoveryquestionnaire-api";

const CreateInterestPage = () => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { profileDetails, profileID } = useStore((state) => state);

  const [formData, setFormData] = useState<API_Create_Interest>({
    name: "",
    image: "",
  });
  const [previewImg, setPreviewImg] = useState<string>("");

  const handleSubmit = async () => {
    //Register auth account
    const res = await createInterest(formData);

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(`Created ${formData.name} interest successfully!`, true);
      navigate(-1);
    }
  };

  console.log("profileDetails; ", profileDetails);

  return !!profileID ? (
    <Box sx={{ minHeight: "71vh", p: "16px" }}>
      <Stack
        component="form"
        sx={{ width: "80vw", margin: "auto", my: "16px", alignItems: "center" }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
          {translation.createInterest}
        </Typography>

        <TextField
          sx={TEXT_FIELD_STYLE}
          required
          label={translation.name}
          value={formData.name}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography>{translation.image}*:</Typography>
          <input
            accept="image/*"
            type="file"
            id={"image-button"}
            hidden
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0) {
                setFormData((prev) => ({
                  ...prev,
                  image: event.target.files![0],
                }));
                setPreviewImg(URL.createObjectURL(event.target.files[0]));
              }
            }}
          />
          <label htmlFor={"image-button"}>
            <IconButton
              component="span"
              sx={{
                borderRadius: "8px",
                backgroundColor: "primary.light",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              <ImageIcon sx={{ color: "txt.secondary" }} />
            </IconButton>
          </label>
          {previewImg !== "" && (
            <Box
              sx={{
                width: "200px",
                height: "200px",
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Image src={previewImg} alt="parent" />
              <ClearIcon
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setPreviewImg("");
                }}
              />
            </Box>
          )}
        </Box>
        <Button
          buttonText={translation.submit || "Submit"}
          style={{ marginTop: "20px" }}
          onClick={handleSubmit}
          disabled={formData.name === ""}
        />
      </Stack>
    </Box>
  ) : (
    <Typography
      sx={{
        fontSize: "36px",
        height: "71vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {translation.not_logged_in}
    </Typography>
  );
};

export default CreateInterestPage;

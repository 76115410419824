import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useStore from "store/store";

import {
  Box,
  Stack,
  Typography,
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { UserRoleMappingInverse } from "types/auth";
import {
  API_Certification,
  API_Experience,
  API_Profile,
  API_Reflection,
  API_Testimonial,
} from "types/profile";
import { getAccountProfile } from "api/profile-api";
import { useNavigate, useParams } from "react-router-dom";

import { translation } from "constants/translation";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import MiniDrawer from "./ProfilePageMiniDrawer";
import MiniDrawerMobile from "./ProfilePageMiniDrawerMobile";
import {
  PastProjectCards,
  PreferredName,
  ProfileImage,
} from "./ProfileComponents";
import {
  PROFILE_SECTION_CERTIFICATIONS,
  PROFILE_SECTION_EXPERIENCE,
  PROFILE_SECTION_PROJECTS,
  PROFILE_SECTION_REFLECTIONS,
  PROFILE_SECTION_TESTIMONIALS,
  dataInitialReflection,
  dataInitialExperience,
  dataInitialTestimonial,
  dataInitialCertification,
} from "./constants";
import { slugify } from "helper/slugify";
import ModalForm from "./ModalForm";
import AboutComponent from "./Components/AboutComponent";
import ExperienceComponent from "./Components/ExperienceComponent";
import ReflectionComponent from "./Components/ReflectionComponent";
import TestimonialComponent from "./Components/TestimonialComponent";
import CertificationComponent from "./Components/CertificationComponent";

interface FormData {
  email: string;
  preferred_name: string;
  full_name: string;
  dob?: string;
  image?: File;
}

export interface RefreshInterface {
  refresh: boolean;
  callback: () => void;
}

const ProfilePage = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const { profileId } = useParams();
  const { profileDetails, visitedProfileDetails, setVisitedProfileDetails } =
    useStore((state) => ({
      profileDetails: state.profileDetails,
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    }));

  const [formData, setFormData] = useState<FormData>({
    email: "",
    preferred_name: "",
    full_name: "",
    dob: "",
  });

  const [refresh, setRefresh] = useState<RefreshInterface>({
    refresh: true,
    callback: () => {},
  });

  // Modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [selectedExperience, setSelectedExperience] = useState<API_Experience>(
    dataInitialExperience(profileId!)
  );
  const [selectedReflection, setSelectedReflection] = useState<API_Reflection>(
    dataInitialReflection(profileId!)
  );
  const [selectedCertification, setSelectedCertification] =
    useState<API_Certification>(dataInitialCertification(profileId!));
  const [selectedTestimonial, setSelectedTestimonial] =
    useState<API_Testimonial>(dataInitialTestimonial(profileId!, -1));

  const [isOwner, setIsOwner] = useState<boolean>(false);

  const updateVisitedProfile = async (profileId: string) => {
    const data: API_Profile = await getAccountProfile(
      profileId,
      localStorage.getItem("access")
    );
    setVisitedProfileDetails(data);
    setIsOwner(data.id === profileDetails.id);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedExperience(dataInitialExperience(profileId!));
    setSelectedReflection(dataInitialReflection(profileId!));
    setSelectedCertification(dataInitialCertification(profileId!));
    setSelectedTestimonial(
      dataInitialTestimonial(profileId!, profileDetails.id)
    );
  };

  useEffect(() => {
    profileId && updateVisitedProfile(profileId);
  }, [profileId, profileDetails.id]);

  useEffect(() => {
    setSelectedTestimonial((prev) => ({
      ...prev,
      author: { ...prev.author, id: profileDetails.id },
    }));
  }, [profileDetails.id]);

  useEffect(() => {
    if (refresh.refresh) {
      refresh.callback();
      setRefresh({ refresh: false, callback: () => {} });
    }
  }, [refresh]);

  useEffect(() => {
    if (visitedProfileDetails.email !== "")
      setFormData({
        email: visitedProfileDetails.email,
        preferred_name: visitedProfileDetails.preferred_name,
        full_name: visitedProfileDetails.full_name,
        dob: visitedProfileDetails.dob,
      });
  }, [visitedProfileDetails.email]);

  const isShowAll =
    visitedProfileDetails.role === "TR" ||
    visitedProfileDetails.role === "AD" ||
    visitedProfileDetails.role === "WAD";

  const isOwnProfile =
    profileDetails.id === -1
      ? true
      : profileDetails.id.toString() === profileId;

  return formData.email === "" ? (
    <LoadingIndicator />
  ) : (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <ModalForm
        open={isModalOpen}
        handleClose={handleModalClose}
        object={
          setObject(
            type,
            selectedExperience,
            selectedReflection,
            selectedCertification,
            selectedTestimonial
          )!
        }
        type={type}
      />
      {isShowAll && isXs ? (
        <MiniDrawerMobile />
      ) : (
        <MiniDrawer currentTab={"tab"} />
      )}
      <Stack
        component="form"
        sx={{
          width: { xs: "calc(100vw - 48px)", sm: "80vw" },
          margin: "auto",
          alignItems: "center",
          my: "16px",
          px: { xs: 0, sm: 3 },
          py: { xs: 0, sm: "14px" },
        }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        {!isOwnProfile && <BackButton />}

        <ProfileImage
          image={visitedProfileDetails.image}
          imageFallbackChar={
            visitedProfileDetails.preferred_name
              ? visitedProfileDetails.preferred_name.charAt(0).toUpperCase()
              : ""
          }
        />
        <PreferredName
          profileId={visitedProfileDetails.id.toString()}
          preferredName={visitedProfileDetails.preferred_name}
          setRefresh={setRefresh}
        />
        <Position
          role={visitedProfileDetails.role}
          school={visitedProfileDetails.school?.name}
        />

        {/* ABOUT */}
        <AboutComponent isOwner={isOwner} setRefresh={setRefresh} />
        {isShowAll && (
          <>
            {/* Experience */}
            <ExperienceComponent
              setIsModalOpen={setIsModalOpen}
              setType={setType}
              isOwner={isOwner}
              setSelectedExperience={setSelectedExperience}
            />

            {/* Reflections */}
            <ReflectionComponent
              setIsModalOpen={setIsModalOpen}
              setType={setType}
              isOwner={isOwner}
              setSelectedReflection={setSelectedReflection}
            />

            {/* Certs / qualitifications Linked to TTA, settle later */}
            <CertificationComponent
              setIsModalOpen={setIsModalOpen}
              setType={setType}
              isOwner={isOwner}
              setSelectedCertification={setSelectedCertification}
            />
            {/* <Card
              id={slugify(PROFILE_SECTION_CERTIFICATIONS)}
              sx={{ width: "100%" }}
            >
              <CardHeader
                sx={{ backgroundColor: "#d0e3e2bd" }}
                title={"Certifications"}
              />
              <CardContent>
                <Box sx={{ maxHeight: 500, overflow: "auto" }}>
                  {isOwner ? translation.noCertifications : ""}
                </Box>
              </CardContent>
            </Card> */}

            {/* Testimonial/endorsement */}
            <TestimonialComponent
              setIsModalOpen={setIsModalOpen}
              setType={setType}
              isOwner={isOwner}
              setSelectedTestimonial={setSelectedTestimonial}
            />
            {/* Past projects  */}
            <Card id={slugify(PROFILE_SECTION_PROJECTS)} sx={{ width: "100%" }}>
              <CardHeader
                sx={{ backgroundColor: "#d0e3e2bd" }}
                title={
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      lineHeight: 1.5,
                      fontWeight: 500,
                    }}
                  >
                    {translation.profileSectionProjects}
                  </Typography>
                }
              />
              <CardContent>
                <Box>
                  {!!visitedProfileDetails.past_projects?.length ? (
                    <PastProjectCards
                      projects={visitedProfileDetails.past_projects!}
                    />
                  ) : (
                    translation.noCompletedProject
                  )}
                </Box>
              </CardContent>
            </Card>
          </>
        )}
      </Stack>
    </Box>
  );
};

const Position = ({
  role,
  school,
}: {
  role: string;
  school: undefined | string;
}) => {
  return (
    <Typography
      sx={{
        fontSize: "1rem",
        fontWeight: "400",
        textAlign: "center",
        mt: { xs: "8px !important", sm: "16px" },
      }}
    >
      {`${UserRoleMappingInverse[role]}${school ? `, ${school}` : ""}`}
    </Typography>
  );
};

export default ProfilePage;

const setObject = (
  type: string,
  experience: API_Experience,
  reflection: API_Reflection,
  certificate: API_Certification,
  testimonial: API_Testimonial
) => {
  if (type === slugify(PROFILE_SECTION_EXPERIENCE)) {
    return experience;
  }
  if (type === slugify(PROFILE_SECTION_REFLECTIONS)) {
    return reflection;
  }
  if (type === slugify(PROFILE_SECTION_CERTIFICATIONS)) {
    return certificate;
  }
  if (type === slugify(PROFILE_SECTION_TESTIMONIALS)) {
    return testimonial;
  }
  return null;
};

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        alignSelf: "flex-start",
        display: "flex",
        alignItems: "center",
        gap: 1.5,
        cursor: "pointer",
      }}
      onClick={() => navigate(-1)}
    >
      <ArrowBackIcon sx={{ fontSize: 14 }} />
      <Typography>{translation.back}</Typography>
    </Box>
  );
};

import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { API_Announcement } from "types/announcement";
import { DATE_FORMAT } from "containers/AnnouncementsPage/AnnouncementComponents";
import React, { useState } from "react";
import { translation } from "constants/translation";
import { UserRoleMappingInverse } from "types/auth";
import useStore from "store/store";
import Image from "components/Image/Image";

import AnnouncementNotSeen from "images/dashboard/announcement-not-seen.png";
import AnnouncementSeen from "images/dashboard/announcement-seen.png";
import TickImage from "images/tick.png";

import ReplyIcon from "@mui/icons-material/Reply";
import PaperClip from "images/paper-clip.png";

import {
  starAnnouncement,
  seenAnnouncement,
} from "api/announcement-api";
import PushPinIcon from "@mui/icons-material/PushPin";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import ImageIcon from "@mui/icons-material/Image";
import {
  ACTION_REQUIRED,
  CURLANG,
  NOTICES,
  PARENT_NOTICE_PAGE,
} from "constants/url";
import { useLocation, useNavigate } from "react-router-dom";
import { AnnouncementResponseModal } from "./AnnouncementResponseModal";
import {
  buttonTextFontSize,
  buttonTextMobileFontSize,
  descriptionTextFontSize,
} from "constants/constantInGeneral";

const DashboardAnnouncementCard = ({
  announcement,
  sx,
  children,
  isParentView = true,
  isPreview = false,
}: {
  announcement: API_Announcement;
  sx?: SxProps;
  children?: React.ReactNode;
  isParentView?: boolean;
  isPreview?: boolean;
}) => {
  const theme = useTheme();
  const {
    currLanguage,
    childrenInSession,
    announcementList,
    setAnnouncementList,
    parentDashboardUnseenAnnouncementCount,
    setParentDashboardUnseenAnnouncementCount,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    childrenInSession: state.childrenInSession,
    parentDashboardUnseenAnnouncementCount:
      state.parentDashboardUnseenAnnouncementCount,
    setParentDashboardUnseenAnnouncementCount:
      state.setParentDashboardUnseenAnnouncementCount,
    announcementList: state.announcementList,
    setAnnouncementList: state.setAnnouncementList,
  }));
  const location = useLocation();
  const navigate = useNavigate();

  const childrenFilteredList = childrenInSession.filter((child) =>
    announcement.profiles.includes(child.id)
  );

  const announcementStarStatusIconStyle = {
    width: 16,
    height: 16,
    cursor: "pointer",
  };

  const handleAnnouncementSeen = async (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    const res = await seenAnnouncement(announcement.id, announcement.profiles);
    if (typeof res !== "string") {
      const updatedAnnouncementList: API_Announcement[] = JSON.parse(
        JSON.stringify(announcementList)
      );
      const announcementIndex = updatedAnnouncementList.findIndex(
        (announcement_) => announcement_.id === announcement.id
      );
      updatedAnnouncementList[announcementIndex] = res;
      setAnnouncementList(updatedAnnouncementList);

      setParentDashboardUnseenAnnouncementCount(
        parentDashboardUnseenAnnouncementCount - 1
      );
    }
  };

  const handleAnnouncementStar = async (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    const res = await starAnnouncement(
      announcement.id,
      announcement.profiles,
      !announcement.is_starred
    );
    if (typeof res !== "string") {
      const updatedAnnouncementList: API_Announcement[] = JSON.parse(
        JSON.stringify(announcementList)
      );
      const announcementIndex = updatedAnnouncementList.findIndex(
        (announcement_) => announcement_.id === announcement.id
      );
      updatedAnnouncementList[announcementIndex] = res;
      setAnnouncementList(updatedAnnouncementList);
    }
  };
  // console.log("announcement: ", announcement);
  // console.log(
  //   "announcementList in dashboard announcement card: ",
  //   announcementList
  // );

  const isRecalled =
    announcement.is_published === false && announcement.published_at;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.75,
        position: "relative",
        backgroundColor: !isParentView
          ? "transparent"
          : // : announcement.is_star
          // ? theme.palette.custom.lightBlue
          announcement.is_seen || announcement.is_responded || isRecalled
          ? "transparent"
          : "#f0f4d2",
        p: 1,

        ...sx,
      }}
    >
      {children}
      {isRecalled && (
        <Box
          sx={{
            top: 0,
            left: 0,
            position: "absolute",
            zIndex: 2,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              pt: 2,
              pb: 2,
              width: "100%",
              backgroundColor: "grey",
              fontSize: {
                xs: isPreview ? buttonTextMobileFontSize : "1rem",
                sm: isPreview ? buttonTextFontSize : "1.25rem",
              },
              textAlign: "center",
              color: "white",
            }}
          >
            {translation.announcementRecalled}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: isRecalled ? "flex" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "grey",
          width: "100%",
          height: "100%",
          opacity: 0.5,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: 1.25,
              color: "rgba(0, 0, 0, 0.6)",
              textAlign: "left",
            }}
          >
            {moment(announcement.start_at).format(DATE_FORMAT)}
          </Typography>

          {announcement.is_pinned && !isParentView && (
            <Box sx={{ width: 16, height: 16 }}>
              <PushPinIcon
                sx={{
                  color: theme.palette.primary.main,
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          )}
        </Box>

        {isParentView && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                // position: "absolute",
                // top: 8,
                // right: 8,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {announcement.is_edited && (
                <Chip
                  size="small"
                  label={translation.updated}
                  sx={{
                    color: "txt.light",
                    backgroundColor: theme.palette.custom.lightBlue,
                    mr: 1,
                  }}
                />
              )}
              {/* {isRecalled && (
                <Chip
                  size="small"
                  label={translation.recalled}
                  sx={{
                    color: "txt.light",
                    backgroundColor: theme.palette.custom.lightGrey,
                    mr: 1,
                  }}
                />
              )} */}
              {announcement.announcement_response_information.announcement !==
              -1 ? (
                <ResponseRequiredIcons
                  isPreview={isPreview}
                  isRecalled={!!isRecalled}
                  announcement={announcement}
                />
              ) : (
                <NoResponseRequiredIcons
                  isPreview={isPreview}
                  announcement={announcement}
                  handleAnnouncementSeen={handleAnnouncementSeen}
                />
              )}
              {announcement.is_pinned ? null : announcement.is_starred ? (
                <Box
                  sx={announcementStarStatusIconStyle}
                  onClick={handleAnnouncementStar}
                >
                  <StarOutlinedIcon
                    sx={{
                      color: theme.palette.custom.lightOrange,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={announcementStarStatusIconStyle}
                  onClick={handleAnnouncementStar}
                >
                  <StarBorderOutlinedIcon
                    sx={{ width: "100%", height: "100%" }}
                  />
                </Box>
              )}
              {announcement.is_pinned && (
                <Box sx={{ width: 16, height: 16 }}>
                  <PushPinIcon
                    sx={{
                      color: theme.palette.primary.main,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>
              )}
            </Box>
            {announcement.announcement_response_information.end_at && (
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: 1.25,
                  color: "rgba(0, 0, 0, 0.6)",
                  textAlign: "right",
                  fontStyle: "italic",
                }}
              >
                {`${translation.respondBy}: ${moment(
                  announcement.announcement_response_information.end_at
                ).format(DATE_FORMAT)}`}
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.75,
          cursor: location.pathname.includes(NOTICES) ? "default" : "pointer",
        }}
        onClick={() => {
          if (!location.pathname.includes(NOTICES)) {
            navigate(`${CURLANG(currLanguage)}/${NOTICES}`);
          }
        }}
      >
        <Typography
          sx={{
            fontSize: isPreview ? "1.125rem" : "1.3125rem",
            lineHeight: 1.25,
            fontWeight: 500,
            mr: 2,
            opacity: isRecalled ? 0.4 : 1,
            // cursor: location.pathname.includes(NOTICES) ? "default" : "pointer",
          }}
          // onClick={() => {
          //   if (!location.pathname.includes(NOTICES)) {
          //     navigate(`${CURLANG(currLanguage)}/${NOTICES}`);
          //   }
          // }}
        >
          {isParentView && isRecalled
            ? announcement.prev_name
            : announcement.name}
        </Typography>

        {/* {isParentView && isRecalled ? (
          <Typography
            sx={{
              fontSize: { xs: titleMobileFontSize, sm: titleFontSize },
              textAlign: "center",
              width: "100%",
            }}
          >
            {translation.announcementRecalled}
          </Typography>
        ) : ( */}
        <>
          <Box sx={{ position: "relative" }}>
            <Box
              className="ck-content"
              sx={{
                ...(isPreview
                  ? {
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 5,
                      lineClamp: "5",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginBottom: isPreview ? "20px" : "0px", // give some space for the Read More link
                    }
                  : {}),
                fontSize: isPreview ? "1.0625rem" : "1.25rem",
                lineHeight: 1.5,
                opacity: isRecalled ? 0.4 : 1,
                color: "#312F30",
                "& figure": {
                  display: isPreview ? "none !important" : "block",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: announcement.description,
              }}
            />
            {isPreview && (
              <Box
                // onClick={() => {
                //   navigate(`${CURLANG(currLanguage)}/${NOTICES}`);
                // }}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  fontStyle: "italic",
                  textDecoration: "none",
                  fontSize: descriptionTextFontSize,
                  color: "black",
                  ":hover": {
                    textDecoration: "underline",
                  },
                  opacity: isRecalled ? 0.4 : 1,
                }}
              >
                Read more
              </Box>
            )}
          </Box>
          {announcement.files.length > 0 &&
            (isPreview ? (
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: 1.25,
                  color: "rgba(0, 0, 0, 0.6)",
                  fontStyle: "italic",
                }}
              >
                *{translation.includesAttachment}
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  rowGap: 0.5,
                  columnGap: 1,
                  flexWrap: "wrap",
                  "& > a": {
                    maxWidth: "20ch",
                  },
                }}
              >
                {announcement.files.map((file) => (
                  <a
                    key={file.id}
                    href={file.file}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <Tooltip title={file.name}>
                      <Box
                        sx={{
                          borderRadius: 5,
                          border: "1px solid var(--primary-main)",
                          display: "flex",
                          gap: 0.5,
                          alignItems: "center",
                          p: 0.5,
                          px: 1,
                        }}
                      >
                        {[".png", ".jpg", ".jpeg"].some((ext) =>
                          file.file.includes(ext)
                        ) && (
                          <ImageIcon
                            sx={{
                              width: 20,
                              height: 20,
                              color: "primary.main",
                            }}
                          />
                        )}

                        {[".pdf"].some((ext) => file.file.includes(ext)) && (
                          <Image
                            src={PaperClip}
                            alt="paper-clip"
                            style={{ width: 20, height: 20 }}
                          />
                        )}
                        <Typography
                          sx={{
                            fontSize: 13,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {file.name}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </a>
                ))}
              </Box>
            ))}
        </>
        {/* )} */}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        {isParentView && isPreview && childrenFilteredList.length > 1 && (
          <AvatarGroup
            max={10}
            sx={{
              "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 15 },
            }}
          >
            {childrenFilteredList.map((child) => {
              return (
                <Avatar
                  key={`child-${child.id}`}
                  alt={`child-${child.id}`}
                  src={`${child.image}`}
                  sx={{ width: 24, height: 24 }}
                />
              );
            })}
          </AvatarGroup>
        )}
        <Typography
          sx={{
            fontSize: 14,
            lineHeight: 1.25,
            color: "rgba(0, 0, 0, 0.6)",
            textAlign: "right",
          }}
        >
          {`${announcement.created_by_preferred_name} | ${
            announcement.created_by_role === "TR"
              ? `${announcement.created_by_class} Class`
              : UserRoleMappingInverse[announcement.created_by_role]
          }`}
        </Typography>
      </Box>
    </Box>
  );
};

const ResponseRequiredIcons = ({
  isPreview,
  isRecalled,
  announcement,
}: {
  isPreview: boolean;
  isRecalled: boolean;
  announcement: API_Announcement;
}) => {
  const { currLanguage, childrenInSession } = useStore((state) => ({
    currLanguage: state.currLanguage,
    childrenInSession: state.childrenInSession,
  }));
  const navigate = useNavigate();
  const theme = useTheme();
  const announcementRespondedIconStyle = {
    width: 16,
    height: 16,
    cursor: "pointer",
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleRedirect = () => {
    let child_id = childrenInSession[0].id;
    if (announcement.next_response_child_id) {
      child_id = announcement.next_response_child_id;
    }
    navigate(
      `${CURLANG(currLanguage)}/${PARENT_NOTICE_PAGE(
        child_id,
        ACTION_REQUIRED
      )}`
    );
  };
  return (
    <>
      <AnnouncementResponseModal
        announcement={announcement}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      {announcement.is_responded ? (
        isPreview ? (
          // onClick={handleOpen}
          <Box sx={announcementRespondedIconStyle}>
            <Image src={TickImage} alt="tick-image" />
          </Box>
        ) : (
          <Box
            sx={{
              border: "1px solid var(--primary-main)",
              borderRadius: "10px",
              display: "flex",
              gap: 1,
              alignItems: "center",
              px: 1,
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            <Box sx={announcementRespondedIconStyle}>
              <Image src={TickImage} alt="tick-image" />
            </Box>
            <Typography>Responded</Typography>
          </Box>
        )
      ) : isPreview ? (
        <Box sx={announcementRespondedIconStyle} onClick={handleRedirect}>
          <ReplyIcon
            sx={{
              width: "100%",
              height: "100%",
              color: theme.palette.primary.main,
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            border: "1px solid var(--primary-main)",
            borderRadius: "10px",
            display: "flex",
            gap: 1,
            alignItems: "center",
            px: 1,
            cursor:
              isRecalled || announcement.is_responded ? "default" : "pointer",
          }}
          onClick={isRecalled ? () => {} : handleOpen}
        >
          <Box sx={announcementRespondedIconStyle}>
            <ReplyIcon
              sx={{
                width: "100%",
                height: "100%",
                transform: "rotateY(180deg)",
                color: theme.palette.primary.main,
              }}
            />
          </Box>
          <Typography>Respond</Typography>
        </Box>
      )}
    </>
  );
};

const NoResponseRequiredIcons = ({
  isPreview,
  announcement,
  handleAnnouncementSeen,
}: {
  isPreview: boolean;
  announcement: API_Announcement;
  handleAnnouncementSeen: (
    event: React.MouseEvent<HTMLDivElement>
  ) => Promise<void>;
}) => {
  const announcementSeenStatusIconStyle = {
    width: 16,
    height: 16,
    cursor: announcement.is_seen ? "default" : "pointer",
  };
  return announcement.is_seen ? (
    <Box sx={announcementSeenStatusIconStyle}>
      <Image src={AnnouncementSeen} alt="announcement-seen" />
    </Box>
  ) : isPreview ? (
    <Box sx={announcementSeenStatusIconStyle} onClick={handleAnnouncementSeen}>
      <Image src={AnnouncementNotSeen} alt="announcement-not-seen" />
    </Box>
  ) : (
    <Box
      sx={{
        border: "1px solid var(--primary-main)",
        borderRadius: "10px",
        display: "flex",
        gap: 1,
        alignItems: "center",
        px: 1,
        cursor: announcement.is_seen ? "default" : "pointer",
      }}
      onClick={handleAnnouncementSeen}
    >
      <Box sx={announcementSeenStatusIconStyle}>
        <Image src={AnnouncementNotSeen} alt="announcement-not-seen" />
      </Box>
      <Typography>Mark as read</Typography>
    </Box>
  );
};

export default DashboardAnnouncementCard;

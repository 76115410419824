import React, { Fragment, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import { Avatar, Box, Chip } from "@mui/material";
import {
  API_Teacher_Certificate_Edit,
  API_Teacher_Module,
} from "types/teacher";
import { translation } from "constants/translation";
import useStore from "store/store";

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
  return [...a, ...not(b, a)];
}

export default function ModuleTransferList({
  currentCertificateData,
  setCurrentCertificateData,
}: {
  currentCertificateData: API_Teacher_Certificate_Edit;
  setCurrentCertificateData: React.Dispatch<
    React.SetStateAction<API_Teacher_Certificate_Edit>
  >;
}) {
  const { teacherModules } = useStore((state) => ({
    teacherModules: state.teacherModules,
  }));

  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [modulesNotInCertificate, setModulesNotInCertificate] = React.useState<
    readonly number[]
  >([]);
  const [modulesInCertificate, setModulesInCertificate] = React.useState<
    readonly number[]
  >([]);

  useEffect(() => {
    const modulesNotInCertificateTemp = () => {
      const notSelectedModules = () =>
        teacherModules.filter((module) => {
          return !currentCertificateData.module.includes(module.id);
        });

      return notSelectedModules().map((module) => {
        return module.id;
      });
    };
    setModulesInCertificate(currentCertificateData.module);
    setModulesNotInCertificate(modulesNotInCertificateTemp());
  }, [teacherModules, currentCertificateData.id]);

  const modulesNotInCertificateChecked = intersection(
    checked,
    modulesNotInCertificate
  );
  const modulesInCertificateChecked = intersection(
    checked,
    modulesInCertificate
  );

  const handleCheckedAssignedmodules = () => {
    setModulesInCertificate(
      modulesInCertificate.concat(modulesNotInCertificateChecked)
    );
    setCurrentCertificateData({
      ...currentCertificateData,
      module: modulesInCertificate.concat(modulesNotInCertificateChecked),
    });
    setModulesNotInCertificate(
      not(modulesNotInCertificate, modulesNotInCertificateChecked)
    );
    setChecked(not(checked, modulesNotInCertificateChecked));
  };

  const handleCheckedUnassignedmodules = () => {
    setModulesNotInCertificate(
      modulesNotInCertificate.concat(modulesInCertificateChecked)
    );
    setModulesInCertificate(
      not(modulesInCertificate, modulesInCertificateChecked)
    );
    setCurrentCertificateData({
      ...currentCertificateData,
      module: not(modulesInCertificate, modulesInCertificateChecked),
    });

    setChecked(not(checked, modulesInCertificateChecked));
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          {CustomList(
            translation.moduleToBeAssigned,
            modulesNotInCertificate,
            teacherModules,
            checked,
            setChecked
          )}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="medium"
              onClick={handleCheckedAssignedmodules}
              disabled={modulesNotInCertificateChecked.length === 0}
              aria-label="move selected modulesInCertificate"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="medium"
              onClick={handleCheckedUnassignedmodules}
              disabled={modulesInCertificateChecked.length === 0}
              aria-label="move selected modulesNotInCertificate"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {CustomList(
            translation.moduleRequiredByCertificate,
            modulesInCertificate,
            teacherModules,
            checked,
            setChecked
          )}
        </Grid>
      </Grid>
    </>
  );
}

const CustomList = (
  title: React.ReactNode,
  modulesId: readonly number[],
  teacherModules: API_Teacher_Module[],
  checked: readonly number[],
  setChecked: React.Dispatch<React.SetStateAction<readonly number[]>>
) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (modulesId: readonly number[]) =>
    intersection(checked, modulesId).length;

  const handleToggleAll = (modulesId: readonly number[]) => () => {
    if (numberOfChecked(modulesId) === modulesId.length) {
      setChecked(not(checked, modulesId));
    } else {
      setChecked(union(checked, modulesId));
    }
  };

  return (
    <Card sx={{ border: "1px solid #98C4C5", borderRadius: "10px" }}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(modulesId)}
            checked={
              numberOfChecked(modulesId) === modulesId.length &&
              modulesId.length !== 0
            }
            indeterminate={
              numberOfChecked(modulesId) !== modulesId.length &&
              numberOfChecked(modulesId) !== 0
            }
            disabled={modulesId.length === 0}
            inputProps={{
              "aria-label": "all modulesId selected",
            }}
          />
        }
        title={
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItem: "center",
              }}
            >
              <Box>{title}</Box>
            </Box>
          </>
        }
        subheader={`${numberOfChecked(modulesId)}/${modulesId.length} selected`}
      />

      <Divider sx={{ borderColor: "primary.main" }} />

      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Search..."
        value={searchQuery}
        onChange={(event) => {
          setSearchQuery(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        sx={{
          px: 2,
          pt: 2,
        }}
      />

      <List
        sx={{
          width: 300,
          height: 230,
          bgcolor: "background.paper",
          overflowX: "hidden",
        }}
        dense
        component="div"
        role="list"
      >
        {modulesId.map((moduleId: number) => {
          const labelId = `transfer-list-all-item-${moduleId}-label`;
          const targetModule = teacherModules.filter((module) => {
            return module.id === moduleId;
          })[0];
          // console.log("targetModule: ", targetModule);
          return (
            <Fragment key={moduleId}>
              {targetModule &&
              targetModule.name
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ? (
                <ListItem
                  role="listitem"
                  button
                  onClick={handleToggle(moduleId)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(moduleId) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                  <ListItemText
                    id={labelId}
                    primary={
                      <Chip
                        avatar={
                          <Avatar
                            alt={targetModule.name}
                            src={targetModule.image}
                          />
                        }
                        label={`${targetModule.name}`}
                        variant="outlined"
                      />
                    }
                  />
                </ListItem>
              ) : (
                <> </>
              )}
            </Fragment>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
};

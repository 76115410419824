import { Text, View, Image, Font } from "@react-pdf/renderer";
import { extractText, removeHtmlTagsAndExtractImages, removeHtmlTagsAndStyles } from "./utils";
import { styles } from "./styles";
import { images } from "../LessonSelectionAssets/DevelopmentalGoalImages";
import fall_back_img from "images/logo.png";
import { checkIsChineseChar } from "helper/helper";
const hyphenationCallback = (word: string) => {
  if (word.length === 1 || !checkIsChineseChar(word)) {
    return [word];
  }
  return Array.from(word)
    .map((char) => [char, ""])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);

};

Font.registerHyphenationCallback(hyphenationCallback)
type QuestionProps = {
  question: string;
  fundamentalId: number;
  milestoneId: number;
  fundamentalDict: Record<string, any>;
};

const Question = ({
  question,
  fundamentalId,
  milestoneId,
  fundamentalDict,
}: QuestionProps) => {
  const currFundamental = fundamentalDict[fundamentalId];
  const milestones = currFundamental
    ? currFundamental.milestones.find(
      (milestone: { id: number }) => milestone.id === milestoneId
    )
    : undefined;

  const fundamentalMilestoneSet =
    currFundamental && milestones
      ? {
        logo: currFundamental.image,
        fundamental: currFundamental.name,
        milestone: milestones.name,
      }
      : undefined;

  return (
    <View>
      <View style={styles.questionNameWrapper} wrap={false}>
        <Text
          style={{
            fontFamily: checkIsChineseChar(question) ? "KaiTi" : "FuturaPT500",
            fontSize: "12px",
            color: "#000",
            marginBottom: "4px",
          }}
        >
          {question}
        </Text>
        {fundamentalMilestoneSet && fundamentalMilestoneSet.logo && (
          <View
            style={{
              marginLeft: "12px",
              backgroundColor: "#EBF8F8",
              border: "1px solid #91C6C5",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "2px 8px",
              borderRadius: "50%",
            }}
          >
            <Image
              src={fundamentalMilestoneSet.logo}
              style={{ height: "16px", width: "16px" }}
            />
            <Text
              style={{
                fontFamily: checkIsChineseChar(fundamentalMilestoneSet.fundamental) ? "KaiTi" : "FuturaPT500",
                fontSize: "12px",
                color: "#000",
              }}
            >
              {fundamentalMilestoneSet.fundamental}:
            </Text>
          </View>
        )}
        {fundamentalMilestoneSet && !fundamentalMilestoneSet.logo && (
          <View
            style={{
              marginLeft: "12px",
              backgroundColor: "#EBF8F8",
              border: "1px solid #91C6C5",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "2px 8px",
              borderRadius: "50%",
            }}
          >
            <Image
              src={fall_back_img}
              style={{ height: "16px", width: "16px" }}
            />
            <Text
              style={{
                fontFamily: "FuturaPT500",
                fontSize: "12px",
                color: "#000",
              }}
            >
              {fundamentalMilestoneSet.fundamental}:{" "}
              {fundamentalMilestoneSet.milestone}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

interface PdfComponentProps {
  instruction: any;
  instIndex: number;
  assessment: boolean;
  fundamentalDict: Record<string, any>;
}

const LessonInstruction = ({
  instruction,
  instIndex,
  assessment,
  fundamentalDict,
}: PdfComponentProps) => {
  const questions = instruction.learning_moment?.questions ?? [];
  const { textWithoutTags: instructionText, imageSrcs: instructionImages } =
    removeHtmlTagsAndExtractImages(instruction.name);
  const instructions = extractText(instruction.name);
  const recommendations = extractText(instruction.recommendation);
  return (
    <View style={styles.regularSection}>

      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', }}>
        <View>
          <Text style={{
            fontSize: "12px",
            position: "absolute",
            marginRight: "10px"


          }}>{`${instIndex + 1}.`}</Text>
        </View>

        <View style={{ marginLeft: '25px' }}>
          {instructions.result.length > 0 && instructions.result.map((text: any, index: number) => {
            return (
              typeof text === 'string' ? (
                <Text style={{
                  fontSize: "12px",

                  ...checkIsChineseChar(text) ? styles.chineseText : styles.englishTextSection
                }}>{text}</Text>



              ) : Array.isArray(text) ? (
                text.map((item: any, index: any) => (
                  <Text style={{
                    fontSize: "12px",
                    display: "flex",
                    flexWrap: "wrap", margin: "4px 0", ...checkIsChineseChar(item) ? styles.chineseText : styles.englishTextSection
                  }} key={`lesson-instruction-li-${index}`}
                  >
                    • {item}
                  </Text>
                ))
              ) : typeof text == 'object' ? (Object.entries(text).map(([key, value], entryIndex) => (
                <Text style={{
                  fontSize: "12px",
                  display: "flex",
                  flexWrap: "wrap", marginBottom: '4px', ...checkIsChineseChar(value) ? styles.chineseText : styles.englishTextSection,
                }}>{`${key}. `}{String(value)}</Text>

              ))) : null
            )
          })}
        </View>
      </View>


      {instructionImages.map((imageSrc: string, index: number) => {
        return <View style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
          {/* src={{
              uri: imageSrc,
              method: 'GET',
              headers: { 'Cache-Control': 'no-cache' },
              body: ''
            }} */}
          <Image
            src={imageSrc}
            style={{
              width: "80%",
              height: "auto",
              marginTop: "10px",
              alignSelf: "center",

            }}
          />
        </View>
      }

      )}
      {instruction.recommendation && (

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#EBF8F8",
            width: "100%",
            alignItems: "flex-start",
            padding: "10px 5px",
          }}
        >
          <View>
            <Image
              src={images.push_pin}
              style={{
                width: "15px",
                height: "15px",
                margin: "0 5px"
              }}
            />
          </View>

          <View style={{ flex: 1 }}>

            {recommendations.result.length > 0 && recommendations.result.map((text: any, index: number) => {
              return (
                typeof text === 'string' ? (
                  <Text style={{
                    fontSize: "12px",

                    ...checkIsChineseChar(text) ? styles.chineseText : styles.englishTextSection
                  }}>{text}</Text>



                ) : Array.isArray(text) ? (
                  text.map((item: any, index: any) => (
                    <Text style={{
                      fontSize: "12px",
                      display: "flex",
                      flexWrap: "wrap", margin: "4px 0", ...checkIsChineseChar(item) ? styles.chineseText : styles.englishTextSection
                    }} key={`lesson-instruction-li-${index}`}
                    >
                      • {item}
                    </Text>
                  ))
                ) : typeof text == 'object' ? (Object.entries(text).map(([key, value], entryIndex) => (
                  <Text style={{
                    fontSize: "12px",
                    display: "flex",
                    flexWrap: "wrap", marginBottom: '4px', ...checkIsChineseChar(value) ? styles.chineseText : styles.englishTextSection,
                  }}>{`${key}. `}{String(value)}</Text>

                ))) : null
              )
            })}

          </View>
        </View>

      )}
      {instruction.learning_moment && (
        <View
          style={{
            border: "1px solid black",
            padding: "5px",
            marginTop: "10px",
          }}
        >
          {!assessment && (
            <>
              <Text
                style={{
                  fontSize: "10px",
                  fontFamily: "FuturaPT600",
                }}
              >
                Learning Moment
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <Image
                  src={images.photo_camera}
                  style={{
                    width: "10px",
                    height: "10px",
                    marginRight: "5px",
                  }}
                />
                <Text style={{ ...styles.regularSectionText, ...checkIsChineseChar(instruction.learning_moment.photo_instruction) ? styles.chineseText : styles.englishTextSection }}>
                  {removeHtmlTagsAndStyles(instruction.learning_moment.photo_instruction)}
                </Text>
              </View>
            </>
          )}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={images.list_alt}
              style={{
                width: "10px",
                height: "10px",
                marginRight: "3px",
              }}
            />
            <Text style={styles.regularSectionText}>
              {assessment ? "Evaluation Moment" : "Milestone check"}
            </Text>
          </View>
          {!assessment && (
            <Question
              fundamentalDict={fundamentalDict}
              fundamentalId={instruction.learning_moment.fundamental}
              milestoneId={instruction.learning_moment.milestone}
              question={removeHtmlTagsAndStyles(
                instruction.learning_moment.name
              )}
            />
          )}
          {/* {questions.map(
            (
              question: {
                name: string;
                id: string;
                fundamental_set: Record<string, any>[];
              },
              index: number
            ) => (
              <Question
                key={question.id}
                fundamentalId={question.fundamental_set[0]?.fundamental}
                milestoneId={question.fundamental_set[0]?.milestone}
                question={`Q ${index + 1}: ${removeHtmlTagsAndStyles(question.name)}`}
                fundamentalDict={fundamentalDict}
              />
            )
          )} */}
        </View>
      )}
    </View>
  );
};

export default LessonInstruction;

import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { ASSESSMENT_PAGE } from 'constants/url';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const AssessmentOverview = lazy(() => import('containers/AssessmentPage/Screens/OverviewScreen'));
const AssessmentPlanScreen = lazy(() => import('containers/AssessmentPage/Screens/AssessmentPlanScreen'));
const EvaluationMomentScreen = lazy(
  () => import('containers/AssessmentPage/Screens/EvaluationMomentScreen/EvaluationMomentScreen')
);
const AssessmentPage = lazy(() => import('containers/AssessmentPage/AssessmentPage'));
const LessonActivityPage = lazy(() => import('containers/ProjectPage/LessonActivityPage'));
const LessonActivityScreen = lazy(() => import('containers/ProjectPage/LessonScreens/LessonActivityScreen'));

type WithChildren<T = unknown> = T & { children?: React.ReactNode };
export const SuspenseWrapper: React.FC<WithChildren> = ({ children }) => (
  <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
);

const LittleEvaluatorRoutes = (
  <Route
    path={ASSESSMENT_PAGE(':assessmentSlug')}
    element={
      <SuspenseWrapper>
        <AssessmentPage />
      </SuspenseWrapper>
    }
  >
    <Route
      index
      element={
        <SuspenseWrapper>
          <AssessmentOverview />
        </SuspenseWrapper>
      }
    />
    <Route
      path={'overview'}
      element={
        <SuspenseWrapper>
          <AssessmentOverview />
        </SuspenseWrapper>
      }
    />
    <Route
      path={'assessment-plans'}
      element={
        <SuspenseWrapper>
          <AssessmentPlanScreen teacher={false} />
        </SuspenseWrapper>
      }
    />
    <Route
      path={'assessment/:assessmentID'}
      element={
        <SuspenseWrapper>
          <LessonActivityPage assessment />
        </SuspenseWrapper>
      }
    >
      <Route
        path={'activity/:activityID'}
        element={
          <SuspenseWrapper>
            <LessonActivityScreen assessmentPlanActivity />
          </SuspenseWrapper>
        }
      />
    </Route>
    <Route
      path={'evaluation-moments'}
      element={
        <SuspenseWrapper>
          <EvaluationMomentScreen />
        </SuspenseWrapper>
      }
    />
  </Route>
);

export default LittleEvaluatorRoutes;

import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Autocomplete,
  SxProps,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { createGallery, addVideos, addImages } from "api/gallery-api";
import { API_Project_Lesson } from "types/project";
import {
  API_Gallery_Create,
  API_Caption,
  API_Gallery_Video_Create,
  API_Gallery_Image_Create,
} from "types/gallery";
import EditIcon from "@mui/icons-material/Edit";

import CustomButton from "../Button/Button";
import "components/ProjectSummaryScreen/ReactGridGallery.css";
import { translation } from "constants/translation";
import EditPhoto from "./Photo/EditPhotoModal";
import CustomImage from "components/Image/Image";

const UploadButton = ({ lessonId }: { lessonId?: number }) => (
  <label
    style={{
      order: 1,
      display: "flex",
      margin: "4px",
      width: "120px",
      height: "120px",
    }}
    htmlFor={`${lessonId}-photo-video`}
  >
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        borderRadius: "10px",
        color: "#444",
        border: "1px dashed",
        borderColor: "#444",
        transition: "all 100ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms ease-in-out",
        },
      }}
    >
      <AddAPhotoIcon />
    </Box>
  </label>
);

const UploadPhotoVideoModal = ({
  lesson,
  lessonNo,
  initializeGallery,
}: {
  lesson?: API_Project_Lesson;
  lessonNo?: number;
  initializeGallery: () => Promise<void>;
}) => {
  const openSnack = useSnack();

  const { currGroup, currLanguage, projID } = useStore((state) => ({
    ...state,
    schoolID: state.profileDetails.school?.id.toString(),
    projID: state.currentLessons.id.toString(),
  }));

  const [photoVideoList, setPhotoVideoList] = useState<File[]>([]);
  const [captions, setCaptions] = useState<API_Caption[]>([]);
  const [isUploadedModalOpen, setIsUploadedModalOpen] =
    useState<boolean>(false);
  const [galleryID, setGalleryID] = useState<number>(-1);
  const [selectedCaption, setSelectedCaption] = useState<string>("");
  const [activityId, setActivityId] = useState<string>(
    lesson?.activities?.[0]?.id?.toString() ?? ""
  );

  const [openEditModal, setOpenEditModal] = useState<boolean>(false); // open edit modal or not
  const [photoIndex, setPhotoIndex] = useState<number>(-1);
  const [editedPhoto, setEditedPhoto] = useState<File | undefined>(); // final output of edited photo
  const [photoName, setPhotoName] = useState<string>(""); // raw photo name to pass
  const [photoSource, setPhotoSource] = useState<string>(""); // raw source to pass

  const [photoProgressPercentage, setPhotoProgressPercentage] =
    useState<number>(0);
  const [isAddingPhoto, setIsAddingPhoto] = useState<boolean>(false);

  const [videoProgressPercentage, setVideoProgressPercentage] =
    useState<number>(0);
  const [isAddingVideo, setIsAddingVideo] = useState<boolean>(false);

  const getOrCreateGallery = async (activityID: string) => {
    const galleryData: API_Gallery_Create = {
      activity: activityID,
      lesson: lesson?.id.toString(),
      project: projID,
      school: currGroup.school.toString(),
      group: currGroup.id.toString() || undefined,
    };

    const res = await createGallery(currLanguage, galleryData); //create or load gallery
    if (typeof res === "string") {
      openSnack(translation.failed_load_gallery, false);
    } else {
      const filteredCaptions = res.captions.filter(
        (caption) => caption.caption !== ""
      );
      if (filteredCaptions.length !== 0) {
        setCaptions(filteredCaptions);
        setSelectedCaption(filteredCaptions[0].caption);
      }
      setGalleryID(res.id);
    }
  };

  const handleClose = (event?: any, reason?: any) => {
    if (reason === "backdropClick" && isAddingVideo) {
      return;
    }

    if (lesson) {
      setActivityId(lesson.activities[0].id.toString());
    }
    setSelectedCaption("");
    setPhotoVideoList([]);
    setIsUploadedModalOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setActivityId(event.target.value);
    getOrCreateGallery(event.target.value);
  };

  const submit = async () => {
    // NO need to create caption (only use pre-populated)
    // if (
    //   selectedCaption !== "" &&
    //   !captions.some((cap) => cap.caption === selectedCaption)
    // ) {
    //   await createCaption(currLanguage, selectedCaption, galleryID.toString());
    // }

    const photoList = photoVideoList.filter((photoVideo) =>
      photoVideo.type.includes("image")
    );
    const videoList = photoVideoList.filter((photoVideo) =>
      photoVideo.type.includes("video")
    );

    let isPhotoUploaded = true;
    let isVideoUploaded = true;
    //photo
    if (photoList.length > 0) {
      const imageData: API_Gallery_Image_Create = {
        gallery: galleryID.toString(),
        caption: selectedCaption === "" ? null : selectedCaption,
        image: photoList,
      };
      setIsAddingPhoto(true);
      const photoRes = await addImages(
        currLanguage,
        imageData,
        setIsAddingPhoto,
        setPhotoProgressPercentage
      );
      if (typeof photoRes === "string") {
        // console.log("photoRes: ", photoRes);
        isPhotoUploaded = false;
      }
    }

    //video
    if (videoList.length > 0) {
      const videoData: API_Gallery_Video_Create = {
        gallery: galleryID.toString(),
        caption: selectedCaption === "" ? null : selectedCaption,
        video: videoList,
      };
      setIsAddingVideo(true);
      const videoRes = await addVideos(
        currLanguage,
        videoData,
        setIsAddingVideo,
        setVideoProgressPercentage
      );
      if (typeof videoRes === "string") {
        // console.log("videoRes: ", videoRes);
        isVideoUploaded = false;
      }
    }

    if (isPhotoUploaded && isVideoUploaded) {
      openSnack(translation.success_upload_photos_videos, true);
      handleClose();
      initializeGallery();
    } else {
      openSnack(translation.failed_add_photos_videos, false);
    }
  };

  //photo edit
  const handleEditPhoto = (photoVideo: File, index: number) => {
    const reader = new FileReader();

    reader.addEventListener("load", () =>
      setPhotoSource(reader.result?.toString() || "")
    );

    reader.readAsDataURL(photoVideo);

    setPhotoIndex(index);
    setPhotoName(photoVideo.name);
    setOpenEditModal(true);
  };

  const handleCloseEditPhotoModal = () => {
    setPhotoName("");
    setPhotoSource("");
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (editedPhoto === undefined || photoIndex === -1) return;

    const updatedPhotoVideoList = photoVideoList.map((photoVideo, index) =>
      index === photoIndex ? editedPhoto : photoVideo
    );

    setPhotoVideoList(updatedPhotoVideoList);
    setPhotoIndex(-1);
    setEditedPhoto(undefined);
  }, [editedPhoto, photoVideoList, photoIndex]);

  const MAX_IMAGE_SIZE_MB = 5; // Set the maximum image size limit in megabytes

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: File[] = Array.prototype.slice.call(
      event.target.files || []
    );

    if (newFiles.length === 0) {
      event.target.value = "";
      return;
    }

    // Validate image size before adding to the list
    const invalidImages = newFiles.filter(
      (file) =>
        file.type.includes("image") &&
        file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024
    );

    if (invalidImages.length > 0) {
      // Display snack message for invalid images
      openSnack(
        `Images should be less than ${MAX_IMAGE_SIZE_MB}MB or compressed.`,
        false
      );
    } else {
      // Continue with valid images
      getOrCreateGallery(activityId);
      setPhotoVideoList(newFiles);
      setIsUploadedModalOpen(true);
    }

    event.target.value = "";
  };
  return (
    <>
      <UploadButton lessonId={lesson?.id} />
      <input
        accept="video/*,image/*"
        id={`${lesson?.id}-photo-video`}
        type="file"
        capture="environment"
        multiple
        hidden
        onChange={handleFileChange}
      />

      {photoVideoList.length !== 0 && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isUploadedModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            m: "24px",
          }}
        >
          <Fade in={isUploadedModalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                maxWidth: "800px",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: "5px 5px 20px var(--primary-main)",
                p: 4,
                borderRadius: "10px",
                overflow: "auto",
                maxHeight: "100%",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {`${translation.uploadMedia} - ${translation.formatString(
                  translation.lessonNo,
                  {
                    number: lessonNo || 0,
                  }
                )}: ${lesson?.name}`}
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns:
                    photoVideoList.length === 1
                      ? "repeat(1, 1fr)"
                      : "repeat(2, 1fr)",
                  gap: "12px",
                  maxHeight: "412px",
                  overflow: "auto",
                  mt: "12px",
                  mb: "24px",
                }}
              >
                {/* Videos */}
                {photoVideoList.map((photoVideo, index) => {
                  const iconStyle: SxProps = {
                    color: "txt.secondary",
                    cursor: "pointer",
                    fontSize: "2rem",
                    backgroundColor: "primary.light",
                    borderRadius: "50%",
                    transition: "all 100ms linear",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      transition: "all 100ms linear",
                    },
                  };
                  const isVideo = photoVideo.type.includes("video");
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        height: photoVideoList.length === 1 ? "400px" : "200px",
                        borderRadius: "10px",
                        overflow: "hidden",
                        position: "relative",
                        flexShrink: 0,
                        flexGrow: 1,
                        "&:hover .overlay": {
                          opacity: 1,
                        },
                      }}
                      key={index}
                    >
                      <Box
                        className="overlay"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: "rgba(0, 0, 0, 0.5)",
                          opacity: 0,
                          transition: "all 100ms linear",
                          // zIndex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1.5,
                        }}
                      >
                        {!isVideo && (
                          <EditIcon
                            sx={{ ...iconStyle, p: "6px" }}
                            onClick={() => handleEditPhoto(photoVideo, index)}
                          />
                        )}

                        <ClearIcon
                          sx={{ ...iconStyle, p: "4px", zIndex: 1 }}
                          onClick={() => {
                            setPhotoVideoList((prev) => {
                              const temp = [...prev];
                              temp.splice(index, 1);
                              return temp;
                            });
                          }}
                        />
                      </Box>
                      <Box sx={{ width: "inherit", height: "inherit" }}>
                        {isVideo ? (
                          <video
                            width="100%"
                            controls
                            controlsList="nodownload"
                            preload="metadata"
                            style={{ borderRadius: "10px" }}
                          >
                            <source
                              src={URL.createObjectURL(photoVideo)}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <CustomImage
                            src={URL.createObjectURL(photoVideo)}
                            alt={photoVideo.name}
                            objectFit="cover"
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              {lesson && lesson.activities.length > 1 && (
                <FormControl fullWidth>
                  <InputLabel id="select-activity-label">
                    {translation.activity}
                  </InputLabel>
                  <Select
                    labelId="select-activity-label"
                    id="select-activity"
                    value={activityId}
                    label={translation.activity}
                    onChange={handleChange}
                  >
                    {lesson?.activities.map((act, index) => (
                      <MenuItem key={act.id} value={act.id.toString()}>
                        {translation.activity} {index + 1}: {act.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Autocomplete
                disablePortal
                freeSolo
                forcePopupIcon={true}
                options={captions.map((cap) => cap.caption)}
                value={selectedCaption}
                inputValue={selectedCaption}
                onInputChange={(event, inputString) => {
                  setSelectedCaption(inputString);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translation.typeCaption}
                    inputProps={{ ...params.inputProps, maxLength: 120 }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ ml: 0 }} {...props}>
                    {option}
                  </Box>
                )}
                sx={{ mt: "12px" }}
              />

              <Box
                sx={{
                  mt: "20px",
                }}
              >
                <CustomButton
                  buttonText={
                    isAddingPhoto
                      ? photoProgressPercentage === 100
                        ? translation.uploadPhotoFinalizing
                        : `${photoProgressPercentage}%`
                      : isAddingVideo
                      ? videoProgressPercentage === 100
                        ? translation.uploadVideoFinalizing
                        : `${videoProgressPercentage}%`
                      : translation.upload
                  }
                  onClick={submit}
                  isLoading={isAddingPhoto || isAddingVideo}
                  disabled={isAddingPhoto || isAddingVideo}
                  arrow={false}
                />
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}

      {openEditModal && photoSource !== "" && (
        <EditPhoto
          open={openEditModal}
          handleClose={handleCloseEditPhotoModal}
          imageSrc={photoSource}
          setEditedImage={setEditedPhoto}
          imageName={photoName}
        />
      )}
    </>
  );
};

export default UploadPhotoVideoModal;

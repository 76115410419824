import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getAllSiteAdminProfile } from "api/profile-api";
import { API_Profile_Short } from "types/profile";

//mui datagrid
import {
  GridColumns,
  DataGrid,
  GridRowId,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridRowModel,
  GridCellParams,
  GridColumnVisibilityModel,
  GridCallbackDetails,
  GridToolbar,
  GridCsvExportOptions,
} from "@mui/x-data-grid";

//Profile
import {
  deleteProfile,
  deleteProfileBulk,
  updateProfile,
} from "api/auth-api";
import {
  ErrorMessage,
  UpdateProfile,
  UpdateProfileResponse,
} from "types/auth";

//warning
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";

import {
  DOB_COLUMN,
  ROLE_COLUMN,
  processRowUpdateBody,
} from "./constants/gridColumn";

import { DATA_GRID_STYLE } from "./constants/styling";

import {
  ACTIONS_COLUMN,
  EditToolbarProfile,
  EMAIL_COLUMN,
  FULL_NAME_COLUMN,
  ID_COLUMN,
  IMAGE_COLUMN,
  MOBILE_NUMBER_COLUMN,
  PREFERRED_NAME_COLUMN,
} from "./constants/gridColumnHelper";

const SiteAdminManagePage = () => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [bulkDeleteLoading, setBulkDeleteLoading] = useState<boolean>(false);
  const [siteAdminList, setSiteAdminList] = useState<API_Profile_Short[]>([]);
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const [bulkActionNames, setbulkActionNames] = useState<any>([]);
  const [rows, setRows] = useState(siteAdminList);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "",
    confirmDelete: false,
  });

  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  const handleSelectionModelChange = (newSelectionModel: any) => {
    if (!bulkDeleteLoading) {
      setSelectionModel(newSelectionModel);
    }
  };

  const handleDelete = (id: GridRowId) => {
    const callDeleteProfile = async () => {
      const res = await deleteProfile(String(id));
      if (typeof res === "string" && res.length >= 1) {
        openSnack(res, false);
      } else {
        openSnack(`Delete Site Admin successfully!`, true);
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    callDeleteProfile();
  };

  const handleBulkDelete = (selectionModel: any) => {
    setBulkDeleteLoading(true);
    const callBulkDeleteProfile = async () => {
      const res = await deleteProfileBulk(selectionModel);
      if (res.status === "success") {
        openSnack(`Deleted Admins successfully!`, true);
        setRows(rows.filter((row) => !selectionModel.includes(row.id)));
      } else if (res.status === "partial") {
        const failedNames = res.failed_ids
          .map((id: any) => rows.find((row) => row.id === id)?.preferred_name)
          .join(", ");
        openSnack(`Failed to Delete: ${failedNames}`, false);
      } else {
        openSnack(`All deletions failed!`, false);
      }
      setBulkDeleteLoading(false);
      setSelectionModel([]);
    };
    callBulkDeleteProfile();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    //double function loop here to prevent it's initialiated once passing
    //call delete api
    setSelectionModel([]);
    setbulkActionNames([]);
    const preferred_name = rows.filter((row) => row.id === id)[0]
      ?.preferred_name;
    handleOpenDialog();
    setWarningBody({
      id: Number(id),
      warningTitle: `Delete Site Admin "${preferred_name}"?`,
      confirmDelete: false,
    });
  };

  const handleBulkDeleteClick = (selectionModel: any[]) => {
    const namesArray: any[] = [];
    selectionModel.forEach((id) => {
      namesArray.push(rows.filter((row) => row.id === id)[0]?.preferred_name);
    });
    setbulkActionNames(namesArray);
    handleOpenDialog();
    setWarningBody({
      id: 100000,
      warningTitle: "Delete Admins Below?",
      confirmDelete: false,
    });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    //call update api
    const body = processRowUpdateBody(newRow);
    const updateResponse: UpdateProfileResponse | ErrorMessage =
      await updateProfile(
        String(newRow.id),
        body as UpdateProfile,
        localStorage.getItem("access")
      );

    if ("error" in updateResponse) {
      openSnack(updateResponse.error.join(" "), false);
      return updateResponse.error.join(" ");
    } else {
      openSnack("Site Admin is updated!", true);
    }
    return newRow;
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const columns: GridColumns = [
    {
      ...ID_COLUMN,
    },
    {
      ...IMAGE_COLUMN,
    },
    {
      ...EMAIL_COLUMN,
    },
    {
      ...MOBILE_NUMBER_COLUMN,
    },
    {
      ...PREFERRED_NAME_COLUMN,
    },
    {
      ...FULL_NAME_COLUMN,
    },
    {
      ...DOB_COLUMN,
    },
    {
      ...ROLE_COLUMN(profileDetails.role),
    },
    {
      ...ACTIONS_COLUMN({
        rowModesModel,
        handleSaveClick,
        handleCancelClick,
        handleEditClick,
        handleDeleteClick,
      }),
    },
  ];

  const [columnVisibility, setColumnVisibility] = useState<any>(() => {
    //Return {name: true, description: true, xxx:true ......}
    const finalObj = {};
    columns.forEach((column) => {
      Object.assign(finalObj, { [column.field]: true });
    });
    return finalObj;
  });

  // Add filter for each user
  //useEffect
  useEffect(() => {
    const populateData = async () => {
      const siteAdminListRes = await getAllSiteAdminProfile();
      setSiteAdminList(siteAdminListRes);
      setRows(siteAdminListRes);
    };

    populateData();
  }, []);

  useEffect(() => {
    if (
      warningBody.confirmDelete === true &&
      warningBody.id !== -1 &&
      warningBody.id !== 100000
    ) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    } else if (warningBody.confirmDelete == true && warningBody.id === 100000) {
      handleBulkDelete(selectionModel);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  return (
    <>
      <DataGridWarningDialog
        {...warningBodyStatic}
        {...warningBody}
        names={bulkActionNames}
      />
      <Box>
        <div style={DATA_GRID_STYLE}>
          <DataGrid
            checkboxSelection
            sx={{
              fontSize: 18,
              "& .MuiDataGrid-cell--withRenderer": {
                padding: 0,
              },
              "& .MuiDataGrid-row .MuiDataGrid-cellCheckbox": {
                opacity: bulkDeleteLoading ? 0.5 : 1,
                pointerEvents: bulkDeleteLoading ? "none" : "auto",
              },
            }}
            rows={rows}
            columns={columns}
            columnVisibilityModel={columnVisibility}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            onCellClick={(
              params: GridCellParams,
              event: MuiEvent<React.MouseEvent>
            ) => {
              event.defaultMuiPrevented = true;
              if (!bulkDeleteLoading) {
                setSelectionModel([]);
              }
            }}
            onColumnVisibilityModelChange={(
              model: GridColumnVisibilityModel,
              details: GridCallbackDetails
            ) => {
              setColumnVisibility(model);
            }}
            components={{
              Toolbar: EditToolbarProfile,
            }}
            componentsProps={{
              toolbar: {
                rows,
                setRows,
                setRowModesModel,
                role: "WAD",
                bulkDeleteLoading: bulkDeleteLoading,
                handleBulkDeleteClick: handleBulkDeleteClick,
                selectionModel: selectionModel,
                setSelectionModel: setSelectionModel,
                names: bulkActionNames,
                childBulkAction: false,
              },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Box>
    </>
  );
};

export default SiteAdminManagePage;

import React, { useEffect, useState, useRef, useCallback } from "react";

import useStore from "../../store/store";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import useSnack from "hooks/useSnack";

import {
  IconButton,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Image from "components/Image/Image";
import Logo from "images/logo-2-colour.png";

import { API_Profile_Short } from "types/profile";
import { createOrUpdateAttendance, getAttendanceDateRange } from "api/auth-api";

const AttendanceWeekMonthOther = ({
  filteredChildren,
  view,
  setModalText,
  setModalAbsentee,
  startDate,
  endDate,
}: {
  filteredChildren: API_Profile_Short[];
  view: string;
  setModalText: React.Dispatch<React.SetStateAction<string>>;
  setModalAbsentee: React.Dispatch<React.SetStateAction<any>>;
  startDate: any;
  endDate: any;
}) => {
  const currentDate = new Date();

  const nthNumber = (number: any) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  function formatFullDateToYYMMDD(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // GETTING ATTENDANCES WEEK AND MONTH AND OTHER
  const getAttendances = async (startDate: any, endDate: any) => {
    const access = localStorage.getItem("access");
    if (access) {
      const response = await getAttendanceDateRange(currGroup.id, access, startDate, endDate);
      setAbsentDays(response?.data);
    }
  };
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));

  const [containerWidth, setContainerWidth] = useState<number>(0);

  const [displayDays, setDisplayDays] = useState<any[]>([]);
  const [absentDays, setAbsentDays] = useState<any[]>([]);

  // MONTH AND OTHER
  function getDaysBetweenDates(startDate: Date, endDate: Date) {
    let currentDate = new Date(startDate);
    let weekdays: any[] = [];

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      // Check if the day is a weekday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        let date = new Date(currentDate);
        const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
        const specificDate = `${date.getDate()}${nthNumber(date.getDate())}`;
        weekdays.push({ date, dayName, specificDate });
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }
    setDisplayDays(weekdays);
  }

  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const startOfWeek = currentDate.getDate() - currentDate.getDay() + 1;
  const endOfWeek = startOfWeek + 4;
  const startOfWeekDate = new Date(currentDate.setDate(startOfWeek));
  const endOfWeekDate = new Date(currentDate.setDate(endOfWeek));

  useEffect(() => {
    if (tableContainerRef.current) {
      const width = tableContainerRef.current.offsetWidth;
      setContainerWidth(width);
    }
  }, []);

  useEffect(() => {
    if (currGroup.id != -1) {
      if (view == "week") {
        getDaysBetweenDates(startOfWeekDate, endOfWeekDate);
        getAttendances(formatFullDateToYYMMDD(startOfWeekDate), formatFullDateToYYMMDD(endOfWeekDate));
      } else if (view == "month") {
        getDaysBetweenDates(firstDayOfMonth, lastDayOfMonth);
        getAttendances(formatFullDateToYYMMDD(firstDayOfMonth), formatFullDateToYYMMDD(lastDayOfMonth));
      } else if (view == "other") {
        getDaysBetweenDates(startDate, endDate);
        getAttendances(formatFullDateToYYMMDD(startDate), formatFullDateToYYMMDD(endDate));
      }
    }
  }, [currGroup, view, createOrUpdateAttendance]);

  return (
    <TableContainer
      sx={{
        marginY: 2,
        borderRadius: "4px",
        border: "1px solid #C9C9C9",
        height: "100%",
      }}
      ref={tableContainerRef}
    >
      <Table sx={{ width: "max-content", flex: 1, overflowX: "hidden" }} stickyHeader>
        <TableHead>
          {/* FIRST ROW (HEADER) */}
          <TableRow>
            <TableCell
              sx={{
                height: "32px",
                backgroundColor: "#D8F2EE",
                zIndex: 7,
                position: "sticky",
                left: 0,
                border: "1px solid #C9C9C9",
                width: containerWidth / 4,
                fontSize: "16px",
                fontWeight: "450",
              }}
            >
              Name
            </TableCell>
            {displayDays.map((day, index) => {
              return (
                <TableCell
                  sx={{
                    height: "32px",
                    position: "sticky",
                    left: 0,
                    zIndex: 6,
                    border: "1px solid #C9C9C9",
                    width: (containerWidth * 0.75) / 5,
                    textAlign: "center",
                  }}
                  key={index}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "450" }}>{day["dayName"]}</Typography>
                  <Typography sx={{ fontSize: "14px", fontWeight: "450" }}>
                    {day["specificDate"]} {day["date"].toLocaleString("default", { month: "short" })}{" "}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            overflow: "hidden",
          }}
        >
          {filteredChildren.map((child: any) => {
            return (
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "white",
                    position: "sticky",
                    left: 0,
                    zIndex: 5,
                    height: "40px",
                    border: "1px solid #C9C9C9",
                    width: containerWidth / 4,
                    fontSize: "16px",
                    fontWeight: "450",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        overflow: "hidden",
                        position: "relative",
                        width: 60,
                        height: 60,
                      }}
                    >
                      <Box
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          paddingTop: "100%",
                        }}
                      >
                        <Image
                          src={child.image || Logo}
                          alt={child.full_name}
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                      {child.preferred_name}
                    </Typography>
                  </Box>
                </TableCell>
                {displayDays.map((day, index) => {
                  return (
                    <AttendanceCell
                      day={day}
                      child={child}
                      absentDays={absentDays}
                      containerWidth={containerWidth}
                      setModalText={setModalText}
                      setModalAbsentee={setModalAbsentee}
                      formatFullDateToYYMMDD={formatFullDateToYYMMDD}
                    />
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AttendanceWeekMonthOther;

const AttendanceCell = ({
  day,
  child,
  absentDays,
  containerWidth,
  setModalText,
  setModalAbsentee,
  formatFullDateToYYMMDD,
}: {
  day: any;
  child: API_Profile_Short;
  absentDays: any;
  containerWidth: number;
  setModalText: React.Dispatch<React.SetStateAction<string>>;
  setModalAbsentee: React.Dispatch<React.SetStateAction<any>>;
  formatFullDateToYYMMDD: (date: any) => string;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [absentee, setAbsentee] = useState<any>({});
  const [reasonText, setReasonText] = useState<any>("");

  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));

  const findAbsentDay = useCallback(() => {
    if (currGroup.id != 1) {
      absentDays.map((absentDay: any) => {
        if (absentDay.profile == child.id && absentDay.date == formatFullDateToYYMMDD(day["date"])) {
          setAbsentee(absentDay);
          setReasonText(absentDay.absent_reason);
        }
      });
    }
  }, [absentDays, currGroup.id, child.id, day["date"]]);

  useEffect(() => {
    findAbsentDay();
  }, [findAbsentDay]);

  return (
    <TableCell
      sx={{
        zIndex: 4,
        height: "32px",
        border: "1px solid #C9C9C9",
        width: (containerWidth * 0.75) / 5,
        textAlign: "center",
        backgroundColor: absentee.profile == child.id ? "#F9EAE9" : "white",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {/* CHILD IS ABSENT */}
      {absentee.profile == child.id ? (
        isHovered ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
            <HalfChip
              day={day}
              absentee={absentee}
              setAbsentee={setAbsentee}
              child={child}
              formatFullDateToYYMMDD={formatFullDateToYYMMDD}
              reasonText={reasonText}
            />
            <IconButton
              sx={{ padding: 0, margin: 0 }}
              onClick={() => {
                setModalText(reasonText);
                setModalAbsentee({
                  profile: child.id,
                  date: formatFullDateToYYMMDD(day["date"]),
                  absent_reason: reasonText,
                });
              }}
            >
              <StickyNote2OutlinedIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
        ) : (
          // DISPLAY WHEN CHILD ABSENT

          <CloseIcon sx={{ color: "#FF5D53" }} />
        )
      ) : // CHILD IS PRESENT
        isHovered ? (
          <HalfChip
            day={day}
            absentee={absentee}
            setAbsentee={setAbsentee}
            child={child}
            formatFullDateToYYMMDD={formatFullDateToYYMMDD}
            reasonText={reasonText}
          />
        ) : (
          // DISPLAY WHEN CHILD PRESENT
          <DoneIcon sx={{ color: "#5CCA9C" }} />
        )}
    </TableCell>
  );
};

function HalfChip({
  day,
  absentee,
  setAbsentee,
  child,
  formatFullDateToYYMMDD,
  reasonText,
}: {
  day: any;
  absentee: any;
  setAbsentee?: any;
  child: API_Profile_Short;
  formatFullDateToYYMMDD: (date: Date) => string;
  reasonText: string;
}) {
  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));
  const openSnack = useSnack();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "116px",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            color: "white",
            fontWeight: "bold",
            width: "100%",
            backgroundColor: absentee.profile == child.id ? "#C9C9C9" : "#5CCA9C",
            borderTopLeftRadius: "35px",
            borderBottomLeftRadius: "35px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            disabled={absentee.profile != child.id}
            sx={{ color: "white", "&:hover": { backgroundColor: "transparent" } }}
            onClick={() => {
              if (reasonText.length > 0) {
                const updateSingleAbsentee = async () => {
                  const access = localStorage.getItem("access");
                  if (access) {
                    const response = await createOrUpdateAttendance(
                      [
                        {
                          profile: absentee.profile,
                          absent_reason: "",
                          date: formatFullDateToYYMMDD(day["date"]),
                        },
                      ],
                      currGroup.id,
                      access
                    );

                    if (response?.data.length == 0 && response.status == 200) {
                      openSnack("Child is Present", true);
                    } else if (response?.status != 200) {
                      openSnack("Error setting Attendance", false);
                    }
                  }
                };

                updateSingleAbsentee();
              }
              setAbsentee({});
            }}
          >
            {/* CLICK TO CHANGE FROM ABSENT TO PRESENT */}
            <DoneIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            color: "white",
            fontWeight: "bold",
            width: "100%",
            backgroundColor: absentee.profile == child.id ? "#FF5D53" : "#C9C9C9",
            borderTopRightRadius: "35px",
            borderBottomRightRadius: "35px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            disabled={absentee.profile == child.id && absentee.absent_reason != ""}
            sx={{ color: "white", "&:hover": { backgroundColor: "transparent" } }}
            onClick={() => {
              if (absentee.profile != child.id) {
                setAbsentee({
                  profile: child.id,
                  date: formatFullDateToYYMMDD(day["date"]),
                  group: currGroup.id,
                  absent_reason: reasonText,
                });
              } else {
                setAbsentee({});
              }
            }}
          >
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

import React, { useState } from "react";

import { Tooltip } from "@mui/material";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";

import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import { PDFInfoType, LessonType, ImageType } from "./constant";
import SelectImageModal from "./SelectImageModal";
import SelectTemplateModal from "./SelectTemplateModal";
import { translation } from "constants/translation";

import LT1Image from "./LT1Image";
import LT2Images from "./LT2Images";
import LT3Images from "./LT3Images";
import LT4Images from "./LT4Images";
import LT5Images from "./LT5Images";

const LessonPage = ({
  gallery,
  scale,
  lesson,
  lessonIndex,
  setPDFInfo,
}: {
  gallery: GalleryImage[];
  scale: number;
  lesson: LessonType;
  lessonIndex: number;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
}) => {
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);

  const handleChange = (key: string, value: string | null) =>
    setPDFInfo((prev) => ({
      ...prev,
      lessons: prev.lessons.map((lesson, index) =>
        index === lessonIndex
          ? { ...lesson, [key]: value ? value : "" }
          : lesson
      ),
    }));

  const handleChangeImage = (selectedImage: ImageType) =>
    setPDFInfo((prev) => ({
      ...prev,
      lessons: prev.lessons.map((lesson, index) =>
        index === lessonIndex
          ? {
              ...lesson,
              images: lesson.images.map((image, imageIndex) =>
                imageIndex === selectedImageIndex ? selectedImage : image
              ),
            }
          : lesson
      ),
    }));

  const handleChangeTemplate = (value: number) =>
    setPDFInfo((prev) => ({
      ...prev,
      lessons: prev.lessons.map((lesson, index) =>
        index === lessonIndex ? { ...lesson, templateNo: value } : lesson
      ),
    }));

  const SelectTemplate = () => (
    <Tooltip title={translation.changeTemplate}>
      <GridViewSharpIcon
        fontSize="small"
        sx={{
          position: "absolute",
          top: `${20 * scale}px`,
          right: `${20 * scale}px`,
          cursor: "pointer",
          pointerEvents: "auto",
          zIndex: 5,
          color: "txt.secondary",
          transition: "all 100ms ease-in-out",
          "&:hover": {
            color: "primary.main",
            transition: "all 100ms ease-in-out",
          },
        }}
        onClick={() => setOpenTemplateModal(true)}
      />
    </Tooltip>
  );

  const TemplateProps = {
    scale,
    lesson,
    handleChange,
    setOpenModal: setOpenImageModal,
    setSelectedImageIndex,
    children: <SelectTemplate />,
  };

  const Template = [
    <LT1Image {...TemplateProps} />,
    <LT2Images {...TemplateProps} />,
    <LT3Images {...TemplateProps} />,
    <LT4Images {...TemplateProps} />,
    <LT5Images {...TemplateProps} />,
  ];

  const images = gallery[lessonIndex].images.map((image) => ({
    id: image.key as number,
    file: image.src,
    caption: image.alt,
  }));

  return (
    <>
      {Template[lesson.templateNo - 1]}

      {openImageModal && selectedImageIndex !== -1 && (
        <SelectImageModal
          open={openImageModal}
          setOpen={setOpenImageModal}
          images={images}
          setImage={(image: ImageType) => handleChangeImage(image)}
          selectedImage={lesson.images[selectedImageIndex]}
        />
      )}

      {openTemplateModal && (
        <SelectTemplateModal
          open={openTemplateModal}
          setOpen={setOpenTemplateModal}
          lesson={lesson}
          setTemplateNo={(templateNo: number) =>
            handleChangeTemplate(templateNo)
          }
          selectedTemplateNo={lesson.templateNo}
        />
      )}
    </>
  );
};

export default LessonPage;

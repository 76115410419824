import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import useStore from "store/store";
import { CalendarViewOptions, MONTHS } from "types/calendar";
import {
  CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
  CALENDAR_CELL_BACKGROUND_COLOR,
  FONT_SIZE,
  RIGHT_COLUMN_WIDTH,
} from "containers/CalendarPage/constants/constants";
import { daysOfAYear } from "containers/CalendarPage/constants/helper";

const OverviewRow = () => {
  //  -----------------------
  //  |  E  |  Overview Row |
  //  |  M  |----------------
  //  |  P  | DR | DR  | DR |
  //  -----------------------
  //  | Left| Right/GridRow |
  //  | Left| Right/GridRow |
  //  | Left| Right/GridRow |
  const { currentView, calendarDates } = useStore((state) => ({
    currentView: Object.keys(state.calendarViews).filter(
      (view) => state.calendarViews[view as CalendarViewOptions] === true
    )[0] as CalendarViewOptions,
    calendarDates: state.calendarDates,
  }));

  const overviewLabel = generateOverviewLabel(currentView, calendarDates);
  // console.log("calendarDates: ", calendarDates.length);
  // console.log("overviewLabel: ", overviewLabel);
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: CALENDAR_CELL_BACKGROUND_COLOR,
        justifyContent: "space-between",
      }}
    >
      {overviewLabel.map((label, index) => (
        <Fragment key={`${label}-${index}`}>
          <Typography
            sx={{
              width: `${
                (RIGHT_COLUMN_WIDTH / calendarDates.length) * label.size
              }vw`,
              fontSize: `${FONT_SIZE}px`,
              fontWeight: "500",
              display: "flex",
              justifyContent: "center",
              borderRight: CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
              height: "20px",
            }}
          >
            {label.size >= 4 ? label.title : label.title.slice(0, 3)}
          </Typography>
        </Fragment>
      ))}
    </Box>
  );
};

const generateOverviewLabel = (
  currentView: CalendarViewOptions,
  calendarDates: Date[]
) => {
  const overviewLabel: { title: string; size: number }[] = [];
  if (calendarDates.length !== 0) {
    if (currentView === "Year") {
      overviewLabel.push({
        title: calendarDates[0].getFullYear().toString(),
        size: daysOfAYear(calendarDates[0].getFullYear()),
      });
    } else {
      calendarDates.forEach((date) => {
        if (overviewLabel.length === 0) {
          overviewLabel.push({ title: MONTHS[date.getMonth()], size: 1 });
        } else if (
          overviewLabel[overviewLabel.length - 1].title ===
          MONTHS[date.getMonth()]
        ) {
          overviewLabel[overviewLabel.length - 1].size += 1;
        } else {
          overviewLabel.push({ title: MONTHS[date.getMonth()], size: 1 });
        }
      });
    }
  }

  return overviewLabel;
};

export default OverviewRow;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Rating,
  TextField,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Avatar,
} from "@mui/material";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
import Button from "components/Button/Button";
import { Profile } from "components/Review/Components";
import Logo from "images/logo-2-colour.png";

import { createTeacherModuleReview } from "api/teacher-api";
import { translation } from "constants/translation";
import { UserRoleMappingInverse } from "types/auth";
import { API_Teacher_Module_Review_Create } from "types/teacher";

const FeedbackModal = () => {
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");

  const {
    profile,
    isOpenTeacherModuleReviewModal,
    setIsOpenTeacherModuleReviewModal,
  } = useStore((state) => ({
    profile: state.profileDetails,
    isOpenTeacherModuleReviewModal: state.isOpenTeacherModuleReviewModal,
    setIsOpenTeacherModuleReviewModal: state.setIsOpenTeacherModuleReviewModal,
  }));

  const handleClose = () => {
    setIsOpenTeacherModuleReviewModal(false);
  };

  return (
    <Modal
      open={isOpenTeacherModuleReviewModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(49,47,48,0.4)",
        },
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={isOpenTeacherModuleReviewModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography> {translation.modulelCompleted}</Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 450 }}>
              {translation.whatDidYouFeelModule}
            </Typography>
          </Box>

          <Form
            rating={rating}
            setRating={setRating}
            feedback={feedback}
            setFeedback={setFeedback}
          >
            <ProfileDetails
              avatar={profile.image}
              name={profile.preferred_name}
              role={UserRoleMappingInverse[profile.role]}
              school={profile.school ? profile.school.name : "Little Lab"}
            />
          </Form>

          <Submit
            rating={rating}
            feedback={feedback}
            handleClose={handleClose}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

const ProfileDetails = ({
  avatar,
  name,
  role,
  school,
}: {
  avatar: string;
  name: string;
  role: string;
  school: string;
}) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: 1.5,
      mb: 2,
    }}
  >
    <Avatar
      src={avatar}
      alt={name}
      sx={{ width: "40px", height: "40px", mb: 0.25 }}
      imgProps={{
        onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
          event.currentTarget.onerror = null;
          event.currentTarget.src = Logo;
        },
      }}
    />

    <Profile name={name} role={role} school={school} />
  </Box>
);

const Form = ({
  rating,
  setRating,
  feedback,
  setFeedback,
  children,
}: {
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number>>;
  feedback: string;
  setFeedback: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <Rating
      sx={{
        fontSize: "3.5rem",
        mt: 1,
        mb: 2,
      }}
      value={rating}
      onChange={(event, newValue) => {
        if (newValue !== null) setRating(newValue);
      }}
    />

    {children}

    <TextField
      placeholder={translation.whatDoYouLikeModule}
      value={feedback}
      onChange={(event) => {
        setFeedback(event.target.value);
      }}
      fullWidth
      multiline
      rows={6}
    />
  </Box>
);

const Submit = ({
  rating,
  feedback,
  handleClose,
}: {
  rating: number;
  feedback: string;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    teacherCurrentModule,
    currLanguage,
    profileDetails,
    setTeacherCurrentModuleReview,
  } = useStore((state) => ({
    teacherCurrentModule: state.teacherCurrentModule,
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    setTeacherCurrentModuleReview: state.setTeacherCurrentModuleReview,
  }));

  const createReview = async () => {
    setIsLoading(true);

    const reviewData: API_Teacher_Module_Review_Create = {
      name: feedback,
      rating: rating,
      profile: profileDetails.id,
      module: teacherCurrentModule.id,
    };

    const res = await createTeacherModuleReview(currLanguage, reviewData);

    setIsLoading(false);

    if (typeof res !== "string") {
      handleClose();
      setTeacherCurrentModuleReview(res);
      openSnack(
        translation.reviewCreated || "Review created successfully",
        true
      );
    } else {
      console.log("res: ", res);
      openSnack(translation.tryAgainLater, false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        mt: 4,
      }}
    >
      <Button
        buttonText={translation.cancel}
        arrow={false}
        style={{
          fontSize: "14px",
          backgroundColor: "transparent",
          color: "var(--primary-main)",
          border: "1px solid var(--primary-main)",
          padding: "9px 25px",
          marginRight: "16px",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        onClick={handleClose}
      />

      <Button
        arrow={false}
        disabled={(rating === 0 && feedback === "") || isLoading}
        style={{
          fontSize: "14px",
          backgroundColor: "var(--primary-main)",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        buttonText={translation.submit}
        onClick={createReview}
      />
    </Box>
  );
};

export default FeedbackModal;

import { Box, SxProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CURLANG,
  MARKETPLACE,
  LITTLE_EVALUATOR,
  PROJECT_MANAGE_PAGE,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";
import useStore from "store/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { translation } from "constants/translation";

type BackButtonProps = {
  variant: "tta" | "ttb" | "assessment" | "custom";
  sx?: SxProps;
  text?: string;
  onBackClick?: () => void;
}

const BackButton = ({ text, sx, variant, onBackClick }: BackButtonProps) => {
  const navigate = useNavigate();
  const { profileDetails, currLanguage } = useStore((state) => state);
    
  const handleBack = () => { 
    if (variant === "tta") { 
      navigate(`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`);
    } else if (variant === "ttb" || variant === "assessment") {
      if (profileDetails.role === "CE") {
        navigate(`${CURLANG(currLanguage)}/${PROJECT_MANAGE_PAGE}`);
      } else if (variant === "ttb") {
        navigate(`${CURLANG(currLanguage)}/${MARKETPLACE}`);
      } else { 
        navigate(`${CURLANG(currLanguage)}/${LITTLE_EVALUATOR}`);
      }
    } else { 
      onBackClick?.();
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        cursor: "pointer",
        pl: "16px",
        ...sx,
      }}
      onClick={handleBack}
    >
      <ArrowBackIcon sx={{ fontSize: "14px" }} />
      <Typography>{text ?? translation.backToLibrary}</Typography>
    </Box>
  );
};

export default BackButton;

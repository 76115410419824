import React from "react";
import { Box, Typography } from "@mui/material";
import { API_Activity_Instruction } from "types/project";
import { translation } from "constants/translation";
import { InstructionSortableList } from "../../ActivityInstructionComponents/InstructionSortableList";
import useStore from "store/store";

import DashedButton from "components/Button/DashedButton";
import InstructionFieldForDnd from "./InstructionFieldForDnd";

type Props = {
  assessment: boolean;
  activityInstructions: API_Activity_Instruction[];
  setActivityInstructions: React.Dispatch<
    React.SetStateAction<API_Activity_Instruction[]>
  >;
};

export const InstructionsFieldWithDnd = ({
  assessment,
  activityInstructions,
  setActivityInstructions,
}: Props) => {
  const { currActivity } = useStore((state) => state);

  const handleAdd = () => {
    const updatedInstructions: API_Activity_Instruction[] = [
      ...activityInstructions,
      {
        id: `unassigned-id-${activityInstructions.length}`,
        activity: currActivity.id,
        name: "",
        recommendation: "",
        weight: activityInstructions.length + 1,
        learning_moment: undefined,
      },
    ];

    setActivityInstructions(updatedInstructions);
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Typography>{translation.stepByStepInstructions}</Typography>
        <InstructionSortableList
          sx={{
            pl: 0,
          }}
          items={activityInstructions}
          setItems={setActivityInstructions}
          renderItem={(item) => {
            return (
              <InstructionSortableList.Item
                sx={{ padding: 0, margin: 0 }}
                id={item.id}
              >
                <InstructionFieldForDnd
                  assessment={assessment}
                  item={item}
                  activityInstructions={activityInstructions}
                  setActivityInstructions={setActivityInstructions}
                />
                <InstructionSortableList.DragHandle />
              </InstructionSortableList.Item>
            );
          }}
        />
        <DashedButton text={translation.addStep} onClick={handleAdd} />
      </Box>
    </Box>
  );
};

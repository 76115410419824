import React, { useLayoutEffect, useState } from "react";

import useStore from "store/store";

import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import CloseIcon from "@mui/icons-material/Close";

import { Modal } from "@mui/material";

import Button from "../Button/Button";
import Image from "../Image/Image";
import { translation } from "constants/translation";
import { API_Project_Lesson } from "types/project";

import Logo from "../../images/logo-2.png";
import LogoBlur from "../../images/logo-2-colour-blur.png";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";

const ProjectSummaryDetailCard = ({
  lesson,
  lessonIndex,
  handleClick,
}: {
  lesson: API_Project_Lesson;
  lessonIndex: number;
  handleClick: () => void;
}) => {
  const [imgSrc, setImgSrc] = useState<string>(LogoBlur);

  useLayoutEffect(() => {
    if (lesson.images.length > 0) {
      setImgSrc(lesson.images[0].file);
    } else {
      setImgSrc(Logo);
    }
  }, [lesson.images]);

  const Title = ({ title }: { title?: string }) => {
    return (
      <Typography
        sx={{
          fontWeight: 500,
          textAlign: "center",
          p: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {title}
      </Typography>
    );
  };

  return (
    <Card
      sx={{
        flexShrink: 0,
        width: { xs: 125, sm: 150 },
        maxWidth: "150px",
        borderRadius: "20px",
        backgroundColor: "#F0F0F0BF",
      }}
      elevation={0}
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        alt={lesson.images.length > 0 ? lesson.images[0].file : "No Image"}
        height="120"
        image={imgSrc}
        sx={{
          height: "120px",
          backgroundColor: "primary.light",
          "&::before": { display: "none" },
        }}
      />
      <Title title={`Lesson ${lessonIndex + 1}`} />
    </Card>
  );
};

export const ProjectSummaryDetailCardModal = ({
  open,
  handleClose,
  currLesson,
  currLessonIndex,
  teacher,
}: {
  open: boolean;
  handleClose: () => void;
  currLesson: API_Project_Lesson;
  currLessonIndex: number;
  teacher: boolean;
}) => {
  const navigate = useNavigate();

  const { projSlug } = useStore((state) => ({
    projSlug: state.currentProject.slug,
  }));

  const Close = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: 15,
          right: 15,
          borderRadius: "50%",
          backgroundColor: "txt.secondary",
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <CloseIcon sx={{ color: "white" }} />
      </Box>
    );
  };

  const Title = () => {
    return (
      <Box sx={{ textAlign: "center", mt: "20px" }}>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "30px",
            maxWidth: "70%",
            margin: "auto",
          }}
        >
          {`${translation.lesson} ${currLessonIndex + 1}: ${currLesson.name}`}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            mt: "6px",
            maxWidth: "70%",
            margin: "auto",
          }}
        >
          <CrisisAlertIcon />
          <div dangerouslySetInnerHTML={{ __html: currLesson.objective }} />
        </Typography>
      </Box>
    );
  };

  const LessonImage = () => {
    return (
      <Box
        sx={{
          width: "300px",
          height: "300px",
          borderRadius: "40px",
          overflow: "hidden",
        }}
      >
        <Image
          src={currLesson.images.length > 0 ? currLesson.images[0].file : Logo}
          alt={
            currLesson.images.length > 0
              ? currLesson.images[0].file
              : "No images"
          }
          objectFit="cover"
        />
      </Box>
    );
  };

  const LessonDetails = ({ teacher }: { teacher: boolean }) => {
    const Duration = () => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "fit-content",
          gap: "4px",
        }}
      >
        <AccessTimeIcon fontSize="large" />
        <Box>
          <Typography
            sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
          >
            {currLesson.duration || "?"}
          </Typography>
          <Typography
            sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
          >
            {translation.min}
          </Typography>
        </Box>
      </Box>
    );

    const Activities = () => (
      <Box
        sx={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
          height: "fit-content",
        }}
      >
        <AssignmentOutlinedIcon fontSize="large" />
        <Box>
          <Typography
            sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
          >
            {currLesson.activities.length}
          </Typography>
          <Typography
            sx={{ textAlign: "center", fontSize: "14px", lineHeight: "14px" }}
          >
            {translation.activities}
          </Typography>
        </Box>
      </Box>
    );

    const Progress = () => (
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            fontSize: "10px",
            textAlign: "center",
            mb: "4px",
          }}
        >{`${currLesson.activities.length} ${translation.activitiesToBeConducted}`}</Typography>
        <Box
          sx={{
            borderRadius: "5px",
            backgroundColor: "primary.light",
            width: "100%",
            height: "4px",
            overflow: "hidden",
            position: "relative",
            "&::after": {
              content: "''",
              width: "50%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "txt.secondary",
            },
          }}
        />
      </Box>
    );

    const ActivitiesList = () => {
      return (
        <Box sx={{ mt: "30px" }}>
          {currLesson.activities.map((act, index) => (
            <Typography key={index} sx={{ fontSize: "20px" }}>{`${
              translation.activity
            } ${index + 1}: ${act.name}`}</Typography>
          ))}
        </Box>
      );
    };

    const StartLessonButton = ({ teacher }: { teacher: boolean }) => {
      const { currLanguage } = useStore((state) => state);
      return (
        <Button
          buttonText="Start Lesson"
          arrow={false}
          style={{
            fontWeight: "normal",
            padding: "5px 30px",
            borderRadius: "5px",
            margin: "auto",
            marginTop: "40px",
            backgroundColor: "var(--txt-secondary)",
          }}
          onClick={() =>
            navigate(
              `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projSlug)}`
            )
          }
        />
      );
    };

    return (
      <Box sx={{ mt: "30px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            minWidth: "350px",
            flexWrap: "wrap",
            justifyContent: "center",
            my: "20px",
          }}
        >
          <Duration />
          <Box
            sx={{ borderRight: "2px solid black", ml: "40px", mr: "20px" }}
          />
          <Activities />
        </Box>
        <Progress />
        <ActivitiesList />
        <StartLessonButton teacher={teacher} />
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableAutoFocus={true}
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: "2",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70vw",
          minHeight: "600px",
          borderRadius: "10px",
          p: "10px",
          backgroundColor: "#d1e4e3dd",
          border: "2px solid #000",
          boxShadow: "2px 2px 20px var(--primary-main)",
        }}
      >
        <Close />
        <Title />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            my: "20px",
            gap: "50px",
          }}
        >
          <LessonImage />
          <LessonDetails teacher={teacher} />
        </Box>
      </Box>
    </Modal>
  );
};

export default ProjectSummaryDetailCard;

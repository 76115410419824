import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { getAlbumByModule } from "api/gallery-api";
import {
  NoPhotoYet,
  Album,
  ProjectCard,
  containerStyles,
} from "./PhotoAlbumComponents";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const ModuleAlbum = ({
  onLoad,
  handleClick,
}: {
  onLoad: (moduleName: string) => void;
  handleClick: (
    moduleId: string,
    moduleName: string,
    projectId: string,
    projectName: string
  ) => void;
}) => {
  const [searchParams] = useSearchParams();

  const moduleId = searchParams.get("module");
  const { childID } = useParams();

  const [album, setAlbum] = useState<Album[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!childID || !moduleId) return;

    const initializeChildImages = async () => {
      setIsLoading(true);

      const album = await getAlbumByModule(
        Number(childID),
        moduleId,
        localStorage.getItem("access")
      );

      if (typeof album !== "string") {
        album.length !== 0 && onLoad(album[0].project.module.name);
        setAlbum(album);
      }

      setIsLoading(false);
    };

    initializeChildImages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childID, moduleId]);

  return isLoading ? (
    <LoadingIndicator />
  ) : album.length === 0 ? (
    <NoPhotoYet />
  ) : (
    <Box sx={containerStyles}>
      {album.map((image, index) => (
        <ProjectCard
          key={index}
          image={image}
          onClick={() =>
            handleClick(
              image.project.module.id.toString(),
              image.project.module.name,
              image.project.id.toString(),
              image.project.name
            )
          }
        />
      ))}
    </Box>
  );
};

export default ModuleAlbum;

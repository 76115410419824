import axios from "api/axiosConfig";
import { AxiosError } from "axios";

import { API_Child } from "types/portfolio";
import { Query_Data } from "types/project";
import { MAX_QUERY } from "containers/teacher/TeacherArchivePage/TeacherArchivePageComponent/TeacherArchiveCommonComponent/TeacherArchiveCommonComponent";

export const getCompletedWorkList = async ({
  language,
  childId,
  queryData,
  selectedModuleSlugs,
  selectedClassSlugs,
  selectedChildIds,
  groupId,
  projectId,
  isTeacherArchive,
}: {
  language: string;
  childId?: number;
  queryData?: Query_Data;
  selectedModuleSlugs?: string[];
  selectedClassSlugs?: string[];
  selectedChildIds?: number[];
  groupId?: number;
  projectId?: number;
  isTeacherArchive?: boolean;
}) => {
  let path = `${language}/lms/completed-work/list?query_limit=${MAX_QUERY}`;
  if (childId) {
    path += `&child_id=${childId}`;
  }
  if (queryData && queryData.query) {
    path += `&search=${queryData.query}`;
  }
  if (queryData && queryData.startDate) {
    path += `&start_date=${queryData.startDate}`;
  }
  if (queryData && queryData.endDate) {
    path += `&end_date=${queryData.endDate}`;
  }
  if (selectedModuleSlugs) {
    path += `&module_slug=${
      selectedModuleSlugs.length > 0 ? selectedModuleSlugs.join(",") : "none"
    }`;
  }
  if (selectedClassSlugs) {
    path += `&class_slug=${
      selectedClassSlugs.length > 0 ? selectedClassSlugs.join(",") : "none"
    }`;
  }
  if (selectedChildIds) {
    path += `&child_id_list=${
      selectedChildIds.length > 0 ? selectedChildIds.join(",") : "none"
    }`;
  }
  if (groupId) {
    path += `&group_id=${groupId}`;
  }
  if (projectId) {
    path += `&project_id=${projectId}`;
  }
  if (isTeacherArchive) {
    path += `&is_teacher_archive=true`;
  }

  try {
    const res = await axios.get(path);
    const data: API_Child.CompletedWork[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getCompletedWorkById = async ({
  language,
  completedWorkId,
}: {
  language: string;
  completedWorkId: number;
}) => {
  const path = `${language}/lms/completed-work/${completedWorkId}`;
  try {
    const res = await axios.get(path);
    const data: API_Child.CompletedWork = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

// Completed Work // Little Work
export const createCompletedWork = async (
  completedWorkData: API_Child.CreateCompletedWork,
  setIsAddingCompletedWork: React.Dispatch<React.SetStateAction<boolean>>,
  setCompletedWorkProgressPercentage: React.Dispatch<
    React.SetStateAction<number>
  >
) => {
  // console.log("childId: ", childId, "milestoneId", milestoneId);
  // console.log("CompletedWorkData: ", CompletedWorkData);
  try {
    const res = await axios.post(
      "en/lms/completed-work/create",
      completedWorkData,
      {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setCompletedWorkProgressPercentage(percentage);
        },
      }
    );
    const data: API_Child.CompletedWork = res.data;
    setIsAddingCompletedWork(false);
    setCompletedWorkProgressPercentage(0);
    return data;
  } catch (error: unknown) {
    setIsAddingCompletedWork(false);
    setCompletedWorkProgressPercentage(0);
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateCompletedWork = async (
  completedWorkData: API_Child.UpdateCompletedWork
) => {
  // console.log("completedWorkData: ", completedWorkData);
  try {
    const res = await axios.patch(
      `en/lms/completed-work/${completedWorkData.id}/update`,
      completedWorkData
    );
    const data: API_Child.CompletedWork = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteCompletedWork = async (completedWorkId: number) => {
  try {
    const res = await axios.delete(
      `en/lms/completed-work/${completedWorkId}/delete`
    );

    const data: API_Child.CompletedWork = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const tagProfilesToCompletedWork = async (
  completedWorkId: number,
  profileList: number[]
) => {
  const res = await axios.post(
    `en/lms/completed-work/${completedWorkId}/tag-to-profiles`,
    {
      profiles: profileList,
    }
  );
  const data: string = await res.data;
  return data;
};

import { Box, Typography } from "@mui/material";
import { useState } from "react";
import {
  API_Teacher_Drag_And_Drop_Activity,
  API_Teacher_Lesson,
  API_Drag_And_Drop_Question,
  API_Drag_And_Drop_Answer,
} from "types/teacher";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import DraggableCard from "./TeacherDragAndDropActivityComponents/DraggableCard";
import DroppableCard from "./TeacherDragAndDropActivityComponents/DroppableCard";
import useSnack from "hooks/useSnack";
import { shuffleArray } from "helper/helper";
import { TeacherLessonActivityScreenDoneButton } from "../TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";
import useStore from "store/store";
import { useParams } from "react-router-dom";

const TeacherDragAndDropActivityScreen = ({
  teacherCurrentActivity,
  teacherCurrentLesson,
}: {
  teacherCurrentActivity: API_Teacher_Drag_And_Drop_Activity;
  teacherCurrentLesson: API_Teacher_Lesson;
}) => {
  const openSnack = useSnack();
  const sensors = useSensors(useSensor(PointerSensor));
  const { lessonSlug } = useParams();

  const { learnerLessonProgressList } = useStore((state) => ({
    learnerLessonProgressList: state.learnerLessonProgressList,
  }));

  const currLessonIndex = learnerLessonProgressList.findIndex(
    (learnerLessonProgress) => learnerLessonProgress.lesson_slug === lessonSlug
  );

  const isActivityDone = learnerLessonProgressList[currLessonIndex]
    ? learnerLessonProgressList[currLessonIndex].activity_done_list.includes(
        teacherCurrentActivity.id
      )
    : false;

  const [dndQuestions, setDndQuestions] = useState<
    API_Drag_And_Drop_Question[]
  >(
    teacherCurrentActivity.drag_and_drop_answer_pairs
      ? shuffleArray(
          teacherCurrentActivity.drag_and_drop_answer_pairs.map(
            (drag_and_drop_answer_pair) => {
              return {
                id: `${drag_and_drop_answer_pair.id}-question`,
                question: drag_and_drop_answer_pair.question,
                isPutCorrectly: isActivityDone ? true : false,
              };
            }
          )
        )
      : []
  );

  const [dndAnswers, setDndAnswers] = useState<API_Drag_And_Drop_Answer[]>(
    teacherCurrentActivity.drag_and_drop_answer_pairs
      ? shuffleArray(
          teacherCurrentActivity.drag_and_drop_answer_pairs.map(
            (drag_and_drop_answer_pair) => {
              return {
                id: `${drag_and_drop_answer_pair.id}-answer`,
                question: drag_and_drop_answer_pair.question,
                answer: drag_and_drop_answer_pair.answer,
                isPutCorrectly: isActivityDone ? true : false,
              };
            }
          )
        )
      : []
  );

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const isAllCorrect = dndAnswers.every((answer) => answer.isPutCorrectly);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.paper",
        borderRadius: "10px",
        p: 2,
        gap: 1,
        flex: 1,
      }}
    >
      <Box sx={{ backgroundColor: "#EBF8F8", p: 2 }}>
        <Typography sx={{ fontSize: 16 }}>
          {teacherCurrentActivity.description}
        </Typography>
      </Box>

      <Typography sx={{ mt: 1, mb: 1 }}>Drag the answers</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 0",
          overflowY: "auto",
        }}
      >
        <DndContext
          sensors={sensors}
          onDragStart={({ active }) => {
            setIsDragging(true);
          }}
          onDragEnd={({ active, over }) => {
            if (over) {
              if (
                active.id.toString().split("-")[0] ===
                over.id.toString().split("-")[0]
              ) {
                let dndQuestionsCopy: API_Drag_And_Drop_Question[] = JSON.parse(
                  JSON.stringify(dndQuestions)
                );
                let dndAnswersCopy: API_Drag_And_Drop_Answer[] = JSON.parse(
                  JSON.stringify(dndAnswers)
                );

                const dndQuestionIndex = dndQuestionsCopy.findIndex(
                  (dndQuestion) => dndQuestion.id === active.id
                );
                const dndAnswerIndex = dndAnswersCopy.findIndex(
                  (dndAnswer) => dndAnswer.id === over.id
                );

                dndQuestionsCopy[dndQuestionIndex] = {
                  ...dndQuestionsCopy[dndQuestionIndex],
                  isPutCorrectly: true,
                };

                dndAnswersCopy[dndAnswerIndex] = {
                  ...dndAnswersCopy[dndAnswerIndex],
                  isPutCorrectly: true,
                };

                const moveToLast = (array: any[], index: number) => {
                  if (index !== -1) {
                    const element = array.splice(index, 1)[0]; // remove the element from its current position
                    array.push(element); // add the element to the end of the array
                  }
                  return array;
                };

                dndQuestionsCopy = moveToLast(
                  dndQuestionsCopy,
                  dndQuestionIndex
                );
                dndAnswersCopy = moveToLast(dndAnswersCopy, dndAnswerIndex);

                setDndQuestions(dndQuestionsCopy);
                setDndAnswers(dndAnswersCopy);
              } else {
                openSnack("The answer is incorrect! Please try again.", false);
              }
            }
            setIsDragging(false);
          }}
          onDragCancel={() => {
            setIsDragging(false);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 4,
              width: "100%",
            }}
          >
            <Box sx={{ flex: 1 }}>
              {dndAnswers.map((dndAnswer) => (
                <DroppableCard key={dndAnswer.id} dndAnswer={dndAnswer} />
              ))}
            </Box>
            <Box
              sx={{
                flex: 1,
                borderRadius: 1,
                backgroundColor: "#F2F2F2",
                display: "flex",
                flexDirection: "column",
                p: 2,
                gap: 2,
                border: "1px solid #C9C9C9",
              }}
            >
              <Typography sx={{ textAlign: "left" }}>
                Possible Answers
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                {" "}
                {dndQuestions.map((dndQuestion) => (
                  <DraggableCard
                    key={dndQuestion.id}
                    dndQuestion={dndQuestion}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </DndContext>
      </Box>
      {isAllCorrect && (
        <TeacherLessonActivityScreenDoneButton
          activityId={teacherCurrentActivity.id}
        />
      )}
    </Box>
  );
};

export default TeacherDragAndDropActivityScreen;

import useStore from "store/store";
import { Box } from "@mui/material";
import { API_Lesson_Checkpoint } from "types/project";
import CheckpointQuestionNameComponent from "./CheckpointQuestionNameComponent";
import CheckpointBoxes from "./CheckpointBoxes";

export const checkpointTabIconStyle = {
  mt: "-2px",
  width: 20,
  height: 20,
};
const CheckpointsSection = ({
  checkpointObject,
}: {
  checkpointObject: API_Lesson_Checkpoint;
}) => {
  const { childrenCount } = useStore((state) => ({
    childrenCount: state.currGroup.children.length,
  }));

  return childrenCount > 0 ? (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        paddingLeft: { xs: 1, sm: 3.5 },
        position: "relative",
        overflowX: "auto",
        "&:hover": {
          backgroundColor: "#eef5f5",
          "& .checkpoint-title": {
            backgroundColor: "#eef5f5",
          },
        },
      }}
    >
      <CheckpointQuestionNameComponent
        name={checkpointObject.name}
        description={checkpointObject.description}
        fundamentalId={checkpointObject.fundamental!}
        checkpointObject={checkpointObject}
      />
      <CheckpointBoxes checkpointID={checkpointObject.id!} />
    </Box>
  ) : null;
};

export default CheckpointsSection;

import React from "react";
import { Box, TextField, Typography, IconButton } from "@mui/material";

import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";

import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";
import Image from "components/Image/Image";
import Logo from "images/logo-2.png";

const NameField = ({
  lesson,
  setLesson,
  onChange,
}: {
  lesson: any;
  setLesson: any;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={translation.lesson_name}
    value={lesson.name ? lesson.name : ""}
    onChange={(event) => {
      setLesson((prev: any) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

const DescriptionField = ({
  lesson,
  setLesson,
  onChange,
}: {
  lesson: any;
  setLesson: any;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>
      {translation.lessonDescription}
      {/* <Typography component="span" sx={{ color: "red", ml: "2px" }}>
        *
      </Typography> */}
    </Typography>

    <RichCKEditor
      data={lesson.description}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setLesson((prev: any) => ({ ...prev, description: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

export const DiscussionTopicField = ({
  lesson,
  setLesson,
  isRequired = false,
  onChange,
}: {
  lesson: any;
  setLesson: any;
  isRequired?: boolean;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={translation.lessonDiscussionTopic}
    value={lesson.discussion_topic}
    onChange={(event) => {
      setLesson((prev: any) => ({
        ...prev,
        discussion_topic: event.target.value,
      }));
      onChange && onChange(event.target.value);
    }}
    required={isRequired}
  />
);

// Image
const ImageField = ({
  image,
  setImage,
}: {
  image: File | string;
  setImage: React.Dispatch<React.SetStateAction<File | string>>;
}) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
    <Typography>{translation.image}</Typography>

    <input
      type="file"
      accept="image/*"
      id="image-button-image"
      hidden
      onChange={(event) => {
        const newImage: File = event.target.files![0];
        setImage(newImage);
      }}
    />

    <label htmlFor="image-button-image">
      <IconButton
        component="span"
        sx={{
          borderRadius: "8px",
          backgroundColor: "primary.light",
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
      >
        <ImageIcon sx={{ color: "txt.secondary" }} />
      </IconButton>
    </label>
    {image && (
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 100,
            height: 100,
            borderRadius: "10px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Image
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt={Logo}
          />
          <ClearIcon
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              color: "txt.secondary",
              cursor: "pointer",
              backgroundColor: "primary.light",
              borderRadius: "50%",
              p: "3px",
              transition: "all 100ms linear",
              "&:hover": {
                p: "4px",
                backgroundColor: "primary.main",
                transition: "all 100ms linear",
              },
            }}
            onClick={() => {
              setImage("");
            }}
          />
        </Box>
      </Box>
    )}
  </Box>
);

export { ImageField, NameField, DescriptionField };

import React from "react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { translation } from "constants/translation";
import useStore from "store/store";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button/Button";
import LearningMomentScreen from "containers/ProjectSummaryPage/Screens/LearningMomentScreen";

const UploadLearningMomentPromptModal = ({
  open,
  setOpen,
  lessonIdIncompleteLearningMomentList,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lessonIdIncompleteLearningMomentList: number[];
}) => {
  const { projectSlug } = useParams();
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disablePortal={document.fullscreenElement ? true : false}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "65vw",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              p: 4,
              borderRadius: "10px",
              maxHeight: "70vh",
              overflow: "auto",
            }}
          >
            <LearningMomentScreen
              lessonIdIncompleteLearningMomentList={
                lessonIdIncompleteLearningMomentList
              }
            />
            {/* Done button */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                zIndex: 10,
                mt: 2,
              }}
            >
              <Box />
              <Button
                style={{
                  width: "33%",
                  backgroundColor: "default",
                }}
                buttonText={translation.done || "Done"}
                arrow={false}
                onClick={() => {
                  navigate(
                    `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
                      projectSlug!
                    )}/end`
                  );
                }}
                btnType={"filled"}
              />

              <Box />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default UploadLearningMomentPromptModal;

import { StoreSlice } from "./store";

export interface LanguageSlice {
  currLanguage: string;
  setCurrLanguage: (currLanguage: string) => void;
}

export const createLanguageSlice: StoreSlice<LanguageSlice> = (set, get) => ({
  currLanguage: "en",
  setCurrLanguage: (currLanguage: string) => {
    set((prev: LanguageSlice) => ({
      ...prev,
      currLanguage: currLanguage,
    }));
  },
});

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import CloseIcon from "@mui/icons-material/Close";
import ScheduleGroupPageSimpleMobile from "./ScheduleGroupPageSimpleMobile";

const ScheduleGroupPageModalMobile = ({
  open,
  handleClose,
  projectId,
  projectTitle,
  groupId,
  scheduleId,
  scheduleDatetimeId,
  startAt,
  endAt,
  handleOpenDialog,
  projectSlug,
  projectModuleName,
  isUpdate = false,
  projectImage,
  setProjectCard,
  setBackpackOpen,
}: // groupList,

{
  open: boolean;
  handleClose: () => void;
  projectId: number;
  projectTitle: string;
  groupId: number;
  scheduleId?: number;
  scheduleDatetimeId?: number;
  startAt?: string;
  endAt?: string;
  handleOpenDialog?: () => void;
  projectSlug?: string;
  isUpdate?: boolean;
  projectModuleName: string;
  projectImage: string;
  setProjectCard: any;
  setBackpackOpen: any;
  // groupList
}) => {
  // console.log(projectSlug);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        // style: { backgroundColor: "rgba(0, 0, 0,0.1)" },
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 2,
            paddingY: 4,
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "85%",
          }}
        >
          <ScheduleGroupPageSimpleMobile
            setBackpackOpen={setBackpackOpen}
            groupId={groupId}
            projectId={projectId}
            projectTitle={projectTitle}
            scheduleId={scheduleId}
            scheduleDatetimeId={scheduleDatetimeId}
            handleClose={handleClose}
            preDefinedStartAt={startAt}
            preDefinedEndAt={endAt}
            handleOpenDialog={handleOpenDialog}
            projectSlug={projectSlug}
            isUpdate={isUpdate}
            projectModuleName={projectModuleName}
            projectImage={projectImage}
            setProjectCard={setProjectCard}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ScheduleGroupPageModalMobile;

export const CURLANG = (currLanguage: string) => {
  return `/${currLanguage}`;
};

// Base
export const HOME = "";
export const HELP = "help";
export const MARKETPLACE = "library";
export const LITTLE_EVALUATOR = "little-evaluator";
export const BACKPACK = "backpack";
export const CALENDAR = "scheduler";
export const TIMETABLE = "timetable";
export const GANTTCHART = "ganttchart";
export const SCHEDULE = "schedule";
export const BASE_PORTFOLIO = "portfolio";
export const CHILDREN_PORTFOLIO = "children-portfolio";
export const TEACHER_PORTFOLIO = "teacher-archive";
export const CLASS = "my-classes";
export const CLASS_DETAIL = "class-detail";
export const ATTENDANCE = 'attendance';
export const TEACHER = "teacher";
export const TEACHER_TRAINING_ACADEMY = "teacher-training-academy";
export const CERTIFICATE = "certificate";
export const PARENT = "parent";
export const LOGIN = "login";
export const FORGOTTEN_PASSWORD = "forgot-password";
export const RESET_PASSWORD = (uid: string, token: string) => {
  return `reset-password/${uid}/${token}`;
};
export const REGISTER = "register";
export const PROFILE = "profile";
export const LEARNING_STORIES= "learning-stories"
export const SCHOOL_PROFILE = "school-profile";
export const WELCOME_BACK_PAGE = "welcome";
export const DEFAULT_PROJECT_SUMMARY = "project/my-magic-hands/summary";
export const SCHOOL = "school";
export const SCHOOLS = "schools";
export const PROJECT = "project";
export const ASSESSMENT = "assessment";
export const ASSESSMENT_ANALYTICS = "assessment-analytics";
export const CHILD = "child";
export const CHILDREN = "children";
export const MY_CHILDREN_PORTFOLIO = "my-" + CHILDREN_PORTFOLIO;
export const NOTICES = "notices";
export const DOCUMENTS = "documents";
export const ACTION_REQUIRED = "action-required";
export const ACCOUNT = "account";
export const SETTINGS = "settings";
export const EMAIL_PREFERENCES = "email-preferences";
export const CHANGE_PASSWORD = `${ACCOUNT}/change_password`;
export const TICKETING_SYSTEM = "ticketing-system";
export const CHATHOME = "chat";
export const CHAT = (chatID: string) => {
  return `${CHATHOME}/${chatID}`;
};
export const DEVELOPMENTAL_GOAL = "developmental-goal";
export const DEVELOPMENTAL_GOALS = "developmental-goals";
export const PROHIBITED_WORD = "prohibited-word";
export const ANNOUNCEMENT = "announcement";
export const ANNOUNCEMENTS = "announcements";

export const NOTICE_PAGE = (
  schoolId: string | number,
  noticesPageSelectedTab: string
) => {
  return `${NOTICES}/${SCHOOLS}/${schoolId}/${noticesPageSelectedTab}`;
};

export const PARENT_NOTICE_PAGE = (
  childID: string | number,
  noticesPageSelectedTab: string
) => {
  return `${NOTICES}/${CHILDREN}/${childID}/${noticesPageSelectedTab}`;
};

// Prediscovery
export const PRE_DISCOVERY_QUESTIONNAIRES = "pre-discovery-questionnaires";
export const INTEREST = "interest";
export const QUESTION = "question";
export const EVALUATION_QUESTIONNAIRES = "evaluation-questionnaires";

//Ticket
export const CREATE_TICKET_PAGE = `${TICKETING_SYSTEM}/create`;

export const SCHOOL_DETAIL = (schoolSlug: string) => {
  return `${SCHOOL}/${schoolSlug}`;
};
export const CREATE_SCHOOL = "school/create";
export const EDIT_SCHOOL = (schoolSlug: string) => {
  return `${SCHOOLS}/${schoolSlug}/edit`;
};
export const PROJECT_SUMMARY_PAGE = (projectSlug: string) => {
  return `${PROJECT}/${projectSlug}`;
};

export const ASSESSMENT_PAGE = (assessmentSlug: string) => {
  return `${ASSESSMENT}/${assessmentSlug}`;
};

export const PROJECT_LESSON_PAGE = (
  projectSlug: string,
  lessonSlug: string
) => {
  return `${PROJECT}/${projectSlug}/lesson/${lessonSlug}`;
};
export const CREATE_PROJECT_PAGE = `${PROJECT}/create`;
export const CREATE_LESSON_PAGE = (projectSlug: string) => {
  return `${PROJECT}/${projectSlug}/lesson/create`;
};
// export const CREATE_TEACHER_LESSON_PAGE = (projectSlug: String) => {
//   return `${TEACHER_TRAINING_ACADEMY}/project/${projectSlug}/lesson/create`;
// };
export const EDIT_PROJECT_PAGE = (projectSlug: string) => {
  return `${PROJECT}/${projectSlug}/edit`;
};
export const CREATE_PROJECT_OBJECTIVE_PAGE = (projectSlug: string) => {
  return `${PROJECT}/${projectSlug}/objective/create`;
};
export const EDIT_PROJECT_OBJECTIVE_PAGE = (projectSlug: string) => {
  return `${PROJECT}/${projectSlug}/objective/edit`;
};
export const EDIT_LESSON_PAGE = (projectSlug: string, lessonID: string) => {
  return `${PROJECT}/${projectSlug}/lesson/${lessonID}/edit`;
};
export const CREATE_ACTIVITY_PAGE = (projectSlug: string, lessonID: string) => {
  return `${PROJECT}/${projectSlug}/lesson/${lessonID}/activity/create`;
};
export const EDIT_ACTIVITY_PAGE = (
  projectSlug: string,
  lessonID: string,
  activityID: string
) => {
  return `${PROJECT}/${projectSlug}/lesson/${lessonID}/activity/${activityID}/edit`;
};
export const EDIT_LESSON_PAGE_2 = (projectSlug: string) => {
  return `${PROJECT}/${projectSlug}/lesson1/edit`;
};

// Profile
export const PROFILE_PAGE = (profileId: string) => {
  return `${PROFILE}/${profileId}`;
};
export const PROFILE_CERTIFICATE_PAGE = (
  profileId: string,
  credentialId: string
) => {
  return `${PROFILE}/${profileId}/${CERTIFICATE}/${credentialId}`;
};

export const TTA_PROFILE_PAGE = (profileId: string) => {
  return `${TEACHER_TRAINING_ACADEMY}/${PROFILE}/${profileId}`;
};
export const TTA_PROFILE_CERTIFICATE_PAGE = (
  profileId: string,
  credentialId: string
) => {
  return `${TEACHER_TRAINING_ACADEMY}/${PROFILE}/${profileId}/${CERTIFICATE}/${credentialId}`;
};

// Timetable
export const TIMETABLE_PAGE = (profileId: string) => {
  return `${TIMETABLE}/${profileId}`;
};

// Lesson Activity Pages
export const LESSON_ACTIVITY_PAGE = (projectSlug: string, lessonID: string) => {
  return `${PROJECT}/${projectSlug}/lesson/${lessonID}`;
};

export const ASSESSMENT_PLAN_PAGE = (
  assessmentSlug: string,
  assessmentPlanId: string
) => {
  return `${ASSESSMENT}/${assessmentSlug}/assessment/${assessmentPlanId}`;
};

export const CREATE_CHILD_PAGE = "child/create";
export const ENROLL_CHILD_PAGE = "child/enroll";
export const UPDATE_CHILD_PAGE = (childID: string) => {
  return `${CHILD}/update/${childID}`;
};
export const ACCOUNT_PAGE = `${ACCOUNT}`;
export const SETTINGS_PAGE = `${SETTINGS}`;
export const EMAIL_PREFERENCES_PAGE = `${EMAIL_PREFERENCES}`;
export const SCHOOL_ACCOUNT_PAGE = (schoolSlug: string) => {
  return `${SCHOOL}/${schoolSlug}/account`;
};

//Site Admin
export const CREATE_SITE_ADMIN_PAGE = `site-admin/create`;

//School Admin
export const CREATE_SCHOOL_ADMIN_PAGE = `school-admin/create`;

//Content Editor
export const CREATE_CONTENT_EDITOR_PAGE = "content-editor/create";

//Parent
export const CREATE_PARENT_PAGE = `${PARENT}/create`;

// Group
export const CREATE_GROUP_PAGE = `${CLASS}/create`;
export const EDIT_GROUP_PAGE = (groupSlug: string) => {
  return `${CLASS}/${groupSlug}/edit`;
};
export const GROUP_PAGE = (groupSlug: string) => {
  return `${CLASS}/${groupSlug}`;
};
export const CHILDREN_PORTFOLIO_PAGE = (groupSlug: string, childID: string) => {
  return `${CHILDREN_PORTFOLIO}/${groupSlug}/${childID}`;
};

export const CHILDREN_PORTFOLIO_PAGE_WITH_TAB = (
  groupSlug: string,
  childID: string,
  tab: string
) => {
  return `${CHILDREN_PORTFOLIO}/${groupSlug}/${childID}/${tab}`;
};

//Prediscovery Questionnaires
export const PRE_DISCOVERY_QUESTIONNAIRE = (questionnaireSlug: string) => {
  return `${PRE_DISCOVERY_QUESTIONNAIRES}/${questionnaireSlug}`;
};

export const CREATE_INTEREST = `interest/create`;
export const CREATE_PRE_DQ_QUESTION = `pre-dq-question/create`;
export const CREATE_PRE_DQ_ANSWER = `pre-dq-answer/create`;

//Evaluation Questionnaires
export const EVALUATION_QUESTIONNAIRE = (questionnaireSlug: string) => {
  return `${EVALUATION_QUESTIONNAIRES}/${questionnaireSlug}`;
};

export const EVALUATION_QUESTIONNAIRE_CREATE = `${EVALUATION_QUESTIONNAIRES}/create`;

export const EVALUATION_QUESTIONNAIRE_EDIT = (questionnaireSlug: string) => {
  return `${EVALUATION_QUESTIONNAIRES}/${questionnaireSlug}/edit`;
};

export const EVALUATION_QUESTIONNAIRE_QUESTION_CREATE = (
  questionnaireSlug: string
) => {
  return `${EVALUATION_QUESTIONNAIRES}/${questionnaireSlug}/create`;
};

export const EVALUATION_QUESTIONNAIRE_QUESTION_EDIT = (
  questionnaireSlug: string,
  questionSlug: string
) => {
  return `${EVALUATION_QUESTIONNAIRES}/${questionnaireSlug}/${questionSlug}/edit`;
};

export const EVALUATION_QUESTIONNAIRE_ANSWER_CREATE = (
  questionnaireSlug: string,
  questionSlug: string
) => {
  return `${EVALUATION_QUESTIONNAIRES}/${questionnaireSlug}/${questionSlug}/create`;
};

export const EVALUATION_QUESTIONNAIRE_ANSWER_EDIT = (
  questionnaireSlug: string,
  questionSlug: string,
  answerSlug: string
) => {
  return `${EVALUATION_QUESTIONNAIRES}/${questionnaireSlug}/${questionSlug}/${answerSlug}/edit`;
};

// Admin Page
export const ADMIN_PAGE = "admin-page";

// Access

export const PROJECT_SCHOOL_MANAGE_PAGE = `${MARKETPLACE}/manage`;

export const PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG = (schoolSlug: string) => {
  return `${PROJECT_SCHOOL_MANAGE_PAGE}/${schoolSlug}`;
};
export const PROJECT_SCHOOL_MANAGE_PAGE_WITH_PAGE_MODE_SLUG = (
  schoolSlug: string,
  pageModeSlug: string
) => {
  return `${PROJECT_SCHOOL_MANAGE_PAGE}/${schoolSlug}/${pageModeSlug}`;
};

export const PROJECT_MANAGE_PAGE = `${MARKETPLACE}/project-manage-page`;

//Payments
export const PAYMENT = "payment";
export const PAYMENT_HISTORY = "payment-history";
export const STATUS = "status";
export const PAYMENT_STATUS = "payment/status";

//Learning Moment
export const LEARNING_MOMENT_PHOTO = "learning-moment-photo";
export const LEARNING_MOMENT_QUESTION = "learning-moment-question";

//Developmental Goal
export const EDIT_DEVELOPMENTAL_GOAL = `${DEVELOPMENTAL_GOAL}/edit`;

//Prohibited Words
export const MANAGE_PROHIBITED_WORD = `${PROHIBITED_WORD}/manage`;

//Teacher Training Academy TTA
export const CREATE_TEACHER_PAGE = `${TEACHER_TRAINING_ACADEMY}/create`;
export const CREATE_TEACHER_MODULE_PAGE = `${TEACHER_TRAINING_ACADEMY}/module/create`;

export const TEACHER_MODULE_PAGE = (moduleSlug: string) => {
  return `${TEACHER_TRAINING_ACADEMY}/${moduleSlug}`;
};
export const EDIT_TEACHER_MODULE_PAGE = (moduleSlug: string) => {
  return `${TEACHER_TRAINING_ACADEMY}/${moduleSlug}/edit`;
};
export const TEACHER_MODULE_DISCUSSION_PAGE = (moduleSlug: string) => {
  return `${TEACHER_TRAINING_ACADEMY}/${moduleSlug}/discussion/`; //Change to module later
};

export const TEACHER_MODULE_MANAGE_PAGE_WITH_MODE = (pageModeSlug: string) => {
  return `${TEACHER_TRAINING_ACADEMY}/manage/${pageModeSlug}`;
};
export const TEACHER_MODULE_MANAGE_PAGE = `${TEACHER_TRAINING_ACADEMY}/manage`;
export const TEACHER_CERTIFICATE_MANAGE_PAGE = `${TEACHER_TRAINING_ACADEMY}/${CERTIFICATE}/manage`;
export const TEACHER_CERTIFICATE_CREATE_PAGE = `${TEACHER_TRAINING_ACADEMY}/${CERTIFICATE}/create`;
export const TEACHER_CERTIFICATE_EDIT_PAGE = (certificateSlug: string) => {
  return `${TEACHER_TRAINING_ACADEMY}/${CERTIFICATE}/${certificateSlug}/edit`;
};
export const TEACHER_LESSON_PAGE = (moduleSlug: string, lessonSlug: string) => {
  return `${TEACHER_TRAINING_ACADEMY}/${moduleSlug}/${lessonSlug}`; //Change to module later
};

export const TEACHER_LESSON_DISCUSSION_PAGE = (
  moduleSlug: string,
  lessonSlug: string
) => {
  return `${TEACHER_TRAINING_ACADEMY}/${moduleSlug}/discussion/${lessonSlug}`; //Change to module later
};

export const TEACHER_LESSON_ACTIVITY_PAGE = (
  moduleSlug: string,
  lessonSlug: string,
  activitySlug: string
) => {
  return `${TEACHER_TRAINING_ACADEMY}/${moduleSlug}/${lessonSlug}/${activitySlug}`; //Change to module later
};

export const MY_PROGRESS = `${TEACHER_TRAINING_ACADEMY}/my-progress`;
export const OUR_STORY = `${TEACHER_TRAINING_ACADEMY}/our-story`;

export const COMMUNITY = `${TEACHER_TRAINING_ACADEMY}/community`;
export const COMMUNITY_KANBAN_BOARD_PAGE = (kanbanBoardSlug: string) => {
  return `${COMMUNITY}/${kanbanBoardSlug}`;
};

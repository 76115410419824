import { Fragment } from "react";
import { Box, SxProps, Typography } from "@mui/material";

import useStore from "store/store";
import { translation } from "constants/translation";
import AddIcon from "@mui/icons-material/Add";
import { API_Fundamental } from "types/project";
import { H3_TABS } from "../PortfolioPage/portfolioPageConstants";
import { TagType } from "./EditDevelopmentalGoalPage";
import { CapitalizeFirstChar } from "helper/helper";

const wrapperStyles: SxProps = {
  borderRadius: "8px",
  cursor: "pointer",
  "&:not(.active):hover": {
    backgroundColor: "rgba(0,0,0,0.04)",
  },
  "&.active": {
    backgroundColor: "rgba(209, 228, 227, 0.37)",
  },
};

const titleStyles: SxProps = {
  fontSize: "1.125rem",
  lineHeight: 1.25,
  p: "6px 16px",
};

const DevelopmentalGoalTreeView = ({
  handleOpen,
  handleClose,
}: {
  handleOpen: (
    selectedDevelopmentalGoalId: number,
    mainDevelopmentalGoalId?: number
  ) => void;
  handleClose: () => void;
}) => {
  const { setCurrDevelopmentalGoalTag, developmentalGoals } = useStore(
    (state) => state
  );

  return (
    <>
      {H3_TABS.map((tab, index) => {
        let tempDevelopmentalGoals = developmentalGoals.filter(
          (developmentalGoal) => developmentalGoal.tag === tab.toLowerCase()
        );
        tempDevelopmentalGoals = tempDevelopmentalGoals.sort(
          (a, b) => a.priority - b.priority
        );

        return (
          <Fragment key={`H3-${tab}`}>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              {tab}
            </Typography>
            {tempDevelopmentalGoals.map(
              (developmentalGoal, developmentalGoalIndex) => (
                <Fragment
                  key={`${developmentalGoal.id}-${developmentalGoalIndex}`}
                >
                  <DevelopmentalGoal
                    developmentalGoal={developmentalGoal}
                    handleOpen={handleOpen}
                    index={index}
                  />
                </Fragment>
              )
            )}

            <AddButton
              title={
                translation.formatString(translation.addDevelopmentalGoal, {
                  tab: CapitalizeFirstChar(tab),
                }) as string
              }
              onClick={() => {
                setCurrDevelopmentalGoalTag(tab as TagType);
                handleOpen(index - 3); //always negative
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
};

const DevelopmentalGoal = ({
  developmentalGoal,

  handleOpen,
  index,
}: {
  developmentalGoal: API_Fundamental;
  handleOpen: (
    selectedDevelopmentalGoalId: number,
    mainDevelopmentalGoalId?: number
  ) => void;
  index: number;
}) => {
  const { currDevelopmentalGoal, setCurrDevelopmentalGoalTag } = useStore(
    (state) => state
  );

  const subFundamentals = developmentalGoal.sub_fundamentals?.sort(
    (a: any, b: any) => a.priority - b.priority
  );
  return (
    <>
      <Box
        className={
          currDevelopmentalGoal.id === developmentalGoal.id ? "active" : ""
        }
        sx={wrapperStyles}
        onClick={() => handleOpen(developmentalGoal.id)}
      >
        <Typography
          sx={{
            ...titleStyles,
            fontSize: "1.25rem",
            fontWeight: 400,
          }}
        >
          {developmentalGoal.name}
        </Typography>
      </Box>
      <Box sx={{ ml: 2 }}>
        {subFundamentals &&
          subFundamentals.length > 0 &&
          subFundamentals.map(
            (subDevelopmentalGoal, subDevelopmentalGoalIndex) => (
              <Fragment
                key={`${subDevelopmentalGoal.id}-${subDevelopmentalGoalIndex}`}
              >
                <SubDevelopmentalGoal
                  developmentalGoalId={developmentalGoal.id}
                  subDevelopmentalGoal={subDevelopmentalGoal}
                  handleOpen={handleOpen}
                  index={index}
                />
              </Fragment>
            )
          )}
        <AddButton
          title={
            translation.formatString(translation.addSubDevelopmentalGoal, {
              tab: CapitalizeFirstChar(developmentalGoal.name),
            }) as string
          }
          onClick={() => {
            setCurrDevelopmentalGoalTag(developmentalGoal.tag as TagType);
            handleOpen(index - 3, developmentalGoal.id);
          }}
        />
      </Box>
    </>
  );
};

const SubDevelopmentalGoal = ({
  developmentalGoalId,
  subDevelopmentalGoal,
  handleOpen,
  index,
}: {
  developmentalGoalId: number;
  subDevelopmentalGoal: API_Fundamental;
  handleOpen: (
    selectedDevelopmentalGoalId: number,
    mainDevelopmentalGoalId?: number
  ) => void;
  index: number;
}) => {
  const {
    currDevelopmentalGoal,
    setCurrDevelopmentalGoalTag,
    developmentalGoals,
  } = useStore((state) => ({
    currDevelopmentalGoal: state.currDevelopmentalGoal,
    setCurrDevelopmentalGoalTag: state.setCurrDevelopmentalGoalTag,
    developmentalGoals: state.developmentalGoals,
  }));

  return (
    <>
      <Box
        className={
          currDevelopmentalGoal.id === subDevelopmentalGoal.id ? "active" : ""
        }
        sx={{ ...wrapperStyles }}
        onClick={() => handleOpen(subDevelopmentalGoal.id, developmentalGoalId)}
      >
        <Typography
          sx={{
            ...titleStyles,
            fontSize: "1.1rem",
            fontWeight: 400,
          }}
        >
          {subDevelopmentalGoal.name}
        </Typography>
      </Box>
    </>
  );
};

const AddButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => (
  <Typography
    sx={{
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      p: "8px 16px",
      borderRadius: "8px",
      border: "1px dashed #444",
      transition: "all 100ms ease-in-out",
      fontSize: "0.875rem",
      ml: 1.5,
      mt: 0.5,
      lineHeight: 1,
      "&:hover": {
        borderColor: "primary.main",
        backgroundColor: "rgba(209, 228, 227, 0.37)",
        transition: "all 100ms ease-in-out",
      },
    }}
    onClick={onClick}
  >
    <AddIcon sx={{ ml: -0.5, mr: 0.5, fontSize: "0.875rem" }} />
    {title}
  </Typography>
);

export default DevelopmentalGoalTreeView;

import React, { Fragment, useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { translation } from "constants/translation";
import { API_Teacher_Text_Activity_Component } from "types/teacher";
import useDialog from "hooks/useDialog";
import { deleteTextActivityComponent } from "api/teacher-api";
import useSnack from "hooks/useSnack";

import WarningDialog from "components/Dialog/WarningDialog";

import RichCKEditor from "components/RichCKEditor/RichCKEditor";

export const TextActivityComponentFieldForDnd = ({
  item,
  textActivityComponents,
  setTextActivityComponents,
}: {
  item: API_Teacher_Text_Activity_Component;
  textActivityComponents: API_Teacher_Text_Activity_Component[];
  setTextActivityComponents: React.Dispatch<
    React.SetStateAction<API_Teacher_Text_Activity_Component[]>
  >;
  onChange?: (input: string) => void;
}) => {
  const openSnack = useSnack();
  const [textActivityComponentName, setTextActivityComponentName] =
    useState<string>(item.name || "");
  const [
    textActivityComponentDescription,
    setTextActivityComponentDescription,
  ] = useState<string>(item.description || "");

  useEffect(() => {
    const inputNameTimeout = setTimeout(async () => {
      // console.log("Updating name now");
      const currTextActivityComponentIndex = textActivityComponents.findIndex(
        (textActivityComponent) => textActivityComponent.id === item.id
      );
      const updatedTextActivityComponents: API_Teacher_Text_Activity_Component[] =
        JSON.parse(JSON.stringify(textActivityComponents));
      updatedTextActivityComponents[currTextActivityComponentIndex].name =
        textActivityComponentName;
      updatedTextActivityComponents[
        currTextActivityComponentIndex
      ].description = textActivityComponentDescription;
      setTextActivityComponents(updatedTextActivityComponents);
    }, 250);
    return () => clearTimeout(inputNameTimeout);
  }, [textActivityComponentName, textActivityComponentDescription]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteTextActivityComponent(Number(item.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setTextActivityComponents(
        res.text_activity_components ? res.text_activity_components : []
      );
      openSnack(
        translation.textActivityComponentDeleted ||
          "Text Activity Component Deleted ",
        true
      );
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${item.name}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.description]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Text Activity Component?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 4, width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography>
            {`${translation.formatString(
              translation.textActivityComponentNo ||
                "Text Activity Component {number}",
              {
                number: item.weight,
              }
            )}`}
          </Typography>

          <TextField
            fullWidth
            label={translation.textActivityComponentTitle}
            value={textActivityComponentName}
            onChange={(event) => {
              setTextActivityComponentName(event.target.value);
            }}
            required
          />

          {/* <TextField
            fullWidth
            label={translation.textActivityComponent}
            value={textActivityComponentDescription}
            onChange={(event) => {
              setTextActivityComponentDescription(event.target.value);
            }}
            required
          /> */}
          <Typography sx={{ mb: "8px" }}>
            {translation.textActivityComponent}
          </Typography>
          <RichCKEditor
            data={textActivityComponentDescription}
            onChange={(event: any, editor: any) => {
              const data: string = editor.getData();
              setTextActivityComponentDescription(data);
            }}
          />
        </Box>
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (typeof item.id === "string" && item.id.includes("unassigned")) {
              const filteredTextActivityComponents =
                textActivityComponents.filter(
                  (textActivityComponent) =>
                    textActivityComponent.id !== item.id
                );
              const finalTextActivityComponents: API_Teacher_Text_Activity_Component[] =
                JSON.parse(JSON.stringify(filteredTextActivityComponents));
              filteredTextActivityComponents.forEach(
                (textActivityComponents, index) => {
                  finalTextActivityComponents[index].weight = index + 1;
                }
              );
              setTextActivityComponents(finalTextActivityComponents);
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};

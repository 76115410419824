// import { Box } from "@mui/material";
// import React from "react";
// import TeacherArchiveByClass from "../TeacherArchiveByClass";
// import { useSearchParams } from "react-router-dom";
// import TeacherArchiveByStudent from "../TeacherArchiveByStudent";
// import { TeacherArchiveContainerBreadCrumbs } from "../TeacherArchiveCommonComponents";
// import TeacherArchiveCompletedWorkScreenByClass from "./TeacherArchiveCompletedWorkScreenByClass";
// import useStore from "../../../../store/store";

const CompletedWorkByClass = () => {
  return null;
};
// const CompletedWorkByClass = () => {
//   const [searchParams] = useSearchParams();
//   const classSlug = searchParams.get("classSlug");
//   const childId = searchParams.get("childId");

//   const { groupList } = useStore((state) => ({
//     groupList: state.groupList,
//   }));

//   return groupList.length > 0 ? (
//     <Box>
//       {classSlug === null ? (
//         <TeacherArchiveByClass />
//       ) : (
//         <Box sx={{ display: "flex", flexDirection: "column" }}>
//           <TeacherArchiveContainerBreadCrumbs
//             classSlug={classSlug}
//             childName={
//               groupList
//                 .find((group) => group.slug === classSlug)
//                 ?.children.find((child) => child.id === Number(childId))
//                 ?.preferred_name
//             }
//           />
//           {childId === null ? (
//             <TeacherArchiveByStudent classSlug={classSlug} />
//           ) : (
//             <TeacherArchiveCompletedWorkScreenByClass
//               classId={groupList.find((group) => group.slug === classSlug)!.id}
//               childId={Number(childId)}
//             />
//           )}
//         </Box>
//       )}
//     </Box>
//   ) : null;
// };

export default CompletedWorkByClass;

import { Box, Chip, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { editSchool } from "api/school-api";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import { API_School } from "types/school";
import { convertToUpperCase } from "containers/PaymentPage/constants/helper";

const SubscriptionControlBar = ({
  schools,
  setSchools,
  currSchool,
  isBypassPayment,
  setIsBypassPayment,
}: {
  schools: API_School[];
  setSchools: (schools: API_School[]) => void;
  currSchool: API_School;
  isBypassPayment: boolean;
  setIsBypassPayment: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const openSnack = useSnack();

  const handleToggle = () => {
    const res = editSchool(
      currSchool.id.toString(),
      { is_bypass_payment: !isBypassPayment },
      localStorage.accessToken
    );

    if (typeof res !== "string") {
      setIsBypassPayment(!isBypassPayment);
      const tempSchool: API_School[] = JSON.parse(JSON.stringify(schools));
      const schoolIndex = tempSchool.findIndex(
        (school) => school.id === currSchool.id
      );
      tempSchool[schoolIndex].is_bypass_payment = !isBypassPayment;
      setSchools(tempSchool);

      openSnack(
        `${currSchool.name} now can${
          !isBypassPayment ? "" : "not"
        } view the projects without subscription.`,
        true
      );
    } else {
      openSnack(res, false);
    }
  };
  return (
    <>
      {schools.length > 0 ? (
        <Box
          sx={{
            width: "inherit",
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={1}>
            <Chip
              size="small"
              label={`${
                convertToUpperCase(currSchool.subscription_status as string) ===
                "None"
                  ? "No Sub"
                  : (currSchool.subscription_status as string) === "trialing"
                  ? translation.sevenDayTrial
                  : (currSchool.subscription_status as string) === "active"
                  ? translation.subscribed
                  : convertToUpperCase(currSchool.subscription_status as string)
              }`}
              sx={{
                height: 24,
                color: "background.paper",
                bgcolor:
                  (currSchool.subscription_status as string) === "active" ||
                  (currSchool.subscription_status as string) === "trialing"
                    ? `primary.main`
                    : (currSchool.subscription_status as string) ===
                        "canceled" ||
                      (currSchool.subscription_status as string) === "None"
                    ? `grey`
                    : "grey",
              }}
            />
            {/* {isBypassPayment ||
            (currSchool.subscription_status as string) ===
              "active" ||
            (currSchool.subscription_status as string) ===
              "trialing" ? (
              <Chip
                size="small"
                label={translation.canViewProjects}
                sx={{
                  height: 24,
                }}
                color={"success"}
              />
            ) : null} */}
          </Stack>

          <Switch
            sx={{ ...switchStyle(20), height: 24, py: "6px", px: 1.5 }}
            checked={isBypassPayment}
            onChange={handleToggle}
          />
          <Typography sx={{ fontSize: "0.875rem" }}>
            {translation.bypassPayment}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export const switchStyle = (heightOrWidth: number) => ({
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 0,
    transform: "translateX(6px) translateY(2px)",
    transition: "transform 0.2s ease-in-out",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(32px) translateY(2px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"/></svg>')`,
        backgroundColor: "primary.main",
        borderRadius: heightOrWidth / 2,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "primary.main",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "grey",
    width: heightOrWidth,
    height: heightOrWidth,
    transition: "transform 0.2s ease-in-out",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "grey",
    borderRadius: heightOrWidth / 2,
    transition: "transform 0.2s ease-in-out",
  },
});

export default SubscriptionControlBar;

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import CloseIcon from "@mui/icons-material/Close";
import ScheduleGroupPageSimple from "./ScheduleGroupPageSimple";

type Props = {
  open: boolean;
  handleClose: () => void;
  projectId: number;
  projectTitle: string;
  assessment: boolean;
  groupId: number;
  scheduleId?: number;
  scheduleDatetimeId?: number;
  startAt?: string;
  endAt?: string;
  handleOpenDialog?: () => void;
  projectSlug?: string;
  isUpdate?: boolean;
}

const ScheduleGroupPageModal = ({
  open,
  handleClose,
  projectId,
  projectTitle,
  assessment,
  groupId,
  scheduleId,
  scheduleDatetimeId,
  startAt,
  endAt,
  handleOpenDialog,
  projectSlug,
  isUpdate = false,
}: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 2,
            paddingY: 4,
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          <ScheduleGroupPageSimple
            assessment={assessment}
            groupId={groupId}
            projectId={projectId}
            projectTitle={projectTitle}
            scheduleId={scheduleId}
            scheduleDatetimeId={scheduleDatetimeId}
            handleClose={handleClose}
            preDefinedStartAt={startAt}
            preDefinedEndAt={endAt}
            handleOpenDialog={handleOpenDialog}
            projectSlug={projectSlug}
            isUpdate={isUpdate}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ScheduleGroupPageModal;

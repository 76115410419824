import React from "react";
import { Paper, InputBase, IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useStore from "store/store";
import { translation } from "constants/translation";
import { ViewMode } from "types/teacher";
import { Query_Data } from "types/project";
import { INSIGHTS, VISUALIZATION } from "constants/staticConstant";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import CustomButton from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import {
  CURLANG,
  TEACHER_CERTIFICATE_CREATE_PAGE,
} from "constants/url";

const SecondRow = ({
  pageMode,
  queryData,
  setQueryData,
}: {
  pageMode: ViewMode;
  queryData: Query_Data;
  setQueryData: (value: React.SetStateAction<Query_Data>) => void;
}) => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, minWidth: 336 }}>
        <SearchBar queryData={queryData} setQueryData={setQueryData} />
        {(pageMode === (INSIGHTS as ViewMode) ||
          pageMode === (VISUALIZATION as ViewMode)) && (
          <>
            <StartAtDateField
              queryData={queryData}
              setQueryData={setQueryData}
            />
            <EndAtDateField queryData={queryData} setQueryData={setQueryData} />
          </>
        )}
      </Box>
      <CustomButton
        buttonText={translation.addCertificate || "Add Certificate"}
        arrow={false}
        onClick={() => {
          navigate(
            `${CURLANG(currLanguage)}/${TEACHER_CERTIFICATE_CREATE_PAGE}`
          );
        }}
      />
    </Box>
  );
};

export const SearchBar = ({
  queryData,
  setQueryData,
}: {
  queryData: Query_Data;
  setQueryData: (value: React.SetStateAction<Query_Data>) => void;
}) => {
  const handleChange = (query: string) => {
    setQueryData((prev) => ({ ...prev, query: query }));
  };
  return (
    <Paper
      component="form"
      sx={{
        py: 0.5,
        px: 1.5,
        display: "flex",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        borderRadius: "10px",
        border: "1px solid #DFDDDD",
        flex: 1,
      }}
      elevation={0}
    >
      <IconButton sx={{ p: 0, pointerEvents: "none" }} aria-label="search">
        <SearchIcon fontSize="small" />
      </IconButton>

      <InputBase
        sx={{ ml: 1.5, flex: 1 }}
        placeholder={translation.search}
        inputProps={{ "aria-label": "Search" }}
        value={queryData.query === null ? "" : queryData.query}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      />
    </Paper>
  );
};
export const SearchBarForNormalUse = ({
  query,
  setQuery,
}: {
  query: string | null;
  setQuery: (value: React.SetStateAction<string | null>) => void;
}) => {
  const handleChange = (query: string) => setQuery(query);

  return (
    <Paper
      component="form"
      sx={{
        py: 0.5,
        px: 1.5,
        display: "flex",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        borderRadius: "10px",
        border: "1px solid #DFDDDD",
      }}
      elevation={0}
    >
      <IconButton sx={{ p: 0, pointerEvents: "none" }} aria-label="search">
        <SearchIcon fontSize="small" />
      </IconButton>

      <InputBase
        sx={{ ml: 1.5, flex: 1 }}
        placeholder={translation.search}
        inputProps={{ "aria-label": "Search" }}
        value={query === null ? "" : query}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      />
    </Paper>
  );
};

export default SecondRow;

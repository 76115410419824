import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";

import {
  Box,
  Rating,
  TextField,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Avatar,
  IconButton,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ClearIcon from "@mui/icons-material/Clear";

import Logo from "images/logo-2-colour.png";
import Button from "components/Button/Button";
import Image from "components/Image/Image";
import getBase64 from "helper/getBase64";

import {
  API_Review,
  API_Review_Image,
  API_Update_Review,
} from "types/review";
import { translation } from "constants/translation";

import { UserRoleMappingInverse } from "types/auth";
import { Profile } from "components/Review/Components";
import {
  API_Create_Lesson_Discussion_Post,
  API_Edit_Lesson_Discussion_Post,
  API_Lesson_Discussion_Post,
} from "types/teacher";
import {
  createLessonDiscussionPost,
  editLessonDiscussionPost,
} from "api/teacher-api";

const DiscussionPostModal = ({
  open,
  setOpen,
  answer,
  setAnswer,
  isEdit = false,
  postId = -1,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  answer: string;
  setAnswer: React.Dispatch<React.SetStateAction<string>>;
  isEdit?: boolean;
  postId?: number;
}) => {
  const { profile } = useStore((state) => ({
    profile: state.profileDetails,
  }));

  const handleClose = () => {
    setAnswer("");
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(49,47,48,0.4)",
        },
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
            {translation.createPost}
          </Typography>

          <Form answer={answer} setAnswer={setAnswer}>
            <ProfileDetails
              avatar={profile.image}
              name={profile.preferred_name}
              role={UserRoleMappingInverse[profile.role]}
              school={profile.school ? profile.school.name : "Little Lab"}
            />
          </Form>

          <Submit
            answer={answer}
            isEdit={isEdit}
            postId={postId}
            handleClose={handleClose}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

const ProfileDetails = ({
  avatar,
  name,
  role,
  school,
}: {
  avatar: string;
  name: string;
  role: string;
  school: string;
}) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: 1.5,
      mb: 2,
    }}
  >
    <Avatar
      src={avatar}
      alt={name}
      sx={{ width: "40px", height: "40px", mb: 0.25 }}
      imgProps={{
        onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
          event.currentTarget.onerror = null;
          event.currentTarget.src = Logo;
        },
      }}
    />

    <Profile name={name} role={role} school={school} />
  </Box>
);

const Form = ({
  answer,
  setAnswer,
  children,
}: {
  answer: string;
  setAnswer: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}) => {
  const { teacherCurrentLesson } = useStore((state) => ({
    teacherCurrentLesson: state.teacherCurrentLesson,
  }));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {children}

      <TextField
        placeholder={teacherCurrentLesson.discussion_topic}
        value={answer}
        onChange={(event) => {
          setAnswer(event.target.value);
        }}
        fullWidth
        multiline
        rows={6}
      />
    </Box>
  );
};

const Submit = ({
  answer,
  isEdit = false,
  postId,
  handleClose,
}: {
  answer: string;
  isEdit?: boolean;
  postId: number;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    currLanguage,
    teacherCurrentLesson,
    lessonDiscussionPostList,
    setLessonDiscussionPostList,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    teacherCurrentLesson: state.teacherCurrentLesson,
    lessonDiscussionPostList: state.lessonDiscussionPostList,
    setLessonDiscussionPostList: state.setLessonDiscussionPostList,
  }));

  const addPost = async () => {
    setIsLoading(true);

    const postData: API_Create_Lesson_Discussion_Post = {
      name: answer,
      lesson: teacherCurrentLesson.id,
    };

    const res = await createLessonDiscussionPost(currLanguage, postData);

    setIsLoading(false);

    if (typeof res !== "string") {
      setLessonDiscussionPostList([res, ...lessonDiscussionPostList]);
      handleClose();
      openSnack(translation.postCreated || "Post Created Successfully ", true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const editPost = async () => {
    if (postId === -1) return;

    setIsLoading(true);

    const postData: API_Edit_Lesson_Discussion_Post = {
      id: postId,
      lesson: teacherCurrentLesson.id,
      name: answer,
    };

    const res = await editLessonDiscussionPost({
      language: currLanguage,
      editedPostInfo: postData,
    });

    setIsLoading(false);

    if (typeof res !== "string") {
      handleClose();
      const lessonDiscussionPostListCopy: API_Lesson_Discussion_Post[] =
        JSON.parse(JSON.stringify(lessonDiscussionPostList));
      const postIndex = lessonDiscussionPostListCopy.findIndex(
        (post_) => post_.id === postId
      );
      lessonDiscussionPostListCopy[postIndex] = res;
      setLessonDiscussionPostList(lessonDiscussionPostListCopy);
      openSnack(
        translation.successUpdatePost || "post Updated Successfully",
        true
      );
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        mt: 4,
      }}
    >
      <Button
        buttonText={translation.cancel || "Cancel"}
        arrow={false}
        style={{
          fontSize: "14px",
          backgroundColor: "transparent",
          color: "var(--primary-main)",
          border: "1px solid var(--primary-main)",
          padding: "9px 25px",
          marginRight: "16px",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        onClick={handleClose}
      />

      <Button
        arrow={false}
        disabled={answer === "" || isLoading}
        style={{
          fontSize: "14px",
          backgroundColor: "var(--primary-main)",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        buttonText={
          isEdit
            ? translation.update || "Update"
            : translation.submit || "Submit"
        }
        onClick={isEdit ? editPost : addPost}
      />
    </Box>
  );
};

export default DiscussionPostModal;

import React from "react";

import { Box, Checkbox, Typography } from "@mui/material";

import {
  ProjectCategory,
  ProjectCategoryModule,
} from "types/project";
import Image from "components/Image/Image";
import { translation } from "constants/translation";

//age
import AgeGroupImg from "images/age-group/age-group.png";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import {
  BLOSSOMS,
  BUDS,
  SPROUTS,
  ageGroupExternalMap,
} from "constants/project-lesson-activity";
import useStore from "store/store";
import { CALENDAR_BORDER_STYLE } from "containers/CalendarPage/constants/constants";

const AgeSection = () => {
  const ageCategories = [
    {
      name: BLOSSOMS,
      src: BlossomsIcon,
    },
    {
      name: BUDS,
      src: BudsIcon,
    },
    {
      name: SPROUTS,
      src: SproutsIcon,
    },
  ];

  return (
    <>
      <Typography sx={{ mt: "2.3rem", mb: 1 }}>
        {translation.byLevels}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {ageCategories.map((ageCategory) => {
          return (
            <Box key={`age-category-${ageCategory.name}`}>
              <AgeSideTabs
                ageName={ageCategory.name}
                ageRangeDescription={ageGroupExternalMap[ageCategory.name]}
                icon={
                  <Box
                    sx={{
                      width: "24px",
                      height: "24px",
                      overflow: "hidden",
                      mr: "12px",
                    }}
                  >
                    <Image
                      src={ageCategory.src}
                      alt={ageCategory.name}
                      objectFit="contain"
                    />
                  </Box>
                }
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

const AgeSideTabs = ({
  ageName,
  ageRangeDescription,
  icon,
}: {
  ageName: string;
  ageRangeDescription: string;
  icon: React.ReactElement;
}) => {
  const { isFilterSelected, isAgeSelected, ageFilters, setAgeFilters } =
    useStore((state) => ({
      isFilterSelected:
        state.ageFilters.length !== 0 ||
        state.recommendationFilters.length !== 0 ||
        state.moduleFilters.length !== 0,
      isAgeSelected: state.ageFilters.includes(ageName),
      ageFilters: state.ageFilters,
      setAgeFilters: state.setAgeFilters,
    }));

  // console.log("moduleFilters: ", moduleFilters);
  return (
    <Box
      sx={
        isAgeSelected
          ? {
              display: "flex",
              alignItems: "center",
              width: "100%",
              minHeight: "50px",
              border: "1px solid #98C4C5",
              background: "rgba(209, 228, 227, 0.37)",
              borderRadius: "10px",
              cursor: "pointer",
              p: "4px 16px",
            }
          : {
              display: "flex",
              alignItems: "center",
              width: "100%",
              minHeight: "50px",
              border: "1px solid",
              borderColor: "neutral.300",
              borderRadius: "10px",
              cursor: "pointer",
              p: "4px 16px",
            }
      }
      onClick={() => {
        if (isAgeSelected) {
          const temp = [...ageFilters];
          const index = temp.findIndex((elem) => elem === ageName);
          temp.splice(index, 1);
          setAgeFilters(temp);
        } else {
          setAgeFilters([...ageFilters, ageName]);
        }

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
    >
      {React.cloneElement(icon, { fontSize: "small" })}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>{ageName}</Typography>
        {/* <Typography sx={{ fontSize: '8px', lineHeight: '1.25', mt: '1px' }}>
          {ageRangeDescription}
        </Typography> */}
        {isFilterSelected && (
          <Checkbox size={"small"} checked={isAgeSelected} />
        )}
      </Box>
    </Box>
  );
};

export default AgeSection;

import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useStore from "store/store";
import { translation } from "constants/translation";
import Button from "components/Button/Button";

import { API_Lesson_Checkpoint } from "types/project";
import CheckpointSection from "../CheckpointQuestionComponents/CheckpointSection";
import { updateScheduleCheckpointProgress } from "api/profile-api";
import useSnack from "hooks/useSnack";
import { populateLessonLearningMomentCheckpointCount } from "helper/project";

const CheckpointQuestionScreen = ({
  checkpointObject,
}: {
  checkpointObject: API_Lesson_Checkpoint;
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          flex: 1,
          justifyContent: "center",
          overflowX: "auto",
        }}
      >
        <CheckpointSection checkpointObject={checkpointObject} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 1,
          width: "100%",
        }}
      >
        <CheckpointQuestionDoneButton checkpointObject={checkpointObject} />
      </Box>
    </>
  );
};

const CheckpointQuestionDoneButton = ({
  checkpointObject,
}: {
  checkpointObject: API_Lesson_Checkpoint;
}) => {
  const { lessonID } = useParams();
  const openSnack = useSnack();
  const navigate = useNavigate();

  const {
    currLanguage,
    projectSlug,
    currentLessons,
    currScheduleObj,
    setCurrScheduleObj,
    setLessonLearningMomentCheckpointCount,
    setRefreshGroupLessonInfoList,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    projectSlug: state.currentProject.slug,
    currentLessons: state.currentLessons,
    currScheduleObj: state.currScheduleObj,
    setCurrScheduleObj: state.setCurrScheduleObj,
    setLessonLearningMomentCheckpointCount:
      state.setLessonLearningMomentCheckpointCount,
    setRefreshGroupLessonInfoList: state.setRefreshGroupLessonInfoList,
  }));

  const currentLessonIndex = currentLessons.lessons.findIndex(
    (lesson) => lesson.id.toString() === lessonID
  );

  const handleDone = async () => {
    if (!currScheduleObj.checkpoints.includes(checkpointObject.id!)) {
      //update if it doesn't updated
      const res = await updateScheduleCheckpointProgress(
        currLanguage,
        localStorage.getItem("access"),
        currScheduleObj.id,
        checkpointObject.id!
      );

      if (typeof res !== "string") {
        setRefreshGroupLessonInfoList(true);
        setCurrScheduleObj(res);
        populateLessonLearningMomentCheckpointCount(
          currLanguage,
          localStorage.getItem("access")!,
          currentLessons.id,
          res.group,
          setLessonLearningMomentCheckpointCount
        );
        openSnack(`Checkpoint is ${translation.done}`, true);
      } else {
        openSnack(translation.pleaseSchedule, false);
        return;
      }
    }
    // const nextLesson = currentLessons.lessons[currentLessonIndex + 1];
    // const nextFirstActivitySlug =
    //   nextLesson && nextLesson.activities.length > 0
    //     ? `activity/${nextLesson.activities[0].id}`
    //     : "";

    // if (currentLessonIndex === currentLessons.lessons.length - 1) {
    //   navigate(
    //     `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projectSlug)}/end`
    //   );
    // } else {
    //   navigate(
    //     `${CURLANG(currLanguage)}/${LESSON_ACTIVITY_PAGE(
    //       projectSlug,
    //       nextLesson.id.toString()
    //     )}/${nextFirstActivitySlug}`
    //   );
    // }
  };
  return (
    <Button
      style={{ width: "30%" }}
      buttonText={translation.done || "Done"}
      arrow={false}
      onClick={handleDone}
    />
  );
};

export default CheckpointQuestionScreen;

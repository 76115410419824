import { Dispatch, SetStateAction, ReactNode } from "react";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { API_Teacher_Module } from "types/teacher";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { editTeacherModule } from "api/teacher-api";

const DndGrid = ({
  moduleList,
  setModuleList,
  children,
}: {
  moduleList: API_Teacher_Module[];
  setModuleList: Dispatch<SetStateAction<API_Teacher_Module[]>>;
  children: ReactNode;
}) => {
  const openSnack = useSnack();
  const { currLanguage, currentLessons, setCurrentLessons } = useStore(
    (state) => ({
      currLanguage: state.currLanguage,
      currentLessons: state.currentLessons,
      setCurrentLessons: state.setCurrentLessons,
    })
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = moduleList.findIndex((l) => l.id === active.id);
      const newIndex = moduleList.findIndex((l) => l.id === over.id);

      const orderedModules = arrayMove(moduleList, oldIndex, newIndex);

      const orderDifference: {
        id: number;
        weight: number;
      }[] = [];

      orderedModules.forEach((module, index) => {
        if (module.id !== moduleList[index].id) {
          orderDifference.push({
            id: module.id,
            weight: index + 1,
          });
        }
      });

      if (orderDifference.length !== 0) {
        orderDifference.forEach(async (module) => {
          console.log("id: ", module.id, "WEIGHT: ", module.weight);
          await editTeacherModule(currLanguage, {
            id: module.id,
            weight: module.weight,
          });
        });
        console.log("orderedModules: ", orderedModules);
        setModuleList(orderedModules);
        openSnack("Reorder modules successfully!", true);
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={moduleList.map((module) => module.id)}
        strategy={rectSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default DndGrid;

import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import { usePopulateScheduleObject } from "hooks/projects";
import HelpTabs from "./HelpTabs";

const HelpPageMiniDrawer = () => {
  const navigate = useNavigate();
  const { groupSlug } = useParams();

  usePopulateScheduleObject();
  const [open, setOpen] = useState<boolean>(true);

  const handleDrawer = () => setOpen((prev) => !prev);

  const pages = [
    "about-faqs",
    "onboarding-videos",
    "teacher-toolbox",
    "teacher-training-academy",
    "parent-portal",
    "feedback",
  ];

  useEffect(() => {
    navigate(pages[0]);
  }, [groupSlug]);
  return (
    <Box
      sx={{
        maxWidth: "300px",
        width: "auto",
        flex: "1 0 auto",
        p: 2,
        pt: "21px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
        alignSelf: "flex-start",
        position: "sticky",
        top: "64px",
        height: "calc(100vh - 64px)",
        borderRight: "1px solid #C9C9C9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          mb: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            width: "100%",
          }}
          onClick={handleDrawer}
        ></Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            width: "100%",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
              Trehaus Method
            </Typography>
          </Box>
          <HelpTabs />
        </Box>
      </Box>
    </Box>
  );
};

export default HelpPageMiniDrawer;

import moment from "moment";
import {
  DATE_FORMAT,
  TIME_FORMAT,
  DISPLAY_TIME_FORMAT,
  MIN_TIME,
  MAX_TIME,
} from "../constants/timetable";

export const getCurrentWeek = () => {
  const today = moment();
  const startOfWeek = today.clone().startOf("week");
  const endOfWeek = today.clone().endOf("week");

  const days = [];
  let day = startOfWeek;

  while (day <= endOfWeek) {
    days.push(day.format(DATE_FORMAT));
    day = day.clone().add(1, "d");
  }

  return days;
};

export const getTimeSlots = () => {
  const timeSlots = [];

  let startTime = moment(MIN_TIME, TIME_FORMAT);
  const endTime = moment(MAX_TIME, TIME_FORMAT);

  while (startTime <= endTime) {
    timeSlots.push(startTime.format(TIME_FORMAT));
    startTime = startTime.clone().add(15, "m");
  }

  if (moment().endOf("day").format(TIME_FORMAT) === MAX_TIME) {
    timeSlots.push(MAX_TIME);
  }

  return timeSlots;
};

export const convert24HrsTo12Hrs = (time: string) =>
  moment(time, TIME_FORMAT).format(DISPLAY_TIME_FORMAT);

export const getUniqueValues = (values: string[]) =>
  values
    .filter(
      (value, index, array) =>
        array
          .map((v) => v.replace(/\s/g, "").toLowerCase())
          .indexOf(value.replace(/\s/g, "").toLowerCase()) === index
    )
    .sort((a, b) => a.localeCompare(b));

import React from "react";

import { Box, Typography } from "@mui/material";

import Background from "images/end-of-project-report/one-img-bg.png";
import Wave from "images/end-of-project-report/wave.png";
import Image from "components/Image/Image";

import { Overlay } from "./Preview";
import { editableStyle, styles, LessonType } from "./constant";

const LT1Image = ({
  scale,
  lesson,
  handleChange,
  setOpenModal,
  setSelectedImageIndex,
  children,
}: {
  scale: number;
  lesson: LessonType;
  handleChange?: (key: string, value: string | null) => void;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedImageIndex?: React.Dispatch<React.SetStateAction<number>>;
  children?: React.ReactNode;
}) => {
  const isTemplatePreview =
    !handleChange && !setOpenModal && !setSelectedImageIndex && !children;

  return (
    <Box sx={styles.body}>
      {children}

      <Image
        src={Wave}
        style={{
          transform: "scaleX(-1)",
        }}
        alt=""
      />

      <Box
        sx={{
          ...styles.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          m: `${40 * scale}px`,
          maxHeight: `${810 * scale}px`,
        }}
      >
        <Typography
          sx={{
            ...(isTemplatePreview ? {} : editableStyle),
            width: "100%",
            textAlign: "center",
            fontSize: 40 * scale,
            fontWeight: 500,
            lineHeight: 1.25,
          }}
          contentEditable
          onBlur={(e) =>
            handleChange && handleChange("title", e.currentTarget.textContent)
          }
          suppressContentEditableWarning={true}
        >
          {lesson.title}
        </Typography>

        <Box
          sx={{
            ...(isTemplatePreview ? {} : editableStyle),
            flex: 1,
            width: `${800 * scale}px`,
            marginTop: `${40 * scale}px`,
            marginBottom: `${40 * scale}px`,
            zIndex: 2,
            minHeight: 0,
            cursor: "pointer",
            position: "relative",
          }}
          onClick={() => {
            setSelectedImageIndex && setSelectedImageIndex(0);
            setOpenModal && setOpenModal(true);
          }}
        >
          <Overlay />
          <Image src={lesson.images[0].file} alt="" objectFit="cover" />
        </Box>

        <Typography
          sx={{
            ...(isTemplatePreview ? {} : editableStyle),
            width: "100%",
            fontSize: 24 * scale,
            textAlign: "center",
          }}
          contentEditable
          onBlur={(e) =>
            handleChange && handleChange("caption", e.currentTarget.textContent)
          }
          suppressContentEditableWarning={true}
        >
          {lesson.caption}
        </Typography>
      </Box>

      <Image
        src={Background}
        style={{
          ...styles.staticImage,
          zIndex: 3,
        }}
        alt=""
      />
    </Box>
  );
};

export default LT1Image;

import { getScheduleByProject } from "api/profile-api";
import { initialScheduleObj } from "store/schedule-slice";
import { getLessonLearningMomentCheckpointCount } from "api/lesson-activity-api";
import { API_Schedule_Simple } from "types/profile";
import { API_Lesson_Learning_Moment_Checkpoint_Count } from "types/lesson-activity";

export const populateLessonLearningMomentCheckpointCount = async (
  currLanguage: string,
  access: string,
  projectId: number,
  groupId: number,
  setLessonLearningMomentCheckpointCount: (
    lessonLearningMomentCheckpointCount: API_Lesson_Learning_Moment_Checkpoint_Count
  ) => void
) => {
  const lessonLearningMomentCheckpointCountRes =
    await getLessonLearningMomentCheckpointCount(
      currLanguage,
      access,
      projectId,
      groupId
    );

  if (typeof lessonLearningMomentCheckpointCountRes !== "string") {
    setLessonLearningMomentCheckpointCount(
      lessonLearningMomentCheckpointCountRes
    );
  } else {
    console.log(
      "error getting lesson learning moment checkpoint count: ",
      lessonLearningMomentCheckpointCountRes
    );
  }
};

export const populateScheduleObject = async (
  currLanguage: string,
  currGroupId: number,
  currentProjectId: number,
  setCurrScheduleObj: (currScheduleObj: API_Schedule_Simple) => void,
  setLessonLearningMomentCheckpointCount: (
    lessonLearningMomentCheckpointCount: API_Lesson_Learning_Moment_Checkpoint_Count
  ) => void
) => {
  let scheduleObjRes;
  if (currGroupId !== -1) {
    scheduleObjRes = await getScheduleByProject(
      currLanguage,
      localStorage.getItem("access"),
      currentProjectId,
      currGroupId
    );
  } else {
    scheduleObjRes = "Curr Group id is -1";
  }

  if (typeof scheduleObjRes !== "string") {
    setCurrScheduleObj(scheduleObjRes);
  } else {
    setCurrScheduleObj(initialScheduleObj);
    console.log("No Schedule Record,", scheduleObjRes);
  }
  populateLessonLearningMomentCheckpointCount(
    currLanguage,
    localStorage.getItem("access")!,
    currentProjectId,
    currGroupId,
    setLessonLearningMomentCheckpointCount
  );
};
import ReactGA from "react-ga4";

const GOOGLE_ANALYTICS_API_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY;
if (GOOGLE_ANALYTICS_API_KEY) ReactGA.initialize(GOOGLE_ANALYTICS_API_KEY);

interface LogEventParams {
  category: string;
  action: string;
  label: string;
  value?: number;
  nonInteraction?: boolean;
}

const logEvent = ({ category, action, label, value, nonInteraction = false }: LogEventParams) => {
  ReactGA.event({
    category,
    action,
    label: JSON.stringify(label), // Converting object to string if needed
    value,
    nonInteraction,
    transport: "beacon",
  });
};

const analytics = {
  logEvent,
};

export default analytics;

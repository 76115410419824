import * as fflate from "fflate";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import useStore from "store/store";
import { getCompletedWorkList } from "api/completed-works-api";
import { API_Child } from "types/portfolio";
import moment from "moment";
import { translation } from "constants/translation";
import CompletedWorkCard from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkCard";
import { getFileTypeFromExtention } from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkCard";
import AddCompletedWorkButton from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/AddCompletedWorkButton";
import CompletedWorkPreviewModal from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkPreviewModal";
import { DATE_FORMAT } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";

const CompletedWorksScreen = () => {
  const {
    currentProject,
    currGroup,
    currLanguage,
    isRefreshCompletedWork,
    setIsRefreshCompletedWork,
    completedWorkList,
    setCompletedWorkList,
  } = useStore((state) => ({
    currentProject: state.currentProject,
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
    isRefreshCompletedWork: state.isRefreshCompletedWork,
    setIsRefreshCompletedWork: state.setIsRefreshCompletedWork,
    completedWorkList: state.completedWorkList,
    setCompletedWorkList: state.setCompletedWorkList,
  }));

  const populateCompletedWorks = async () => {
    const res = await getCompletedWorkList({
      language: currLanguage,
      groupId: currGroup.id,
      projectId: currentProject.id,
    });

    if (typeof res !== "string") {
      res.map(async (completedWork: any) => {
        console.log(completedWork);
        if (getFileTypeFromExtention(completedWork.file) == "Unknown") {
          try {
            const response = await fetch(completedWork.file);
            const sizeBeforeDecompression = Number(
              response.headers.get("Content-Length")
            );
            console.log(
              `Size before decompression: ${sizeBeforeDecompression} bytes`
            );
            const arrayBuffer = await response.arrayBuffer();
            const decompressedData = fflate.decompressSync(
              new Uint8Array(arrayBuffer)
            );
            const blob = new Blob([decompressedData], {
              type: completedWork.original_type,
            });
            const sizeAfterDecompression = blob.size;
            console.log(
              `Size after decompression: ${sizeAfterDecompression} bytes`
            );
            const imageUrl = URL.createObjectURL(blob);
            completedWork.file = imageUrl;
          } catch (error) {
            console.error("Error fetching and decompressing image:", error);
          }
        }
      });
      setCompletedWorkList(res);
    }

    setIsRefreshCompletedWork(false);
  };

  useEffect(() => {
    isRefreshCompletedWork && populateCompletedWorks();
  }, [isRefreshCompletedWork]);

  useEffect(() => {
    populateCompletedWorks();
  }, [currGroup.id]);

  // Preview
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCompletedWork, setSelectedCompletedWork] =
    useState<API_Child.CompletedWork>();

  const handleOpen = (completedWork: API_Child.CompletedWork) => {
    setOpen(true);
    setSelectedCompletedWork(completedWork);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompletedWork(undefined);
  };

  return (
    <>
      {open && selectedCompletedWork && (
        <CompletedWorkPreviewModal
          open={open}
          completedWork={selectedCompletedWork}
          setCompletedWork={setSelectedCompletedWork}
          handleClose={handleClose}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop: "5vh",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "24px",
            textAlign: "center",
          }}
        >
          {translation.completedWorks}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <AddCompletedWorkButton />
          {completedWorkList.map((completedWork) => {
            return (
              <CompletedWorkCard
                key={`completed-work-${completedWork.id}`}
                completedWork={completedWork}
                fromNow={moment(completedWork.completed_at).format(DATE_FORMAT)}
                onClick={() => {
                  handleOpen(completedWork);
                }}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default CompletedWorksScreen;

import React from "react";
import PhotoBookCoverBack from "images/children-photo-book/photo-book-cover-back.png";
import { Image, View, Page } from "@react-pdf/renderer";
import {
  imageSrc,
  photoBookPageSize,
  styles,
} from "components/ChildrenPortfolioPDF/Templates/constant";

const PhotoBookCoverBackComponent = () => {
  return (
    <Page size={photoBookPageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={imageSrc(PhotoBookCoverBack)} style={styles.background} />;
      </View>
    </Page>
  );
};

export default PhotoBookCoverBackComponent;

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { Box } from "@mui/material";
import { translation } from "constants/translation";
import { getChildImages } from "api/gallery-api";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Container from "./Container";
import { ToggleButton, NoPhotoYet, ImageType } from "./PhotoAlbumComponents";
import YearView from "./YearView";
import MonthView from "./MonthView";
import DayView from "./DayView";

const AllPhotosScreen = ({
  isParentView = false,
}: {
  isParentView?: boolean;
}) => {
  const { childID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const year = searchParams.get("year");
  const month = searchParams.get("month");

  const isYearView = !year && !month;
  const isDayView = year !== null && month !== null;
  const isMonthView = !isYearView && !isDayView;

  const [images, setImages] = useState<ImageType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const latestYear =
    images.length !== 0 ? moment(images[0].created_at).year().toString() : "";
  const latestMonth =
    images.length !== 0
      ? (moment(images[0].created_at).month() + 1).toString()
      : "";

  useEffect(() => {
    if (!childID) return;

    const initializeChildImages = async () => {
      setIsLoading(true);

      const res = await getChildImages(
        Number(childID),
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        const data: ImageType[] = res
          .map((image) => ({
            id: image.id,
            link: image.image,
            caption: image.caption,
            created_at: image.created_at,
          }))
          .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));

        setImages(data);
      }

      setIsLoading(false);
    };

    initializeChildImages();
  }, [childID]);

  useEffect(() => {
    if ((!year && !month) || latestYear === "" || latestMonth === "") return;

    setTimeout(() => {
      let target = null;

      if (
        year !== null &&
        month !== null &&
        (year !== latestYear || month !== latestMonth)
      ) {
        // Day View
        target = document.getElementById(`${year}-${month}`);
      } else if (year !== null && !month && year !== latestYear) {
        // Month View
        target = document.getElementById(year);
      }

      if (target) {
        const headerOffset = 80;
        const elementPosition = target.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 250);
  }, [year, month, latestMonth, latestYear]);

  return (
    <Container isParentView={isParentView}>
      {isLoading ? (
        <LoadingIndicator />
      ) : images.length === 0 ? (
        <NoPhotoYet />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
              mb: 3,
            }}
          >
            <ToggleButton
              label={translation.year}
              isActive={isYearView}
              onClick={() => setSearchParams({})}
            />
            <ToggleButton
              label={translation.month}
              isActive={isMonthView}
              onClick={() =>
                setSearchParams({
                  year: latestYear,
                })
              }
            />
            <ToggleButton
              label={translation.day}
              isActive={isDayView}
              onClick={() =>
                setSearchParams({
                  year: latestYear,
                  month: latestMonth,
                })
              }
            />
          </Box>

          {isYearView ? (
            <YearView
              images={images}
              handleClick={(year: string) =>
                setSearchParams({
                  year,
                })
              }
            />
          ) : isMonthView ? (
            <MonthView
              images={images}
              handleClick={(year: string, month: string) =>
                setSearchParams({
                  year,
                  month,
                })
              }
            />
          ) : isDayView ? (
            <DayView images={images} />
          ) : null}
        </>
      )}
    </Container>
  );
};

export default AllPhotosScreen;

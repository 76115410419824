import React, { useState, useEffect } from "react";

import useStore from "store/store";

import { Box, Typography } from "@mui/material";

import BackpackContent from "./BackpackContent/BackpackContent";
import TabContainer from "components/TabContainer/TabContainer";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { TabContainerProps } from "components/TabContainer/TabContainer";
import { translation } from "constants/translation";
import { useParams } from "react-router-dom";

const BackpackPage = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tabs, setTabs] = useState<TabContainerProps["tabs"]>([]);
  const { languageSlug } = useParams();

  const { setRefreshGroupBackpack, setRefreshGroupFavourite, groupList } =
    useStore((state) => ({
      groupList: state.groupList,
      setRefreshGroupBackpack: state.setRefreshGroupBackpack,
      setRefreshGroupFavourite: state.setRefreshGroupFavourite,
    }));

  useEffect(() => {
    const initTabs = async () => {
      const allTabs: TabContainerProps["tabs"] = [];

      for (let i = 0; i < groupList.length; i++) {
        const group = groupList[i];
        allTabs.push({
          name: group.name,
          avatar: typeof group.image === "string" ? group.image : "",
          element: <BackpackContent key={group.id} />,
        });
      }
      setTabs(allTabs);
      setLoaded(true);
      setRefreshGroupBackpack(true);
      setRefreshGroupFavourite(true);
    };
    initTabs();
    // run only when need to refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupList]);

  return (
    <Box sx={{ padding: "10px 20px" }}>
      {!loaded ? (
        <LoadingIndicator />
      ) : tabs.length > 0 ? (
        <TabContainer tabs={tabs} groupList={groupList} checkedStyle />
      ) : (
        <Typography
          sx={{ fontWeight: "500", fontSize: "30px", textAlign: "center" }}
        >
          {translation.noenrolledChild}
        </Typography>
      )}
    </Box>
  );
};

export default BackpackPage;

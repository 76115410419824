import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, Stack, Divider } from "@mui/material";

import Button from "components/Button/Button";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { API_Fundamental_Create, API_Milestone } from "types/project";

import {
  createFundamental,
  editFundamentalImage,
} from "api/fundamental-api";

import { translation } from "constants/translation";

import {
  DescriptionField,
  ImageField,
  MilestoneFieldWithDnd,
  NameField,
} from "./EditDevelopmentalGoalFormComponents";

const CreateDevelopmentalGoalForm = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const {
    currDevelopmentalGoal,

    currDevelopmentalGoalTag,
  } = useStore((state) => ({
    currDevelopmentalGoal: state.currDevelopmentalGoal,
    currDevelopmentalGoalTag: state.currDevelopmentalGoalTag,
  }));
  const openSnack = useSnack();

  const { currLanguage, setIsRefreshAllFundamental } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setIsRefreshAllFundamental: state.setIsRefreshAllFundamental,
  }));

  const [image, setImage] = useState<File | string>("");
  const [currMilestones, setCurrMilestones] = useState<API_Milestone[]>([]);
  const [mainFormData, setMainFormData] = useState<API_Fundamental_Create>({
    ...currDevelopmentalGoal,
    tag: currDevelopmentalGoalTag.toLowerCase(),
  });

  useEffect(() => {
    setMainFormData({
      ...currDevelopmentalGoal,
      tag:
        currDevelopmentalGoal.tag !== ""
          ? currDevelopmentalGoal.tag
          : currDevelopmentalGoalTag.toLowerCase(),
    });
    setImage(currDevelopmentalGoal.image);
    setCurrMilestones(currDevelopmentalGoal.milestones);
  }, [currDevelopmentalGoal]);

  const handleSubmit = async () => {
    const finalMainFormData: API_Fundamental_Create = {
      ...mainFormData,
      milestones: currMilestones,
      image: "to be delete", //use only the image declared in the usestate to update
    };
    delete finalMainFormData.image;

    let isRunImageUpdate = true;
    if (typeof image === "string" && image.length > 0) {
      // string file remains, so don't send image go, removing image whatsoever.
      isRunImageUpdate = false;
    }

    let res = await createFundamental(finalMainFormData, currLanguage);

    if (typeof res !== "string") {
      if (isRunImageUpdate) {
        res = await editFundamentalImage(res.id, image, currLanguage);
      }
    }

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      // setDevelopmentalGoals([...developmentalGoals, res]);
      setCurrMilestones(res.milestones);
      setIsRefreshAllFundamental(true);
      // navigate(`${CURLANG(currLanguage)}/${EDIT_DEVELOPMENTAL_GOAL}`);
      openSnack(translation.createdDevelopmentalGoal, true);
      handleClose();
    }
  };

  return false ? (
    <LoadingIndicator />
  ) : (
    <>
      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <NameField data={mainFormData} setData={setMainFormData} />

        <DescriptionField data={mainFormData} setData={setMainFormData} />

        <MilestoneFieldWithDnd
          currDevelopmentalGoal={currDevelopmentalGoal}
          currMilestones={currMilestones}
          setCurrMilestones={setCurrMilestones}
          handleClose={handleClose}
        />
        <ImageField image={image} setImage={setImage} />
        <Divider />

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          <Button
            buttonText={translation.submit || "Submit"}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
              height: "auto",
            }}
            onClick={() => {
              if (mainFormData.name === "") {
                openSnack(
                  "Please fill in the developemental goal name!",
                  false
                );
              }
              handleSubmit();
            }}
          />
        </Box>
      </Stack>
    </>
  );
};

export default CreateDevelopmentalGoalForm;

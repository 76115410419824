
import { Box, CircularProgress } from "@mui/material";

const SmallLoadingIndicator = ({ text }: { text?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
      }}
    >
      <CircularProgress sx={{ color: "txt.secondary" }} size={24} />
    </Box>
  );
};

export default SmallLoadingIndicator;

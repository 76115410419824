import React from "react";
import { Box, IconButton, SxProps, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useNavigate } from "react-router-dom";

import {
  CURLANG,
  MARKETPLACE,
  PROJECT_MANAGE_PAGE,
  PROJECT_SCHOOL_MANAGE_PAGE,
} from "constants/url";
import useStore from "store/store";
import { ViewMode } from "types/teacher";
import { ACCESS_CONTROL, INSIGHTS } from "constants/staticConstant";
import { API_School } from "types/school";

const FirstRow = ({
  pageMode,
  setPageMode,
  currSchool,
  children,
}: {
  pageMode: ViewMode;
  setPageMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  currSchool: API_School;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2.5,
      }}
    >
      <BreadCrumbs />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2.75,
        }}
      >
        {children}

        <TTBIcons
          pageMode={pageMode}
          setPageMode={setPageMode}
          currSchool={currSchool}
        />
      </Box>
    </Box>
  );
};

const BreadCrumbs = () => {
  const navigate = useNavigate();
  const { currLanguage, profileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      <Typography
        sx={{
          cursor: "pointer",
          lineHeight: 1,
        }}
        onClick={() => {
          if (profileDetails.role === "CE") {
            navigate(`${CURLANG(currLanguage)}/${PROJECT_MANAGE_PAGE}`);
          } else {
            navigate(`${CURLANG(currLanguage)}/${MARKETPLACE}`);
          }
        }}
      >
        Library
      </Typography>

      <NavigateNextIcon fontSize="small" />

      <Typography sx={{ fontWeight: "bold", color: "#407374", lineHeight: 1 }}>
        Manage Access
      </Typography>
    </Box>
  );
};

export const TTBIcons = ({
  pageMode,
  setPageMode,
  currSchool,
}: {
  pageMode: ViewMode;
  setPageMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  currSchool: API_School;
}) => {
  const navigate = useNavigate();
  const iconStyle: SxProps = {
    color: "#312F30",
    cursor: "pointer",
  };

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      <IconButton
        aria-label="access-control-icon-button"
        onClick={() => {
          // setPageMode(ACCESS_CONTROL);
          navigate(
            `${CURLANG(currLanguage)}/${PROJECT_SCHOOL_MANAGE_PAGE}/${
              currSchool.slug
            }/${ACCESS_CONTROL}`
          );
        }}
      >
        <LockOutlinedIcon
          fontSize="small"
          sx={{
            ...iconStyle,
            color:
              pageMode === (ACCESS_CONTROL as ViewMode) ? "#407374" : "default",
            opacity: pageMode === (ACCESS_CONTROL as ViewMode) ? 1 : 0.5,
            pointerEvents: "none",
          }}
        />
      </IconButton>
      <IconButton
        aria-label="analytic-icon-button"
        onClick={() => {
          navigate(
            `${CURLANG(currLanguage)}/${PROJECT_SCHOOL_MANAGE_PAGE}/${
              currSchool.slug
            }/${INSIGHTS}`
          );
          // setPageMode(INSIGHTS as ViewMode);
        }}
      >
        <TableChartOutlinedIcon
          fontSize="small"
          sx={{
            ...iconStyle,
            pointerEvents: "none",
            opacity: pageMode === (INSIGHTS as ViewMode) ? 1.0 : 0.5,
            color: pageMode === (INSIGHTS as ViewMode) ? "#407374" : "default",
          }}
        />
      </IconButton>
      {/* <IconButton
        aria-label="chart-icon-button"
        onClick={() => {
          // setPageMode(ACCESS_CONTROL);
          navigate(
            `${CURLANG(
              currLanguage
            )}/${PROJECT_SCHOOL_MANAGE_PAGE}/${ACCESS_CONTROL}`
          );
        }}
        disabled
      >
        {" "}
        <AssessmentOutlinedIcon
          fontSize="small"
          sx={{ ...iconStyle, pointerEvents: "none", opacity: 0.5 }}
        />
      </IconButton> */}
    </Box>
  );
};
export default FirstRow;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, SxProps, Typography, Checkbox, Tooltip } from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

import { translation } from "constants/translation";
import { API_Project_Analytic, Age_Group } from "types/project";
// import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  CURLANG,
  EDIT_PROJECT_PAGE,
  PROJECT_SUMMARY_PAGE,
} from "constants/url";
import { API_School } from "types/school";
import IndividualChartModal from "./IndividualChartModal";
import { categorizeSentiment } from "helper/helper";
import { SPROUTS } from "constants/project-lesson-activity";

const ProjectsAnalyticTable = ({
  currSchool,
  projects,
  setProjects,
  setSelected,
}: {
  currSchool: API_School;
  projects: API_Project_Analytic[];
  setProjects: React.Dispatch<React.SetStateAction<API_Project_Analytic[]>>;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  // const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  // Modal
  const [selectedProject, setSelectedProject] = useState<API_Project_Analytic>({
    id: -1,
    name: "",
    slug: "",
    module_name: "",
    age: SPROUTS as Age_Group,
    no_of_lessons: -1,
    rating_count: -1,
    average_rating: -1,
    group_enrolled_count: -1,
    group_completed_count: -1,
  });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  const iconStyles: SxProps = {
    color: "#312F30B2",
    cursor: "pointer",
  };

  const columnMinWidth = 130;

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Title",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Link
          style={{
            color: "#407374",
          }}
          to={`${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
            params.row.slug
          )}`}
        >
          <Tooltip title={params.value}>
            <Box
              sx={{
                maxWidth: params.colDef.computedWidth,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params.value}
            </Box>
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "module_name",
      headerName: "Module",
      maxWidth: 400,
      minWidth: 150,
    },
    { field: "age", headerName: "Level", maxWidth: 100 },
    {
      field: "no_of_lessons",
      headerName: "No. of Lessons",
      type: "number",
      minWidth: columnMinWidth,
    },
    {
      field: "number_of_goal_covered",
      headerName: "No. of dev goals covered",
      type: "number",
      minWidth: columnMinWidth,
    },
    {
      field: "number_of_milestone_covered",
      headerName: "No. of milestones covered",
      type: "number",
      minWidth: columnMinWidth,
    },
    {
      field: "avg_age_of_learners",
      headerName: "Avg age of learners",
      type: "number",
      minWidth: columnMinWidth,
    },
    {
      field: "rating_count",
      headerName: "Ratings count",
      type: "number",
      minWidth: columnMinWidth,
      resizable: true,
    },
    {
      field: "average_rating",
      headerName: "Average Ratings",
      type: "number",
      minWidth: columnMinWidth,
    },
    {
      field: "review_score",
      headerName: "Sentiment Score",
      type: "number",
      minWidth: columnMinWidth,
      renderCell: (params) => {
        const sentiment_score = params.value;
        return categorizeSentiment(sentiment_score);
      },
    },
    {
      field: "module_review_score",
      headerName: "Module Sentiment Score",
      type: "number",
      minWidth: columnMinWidth,
      renderCell: (params) => {
        const sentiment_score = params.value;
        return categorizeSentiment(sentiment_score);
      },
    },
    {
      field: "group_enrolled_count",
      headerName: "Class Enrolled",
      type: "number",
      minWidth: columnMinWidth,
    },
    {
      field: "group_completed_count",
      headerName: "Class Completed",
      type: "number",
      minWidth: columnMinWidth,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      maxWidth: 100,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            to={`${CURLANG(currLanguage)}/${EDIT_PROJECT_PAGE(
              params.row.slug
            )}`}
          >
            <EditIcon fontSize="small" sx={iconStyles} />
          </Link>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              // pointerEvents: "none",
            }}
            onClick={() => {
              setSelectedProject(params.row);
              handleOpen();
            }}
          >
            <AssessmentOutlinedIcon
              fontSize="small"
              sx={{ ...iconStyles, opacity: 1 }}
              // sx={{ ...iconStyles, opacity: 0.5 }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  // console.log("projects: ", projects);
  // console.log("selectedProject:", selectedProject);
  return (
    <>
      <IndividualChartModal
        selectedProject={selectedProject}
        currSchool={currSchool}
        open={openModal}
        handleClose={handleClose}
      />
      <DataGrid
        rows={projects}
        columns={columns}
        disableColumnMenu
        components={{
          BaseCheckbox(props) {
            return (
              <Checkbox
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent !important",
                  },
                }}
                icon={
                  <CheckBoxOutlineBlankOutlinedIcon
                    fontSize="small"
                    sx={iconStyles}
                  />
                }
                checkedIcon={
                  <CheckBoxOutlinedIcon
                    fontSize="small"
                    sx={{ ...iconStyles, color: "black" }}
                  />
                }
                indeterminateIcon={
                  <IndeterminateCheckBoxOutlinedIcon
                    fontSize="small"
                    sx={iconStyles}
                  />
                }
                disableRipple
                {...props}
              />
            );
          },
          NoRowsOverlay: NoProjectFound,
        }}
        loading={false}
        sx={{
          border: "none",
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: 0,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        onSelectionModelChange={(ids) => {
          const selectedIds = ids.map((id) => id.toString());
          setSelected(selectedIds);
        }}
      />
    </>
  );
};

const NoProjectFound = () => (
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "1.25rem",
      textAlign: "center",
      backgroundColor: "#FBFBFB",
      p: 2,
    }}
  >
    {translation.noProjectFound}
  </Typography>
);

export default ProjectsAnalyticTable;

import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { createFavItem, deleteFavItem } from "api/backpack-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";
import { translation } from "constants/translation";

const Misc = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexWrap: "wrap",
        mt: "0.75rem",
        justifyContent: "center",
      }}
    >
      <Favourite />
      <Rating />
    </Box>
  );
};

const Favourite = () => {
  const openSnack = useSnack();
  const { currGroup, currGroupFavourite, projectID, setRefreshGroupFavourite } =
    useStore((state) => ({
      currGroup: state.currGroup,
      currGroupFavourite: state.currGroupFavourite,
      projectID: state.currentProject.id,
      setRefreshGroupFavourite: state.setRefreshGroupFavourite,
    }));

  const [liked, setLiked] = useState<boolean>(
    currGroupFavourite.projects.filter(
      (favProject) => favProject.id === projectID
    ).length !== 0
  );

  useEffect(() => {
    setLiked(
      currGroupFavourite.projects.filter(
        (favProject) => favProject.id === projectID
      ).length !== 0
    );
  }, [currGroupFavourite]);

  const handleClick = () => {
    const addFav = async (projectID: number, groupID: number) => {
      const res = await createFavItem(projectID, groupID);
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        openSnack("Favourited successfully!", true);
        setRefreshGroupFavourite(true);
      }
    };

    const delFav = async (projectID: number, groupID: number) => {
      const res = await deleteFavItem([projectID], groupID);
      if (res === "success") {
        openSnack("Removed the project from favourite!", true);
        setRefreshGroupFavourite(true);
      } else {
        openSnack(res, false);
      }
    };

    liked ? delFav(projectID, currGroup.id) : addFav(projectID, currGroup.id);
  };

  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <Box onClick={handleClick} sx={{ display: "flex" }}>
        <Tooltip title={translation.addFavourites}>
          <FavoriteIcon
            sx={{
              color: `${liked ? "#ff0000" : "#C4C4C4"}`,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </Box>
      <Typography sx={{ fontSize: "0.75rem", fontWeight: "500" }}>
        {liked ? translation.saved : translation.notSaved}
      </Typography>
      {/* <AddBagFavModal
        open={openModal}
        setOpen={setOpenModal}
        type="favourite"
        projectID={projectID}
      /> */}
    </Box>
  );
};

const Rating = () => {
  const { rating, reviewCount } = useStore((state) => ({
    rating: state.currentProject.average_rating,
    reviewCount: state.currentProject.reviews.length,
  }));

  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <Tooltip title={rating !== null ? "Total Rating" : "No Rating Yet"}>
        <StarIcon
          sx={{ color: `${rating !== null ? "#ffc107" : "#C4C4C4"}` }}
        />
      </Tooltip>
      <Typography sx={{ fontSize: "0.75rem", fontWeight: "500" }}>
        {rating !== null
          ? `${Number(rating).toFixed(1)} / 5.0 (${reviewCount})`
          : translation.noRating}
      </Typography>
    </Box>
  );
};

export default Misc;

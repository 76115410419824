import React, { useState, useEffect } from "react";

import useStore from "store/store";

import { Box, Container, Typography } from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import FavoriteIcon from "@mui/icons-material/Favorite";

import SearchBar from "components/SearchBar/SearchBar";
import ProjectRow from "components/ProjectRow/ProjectRow";

import { API_Project } from "types/project";
import { translation } from "constants/translation";

const BackpackContent = () => {
  const { backpack, fav } = useStore((state) => ({
    backpack: state.currGroupBackpack.projects,
    fav: state.currGroupFavourite.projects,
  }));
  const [backpackProj, setBackpackProj] = useState<API_Project[]>(backpack);
  const [favProj, setFavProj] = useState<API_Project[]>(fav);

  useEffect(() => {
    setBackpackProj(backpack);
  }, [backpack]);

  useEffect(() => {
    setFavProj(fav);
  }, [fav]);
  // console.log("backpackProj: ", backpackProj);
  return (
    <Box>
      <Container
        sx={{
          backgroundColor: "#FBFBFB",
          borderRadius: "10px",
          pl: { xs: "10px", sm: "30px" },
          pb: { xs: "0px", sm: "45px" },
          pt: { xs: "8px", sm: "16px" },
        }}
        maxWidth="xl"
      >
        <BackpackSearchBar
          backpack={backpack}
          fav={fav}
          setBackpackProj={setBackpackProj}
          setFavProj={setFavProj}
        />
        <BackpackSection projects={backpackProj} />
        <FavouriteSection projects={favProj} />
      </Container>
    </Box>
  );
};

const BackpackSearchBar = ({
  backpack,
  fav,
  setBackpackProj,
  setFavProj,
}: {
  backpack: API_Project[];
  fav: API_Project[];
  setBackpackProj: React.Dispatch<React.SetStateAction<API_Project[]>>;
  setFavProj: React.Dispatch<React.SetStateAction<API_Project[]>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: { xs: "80vw", sm: "30vw" },
        margin: "auto",
      }}
    >
      <SearchBar
        handleChange={(searchVal: string) => {
          const searchValLowerCase = searchVal.toLowerCase();
          const filteredProj = backpack.filter(
            (proj) =>
              proj.description.toLowerCase().includes(searchValLowerCase) ||
              proj.name.toLowerCase().includes(searchValLowerCase)
          );
          setBackpackProj(filteredProj);

          const filteredFav = fav.filter(
            (proj) =>
              proj.description.toLowerCase().includes(searchValLowerCase) ||
              proj.name.toLowerCase().includes(searchValLowerCase)
          );
          setFavProj(filteredFav);
        }}
      />
    </Box>
  );
};

const BackpackSection = ({ projects }: { projects: API_Project[] }) => {
  return (
    <>
      <Box
        sx={{ display: "flex", gap: "2px", alignItems: "center", my: "1rem" }}
      >
        <ShoppingBagIcon sx={{ fontSize: "18px" }} />
        <Typography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
          {translation.backpack}
        </Typography>
      </Box>
      {projects && projects.length > 0 ? (
        <ProjectRow projects={projects} inBag />
      ) : (
        <Typography sx={{ mb: "40px" }}>{translation.noBackpack}</Typography>
      )}
    </>
  );
};

const FavouriteSection = ({ projects }: { projects: API_Project[] }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "2px",
          alignItems: "center",
          mt: "1rem",
          mb: { xs: "0rem", sm: "1rem" },
        }}
      >
        <FavoriteIcon sx={{ color: "red", fontSize: "18px" }} />
        <Typography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
          {translation.favourites}
        </Typography>
      </Box>

      {projects && projects.length > 0 ? (
        <ProjectRow projects={projects} />
      ) : (
        <Typography sx={{ mb: "40px" }}>{translation.noFavourites}</Typography>
      )}
    </>
  );
};

// export const BackpackContentAccordion = ({
//   projects,
//   groupName,
//   groupImg,
// }: {
//   projects: API_Project[];
//   groupName: string;
//   groupImg?: string;
// }) => {
//   const [show, setShow] = useState<boolean>(false);

//   return (
//     <Box>
//       <Accordion
//         displayContent={
//           <DisplayContent
//             groupName={groupName}
//             groupImg={groupImg}
//             setShow={setShow}
//             show={show}
//           />
//         }
//         expandedContent={<ExpandedContent projects={projects} />}
//         show={show}
//       />
//     </Box>
//   );
// };

// const DisplayContent = ({
//   groupName,
//   groupImg,
//   setShow,
//   show,
// }: {
//   groupName: string;
//   groupImg?: string;
//   setShow: React.Dispatch<React.SetStateAction<boolean>>;
//   show: boolean;
// }) => {
//   return (
//     <Container
//       onClick={() => setShow((prev: boolean) => !prev)}
//       sx={{
//         cursor: "pointer",
//         backgroundColor: "#eef5f5",
//         border: "1px solid var(--secondary-bg-color)",
//         padding: "10px",
//         borderRadius: "10px",
//         mb: "10px",
//         position: "relative",
//       }}
//       maxWidth="xl"
//     >
//       <ChildPill
//         name={groupName}
//         img={groupImg}
//         sx={{ border: "none", margin: "auto" }}
//       />
//       <KeyboardArrowDownIcon
//         sx={{
//           position: "absolute",
//           right: 20,
//           top: "50%",
//           transform: `${
//             show ? "translateY(-50%) rotate(180deg)" : "translateY(-50%)"
//           }`,
//           transition: "all 200ms ease-in-out",
//           color: "primary.main",
//         }}
//       />
//     </Container>
//   );
// };

// const ExpandedContent = ({ projects }: { projects: API_Project[] }) => {
//   return (
//     <Container
//       sx={{
//         backgroundColor: "#FBFBFB",
//         borderRadius: "10px",
//         pl: "30px",
//         pb: "45px",
//         pt: "16px",
//       }}
//       maxWidth="xl"
//     >
//       {/* -------------- Searchbar start -------------- */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           width: "30vw",
//           margin: "auto",
//         }}
//       >
//         <SearchBar
//           handleChange={(searchVal: string) => {
//             console.log(searchVal);
//           }}
//         />
//       </Box>
//       {/* -------------- Searchbar end -------------- */}

//       {/* -------------- Backpack start -------------- */}
//       <Box
//         sx={{ display: "flex", gap: "2px", alignItems: "center", my: "1rem" }}
//       >
//         <ShoppingBagIcon sx={{ fontSize: "18px" }} />
//         <Typography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
//           {translation.backpack}
//         </Typography>
//       </Box>
//       <ProjectRow projects={projects} />
//       {/* -------------- Backpack end -------------- */}

//       {/* -------------- Favourites start -------------- */}
//       <Box
//         sx={{ display: "flex", gap: "2px", alignItems: "center", my: "1rem" }}
//       >
//         <FavoriteIcon sx={{ color: "red", fontSize: "18px" }} />
//         <Typography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
//           {translation.favourites}
//         </Typography>
//       </Box>
//       <ProjectRow projects={projects} />
//       {/* -------------- Favourites end -------------- */}
//     </Container>
//   );
// };

export default BackpackContent;

import React, { useState } from "react";

const useDialog = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = (e?: any) => {
    setOpenDialog(false);
  };
  return { openDialog, handleOpenDialog, handleCloseDialog };
};

export default useDialog;

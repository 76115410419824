import React, { Fragment, useState } from "react";

import { Box, Typography } from "@mui/material";

import "components/ProjectSummaryScreen/ReactGridGallery.css";
import { translation } from "constants/translation";
import { GalleryPhotoVideo } from "types/gallery";

import {
  API_Lesson_Learning_Moment,
  API_Project_Lesson,
  API_Project_Lesson_Activity,
} from "types/project";
import LearningMomentModal from "containers/ProjectPage/LessonScreens/CanvasComponents/LearningMomentModal";
import LearningMomentPhotoScreen from "containers/ProjectPage/LessonScreens/LearningMomentPhotoScreen";
import { LITTLE_LEARNERS } from "containers/CalendarPage/constants/constants";
import LearningMomentQuestionScreen from "containers/ProjectPage/LessonScreens/LearningMomentQuestionScreen";

const LearningMomentScreenActivity = ({
  lesson,
  lessonIndex,
  activity,
  learningMomentObjectList,
  activityIndex,
  isShowActivityTitle,
}: {
  lesson: API_Project_Lesson;
  lessonIndex: number;
  activity: API_Project_Lesson_Activity;
  learningMomentObjectList: API_Lesson_Learning_Moment[];
  activityIndex: number;
  isShowActivityTitle: boolean;
}) => {
  return (
    <>
      {learningMomentObjectList.map((learningMomentObject, index) => {
        return (
          <LearningMomentActivityComponent
            key={`learning-moment-object-${learningMomentObject.id}`}
            lesson={lesson}
            lessonIndex={lessonIndex}
            learningMomentObject={learningMomentObject}
            activity={activity}
            activityIndex={activityIndex}
            isShowActivityTitle={isShowActivityTitle}
            index={index}
          />
        );
      })}
    </>
  );
};

const LearningMomentActivityComponent = ({
  lesson,
  lessonIndex,
  learningMomentObject,
  activity,
  activityIndex,
  isShowActivityTitle,
  index,
}: {
  lesson: API_Project_Lesson;
  lessonIndex: number;
  learningMomentObject: API_Lesson_Learning_Moment;
  activity: API_Project_Lesson_Activity;
  activityIndex: number;
  isShowActivityTitle: boolean;
  index: number;
}) => {
  //combined -> They are sharing the same photoVideoGalleryList (Learning moments within each activity, so need to apply filter via learning moment obj inside) -> split it into new component and usestate to control might be faster
  const [photoVideoGalleryList, setPhotoVideoGalleryList] = useState<
    GalleryPhotoVideo[]
  >([]);

  const [openLearningMomentModal, setOpenLearningMomentModal] =
    useState<boolean>(false);
  const [isLearningMomentPhotoScreen, setIsLearningMomentPhotoScreen] =
    useState<boolean>(false);

  let isLearningMomentPhotoDone = false;
  photoVideoGalleryList.every((gallery) => {
    gallery.photo_video_list.every((photoVideo) => {
      isLearningMomentPhotoDone = photoVideo
        .key!.toString()
        .includes(`activity-${activity.id}`);
      return !isLearningMomentPhotoDone;
    });
    return !isLearningMomentPhotoDone;
  });

  return (
    <Fragment key={`learning-moment-${learningMomentObject.id}`}>
      <LearningMomentModal
        open={openLearningMomentModal}
        setOpen={setOpenLearningMomentModal}
        currentLesson={lesson}
        learningMomentObject={learningMomentObject}
        isLearningMomentPhotoScreen={isLearningMomentPhotoScreen}
        setIsLearningMomentPhotoScreen={setIsLearningMomentPhotoScreen}
        isForLearningMomentTab
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          paddingLeft: { xs: 0, sm: 2, md: 4 },
        }}
      >
        {isShowActivityTitle && index === 0 && (
          <Typography variant="h6" sx={{ fontSize: 20 }}>
            {lesson.module_category === LITTLE_LEARNERS
              ? `${translation.formatString(translation.activityNo, {
                  number: activityIndex + 1,
                })}: ${activity.name}`
              : `${translation.formatString(translation.optionNo, {
                  number: activityIndex + 1,
                })}: ${activity.name}`}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            paddingTop: 1,
            position: "relative",
            flexDirection: "column",
            // border: "2px solid var(--primary-main)",
          }}
        >
          <LearningMomentPhotoScreen
            isPhotoVideoExist={isLearningMomentPhotoDone}
            photoVideoGalleryList={photoVideoGalleryList}
            setPhotoVideoGalleryList={setPhotoVideoGalleryList}
            currentLesson={lesson}
            learningMomentObject={learningMomentObject}
            setIsLearningMomentPhotoScreen={setIsLearningMomentPhotoScreen}
            isForLearningMomentTab={true}
          />
          <LearningMomentQuestionScreen
            isPhotoVideoExist={isLearningMomentPhotoDone}
            learningMomentObject={learningMomentObject}
            setIsLearningMomentPhotoScreen={setIsLearningMomentPhotoScreen}
            isForLearningMomentTab={true}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default LearningMomentScreenActivity;

import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { TITLE } from "constants/title";

import LogoBlur from "images/logo-blur.png";
import Image from "components/Image/Image";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  CALENDAR_TEXT,
  LESSON_LIBRARY_TEXT,
  MY_CHILDREN_TEXT,
  MY_CLASSES_TEXT,
  NOTICES_TEXT,
  PHOTOS_TEXT,
  PORTFOLIOS_TEXT,
} from "constants/navbar";
import { translation } from "constants/translation";
import {
  BACKPACK,
  CALENDAR,
  MY_CHILDREN_PORTFOLIO,
  CHILDREN_PORTFOLIO,
  CLASS,
  CLASS_DETAIL,
  ATTENDANCE,
  CURLANG,
  MARKETPLACE,
  PROJECT_MANAGE_PAGE,
  TEACHER_PORTFOLIO,
  TIMETABLE_PAGE,
  NOTICES,
  DEVELOPMENTAL_GOALS,
} from "constants/url";
import useStore from "store/store";
import {
  COMPLETED_WORKS,
  PHOTO_ALBUM,
  PROGRESS_REPORT,
  PROJECT_SUMMARY,
} from "containers/ChildrenPortfolioPage/constants";
import { getIsParentNeedResponse } from "api/announcement-api";

type Props = {
  pages: { [item: string]: string };
  page: string;
  handleClose: (() => void) | undefined;
  icons: { [item: string]: string };
  text: { [item: string]: string };
  isMobileView?: boolean;
};

const NavbarMenuBlock = ({ pages, page, handleClose, icons, text, isMobileView = false }: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const {
    currGroup,
    currLanguage,
    profileDetails,
    childrenInSession,
    isRefreshNeedResponse,
  } = useStore((state) => ({
    ...state,
    isRefreshNeedResponse:
      state.announcementList.filter(
        (announcement) => announcement.is_responded === false
      ).length > 0,
  }));

  const isLessonLibraryOrCalendarOrPortfolio =
    page === LESSON_LIBRARY_TEXT ||
    page === CALENDAR_TEXT ||
    page === PORTFOLIOS_TEXT ||
    page === MY_CLASSES_TEXT;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isTabSelected =
    location.pathname === pages[page] ||
    (location.pathname.includes("/timetable") && page === CALENDAR_TEXT) ||
    (location.pathname.includes("/backpack") && page === LESSON_LIBRARY_TEXT) ||
    (location.pathname.includes(`/${CHILDREN_PORTFOLIO}/`) && page === PORTFOLIOS_TEXT) ||
    (location.pathname.includes(TEACHER_PORTFOLIO) && page === PORTFOLIOS_TEXT) ||
    (location.pathname.includes(MY_CHILDREN_PORTFOLIO) && page === `${PORTFOLIOS_TEXT}`) ||
    (location.pathname.includes(`${PHOTO_ALBUM}`) && page === `${PHOTOS_TEXT}`) ||
    (location.pathname.includes(NOTICES) && page === NOTICES_TEXT) ||
    (location.pathname.includes(`/${MY_CLASSES_TEXT}`) && page === MY_CLASSES_TEXT);

  const contentEditorLessonLibraryMenu = [
    <CustomMenuItem
      key={`menu-custom-item-${translation.library}-${`${CURLANG(currLanguage)}/${MARKETPLACE}`}-card-view`}
      displayText={`Card View`}
      path={`${CURLANG(currLanguage)}/${MARKETPLACE}`}
      handleClose={handleMenuClose}
    />,
    <CustomMenuItem
      key={`menu-custom-item-${translation.library}-${`${CURLANG(currLanguage)}/${BACKPACK}`}-table-view`}
      displayText={`Table View`}
      path={`${CURLANG(currLanguage)}/${PROJECT_MANAGE_PAGE}`}
      handleClose={handleMenuClose}
    />,
  ];

  const generalLessonLibraryMenu = [
    <CustomMenuItem
      key={`menu-custom-item-${translation.library}-${`${CURLANG(currLanguage)}/${MARKETPLACE}`}`}
      displayText={`${translation.library}`}
      path={`${CURLANG(currLanguage)}/${MARKETPLACE}`}
      handleClose={handleMenuClose}
    />,
    <CustomMenuItem
      key={`menu-custom-item-${translation.backpack}-${`${CURLANG(currLanguage)}/${BACKPACK}`}`}
      displayText={translation.backpack}
      path={`${CURLANG(currLanguage)}/${BACKPACK}`}
      handleClose={handleMenuClose}
    />,
  ];

  const generalPortofolioMenu = [
    <CustomMenuItem
      key={`menu-custom-item-${translation.childrenPortfolio}-${`${CURLANG(
        currLanguage
      )}/${CHILDREN_PORTFOLIO}`}`}
      displayText={translation.childrenPortfolio}
      path={`${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO}`}
      handleClose={handleMenuClose}
    />,
    <CustomMenuItem
      key={`menu-custom-item-${translation.teacherPortfolio}-${`${CURLANG(
        currLanguage
      )}/${TEACHER_PORTFOLIO}`}`}
      displayText={translation.teacherPortfolio}
      path={`${CURLANG(currLanguage)}/${TEACHER_PORTFOLIO}`}
      handleClose={handleMenuClose}
    />,
  ];

  const generalMyClassesMenu = [
    <CustomMenuItem
      key={`menu-custom-item-${translation.classDetail}-${`${CURLANG(currLanguage)}/${CLASS_DETAIL}`}`}
      displayText={translation.classDetail}
      path={`${CURLANG(currLanguage)}/${CLASS}`}
      handleClose={handleMenuClose}
    />,
    <CustomMenuItem
      key={`menu-custom-item-${translation.attendance}-${`${CURLANG(currLanguage)}/${ATTENDANCE}`}`}
      displayText={translation.attendance}
      path={`${CURLANG(currLanguage)}/${CLASS}/${currGroup.slug}/${ATTENDANCE}`}
      handleClose={handleMenuClose}
    />,
  ];

  const parentPortfolioMenu = [
    <CustomMenuItem
      key={`menu-custom-item-parent-${translation.developmentalGoals}`}
      displayText={translation.developmentalGoals}
      path={`${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${
        childrenInSession[0]?.id
      }/${DEVELOPMENTAL_GOALS}`}
      handleClose={handleMenuClose}
    />,
    <CustomMenuItem
      key={`menu-custom-item-parent-${translation.developmentalGoals}`}
      displayText={translation.progressReport}
      path={`${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${
        childrenInSession[0]?.id
      }/${PROGRESS_REPORT}`}
      handleClose={handleMenuClose}
      // isDisabled
    />,
    <CustomMenuItem
      key={`menu-custom-item-parent-${translation.completedWorks}`}
      displayText={translation.completedWorks}
      path={`${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${
        childrenInSession[0]?.id
      }/${COMPLETED_WORKS}`}
      handleClose={handleMenuClose}
    />,
    <CustomMenuItem
      key={`menu-custom-item-parent-${translation.projectSummaries}`}
      displayText={translation.projectSummaries}
      path={`${CURLANG(currLanguage)}/${MY_CHILDREN_PORTFOLIO}/${
        childrenInSession[0]?.id
      }/${PROJECT_SUMMARY}`}
      handleClose={handleMenuClose}
    />,
  ];
  const [isParentNeedResponse, setIsParentNeedResponse] = useState<boolean>(false);

  useEffect(() => {
    if (profileDetails.role !== "PR") {
      return;
    }
    const populateIsParentNeedResponse = async () => {
      const res = await getIsParentNeedResponse();
      if (typeof res !== "string") {
        setIsParentNeedResponse(res);
      }
    };
    populateIsParentNeedResponse();
  }, [isRefreshNeedResponse, profileDetails.role]);
  const isSmallScreen = window.matchMedia('(max-width: 1450px)').matches;

  return (
    <Box
      id={`navbar-menu-block-${page.toLowerCase().split(" ").join("-")}`}
      sx={{ display: "flex", flexDirection: "column", gap: 0 }}
      key={`individual-menu-block-${page}`}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: 1,
          transition: "all 200ms ease-in-out",
          "&:hover": {
            transition: "all 200ms ease-in-out",
            backgroundColor: `background.paper`,
          },
          borderRadius: `10px`,
          backgroundColor: isTabSelected ? "white" : open ? "background.paper" : "primary.light",
          cursor: "pointer",
        }}
        onMouseOver={(event: React.MouseEvent<HTMLElement>) => {
          if (isLessonLibraryOrCalendarOrPortfolio) {
            setAnchorEl(event.currentTarget);
          }
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          if (isTabSelected) {
            return;
          }
          if (isLessonLibraryOrCalendarOrPortfolio) {
            setAnchorEl(event.currentTarget);
          } else {
            if (!isMobileView && event instanceof MouseEvent) {
              handleClose && handleClose();
              document.title = `${TITLE} | ${page}`;
            }
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 20,
            width: isMobileView ? 140 : "100%",
            maxWidth: page === MY_CHILDREN_TEXT ? 200 : 140,
            gap: 0.5,
          }}
        >
          <Image
            style={{
              overflow: "hidden",
              height: isSmallScreen ? 20 : 30,
              width: isSmallScreen ? 20 : 30,
            }}
            src={icons[page]}
            alt={page}
            loadingImgSrc={LogoBlur}
          />
          <Link to={pages[page]} style={{ textDecoration: "none", color: "black" }}>
            <Typography
              sx={{
                color: "txt.dark",
                fontSize: isSmallScreen ? '12px' : '14px', // Adjust fontSize based on screen size
                display: "flex",
                justifyContent: "center",
                lineHeight: 1.25,
                textAlign: "center",
              }}
            >
              {text[page].toUpperCase()}
            </Typography>
          </Link>

          {isLessonLibraryOrCalendarOrPortfolio ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : isMobileView ? (
            <Box sx={{ minWidth: 20, minHeight: 20 }} />
          ) : null}
          {page === NOTICES_TEXT && isParentNeedResponse && (
            <Box
              sx={{
                borderRadius: "50%",
                backgroundColor: theme.palette.custom.lightOrange,
                width: 12,
                height: 12,
                ml: 1,
              }}
            />
          )}
        </Box>
      </Box>
      <Menu
        id={`${page}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{ onMouseLeave: handleMenuClose }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenu-list": {
            p: 0,
          },
        }}
      >
        {page === LESSON_LIBRARY_TEXT
          ? profileDetails.role === "CE"
            ? contentEditorLessonLibraryMenu
            : generalLessonLibraryMenu
          : page === CALENDAR_TEXT
            ? [
              <CustomMenuItem
                key={`menu-custom-item-${translation.visualTimetable}-${CURLANG(
                  currLanguage
                )}/${TIMETABLE_PAGE(profileDetails.id.toString())}`}
                displayText={translation.visualTimetable}
                path={`${CURLANG(currLanguage)}/${TIMETABLE_PAGE(profileDetails.id.toString())}`}
                handleClose={handleMenuClose}
              />,
              <CustomMenuItem
                key={`menu-custom-item-${translation.scheduler}-${`${CURLANG(currLanguage)}/${CALENDAR}`}`}
                displayText={translation.scheduler}
                path={`${CURLANG(currLanguage)}/${CALENDAR}`}
                handleClose={handleMenuClose}
              />,
            ]
            : page === PORTFOLIOS_TEXT
              ? profileDetails.role === "PR"
                ? parentPortfolioMenu
                : generalPortofolioMenu
              : page === MY_CLASSES_TEXT
                ? generalMyClassesMenu
                : null}
      </Menu>
    </Box>
  );
};

const CustomMenuItem = ({
  displayText,
  path,
  handleClose,
  isDisabled = false,
}: {
  displayText: string;
  path: string;
  handleClose: (() => void) | undefined;
  isDisabled?: boolean;
}) => {
  const navigate = useNavigate();

  // make menu-items as link
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClose && handleClose();
    navigate(path);
  };
  return (
    <MenuItem
      sx={{
        p: 0.5,
      }}
      disableGutters
      onClick={handleClick}
      disabled={isDisabled}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 25,
          maxWidth: 150,
          px: 1,
        }}
      >
        <Link to={path} onClick={handleClick} style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              color: "txt.dark",
              fontSize: 14,
              display: "flex",
              justifyContent: "center",
              lineHeight: 1.25,
              textAlign: "center",
            }}
          >
            {displayText}
          </Typography>
        </Link>
      </Box>
    </MenuItem>
  );
};

export default NavbarMenuBlock;

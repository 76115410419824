import React from "react";
import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageTwelve from "../../../../../../../images/children-photo-book/page-12.png";

import { CaptionPDFComponent } from "./constant";
import {
  PDFScale,
  photoBookPageTwelveCaptionInfo,
  photoBookPageTwelveCaptionPositionList,
  photoBookPageTwelveImageInfo,
  photoBookPageTwelveImagePositionList,
} from "../photoBookCommonConstant";
import { Image } from "@react-pdf/renderer";
import {
  imageSrc,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";

export const PhotoBookPageTwelveComponent = ({
  images,
}: {
  images: ImageType[];
}) => {
  const scale = PDFScale;
  const imagePositionList = photoBookPageTwelveImagePositionList(images, scale);
  const captionPositionList = photoBookPageTwelveCaptionPositionList(
    images,
    scale
  );
  const imageInfo = photoBookPageTwelveImageInfo(scale);
  const captionInfo = photoBookPageTwelveCaptionInfo(scale);

  return (
    <>
      <Image src={imageSrc(PhotoBookPageTwelve)} style={styles.background} />

      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            key={`image-${imagePositionInfo.src}`}
            src={imageSrc(imagePositionInfo.src)}
            style={{
              position: "absolute",
              top: imagePositionInfo.top,
              left: imagePositionInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
              objectFit: "cover",
            }}
          />
        );
      })}

      {/* <CaptionComponent
        top={120 * scale}
        left={30 * scale}
        width={250 * scale}
        caption={images[0].caption}
        clampLine={1}
        fontSize={13 * scale}
        textAlign="center"
      /> */}
      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionPDFComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionPositionInfo.top}
            left={captionPositionInfo.left}
            width={captionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize}
            textAlign="center"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageTwelveComponent;

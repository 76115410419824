import Button from "@mui/material/Button";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarExport,
} from "@mui/x-data-grid";
import SmallLoadingIndicator from "components/LoadingIndicator/SmallLoadingIndicator";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import AddIcon from "@mui/icons-material/Add";
import { BUTTON_TEXT_STYLE, EMAIL_WIDTH, IMAGE_WIDTH, MOBILE_NUMBER_WIDTH } from "./styling";
import { UserRoleAbbr, UserRoleMappingInverse } from "types/auth";
import CreateProfileModal from "../components/CreateProfileModal";
import { useState } from "react";
import CreateSchoolModal from "../components/CreateSchoolModal";
import { Avatar, AvatarGroup, Box, TextField, Typography } from "@mui/material";
import Logo from "images/logo-2-colour.png";
import { API_Group_Simple } from "types/group";
import { API_School } from "types/school";
import CreatePaymentRecordModal from "../components/CreatePaymentRecordModal";
import AvatarComponent from "../components/AvatarComponent";
import { translation } from "constants/translation";
import UploadIcon from "@mui/icons-material/Upload";
import useSnack from "hooks/useSnack";
import { API_Create_Child, API_Create_Child_Bulk } from "types/profile";
import { createBulkChildProfiles } from "api/profile-api";
import useStore from "store/store";
import { registerAccountBulk, updateProfileBulkV2 } from "api/auth-api";

interface EditToolbarProps {
  filterGroups: any;
  setFilterGroups: any;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  handleOpen: () => void;
  role: UserRoleAbbr;
  showImportButton?: boolean;
  selectionModel: any[];
  bulkDeleteLoading: boolean;
  bulkUpdateLoading: boolean;
  handleBulkDeleteClick: (selectionModel: any[]) => void;
  handleBulkClassClick: (selectionModel: any[]) => void;
  setBulkUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  childBulkAction: boolean;
}

EditToolbarProfile.defaultProps = {
  showImportButton: false, // Default value is false
};

export function EditToolbarProfile(props: EditToolbarProps) {
  const {
    filterGroups,
    setFilterGroups,
    setRows,
    setRowModesModel,
    role,
    showImportButton,
    selectionModel,
    bulkDeleteLoading,
    bulkUpdateLoading,
    handleBulkDeleteClick,
    handleBulkClassClick,
    childBulkAction,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const openSnack = useSnack();
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));

  const handleImport = async () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv";

    fileInput.addEventListener("change", async (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0];

      if (file && file.name.endsWith(".csv")) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const content: string = e.target?.result;
          const rows: string[] = content.split("\n");
          const headers: string[] = rows[0].split(",").map((header) => header.trim());

          const roleConfig: { [key: string]: any } = {
            CH: {
              requiredFields: ["preferred_name", "full_name", "dob"],
              processData: () => {
                openSnack("CSV file uploaded successfully for CH role.", true);
                const data: any[] = [];

                // Extract data from CSV and push to the 'data' array
                for (let i = 1; i < rows.length; i++) {
                  const values = rows[i].split(",").map((value) => value.trim());
                  if (values.length === headers.length) {
                    const child: any = {
                      preferred_name: values[headers.indexOf("preferred_name")],
                      full_name: values[headers.indexOf("full_name")],
                      dob: values[headers.indexOf("dob")],
                      role: "CH",
                      school: profileDetails?.school?.id !== undefined ? profileDetails.school.id : null,
                    };
                    data.push(child);
                  }
                }

                if (data.length > 0) {
                  console.log("Data for CH role:", data);
                  createBulkChildProfiles(data)
                    .then((response: any) => {
                      openSnack("Bulk profiles created successfully.", true);
                    })
                    .catch((error: any) => {
                      openSnack(`Error creating bulk profiles ${error}`, true);
                    });
                } else {
                  openSnack("CSV file does not contain any data for CH role.", false);
                }
              },
            },
            TR: {
              requiredFields: ["email", "password", "full_name", "preferred_name"],
              processData: () => {
                const data: any[] = [];

                //  Extract data from CSV and push to the 'data' array
                for (let i = 1; i < rows.length; i++) {
                  const values = rows[i].split(",").map((value) => value.trim());
                  if (values.length === headers.length) {
                    const user: any = {
                      email: values[headers.indexOf("email")],
                      password: values[headers.indexOf("password")],
                      full_name: values[headers.indexOf("full_name")],
                      dob: values[headers.indexOf("dob")],
                      preferred_name: values[headers.indexOf("preferred_name")],
                      mobile_number: values[headers.indexOf("mobile_number")],
                      role: "TR",
                      school: profileDetails?.school?.id !== undefined ? profileDetails.school.id : null,
                    };
                    data.push(user);
                  }
                }

                if (data.length > 0) {
                  console.log("Data for TR role:", data);
                  registerAndProfileUpdateLogic(data);
                } else {
                  openSnack("CSV file does not contain any data for TR role.", false);
                }
              },
            },
            // ... (add configurations for other roles as needed)
          };

          const roleConfigurations = roleConfig[role];

          if (roleConfigurations) {
            const { requiredFields, processData } = roleConfigurations;
            const missingFields = requiredFields.filter(
              (field: string) => !headers.some((header) => header.trim() === field)
            );

            if (missingFields.length > 0) {
              openSnack(`CSV is missing required headers: ${missingFields.join(", ")}`, false);
            } else {
              processData();
            }
          } else {
            openSnack("Invalid role specified.", false);
          }
        };

        reader.readAsText(file);
      } else {
        openSnack("Please select a valid CSV file.", false);
      }
    });

    fileInput.click();
  };

  async function registerAndProfileUpdateLogic(data: any[]) {
    // ... (perform actions specific to "TR" role)
    console.log("Performing actions specific to TR role:", data);
    // Bulk register accounts
    const registerResponse = await registerAccountBulk(data);

    if ("error" in registerResponse) {
      console.error("Error during bulk registration:", registerResponse.error);
      openSnack(`Error:${registerResponse.error}`, false);
      return;
    }

    // Extract profile IDs from the registration response
    const profileIds = registerResponse.map((response: any) => response.profile_id);

    // Prepare profiles for bulk update
    const profilesToUpdate = profileIds.map((id, index) => ({
      id,
      full_name: data[index].full_name,
      preferred_name: data[index].preferred_name,
      dob: data[index].dob,
      mobile_number: data[index].mobile_number,
      email: data[index].email,
      school: data[index].school,
      role: "TR", // Assuming "TR" is a constant role for all profiles
    }));

    // Update profiles in bulk
    const updateResponse = await updateProfileBulkV2(profilesToUpdate);

    if ("error" in updateResponse) {
      console.error("Error during bulk profile update:", updateResponse.error);
      openSnack(`Error:${updateResponse.error}`, false);
    } else {
      console.log("Bulk registration and update successful");
      openSnack("Bulk profiles created successfully.", true);
    }
  }

  return (
    <>
      <CreateProfileModal open={openModal} handleClose={handleClose} role={role} setRows={setRows} />
      <GridToolbarContainer sx={{ display: "flex", gap: 2, mb: 1 }}>
        {childBulkAction && (
          <>
            <Typography sx={{ marginLeft: 1 }}>Filter by Class:</Typography>
            <TextField
              size="small"
              value={filterGroups}
              onChange={(event) => {
                setFilterGroups(event.target.value);
              }}
            />
          </>
        )}
        <Button startIcon={<AddIcon />} onClick={handleOpen} sx={BUTTON_TEXT_STYLE}>
          {`${translation.add} ${UserRoleMappingInverse[role]}`}
        </Button>
        <GridToolbarExport
          sx={{ height: 38.1 }}
          csvOptions={{ fileName: `${UserRoleMappingInverse[role]}` }}
        />
        {showImportButton && (
          <Button startIcon={<UploadIcon />} onClick={handleImport} sx={BUTTON_TEXT_STYLE}>
            {`${"Import"}`}
          </Button>
        )}
        {selectionModel && selectionModel.length > 0 && childBulkAction && (
          <Button
            onClick={() => {
              handleBulkClassClick(selectionModel);
            }}
          >
            Add to Class
          </Button>
        )}
        {selectionModel && selectionModel.length > 0 && (
          <Button
            onClick={() => {
              handleBulkDeleteClick(selectionModel);
            }}
          >
            {bulkDeleteLoading ? <SmallLoadingIndicator /> : "Delete"}
          </Button>
        )}
      </GridToolbarContainer>
    </>
  );
}

export function EditToolbarSchool(props: EditToolbarProps) {
  const { setRows, setRowModesModel, role } = props; //profile role
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <CreateSchoolModal open={openModal} handleClose={handleClose} setRows={setRows} />
      <GridToolbarContainer>
        <Button startIcon={<AddIcon />} onClick={handleOpen} sx={BUTTON_TEXT_STYLE} disabled={role !== "WAD"}>
          {`${translation.add} ${translation.school}`}
        </Button>
      </GridToolbarContainer>
    </>
  );
}

export function EditToolbarPaymentRecord(props: EditToolbarProps) {
  const { setRows } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <CreatePaymentRecordModal open={openModal} handleClose={handleClose} setRows={setRows} />
      <GridToolbarContainer>
        <Button startIcon={<AddIcon />} onClick={handleOpen} sx={BUTTON_TEXT_STYLE}>
          {`${translation.addPaymentRecord}`}
        </Button>
      </GridToolbarContainer>
    </>
  );
}

export const ID_COLUMN = {
  field: "unique_sn",
  headerName: "SN",
  width: 50,
  filterable: false,
  sortable: false,
  renderCell: (index: any) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {index.api.getRowIndex(index.row.id) + 1}
      </Box>
    );
  },
};

export const IMAGE_COLUMN = {
  field: "image",
  headerName: translation.image,
  width: IMAGE_WIDTH,
  editable: true,
  renderCell: (params: any) => <AvatarComponent imgsrc={params.value} letter={"I"} />,
};

export const EMAIL_COLUMN = {
  field: "email",
  headerName: translation.email,
  width: EMAIL_WIDTH,
  editable: false,
};

export const MOBILE_NUMBER_COLUMN = {
  field: "mobile_number",
  headerName: translation.mobile_number,
  width: MOBILE_NUMBER_WIDTH,
  editable: false,
};

export const PREFERRED_NAME_COLUMN = {
  field: "preferred_name",
  headerName: translation.preferred_name,
  width: 180,
  editable: true,
};

export const FULL_NAME_COLUMN = {
  field: "full_name",
  headerName: translation.full_name,
  width: 180,
  editable: true,
};

export const GROUP_COLUMN = (handleOpenModal: (id: number) => void) => {
  return {
    field: "id",
    headerName: translation.group,
    width: 150,
    filterable: false,
    sortable: false,
    editable: false,
    renderCell: (params: any) => {
      return (
        <Box
          key={`${params.row.id}`}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            // console.log(params.value);

            handleOpenModal(params.value);
          }}
        >
          {params.row.groups?.length !== 0 ? (
            <AvatarGroup max={3}>
              {params.row.groups.map((group: API_Group_Simple) => {
                return (
                  <Avatar
                    key={`${params.row.id}-${group.id}`}
                    alt={Logo}
                    src={group.image ? group.image : Logo}
                  />
                );
              })}
            </AvatarGroup>
          ) : (
            <Button sx={{ marginY: 1 }} onClick={() => handleOpenModal(params.value)}>
              {translation.addGroup}
            </Button>
          )}
        </Box>
      );
    },
  };
};

export const SCHOOL_COLUMN = (schoolList: API_School[], isCE?: boolean) => {
  return {
    field: "school",
    headerName: "School",
    type: "singleSelect",
    valueOptions: (params: any) => {
      // console.log("params: ", params);
      let options = [
        ...schoolList.map((school) => ({
          value: school.id,
          label: school.name,
        })),
      ];

      if (isCE) {
        options = [...options, { value: -1, label: "No School" }];
      }
      return options;
      // return schoolList.map((school) => {
      //   return school.name;
      // });
    },
    valueFormatter: ({ value }: { value: any }) => {
      const schoolOption = schoolList.map((school) => ({
        value: school.id,
        label: school.name,
      }));
      return schoolOption?.find((opt) => opt?.value === value)?.label ?? "";
    },
    width: 160,
    editable: true,
  };
};

export const ACTIONS_COLUMN = ({
  rowModesModel,
  handleSaveClick,
  handleCancelClick,
  handleEditClick,
  handleDeleteClick,
}: {
  rowModesModel: any;
  handleSaveClick: any;
  handleCancelClick: any;
  handleEditClick: any;
  handleDeleteClick: any;
}) => {
  return {
    field: "actions",
    type: "actions",
    headerName: translation.actions,
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }: { id: number }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  };
};

export const undefinedValueFormatter = (params: any) => (params?.value ? params?.value : "None");

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
  CALENDAR_BORDER_BOLD_STYLE,
  CALENDAR_BORDER_STYLE,
  CALENDAR_HEIGHT,
} from "../constants/constants";
import LeftColumnBody from "./CalendarBodyComponents/LeftColumnComponents/LeftColumnBody";
import RightColumnBody from "./CalendarBodyComponents/RightColumnComponents/RightColumnBody";
import LeftColumnHeader from "./CalendarBodyComponents/LeftColumnComponents/LeftColumnHeader";
import RightColumnHeader from "./CalendarBodyComponents/RightColumnComponents/RightColumnHeader";
import useStore from "store/store";
import moment from "moment";
import {
  CalendarViewOptions,
  GroupProjectBoxProps,
} from "types/calendar";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import {
  generateMonthDates,
  generateWeekDates,
  generateYearDates,
} from "../constants/helper";

const CalendarBodySection = () => {
  const {
    groupList,
    currGroup,
    startAndEndDate,

    currentView,
    setCalendarDates,
    calendarDates,
    groupProjectBoxProps,
    refreshProjectBox,
    setRefreshProjectBox,
    isLoadingCalendar,
    setIsLoadingCalendar,
    isRefreshBypassLoading,
    setIsRefreshBypassLoading,
  } = useStore((state) => ({
    groupList: state.groupList,
    currGroup: state.currGroup,
    startAndEndDate: state.startAndEndDate,
    currentView: Object.keys(state.calendarViews).filter(
      (view) => state.calendarViews[view as CalendarViewOptions] === true
    )[0] as CalendarViewOptions,
    setCalendarDates: state.setCalendarDates,
    calendarDates: state.calendarDates,
    groupProjectBoxProps: state.groupProjectBoxProps,
    refreshProjectBox: state.refreshProjectBox,
    setRefreshProjectBox: state.setRefreshProjectBox,
    isLoadingCalendar: state.isLoadingCalendar,
    setIsLoadingCalendar: state.setIsLoadingCalendar,
    isRefreshBypassLoading: state.isRefreshBypassLoading,
    setIsRefreshBypassLoading: state.setIsRefreshBypassLoading,
  }));

  const { startDate, endDate } = startAndEndDate;

  const isBetweenDate = (projectStartAt: string, projectEndAt: string) => {
    const projectStartAtMoment = moment(projectStartAt);
    const projectEndAtMoment = moment(projectEndAt);

    return (
      (projectStartAtMoment.isSameOrAfter(startDate) &&
        projectStartAtMoment.isSameOrBefore(endDate)) || // inner
      (projectEndAtMoment.isSameOrAfter(startDate) &&
        projectEndAtMoment.isSameOrBefore(endDate)) || // inner
      (projectStartAtMoment.isSameOrBefore(startDate) &&
        projectEndAtMoment.isSameOrAfter(endDate))
    );
  };

  const getProjectBoxes = () => {
    if (Object.keys(groupProjectBoxProps).length === 0) {
      return {};
    }
    const projectBoxesTemp: GroupProjectBoxProps = {};

    for (const [groupId, projectBoxes] of Object.entries(
      groupProjectBoxProps
    )) {
      // console.log("groupProjectBoxProps: ", groupProjectBoxProps);
      // console.log("projectBoxes: ", projectBoxes);
      const filteredProjects = projectBoxes.filter((projectBox) =>
        isBetweenDate(projectBox.startAt, projectBox.endAt)
      );
      // console.log("filteredProjects: ", filteredProjects);
      projectBoxesTemp[groupId.toString()] = filteredProjects;
    }
    // console.log("calling getProjectBoxes projectBoxesTemp: ", projectBoxesTemp);
    return projectBoxesTemp;
  };

  const [projectBoxesToRender, setProjectBoxesToRender] = useState<
    GroupProjectBoxProps | undefined
  >(undefined);
  // console.log(
  //   startAndEndDate.startDate,
  //   startAndEndDate.startDate.getTime(),
  //   startAndEndDate.endDate,
  //   startAndEndDate.endDate.getTime()
  // );
  useEffect(() => {
    // console.log("refreshProjectBox: ", refreshProjectBox);
    // console.log("isLoadingCalendar: ", isLoadingCalendar);
    if (
      !refreshProjectBox && //refreshprojectbox false = done loading groupprojectboxprops
      isLoadingCalendar &&
      startAndEndDate.startDate.getTime() !== startAndEndDate.endDate.getTime()
    ) {
      const projectBoxesRes = getProjectBoxes();
      // console.log("projectBoxesRes: ", projectBoxesRes);
      setProjectBoxesToRender(projectBoxesRes);
    }
  }, [
    refreshProjectBox,
    isLoadingCalendar,
    startAndEndDate.startDate.getTime(),
  ]);

  useEffect(() => {
    // console.log("isRefreshBypassLoading: ", isRefreshBypassLoading);
    if (isRefreshBypassLoading) {
      const projectBoxesRes = getProjectBoxes();
      setProjectBoxesToRender(projectBoxesRes);
      setIsRefreshBypassLoading(false);
    }
  }, [isRefreshBypassLoading]);

  // useEffect(() => {
  //   if (Object.keys(groupProjectBoxProps).length > 0) {
  //     setProjectBoxes(getProjectBoxes());
  //     setRefreshProjectBox(false);
  //   }
  // }, [Object.keys(groupProjectBoxProps).length]);
  useEffect(() => {
    if (
      projectBoxesToRender === undefined ||
      isLoadingCalendar === false ||
      refreshProjectBox === true
    ) {
      return;
    }
    // console.log("isLoaindg: ", isLoadingCalendar);
    // console.log("refreshProjectBoxes: ", refreshProjectBox);
    // console.log("projectBoxesToRender: ", projectBoxesToRender);
    // console.log("setting to false");
    setIsLoadingCalendar(false);
    // const loadTimeout = setTimeout(async () => {
    //   setIsLoadingCalendar(false);
    // }, 500);
    // // setIsLoaded(true);
    // return () => clearTimeout(loadTimeout);
  }, [projectBoxesToRender]);
  // console.log(
  //   "isLoading: ",
  //   isLoadingCalendar === false
  //     ? "**********************************FALSE"
  //     : "==================================True"
  // );
  useEffect(() => {
    // console.log("Set start and end date...");
    let dates: Date[];
    switch (currentView) {
      case "Week":
        dates = generateWeekDates(startAndEndDate);
        // generateDayLabels(dates);
        break;
      case "Month":
        dates = generateMonthDates(startAndEndDate);
        break;
      case "Year":
        dates = generateYearDates(startAndEndDate);
        break;
      default:
        dates = [];
    }
    setCalendarDates(dates);
  }, [startAndEndDate, currentView]);

  useEffect(() => {
    setRefreshProjectBox(true);
  }, [calendarDates]);

  // Group list sorted
  const groupListSorted = () => {
    const groupFiltered = groupList.filter(
      (group) => group.id === currGroup.id
    )[0];
    const groupListFiltered = groupList.filter(
      (group) => group.id !== currGroup.id
    );
    if (groupList.length === 0) {
      return [];
    }
    return [groupFiltered, ...groupListFiltered];
  };
  // console.log("projectBoxesToRender: ", projectBoxesToRender);
  return (
    <>
      {projectBoxesToRender ? (
        <>
          <Box
            sx={{
              mt: "14px",
              width: "100%",
              height: "fit-content",
              border: CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
              borderBottom: "0px solid #F0F0F0",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              overflowX: "hidden",
              display: "flex",
            }}
          >
            <LeftColumnHeader />
            <RightColumnHeader />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: `${CALENDAR_HEIGHT}vh`,
              border: CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
              borderTop: "0px solid #F0F0F0",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              overflowX: "hidden",
              overflowY: "overlay",
              display: "flex",
            }}
          >
            <LeftColumnBody
              projectBoxes={projectBoxesToRender}
              groupList={groupListSorted()}
            />
            <RightColumnBody
              projectBoxes={projectBoxesToRender}
              groupList={groupListSorted()}
            />
          </Box>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

export default CalendarBodySection;

import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

import { Fragment, useState } from "react";
import { API_Current_Lesson } from "types/profile";

import Logo from "images/logo-2.png";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { translation } from "constants/translation";
import { GoToLessonButton } from "./CommonButtons";
import LessonCardContent from "./LessonCardContent";
import { LESSON_CARD_MODAL_MEDIA_HEIGHT } from "../../constants";
import Image from "components/Image/Image";
import CloseIcon from "@mui/icons-material/Close";
import { NothingMessage } from "../TeacherDashboard";

const TeacherLessonModal = ({
  groupId,
  open,
  setOpen,
  lesson,
  currLessonWeight,
  isTodayLesson,
}: {
  groupId: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lesson: API_Current_Lesson;
  currLessonWeight: number;
  isTodayLesson: boolean;
}) => {
  const [openIndex, setOpenIndex] = useState<number>(0);

  const handleClose = () => {
    setOpen(false);
  };

  // console.log("lesson: ", lesson);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card
          sx={{
            width: { xs: "100%", sm: "80%", md: "60%" },
            height: { xs: "100%", sm: "90%" },
            borderRadius: { xs: 0, sm: "10px" },
            transition: "200ms all ease-in-out",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: { xs: "0", sm: "5%" },
            left: { xs: "0", sm: "10%", md: "20%" },
            overflowY: "auto",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <CloseIcon
            sx={{
              display: { xs: "flex", sm: "none" },
              position: "absolute",
              top: "12px",
              right: "12px",
              cursor: "pointer",
              borderRadius: "4px",
              color: "white",
              zIndex: 1,
            }}
            onClick={handleClose}
          />

          <CardMedia
            component="img"
            alt={lesson.image ? lesson.image : "No Image"}
            image={lesson.image ? lesson.image : Logo}
            sx={{
              backgroundColor: "primary.light",
              "&::before": { display: "none" },
              position: "relative",
              height: LESSON_CARD_MODAL_MEDIA_HEIGHT,
            }}
            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            }}
          />

          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flexGrow: 1,
              p: { xs: 2, sm: "16px 32px 8px" },
              justifyContent: "space-between",
            }}
          >
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: { xs: "flex-start", sm: "center" },
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 0.5,
                }}
              >
                <Box sx={{ flex: 1, fontSize: "1.1rem" }}>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 500,
                    }}
                  >
                    {`${translation.formatString(translation.lesson_number, {
                      number: lesson.weight,
                    })}: ${lesson.name}`}
                  </Typography>
                </Box>

                {lesson.duration ? (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "right",
                    }}
                  >
                    <Tooltip title="Expected Duration (mins)">
                      <AccessTimeIcon />
                    </Tooltip>
                    <Typography
                      sx={{ fontSize: "1.1rem" }}
                    >{`${lesson.duration} min`}</Typography>
                  </Box>
                ) : null}
              </Box>

              {lesson.objective ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      fontSize: "1.1rem",
                      mb: 1,
                      display: "flex",
                    }}
                  >
                    <Tooltip title="Lesson Objective">
                      <CrisisAlertIcon sx={{ mr: "6px" }} />
                    </Tooltip>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: lesson.objective,
                      }}
                    ></Box>
                  </Box>
                </Box>
              ) : null}

              {/* Grid Titles Without Image*/}
              {/* <Box sx={{ flexGrow: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={4}
                    sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                  >
                    {translation.activities}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{ fontSize: "1.1rem", fontWeight: 500 }}
                  >
                    {translation.teachingMaterials}
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                      alignItems: "stretch",
                    }}
                    item
                    xs={4}
                  >
                    <Grid container>
                      {lesson.activities.map((lessonActivity, index) => (
                        <Fragment key={`${index}-${lessonActivity.name}`}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bgcolor: index % 2 === 0 ? "primary.light" : "",
                              width: "100%",
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              sx={{ textAlign: "center", fontWeight: "500" }}
                            >
                              {` ${index + 1}`}
                            </Grid>
                            <Grid item xs={11}>
                              {`${lessonActivity.name}`}
                            </Grid>
                          </Box>
                        </Fragment>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    dangerouslySetInnerHTML={{
                      __html: lesson.material ? lesson.material : "",
                    }}
                  />
                </Grid>
              </Box> */}

              {/* Grid Titles - Desktop */}
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  display: { xs: "none", sm: "block" },
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6.75}
                    sx={{ fontSize: "1.2rem", fontWeight: 500 }}
                  >
                    {translation.activities}
                  </Grid>
                  <Grid
                    item
                    xs={5.25}
                    sx={{ fontSize: "1.2rem", fontWeight: 500 }}
                  >
                    {translation.teachingMaterials}
                  </Grid>
                  {lesson.activities.length !== 0 ? (
                    lesson.activities.map((lessonActivity, index) => (
                      <Fragment key={`${index}-${lessonActivity.name}`}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: index % 2 === 0 ? "primary.light" : "",
                            width: "100%",
                            p: 2,
                            borderRadius: 3,
                          }}
                        >
                          <Grid
                            item
                            xs={0.5}
                            sx={{ textAlign: "center", fontWeight: "500" }}
                          >
                            {` ${index + 1}`}
                          </Grid>
                          <Grid item xs={2.5} sx={{ p: 0.5 }}>
                            <Image
                              src={
                                lessonActivity.image
                                  ? lessonActivity.image
                                  : lesson.image
                                  ? lesson.image
                                  : Logo
                              }
                              alt={"No images"}
                              objectFit="contain"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              sx={{ fontWeight: "500" }}
                            >{`${lessonActivity.name}`}</Typography>
                          </Grid>
                          <Grid
                            sx={{ "& .image img": { width: "100%" } }}
                            item
                            xs={5}
                            dangerouslySetInnerHTML={{
                              __html: lessonActivity.material
                                ? lessonActivity.material
                                : "",
                            }}
                          />
                        </Box>
                      </Fragment>
                    ))
                  ) : (
                    <Box
                      sx={{
                        mt: 1,
                        width: "100%",
                      }}
                    >
                      <NothingMessage message={translation.noActivity} />
                    </Box>
                  )}
                </Grid>
              </Box>

              {/* Grid Titles - Mobile */}
              <Box
                sx={{
                  display: { xs: "block", sm: "none" },
                  flex: 1,
                  width: "100%",
                  my: 1,
                }}
              >
                <Typography
                  sx={{ fontSize: "1.2rem", fontWeight: 500, mb: 0.5 }}
                >
                  {translation.activities}
                </Typography>

                {lesson.activities.length !== 0 ? (
                  lesson.activities.map((lessonActivity, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems:
                          openIndex === index ? "flex-start" : "center",
                        gap: 2,
                        bgcolor: openIndex === index ? "#E7EFEB" : "white",
                        p: 2,
                        mx: -2,
                      }}
                      onClick={() => setOpenIndex(index)}
                    >
                      <Image
                        src={
                          lessonActivity.image
                            ? lessonActivity.image
                            : lesson.image
                            ? lesson.image
                            : Logo
                        }
                        alt=""
                        objectFit="contain"
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                      />

                      <Box>
                        <Typography
                          sx={{ fontSize: "1.125rem", fontWeight: 500 }}
                        >
                          {translation.formatString(translation.activityNo, {
                            number: index + 1,
                          })}
                        </Typography>
                        <Typography>{`${lessonActivity.name}`}</Typography>

                        {openIndex === index && (
                          <>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                mt: 1,
                              }}
                            >
                              {translation.teachingMaterials}
                            </Typography>

                            <Box
                              sx={{ "& .image img": { width: "100%" } }}
                              dangerouslySetInnerHTML={{
                                __html: lessonActivity.material
                                  ? lessonActivity.material
                                  : "-",
                              }}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <NothingMessage message={translation.noActivity} />
                )}
              </Box>

              {/* <Box> */}
              {/* <Grid container spacing={2}> */}

              {/* </Grid> */}
              {/* </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                {isTodayLesson ? (
                  <>
                    {/* <UpdateLessonProgressButton
                      projectId={lesson.project_id}
                      groupId={groupId}
                    /> */}
                    <GoToLessonButton
                      lesson={lesson}
                      buttonText={translation.resume}
                    />
                  </>
                ) : (
                  <GoToLessonButton
                    lesson={lesson}
                    buttonText={translation.goToLesson}
                  />
                )}
              </Box>
            </>
          </CardContent>
          <LessonCardContent
            currLessonWeight={currLessonWeight}
            lesson={lesson}
            mediaHeight={LESSON_CARD_MODAL_MEDIA_HEIGHT}
          />
          {/* <<Box
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              width: "100%",
              height: "330px",
              bgcolor: "rgba(0, 0, 0, 0.54)",
              color: "white",
              pt: "10px",
              pl: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#D4DEF8",
                padding: "5px",
                borderRadius: "5px",
                fontSize: ".75rem",
                fontWeight: "500",
                display: "flex",
                gap: "5px",
                mr: "auto",
              }}
              key={`${lesson.id}-module`}
            >
              <Avatar
                alt={lesson.module[0]}
                src={lesson.module_icon}
                sx={{
                  width: "1rem",
                  height: "1rem",
                }}
              />
              {lesson.module}
            </Box>
            <Typography
              sx={{
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "500",
              }}
            >
              {lesson.project_name}
            </Typography>
          </Box>> */}
        </Card>
      </Fade>
    </Modal>
  );
};

export default TeacherLessonModal;

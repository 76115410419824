import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { staticTabStyle } from "containers/ProjectSummaryPage/Screens/ConceptScreen";

import { UNIT_GUIDE } from "../constant";
const ProjectUnitGuide = () => {
  // Tabs
  const openSnack = useSnack();
  const navigate = useNavigate();

  const { currLanguage, projectSlug, currentProject } = useStore((state) => ({
    currLanguage: state.currLanguage,
    projectSlug: state.currentProject.slug,
    currentProject: state.currentProject,
  }));

  const [currentUnitGuideIndex, setCurrentUnitGuideIndex] = useState<number>(0);

  const handleUnitGuideChange = (
    event: React.SyntheticEvent,
    updatedUnitGuideIndex: number
  ) => {
    setCurrentUnitGuideIndex(updatedUnitGuideIndex);
    // navigate(
    //   `${CURLANG(currLanguage)}/${LESSON_ACTIVITY_PAGE(
    //     projectSlug!,
    //     lesson.id.toString()
    //   )}/unitGuide/${activityId}`
    // );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        p: 4,
      }}
    >
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: 500,
          fontSize: 26,
          ml: 2,
          mb: 1,
          color: "primary.main",
        }}
      >
        {`${UNIT_GUIDE}`}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {/* Tabs here */}
          <Tabs
            value={currentUnitGuideIndex}
            onChange={handleUnitGuideChange}
            variant="fullWidth"
            TabIndicatorProps={{ style: { display: "none" } }}
            // sx={{ ml: 2 }}
          >
            {currentProject.unit_guides.map((unitGuide, unitGuideIndex_) => {
              return (
                <Tab
                  key={`tab-${unitGuide.id}`}
                  label={`${unitGuide.name}`}
                  sx={{
                    ...staticTabStyle,
                    ml: unitGuideIndex_ === 0 ? 0 : 2,
                    backgroundColor:
                      currentUnitGuideIndex === unitGuideIndex_
                        ? "primary.light"
                        : "transparent",
                  }}
                />
              );
            })}
          </Tabs>

          <Box
            sx={{
              p: 2,
              width: "100%",
              height: { lg: `60vh` },
              border: "2px solid var(--primary-main)",
              backgroundColor: "background.paper",
              position: "relative",
              overflow: "auto",
              transition: "all 1s ease",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              className="ck-content"
              sx={{ lineHeight: 1.3, "& .image img": { width: "100%" } }}
              dangerouslySetInnerHTML={{
                __html:
                  currentProject.unit_guides[currentUnitGuideIndex].description,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectUnitGuide;

import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { ASSESSMENT_ANALYTICS } from 'constants/url';
import { SuspenseWrapper } from './LittleEvaluator';
import { environment } from 'environment';

const AssessmentAnalytics = lazy(() => import('containers/AssessmentAnalytics'));

const AssessmentAnalyticsRoutes = (
  <Route
    path={ASSESSMENT_ANALYTICS}
    element={
      <SuspenseWrapper>
        <AssessmentAnalytics />
      </SuspenseWrapper>
    }
  >
    {/* <Route
      index
      element={
        <SuspenseWrapper>
          <AssessmentOverview />
        </SuspenseWrapper>
      }
    /> */}
  </Route>
);

export default environment.previewFeatures ? AssessmentAnalyticsRoutes : null;

import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  SelectChangeEvent,
  Divider,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Avatar,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";

import {
  API_Checkpoint_Fundamental,
  API_Lesson_Checkpoint,
} from "types/project";
import { translation } from "constants/translation";
import { SubtitleWithAddIcon } from "../ActivityFormComponents";

import useDialog from "hooks/useDialog";
import { deleteLessonCheckpoint } from "api/project-api";
import useSnack from "hooks/useSnack";
import WarningDialog from "../../Dialog/WarningDialog";
import useStore from "store/store";
import DashedButton from "components/Button/DashedButton";

const ProjectCheckpointFundamentalField = ({
  checkpointFundamentals,
  setCheckpointFundamentals,
}: {
  checkpointFundamentals: API_Checkpoint_Fundamental[];
  setCheckpointFundamentals: React.Dispatch<
    React.SetStateAction<API_Checkpoint_Fundamental[]>
  >;
}) => {
  const { fundamentalDict, fundamentalListToDisplay } = useStore((state) => state);
  const handleDevelopmentalGoalChange = (
    event: SelectChangeEvent,
    checkpointFundamentalIndex: number
  ) => {
    const fundamentalId = Number(event.target.value);
    const newCheckpointFundamentals: API_Checkpoint_Fundamental[] = JSON.parse(
      JSON.stringify(checkpointFundamentals)
    );

    const newCheckpoint = newCheckpointFundamentals[
      checkpointFundamentalIndex
    ].checkpoints.map((checkpoint_) => ({
      ...checkpoint_,
      fundamental: fundamentalId,
      milestone:
        fundamentalDict[fundamentalId].milestones.length > 0
          ? Number(fundamentalDict[fundamentalId].milestones[0].id)
          : -1,
    }));

    newCheckpointFundamentals[checkpointFundamentalIndex] = {
      id: fundamentalDict[fundamentalId].id,
      name: fundamentalDict[fundamentalId].name,
      description: fundamentalDict[fundamentalId].description,
      title: fundamentalDict[fundamentalId].title,
      image: fundamentalDict[fundamentalId].image,
      tag: fundamentalDict[fundamentalId].tag,
      checkpoints: newCheckpoint,
    };
    setCheckpointFundamentals(newCheckpointFundamentals);
  };

  const handleMilestoneChange = (
    event: SelectChangeEvent,
    checkpointFundamentalIndex: number
  ) => {
    const milestoneId = Number(event.target.value);
    const newCheckpointFundamentals: API_Checkpoint_Fundamental[] = JSON.parse(
      JSON.stringify(checkpointFundamentals)
    );

    const newCheckpoints = newCheckpointFundamentals[
      checkpointFundamentalIndex
    ].checkpoints.map((checkpoint_) => ({
      ...checkpoint_,
      milestone: milestoneId,
    }));
    newCheckpointFundamentals[checkpointFundamentalIndex].checkpoints =
      newCheckpoints;

    setCheckpointFundamentals(newCheckpointFundamentals);
  };

  const handleCheckbox = (
    checkpointFundamentalIndex: number,
    checkpointIndex: number
  ) => {
    const newCheckpointFundamentals: API_Checkpoint_Fundamental[] = JSON.parse(
      JSON.stringify(checkpointFundamentals)
    );
    newCheckpointFundamentals[checkpointFundamentalIndex].checkpoints[
      checkpointIndex
    ].is_required =
      !newCheckpointFundamentals[checkpointFundamentalIndex].checkpoints[
        checkpointIndex
      ].is_required;
    setCheckpointFundamentals(newCheckpointFundamentals);
  };
  // console.log("checkpointFundamentals: ", checkpointFundamentals);

  const handleAdd = () => {
    const newCheckpointFundamentals: API_Checkpoint_Fundamental[] = JSON.parse(
      JSON.stringify(checkpointFundamentals)
    );

    newCheckpointFundamentals.push({
      id: fundamentalDict[Object.keys(fundamentalDict)[0]].id,
      name: "",
      description: "",
      image: "",
      title: "",
      tag: "",
      checkpoints: [
        {
          id: -1,
          is_required: false,
          name: "",
          description: "",
          fundamental: fundamentalDict[Object.keys(fundamentalDict)[0]].id,
          milestone:
            Number(
              fundamentalDict[Object.keys(fundamentalDict)[0]].milestones[0].id
            ) || -1,
        },
      ],
    });
    setCheckpointFundamentals(newCheckpointFundamentals);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography>{translation.project_checkpoints}</Typography>
      {checkpointFundamentals.map(
        (checkpointFundamental, checkpointFundamentalIndex) => (
          <React.Fragment key={checkpointFundamentalIndex}>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                my: "16px",
              }}
              key={checkpointFundamentalIndex}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "25%",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="multiple-chip-label-developmental-goal-checkpoint">
                    Developmental Goal
                  </InputLabel>

                  <Select
                    labelId="multiple-chip-label-developmental-goal-checkpoint"
                    value={checkpointFundamental.id.toString()}
                    onChange={(event) => {
                      handleDevelopmentalGoalChange(
                        event,
                        checkpointFundamentalIndex
                      );
                    }}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip-developmental-goal-checkpoint"
                        label="Developmental Goal"
                      />
                    }
                    renderValue={(checkpointFundamentalId) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        <Chip
                          avatar={
                            <Avatar
                              alt={
                                fundamentalDict[checkpointFundamentalId].name
                              }
                              src={
                                fundamentalDict[checkpointFundamentalId].image
                              }
                            />
                          }
                          key={checkpointFundamentalId}
                          label={fundamentalDict[checkpointFundamentalId].name}
                        />
                      </Box>
                    )}
                  >
                    {fundamentalListToDisplay.map((fundamental) => {
                      return fundamental.sub_fundamentals &&
                        fundamental.sub_fundamentals.length > 0 ? (
                        <ListSubheader
                          key={`${fundamental.id}-sub-header`}
                          disableGutters
                        >
                          {fundamental.name}
                        </ListSubheader>
                      ) : fundamental.main_fundamental ? (
                        <MenuItem
                          key={`${fundamental.id}-dev-goal`}
                          value={fundamental.id}
                        >
                          {fundamental.name}
                        </MenuItem>
                      ) : (
                        [
                          <ListSubheader
                            key={`${fundamental.id}-sub-header`}
                            disableGutters
                          >
                            {fundamental.name}
                          </ListSubheader>,
                          <MenuItem
                            key={`${fundamental.id}-dev-goal`}
                            value={fundamental.id}
                          >
                            {fundamental.name}
                          </MenuItem>,
                        ]
                      );
                    })}
                  </Select>
                </FormControl>

                {fundamentalDict[checkpointFundamental.id].milestones.length >
                0 ? (
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="multiple-chip-label">
                      {translation.milestone}
                    </InputLabel>

                    <Select
                      labelId="multiple-chip-label"
                      value={
                        checkpointFundamental?.checkpoints[0]?.milestone
                          ? checkpointFundamental?.checkpoints[0]?.milestone?.toString()
                          : "-1"
                      }
                      onChange={(event) => {
                        handleMilestoneChange(
                          event,
                          checkpointFundamentalIndex
                        );
                      }}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip-milestone-checkpoint"
                          label="Developmental Goal"
                        />
                      }
                      renderValue={(selectedMilestoneId) => {
                        const filteredMilestone = fundamentalDict[
                          checkpointFundamental.id
                        ].milestones.filter(
                          (milestone) =>
                            milestone.id.toString() === selectedMilestoneId
                        );
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            <Chip
                              key={selectedMilestoneId}
                              label={
                                filteredMilestone.length > 0
                                  ? filteredMilestone[0].name
                                  : "Please select a milestone"
                              }
                            />
                          </Box>
                        );
                      }}
                    >
                      {fundamentalDict[checkpointFundamental.id].milestones.map(
                        (milestone) => (
                          <MenuItem key={milestone.id} value={milestone.id}>
                            {milestone.name}
                          </MenuItem>
                        )
                      )}
                      <MenuItem disabled value="-1">
                        Please select a milestone
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : null}

                <SubtitleWithAddIcon
                  subtitle={translation.addCheckpoint}
                  fontSize="0.875rem"
                  handleAdd={() => {
                    const newCheckpointFundamentals: API_Checkpoint_Fundamental[] =
                      JSON.parse(JSON.stringify(checkpointFundamentals));

                    newCheckpointFundamentals[
                      checkpointFundamentalIndex
                    ].checkpoints.push({
                      fundamental: checkpointFundamental.id,
                      milestone: -1,
                      id:
                        -1 -
                        newCheckpointFundamentals[checkpointFundamentalIndex]
                          .checkpoints.length,
                      name: "",
                      description: "",
                      is_required: false,
                    });

                    setCheckpointFundamentals(newCheckpointFundamentals);
                  }}
                />
              </Box>

              <Box
                sx={{
                  flex: 1,
                }}
              >
                {checkpointFundamental.checkpoints.map(
                  (checkpoint_, checkpointIndex) => {
                    return (
                      <React.Fragment
                        key={`checkpoint-${checkpoint_.id}-${checkpointIndex}`}
                      >
                        <CheckpointNameDescComponent
                          checkpoint={checkpoint_}
                          checkpointIndex={checkpointIndex}
                          checkpointFundamentals={checkpointFundamentals}
                          setCheckpointFundamentals={setCheckpointFundamentals}
                          checkpointFundamentalIndex={
                            checkpointFundamentalIndex
                          }
                          handleCheckbox={handleCheckbox}
                        />
                      </React.Fragment>
                    );
                  }
                )}
              </Box>
            </Box>

            <Divider />
          </React.Fragment>
        )
      )}
      <DashedButton text="Add Checkpoints" onClick={handleAdd} />
    </Box>
  );
};

const CheckpointNameDescComponent = ({
  checkpoint,
  checkpointIndex,
  checkpointFundamentals,
  setCheckpointFundamentals,
  checkpointFundamentalIndex,
  handleCheckbox,
}: {
  checkpoint: API_Lesson_Checkpoint;
  checkpointIndex: number;
  checkpointFundamentals: API_Checkpoint_Fundamental[];
  setCheckpointFundamentals: React.Dispatch<
    React.SetStateAction<API_Checkpoint_Fundamental[]>
  >;
  checkpointFundamentalIndex: number;
  handleCheckbox: (
    checkpointFundamentalIndex: number,
    checkpointIndex: number
  ) => void;
}) => {
  //warning
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");
  const { currentProject, setCurrentProject } = useStore((state) => state);
  // const handleActivityChange = (event: SelectChangeEvent) => {
  //   const activityId = Number(event.target.value);
  //   const newProject: API_Create_Project = JSON.parse(JSON.stringify(project));
  //   newProject.checkpoint_fundamentals[checkpointFundamentalIndex].checkpoints[
  //     checkpointIndex
  //   ].activity = activityId;
  //   setProject(newProject);
  // };

  const handleDelete = async () => {
    let isSuccessFlag = true;
    if (checkpoint.id && checkpoint.id > 0) {
      const res = await deleteLessonCheckpoint(checkpoint.id);
      if (res !== "success") {
        openSnack(res, false);
        isSuccessFlag = false;
      }
    }
    if (isSuccessFlag) {
      //filter out checkpoint
      const newCheckpointFundamentals: API_Checkpoint_Fundamental[] =
        JSON.parse(JSON.stringify(checkpointFundamentals));

      newCheckpointFundamentals[checkpointFundamentalIndex].checkpoints =
        newCheckpointFundamentals[
          checkpointFundamentalIndex
        ].checkpoints.filter((checkpoint_) => checkpoint_.id !== checkpoint.id);
      //Remove fundamental
      if (
        newCheckpointFundamentals[checkpointFundamentalIndex].checkpoints
          .length === 0
      ) {
        newCheckpointFundamentals.splice(checkpointFundamentalIndex, 1);
      }
      setCheckpointFundamentals(newCheckpointFundamentals);

      // To update curent project that doesn't have other changes
      const updatedProjectCheckpointFundamentals: API_Checkpoint_Fundamental[] =
        JSON.parse(JSON.stringify(currentProject.checkpoint_fundamentals));
      updatedProjectCheckpointFundamentals[
        checkpointFundamentalIndex
      ].checkpoints = updatedProjectCheckpointFundamentals[
        checkpointFundamentalIndex
      ].checkpoints.filter((checkpoint_) => checkpoint_.id !== checkpoint.id);
      //Remove fundamental
      if (
        updatedProjectCheckpointFundamentals[checkpointFundamentalIndex]
          .checkpoints.length === 0
      ) {
        updatedProjectCheckpointFundamentals.splice(
          checkpointFundamentalIndex,
          1
        );
      }

      setCurrentProject({
        ...currentProject,
        checkpoint_fundamentals: updatedProjectCheckpointFundamentals,
      });

      openSnack("Removed checkpoint successfully!", true);
    }
  };

  const [checkpointName, setCheckpointName] = useState<string>(checkpoint.name);
  const [checkpointDescription, setCheckpointDescription] = useState<string>(
    checkpoint.description
  );

  useEffect(() => {
    const inputTimeout = setTimeout(async () => {
      // console.log("Updating description now");
      const newCheckpointFundamentals: API_Checkpoint_Fundamental[] =
        JSON.parse(JSON.stringify(checkpointFundamentals));

      newCheckpointFundamentals[checkpointFundamentalIndex].checkpoints[
        checkpointIndex
      ].name = checkpointName;
      newCheckpointFundamentals[checkpointFundamentalIndex].checkpoints[
        checkpointIndex
      ].description = checkpointDescription;
      setCheckpointFundamentals(newCheckpointFundamentals);
    }, 500);
    return () => clearTimeout(inputTimeout);
  }, [checkpointName, checkpointDescription]);

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! All information related to Checkpoint ${
          checkpointIndex + 1
        } with the name ${checkpoint.name} will be deleted for all languages.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkpointIndex]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Checkpoint?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <Box>
      <WarningDialog {...warningBody} />
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography sx={{ fontSize: "0.875rem" }}>{`Checkpoint ${
          checkpointIndex + 1
        }`}</Typography>
      </Box>

      <Box //Checkpoints
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          mt: "8px",
          mb: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            gap: "12px",
          }}
        >
          <TextField
            label={translation.name}
            required
            fullWidth
            value={checkpointName}
            onChange={(event) => {
              setCheckpointName(event.target.value);
            }}
            key={`checkpoint-name-${checkpointIndex}-${checkpoint.id}`}
          />
          <TextField
            label={translation.description}
            fullWidth
            value={checkpointDescription}
            onChange={(event) => {
              setCheckpointDescription(event.target.value);
            }}
            key={`checkpoint-desc-${checkpointIndex}-${checkpoint.id}`}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              color: "txt.secondary",
              position: "absolute",
              top: "-16px",
            }}
          >
            {translation.required}
          </Typography>
          {checkpoint.is_required ? (
            <CheckBoxIcon
              sx={{
                cursor: "pointer",
                color: "txt.secondary",
              }}
              onClick={() => {
                handleCheckbox(checkpointFundamentalIndex, checkpointIndex);
              }}
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              sx={{
                cursor: "pointer",
                color: "txt.secondary",
              }}
              onClick={() => {
                handleCheckbox(checkpointFundamentalIndex, checkpointIndex);
              }}
            />
          )}
        </Box>

        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={() => {
            if (checkpoint.id && checkpoint.id > 0) {
              handleOpenDialog();
            } else {
              const newCheckpointFundamentals: API_Checkpoint_Fundamental[] =
                JSON.parse(JSON.stringify(checkpointFundamentals));

              newCheckpointFundamentals[
                checkpointFundamentalIndex
              ].checkpoints = newCheckpointFundamentals[
                checkpointFundamentalIndex
              ].checkpoints.filter(
                (checkpoint_) => checkpoint_.id !== checkpoint.id
              );

              if (
                newCheckpointFundamentals[checkpointFundamentalIndex]
                  .checkpoints.length === 0
              ) {
                newCheckpointFundamentals.splice(checkpointFundamentalIndex, 1);
              }
              setCheckpointFundamentals(newCheckpointFundamentals);
            }
          }}
        />
      </Box>
    </Box>
  );
};
export default ProjectCheckpointFundamentalField;

import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, IconButton, Switch } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const SubtitleWithAddIcon = ({
  subtitle,
  fontSize,
  handleAdd,
  disabled = false,
}: {
  subtitle: string;
  fontSize?: string;
  handleAdd: () => void;
  disabled?: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Typography sx={{ fontSize }}>{subtitle}</Typography>

      <AddIcon
        sx={{
          backgroundColor: "primary.light",
          borderRadius: "4px",
          p: "2px",
          cursor: disabled ? "default" : "pointer",
          color: "txt.secondary",
          transition: "all 100ms linear",
          "&:hover": {
            backgroundColor: disabled ? "default" : "primary.main",
            transition: "all 100ms linear",
          },
          opacity: disabled ? 0.5 : 1,
        }}
        onClick={disabled ? () => {} : handleAdd}
      />
    </Box>
  );
};

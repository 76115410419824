import { Avatar, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import useStore from "store/store";
import {
  ChangePassword,
  ChangePasswordError,
  ChangePasswordResponse,
} from "types/auth";
import Button from "components/Button/Button";
import { changePassword } from "api/auth-api";
import { useNavigate } from "react-router-dom";
import { CURLANG, WELCOME_BACK_PAGE } from "constants/url";
import useSnack from "hooks/useSnack";
import Logo from "images/logo-2-colour.png";

const ChangePasswordPage = () => {
  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));

  return (
    <Stack
      component="form"
      sx={{ width: "80vw", margin: "auto", alignItems: "center", my: "16px" }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
      <Name displayName={profileDetails.full_name} />
      <ProfileImage
        image={profileDetails.image}
        imageFallbackChar={
          profileDetails.preferred_name
            ? profileDetails.preferred_name.charAt(0).toUpperCase()
            : ""
        }
      />
      <PasswordForm profileID={profileDetails.id.toString()} />
    </Stack>
  );
};

const Name = ({ displayName }: { displayName: string }) => {
  return (
    <Typography
      sx={{
        fontSize: "2rem",
        fontWeight: "500",
        textAlign: "center",
        my: "16px",
      }}
    >
      {displayName}
    </Typography>
  );
};

const ProfileImage = ({
  image,
  imageFallbackChar,
}: {
  image: string;
  imageFallbackChar: string;
}) => {
  return (
    <Box sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}>
      <Avatar
        src={image}
        sx={{
          width: "20vw",
          height: "20vw",
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "10vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {imageFallbackChar}
      </Avatar>
    </Box>
  );
};

const PasswordForm = ({ profileID }: { profileID: string }) => {
  const navigate = useNavigate();
  const openSnack = useSnack();

  const [passwordData, setPasswordData] = useState<ChangePassword>({
    password: "",
    password2: "",
    old_password: "",
  });

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const handleChange = async () => {
    // console.log(passwordData)
    const response: ChangePasswordResponse | ChangePasswordError =
      await changePassword(
        profileID,
        passwordData,
        localStorage.getItem("access")
      );

    if ("error" in response) {
      console.log(response.error);
      if ("old_password" in response.error[0]) {
        openSnack(response.error[0].old_password!.join(" "), false);
      } else if ("password" in response.error[0]) {
        openSnack(response.error[0].password!.join(" "), false);
      } else {
        openSnack(response.error[0].non_field_errors!.join(" "), false);
      }
    } else {
      openSnack("Changed password successfully", true);
      navigate(`${CURLANG(currLanguage)}/${WELCOME_BACK_PAGE}`);
    }
  };

  return (
    <Stack
      component="form"
      sx={{ width: "80vw", margin: "auto" }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        label="Old Password"
        type="password"
        onChange={(event) => {
          setPasswordData((prev) => ({
            ...prev,
            old_password: event.target.value,
          }));
        }}
      />
      <TextField
        required
        label="New Password"
        type="password"
        onChange={(event) => {
          setPasswordData((prev) => ({
            ...prev,
            password: event.target.value,
          }));
        }}
      />
      <TextField
        required
        label="Retype Password"
        type="password"
        onChange={(event) => {
          setPasswordData((prev) => ({
            ...prev,
            password2: event.target.value,
          }));
        }}
      />
      <Button
        buttonText="Change Password"
        arrow={false}
        disabled={
          passwordData.password === "" ||
          passwordData.password2 === "" ||
          passwordData.old_password === ""
        }
        onClick={handleChange}
      />
    </Stack>
  );
};

export default ChangePasswordPage;

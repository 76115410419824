import React from "react";

import useStore from "store/store";
import SideTabView from "./SideTabView";

type Props = {
  title: string;
  icon: React.ReactElement;
  type: "module" | "devGoal"
  slug: string;
}

const SideTabs = ({
  type,
  title,
  slug,
  icon,
}: Props) => {
  const {
    isAssessmentModuleSelected,
    isAssessmentDevGoalSelected,
    assessmentDevGoalsFilters,
    setAssessmentDevGoalsFilters,
  } = useStore((state) => ({
    ...state,
    isAssessmentModuleSelected: state.assessmentModuleFilters.includes(slug),
    isAssessmentDevGoalSelected: state.assessmentDevGoalsFilters.includes(slug),
  }));

  const handleSelected = () => {
    switch (type) {
      case "devGoal":
        if (isAssessmentDevGoalSelected) { 
          const newAssessmentDevGoalFilters = [...assessmentDevGoalsFilters];
          const existingElementIndex = newAssessmentDevGoalFilters.findIndex((elem) => elem === slug);
          newAssessmentDevGoalFilters.splice(existingElementIndex, 1);
          setAssessmentDevGoalsFilters(newAssessmentDevGoalFilters);
        } else {
          setAssessmentDevGoalsFilters([...assessmentDevGoalsFilters, slug]);
        }
        break;

      default:
        break;
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <SideTabView
      isSelected={isAssessmentDevGoalSelected || isAssessmentModuleSelected}
      title={title}
      icon={icon}
      handleSelected={handleSelected}
    />
  );
};

export default SideTabs;

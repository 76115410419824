import React, { useEffect, RefObject } from "react";

const useScale = (
  ref: RefObject<HTMLDivElement>,
  setScale: React.Dispatch<React.SetStateAction<number>>,
  isChildrenPortfolio = false
) => {
  useEffect(() => {
    const getScale = () => {
      if (ref.current) {
        setScale(ref.current.clientWidth / (isChildrenPortfolio ? 595 : 1440));
      }
    };

    window.addEventListener("resize", getScale);

    setTimeout(() => window.dispatchEvent(new Event("resize")), 0);

    return () => window.removeEventListener("resize", getScale);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default useScale;

import { ReactNode, useState, useEffect } from "react";
import { Answer, CustomModal } from "./Components";
import Introduction from "./pages/Introduction";
import ChildInterests from "./pages/ChildInterests";
import ChildGoals from "./pages/ChildGoals";
import ChildCommunicationMethod from "./pages/ChildCommunicationMethod";
import ConcludingPage from "./pages/ConcludingPage";

import { API_Fundamental, Age_Group } from "types/project";
import { getAllFundamentals } from "api/fundamental-api";
import useStore from "store/store";
import { getInterestList } from "api/prediscoveryquestionnaire-api";
import { API_Interest } from "types/prediscoveryquestionnaire";
import { getGroupQuickQuestionInfoById } from "api/profile-api";

const QuickQuestionsModal = () => {
  const { currLanguage, currGroup } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currGroup: state.currGroup,
  }));

  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [levelId, setLevelId] = useState<number>(-1);

  const [answer, setAnswer] = useState<Answer>({
    interests: [],
    goals: [],
    level: "",
  });

  useEffect(() => {
    if (currGroup.id === -1) return;

    const populateData = async () => {
      const currGroupRes = await getGroupQuickQuestionInfoById(currGroup.id);
      // console.log("currGroupRes: ", currGroupRes);
      if (typeof currGroupRes !== "string") {
        const interests: number[] =
          currGroupRes.interest && currGroupRes.interest.length > 0
            ? currGroupRes.interest.map((interest) => interest.id)
            : [];

        const goals: number[] =
          currGroupRes.fundamental_target &&
          currGroupRes.fundamental_target.length > 0
            ? currGroupRes.fundamental_target.map((goal) => goal.id)
            : [];

        const level: Age_Group | string =
          currGroupRes.communication_lvl !== null
            ? currGroupRes.communication_lvl
            : "";

        const pageNumber: number =
          interests.length !== 0 && goals.length !== 0 && level !== ""
            ? 5
            : interests.length === 0
            ? 1
            : goals.length === 0
            ? 3
            : 4; // level === ""

        setCurrentPage(pageNumber);
        setLevelId(-1);
        setAnswer({
          interests,
          goals,
          level,
        });
        setOpen(pageNumber !== 5);
      }
    };

    populateData();
  }, [currGroup.id]);

  const [interestList, setInterestList] = useState<API_Interest[]>([]);
  const [fundamentalList, setFundamentalList] = useState<API_Fundamental[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (isDataLoaded) return;

    const populateData = async () => {
      const interestList = await getInterestList();
      setInterestList(interestList);

      const fundamentalList = await getAllFundamentals(currLanguage);
      setFundamentalList(fundamentalList);

      setIsDataLoaded(true);
    };

    populateData();
  }, [currLanguage, isDataLoaded]);

  const Pages: ReactNode[] = [
    <Introduction pageNumber={1} setCurrentPage={setCurrentPage} />,
    <ChildInterests
      pageNumber={2}
      setCurrentPage={setCurrentPage}
      answer={answer}
      setAnswer={setAnswer}
      interestList={interestList}
    />,
    <ChildGoals
      pageNumber={3}
      setCurrentPage={setCurrentPage}
      answer={answer}
      setAnswer={setAnswer}
      fundamentalList={fundamentalList}
    />,
    <ChildCommunicationMethod
      pageNumber={4}
      setCurrentPage={setCurrentPage}
      answer={answer}
      setAnswer={setAnswer}
      levelId={levelId}
      setLevelId={setLevelId}
    />,
    <ConcludingPage answer={answer} setOpen={setOpen} />,
  ];

  return isDataLoaded ? (
    <CustomModal
      open={open}
      isQuestion={currentPage === 2 || currentPage === 3}
    >
      {Pages[currentPage - 1]}
    </CustomModal>
  ) : null;
};

export default QuickQuestionsModal;

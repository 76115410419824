import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { translation } from "constants/translation";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import useStore from "store/store";
import UploadCompletedWorkModal from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/UploadCompletedWorkModal";
import { getCompletedWorkList } from "api/completed-works-api";
import { API_Child } from "types/portfolio";
import AddCompletedWorkButton from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/AddCompletedWorkButton";
import moment from "moment";
import CompletedWorkCard from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkCard";
import CompletedWorkPreviewModal from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkPreviewModal";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button/Button";
import { DATE_FORMAT } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";

const UploadCompletedWorkPromptModal = ({
  open,
  setOpen,
  isOpenUploadLearningMomentPrompt,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenUploadLearningMomentPrompt: boolean;
}) => {
  const { projectSlug } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    currentProject,
    currGroup,
    currLanguage,
    isRefreshCompletedWork,
    setIsRefreshCompletedWork,
    completedWorkList,
    setCompletedWorkList,
  } = useStore((state) => ({
    currentProject: state.currentProject,
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
    isRefreshCompletedWork: state.isRefreshCompletedWork,
    setIsRefreshCompletedWork: state.setIsRefreshCompletedWork,
    completedWorkList: state.completedWorkList,
    setCompletedWorkList: state.setCompletedWorkList,
  }));

  const handleClose = () => setOpen(false);

  const populateCompletedWorks = async () => {
    const res = await getCompletedWorkList({
      language: currLanguage,
      groupId: currGroup.id,
      projectId: currentProject.id,
    });

    if (typeof res !== "string") {
      setCompletedWorkList(res);
    }
    setIsRefreshCompletedWork(false);
  };

  useEffect(() => {
    isRefreshCompletedWork && populateCompletedWorks();
  }, [isRefreshCompletedWork]);

  useEffect(() => {
    populateCompletedWorks();
  }, [currGroup.id]);

  // Preview
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [selectedCompletedWork, setSelectedCompletedWork] =
    useState<API_Child.CompletedWork>();

  const handlePreviewOpen = (completedWork: API_Child.CompletedWork) => {
    setIsPreviewOpen(true);
    setSelectedCompletedWork(completedWork);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
    setSelectedCompletedWork(undefined);
  };

  return (
    <>
      {open && selectedCompletedWork && (
        <CompletedWorkPreviewModal
          open={isPreviewOpen}
          completedWork={selectedCompletedWork}
          setCompletedWork={setSelectedCompletedWork}
          handleClose={handlePreviewClose}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disablePortal={document.fullscreenElement ? true : false}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "65vw",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              p: 4,
              borderRadius: "10px",
            }}
          >
            <Typography sx={{ fontWeight: 500, textAlign: "center", mb: 1 }}>
              {translation.uploadChildrenCompletedWork}
            </Typography>
            {completedWorkList.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "left",
                  overflow: "auto",
                }}
              >
                <AddCompletedWorkButton />
                {completedWorkList.map((completedWork) => {
                  return (
                    <Box
                      key={`completed-work${completedWork.id}`}
                      sx={{
                        minWidth: 200,
                        minHeight: 200,
                      }}
                    >
                      <CompletedWorkCard
                        key={`completed-work-${completedWork.id}`}
                        completedWork={completedWork}
                        fromNow={moment(completedWork.completed_at).format(
                          DATE_FORMAT
                        )}
                        onClick={() => {
                          handlePreviewOpen(completedWork);
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <>
                <label
                  style={
                    {
                      //   order: 1,
                    }
                  }
                  htmlFor={`${currentProject.id}-completed-work`}
                >
                  <Box
                    sx={{
                      p: 4,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      backgroundColor: "#F8F8F8",
                      borderRadius: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      "&:hover": {
                        // color: "primary.main",
                        borderColor: "primary.main",
                        backgroundColor: "rgba(209, 228, 227, 0.37)",
                        transition: "all 100ms ease-in-out",
                      },
                    }}
                  >
                    <AddAPhotoIcon
                      sx={{
                        color: theme.palette.custom.yellow,
                        width: 90,
                        height: 90,
                        marginLeft: "-8px",
                      }}
                    />
                    <Typography sx={{ fontSize: 20 }}>
                      {translation.addCompletedWork}
                    </Typography>
                  </Box>
                </label>
                <UploadCompletedWorkModal project={currentProject} />
              </>
            )}
            {/* Done button */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                zIndex: 10,
                mt: 2,
              }}
            >
              <Box />
              <Button
                style={{
                  width: "33%",
                  backgroundColor: "default",
                }}
                buttonText={translation.done || "Done"}
                arrow={false}
                onClick={() => {
                  if (!isOpenUploadLearningMomentPrompt) {
                    navigate(
                      `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
                        projectSlug!
                      )}/end`
                    );
                  } else {
                    handleClose();
                  }
                }}
                btnType={"filled"}
              />

              <Box />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default UploadCompletedWorkPromptModal;

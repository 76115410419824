import { useNavigate, useLocation } from "react-router-dom";

import useStore from "store/store";
import { Box, Typography } from "@mui/material";

import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { translation } from "constants/translation";
import CreateLessonForm from "components/ProjectForms/CreateLessonForm";

interface Props {
  teacher?: boolean;
  editing?: boolean;
}

const CreateLessonPage = ({ teacher = false }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const editing = pathname.split("/").at(-1) === "edit";

  const { currLanguage, currentProject } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currentProject: state.currentProject,
  }));

  const handleCloseNavigate = () => {
    navigate(
      `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
        currentProject.slug
      )}/lesson-plans`
    );
  };

  return (
    <Box sx={{ p: "30px 50px" }}>
      <Title editing={editing} teacher={teacher} />
      <CreateLessonForm
        assessment={currentProject.assessment}
        projectID={currentProject.id.toString()}
        handleClose={handleCloseNavigate}
      />
    </Box>
  );
};

const Title = ({ teacher = false, editing = false }: Props) => {
  return (
    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
        mb: "20px",
      }}
    >
      {translation.lessons_for_children}
      <br />
      {editing ? translation.edit_lessons : translation.create_lessons}
    </Typography>
  );
};

export default CreateLessonPage;

import { Box } from "@mui/material";
import { Navigate, useSearchParams } from "react-router-dom";

import useStore from "store/store";
import LoginForm from "components/AuthForm/LoginForm";

const LoginPage = () => {
  const { profileID } = useStore((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();

  return !profileID ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "auto",
        flex: 1,
      }}
    >
      <LoginForm />
    </Box>
  ) : (
    <Navigate
      to={searchParams.get("next") !== null ? searchParams.get("next")! : "/"}
    />
  );
};

export default LoginPage;

import { useEffect } from "react";

import { useParams } from "react-router-dom";
import {
  getLessonDiscussionPostList,
} from "../api/teacher-api";

import useStore from "../store/store";
import useSnack from "./useSnack";

const useInitialiseTeacherLessonDiscussionBoard = () => {
  const openSnack = useSnack();
  const { lessonSlug } = useParams();

  const {
    setLessonDiscussionPostList,
    isRefreshLessonDiscussionPost,
    setIsRefreshLessonDiscussionPost,
    setIsLessonDiscussionPostLoading,
    currLanguage,
  } = useStore((state) => ({
    setLessonDiscussionPostList: state.setLessonDiscussionPostList,
    isRefreshLessonDiscussionPost: state.isRefreshLessonDiscussionPost,
    setIsRefreshLessonDiscussionPost: state.setIsRefreshLessonDiscussionPost,
    setIsLessonDiscussionPostLoading: state.setIsLessonDiscussionPostLoading,
    currLanguage: state.currLanguage,
  }));

  const initialiseLessonDiscussionPostList = async (isShowLoading: boolean) => {
    if (isShowLoading) {
      setIsLessonDiscussionPostLoading(true);
    }
    const res = await getLessonDiscussionPostList(
      lessonSlug!,
      currLanguage,
      localStorage.getItem("access")
    );
    if (typeof res !== "string") {
      setLessonDiscussionPostList(res);
    } else {
      openSnack(res, false);
    }
    setIsLessonDiscussionPostLoading(false);
    setIsRefreshLessonDiscussionPost(false);
  };

  useEffect(() => {
    initialiseLessonDiscussionPostList(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonSlug]);

  useEffect(() => {
    isRefreshLessonDiscussionPost && initialiseLessonDiscussionPostList(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshLessonDiscussionPost]);
};

export default useInitialiseTeacherLessonDiscussionBoard;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import useStore from "store/store";
import DetailCard from "components/DetailCard/DetailCard";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { getFilteredAssessmentProjectsList } from "api/project-api";
import { API_Project } from "types/project";
import ProjectModal from "components/ProjectModal/ProjectModal";
import { translation } from "constants/translation";

type Props = {
  query: string | null;
};

const Assessments = ({ query }: Props) => {
  const { languageSlug } = useParams();
  const {
    assessments,
    setAssessments,
    currGroup,
    isCurrGroupLoaded,
    devGoalsFilters,
    currLanguage,
  } = useStore((state) => ({
    ...state,
    assessments: state.assessmentModules,
    setAssessments: state.setAssessmentModules,
    devGoalsFilters: state.assessmentDevGoalsFilters,
  }));

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<number>(-1);
  const [filteredAssessments, setFilteredAssessments] = useState<
    API_Project[] | undefined
  >(undefined);

  const updateData = async () => {
    setIsLoaded(false);
    const assessments: API_Project[] = await getFilteredAssessmentProjectsList({
      language: currLanguage,
      query: query !== null ? query : "",
      accessToken: localStorage.getItem("access"),
      currGroupId: currGroup.id ? currGroup.id : -1,
    });

    assessments.sort((a: API_Project, b: API_Project) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      const numbersA = a?.name?.match(/\d+/);
      const numbersB = b?.name?.match(/\d+/);

      if (numbersA?.length && numbersB?.length) {
        return parseInt(numbersA[0]) - parseInt(numbersB[0]);
      }

      return nameA.localeCompare(nameB);
    });

    setAssessments(assessments);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (query === null) return;

    const searchTimeout = setTimeout(async () => {
      if (query !== "") {
        setIsLoaded(false);
      }
      updateData();
    }, 1200);
    return () => clearTimeout(searchTimeout);
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (!isCurrGroupLoaded) return;
    updateData();
    // eslint-disable-next-line
  }, [languageSlug, currGroup]);

  useEffect(() => {
    if (!isCurrGroupLoaded) return;

    let filtered: API_Project[] = [];
    if (devGoalsFilters && devGoalsFilters.length > 0) {
      filtered = assessments.filter((assessment) => {
        const fundamentals = assessment.objective_fundamentals.map(
          (fundamental) => fundamental.slug
        );
        return devGoalsFilters.some((devGoal) =>
          fundamentals.includes(devGoal)
        );
      });
    }

    if (devGoalsFilters && devGoalsFilters.length > 0) {
      setFilteredAssessments(filtered);
    } else {
      setFilteredAssessments(undefined);
    }
    // eslint-disable-next-line
  }, [devGoalsFilters]);

  // Group projects by category
  const groupedProjects: { [category: string]: API_Project[] } = (
    filteredAssessments ?? assessments
  ).reduce(
    (grouped, proj) => {
      const category = proj.module.name; // Using 'name' property of module
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(proj);
      return grouped;
    },
    {} as { [category: string]: API_Project[] } // Add this type assertion
  );

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      sx={{
        overflowY: "auto",
        overflowX: "clip",
        maxHeight: "calc(100vh - 64px)",
        p: 3,
        minWidth: { xs: "200px", lg: "900px" },
      }}
      maxWidth={false}
    >
      {isLoaded ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 3,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.5rem",
              textAlign: "center",
            }}
          >
            {translation.littleEvaluator}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: 1.5,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-line-clamp": 2, // Limit to 2 lines
              "-webkit-box-orient": "vertical",
            }}
          >
            "Little Evaluator" is a fun and play-based set of activities
            designed to assess the development of children from 18 months to 6
            years old. These easy-to-use tasks blend into group play, allowing
            teachers to discern where each child excels or may require
            additional support across the 'heart', 'head', and 'hands'
            developmental domains, without the children feeling tested. Ideal
            for use at any moment, "Little Evaluator" not only provides
            essential insights into each child's developmental journey but also
            informs Little Lab to recommend the most suitable curriculum
            tailored to their individual needs.
          </Typography>
          {isMobileView &&
            Object.entries(groupedProjects).map(
              ([category, categoryProjects]) => (
                <div key={category}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    {category}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      overflowY: "auto",
                      gap: "1rem",
                      padding: "1rem",
                      marginBottom: "2rem",
                      "-ms-overflow-style": "none", // Hide scrollbar in IE and Edge
                      "&::-webkit-scrollbar": {
                        width: "0px", // Hide scrollbar in Chrome and Safari
                        height: "0px",
                      },
                    }}
                  >
                    {categoryProjects.map((proj) => (
                      <DetailCard
                        project={proj}
                        key={proj.id}
                        onClick={() => {
                          setOpen(true);
                          setProjectId(proj.id);
                        }}
                      />
                    ))}
                  </Box>
                </div>
              )
            )}

          {!isMobileView &&
            ((filteredAssessments ?? assessments).length !== 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: 3,
                }}
              >
                {(filteredAssessments ?? assessments).map((assessment) => (
                  <DetailCard
                    project={assessment}
                    key={assessment.id}
                    onClick={() => {
                      setOpen(true);
                      setProjectId(assessment.id);
                    }}
                  />
                ))}
              </Box>
            ) : (
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  textAlign: "center",
                }}
              >
                No Assessments found.
              </Typography>
            ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <LoadingIndicator />
        </Box>
      )}

      {open && projectId !== -1 && (
        <ProjectModal
          open={open}
          setOpen={setOpen}
          projectId={projectId}
          setProjectId={setProjectId}
        />
      )}
    </Container>
  );
};

export default Assessments;

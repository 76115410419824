import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import useStore from "store/store";
import ReviewModal from "components/Review/ReviewModal";
import { translation } from "constants/translation";
import { API_Review_Image } from "types/review";
import Masonry from "@mui/lab/Masonry/Masonry";
import WhatDoPeopleLikeCard from "components/Review/WhatDoPeopleLikeCard";
import { useParams } from "react-router-dom";
import { getProjectReviewList } from "api/review-api";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useColors from "hooks/useColors";
import Actions from './Actions';

const WhatDoPeopleLikeScreen = () => {
  const { projectSlug } = useParams();

  const {
    isRefreshReviewList,
    setIsRefreshReviewList,
    currentProjectReviewList,
    setCurrentProjectReviewList,
    currentProject,
    setCurrentProject,
    currLanguage,
  } = useStore((state) => ({
    isRefreshReviewList: state.isRefreshReviewList,
    setIsRefreshReviewList: state.setIsRefreshReviewList,
    currentProjectReviewList: state.currentProjectReviewList,
    setCurrentProjectReviewList: state.setCurrentProjectReviewList,
    currentProject: state.currentProject,
    setCurrentProject: state.setCurrentProject,
    profileID: state.profileID,
    currLanguage: state.currLanguage,
  }));

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [oldImages, setOldImages] = useState<API_Review_Image[]>([]);
  const [deleteImages, setDeleteImages] = useState<boolean[]>([]);
  const [isEditReview, setIsEditReview] = useState<boolean>(false);
  const [reviewId, setReviewId] = useState<number>(-1);
  const [isMostLike, setIsMostLike] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const populateReviewList = async (isRefreshScreen = true) => {
    isRefreshScreen && setIsLoading(true);
    const res = await getProjectReviewList(
      projectSlug!,
      isMostLike,
      localStorage.getItem("access"),
      currLanguage
    );

    if (typeof res !== "string") {
      setCurrentProjectReviewList(res);
      setCurrentProject({ ...currentProject, reviews: res });
    }
    setIsLoading(false);
    setIsRefreshReviewList(false);
  };

  useEffect(() => {
    projectSlug && populateReviewList();
  }, [projectSlug, isMostLike]);

  useEffect(() => {
    isRefreshReviewList && populateReviewList(false);
  }, [isRefreshReviewList]);

  const handleEditReview = ({
    rating,
    comment,
    reviewId,
    images,
  }: {
    rating: number;
    comment: string;
    reviewId: number;
    images: API_Review_Image[];
  }) => {
    setIsEditReview(true);
    setRating(rating);
    setComment(comment);
    setReviewId(reviewId);
    setOldImages(images);
    setImages([]);
    setDeleteImages([]);
    setOpenModal(true);
  };

  const colorList = useColors();

  return (
    <Box sx={{ p: "30px", pt: {xs: "16px", md: "30px"} }}>
      <ReviewModal
        open={openModal}
        setOpen={setOpenModal}
        rating={rating}
        setRating={setRating}
        comment={comment}
        setComment={setComment}
        images={images}
        setImages={setImages}
        oldImages={oldImages}
        deleteImages={deleteImages}
        setDeleteImages={setDeleteImages}
        isEdit={isEditReview}
        reviewId={reviewId}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          mb: 1.5,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "18px", sm: "24px" },
            fontWeight: 500,
          }}
        >
          {translation.whatPeopleLike}
        </Typography>
        <Actions isMostLike={isMostLike} setIsMostLike={setIsMostLike} />
      </Box>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Masonry
          columns={{ xs: 1, s: 2, lg: 3 }}
          spacing={2}
          sx={{ width: "100%" }}
          defaultSpacing={2}
        >
          {currentProjectReviewList.length !== 0 ? (
            currentProjectReviewList.map((review, index) => (
              <Box key={`review-${review.id}`}>
                <WhatDoPeopleLikeCard
                  review={review}
                  handleEditReview={handleEditReview}
                  cardBackgroundColor={colorList[index % colorList.length]}
                />
              </Box>
            ))
          ) : (
            <Typography>{translation.noContent}</Typography>
          )}
        </Masonry>
      )}
    </Box>
  );
};

export default WhatDoPeopleLikeScreen;

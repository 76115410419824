import React, { useEffect, useState } from "react";
import useStore from "store/store";
import { pdf } from "@react-pdf/renderer";

import { Box, Chip, Typography, IconButton } from "@mui/material";
import {
  cardStyles,
  reportStyles,
} from "../PhotoAlbumScreenComponents/PhotoAlbumComponents";
import { pdfjs } from "react-pdf";
import { Child_Progress_Report } from "types/project";
import { slugify } from "helper/slugify";
import { PDFPreviewComponent } from "../CompletedWorkComponents/CompletedWorkCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CHILDREN_PORTFOLIO_PAGE, CURLANG } from "constants/url";
import { useNavigate, useParams } from "react-router-dom";
import { PROGRESS_REPORT } from "containers/ChildrenPortfolioPage/constants";
import DraftChildrenPortfolioPDF from "components/ChildrenPortfolioPDF/DraftChildrenPortfolioPDF";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface MonthAbbreviation {
  [key: string]: string;
}

const monthAbbreviations: MonthAbbreviation = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December",
};

function getFullMonthName(abbrMonth: string): string {
  // Fallback to the original abbreviation if no match is found
  return monthAbbreviations[abbrMonth] || abbrMonth;
}

const ChildProgressReportCard = ({
  childProgressReport,
  isChildPortfolio = false,
  isTeacherArchive = false,
  handleOpenDialog,
  setDeleteProgressReport,
}: {
  childProgressReport: Child_Progress_Report;
  isChildPortfolio?: boolean;
  isTeacherArchive?: boolean;
  handleOpenDialog: () => void;
  setDeleteProgressReport?: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { currLanguage, groupList, developmentalGoals } = useStore(
    (state) => state
  );
  const { groupSlug, childID } = useParams();
  const [draftFile, setDraftFile] = useState<any>(null);

  const [time, setTime] = useState<string>("");
  const generateDraftPreview = async () => {
    const PDFObj = JSON.parse(childProgressReport.PDFInfo);
    const blob = await pdf(
      <DraftChildrenPortfolioPDF
        PDFInfo={PDFObj}
        currentLanguage={currLanguage}
      />
    ).toBlob();
    if (blob) {
      const blobUrl = URL.createObjectURL(blob);
      setDraftFile(blobUrl);
    }
  };
  useEffect(() => {
    if (!childProgressReport.file) {
      generateDraftPreview();
    }
  }, []);
  useEffect(() => {
    const specificDate = new Date(childProgressReport.created_at);

    const currentDate = new Date();

    const diffMillis = specificDate.getTime() - currentDate.getTime();
    const diffMinutes = Math.floor(diffMillis / 1000 / 60);
    let timeAgoMessage = "";

    if (-diffMinutes < 60) {
      if (-diffMinutes === 1) {
        timeAgoMessage = `${-diffMinutes} min ago`;
      } else {
        timeAgoMessage = `${-diffMinutes} mins ago`;
      }
    } else {
      const diffHours = Math.floor(diffMillis / 1000 / 60 / 60);
      if (-diffHours < 24) {
        if (-diffHours === 1) {
          timeAgoMessage = `${-diffHours} hour ago`;
        } else {
          timeAgoMessage = `${-diffHours} hours ago`;
        }
      } else {
        const diffDays = Math.floor(diffMillis / (1000 * 60 * 60 * 24));
        if (-diffDays === 1) {
          timeAgoMessage = `${-diffDays} day ago`;
        } else {
          timeAgoMessage = `${-diffDays} days ago`;
        }
      }
    }
    setTime(timeAgoMessage);
  }, []);

  return (
    <Box
      sx={{
        ...cardStyles,
        width: {
          xs: "35vw",
          lg: "250px",
        },
        height: {
          xs: "35vw",
          lg: "250px",
        },
      }}
      onClick={() => {
        if (!childProgressReport.file) {
          navigate(
            `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
              groupSlug!,
              childID!
            )}/${slugify(PROGRESS_REPORT)}/create/${childProgressReport.id}`
          );
        } else {
          window.open(
            childProgressReport.file,
            "_blank",
            "noopener,noreferrer"
          );
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <IconButton
          onClick={(event: any) => {
            event.stopPropagation();
            console.log(childProgressReport);
            if (setDeleteProgressReport) {
              setDeleteProgressReport(childProgressReport);
            }
            handleOpenDialog();
          }}
          style={{ position: "absolute", top: 10, right: 10 }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
      <Chip
        label={childProgressReport.file ? "Published" : "Saved Draft"}
        sx={{
          height: "20px",
          borderColor: "#79B5B4",
          backgroundColor: childProgressReport.file ? "#D8F2EE" : "#FFECCF",
          border: childProgressReport.file
            ? "1px solid #D8F2EE"
            : "1px solid #FFECCF",
          position: "absolute",
          top: 10,
          left: 10,
        }}
        variant="outlined"
      />
      {childProgressReport.file && (
        <PDFPreviewComponent file={childProgressReport.file} />
      )}

      {draftFile && <PDFPreviewComponent file={draftFile} />}

      <Box
        sx={{
          ...reportStyles,
          bottom: 0,
          width: "100%",
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 0.75,
        }}
      >
        {(childProgressReport.name && childProgressReport.name === "Default") ||
        childProgressReport.name === "" ? (
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 450,
              color: "transparent", // Makes the text transparent
              height: "16px", // Adjust based on your typography settings
              width: "100%", // Adjust based on your typography settings
            }}
            noWrap
          >
            &nbsp; {/* Non-breaking space to reserve space */}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: 1,
              fontWeight: 450,
              color: "#323031",
            }}
            noWrap
          >
            {childProgressReport.name}
          </Typography>
        )}
        {childProgressReport.start_month && childProgressReport.end_month && (
          <Typography
            sx={{
              fontSize: 10,
              lineHeight: 1,
              fontWeight: 400,
              color: "#8C8C8C",
            }}
            noWrap
          >
            {`${getFullMonthName(
              childProgressReport.start_month.split(" ")[0]
            )} ${
              childProgressReport.start_month.split(" ")[1]
            } - ${getFullMonthName(
              childProgressReport.end_month.split(" ")[0]
            )} ${childProgressReport.end_month.split(" ")[1]}`}
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {isTeacherArchive ? (
            <Typography
              sx={{
                fontSize: "10px",
                lineHeight: 1,
                fontWeight: 700,
                color: "#312F30",
              }}
              noWrap
            >
              {`${childProgressReport.child.preferred_name} in group: ${childProgressReport.group.name}`}
            </Typography>
          ) : (
            childProgressReport.created_by && (
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: 1,
                  fontWeight: 400,
                  color: "#8C8C8C",
                }}
                noWrap
              >
                {`By: ${childProgressReport.created_by}`}
              </Typography>
            )
          )}
          <Typography
            sx={{
              fontSize: 10,
              lineHeight: 1,
              fontWeight: 400,
              color: "#8C8C8C",
            }}
            noWrap
          >
            {childProgressReport.PDFInfo ? "Edited" : "Created"} {time}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ChildProgressReportCard;


import useStore from "store/store";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import {
  CURLANG,
  HOME,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";

import { TITLE } from "constants/title";
import LogoImage from "images/logo.png";
import TTALogoImage from "images/tta-logo.png";
import LittleLabTeachingPlatformImage from "images/little-lab-teaching-platform-logo.png";
import Image from "components/Image/Image";

const DesktopLogo = ({
  width,
  isTTA,
}: {
  width: number | undefined;
  isTTA: boolean;
}) => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => state);

  return (
    <Box
      sx={{
        display: { xs: "none", lg: "flex" },
        gap: 0.5,
        alignItems: "center",
        maxWidth: 300,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          document.title = TITLE;
          navigate(
            isTTA
              ? `${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`
              : `${CURLANG(currLanguage)}/${HOME}`
          );
        }}
      >
        <Image
          src={isTTA ? TTALogoImage : LittleLabTeachingPlatformImage}
          // variant="square"
          style={{
            display: "flex",
            height: 44,
            transform: isTTA ? "scale(1.75)" : "scale(1)",
            paddingLeft: isTTA ? 28 : 0,
            paddingRight: isTTA ? 32 : 0,
          }}
          alt={LogoImage}
        />
      </Box>
    </Box>
  );
};

export default DesktopLogo;

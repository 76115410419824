import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { createSchedule, updateScheduleDatetime } from "api/calendar-api";
import { populateScheduleObject } from "helper/project";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Button from "components/Button/Button";

import { translation } from "constants/translation";
import { ASSESSMENT_PAGE, CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import Logo from "images/logo-2-colour.png";

import { API_Create_Schedule, ProjectBoxProps } from "types/calendar";

import { DATETIME_FORMAT } from "./constants/constants";
import { getProjectBoxObjects } from "./constants/helper"
import DeleteScheduleObjectComponent from "./CalendarSection/CalendarBodyComponents/RightColumnComponents/DeleteScheduleObjectComponent";
import NavigateProjectComponent from "./CalendarSection/CalendarBodyComponents/RightColumnComponents/NavigateProjectComponent";

const ScheduleGroupPageSimple = ({
  assessment,
  projectId,
  projectTitle,
  groupId,
  scheduleId,
  scheduleDatetimeId,
  handleClose,
  preDefinedStartAt,
  preDefinedEndAt,
  handleOpenDialog,
  projectSlug,
  isUpdate,
}: {
  assessment: boolean;
  projectId: number;
  projectTitle: string;
  groupId: number;
  scheduleId?: number;
  scheduleDatetimeId?: number;
  handleClose: () => void;
  preDefinedStartAt?: string;
  preDefinedEndAt?: string;
  handleOpenDialog?: () => void;
  projectSlug?: string;
  isUpdate?: boolean;
}) => {
  const navigate = useNavigate();

  const {
    currLanguage,
    calendarDates,
    currGroup,
    groupProjectBoxProps,
    setGroupProjectBoxProps,
    setRefreshProjectBox,
    setRefreshGroupLessonInfoList,
    setCurrScheduleObj,
    setLessonLearningMomentCheckpointCount,
  } = useStore((state) => ({
    ...state,
    currGroup: state.groupList.filter((group) => group.id === groupId)[0],
  }));

  const initialState = {
    project_id: projectId,
    group_id: groupId,
    start_at: preDefinedStartAt
      ? preDefinedStartAt
      : moment().format(DATETIME_FORMAT),
    end_at: preDefinedEndAt
      ? preDefinedEndAt
      : moment().add(7, "days").format(DATETIME_FORMAT),
  };

  const [formData, setFormData] = useState<API_Create_Schedule>(initialState);
  const [startAt, setStartAt] = useState<Date | null>(
    new Date(initialState.start_at)
  );
  const [endAt, setEndAt] = useState<Date | null>(
    new Date(initialState.end_at)
  );

  const openSnack = useSnack();

  const handleSubmit = async () => {
    const res = await createSchedule(formData);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      let updatedProjectBoxes: ProjectBoxProps[] = [];
      res.schedules.map((schedule) => {
        const temp_schedules = getProjectBoxObjects(schedule, calendarDates);
        updatedProjectBoxes = [...updatedProjectBoxes, ...temp_schedules];
      });

      setGroupProjectBoxProps({
        ...groupProjectBoxProps,
        [groupId]: updatedProjectBoxes,
      });
      openSnack("Scheduled!", true);
      setRefreshProjectBox(true);
      setRefreshGroupLessonInfoList(true);
      populateScheduleObject(
        currLanguage,
        formData.group_id,
        formData.project_id,
        setCurrScheduleObj,
        setLessonLearningMomentCheckpointCount
      );
      handleClose();
      const navigateMethod = assessment
        ? ASSESSMENT_PAGE
        : PROJECT_SUMMARY_PAGE;
      projectSlug &&
        navigate(`${CURLANG(currLanguage)}/${navigateMethod(projectSlug)}`);
    }
    return res;
  };

  const handleUpdate = async () => {
    if (scheduleDatetimeId && scheduleId) {
      const res = await updateScheduleDatetime(
        scheduleDatetimeId,
        scheduleId,
        formData.start_at,
        formData.end_at
      );
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        const updatedProjectBoxProps = groupProjectBoxProps[groupId.toString()];
        const index = updatedProjectBoxProps.findIndex(
          (item) => item.id === scheduleDatetimeId
        );

        const projectBox = updatedProjectBoxProps[index];
        projectBox.startAt = formData.start_at;
        projectBox.endAt = formData.end_at;

        setGroupProjectBoxProps({
          ...groupProjectBoxProps,
          [groupId.toString()]: updatedProjectBoxProps,
        });

        setRefreshProjectBox(true);

        openSnack("Updated!", true);
        handleClose();
      }
      return res;
    }
  };

  return (
    <Stack
      component="form"
      sx={{
        width: "100%",
        maxWidth: "600px",
        minWidth: "250px",
        margin: "auto",
        alignItems: "center",
      }}
      spacing={1}
      noValidate
      autoComplete="off"
    >
      <Typography
        sx={{
          textAlign: "center",
        }}
      >
        Schedule when your {assessment ? "assessment" : translation.project} is
        going to take place!
        <br />
        You can view this in your curriculum calendar under our 'Scheduler'
        feature.
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Avatar
          src={typeof currGroup.image === "string" ? currGroup.image : Logo}
          sx={{
            width: "150px",
            height: "150px",
            backgroundColor: "primary.main",
            color: "txt.light",
            border: "2px solid",
            borderColor: "primary.main",
          }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        />
      </Box>

      {formData.project_id !== -1 ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {projectTitle}

              {isUpdate && handleOpenDialog && (
                <DeleteScheduleObjectComponent
                  handleOpenDialog={handleOpenDialog}
                  inModal={true}
                />
              )}

              {isUpdate && projectSlug && (
                <NavigateProjectComponent
                  projectSlug={projectSlug}
                  inModal={true}
                />
              )}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Class: {currGroup.name}
            </Typography>
          </Box>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              inputFormat={DATETIME_FORMAT}
              label={translation.projectStartDate}
              value={startAt}
              onChange={(newValue: Date | null) => {
                setStartAt(newValue);
                setFormData((prev) => ({
                  ...prev,
                  start_at:
                    newValue !== null
                      ? moment(newValue).format(DATETIME_FORMAT)
                      : "",
                }));
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />

            <DatePicker
              inputFormat={DATETIME_FORMAT}
              label={translation.projectEndDate}
              value={endAt}
              onChange={(newValue: Date | null) => {
                setEndAt(newValue);
                setFormData((prev) => ({
                  ...prev,
                  end_at:
                    newValue !== null
                      ? moment(newValue).format(DATETIME_FORMAT)
                      : "",
                }));
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>

          <Button
            buttonText={
              preDefinedStartAt
                ? translation.update || "Update"
                : translation.submit || "Submit"
            }
            disabled={formData.project_id === -1}
            onClick={preDefinedStartAt ? handleUpdate : handleSubmit}
          />
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Stack>
  );
};

export default ScheduleGroupPageSimple;

import React from "react";

import { Box, SxProps, Typography } from "@mui/material";

import Background from "images/end-of-project-report/four-polaroids.png";
import Wave from "images/end-of-project-report/wave.png";
import Image from "components/Image/Image";

import { Overlay } from "./Preview";
import { editableStyle, styles, LessonType } from "./constant";

const LT4Images = ({
  scale,
  lesson,
  handleChange,
  setOpenModal,
  setSelectedImageIndex,
  children,
}: {
  scale: number;
  lesson: LessonType;
  handleChange?: (key: string, value: string | null) => void;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedImageIndex?: React.Dispatch<React.SetStateAction<number>>;
  children?: React.ReactNode;
}) => {
  const isTemplatePreview =
    !handleChange && !setOpenModal && !setSelectedImageIndex && !children;

  const imageStyles: SxProps = {
    position: "absolute",
    transformOrigin: "top left",
    zIndex: 2,
    minHeight: 0,
    cursor: "pointer",
  };

  const imageStyles1: SxProps = {
    width: `${280 * scale}px`,
    height: `${250 * scale}px`,
  };

  const imageStyles2: SxProps = {
    width: `${246 * scale}px`,
    height: `${220 * scale}px`,
  };

  const textStyles: SxProps = {
    position: "absolute",
    textAlign: "center",
    left: `${40 * scale}px`,
    right: `${40 * scale}px`,
    zIndex: 2,
  };

  return (
    <Box sx={styles.body}>
      {children}

      <Image src={Wave} alt="" />

      <Box
        sx={{
          ...imageStyles,
          ...imageStyles1,
          ...(isTemplatePreview ? {} : editableStyle),
          top: `${216 * scale}px`,
          left: `${92 * scale}px`,
          transform: "rotate(8.71deg)",
        }}
        onClick={() => {
          setSelectedImageIndex && setSelectedImageIndex(0);
          setOpenModal && setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={lesson.images[0].file} alt="" objectFit="cover" />
      </Box>

      <Box
        sx={{
          ...imageStyles,
          ...imageStyles2,
          ...(isTemplatePreview ? {} : editableStyle),
          top: `${516 * scale}px`,
          left: `${422 * scale}px`,
          transform: "rotate(-12deg)",
        }}
        onClick={() => {
          setSelectedImageIndex && setSelectedImageIndex(1);
          setOpenModal && setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={lesson.images[1].file} alt="" objectFit="cover" />
      </Box>

      <Box
        sx={{
          ...imageStyles,
          ...imageStyles1,
          ...(isTemplatePreview ? {} : editableStyle),
          top: `${271 * scale}px`,
          left: `${726 * scale}px`,
          transform: "rotate(-6.67deg)",
        }}
        onClick={() => {
          setSelectedImageIndex && setSelectedImageIndex(2);
          setOpenModal && setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={lesson.images[2].file} alt="" objectFit="cover" />
      </Box>

      <Box
        sx={{
          ...imageStyles,
          ...imageStyles2,
          ...(isTemplatePreview ? {} : editableStyle),
          top: `${403 * scale}px`,
          left: `${1133 * scale}px`,
          transform: "rotate(9deg)",
        }}
        onClick={() => {
          setSelectedImageIndex && setSelectedImageIndex(3);
          setOpenModal && setOpenModal(true);
        }}
      >
        <Overlay />
        <Image src={lesson.images[3].file} alt="" objectFit="cover" />
      </Box>

      <Typography
        sx={{
          ...textStyles,
          ...(isTemplatePreview ? {} : editableStyle),
          fontSize: 40 * scale,
          fontWeight: 500,
          lineHeight: 1.25,
          top: `${40 * scale}px`,
        }}
        contentEditable
        onBlur={(e) =>
          handleChange && handleChange("title", e.currentTarget.textContent)
        }
        suppressContentEditableWarning={true}
      >
        {lesson.title}
      </Typography>

      <Typography
        sx={{
          ...textStyles,
          ...(isTemplatePreview ? {} : editableStyle),
          fontSize: 24 * scale,
          top: `${102 * scale}px`,
        }}
        contentEditable
        onBlur={(e) =>
          handleChange && handleChange("caption", e.currentTarget.textContent)
        }
        suppressContentEditableWarning={true}
      >
        {lesson.caption}
      </Typography>

      <Image
        src={Background}
        style={{
          ...styles.staticImage,
          zIndex: 3,
        }}
        alt=""
      />
    </Box>
  );
};

export default LT4Images;

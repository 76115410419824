import {
  API_Evaluation_Questionnaire,
  API_Evaluation_Questionnaire_Detail,
} from "../types/evaluationquestionnaire";
import { StoreSlice } from "./store";

export interface EvaluationQuestionnaireSlice {
  evaluationQuestionnaires: API_Evaluation_Questionnaire[];
  currentEvaluationQuestionnaire: API_Evaluation_Questionnaire_Detail;

  setEvaluationQuestionnaires: (
    evaluationQuestionnaires: API_Evaluation_Questionnaire[]
  ) => void;
  setCurrentEvaluationQuestionnaire: (
    currentEvaluationQuestionnaire: API_Evaluation_Questionnaire_Detail
  ) => void;
}

export const createEvaluationQuestionnaireSlice: StoreSlice<
  EvaluationQuestionnaireSlice
> = (set, get) => ({
  evaluationQuestionnaires: [],
  currentEvaluationQuestionnaire: {
    id: -1,
    name: "",
    slug: "",
    description: "",
    is_published: false,
    weight: 0,
    questions: [],
  },

  setEvaluationQuestionnaires: (
    evaluationQuestionnaires: API_Evaluation_Questionnaire[]
  ) => {
    set((prev: EvaluationQuestionnaireSlice) => ({
      ...prev,
      evaluationQuestionnaires: evaluationQuestionnaires,
    }));
  },

  setCurrentEvaluationQuestionnaire: (
    currentEvaluationQuestionnaire: API_Evaluation_Questionnaire_Detail
  ) => {
    set((prev: EvaluationQuestionnaireSlice) => ({
      ...prev,
      currentEvaluationQuestionnaire: currentEvaluationQuestionnaire,
    }));
  },
});

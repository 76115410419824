import React from "react";

import {
  Box,
  Avatar,
  Typography,
  Checkbox,
} from "@mui/material";

import { API_Child } from "types/profile";

type ChildProps = {
  child: API_Child;
  selected: number[];  
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
}

const ChildSelectComponent = ({ child, selected, setSelected }: ChildProps) => { 
  const handleCheckboxChange = (child: API_Child) => {
    const updatedSelected = [...selected];
    if (updatedSelected.some((id) => id === child.id)) {
      const index = updatedSelected.findIndex((id) => id === child.id);
      updatedSelected.splice(index, 1);
    } else {
      updatedSelected.push(child.id);
    }
    setSelected(updatedSelected);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 'fit-content',
        width: '100%',
        padding: '0 12px',
        gap: 1,
      }}
    >
      <Box
        sx={{
          height: '24px',
          width: '100%',
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar
          src={child.image}
          alt={`${child.preferred_name}'s picture`}
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
        <Typography>{child.preferred_name}</Typography>
      </Box> 
      <Checkbox
        size="medium"
        indeterminate={false}
        checked={selected.includes(child.id)}
        onChange={() => handleCheckboxChange(child)}
        color="primary"
      />
    </Box>)
}


export default ChildSelectComponent;
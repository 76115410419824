import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageOne from "images/children-photo-book/page-1.png";
import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageOneCaptionInfo,
  photoBookPageOneImageInfo,
} from "../photoBookCommonConstant";

export const PhotoBookPageOneComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imageInfo = photoBookPageOneImageInfo(scale);
  const captionInfo = photoBookPageOneCaptionInfo(scale);
  return (
    <>
      <Image
        src={PhotoBookPageOne}
        alt={"photo-book-page-1"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      <Image
        src={images[0].link}
        alt={"photo-book-page-1"}
        objectFit="cover"
        style={{
          position: "absolute",
          top: imageInfo.top,
          left: imageInfo.left,
          width: imageInfo.width,
          height: imageInfo.height,
        }}
      />
      <CaptionComponent
        top={captionInfo.top}
        left={captionInfo.left}
        width={captionInfo.width}
        caption={images[0].caption}
        clampLine={captionInfo.clampLine}
        fontSize={captionInfo.fontSize}
      />
    </>
  );
};

export default PhotoBookPageOneComponent;

import { Page, Text, View, Image, Font } from "@react-pdf/renderer";

import { API_Project_Module } from "types/project";

import ttb_logo from "images/ttb-logo.png";
import watermark from "images/ttb-watermark-pdf.png";

import time from "../LessonSelectionAssets/time.png";
import { checkIsChineseChar } from "helper/helper";

import {
  removeHtmlTagsAndStyles,
  removeHtmlTagsWithLiAsListAndStyles,
  extractText,
} from "./utils";
import { styles } from "./styles";
import LessonHeader from "./LessonHeader";

function splitAndFormatMaterials(text: string): any[] {
  const step1 = text.replace(/<p[^>]*>.*?<\/p>/g, "");

  const step2 = step1.split("<li>");

  const step3 = step2.map((item) =>
    item
      .replace(/<[^>]*>/g, "")
      .replace(/&nbsp;/g, "")
      .trim()
  );

  const result = step3.filter((item) => item !== "");

  return result;
}

interface SelectedLessonPageProps {
  lesson: any;
  lessonIndex: number;
  projModule: API_Project_Module;
  currentProject: any;
}

const SelectedLessonPage = ({
  lesson,
  lessonIndex,
  currentProject,
  projModule,
}: SelectedLessonPageProps) => {
  const instructions = extractText(lesson.instruction);
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);
  return (
    <Page key={lessonIndex} style={styles.page} wrap>
      <View style={styles.watermarkContainer} fixed>
        <Image style={styles.watermarkImage} src={watermark} />
      </View>
      <Image src={ttb_logo} style={styles.logoContainer} />
      <LessonHeader currentProject={currentProject} projModule={projModule} />
      <View
        style={{
          marginTop: "10px",
          border: "1px solid black",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px 5px",
        }}
      >
        {lesson.name && (
          <View>
            <Text
              style={{
                fontFamily: "FuturaPT600",
                fontSize: "16px",
              }}
            >
              {currentProject.assessment ? "Assessment Plan " : "Lesson "}{" "}
              {lesson.lessonNumber}:
              <Text
                style={{
                  fontFamily: checkIsChineseChar(lesson.name)
                    ? "KaiTi"
                    : "FuturaPT600",
                  fontSize: "16px",
                }}
              >
                {" "}
                {removeHtmlTagsAndStyles(lesson.name)}
              </Text>
            </Text>
          </View>
        )}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image src={time} style={{ width: 10, height: 10, marginRight: 1 }} />

          <Text
            style={{
              fontSize: "10px",
              marginLeft: "5px",
            }}
          >
            {removeHtmlTagsAndStyles(String(lesson.duration))} minutes
          </Text>
        </View>
      </View>

      <Text style={{ marginTop: "16px", fontFamily: "FuturaPT600" }}>
        {currentProject.assessment ? "Assessment Plan " : "Lesson "}
        Toolbox
      </Text>

      <View style={styles.regularSection}>
        <Text style={styles.miniHeaderText}>
          {currentProject.assessment ? "Assessment Plan " : "Lesson "}
          Intention
        </Text>
        <Text
          style={{
            ...styles.regularSectionText,
            ...(checkIsChineseChar(lesson.intention)
              ? styles.chineseText
              : styles.englishTextSection),
          }}
        >
          {lesson.intention}
        </Text>
      </View>
      <View style={styles.regularSection}>
        <Text style={styles.miniHeaderText}>
          {currentProject.assessment ? "Assessment Plan " : "Lesson "}
          Objective
        </Text>

        {lesson.objective_fundamentals &&
          Object.keys(lesson.objective_fundamentals).map((key, index) => (
            <View key={index}>
              <Text
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  color: "#5C9391",
                  fontFamily: checkIsChineseChar(key) ? "KaiTi" : "FuturaPT600",
                }}
              >
                {key}
              </Text>
              {Array.isArray(lesson.objective_fundamentals[key]) &&
                lesson.objective_fundamentals[key].map(
                  (milestone: string, idx: number) => {
                    return (
                      <Text
                        key={`milestone-${key}-${idx}`}
                        style={{
                          ...styles.regularSectionText,
                          ...(checkIsChineseChar(milestone)
                            ? styles.chineseText
                            : styles.englishTextSection),
                        }}
                      >
                        • {milestone}
                      </Text>
                    );
                  }
                )}
            </View>
          ))}

        {!Object.keys(lesson.objective_fundamentals).length && (
          <Text style={styles.regularSectionText}>No Objectives set!</Text>
        )}
      </View>

      <View style={styles.regularSection} wrap={false}>
        <Text style={styles.miniHeaderText}>Setup Instruction</Text>
        {instructions.result.length > 0 &&
          instructions.result.map((text: any, index: number) => {
            return typeof text === "string" ? (
              <>
                <Text style={{ ...styles.regularSectionText, margin: "4px 0" }}>
                  {" "}
                  <Text
                    style={{
                      ...styles.regularSectionText,
                      margin: "4px 0",
                      ...(checkIsChineseChar(text)
                        ? styles.chineseText
                        : styles.englishTextSection),
                    }}
                  >
                    {text}
                  </Text>
                </Text>
              </>
            ) : Array.isArray(text) ? (
              text.map((item: any, index: any) => (
                <Text
                  style={{
                    ...styles.regularSectionText,
                    margin: "4px 0",
                    ...(checkIsChineseChar(item)
                      ? styles.chineseText
                      : styles.englishTextSection),
                  }}
                  key={`lesson-instruction-li-${index}`}
                >
                  • {item}
                </Text>
              ))
            ) : typeof text == "object" ? (
              Object.entries(text).map(([key, value], entryIndex) => (
                <Text style={{ ...styles.regularSectionText, margin: "4px 0" }}>
                  {`${key}: `}{" "}
                  <Text
                    style={{
                      ...styles.regularSectionText,
                      margin: "4px 0",
                      ...(checkIsChineseChar(value)
                        ? styles.chineseText
                        : styles.englishTextSection),
                    }}
                  >
                    {String(value)}
                  </Text>
                </Text>
              ))
            ) : null;
          })}
      </View>

      {!currentProject.assessment && (
        <View style={styles.regularSection} wrap={false}>
          <Text style={styles.miniHeaderText}>Key Vocabulary</Text>
          <Text
            style={{
              ...styles.regularSectionText,
              ...(checkIsChineseChar(lesson.vocabulary)
                ? styles.chineseText
                : styles.englishTextSection),
            }}
          >
            {removeHtmlTagsAndStyles(lesson.vocabulary)}
          </Text>
        </View>
      )}

      <View style={styles.regularSection}>
        <Text style={styles.miniHeaderText}>Materials</Text>
        {lesson.activities.map((activity: any, index: number) => {
          return (
            <View key={index} wrap={false} style={styles.regularSection}>
              <Text
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  color: "#5C9391",
                  fontFamily: "FuturaPT600",
                }}
              >
                Activity {index + 1}
              </Text>
              {activity.material &&
                splitAndFormatMaterials(activity.material).map(
                  (material: any, index: number) => {
                    return (
                      <Text
                        key={index}
                        style={{
                          marginTop: "10px",
                          fontSize: "12px",
                          color: "black",
                          fontFamily: checkIsChineseChar(material)
                            ? "KaiTi"
                            : "FuturaPT500",
                        }}
                      >
                        {material}
                      </Text>
                    );
                  }
                )}
              {splitAndFormatMaterials(activity.material).length == 0 && (
                <Text
                  style={{
                    marginTop: "10px",
                    fontSize: "12px",
                    color: "black",
                    fontFamily: "FuturaPT500",
                  }}
                >
                  -
                </Text>
              )}
            </View>
          );
        })}
      </View>
    </Page>
  );
};

export default SelectedLessonPage;

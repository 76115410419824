import React, { useEffect, useState } from "react";
import { Image } from "react-grid-gallery";
import { Box } from "@mui/material";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getChildPortfolioObservation } from "api/portfolio-api";
import { GalleryPhotoVideo } from "types/gallery";
import PhotoVideoGalleryComponent from "components/PhotoVideoGalleryComponents/PhotoVideoGalleryComponent";
import { API_Profile_Short } from "types/profile";

import useStore from "store/store";
import DevelopmentalGoalObservationModal from "./DevelopmentalGoalObservationModal";
// import MiniLearningStoryModal from "./MiniLearningStoryModal";
// import CreateLearningStoryModal from "./CreateLearningStoryModal";
import LearningStoryModal from "components/Organisms/LearningStoryModal/LearningStoryModal";
import PermissionModal from "components/Organisms/PermissionModal/PermissionModal";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import { createlearningStoryAPI } from "api/learning-stories-api";
import {
  CURLANG,
  LEARNING_STORIES,
  CHILDREN_PORTFOLIO,
  CLASS,
} from "constants/url";
import { getAllFundamentals } from "api/fundamental-api";

const MilestonePhotoGalleryComponent = ({
  developmentalGoal,
  milestone,
  setIsCreateObservationModalOpen,
  setSelectedMilestoneId,
}: {
  developmentalGoal: any;
  milestone: any;
  setIsCreateObservationModalOpen: any;
  setSelectedMilestoneId: any;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDevGoal, setSelectedDevGoal] = useState<any>(
    developmentalGoal.id
  );
  const [selectedMilestoneId, setMileStoneId] = useState<string | undefined>(
    milestone.id
  );
  const [fundamentalList, setFundamentalList] = useState<any>([]);
  const openSnack = useSnack();
  const navigate = useNavigate();
  const groupID = localStorage.getItem("curr_group_id");
  const [openPermissionModal, setOpenPermissionModal] =
    useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [openGalleryModal, setOpenGalleryModal] = useState<boolean>(false);
  const [havePhotoVideo, setHavePhotoVideo] = useState<boolean>(false);
  const [currPhotoVideoIndex, setCurrPhotoVideoIndex] = useState<number>(0);
  const [milestoneObservations, setMilestoneObservations] = useState<any>([]);
  const [selectedProfiles, setSelectedProfiles] = useState<string[][][]>([]);
  const [galleryIndex, setGalleryIndex] = useState<number>(0);
  const [profileDict, setProfileDict] = useState<{
    [id: string]: API_Profile_Short;
  }>({});
  const [allObservations, setAllObservations] = useState<any>([]);
  const [learningStoryModal, setLearningStoryModal] = useState<boolean>(false);
  const { groupSlug, childID, h3TabSlug } = useParams();
  const {
    currLanguage,
    isRefreshPortfolioObservation,
    setIsRefreshPortfolioObservation,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    isRefreshPortfolioObservation: state.isRefreshPortfolioObservation,
    setIsRefreshPortfolioObservation: state.setIsRefreshPortfolioObservation,
  }));

  // console.log(developmentalGoal, milestone)

  const populateChildPortfolioObservation = async () => {
    // console.log(developmentalGoal)
    const observationRes = await getChildPortfolioObservation({
      language: currLanguage,
      childId: Number(childID),
      developmentalGoalTag: h3TabSlug!,
      developmentalGoalId: developmentalGoal.id,
      isShowAll: true,
    });
    // console.log(observationRes)
    // const allRes = await getChildImages(
    //     Number(childID),
    //     localStorage.getItem("access")
    // );

    // console.log(observationRes, allRes, milestone.name)
    if (typeof observationRes !== "string") {
      const newImageVideoList: GalleryPhotoVideo[] = [];
      const newProfileList: string[][][] = [];
      let milestoneIndex = 0;
      const milestoneList: string[] = [];
      let milestoneName = "";
      const observationFilter = observationRes.filter(
        (observation) => observation.milestone.name == milestone.name
      );
      setAllObservations(observationFilter);
      // BELOW MATCHINGITEMS IS ONLY LEARNING MOMENTS,PORTFOLIO OBSERVATIONS ARE NOT RECORDED AS OFFICIAL IMAGES
      // const matchingItems = allRes.filter(globalImage => observationFilter.some(observation => observation.image === globalImage.image && milestone.name == observation.milestone.name));
      // const remainingItems = observationFilter.filter(observation => !matchingItems.some(matchingItem => matchingItem.image === observation.image && milestone.name == observation.milestone.name));

      if (observationFilter.length > 0) {
        setHavePhotoVideo(true);
        observationFilter.forEach((photoVideo: any) => {
          if (photoVideo.learning_moment === undefined) return;
          milestoneName = milestone.name!;
          milestoneIndex = milestoneList.indexOf(milestoneName);

          if (milestoneIndex === -1) {
            milestoneList.push(milestoneName);
            newImageVideoList.push({
              lesson: milestoneName,
              photo_video_list: [],
            });
            milestoneIndex = newImageVideoList.length - 1;
            newProfileList.push([]);
          }

          const imageData: Image = {
            key:
              `photo-${photoVideo.id}-${milestone.id}` +
              (photoVideo.learning_moment
                ? `-learning-moment-${photoVideo.learning_moment}`
                : ""),
            src: photoVideo.image!,
            width: photoVideo.width,
            height: photoVideo.height,
            alt: photoVideo.caption,
            customOverlay: (
              <>
                {newImageVideoList[milestoneIndex].photo_video_list.length !==
                  0 && (
                  <div className="number-overlay">
                    <p>
                      +
                      {newImageVideoList[milestoneIndex].photo_video_list
                        .length + 1}
                    </p>
                  </div>
                )}
                {/* <div className="custom-overlay">
                                    <p>{photoVideo.caption}</p>
                                </div> */}
              </>
            ),
          };

          if (imageData.src) {
            newImageVideoList[milestoneIndex].photo_video_list.unshift(
              imageData
            );
          }

          // newProfileList[milestoneIndex].unshift(
          //     photoVideo.profile.map((prof: any) => prof.id.toString())
          // );
        });

        setMilestoneObservations(newImageVideoList);
        // console.log(newImageVideoList)
        // setSelectedProfiles(newProfileList);
        setIsRefreshPortfolioObservation(false);
      }
    }
  };
  useEffect(() => {
    if (isRefreshPortfolioObservation) {
      populateChildPortfolioObservation();
    }
  }, [isRefreshPortfolioObservation]);

  useEffect(() => {
    populateChildPortfolioObservation();
  }, [childID, developmentalGoal]);

  const fetchPhoto = (imageUrl: string | undefined) => {
    if (!imageUrl) return;

    return fetch(imageUrl, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((res) => res.blob())
      .then((imageBlob) => {
        const file = new File([imageBlob], "image.jpg", {
          type: imageBlob.type,
        });
        setImageFile(file);
      })
      .catch((err) => {
        console.error("Failed to fetch image from S3:", err);
        openSnack("Failed to fetch image. Please try again.", false);
      });
  };

  const createLearningStory = async (data: {
    story?: string | undefined;
    date?: string | undefined;
    parentView: boolean;
    photo?: File | undefined;
    image_url?: string | undefined;
    fundamental: string | undefined;
    milestone: string | undefined;
  }) => {
    setIsLoading(true);
    const learningStoryData = {
      child_id: Number(childID),
      group_id: Number(groupID),
      story: data.story || "",
      date: data.date || "",
      image: data.photo || undefined,
      image_url: undefined,
      fundamental_id: data.fundamental || undefined,
      milestone_id: data.milestone || undefined,
      accessToken: localStorage.getItem("access"),
    };

    const res = await createlearningStoryAPI(learningStoryData);
    if (res !== "string") {
      setIsLoading(false);
      setLearningStoryModal(false);
      openSnack(translation.createSuccess, true);
      navigate(
        `${CURLANG(
          currLanguage
        )}/${CHILDREN_PORTFOLIO}/${groupSlug}/${childID}/${LEARNING_STORIES}`
      );
    } else {
      openSnack(translation.failed_upload, false);
    }
  };

  return (
    <>
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "purple",
          width: "100%",
        }}
      >
        <PhotoVideoGalleryComponent
          key={`photo-video-${milestone.id}`}
          isLearningMomentPhotoVideoGallery={true}
          isForLearningMomentTab={false}
          // lesson={currentLesson}
          // learningMomentObject={learningMomentObject}
          // lessonNo={currentLesson.weight}
          milestone={milestone}
          photoVideoGalleryList={milestoneObservations}
          setGalleryIndex={setGalleryIndex}
          setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
          setOpen={setOpenGalleryModal}
          initializeGallery={populateChildPortfolioObservation}
          setIsCreateObservationModalOpen={setIsCreateObservationModalOpen}
          setSelectedMilestoneId={setSelectedMilestoneId}
        />
      </Box>
      {/* {photoVideoGalleryList.length > 0 &&
          havePhotoVideo &&
          photoVideoGalleryList[galleryIndex].photo_video_list.length !== 0 && (
            <PhotoVideoGalleryModal
              open={openGalleryModal}
              setOpen={setOpenGalleryModal}
              lessonName={currentLesson.name}
              photoVideoGalleryList={photoVideoGalleryList}
              galleryIndex={galleryIndex}
              currPhotoVideoIndex={currPhotoVideoIndex}
              setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
              profileDict={profileDict}
              selectedProfiles={selectedProfiles}
              setSelectedProfiles={setSelectedProfiles}s
              initializeGallery={initializeGallery}
              isLearningMomentPhotoAlbum
            />
          )} */}
      {milestoneObservations.length > 0 && havePhotoVideo && (
        <DevelopmentalGoalObservationModal
          open={openGalleryModal}
          setOpen={setOpenGalleryModal}
          setPermissionModal={setOpenPermissionModal}
          photoVideoGalleryList={milestoneObservations}
          galleryIndex={galleryIndex}
          setGalleryIndex={setGalleryIndex}
          currPhotoVideoIndex={currPhotoVideoIndex}
          setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
          profileDict={profileDict}
          milestone={milestone}
          selectedProfiles={selectedProfiles}
          setSelectedProfiles={setSelectedProfiles}
          initializeGallery={populateChildPortfolioObservation}
          allObservations={allObservations}
        />
      )}
      {openPermissionModal && (
        <PermissionModal
          title="Are you done editing this Photo?"
          description="Choose Yes to start creating your Learning Story!"
          isOpen={openPermissionModal}
          setIsOpen={setOpenPermissionModal}
          onClose={() => {
            setOpenPermissionModal(false);
            setOpenGalleryModal(true);
          }}
          onConfirm={async () => {
            setLearningStoryModal(true);
            setOpenPermissionModal(false);
            const imageUrl =
              milestoneObservations[galleryIndex].photo_video_list[
                currPhotoVideoIndex
              ].src;
            await fetchPhoto(imageUrl);
          }}
          onConfirmLabel="Yes"
          onCancelLabel="No"
        />
      )}
      {milestoneObservations.length > 0 &&
        havePhotoVideo &&
        learningStoryModal && (
          <LearningStoryModal
            open={learningStoryModal}
            setOpen={setLearningStoryModal}
            onSave={createLearningStory}
            title="Create Learning Story"
            orientation="landscape"
            selectedDevGoal={selectedDevGoal}
            selectedMileStones={selectedMilestoneId}
            image={imageFile}
            date={moment(allObservations[galleryIndex].created_at).format(
              "YYYY-MM-DD"
            )}
            story={allObservations[galleryIndex].caption}
            isLoading={isLoading}
          />
        )}
      {/* {
                openMiniLearningStoryModal && <MiniLearningStoryModal openMiniLearningStoryModal={openMiniLearningStoryModal} setOpenMiniLearningStoryModal={setOpenMiniLearningStoryModal} setLearningStoryModal={setLearningStoryModal} />
            } */}
      {/* {
                learningStoryModal && <CreateLearningStoryModal photoVideoGalleryList={milestoneObservations}
                    galleryIndex={galleryIndex}
                    setGalleryIndex={setGalleryIndex}
                    currPhotoVideoIndex={currPhotoVideoIndex} initializeGallery={populateChildPortfolioObservation}
                    allObservations={allObservations} setOpenCreateLearningStory={setLearningStoryModal} />
            } */}
    </>
  );
};

export default MilestonePhotoGalleryComponent;

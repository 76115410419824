import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Image from "../../../Image/Image";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";

interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
  scale: number;
};

const ImageStructureForThree: React.FC<ImageStructureProps> = ({
  projects,
  scale,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        m: "20px",
      }}
    >
      <Grid container spacing={1} sx={{ width: "100%" }}>
        {projects.slice(0, 2).map((project, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: `${20 * scale}px`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                height: `${350 * scale}px`,
                width: `${450 * scale}px`,
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "70%",
                  mb: `-${5 * scale}px`,
                }}
              >
                <Image
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                  }}
                  src={Frame}
                  alt="frame"
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "11%",
                    left: "11%",
                    width: "79%",
                    height: "59%",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                  src={project.image_url}
                  alt={project.title || "projects"}
                />
              </Box>

              {/* Caption positioned below the frame */}
              <Box
                sx={{
                  width: "90%",
                  backgroundColor: "#F9F6F1",
                  textAlign: "center",
                  p: `${5 * scale}px`,
                  marginTop: `-${35 * scale}px`,
                }}
              >
                <Typography
                  sx={{
                    fontSize: `${14 * scale}px`,
                    fontFamily: "FuturaBkBT",
                    color: "#000",
                    lineHeight: "22px",
                    letterSpacing: "1.4px",
                    fontWeight: 400,
                  }}
                >
                  {project.category}
                </Typography>
                <Typography
                  sx={{
                    fontSize: `${12 * scale}px`,
                    fontFamily: "FuturaBkBT",
                    color: "#000",
                    lineHeight: "18px",
                    letterSpacing: "1.2px",
                    fontWeight: 300,
                  }}
                >
                  {project.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", marginTop: "-50px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              height: `${350 * scale}px`,
              width: `${450 * scale}px`,
              boxSizing: "border-box",
            }}
          >
            {/* Simple Frame Image */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "70%",
              }}
            >
              <Image
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
                src={Frame}
                alt="frame"
              />
              <Image
                style={{
                  position: "absolute",
                  top: "9%",
                  left: "26%",
                  width: "48.5%",
                  height: "63%",
                  objectFit: "cover",
                  zIndex: 1,
                }}
                src={projects[2].image_url}
                alt={projects[2].title || "projects"}
              />
            </Box>

            <Box
              sx={{
                width: "60%",
                backgroundColor: "#F9F6F1",
                textAlign: "center",
                p: `${5 * scale}px`,
                marginTop: `-${40 * scale}px`,
              }}
            >
              <Typography
                sx={{
                  fontSize: `${14 * scale}px`,
                  fontFamily: "FuturaBkBT",
                  color: "#000",
                  lineHeight: "22px",
                  letterSpacing: "1.4px",
                  fontWeight: 400,
                }}
              >
                {projects[2].category}
              </Typography>
              <Typography
                sx={{
                  fontSize: `${12 * scale}px`,
                  fontFamily: "FuturaBkBT",
                  color: "#000",
                  lineHeight: "18px",
                  letterSpacing: "1.2px",
                  fontWeight: 300,
                }}
              >
                {projects[2].title}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageStructureForThree;

import React from "react";
import useStore from "store/store";
import { useNavigate } from "react-router-dom";
import { Container} from "@mui/material";
import {
  CURLANG,
  HOME,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";

import { TITLE } from "constants/title";
import LogoImage from "images/logo.png";
import TTALogoImage from "images/tta-logo.png";
import LittleLabTeachingPlatformImage from "images/little-lab-teaching-platform-logo.png";
import Image from "components/Image/Image";

const MobileLogo = ({ isTTA }: { isTTA: boolean }) => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => state);

  return (
    <Container
      sx={{
        display: { xs: "flex", lg: "none" },
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        width: "auto",
        p: "0 !important",
        m: 0,
      }}
      onClick={() => {
        document.title = TITLE;
        navigate(
          isTTA
            ? `${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`
            : `${CURLANG(currLanguage)}/${HOME}`
        );
      }}
    >
      {/* <Avatar
        src={LogoImage}
        variant="square"
        sx={{ display: "flex" }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = LogoImage;
          },
        }}
      ></Avatar> */}
      <Image
        src={isTTA ? TTALogoImage : LittleLabTeachingPlatformImage}
        // variant="square"
        style={{
          display: "flex",
          height: 44,
          transform: isTTA ? "scale(1.75)" : "scale(1)",
          paddingLeft: isTTA ? 28 : 0,
          paddingRight: isTTA ? 32 : 0,
        }}
        alt={LogoImage}
      />
    </Container>
  );
};

export default MobileLogo;

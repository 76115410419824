import React from "react";
import { Avatar, Box, TextField, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { NavigateFunction } from "react-router-dom";
import { CHAT, CURLANG } from "constants/url";
import { API_Chat } from "types/chat";
import { API_Profile_Details } from "types/profile";
import { Role } from "./ChatComponent";

export const SearchBar = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      placeholder="Search..."
      value={searchQuery}
      onChange={(event) => {
        setSearchQuery(event.target.value);
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
      sx={{
        maxWidth: "500px",
        mx: "auto",
      }}
    />
  );
};

export const handleStartChatRes = ({
  res,
  profileID,
  allChats,
  setAllChats,
  chatSockets,
  setChatSockets,
  setOpen,
  navigate,
  currLanguage,
}: {
  res: API_Chat;
  profileID: string;
  allChats: API_Chat[];
  setAllChats: (allChats: API_Chat[]) => void;
  chatSockets: {
    [id: string]: WebSocket;
  };
  setChatSockets: (chatSockets: { [id: string]: WebSocket }) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
  currLanguage: string;
}) => {
  const updatedAllChats = [...allChats, res];
  setAllChats(updatedAllChats);
  setOpen(false);
  navigate(`${CURLANG(currLanguage)}/${CHAT(res.id.toString())}`);
};

export const Title = ({ title }: { title: string }) => (
  <Typography
    sx={{
      fontSize: "1.5rem",
      fontWeight: 500,
      mb: 1.5,
    }}
  >
    {title}
  </Typography>
);

export const ContactList = ({
  contacts,
  profiles,
  onClick,
}: {
  contacts: API_Profile_Details[];
  profiles?: number[];
  onClick: (id: number) => void;
}) => (
  <Box
    sx={{
      mt: 3,
      flex: "1 1 0",
      overflowY: "auto",
    }}
  >
    {contacts.length === 0 ? (
      <Typography
        sx={{
          textAlign: "center",
        }}
      >
        No result found
      </Typography>
    ) : (
      <>
        {contacts.map((contact, index) => (
          <React.Fragment key={contact.id}>
            {(index === 0 ||
              contacts[index - 1]?.preferred_name?.[0]?.toLowerCase() !==
                contact.preferred_name?.[0]?.toLowerCase()) && (
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  mt: index === 0 ? 0 : 1.5,
                  textTransform: "uppercase",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                {contact.preferred_name[0]}
              </Typography>
            )}

            <Box
              sx={{
                cursor: "pointer",
                border: "1px solid #E4E4E4",
                borderRadius: "10px",
                transition: "all 0.2s ease-in-out",
                mt: 0.5,
                "&:hover": {
                  backgroundColor: "rgba(209, 228, 227, 0.37)",
                },
              }}
              onClick={() => onClick(contact.id)}
            >
              <ContactBox contact={contact} profiles={profiles} />
            </Box>
          </React.Fragment>
        ))}
      </>
    )}
  </Box>
);

export const ContactBox = ({
  contact,
  profiles,
}: {
  contact: API_Profile_Details;
  profiles?: number[];
}) => (
  <Box sx={{ display: "flex", gap: 1.5, px: 2.5, py: 1.5 }}>
    <Avatar
      src={contact.image !== null ? contact.image : "no image"}
      sx={{
        width: "25px",
        height: "25px",
        my: "auto",
      }}
    >
      {contact.preferred_name[0]}
    </Avatar>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography noWrap>{contact.preferred_name}</Typography>
        <Role chatProfiles={[contact]} />
      </Box>
      <Typography sx={{ fontSize: 12, opacity: 0.7 }}>
        {contact.email}
      </Typography>
    </Box>

    {profiles !== undefined && (
      <>
        {profiles.includes(contact.id) ? (
          <CheckBoxIcon
            fontSize="small"
            sx={{
              ml: "auto",
              color: "txt.secondary",
              my: "auto",
            }}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            fontSize="small"
            sx={{
              ml: "auto",
              color: "txt.secondary",
              my: "auto",
            }}
          />
        )}
      </>
    )}
  </Box>
);

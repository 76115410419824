import axios from "api/axiosConfig";
import { AxiosError } from "axios";
import {
  Child_Progress_Report,
  Project_Summary_Report,
  Query_Data,
} from "types/project";
import { MAX_QUERY } from "containers/teacher/TeacherArchivePage/TeacherArchivePageComponent/TeacherArchiveCommonComponent/TeacherArchiveCommonComponent";

export const getProjectSummaryReportList = async ({
  language,
  childId,
  queryData,
  selectedModuleSlugs,
  selectedClassSlugs,
  selectedChildIds,
  groupId,
  projectId,
  isTeacherArchive,
}: {
  language: string;
  childId?: number;
  queryData?: Query_Data;
  selectedModuleSlugs?: string[];
  selectedClassSlugs?: string[];
  selectedChildIds?: number[];
  groupId?: number;
  projectId?: number;
  isTeacherArchive?: boolean;
}) => {
  let path = `${language}/lms/project-summary-report/list?query_limit=${MAX_QUERY}`;
  if (childId) {
    path += `&child_id=${childId}`;
  }
  if (queryData && queryData.query) {
    path += `&search=${queryData.query}`;
  }
  if (queryData && queryData.startDate) {
    path += `&start_date=${queryData.startDate}`;
  }
  if (queryData && queryData.endDate) {
    path += `&end_date=${queryData.endDate}`;
  }
  if (selectedModuleSlugs) {
    path += `&module_slug=${
      selectedModuleSlugs.length > 0 ? selectedModuleSlugs.join(",") : "none"
    }`;
  }
  if (selectedClassSlugs) {
    path += `&class_slug=${
      selectedClassSlugs.length > 0 ? selectedClassSlugs.join(",") : "none"
    }`;
  }
  if (selectedChildIds) {
    path += `&child_id_list=${
      selectedChildIds.length > 0 ? selectedChildIds.join(",") : "none"
    }`;
  }
  if (groupId) {
    path += `&group_id=${groupId}`;
  }
  if (projectId) {
    path += `&project_id=${projectId}`;
  }
  if (isTeacherArchive) {
    path += `&is_teacher_archive=true`;
  }

  try {
    const res = await axios.get(path);
    const data: Project_Summary_Report[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getProjectSummaryReport = async (
  project: number,
  group: number
) => {
  try {
    const res = await axios.get(
      `en/lms/project-summary-report/get?project_id=${project}&group_id=${group}`
    );
    const data: Project_Summary_Report = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createProjectSummaryReport = async (
  file: Blob,
  fileName: string,
  project: number,
  group: number,
  setIsCreatingReport: React.Dispatch<React.SetStateAction<boolean>>,
  setProgressPercentage: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    const res = await axios.post(
      "en/lms/project-summary-report/create",
      {
        file,
        file_name: fileName,
        project,
        group,
      },
      {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressPercentage(percentage);
        },
      }
    );
    const data: Project_Summary_Report = res.data;
    setIsCreatingReport(false);
    setProgressPercentage(0);
    return data;
  } catch (error: unknown) {
    setIsCreatingReport(false);
    setProgressPercentage(0);
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getSingleChildProgressList = async ({
  language,
  progressReportId,
}: {
  language: string;
  progressReportId: any;
}) => {
  try {
    const res = await axios.get(
      `${language}/lms/child-progress-report/get/${progressReportId}`
    );
    const data: any = res.data;
    console.log(data);
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getChildProgressReportList = async ({
  language,
  childId,
  queryData,
  selectedClassSlugs,
  selectedChildIds,
  groupId,
  isTeacherArchive,
}: {
  language: string;
  childId?: number;
  queryData?: Query_Data;
  selectedClassSlugs?: string[];
  selectedChildIds?: number[];
  groupId?: number;
  isTeacherArchive?: boolean;
}) => {
  let path = `${language}/lms/child-progress-report/list?query_limit=${MAX_QUERY}`;
  if (childId) {
    path += `&child_id=${childId}`;
  }
  if (queryData && queryData.query) {
    path += `&search=${queryData.query}`;
  }
  if (queryData && queryData.startDate) {
    path += `&start_date=${queryData.startDate}`;
  }
  if (queryData && queryData.endDate) {
    path += `&end_date=${queryData.endDate}`;
  }
  if (selectedClassSlugs) {
    path += `&class_slug=${
      selectedClassSlugs.length > 0 ? selectedClassSlugs.join(",") : "none"
    }`;
  }
  if (selectedChildIds) {
    path += `&child_id_list=${
      selectedChildIds.length > 0 ? selectedChildIds.join(",") : "none"
    }`;
  }
  if (groupId) {
    path += `&group_id=${groupId}`;
  }
  if (isTeacherArchive) {
    path += `&is_teacher_archive=true`;
  }
  // console.log("Path: ", path);
  try {
    const res = await axios.get(path);
    const data: any[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createChildProgressReport = async (
  child: string,
  groupSlug: string,
  startMonth: string,
  endMonth: string,
  setIsCreatingReport: React.Dispatch<React.SetStateAction<boolean>>,
  setProgressPercentage: React.Dispatch<React.SetStateAction<number>>,
  name?: string,
  PDFInfo?: any,
  file?: any,
  fileName?: string
) => {
  try {
    const res = await axios.post(
      "en/lms/child-progress-report/create",
      {
        name,
        PDFInfo,
        file,
        file_name: fileName,
        start_month: startMonth,
        end_month: endMonth,
        child,
        group_slug: groupSlug,
      },
      {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setProgressPercentage(percentage);
        },
      }
    );

    const data: Child_Progress_Report = res.data;
    setIsCreatingReport(false);
    setProgressPercentage(0);
    return data;
  } catch (error: unknown) {
    setIsCreatingReport(false);
    setProgressPercentage(0);
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updatePDFDraft = async (
  progressReportId: any,
  name?: string,
  PDFInfo?: any
) => {
  try {
    const res = await axios.put(
      `en/lms/child-progress-report/${progressReportId}/update`,
      {
        name,
        PDFInfo,
      },
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
    const data: Child_Progress_Report = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};
export const deleteChildProgressReport = async (
  id: number | undefined,
  accessToken: string | null
) => {
  try {
    const res = await axios.delete(
      `en/lms/child-progress-report/${id}/delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: any = await res.data;
    return {};
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

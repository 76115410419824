
import { Box } from "@mui/system";
import { Link as MuiLink, Typography } from "@mui/material";

import Logo from "images/logo.png";

import {
  Link as RouterLink,
  useParams,
} from "react-router-dom";


import useStore from "store/store";

import { slugify } from "helper/slugify";

import {
  CHILDREN_PORTFOLIO_PAGE,
  CURLANG,
  MY_CHILDREN_PORTFOLIO,
} from "constants/url";
import {
  translatedTabs,
  tabs,
} from "./constants";
import devGoalsIcon from "images/children-portfolio/sidebar/developmental-goals.png";
import progressReportIcon from "images/children-portfolio/sidebar/progress-report.png";
import completedWorksIcon from "images/children-portfolio/sidebar/completed-works.png";
import photoAlbumIcon from "images/children-portfolio/sidebar/photo-album.png";
import projectSummaryIcon from "images/children-portfolio/sidebar/project-summary.png";
import Image from "components/Image/Image";

const ICONS = [
  Logo,
  devGoalsIcon,
  progressReportIcon,
  completedWorksIcon,
  photoAlbumIcon,
  projectSummaryIcon,
];

const TabsContainerMobile = ({ currentTab }: { currentTab: string }) => {
  return (
    <>
      {tabs.map((text, index) => {
        return (
          <Tabs
            text={text}
            currentTab={currentTab}
            key={index}
            icon={ICONS[index]}
          />
        );
      })}
    </>
  );
};

const Tabs = ({
  text,
  currentTab,
  icon,
  isParentView = false,
}: {
  text: string;
  currentTab: string;
  icon: string;
  isParentView?: boolean;
}) => {
  const { groupSlug, childID } = useParams();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  return (
    <>
      <MuiLink
        component={RouterLink}
        to={`${CURLANG(currLanguage)}/${
          isParentView
            ? `${MY_CHILDREN_PORTFOLIO}/${childID}`
            : CHILDREN_PORTFOLIO_PAGE(groupSlug!, childID!)
        }/${slugify(text)}`}
        sx={{
          backgroundColor: `${
            currentTab === text ? "rgba(209, 228, 227, 0.37)" : "#fff"
          }`,
          border: "1px solid #98C4C5",
          borderRadius: "10px",
          p: "4px 8px",
          paddingRight: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 100ms linear",
          "&:hover": {
            backgroundColor: "rgba(209, 228, 227, 0.37)",
            transition: "all 100ms linear",
          },
          whiteSpace: "nowrap",
          position: "relative",
          textDecoration: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Image src={icon} alt={text} style={{ width: 24, height: 24 }} />
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography sx={{ fontSize: "13px", color: "txt.dark2" }}>
              {translatedTabs[text]}
            </Typography>
          </Box>
        </Box>
      </MuiLink>
    </>
  );
};

export default TabsContainerMobile;

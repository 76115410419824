import { StoreSlice } from "./store";

import { CurrGroupBackpackFav } from "../types/backpack";

export interface BackpackSlice {
  currGroupBackpack: CurrGroupBackpackFav;
  currGroupFavourite: CurrGroupBackpackFav;
  refreshGroup: boolean;
  refreshGroupBackpack: boolean;
  refreshGroupFavourite: boolean;
  setCurrGroupBackpack: (currGroupBackpack: CurrGroupBackpackFav) => void;
  setCurrGroupFavourite: (currGroupFavourite: CurrGroupBackpackFav) => void;
  setRefreshGroup: (refreshGroup: boolean) => void;
  setRefreshGroupBackpack: (refreshGroupBackpack: boolean) => void;
  setRefreshGroupFavourite: (refreshGroupFavourite: boolean) => void;
}

export const createBackpackSlice: StoreSlice<BackpackSlice> = (set, get) => ({
  currGroupBackpack: {
    projects: [],
  },
  currGroupFavourite: {
    projects: [],
  },
  refreshGroup: true,
  refreshGroupBackpack: true,
  refreshGroupFavourite: true,
  setCurrGroupBackpack: (currGroupBackpack: CurrGroupBackpackFav) => {
    set((prev: BackpackSlice) => ({
      ...prev,
      currGroupBackpack: currGroupBackpack,
    }));
  },
  setCurrGroupFavourite: (currGroupFavourite: CurrGroupBackpackFav) => {
    set((prev: BackpackSlice) => ({
      ...prev,
      currGroupFavourite: currGroupFavourite,
    }));
  },
  setRefreshGroup: (refreshGroup: boolean) => {
    set((prev: BackpackSlice) => ({
      ...prev,
      refreshGroup: refreshGroup,
    }));
  },
  setRefreshGroupBackpack: (refreshGroupBackpack: boolean) => {
    set((prev: BackpackSlice) => ({
      ...prev,
      refreshGroupBackpack: refreshGroupBackpack,
    }));
  },
  setRefreshGroupFavourite: (refreshGroupFavourite: boolean) => {
    set((prev: BackpackSlice) => ({
      ...prev,
      refreshGroupFavourite: refreshGroupFavourite,
    }));
  },
});

// export interface BackpackSlice {
//   childrenBackpack: ChildrenBackpack;
//   childrenFav: ChildrenBackpack;
//   refreshGroup: boolean;
//   setChildrenBackpack: (childrenBackpack: ChildrenBackpack) => void;
//   setChildrenFav: (childrenFav: ChildrenBackpack) => void;
//   setRefreshGroup: (refreshGroup: boolean) => void;
// }

// export const createBackpackSlice: StoreSlice<BackpackSlice> = (set, get) => ({
//   childrenBackpack: {},
//   childrenFav: {},
//   refreshGroup: true,
//   setChildrenBackpack: (childrenBackpack: ChildrenBackpack) => {
//     set((prev: BackpackSlice) => ({
//       ...prev,
//       childrenBackpack: childrenBackpack,
//     }));
//   },
//   setChildrenFav: (childrenFav: ChildrenBackpack) => {
//     set((prev: BackpackSlice) => ({
//       ...prev,
//       childrenFav: childrenFav,
//     }));
//   },
//   setRefreshGroup: (refreshGroup: boolean) => {
//     set((prev: BackpackSlice) => ({
//       ...prev,
//       refreshGroup: refreshGroup,
//     }));
//   },
// });

import React, { useEffect, useRef } from "react";

import { Box } from "@mui/material";

import {
  API_Activity_Instruction,
  API_Project_Lesson,
  API_Project_Lesson_Activity,
} from "types/project";
import { useParams } from "react-router-dom";
import ActivityStepByStepInstruction from "./ActivityStepByStepInstructions";

const Content = ({
  contentRef,
  currentLesson,
  currentActivity,
  currentInstructions,
}: {
  contentRef: React.MutableRefObject<HTMLDivElement | undefined>;
  currentLesson: API_Project_Lesson;
  currentActivity: API_Project_Lesson_Activity;
  currentInstructions: API_Activity_Instruction[];
}) => {
  const { activityID } = useParams();
  const contentScreenRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (contentScreenRef.current) {
      contentScreenRef.current.scrollTo(0, 0);
    }
  }, [activityID]);

  return (
    currentActivity && (
      <Box
        ref={contentRef}
        sx={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <Box
            ref={contentScreenRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflow: "auto",
              "::-webkit-scrollbar": {
                width: { xs: 5, sm: 10 },
                height: { xs: 5, sm: 10 },
              },
            }}
          >
            <Box
              sx={{
                paddingLeft: 1,
                paddingRight: 2,
                paddingTop: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                // height: "calc(100% - 30px)",
              }}
            >
              {currentInstructions.map((instruction, instructionIndex) => {
                return (
                  <Box
                    key={`activity-intruction-${instruction.id}`}
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <ActivityStepByStepInstruction
                      currentLesson={currentLesson}
                      instruction={instruction}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default Content;
export * from "./FullScreenButton";
export * from "./TabPanel";

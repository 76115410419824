
import React, { useEffect, useState } from "react";
import useStore from "store/store";
import {
  Button,
  Box,
  Checkbox,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  Chip,
} from "@mui/material";
import {
  H3_TABS,
  HANDS_TEXT,
  HEAD_TEXT,
  HEART_TEXT,
} from "containers/PortfolioPage/portfolioPageConstants";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { getAllFundamentals } from "api/fundamental-api";
import { API_Fundamental, Query_Data } from "types/project";

import { useNavigate, useParams, Outlet } from "react-router-dom";
import Image from "components/Image/Image";
import { getChildPortfolioObservation } from "api/portfolio-api";

import HeartImg from "images/heart.png";
import HeadImg from "images/head.png";
import HandImg from "images/hand.png";

import {
  CHILDREN_PORTFOLIO_PAGE,
  CURLANG,
  MY_CHILDREN_PORTFOLIO,
} from "constants/url";
import { tabs } from "../constants";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import { translation } from "constants/translation";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CompactViewModal from "./DevelopmentalGoalsScreenComponents/CompactViewModal";
import EditObservationModal from "./DevelopmentalGoalsScreenComponents/EditObservationModal";
import { initialDevelopmentalGoal } from "containers/DevelopmentalGoalPage/EditDevelopmentalGoalPage";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";

const DevelopmentalGoalsScreen = ({
  isParentView = false,
}: {
  isParentView?: boolean;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const { groupSlug, childID, h3TabSlug, devGoal } = useParams();
  const navigate = useNavigate();

  const {
    currLanguage,
    setIsRefreshLatestMilestone,
    isRefreshPortfolioObservation,
    setIsRefreshPortfolioObservation,
    childPortfolioObservationList,
    setChildPortfolioObservationList,
    isCompactView,
    setIsCompactView,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setIsRefreshLatestMilestone: state.setIsRefreshLatestMilestone,
    isRefreshPortfolioObservation: state.isRefreshPortfolioObservation,
    setIsRefreshPortfolioObservation: state.setIsRefreshPortfolioObservation,
    childPortfolioObservationList: state.childPortfolioObservationList,
    setChildPortfolioObservationList: state.setChildPortfolioObservationList,
    isCompactView: state.isCompactView,
    setIsCompactView: state.setIsCompactView,
  }));

  useEffect(
    () => setIsCompactView(isParentView),
    [isParentView, setIsCompactView]
  );

  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });

  const [relatedDevelopmentalGoals, setRelatedDevelopmentalGoals] = useState<
    API_Fundamental[]
  >([]);

  useEffect(() => {
    const populateFundamentals = async () => {
      setDevelopmentalGoalsLoading(true);
      const res = await getAllFundamentals(
        currLanguage,
        h3TabSlug!,
        Number(childID)
      );
      res.sort((a, b) => a.priority - b.priority);

      if (typeof res !== "string") {
        setRelatedDevelopmentalGoals(res);
      } else {
        setRelatedDevelopmentalGoals([]);
      }
      setIsRefreshLatestMilestone(false);
      setDevelopmentalGoalsLoading(false);
    };

    if (h3TabSlug && childID && currLanguage) {
      populateFundamentals();
    }
  }, [h3TabSlug, currLanguage, childID]);
  const populateChildPortfolioObservation = async () => {
    const res = await getChildPortfolioObservation({
      language: currLanguage,
      childId: Number(childID),
      developmentalGoalTag: h3TabSlug!,
      queryData: queryData,
    });

    if (typeof res !== "string") {
      setChildPortfolioObservationList(res);
    } else {
      setChildPortfolioObservationList([]);
    }
    setIsRefreshPortfolioObservation(false);
  };

  useEffect(() => {
    if (h3TabSlug && currLanguage && childID) {
      populateChildPortfolioObservation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    h3TabSlug,
    currLanguage,
    childID,
    isRefreshPortfolioObservation,
    queryData,
  ]);

  // Parent View Modal
  const [isParentViewModalOpen, setIsParentViewModalOpen] =
    useState<boolean>(false);
  const handleParentViewModalClose = () => {
    setIsParentViewModalOpen(false);
  };

  // Edit Dev Goal Modal
  const [selectedDevelopmentalGoal, setSelectedDevelopmentalGoal] =
    useState<API_Fundamental>(initialDevelopmentalGoal);

  const [isEditDevGoalModalOpen, setIsEditDevGoalModalOpen] =
    useState<boolean>(false);
  const handleDevGoalModalClose = () => {
    setIsEditDevGoalModalOpen(false);
  };
  const [developmentalGoalsLoading, setDevelopmentalGoalsLoading] =
    useState<boolean>(false);

  if (developmentalGoalsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator />
      </Box>
    );
  }

  if (
    devGoal &&
    relatedDevelopmentalGoals.some((goal) => devGoal === goal.slug)
  ) {
    return <Outlet />;
  }

  return (
    <>
      <CompactViewModal
        open={isParentViewModalOpen}
        handleClose={handleParentViewModalClose}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
          position: "relative",
        }}
      >
        {H3_TABS.map((h3_tab) => {
          return (
            <Box
              key={`selection-h3_tab-${h3_tab}`}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                padding: isLg ? 2 : isMd ? 1 : 1,
                flex: 0.75,
                "&:hover": {
                  backgroundColor: `#C4E4DF`,
                },
                gap: 1,
                borderRadius: isLg ? "10px" : "25px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                backgroundColor: `${h3TabSlug === h3_tab ? "#C4E4DF" : "white"
                  }`,
                cursor: "pointer",
                border: "1px solid #91C6C5",
                zIndex: h3_tab === "heart" ? 10 : h3_tab === "head" ? 5 : 2,
                marginLeft: h3_tab === "heart" ? 0 : isLg ? "-5px" : "-8px",
              }}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                !developmentalGoalsLoading &&
                  navigate(
                    `${CURLANG(currLanguage)}/${isParentView
                      ? `${MY_CHILDREN_PORTFOLIO}/${childID}`
                      : CHILDREN_PORTFOLIO_PAGE(groupSlug!, childID!)
                    }/${tabs[1]}/${h3_tab}`
                  );
              }}
            >
              <Image
                style={{
                  maxHeight: "25px",
                  width: "26px",
                  maxWidth: "100px",
                }}
                src={
                  h3_tab === HEART_TEXT
                    ? HeartImg
                    : h3_tab === HEAD_TEXT
                      ? HeadImg
                      : h3_tab === HANDS_TEXT
                        ? HandImg
                        : ""
                }
                alt={translation.developmentalGoals}
              />
              <Typography
                sx={{
                  textTransform: "capitalize",
                  display: "flex",
                  fontSize: isLg ? "16px" : isMd ? "14px" : "12px",
                  fontWeight: isLg ? "750" : "450",
                }}
              >
                {h3_tab}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            width: "100%",
            mb: 2,
          }}
        >
          <StartAtDateField queryData={queryData} setQueryData={setQueryData} />
          <EndAtDateField queryData={queryData} setQueryData={setQueryData} />
          {!isParentView && !isXs && (
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                ml: { xs: 0, sm: "auto" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  px: 1,
                  border: "1px solid var(--primary-main)",
                  borderRadius: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsCompactView(!isCompactView);
                }}
              >
                <Checkbox size={"small"} checked={isCompactView} />
                <Typography>{translation.parentView}</Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setIsParentViewModalOpen(true);
                }}
              >
                <HelpOutlineIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isLg ? "1fr 1fr 1fr" : isMd ? "1fr 1fr" : "1fr",
          width: "100%",
          gap: 3,
        }}
      >
        <EditObservationModal
          open={isEditDevGoalModalOpen}
          handleClose={handleDevGoalModalClose}
          developmentalGoal={selectedDevelopmentalGoal!}
        />

        {relatedDevelopmentalGoals.map((developmentalGoal) => {
          const subDevGoalIds = developmentalGoal?.sub_fundamentals?.map((subFundamental) => subFundamental.id) || [];
          const allObservations = childPortfolioObservationList.filter(
            (portfolioObservation) => {
              return (
                portfolioObservation.developmental_goal === developmentalGoal.id ||
                subDevGoalIds.includes(portfolioObservation.developmental_goal)
              );
            }
          );

          return (
            <Card
              key={`developmentalGoal-${developmentalGoal.name}`}
              sx={{
                flexShrink: 1,
                flexBasis: "calc(33.3333%)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {allObservations.length > 0 &&
                  allObservations.some((observation) => !!observation.image) ? (
                  <Image
                    style={{
                      height: "208px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={
                      allObservations.find((observation) => !!observation.image)
                        ?.image
                    }
                    alt={
                      allObservations.find((observation) => !!observation.image)
                        ?.caption || ""
                    }
                  />
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#D8F2EE",
                      height: "208px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AddAPhotoIcon
                      sx={{
                        color: "white",
                        backgroundColor: "#D8F2EE",
                        height: "100px",
                        width: "100px",
                      }}
                    />
                  </Box>
                )}

                <Box sx={{ backgroundColor: "#EBF8F8", display: "flex" }}>
                  <Box sx={{ display: "flex", padding: "12px", gap: 1 }}>
                    <Image
                      style={{
                        height: "24px",
                        // width: 40,
                        width: "24px",
                      }}
                      src={developmentalGoal.image}
                      alt={developmentalGoal.name}
                    />
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      {developmentalGoal.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 2,
                    pt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "450",
                      color: "#91C6C5",
                    }}
                  >
                    Current Milestone
                  </Typography>

                  <Chip
                    size="small"
                    label={`${developmentalGoal.learning_moment_documented || 0
                      } learning ${developmentalGoal.learning_moment_documented == 1
                        ? "moment"
                        : "moments"
                      } documented`}
                    sx={{ cursor: "pointer", px: "8px", py: "4px" }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "white",
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {typeof developmentalGoal.latest_achieved_milestone !==
                  "number" && developmentalGoal.latest_achieved_milestone ? (
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        overflow: "hidden",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#D8F2EE",
                      }}
                    >
                      <Image
                        style={{ width: "20px", height: "20px" }}
                        src={
                          developmentalGoal.latest_achieved_milestone.age ==
                            "Sprouts"
                            ? SproutsIcon
                            : developmentalGoal.latest_achieved_milestone.age ==
                              "Buds"
                              ? BudsIcon
                              : BlossomsIcon
                        }
                        alt="Level"
                      />
                    </Box>
                    <Typography sx={{ flexGrow: 1 }}>
                      {developmentalGoal.latest_achieved_milestone.name}
                    </Typography>
                  </Box>
                ) : (
                  <Typography>No milestones recorded!</Typography>
                )}
                <Button
                  sx={{
                    backgroundColor: "#91C6C5",
                    width: "100%",
                    "&:hover": { backgroundColor: "#D8F2EE" },
                  }}
                  onClick={() => {
                   
                    navigate(
                      `${CURLANG(currLanguage)}/${isParentView
                        ? `${MY_CHILDREN_PORTFOLIO}/${childID}`
                        : CHILDREN_PORTFOLIO_PAGE(groupSlug!, childID!)
                      }/${tabs[1]}/${h3TabSlug}/${developmentalGoal.slug}`
                    );
                  }}
                >
                  <Typography sx={{ color: "white", textTransform: "none" }}>
                    See All Milestones
                  </Typography>
                </Button>
              </Box>
            </Card>
          );
        })}
      </Box>
    </>
  );
};

export default DevelopmentalGoalsScreen;

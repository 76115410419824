import { createTheme } from "@mui/material";
import "@mui/material/styles";
import "@mui/material/styles/createPalette";

import type {} from "@mui/x-data-grid/themeAugmentation";

declare module "@mui/material/styles" {
  interface Palette {
    txt: {
      dark: string;
      dark2: string;
      light: string;
      secondary: string;
    };
    custom: {
      lightBlue: string;
      lightOrange: string;
      blue: string;
      lime: string;
      grey: string;
      lightGrey: string;
      lightPink: string;
      yellow: string;
    };
    neutral: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
    cyan: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
    };
  }
  interface PaletteOptions {
    txt?: {
      dark?: string;
      dark2?: string;
      light?: string;
      secondary?: string;
    };
    custom?: {
      lightBlue: string;
      lightOrange: string;
      blue: string;
      lime: string;
      grey: string;
      lightGrey: string;
      lightPink: string;
      yellow: string;
    };
    neutral?: {
      50?: string;
      100?: string;
      200?: string;
      300?: string;
      400?: string;
      500: string;
      600?: string;
      700?: string;
      800?: string;
      900?: string;
    };
    cyan?: {
      50?: string;
      100?: string;
      200?: string;
      300?: string;
      400?: string;
      500: string;
      600?: string;
      700: string;
      800?: string;
      900?: string;
    };
  }
}

type Theme = ReturnType<typeof createTheme>;

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#98C4C5",
      light: "#D1E4E3",
      contrastText: "#000",
    },
    secondary: {
      main: "#fff",
    },
    info: {
      main: "#D1E4E3",
    },
    txt: {
      dark: "#000",
      dark2: "#444",
      light: "#fff",
      secondary: "#407374",
    },
    neutral: {
      50: "#FFF",
      100: "#F2F2F2",
      300: "#C9C9C9",
      500: "#8C8C8C",
      900: "#323031",
    },
    cyan: {
      50: "#F8FDFD",
      100: "#EBF8F8",
      200: "#D8F2EE",
      500: "#91C6C5",
      700: "#5C9391",
    },
    custom: {
      lightBlue: "#c5e7e9",
      lightOrange: "#ffdd8e",
      blue: "#91c6c5",
      lime: "#ecea5c",
      grey: "#464547",
      lightGrey: "#c0c0c0",
      lightPink: "#fcd1ca",
      yellow: "#FEC106",
    },
  },
  typography: {
    fontFamily: [
      "Futura PT",
      "KaiTi",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    // Use `MuiDataGrid` on DataGrid, DataGridPro and DataGridPremium
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // backgroundColor: "#D1E4E3",
          border: "1px solid #98C4C5",
          "& .MuiButtonBase-root:hover": {
            color: "#FFFFFF",
            backgroundColor: "#98C4C5",
          },
          "& .MuiInputBase-root": {
            fontSize: 18,
          },
          "& .MuiButton-root": {
            border: "1px solid #98C4C5",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "100%",
          "& .MuiChip-label": {
            wordWrap: "break-word",
            whiteSpace: "normal",
            textOverflow: "clip",
          },
          "& .MuiAvatar-img": {
            objectFit: "scale-down",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          // height: "100%",
          "& .MuiAvatar-img": {
            objectFit: "cover",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          // height: "100%",
          "& .MuiSvgIcon-root": {
            color: "var(--primary-main)",
          },
          "& .MuiListItemIcon-root": {
            minWidth: 40,
          },
          "& .MuiTypography-root": {
            lineHeight: 1.25,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          // height: "100%",
          "& .MuiSvgIcon-root": {
            color: "var(--primary-main)",
          },
          // "& .MuiTabs-flexContainer": {
          //   justifyContent: "center",
          // },
        },
      },
    },
  },
});

export default theme;

import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_Create_Lesson } from "types/project";
import { createLesson } from "api/project-api";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { translation } from "constants/translation";
import getBase64 from "helper/getBase64";

import {
  NameField,
  DurationField,
  KeyVocabField,
  DescriptionField,
  InstructionsField,
  ImageField,
  IntentionField,
  LessonObjectiveField,
} from "./LessonFormComponents";
// import CheckpointsField from "./LessonFormComponents/CheckpointsField";
import { initialLesson } from "./EditLessonForm";
import DrawerHeader from "containers/EditProjectPage/DrawerHeader";
import MaterialButton from "components/Button/MaterialButton";

type Props = {
  assessment: boolean;
  projectID: string;
  handleClose: () => void;
};

const CreateLessonForm = ({ assessment, projectID, handleClose }: Props) => {
  const openSnack = useSnack();
  const [images, setImages] = useState<File[]>([]);

  const { currLanguage, setRefreshProjAndLess, fundamentalDict } = useStore(
    (state) => state);

  const [lesson, setLesson] = useState<API_Create_Lesson>(initialLesson);

  useEffect(() => {
    setLesson((prev) => ({
      ...prev,
      project: +projectID,
    }));
  }, [projectID]);

  const lessonRef = useRef(lesson);
  lessonRef.current = lesson;

  const submitForm = async () => {
    if (!lesson.name || lesson.name === "") {
      openSnack("Lesson name is required!", false);
      return;
    }
    if (images.length > 0) {
      const base64Images: { file: string }[] = [];

      for (let i = 0; i < images.length; i++) {
        const base64 = await getBase64(images[i]);
        base64Images.push({ file: base64 });
      }

      lesson.images = base64Images;
    }

    const createLessonRes = await createLesson(currLanguage, lesson);
    if (typeof createLessonRes !== "string") {
      setRefreshProjAndLess(true);
      openSnack(
        translation.success_create_lesson || "Created lesson successfully!",
        true
      );
      handleClose();
    } else {
      openSnack(
        translation.fail_create_lesson || "Failed to create lesson!",
        false
      );
    }
  };

  return Object.keys(fundamentalDict).length === 0 ? (
    <LoadingIndicator />
  ) : (
    <Stack
      component="form"
      sx={{
        width: "100%",
        mb: 8,
      }}
      spacing={3}
      noValidate
      autoComplete="off"
    >
      <DrawerHeader
        type="Lesson"
        mode="Add"
        assessment={assessment}
        handleClose={handleClose}
      >
        <MaterialButton
          buttonText={
            assessment
              ? "Add Assessment Plan"
              : translation.add_lesson || "Add Lesson"
          }
          onClick={() => submitForm()}
        />
      </DrawerHeader>
      <NameField
        label={assessment ? "Add Assessment Plan" : undefined}
        lesson={lesson}
        setLesson={setLesson}
      />
      <IntentionField
        label={assessment ? "Assessment Plan Intention" : undefined}
        lesson={lesson}
        setLesson={setLesson}
      />
      <DurationField lesson={lesson} setLesson={setLesson} />
      {!assessment && <KeyVocabField lesson={lesson} setLesson={setLesson} />}
      <LessonObjectiveField
        label={assessment ? "Assessment Plan Objective" : undefined}
        lesson={lesson}
        setLesson={setLesson}
      />
      <DescriptionField
        richText={assessment}
        lesson={lesson}
        setLesson={setLesson}
      />
      <InstructionsField
        label={assessment ? "Set-up Instructions (if any)" : undefined}
        lesson={lesson}
        setLesson={setLesson}
      />
      <ImageField images={images} setImages={setImages} multiple />
    </Stack>
  );
};

export default CreateLessonForm;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useStore from "store/store";
import {
  API_Announcement,
  API_Announcement_Response_Information,
} from "types/announcement";
import {
  deleteAnnouncement,
  getAnnouncementList,
  getAnnouncementListByChild,
} from "api/announcement-api";
import { translation } from "constants/translation";
import { NothingMessage } from "containers/WelcomeBackPage/components/TeacherDashboard";
import moment from "moment";
import useSnack from "hooks/useSnack";
import {
  AnnouncementCard,
  AnnouncementModal,
  UpdateLastSeenAnnouncementTime,
} from "containers/AnnouncementsPage/AnnouncementComponents";
import WarningDialog from "components/Dialog/WarningDialog";
import useDialog from "hooks/useDialog";
import { AnnouncementChartModal } from "containers/WelcomeBackPage/components/ParentDashboardComponents/AnnouncementChartModal";
import AddIcon from "images/add-icon.png";
import Image from "components/Image/Image";

export const initialAnnouncementResponseInformation: API_Announcement_Response_Information =
  {
    id: -1,
    announcement: -1,
    end_at: "",
    response_question: "",
    response_type: "",
    response_options: [],
    is_allow_text: false,
    text_instruction: "",
    is_allow_attachment: false,
    attachment_instruction: "",
  };

const AnnouncementsPage = ({
  isActionRequiredPage,
}: {
  isActionRequiredPage?: boolean;
}) => {
  const openSnack = useSnack();
  const { childID, schoolId } = useParams();

  const {
    currLanguage,
    profileDetails,
    announcementList,
    setAnnouncementList,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    announcementList: state.announcementList,
    setAnnouncementList: state.setAnnouncementList,
  }));

  const today = moment().set({
    hour: 8,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const initialAnnouncement: API_Announcement = {
    id: -1,
    school: Number(schoolId)!,
    name: "",
    prev_name: "",
    description: "",
    files: [],
    start_at: today.toISOString(),
    // end_at: today.add(30, "days").toISOString(),
    created_by: profileDetails.user,
    created_by_preferred_name: "",
    created_by_role: "",
    created_by_class: "",
    profiles: [],
    is_seen: false,
    is_starred: false,
    is_pinned: false,
    is_published: false,
    is_responded: false,
    is_edited: false,
    announcement_response_information: initialAnnouncementResponseInformation,
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [announcement, setAnnouncement] =
    useState<API_Announcement>(initialAnnouncement);
  const [openModal, setOpenModal] = useState<boolean>(false); //Edit modal
  const [openChartModal, setOpenChartModal] = useState<boolean>(false);

  useEffect(() => {
    if (!childID && !schoolId) return;

    const fetchAnnouncements = async () => {
      setIsLoading(true);

      let announcements;

      if (isActionRequiredPage) {
        if (childID) {
          announcements = await getAnnouncementListByChild(
            currLanguage,
            Number(childID),
            true
          );
        } else {
          announcements = await getAnnouncementList(
            currLanguage,
            Number(schoolId),
            true
          );
        }
      } else {
        if (childID) {
          announcements = await getAnnouncementListByChild(
            currLanguage,
            Number(childID)
          );
        } else {
          announcements = await getAnnouncementList(
            currLanguage,
            Number(schoolId)
          );
        }
      }

      if (typeof announcements !== "string") {
        if (isActionRequiredPage) {
          announcements = announcements.sort((a, b) => {
            if (a.is_responded && !b.is_responded) {
              return 1;
            }
            if (!a.is_responded && b.is_responded) {
              return -1;
            }
            return 0;
          });
        }
        setAnnouncementList(announcements);
      }

      setIsLoading(false);
    };

    fetchAnnouncements();
    //eslint-disable-next-line
  }, [currLanguage, childID, schoolId, isActionRequiredPage]);

  const handleDelete = async () => {
    const response = await deleteAnnouncement(currLanguage, announcement.id);

    if (response === "Success") {
      openSnack(translation.announcementDeletedSuccess, true);
      setAnnouncementList(
        announcementList.filter((a) => a.id !== announcement.id)
      );
    } else {
      openSnack(translation.announcementDeletedFailed, false);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: translation.deleteAnnouncement + "?",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  const isParentView = profileDetails.role === "PR";

  return (
    <>
      <UpdateLastSeenAnnouncementTime />

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <WarningDialog {...warningBody} />

          {!isParentView && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "end",
                gap: 2,
                mb: 1.5,
                mr: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  // flex: 1,
                  borderRadius: "10px",
                  border: "1px solid var(--primary-main)",
                  px: 1,
                  width: "fit-content",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAnnouncement(initialAnnouncement);
                  setOpenModal(true);
                }}
              >
                <Image
                  src={AddIcon}
                  alt="add-icon"
                  style={{ paddingLeft: 1, width: 15, height: 15 }}
                />
                <Typography sx={{ fontSize: 14 }}>
                  {translation.addNewAnnouncement}
                </Typography>
              </Box>
            </Box>
          )}

          {announcementList.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {announcementList.map((announcement, index) => (
                <>
                  <AnnouncementCard
                    key={`announcement-card-${announcement.id}`}
                    announcement={announcement as API_Announcement}
                    handleChartClick={() => {
                      setAnnouncement(announcement);
                      setOpenChartModal(true);
                    }}
                    handleClickEdit={() => {
                      setAnnouncement(announcement);
                      setOpenModal(true);
                    }}
                    handleClickDelete={() => {
                      setAnnouncement(announcement);
                      handleOpenDialog();
                    }}
                    isParentView={isParentView}
                    isEditable={
                      profileDetails.role === "WAD" ||
                      profileDetails.role === "AD" ||
                      announcement.created_by === profileDetails.user
                    }
                  />
                  {index !== announcementList.length - 1 && <Divider />}
                </>
              ))}
            </Box>
          ) : (
            <NothingMessage
              message={
                isActionRequiredPage
                  ? translation.noActionRequiredYet
                  : translation.noAnnouncementYet
              }
            />
          )}
          {openModal && (
            <AnnouncementModal
              open={openModal}
              handleClose={() => setOpenModal(false)}
              announcement={announcement}
            />
          )}
          {openChartModal && (
            <AnnouncementChartModal
              open={openChartModal}
              handleClose={() => setOpenChartModal(false)}
              announcement={announcement}
            />
          )}
        </>
      )}
    </>
  );
};

export default AnnouncementsPage;

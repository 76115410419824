import { Box } from "@mui/material"


type Props = {
  count: number;
  step: number;
}

const StepNavigationDots = ({ count, step } : Props) => { 
  return (
    <Box sx={{ display: "flex", gap: "4px" }}>
      {Array(count).fill(1).map((_, index) => (
        <Box
          sx={{
            width: "6px",
            height: "6px",
            borderRadius: "3px",
            bgcolor: step === index ? "#B4B4B4" : "#DDD",
          }}
        />
      ))}
    </Box>
  );
}

export default StepNavigationDots;
import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  API_Teacher_Lesson,
  API_Teacher_Module_Learner_Lesson_Progress,
  API_Teacher_Pictorial_Observation_Activity,
  API_Teacher_Pictorial_Observation_Activity_Learner_Answer,
} from "types/teacher";

import { translation } from "constants/translation";
import CustomImage from "components/Image/Image";
import Button from "components/Button/Button";

import "./videoActivity.css";
import useSnack from "hooks/useSnack";
import { useParams } from "react-router-dom";
import useStore from "store/store";
import {
  getPictorialObservationLearnerAnswer,
  getPictorialObservationLearnerList,
  updateCreatePictorialObservation,
} from "api/teacher-api";
import { TeacherLessonActivityScreenDoneButton } from "../TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";
import { API_Profile_Short } from "types/profile";
import ProfileSelectionList from "./TeacherActivitySharedComponents/ProfileSelectionList";
import { updateLearnerActivityProgressFunctionCall } from "../TeacherPage/TeacherPageHelper";

const TeacherPictorialObservationActivityScreen = ({
  teacherCurrentActivity,
  teacherCurrentLesson,
}: {
  teacherCurrentActivity: API_Teacher_Pictorial_Observation_Activity;
  teacherCurrentLesson: API_Teacher_Lesson;
}) => {
  const openSnack = useSnack();
  const { lessonSlug } = useParams();

  const {
    currLanguage,
    profileDetails,
    learnerLessonProgressList,
    setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    learnerLessonProgressList: state.learnerLessonProgressList,
    setLearnerLessonProgressList: state.setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo:
      state.setDisplayObtainedCertificateModalInfo,
  }));

  const [
    pictorialObservationLearnerAnswer,
    setPictorialObservationLearnerAnswer,
  ] = useState<API_Teacher_Pictorial_Observation_Activity_Learner_Answer>({
    id: -1,
    profile: profileDetails.id,
    pictorial_observation_activity: teacherCurrentActivity.id,
    name: "",
  });
  const [profileList, setProfileList] = useState<API_Profile_Short[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<API_Profile_Short>({
    id: profileDetails.id,
    image: profileDetails.image,
    preferred_name: profileDetails.preferred_name,
    full_name: profileDetails.full_name,
    dob: profileDetails.dob,
  });
  useEffect(() => {
    const populatePictorialObservationProfileList = async () => {
      const res = await getPictorialObservationLearnerList(
        teacherCurrentActivity.id,
        localStorage.getItem("access")
      );
      if (typeof res !== "string") {
        setProfileList(res);
      }
    };
    populatePictorialObservationProfileList();
  }, []);

  useEffect(() => {
    const populatePictorialObservationLearnerAnswer = async () => {
      const res = await getPictorialObservationLearnerAnswer(
        teacherCurrentActivity.id,
        selectedProfile.id,
        localStorage.getItem("access")
      );
      if (typeof res !== "string") {
        setPictorialObservationLearnerAnswer(res);
      }
    };
    populatePictorialObservationLearnerAnswer();
  }, [selectedProfile.id]);

  const currLessonIndex = learnerLessonProgressList.findIndex(
    (learnerLessonProgress) => learnerLessonProgress.lesson_slug === lessonSlug
  );

  const isActivityDone = learnerLessonProgressList[currLessonIndex]
    ? learnerLessonProgressList[currLessonIndex].activity_done_list.includes(
        teacherCurrentActivity.id
      )
    : false;

  const isSameProfile = selectedProfile.id === profileDetails.id;

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        borderRadius: "10px",
        p: 2,
        flex: 1,
        display: "flex",
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          bgcolor: "background.paper",
          borderRadius: "10px",
          flex: 1,
        }}
      >
        {/* {translation.formatString(translation.lessonNo, {
        number: teacherCurrentLesson.weight,
      })} */}
        {(profileDetails.role === "WAD" || profileDetails.role === "TTACE") && (
          <ProfileSelectionList
            profileList={profileList}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0",
            overflowY: "auto",
            px: 1,
          }}
        >
          <CustomImage
            src={teacherCurrentActivity.image as string}
            objectFit="contain"
            style={{
              // maxWidth: "50vw",
              maxHeight: "30vh",
            }}
            alt="secondary"
          />

          <Typography sx={{ alignSelf: "center" }}>
            {teacherCurrentActivity.description}
          </Typography>
          <TextField
            multiline
            rows={4}
            placeholder="Long Answer Text"
            // variant="standard"
            fullWidth
            // label={`${interactiveDataEntryPrompt.name}-prompt`}
            value={pictorialObservationLearnerAnswer.name}
            onChange={(event) => {
              setPictorialObservationLearnerAnswer((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
          />
          {isSameProfile && (
            <Box sx={{ display: "flex", justifyContent: "end", py: 1 }}>
              <Button
                // style={{ display: isPrevPathDisabled ? "none" : "flex" }}
                buttonText={
                  isActivityDone
                    ? translation.update || "Update"
                    : translation.submit || "Submit"
                }
                // btnType="outlined"
                arrow={false}
                // style={{ backgroundColor: "background.paper" }}
                onClick={async () => {
                  if (pictorialObservationLearnerAnswer.name === "") {
                    openSnack(
                      translation.pleaseAnswerAll || "Please Answer All",
                      false
                    );
                    return;
                  }

                  const res = await updateCreatePictorialObservation(
                    pictorialObservationLearnerAnswer,
                    localStorage.getItem("access")
                  );
                  if (typeof res !== "string") {
                    openSnack(
                      translation.answerSuccessfullySubmit ||
                        "Answer Successfully Submitted",
                      true
                    );
                    setPictorialObservationLearnerAnswer(res);

                    if (!isActivityDone) {
                      const isUpdateSuccess =
                        await updateLearnerActivityProgressFunctionCall({
                          profileDetails: profileDetails,
                          currLanguage: currLanguage,
                          teacherCurrentActivityId: teacherCurrentActivity.id,
                          accessToken: localStorage.getItem("access")!,
                          learnerLessonProgressList,
                          currLessonIndex,
                          setLearnerLessonProgressList,
                          setDisplayObtainedCertificateModalInfo,
                        });
                    }
                  } else {
                    openSnack(
                      translation.answerFailedSubmit ||
                        "Answer Submission Failed",
                      false
                    );
                  }

                  // navigate(String(prevPath));
                }}
              />
            </Box>
          )}
        </Box>

        {isSameProfile && (
          <TeacherLessonActivityScreenDoneButton
            isDisplayButton={false}
            activityId={teacherCurrentActivity.id}
          />
        )}
      </Box>
    </Box>
  );
};

export default TeacherPictorialObservationActivityScreen;

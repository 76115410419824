import {  FormControlLabel, Radio } from "@mui/material";
import React from "react";
import {
  API_Mcq_Option,
} from "types/teacher";

export const iconButtonStyle = {
  color: "primary.main",
};

type Props = {
  isActivityDone: boolean;
  mcqOption: API_Mcq_Option;
  selectedOptions: API_Mcq_Option[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<API_Mcq_Option[]>>;
}

const McqOption = ({
  isActivityDone,
  mcqOption,
  selectedOptions,
  setSelectedOptions,
}: Props) => {
  const isSelected =
    selectedOptions.filter(
      (selectedOption) => selectedOption.id === mcqOption.id
      ).length > 0;
    
  const handleSelect = () => {
    if (isSelected || isActivityDone) {
      return;
    }
    const selectedOptionsCopy: API_Mcq_Option[] = JSON.parse(
      JSON.stringify(selectedOptions)
    );
    const index = selectedOptionsCopy.findIndex(
      (selectedOption) =>
        selectedOption.mcq_question === mcqOption.mcq_question
    );
    if (index === -1) {
      setSelectedOptions([...selectedOptionsCopy, mcqOption]);
    } else {
      selectedOptionsCopy[index] = mcqOption;
      setSelectedOptions(selectedOptionsCopy);
    }
  }

  return (
    <FormControlLabel
      onClick={handleSelect}
      sx={{
        cursor: isSelected || isActivityDone ? "" : "pointer",  
        m: 0,
      }}
      value={mcqOption.name}
      control={
        <Radio
          color="primary"
          checked={isSelected || (isActivityDone && mcqOption.is_correct)}
          inputProps={{ "aria-label": mcqOption.name }}
        />
      }
      label={mcqOption.name}
    />
  );
};

export default McqOption;
import axios from "api/axiosConfig";

import { API_Project } from "types/project";
import { API_Create_Backpack_Response } from "types/backpack";

import { AxiosError } from "axios";

export const getBackpackProjectList = async (
  language: string,
  groupID: number
) => {
  try {
    const res = await axios.get(
      `${language}/profile/backpack/${groupID}/projects/list/v2`
    );
    const data: API_Project[] = await res.data;
    return { projects: data };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createBackpackItem = async (
  projectID: number,
  groupID: number
) => {
  try {
    const res = await axios.post("en/profile/backpack/create", {
      project: projectID,
      group: groupID,
    });
    const data: API_Create_Backpack_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteBackpackItem = async (
  projectIDs: number[],
  groupID: number
) => {
  try {
    const res = await axios.post("en/profile/backpack/delete", {
      project: projectIDs,
      group: groupID,
    });
    await res.data;
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createFavItem = async (projectID: number, groupID: number) => {
  try {
    const res = await axios.post("en/profile/favourite/create", {
      project: projectID,
      group: groupID,
    });
    const data: API_Create_Backpack_Response = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getFavProjectList = async (language: string, groupID: number) => {
  try {
    const res = await axios.get(
      `${language}/profile/favourite/${groupID}/projects/list`
    );
    const data: API_Project[] = await res.data;
    return { projects: data };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteFavItem = async (projectIDs: number[], groupID: number) => {
  try {
    const res = await axios.post("en/profile/favourite/delete", {
      project: projectIDs,
      group: groupID,
    });
    await res.data;
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};


import { Box, Typography } from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";

import useStore from "store/store";
import { API_Lesson_Learning_Moment } from "types/project";
import { translation } from "constants/translation";
import LearningMomentQuestionNameComponent from "./LearningMomentQuestionNameComponent";
import LearningMomentBoxes from "./LearningMomentBoxes";
import { learningMomentTabIconStyle } from "../LearningMomentPhotoScreen";
import EvaluationMomentQuestionName from "./EvaluationMomentQuestionName";
import EvaluationMomentBoxes from "./EvaluationMomentBoxes";

type Props = {
  assessment?: boolean;
  hideCheckbox?: boolean;
  learningMomentObject: API_Lesson_Learning_Moment;
};

const LearningMomentsSection = ({
  assessment,
  hideCheckbox,
  learningMomentObject,
}: Props) => {
  const { childrenCount, currScheduleObj } = useStore((state) => ({
    childrenCount: state.currGroup.children.length,
    currScheduleObj: state.currScheduleObj,
  }));

  const isLearningMomentQuestionDone =
    currScheduleObj.learning_moments.length > 0
      ? currScheduleObj.learning_moments.includes(learningMomentObject.id!)
      : false;

  return (
    <Box
      sx={{
        mt: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: 1,
          minHeight: 28,
        }}
      >
        {!hideCheckbox && (
          <>
            {isLearningMomentQuestionDone ? (
              <CircleCheckedFilled
                sx={{ ...learningMomentTabIconStyle }}
                color="primary"
              />
            ) : (
              <CircleUnchecked
                sx={{ ...learningMomentTabIconStyle }}
                color="primary"
              />
            )}
          </>
        )}
        <ListAltOutlinedIcon sx={{ ...learningMomentTabIconStyle }} />
        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
          {hideCheckbox
            ? translation.evaluationMoment
            : translation.milestoneCheck}
        </Typography>
      </Box>

      {childrenCount > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: { xs: 1, sm: 3.5 },
            position: "relative",
            height: "100%",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {!!learningMomentObject.questions?.length ? (
              <>
                {learningMomentObject.questions?.map((question, index) => (
                  <Box
                    key={question.id}
                    sx={{ display: "flex", flexDirection: "column", pt: 1 }}
                  >
                    <EvaluationMomentQuestionName
                      index={index}
                      name={question.name!}
                      fundamentalSet={question.fundamental_set}
                    />
                    <EvaluationMomentBoxes
                      learningMomentID={learningMomentObject.id!}
                      learningMomentQuestionID={question.id!}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <>
                <LearningMomentQuestionNameComponent
                  key="learn-question"
                  name={learningMomentObject.name!}
                  description={learningMomentObject.description!}
                  milestoneId={learningMomentObject.milestone!}
                  fundamentalId={learningMomentObject.fundamental!}
                />
                <LearningMomentBoxes
                  key="learn-moment-boxes"
                  learningMomentID={learningMomentObject.id!}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LearningMomentsSection;

import { Box } from "@mui/material";
import { useCallback } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

type Props = {
    setDrawerWidth: React.Dispatch<React.SetStateAction<string>>;
}

export const Resizer = ({
    setDrawerWidth,
}: Props) => {
    const handleMouseDown = () => {
        document.body.classList.add("is-dragging");
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.body.classList.remove("is-dragging");
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(
        (e: any) => {
            const newVWidth =
                (100 *
                    (document.body.offsetLeft +
                        document.body.offsetWidth -
                        e.clientX +
                        8)) /
                window.innerWidth;

            if (newVWidth >= 40 && newVWidth <= 90) {
                setDrawerWidth(`${newVWidth}vw`);
                localStorage.setItem("drawerWidth", `${newVWidth}vw`);
            }
        },
        [setDrawerWidth]
    );

    return (
        <Box
            className="resizer"
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F2F2F2",
                zIndex: 10,
                cursor: "col-resize",
                opacity: 0,
                transition: "all 200ms ease-in-out",
                "&:hover": {
                    opacity: 1,
                    transition: "all 200ms ease-in-out",
                },
            }}
            onMouseDown={handleMouseDown}
        >
            <DragIndicatorIcon sx={{ opacity: 0.75, fontSize: "1rem" }} />
        </Box>
    );
};

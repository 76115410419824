import CloseIcon from "@mui/icons-material/Close";

import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Stack,
  TextField,
  Avatar,
  IconButton,
} from "@mui/material";

import { createPortfolioObservation } from "api/portfolio-api";
import { useParams } from "react-router-dom";
import { API_Child } from "types/portfolio";
import { useEffect, useState } from "react";
import useStore from "store/store";
import { translation } from "constants/translation";

import Button from "components/Button/Button";
import Logo from "images/logo-2.png";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import useSnack from "hooks/useSnack";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { GENERAL_DATE_FORMAT } from "constants/constantInGeneral";

const CreateObservationModal = ({
  open,
  handleClose,
  milestoneId,
}: {
  open: boolean;
  handleClose: () => void;
  milestoneId: number;
}) => {
  const openSnack = useSnack();
  const { childID } = useParams();
  const { setIsRefreshPortfolioObservation } = useStore((state) => ({
    setIsRefreshPortfolioObservation: state.setIsRefreshPortfolioObservation,
  }));

  // DATE
  const today = new Date().toLocaleDateString("en-ca");

  const initialFormData = {
    child: Number(childID!),
    milestone: milestoneId,
    image: "",
    caption: "",
    is_published: true,
    completed_at: moment(today).format(GENERAL_DATE_FORMAT),
  };

  const [formData, setFormData] =
    useState<API_Child.CreatePortfolioObservation>(initialFormData);

  useEffect(() => {
    setFormData((prev: API_Child.CreatePortfolioObservation) => ({
      ...prev,
      milestone: milestoneId,
    }));
  }, [milestoneId]);

  const handleSubmit = async () => {
    if(formData.image === "") {
      openSnack("Image is Required", false);
      return;
    }
    console.log(formData)
    const res = await createPortfolioObservation(formData);
    console.log(res)
    if (typeof res !== "string") {
      setIsRefreshPortfolioObservation(true);
      handleClose();
      setFormData(initialFormData);
      openSnack(translation.createSuccess, true);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            p: 3,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            borderRadius: "10px",
            // height: "90vh",
            // width: "80vw",
            overflow: "overlay",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          <Stack
            component="form"
            sx={{ margin: "auto", my: "16px", alignItems: "center" }}
            spacing={1}
            noValidate
            autoComplete="off"
          >
            <ObservationImage formData={formData} setFormData={setFormData} />
            <Stack spacing={1} direction="column">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  maxDate={moment(today)}
                  label={translation.observedOn}
                  inputFormat={GENERAL_DATE_FORMAT}
                  value={formData.completed_at}
                  onChange={(newValue: moment.Moment | null) => {
                    if (newValue !== null) {
                      setFormData(
                        (prev: API_Child.CreatePortfolioObservation) => ({
                          ...prev,
                          completed_at: newValue
                            .format(GENERAL_DATE_FORMAT)
                            .toString(),
                        })
                      );
                    } else {
                      setFormData(
                        (prev: API_Child.CreatePortfolioObservation) => ({
                          ...prev,
                          completed_at: moment(today)
                            .format(GENERAL_DATE_FORMAT)
                            .toString(),
                        })
                      );
                    }
                  }}
                  renderInput={(params) => (
                    // sx={{ minWidth: 145 }}
                    <TextField
                      {...params}
                      size="small"
                      sx={{ flex: 1, mt: 1 }}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                sx={{ minWidth: { xs: 200, sm: 500 } }}
                fullWidth
                label={translation.story}
                value={formData.caption}
                onChange={(event) => {
                  setFormData((prev: API_Child.CreatePortfolioObservation) => ({
                    ...prev,
                    caption: event.target.value,
                  }));
                }}
              />
            </Stack>

            <Button
              buttonText={translation.submit || "Submit"}
              style={{ marginTop: "20px" }}
              onClick={handleSubmit}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

const ObservationImage = ({
  formData,
  setFormData,
}: {
  formData: API_Child.CreatePortfolioObservation;
  setFormData: React.Dispatch<
    React.SetStateAction<API_Child.CreatePortfolioObservation>
  >;
}) => {
  const [currImage, setCurrImage] = useState<string>("");
  useEffect(() => {
    if (typeof formData.image === "string") {
      setCurrImage(formData.image);
    } else {
      setCurrImage(URL.createObjectURL(formData.image));
    }
  }, [formData.image]);
  //   console.log("CUR IMAGE:", currImage);
  return (
    <Box sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}>
      <Avatar
        src={currImage ? currImage : Logo}
        sx={{
          width: 200,
          height: 200,
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "8vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {`Obs`}
      </Avatar>
      <input
        accept="image/*"
        type="file"
        id={`observation-image-button`}
        hidden
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            setFormData((prev: API_Child.CreatePortfolioObservation) => ({
              ...prev,
              image: event.target.files![0],
            }));
          }
        }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20%",
          backgroundColor: "primary.main",
          opacity: 0.5,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
            transition: "all 150ms linear",
          },
        }}
      >
        <label htmlFor={`observation-image-button`}>
          <IconButton
            component="span"
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: { xs: "3vw", sm: "1.5vw" },
                color: "primary.light",
                cursor: "pointer",
                transition: "all 50ms linear",
                "&:hover": {
                  color: "txt.secondary",
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box>
    </Box>
  );
};

export default CreateObservationModal;
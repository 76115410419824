import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { slugify, unSlugify } from "helper/slugify";
import { translation } from "constants/translation";

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [tabs, setTabs] = useState<string[]>([
    translation.account || "Account",
    translation.emailPreferences || "Email Preferences",
  ]);

  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    let landingScreen = unSlugify(
      String(location.pathname).split("/").slice(-1)[0]
    );

    if (!tabs.includes(landingScreen)) {
      landingScreen = tabs[0];
      navigate(slugify(landingScreen));
    }
    setCurrentTab(landingScreen);
  }, [tabs, location.pathname, navigate]);

  return (
    <Box sx={{ display: "flex", mx: { xs: "0px", sm: "60px" }, gap: "32px" }}>
      {!isMobileView && (
        <Box
          sx={{
            maxWidth: "200px",
            height: "100%",
            position: "sticky",
            top: "96px",
            my: 3,
            flexShrink: 0,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h6">{translation.settings}</Typography>
          </Box>

          <TabsContainer
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </Box>
      )}

      {isMobileView && (
        <Drawer
          id="admin-menu-drawer"
          anchor="right"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          {" "}
          <Box sx={{ p: 2 }}>
            <TabsContainer
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </Box>
        </Drawer>
      )}

      <Container
        sx={{
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
          flex: 1,
          alignSelf: "start",
          my: 3,
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            mb: "6px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h4">{currentTab}</Typography>
          {isMobileView && (
            <Box
              id="admin-menu-drawer-mobile"
              aria-expanded={isDrawerOpen}
              onClick={toggleDrawer}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContents: "center",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                sx={{ display: { xs: "block", sm: "none" } }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <Outlet context={{ setCurrentTab }} />
      </Container>
    </Box>
  );
};

const TabsContainer = ({
  tabs,
  currentTab,
  setCurrentTab,
}: {
  tabs: string[] | undefined;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        mt: "24px",
      }}
    >
      {tabs?.map((text, index) => (
        <Tabs
          text={text}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          key={`${text}-${index}`}
        />
      ))}
    </Box>
  );
};

const Tabs = ({
  text,
  setCurrentTab,
  currentTab,
}: {
  text: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  currentTab: string;
}) => {
  const navigate = useNavigate();

  return (
    <Box
      id={`admin-tab-${text}`}
      sx={{
        backgroundColor: `${
          currentTab === text ? "rgba(209, 228, 227, 0.37)" : "#fff"
        }`,
        border: "1px solid #98C4C5",
        borderRadius: "10px",
        p: "12px 16px",
        height: "44px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        cursor: "pointer",
        transition: "all 100ms linear",
        "&:hover": {
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms linear",
        },
      }}
      onClick={() => {
        setCurrentTab(text);
        navigate(slugify(text));
      }}
    >
      {text === "Email Preferences" ? (
        <NotificationsActiveIcon
          sx={{
            background: "white",
            borderRadius: "999px",
            p: "4px",
          }}
        />
      ) : (
        <SettingsIcon
          sx={{
            background: "white",
            borderRadius: "999px",
            p: "4px",
          }}
        />
      )}
      <Typography sx={{ fontSize: "16px", color: "txt.dark2" }}>
        {text === "Trehaus Curriculum Specialist" ? "Trehaus C.S." : text}
      </Typography>
    </Box>
  );
};
export default SettingsPage;

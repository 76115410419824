import React, { useEffect, useState } from "react";

import { Navigate, Outlet } from "react-router-dom";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import useStore from "store/store";

import { CURLANG} from "constants/url";
import { isProfileInFundamentalEditableRoleList } from "helper/helper";

const EditorOnlyRouteWithoutTrehaus = ({
  isAuth,
  children,
}: {
  isAuth?: boolean;
  children?: React.ReactElement;
}) => {
  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));
  const [canRender, setCanRender] = useState<boolean>(false);

  useEffect(() => {
    if (profileDetails.id !== -1) {
      setCanRender(true);
    }
  }, [profileDetails]);

  if (
    canRender &&
    isProfileInFundamentalEditableRoleList(profileDetails.role)
  ) {
    return children ? children : <Outlet />;
  } else {
    if (canRender) {
      return <Navigate to={`${CURLANG(currLanguage)}/cannot-access`} replace />;
    } else {
    }
    return <LoadingIndicator />;
  }
};

export default EditorOnlyRouteWithoutTrehaus;

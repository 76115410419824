import React, { PropsWithChildren } from "react";
import { useDraggable } from "@dnd-kit/core";
import { Box, Typography } from "@mui/material";
import { API_Drag_And_Drop_Question } from "types/teacher";

export interface Props {
  dndQuestion: API_Drag_And_Drop_Question;
}

export const dndCardStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  minWidth: "auto",
  bgcolor: "white",
  p: 2,
  borderRadius: "4px",
  margin: "8px 0",
  border: "1px solid #C9C9C9",
  backgroundColor: "white",
  boxShadow: "0px 4px 8px 0px rgba(45, 55, 72, 0.10)",
};

export const dndCardTextStyle = {
  fontSize: 16,
  flexGrow: 1,
};

const DraggableCard = ({ children, dndQuestion }: PropsWithChildren<Props>) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: dndQuestion.id,
    disabled: dndQuestion.isPutCorrectly,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Box
      ref={setNodeRef}
      style={{ ...style, width: "auto" }}
      {...listeners}
      {...attributes}
    >
      <Box
        sx={{
          ...dndCardStyle,
          cursor: dndQuestion.isPutCorrectly ? "default" : "pointer",
          "&:hover": {
            backgroundColor: dndQuestion.isPutCorrectly
              ? "default"
              : "primary.light",
          },
          opacity: dndQuestion.isPutCorrectly ? 0.6 : 1,
        }}
      >
        <Typography sx={dndCardTextStyle}>{dndQuestion.question}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", paddingLeft: 1 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9.16668 15C9.16668 15.9167 8.41668 16.6667 7.50001 16.6667C6.58334 16.6667 5.83334 15.9167 5.83334 15C5.83334 14.0833 6.58334 13.3333 7.50001 13.3333C8.41668 13.3333 9.16668 14.0833 9.16668 15ZM7.50001 8.33334C6.58334 8.33334 5.83334 9.08334 5.83334 10C5.83334 10.9167 6.58334 11.6667 7.50001 11.6667C8.41668 11.6667 9.16668 10.9167 9.16668 10C9.16668 9.08334 8.41668 8.33334 7.50001 8.33334ZM7.50001 3.33334C6.58334 3.33334 5.83334 4.08334 5.83334 5C5.83334 5.91667 6.58334 6.66667 7.50001 6.66667C8.41668 6.66667 9.16668 5.91667 9.16668 5C9.16668 4.08334 8.41668 3.33334 7.50001 3.33334ZM12.5 6.66667C13.4167 6.66667 14.1667 5.91667 14.1667 5C14.1667 4.08334 13.4167 3.33334 12.5 3.33334C11.5833 3.33334 10.8333 4.08334 10.8333 5C10.8333 5.91667 11.5833 6.66667 12.5 6.66667ZM12.5 8.33334C11.5833 8.33334 10.8333 9.08334 10.8333 10C10.8333 10.9167 11.5833 11.6667 12.5 11.6667C13.4167 11.6667 14.1667 10.9167 14.1667 10C14.1667 9.08334 13.4167 8.33334 12.5 8.33334ZM12.5 13.3333C11.5833 13.3333 10.8333 14.0833 10.8333 15C10.8333 15.9167 11.5833 16.6667 12.5 16.6667C13.4167 16.6667 14.1667 15.9167 14.1667 15C14.1667 14.0833 13.4167 13.3333 12.5 13.3333Z"
              fill="#8C8C8C"
            />
          </svg>
        </Box>
      </Box>
    </Box>
  );
};

export default DraggableCard;

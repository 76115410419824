import { AxiosError } from "axios";
import oriAxios from "axios";
import {
  API_Announcement,
  API_Announcement_File_Update,
  API_Announcement_Profile,
  API_Create_Announcement,
  API_Create_Document,
  API_Document,
  API_Update_Announcement,
  API_Update_Announcement_Response_Information,
  API_Update_Document,
} from "types/announcement";
import axios from "api/axiosConfig";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

export const getAnnouncementList = async (
  language: string,
  schoolId: number,
  isResponseRequired?: boolean
) => {
  try {
    const res = await axios.get(
      `${language}/announcement/school/${schoolId}/list?is_response_required=${!!isResponseRequired}`
    );
    const data: API_Announcement[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getAnnouncementListByChild = async (
  language: string,
  childId: number,
  isResponseRequired?: boolean
) => {
  try {
    const res = await axios.get(
      `${language}/announcement/child/${childId}/list?is_response_required=${!!isResponseRequired}`
    );
    const data: API_Announcement[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createAnnouncement = async (
  language: string,
  announcementData: API_Create_Announcement,
  fileNameToCopyList: string[]
) => {
  try {
    const res = await axios.post(
      `${language}/announcement/create?file_name_list=${fileNameToCopyList.join(
        ";;"
      )}`,
      announcementData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateAnnouncement = async (
  language: string,
  announcementId: number,
  announcementData: API_Update_Announcement
) => {
  try {
    const res = await axios.patch(
      `${language}/announcement/${announcementId}/update`,
      announcementData
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteAnnouncement = async (
  language: string,
  announcementId: number
) => {
  try {
    await axios.delete(`${language}/announcement/${announcementId}/delete`);

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getAnnouncementProfileList = async (
  language: string,
  announcementId: number
) => {
  try {
    const res = await axios.get(
      `${language}/announcement/announcement-profile/list?announcement_id=${announcementId}`
    );
    const data: API_Announcement_Profile[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getAnnouncementProfile = async (
  language: string,
  childId: number,
  announcementId: number
) => {
  try {
    const res = await axios.get(
      `${language}/announcement/announcement-profile/get?child_id=${childId}&announcement_id=${announcementId}`
    );
    const data: API_Announcement_Profile = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateAnnouncementProfile = async (
  language: string,
  announcementProfileId: number,
  announcementProfileData: API_Announcement_Profile
) => {
  try {
    const res = await axios.patch(
      `${language}/announcement/announcement-profile/${announcementProfileId}/update`,
      announcementProfileData
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const addAnnouncementProfileFile = async (
  language: string,
  announcementProfileId: number,
  files: File[]
) => {
  try {
    const res = await axios.post(
      `${language}/announcement/announcement-profile/${announcementProfileId}/file/add`,
      {
        files,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteAnnouncementProfileFile = async (
  language: string,
  fileId: number
) => {
  try {
    await axios.delete(
      `${language}/announcement/announcement-profile/file/${fileId}/delete`
    );
    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const addAnnouncementFile = async (
  language: string,
  announcementId: number,
  files: File[],
  fileNameToCopyList: string[]
) => {
  try {
    const res = await axios.post(
      `${language}/announcement/${announcementId}/file/add?file_name_list=${fileNameToCopyList.join(
        ";;"
      )}`,
      {
        files,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateAnnouncementFile = async (
  language: string,
  existingAnnouncementFileUpdateList: API_Announcement_File_Update[]
) => {
  try {
    await axios.post(
      `${language}/announcement/file/bulk-update`,
      existingAnnouncementFileUpdateList
    );

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteAnnouncementFile = async (
  language: string,
  fileId: number
) => {
  try {
    await axios.delete(`${language}/announcement/file/${fileId}/delete`);

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const seenAnnouncement = async (
  announcementId: number,
  profiles: number[]
) => {
  try {
    const res = await axios.patch(
      `en/announcement/${announcementId}/seen-announcement`,
      {
        profiles: profiles,
      }
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const starAnnouncement = async (
  announcementId: number,
  profiles: number[],
  isStarred: boolean
) => {
  try {
    const res = await axios.patch(
      `en/announcement/${announcementId}/star-announcement`,
      {
        profiles: profiles,
        is_starred: isStarred,
      }
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createAnnouncementResponse = async (
  language: string,
  announcementResponseData: API_Update_Announcement_Response_Information
) => {
  try {
    const res = await axios.post(
      `${language}/announcement/response-information/create`,
      announcementResponseData
    );
    const data: API_Announcement = res.data;
    console.log("res: ", res);
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateAnnouncementResponse = async (
  language: string,
  announcementResponseData: API_Update_Announcement_Response_Information
) => {
  try {
    const res = await axios.patch(
      `${language}/announcement/response-information/${announcementResponseData.id}/update`,
      announcementResponseData
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteAnnouncementResponse = async (
  announcementResponseId: number
) => {
  try {
    await axios.delete(
      `en/announcement/response-information/${announcementResponseId}/delete`
    );

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

// Documents

export const getDocumentList = async (language: string, schoolId: number) => {
  try {
    const res = await axios.get(
      `${language}/announcement/document/school/${schoolId}/list`
    );
    const data: API_Document[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getDocumentListByChild = async (
  language: string,
  childId: number
) => {
  try {
    const res = await axios.get(
      `${language}/announcement/document/child/${childId}/list`
    );
    const data: API_Document[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createDocument = async (
  language: string,
  documentData: API_Create_Document
) => {
  try {
    const res = await axios.post(
      `${language}/announcement/document/create`,
      documentData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const data: API_Document[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateDocument = async (
  language: string,
  documentId: number,
  documentData: API_Update_Document
) => {
  try {
    const res = await axios.patch(
      `${language}/announcement/document/${documentId}/update`,
      documentData
    );
    const data: API_Document = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteDocument = async (language: string, documentId: number) => {
  try {
    await axios.delete(
      `${language}/announcement/document/${documentId}/delete`
    );

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const addDocumentFile = async (
  language: string,
  documentId: number,
  files: File[]
) => {
  try {
    const res = await axios.post(
      `${language}/announcement/document/${documentId}/file/add`,
      {
        files,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const data: API_Announcement = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteDocumentFile = async (language: string, fileId: number) => {
  try {
    await axios.delete(
      `${language}/announcement/document/file/${fileId}/delete`
    );

    return "Success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const downloadDocumentFile = async (
  urls: string[],
  names: string[],
  zipName: string,
  setIsDownloadLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // console.log("urls: ", urls);
  setIsDownloadLoading(true);
  if (urls.length === 1) {
    await oriAxios({
      url: urls[0] + "?add-this-to-prevent-read-from-cache",
      method: "GET",
      responseType: "blob",
    }).then((response: any) => {
      // console.log("file response: ", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${names[0]}`);
      document.body.appendChild(link);
      link.click();
    });
  } else {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = zipName;

    urls.forEach(function (url, index) {
      const filename = names[index];
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(
        url + "?add-this-to-prevent-read-from-cache",
        function (err: any, data: any) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
            });
          }
        }
      );
    });
  }
  setIsDownloadLoading(false);
};

export const getIsNeedResponse = async (childId: number) => {
  try {
    const res = await axios.get(
      `en/announcement/child/${childId}/get-is-need-response`
    );
    const data: boolean = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getIsParentNeedResponse = async () => {
  try {
    const res = await axios.get(`en/announcement/get-is-parent-need-response`);
    const data: boolean = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

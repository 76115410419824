import { useEffect, useMemo, useState } from "react";
import { Page, Image, View } from "@react-pdf/renderer";
import { pageSize, styles } from "./constant";
import { checkIsChineseChar } from "helper/helper";
import { PDFInfoType } from "../Preview/constant";
import Background from "images/children-portfolio-v2/concluding-remark.png";
import ConcludingRemarksPageEnglish from "./ConcludingRemarksPageEnglish";
import ConcludingRemarksPageChinese from "./ConcludingRemarksPageChinese";

const ConcludingRemarkPage = ({ PDFInfo }: { PDFInfo: PDFInfoType }) => {
  // chinese full page limit: length 395,  6 lines
  // english full page limit: length 1900, 7 lines
  // chinese half (all in 1 page) limit: length 245, 4 lines
  // english half (all in 1 page) limit: length ,


  const [multipleLanguages, setMultipleLanguages] = useState<boolean>(false);
  const [isChineseFirst, setIsChineseFirst] = useState<boolean>(false);

  const filteredParagraphs = useMemo(
    () =>
      PDFInfo.concludingRemarkText
        .split(/\r\n|\n/)
        .filter((paragraph) => paragraph.trim() !== ""),
    [PDFInfo.concludingRemarkText]
  );

  const { englishParagraphs, chineseParagraphs } = useMemo(
    () =>
      filteredParagraphs.reduce(
        (acc, paragraph) => {
          if (paragraph.split("").some(checkIsChineseChar)) {
            acc.chineseParagraphs.push(paragraph);
          } else {
            acc.englishParagraphs.push(paragraph);
          }
          return acc;
        },
        {
          englishParagraphs: [] as string[],
          chineseParagraphs: [] as string[],
        }
      ),
    [filteredParagraphs]
  );

  const englishParagraphsWorLength = useMemo(
    () =>
      englishParagraphs.reduce(
        (totalLength: any, str: any) => totalLength + str.length,
        0
      ),
    [englishParagraphs]
  );

  const chineseParagraphWordsLength = useMemo(
    () =>
      chineseParagraphs.reduce(
        (totalLength: any, str: any) => totalLength + str.length,
        0
      ),
    [chineseParagraphs]
  );

  // Determine if multiple languages are present
  useEffect(() => {
    if (englishParagraphs.length > 0 && chineseParagraphs.length > 0) {
      setMultipleLanguages(true);
    }
  }, [englishParagraphs, chineseParagraphs]);

  useEffect(() => {
    if (filteredParagraphs.length > 0) {
      setIsChineseFirst(checkIsChineseChar(filteredParagraphs[0]));
    }
  }, [filteredParagraphs]);

  // Render the appropriate pages based on the content
  if (englishParagraphs.length > 0 && chineseParagraphs.length > 0) {
    // Render one page if both languages fit within the limits
    if (
      englishParagraphsWorLength <= 1100 &&
      chineseParagraphWordsLength <= 310
    ) {
      return (
        <Page size={pageSize} style={styles.body}>
          <View style={styles.main} wrap={false}>
            <Image src={Background} style={styles.background} />
            {isChineseFirst ? (
              <View>
                {chineseParagraphs.length > 0 && (
                  <ConcludingRemarksPageChinese
                    multipleLanguages={multipleLanguages}
                    paragraphs={chineseParagraphs}
                    englishFirst={false}
                  />
                )}
                {englishParagraphs.length > 0 && (
                  <ConcludingRemarksPageEnglish
                    multipleLanguages={multipleLanguages}
                    paragraphs={englishParagraphs}
                    englishFirst={false}
                  />
                )}
              </View>
            ) : (
              <View>
                {englishParagraphs.length > 0 && (
                  <ConcludingRemarksPageEnglish
                    multipleLanguages={multipleLanguages}
                    paragraphs={englishParagraphs}
                    englishFirst={true}
                  />
                )}
                {chineseParagraphs.length > 0 && (
                  <ConcludingRemarksPageChinese
                    multipleLanguages={multipleLanguages}
                    paragraphs={chineseParagraphs}
                    englishFirst={true}
                  />
                )}
              </View>
            )}
          </View>
        </Page>
      );
    } else if (
      (englishParagraphsWorLength <= 1100 &&
        chineseParagraphWordsLength > 310) ||
      (englishParagraphsWorLength > 1100 && chineseParagraphWordsLength <= 310)
    ) {
      return (
        <>
          {isChineseFirst ? (
            <>
              <ConcludingRemarksPageChinese
                multipleLanguages={multipleLanguages}
                paragraphs={chineseParagraphs}
                englishFirst={false}
                exception={true}
              />
              <ConcludingRemarksPageEnglish
                multipleLanguages={multipleLanguages}
                paragraphs={englishParagraphs}
                englishFirst={false}
                exception={true}
              />
            </>
          ) : (
            <>
              <ConcludingRemarksPageEnglish
                multipleLanguages={multipleLanguages}
                paragraphs={englishParagraphs}
                englishFirst={true}
                exception={true}
              />
              <ConcludingRemarksPageChinese
                multipleLanguages={multipleLanguages}
                paragraphs={chineseParagraphs}
                englishFirst={true}
                exception={true}
              />
            </>
          )}
        </>
      );
    } else {
      // Render multiple pages for both languages
      return (
        <>
          {isChineseFirst ? (
            <>
              {chineseParagraphs.length > 0 && (
                <ConcludingRemarksPageChinese
                  multipleLanguages={multipleLanguages}
                  paragraphs={chineseParagraphs}
                  englishFirst={false}
                />
              )}
              {englishParagraphs.length > 0 && (
                <ConcludingRemarksPageEnglish
                  multipleLanguages={multipleLanguages}
                  paragraphs={englishParagraphs}
                  englishFirst={false}
                />
              )}
            </>
          ) : (
            <>
              {englishParagraphs.length > 0 && (
                <ConcludingRemarksPageEnglish
                  multipleLanguages={multipleLanguages}
                  paragraphs={englishParagraphs}
                  englishFirst={true}
                />
              )}
              {chineseParagraphs.length > 0 && (
                <ConcludingRemarksPageChinese
                  multipleLanguages={multipleLanguages}
                  paragraphs={chineseParagraphs}
                  englishFirst={true}
                />
              )}
            </>
          )}
        </>
      );
    }
  } else if (englishParagraphs.length > 0) {
    return (
      <ConcludingRemarksPageEnglish
        multipleLanguages={multipleLanguages}
        paragraphs={englishParagraphs}
        englishFirst={checkIsChineseChar(filteredParagraphs[0])}
      />
    );
  } else {
    return (
      <ConcludingRemarksPageChinese
        multipleLanguages={multipleLanguages}
        paragraphs={chineseParagraphs}
        englishFirst={!checkIsChineseChar(filteredParagraphs[0])}
      />
    );
  }
};

export default ConcludingRemarkPage;

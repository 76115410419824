import React from "react";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageFour from "images/children-photo-book/page-4.png";
import PhotoBookPageFourTop from "images/children-photo-book/page-4-top.png";
import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageFourCaptionInfo,
  photoBookPageFourCaptionPositionList,
  photoBookPageFourImagePositionList,
} from "../photoBookCommonConstant";

export const PhotoBookPageFourComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imagePositionList = photoBookPageFourImagePositionList(images, scale);
  const captionPositionList = photoBookPageFourCaptionPositionList(
    images,
    scale
  );
  const captionInfo = photoBookPageFourCaptionInfo(scale);
  return (
    <>
      <Image
        src={PhotoBookPageFour}
        alt={"photo-book-page-4"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      {imagePositionList.map((imageInfo) => {
        return (
          <Image
            src={imageInfo.src}
            alt={"photo-book-page-1"}
            objectFit="cover"
            style={{
              position: "absolute",
              top: imageInfo.top,
              left: imageInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
            }}
          />
        );
      })}
      <Image
        src={PhotoBookPageFourTop}
        alt={"photo-book-page-4-top"}
        objectFit="cover"
        style={{
          ...photoBookImageStyle,
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionInfo.top}
            left={captionPositionInfo.left}
            width={captionPositionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize * scale}
            textAlign="center"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageFourComponent;

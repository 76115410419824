import { StoreSlice } from "./store";

export interface GallerySlice {
  isRefreshLearningMomentPhotoVideoGallery: boolean;
  setIsRefreshLearningMomentPhotoVideoGallery: (
    isRefreshLearningMomentPhotoVideoGallery: boolean
  ) => void;
}

export const createGallerySlice: StoreSlice<GallerySlice> = (set, get) => ({
  isRefreshLearningMomentPhotoVideoGallery: true,
  setIsRefreshLearningMomentPhotoVideoGallery: (
    isRefreshLearningMomentPhotoVideoGallery: boolean
  ) => {
    set((prev: GallerySlice) => ({
      ...prev,
      isRefreshLearningMomentPhotoVideoGallery:
        isRefreshLearningMomentPhotoVideoGallery,
    }));
  },
});

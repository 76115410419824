import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getAllTcsProfile } from "api/profile-api";
import { API_Profile_Short } from "types/profile";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

//mui datagrid
import {
  GridColumns,
  DataGrid,
  GridRowId,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridRowModel,
  GridColumnVisibilityModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";

//School
import { getAllSchool } from "api/school-api";
import { API_School } from "types/school";

//Profile
import { deleteProfile, updateProfile } from "api/auth-api";
import {
  ErrorMessage,
  UpdateProfile,
  UpdateProfileResponse,
} from "types/auth";

//warning
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import {
  DOB_COLUMN,
  processRowUpdateBody,
  ROLE_COLUMN,
} from "./constants/gridColumn";

import { DATA_GRID_STYLE } from "./constants/styling";

import {
  ACTIONS_COLUMN,
  EditToolbarProfile,
  EMAIL_COLUMN,
  FULL_NAME_COLUMN,
  GROUP_COLUMN,
  ID_COLUMN,
  IMAGE_COLUMN,
  PREFERRED_NAME_COLUMN,
  SCHOOL_COLUMN,
} from "./constants/gridColumnHelper";

import EditGroupModal from "./components/EditGroupModal";

const TrehausCurriculumSpecialistManagePage = () => {
  const openSnack = useSnack();
  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));
  const [schoolList, setSchoolList] = useState<API_School[]>([]);
  const [tcsList, setTcsList] = useState<API_Profile_Short[]>([]);
  const [rows, setRows] = React.useState(tcsList);
  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "",
    confirmDelete: false,
  });
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleDelete = (id: GridRowId) => {
    console.log("calling handle delete:");
    //call delete api
    const callDeleteProfile = async () => {
      const res = await deleteProfile(String(id));
      if (typeof res === "string" && res.length >= 1) {
        //if delete success, it will return ""
        openSnack(res, false);
      } else {
        openSnack(`Delete Trehaus Curriculum Specialist successfully!`, true);
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    callDeleteProfile();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    //double function loop here to prevent it's initialiated once passing
    //call delete api
    const preferred_name = rows.filter((row) => row.id === id)[0]
      ?.preferred_name;
    handleOpenDialog();
    setWarningBody({
      id: Number(id),
      warningTitle: `Delete Trehaus Curriculum Specialist "${preferred_name}"?`,
      confirmDelete: false,
    });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const body = processRowUpdateBody(newRow);
    // console.log("outside body: ", body);
    const updateResponse: UpdateProfileResponse | ErrorMessage =
      await updateProfile(
        String(newRow.id),
        body as UpdateProfile,
        localStorage.getItem("access")
      );
    // console.log("updateResponse: ", updateResponse);
    if ("error" in updateResponse) {
      openSnack(updateResponse.error.join(" "), false);
      return updateResponse.error.join(" ");
    } else {
      openSnack("Trehaus Curriculum Specialist is updated!", true);
    }
    return newRow;
  };

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  //handling clicks
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const [profileId, setProfileId] = useState<number>(-1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setProfileId(-1);
    setOpenModal(false);
  };
  const handleOpenModal = (profileId: number) => {
    setProfileId(profileId);
    setOpenModal(true);
  };

  const columns: GridColumns = [
    {
      ...ID_COLUMN,
    },
    {
      ...IMAGE_COLUMN,
    },
    {
      ...EMAIL_COLUMN,
    },
    {
      ...PREFERRED_NAME_COLUMN,
    },
    {
      ...FULL_NAME_COLUMN,
    },
    {
      ...DOB_COLUMN,
    },
    {
      ...ROLE_COLUMN(profileDetails.role),
    },
    {
      ...GROUP_COLUMN(handleOpenModal),
    },
    {
      ...SCHOOL_COLUMN(schoolList),
    },
    {
      ...ACTIONS_COLUMN({
        rowModesModel,
        handleSaveClick,
        handleCancelClick,
        handleEditClick,
        handleDeleteClick,
      }),
    },
  ];

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  //use Effect
  useEffect(() => {
    const populateData = async () => {
      const tcsListRes = await getAllTcsProfile();
      setTcsList(tcsListRes);
      setRows(tcsListRes);
      const schoolRes = await getAllSchool();
      setSchoolList(schoolRes);
    };

    populateData();
  }, []);

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  const [columnVisibility, setColumnVisibility] = useState<any>(() => {
    //Return {name: true, description: true, xxx:true ......}
    const finalObj = {};
    columns.forEach((column) => {
      Object.assign(finalObj, { [column.field]: true });
    });
    return finalObj;
  });

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <EditGroupModal
        open={openModal}
        handleClose={handleCloseModal}
        profileId={profileId}
        setRows={setRows}
      />

      <Box>
        <div style={DATA_GRID_STYLE}>
          <DataGrid
            sx={{ fontSize: 18 }}
            rows={rows}
            columns={columns}
            columnVisibilityModel={columnVisibility}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onColumnVisibilityModelChange={(
              model: GridColumnVisibilityModel,
              details: GridCallbackDetails
            ) => {
              setColumnVisibility(model);
            }}
            components={{
              Toolbar: EditToolbarProfile,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel, role: "TCS" },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Box>
    </>
  );
};

export default TrehausCurriculumSpecialistManagePage;

import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { translation } from "constants/translation";
import {
  CURLANG,
  TEACHER_LESSON_DISCUSSION_PAGE,
} from "constants/url";
import useStore from "store/store";

const TeacherModuleDiscussionScreen = () => {
  const { moduleSlug } = useParams();
  const navigate = useNavigate();
  const {
    currLanguage,
    profileDetails,
    teacherCurrentModule,
    isTeacherModuleLoading,
    setIsTeacherModuleLoading,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    teacherCurrentModule: state.teacherCurrentModule,
    setTeacherCurrentActivity: state.setTeacherCurrentActivity,
    isTeacherModuleLoading: state.isTeacherModuleLoading,
    setIsTeacherModuleLoading: state.setIsTeacherModuleLoading,
  }));

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1, flex: 1 }}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 500, mb: 1 }}>
          {translation.discussion}
        </Typography>
        {teacherCurrentModule.lessons
          .filter((lesson) => lesson.discussion_topic !== "")
          .map((lesson) => {
            return (
              <Fragment key={`lesson-discussion-${lesson.id}`}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    bgcolor: "primary.light",
                    p: 2,
                    borderRadius: "10px",
                    "&:hover": {
                      bgcolor: "primary.main",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(
                      `${CURLANG(
                        currLanguage
                      )}/${TEACHER_LESSON_DISCUSSION_PAGE(
                        moduleSlug!,
                        lesson.slug
                      )}`
                    );
                  }}
                >
                  <Typography sx={{ fontSize: 22, fontWeight: 500 }}>
                    {`${translation.formatString(translation.lessonNo, {
                      number: lesson.weight,
                    })}:`}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 22, fontWeight: 500 }}
                  >{`${lesson.name}`}</Typography>
                </Box>
              </Fragment>
            );
          })}
      </Box>
    </>
  );
};

export default TeacherModuleDiscussionScreen;

import React from "react";
import { Box, Typography, Switch } from "@mui/material";
import { API_Create_Activity } from "types/project";
import { translation } from "constants/translation";

export const CompulsoryField = ({
  activity,
  setActivity,
  onChange,
}: {
  activity: API_Create_Activity;
  setActivity: React.Dispatch<React.SetStateAction<API_Create_Activity>>;
  onChange?: (input: boolean) => void;
}) => (
  <Box
    sx={{
      display: "flex",
      height: "56px",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Switch
      checked={activity.is_compulsory}
      onClick={() => {
        setActivity((prev) => ({
          ...prev,
          is_compulsory: !prev.is_compulsory,
        }));

        onChange && onChange(true);
      }}
    />
    <Typography>{translation.compulsory}</Typography>
  </Box>
);

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import {
  API_Teacher_Course,
  API_Teacher_Module,
  API_Teacher_Lesson,
  API_Teacher_Module_Learner_Progress,
} from "types/teacher";
import useStore from "store/store";
import Button from "components/Button/Button";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  getAllCourses,
  getAllModules,
  getLearnerModuleProgressList,
  getProgressedModules,
} from "api/teacher-api";
import TeacherDetailCard from "../TeacherPageComponents/TeacherDetailCard/TeacherDetailsCard";

import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import { translation } from "constants/translation";
import { SearchBarForNormalUse } from "../../TeacherAnalytic/SecondRow";

const TeacherProgressPage = () => {
  const navigate = useNavigate();
  const {
    profileDetails,
    filters,
    currLanguage,
    isTeacherModuleLoading,
    setIsTeacherModuleLoading,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    filters: state.filters,
    currLanguage: state.currLanguage,
    // isLearnerEnrolled: state.isLearnerEnrolled,
    // setIsLearnerEnrolled: state.setIsLearnerEnrolled,
    isTeacherModuleLoading: state.isTeacherModuleLoading,
    setIsTeacherModuleLoading: state.setIsTeacherModuleLoading,
  }));

  const [moduleList, setModuleList] = useState<API_Teacher_Module[]>([]);
  const [query, setQuery] = useState<string | null>(null);
  const [learnerModuleProgressList, setLearnerModuleProgressList] = useState<
    API_Teacher_Module_Learner_Progress[]
  >([]);
  const [isCurrentlyLearning, setIsCurrentlyLearning] = useState<boolean>(true);

  const populateData = async () => {
    setIsTeacherModuleLoading(true);
    // setCourses(await getAllCourses());
    const progressedModules = await getProgressedModules(
      currLanguage,
      query !== null ? query : ""
    );
    setModuleList(progressedModules);
    setIsTeacherModuleLoading(false);
  };

  const populateLearnerProgress = async () => {
    const learnerModuleProgressListRes = await getLearnerModuleProgressList(
      localStorage.getItem("access")
    );
    if (typeof learnerModuleProgressListRes !== "string") {
      setLearnerModuleProgressList(learnerModuleProgressListRes);
    }
  };

  useEffect(() => {
    populateData();
    populateLearnerProgress();
  }, []);

  useEffect(() => {
    if (query === null) return;

    const searchTimeout = setTimeout(async () => {
      populateData();
    }, 1200);

    return () => clearTimeout(searchTimeout);
  }, [query]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ textAlign: "center", mb: 1 }} variant="h5">
        {`${translation.welcome} ${profileDetails.preferred_name}`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#FBFBFB",
          borderRadius: "10px",
          p: 1,
          width: "100%",
          maxWidth: "90vw",
        }}
      >
        {/* Header bar */}
        <Box sx={{ px: 2, mb: 1 }}>
          <Typography sx={{ alignSelf: "center" }} variant="h6">
            {translation.myProgress}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            width: "100%",
            marginBottom: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              key={`currently-learning-button`}
              btnType={isCurrentlyLearning ? "filled" : "outlined"}
              arrow={false}
              style={{
                fontSize: 12,
                width: "100%",
                minWidth: 150,
                flex: 1,
                textAlign: "center",
                display: "flex",
                cursor: isCurrentlyLearning ? "default" : "pointer",
              }}
              buttonText={translation.currentlyLearning || "Currently Learning"}
              onClick={() => {
                setIsCurrentlyLearning(true);
              }}
            />

            <Button
              key={`completed-button`}
              btnType={isCurrentlyLearning ? "outlined" : "filled"}
              arrow={false}
              style={{
                fontSize: 12,
                width: "100%",
                flex: 1,
                textAlign: "center",
                display: "flex",
                cursor: isCurrentlyLearning ? "pointer" : "default",
              }}
              buttonText={translation.completed || "Completed"}
              onClick={() => {
                setIsCurrentlyLearning(false);
              }}
            />
          </Box>

          <SearchBarForNormalUse query={query} setQuery={setQuery} />
        </Box>

        <Box>
          {isTeacherModuleLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <>
              {/* YC TODO: the logic here funky, re-implement this when proper tracking of progress is done */}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "2rem",
                  justifyContent: "center",
                }}
              >
                {moduleList.map((module_, index) => {
                  const progress = learnerModuleProgressList.find(
                    (learnerModuleProgress) =>
                      learnerModuleProgress.id === module_.id
                  );
                  return isCurrentlyLearning ? (
                    progress && progress.progress_percentage !== 100 ? (
                      <TeacherDetailCard
                        key={`module-id-${module_.id}`}
                        module={module_}
                        progress={
                          progress
                            ? progress
                            : {
                                id: -1,
                                slug: "",
                                progress_percentage: 0,
                                is_enrolled: false,
                                no_of_minutes_left: 0,
                              }
                        }
                      />
                    ) : null
                  ) : progress && progress.progress_percentage === 100 ? (
                    <TeacherDetailCard
                      key={`module-id-${module_.id}`}
                      module={module_}
                      progress={
                        progress
                          ? progress
                          : {
                              id: -1,
                              slug: "",
                              progress_percentage: 0,
                              is_enrolled: false,
                              no_of_minutes_left: 0,
                            }
                      }
                    />
                  ) : null;
                })}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

// <>
//   <Box>
//     <UnderConstruction title="My Progress" />
//   </Box>
// </>

export default TeacherProgressPage;

import axios from "api/axiosConfig";
import { environment } from 'environment';

class UploadAdaptor {
  loader: any;
  abortController?: AbortController;
  uploadURL: string;
  genericErrorText: string;

  constructor(loader: any) {
    this.loader = loader;
    this.uploadURL = `${environment.backendUrl}/en/upload/file/create`;
    this.genericErrorText = "Could not upload file";
  }

  async upload() {
    const file: File = await this.loader.file;

    this.genericErrorText = `Could not upload file: ${file.name}.`;

    return new Promise<{ default: string }>((resolve, reject) => {
      const data = this._prepareData(file);
      this._send(data, resolve, reject);
    });
  }

  abort() {
    if (this.abortController) this.abortController.abort();
  }

  _prepareData(file: File) {
    const data = new FormData();
    data.append("file", file);
    return data;
  }

  async _send(
    data: FormData,
    resolve: (
      value: { default: string } | PromiseLike<{ default: string }>
    ) => void,
    reject: (reason?: any) => void
  ) {
    this.abortController = new AbortController();

    try {
      const res = await axios.post(this.uploadURL, data, {
        onUploadProgress: (progressEvent) => {
          this.loader.uploadTotal = progressEvent.total;
          this.loader.uploaded = progressEvent.loaded;
        },
        signal: this.abortController.signal,
      });
      resolve({ default: res.data.file });
    } catch (error: any) {
      if (error.name === "AbortError") {
        return reject();
      }

      return reject(`Error uploading ${error || this.genericErrorText}`);
    }
  }
}

export default UploadAdaptor;

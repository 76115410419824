import { API_Group_Full } from "types/group";

export const currGroupInitialState: API_Group_Full = {
  id: -1,
  name: "",
  description: "",
  slug: "",
  age: "Sproutlings",
  school: -1,
  children: [],
  children_school: [],
  teachers: [],
  teachers_school: [],
  communication_lvl: "",
  current_lessons: [],
  next_lessons: [],
};

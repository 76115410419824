import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { translation } from "../constants/translation";

const Rating = ({ rating }: { rating?: number }) => {
  return (
    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <StarIcon sx={{ color: `${!!rating && rating >= 0 ? "#ffc107" : "#C4C4C4"}` }} />
      <Typography sx={{ fontSize: "12px", fontWeight: "500" }}>
        {!!rating && rating >= 0
          ? `${Number(rating).toFixed(1)} / 5.0`
          : translation.noRating}
      </Typography>
    </Box>
  );
};

export default Rating;
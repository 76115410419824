import {
  API_Refresh_Number_Of_Active_User,
  API_School,
  API_School_Detail,
} from "../types/school";
import { StoreSlice } from "./store";

export interface SchoolSlice {
  schools: API_School[];
  currSchool: API_School_Detail;
  setSchools: (schools: API_School[]) => void;
  setCurrSchool: (currSchool: API_School_Detail) => void;
  numberOfActiveUser: number;
  setNumberOfActiveUser: (numberOfActiveUser: number) => void;
  refreshNumberOfActiveUserInfo: API_Refresh_Number_Of_Active_User;
  setRefreshNumberOfActiveUserInfo: (
    refreshNumberOfActiveUserInfo: API_Refresh_Number_Of_Active_User
  ) => void;
}

export const createSchoolSlice: StoreSlice<SchoolSlice> = (set, get) => ({
  schools: [],
  currSchool: {
    id: -1,
    name: "",
    slug: "",
    teachers: 0,
    parents: 0,
    children: 0,
    admin: 0,
    total: 0,
  },
  setSchools: (schools: API_School[]) => {
    set((prev: SchoolSlice) => ({
      ...prev,
      schools: schools,
    }));
  },
  setCurrSchool: (currSchool: API_School_Detail) => {
    set((prev: SchoolSlice) => ({
      ...prev,
      currSchool: currSchool,
    }));
  },
  numberOfActiveUser: -1,
  setNumberOfActiveUser: (numberOfActiveUser: number) => {
    set((prev: SchoolSlice) => ({
      ...prev,
      numberOfActiveUser: numberOfActiveUser,
    }));
  },
  refreshNumberOfActiveUserInfo: {
    isRefresh: false,
    schoolId: -1,
  },
  setRefreshNumberOfActiveUserInfo: (
    refreshNumberOfActiveUserInfo: API_Refresh_Number_Of_Active_User
  ) => {
    set((prev: SchoolSlice) => ({
      ...prev,
      refreshNumberOfActiveUserInfo: refreshNumberOfActiveUserInfo,
    }));
  },
});

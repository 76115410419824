import moment from "moment";
import {
  GENERAL_DATE_FORMAT,
  ONE_YEAR,
  SEVEN_DAYS,
  THIRTY_DAYS,
} from "../constants/constantInGeneral";
import { AnalyticPeriodType } from "../types/analytic";
import { API_Profile_Details } from "../types/profile";

export const convertURLToFile = async (url: string) => {
  // Convert "image source" (url) to "Base64"
  const toDataURL = (url: string): Promise<string> =>
    fetch(url, {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        // FIXME:
        console.log(response);
        return response.blob();
      })
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  // Convert "Base64" to javascript "File Object"
  const dataURLtoFile = (dataurl: string, filename: string) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const dataUrl = await toDataURL(url);
  const fileData = dataURLtoFile(dataUrl, "image.jpg");
  console.log(dataUrl);
  console.log(fileData);
  return fileData;
};

export const FileToBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const secondToMinuteAndSecondString = (seconds: number | any) => {
  if (isNaN(seconds)) {
    console.log("seconds is NaN");
    return "";
  }
  return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)}`;
};

export const UpperCaseWordSpacing = (inputText: string) => {
  return inputText.replace(/([A-Z])/g, " $1").trim();
};

export const UpperCaseWordUndoSpacing = (inputText: string) => {
  return inputText.replace(/\s+/g, "");
};

export const CapitalizeFirstChar = (inputText: string) => {
  return inputText[0].toUpperCase() + inputText.slice(1);
};

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;
  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

export const checkIsChineseChar = (str: any) => {
  const REGEX_CHINESE =
    /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
  const hasChinese = str?.match(REGEX_CHINESE);
  if (hasChinese) {
    return true;
  }
  return false;
};

export const formatDateToYYYYMMDD = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatDateToDMMMMYYYY = (dateString: string) =>
  moment(dateString).format("D MMMM YYYY");

export const getStartEndDate = (analyticPeriodType: AnalyticPeriodType) => {
  const today = moment(new Date(new Date().getTime() + 86400000)); //+1 day

  const todayCopy = moment(new Date(new Date().getTime() + 86400000));
  if (analyticPeriodType === SEVEN_DAYS) {
    return [
      todayCopy.subtract(7, "days").format(GENERAL_DATE_FORMAT),
      today.format(GENERAL_DATE_FORMAT),
    ];
  }

  if (analyticPeriodType === THIRTY_DAYS) {
    return [
      todayCopy.subtract(30, "days").format(GENERAL_DATE_FORMAT),
      today.format(GENERAL_DATE_FORMAT),
    ];
  }

  if (analyticPeriodType === ONE_YEAR) {
    return [
      todayCopy.subtract(364, "days").format(GENERAL_DATE_FORMAT),
      today.format(GENERAL_DATE_FORMAT),
    ];
  }
  return ["1970-01-01", today.format(GENERAL_DATE_FORMAT)];
};

export const getdiffInMonths = (endDate: Date, startDate: Date) =>
  (endDate.getFullYear() - startDate.getFullYear()) * 12 +
  (endDate.getMonth() - startDate.getMonth());

export const partitionArray = (arr: number[]): number[][] => {
  const MAX_VALUES_PER_ELEMENT = 2;
  const subArrays: number[][] = [];
  let tempArr: number[] = [];
  let sum = 0;

  arr.forEach((val, index) => {
    if (sum + val <= MAX_VALUES_PER_ELEMENT) {
      tempArr.push(val);
      sum += val;
    } else {
      subArrays.push(tempArr);
      tempArr = [val];
      sum = val;
    }

    // Push remaining values to the subarrays if end of the array is reached
    if (index === arr.length - 1) {
      subArrays.push(tempArr);
    }
  });

  return subArrays;
};

export const categorizeSentiment = (sentimentScore: number) => {
  if (sentimentScore < -0.5) {
    return "Negative";
  } else if (sentimentScore <= 0.5) {
    return "Neutral";
  } else {
    return "Positive";
  }
};

export const isProfileInTeacherRoleList = (role: string) => {
  const TeacherRoleList = ["TR"]; //Refer options.py
  return TeacherRoleList.includes(role);
};

export const isProfileInCreateGroupRoleList = (role: string) => {
  const CreateGroupRoleList = ["AD", "WAD"];
  return CreateGroupRoleList.includes(role);
};

export const isProfileInProjectEditableRoleList = (
  profileDetails: API_Profile_Details
) => {
  const ProjectEditableRoleList = ["CD", "CE", "WAD"];
  // Don't allow trehaus to edit anymore
  // if (profileDetails.school) {
  //   if (
  //     profileDetails.school.name === "Trehaus" &&
  //     (profileDetails.role === "AD" || profileDetails.role === "TR")
  //   ) {
  //     return true;
  //   }
  // }
  return ProjectEditableRoleList.includes(profileDetails.role);
};

export const isProfileInTTAModuleEditableRoleList = (
  profileDetails: API_Profile_Details
) => {
  const TTAModuleEditableRoleList = ["TTACE", "WAD"];
  return TTAModuleEditableRoleList.includes(profileDetails.role);
};

export const isProfileInFundamentalEditableRoleList = (role: string) => {
  const FundamentalEditableRoleList = ["CD", "CE", "WAD"];
  return FundamentalEditableRoleList.includes(role);
};

// This is also applicable to uploading learning moment and completed creations
// TR = Teacher, CD = Curriculum Developer, CE = Content Editor, AD = Admin, WAD -> Site admin
export const isProfileInGalleryAccessRoleList = (role: string) => {
  const GalleryAccessRoleList = ["TR", "CD", "CE", "AD", "WAD"];
  return GalleryAccessRoleList.includes(role);
};

export const isProfileInReviewEditableRoleList = (role: string) => {
  const ReviewEditableRoleList = ["AD", "WAD"];
  return ReviewEditableRoleList.includes(role);
};

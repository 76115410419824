import { Stack, TextField } from "@mui/material";
import Button from "components/Button/Button";
import { useEffect, useState } from "react";
import {
  createCertification,
  updateCertification,
} from "api/profile-api";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_Certification } from "types/profile";


import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { MONTH_FORMAT } from "components/ChildrenPortfolioPDF/Preview/constant";
import { isValidUrl } from "containers/PaymentPage/constants/helper";

const CertificationForm = ({
  certification,
  handleClose,
}: {
  certification: API_Certification;
  handleClose: () => void;
}) => {
  const { visitedProfileDetails, setVisitedProfileDetails } = useStore(
    (state) => ({
      visitedProfileDetails: state.visitedProfileDetails,
      setVisitedProfileDetails: state.setVisitedProfileDetails,
    })
  );
  const openSnack = useSnack();

  const [formData, setFormData] = useState<API_Certification>(certification);
  const [isCreate, setIsCreate] = useState<boolean>(certification.id === -1);

  useEffect(() => {
    setFormData(certification);
    setIsCreate(certification.id === -1);
  }, [certification]);

  const handleSubmit = async () => {
    if (
      formData.credential_url !== "" &&
      !isValidUrl(formData.credential_url)
    ) {
      openSnack("Credential URL is not valid!", false);
      return;
    }
    //Register auth account
    const response: API_Certification | string = isCreate
      ? await createCertification(formData)
      : await updateCertification(formData);
    if (typeof response === "string") {
      openSnack(response, false);
    } else {
      console.log("visitedProfileDetails: ", visitedProfileDetails);
      console.log("response: ", response);

      if (isCreate) {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          certifications: [response, ...visitedProfileDetails.certifications!],
        });
      } else {
        setVisitedProfileDetails({
          ...visitedProfileDetails,
          certifications: visitedProfileDetails.certifications?.map(
            (certification_: API_Certification) => {
              return certification_.id === certification.id
                ? response
                : certification_;
            }
          ),
        });
      }
      openSnack(translation.updateSuccess, true);
      handleClose();
    }
  };

  return (
    <Stack
      component="form"
      sx={{ margin: "auto", my: "16px", alignItems: "center" }}
      spacing={1}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={1} direction="column">
        <TextField
          sx={{ width: 400 }}
          fullWidth
          required
          label={translation.title}
          value={formData.name}
          onChange={(event) => {
            setFormData((prev: API_Certification) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />

        <TextField
          fullWidth
          required
          label={translation.issuingOrganization}
          value={formData.issuing_organization}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              issuing_organization: event.target.value,
            }));
          }}
        />
        <Stack spacing={1} direction="row">
          <IssueDateField
            formData={formData}
            setFormData={setFormData}
            label="Issue Date"
          />
          <ExpirationDateField
            formData={formData}
            setFormData={setFormData}
            label="Expiration Date"
          />
        </Stack>
        <TextField
          fullWidth
          label={translation.credentialId}
          value={formData.credential_id}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              credential_id: event.target.value,
            }));
          }}
        />
        <TextField
          fullWidth
          label={translation.credentialURL}
          value={formData.credential_url}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              credential_url: event.target.value,
            }));
          }}
        />
        {/*     <TextField
          fullWidth
          multiline
          rows={4}
          required
          label={translation.issuingOrganization}
          value={formData.issuing_organization}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              issuing_organization: event.target.value,
            }));
          }}
        /> */}
      </Stack>

      <Button
        buttonText={
          isCreate
            ? translation.submit || "Submit"
            : translation.update || "Update"
        }
        style={{ marginTop: "20px" }}
        onClick={handleSubmit}
      />
    </Stack>
  );
};

const IssueDateField = ({
  formData,
  setFormData,
  label,
}: {
  formData: API_Certification;
  setFormData: React.Dispatch<React.SetStateAction<API_Certification>>;
  label: string;
}) => {
  const today = new Date().toLocaleDateString("en-ca");
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        maxDate={moment(today)}
        label={label}
        views={["year", "month"]}
        inputFormat={MONTH_FORMAT}
        value={formData.issue_date ? formData.issue_date : null}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            const firstDayOfMonth = newValue.clone().startOf("month");
            setFormData((prev) => ({
              ...prev,
              issue_date: firstDayOfMonth.format("YYYY-MM-DD").toString(),
            }));
          }
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

const ExpirationDateField = ({
  formData,
  setFormData,
  label,
}: {
  formData: API_Certification;
  setFormData: React.Dispatch<React.SetStateAction<API_Certification>>;
  label: string;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        minDate={moment(formData.issue_date)}
        label={label}
        views={["year", "month"]}
        inputFormat={MONTH_FORMAT}
        value={formData.expiration_date ? formData.expiration_date : null}
        onChange={(newValue: moment.Moment | null) => {
          if (newValue !== null) {
            const firstDayOfMonth = newValue.clone().startOf("month");
            setFormData((prev) => ({
              ...prev,
              expiration_date: firstDayOfMonth.format("YYYY-MM-DD").toString(),
            }));
          }
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

// const OrganizationImageForm = ({
//   image,
//   setFormData,
// }: {
//   image: string;
//   setFormData: React.Dispatch<React.SetStateAction<API_Certification>>;
// }) => {
//   const [currImage, setCurrImage] = useState<string>();
//   useEffect(() => {
//     setCurrImage(image);
//   }, [image]);
//   //   console.log("CUR IMAGE:", currImage);
//   return (
//     <Box sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}>
//       <Image
//         src={currImage ? currImage : Logo}
//         alt={Logo}
//         style={{
//           width: 200,
//           height: 200,
//           backgroundColor: "primary.main",
//           color: "txt.light",
//           border: "2px solid",
//           borderColor: "primary.main",
//           fontSize: "8vw",
//         }}
//       />

//       <input
//         accept="image/*"
//         type="file"
//         id={`organization-image-button`}
//         hidden
//         onChange={(event) => {
//           if (event.target.files && event.target.files.length > 0) {
//             setFormData((prev: any) => ({
//               ...prev,
//               image: event.target.files![0],
//             }));
//           }
//         }}
//       />

//       <Box
//         sx={{
//           position: "absolute",
//           bottom: 0,
//           left: 0,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           width: "100%",
//           height: "20%",
//           backgroundColor: "primary.main",
//           opacity: 0.5,
//           transition: "all 150ms linear",
//           "&:hover": {
//             opacity: 1,
//             transition: "all 150ms linear",
//           },
//         }}
//       >
//         <label htmlFor={`profile-image-button`}>
//           <IconButton
//             component="span"
//             sx={{
//               "&:hover": {
//                 backgroundColor: "inherit",
//               },
//             }}
//           >
//             <PhotoCameraIcon
//               sx={{
//                 fontSize: "2.5vw",
//                 color: "primary.light",
//                 cursor: "pointer",
//                 transition: "all 50ms linear",
//                 "&:hover": {
//                   color: "txt.secondary",
//                   transition: "all 50ms linear",
//                 },
//               }}
//             />
//           </IconButton>
//         </label>
//       </Box>
//     </Box>
//   );
// };

export default CertificationForm;

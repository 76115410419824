import React, { useState } from "react";
import { Box, Avatar, Typography } from "@mui/material";

import Logo from "images/logo-2-colour.png";
import useSnack from "hooks/useSnack";
import useDialog from "hooks/useDialog";

import { translation } from "constants/translation";
import { UserRoleMappingInverse } from "types/auth";
import Image from "components/Image/Image";

import DiscussionPostCommentSection from "./DiscussionPostCommentSection";
import { isProfileInReviewEditableRoleList } from "helper/helper";
import useStore from "store/store";
import WarningDialog from "components/Dialog/WarningDialog";
import {
  CommentOrReply,
  MoreButton,
  Profile,
  Time,
} from "components/Review/Components";
import { deleteLessonDiscussionPost } from "api/teacher-api";
import { API_Lesson_Discussion_Post } from "types/teacher";
import { Like } from "./DiscussionPostComponents";

const DiscussionPostCard = ({
  post,
  handleEditPost,
}: {
  post: API_Lesson_Discussion_Post;
  handleEditPost: ({
    answer,
    postId,
  }: {
    answer: string;
    postId: number;
  }) => void;
}) => {
  const openSnack = useSnack();

  const {
    profileDetails,
    currLanguage,
    profileID,
    lessonDiscussionPostList,
    setLessonDiscussionPostList,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
    profileID: state.profileID,
    lessonDiscussionPostList: state.lessonDiscussionPostList,
    setLessonDiscussionPostList: state.setLessonDiscussionPostList,
  }));

  const [isComment, setIsComment] = useState<boolean>(false);

  const avatar = post.profile.image;
  const name = post.profile.preferred_name;
  const answer = post.name;
  const role = UserRoleMappingInverse[post.profile.role];
  const school = post.profile.school ? post.profile.school.name : "Little Lab";

  const isMyReview = post.profile.id.toString() === profileID;

  const editMyPost = () => handleEditPost({ answer, postId: post.id });

  const deleteMyPost = async () => {
    const res = await deleteLessonDiscussionPost(
      post.id,
      localStorage.getItem("access")!
    );

    if (typeof res !== "string") {
      openSnack(translation.postDeleted || "Post Deleted", true);
      let lessonDiscussionPostListCopy: API_Lesson_Discussion_Post[] =
        JSON.parse(JSON.stringify(lessonDiscussionPostList));
      lessonDiscussionPostListCopy = lessonDiscussionPostListCopy.filter(
        (post_) => post_.id !== post.id
      );
      setLessonDiscussionPostList(lessonDiscussionPostListCopy);
    } else {
      openSnack(translation.tryAgainLater || "Try Again Later", false);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: translation.deletePostQuestionMark || "Delete the Post?",
    warningContext:
      translation.irreversibleAction || "This Action is Irreversible",
    handleDelete: deleteMyPost,
  };
  // console.log("lessonDiscussionPostList: ", lessonDiscussionPostList);
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1.5,
        background: "white",
        border: "1px solid #C4C4C4",
        borderRadius: "10px",
        p: 1.5,
        width: "100%",
      }}
    >
      <Avatar
        src={avatar}
        alt={name}
        sx={{ width: "40px", height: "40px" }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Profile name={name} role={role} school={school} />

        {answer && (
          <Typography sx={{ whiteSpace: "pre-wrap", lineHeight: 1.333 }}>
            {answer}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mt: 1,
          }}
        >
          <Like type="post" likes={post.likes} objectId={post.id} />
          {/* Post > Comment > Reply (not for now) */}
          <CommentOrReply
            type="comment"
            onClick={() => setIsComment((prev) => !prev)}
          />
        </Box>

        {(isComment || post.comments.length !== 0) && (
          <DiscussionPostCommentSection
            post={post}
            isComment={isComment}
            setIsComment={setIsComment}
          />
        )}
      </Box>

      <Box
        sx={{
          minWidth: "40px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-end",
        }}
      >
        {(isMyReview ||
          isProfileInReviewEditableRoleList(profileDetails.role)) && (
          <>
            <WarningDialog {...warningBody} />

            <MoreButton
              type="post"
              handleEdit={editMyPost}
              handleDelete={handleOpenDialog}
              isDeleteOnly={!isMyReview}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default DiscussionPostCard;

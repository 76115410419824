import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import useStore from "store/store";
import { registerAccount, updateProfile } from "api/auth-api";
import { getAllSchoolForRegister } from "api/school-api";
import useSnack from "hooks/useSnack";

import Button from "components/Button/Button";
import { translation } from "constants/translation";
import { CURLANG, FORGOTTEN_PASSWORD, LOGIN } from "constants/url";
import {
  AuthAccountData,
  RegisterAccountResponse,
  ErrorMessage,
  UpdateProfileResponse,
  UserRoleAbbr,
} from "types/auth";
import { API_School_Simple } from "types/school";

import RoleForm from "./RoleForm";

const LoginRegisterForm = ({ type }: { type: "login" | "register" }) => {
  const [formData, setFormData] = useState<AuthAccountData>({
    preferred_name: "",
    email: "",
    password: "",
  });

  const [formDataDetails, setFormDataDetails] = useState<{
    preferred_name: string;
    full_name: string;
    school: number | null;
    role: UserRoleAbbr;
  }>({
    preferred_name: "",
    full_name: "",
    school: -1,
    role: "PR",
  });

  const [schoolList, setSchoolList] = useState<API_School_Simple[]>([]);

  const { currLanguage, setProfileID } = useStore((state) => state);

  useEffect(() => {
    // To get profile data after re-login (refresh token expired)
    setProfileID("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get school list
  useEffect(() => {
    const populateData = async () => {
      const res = await getAllSchoolForRegister();
      setSchoolList(res);
    };

    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const openSnack = useSnack();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetFormData = () => {
    setFormData({ preferred_name: "", email: "", password: "" });
  };

  const handleRegister = async () => {
    const response: RegisterAccountResponse | ErrorMessage =
      await registerAccount({ ...formData, role: formDataDetails.role });

    if ("error" in response) {
      openSnack(response.error.join(" "), false);
    } else {
      localStorage.setItem("access", response.tokens.access);
      localStorage.setItem("refresh", response.tokens.refresh);
      localStorage.setItem("profile_id", response.profile_id);

      const updateResponse: UpdateProfileResponse | ErrorMessage =
        await updateProfile(
          response.profile_id,
          { ...formDataDetails, email: formData.email },
          response.tokens.access
        );

      if ("error" in updateResponse) {
        const errorMessages = updateResponse.error
          .map((errorObj) => {
            // Assuming each property in errorObj is an array of strings
            return Object.values(errorObj).flat().join(" ");
          })
          .join(" ");
        openSnack(errorMessages, false);
      } else {
        openSnack("Registration successful", true);
        resetFormData();
        setProfileID(response.profile_id.toString()); //To refresh stuff after successfully login
        navigate(`${CURLANG(currLanguage)}/${LOGIN}`);
      }
    }
  };

  return (
    <Stack
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 800,
        minWidth: { xs: 300, sm: 400, md: 500 },
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        mt: "-30px",
      }}
      spacing={2}
      noValidate
      autoComplete="off"
    >
      <Typography
        sx={{
          fontSize: "2rem",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </Typography>
      <TextField
        required
        label={translation.email}
        value={formData.email}
        onChange={(event) => {
          setFormData((prev) => ({ ...prev, email: event.target.value }));
        }}
        fullWidth
      />
      <TextField
        required
        label={translation.password}
        type="password"
        value={formData.password}
        onChange={(event) => {
          setFormData((prev) => ({ ...prev, password: event.target.value }));
        }}
        fullWidth
      />

      <TextField
        label={translation.preferred_name}
        value={formDataDetails.preferred_name}
        onChange={(event) => {
          setFormDataDetails((prev) => ({
            ...prev,
            preferred_name: event.target.value,
          }));
        }}
        fullWidth
      />
      <TextField
        label={translation.full_name}
        value={formDataDetails.full_name}
        onChange={(event) => {
          setFormDataDetails((prev) => ({
            ...prev,
            full_name: event.target.value,
          }));
        }}
        fullWidth
      />
      <RoleForm
        // selectedRole={
        //   schoolList.length === 0 ? "WAD" : formDataDetails.role
        // }
        selectedRole={formDataDetails.role}
        setSelectedRole={(role: UserRoleAbbr) => {
          setFormDataDetails((prev) => ({ ...prev, role: role }));
        }}
        schoolListLength={schoolList.length}
      />
      {formDataDetails.role === "WAD" ? null : (
        <Autocomplete
          disablePortal
          id="combo-box-register"
          onChange={(event: any, school_updated: API_School_Simple | null) => {
            if (school_updated !== null) {
              setFormDataDetails((prev) => ({
                ...prev,
                school: school_updated.id,
              }));
            }
          }}
          options={schoolList}
          getOptionLabel={(school) => school.name}
          // renderOption={(props, option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Schools"
                placeholder=""
                required
                fullWidth
              />
            );
          }}
        />
      )}

      <Button
        style={{ width: "100%" }}
        buttonText={type.charAt(0).toUpperCase() + type.slice(1)}
        arrow={false}
        disabled={formData.email === "" || formData.password === ""}
        onClick={handleRegister}
        isLoading={isLoading}
      />
      <Typography
        sx={{
          color: "txt.secondary",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`${CURLANG(currLanguage)}/${FORGOTTEN_PASSWORD}`);
        }}
      >
        {translation.forgottenPassword}
      </Typography>
    </Stack>
  );
};

export default LoginRegisterForm;

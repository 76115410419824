import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "store/store";

import AgeGroupImg from "images/age-group/age-group.png";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";

import { translation } from "constants/translation";
import { Box } from "@mui/system";
import ModuleTag from "components/ModuleTag/ModuleTag";

import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Tooltip, Typography } from "@mui/material";
import {
  CURLANG,
  EDIT_PROJECT_PAGE,
  EDIT_TEACHER_MODULE_PAGE,
} from "constants/url";

import Image from "components/Image/Image";
import { isProfileInTTAModuleEditableRoleList } from "helper/helper";

const Title = () => {
  const {
    profileDetails,
    teacherCurrentModule,
    setTeacherModules,
    filters,
    currLanguage,
    isTeacherModuleLoading,
    setIsTeacherModuleLoading,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    teacherCurrentModule: state.teacherCurrentModule,
    setTeacherModules: state.setTeacherModules,
    filters: state.filters,
    currLanguage: state.currLanguage,
    isTeacherModuleLoading: state.isTeacherModuleLoading,
    setIsTeacherModuleLoading: state.setIsTeacherModuleLoading,
  }));

  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={{ mb: "16px", px: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
            {`${teacherCurrentModule.name}${
              teacherCurrentModule.is_published ? "" : ` (${translation.draft})`
            }`}
          </Typography>
          {isProfileInTTAModuleEditableRoleList(profileDetails) && (
            <Tooltip title="Edit Module">
              <EditIcon
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  color: "txt.secondary",
                  transition: "all 100ms ease-in-out",
                  "&:hover": {
                    color: "primary.main",
                    transition: "all 100ms ease-in-out",
                  },
                }}
                onClick={() => {
                  navigate(
                    `${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(
                      teacherCurrentModule.slug
                    )}`
                  );
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Title;

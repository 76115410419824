import { MouseEvent } from "react";
import { Box, Typography } from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { translatedTabs } from "../constants";
import { slugify } from "helper/slugify";

const ProfileTab = ({
  tab,
  currentTab,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}: {
  tab: string;
  currentTab: string;
  open: boolean;
  handlePopoverOpen: (event: MouseEvent<HTMLElement>, name: string) => void;
  handlePopoverClose: () => void;
}) => {
  const isActiveTab = tab.toLowerCase() === currentTab.toLowerCase();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "8px",
        cursor: isActiveTab ? "default" : "pointer",
        textDecoration: "none",
        color: "#444",
        flexDirection: { xs: "column", sm: "row" },
      }}
      onMouseEnter={(e) => handlePopoverOpen(e, translatedTabs[tab])}
      onMouseLeave={() => handlePopoverClose()}
      onClick={(ev) => {
        const target = document.querySelector(`#${slugify(tab)}`);

        if (target) {
          const headerOffset = 80;
          const elementPosition = target.getBoundingClientRect().top;
          const offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          p: 0.5,
          width: "40px",
          height: "40px",
          borderRadius: "999px",
          border: "1px solid",
          borderColor: isActiveTab ? "#98C4C5" : "#F0F0F0",
          backgroundColor: isActiveTab ? "primary.light" : "var(--txt-light)",
        }}
      >
        <PlaylistAddCheckIcon
          sx={{
            backgroundColor: "white",
            borderRadius: "999px",
            p: "4px",
          }}
        />
      </Box>

      {open && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: isActiveTab ? "bold" : "normal",
            color: isActiveTab ? "primary.main" : "#444",
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {translatedTabs[tab]}
        </Typography>
      )}
    </Box>
  );
};

export default ProfileTab;

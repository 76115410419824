import { stringOrDate } from "react-big-calendar";
import { API_Project_Module } from "./project";

export type CalendarViewOptions = "Week" | "Month" | "Year";

export type CalendarViews = {
  [duration in CalendarViewOptions]: boolean;
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

interface Map {
  [key: string]: number;
}

export const MONTHS_TO_INDEX: Map = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
} as const;

export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

export type MonthString = typeof MONTHS[number];
export type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type RecommendedProjects = string[];

export interface BoxPosition {
  startX: number; //px
  startY: number; //px
}
export interface StartAndEndDate {
  startDate: Date;
  endDate: Date;
}

export interface CalendarLabel {
  title: string;
  subtitle: string;
  size?: number;
  endDay?: number;
}
export interface ProjectBoxInfo {
  id: number; // if id is defined, then add new, else replace
  groupId: string;
  project: string;
  title: string;
  durationInMin: number;
  offsetX?: number;
  offsetY?: number;
}

export interface ProjectBoxProps {
  id: number;
  projectId: number;
  scheduleId: number;
  groupId: string;
  projectTitle: string;
  projectDescription: string;
  projectSlug: string;
  moduleName: string;
  moduleCategoryName: string;
  image: string;
  width: string;
  height: string;
  startAt: string; //"2023/02/28"
  endAt: string; //"2023/02/28"
  bgColor?: string;
}

export interface GroupProjectBoxProps {
  [groupId: string]: ProjectBoxProps[];
}

export interface API_Schedule_Project {
  id: number;
  name: string;
  description: string;
  slug: string;
  module: API_Project_Module;
  image: string;
}

export interface API_Group_Calendar {
  id: number; //group id
  name: string; //group name
  image: string; //group image
  schedules: API_Group_Schedule[];
}

export interface API_Group_Schedule {
  id: number;
  group: number;
  project: API_Schedule_Project;
  next_lesson: number;
  schedules_datetime: API_Schedule_Datetime[];
}

export interface API_Schedule_Datetime {
  id: number;
  start_at: string;
  end_at: string;
}
export interface API_Create_Schedule {
  project_id: number;
  group_id: number;
  start_at: string;
  end_at: string;
}

export interface API_Create_Schedule_Res {
  id: number;
  project: number;
  group: number;
}

export interface Big_Calendar_Events {
  id: string;
  title: string;
  isAllDay?: boolean;
  start: stringOrDate;
  end: stringOrDate;
}

import React from "react";
import { TextField } from "@mui/material";

import {
  API_Create_Lesson,
} from "types/project";
import { translation } from "constants/translation";

const KeyVocabField = ({
  lesson,
  setLesson,
  onChange,
}: {
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={translation.key_vocabulary}
    value={lesson.vocabulary}
    onChange={(event) => {
      setLesson((prev) => ({ ...prev, vocabulary: event.target.value }));
      onChange && onChange(event.target.value);
    }}
  />
);

export default KeyVocabField;
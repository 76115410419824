import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import useStore from "store/store";
import { translation } from "constants/translation";
import { NothingMessage } from "../TeacherDashboard";
import { API_Child } from "types/profile";
import Logo from "images/logo-2-colour.png";
import { getIsNeedResponse } from "api/announcement-api";

const MyChildrenSection = ({
  selectedChildId,
  setSelectedChildId,
}: {
  selectedChildId: number;
  setSelectedChildId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { childrenInSession } = useStore((state) => ({
    childrenInSession: state.childrenInSession,
  }));

  const [justifyContent, setJustifyContent] = useState("center");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setJustifyContentFunction = () => {
      if (containerRef.current) {
        // Check if there's overflow
        const isOverflowing =
          containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setJustifyContent(isOverflowing ? "flex-start" : "center");
      }
    };

    window.addEventListener("resize", () => setJustifyContentFunction());

    return () => {
      window.removeEventListener("resize", () => setJustifyContentFunction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log("justifyContent: children:", justifyContent);
  return childrenInSession.length > 0 ? (
    <Box>
      {/* <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "1.5rem",
          mb: 1.5,
        }}
      >
        {translation.myChildren}
      </Typography> */}

      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          justifyContent,
          // flexWrap: "wrap",
          overflowX: "auto",
          gap: 4,
        }}
      >
        {childrenInSession.map((child, index) => (
          <ChildContainer
            key={index}
            child={child}
            selectedChildId={selectedChildId}
            onClick={() => setSelectedChildId(child.id)}
            width={50}
            height={50}
            isDashboard
          />
        ))}
      </Box>
    </Box>
  ) : (
    <NothingMessage message={translation.noChild} />
  );
};

export const ChildContainer = ({
  child,
  selectedChildId,
  onClick,
  width = 60,
  height = 60,
  isDashboard,
}: {
  child: API_Child;
  selectedChildId: number;
  onClick: () => void;
  width?: number;
  height?: number;
  isDashboard?: boolean;
}) => {
  const theme = useTheme();
  const [isNeedResponse, setIsNeedResponse] = useState<boolean>(false);
  const { isRefreshNeedResponse } = useStore((state) => ({
    isRefreshNeedResponse:
      state.announcementList.filter(
        (announcement) => announcement.is_responded === false
      ).length > 0,
  }));
  const isSelected = child.id === selectedChildId;
  const extraGrowth = 3;
  useEffect(() => {
    const populateIsNeedResponse = async () => {
      const res = await getIsNeedResponse(child.id);
      if (typeof res !== "string") {
        setIsNeedResponse(res);
      }
    };
    populateIsNeedResponse();
  }, [isRefreshNeedResponse]);

  return (
    <Box
      sx={{
        cursor: "pointer",
        pointerEvents: isSelected ? "none" : "auto",
        position: "relative",
      }}
      onClick={onClick}
    >
      {isNeedResponse && !isDashboard && (
        <Box
          sx={{
            borderRadius: "50%",
            backgroundColor: theme.palette.custom.lightOrange,
            position: "absolute",
            top: 0,
            right: 0,
            width: 12,
            height: 12,
            zIndex: 3,
          }}
        />
      )}

      <Avatar
        src={child.image ? child.image : Logo}
        sx={{
          mb: 1,
          width: {
            xs: `${isSelected ? width + extraGrowth : width}px`,
            md: `${isSelected ? width + 20 + extraGrowth : width + 20}px`,
          },
          height: {
            xs: `${isSelected ? height + extraGrowth : height}px`,
            md: `${isSelected ? height + 20 + extraGrowth : height + 20}px`,
          },
          mx: "auto",
          backgroundColor: "primary.main",
          border: `${isSelected ? extraGrowth : 0}px solid var(--primary-main)`,
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      <Typography
        sx={{
          textAlign: "center",
          fontSize: "1.0rem",
          lineHeight: "1.25",
          fontWeight: isSelected ? 500 : "normal",
          maxWidth: { xs: "80px", sm: "100px" },
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: 2,
          lineClamp: 2,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
        }}
      >
        {child.preferred_name}
      </Typography>
    </Box>
  );
};

export default MyChildrenSection;

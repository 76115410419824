import { Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const CannotAccessPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: ' 100%',
        flexDirection: 'column',
      }}
    >
      <WarningIcon
        sx={{ fontSize: 'clamp(10px, 7vw, 80px)', color: 'txt.secondary' }}
      />
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: 'clamp(10px, 7vw, 80px)',
          color: 'txt.secondary',
          textAlign: 'center',
        }}
      >
        You are not allowed to access this page!
      </Typography>
    </Box>
  );
};

export default CannotAccessPage;

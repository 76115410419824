import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import {
  GridColumns,
  DataGrid,
  GridRowId,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridRowModel,
  GridColumnVisibilityModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";

//School
import {
  deleteSchool,
  editSchool,
  getAllSchoolWithStripeInfo,
} from "api/school-api";
import { API_Edit_School, API_School } from "types/school";

//profile
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { DATA_GRID_STYLE } from "./constants/styling";
import {
  ACTIONS_COLUMN,
  EditToolbarSchool,
  ID_COLUMN,
  IMAGE_COLUMN,
  undefinedValueFormatter,
} from "./constants/gridColumnHelper";
import {
  convertCentsToString,
  convertCurrencyStrToSymbol,
  convertToUpperCase,
  convertUnixTimestampToStrDate,
} from "containers/PaymentPage/constants/helper";
import { Chip } from "@mui/material";
import { translation } from "constants/translation";

const SchoolManagePage = () => {
  const openSnack = useSnack();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const { profileDetails, schools, setSchools } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      schools: state.schools,
      setSchools: state.setSchools,
      currLanguage: state.currLanguage,
    })
  );
  const [rows, setRows] = React.useState(schools);

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "",
    confirmDelete: false,
  });
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDelete = (id: GridRowId) => {
    //call delete api
    const callDeleteSchool = async () => {
      const res = await deleteSchool(String(id));
      if (typeof res === "string" && res.length >= 1) {
        //if delete success, it will return ""
        openSnack(res, false);
      } else {
        openSnack(`Delete School successfully!`, true);
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    callDeleteSchool();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    //double function loop here to prevent it's initialiated once passing
    //call delete api
    const name = rows.filter((row) => row.id === id)[0]?.name;
    handleOpenDialog();
    setWarningBody({
      id: Number(id),
      warningTitle: `Delete School "${name}"?`,
      confirmDelete: false,
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    console.log("new row: ", newRow);
    //call update api

    let body = { name: newRow.name, image: newRow.image };

    if (typeof newRow.image === "string") {
      //don't send if is string
      body = Object.keys(newRow)
        .filter((key: string) => key !== "image")
        .reduce((acc: any, key: string) => {
          acc[key] = newRow[key];
          return acc;
        }, {});
    }

    // console.log("body: ", body);
    const updateResponse: API_School | string = await editSchool(
      String(newRow.id),
      body as API_Edit_School,
      localStorage.getItem("access")
    );
    // console.log("Update res: ", updateResponse);
    if (typeof updateResponse === "string") {
      openSnack(updateResponse, false);
      return updateResponse;
    } else {
      openSnack("School is updated!", true);
    }

    return newRow;
  };
  // console.log("schools: ", schools);
  const columns: GridColumns = [
    {
      ...ID_COLUMN,
    },
    {
      ...IMAGE_COLUMN,
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      editable: true,
    },
    {
      field: "customer_id",
      headerName: "Customer Id",
      width: 180,
      editable: false,
      valueFormatter: undefinedValueFormatter,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 180,
      editable: false,
      valueFormatter: undefinedValueFormatter,
    },
    {
      field: "unit_amount",
      headerName: "Price",
      width: 140,
      editable: false,
      renderCell: (params: any) => {
        if (!params?.value) {
          return "None";
        }

        return `${convertCurrencyStrToSymbol(
          params?.row.currency
        )}${convertCentsToString(params?.value)}`;
      },
    },
    {
      field: "subscription_end",
      headerName: "Valid Until",
      width: 160,
      editable: false,
      valueFormatter: (params: any) =>
        convertUnixTimestampToStrDate(params?.value),
    },
    {
      field: "subscription_status",
      headerName: "Status",
      width: 150,
      editable: false,
      renderCell: (params: any) => (
        <Chip
          size="small"
          label={`${convertToUpperCase(params?.value)}`}
          sx={{
            height: 24,
          }}
          color={
            params?.value === "active"
              ? `success`
              : params?.value === "canceled"
              ? `error`
              : "default"
          }
        />
      ),
    },
    {
      field: "is_cancel_at_period_end",
      headerName: "Next Cycle",
      width: 100,
      editable: false,
      renderCell: (params: any) => (
        <Chip
          size="small"
          label={
            params?.value === true
              ? `${translation.noRenew}`
              : params?.value === false
              ? `${translation.willRenew}`
              : "None"
          }
          sx={{
            height: 24,
          }}
          color={
            params?.value === true
              ? "error"
              : params?.value === false
              ? "success"
              : "default"
          }
        />
      ),
    },
    {
      ...ACTIONS_COLUMN({
        rowModesModel,
        handleSaveClick,
        handleCancelClick,
        handleEditClick,
        handleDeleteClick,
      }),
    },
  ];

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningContext: "The action is irreversable!",
    setWarningBody,
  };

  useEffect(() => {
    const populateData = async () => {
      // const childListRes = await getAllChildProfile();
      // setRows(childListRes);
      const schoolRes = await getAllSchoolWithStripeInfo();
      setSchools(schoolRes);
      setRows(schoolRes);
    };
    populateData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id !== -1) {
      console.log("warningBody: ", warningBody);
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warningBody]);

  const [columnVisibility, setColumnVisibility] = useState<any>(() => {
    //Return {name: true, description: true, xxx:true ......}
    const finalObj = {};
    columns.forEach((column) => {
      Object.assign(finalObj, { [column.field]: true });
    });
    return finalObj;
  });

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <Box>
        <div style={DATA_GRID_STYLE}>
          <DataGrid
            sx={{ fontSize: 18 }}
            rows={rows}
            columns={columns}
            columnVisibilityModel={columnVisibility}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onColumnVisibilityModelChange={(
              model: GridColumnVisibilityModel,
              details: GridCallbackDetails
            ) => {
              setColumnVisibility(model);
            }}
            components={{
              Toolbar: EditToolbarSchool,
            }}
            componentsProps={{
              toolbar: {
                setRows,
                setRowModesModel,
                setSchools,
                role: profileDetails.role,
              },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Box>
    </>
  );
};

export default SchoolManagePage;

import * as React from "react";
import { Box, Typography } from "@mui/material";
import Image from "../../../Image/Image";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";

interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
  scale: number;
};

const ImageStructure: React.FC<ImageStructureProps> = ({ projects, scale }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {projects.map((project, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            height: `${350 * scale}px`,
            width: `${250 * scale}px`,
            boxSizing: "border-box",
          }}
        >
          {/* Frame Image */}
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "60%",
              mb: `-${40 * scale}px`,
            }}
          >
            <Image
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: 2,
              }}
              src={Frame}
              alt="frame"
            />
            <Image
              style={{
                position: "absolute",
                top: "9.1%",
                left: "13%",
                width: "75%",
                height: "62.3%",
                objectFit: "cover",
                zIndex: 1,
              }}
              src={project.image_url || "images/empathy.png"}
              alt={project.title || "projects"}
            />
          </Box>

          {/* Caption positioned below the frame */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F9F6F1",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: `${14 * scale}px`,
                fontFamily: "FuturaBkBT",
                color: "#000",
                lineHeight: "22px",
                letterSpacing: "1.4px",
                fontWeight: 400,
              }}
            >
              {project.category}
            </Typography>
            <Typography
              sx={{
                fontSize: `${12 * scale}px`,
                fontFamily: "FuturaBkBT",
                color: "#000",
                lineHeight: "18px",
                letterSpacing: "1.2px",
                fontWeight: 300,
              }}
            >
              {project.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ImageStructure;

import { useState } from "react";
import useStore from "store/store";
import { Button, TextField, Typography, Box, Fade, Modal } from "@mui/material";
import { slugify } from "helper/slugify";
import { PROGRESS_REPORT } from "containers/ChildrenPortfolioPage/constants";
import { CHILDREN_PORTFOLIO_PAGE, CURLANG } from "constants/url";
import { useNavigate, useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import useSnack from "hooks/useSnack";
import { createChildProgressReport, updatePDFDraft } from "api/report-api";

const SaveDraftModal = ({
  open,
  handleClose,
  saveDraftModalText,
  setSaveDraftModalText,
  PDFInfo,
}: {
  open: any;
  handleClose: () => void;
  saveDraftModalText: any;
  setSaveDraftModalText: any;
  PDFInfo: any;
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();

  const { groupSlug, childID, fileId } = useParams();
  const [progressPercentage, setProgressPercentage] = useState<number>(0); //Progress is useless here as it's generating PDF that takes time, but no way to track
  const [isCreatingReport, setIsCreatingReport] = useState<boolean>(false); //isGeneratingPDF replace
  const { currLanguage } = useStore((state) => state);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: "16px",
            borderRadius: "10px",
            overflow: "auto",
            width: "40%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontSize: "24px", fontWeight: "600", marginBottom: "60px" }}
            >
              Save Draft
            </Typography>

            <TextField
              value={saveDraftModalText}
              placeholder="Enter Name"
              sx={{
                marginTop: "4",
                // "& .MuiInputBase-root": { height: "100px" },
              }}
              onChange={(e: any) => {
                setSaveDraftModalText(e.target.value);
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
                gap: 1,
              }}
            >
              <Button sx={{ width: "100%" }} onClick={handleClose}>
                Back
              </Button>
              <Button
                variant="contained"
                sx={{ width: "100%", color: "white" }}
                onClick={() => {
                  if (fileId) {
                    const updatePDFDraftFunction = async () => {
                      const res = await updatePDFDraft(
                        fileId,
                        saveDraftModalText,
                        JSON.stringify(PDFInfo)
                      );

                      console.log(res);
                      if (res == "Success") {
                        openSnack("Draft Saved!", true);
                      } else {
                        openSnack("Draft Not Saved!", false);
                      }
                      navigate(
                        `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
                          groupSlug!,
                          childID!
                        )}/${slugify(PROGRESS_REPORT)}`
                      );
                    };
                    updatePDFDraftFunction();
                  } else {
                    const savePDFDraft = async () => {
                      const res = await createChildProgressReport(
                        childID!,
                        groupSlug!,
                        PDFInfo.startMonth,
                        PDFInfo.endMonth,
                        setIsCreatingReport,
                        setProgressPercentage,
                        saveDraftModalText,
                        JSON.stringify(PDFInfo),
                        "",
                        `${PDFInfo.profileName}_${PDFInfo.startMonth}_To_${PDFInfo.endMonth}_Report`
                      );
                      if (res == "success") {
                        navigate(
                          `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
                            groupSlug!,
                            childID!
                          )}/${slugify(PROGRESS_REPORT)}`
                        );
                      }
                    };
                    savePDFDraft();
                    openSnack("Draft Generated!", true);
                    handleClose();
                  }
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SaveDraftModal;

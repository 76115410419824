import { Dispatch, SetStateAction, ReactNode } from "react";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import {
  API_Teacher_Community_Kanban_Board_Card,
  API_Teacher_Community_Kanban_Board_With_Card,
} from "types/teacher";
import {
  editCommunityKanbanBoardCardWeight,
} from "api/teacher-api";

const DndKanbanGrid = ({
  cards,
  setKanbanBoard,
  children,
}: {
  cards: API_Teacher_Community_Kanban_Board_Card[];
  setKanbanBoard: Dispatch<
    SetStateAction<API_Teacher_Community_Kanban_Board_With_Card>
  >;
  children: ReactNode;
}) => {
  const openSnack = useSnack();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = cards.findIndex((l) => l.id === active.id);
      const newIndex = cards.findIndex((l) => l.id === over.id);

      const orderedCards = arrayMove(cards, oldIndex, newIndex);
      setKanbanBoard((prev) => ({ ...prev, cards: orderedCards }));
      const orderDifference = orderedCards.map((card, index) => {
        return { id: card.id, weight: index + 1 };
      });

      const res = await editCommunityKanbanBoardCardWeight(
        currLanguage,
        orderDifference
      );

      if (typeof res !== "string") {
        setKanbanBoard(res);
        openSnack("Reorder cards successfully!", true);
      } else {
        openSnack("Reorder failed!", false);
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={cards.map((card) => card.id)}
        strategy={rectSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default DndKanbanGrid;

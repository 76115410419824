import { useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import useStore from "store/store";
import { TITLE } from "constants/title";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import ModuleTreeView from "../ModuleTreeView/ModuleTreeView";
import ModuleResizableDrawer from "../ModuleResizableDrawer";
import "./EditModulePage.css";
import useInitialiseCurrModule from "hooks/useInitialiseCurrModule";
import PageTitle from "./PageTitle";

export type Type = "Module" | "Lesson" | "Activity";

export interface Selected {
  id: string;
  index: number;
  parentIndex?: number;
  parentId?: string;
  type: Type;
}
// Start edit this
const EditModulePage = () => {
  document.title = TITLE;
  useInitialiseCurrModule(true);

  const { moduleSlug } = useParams();
  const { isTeacherModuleLoading } =
    useStore((state) => ({
      teacherModules: state.teacherModules,
      setTeacherCurrentModule: state.setTeacherCurrentModule,
      isTeacherModuleLoading: state.isTeacherModuleLoading,
    }));

  const [selected, setSelected] = useState<Selected | null>(null);
  const [prevSelected, setPrevSelected] = useState<Selected | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleOpen = (selectedOption: Selected) => {
    if (
      openDrawer &&
      selected &&
      JSON.stringify(selectedOption) === JSON.stringify(selected)
    ) {
      handleClose();
      return;
    }

    document.body.classList.add("drawer-open");
    setSelected(selectedOption);
    setPrevSelected(selectedOption);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    if (!openDrawer) return;

    document.body.classList.remove("drawer-open");
    setSelected(null);
    setOpenDrawer(false);
  };

  return (
    <Box
      className="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "24px",
        gap: 2,
      }}
    >
      <PageTitle moduleSlug={moduleSlug!} handleClose={handleClose} />
      {isTeacherModuleLoading ? (
        <LoadingIndicator />
      ) : (
        <ModuleTreeView
          selected={prevSelected}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      )}
      <ModuleResizableDrawer
        selected={selected}
        openDrawer={openDrawer}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default EditModulePage;

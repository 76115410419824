import { Box, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { translation } from "constants/translation";

import {
  API_Teacher_Lesson,
  API_Interactive_Data_Entry_Prompt,
  API_Teacher_Interactive_Data_Entry_Activity,
  API_Interactive_Data_Entry_Prompt_Learner_Answer,
} from "types/teacher";

import Button from "components/Button/Button";
import {
  getInteractiveDataEntryLearnerAnswer,
  getInteractiveDataEntryLearnerList,
  updateCreateInteractiveDataEntryPrompt,
} from "api/teacher-api";
import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { TeacherLessonActivityScreenDoneButton } from "../TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";

import { useParams } from "react-router-dom";
import { API_Profile_Short } from "types/profile";
import ProfileSelectionList from "./TeacherActivitySharedComponents/ProfileSelectionList";
import { updateLearnerActivityProgressFunctionCall } from "../TeacherPage/TeacherPageHelper";

const TeacherInteractiveDataEntryActivityScreen = ({
  teacherCurrentActivity,
  teacherCurrentLesson,
}: {
  teacherCurrentActivity: API_Teacher_Interactive_Data_Entry_Activity;
  teacherCurrentLesson: API_Teacher_Lesson;
}) => {
  const openSnack = useSnack();
  const { lessonSlug } = useParams();
  const {
    currLanguage,
    profileDetails,
    learnerLessonProgressList,
    setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    learnerLessonProgressList: state.learnerLessonProgressList,
    setLearnerLessonProgressList: state.setLearnerLessonProgressList,
    setDisplayObtainedCertificateModalInfo:
      state.setDisplayObtainedCertificateModalInfo,
  }));
  const [
    interactiveDataEntryLearnerAnswerList,
    setInteractiveDataEntryLearnerAnswerList,
  ] = useState<API_Interactive_Data_Entry_Prompt_Learner_Answer[]>([]);

  const [profileList, setProfileList] = useState<API_Profile_Short[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<API_Profile_Short>({
    id: profileDetails.id,
    image: profileDetails.image,
    preferred_name: profileDetails.preferred_name,
    full_name: profileDetails.full_name,
    dob: profileDetails.dob,
  });

  useEffect(() => {
    const populateInteractiveDataEntryProfileList = async () => {
      const res = await getInteractiveDataEntryLearnerList(
        teacherCurrentActivity.id,
        localStorage.getItem("access")
      );
      if (typeof res !== "string") {
        setProfileList(res);
      }
    };
    populateInteractiveDataEntryProfileList();
  }, []);

  useEffect(() => {
    const populateInteractiveDataEntryLearnerAnswerList = async () => {
      const res = await getInteractiveDataEntryLearnerAnswer(
        teacherCurrentActivity.id,
        selectedProfile.id,
        localStorage.getItem("access")
      );
      if (typeof res !== "string") {
        setInteractiveDataEntryLearnerAnswerList(res);
      }
    };
    populateInteractiveDataEntryLearnerAnswerList();
  }, [selectedProfile.id]);

  const currLessonIndex = learnerLessonProgressList.findIndex(
    (learnerLessonProgress) => learnerLessonProgress.lesson_slug === lessonSlug
  );

  const isActivityDone = learnerLessonProgressList[currLessonIndex]
    ? learnerLessonProgressList[currLessonIndex].activity_done_list.includes(
        teacherCurrentActivity.id
      )
    : false;

  const isSameProfile = selectedProfile.id === profileDetails.id;
  // console.log("profileList: ", profileList);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "primary.main",
          borderRadius: "10px",
          p: 2,
          gap: 1,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            gap: 1,
            bgcolor: "background.paper",
            borderRadius: "10px",
            overflowX: "hidden",
            flex: 1,
          }}
        >
          {/* <Typography sx={{ fontSize: 22 }}>
            {translation.interactiveDataEntry}
          </Typography> */}
          {(profileDetails.role === "WAD" ||
            profileDetails.role === "TTACE") && (
            <ProfileSelectionList
              profileList={profileList}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
            />
          )}

          <Typography sx={{ fontSize: 16, textAlign: "left", fontWeight: 500 }}>
            {teacherCurrentActivity.description}
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 3,
              flex: "1 1 0",
              overflowY: "auto",
              overflowX: "hidden",
              // justifyContent: "center",
            }}
          >
            {/* <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{translation.prompt}</TableCell>
                    <TableCell>{translation.answer}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody> */}
            {teacherCurrentActivity.interactive_data_entry_prompts?.map(
              (interactiveDataEntryPrompt) => {
                return (
                  <Box
                    sx={{ flex: 1 }}
                    key={`interactive-data-entry-prompt-id-${interactiveDataEntryPrompt.id}`}
                  >
                    <InteractiveDataEntryPrompt
                      isSameProfile={isSameProfile}
                      interactiveDataEntryLearnerAnswerList={
                        interactiveDataEntryLearnerAnswerList
                      }
                      setInteractiveDataEntryLearnerAnswerList={
                        setInteractiveDataEntryLearnerAnswerList
                      }
                      interactiveDataEntryPrompt={interactiveDataEntryPrompt}
                    />
                  </Box>
                );
              }
            )}
            {/* </TableBody>
              </Table>
            </TableContainer> */}
          </Box>
          {isSameProfile && (
            <Box sx={{ display: "flex", justifyContent: "end", py: 1 }}>
              <Button
                // style={{ display: isPrevPathDisabled ? "none" : "flex" }}
                buttonText={
                  isActivityDone
                    ? translation.update || "Update"
                    : translation.submit || "Submit"
                }
                // btnType="outlined"
                arrow={false}
                // style={{ backgroundColor: "background.paper" }}
                onClick={async () => {
                  if (
                    interactiveDataEntryLearnerAnswerList.length <
                    teacherCurrentActivity.interactive_data_entry_prompts!
                      .length
                  ) {
                    openSnack(
                      translation.pleaseAnswerAll || "Please Answer All",
                      false
                    );
                    return;
                  }

                  const res = await updateCreateInteractiveDataEntryPrompt(
                    interactiveDataEntryLearnerAnswerList,
                    localStorage.getItem("access")
                  );
                  if (typeof res !== "string") {
                    if (!isActivityDone) {
                      const isUpdateSuccess =
                        await updateLearnerActivityProgressFunctionCall({
                          profileDetails: profileDetails,
                          currLanguage: currLanguage,
                          teacherCurrentActivityId: teacherCurrentActivity.id,
                          accessToken: localStorage.getItem("access")!,
                          learnerLessonProgressList,
                          currLessonIndex,
                          setLearnerLessonProgressList,
                          setDisplayObtainedCertificateModalInfo,
                        });

                      if (isUpdateSuccess) {
                        openSnack(
                          translation.answerSuccessfullySubmit ||
                            "Answer Successfully Submitted",
                          true
                        );
                        setInteractiveDataEntryLearnerAnswerList(res);
                      } else {
                        openSnack(translation.tryAgainLater, false);
                      }
                    } else {
                      openSnack(translation.updateSuccess, true);
                    }

                    // const completedActivityList =
                    //   await updateLearnerActivityProgress(
                    //     teacherCurrentActivity.id,
                    //     localStorage.getItem("access")
                    //   );

                    // if (typeof completedActivityList !== "string") {
                    //   if (
                    //     learnerLessonProgressList[currLessonIndex]
                    //       .activity_done_list.length !==
                    //     completedActivityList.length
                    //   ) {
                    //     //updated
                    //     const learnerLessonProgressCopyList: API_Teacher_Module_Learner_Lesson_Progress[] =
                    //       JSON.parse(JSON.stringify(learnerLessonProgressList));
                    //     learnerLessonProgressCopyList[
                    //       currLessonIndex
                    //     ].activity_done_list = completedActivityList;
                    //     setLearnerLessonProgressList(
                    //       learnerLessonProgressCopyList
                    //     );
                    //   }
                    // }
                  } else {
                    openSnack(
                      translation.answerFailedSubmit ||
                        "The Answer Submit Failed",
                      false
                    );
                  }

                  // navigate(String(prevPath));
                }}
              />
            </Box>
          )}
          {isSameProfile && (
            <TeacherLessonActivityScreenDoneButton
              isDisplayButton={false}
              activityId={teacherCurrentActivity.id}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

const InteractiveDataEntryPrompt = ({
  isSameProfile,
  interactiveDataEntryLearnerAnswerList,
  setInteractiveDataEntryLearnerAnswerList,
  interactiveDataEntryPrompt,
}: {
  isSameProfile: boolean;
  interactiveDataEntryLearnerAnswerList: API_Interactive_Data_Entry_Prompt_Learner_Answer[];
  setInteractiveDataEntryLearnerAnswerList: React.Dispatch<
    React.SetStateAction<API_Interactive_Data_Entry_Prompt_Learner_Answer[]>
  >;
  interactiveDataEntryPrompt: API_Interactive_Data_Entry_Prompt;
}) => {
  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));

  const answerIndex = interactiveDataEntryLearnerAnswerList.findIndex(
    (answer) =>
      answer.interactive_data_entry_prompt === interactiveDataEntryPrompt.id
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography
        sx={{ fontSize: 16 }}
      >{`${interactiveDataEntryPrompt.name}`}</Typography>

      <TextField
        multiline
        maxRows={2}
        placeholder="Short Answer Text"
        variant="outlined"
        fullWidth
        // label={`${interactiveDataEntryPrompt.name}-prompt`}
        value={
          answerIndex === -1
            ? ""
            : interactiveDataEntryLearnerAnswerList[answerIndex].name
        }
        onChange={(event) => {
          setInteractiveDataEntryLearnerAnswerList((prev) => {
            let finalizeAnswer;
            const index = prev.findIndex(
              (answer) =>
                answer.interactive_data_entry_prompt ===
                interactiveDataEntryPrompt.id
            );
            if (index === -1) {
              finalizeAnswer = {
                id: -1,
                interactive_data_entry_prompt: interactiveDataEntryPrompt.id,
                profile: profileDetails.id,
                name: event.target.value,
              };
              return [...prev, finalizeAnswer];
            } else {
              finalizeAnswer = JSON.parse(JSON.stringify(prev));
              finalizeAnswer[index].name = event.target.value;
              return finalizeAnswer;
            }
          });
        }}
        disabled={!isSameProfile}
      />
    </Box>
  );
};

//  <TableRow
//    key={interactiveDataEntryPrompt.name}
//    sx={{
//      "&:last-child td, &:last-child th": { border: 0 },
//    }}
//  >
//    <TableCell component="th" scope="row" sx={{ maxWidth: 25 }}>
//      <Typography
//        sx={{ fontSize: 18 }}
//      >{`${interactiveDataEntryPrompt.name}`}</Typography>
//    </TableCell>
//    <TableCell align="right">
//      <TextField
//        multiline
//        maxRows={2}
//        placeholder="Short Answer Text"
//        variant="standard"
//        fullWidth
//        // label={`${interactiveDataEntryPrompt.name}-prompt`}
//        value={
//          answerIndex === -1
//            ? ""
//            : interactiveDataEntryLearnerAnswerList[answerIndex].name
//        }
//        onChange={(event) => {
//          setInteractiveDataEntryLearnerAnswerList((prev) => {
//            let finalizeAnswer;
//            const index = prev.findIndex(
//              (answer) =>
//                answer.interactive_data_entry_prompt ===
//                interactiveDataEntryPrompt.id
//            );
//            if (index === -1) {
//              finalizeAnswer = {
//                id: -1,
//                interactive_data_entry_prompt: interactiveDataEntryPrompt.id,
//                profile: profileDetails.id,
//                name: event.target.value,
//              };
//              return [...prev, finalizeAnswer];
//            } else {
//              finalizeAnswer = JSON.parse(JSON.stringify(prev));
//              finalizeAnswer[index].name = event.target.value;
//              return finalizeAnswer;
//            }
//          });
//        }}
//        disabled={!isSameProfile}
//      />
//    </TableCell>
//  </TableRow>;

export default TeacherInteractiveDataEntryActivityScreen;

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";
import { API_Teacher_Module_Visualization_Demographic_Info } from "types/teacher";
import { responsiveContainerStyle } from "./VisualizationPage";
import { useTheme } from "@mui/material";

interface HighestEducationQualificationInfo {
  category: string;
  count: number;
}

const HighestEducationQualificationChart = ({
  data,
}: {
  data: API_Teacher_Module_Visualization_Demographic_Info[];
}) => {
  const theme = useTheme();
  let processedData: HighestEducationQualificationInfo[] = data.reduce(
    (
      acc: HighestEducationQualificationInfo[],
      item: API_Teacher_Module_Visualization_Demographic_Info
    ) => {
      const categoryName = item.highest_education_qualification;
      let educationInfo: HighestEducationQualificationInfo | undefined =
        acc.find(
          (d: HighestEducationQualificationInfo) => d.category === categoryName
        );
      if (!educationInfo) {
        educationInfo = {
          category: categoryName,
          count: 0,
        };
        acc.push(educationInfo);
      }
      educationInfo.count += 1;
      return acc;
    },
    []
  );

  processedData = processedData.sort((a, b) => (b.count < a.count ? -1 : 1));

  return (
    <ResponsiveContainer {...responsiveContainerStyle}>
      <BarChart
        width={500}
        height={300}
        layout="horizontal"
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="category" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="count" fill={theme.palette.custom.lightBlue}>
          {/* {data.map((entry, index) => {
            const color =
              index % 2 === 0
                ? theme.palette.custom.lightBlue
                : theme.palette.custom.lightOrange;
            return <Cell fill={color} />;
          })} */}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HighestEducationQualificationChart;

import React from "react";
import { Image, View, StyleSheet, Text, Page } from "@react-pdf/renderer";
import PhotoBookCoverFrontTop from "../../../../../../../images/children-photo-book/photo-book-cover-front-top.png";
import {
  imageSrc,
  photoBookPageSize,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";

const PhotoBookCoverFrontComponent = ({
  projectImageSrc,
  moduleName,
  projectName,
}: {
  projectImageSrc: string;
  moduleName: string;
  projectName: string;
}) => {
  const templateStyles = StyleSheet.create({
    text: {
      display: "flex",
      fontWeight: 400,
      lineHeight: 1.25,
      textAlign: "center",
      position: "absolute",
      fontFamily: "FuturaMediumBT",
      textOverflow: "ellipsis",
      maxLines: 1,
    },
  });

  return (
    <Page size={photoBookPageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image
          src={imageSrc(projectImageSrc)}
          style={{
            position: "absolute",
            top: 160,
            left: 80,
            width: 335,
            height: 208,
            objectFit: "cover",
          }}
        />
        <Image
          src={imageSrc(PhotoBookCoverFrontTop)}
          style={styles.background}
        />

        <Text
          style={[
            templateStyles.text,
            {
              top: 32,
              left: 48,
              width: 400,
              textAlign: "center",
              fontSize: 16 * 0.85,
            },
          ]}
        >
          {`${moduleName}`}
        </Text>

        <Text
          style={[
            templateStyles.text,
            {
              top: 64,
              left: 48,
              width: 400,
              textAlign: "center",
              fontSize: 25 * 0.85,
            },
          ]}
        >
          {`${projectName}`}
        </Text>
      </View>
    </Page>
  );
};

export default PhotoBookCoverFrontComponent;

import useStore from "store/store";
import { OVERVIEW, getProjectLessonActivityTabs } from "../constant";
import { LITTLE_PROJECTS } from "containers/CalendarPage/constants/constants";
import TabsContainerMobile from "./TabsContainerMobile";
import { usePopulateScheduleObject } from "hooks/projects";
import { useInsights } from "hooks/projects";

const ProjectSummarySideMenuMobile = () => {
  const {
    profileDetails,
    currentProject,
  } = useStore((state) => state);
  usePopulateScheduleObject();

  const { isInBag, isInSchedule } = useInsights();
  

  const onlyOverviewTab = [OVERVIEW];

  return (
    <TabsContainerMobile
      projectLessonActivityTabs={
        (isInBag() && isInSchedule()) ||
        profileDetails.role === "WAD" ||
        profileDetails.role === "CE"
          ? getProjectLessonActivityTabs(
              currentProject.module.category === LITTLE_PROJECTS
            )
          : onlyOverviewTab
      }
      isInBag={isInBag()}
      isInSchedule={isInSchedule()}
    />
  );
};

export default ProjectSummarySideMenuMobile;

import { LessonType } from "../Preview/constant";

import LT1Image from "./LT1Image";
import LT2Images from "./LT2Images";
import LT3Images from "./LT3Images";
import LT4Images from "./LT4Images";
import LT5Images from "./LT5Images";

const LessonPage = ({ lesson }: { lesson: LessonType }) => {
  const TemplateProps = {
    lesson,
    gallery: {
      lesson: "",
      images: [],
    },
  };

  const Template = [
    <LT1Image {...TemplateProps} />,
    <LT2Images {...TemplateProps} />,
    <LT3Images {...TemplateProps} />,
    <LT4Images {...TemplateProps} />,
    <LT5Images {...TemplateProps} />,
  ];

  return Template[lesson.templateNo - 1];
};

export default LessonPage;

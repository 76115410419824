import React from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { translation } from "constants/translation";

const AlbumBreadcrumbs = ({
  moduleName,
  setModuleName,
  projectName,
  setProjectName,
}: {
  moduleName: string;
  setModuleName: React.Dispatch<React.SetStateAction<string>>;
  projectName: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const moduleId = searchParams.get("module");
  const projectId = searchParams.get("project");

  const showBreadcrumbs = moduleName !== "" && moduleId !== null;
  const showProjectName = projectName !== "" && projectId !== null;

  return showBreadcrumbs ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        mb: 1.25,
        minHeight: "20px",
      }}
    >
      <BreadCrumbsTitle
        title={translation.albums}
        isActive={moduleName === "" && projectName === ""}
        onClick={() => {
          setModuleName("");
          setProjectName("");
          setSearchParams({});
        }}
      />

      <NavigateNextIcon fontSize="small" />

      <BreadCrumbsTitle
        title={moduleName}
        isActive={showBreadcrumbs && !showProjectName}
        onClick={() => {
          setProjectName("");
          setSearchParams({ module: moduleId });
        }}
      />

      {showProjectName && (
        <>
          <NavigateNextIcon fontSize="small" />

          <BreadCrumbsTitle title={projectName} isActive={showProjectName} />
        </>
      )}
    </Box>
  ) : null;
};

export const BreadCrumbsTitle = ({
  title,
  isActive,
  onClick,
}: {
  title: string;
  isActive: boolean;
  onClick?: () => void;
}) => (
  <Typography
    sx={{
      cursor: isActive ? "default" : "pointer",
      fontWeight: isActive ? 700 : 400,
      color: isActive ? "#407374" : "black",
      lineHeight: 1,
    }}
    onClick={onClick}
  >
    {title}
  </Typography>
);

export default AlbumBreadcrumbs;

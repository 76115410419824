import axios from "api/axiosConfig";
import { AxiosError } from "axios";
import { API_Lesson_Learning_Moment_Checkpoint_Count } from "types/lesson-activity";
import { Image } from "react-grid-gallery";

export const getLessonIdIncompleteLearningMomentList = async ({
  language,
  groupId,
  projectId,
}: {
  language: string;
  groupId: number;
  projectId: number | string;
}) => {
  try {
    const res = await axios.get(
      `${language}/cms/lesson/get-lesson-id-incomplete-learning-moment-list?group=${groupId}&project=${projectId}`
    );
    const data: number[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getIsLessonDone = async (
  language: string,
  accessToken: string | null,
  lessonId: number,
  groupId: number,
  projectId: number | string
) => {
  try {
    const res = await axios.get(
      `${language}/cms/lesson/${lessonId}/get-is-lesson-done?group=${groupId}&project=${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: boolean = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getIsLessonLearningMomentDone = async (
  language: string,
  accessToken: string | null,
  lessonId: number,
  groupId: number,
  projectId: number | string
) => {
  try {
    const res = await axios.get(
      `${language}/cms/lesson/${lessonId}/get-is-lesson-learning-moment-done?group=${groupId}&project=${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: boolean = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getIsActivityLearningMomentDone = async (
  language: string,
  accessToken: string | null,
  activityId: number,
  groupId: number,
  projectId: number | string
) => {
  try {
    const res = await axios.get(
      `${language}/cms/activity/${activityId}/get-is-learning-moment-done?group=${groupId}&project=${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: boolean = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLessonLearningMomentCheckpointCount = async (
  language: string,
  accessToken: string | null,
  projectId: number,
  groupId: number
) => {
  try {
    const res = await axios.get(
      `${language}/cms/project/${projectId}/get-lesson-learning-moment-checkpoint-count?group=${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: API_Lesson_Learning_Moment_Checkpoint_Count = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getInstructionLearningMomentPhoto = async (
  language: string,
  accessToken: string | null,
  instructionId: number,
  groupId: number
) => {
  try {
    const res = await axios.get(
      `${language}/cms/activity/instruction/${instructionId}/get-learning-moment-photo?group=${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: Image[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

import { useState, MouseEvent, useEffect } from "react";
import {
  Box,
  Typography,
  Popover,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useParams } from "react-router-dom";
import { translation } from "constants/translation";
import { slugify } from "helper/slugify";
import { CURLANG, TEACHER_PORTFOLIO } from "constants/url";
import useStore from "store/store";
import Image from "components/Image/Image";

import progressReportIcon from "images/children-portfolio/sidebar/progress-report.png";
import completedWorksIcon from "images/children-portfolio/sidebar/completed-works.png";
import photoAlbumIcon from "images/children-portfolio/sidebar/photo-album.png";
import projectSummaryIcon from "images/children-portfolio/sidebar/project-summary.png";
import {
  teacherArchiveTabs,
  teacherArchiveTranslatedTabs,
} from "./teacherArchivePageConstant";

const ICONS = [
  progressReportIcon,
  completedWorksIcon,
  photoAlbumIcon,
  projectSummaryIcon,
];

const TeacherArchiveMiniDrawer = ({ currentTab }: { currentTab: string }) => {
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { open, setOpen, profileDetails } = useStore((state) => ({
    open: state.isPortfolioMiniDrawerOpen,
    setOpen: state.setIsPortfolioMiniDrawerOpen,
    profileDetails: state.profileDetails,
  }));
  const [name, setName] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen(!open);
  useEffect(() => {
    if (isBelowSm) {
      setOpen(false);
    }
  }, [isBelowSm]);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(240, 240, 240, 0.5)",
        maxWidth: "300px",
        flex: open ? "1 0 auto" : "0 0 auto",
        p: 2,
        pt: "21px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        transition: "all 200ms ease-in-out",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mb: 1,
        }}
      >
        {open && (
          <Typography
            sx={{
              fontWeight: "bold",
              lineHeight: 1,
              mr: "auto",
            }}
          >
            {translation.formatString(translation.teacherNameArchive, {
              name: profileDetails.preferred_name,
            })}
          </Typography>
        )}

        <Box
          sx={{
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0.75,
            m: 0.5,
            borderRadius: "999px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
            },
          }}
          onClick={handleDrawer}
        >
          {isBelowSm ? null : open ? (
            <CloseIcon fontSize="small" />
          ) : (
            <ChevronRightIcon fontSize="small" />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {teacherArchiveTabs.map((tab, index) => (
          <DrawerButton
            key={index}
            tab={tab}
            currentTab={currentTab}
            icon={ICONS[index]}
            open={open}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
        ))}
      </Box>

      {!open && name !== "" && (
        <Popover
          sx={{
            pointerEvents: "none",
            borderRadius: "10px",
            ml: 1,
          }}
          elevation={2}
          open={openPopOver}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            sx={{
              color: "#444",
              p: 1.5,
              lineHeight: 1,
            }}
          >
            {name}
          </Typography>
        </Popover>
      )}
    </Box>
  );
};

const DrawerButton = ({
  tab,
  currentTab,
  icon,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}: {
  tab: string;
  currentTab: string;
  icon: string;
  open: boolean;
  handlePopoverOpen: (event: MouseEvent<HTMLElement>, name: string) => void;
  handlePopoverClose: () => void;
}) => {
  const { groupSlug, childID } = useParams();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const isActiveTab = tab.toLowerCase() === currentTab.toLowerCase();

  return (
    <Link
      to={`${CURLANG(currLanguage)}/${TEACHER_PORTFOLIO}/${slugify(tab)}`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "8px",
        cursor: isActiveTab ? "default" : "pointer",
        textDecoration: "none",
        color: "#444",
      }}
      onMouseEnter={(e) =>
        handlePopoverOpen(e, teacherArchiveTranslatedTabs[tab])
      }
      onMouseLeave={() => handlePopoverClose()}
      onClick={(e) => {
        if (isActiveTab) {
          e.preventDefault();
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          p: 0.5,
          width: "40px",
          height: "40px",
          borderRadius: "999px",
          border: "1px solid",
          borderColor: isActiveTab ? "#98C4C5" : "#F0F0F0",
          backgroundColor: isActiveTab ? "primary.light" : "var(--txt-light)",
        }}
      >
        <Image
          src={icon}
          alt={slugify(tab)}
          style={{
            backgroundColor: "white",
            borderRadius: "999px",
            padding: "2px",
          }}
          objectFit="contain"
        />
      </Box>

      {open && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: isActiveTab ? "bold" : "normal",
            color: isActiveTab ? "primary.main" : "#444",
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {teacherArchiveTranslatedTabs[tab]}
        </Typography>
      )}
    </Link>
  );
};

export default TeacherArchiveMiniDrawer;

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import useStore from "store/store";

import Background from "images/end-of-project-report/overview-bg.png";
import ImageTape from "images/end-of-project-report/overview-img-tape.png";
import Image from "../../Image/Image";
import SelectImageModal from "./SelectImageModal";

import { Overlay } from "./Preview";
import { PDFInfoType, editableStyle, styles, ImageType } from "./constant";

const Overview = ({
  scale,
  PDFInfo,
  setPDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [images, setImages] = useState<ImageType[]>([]);

  const { project, lessons } = useStore((state) => ({
    project: state.currentProject,
    lessons: state.currentLessons.lessons,
  }));

  useEffect(() => {
    const projectImages: ImageType[] = project.images.map((image) => ({
      id: image.id,
      file: image.file,
    }));

    const lessonsImages: ImageType[] = [];

    lessons.forEach((lesson) => {
      if (lesson.images.length >= 1) {
        lesson.images.map((image) =>
          lessonsImages.push({ id: Math.random(), file: image.file })
        );
      }
    });

    setImages([...projectImages, ...lessonsImages]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (key: string, value: string | null) =>
    setPDFInfo((prev) => ({ ...prev, [key]: value ? value : "" }));

  const strip = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Box sx={styles.body}>
      <Image src={Background} alt="" />

      <Box
        sx={{
          ...styles.main,
          p: `${40 * scale}px`,
          mt: `${115 * scale}px`,
          mb: `${124 * scale}px`,
          ml: `${182 * scale}px`,
          mr: `${317 * scale}px`,
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: 40 * scale,
            fontWeight: 500,
            lineHeight: 1.25,
            mt: `${20 * scale}px`,
            mb: `${40 * scale}px`,
          }}
        >
          {PDFInfo.overviewTitle}
        </Typography>

        <Typography
          sx={{
            ...editableStyle,
            flex: 1,
            textAlign: "center",
            fontSize: 24 * scale,
          }}
          contentEditable
          onBlur={(e) => handleChange("overview", e.currentTarget.textContent)}
          suppressContentEditableWarning={true}
        >
          {strip(PDFInfo.overview)}
        </Typography>
      </Box>

      {PDFInfo.overviewImage !== null && (
        <>
          <Box
            sx={{
              ...editableStyle,
              position: "absolute",
              top: `${530 * scale}px`,
              left: `${1070 * scale}px`,
              transformOrigin: "top left",
              transform: "rotate(-6deg)",
              width: `${320 * scale}px`,
              height: `${200 * scale}px`,
              zIndex: 2,
              cursor: "pointer",
            }}
            onClick={() => setOpenModal(true)}
          >
            <Overlay />
            <Image src={PDFInfo.overviewImage.file} alt="" objectFit="cover" />
          </Box>

          <Image
            src={ImageTape}
            style={{
              ...styles.staticImage, top: 40, left: 60,
              zIndex: 3,
            }}
            alt=""
          />

          {openModal && (
            <SelectImageModal
              open={openModal}
              setOpen={setOpenModal}
              images={images}
              setImage={(image: ImageType) =>
                setPDFInfo((prev) => ({
                  ...prev,
                  overviewImage: image,
                }))
              }
              selectedImage={PDFInfo.overviewImage}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Overview;

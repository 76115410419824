import { ReactNode } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { translation } from "constants/translation";
import { TreeViewData, Selected } from "./types";
import DashedButton from "components/Button/DashedButton";

const titleStyles: SxProps = {
  fontSize: "1.125rem",
  lineHeight: 1.25,
};

type Props = {
  lessons: TreeViewData[];
  lessonId: string;
  selected: Selected | null;
  lessonIndex: number;
  isSortingContainer: boolean;
  children: ReactNode;
  handleOpen: (selected: Selected) => void;
}

const Lesson = ({
  lessons,
  lessonId,
  lessonIndex,
  selected,
  isSortingContainer,
  children,
  handleOpen,
}: Props) => {
  const { listeners, isDragging } = useSortable({ id: lessonId });

  const lesson = lessons.find((lesson) => lesson.id === lessonId);

  if (!lesson) { 
    return null;
  }

  return (
    <Box
      sx={{
        padding: "0 16px",
      }}
    >
      <Box
        className={
          selected && selected.id === lesson.id && selected.type === lesson.type
            ? "active"
            : ""
        }
        sx={{ 
          borderRadius: "8px",
          cursor: isDragging ? "grabbing" : "pointer",
          color: "cyan.700",
          "&:not(.active):hover": {
            backgroundColor: "rgba(0,0,0,0.04)",
          },
        }}
        onClick={() =>
          handleOpen({
            id: lesson.id,
            index: lessonIndex,
              type: lesson.type,
            assessment: !!lesson.assessment,
          })
        }
      >
        <Typography sx={titleStyles} {...listeners}>
          {translation.formatString(lesson.assessment ? translation.assessmentPlanNo : translation.lessonNo, {
            number: lessonIndex + 1,
          })}
          : {lesson.name}
        </Typography>
      </Box>

      {/* Activities */}
      {children}

      <Box
        sx={{
          my: 1,
          ml: 3,
          cursor: isDragging || isSortingContainer ? "grabbing" : "auto",
        }}
      >
        <DashedButton
          sx={{
            py: "6px",
          }}
          text={translation.add_activity}
          onClick={() =>
            handleOpen({
              id: "-1",
              index: 0,
              type: "Activity",
              parentId: lesson.id,
              parentIndex: lessonIndex,
              assessment: !!lesson.assessment,
            })
          }
        />
      </Box>
    </Box>
  );
};

export default Lesson;

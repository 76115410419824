import React, { useState } from "react";
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import { Image } from "react-grid-gallery";
import PhotoVideo from "./PhotoVideo";

import { GalleryPhotoVideo } from "types/gallery";

const paginateImages = (images: Image[], maxRows = 4): Record<number, Image[]> => {
  const imagesPerRow = 5;

  // Calculate the total number of images per page
  const imagesPerPage = maxRows * imagesPerRow;

  // Calculate the total number of pages
  const totalPages = Math.ceil(images.length / imagesPerPage);

  // Create an array to hold the paginated images
  const paginatedImages: Record<number, Image[]> = {};

  // Iterate through each page
  for (let page = 0; page < totalPages; page++) {
    const startIndex = page * imagesPerPage;
    const endIndex = startIndex + imagesPerPage;

    // Slice the images for the current page
    const pageImages = images.slice(startIndex, endIndex);

    // Add the current page's images to the paginatedImages array
    paginatedImages[page] = pageImages;
  }

  return paginatedImages;
};


type Props = {
  photoVideoGalleryList: GalleryPhotoVideo[];
  galleryIndex: number;
  setCurrPhotoVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  setEditCaption: React.Dispatch<React.SetStateAction<boolean>>;
  isLearningMomentPhotoAlbum?: boolean;
  selectedPhotoVideoIDs: string[];
  setSelectedPhotoVideoIDs: React.Dispatch<React.SetStateAction<string[]>>;
  fullFrame: boolean;
  milestone?: any;
}

const PhotoVideoList = ({
  photoVideoGalleryList,
  galleryIndex,
  setCurrPhotoVideoIndex,
  setEditCaption,
  isLearningMomentPhotoAlbum = false,
  selectedPhotoVideoIDs,
  setSelectedPhotoVideoIDs,
  fullFrame,
  milestone
}: Props) => {
  const rowsToShow = fullFrame ? 4 : 1;
  const allImages = photoVideoGalleryList[galleryIndex].photo_video_list;
  const [page, setPage] = useState(0);
  const paginatedImagesDict = paginateImages(allImages, rowsToShow);



  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: milestone && !fullFrame ? "none" : { xs: "grid", sm: "flex" },
          gridTemplateColumns: "1fr 1fr",
          gap: "12px",
          flexWrap: "wrap",
          // maxHeight: fullFrame ? "auto" : "204px",
          maxHeight: fullFrame && milestone ? "auto" : fullFrame ? "auto" : "204px",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {paginatedImagesDict[page]?.map(
          (galleryPhotoVideo, index) => (
            <PhotoVideo
              key={`${galleryPhotoVideo.key}`}
              index={(rowsToShow * 5) * page + index}
              galleryPhotoVideo={galleryPhotoVideo}
              setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
              setEditCaption={setEditCaption}
              isLearningMomentPhotoAlbum={isLearningMomentPhotoAlbum}
              selectedPhotoVideoIDs={selectedPhotoVideoIDs}
              setSelectedPhotoVideoIDs={setSelectedPhotoVideoIDs}
            />
          ))}
      </Box>
      {
        !(milestone && !fullFrame) && <Pagination
          count={Object.keys(paginatedImagesDict).length}
          onChange={(_, v) => {
            setPage(v - 1);
          }} />
      }


    </Box>
  );
};

export default PhotoVideoList;
import {  useTheme } from "@mui/material";

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { API_Teacher_Module_Visualization_Demographic_Info } from "types/teacher";
import { countryCoordinates } from "./countryCoordinates";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const MapChart = ({
  data,
}: {
  data: API_Teacher_Module_Visualization_Demographic_Info[];
}) => {
  const theme = useTheme();

  const countryCounts: { [country: string]: number } = {};
  data.forEach((item) => {
    if (countryCounts[item.country]) {
      countryCounts[item.country]++;
    } else {
      countryCounts[item.country] = 1;
    }
  });

  const colorScale = (count: number) => {
    if (count > 100) {
      return theme.palette.custom.yellow;
    } else if (count > 50) {
      return theme.palette.custom.lightOrange;
    } else {
      return theme.palette.custom.lightBlue;
    }
  };

  return (
    <ComposableMap
      style={{
        width: "100%",
        height: "auto",
        position: "relative",
        border: "1px solid var(--primary-main)",
      }}
    >
      <ZoomableGroup zoom={2}>
        <Geographies geography={geoUrl}>
          {({ geographies }: { geographies: any }) =>
            geographies.map((geo: any) => {
              const count = countryCounts[geo.properties.name];
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={count ? colorScale(count) : theme.palette.primary.main}
                  style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                  }}
                />
              );
            })
          }
        </Geographies>
        {Object.keys(countryCounts).map((country, index) => {
          const coordinates = [
            countryCoordinates[country].lng,
            countryCoordinates[country].lat,
          ] as [number, number];

          return (
            <Marker key={country} coordinates={coordinates}>
              {/* <Annotation
              subject={coordinates}
              connectorProps={{
                stroke: "#FF5533",
                strokeWidth: 1,
                strokeLinecap: "round",
              }}
              dx={randomDxDy[index % 4][0]} // offset distance from the marker
              dy={randomDxDy[index % 4][1]} // offset distance from the marker
              curve={0.5}
            > */}
              <circle
                r={10}
                color={theme.palette.custom.lightOrange}
                opacity={0.5}
                fill={theme.palette.custom.lightOrange}
              />
              <text
                textAnchor="middle"
                style={{
                  fontFamily: "Futura",
                  fill: "#5D5A6D",
                  fontSize: 7,
                  fontWeight: 600,
                }}
              >
                {`${country}`}
              </text>
              <text
                textAnchor="middle"
                y={8}
                style={{ fontFamily: "Futura", fill: "#5D5A6D", fontSize: 7 }}
              >
                {`${countryCounts[country]}`}
              </text>
              {/* </Annotation> */}
            </Marker>
          );
        })}
      </ZoomableGroup>
    </ComposableMap>
  );
};

export default MapChart;

import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, Stack, Divider } from "@mui/material";

import Button from "components/Button/Button";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import {
  API_Fundamental,
  API_Fundamental_Create,
  API_Milestone,
} from "types/project";

import {
  editFundamental,
  deleteFundamental,
  createFundamental,
  editFundamentalImage,
} from "api/fundamental-api";

import { CURLANG, EDIT_DEVELOPMENTAL_GOAL } from "constants/url";

import { translation } from "constants/translation";
//Prompt user when leaving without saving
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import {
  DescriptionField,
  ImageField,
  MilestoneFieldWithDnd,
  NameField,
  PriorityField,
} from "./EditDevelopmentalGoalFormComponents";

const EditDevelopmentalGoalForm = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const {
    currDevelopmentalGoal,
    setCurrDevelopmentalGoal,
    currDevelopmentalGoalTag,
    developmentalGoals,
    setDevelopmentalGoals,
  } = useStore((state) => ({
    currDevelopmentalGoal: state.currDevelopmentalGoal,
    setCurrDevelopmentalGoal: state.setCurrDevelopmentalGoal,
    currDevelopmentalGoalTag: state.currDevelopmentalGoalTag,
    developmentalGoals: state.developmentalGoals,
    setDevelopmentalGoals: state.setDevelopmentalGoals,
  }));
  const openSnack = useSnack();
  const navigate = useNavigate();

  const { currLanguage, setIsRefreshAllFundamental } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setIsRefreshAllFundamental: state.setIsRefreshAllFundamental,
  }));

  const [image, setImage] = useState<File | string>("");
  const [currMilestones, setCurrMilestones] = useState<API_Milestone[]>([]);
  const [mainFormData, setMainFormData] = useState<API_Fundamental_Create>({
    ...currDevelopmentalGoal,
    tag: currDevelopmentalGoalTag.toLowerCase(),
  });

  useEffect(() => {
    setMainFormData({
      ...currDevelopmentalGoal,
      tag:
        currDevelopmentalGoal.tag !== ""
          ? currDevelopmentalGoal.tag
          : currDevelopmentalGoalTag.toLowerCase(),
    });
    setImage(currDevelopmentalGoal.image);
    setCurrMilestones(currDevelopmentalGoal.milestones);
  }, [currDevelopmentalGoal]);

  const handleSubmit = async () => {
    const finalMainFormData: API_Fundamental_Create = {
      ...mainFormData,
      milestones: currMilestones,
      image: "to be delete", //use only the image declared in the usestate to update
    };
    delete finalMainFormData.image;
    delete finalMainFormData.image_colored;

    let isRunImageUpdate = true;
    if (typeof image === "string" && image.length > 0) {
      // string file remains, so don't send image go, removing image whatsoever.
      isRunImageUpdate = false;
    }

    let res = await editFundamental(
      currDevelopmentalGoal.id,
      finalMainFormData,
      currLanguage
    );

    // TODO handle image_colored payload
    // Milestone Files

    // if (typeof res !== "string") {
    //   // console.log("runnning updateing milestone file");
    //   finalMainFormData.milestones.forEach(async (milestone, index) => {
    //     if (milestone.new_files && milestone.new_files.length > 0) {
    //       const milestoneRes = await addMilestoneFile(
    //         Number(milestone.id),
    //         milestone.new_files,
    //         currLanguage
    //       );
    //       if (typeof milestoneRes !== "string") {
    //         res.milestones[index].files = [
    //           ...res.milestones[index].files,
    //           ...milestoneRes,
    //         ];
    //       }
    //     }
    //   });
    // }

    if (typeof res !== "string") {
      if (isRunImageUpdate) {
        res = await editFundamentalImage(res.id, image, currLanguage);
      }
    }

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const updatedDevelopmentalGoals: API_Fundamental[] = JSON.parse(
        JSON.stringify(developmentalGoals)
      );
      const index = updatedDevelopmentalGoals.findIndex(
        (developmentalGoal) => developmentalGoal.id === mainFormData.id
      );
      updatedDevelopmentalGoals[index] = res;
      setDevelopmentalGoals(updatedDevelopmentalGoals);

      setCurrMilestones(res.milestones);
      setIsRefreshAllFundamental(true);
      navigate(`${CURLANG(currLanguage)}/${EDIT_DEVELOPMENTAL_GOAL}`);
      openSnack(translation.updatedDevelopmentalGoal, true);
      handleClose();
    }
  };

  return false ? (
    <LoadingIndicator />
  ) : (
    <>
      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        {/* NameField, DescriptionField, MilestoneField, ImageField */}
        <NameField data={mainFormData} setData={setMainFormData} />
        <PriorityField data={mainFormData} setData={setMainFormData} />

        <DescriptionField data={mainFormData} setData={setMainFormData} />

        <MilestoneFieldWithDnd
          currDevelopmentalGoal={currDevelopmentalGoal}
          currMilestones={currMilestones}
          setCurrMilestones={setCurrMilestones}
          handleClose={handleClose}
        />
        <ImageField image={image} setImage={setImage} />
        <Divider />
        <DeleteDevelopmentalGoalButton handleClose={handleClose} />

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          <Button
            buttonText={translation.update}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
              height: "auto",
            }}
            onClick={() => {
              if (mainFormData.name === "") {
                openSnack(
                  "Please fill in the developemental goal name!",
                  false
                );
              }
              handleSubmit();
            }}
          />
        </Box>
      </Stack>
    </>
  );
};

const DeleteDevelopmentalGoalButton = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const {
    currDevelopmentalGoal,
    developmentalGoals,
    setDevelopmentalGoals,
    setIsRefreshAllFundamental,
  } = useStore((state) => ({
    currDevelopmentalGoal: state.currDevelopmentalGoal,
    developmentalGoals: state.developmentalGoals,
    setDevelopmentalGoals: state.setDevelopmentalGoals,
    setIsRefreshAllFundamental: state.setIsRefreshAllFundamental,
  }));
  const openSnack = useSnack();
  const navigate = useNavigate();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteFundamental(currDevelopmentalGoal.id, currLanguage);
    if (res !== "success") {
      openSnack(res, false);
    } else {
      openSnack(
        currDevelopmentalGoal.main_fundamental
          ? translation.removedSubDevelopmentalGoal
          : translation.removedDevelopmentalGoal,
        true
      );
      setIsRefreshAllFundamental(true);
      // setDevelopmentalGoals(
      //   developmentalGoals.filter(
      //     (developmentalGoal) =>
      //       developmentalGoal.id !== currDevelopmentalGoal.id
      //   )
      // );
      handleClose();
      navigate(`${CURLANG(currLanguage)}/${EDIT_DEVELOPMENTAL_GOAL}`);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! ${currDevelopmentalGoal.name} will be deleted.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currDevelopmentalGoal.name]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Developmental Goal?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <Button
        arrow={false}
        buttonText={
          translation.deleteDevelopmentalGoal || "Delete Developmental Goal"
        }
        btnType="outlined"
        style={{
          fontSize: "14px",
          marginTop: 0,
        }}
        onClick={handleOpenDialog}
      />
    </>
  );
};

export default EditDevelopmentalGoalForm;

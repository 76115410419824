import React, { useState, useRef, useEffect } from "react";
import { Typography, Box, Button, TextField, IconButton } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ListIcon from "@mui/icons-material/List";
import useStore from "store/store";
import Divider, { dividerClasses } from "@mui/material/Divider";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Document, Page, pdfjs } from "react-pdf";
import SvgIcon from "@mui/material/SvgIcon";
import FlipPage from "react-flip-page";
import { TeacherLessonActivityScreenDoneButton } from "../../TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FlipBookPDFComponentProps {
  file: File | string | null;
  dimensions: { width: number; height: number };
  scale: number;
  currTextActivityComponentIndex?: any;
  setCurrTextActivityComponentIndex?: any;
}

const BUTTON_WIDTH = 0; // Width of the button
const MARGIN = 12; // Margin on either side of the flip book

const FlipBookPDFComponent: React.FC<FlipBookPDFComponentProps> = ({
  file,
  dimensions,
  scale,
  currTextActivityComponentIndex,
  setCurrTextActivityComponentIndex,
}) => {
  const { fullScreen, setFullScreen, teacherCurrentActivity } = useStore(
    (state) => state
  );

  const [pageSizes, setPageSizes] = useState<
    Array<{ width: number; height: number }>
  >([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [displayMiniViewer, setDisplayMiniViewer] = useState<boolean>(true);

  const flipPageRef = useRef<any>(null);
  const mainPageContainerRef = useRef<any>(null);
  const miniViewerRef = useRef<any>(null);
  const controlsRef = useRef<any>(null);

  const [mainContainerPageBaseWidth, setMainContainerPageBaseWidth] =
    useState<number>(0);
  const [mainContainerPageBaseHeight, setMainContainerPageBaseHeight] =
    useState<number>(0);
  const [mainContainerPageExtendedWidth, setMainContainerPageExtendedWidth] =
    useState<number>(0);
  const [mainContainerPageExtendedHeight, setMainContainerPageExtendedHeight] =
    useState<number>(0);

  const [inputPage, setInputPage] = useState("");

  const [pageScale, setPageScale] = useState<number>(scale);

  // This hook updates the input field whenever the current page changes
  useEffect(() => {
    setInputPage(currentPage.toString());
  }, [currentPage]);

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageSizes(
      new Array(numPages).fill({
        width: dimensions.width,
        height: dimensions.height,
      })
    );
    setNumPages(numPages);
    setCurrentPage(1); // Reset to first page when a new document is loaded
  };

  const onRenderSuccess = (index: number) => (page: any) => {
    const scaledHeight = (100 / page.originalWidth) * page.originalHeight;
    setPageSizes((prev) => {
      const newSizes = [...prev];
      newSizes[index] = { width: 150, height: 20 };
      return newSizes;
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (mainPageContainerRef.current) {
        if (!fullScreen) {
          if (
            mainContainerPageBaseWidth == 0 &&
            mainContainerPageBaseHeight == 0
          ) {
            setMainContainerPageBaseWidth(
              mainPageContainerRef.current.offsetWidth
            );
            setMainContainerPageBaseHeight(
              mainPageContainerRef.current.offsetHeight - 50
            );
          }
        } else {
          if (
            mainContainerPageExtendedWidth == 0 &&
            mainContainerPageExtendedHeight == 0
          ) {
            setMainContainerPageExtendedWidth(
              mainPageContainerRef.current.offsetWidth
            );
            setMainContainerPageExtendedHeight(
              mainPageContainerRef.current.offsetHeight - 50
            );
          }
        }
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [fullScreen]);

  const handlePrevPage = () => {
    const newPage = Math.max(1, currentPage - 1);
    setCurrentPage(newPage);
    if (setCurrTextActivityComponentIndex) {
      setCurrTextActivityComponentIndex(0);
    }
    flipPageRef.current.gotoPreviousPage();
    if (setCurrTextActivityComponentIndex) {
      setCurrTextActivityComponentIndex(0);
    }
  };

  const handleNextPage = () => {
    const newPage = Math.min(numPages, currentPage + 1);
    if (newPage == numPages && setCurrTextActivityComponentIndex) {
      setCurrTextActivityComponentIndex(1);
    }
    setCurrentPage(newPage);
    flipPageRef.current.gotoNextPage();
  };

  const onPageChange = (pageIndex: number) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleInputPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPageNumber = parseInt(event.target.value, 10);
    if (
      !isNaN(newPageNumber) &&
      newPageNumber >= 1 &&
      newPageNumber <= numPages
    ) {
      if (newPageNumber == numPages && setCurrTextActivityComponentIndex) {
        setCurrTextActivityComponentIndex(1);
      } else if (
        newPageNumber != numPages &&
        setCurrTextActivityComponentIndex
      ) {
        setCurrTextActivityComponentIndex(0);
      }
      setCurrentPage(newPageNumber);
      flipPageRef.current.gotoPage(newPageNumber - 1);
    }
    setInputPage(event.target.value);
  };

  const navigateToPage = (page: number) => {
    if (flipPageRef.current) {
      flipPageRef.current.gotoPage(page - 1);
    }
    if (page == numPages && setCurrTextActivityComponentIndex) {
      setCurrTextActivityComponentIndex(1);
    } else if (setCurrTextActivityComponentIndex) {
      setCurrTextActivityComponentIndex(0);
    }
    setCurrentPage(page);
  };

  return (
    <Box
      id="pdfContainer"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        overflowY: "hidden",
        padding: 0,
        margin: 0,
      }}
    >
      {fullScreen && (
        <Box
          sx={{
            height: "calc(100vh - 64px)",
            minWidth: "250px",
            maxWidth: "250px",
            borderRight: "0.5px solid black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              py: 2,
              px: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              setFullScreen(false);
            }}
          >
            <ArrowBackIcon />
            <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
              Flip Book View
            </Typography>
          </Box>
          <Box
            sx={{
              overflowY: "scroll",
              overflowX: "hidden",
              width: "100%",
              height: "100%",
            }}
            id="scrollbar"
          >
            {" "}
            <Document
              file={file}
              options={{ cMapUrl: "cmaps/", cMapPacked: true }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.5,
                }}
              >
                {pageSizes.map((size, index) => (
                  <Box
                    key={index}
                    onClick={() => navigateToPage(index + 1)}
                    sx={{ width: 250, mb: 0.5, position: "relative" }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        zIndex: 2,
                        borderRadius: "50%",
                        height: "25px",
                        width: "25px",
                        backgroundColor: "#323031B2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 1,
                      }}
                    >
                      <Typography sx={{ color: "white", fontSize: "14px" }}>
                        {index + 1}
                      </Typography>
                    </Box>
                    <Page
                      pageNumber={index + 1}
                      width={250}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </Box>
                ))}
              </Box>
            </Document>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          py: fullScreen ? 3 : 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
          ref={mainPageContainerRef}
        >
          <IconButton sx={{}}>
            <ArrowBackIosNewIcon onClick={handlePrevPage} />
          </IconButton>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: fullScreen
                ? mainContainerPageExtendedWidth
                : mainContainerPageBaseWidth,
            }}
          >
            <Document
              file={file}
              onLoadSuccess={onLoadSuccess}
              options={{ cMapUrl: "cmaps/", cMapPacked: true }}
            >
              <Box
                sx={{
                  height: fullScreen
                    ? mainContainerPageExtendedHeight
                    : mainContainerPageBaseHeight,
                  overflowY: "hidden",
                }}
              >
                <FlipPage
                  orientation="horizontal"
                  flipOnTouch={true}
                  showSwipeHint={true}
                  height={
                    fullScreen
                      ? mainContainerPageExtendedHeight
                      : mainContainerPageBaseHeight
                  }
                  width={"100%"}
                  ref={flipPageRef}
                  onPageChange={onPageChange}
                >
                  {pageSizes.map((size, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        m: 0,
                        p: 0,
                        height: fullScreen
                          ? mainContainerPageExtendedHeight
                          : mainContainerPageBaseHeight,
                      }}
                    >
                      <Page
                        pageNumber={index + 1}
                        height={
                          fullScreen
                            ? mainContainerPageExtendedHeight
                            : mainContainerPageBaseHeight
                        }
                        onRenderSuccess={onRenderSuccess(index)}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    </Box>
                  ))}
                </FlipPage>
              </Box>
            </Document>
          </Box>
          <IconButton sx={{}}>
            <ArrowForwardIosIcon onClick={handleNextPage} />
          </IconButton>
        </Box>

        <Box
          sx={{
            visibility: displayMiniViewer ? "visible" : "hidden",
            display: fullScreen ? "none" : "block",
          }}
          ref={miniViewerRef}
        >
          <Box
            sx={{
              // padding: 0,
              // marginBottom: "-10px",
              overflowY: "hidden",
              overflowX: "hidden",
              width: fullScreen
                ? mainContainerPageExtendedWidth
                : mainContainerPageBaseWidth,
            }}
          >
            <Document
              file={file}
              options={{ cMapUrl: "cmaps/", cMapPacked: true }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  overflowX: "scroll",
                }}
                id="scrollbar"
              >
                {pageSizes.map((size, index) => (
                  <Box
                    key={index}
                    onClick={() => navigateToPage(index + 1)}
                    sx={{ width: 115, position: "relative" }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        zIndex: 2,
                        borderRadius: "50%",
                        height: "20px",
                        width: "20px",
                        backgroundColor: "#323031B2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "white", fontSize: "14px" }}>
                        {index + 1}
                      </Typography>
                    </Box>
                    <Page
                      pageNumber={index + 1}
                      width={115}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </Box>
                ))}
              </Box>
            </Document>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            mt: 1,
          }}
          ref={controlsRef}
        >
          <Typography sx={{}}>Page</Typography>
          <TextField
            size="small"
            type="number"
            value={inputPage}
            onChange={handleInputPageChange}
            InputProps={{
              inputProps: {
                min: 1,
                max: numPages,
              },
            }}
            sx={{ width: "auto", margin: "3px" }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              color: "#323031",
              mr: 1,
            }}
          >
            / {numPages}
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "black", my: "5px" }}
          />

          <IconButton
            onClick={() => {
              setDisplayMiniViewer(!displayMiniViewer);
            }}
            disableRipple
            disabled={fullScreen}
            sx={{
              ml: "5px",
              color: "inherit",
              "&:hover": {
                borderRadius: "0px",
                border: "0px",
                boxShadow: "0 0 0 0.1px rgba(0,0,0,1)",
                backgroundColor: "#DDDDDD",
              },
              ...(displayMiniViewer && {
                borderRadius: "0px",
                border: "0px",
                boxShadow: "0 0 0 0.1px rgba(0,0,0,1)",
                backgroundColor: "#DDDDDD",
              }),
              p: 0,
            }}
          >
            <ListIcon sx={{ m: 0, p: 0, fontSize: "32px" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setFullScreen(!fullScreen);
            }}
            sx={{ color: "inherit" }}
          >
            <OpenInFullIcon />
          </IconButton>
        </Box>
        <TeacherLessonActivityScreenDoneButton
          activityId={teacherCurrentActivity.id}
          currTextActivityComponentIndex={currTextActivityComponentIndex}
        />
      </Box>
    </Box>
  );
};

export default React.memo(FlipBookPDFComponent);
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import {
  Box,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { API_Project_Lesson } from "types/project";
import { getDrawingsByID } from "api/drawing-api";
import { API_Element_Optional, Element } from "types/drawing";
import { ASSESSMENT_PLAN_PAGE, CURLANG, LESSON_ACTIVITY_PAGE } from "constants/url";
import { translation } from "constants/translation";
import Content, { FullscreenButton } from "../CanvasComponents/Content";
import { LITTLE_LEARNERS } from "containers/CalendarPage/constants/constants";
import { staticTabStyle } from "containers/ProjectSummaryPage/Screens/ConceptScreen";

import "../flipBook.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import HTMLFlipBook from "react-pageflip";
import { vhToPx, vwToPx } from "containers/CalendarPage/constants/helper";
import { contentMobileFontSize } from "constants/constantInGeneral";
import { contentFontSize } from "../learningMomentConstant";
import ActivityAction from "./ActivityAction";

type Props = {
  assessment?: boolean;
  lesson: API_Project_Lesson;
  activityIndex: number;
};

const Canvas = ({
  assessment = false,
  lesson,
  activityIndex,
}: Props) => {
  const { projectSlug, assessmentSlug, lessonID, assessmentID, activityID } = useParams();

  const {
    profileID,
    currLanguage,
  } = useStore((state) => ({
    ...state,
    profileID: state.profileDetails.id,
  }));
  const openSnack = useSnack();
  const navigate = useNavigate();

  const [oldDrawings, setOldDrawings] = useState<Element[]>();
  const containerRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();

  const [controlPos, setControlPos] = useState<{
    top: number;
    left: number;
  }>({ top: 200, left: -100 });

  useEffect(() => {
    const initialiseDrawings = async () => {
      const res = await getDrawingsByID(
        profileID,
        lesson.activities[activityIndex].id
      );
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        setOldDrawings(
          res.map((drawing: API_Element_Optional, index) => {
            delete drawing.activity;
            delete drawing.profile;
            drawing.id = index;
            return drawing;
          }) as Element[]
        );
      }
    };
    activityIndex !== -1 && initialiseDrawings();
  }, [profileID, activityIndex]);

  const updateControlPos = () => {
    if (containerRef.current) {
      setControlPos({
        top: containerRef.current.getBoundingClientRect().top,
        left: containerRef.current.getBoundingClientRect().left - 80,
      });
    }
  };

  useEffect(() => {
    const updateWhenScrollNResize = () => {
      updateControlPos();
      updateBookSize();
    };

    setTimeout(() => {
      updateWhenScrollNResize();
    }, 500);
    window.addEventListener("scroll", updateWhenScrollNResize);
    window.addEventListener("resize", updateWhenScrollNResize);

    return () => {
      window.removeEventListener("scroll", updateWhenScrollNResize);
      window.removeEventListener("resize", updateWhenScrollNResize);
    };
  }, []);

  const isLittleLearners = lesson.module_category === LITTLE_LEARNERS;

  // Tabs
  const handleActivityChange = (
    event: React.SyntheticEvent,
    activityIndex: number
  ) => {
    const activityId = lesson.activities[activityIndex].id;
    const navigateTo = assessment ? ASSESSMENT_PLAN_PAGE : LESSON_ACTIVITY_PAGE;
    navigate(
      `${CURLANG(currLanguage)}/${navigateTo(
        assessment ? assessmentSlug! : projectSlug!,
        lesson.id.toString()
      )}/activity/${activityId}`
    );
  };

  //Pagination
  const stepsPerPage = 10;

  const book = useRef<any>();

  const [page, setPage] = useState(1);

  const onFlip = (e: any) => {
    setPage(e.data + 1);
  };

  const onChangeState = (flipEvent: any) => {
    // 'flipping' or 'read'
    setIsAnimating(flipEvent.data === "flipping");
  };

  // Animation
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [bookWidth, setBookWidth] = useState<number>(
    Math.max(1, vwToPx(70) - 300)
  );
  const [bookHeight, setBookHeight] = useState<number>(
    Math.max(1, vhToPx(55 * 0.75))
  );
  // const [bookWidth, setBookWidth] = useState<number>(10);
  // const [bookHeight, setBookHeight] = useState<number>(10);

  const updateBookSize = () => {
    if (containerRef.current) {
      setBookWidth(containerRef.current.clientWidth);
      setBookHeight(containerRef.current.offsetHeight);
    }
  };

  const [lessonActivityPage, setLessonActivityPage] = useState<any[]>([]);
  useEffect(() => {
    const entityId = lesson.assessment ? assessmentID : lessonID;
    if (entityId && activityID) {
      setPage(1);
      setLessonActivityPage([]);
      const temp = [];
      for (
        let i = 0;
        i < lesson.activities[activityIndex].instructions.length;
        i += stepsPerPage
      ) {
        temp.push(
          <Box
            key={i}
            sx={{
              position: "absolute",
              top: "0px !important",
              left: "0px !important",
              bgcolor: "white",
              width: "100% !important",
              height: "100% !important",
            }}
          >
            <Content
              contentRef={contentRef}
              currentLesson={lesson}
              currentActivity={lesson.activities[activityIndex]}
              currentInstructions={
                lesson.activities[activityIndex].instructions
              }
            />
          </Box>
        );
      }
      setLessonActivityPage(temp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonID, assessmentID, activityID]);

  useEffect(() => {
    book.current?.pageFlip()?.turnToPage(0);
  }, [lessonID, assessmentID, activityID]);

  const isActivityCompulsory =
    lesson.activities.filter((activity) => activity.is_compulsory === true)
      .length > 1;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          justifyContent: "center",
          flex: 1,
        }}
      >
        {lesson.activities.length > 1 ? (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 500,
              fontSize: { xs: contentMobileFontSize, sm: contentFontSize },
              ml: 2,
              color: "primary.main",
            }}
          >
            {`${
              isLittleLearners || isActivityCompulsory
                ? translation.completeAll
                : translation.pickToComplete
            }`}
          </Typography>
        ) : (
          <Box sx={{ mb: 1 }} />
        )}

        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignSelf: "stretch",
            }}
          >
            <Tabs
              value={activityIndex}
              onChange={handleActivityChange}
              variant="fullWidth"
              TabIndicatorProps={{ style: { display: "none" } }}
              // sx={{ ml: 2 }}
            >
              {lesson.activities.map((activity, activityIndex_) => {
                return (
                  <Tab
                    key={`tab-${activity.id}-${activityIndex_}`}
                    label={`${
                      isLittleLearners ||
                      lesson.activities.length === 1 ||
                      isActivityCompulsory
                        ? lesson.activities.length === 1
                          ? translation.activity
                          : translation.formatString(translation.activityNo, {
                              number: activityIndex_ + 1,
                            })
                        : translation.formatString(translation.optionNo, {
                            number: activityIndex_ + 1,
                          })
                    }`}
                    sx={{
                      ...staticTabStyle,
                      ml: activityIndex_ === 0 ? 0 : 2,
                      backgroundColor:
                        Number(activityID) === activity.id
                          ? "primary.light"
                          : "transparent",
                    }}
                  />
                );
              })}
            </Tabs>

            <Box
              sx={{
                p: { xs: 1, sm: 2 },
                width: "100%",
                // height: { lg: `60vh` },
                flex: 1,
                border: "2px solid var(--primary-main)",
                backgroundColor: "background.paper",
                position: "relative",
                // ml: 2,
                overflow: "hidden",
                transition: "all 1s ease",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                display: "flex",
                flexDirection: "column",
                "& .stf__parent": {
                  minWidth: "inherit !important",
                  minHeight: "0 !important",
                  width: "inherit !important",
                  flex: 1,
                },
                "& .stf__wrapper": {
                  paddingBottom: "0 !important",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "left", sm: "center" },
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: 0.5, sm: 2 },
                    mr: 2,
                  }}
                >
                  <Typography sx={{ fontWeight: "600" }}>
                    {`${lesson.activities[activityIndex].name}`}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <AccessTimeIcon fontSize="small" />
                    {`${lesson.activities[activityIndex].duration} ${translation.min}`}
                  </Box>
                </Box>
                {document.fullscreenEnabled && <FullscreenButton />}
              </Box>
              <Divider sx={{ mb: 0.5 }} />

              {/* @ts-ignore */}
              <HTMLFlipBook
                key={`${bookWidth}-${bookHeight}-flip-book`}
                ref={book}
                onFlip={onFlip}
                onChangeState={onChangeState}
                width={bookWidth}
                height={bookHeight}
                minWidth={bookWidth}
                minHeight={bookHeight}
                flippingTime={750}
                size="stretch"
                useMouseEvents={false}
              >
                {lessonActivityPage}
              </HTMLFlipBook>
              <ActivityAction lesson={lesson} activityIndex={activityIndex} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Canvas;

import * as React from "react";
import { View, Text, Image as PdfImage, StyleSheet } from "@react-pdf/renderer";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import { checkIsChineseChar } from "helper/helper";

interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "90%",
    marginBottom: 20,
  },
  projectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box",
    margin: 15,
  },
  frameContainer: {
    position: "relative",
    width: 250,
    height: 220,
    marginBottom: -5,
  },
  frameImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  categoryText: {
    color: "#000",
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 1.2,
  },
  titleText: {
    color: "#000",
    fontWeight: 300,
    fontSize: 12,
    letterSpacing: 1.0,
  },
  singleProjectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box",
    marginTop: 20,
  },
  singleProjectImage: {
    position: "absolute",
    top: "9%",
    left: "11%",
    width: "80%",
    height: "63%",
    objectFit: "cover",
  },
  singleCaptionContainer: {
    top: -30,
    width: 220,
    height: 50,
    backgroundColor: "#F9F6F1",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
    justifyContent: "center",
  },
});

const ImageStructureForThree: React.FC<ImageStructureProps> = ({
  projects,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.rowContainer}>
        {projects.slice(0, 2).map((project, index) => {
          const isChineseCategory = checkIsChineseChar(project.category);
          const isChineseTitle = checkIsChineseChar(project.title);

          return (
            <View
              key={index}
              style={[styles.projectContainer, { marginHorizontal: 40 }]}
            >
              <View style={styles.frameContainer}>
                <PdfImage
                  style={styles.singleProjectImage}
                  src={{
                    uri: project.image_url,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
                <PdfImage style={styles.frameImage} src={Frame} />
              </View>
              <View style={styles.singleCaptionContainer}>
                <Text
                  style={[
                    styles.categoryText,
                    {
                      fontFamily: isChineseCategory ? "KaiTi" : "FuturaBkBT",
                    },
                  ]}
                >
                  {project.category}
                </Text>
                <Text
                  style={[
                    styles.titleText,
                    {
                      fontFamily: isChineseTitle ? "KaiTi" : "FuturaBkBT",
                    },
                  ]}
                >
                  {project.title}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={styles.singleProjectContainer}>
        <View style={styles.frameContainer}>
          <PdfImage
            style={styles.singleProjectImage}
            src={{
              uri: projects[2].image_url,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
          />
          <PdfImage style={styles.frameImage} src={Frame} />
        </View>
        <View style={styles.singleCaptionContainer}>
          <Text
            style={[
              styles.categoryText,
              {
                fontFamily: checkIsChineseChar(projects[2].category)
                  ? "KaiTi"
                  : "FuturaBkBT",
              },
            ]}
          >
            {projects[2].category}
          </Text>
          <Text
            style={[
              styles.titleText,
              {
                fontFamily: checkIsChineseChar(projects[2].title)
                  ? "KaiTi"
                  : "FuturaBkBT",
              },
            ]}
          >
            {projects[2].title}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ImageStructureForThree;

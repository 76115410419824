import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Outlet,
  useOutletContext,
  useLocation,
} from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import useStore from "store/store";
import { BackButton } from "components/Button/Button";
import { CURLANG } from "constants/url";
import { slugify, unSlugify } from "helper/slugify";
import WalkthroughSteps from "containers/WalkthroughSteps";
import ReactGA from "react-ga4";
import { translation } from "constants/translation";

interface Props {
  teacher?: boolean;
}

type ContextType = {
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
};

export function useSetCurrentTab() {
  return useOutletContext<ContextType>();
}

const AdminPage = ({ teacher = true }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));

  // ["School", "Educator", "Parent", "Group", "Child", "Interest", ];

  // WAD
  const [tabs, setTabs] = useState<string[]>([
    translation.siteAdmin || "Site Admin",
    translation.contentEditor || "Content Editor",
    translation.ttaContentEditor || "TTA Content Editor",
    translation.school || "School",
    translation.schoolAdmin || "School Admin",
    translation.teacher || "Teacher",
    // "Trehaus Curriculum Specialist",    // Temporary no use now -> manually allow Trehaus teacher to edit project through manual logic.
    translation.parent || "Parent",
    translation.child || "Child",
    translation.paymentRecord || "Payment Record",
    // "Ticket",
    // "Group",
    // "Interest",
    // "Pre Dq Question",
  ]);

  useEffect(() => {
    if (profileDetails.role === "AD") {
      setTabs([
        translation.school || "School",
        translation.teacher || "Teacher",
        translation.parent || "Parent",
        translation.child || "Child",
        translation.paymentRecord || "Payment History",
      ]);
      return;
    }

    if (profileDetails.role !== "WAD") {
      setTabs([
        translation.school || "School",
        // "School Admin",
        // "Content Editor",
        translation.teacher || "Teacher",
        translation.parent || "Parent",
        translation.child || "Child",
        // "Ticket",
      ]);
      return;
    }
  }, [profileDetails]);

  const [currentTab, setCurrentTab] = useState<string>(tabs[0]);
  useEffect(() => {
    let landingScreen = unSlugify(
      String(location.pathname).split("/").slice(-1)[0]
    );
    // console.log("landingScreen: ", landingScreen);
    if (!tabs.includes(landingScreen)) {
      landingScreen = tabs[0];
      navigate(slugify(landingScreen));
    }
    setCurrentTab(landingScreen);
  }, [tabs]);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // google analytics page view for admin page
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/admin-page",
      title: "Little Lab | Admin Page",
    });
  }, []);

  return (
    <Box sx={{ display: "flex", mx: { xs: "0px", sm: "60px" }, gap: "32px" }}>
      {/* <WarningDialog {...warningBody} /> */}
      {!isMobileView && (
        <Box
          sx={{
            maxWidth: "200px",
            height: "100%",
            position: "sticky",
            top: "96px",
            my: 3,
            flexShrink: 0,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <BackButton
              onClick={() => {
                navigate(`${CURLANG(currLanguage)}`);
              }}
            />

            <Box
              sx={{
                marginLeft: 5,
                alignSelf: "center",
                display: "flex",
                justifyContent: "space-around",
              }}
            ></Box>
          </Box>

          <TabsContainer
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </Box>
      )}

      {isMobileView && (
        <Drawer
          id="admin-menu-drawer"
          anchor="right"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          {" "}
          <Box sx={{ p: 2 }}>
            <TabsContainer
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </Box>
        </Drawer>
      )}

      <Container
        sx={{
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
          flex: 1,
          alignSelf: "start",
          my: 3,
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            mb: "6px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h4">{currentTab}</Typography>
          {isMobileView && (
            <Box
              id="admin-menu-drawer-mobile"
              aria-expanded={isDrawerOpen}
              onClick={toggleDrawer}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContents: "center",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                sx={{ display: { xs: "block", sm: "none" } }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <Outlet context={{ setCurrentTab }} />
      </Container>
      <WalkthroughSteps mode="admin-page" />
    </Box>
  );
};

const TabsContainer = ({
  tabs,
  currentTab,
  setCurrentTab,
}: {
  tabs: string[] | undefined;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        mt: "24px",
      }}
    >
      {tabs &&
        tabs.map((text, index) => (
          <Tabs
            text={text}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            key={`${text}-${index}`}
          />
        ))}
    </Box>
  );
};

const Tabs = ({
  text,
  setCurrentTab,
  currentTab,
}: {
  text: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  currentTab: string;
}) => {
  const navigate = useNavigate();

  return (
    <Box
      id={`admin-tab-${text}`}
      sx={{
        backgroundColor: `${
          currentTab === text ? "rgba(209, 228, 227, 0.37)" : "#fff"
        }`,
        border: "1px solid #98C4C5",
        borderRadius: "10px",
        p: "12px 16px",
        height: "44px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        cursor: "pointer",
        transition: "all 100ms linear",
        "&:hover": {
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms linear",
        },
      }}
      onClick={() => {
        setCurrentTab(text);
        navigate(slugify(text));
      }}
    >
      <SettingsIcon
        sx={{
          background: "white",
          borderRadius: "999px",
          p: "4px",
        }}
      />
      <Typography sx={{ fontSize: "16px", color: "txt.dark2" }}>
        {text === "Trehaus Curriculum Specialist" ? "Trehaus C.S." : text}
      </Typography>
    </Box>
  );
};

export default AdminPage;

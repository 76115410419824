
import { Box } from "@mui/material";
import ViewControl from "./CalendarHeadComponents/ViewControl";
import DateControl from "./CalendarHeadComponents/DateControl";


const CalendarHeadSection = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ViewControl />
        <DateControl />
      </Box>
    </>
  );
};

export default CalendarHeadSection;

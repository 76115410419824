import { AxiosError } from "axios";
import { API_Create_Ticket, API_Ticket } from "types/ticketingSystem";
import axios from "api/axiosConfig";

// Edit here to modify all to ticketing
// path('ticket/list', views.TicketingSystemView.List.as_view()),
// path('ticket/<int:pk>', views.TicketingSystemView.Get.as_view()),
// path('ticket/create', views.TicketingSystemView.Create.as_view()),
// path('ticket/<int:pk>/delete',views.TicketingSystemView.Delete.as_view()),
// path('ticket/<int:pk>/update', views.TicketingSystemView.Update.as_view()),
// path('ticket/slug/<slug:slug>/', views.TicketingSystemView.GetBySlug.as_view()),

export const getAllTickets = async (language: string) => {
  const res = await axios.get(language + "/ticketing-system/ticket/list");
  const data: API_Ticket[] = await res.data;
  return data;
};

export const getTicket = async (language: string, ticketId: number) => {
  const res = await axios.get(
    language + `/ticketing-system/ticket/${ticketId}`
  );
  const data: API_Ticket = await res.data;
  return data;
};

export const createTicket = async (ticketData: API_Create_Ticket) => {
  try {
    const res = await axios.post(
      "en/ticketing-system/ticket/create",
      ticketData
    );
    const data: API_Ticket = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updateTicket = async (ticketData: API_Ticket) => {
  try {
    const res = await axios.patch(
      `en/ticketing-system/ticket/${ticketData.id}/update`,
      ticketData
    );
    const data: API_Ticket = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deleteTicket = async (ticketId: string) => {
  try {
    const res = await axios.delete(
      `en/ticketing-system/ticket/${ticketId}/delete`
    );
    const data: API_Ticket = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

import { useEffect, useState } from "react";
import { Box, Fab, useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "./ParentDashboardComponents/Sidebar";
import MyChildrenSection from "./ParentDashboardComponents/MyChildrenSection";
import useStore from "store/store";
import ChildrenPhotoSection from "./ParentDashboardComponents/ChildrenPhotoSection";
import NowLearningSection from "./ParentDashboardComponents/NowLearningSection";
import Image from "components/Image/Image";
import DashboardBackground from "images/dashboard/dashboard-background.png";
import CampaignIcon from "@mui/icons-material/Campaign";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ParentDashboard = () => {
  const { childrenInSession } = useStore((state) => ({
    childrenInSession: state.childrenInSession,
  }));

  const [selectedChildId, setSelectedChildId] = useState<number>(-1);

  useEffect(() => {
    if (childrenInSession.length > 0) {
      setSelectedChildId(childrenInSession[0].id);
    } else {
      setSelectedChildId(-1);
    }
  }, [childrenInSession]);

  const theme = useTheme();
  const isLessThan1200 = useMediaQuery(theme.breakpoints.down("lg"));

  const [isAnnouncementOpen, setIsAnnouncementOpen] = useState<boolean>(false);
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        gap: 4,
        mb: 4,
        maxWidth: 1200,
        minWidth: window.innerWidth >= 1200 ? 1200 : 0,
        alignSelf: window.innerWidth >= 1200 ? "center" : "auto",
      }}
    >
      <Image
        src={DashboardBackground}
        alt="background"
        objectFit="cover"
        style={{
          opacity: 0.5,
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          flex: 1,
          width: "70%", //tolerate 30% to the announcement
        }}
      >
        {isLessThan1200 ? (
          isAnnouncementOpen ? (
            <>
              <Fab
                size="small"
                color="primary"
                aria-label="announcement"
                onClick={() => {
                  setIsAnnouncementOpen(false);
                }}
                sx={{
                  position: "absolute",
                  right: 25,
                  marginTop: 1,
                  marginRight: 1,
                }}
              >
                <KeyboardArrowRightIcon />
              </Fab>
              <Sidebar
                sx={{
                  display: {
                    xs: "flex",
                    lg: "none",
                  },
                  maxWidth: "100%",
                  mb: 3,
                  position: "static",
                }}
              />
            </>
          ) : (
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box />
              <Fab
                size="small"
                color="primary"
                aria-label="announcement"
                onClick={() => {
                  setIsAnnouncementOpen(true);
                }}
              >
                <CampaignIcon />
              </Fab>
            </Box>
          )
        ) : (
          <Sidebar
            sx={{
              display: {
                xs: "flex",
                lg: "none",
              },
              maxWidth: "100%",
              mb: 3,
              position: "static",
            }}
          />
        )}

        <MyChildrenSection
          selectedChildId={selectedChildId}
          setSelectedChildId={setSelectedChildId}
        />

        {selectedChildId !== -1 && (
          <>
            <NowLearningSection childId={selectedChildId} />
            <ChildrenPhotoSection childId={selectedChildId} />
          </>
        )}
      </Box>

      <Sidebar
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
      />
    </Box>
  );
};

export default ParentDashboard;

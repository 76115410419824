import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
import Button from "components/Button/Button";

import { translation } from "constants/translation";

import {
  DescriptionField,
  DiscussionTopicField,
  NameField,
} from "../TeacherFormComponents/LessonFormComponents";
import { API_Create_Teacher_Lesson } from "types/teacher";
import { ImageField } from "../TeacherFormComponents/LessonFormComponents";
import { createTeacherLesson } from "api/teacher-api";

const CreateLessonForm = ({
  moduleId,
  handleClose,
}: {
  moduleId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();

  const { currLanguage, setIsRefreshTeacherModule } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));

  const [image, setImage] = useState<File | string>("");
  const [updatedLessonInfo, setUpdatedLessonInfo] =
    useState<API_Create_Teacher_Lesson>({
      module: moduleId,
      name: "",
      description: "",
      discussion_topic: "",
    });

  const lessonRef = useRef(updatedLessonInfo);
  lessonRef.current = updatedLessonInfo;

  const submitForm = async () => {
    if (updatedLessonInfo.name === "") {
      openSnack("Name is required!", false);
      return;
    }

    const submitData: API_Create_Teacher_Lesson = JSON.parse(
      JSON.stringify(updatedLessonInfo)
    );
    // console.log("image: ", image);
    if (typeof image === "object") {
      submitData.image = image;
    }

    const createLessonRes = await createTeacherLesson(currLanguage, submitData);
    if (typeof createLessonRes !== "string") {
      setIsRefreshTeacherModule(true);
      openSnack(
        translation.success_create_lesson || "Lesson Created Successfully",
        true
      );
      handleClose();
    } else {
      openSnack(`${translation.fail_create_lesson}: ${createLessonRes}`, false);
    }
  };

  return (
    <Stack
      component="form"
      sx={{
        width: "100%",
        mb: 8,
      }}
      spacing={3}
      noValidate
      autoComplete="off"
    >
      <NameField lesson={updatedLessonInfo} setLesson={setUpdatedLessonInfo} />
      <DescriptionField
        lesson={updatedLessonInfo}
        setLesson={setUpdatedLessonInfo}
      />
      {/* <ImageField image={image} setImage={setImage} /> */}
      <DiscussionTopicField
        lesson={updatedLessonInfo}
        setLesson={setUpdatedLessonInfo}
      />

      <Box
        sx={{
          display: "flex",
          gap: "24px",
          position: "fixed",
          bottom: 24,
          right: 34,
          zIndex: 10,
        }}
      >
        <Button
          buttonText={translation.create || "Create"}
          style={{
            fontSize: "14px",
            backgroundColor: "var(--txt-secondary)",
            height: "auto",
          }}
          onClick={() => submitForm()}
        />
      </Box>
    </Stack>
  );
};

export default CreateLessonForm;

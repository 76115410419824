import React, { useEffect } from "react";
import useStore from "store/store";
import { Link } from "react-router-dom";
import {
  COMMUNITY,
  CURLANG,
  MY_PROGRESS,
  OUR_STORY,
} from "constants/url";

import { TITLE } from "constants/title";
import {
  HOME,
  COMMUNITY_TEXT,
  DASHBOARD_TEXT,
  MY_PROGRESS_TEXT,
  OUR_STORY_TEXT,
} from "constants/navbar";
import { translation } from "constants/translation";
import ProtectedLink from "components/ProtectedLink/ProtectedLink";
import { icons } from "../Navbar";
import NavbarMenuBlock from "./TTANavbarMenuBlock";

const TTAMenuLinks = ({ handleClose }: { handleClose?: () => void }) => {
  const { currLanguage, profileID } = useStore((state) => state);

  const [text, setText] = React.useState<{ [item: string]: string }>({});

  useEffect(() => {
    setText({
      [DASHBOARD_TEXT]: translation.dashboard || "My Dashboard",
      [MY_PROGRESS_TEXT]: translation.myProgress || "My Progress",
      [COMMUNITY_TEXT]: translation.community || "Community",
      [OUR_STORY_TEXT]: translation.ourStory || "Our Story",
    });
  }, [currLanguage]);

  let pages: { [item: string]: string };

  pages = {
    [DASHBOARD_TEXT]: `${CURLANG(currLanguage)}/${HOME}`,
    [MY_PROGRESS_TEXT]: MY_PROGRESS,
    [OUR_STORY_TEXT]: OUR_STORY,
    [COMMUNITY_TEXT]: COMMUNITY,
  };

  return (
    <>
      {Object.keys(pages).map((page) =>
        !!profileID ? (
          <ProtectedLink key={`${page}`} isAuth={!!profileID}>
            <Link
              to={`${CURLANG(currLanguage)}/${pages[page]}`}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
              onClick={() => {
                handleClose && handleClose();
                document.title = `${TITLE} | ${page}`;
              }}
            >
              <NavbarMenuBlock
                key={`navbar-menu-block-${page}`}
                isMobileView={false}
                pages={pages}
                page={page}
                handleClose={handleClose}
                icons={icons}
                text={text}
              />
            </Link>
          </ProtectedLink>
        ) : null
      )}
    </>
  );
};
export default TTAMenuLinks;

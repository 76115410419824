import useStore from '../store/store';

const useSnack = () => {
  const { setSnackMessage, setSnackSuccess, setSnackOpen } = useStore(
    (state) => ({
      setSnackMessage: state.setSnackMessage,
      setSnackSuccess: state.setSnackSuccess,
      setSnackOpen: state.setSnackOpen,
    })
  );

  return (message: string, success: boolean) => {
    setSnackMessage(message);
    setSnackSuccess(success);
    setSnackOpen(true);
  };
};

export default useSnack;

import { translation } from "constants/translation";

export const SEVEN_DAYS = "Last 7 days";
export const THIRTY_DAYS = "Last 30 days";
export const ONE_YEAR = "1 Year";
export const ALL_TIME = "All Time";

export const ANALYTIC_PERIOD_LIST = [
  SEVEN_DAYS,
  THIRTY_DAYS,
  ONE_YEAR,
  // ALL_TIME,
];

export const PERIOD_MAPPING: { [period: string]: string } = {
  [SEVEN_DAYS]: translation.sevenDays,
  [THIRTY_DAYS]: translation.thirtyDays,
  [ONE_YEAR]: translation.oneYear,
  [ALL_TIME]: translation.allTime,
};

export const GENERAL_DATE_FORMAT = "YYYY-MM-DD";

// FONT

export const titleFontSize = "24px";
export const titleMobileFontSize = "20px";

export const contentFontSize = "20px";
export const contentMobileFontSize = "16px";

export const buttonTextFontSize = "16px";
export const buttonTextMobileFontSize = "14px";

export const descriptionTextFontSize = "14px";
export const descriptionTextMobileFontSize = "13px";

// MOBILE

export const MOBILE_SCALE = 0.8;

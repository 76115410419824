import { Box, Typography } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

const VideoDurationDisplay = ({ duration }: { duration: string }) => {
  return (
    <Box sx={{ display: "flex", gap: 0.5 }}>
      <PlayCircleIcon
        sx={{
          color: "primary.light",
        }}
      />
      <Typography sx={{ color: "primary.light" }}>{duration}</Typography>
    </Box>
  );
};

export default VideoDurationDisplay;

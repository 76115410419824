import React, { useEffect, useState } from "react";

import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { useSearchParams } from "react-router-dom";

import {
  Avatar,
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";

import Button from "components/Button/Button";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import { API_Enroll_Child } from "types/profile";
import { API_Project } from "types/project";
import { getAllProjects } from "api/project-api";
import { enrollChild } from "api/profile-api";

import { translation } from "constants/translation";

import Logo from "images/logo-2-colour.png";

const EnrollChildPage = () => {
  const openSnack = useSnack();

  const [searchParams] = useSearchParams();

  const [projects, setProjects] = useState<API_Project[]>();
  const [formData, setFormData] = useState<API_Enroll_Child>({
    project: searchParams.get("projectID")
      ? Number(searchParams.get("projectID"))
      : -1,
    child: -1,
  });

  const { setRefreshEnrollment, currLanguage } = useStore((state) => ({
    setRefreshEnrollment: state.setRefreshEnrollment,
    currLanguage: state.currLanguage,
  }));

  useEffect(() => {
    const initialiseProj = async () => {
      const res = await getAllProjects(
        currLanguage,
        localStorage.getItem("access")
      );
      setProjects(res);
    };
    initialiseProj();
  }, [currLanguage]);

  const handleSubmit = async () => {
    const res = await enrollChild(formData);

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack("Enrolled child!", true);
      setFormData({ project: -1, child: formData.child });
      setRefreshEnrollment(true);
    }
  };

  return (
    <Box sx={{ minHeight: "71vh", p: "30px" }}>
      <Typography
        sx={{ fontSize: "1.5rem", fontWeight: "500", textAlign: "center" }}
      >
        {translation.enroll_child}
      </Typography>
      {projects ? (
        <Stack
          component="form"
          sx={{ width: "30vw", margin: "auto", my: "16px" }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <ChildrenForm
            selectedChild={formData.child}
            setSelectedChild={(childID: number) => {
              setFormData((prev) => ({ ...prev, child: childID }));
            }}
          />
          <ProjectForm
            projects={projects}
            selectedProj={formData.project}
            setSelectedProj={(projID: number) => {
              setFormData((prev) => ({ ...prev, project: projID }));
            }}
          />
          <Button
            buttonText={translation.submit || "Submit"}
            disabled={formData.child === -1 || formData.project === -1}
            onClick={handleSubmit}
          />
        </Stack>
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};

const ChildrenForm = ({
  selectedChild,
  setSelectedChild,
}: {
  selectedChild: number;
  setSelectedChild: (childID: number) => void;
}) => {
  const { childrenInSession } = useStore((state) => ({
    childrenInSession: state.childrenInSession,
  }));

  const [childIndex, setChildIndex] = useState<number>(-1);

  const handleChange = (event: SelectChangeEvent) => {
    const index = Number(event.target.value);
    setSelectedChild(childrenInSession[index].id);
    setChildIndex(index);
  };

  return (
    <Box
      sx={{ minWidth: 120, display: "flex", alignItems: "center", gap: "16px" }}
    >
      <FormControl fullWidth required>
        <InputLabel>{translation.child}</InputLabel>
        <Select
          value={childIndex.toString()}
          label={translation.module}
          onChange={handleChange}
        >
          {Object.keys(childrenInSession).map((index) => {
            return (
              <MenuItem key={childrenInSession[Number(index)].id} value={index}>
                {childrenInSession[Number(index)].preferred_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {childIndex !== -1 && (
        <Avatar
          src={childrenInSession[childIndex]?.image}
          sx={{ backgroundColor: "primary.main" }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        >
          {childrenInSession[childIndex]?.preferred_name
            .charAt(0)
            .toUpperCase()}
        </Avatar>
      )}
    </Box>
  );
};

const ProjectForm = ({
  selectedProj,
  setSelectedProj,
  projects,
}: {
  selectedProj: number;
  setSelectedProj: (projID: number) => void;
  projects: API_Project[];
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedProj(Number(event.target.value));
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth required>
        <InputLabel>{translation.project}</InputLabel>
        <Select
          value={selectedProj.toString()}
          label={translation.module}
          onChange={handleChange}
        >
          {projects.map((proj) => {
            return (
              <MenuItem key={proj.id} value={proj.id}>
                {proj.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default EnrollChildPage;

import React from "react";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageSeven from "images/children-photo-book/page-7.png";
import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageSevenCaptionInfo,
  photoBookPageSevenImageInfo,
} from "../photoBookCommonConstant";

export const PhotoBookPageSevenComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imageInfo = photoBookPageSevenImageInfo(scale);
  const captionInfo = photoBookPageSevenCaptionInfo(scale);

  return (
    <>
      <Image
        src={PhotoBookPageSeven}
        alt={"photo-book-page-7"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      <Image
        src={images[0].link}
        alt={"photo-book-page-1"}
        objectFit="cover"
        style={{
          position: "absolute",
          top: imageInfo.top,
          left: imageInfo.left,
          width: imageInfo.width,
          height: imageInfo.height,
        }}
      />

      <CaptionComponent
        top={captionInfo.top}
        left={captionInfo.left}
        width={captionInfo.width}
        caption={images[0].caption}
        clampLine={captionInfo.clampLine}
        fontSize={captionInfo.fontSize}
        textAlign="left"
      />
    </>
  );
};

export default PhotoBookPageSevenComponent;

import { Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import { pageSize, imageSrc, styles } from "../ProjectReportPDF";
import { LessonType } from "../Preview/constant";
import { checkIsChineseChar } from "helper/helper";

import Background from "images/end-of-project-report/four-polaroids.png";
import Wave from "images/end-of-project-report/wave.png";

const templateStyles = StyleSheet.create({
  images1: {
    position: "absolute",
    width: 280,
    height: 250,
    objectFit: "cover",
    transformOrigin: "top left",
  },
  images2: {
    position: "absolute",
    width: 246,
    height: 220,
    objectFit: "cover",
    transformOrigin: "top left",
  },
  image1: {
    top: 216,
    left: 92,
    transform: "rotate(8.71deg)",
  },
  image2: {
    top: 516,
    left: 422,
    transform: "rotate(-12deg)",
  },
  image3: {
    top: 271,
    left: 726,
    transform: "rotate(-6.67deg)",
  },
  image4: {
    top: 403,
    left: 1133,
    transform: "rotate(9deg)",
  },
  title: {
    position: "absolute",
    top: 40,
    left: 40,
    right: 40,
  },
  caption: {
    position: "absolute",
    top: 102,
    left: 40,
    right: 40,
  },
});

const LT4Images = ({
  lesson,
  gallery,
}: {
  lesson?: LessonType;
  gallery: GalleryImage;
}) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);
  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Wave} style={styles.background} />

        <Image
          src={imageSrc(lesson ? lesson.images[0].file : gallery.images[0].src)}
          style={[templateStyles.images1, templateStyles.image1]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[1].file : gallery.images[1].src)}
          style={[templateStyles.images2, templateStyles.image2]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[2].file : gallery.images[2].src)}
          style={[templateStyles.images1, templateStyles.image3]}
        />

        <Image
          src={imageSrc(lesson ? lesson.images[3].file : gallery.images[3].src)}
          style={[templateStyles.images2, templateStyles.image4]}
        />

        <Image src={Background} style={styles.background} />

        <Text style={[styles.pageTitle, templateStyles.title]}>
          {lesson ? lesson.title : gallery.lesson}
        </Text>
        {checkIsChineseChar(lesson?.caption) ? (
          <Text
            style={{
              textAlign: "center",
              fontFamily: "KaiTi",
              ...templateStyles.caption,
            }}
          >
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        ) : (
          <Text style={[styles.textCenter, templateStyles.caption]}>
            {lesson ? lesson.caption : gallery.images[0].alt}
          </Text>
        )}
      </View>
    </Page>
  );
};

export default LT4Images;

import { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import useScale from "hooks/useScale";
import { ImageType, PDFInfoType } from "./constant";

import FrontPage from "./FrontPage";
import IntroPage from "./IntroPage";
import ConcludingRemarkPage from "./ConcludingRemarkPage";
import AccomplishedPage from "./AccomplishedPage";
import LearningHighlightPage from "./LearningHighlightPage";
// import PhotoGalleryPage from "./PhotoGalleryPage";
import DevelopmentalGoalsAndMilestonesPage from "./DevelopmentalGoalsAndMilestonesPage";
import DevelopmentalGoalPage from "./DevelopmentalGoalPage";
import ModulePages from "./ModulePages/ModulePages";
import {
  HEAD_TEXT,
  HEART_TEXT,
  HANDS_TEXT,
} from "containers/PortfolioPage/portfolioPageConstants";
import { idID } from "@mui/material/locale";
import LearningStoryPages from "./LearningStories/LearningStoryPages";
import LearningStoriesCover from "components/ChildrenPortfolioPDF/Preview/LearningStoriesCover";

const Preview = ({
  PDFInfo,
  setPDFInfo,
  childImageList,
  completedWorkImageList,
  setTotalPages,
}: {
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
  childImageList: ImageType[];
  completedWorkImageList: ImageType[];
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(0);
  useScale(ref, setScale, true);

  useEffect(() => {
    if (ref.current) {
      const totalPages = ref.current.childNodes.length;
      setTotalPages(totalPages);
    }
  }, [PDFInfo, setTotalPages]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: "30px",
        gap: "30px",
        backgroundColor: "rgb(75, 75, 75)",
        flex: "1 1 0",
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          width: "100%",
          maxWidth: "595px",
          counterReset: "page",
          height: "100%",
          "& > .page": {
            counterIncrement: "page",
          },
          "& > .page::before": {
            content: "'Page 'counter(page)",
            color: "white",
            display: "block",
            mb: 1,
            fontSize: 14,
            lineHeight: 1,
          },
        }}
      >
        <FrontPage
          scale={scale}
          PDFInfo={PDFInfo}
          setPDFInfo={setPDFInfo}
          childImageList={childImageList}
        />

        <IntroPage />

        {/* Might need to hide this if there's no project accomplished & photo gallery*/}
        <LearningHighlightPage />

        {/* <AccomplishedPage
          scale={scale}
          PDFInfo={PDFInfo}
          isLittleProject={true}
        />

        <AccomplishedPage
          scale={scale}
          PDFInfo={PDFInfo}
          isLittleProject={false}
        /> */}

        <ModulePages scale={scale} PDFinfo={PDFInfo} />
        <LearningStoriesCover />
        <LearningStoryPages scale={scale} PDFInfo={PDFInfo} />

        {/* {childImageList.length > 0 && (
          <PhotoGalleryPage
            scale={scale}
            PDFInfo={PDFInfo}
            setPDFInfo={setPDFInfo}
            childImageList={childImageList}
          />
        )}

        {completedWorkImageList.length > 0 && (
          <PhotoGalleryPage
            scale={scale}
            PDFInfo={PDFInfo}
            setPDFInfo={setPDFInfo}
            childImageList={completedWorkImageList}
            isCompletedWork={true}
          />
        )} */}
        {/* Might need to hide this if there's no milestone attained in 3 of the tags */}
        <DevelopmentalGoalsAndMilestonesPage />

        <DevelopmentalGoalPage
          scale={scale}
          PDFInfo={PDFInfo}
          tag={HEART_TEXT}
        />

        <DevelopmentalGoalPage
          scale={scale}
          PDFInfo={PDFInfo}
          tag={HEAD_TEXT}
        />

        <DevelopmentalGoalPage
          scale={scale}
          PDFInfo={PDFInfo}
          tag={HANDS_TEXT}
        />

        <ConcludingRemarkPage
          scale={scale}
          PDFInfo={PDFInfo}
          setPDFInfo={setPDFInfo}
        />
      </Box>
    </Box>
  );
};

export default Preview;

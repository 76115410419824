import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { translation } from "constants/translation";
import useStore from "store/store";
import { CHILDREN_PORTFOLIO_PAGE, CURLANG } from "constants/url";
import { tabs } from "../constants";
import useDialog from "hooks/useDialog";
import ChoiceDialog from "components/Dialog/ChoiceDialog";
import useSnack from "hooks/useSnack";

import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import { Child_Progress_Report, Query_Data } from "types/project";
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import {
  deleteChildProgressReport,
  getChildProgressReportList,
} from "api/report-api";
import ChildProgressReportCard from "./ChildProgressReportComponents/ChildProgressReportCard";

const ProgressReportScreen = ({
  isParentView = false,
}: {
  isParentView?: boolean;
}) => {
  const openSnack = useSnack();

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  // const [deleteReportName, setDeleteReportName] = useState<string>("");
  const [deleteProgressReport, setDeleteProgressReport] =
    useState<Child_Progress_Report | null>(null);
  const { childID } = useParams();

  const { currGroup, currLanguage } = useStore((state) => ({
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
  }));

  const [childProgressReportList, setChildProgressReportList] = useState<
    Child_Progress_Report[]
  >([]);

  // Search
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });

  const populateProgressReportList = async () => {
    const res = await getChildProgressReportList({
      language: currLanguage,
      childId: Number(childID),
      groupId: currGroup.id,
      queryData: queryData,
    });

    if (typeof res !== "string") {
      setChildProgressReportList(res);
    }
  };

  useEffect(() => {
    populateProgressReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currGroup.id, queryData.query, queryData.startDate, queryData.endDate]);

  const handleDelete = async () => {
    const res = await deleteChildProgressReport(
      deleteProgressReport?.id,
      localStorage.getItem("access")
    );
    console.log(res);
    if (typeof res === "string") {
      // empty string
      openSnack("Failed to Delete Report", false);
    } else {
      // empty object
      openSnack("Deleted Report", true);
      populateProgressReportList();
    }
    handleCloseDialog();
    populateProgressReportList();
  };

  const handleLeftButton = () => {
    handleCloseDialog();
    // handleOpenDialog()
  };
  const handleRightButton = () => {
    handleDelete();
  };

  const choiceBody = {
    openDialog,
    handleCloseDialog,
    choiceTitle: "Delete Progress Report?",
    choiceContext: "The Action is Irreversible!",
    handleLeftButton,
    handleRightButton,
    leftButtonText: "Cancel",
    rightButtonText: "Delete",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <ChoiceDialog {...choiceBody} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: {
            xs: "wrap",
            sm: "nowrap",
          },
          gap: 2,
          width: {
            xs: "100%",
            lg: "70%",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            flex: {
              xs: "none",
              sm: 1,
            },
          }}
        >
          <SearchBar
            queryData={queryData}
            setQueryData={setQueryData}
            maxWidth={9999}
          />
        </Box>

        <StartAtDateField queryData={queryData} setQueryData={setQueryData} />
        <EndAtDateField queryData={queryData} setQueryData={setQueryData} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: {
            xs: "center",
            md: "flex-start",
          },
        }}
      >
        {!isParentView && <AddNewReportButton />}
        {childProgressReportList.map((childProgressReport) => (
          <>
            <ChildProgressReportCard
              key={`child-progress-report-${childProgressReport.id}`}
              childProgressReport={childProgressReport}
              handleOpenDialog={handleOpenDialog}
              setDeleteProgressReport={setDeleteProgressReport}
              // setDeleteReportName = {setDeleteReportName}
              // handleRightButton={handleRightButton}
              isChildPortfolio
            />
          </>
        ))}
      </Box>
    </Box>
  );
};

const AddNewReportButton = () => {
  const navigate = useNavigate();
  const { groupSlug, childID } = useParams();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const handleAddNewReport = () => {
    navigate(
      `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO_PAGE(
        groupSlug!,
        childID!
      )}/${tabs[2]}/create`
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        p: 2,
        width: {
          xs: "35vw",
          lg: "250px",
        },
        height: {
          xs: "35vw",
          lg: "250px",
        },
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        cursor: "pointer",
        transition: "all 200ms ease-in-out",
        "&:hover": {
          boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "primary.light",
        },
        "&:hover .child": {
          color: "white",
        },
      }}
      onClick={handleAddNewReport}
    >
      <AddCircleIcon
        className="child"
        sx={{
          color: "#C4C4C4",
          fontSize: "50px",
          transition: "all 200ms ease-in-out",
        }}
      />

      <Typography
        className="child"
        sx={{
          color: "#312F30",
          opacity: "0.7",
          fontWeight: 500,
          lineHeight: 1,
          transition: "all 200ms ease-in-out",
          textAlign: "center",
        }}
      >
        Create New
        <br /> Progress Report
      </Typography>
    </Box>
  );
};

export default ProgressReportScreen;

import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

export const BackButton = ({
  btnText = "Back",
  onClick,
}: {
  btnText?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <ArrowBackIcon sx={{ fontSize: "14px" }} />
      <Typography>{btnText}</Typography>
    </Box>
  );
};
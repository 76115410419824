import { SxProps } from "@mui/material";

export interface ImageType {
  id: number;
  file: string;
  width?: number;
  height?: number;
  caption?: string;
}

export interface LessonType {
  templateNo: number;
  title: string;
  images: ImageType[];
  caption: string;
}

export interface PDFInfoType {
  name: string;
  presentedBy: string;
  group: string;
  school: string;
  overviewTitle: string;
  overview: string;
  overviewImage: ImageType | null;
  fundamentalsTitle: string;
  fundamentals: {
    name: string;
    image: string;
  }[];
  lessons: LessonType[];
  congratsTitle: string;
  congratsText: string;
  congratsImages: string[];
}

export const editableStyle: SxProps = {
  border: "1px dashed #98C4C5",
  backgroundColor: "rgba(209, 228, 227, 0.37)",
  outline: "none",
  pointerEvents: "auto",
  "&:hover .overlay": {
    opacity: 1,
  },
};

export const styles: {
  body: SxProps;
  main: SxProps;
  staticImage: React.CSSProperties;
} = {
  body: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    color: "#312F30",
    backgroundColor: "#DFEBEB",
    lineHeight: 1.5,
    overflow: "hidden",
    pointerEvents: "none",
  },
  main: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  staticImage: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    objectFit: "contain",
  },
};

import { Box } from "@mui/material";
import useStore from "store/store";
import { translation } from "constants/translation";
import Button from "components/Button/Button";

import { API_Lesson_Learning_Moment } from "types/project";
import LearningMomentSection from "./LearningMomentQuestionComponents/LearningMomentSection";
import { updateScheduleLearningMomentProgress } from "api/profile-api";
import useSnack from "hooks/useSnack";
import { populateLessonLearningMomentCheckpointCount } from "helper/project";

type Props = {
  hideDone?: boolean;
  hideCheckbox?: boolean;
  assessment?: boolean;
  isPhotoVideoExist: boolean;
  learningMomentObject: API_Lesson_Learning_Moment;
  setIsLearningMomentPhotoScreen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
  isForLearningMomentTab?: boolean;
};

const LearningMomentQuestionScreen = ({
  hideDone = false,
  hideCheckbox = false,
  assessment = false,
  isPhotoVideoExist,
  learningMomentObject,
  setIsLearningMomentPhotoScreen,
  handleClose = () => {},
  isForLearningMomentTab = false,
}: Props) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: 5,
          display: "flex",
          flexDirection: "row",
          position: "relative",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <LearningMomentSection
            assessment={assessment}
            hideCheckbox={hideCheckbox}
            learningMomentObject={learningMomentObject}
          />
        </Box>
      </Box>
      {!hideDone && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            width: "100%",
          }}
        >
          <LearningMomentQuestionDoneButton
            isPhotoVideoExist={isPhotoVideoExist}
            learningMomentObject={learningMomentObject}
            handleClose={handleClose}
          />
        </Box>
      )}
    </>
  );
};

const LearningMomentQuestionDoneButton = ({
  isPhotoVideoExist,
  learningMomentObject,
  handleClose,
}: {
  isPhotoVideoExist: boolean;
  learningMomentObject: API_Lesson_Learning_Moment;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();

  const {
    currLanguage,
    currentLessons,
    currScheduleObj,
    setCurrScheduleObj,
    setLessonLearningMomentCheckpointCount,
    setRefreshGroupLessonInfoList,
  } = useStore((state) => ({
    ...state,
    projectSlug: state.currentProject.slug,
  }));

  const handleDone = async () => {
    if (currScheduleObj.learning_moments.includes(learningMomentObject.id!)) {
      handleClose();
      return;
    }

    const res = await updateScheduleLearningMomentProgress(
      currLanguage,
      localStorage.getItem("access"),
      currScheduleObj.id,
      learningMomentObject.id!
    );

    if (typeof res !== "string") {
      setRefreshGroupLessonInfoList(true);
      setCurrScheduleObj(res);
      populateLessonLearningMomentCheckpointCount(
        currLanguage,
        localStorage.getItem("access")!,
        currentLessons.id,
        res.group,
        setLessonLearningMomentCheckpointCount
      );
      openSnack(`Learning Moment is ${translation.done}`, true);
      handleClose();
    } else {
      openSnack(translation.pleaseSchedule, false);
    }
  };

  return (
    <Button
      style={{ width: "30%" }}
      buttonText={translation.done || "Done"}
      arrow={false}
      onClick={handleDone}
    />
  );
};

export default LearningMomentQuestionScreen;

import React, { useState, useEffect } from "react";

import {
  Box,
  Fade,
  Modal,
  Typography,
} from "@mui/material";

import { API_Child } from "types/profile";
import Button from "components/Button/Button";
import SearchBar from "./SearchBar";
import groupChildrenAlphabetically, { ChildrenGroup } from "./groupChildrenAlphabetically";
import ChildSelectComponent from "./ChildSelectComponent";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  className: string;
  childLearningMomentCheckList: number[];
  childList: API_Child[];
  updateChildren: (child: API_Child[], type?: 'bulkAdd') => Promise<void>;
}

const BulkAddChildrenModal = ({
  open,
  setOpen,
  className,
  childLearningMomentCheckList,
  childList,
  updateChildren,
}: Props) => {
  const [childrenGroups, setChildrenGroups] = useState<ChildrenGroup[]>([])
  const [searchText, setSearchText] = useState<string>('');
  const [selected, setSelected] = useState<number[]>(childLearningMomentCheckList);


  useEffect(() => {
    setSelected(childLearningMomentCheckList);
  }, [childLearningMomentCheckList])
  useEffect(() => {
    let childrenToBeGrouped = childList;
    if (searchText) {
      childrenToBeGrouped = childList.filter((child) => child.preferred_name.includes(searchText));
    }
    setChildrenGroups(groupChildrenAlphabetically(childrenToBeGrouped))
  }, [childList, searchText])


  const handleClose = () => setOpen(false);

  const handleAddChildren = async () => {
    const childrenToAdd = childList.filter((child) =>
      selected.includes(child.id) &&
      !childLearningMomentCheckList.includes(child.id)
    );

    if (childrenToAdd.length > 0) { 
      await updateChildren(childrenToAdd, 'bulkAdd');
    }
    handleClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "527px",
            bgcolor: "background.paper",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: "16px 24px",
            borderRadius: "8px",
            maxHeight: "min(716px, 90%)",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              alignSelf: "stretch"
            }}
          >
            <Typography
              sx={{
                flex: "1 0 0",
                fontSize: "24px",
                fontWeight: 600,
                lineHeight: 1.33
              }}
            >
              {className}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: 1.50
              }}
            >
              Total: {childList.length} children
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "40px",
              width: "100%",
              gap: 1.5,
            }}
          >
            <SearchBar handleChange={setSearchText} />
            <Button
              arrow={false}
              buttonText="Add Children"
              style={{
                whiteSpace: "nowrap",
                color: "txt.primary",
                padding: "8px 24px",
                width: "fit-content",
                height: "42px",
                fontSize: "14px",
                borderRadius: "4px",
              }}
              onClick={handleAddChildren}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column", 
              width: "100%",
              height: "fit-content",
              maxHeight: { xs: "80%", sm: "min(580px, calc(90% - 132px))" },
              overflowY: "auto",
              gap: 1,
              alignItems: "flex-start",
              alignSelf: "stretch",
              borderRadius: 0.5,
              border: "1px solid #C9C9C9",
            }}
          >
            {childrenGroups.map((group) => (
              <Box
                key={group.letter}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  width: '100%',
                  height: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    width: '28px',
                    color: "#91C6C5",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: 1.4,
                    textAlign: "center",
                  }}
                >
                  {group.letter}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 'fit-content',
                    width: '100%',
                  }}
                >
                  {group.children.map((child) => (
                    <ChildSelectComponent
                      key={child.id}
                      child={child}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BulkAddChildrenModal;

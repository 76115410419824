import React, { useState } from "react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";

import {
  API_Project_Lesson,
  API_Lesson_Learning_Moment,
} from "types/project";
import LearningMomentPhotoScreen from "../LearningMomentPhotoScreen";
import LearningMomentQuestionScreen from "../LearningMomentQuestionScreen";
import { GalleryPhotoVideo } from "types/gallery";

const LearningMomentModal = ({
  open,
  setOpen,
  currentLesson,
  learningMomentObject,
  isLearningMomentPhotoScreen,
  setIsLearningMomentPhotoScreen,
  isForLearningMomentTab = false,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentLesson: API_Project_Lesson;
  learningMomentObject: API_Lesson_Learning_Moment;
  isLearningMomentPhotoScreen: boolean;
  setIsLearningMomentPhotoScreen: React.Dispatch<React.SetStateAction<boolean>>;
  isForLearningMomentTab?: boolean;
}) => {
  const handleClose = () => setOpen(false);
  //gallery -> passing empty list to it to populate for future use
  const [photoVideoGalleryList, setPhotoVideoGalleryList] = useState<
    GalleryPhotoVideo[]
  >([]);

  const isPhotoVideoExist =
    photoVideoGalleryList.length > 0
      ? photoVideoGalleryList[0].photo_video_list.length > 0
      : false;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disablePortal={document.fullscreenElement ? true : false}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "calc(100% - 32px)", sm: "65vw" },
            minWidth: 325,
            maxHeight: { xs: "calc(100% - 80px)", sm: "inherit" },
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Box>
            {!currentLesson.assessment && (
              <LearningMomentPhotoScreen
                isPhotoVideoExist={isPhotoVideoExist}
                photoVideoGalleryList={photoVideoGalleryList}
                setPhotoVideoGalleryList={setPhotoVideoGalleryList}
                currentLesson={currentLesson}
                learningMomentObject={learningMomentObject}
                setIsLearningMomentPhotoScreen={setIsLearningMomentPhotoScreen}
              />
            )}
            <LearningMomentQuestionScreen
              isPhotoVideoExist={isPhotoVideoExist}
              learningMomentObject={learningMomentObject}
              setIsLearningMomentPhotoScreen={setIsLearningMomentPhotoScreen}
              handleClose={handleClose}
              isForLearningMomentTab={isForLearningMomentTab}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
export default LearningMomentModal;

import { useEffect } from "react";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import useInitialiseCurrProjAndLess from "hooks/useInitialiseCurrProjAndLess";

import { unSlugify } from "helper/slugify";
import useStore from "store/store";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  LESSON_PLANS,
  OVERVIEW,
  PROJECT_SUMMARY,
  getProjectLessonActivityTabs,
} from "./constant";
import { LITTLE_PROJECTS } from "containers/CalendarPage/constants/constants";
import BinderImage from "images/binder-edited.png";
import ProjectSummarySideMenuMobile from "./ProjectSummarySideMenu/ProjectSummarySideMenuMobile";
import BackButton from "components/BackButton";
import WalkthroughSteps from "containers/WalkthroughSteps";
import ProjectSummarySideMenuCopy from "./ProjectSummarySideMenu/ProjectSummarySideMenuCopy";

const ProjectSummaryPage = () => {
  useInitialiseCurrProjAndLess();
  const {
    currentProject,
    isProjectLoading,
    setCurrentProjectLessonActivityTab,
  } = useStore((state) => ({
    currentProject: state.currentProject,
    isProjectLoading: state.isProjectLoading,
    setCurrentProjectLessonActivityTab:
      state.setCurrentProjectLessonActivityTab,
  }));

  const { pathname } = useLocation();

  useEffect(() => {
    let landingScreen = unSlugify(String(pathname).split("/").slice(-1)[0]);

    if (
      landingScreen.toLowerCase() === "end" ||
      landingScreen.toLowerCase() === "report"
    ) {
      landingScreen = PROJECT_SUMMARY;
    } else if (String(pathname).includes("/lesson")) {
      landingScreen = LESSON_PLANS;
    } else if (
      !getProjectLessonActivityTabs(
        currentProject.module.category === LITTLE_PROJECTS
      ).includes(landingScreen)
    ) {
      landingScreen = OVERVIEW;
    }

    setCurrentProjectLessonActivityTab(landingScreen);
  }, [pathname]);

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  return currentProject.id === -1 ||
    !pathname.includes(currentProject.slug) ||
    isProjectLoading ? (
    <Box
      sx={{
        display: "flex",
        mx: { xs: "10px", lg: "60px" },
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingIndicator />
    </Box>
  ) : (
    <>
      {isLg ? (
        <ProjectSummaryPageContent />
      ) : (
        <ProjectSummaryPageMobileContent />
      )}
      <WalkthroughSteps mode="project-summary" />
    </>
  );
};

const ProjectSummaryPageContent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        // mx: { xs: "10px", lg: "60px" },
        // gap: "32px",
        flex: 1,
      }}
    >
      <ProjectSummarySideMenuCopy />
      {/* <ProjectSummarySideMenu /> */}

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
          flex: 1,
          my: 3,
          minWidth: "0px",
          position: "relative",
        }}
        maxWidth={false}
      >
        <Outlet />
      </Container>
    </Box>
  );
};

const ProjectSummaryPageMobileContent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        flex: 1,
        backgroundColor: "#FBFBFB",
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          backgroundColor: `#F6F6F6`,
          width: "100%",
          position: "sticky",
          top: { xs: 56, sm: 64 },
          zIndex: 2,
          flexDirection: "row",
          overflowX: "auto",
          overflowY: "visible",
          "::-webkit-scrollbar": {
            width: 5,
            height: 5,
          },
          gap: 1,
          p: 0.5,
        }}
      >
        <ProjectSummarySideMenuMobile />
      </Box>
      <BackButton sx={{ mt: "4px", zIndex: 1 }} variant="ttb" />

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
          flex: 1,
          minWidth: "0px",
          position: "relative",
          p: "0px !important",
        }}
        maxWidth={false}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            backgroundImage: `url(${BinderImage})`,
            position: "absolute",
            // height: window.innerHeight - 64,
            overflow: "hidden",
            zIndex: 1,
            left: "-35px",
            width: "50px",
            top: "-24px",
            bottom: "-24px",
            backgroundRepeat: "repeat-y",
          }}
        />
        <Outlet />
      </Container>
    </Box>
  );
};

export default ProjectSummaryPage;

export const countryCoordinates: Record<string, { lat: number; lng: number }> =
  {
    Afghanistan: { lat: 34.5553, lng: 69.2075 },
    Albania: { lat: 41.3275, lng: 19.8187 },
    Algeria: { lat: 36.7372, lng: 3.0865 },
    Andorra: { lat: 42.5063, lng: 1.5218 },
    Angola: { lat: -8.839, lng: 13.2894 },
    "Antigua and Barbuda": { lat: 17.0747, lng: -61.8175 },
    Argentina: { lat: -34.6037, lng: -58.3816 },
    Armenia: { lat: 40.1792, lng: 44.4991 },
    Australia: { lat: -35.2809, lng: 149.13 },
    Austria: { lat: 48.2082, lng: 16.3738 },
    Azerbaijan: { lat: 40.4093, lng: 49.8671 },
    Bahamas: { lat: 25.0343, lng: -77.3963 },
    Bahrain: { lat: 26.2285, lng: 50.586 },
    Bangladesh: { lat: 23.8103, lng: 90.4125 },
    Barbados: { lat: 13.1939, lng: -59.5432 },
    Belarus: { lat: 53.9045, lng: 27.5615 },
    Belgium: { lat: 50.8503, lng: 4.3517 },
    Belize: { lat: 17.1899, lng: -88.4976 },
    Benin: { lat: 6.4667, lng: 2.6168 },
    Bhutan: { lat: 27.4728, lng: 89.6393 },
    Bolivia: { lat: -16.5, lng: -68.15 },
    "Bosnia and Herzegovina": { lat: 43.8563, lng: 18.4131 },
    Botswana: { lat: -24.6282, lng: 25.9231 },
    Brazil: { lat: -15.795, lng: -47.8822 },
    Brunei: { lat: 4.9031, lng: 114.9398 },
    Bulgaria: { lat: 42.6977, lng: 23.3219 },
    "Burkina Faso": { lat: 12.3714, lng: -1.5197 },
    Burundi: { lat: -3.383, lng: 29.3644 },
    Cambodia: { lat: 11.5564, lng: 104.9282 },
    Cameroon: { lat: 3.848, lng: 11.5021 },
    Canada: { lat: 45.4215, lng: -75.699 },
    "Cape Verde": { lat: 14.9315, lng: -23.5125 },
    "Central African Republic": { lat: 4.3947, lng: 18.5582 },
    Chad: { lat: 12.1348, lng: 15.0557 },
    Chile: { lat: -33.4569, lng: -70.6483 },
    China: { lat: 39.9042, lng: 116.4074 },
    Colombia: { lat: 4.711, lng: -74.0721 },
    Comoros: { lat: -11.7172, lng: 43.2473 },
    "Congo, Democratic Republic of": { lat: -4.0383, lng: 21.759 },
    "Congo, Republic of": { lat: -4.2634, lng: 15.2429 },
    "Costa Rica": { lat: 9.9281, lng: -84.0907 },
    Croatia: { lat: 45.8132, lng: 15.9775 },
    Cuba: { lat: 23.1136, lng: -82.3666 },
    Cyprus: { lat: 35.1856, lng: 33.3823 },
    "Czech Republic": { lat: 50.0878, lng: 14.4205 },
    Denmark: { lat: 55.6761, lng: 12.5683 },
    Djibouti: { lat: 11.8251, lng: 42.5903 },
    Dominica: { lat: 15.3017, lng: -61.3881 },
    "Dominican Republic": { lat: 18.4861, lng: -69.9312 },
    "East Timor": { lat: -8.5662, lng: 125.5675 },
    Ecuador: { lat: -0.2295, lng: -78.5248 },
    Egypt: { lat: 30.0444, lng: 31.2357 },
    "El Salvador": { lat: 13.6929, lng: -89.2182 },
    "Equatorial Guinea": { lat: 3.7504, lng: 8.7371 },
    Eritrea: { lat: 15.3229, lng: 38.9251 },
    Estonia: { lat: 59.437, lng: 24.7536 },
    Ethiopia: { lat: 9.0054, lng: 38.7636 },
    Fiji: { lat: -18.1248, lng: 178.4501 },
    Finland: { lat: 60.1756, lng: 24.9342 },
    France: { lat: 48.8566, lng: 2.3522 },
    Gabon: { lat: 0.3906, lng: 9.4534 },
    Gambia: { lat: 13.4556, lng: -16.5775 },
    Georgia: { lat: 41.7151, lng: 44.8271 },
    Germany: { lat: 52.52, lng: 13.405 },
    Ghana: { lat: 5.56, lng: -0.2057 },
    Greece: { lat: 37.9838, lng: 23.7275 },
    Grenada: { lat: 12.0561, lng: -61.7488 },
    Guatemala: { lat: 14.6349, lng: -90.5069 },
    Guinea: { lat: 9.6412, lng: -13.5784 },
    "Guinea-Bissau": { lat: 11.8037, lng: -15.1804 },
    Guyana: { lat: 6.8046, lng: -58.1548 },
    Haiti: { lat: 18.5944, lng: -72.3074 },
    Honduras: { lat: 14.0723, lng: -87.1921 },
    Hungary: { lat: 47.4979, lng: 19.0402 },
    Iceland: { lat: 64.1472, lng: -21.9426 },
    India: { lat: 28.6139, lng: 77.209 },
    Indonesia: { lat: -6.2, lng: 106.8167 },
    Iran: { lat: 35.6892, lng: 51.389 },
    Iraq: { lat: 33.3152, lng: 44.3661 },
    Ireland: { lat: 53.3498, lng: -6.2603 },
    Israel: { lat: 31.7683, lng: 35.2137 },
    Italy: { lat: 41.9028, lng: 12.4964 },
    "Ivory Coast": { lat: 5.3545, lng: -4.0018 },
    Jamaica: { lat: 18.0075, lng: -76.7872 },
    Japan: { lat: 35.6895, lng: 139.6917 },
    Jordan: { lat: 31.9566, lng: 35.9457 },
    Kazakhstan: { lat: 51.1694, lng: 71.4491 },
    Kenya: { lat: -1.2864, lng: 36.8172 },
    Kiribati: { lat: 1.3292, lng: 172.9752 },
    Kosovo: { lat: 42.6629, lng: 21.1655 },
    Kuwait: { lat: 29.3759, lng: 47.9774 },
    Kyrgyzstan: { lat: 42.8746, lng: 74.5698 },
    Laos: { lat: 17.9757, lng: 102.6331 },
    Latvia: { lat: 56.9496, lng: 24.1052 },
    Lebanon: { lat: 33.8938, lng: 35.5018 },
    Lesotho: { lat: -29.3151, lng: 27.4869 },
    Liberia: { lat: 6.3005, lng: -10.7969 },
    Libya: { lat: 32.8872, lng: 13.1913 },
    Liechtenstein: { lat: 47.141, lng: 9.5209 },
    Lithuania: { lat: 54.6872, lng: 25.2797 },
    Luxembourg: { lat: 49.6116, lng: 6.1319 },
    Macedonia: { lat: 41.9973, lng: 21.428 },
    Madagascar: { lat: -18.8792, lng: 47.5079 },
    Malawi: { lat: -13.9626, lng: 33.7741 },
    Malaysia: { lat: 3.139, lng: 101.6869 },
    Maldives: { lat: 4.1755, lng: 73.5093 },
    Mali: { lat: 12.6392, lng: -8.0029 },
    Malta: { lat: 35.8989, lng: 14.5146 },
    "Marshall Islands": { lat: 7.1315, lng: 171.1845 },
    Mauritania: { lat: 18.0735, lng: -15.9582 },
    Mauritius: { lat: -20.1605, lng: 57.5012 },
    Mexico: { lat: 19.4326, lng: -99.1332 },
    Micronesia: { lat: 6.9175, lng: 158.1621 },
    Moldova: { lat: 47.0105, lng: 28.8638 },
    Monaco: { lat: 43.7397, lng: 7.427 },
    Mongolia: { lat: 47.8864, lng: 106.9057 },
    Montenegro: { lat: 42.4304, lng: 19.2594 },
    Morocco: { lat: 34.0209, lng: -6.8417 },
    Mozambique: { lat: -25.9664, lng: 32.5713 },
    Myanmar: { lat: 16.8661, lng: 96.1951 },
    Namibia: { lat: -22.5609, lng: 17.0658 },
    Nauru: { lat: -0.5228, lng: 166.9246 },
    Nepal: { lat: 27.7172, lng: 85.324 },
    Netherlands: { lat: 52.3667, lng: 4.8945 },
    "New Zealand": { lat: -41.2865, lng: 174.7762 },
    Nicaragua: { lat: 12.114, lng: -86.2362 },
    Niger: { lat: 13.5116, lng: 2.1254 },
    Nigeria: { lat: 9.0578, lng: 7.4951 },
    "North Korea": { lat: 39.0392, lng: 125.7625 },
    Norway: { lat: 59.9139, lng: 10.7522 },
    Oman: { lat: 23.61, lng: 58.5909 },
    Pakistan: { lat: 33.6844, lng: 73.0479 },
    Palau: { lat: 7.5149, lng: 134.5825 },
    Panama: { lat: 8.9833, lng: -79.5167 },
    "Papua New Guinea": { lat: -9.4438, lng: 147.1803 },
    Paraguay: { lat: -25.2637, lng: -57.5759 },
    Peru: { lat: -12.0464, lng: -77.0428 },
    Philippines: { lat: 14.5995, lng: 120.9842 },
    Poland: { lat: 52.2297, lng: 21.0122 },
    Portugal: { lat: 38.7223, lng: -9.1393 },
    Qatar: { lat: 25.3548, lng: 51.1839 },
    Romania: { lat: 44.4268, lng: 26.1025 },
    Russia: { lat: 55.7512, lng: 37.6184 },
    Rwanda: { lat: -1.9706, lng: 30.1044 },
    "Saint Kitts and Nevis": { lat: 17.3026, lng: -62.7177 },
    "Saint Lucia": { lat: 13.9094, lng: -60.9789 },
    "Saint Vincent and the Grenadines": { lat: 13.2528, lng: -61.1971 },
    Samoa: { lat: -13.8415, lng: -171.7382 },
    "San Marino": { lat: 43.9424, lng: 12.4578 },
    "Sao Tome and Principe": { lat: 0.1864, lng: 6.6131 },
    "Saudi Arabia": { lat: 24.7136, lng: 46.6753 },
    Senegal: { lat: 14.6928, lng: -17.4467 },
    Serbia: { lat: 44.7866, lng: 20.4489 },
    Seychelles: { lat: -4.6796, lng: 55.492 },
    "Sierra Leone": { lat: 8.4657, lng: -13.2317 },
    Singapore: { lat: 1.3521, lng: 103.8198 },
    Slovakia: { lat: 48.1486, lng: 17.1077 },
    Slovenia: { lat: 46.0569, lng: 14.5058 },
    "Solomon Islands": { lat: -9.4376, lng: 159.972 },
    Somalia: { lat: 2.0469, lng: 45.3182 },
    "South Africa": { lat: -25.7461, lng: 28.1881 },
    "South Korea": { lat: 37.5665, lng: 126.978 },
    "South Sudan": { lat: 4.8594, lng: 31.5713 },
    Spain: { lat: 40.4168, lng: -3.7038 },
    "Sri Lanka": { lat: 6.9271, lng: 79.8612 },
    Sudan: { lat: 15.5007, lng: 32.5599 },
    Suriname: { lat: 5.852, lng: -55.2038 },
    Swaziland: { lat: -26.3054, lng: 31.1367 },
    Sweden: { lat: 59.3293, lng: 18.0686 },
    Switzerland: { lat: 46.948, lng: 7.4474 },
    Syria: { lat: 33.5138, lng: 36.2765 },
    Taiwan: { lat: 25.032, lng: 121.5654 },
    Tajikistan: { lat: 38.5737, lng: 68.7738 },
    Tanzania: { lat: -6.7924, lng: 39.2083 },
    Thailand: { lat: 13.7563, lng: 100.5018 },
    Togo: { lat: 6.1319, lng: 1.2225 },
    Tonga: { lat: -21.1389, lng: -175.22 },
    "Trinidad and Tobago": { lat: 10.6918, lng: -61.2225 },
    Tunisia: { lat: 36.8065, lng: 10.1815 },
    Turkey: { lat: 39.9334, lng: 32.8597 },
    Turkmenistan: { lat: 37.9601, lng: 58.3261 },
    Tuvalu: { lat: -8.5178, lng: 179.1979 },
    Uganda: { lat: 0.3476, lng: 32.5825 },
    Ukraine: { lat: 50.4501, lng: 30.5234 },
    "United Arab Emirates": { lat: 24.4539, lng: 54.3773 },
    "United Kingdom": { lat: 51.5074, lng: -0.1278 },
    "United States": { lat: 38.9072, lng: -77.0379 },
    Uruguay: { lat: -34.9011, lng: -56.1645 },
    Uzbekistan: { lat: 41.3775, lng: 64.5853 },
    Vanuatu: { lat: -17.734, lng: 168.322 },
    "Vatican City": { lat: 41.9029, lng: 12.4534 },
    Venezuela: { lat: 10.4806, lng: -66.9036 },
    Vietnam: { lat: 21.0285, lng: 105.8542 },
    Yemen: { lat: 15.3694, lng: 44.191 },
    Zambia: { lat: -15.4167, lng: 28.2833 },
    Zimbabwe: { lat: -17.8292, lng: 31.0522 },
  };

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import useStore from "store/store";
import { API_Project_Review } from "types/project";
import { API_Review_Image } from "types/review";

import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useSnack from "hooks/useSnack";
import { likeReview, unlikeReview, deleteReview } from "api/review-api";
import { translation } from "constants/translation";
import WarningDialog from "../Dialog/WarningDialog";
import { Profile, Time } from "./Components";
import Logo from "images/logo-2-colour.png";
import ReviewModalReadOnly from "./ReviewModalReadOnly";

const WhatDoPeopleLikeCard = ({
  review,
  handleEditReview,
  cardBackgroundColor,
}: 
{
  review: API_Project_Review;
  handleEditReview: ({
    rating,
    comment,
    reviewId,
    images,
  }: {
    rating: number;
    comment: string;
    reviewId: number;
    images: API_Review_Image[];
  }) => void;
  cardBackgroundColor?: string;
  //   kanbanBoard: API_Teacher_Community_Kanban_Board_With_Card;
  //   setKanbanBoard: React.Dispatch<
  //     React.SetStateAction<API_Teacher_Community_Kanban_Board_With_Card>
  //   >;
}) => {
  const { profileDetails, currLanguage, setIsRefreshReviewList } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      currLanguage: state.currLanguage,
      setIsRefreshReviewList: state.setIsRefreshReviewList,
    })
  );

  const openSnack = useSnack();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = !!anchorEl;

  const handleLike = async (e: any) => {
    e.stopPropagation();

    const res = await likeReview(
      currLanguage,
      {
        review: review.id,
        profile: profileDetails.id.toString(),
      },
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setIsRefreshReviewList(true);
    }
  };

  const myLike = review.likes.find(
    ({ profile }) => profile === profileDetails.id
  );

  const handleUnlike = async (e: any) => {
    e.stopPropagation();
    if (myLike) {
      const res = await unlikeReview(
        currLanguage,
        myLike.id,
        localStorage.getItem("access")
      );

      if (res !== "Error") {
        setIsRefreshReviewList(true);
      } else {
        openSnack(translation.tryAgainLater, false);
      }
    }
    return;
  };

  const isCardOwner = profileDetails.id === review.profile.id;
  const isCardOwnerOrAdmin =
    isProfileInTTAModuleEditableRoleList(profileDetails) || isCardOwner;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleModalClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenModal(false);
  };

  // delete dialog
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  return (
    <Box sx={{ height: "100%", maxHeight: 400 }}>
      <ReviewModalReadOnly
        open={openModal}
        handleModalClose={handleModalClose}
        review={review}
      />
      <Card
        sx={{
          // width: "25vw",
          height: "100%",
          borderRadius: "10px",
          backgroundColor: cardBackgroundColor
            ? cardBackgroundColor
            : "#F0F0F0",
          filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))",
          transition: "200ms all ease-in-out",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: "13px 18px",
            flexGrow: 1,
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (isOpenDialog) {
              return;
            }
            if (isCardOwner) {
              handleEditReview({
                rating: review.rating,
                comment: review.comment,
                reviewId: review.id,
                images: review.images,
              });
            } else {
              setOpenModal(true);
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <Avatar
                  src={review.profile.image}
                  alt={review.profile.preferred_name}
                  sx={{ width: "40px", height: "40px" }}
                  imgProps={{
                    onError: (
                      event: React.SyntheticEvent<HTMLImageElement, Event>
                    ) => {
                      event.currentTarget.onerror = null;
                      event.currentTarget.src = Logo;
                    },
                  }}
                />
                <Profile
                  name={review.profile.preferred_name}
                  role={review.profile.role}
                  school={
                    review.profile.school ? review.profile.school.name : ""
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                <Box sx={{ mb: 1 }}>📌</Box>
                <Time
                  type="review"
                  created_at={review.created_at}
                  updated_at={review.updated_at}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Typography sx={{ fontSize: 16 }}>{review.comment}</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                sx={{ alignSelf: "start" }}
                id="thumb-off-button"
                onClick={myLike ? handleUnlike : handleLike}
              >
                <Badge badgeContent={review.likes.length}>
                  {myLike ? (
                    <ThumbUpIcon sx={{ color: "primary.main" }} />
                  ) : (
                    <ThumbUpOffAltIcon />
                  )}
                </Badge>
              </IconButton>
              {isCardOwnerOrAdmin ? (
                <IconButton
                  sx={{ alignSelf: "start" }}
                  id="basic-icon-button"
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event);
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              ) : (
                <Box sx={{ width: 40, height: 40 }}> </Box>
              )}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleMenuClose();
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* {isCardOwner && (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleEditReview({
                        rating: review.rating,
                        comment: review.comment,
                        reviewId: review.id,
                        images: review.images,
                      });
                      handleMenuClose();
                    }}
                  >
                    <Typography>{translation.edit}</Typography>
                  </MenuItem>
                )} */}

                <DeleteReviewMenuItem
                  // style={{ backgroundColor: "pink" }}
                  isOpenDialog={isOpenDialog}
                  setIsOpenDialog={setIsOpenDialog}
                  review={review}
                  handleMenuClose={handleMenuClose}
                />
              </Menu>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const DeleteReviewMenuItem = ({
  isOpenDialog,
  setIsOpenDialog,
  handleMenuClose,
  review,
}: {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleMenuClose: () => void;
  review: API_Project_Review;
}) => {
  const openSnack = useSnack();
  const { profileDetails, currLanguage, setIsRefreshReviewList } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      currLanguage: state.currLanguage,
      setIsRefreshReviewList: state.setIsRefreshReviewList,
    })
  );

  const deleteMyReview = async () => {
    const res = await deleteReview(
      currLanguage,
      review.id,
      localStorage.getItem("access")
    );

    if (res !== "Error") {
      openSnack(translation.reviewDeleted, true);
      setIsRefreshReviewList(true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const warningBody = {
    openDialog: isOpenDialog,
    handleCloseDialog: (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      handleMenuClose();
      setIsOpenDialog(false);
    },
    warningTitle: `${translation.deleteReview} - ${review.comment}?`,
    warningContext: translation.irreversibleAction,
    handleDelete: deleteMyReview,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setIsOpenDialog(true);
        }}
      >
        {translation.delete}
      </MenuItem>
    </>
  );
};

export default WhatDoPeopleLikeCard;

import WalkthroughIntro from "./components/Intro/Intro";
import WalkthroughStep from "./components/WalkthroughStep";
import type { Step, SetOpen, EmptyFn } from "./types";

const curriculumStepId = "navbar-menu-block-curriculum";
const portfolioStepId = "navbar-menu-block-portfolios";
const calendarStepId = "navbar-menu-block-calendar";

export const getLinearElementDetails = (
  elementId: string,
  width: number,
  parentId?: string
) => {
  let element: HTMLElement | null;

  if (parentId) {
    const parentElement = document.querySelector(`#${parentId}`);
    element = parentElement?.querySelector(`#${elementId}`) ?? null;
  } else {
    element = document.querySelector(`#${elementId}`);
  }

  const elementHeight = element?.offsetHeight ?? 0;
  const elementWidth = element?.clientWidth ?? 0;
  const elementTop = element?.offsetTop ?? 0;
  const elementLeft = element?.offsetLeft ?? 0;

  return {
    html: element?.innerHTML ?? "",
    top: elementTop + elementHeight - 5,
    left: elementLeft + 24 - (width - elementWidth) / 2,
  };
};

const computeTeacherDashboardSteps = (
  setOpen: SetOpen,
  handleBackStep: EmptyFn,
  handleNextStep: EmptyFn,
  handleClose: EmptyFn,
  isSm: boolean,
  isLg: boolean
): (() => Step)[] => {
  const width = isSm ? 276 : 415;
  const introStep = () => ({
    width,
    height: "fit-content",
    top: (window.innerHeight - 134) / 2,
    left: (window.innerWidth - width) / 2,
    component: (
      <WalkthroughIntro
        role="teacher"
        handleNext={handleNextStep}
        handleClose={handleClose}
      />
    ),
  });

  const dashboardStep = () => ({
    width: 300,
    height: "fit-content",
    top: 100,
    right: isSm ? (window.innerWidth - 300) / 2 : 100,
    component: (
      <WalkthroughStep
        handleNext={handleNextStep}
        handleBack={handleBackStep}
        steps={4}
        step={1}
        description="The dashboard provides you with a summary of your timetable, your classes, and their ongoing lessons."
      />
    ),
  });

  const curriculumStep = () => {
    const { top, left, html } = getLinearElementDetails(
      curriculumStepId,
      300,
      isLg ? "mobile-menu-drawer" : undefined
    );

    return {
      width: isLg ? 276 : 300,
      height: "fit-content",
      top,
      left: isLg ? left + 50 : left,
      line: "vertical",
      mobileMenu: true,
      component: (
        <WalkthroughStep
          key={curriculumStepId}
          handleNext={handleNextStep}
          handleBack={handleBackStep}
          steps={4}
          step={2}
          html={html}
          description="You can explore the curriculum to find the right projects for your classes."
        />
      ),
    };
  };

  const portfolioStep = () => {
    const { top, left, html } = getLinearElementDetails(
      portfolioStepId,
      300,
      isLg ? "mobile-menu-drawer" : undefined
    );

    return {
      width: isLg ? 276 : 300,
      height: "fit-content",
      top,
      left: isLg ? left + 50 : left,
      line: "vertical",
      mobileMenu: true,
      component: (
        <WalkthroughStep
          key={portfolioStepId}
          handleNext={handleNextStep}
          handleBack={handleBackStep}
          steps={4}
          step={3}
          html={html}
          description={
            "To assess the overall progress of children, you can go to portfolio."
          }
        />
      ),
    };
  };

  const calendarStep = () => {
    const { top, left, html } = getLinearElementDetails(
      calendarStepId,
      300,
      isLg ? "mobile-menu-drawer" : undefined
    );

    return {
      width: isLg ? 276 : 300,
      height: "fit-content",
      top,
      left: isLg ? left + 50 : left,
      line: "vertical",
      mobileMenu: true,
      component: (
        <WalkthroughStep
          key={calendarStepId}
          handleNext={handleNextStep}
          handleBack={handleBackStep}
          steps={4}
          step={4}
          html={html}
          description={"Great! Last step is organizing lessons in calendar"}
        />
      ),
    };
  };

  return [
    introStep,
    dashboardStep,
    curriculumStep,
    portfolioStep,
    calendarStep,
  ];
};

export default computeTeacherDashboardSteps;

import { useEffect, useState } from "react";
import {
  MenuItem,
  ListItemText,
  FormGroup,
  FormControl,
  Divider,
  Checkbox,
  Box,
} from "@mui/material";
import {
  // API_Project,
  API_Fundamental,
  // API_Project_Fundamental,
  // API_Project_Lesson,
} from "types/project";
import { DownloadButton } from "components/Button/Button";
import { API_Project_Lesson } from "types/project";
import { translation } from "constants/translation";
import { pdfjs } from "react-pdf";
import LessonSelectionPDF from "./LessonSelectionPDF";

import { pdf } from "@react-pdf/renderer";
import useStore from "store/store";
import { getAllFundamentals } from "api/fundamental-api";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LessonMenuSelection = ({
  lessons,
  currentLesson,
  selectedLessons,
  setSelectedLessons,
  onSubmit,
}: {
  lessons: API_Project_Lesson[];
  currentLesson: API_Project_Lesson;
  selectedLessons: API_Project_Lesson[];
  setSelectedLessons: React.Dispatch<
    React.SetStateAction<API_Project_Lesson[]>
  >;
  onSubmit: () => void;
}) => {
  const [allDevelopmentalGoals, setAllDevelopmentalGoals] = useState<
    API_Fundamental[]
  >([]);


  const toggleSelectAll = () => {
    setSelectedLessons((prevSelected) =>
      prevSelected.length === lessons.length ? [] : [...lessons]
    );
  };

  const toggleSelectLesson = (lessonId: number) => {
    const lessonToToggle = lessons.find((lesson) => lesson.id === lessonId);

    if (!lessonToToggle) return;

    setSelectedLessons((prevSelected) =>
      prevSelected.some((selectedLesson) => selectedLesson.id === lessonId)
        ? prevSelected.filter(
          (selectedLesson) => selectedLesson.id !== lessonId
        )
        : [...prevSelected, lessonToToggle]
    );
  };

  const projModule = useStore((state) => state.currentProject.module);

  const { currentProject, fundamentalDict, currLanguage } = useStore(
    (state) => ({
      currentProject: state.currentProject,
      fundamentalDict: state.fundamentalDict,
      currLanguage: state.currLanguage,
    })
  );

  const handleSubmit = async () => {
    // Map over the selected lessons to get fundamental and milestone names
    // const lessonObjectives = []
    const selectedLessonData = selectedLessons.map((selectedLesson, index) => {

      const lessonNumber = lessons.findIndex(
        (lesson) => lesson.id === selectedLesson.id
      );
      const objectiveFundamentalsWithNames: Record<string, any[]> = {};

      selectedLesson.objective_fundamentals.forEach((fundamental) => {
        const fundamentalName = fundamentalDict[fundamental.fundamental].name;
        const milestoneName = fundamental.milestone
          ? fundamentalDict[fundamental.fundamental].milestones
            .filter((milestone) => milestone.id === fundamental.milestone)
            .map((milestone) => milestone.name)[0]
          : null;

        if (!objectiveFundamentalsWithNames[fundamentalName]) {
          objectiveFundamentalsWithNames[fundamentalName] = [];
        }

        if (milestoneName !== null) {
          objectiveFundamentalsWithNames[fundamentalName].push(milestoneName);
        }
      });
      return {
        ...selectedLesson,
        lessonNumber: lessonNumber + 1,
        objective_fundamentals: objectiveFundamentalsWithNames,
        // activities: lessons[lessonNumber].activities,
      };
    });

    const currentProjectData = {
      ...currentProject,
      objective_fundamentals: currentProject.objective_fundamentals.map(
        (fundamental) => {
          const fundamentalName = fundamentalDict[fundamental.fundamental].name;
          const milestoneName = fundamental.milestone
            ? fundamentalDict[fundamental.fundamental].milestones
              .filter((milestone) => milestone.id === fundamental.milestone)
              .map((milestone) => milestone.name)[0]
            : "";
          return {
            fundamentalName,
            milestoneName,
          };
        }
      ),
    };

    // Create a PDF document with selected lessons using react-pdf
    selectedLessonData.sort((a, b) => a.lessonNumber - b.lessonNumber);
    const pdfBlob = await pdf(
      <LessonSelectionPDF
        selectedLessonData={selectedLessonData}
        projModule={projModule}
        currentProject={currentProjectData}
        projectCards={projectCards}
        fundamentalDict={fundamentalDict}
      />
    ).toBlob();

    // Convert the blob to a URL
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the generated PDF in a new tab or window
    window.open(pdfUrl, "_blank");

    onSubmit();
  };

  const populateFundamentals = async () => {
    const res = await getAllFundamentals(currLanguage);
    if (typeof res !== "string") {
      setAllDevelopmentalGoals(res);
    }
  };

  useEffect(() => {
    populateFundamentals();
  }, []);

  const projectCards: any = [];

  const goalsWithSubFundamentals = allDevelopmentalGoals.filter(
    (goal: any) => goal?.sub_fundamentals?.length > 0
  );

  const goalsWithoutSubFundamentals = allDevelopmentalGoals.filter(
    (goal: any) => goal?.sub_fundamentals?.length == 0
  );

  currentProject.objective_fundamentals.forEach((devGoal: any) => {
    const filteredMilestones = fundamentalDict[
      devGoal.fundamental
    ].milestones.filter((milestone) => milestone.id === devGoal.milestone);
    const objectiveFundamentalMilestoneName =
      filteredMilestones.length > 0 ? filteredMilestones[0].name : "";
    const goalNoSubFundamental = goalsWithoutSubFundamentals.find(
      (goal: any) => goal?.name == devGoal.name
    );
    if (!objectiveFundamentalMilestoneName) {
      let devGoalNone = allDevelopmentalGoals.find(
        (goal: any) => goal?.name == devGoal?.name
      );
      if (!devGoalNone) {
        devGoalNone = allDevelopmentalGoals.find((goal: any) =>
          goal?.sub_fundamentals.find(
            (subFundamental: any) => subFundamental?.name == devGoal?.name
          )
        );
      }
      const projectCard = projectCards.find(
        (project: any) => project.name == devGoalNone?.name
      );
      if (!projectCard) {
        projectCards.push({
          name: devGoalNone?.name,
          image: devGoalNone?.image,
        });
      }
      return;
    }
    if (goalNoSubFundamental) {
      const projectCard = projectCards.find(
        (project: any) => project.name == goalNoSubFundamental?.name
      );
      if (!projectCard) {
        projectCards.push({
          name: goalNoSubFundamental?.name,
          image: goalNoSubFundamental?.image,
          objectives: [objectiveFundamentalMilestoneName],
        });
      } else if (
        !projectCard.objectives.find(
          (objective: any) => objective == objectiveFundamentalMilestoneName
        )
      ) {
        projectCard.objectives.push(objectiveFundamentalMilestoneName);
      }
    } else if (!goalNoSubFundamental) {
      const goalWithSubFundamental = goalsWithSubFundamentals.find((goal: any) =>
        goal?.sub_fundamentals.find((subFundamental: any) =>
          subFundamental.milestones.find(
            (milestone: any) =>
              milestone.name == objectiveFundamentalMilestoneName
          )
        )
      );
      const projectCard = projectCards.find(
        (project: any) => project.name == goalWithSubFundamental?.name
      );
      if (!projectCard) {
        projectCards.push({
          name: goalWithSubFundamental?.name,
          image: goalWithSubFundamental?.image,
          subFundamentals: [
            {
              name: devGoal.name,
              objectives: [objectiveFundamentalMilestoneName],
            },
          ],
        });
      } else if (
        !projectCard.subFundamentals.find(
          (sub: any) => sub.name == devGoal.name
        )
      ) {
        projectCard.subFundamentals.push({
          name: devGoal.name,
          objectives: [objectiveFundamentalMilestoneName],
        });
      } else if (
        !projectCard.subFundamentals.find((sub: any) =>
          sub.objectives.find(
            (objective: any) => objective == objectiveFundamentalMilestoneName
          )
        )
      ) {
        projectCard.subFundamentals
          .find((sub: any) => sub.name == devGoal.name)
          .objectives.push(objectiveFundamentalMilestoneName);
      }
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <FormControl component="fieldset">
          <FormGroup>
            {/* "This Lesson" option */}
            <MenuItem
              onClick={() => toggleSelectLesson(currentLesson.id)}
              sx={{
                paddingLeft: "8px",
                paddingRight: "8px",
                minWidth: "216px",
              }}
            >
              <Checkbox
                checked={selectedLessons.some(
                  (selectedLesson) => selectedLesson.id === currentLesson.id
                )}
                color="primary"
              />
              <ListItemText primary="This Lesson" />
            </MenuItem>

            {/* "Select All" option */}
            <MenuItem
              onClick={toggleSelectAll}
              sx={{
                paddingLeft: "8px",
                paddingRight: "8px",
                minWidth: "216px",
              }}
            >
              <Checkbox
                checked={selectedLessons.length === lessons.length}
                color="primary"
              />
              <ListItemText primary="Select All Lessons" />
            </MenuItem>

            <Divider
              sx={{
                paddingLeft: "8px",
                paddingRight: "8px",
                minWidth: "216px",
              }}
            />

            {/* Map through lessons to create menu items */}
            {lessons.map((lesson, index) => (
              <MenuItem
                key={`menu-custom-item-lesson-${lesson.id}`}
                disableGutters
                sx={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  minWidth: "216px",
                }}
              >
                <Checkbox
                  checked={selectedLessons.some(
                    (selectedLesson) => selectedLesson.id === lesson.id
                  )}
                  color="primary"
                  inputProps={{ "aria-label": `Lesson ${index + 1}` }}
                  onChange={() => toggleSelectLesson(lesson.id)}
                />
                <ListItemText primary={`Lesson ${index + 1}`} />
              </MenuItem>
            ))}
          </FormGroup>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          alignSelf: "stretch",
          padding: "var(--space-4, 8px)",
          width: "100%",
        }}
      >
        <MenuItem key="menu-custom-item-submit" disableGutters>
          <DownloadButton
            btnText={translation.downloadpdf}
            onClick={handleSubmit}
          />
        </MenuItem>
      </Box>
    </form>
  );
};

export default LessonMenuSelection;

import { useEffect, useRef, useState, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  API_Teacher_Module,
  API_Teacher_Module_Learner_Progress,
} from "types/teacher";
import useStore from "store/store";

import { getAllModules, getLearnerModuleProgressList } from "api/teacher-api";
import TopSection from "./TopSection/TopSection";
import CertificateSection from "./CertificateSection";
import CTASection from "./ctaSection";
// import FooterSection from "./FooterSectionNew";
import FooterSection from "./FooterSection";
import ContinueLearningSection from "./ContinueLearningSection";
import ModulesSection from "./ModulesSection";
import { CURLANG, TEACHER_LESSON_ACTIVITY_PAGE } from "constants/url";
import EmptyStateSVG from "./EmptyStateSVG";
import RecommendedForYouSection from "./RecommendedForYouSection";
import LaptopIllustrationSection from "./LaptopIllustrationSection";
import TestimonialSection from "./TestimonialSection";

const filterModules = (
  teacherModules: API_Teacher_Module[],
  learnerModuleProgressList: API_Teacher_Module_Learner_Progress[]
) => {
  const newModules: API_Teacher_Module[] = [];
  const enrolledModules: API_Teacher_Module[] = [];
  teacherModules.forEach((teacherModule: any) => {
    const progress = learnerModuleProgressList.find(
      (learnerModuleProgress) => learnerModuleProgress.id === teacherModule.id
    );

    if (progress && !progress.is_enrolled) {
      newModules.push(teacherModule);
    } else {
      enrolledModules.push(teacherModule);
    }
  });

  return { newModules, enrolledModules };
};

const TeacherLibrary = () => {
  const {
    profileDetails,
    teacherModules,
    setTeacherModules,
    currLanguage,
    setIsTeacherModuleLoading,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    teacherModules: state.teacherModules,
    setTeacherModules: state.setTeacherModules,
    currLanguage: state.currLanguage,
    isTeacherModuleLoading: state.isTeacherModuleLoading,
    setIsTeacherModuleLoading: state.setIsTeacherModuleLoading,
  }));

  const { search } = useLocation();

  const [filteredTeacherModules, setFilteredTeacherModules] = useState<
    API_Teacher_Module[]
  >([]);
  const [moduleList, setModuleList] =
    useState<API_Teacher_Module[]>(teacherModules);
  const [newModuleList, setNewModuleList] = useState<API_Teacher_Module[]>([]);
  const [learnerModuleProgressList, setLearnerModuleProgressList] = useState<
    API_Teacher_Module_Learner_Progress[]
  >([]);

  const query = useMemo(() => (search ? search.split("=")[1] : null), [search]);

  useEffect(() => {
    const populateLearnerProgress = async () => {
      const learnerModuleProgressListRes = await getLearnerModuleProgressList(
        localStorage.getItem("access")
      );
      if (typeof learnerModuleProgressListRes !== "string") {
        setLearnerModuleProgressList(learnerModuleProgressListRes);
      }
    };

    const populateData = async () => {
      setIsTeacherModuleLoading(true);
      const modules = await getAllModules(currLanguage, "");
      setTeacherModules(modules);
      setIsTeacherModuleLoading(false);
    };

    populateLearnerProgress();
    populateData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLanguage]);

  useEffect(() => {
    if (query === null) return;

    const populateDataWithQuery = async () => {
      setIsTeacherModuleLoading(true);
      const modules = await getAllModules(currLanguage, query);
      setFilteredTeacherModules(modules);
      setIsTeacherModuleLoading(false);
    };

    populateDataWithQuery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLanguage, query]);

  const libraryRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const { enrolledModules, newModules } = filterModules(
      teacherModules,
      learnerModuleProgressList
    );

    setModuleList(enrolledModules);
    setNewModuleList(newModules);
  }, [learnerModuleProgressList, teacherModules]);

  useEffect(() => {
    const modules = query ? filteredTeacherModules : teacherModules;
    const { enrolledModules, newModules } = filterModules(
      modules,
      learnerModuleProgressList
    );

    setModuleList(enrolledModules);
    setNewModuleList(newModules);
  }, [
    filteredTeacherModules,
    learnerModuleProgressList,
    teacherModules,
    query,
  ]);

  const suggestions = useMemo(() => {
    return [...(moduleList ?? []), ...(newModuleList ?? [])]
      .splice(0, 2)
      .map((module) => ({
        name: module.name,
        url: `${CURLANG(currLanguage)}/${TEACHER_LESSON_ACTIVITY_PAGE(
          module.slug,
          module.lessons[0].slug,
          module.lessons[0].activities[0].slug
        )}`,
      }));
  }, [currLanguage, moduleList, newModuleList]);

  return (
    <>
      <TopSection
        userName={profileDetails.preferred_name}
        suggestions={suggestions}
      />
      {!query && <CertificateSection />}
      <Box
        ref={libraryRef}
        sx={{
          width: "100%",
          padding: 2,
          px: "80px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        {/* <RecommendedForYouSection /> */}
        {!!moduleList.length && (
          <ContinueLearningSection
            moduleList={moduleList}
            setModuleList={setModuleList}
            learnerModuleProgressList={learnerModuleProgressList}
          />
        )}
        {!!newModuleList.length && (
          <ModulesSection
            newModuleList={newModuleList}
            setNewModuleList={setNewModuleList}
            learnerModuleProgressList={learnerModuleProgressList}
          />
        )}
        {query && !filteredTeacherModules.length && (
          <>
            <Typography
              sx={{
                width: "100%",
                color: "#5C9391",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              No Results for “{query}"
            </Typography>
            <Box>
              <EmptyStateSVG />
            </Box>
            <ModulesSection
              newModuleList={
                filterModules(teacherModules, learnerModuleProgressList)
                  .newModules
              }
              setNewModuleList={setNewModuleList}
              learnerModuleProgressList={learnerModuleProgressList}
            />
          </>
        )}
      </Box>
      {/* {!query && <CTASection />} */}
      <LaptopIllustrationSection />
      {/* <TestimonialSection /> */}
      <FooterSection />
    </>
  );
};

export default TeacherLibrary;

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Query_Data } from "types/project";
import useStore from "store/store";
import CompletedWorkCard from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkCard";
import { getCompletedWorkList } from "api/completed-works-api";
import { API_Child } from "types/portfolio";
import CompletedWorkPreviewModal from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/CompletedWorkPreviewModal";
import { DATE_FORMAT } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import moment from "moment";

// Filter
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import CustomButton from "components/Button/Button";
import ModuleFilterButton from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/ModuleFilterButton";
import ClassFilterButton from "../TeacherArchiveCommonComponent/ClassFilterButton";
import ChildFilterButton from "../TeacherArchiveCommonComponent/ChildFilterButton";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import {
  ClickSearchToBegin,
  MAX_QUERY,
  MaxQueryAdvice,
  teacherArchiveCardStyle,
} from "../TeacherArchiveCommonComponent/TeacherArchiveCommonComponent";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const TeacherArchiveCompletedWorkScreen = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  // Search
  const [isLoading, setIsLoading] = useState(false);
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });
  const [selectedModuleSlugs, setSelectedModuleSlugs] = useState<string[]>([]);
  const [selectedClassSlugs, setSelectedClassSlugs] = useState<string[]>([]);
  const [selectedChildIds, setSelectedChildIds] = useState<number[]>([]);

  const [completedWorkList, setChildCompletedWorkList] = useState<
    API_Child.CompletedWork[]
  >([]);

  const populateCompletedWorkList = async () => {
    setIsLoading(true);
    const res = await getCompletedWorkList({
      language: currLanguage,
      selectedModuleSlugs: selectedModuleSlugs,
      selectedClassSlugs: selectedClassSlugs,
      selectedChildIds: selectedChildIds,
      queryData: queryData,
      isTeacherArchive: true,
    });
    if (typeof res !== "string") {
      setChildCompletedWorkList(res);
    }
    setIsLoading(false);
  };

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      isInitialized ||
      selectedModuleSlugs.length === 0 ||
      selectedClassSlugs.length === 0 ||
      selectedChildIds.length === 0
    )
      return;

    populateCompletedWorkList();
    setIsInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModuleSlugs, selectedClassSlugs, selectedChildIds]);

  // Preview
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCompletedWork, setSelectedCompletedWork] =
    useState<API_Child.CompletedWork>();

  const handleOpen = (completedWork: API_Child.CompletedWork) => {
    setOpen(true);
    setSelectedCompletedWork(completedWork);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompletedWork(undefined);
  };

  return (
    <>
      {open && selectedCompletedWork && (
        <CompletedWorkPreviewModal
          open={open}
          completedWork={selectedCompletedWork}
          setCompletedWork={setSelectedCompletedWork}
          isChildPortfolio
          handleClose={handleClose}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, sm: 2 },
            width: {
              xs: "100%",
              xl: "80%",
            },
          }}
        >
          <SearchBar
            queryData={queryData}
            setQueryData={setQueryData}
            maxWidth={9999}
          />

          <CustomButton
            buttonText={isXs ? "" : "Search"}
            onClick={() => {
              populateCompletedWorkList();
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            flexWrap: "wrap",
            width: {
              xs: "100%",
              xl: "80%",
            },
          }}
        >
          <StartAtDateField queryData={queryData} setQueryData={setQueryData} />
          <EndAtDateField queryData={queryData} setQueryData={setQueryData} />
          <Box
            sx={{
              flex: { xs: "1 0 100%", sm: "1" },
            }}
          >
            <ModuleFilterButton
              selectedModuleSlugs={selectedModuleSlugs}
              setSelectedModuleSlugs={setSelectedModuleSlugs}
            />
          </Box>

          <ClassFilterButton
            selectedClassSlugs={selectedClassSlugs}
            setSelectedClassSlugs={setSelectedClassSlugs}
          />
          <ChildFilterButton
            selectedClassSlugs={selectedClassSlugs}
            selectedChildIds={selectedChildIds}
            setSelectedChildIds={setSelectedChildIds}
          />
        </Box>
      </Box>
      {isLoading ? (
        <LoadingIndicator />
      ) : completedWorkList.length === 0 ? (
        <ClickSearchToBegin />
      ) : (
        <>
          <Box sx={teacherArchiveCardStyle}>
            {completedWorkList.map((completedWork) => {
              return (
                <CompletedWorkCard
                  key={`child-progress-report-${completedWork.id}`}
                  completedWork={completedWork}
                  fromNow={moment(completedWork.completed_at).format(
                    DATE_FORMAT
                  )}
                  onClick={() => {
                    handleOpen(completedWork);
                  }}
                />
              );
            })}
          </Box>
          {completedWorkList.length === MAX_QUERY &&
            MaxQueryAdvice({ maxQuery: MAX_QUERY })}
        </>
      )}
    </>
  );
};

export default TeacherArchiveCompletedWorkScreen;

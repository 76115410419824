import { useState, useMemo } from "react";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import {
  ImageCard,
  ImageType,
  containerStyles,
  ImagePreviewModal,
  DATE_FORMAT,
} from "./PhotoAlbumComponents";
import LearningStoryModal from "components/Organisms/LearningStoryModal/LearningStoryModal";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import { createlearningStoryAPI } from "api/learning-stories-api";
import { useNavigate, useParams } from "react-router-dom";
import { CURLANG, LEARNING_STORIES, CHILDREN_PORTFOLIO } from "constants/url";
import useStore from "store/store";

interface DateGroup {
  [date: string]: ImageType[];
}

const DayView = ({ images }: { images: ImageType[] }) => {
  const openSnack = useSnack();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const { childID, groupSlug } = useParams();
  const { currLanguage } = useStore((state) => state);
  const groupID = localStorage.getItem("curr_group_id");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImageType>();
  const [openLearningModal, setOpenLearningModal] = useState<boolean>(false);

  const dateGroup: DateGroup = useMemo(
    () =>
      images.reduce((group: DateGroup, image: ImageType) => {
        const date = moment(image.created_at).format(DATE_FORMAT);

        if (!group[date]) {
          group[date] = [image];
        } else {
          group[date].push(image);
        }

        return group;
      }, {}),
    [images]
  );

  const handleOpen = (image: ImageType) => {
    setOpen(true);
    setSelectedImage(image);
  };

  const handleClose = async () => {
    setOpen(false);
    if (selectedImage?.link) {
      await fetchPhoto(selectedImage.link);
    }
    setOpenLearningModal(true);
  };

  const createLearningStory = async (data: {
    story?: string;
    date?: string;
    photo?: File | undefined;
    parentView: boolean;
    fundamental: string | undefined;
    milestone: string | undefined;
  }) => {
    setIsLoading(true);
    const learningStoryData = {
      child_id: Number(childID),
      group_id: Number(groupID),
      story: data.story || "",
      date: data.date || "",
      fundamental_id: data.fundamental || undefined,
      milestone_id: data.milestone || undefined,
      image: data.photo || undefined,
      accessToken: localStorage.getItem("access"),
    };

    const res = await createlearningStoryAPI(learningStoryData);
    if (typeof res !== "string") {
      setIsLoading(false);
      setOpenLearningModal(false);
      openSnack(translation.createSuccess, true);
      navigate(
        `${CURLANG(
          currLanguage
        )}/${CHILDREN_PORTFOLIO}/${groupSlug}/${childID}/${LEARNING_STORIES}`
      );
    } else {
      openSnack(translation.failed_upload, false);
      setIsLoading(false);
    }
  };

  const fetchPhoto = (imageUrl: string | undefined) => {
    if (!imageUrl) return;

    return fetch(imageUrl, {
      headers: { "Cache-Control": "no-cache" },
    })
      .then((res) => res.blob())
      .then((imageBlob) => {
        const file = new File([imageBlob], "image.jpg", {
          type: imageBlob.type,
        });
        setImageFile(file);
      })
      .catch((err) => {
        console.error("Failed to fetch image from S3:", err);
        openSnack("Failed to fetch image. Please try again.", false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {Object.entries(dateGroup)
        .sort((a, b) =>
          moment(b[0], DATE_FORMAT).diff(moment(a[0], DATE_FORMAT))
        )
        .map(([date, _images], yIndex, array) => {
          const currentId = `${moment(date, DATE_FORMAT).year()}-${
            moment(date, DATE_FORMAT).month() + 1
          }`;

          const previousId =
            yIndex === 0
              ? ""
              : `${moment(array[yIndex - 1][0], DATE_FORMAT).year()}-${
                  moment(array[yIndex - 1][0], DATE_FORMAT).month() + 1
                }`;

          return (
            <Box
              key={yIndex}
              id={currentId !== previousId ? currentId : undefined}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  lineHeight: 1,
                  mb: 1.25,
                }}
              >
                {date}
              </Typography>

              <Box sx={containerStyles}>
                {_images.map((image, iIndex) => (
                  <Box key={iIndex} sx={containerStyles}>
                    <ImageCard
                      src={image.link}
                      caption={image.caption}
                      onClick={() => handleOpen(image)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          );
        })}

      {open && selectedImage && (
        <ImagePreviewModal
          open={open}
          image={selectedImage}
          handleClose={handleClose}
        />
      )}

      {openLearningModal && selectedImage && (
        <LearningStoryModal
          title="Create Learning Story"
          open={openLearningModal}
          setOpen={setOpenLearningModal}
          image={imageFile}
          story={selectedImage.caption}
          date={moment(selectedImage.created_at).format("YYYY-MM-DD")}
          onSave={createLearningStory}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default DayView;

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createObjective, getAllFundamentals } from "api/fundamental-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  API_Fundamental,
  API_Create_Project_Fundamental,
} from "types/project";

import Button from "components/Button/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { translation } from "constants/translation";

const CreateObjectivePage = () => {
  return (
    <Box sx={{ alignItems: "top" }}>
      <Title />
      <Form />
    </Box>
  );
};

const Title = () => {
  const title = useStore((state) => state.currentProject.name);

  return (
    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
        mb: "20px",
      }}
    >
      {translation.adding_curriculum_fundamental_to} {title}
    </Typography>
  );
};

const Form = () => {
  const { projectID, projectSlug, currLanguage } = useStore((state) => ({
    projectID: state.currentProject.id,
    projectSlug: state.currentProject.slug,
    currLanguage: state.currLanguage,
  }));

  const openSnack = useSnack();
  const navigate = useNavigate();

  const [fundamentalList, setFundamentalList] = useState<API_Fundamental[]>([]);
  const [objectives, setObjectives] = useState<
    API_Create_Project_Fundamental[]
  >([]);

  useEffect(() => {
    const updateFundamentals = async () => {
      const updatedAllFundamental: API_Fundamental[] = await getAllFundamentals(
        currLanguage
      );
      setFundamentalList(updatedAllFundamental);
      setObjectives([
        {
          project: projectID.toString(),
          fundamental: "-1",
          objective: "",
        },
      ]);
    };

    updateFundamentals();
  }, [currLanguage, projectID]);

  const SubmitForm = async () => {
    let createFailed = false;

    for (let index = 0; index < objectives.length; index++) {
      const res = await createObjective(currLanguage, objectives[index]);
      if (typeof res === "string") {
        openSnack(res, false);
        createFailed = false;
      }
    }

    if (!createFailed) {
      openSnack("Added Learning Objectives successfully!", true);
      navigate(`${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projectSlug)}`);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "2rem",
          justifyContent: "center",
        }}
      >
        {objectives.map((fun, index) => {
          return (
            <NewFundamentalCard
              index={index}
              objectives={objectives}
              setObjectives={setObjectives}
              fundamentalList={fundamentalList}
            />
          );
        })}
      </Box>
      <Button
        buttonText={translation.add_objective || "Add Objective"}
        arrow={false}
        style={{
          fontSize: "14px",
          margin: "50px auto",
        }}
        onClick={() => {
          const updatedObjectives = objectives.map((o) => ({ ...o }));
          updatedObjectives.push({
            project: projectID.toString(),
            fundamental: "-1",
            objective: "",
          });
          setObjectives(updatedObjectives);
        }}
      />
      <Button
        buttonText={translation.create || "Create"}
        style={{
          fontSize: "14px",
          backgroundColor: "var(--txt-secondary)",
          position: "fixed",
          bottom: 30,
          right: 40,
        }}
        onClick={SubmitForm}
      />
    </>
  );
};

const NewFundamentalCard = ({
  index,
  objectives,
  setObjectives,
  fundamentalList,
}: {
  index: number;
  objectives: API_Create_Project_Fundamental[];
  setObjectives: React.Dispatch<
    React.SetStateAction<API_Create_Project_Fundamental[]>
  >;
  fundamentalList: API_Fundamental[];
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    const updatedObjectives = objectives.map((o) => ({ ...o }));
    updatedObjectives[index].fundamental = event.target.value;
    setObjectives(updatedObjectives);
  };

  return (
    <Card
      sx={{
        minWidth: 250,
        maxWidth: 250,
        border: "2px solid",
        borderColor: "primary.main",
        borderRadius: "10px",
        backgroundColor: "primary.light",
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        transition: "200ms all ease-in-out",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: "25px",
      }}
    >
      <CardHeader
        action={
          <IconButton title={translation.delete_fundamental}>
            <ClearIcon
              onClick={() => {
                if (objectives.length > 1) {
                  const updatedObjectives = objectives.map((o) => ({ ...o }));
                  updatedObjectives.splice(index, 1);
                  setObjectives(updatedObjectives);
                }
              }}
            />
          </IconButton>
        }
        subheader={`${translation.fundamental_str} ${index + 1}`}
      />
      <CardContent
        sx={{
          width: "200px",
        }}
      >
        <Box>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              {translation.fundamental_str}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={objectives[index].fundamental}
              label={translation.fundamental_str}
              onChange={handleChange}
            >
              {fundamentalList.map((fun) => {
                return (
                  <MenuItem key={fun.id} value={fun.id}>
                    {fun.name}
                  </MenuItem>
                );
              })}
              <MenuItem key="-1" value="-1">
                ---------
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={translation.objective_str}
            value={objectives[index].objective}
            style={{ marginTop: "5px" }}
            onChange={(event) => {
              const updatedObjectives = objectives.map((o) => ({ ...o }));
              updatedObjectives[index].objective = event.target.value;
              setObjectives(updatedObjectives);
            }}
            key={"name"}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CreateObjectivePage;

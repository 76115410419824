import { useState } from "react";
import { Typography } from "@mui/material";
import useStore from "store/store";

import { translation } from "constants/translation";
import TeacherGroupContainer from "./TeacherDashboardComponent/TeacherGroupContainer";
import Classes from "./TeacherDashboardComponent/Classes";
import QuickItems from "./TeacherDashboardComponent/QuickItems";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { usePopulateLessonsInfo } from "hooks/lessons";

export interface Class_Info {
  id: number;
  image?: string;
  name: string;
  slug: string;
}

const TeacherDashboard = () => {
  usePopulateLessonsInfo();
  const { groupLessonInfoList, isGroupLessonInfoListLoading } = useStore(
    (state) => ({
      groupLessonInfoList: state.groupLessonInfoList,
      isGroupLessonInfoListLoading: state.isGroupLessonInfoListLoading,
    })
  );

  const classes: Class_Info[] = groupLessonInfoList.map((groupLessonInfo) => ({
    id: groupLessonInfo.id,
    image: groupLessonInfo.image,
    name: groupLessonInfo.name,
    slug: groupLessonInfo.slug,
  }));

  const [classIndex, setClassIndex] = useState<number>(0);

  return (
    <>

      {isGroupLessonInfoListLoading ? (
        <LoadingIndicator />
      ) : groupLessonInfoList.length > 0 ? (
        <>
          <Classes
            classes={classes}
            classIndex={classIndex}
            setClassIndex={setClassIndex}
            variant="teacher"
          />

          <TeacherGroupContainer
            classIndex={classIndex}
            groupLessonInfoList={groupLessonInfoList}
          />
        </>
      ) : (
        <NothingMessage message={translation.noGroupAssigned} />
      )}
    </>
  );
};

export const NothingMessage = ({ message }: { message: string }) => (
  <Typography
    sx={{
      fontSize: "1.25rem",
      backgroundColor: "primary.light",
      borderRadius: "10px",
      p: "10px 20px",
      alignSelf: "center",
      textAlign: "center",
    }}
  >
    {message}
  </Typography>
);

export default TeacherDashboard;

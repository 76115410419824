import React, { useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import FavoriteIcon from "@mui/icons-material/Favorite";
import { Chip, Tooltip } from "@mui/material";

import { AddToBagButton } from "../Button/Button";

import { API_Project } from "types/project";

import {
  createBackpackItem,
  createFavItem,
  deleteBackpackItem,
  deleteFavItem,
} from "api/backpack-api";

import Logo from "images/logo-2.png";
import { translation } from "constants/translation";
import { Stack } from "@mui/system";
import ModuleTag from "../ModuleTag/ModuleTag";
import Image from "components/Image/Image";
import Rating from "../Rating";

//age
import BlossomsIcon from "../../images/age-group/blossoms-icon.png";
import BudsIcon from "../../images/age-group/buds-icon.png";
import SproutsIcon from "../../images/age-group/sprouts-icon.png";
import {
  BLOOMS,
  BLOSSOMS,
  BUDS,
  SPROUTLINGS,
  SPROUTS,
} from "constants/project-lesson-activity";

//lock icon
import LockIcon from "../../images/lock-icon.png";
import { ActionButton } from "../ProjectModal/ProjectModal";
import { useLocation } from "react-router-dom";
import { BACKPACK } from "constants/url";
import { ViewProjectButton } from "../ProjectModal/components/CommonComponents";
import { MOBILE_SCALE } from "constants/constantInGeneral";

const DetailCard = ({
  project,
  onClick,
  inBag = false,
  isForDisplay = false,
  groupID,
}: {
  project: API_Project;
  onClick: () => any;
  inBag?: boolean;
  isForDisplay?: boolean;
  groupID?: number;
}) => {
  const { pathname } = useLocation();

  const isBackpackPage = pathname.includes(BACKPACK);

  const { currGroup, currGroupBackpack, currGroupFavourite, profileDetails } =
    useStore((state) => state);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(
    currGroupFavourite.projects.filter(
      (favProject) => favProject.id === project.id
    ).length !== 0
  );

  const [isInBag, setIsInBag] = useState<boolean>(
    currGroupBackpack.projects.filter(
      (backpackProject) => backpackProject.id === project.id
    ).length !== 0
  );
  // console.log("project: ", project);
  // console.log("currGroup: ", currGroup);

  let AgeGroupImgSrc = "";
  let translatedAge = "";

  if (project.age === BLOSSOMS || project.age === BLOOMS) {
    AgeGroupImgSrc = BlossomsIcon;
    translatedAge = translation.Blossoms;
  } else if (project.age === BUDS) {
    AgeGroupImgSrc = BudsIcon;
    translatedAge = translation.Buds;
  } else if (project.age === SPROUTS || project.age === SPROUTLINGS) {
    AgeGroupImgSrc = SproutsIcon;
    translatedAge = translation.Sprouts;
  }


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile screen size

  return (
    <>
      <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          minWidth: { xs: MOBILE_SCALE * 265, sm: 265 },
          maxWidth: { xs: MOBILE_SCALE * 265, sm: 265 },
          borderRadius: "10px",
          backgroundColor: "#F0F0F0",
          filter: project.is_published
            ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            : "none",
          // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          cursor: project.is_granted_access ? "pointer" : "default",
          transition: "200ms all ease-in-out",
          position: "relative",
          display: "flex",
          flexDirection: "column",

          opacity: project.is_published && project.is_granted_access ? 1 : 0.7,
        }}
        onClick={project.is_granted_access ? onClick : () => { }}
      >
        {!project.is_granted_access && isHovered && (
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "#000000d4",
              zIndex: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "primary.main",
                textAlign: "center",
              }}
            >
              <Image
                style={{ width: 100, height: 100 }}
                src={LockIcon}
                alt="lock-icon"
              />

              <Typography
                sx={{
                  p: 2,
                  fontWeight: 500,
                  fontSize: 20,
                }}
              >
                {translation.oopsDemoAccount}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            position: "absolute",
            display: "flex",
            width: "100%",
            height: "35px",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            pt: 1,
            pl: 1,
          }}
        >
          {project.is_recommended && (
            <Chip
              size="small"
              label="Recommended"
              color="primary"
              sx={{ cursor: "pointer", color: "txt.light" }}
            />
          )}
        </Box>
        <CardMedia
          component="img"
          alt={project.images.length > 0 ? project.images[0].file : "No Image"}
          image={project.images.length > 0 ? project.images[0].file : Logo}
          sx={{
            backgroundColor: "primary.light",
            height: { xs: 106, sm: 206 },
            "&::before": { display: "none" },
          }}
          onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          }}
        />
        {!isForDisplay && (
          <FavouriteBtn
            projectID={project.id}
            groupID={currGroup.id}
            liked={liked}
          />
        )}

        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "8px 14px", sm: "13px 18px" },
            gap: "0.5rem",
            flexGrow: 1,
            pb: "0px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Rating rating={project.average_rating} />
            </Stack>
            <Stack direction="column" spacing={0.5}>
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Box sx={{ mb: "-10px" }}>
                  <ModuleTag
                    projModule={project.module}
                    styles={{ backgroundColor: "rgba(209, 228, 227, 1)" }}
                  />
                </Box>
                {project.age && <Tooltip title={<AgeLabel ageCategory={project.age} />}>
                  <Box
                    sx={{
                      width: "32px",
                      height: "32px",
                      overflow: "hidden",
                      // border: "2px solid var(--primary-main)",
                      border: "1px solid #b9b9b9",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{ width: "20px", height: "20px" }}
                      src={AgeGroupImgSrc}
                      alt="Level"
                    />
                  </Box>
                </Tooltip>}
              </Stack>
              <Title title={project.name} />
            </Stack>

            {!isMobile && <Desc desc={project.description} />}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            {profileDetails.role === "CE" ? (
              <ViewProjectButton
                slug={project.slug}
                assessment={!!project.assessment}
              />
            ) : (
              !isForDisplay &&
              (isBackpackPage ? (
                <BackpackButton
                  style={{
                    marginTop: "auto",
                    width: "inherit",
                    backgroundColor: isInBag ? "#b9b9b9d1" : "default",
                  }}
                  isInBag={isInBag}
                  groupID={currGroup.id}
                  projectID={project.id}
                />
              ) : (
                <ActionButton project={project} />
              ))
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

const AgeLabel = ({ ageCategory }: { ageCategory: string }) => {
  const italicBoldStyle = {
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "Italic",
  };

  const LabelComponent = ({
    title,
    descPartOne,
    descPartTwo,
  }: {
    title: string;
    descPartOne: string;
    descPartTwo: string;
  }) => {
    return (
      <>
        <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
        <Typography>
          <Typography sx={italicBoldStyle} component="span">
            {descPartOne}
          </Typography>
          {descPartTwo}
        </Typography>
      </>
    );
  };

  return (
    <Box sx={{}}>
      {ageCategory === SPROUTS || ageCategory === SPROUTLINGS ? (
        <LabelComponent
          title={SPROUTS}
          descPartOne={translation.sproutsExplainationPartOne}
          descPartTwo={translation.sproutsExplainationPartTwo}
        />
      ) : ageCategory === BUDS ? (
        <LabelComponent
          title={BUDS}
          descPartOne={translation.budsExplainationPartOne}
          descPartTwo={translation.budsExplainationPartTwo}
        />
      ) : ageCategory === BLOSSOMS || ageCategory === BLOOMS ? (
        <LabelComponent
          title={BLOSSOMS}
          descPartOne={translation.blossomsExplainationPartOne}
          descPartTwo={translation.blossomsExplainationPartTwo}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

const FavouriteBtn = ({
  projectID,
  groupID,
  liked,
}: {
  projectID: number;
  groupID: number;
  liked: boolean;
}) => {
  const openSnack = useSnack();
  // const [openModal, setOpenModal] = useState<boolean>(false);

  const { setRefreshGroupFavourite } = useStore((state) => state);

  const addFav = async (projectID: number, groupID: number) => {
    const res = await createFavItem(projectID, groupID);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack("Favourited successfully!", true);
      setRefreshGroupFavourite(true);
    }
  };

  const delFav = async (projectID: number, groupID: number) => {
    const res = await deleteFavItem([projectID], groupID);
    if (res === "success") {
      openSnack("Removed the project from favourite!", true);
      setRefreshGroupFavourite(true);
    } else {
      openSnack(res, false);
    }
  };

  return (
    <>
      <FavoriteIcon
        sx={{
          color: `${liked ? "#ff0000" : "#ffffff"}`,
          position: "absolute",
          top: 12,
          right: 12,
          cursor: "pointer",
          "&:hover": {
            color: `${liked ? "#ffffff" : "#ff0000"}`,
          },
        }}
        onClick={(event: React.MouseEvent<SVGSVGElement>) => {
          event.stopPropagation();
          // handleLike && handleLike();
          if (groupID !== -1) {
            liked ? delFav(projectID, groupID) : addFav(projectID, groupID);
          } else {
            openSnack(translation.noGroupAssigned, false);
          }
        }}
      />
    </>
  );
};

const BackpackButton = ({
  style,
  isInBag,
  groupID,
  projectID,
}: {
  style?: React.CSSProperties;
  isInBag: boolean;
  groupID: number;
  projectID: number;
}) => {
  const openSnack = useSnack();
  // const [openModal, setOpenModal] = useState<boolean>(false);

  const { setRefreshGroupBackpack } = useStore((state) => state);

  const delProject = async (projectID: number, groupID: number) => {
    const res = await deleteBackpackItem([projectID], groupID);
    if (res === "success") {
      setRefreshGroupBackpack(true);
      openSnack("Removed from backpack!", true);
    } else {
      openSnack(res, false);
    }
  };

  const addProject = async (projectID: number, groupID: number) => {
    const res = await createBackpackItem(projectID, groupID);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setRefreshGroupBackpack(true);
      openSnack("Added to backpack!", true);
    }
  };

  return (
    <>
      <AddToBagButton
        btnText={
          isInBag ? translation.removeFromBackpack : translation.addToBackpack
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();

          if (groupID !== -1) {
            isInBag
              ? delProject(projectID, groupID)
              : addProject(projectID, groupID);
          } else {
            openSnack(translation.noGroupAssigned, false);
          }

          // if (groupID) dont show modal -> call api
          // else show modal and allow user selection
        }}
        style={style}
      />
      {/* <AddBagFavModal
        open={openModal}
        setOpen={setOpenModal}
        type="backpack"
        projectID={projectID}
      /> */}
    </>
  );
};

const Title = ({ title }: { title?: string }) => {
  return (
    <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
      {title}
    </Typography>
  );
};

const Desc = ({ desc }: { desc?: string }) => {
  return (
    <Box
      color="txt.dark2"
      sx={{
        fontSize: ".75rem",
        width: "100%",
        maxHeight: "50px",
        overflow: "hidden",
      }}
      dangerouslySetInnerHTML={{ __html: desc ? desc : "" }}
    ></Box>
  );
};

export default DetailCard;

import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Type } from "./types";

type Props = {
  type: Type;
  mode?: "Create" | "Add" | "Edit";
  assessment: boolean;
  children?: React.ReactNode | React.ReactNode[];
  handleClose: () => void;
};

const DrawerHeader = ({ type, mode="Edit", assessment, handleClose, children }: Props) => {
  const textToShow = useMemo(() => {
    if (!assessment) {
      return `${mode} ${type}`;
    }

    if (type === "Project") {
      return "Edit Assessment";
    }

    if (type === "Lesson") {
      return `${mode} Assessment Plan`;
    }

    if (type === "Activity") {
      return `${mode} Assessment Activity`;
    }

    return "";
  }, [assessment, type, mode]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <ArrowBackIcon
          sx={{
            cursor: "pointer",
            borderRadius: "4px",
            transition: "all 200ms ease-in-out",
            color: "#444",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
              transition: "all 200ms ease-in-out",
            },
          }}
          onClick={handleClose}
        />
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          {textToShow}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default DrawerHeader;
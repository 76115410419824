import useStore from "store/store";

import Dashboard from "containers/WelcomeBackPage/components/Dashboard";
import LoginPage from "containers/LoginPage/LoginPage";

const LandingPage = () => {
  const { profileID } = useStore((state) => state);

  return !!profileID ? <Dashboard /> : <LoginPage />;
};

export default LandingPage;

import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React, { useState } from "react";
import { createChat } from "api/chat-api";

import { API_Profile_Details } from "types/profile";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import { CHAT, CURLANG } from "constants/url";
import { SearchBar, Title, ContactList } from "./CommonComponents";
import useSnack from "hooks/useSnack";
import { translation } from "constants/translation";
import { chatModalStyle } from "./chatConstants";

const NewChatModal = ({
  open,
  setOpen,
  allContacts,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allContacts: API_Profile_Details[];
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const { allChats, setAllChats, currLanguage } = useStore((state) => ({
    allChats: state.allChats,
    setAllChats: state.setAllChats,
    currLanguage: state.currLanguage,
  }));

  const startChat = async (contactId: number) => {
    const res = await createChat(contactId, localStorage.getItem("access"));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const updatedAllChats = [...allChats, res];
      setAllChats(updatedAllChats);
      setOpen(false);
      navigate(`${CURLANG(currLanguage)}/${CHAT(res.id.toString())}`);
    }
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setSearchQuery("");
    setOpen(false);
  };

  const [searchQuery, setSearchQuery] = useState<string>("");

  const contacts =
    searchQuery === ""
      ? allContacts
      : allContacts.filter((contact) =>
          contact.preferred_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={chatModalStyle}>
          <Title title={translation.selectContact} />

          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />

          <ContactList contacts={contacts} onClick={startChat} />
        </Box>
      </Fade>
    </Modal>
  );
};

export default NewChatModal;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet, useParams } from "react-router-dom";

import useStore from "store/store";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

import LanguageSelector from "components/LanguageSelector/LanguageSelector";

import HomeImg from "images/home.png";
import BookImg from "images/book.png";
import PortfolioImg from "images/portfolio.png";
import CalendarImg from "images/calendar.png";
import MyClassesImg from "images/my-classes.png";
import TeacherImg from "images/teacher.png";
import GalleryImg from "images/gallery-icon.png";
import NoticesImg from "images/dashboard/notices-icon.png";
import LittleEvaluatorLogo from "images/little-evaluator-logo.png";

import { TEACHER_TRAINING_ACADEMY } from "constants/url";
import { Drawer } from "@mui/material";
import { languageList } from "types/language";
import { getGroupList, getAccountProfile } from "api/profile-api";
import GroupSelector from "components/GroupSelector/GroupSelector";
import CloseIcon from "@mui/icons-material/Close";
import LoginButton from "./LoginButton";
import TTALoginButton from "./TTALoginButton";
import {
  PORTFOLIOS_TEXT,
  CALENDAR_TEXT,
  COMMUNITY_TEXT,
  DASHBOARD_TEXT,
  LESSON_LIBRARY_TEXT,
  MY_CLASSES_TEXT,
  MY_PROGRESS_TEXT,
  MY_CHILDREN_TEXT,
  OUR_STORY_TEXT,
  NOTICES_TEXT,
  PHOTOS_TEXT,
  PROFILE_TEXT,
  LITTLE_EVALUATOR_TEXT,
} from "constants/navbar";
import TeachMenuList from "./NavbarComponents/TeachMenuList";
import ChatButton from "./NavbarComponents/ChatButton";
import DesktopLogo from "./NavbarComponents/DesktopLogo";
import MobileLogo from "./NavbarComponents/MobileLogo";
import TTAMenuLinks from "./NavbarComponents/TTAMenuLinks";
import NotificationButton from "./NavbarComponents/NotificationButton";

export const icons: { [item: string]: string } = {
  //Teaching platform
  [DASHBOARD_TEXT]: HomeImg,
  [LESSON_LIBRARY_TEXT]: BookImg,
  [PORTFOLIOS_TEXT]: PortfolioImg,
  [LITTLE_EVALUATOR_TEXT]: LittleEvaluatorLogo,
  [CALENDAR_TEXT]: CalendarImg,
  [MY_CLASSES_TEXT]: MyClassesImg,
  [MY_CHILDREN_TEXT]: PortfolioImg,
  [PROFILE_TEXT]: MyClassesImg,
  [NOTICES_TEXT]: NoticesImg,
  [PHOTOS_TEXT]: GalleryImg,

  // Teacher Traning Academy
  [MY_PROGRESS_TEXT]: TeacherImg,
  [OUR_STORY_TEXT]: TeacherImg,
  [COMMUNITY_TEXT]: TeacherImg,
};

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { languageSlug } = useParams();
  const ref = React.useRef<HTMLDivElement>(null);

  const {
    currLanguage,
    setIsCurrGroupLoaded,
    setCurrLanguage,
    setCurrGroup,
    setGroupList,
    profileID,
    profileDetails,
    refreshGroup,
    setRefreshGroup,
  } = useStore((state) => state);

  useEffect(() => {
    if (languageSlug) {
      if (!languageList.includes(languageSlug)) {
        setCurrLanguage("en");
        navigate("/en" + location.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLanguage]);

  //Group
  useEffect(() => {
    const populateData = async () => {
      setIsCurrGroupLoaded(false);
      const res = await getGroupList(currLanguage);
      if (typeof res === "string" || res.length === 0) {
      } else {
        setGroupList(res);
        const curGroupId = localStorage.getItem("curr_group_id");
        if (
          curGroupId &&
          res.filter((group) => group?.id === Number(curGroupId))[0]?.id ===
            Number(curGroupId)
        ) {
          setCurrGroup(
            res.filter((group) => group.id === Number(curGroupId))[0]
          );
        } else {
          setCurrGroup(res[0]);
          localStorage.setItem("curr_group_id", String(res[0].id));
        }
      }
      setIsCurrGroupLoaded(true);
    };

    if (refreshGroup && profileID) {
      populateData();
      setRefreshGroup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGroup, profileID]);

  const isTTA = location.pathname
    .toString()
    .includes("/" + TEACHER_TRAINING_ACADEMY);

  return (
    <>
      <AppBar position="sticky">
        <Container maxWidth={false} sx={{ maxHeight: "64px", zIndex: 1000 }}>
          <Toolbar disableGutters>
            <DesktopLogo width={ref.current?.offsetWidth} isTTA={isTTA} />

            <DesktopMenu isTTA={isTTA} />
            <MobileMenu isTTA={isTTA} />
            <MobileLogo isTTA={isTTA} />
            <Box
              ref={ref}
              sx={{
                display: "flex",
                alignItems: "center",
                ml: "auto",
              }}
            >
              {profileID && (
                <Box
                  sx={{
                    display: { xs: "none", lg: "flex" },
                    alignItems: "center",
                  }}
                >
                  {profileDetails.role !== "PR" && !isTTA && <GroupSelector />}
                  <LanguageSelector isTTA={isTTA} />
                  {!isTTA && (
                    <>
                      <ChatButton />
                      {profileDetails.role !== "PR" && <NotificationButton />}
                    </>
                  )}
                </Box>
              )}

              {!profileID && <LanguageSelector isTTA={isTTA} />}
              {isTTA ? <TTALoginButton /> : <LoginButton />}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Outlet />
    </>
  );
};

const DesktopMenu = ({ isTTA }: { isTTA: boolean }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: "none", lg: "flex" },
        gap: "16px",
        justifyContent: "center",
        mx: 3,
      }}
    >
      {isTTA ? <TTAMenuLinks /> : <TeachMenuList />}
    </Box>
  );
};

const MobileMenu = ({ isTTA }: { isTTA: boolean }) => {
  const { profileID, profileDetails } = useStore((state) => state);

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return profileDetails.id !== -1 ? (
    <Box sx={{ display: { xs: "flex", lg: "none" }, mr: "auto" }}>
      <IconButton
        id="mobile-menu-button"
        aria-expanded={open ? true : false}
        size="large"
        onClick={handleOpen}
        sx={{
          p: 1,
        }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        id="mobile-menu-drawer"
        anchor="left"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDrawer-paper": {
            minWidth: 200,
            backgroundColor: "primary.main",
            p: 3,
          },
        }}
      >
        <Box
          id="mobile-menu-close-button"
          onClick={handleClose}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseIcon
            sx={{
              cursor: "pointer",
              borderRadius: "4px",
              ml: "auto",
              transition: "all 200ms ease-in-out",
              color: "#444",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.05)",
                transition: "all 200ms ease-in-out",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1.5,
            my: 2,
          }}
        >
          {isTTA ? (
            <TTAMenuLinks handleClose={handleClose} />
          ) : (
            <TeachMenuList handleClose={handleClose} isMobileView />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "auto",
            mb: 2,
            gap: 3,
          }}
        >
          {!isTTA && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
            >
              <ChatButton />
              {profileDetails.role !== "PR" && <NotificationButton />}
            </Box>
          )}

          {profileID && profileDetails.role !== "PR" && !isTTA && (
            <GroupSelector isMobileView />
          )}

          <LanguageSelector isMobileView />
        </Box>
      </Drawer>
    </Box>
  ) : null;
};

export default NavBar;

import { useEffect } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutSession from "./CheckoutSession";
import useSnack from "hooks/useSnack";

const stripePromise = loadStripe(
  "pk_test_51MF670JdaBksaUx9fp2c0WnfEcfybM6e8EglSEqIUiWFeH1oaQt96ZyanAEB2DGZvpTie0kYPmhS2KjAnc8Sd6j700DmjrsyCw"
);

const PaymentPage = () => {
  const openSnack = useSnack();
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      openSnack("Payment succeed!", true);
    }
    if (query.get("canceled")) {
      openSnack("Payment canceled!", false);
    }
  }, []);

  return (
    <>
      <Elements stripe={stripePromise}>
        {/* <CheckoutForm /> */}
        <CheckoutSession />
        {/* <PricingPage /> */}
      </Elements>
    </>
  );
};

export default PaymentPage;

import React, { useState } from "react";
import moment from "moment";
import { HeaderProps } from "react-big-calendar";
import useStore from "store/store";
import {
  Box,
  Typography,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DAYS } from "types/calendar";
import { API_Timetable_Event_Info } from "types/timetable";
import { DATE_FORMAT, TIME_FORMAT } from "constants/timetable";
import {
  getTimeSlots,
  convert24HrsTo12Hrs,
  getUniqueValues,
} from "helper/timetable";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import { translation } from "constants/translation";
import Button from "components/Button/Button";
import {
  deleteTimetableEvent,
  createTimetableEvent,
  updateTimetableEvent,
} from "api/timetable-api";
import useSnack from "hooks/useSnack";
import useColors from "hooks/useColors";

export const Title = ({ day, date }: { day: string; date: string }) => (
  <Box
    sx={{
      color: "#312F30",
      textAlign: "center",
      mt: 1,
      mb: 2,
    }}
  >
    <Typography
      sx={{
        fontSize: "18px",
      }}
    >
      {day}
    </Typography>
    {/* <Typography
      sx={{
        fontSize: "12px",
      }}
    >
      {date}
    </Typography> */}
  </Box>
);

export const CustomHeader = ({ date }: HeaderProps) => (
  <Title
    day={DAYS[Number(moment(date).format("d"))]}
    date={moment(date).format(DATE_FORMAT)}
  />
);

export const CustomEvent = ({
  event,
  setEvents,
}: {
  event: API_Timetable_Event_Info;
  setEvents: React.Dispatch<
    React.SetStateAction<API_Timetable_Event_Info[] | undefined>
  >;
}) => {
  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));
  const colors = useColors();
  const openSnack = useSnack();

  const color = colors[event.color % colors.length];

  const handleDelete = async () => {
    const res = await deleteTimetableEvent(event.id);

    if (res === "Success") {
      setEvents((prev) => prev?.filter((e) => e.id !== event.id));
      openSnack(translation.successDeleteEvent, true);
    } else {
      openSnack(translation.failDeleteEvent, false);
    }
  };

  const handleDuplicate = async () => {
    const newEvent = await createTimetableEvent({
      name: event.name,
      type: event.type,
      day: event.day,
      start: moment(event.start).format(TIME_FORMAT),
      end: moment(event.end).format(TIME_FORMAT),
      color: event.color,
      currGroup: currGroup.id,
    });

    if (typeof newEvent !== "string") {
      setEvents((prev) => (prev ? [...prev, newEvent] : [newEvent]));
      openSnack(translation.successDuplicateEvent, true);
    } else {
      openSnack(translation.failDuplicateEvent, false);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `${translation.delete} ${event.name}?`,
    warningContext: translation.irreversibleAction,
    handleDelete,
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        <WarningDialog {...warningBody} />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.25,
          position: "absolute",
          top: -21,
          right: -7,
          backgroundColor: "white",
        }}
      >
        <ContentCopyIcon
          sx={{
            color: "#312F30",
            cursor: "pointer",
            width: "0.7em",
            height: "0.7em",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDuplicate();
          }}
        />

        <CloseIcon
          fontSize="small"
          sx={{
            color: "red",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenDialog();
          }}
        />
      </Box>

      <Typography
        sx={{
          my: 0.25,
        }}
      >
        {event.name}
      </Typography>

      <Typography
        sx={{
          color: "#312F30",
          backgroundColor: color,
          fontSize: "12px",
          lineHeight: 1,
          py: 0.5,
          px: 1,
          borderRadius: "6px",
          display: "inline-block",
        }}
      >
        {event.type}
      </Typography>
    </Box>
  );
};

const CustomModal = ({
  open,
  handleClose,
  isEdit = false,
  children,
}: {
  open: boolean;
  handleClose: () => void;
  isEdit?: boolean;
  children: React.ReactNode;
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      sx: {
        backgroundColor: "rgba(49,47,48,0.4)",
      },
    }}
    sx={{
      m: "24px",
    }}
  >
    <Fade in={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: isEdit ? "600px" : "320px",
          bgcolor: "background.paper",
          p: 3,
          borderRadius: "10px",
          overflow: "auto",
          maxHeight: "100%",
          color: "#312F30",
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        {children}
      </Box>
    </Fade>
  </Modal>
);

export const EventCardPopup = ({
  event,
  open,
  setOpen,
}: {
  event: API_Timetable_Event_Info;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const colors = useColors();
  const color = colors[event.color % colors.length];

  const handleClose = () => setOpen(false);

  const startTime = convert24HrsTo12Hrs(event.start);
  const endTime = convert24HrsTo12Hrs(event.end);

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "1.25rem",
          lineHeight: 1.25,
        }}
      >
        {event.name}
      </Typography>

      <Typography
        sx={{
          color: "#312F30",
          backgroundColor: color,
          fontSize: "14px",
          lineHeight: 1,
          py: 1,
          px: 2,
          borderRadius: "10px",
          display: "inline-block",
          mt: 1,
          mb: 1.25,
        }}
      >
        {event.type}
      </Typography>

      <Typography
        sx={{
          fontSize: "14px",
        }}
      >
        {startTime} — {endTime} ({DAYS[event.day]})
      </Typography>
    </CustomModal>
  );
};

const EVENT_TITLE_SUGGESTION = ["Breakfast", "Lunch", "Nap", "Lesson", "Break"];

export const EventPopup = ({
  event,
  events,
  isEdit = false,
  open,
  setOpen,
  setEvents,
  modules,
}: {
  event?: API_Timetable_Event_Info;
  events: API_Timetable_Event_Info[];
  isEdit?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEvents: React.Dispatch<
    React.SetStateAction<API_Timetable_Event_Info[] | undefined>
  >;
  modules: string[];
}) => {
  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));
  const TimeSlots = getTimeSlots();

  const initialEvent: API_Timetable_Event_Info = {
    id: -1,
    name: "",
    type: modules[0],
    day: moment().day(),
    start: "09:00:00",
    end: "10:00:00",
    color: 0,
    currGroup: currGroup.id,
  };

  const [eventDetails, setEventDetails] = useState<API_Timetable_Event_Info>(
    event || initialEvent
  );

  const startTimeIndex = TimeSlots.findIndex(
    (slot) => slot === eventDetails.start
  );
  const endTimeIndex = TimeSlots.findIndex((slot) => slot === eventDetails.end);

  const handleClose = () => {
    setEventDetails(event || initialEvent);
    setOpen(false);
  };

  return (
    <CustomModal open={open} handleClose={handleClose} isEdit={true}>
      <Typography variant="h6" component="h2">
        {isEdit ? translation.editEvent : translation.addNewEvent}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2.5,
          mt: 2.5,
        }}
      >
        <AutocompleteField
          label={translation.eventTitle}
          value={eventDetails.name}
          setValue={(name) => setEventDetails((prev) => ({ ...prev, name }))}
          options={getUniqueValues([
            ...EVENT_TITLE_SUGGESTION,
            ...events.map((event) => event.name),
          ])}
        />

        <AutocompleteField
          label={translation.eventType}
          value={eventDetails.type}
          setValue={(type) => setEventDetails((prev) => ({ ...prev, type }))}
          options={getUniqueValues([
            ...modules,
            ...events.map((event) => event.type),
          ])}
        />

        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <SelectField
            value={eventDetails.day.toString()}
            label={translation.dayOfWeek}
            handleChange={(e) =>
              setEventDetails((prev) => ({
                ...prev,
                day: Number(e.target.value) as number,
              }))
            }
            selections={[...DAYS]}
            isNumber={true}
          />

          <SelectField
            value={eventDetails.start}
            label={translation.startingTime}
            handleChange={(e) => {
              const updatedStartTimeIndex = TimeSlots.findIndex(
                (slot) => slot === e.target.value
              );

              const updatedIndex =
                updatedStartTimeIndex + (endTimeIndex - startTimeIndex);

              setEventDetails((prev) => ({
                ...prev,
                start: e.target.value,
                end:
                  updatedStartTimeIndex >= endTimeIndex
                    ? TimeSlots[
                        updatedIndex > TimeSlots.length - 1
                          ? TimeSlots.length - 1
                          : updatedIndex
                      ]
                    : prev.end,
              }));
            }}
            selections={TimeSlots.slice(0, -1)}
            is12HrsFormat={true}
          />

          <SelectField
            value={eventDetails.end}
            label={translation.endingTime}
            handleChange={(e) =>
              setEventDetails((prev) => ({
                ...prev,
                end: e.target.value,
              }))
            }
            selections={TimeSlots.slice(startTimeIndex + 1, TimeSlots.length)}
            is12HrsFormat={true}
          />
        </Box>

        <SelectColorField
          label="Pick A Color"
          value={eventDetails.color}
          handleChange={(index: number) =>
            setEventDetails((prev) => ({
              ...prev,
              color: index,
            }))
          }
        />
      </Box>

      <Submit
        event={eventDetails}
        setEvents={setEvents}
        handleClose={handleClose}
        isEdit={isEdit}
      />
    </CustomModal>
  );
};

const AutocompleteField = ({
  value,
  setValue,
  label,
  options,
}: {
  value: string;
  setValue: (value: string) => void;
  label: string;
  options: string[];
}) => (
  <Autocomplete
    freeSolo
    forcePopupIcon={true}
    options={options}
    value={value}
    inputValue={value}
    onInputChange={(event, inputString) => setValue(inputString)}
    renderInput={(params) => <TextField {...params} label={label} />}
    renderOption={(props, option) => (
      <Box component="li" sx={{ ml: 0 }} {...props}>
        {option}
      </Box>
    )}
    ListboxProps={{
      sx: {
        maxHeight: "300px",
      },
    }}
  />
);

const SelectField = ({
  value,
  label,
  handleChange,
  selections,
  isNumber = false,
  is12HrsFormat = false,
}: {
  value: string;
  label: string;
  handleChange: (event: SelectChangeEvent) => void;
  selections: string[];
  isNumber?: boolean;
  is12HrsFormat?: boolean;
}) => (
  <FormControl fullWidth>
    <InputLabel>{label}</InputLabel>

    <Select
      value={value}
      label={label}
      onChange={handleChange}
      MenuProps={{
        sx: {
          maxHeight: "300px",
        },
      }}
    >
      {selections.map((selection, index) => (
        <MenuItem key={index} value={isNumber ? index : selection}>
          {is12HrsFormat ? convert24HrsTo12Hrs(selection) : selection}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const SelectColorField = ({
  value,
  label,
  handleChange,
}: {
  value: number;
  label: string;
  handleChange: (index: number) => void;
}) => {
  const colors = useColors();

  return (
    <Box>
      <InputLabel
        sx={{
          transform: "scale(0.75)",
        }}
      >
        {label}
      </InputLabel>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        {colors.map((color, index) =>
          value === index ? (
            <CheckCircleIcon
              key={index}
              fontSize="large"
              sx={{
                color,
              }}
            />
          ) : (
            <CircleIcon
              key={index}
              fontSize="large"
              sx={{
                color,
                cursor: "pointer",
              }}
              onClick={() => handleChange(index)}
            />
          )
        )}
      </Box>
    </Box>
  );
};

const Submit = ({
  event,
  isEdit = false,
  setEvents,
  handleClose,
}: {
  event: API_Timetable_Event_Info;
  isEdit?: boolean;
  setEvents: React.Dispatch<
    React.SetStateAction<API_Timetable_Event_Info[] | undefined>
  >;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAdd = async () => {
    setIsLoading(true);

    const newEvent = await createTimetableEvent(event);

    if (typeof newEvent !== "string") {
      setEvents((prev) => (prev ? [...prev, newEvent] : [newEvent]));
      openSnack(translation.successAddEvent, true);

      handleClose();
    } else {
      openSnack(translation.failAddEvent, false);
    }

    setIsLoading(false);
  };

  const handleEdit = async () => {
    setIsLoading(true);

    const updatedEvent = await updateTimetableEvent(event);

    if (typeof updatedEvent !== "string") {
      setEvents((prev) =>
        prev?.map((e) => (e.id === updatedEvent.id ? updatedEvent : e))
      );
      openSnack(translation.successUpdateEvent, true);
      handleClose();
    } else {
      openSnack(translation.failUpdateEvent, false);
    }

    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);

    const res = await deleteTimetableEvent(event.id);

    if (res === "Success") {
      setEvents((prev) => prev?.filter((e) => e.id !== event.id));
      openSnack(translation.successDeleteEvent, true);
      handleClose();
    } else {
      openSnack(translation.failDeleteEvent, false);
    }

    setIsLoading(false);
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `${translation.delete} ${event.name}?`,
    warningContext: translation.irreversibleAction,
    handleDelete,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        mt: 4,
      }}
    >
      {isEdit && (
        <>
          <WarningDialog {...warningBody} />

          <Button
            buttonText={translation.delete || "Delete"}
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "red",
              padding: "0",
              marginRight: "auto",
              textAlign: "center",
              fontWeight: "500",
            }}
            onClick={handleOpenDialog}
          />
        </>
      )}

      <Button
        buttonText={translation.cancel}
        arrow={false}
        style={{
          fontSize: "14px",
          backgroundColor: "transparent",
          color: "var(--primary-main)",
          border: "1px solid var(--primary-main)",
          padding: "9px 25px",
          marginRight: "16px",
          marginLeft: "16px",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        onClick={handleClose}
      />

      <Button
        arrow={false}
        disabled={event.name === "" || event.type === "" || isLoading}
        style={{
          fontSize: "14px",
          backgroundColor: "var(--primary-main)",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        buttonText={isEdit ? translation.update : translation.submit}
        onClick={isEdit ? handleEdit : handleAdd}
        isLoading={isLoading}
      />
    </Box>
  );
};

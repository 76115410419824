import background from "images/children-portfolio-v2/LittleLearnerBackground.png";
import learningTitleImage from "images/children-portfolio-v2/learningTitleImage.png";
import Page from "../Page";
import { Typography, Box } from "@mui/material";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";
import Image from "components/Image/Image";

const PageContent = ({
  scale,
  learningStories,
}: {
  scale: number;
  learningStories: {
    fundamental: string;
    milestone: string;
    image: string;
    story: string;
  }[];
}) => {
  return (
    <Page>
      <img
        src={background}
        alt="background"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <Box
        sx={{
          position: "absolute",
          top: `${50 * scale}px`,
          left: 0,
          width: "100%",
          height: "100%",
          paddingRight: `${20 * scale}px`,
          paddingLeft: `${40 * scale}px`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Title Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            gap: 1,
          }}
        >
          <Box>
            <img
              src={learningTitleImage}
              alt="Learning Title"
              style={{ height: "40px", width: "40px", marginBottom: "20px" }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "TheOnlyException",
                fontSize: "32px",
                fontWeight: 400,
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Learning Stories
            </Typography>
          </Box>
        </Box>
        <Box sx={{ height: "100%" }}>
          {learningStories.length == 1 ? (
            <Box
              sx={{
                height: "90%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Image
                  style={{
                    justifySelf: "center",
                    position: "absolute",
                    width: "100%",
                    height: "75%",
                    zIndex: 3,
                    objectFit:"contain"
                  }}
                  src={Frame}
                  alt="frame"
                />
                <Image
                  style={{
                    position: "relative",
                    top: "9.3%",
                    width: "79.5%",
                    height: "43%",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                  src={learningStories[0].image}
                  alt=""
                />
              </Box>
              <Box sx={{ position: "absolute", top: "55%" }}>
                <Box
                  sx={{ backgroundColor: "#F9F6F1", p: "24px", width: "450px" }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "center",
                      fontFamily: "Futura PT",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "22px",
                      letterSpacing: "1.4px",
                      textTransform: "uppercase",
                    }}
                  >
                    {learningStories[0].fundamental}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "center",
                      fontFamily: "Futura PT",
                      // fontSize: `${12 * scale}px`,
                      fotnSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 300,
                      lineHeight: `${20 * scale}px` /* 166.667% */,
                      letterSpacing: `${1.2 * scale}px`,
                    }}
                  >
                    {learningStories[0].milestone}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      position: "absolute",
                      width: "100%",
                      color: "#000",
                      textAlign: "center",
                      // textAlign: "left",
                      fontFamily: "Futura PT",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 300,
                      lineHeight: "20px" /* 166.667% */,
                      letterSpacing: "1.2px",
                      marginTop: "10px",
                    }}
                  >
                    {learningStories[0].story}
                  </Typography>{" "}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                height: "90%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "50px",

              }}
            >
              {learningStories.map((story, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // gap: "20px",
                    // padding: "20px",
                    // mx: '50px',
                    width: "100%",
                    alignItems: "center",
                    flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                  }}

                >
                  <Box
                    sx={{
                      // height:
                      //   index % 2 === 0 ? `${250 * scale}px` : `${230 * scale}px`,
                      // width:
                      //   index % 2 === 0 ? `${300 * scale}px` : `${300 * scale}px`,
                      // height: `${250 * scale}px`,
                      // width: `${300 * scale}px`,
                      height: "233px",
                      width: "280px",
                      position: "relative",
                    }}
                  >
                    <Image
                      style={{
                        position: "absolute",
                        // top: 0,
                        // left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 5,
                        objectFit: "contain",
                      }}
                      // src={index % 2 === 0 ? Frame : VerticalFrame}
                      src={Frame}
                      alt="frame"
                    />
                    <Image
                      style={{
                        position: "absolute",
                        top: "10.8%",
                        left: "11%",
                        width: "79.5%",
                        height: "59.7%",
                        zIndex: 1,
                        objectFit: "cover",
                      }}
                      src={story.image}
                      alt=""
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: `${300 * scale}px`,
                      mr: index % 2 === 0 ? "30px" : "0",
                      ml: index % 2 === 0 ? "0" : "20px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#F9F6F1",
                        p: "24px",
                        width: "100%",
                        mt: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          textAlign: "center",
                          fontFamily: "Futura PT",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "22px",
                          letterSpacing: "1.4px",
                          textTransform: "uppercase",
                        }}
                      >
                        {story.fundamental}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000",
                          textAlign: "center",
                          fontFamily: "Futura PT",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: `${20 * scale}px` /* 166.667% */,
                          letterSpacing: `${1.2 * scale}px`,
                        }}
                      >
                        {story.milestone}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#000",
                        textAlign: "center",
                        fontFamily: "Futura PT",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 300,
                        lineHeight: "20px" /* 166.667% */,
                        letterSpacing: "0.6px",
                        marginTop: "10px",
                        width: "90%",
                      }}
                    >
                      {story.story}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {/* Story Sections */}
      </Box>
    </Page>
  );
};

export default PageContent;


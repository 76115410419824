import { Box } from "@mui/material";

import {
  CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
  CALENDAR_BORDER_BOLD_STYLE,
  CALENDAR_CELL_BACKGROUND_COLOR,
} from "../../../constants/constants";

export default function EmptyCell() {
  return (
    <Box
      sx={{
        height: "68px",
        width: "100%",
        borderRight: CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
        borderBottom: CALENDAR_BORDER_BOLD_STYLE,
        backgroundColor: CALENDAR_CELL_BACKGROUND_COLOR,
      }}
    ></Box>
  );
}

import { Box, Divider, TextField, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import useStore from "store/store";
import useMessageLoad from "./useMessageLoad";
import moment from "moment";
import { translation } from "constants/translation";
import ChatComponent from "./ChatComponent";
import useSnack from "hooks/useSnack";
import { CHAT_MESSAGE_TYPE, SEEN_MESSAGE_TYPE } from "./chatConstants";
import { API_Chat } from "types/chat";
import formatDate from "helper/formatDate";
import { SharedAvatar } from "./ChatComponent";

const MessageBoxes = ({ chatID }: { chatID: string }) => {
  const profileID = localStorage.getItem("profile_id");
  const { allChats, setAllChats, preferred_name, chatSockets } = useStore(
    (state) => ({
      allChats: state.allChats,
      setAllChats: state.setAllChats,
      preferred_name: state.profileDetails.preferred_name,
      chatSockets: state.chatSockets,
    })
  );
  const observer = useRef<IntersectionObserver | null>(null);
  const seenObserver = useRef<IntersectionObserver | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [currSocket, setCurrSocket] = useState<WebSocket>(chatSockets[chatID]);
  const [currChat, setCurrChat] = useState<API_Chat>(
    allChats.filter((chat) => chat.id.toString() === chatID)[0]
  );
  const [moreMsgLoading, isMoreMsg] = useMessageLoad(pageNumber, chatID);
  const messagesEndRef = useRef<HTMLDivElement>();
  const bottomMessage = useRef<HTMLDivElement>();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
        if (bottomMessage.current) {
          bottomMessage.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [currChat]);

  useEffect(() => {
    setPageNumber(1);
    setCurrSocket(chatSockets[chatID]);
    setCurrChat(allChats.filter((chat) => chat.id.toString() === chatID)[0]);
  }, [chatID]);

  useEffect(() => {
    setCurrChat(allChats.filter((chat) => chat.id.toString() === chatID)[0]);
  }, [allChats]);

  // last message = the last message fetched from database. if reach means not enough msg, need to load more. Happens when scroll upwards.
  const lastMessageElement = useCallback(
    (node: HTMLDivElement | null) => {
      if (moreMsgLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (isMoreMsg) {
            setPageNumber((prev) => prev + 1);
          }
          currSocket.send(
            JSON.stringify({
              type: SEEN_MESSAGE_TYPE,
              from: profileID,
              chatID: chatID,
              message: currChat.latest_messages[0].id,
            })
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [moreMsgLoading, isMoreMsg]
  );

  //When it reach the latest message, send to db to update that the user have seen
  const seenMessageElement = useCallback(
    (node: HTMLDivElement | null) => {
      if (seenObserver.current) seenObserver.current.disconnect();
      seenObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          currSocket.send(
            JSON.stringify({
              type: SEEN_MESSAGE_TYPE,
              from: profileID,
              chatID: chatID,
              message: currChat.latest_messages[0].id,
            })
          );
          const updatedChats = allChats;
          const currChatIndex = updatedChats.findIndex(
            (chat) => chat.id.toString() === chatID
          );
          if (updatedChats[currChatIndex]) {
            updatedChats[currChatIndex].unseen_messages_count = 0;
            setAllChats(updatedChats);
          }
        }
      });
      if (node) seenObserver.current.observe(node);
    },
    [currSocket, chatSockets[chatID]]
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {currChat && <ChatComponent chat={currChat} />}
      <Box
        sx={{
          flex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
            gap: 1.5,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflowX: "hidden",
            overflowY: "auto",
            py: 2,
            px: 3,
            "& > *:first-child": {
              mb: "auto",
            },
          }}
        >
          {currChat &&
            currChat.latest_messages.map((msg, index) => {
              const profile = currChat.profiles.find(
                (prof) => prof.id.toString() === msg.author_id.toString()
              );

              const date = formatDate(msg.timestamp, true);
              const prevDate =
                msg.timestamp && index !== currChat.latest_messages.length - 1
                  ? formatDate(
                      currChat.latest_messages[index + 1].timestamp,
                      true
                    )
                  : "";

              const time = moment(msg.timestamp).format("LT");
              const prevTime =
                msg.timestamp && index !== currChat.latest_messages.length - 1
                  ? moment(
                      currChat.latest_messages[index + 1].timestamp
                    ).format("LT")
                  : "";

              const prevAuthor =
                index !== currChat.latest_messages.length - 1
                  ? currChat.latest_messages[index + 1].author
                  : "";

              const visible =
                time !== prevTime ||
                (msg.author.toString() !== prevAuthor && time === prevTime);

              return (
                <Fragment key={index}>
                  <Box
                    sx={{
                      ml: msg.author.toString() === preferred_name ? "auto" : 0,
                      mr: msg.author.toString() !== preferred_name ? "auto" : 0,
                      mt: visible ? 0 : -0.75,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      {msg.author.toString() !== preferred_name && (
                        <SharedAvatar
                          imgSrc={profile?.image ? profile.image : ""}
                          isGroup={false}
                          visible={visible}
                        />
                      )}
                      <Box>
                        {visible && (
                          <Typography
                            sx={{
                              textAlign:
                                msg.author.toString() === preferred_name
                                  ? "right"
                                  : "left",
                              fontSize: 12,
                              mb: 0.25,
                            }}
                          >
                            {time}
                            {msg.author.toString() !== preferred_name &&
                              currChat.profiles.length > 1 &&
                              ` -- ${msg.author.toString()}`}
                          </Typography>
                        )}

                        <Typography
                          ref={
                            currChat.latest_messages.length === index + 1 &&
                            index !== 0
                              ? lastMessageElement
                              : index === 0
                              ? seenMessageElement
                              : null
                          }
                          sx={{
                            width: "fit-content",
                            maxWidth: "700px",
                            ml:
                              msg.author.toString() === preferred_name
                                ? "auto"
                                : 0,
                            mr:
                              msg.author.toString() !== preferred_name
                                ? "auto"
                                : 0,
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            py: 0.75,
                            px: 1.5,
                            backgroundColor:
                              msg.author.toString() === preferred_name
                                ? "rgba(0, 0, 0, 0.1)"
                                : "#D1E4E3",
                            borderRadius:
                              msg.author.toString() === preferred_name
                                ? "10px 10px 0 10px"
                                : "0 10px 10px 10px",
                          }}
                        >
                          {msg.content}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {index === currChat.unseen_messages_count &&
                    currChat.unseen_messages_count !== 0 && (
                      <Box ref={messagesEndRef}>
                        <Divider
                          sx={{
                            fontSize: 14,
                            color: "#312F30",
                          }}
                        >
                          Unread Messages
                        </Divider>
                      </Box>
                    )}

                  {(index === currChat.latest_messages.length - 1 || //if top
                    date !== prevDate) && (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 14,
                        color: "#312F30",
                        opacity: 0.75,
                        mt: 1,
                      }}
                    >
                      {date}
                    </Typography>
                  )}
                </Fragment>
              );
            })}
        </Box>
      </Box>
      <MessageInput chatID={chatID} currSocket={currSocket} />
    </Box>
  );
};

const MessageInput = ({
  chatID,
  currSocket,
}: {
  chatID: string;
  currSocket: WebSocket;
}) => {
  const openSnack = useSnack();

  const profileID = localStorage.getItem("profile_id");
  const { isLoadingChat } = useStore((state) => ({
    isLoadingChat: state.isLoadingChat,
  }));

  const [typedMessage, setTypedMessage] = useState<string>("");

  useEffect(() => {
    setTypedMessage("");
  }, [chatID]);

  const sendMessage = () => {
    const messageToSend = {
      type: CHAT_MESSAGE_TYPE,
      from: profileID,
      chatID: chatID,
      content: typedMessage,
    };
    currSocket.send(JSON.stringify(messageToSend)); //new msgs
    setTypedMessage("");
  };

  const handleSendMessage = () => {
    isLoadingChat
      ? openSnack("Chat is moreMsgLoading... Please try again later", false)
      : typedMessage === ""
      ? openSnack("Cannot send empty text!", false)
      : sendMessage();
  };

  return (
    <TextField
      sx={{
        borderTop: "1px solid #E1E2E9",
        position: { xs: "fixed", sm: "relative"},
        bottom: 0,
        right: 0,
      }}
      fullWidth
      variant="standard"
      placeholder={translation.typeYourMsgHere}
      value={typedMessage}
      onChange={(event) => setTypedMessage(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSendMessage();
        }
      }}
      InputProps={{
        endAdornment: (
          <SendIcon
            sx={{
              color: "#312F30",
              opacity: 0.5,
              ml: 1,
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                opacity: 1,
                color: "primary.main",
              },
            }}
            onClick={handleSendMessage}
          />
        ),
        disableUnderline: true,
        sx: {
          py: 2,
          px: 3,
        },
      }}
      inputProps={{
        style: {
          padding: 0,
        },
      }}
    />
  );
};

export default MessageBoxes;

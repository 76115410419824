import { Fragment, useEffect, useState } from "react";
import { Badge, Box, Divider, Menu, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { NOTICE_PAGE, CURLANG, ANNOUNCEMENTS } from "constants/url";
import useStore from "store/store";
import { API_Announcement } from "types/announcement";
import { getAnnouncementList } from "api/announcement-api";
import { translation } from "constants/translation";
import { TITLE } from "constants/title";
import AnnouncementCard from "containers/WelcomeBackPage/components/ParentDashboardComponents/DashboardAnnouncementCard";

const NotificationButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAnnouncementPage = location.pathname.includes(ANNOUNCEMENTS);

  const { currLanguage, profileDetails } = useStore((state) => state);

  const [announcements, setAnnouncements] = useState<API_Announcement[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const unseenAnnouncementCount = announcements.filter((announcement) =>
    moment(profileDetails.last_seen_announcement_at).isBefore(
      moment(announcement.start_at)
    )
  ).length;

  useEffect(() => {
    if (
      profileDetails.id === -1 ||
      profileDetails.school === undefined ||
      profileDetails.school === null
    )
      return;

    const fetchAnnouncements = async () => {
      const announcements = await getAnnouncementList(
        currLanguage,
        profileDetails.school?.id!
      );

      if (typeof announcements !== "string") {
        setAnnouncements(
          announcements.filter((announcement) =>
            moment().isAfter(moment(announcement.start_at))
          )
        );
      }
    };

    fetchAnnouncements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) =>
    profileDetails.role === "WAD" &&
    (profileDetails.school === undefined || profileDetails.school === null)
      ? handleRedirect()
      : setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleRedirect = () => {
    document.title = `${TITLE} | ${translation.announcements}`;

    navigate(
      `${CURLANG(currLanguage)}/${NOTICE_PAGE(
        profileDetails.school ? profileDetails.school.id.toString() : "-1",
        ANNOUNCEMENTS
      )}`
    );

    handleClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mr: { xs: 0, lg: "10px" },
          cursor: isAnnouncementPage ? "default" : "pointer",
          pointerEvents: isAnnouncementPage ? "none" : "auto",
          alignItems: "center",
          backgroundColor: isAnnouncementPage ? "white" : "#DFDDDD",
          p: 1,
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
        onClick={handleClick}
      >
        <Badge color="info" badgeContent={unseenAnnouncementCount} max={9}>
          <NotificationsNoneIcon fontSize="small" />
        </Badge>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: {
            minWidth: "200px",
            maxWidth: "300px",
            color: "black",
          },
        }}
        MenuListProps={{
          sx: {
            py: 1.5,
            px: 2,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            mb: 0.5,
            cursor: "pointer",
          }}
          onClick={handleRedirect}
        >
          {translation.announcements}
        </Typography>

        {announcements.length !== 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              maxHeight: "400px",
              overflowY: "auto",
              pr: 2,
              mr: -2,
              mt: 1,
              mb: 1,
              cursor: "pointer",
            }}
            onClick={handleRedirect}
          >
            {announcements.slice(0, 10).map((announcement, index) => (
              <Fragment key={index}>
                {index !== 0 && <Divider />}
                <AnnouncementCard
                  announcement={announcement}
                  sx={{
                    gap: 0.5,
                  }}
                  isPreview
                  isParentView={false}
                />
              </Fragment>
            ))}
          </Box>
        ) : (
          <Typography>{translation.noAnnouncementYet}</Typography>
        )}

        {(announcements.length > 0 ||
          profileDetails.role === "WAD" ||
          profileDetails.role === "AD") && (
          <Typography
            sx={{
              alignSelf: "flex-start",
              color: "var(--txt-secondary)",
              cursor: "pointer",
              mt: 1,
            }}
            onClick={handleRedirect}
          >
            {translation.viewAllAnnouncements}
          </Typography>
        )}
      </Menu>
    </>
  );
};

export default NotificationButton;

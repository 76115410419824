/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import DetailCard from "../DetailCard/DetailCard";
import { API_Project } from "types/project";

import useStore from "store/store";
import ProjectModal from "../ProjectModal/ProjectModal";

const ProjectRow = ({
  title,
  projects,
  arrow = true,
  inBag = false,
}: {
  title?: string;
  projects: API_Project[];
  arrow?: boolean;
  inBag?: boolean;
}) => {
  const { currGroup } = useStore((state) => state);

  const [width, setWidth] = useState<number>(0);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [isDown, setIsDown] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<number>(-1);

  const container = useRef<HTMLDivElement>();

  const showArrow = () => {
    scrollLeft > 0 && setShowLeftArrow(true);
    scrollLeft > 0 || setShowLeftArrow(false);
    scrollLeft < width && setShowRightArrow(true);
    scrollLeft < width || setShowRightArrow(false);
  };

  useEffect(() => {
    if (container && container.current) setWidth(container.current.offsetWidth);
  }, []);

  useEffect(() => {
    showArrow();
  }, [width, scrollLeft]);

  const handleScroll = (left = false, scrollSpeed = 600) => {
    if (container && container.current) {
      const scrollX = left ? -scrollSpeed : scrollSpeed;
      container.current.scrollLeft += scrollX;
      setScrollLeft(container.current.scrollLeft + scrollX);
    }
  };

  // for using mouse to drag to scroll row
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (container && container.current) {
      setIsDown(true);
      setStartX(e.pageX - container.current.offsetLeft);
      setScrollLeft(container.current.scrollLeft);
    }
  };

  // for using mouse to drag to scroll row
  const handleMouseUpMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDown(false);
  };

  // for using mouse to drag to scroll row
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDown) return;
    if (container && container.current) {
      const x = e.pageX - container.current.offsetLeft;
      const walk = (x - startX) * 2;
      container.current.scrollLeft = scrollLeft - walk;
    }
  };

  return (
    <Box>
      {title && (
        <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
            {title}
          </Typography>
          {arrow && <ChevronRightIcon />}
        </Box>
      )}
      <Box
        sx={{
          overflowX: { xs: "auto", sm: "hidden" },
          padding: "10px 4px",
          position: "relative",
          userSelect: "none",
        }}
        // disabled because it affects click events on child elements
        // onMouseDown={handleMouseDown}
        // onMouseLeave={handleMouseUpMouseLeave}
        // onMouseUp={handleMouseUpMouseLeave}
        // onMouseMove={handleMouseMove}
      >
        <DetailCardsAll
          projects={projects}
          container={container}
          onScroll={() =>
            container &&
            container.current &&
            setScrollLeft(container.current?.scrollLeft)
          }
          groupID={currGroup.id}
          inBag={inBag}
          onClick={(projectId: number) => {
            setOpen(true);
            setProjectId(projectId);
          }}
        />
        {showLeftArrow && <LeftArrow onClick={() => handleScroll(true)} />}
        {showRightArrow && <RightArrow onClick={() => handleScroll(false)} />}

        {open && projectId !== -1 && (
          <ProjectModal
            open={open}
            setOpen={setOpen}
            projectId={projectId}
            setProjectId={setProjectId}
          />
        )}
      </Box>
    </Box>
  );
};

const DetailCardsAll = ({
  projects,
  container,
  onScroll,
  groupID,
  inBag,
  onClick,
}: {
  projects: API_Project[];
  container: React.MutableRefObject<HTMLDivElement | undefined>;
  onScroll: () => any;
  groupID?: number;
  inBag: boolean;
  onClick: (projectId: number) => void;
}) => {
  const { currGroupBackpack, currGroupFavourite } = useStore((state) => state);

  return (
    <Box
      sx={{
        display: "flex",
        gap: { xs: "1rem", sm: "4rem" },
        flexWrap: "nowrap",
        transition: "all 200ms ease-in-out",
        padding: "10px 90px 10px 10px",
        overflow: "auto",
        overflowX: "scroll",
        scrollBehavior: "smooth",
        scrollbarWidth: "none", // for firefox browsers
        "&::-webkit-scrollbar": {
          display: { xs: "flex", sm: "none" }, // for chromuim browsers
          width: { xs: "0px", sm: "none" },
          height: { xs: "0px", sm: "none" },
        },
      }}
      ref={container}
      onScroll={onScroll}
    >
      {projects.map((proj: API_Project) => {
        return (
          <DetailCard
            project={proj}
            groupID={groupID}
            inBag={inBag}
            key={`${proj.id}-${currGroupFavourite.projects}-${currGroupBackpack.projects}`}
            onClick={() => onClick(proj.id)}
          />
        );
      })}
    </Box>
  );
};

const LeftArrow = ({ onClick }: { onClick: () => any }) => {
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          position: "absolute",
          zIndex: 2,
          left: "0",
          top: "50%",
          background: "#ddd",
          borderRadius: "50%",
          alignItems: "center",
          cursor: "pointer",
          transform: "rotateY(180deg) translateY(-50%)",
          transition: "all 300ms ease-in-out",
          "&:hover": {
            background: "#555",
            transition: "all 300ms ease-in-out",
          },
        }}
        onClick={onClick}
      >
        <ChevronRightIcon fontSize="large" sx={{ color: "#999" }} />
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "150px",
          background: {
            xs: "",
            sm: "linear-gradient(90deg, #FBFBFB 0%, #FBFBFB 34.38%, rgba(255, 255, 255, 0) 100%)",
          },
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 1,
        }}
      />
    </>
  );
};

const RightArrow = ({ onClick }: { onClick: () => any }) => {
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },

          position: "absolute",
          zIndex: 2,
          right: 0,
          top: "50%",
          background: "#ddd",
          borderRadius: "50%",
          alignItems: "center",
          cursor: "pointer",
          transform: "translateY(-50%)",
          transition: "all 300ms ease-in-out",
          "&:hover": {
            background: "#555",
            transition: "all 300ms ease-in-out",
          },
        }}
        onClick={onClick}
      >
        <ChevronRightIcon fontSize="large" sx={{ color: "#999" }} />
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "150px",
          background: {
            xs: "",
            sm: "linear-gradient(270deg, #FBFBFB 0%, #FBFBFB 34.38%, rgba(255, 255, 255, 0) 100%)",
          },
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 1,
        }}
      />
    </>
  );
};

export default ProjectRow;

import { Box, Stack, TextField, Typography, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useStore from "store/store";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { translation } from "constants/translation";

import EditActivityForm from "components/ProjectForms/EditActivityForm";

const EditActivityPage = () => {
  const { projectSlug, lessonID, activityID } = useParams();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const navigate = useNavigate();
  const handleCloseNavigate = () => {
    navigate(
      `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
        projectSlug!
      )}/lesson-plans`
    );
  };
  return (
    <Box sx={{ p: "30px 50px" }}>
      <Title />
      <EditActivityForm
        lessonID={lessonID!}
        activityID={activityID!}
        handleClose={handleCloseNavigate}
      />
    </Box>
  );
};

const Title = () => {
  const { projectSlug, lessonID, activityID } = useParams();
  const currActivity = useStore((state) => state.currActivity);

  return (
    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
        mb: "20px",
      }}
    >
      {activityID
        ? currActivity.weight !== 0 && currActivity.name !== ""
          ? `${translation.editing_activity} ${currActivity.weight}: ${currActivity.name}`
          : "Editing Activity"
        : `${translation.creating_activity}`}
    </Typography>
  );
};

// const ActivityForm = () => {
//   const { projectSlug, lessonID, activityID } = useParams();

//   const navigate = useNavigate();
//   const openSnack = useSnack();

//   if (activityID) {
//     const editing = true;
//   } else {
//     const editing = false;
//   }

//   const [files, setFiles] = useState<File[]>([]);
//   const [delFiles, setDelFiles] = useState<boolean[]>([]);
//   const [galleryID, setGalleryID] = useState<string>("");
//   const [captions, setCaptions] = useState<API_Caption[]>([]);
//   const [currCaptions, setCurrCaptions] = useState<API_Caption[]>([]);
//   const [delCaption, setDelCaption] = useState<boolean[]>([]);
//   const [filledRequired, setFilledRequired] = useState<boolean>(false);
//   const [isLoading, setIsLoading] = useState<boolean>(false);

//   const {
//     currGroup,
//     projectID,
//     currLanguage,
//     currActivity,
//     setCurrActivity,
//     setRefreshProjAndLess,
//     setSnackMessage,
//     setSnackSuccess,
//     setSnackOpen,
//     bringOverActivity,
//   } = useStore((state) => ({
//     currGroup: state.currGroup,
//     projectID: state.currentProject.id.toString(),
//     currLanguage: state.currLanguage,
//     currActivity: state.currActivity,
//     setCurrActivity: state.setCurrActivity,
//     setRefreshProjAndLess: state.setRefreshProjAndLess,
//     setSnackMessage: state.setSnackMessage,
//     setSnackSuccess: state.setSnackSuccess,
//     setSnackOpen: state.setSnackOpen,
//     bringOverActivity: state.bringOverActivity,
//   }));

//   const [activity, setActivity] = useState<API_Create_Activity>({
//     lesson: lessonID!,
//     files: [],
//     name: "",
//     description: "",
//     duration: 0,
//     instruction: "",
//     instructions: [],
//     remark: "",
//     is_compulsory: true,
//   });

//   const activityRef = useRef(activity);
//   activityRef.current = activity;

//   useEffect(() => {
//     const initialiseActivity = async () => {
//       if (showChanges) return;
//       if (activityID) {
//         setIsLoading(true);
//         const act = await getActivityByID(currLanguage, activityID);

//         if (typeof act === "string") {
//           setSnackMessage(act);
//           setSnackSuccess(false);
//           setSnackOpen(true);
//           setIsLoading(false);
//         } else {
//           setCurrActivity(act);
//           setIsLoading(false);
//         }
//       }
//     };

//     initialiseActivity();
//   }, [currActivity.id, activityID, currLanguage]);

//   useEffect(() => {
//     const initialiseCaptions = async () => {
//       if (activityID) {
//         const galleryData: API_Gallery_Create = {
//           activity: activityID,
//         };

//         const gallery = await createGallery(currLanguage, galleryData);

//         if (typeof gallery === "string") {
//           setSnackMessage("Failed to load captions!");
//           setSnackSuccess(false);
//           setSnackOpen(true);
//         } else {
//           setGalleryID(gallery.id.toString());
//           setCaptions(gallery.captions);
//           setCurrCaptions(JSON.parse(JSON.stringify(gallery.captions)));

//           const newDelCaption: boolean[] = Array(gallery.captions.length).fill(
//             false
//           );
//           setDelCaption(newDelCaption);
//         }
//       }
//     };

//     initialiseCaptions();
//   }, [currActivity.id, activityID, currLanguage]);

//   useEffect(() => {
//     // console.log(bringOverActivity);
//     setActivity({
//       lesson: lessonID!,
//       files: [],
//       name: "",
//       description: "",
//       duration: 0,
//       instruction: "",
//       remark: "",
//       is_compulsory: true,
//     });
//     setActivity({
//       lesson: currActivity.lesson.toString(),
//       files: [],
//       name: bringOverActivity.name ? bringOverActivity.name : currActivity.name,
//       description: bringOverActivity.description
//         ? bringOverActivity.description
//         : currActivity.description,
//       duration: bringOverActivity.duration
//         ? bringOverActivity.duration
//         : currActivity.duration,
//       instruction: bringOverActivity.instruction
//         ? bringOverActivity.instruction
//         : currActivity.instruction,
//       remark: bringOverActivity.remark
//         ? bringOverActivity.remark
//         : currActivity.remark,
//       is_compulsory:
//         bringOverActivity.is_compulsory !== undefined
//           ? bringOverActivity.is_compulsory
//           : currActivity.is_compulsory,
//     });
//   }, [currActivity, currLanguage]);

//   useEffect(() => {
//     const isCaptionsFilled =
//       captions.length !== 0
//         ? !captions.some((cap) => cap.caption === "")
//         : true;

//     if (
//       activity.name !== "" &&
//       activity.instruction !== "" &&
//       isCaptionsFilled
//     ) {
//       setFilledRequired(true);
//     } else {
//       setFilledRequired(false);
//     }
//   }, [activity, captions]);

//   useEffect(() => {
//     const isCaptionsFilled =
//       captions.length !== 0
//         ? !captions.some((cap) => cap.caption === "")
//         : true;

//     if (
//       activity.name !== "" &&
//       activity.instruction !== "" &&
//       isCaptionsFilled
//     ) {
//       setFilledRequired(true);
//     } else {
//       setFilledRequired(false);
//     }
//   }, [activity, captions]);

//   const submitEditForm = async (isNavigate: boolean = true) => {
//     let editFailed = false;
//     const activityData: API_Edit_Activity = {};

//     if (activity.name !== currActivity.name) activityData.name = activity.name;
//     if (activity.lesson !== currActivity.lesson.toString())
//       activityData.lesson = activity.lesson;
//     if (activity.description !== currActivity.description)
//       activityData.description = activity.description;
//     if (activity.duration !== currActivity.duration)
//       activityData.duration = activity.duration;
//     if (activity.instruction !== currActivity.instruction)
//       activityData.instruction = activity.instruction;
//     if (activity.remark !== currActivity.remark)
//       activityData.remark = activity.remark;
//     if (activity.is_compulsory !== currActivity.is_compulsory)
//       activityData.is_compulsory = activity.is_compulsory;

//     if (files.length > 0) {
//       const res = await addActivityFileWithForm(
//         currLanguage,
//         currActivity.id.toString(),
//         files,
//         localStorage.getItem("access")
//       );
//     }

//     for (let i = 0; i < delFiles.length; i++) {
//       if (delFiles[i]) {
//         const res = await deleteActivityFile(currActivity.files[i].id);
//         if (res !== "success") openSnack(res, false);
//       }
//     }

//     // update existing captions
//     for (let i = 0; i < currCaptions.length; i++) {
//       if (captions[i].caption !== currCaptions[i].caption) {
//         await editCaption(
//           currLanguage,
//           captions[i].caption,
//           currCaptions[i].id.toString()
//         );
//       }
//     }

//     // add new captions
//     for (let i = currCaptions.length; i < captions.length; i++) {
//       await createCaption(currLanguage, captions[i].caption, galleryID);
//     }

//     // delete captions
//     for (let i = 0; i < delCaption.length; i++) {
//       if (delCaption[i]) {
//         await deleteCaption(currLanguage, currCaptions[i].id.toString());
//       }
//     }

//     if (Object.keys(activityData).length > 0) {
//       const res = await editActivity(
//         currLanguage,
//         currActivity.id.toString(),
//         activityData,
//         localStorage.getItem("access")
//       );
//       if (typeof res === "string") {
//         openSnack(`${translation.fail_update_lesson}: ${res}`, false);
//         editFailed = true;
//       }
//     }

//     if (!editFailed) {
//       setRefreshProjAndLess(true);
//       openSnack("Updated activity successfully!", true);
//       if (isNavigate) {
//         navigate(
//           `${CURLANG(currLanguage)}/${EDIT_LESSON_PAGE(
//             projectSlug!,
//             lessonID!
//           )}`
//         );
//       }
//     }
//   };

//   const submitForm = async () => {
//     const submitData: API_Create_Activity = {
//       lesson: lessonID!,
//       files: [],
//       name: activity.name,
//       description: activity.description,
//       duration: activity.duration,
//       instruction: activity.instruction,
//       remark: activity.remark,
//       is_compulsory: activity.is_compulsory,
//     };

//     if (files.length > 0) {
//       submitData.files = files;
//     }

//     // console.log(submitData);

//     const res = await createActivity(
//       currLanguage,
//       submitData,
//       localStorage.getItem("access")
//     );

//     openSnack("Activity created successfully!", true);
//     navigate(
//       `${CURLANG(currLanguage)}/${EDIT_LESSON_PAGE(projectSlug!, lessonID!)}`
//     );
//   };

//   const [showDialog, setShowDialog] = useState<boolean>(false);
//   const [keptData, setKeptData] = useState<API_Edit_Activity>({});
//   const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
//     useCallbackPrompt(showDialog);

//   // console.log("show Prompt:" + showPrompt);
//   // console.log("show changes: " + showChanges);
//   // console.log(keptData);

//   const dialogBody = {
//     showDialog: showPrompt,
//     confirmNavigation,
//     cancelNavigation,
//     handleAction: submitEditForm,
//   };

//   const changesDialogBody = {
//     showChangesDialog: showChanges,
//     confirmNavigation,
//     cancelNavigation,
//     project: {},
//     lesson: {},
//     activity: keptData,
//   };

//   return activity.name === undefined || isLoading ? (
//     <LoadingIndicator />
//   ) : (
//     <>
//       <BlockLeavingDialog {...dialogBody} />
//       <KeepChangesDialog {...changesDialogBody} />
//       <Stack
//         component="form"
//         sx={{
//           width: "80vw",
//           margin: "auto",
//         }}
//         spacing={2}
//         noValidate
//         autoComplete="off"
//       >
//         <TextField
//           label={translation.activity_name}
//           value={activity.name}
//           onChange={(event) => {
//             setActivity((prev) => ({ ...prev, name: event.target.value }));
//             setShowDialog(true);
//             setKeptData((prev) => ({ ...prev, name: event.target.value }));
//           }}
//           required
//         />
//         <TextField
//           label={translation.expected_duration_mins}
//           value={activity.duration}
//           onChange={(event) => {
//             let currVal = Number(event.target.value);
//             if (Number.isNaN(currVal)) {
//               currVal = activity.duration || 0;
//             } else if (currVal < 0) {
//               currVal = 0;
//             }
//             setActivity((prev) => ({ ...prev, duration: currVal }));
//             setShowDialog(true);
//             setKeptData((prev) => ({ ...prev, duration: currVal }));
//           }}
//         />

//         {/* <div id={'description'}>
//           <Typography sx={{ mb: '8px' }}>Description</Typography>
//           <CKEditor
//             id={'description'}
//             key={'description'}
//             editor={ClassicEditor}
//             data={activity.description}
//             onReady={(editor: any) => {
//               UploadAdapterPlugin(editor)
//             }}
//             onChange={(event: any, editor: any) => {
//               const data: string = editor.getData()
//               const updatedActivity = activity
//               updatedActivity.description = data
//               setActivity(updatedActivity)
//             }}
//           />
//         </div> */}

//         <div id={"instruction"}>
//           <Typography sx={{ mb: "8px" }}>
//             {translation.instructions}
//             <Typography component="span" sx={{ color: "red", ml: "2px" }}>
//               *
//             </Typography>
//           </Typography>
//           <RichCKEditor
//             data={activity.instruction}
//             onChange={(event: any, editor: any) => {
//               const data: string = editor.getData();
//               setActivity((prev) => ({ ...prev, instruction: data }));
//               setShowDialog(true);
//               setKeptData((prev) => ({ ...prev, instruction: data }));
//             }}
//           />
//         </div>

//         {activityID && (
//           <PhotoCaptionsField
//             captions={captions}
//             setCaptions={setCaptions}
//             delCaption={delCaption}
//             setDelCaption={setDelCaption}
//           />
//         )}

//         <Typography sx={{ mb: "8px" }}>Compulsory</Typography>
//         {activity.is_compulsory ? (
//           <CheckBoxIcon
//             sx={{ cursor: "pointer", color: "txt.secondary" }}
//             onClick={() => {
//               setActivity((prev) => ({
//                 ...prev,
//                 is_compulsory: !prev.is_compulsory,
//               }));
//               setShowDialog(true);
//               setKeptData((prev) => ({ ...prev, is_compulsory: false }));
//             }}
//           />
//         ) : (
//           <CheckBoxOutlineBlankIcon
//             sx={{ cursor: "pointer", color: "txt.secondary" }}
//             onClick={() => {
//               setActivity((prev) => ({
//                 ...prev,
//                 is_compulsory: !prev.is_compulsory,
//               }));
//               setShowDialog(true);
//               setKeptData((prev) => ({ ...prev, is_compulsory: true }));
//             }}
//           />
//         )}

//         <FileForm
//           files={files}
//           setFiles={setFiles}
//           title={translation.attachment_if_any}
//           key={`file-set`}
//           oldFiles={activityID ? currActivity : undefined}
//           delFiles={delFiles}
//           setDelFiles={setDelFiles}
//           multiple
//         />

//         <div id={"remark"}>
//           <Typography sx={{ mb: "8px" }}>{translation.remarks}</Typography>
//           <RichCKEditor
//             data={activity.remark}
//             onChange={(event: any, editor: any) => {
//               const data: string = editor.getData();
//               setActivity((prev) => ({ ...prev, remark: data }));
//               setShowDialog(true);
//               setKeptData((prev) => ({ ...prev, remark: data }));
//             }}
//           />
//         </div>

//         <Box
//           sx={{
//             display: "flex",
//             gap: "24px",
//             position: "fixed",
//             bottom: 30,
//             right: 40,
//           }}
//         >
//           <Button
//             buttonText="Back"
//             arrow={false}
//             style={{
//               fontSize: "14px",
//               backgroundColor: "var(--txt-secondary)",
//               height: "auto",
//             }}
//             onClick={() =>
//               navigate(
//                 `${CURLANG(currLanguage)}/${EDIT_LESSON_PAGE(
//                   projectSlug!,
//                   lessonID!
//                 )}`
//               )
//             }
//           />

//           <Button
//             buttonText={activityID ? translation.update : translation.create}
//             style={{
//               fontSize: "14px",
//               backgroundColor: "var(--txt-secondary)",
//               height: "auto",
//             }}
//             onClick={() => {
//               activityID
//                 ? filledRequired && submitEditForm()
//                 : filledRequired && submitForm();
//             }}
//             disabled={!filledRequired}
//           />
//         </Box>
//       </Stack>
//     </>
//   );
// };

// const PhotoCaptionsField = ({
//   captions,
//   setCaptions,
//   delCaption,
//   setDelCaption,
// }: {
//   captions: API_Caption[];
//   setCaptions: React.Dispatch<React.SetStateAction<API_Caption[]>>;
//   delCaption: boolean[];
//   setDelCaption: React.Dispatch<React.SetStateAction<boolean[]>>;
// }) => {
//   return (
//     <Box>
//       <SubtitleWithAddIcon
//         subtitle="Photo Captions"
//         handleAdd={() => {
//           const updatedCaptions = [...captions];
//           updatedCaptions.push({ id: -1, caption: "", gallery: -1 });
//           setCaptions(updatedCaptions);
//         }}
//       />
//       {captions.map((caption, index) => {
//         const isDelete =
//           index < delCaption.length && delCaption[index] ? true : false;

//         return (
//           <Box
//             sx={{
//               display: "flex",
//               gap: "16px",
//               my: "12px",
//               mr: "12px",
//               alignItems: "center",
//             }}
//             key={index}
//           >
//             <TextField
//               label="Caption"
//               required
//               value={caption.caption}
//               onChange={(event) => {
//                 const updatedCaptions = [...captions];
//                 updatedCaptions[index].caption = event.target.value;
//                 setCaptions(updatedCaptions);
//               }}
//               key={`caption-${index}`}
//               sx={{
//                 width: "100%",
//                 textDecoration: isDelete ? "line-through" : "none",
//               }}
//               disabled={isDelete}
//             />

//             {isDelete && (
//               <Box
//                 sx={{
//                   backgroundColor: "#fc6f6fb3",
//                   borderRadius: "10px",
//                   p: "5px 10px",
//                   fontSize: "12px",
//                   userSelect: "none",
//                 }}
//               >
//                 {translation.delete}
//               </Box>
//             )}

//             <ClearIcon
//               sx={{
//                 color: "red",
//                 cursor: "pointer",
//                 transition: "all 100ms linear",
//                 "&:hover": {
//                   color: "darkred",
//                   transition: "all 100ms linear",
//                 },
//               }}
//               onClick={() => {
//                 if (index < delCaption.length) {
//                   const newDelCaption = JSON.parse(JSON.stringify(delCaption));
//                   newDelCaption[index] = !newDelCaption[index];
//                   setDelCaption(newDelCaption);
//                 } else {
//                   const updatedCaptions = [...captions];
//                   updatedCaptions.splice(index, 1);
//                   setCaptions(updatedCaptions);
//                 }
//               }}
//             />
//           </Box>
//         );
//       })}
//     </Box>
//   );
// };

// const SubtitleWithAddIcon = ({
//   subtitle,
//   handleAdd,
// }: {
//   subtitle: string;
//   handleAdd: () => void;
// }) => {
//   return (
//     <Box sx={{ display: "flex", alignItems: "center", mb: "8px", gap: "10px" }}>
//       <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>
//         {subtitle}
//       </Typography>
//       <AddIcon
//         sx={{
//           backgroundColor: "primary.light",
//           borderRadius: "4px",
//           p: "2px",
//           cursor: "pointer",
//           color: "txt.secondary",
//           transition: "all 100ms linear",
//           "&:hover": {
//             backgroundColor: "primary.main",
//             transition: "all 100ms linear",
//           },
//         }}
//         onClick={handleAdd}
//       />
//     </Box>
//   );
// };

// const FileForm = ({
//   files,
//   setFiles,
//   title,
//   multiple = false,
//   oldFiles,
//   delFiles,
//   setDelFiles,
// }: {
//   files: File[];
//   setFiles: React.Dispatch<React.SetStateAction<File[]>>;
//   title: string;
//   multiple?: boolean;
//   oldFiles?: API_Project_Lesson_Activity;
//   delFiles: boolean[];
//   setDelFiles: React.Dispatch<React.SetStateAction<boolean[]>>;
// }) => {
//   return (
//     <Box>
//       <Typography sx={{ fontWeight: "500", fontSize: "18px", mb: "8px" }}>
//         {title}
//       </Typography>
//       <input
//         type="file"
//         id={`image-button-${title}`}
//         multiple={multiple}
//         hidden
//         onChange={(event) => {
//           const newImages: File[] = Array.prototype.slice.call(
//             event.target.files
//           );
//           setFiles((prev: File[]) => [...prev, ...newImages]);
//         }}
//       />
//       <label htmlFor={`image-button-${title}`}>
//         <IconButton
//           component="span"
//           sx={{
//             borderRadius: "8px",
//             backgroundColor: "primary.light",
//             "&:hover": {
//               backgroundColor: "primary.main",
//             },
//           }}
//         >
//           <ImageIcon sx={{ color: "txt.secondary" }} />
//         </IconButton>
//       </label>
//       {oldFiles && (
//         <Box
//           sx={{ display: "flex", gap: "10px", flexWrap: "wrap", my: "10px" }}
//         >
//           {oldFiles.files.map((img, index) => {
//             return (
//               <Box
//                 sx={{
//                   width: "100px",
//                   borderRadius: "10px",
//                   overflow: "hidden",
//                   position: "relative",
//                   border: `${delFiles[index] ? "2px solid red" : ""}`,
//                 }}
//                 key={Math.random()}
//               >
//                 {delFiles[index] && (
//                   <Box
//                     sx={{
//                       position: "absolute",
//                       backgroundColor: "#fc6f6fb3",
//                       width: "100%",
//                       height: "100%",
//                       left: 0,
//                       top: 0,
//                       zIndex: 1,
//                     }}
//                   />
//                 )}
//                 <a href={img.file}> {img.name} </a>
//                 <ClearIcon
//                   sx={{
//                     position: "absolute",
//                     top: 5,
//                     right: 5,
//                     zIndex: 3,
//                     color: "txt.secondary",
//                     cursor: "pointer",
//                     backgroundColor: "primary.light",
//                     borderRadius: "50%",
//                     p: "3px",
//                     transition: "all 100ms linear",
//                     "&:hover": {
//                       p: "4px",
//                       backgroundColor: "primary.main",
//                       transition: "all 100ms linear",
//                     },
//                   }}
//                   onClick={() => {
//                     setDelFiles((prev) => {
//                       const temp = JSON.parse(JSON.stringify(prev));
//                       temp[index] = !temp[index];
//                       return temp;
//                     });
//                   }}
//                 />
//               </Box>
//             );
//           })}
//         </Box>
//       )}
//       <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap", my: "10px" }}>
//         {files.map((img, index) => {
//           return (
//             <Box
//               sx={{
//                 width: "100px",
//                 height: "100px",
//                 borderRadius: "10px",
//                 overflow: "hidden",
//                 position: "relative",
//               }}
//               key={`${img.name}-index`}
//             >
//               <Image src={URL.createObjectURL(img)} alt={img.name} />
//               <ClearIcon
//                 sx={{
//                   position: "absolute",
//                   top: 5,
//                   right: 5,
//                   color: "txt.secondary",
//                   cursor: "pointer",
//                   backgroundColor: "primary.light",
//                   borderRadius: "50%",
//                   p: "3px",
//                   transition: "all 100ms linear",
//                   "&:hover": {
//                     p: "4px",
//                     backgroundColor: "primary.main",
//                     transition: "all 100ms linear",
//                   },
//                 }}
//                 onClick={() => {
//                   setFiles((prev) => {
//                     const temp = [...prev];
//                     temp.splice(index, 1);
//                     return temp;
//                   });
//                 }}
//               />
//             </Box>
//           );
//         })}
//       </Box>
//     </Box>
//   );
// };

export default EditActivityPage;

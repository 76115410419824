export const MODULE_CARD_HEIGHT = 387;
export const MODULE_CARD_WIDTH = 308;

export const RESOURCE_TYPE_TEXT = "TextActivity";
export const RESOURCE_TYPE_VIDEO = "VideoActivity";
export const RESOURCE_TYPE_FLIP_CARD = "FlipCardActivity";
export const RESOURCE_TYPE_DRAG_AND_DROP = "DragAndDropActivity";
export const RESOURCE_TYPE_MCQ = "McqActivity";
export const RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY =
  "InteractiveDataEntryActivity";
export const RESOURCE_TYPE_PICTORIAL_OBSERVATION =
  "PictorialObservationActivity";

export const RESOURCE_TYPE_LIST = [
  RESOURCE_TYPE_TEXT,
  RESOURCE_TYPE_VIDEO,
  RESOURCE_TYPE_FLIP_CARD,
  RESOURCE_TYPE_DRAG_AND_DROP,
  RESOURCE_TYPE_MCQ,
  RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY,
  RESOURCE_TYPE_PICTORIAL_OBSERVATION,
];

export const RESOURCE_TYPE_LIST_MAPPING: { [role: string]: string } = {
  [RESOURCE_TYPE_TEXT]: "Readings",
  [RESOURCE_TYPE_VIDEO]: "Video Introduction",
  [RESOURCE_TYPE_FLIP_CARD]: "Flash Cards",
  [RESOURCE_TYPE_DRAG_AND_DROP]: "Drag And Drop",
  [RESOURCE_TYPE_MCQ]: "Multiple Choice Question",
  [RESOURCE_TYPE_INTERACTIVE_DATA_ENTRY]: "Interactive Data Entry",
  [RESOURCE_TYPE_PICTORIAL_OBSERVATION]: "Pictorial Observation",
};

export const JOB_STATUS_DONE_LIST = ["DONE", "MISSING", "STOPPED"];

import React from "react";
import {
  Box,
  TextField,
} from "@mui/material";

import {
  API_Create_Lesson,
} from "types/project";
import { translation } from "constants/translation";

const IntentionField = ({
  label,
  lesson,
  setLesson,
  onChange,
}: {
  label?: string;
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <TextField
      fullWidth
      label={label ?? translation.lesson_intention}
      value={lesson.intention}
      onChange={(event) => {
        setLesson((prev) => ({ ...prev, intention: event.target.value }));
        onChange && onChange(event.target.value);
      }}
    />
  </Box>
);

export default IntentionField;
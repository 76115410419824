import { RefObject } from "react";
import {
  Editor,
  EditorState,
  DefaultDraftBlockRenderMap,
  EditorBlock,
  ContentBlock,
  ContentState,
  DraftHandleValue,
  RichUtils,
} from "draft-js";
import { Map } from "immutable";
import { Box, SxProps } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import "draft-js/dist/Draft.css";
import { translation } from "constants/translation";

const TODO_TYPE = "todo";

const getDefaultBlockData = (blockType: string, initialData = {}) => {
  switch (blockType) {
    case TODO_TYPE:
      return { checked: false };
    default:
      return initialData;
  }
};

const resetBlockType = (editorState: EditorState, newType = "unstyled") => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const key = selectionState.getStartKey();
  const blockMap = contentState.getBlockMap();
  const block = blockMap.get(key);
  let newText = "";
  const text = block.getText();
  if (block.getLength() >= 2) {
    newText = text.substr(1);
  }
  const newBlock = block.merge({
    text: newText,
    type: newType,
    data: getDefaultBlockData(newType),
  });
  const newContentState = contentState.merge({
    blockMap: blockMap.set(key, newBlock as ContentBlock),
    selectionAfter: selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
    }),
  });
  return EditorState.push(
    editorState,
    newContentState as ContentState,
    "change-block-type"
  );
};

const updateDataOfBlock = (
  editorState: EditorState,
  block: ContentBlock,
  newData: any
) => {
  const contentState = editorState.getCurrentContent();
  const newBlock = block.merge({
    data: newData,
  });
  const newContentState = contentState.merge({
    blockMap: contentState
      .getBlockMap()
      .set(block.getKey(), newBlock as ContentBlock),
  });
  return EditorState.push(
    editorState,
    newContentState as ContentState,
    "change-block-data"
  );
};

const TodoBlock = (props: {
  block: ContentBlock;
  blockProps: {
    getEditorState: () => EditorState;
    onChange: (editorState: EditorState, isCheckbox: boolean) => void;
  };
}) => {
  const { block, blockProps } = props;
  const { onChange, getEditorState } = blockProps;
  const data = block.getData();
  const checked = data.has("checked") && data.get("checked") === true;

  const updateData = () => {
    const newData = data.set("checked", !checked);
    onChange(updateDataOfBlock(getEditorState(), block, newData), true);
  };

  const iconStyles: SxProps = {
    cursor: "pointer",
    opacity: 0.5,
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 0.75,
        py: 0.25,
        textDecoration: checked ? "line-through" : "none",
      }}
    >
      {checked ? (
        <CheckBoxIcon fontSize="small" sx={iconStyles} onClick={updateData} />
      ) : (
        <CheckBoxOutlineBlankIcon
          fontSize="small"
          sx={iconStyles}
          onClick={updateData}
        />
      )}
      <EditorBlock {...props} />
    </Box>
  );
};

const getBlockRendererFn =
  (
    getEditorState: () => EditorState,
    onChange: (editorState: EditorState, isCheckbox: boolean) => void
  ) =>
  (block: ContentBlock) => {
    const type = block.getType();

    switch (type) {
      case TODO_TYPE:
        return {
          component: TodoBlock,
          props: {
            onChange,
            getEditorState,
          },
        };
      default:
        return null;
    }
  };

const CustomEditor = ({
  editorRef,
  editorState,
  setEditorState,
  onBlur,
}: {
  editorRef: RefObject<Editor>;
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  onBlur: (editorState: EditorState) => void;
}) => {
  const blockRenderMap = Map({
    [TODO_TYPE]: {
      element: "div",
    },
  }).merge(DefaultDraftBlockRenderMap);

  const onChange = (newEditorState: EditorState, isCheckbox = false) =>
    isCheckbox ? onBlur(newEditorState) : setEditorState(newEditorState);

  const getEditorState = () => editorState;

  const blockRendererFn = getBlockRendererFn(getEditorState, onChange);

  const blockStyleFn = (block: ContentBlock) => {
    const type = block.getType();

    switch (type) {
      case TODO_TYPE:
        return "block block-todo";
      default:
        return "block";
    }
  };

  const handleBeforeInput: (str: string) => DraftHandleValue = (
    str: string
  ) => {
    if (str !== "]") {
      return "not-handled";
    }
    const selection = editorState.getSelection();
    const currentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());
    const blockType = currentBlock.getType();
    const blockLength = currentBlock.getLength();
    if (blockLength === 1 && currentBlock.getText() === "[") {
      onChange(
        resetBlockType(
          editorState,
          blockType !== TODO_TYPE ? TODO_TYPE : "unstyled"
        )
      );
      return "handled";
    }
    return "not-handled";
  };

  const handleKeyCommand: (str: string) => DraftHandleValue = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  return (
    <Editor
      ref={editorRef}
      autoComplete="off"
      spellCheck={false}
      placeholder={translation.typeYourNotesHere}
      editorState={editorState}
      onChange={onChange}
      blockRenderMap={blockRenderMap}
      blockRendererFn={blockRendererFn}
      blockStyleFn={blockStyleFn}
      handleBeforeInput={handleBeforeInput}
      handleKeyCommand={handleKeyCommand}
      onBlur={() => onBlur(editorState)}
    />
  );
};

export default CustomEditor;

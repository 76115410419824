import { Box, SxProps, Typography } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useStore from "store/store";
import { translation } from "constants/translation";
import { NothingMessage } from "containers/WelcomeBackPage/components/TeacherDashboard";
import ChildCard from "./ChildCard";

const MyChildrenOverviewPage = () => {
  const { childrenInSession } = useStore((state) => ({
    childrenInSession: state.childrenInSession,
  }));

  return childrenInSession.length === 0 ? (
    <Box sx={containerStyles}>
      <LoadingIndicator />
    </Box>
  ) : (
    <Box
      sx={{
        ...containerStyles,
        my: 5,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "1.5rem",
          mb: 2,
        }}
      >
        {translation.myChildren}
      </Typography>

      {childrenInSession.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: 4,
          }}
        >
          {childrenInSession.map((child, index) => (
            <ChildCard key={index} child={child} />
          ))}
        </Box>
      ) : (
        <NothingMessage message={translation.noChild} />
      )}
    </Box>
  );
};

export const containerStyles: SxProps = {
  px: 3,
  py: 4,
  mx: {
    lg: "60px",
  },
};

export default MyChildrenOverviewPage;

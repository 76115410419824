import { Box, Stack, Divider } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
import useDialog from "hooks/useDialog";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Button from "components/Button/Button";

//Prompt user when leaving without saving

import useCallbackPrompt from "hooks/useCallbackPrompt";
import { translation } from "constants/translation";

import {
  NameField,
  DescriptionField,
  ImageField,
  DiscussionTopicField,
} from "../TeacherFormComponents/LessonFormComponents";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { useNavigate, useParams } from "react-router-dom";
import BlockLeavingDialog from "components/Dialog/BlockLeavingDialog";
import KeepChangesDialog from "components/Dialog/KeepChangesDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import {
  deleteTeacherLesson,
  editTeacherLesson,
  getLessonById,
} from "api/teacher-api";
import { API_Edit_Teacher_Lesson } from "types/teacher";

//used in drawer, latest
const EditLessonForm = ({
  lessonId,
  handleClose,
  isEditLessonPage = false,
}: {
  lessonId: number;
  handleClose: () => void;
  isEditLessonPage?: boolean;
}) => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { projectSlug } = useParams();

  // const [isFilled, setIsFilled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    currLanguage,
    teacherCurrentLesson,
    setTeacherCurrentLesson,
    setIsRefreshTeacherModule,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    teacherCurrentLesson: state.teacherCurrentLesson,
    setTeacherCurrentLesson: state.setTeacherCurrentLesson,
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));

  const [image, setImage] = useState<File | string>("");
  const [updatedLessonInfo, setUpdatedLessonInfo] =
    useState<API_Edit_Teacher_Lesson>({
      id: -1,
    });

  useEffect(() => {
    const intialiseLesson = async () => {
      if (showChanges) return;
      setIsLoading(true);
      const lessonRes = await getLessonById(currLanguage, lessonId);
      if (typeof lessonRes === "string") {
        openSnack(lessonRes, false);
      } else {
        setTeacherCurrentLesson(lessonRes);
        setUpdatedLessonInfo(lessonRes);
        setImage(lessonRes.image);
      }
      setIsLoading(false);
    };

    intialiseLesson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  const lessonRef = useRef(updatedLessonInfo);
  lessonRef.current = updatedLessonInfo;

  const submitEditForm = async () => {
    let editFailed = false;

    if (updatedLessonInfo.name === "") {
      //filled
      openSnack("Lesson name is required!", false);
      return;
    }

    const lessonData: API_Edit_Teacher_Lesson = { id: teacherCurrentLesson.id };

    if (updatedLessonInfo.name !== teacherCurrentLesson.name)
      lessonData.name = updatedLessonInfo.name;
    if (updatedLessonInfo.description !== teacherCurrentLesson.description)
      lessonData.description = updatedLessonInfo.description;
    if (
      updatedLessonInfo.discussion_topic !==
      teacherCurrentLesson.discussion_topic
    )
      lessonData.discussion_topic = updatedLessonInfo.discussion_topic;
    if (image !== teacherCurrentLesson.image) lessonData.image = image;
    // console.log("updatedLessonInfo: ", updatedLessonInfo);
    // console.log("teacherCurrentLesson: ", teacherCurrentLesson);
    // console.log("lessonData: ", lessonData);
    if (Object.keys(lessonData).length > 0) {
      const res = await editTeacherLesson({
        language: currLanguage,
        teacherLessonData: lessonData,
      });
      if (typeof res === "string") {
        openSnack(`${translation.fail_update_lesson}: ${res}`, false);
        editFailed = true;
      }
    }

    if (!editFailed) {
      setIsRefreshTeacherModule(true);
      openSnack(
        translation.success_update_lesson || "Lesson Updated Successfully",
        true
      );
      handleClose();
    }
  };

  //DIALOG
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const dialogBody = {
    showDialog: showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleAction: submitEditForm,
  };

  // console.log("updatedLessonInfo; ", updatedLessonInfo);
  return updatedLessonInfo.name === undefined || isLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <BlockLeavingDialog {...dialogBody} />

      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <NameField
          lesson={updatedLessonInfo}
          setLesson={setUpdatedLessonInfo}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <DescriptionField
          lesson={updatedLessonInfo}
          setLesson={setUpdatedLessonInfo}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <DiscussionTopicField
          lesson={updatedLessonInfo}
          setLesson={setUpdatedLessonInfo}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        {/* <ImageField image={image} setImage={setImage} /> */}

        <Divider />
        <DeleteLessonButton lessonId={lessonId} handleClose={handleClose} />

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          {isEditLessonPage && (
            <Button
              arrow={false}
              buttonText={translation.back || "Back"}
              style={{
                fontSize: "14px",
                backgroundColor: "var(--txt-secondary)",
                height: "auto",
              }}
              onClick={() => {
                navigate(
                  `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
                    projectSlug!
                  )}/updatedLessonInfo-plans`
                );
              }}
            />
          )}
          <Button
            buttonText={translation.update || "Update"}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
              height: "auto",
            }}
            onClick={() => {
              submitEditForm();
            }}
          />
        </Box>
      </Stack>
    </>
  );
};

const DeleteLessonButton = ({
  lessonId,
  handleClose,
}: {
  lessonId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const { setIsRefreshTeacherModule } = useStore((state) => ({
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));

  const handleDelete = async () => {
    const res = await deleteTeacherLesson(
      lessonId,
      localStorage.getItem("access")!
    );
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setIsRefreshTeacherModule(true);
      openSnack(
        translation.success_remove_lesson || "Lesson Removed Successfully",
        true
      );
      handleClose();
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Lesson?",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <Button
        arrow={false}
        buttonText={translation.delete_lesson || "Delete Lesson"}
        btnType="outlined"
        style={{
          fontSize: "14px",
          marginTop: 0,
        }}
        onClick={handleOpenDialog}
      />
    </>
  );
};

export default EditLessonForm;

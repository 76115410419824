export const ACKNOWLEDGE = "Acknowledge"; // accept/acknowledge
export const DECISION = "Decision"; // yes or not
export const CHECK_BOXES = "Check Boxes"; // multiple option with >=1 answer
export const MULTIPLE_CHOICE = "Multiple Choice"; // multiple option with 1 answer

export const YES = "Yes";
export const NO = "No";

export const RESPONSE_TYPE = [
  ACKNOWLEDGE,
  DECISION,
  CHECK_BOXES,
  MULTIPLE_CHOICE,
];

import { Box, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { translation } from "constants/translation";
import { teacherArchiveTranslatedTabs } from "./teacherArchivePageConstant";

const TeacherArchiveBreadcrumbs = ({ currentTab }: { currentTab: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        mb: 3,
      }}
    >
      <Typography>{translation.teacherPortfolio}</Typography>

      <NavigateNextIcon fontSize="small" />

      <Typography sx={{ fontWeight: "bold", color: "#407374", lineHeight: 1 }}>
        {teacherArchiveTranslatedTabs[currentTab]}
      </Typography>
    </Box>
  );
};

export default TeacherArchiveBreadcrumbs;

import useStore from "store/store";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CREATE_LESSON_PAGE, CURLANG } from "constants/url";

import { isProfileInProjectEditableRoleList } from "helper/helper";

const AddLessonButton = () => {
  const navigate = useNavigate();

  const { profileDetails, role, projectSlug, currLanguage } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      role: state.profileDetails.role,
      projectSlug: state.currentProject.slug,
      currLanguage: state.currLanguage,
    })
  );

  return isProfileInProjectEditableRoleList(profileDetails) ? (
    <Box
      sx={{
        flex: 1,
        minHeight: { xs: 235, sm: 367 },
        minWidth: { xs: 223, sm: 267 },
        maxWidth: { xs: 223, sm: 267 },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        borderRadius: "10px",
        color: "#444",
        border: "1px dashed",
        borderColor: "#444",
        transition: "all 100ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms ease-in-out",
        },
      }}
      onClick={() =>
        navigate(`${CURLANG(currLanguage)}/${CREATE_LESSON_PAGE(projectSlug)}`)
      }
    >
      <AddIcon />
    </Box>
  ) : null;
};


export default AddLessonButton;
import { Box, SxProps, Typography } from "@mui/material";

import { Selected } from "../EditModulePage/EditModulePage";
import { translation } from "constants/translation";
import { TreeViewData } from "./types";
import Image from "components/Image/Image";

const titleStyles: SxProps = {
  fontSize: "1.125rem",
  lineHeight: 1.25,
};

type Props = {
  module_: TreeViewData;
  selected: Selected | null;
  handleOpen: (selected: Selected) => void;
};

export const Module = ({ module_, selected, handleOpen }: Props) => {
  const handleClick = () => { 
    handleOpen({
      id: module_.id,
      index: 0,
      type: module_.type,
    })
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: "#EBF8F8",
        display: "flex",
        gap: 2,
        p: 1,
      }}
    >
      <Image style={{ width: "150px" }} src={module_.image} alt="module-image" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            ...titleStyles,
            fontSize: "1.25rem",
            fontWeight: 500,
          }}
        >
          {`${module_.name} ${module_.isPublished ? "" : `(${translation.draft})`}`}
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            lineHeight: 1.5,
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: module_.description ?? '',
            }}
          />
        </Typography>
        <div
          
        />
      </Box>
    </Box>
  )
};
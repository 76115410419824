

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import CloseIcon from "@mui/icons-material/Close";
import CreateSchoolPage from "containers/SchoolPage/CreateSchoolPage";
import { GridRowsProp } from "@mui/x-data-grid";

const CreateSchoolModal = ({
  open,
  handleClose,
  setRows,
}: {
  open: boolean;
  handleClose: () => void;
  setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            minWidth: "30vw",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          <CreateSchoolPage handleClose={handleClose} setRows={setRows} />
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateSchoolModal;

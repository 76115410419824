import React, { useState } from "react";
import moment from "moment";
import useStore from "store/store";
import {
  Calendar,
  momentLocalizer,
  Views,
  stringOrDate,
  SlotInfo,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Box } from "@mui/material";
import { API_Timetable_Event_Info } from "types/timetable";
import {
  TIME_FORMAT,
  MIN_TIME,
  MAX_TIME,
  DISPLAY_TIME_FORMAT,
} from "constants/timetable";
import { CustomEvent, CustomHeader, EventPopup } from "./TimetableComponents";
import { updateTimetableEvent } from "api/timetable-api";
import useSnack from "hooks/useSnack";
import useColors from "hooks/useColors";
import { translation } from "constants/translation";
import "./RBC.css";

const localizer = momentLocalizer(moment);

const EditTimetable = ({
  events,
  setEvents,
  modules,
}: {
  events: API_Timetable_Event_Info[];
  setEvents: React.Dispatch<
    React.SetStateAction<API_Timetable_Event_Info[] | undefined>
  >;
  modules: string[];
}) => {
  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));
  const colors = useColors();
  const openSnack = useSnack();

  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] =
    useState<API_Timetable_Event_Info>();

  const DnDCalendar = withDragAndDrop(Calendar);

  const min = moment(MIN_TIME, TIME_FORMAT);
  const max = moment(MAX_TIME, TIME_FORMAT);

  const isTimeRangeExceeded = (date: stringOrDate) =>
    moment(moment(date).format(TIME_FORMAT), TIME_FORMAT).isAfter(max) ||
    moment(moment(date).format(TIME_FORMAT), TIME_FORMAT).isBefore(min);

  const isMaxTime = (date: stringOrDate) =>
    moment(date).format(TIME_FORMAT) === max.format(TIME_FORMAT);

  const GetUpdatedTime = (date: stringOrDate, isEnd: boolean) => {
    return isEnd && isTimeRangeExceeded(date)
      ? moment(MAX_TIME, TIME_FORMAT).format(DISPLAY_TIME_FORMAT)
      : isEnd && !isMaxTime(date) && moment(date).second() === 59
      ? moment(date).add(1, "second").format(DISPLAY_TIME_FORMAT)
      : moment(date).format(DISPLAY_TIME_FORMAT);
  };

  const formatTimeRange = ({
    start,
    end,
  }: {
    start: stringOrDate;
    end: stringOrDate;
  }) => `${GetUpdatedTime(start, false)} — ${GetUpdatedTime(end, true)}`;

  const handleUpdate = async ({
    event,
    start,
    end,
  }: {
    event: object;
    start: stringOrDate;
    end: stringOrDate;
  }) => {
    const updatedDay = Number(moment(start).format("d"));
    const updatedStart = moment(start).format(TIME_FORMAT);
    const updatedEnd = isTimeRangeExceeded(end)
      ? moment(MAX_TIME, TIME_FORMAT).format(TIME_FORMAT)
      : !isMaxTime(end) && moment(end).second() === 59
      ? moment(end).add(1, "second").format(TIME_FORMAT)
      : moment(end).format(TIME_FORMAT);

    const eventDetails: API_Timetable_Event_Info = {
      ...event,
      day: updatedDay,
      start: updatedStart,
      end: updatedEnd,
    } as API_Timetable_Event_Info;

    setEvents((prev) =>
      prev?.map((e) => (e.id === eventDetails.id ? eventDetails : e))
    );

    const updatedEvent = await updateTimetableEvent(eventDetails);

    if (typeof updatedEvent !== "string") {
      setEvents((prev) =>
        prev?.map((e) => (e.id === updatedEvent.id ? updatedEvent : e))
      );
      openSnack(translation.successUpdateEvent, true);
    } else {
      const originalEvent: API_Timetable_Event_Info = {
        ...event,
        day: (event as API_Timetable_Event_Info).day,
        start: moment((event as API_Timetable_Event_Info).start).format(
          TIME_FORMAT
        ),
        end: moment((event as API_Timetable_Event_Info).end).format(
          TIME_FORMAT
        ),
      } as API_Timetable_Event_Info;

      setEvents((prev) =>
        prev?.map((e) =>
          e.id === (event as API_Timetable_Event_Info).id ? originalEvent : e
        )
      );
      openSnack(translation.failUpdateEvent, false);
    }
  };

  const handleSelectEvent = (event: object) => {
    const selectedEvent: API_Timetable_Event_Info = {
      ...event,
      start: moment((event as API_Timetable_Event_Info).start).format(
        TIME_FORMAT
      ),
      end: moment((event as API_Timetable_Event_Info).end).format(TIME_FORMAT),
    } as API_Timetable_Event_Info;

    setSelectedEvent(selectedEvent);
    setIsEdit(true);
    setOpen(true);
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    const startTime = moment(slotInfo.start);
    const endTime = moment(slotInfo.end);

    const newEvent: API_Timetable_Event_Info = {
      id: -1,
      name: "",
      type: modules[0],
      day: startTime.day(),
      start: startTime.format(TIME_FORMAT),
      end: endTime.format(TIME_FORMAT),
      color: 0,
      currGroup: currGroup.id,
    };

    setSelectedEvent(newEvent);
    setIsEdit(false);
    setOpen(true);
  };

  return (
    <Box
      className="custom-rbc"
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DnDCalendar
        localizer={localizer}
        defaultView={Views.WEEK}
        min={min.toDate()}
        max={max.toDate()}
        step={15}
        timeslots={2}
        toolbar={false}
        events={events.map((event) => ({
          ...event,
          start: moment(event.start, TIME_FORMAT).day(event.day).toDate(),
          end: moment(event.end, TIME_FORMAT).day(event.day).toDate(),
        }))}
        formats={{
          timeGutterFormat: (date: Date) =>
            moment(date).format(DISPLAY_TIME_FORMAT),
          eventTimeRangeStartFormat: formatTimeRange,
          eventTimeRangeEndFormat: formatTimeRange,
          eventTimeRangeFormat: formatTimeRange,
          selectRangeFormat: formatTimeRange,
        }}
        components={{
          header: CustomHeader,
          event: ({ event }: { event: object }) => (
            <CustomEvent
              event={event as API_Timetable_Event_Info}
              setEvents={setEvents}
            />
          ),
        }}
        eventPropGetter={(event: object) => {
          const color =
            colors[(event as API_Timetable_Event_Info).color % colors.length];

          return {
            style: {
              color: "#312f30",
              backgroundColor: "white",
              padding: "4px 12px",
              flexWrap: "nowrap",
              borderRadius: 10,
              borderColor: color,
            },
          };
        }}
        onEventDrop={handleUpdate}
        onEventResize={handleUpdate}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
      />

      {open && selectedEvent && (
        <EventPopup
          events={events}
          event={selectedEvent}
          isEdit={isEdit}
          open={open}
          setOpen={setOpen}
          setEvents={setEvents}
          modules={modules}
        />
      )}
    </Box>
  );
};

export default EditTimetable;

import { API_Group_Simple } from "./group";

export interface AuthAccountData {
  preferred_name?: string;
  email: string;
  password: string;
  role?: string;
}

export interface RegisterAccountResponse {
  profile_id: string;
  id: number;
  email: string;
  tokens: {
    refresh: string;
    access: string;
  };
}

export interface LoginAccountResponse {
  refresh: string;
  access: string;
  id: number;
  schoolID: number;
}

export interface RegisterEmailError {
  email: string;
}

export interface RegisterPasswordError {
  password: string[];
}

export interface LoginError {
  detail: string;
}

export interface ErrorMessage {
  error: string[];
}

export interface ErrorMessageV2 {
  error: {
    status: number;
    statusText: string;
  };
}
//weakest to strongest
export type UserRole =
  | "Child"
  | "Parent"
  | "Teacher"
  | "Curiculum Developer"
  | "Content Editor"
  | "TTA Content Editor"
  | "Trehaus Curriculum Specialist"
  | "Admin"
  | "Web Admin";

export type UserRoleAbbr =
  | "CH"
  | "PR"
  | "TR"
  | "CD"
  | "CE"
  | "TTACE"
  | "TCS"
  | "AD"
  | "WAD";

export const NORMAL_ROLE_LIST = ["Teacher", "Parent"];

export const UserRoleMapping: { [role: string]: string } = {
  Child: "CH",
  Parent: "PR",
  Teacher: "TR",
  "Curiculum Developer": "CD",
  "Content Editor": "CE",
  "TTA Content Editor": "TTACE",
  "Trehaus Curriculum Specialist": "TCS",
  Admin: "AD",
  "Web Admin": "WAD",
};

export const UserRoleMappingInverse: { [role: string]: string } = {
  CH: "Child",
  PR: "Parent",
  TR: "Teacher",
  CD: "Curiculum Developer",
  CE: "Content Editor",
  TTACE: "TTA Content Editor",
  TCS: "Trehaus Curriculum Specialist",
  AD: "Admin",
  WAD: "Web Admin",
};

export interface UpdateProfile {
  email?: string;
  mobile_number?: string;
  customer_id?: string;
  role?: UserRoleAbbr;
  role_en?: UserRoleAbbr;
  role_zh?: UserRoleAbbr;
  role_ind?: UserRoleAbbr;
  preferred_name?: string;
  preferred_name_en?: string;
  preferred_name_zh?: string;
  preferred_name_ind?: string;
  full_name?: string;
  full_name_en?: string;
  full_name_zh?: string;
  full_name_ind?: string;
  dob?: string;
  image?: File | string;
  description?: string;
  share_timetable?: boolean;
  last_seen_announcement_at?: string;
  attendance?: any
}

export interface UpdateProfileResponse {
  id: number;
  email: string;
  role: string;
  role_en: string;
  role_zh: string;
  role_ind: string;
  preferred_name: string;
  preferred_name_en: string;
  preferred_name_zh: string;
  preferred_name_ind: string;
  full_name: string;
  full_name_en: string;
  full_name_zh: string;
  full_name_ind: string;
  dob: string;
  image: string;
  description: string;
  appraisal: string;
  appraisal_en: string;
  appraisal_zh: string;
  appraisal_ind: string;
  create_at: string;
  update_at: string;
  user: string;
  parent: string;
  school: number;
  groups?: API_Group_Simple[];
  share_timetable?: boolean;
  last_seen_announcement_at?: string;
  terms_agreed?: boolean;
  attendance?: any

}

export interface ChangePassword {
  password: string;
  password2: string;
  old_password: string;
}

export interface ChangePasswordResponse {
  password?: string[];
  old_password?: string[];
  non_field_errors?: string[];
}

export interface ChangePasswordError {
  error: ChangePasswordResponse[];
}

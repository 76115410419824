
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "../Button/Button";
import useStore from "store/store";

const BlockLeavingDialog = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  handleAction,
}: {
  showDialog: any;
  cancelNavigation: any;
  confirmNavigation: any;
  handleAction: any;
}) => {

  const { setBringOverProject, setBringOverLesson } = useStore((state) => state)

  return (
    <Box>
      <Dialog
        open={showDialog}
        onClose={cancelNavigation}
        aria-labelledby="block-dialog-title"
        aria-describedby="block-dialog-description"
      >
        <DialogTitle id="block-dialog-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="block-dialog-description">
            There are unsaved changes. Do you want to discard or save them
            before leaving the page?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "20px" }}>
          <Button
            buttonText="Cancel"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "var(--txt-secondary)",
              padding: "0",
              marginRight: "auto",
            }}
            onClick={cancelNavigation}
          />

          <Button
            buttonText="Discard"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "var(--txt-secondary)",
              padding: "0",
              marginRight: "20px",
            }}
            onClick={() => {
              setBringOverProject({});
              setBringOverLesson({});
              confirmNavigation();
            }}
          />

          <Button
            buttonText="Save"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
            }}
            onClick={async () => {
              setBringOverProject({});
              setBringOverLesson({});
              handleAction(false);
              confirmNavigation();
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BlockLeavingDialog;

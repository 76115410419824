import { Box } from "@mui/material";
import {
  LITTLES_LEARNERS_COLOR,
  LITTLES_PROJECTS_COLOR,
} from "containers/CalendarPage/constants/constants";

const Legends = () => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "3px", ml: 3 }}>
      <Box
        key={`little-projects`}
        sx={{
          width: "fit-content",
          height: "fit-content",
          padding: "5px",
          border: `1px solid ${"#C4C4C4"}`,
          borderRadius: "5px",
          background: `${LITTLES_PROJECTS_COLOR}`,
        }}
      >
        Little Projects
      </Box>
      <Box
        key={`little-learners`}
        sx={{
          width: "fit-content",
          height: "fit-content",
          padding: "5px",
          border: `1px solid ${"#C4C4C4"}`,
          borderRadius: "5px",
          background: `${LITTLES_LEARNERS_COLOR}`,
        }}
      >
        Little Learners
      </Box>
    </Box>
  );
};

export default Legends;

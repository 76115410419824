import { Selected } from "./EditModulePage/EditModulePage";

// import EditModuleForm from "../../../components/TeacherModuleForms/EditModuleForm";
import EditLessonForm from "./TeacherForms/EditLessonForm";
import EditActivityForm from "./TeacherForms/EditActivityForm";

import CreateActivityForm from "./TeacherForms/CreateActivityForm";
import CreateLessonForm from "./TeacherForms/CreateLessonForm";
import EditModuleForm from "./TeacherForms/EditModuleForm";

const ModuleForm = ({
  selected,
  handleClose,
}: {
  selected: Selected;
  handleClose: () => void;
}) => {
  return selected.id !== "-1" ? (
    selected.type === "Module" ? (
      <EditModuleForm
        moduleId={Number(selected.id)}
        handleClose={handleClose}
      />
    ) : selected.type === "Lesson" ? (
      <EditLessonForm
        lessonId={Number(selected.id)}
        handleClose={handleClose}
      />
    ) : (
      <EditActivityForm
        activityId={Number(selected.id)}
        lessonId={Number(selected.parentId!)}
        handleClose={handleClose}
      />
    )
  ) : selected.type === "Lesson" ? (
    <CreateLessonForm
      moduleId={Number(selected.parentId!)}
      handleClose={handleClose}
    />
  ) : (
    <CreateActivityForm
      lessonId={Number(selected.parentId!)}
      handleClose={handleClose}
    />
  );
};

export default ModuleForm;

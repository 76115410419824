import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import { checkIsChineseChar } from "helper/helper";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import VerticalFrame from "images/children-portfolio-v2/littleLearnerFrameVertical.png";

const templateStyle = StyleSheet.create({
  StorySection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  TwoStorySection: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  ImageFrame: {
    position: "relative",
  },
  StoryTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "40%",
  },
  StoryTwoTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
  },
  titleContainer: {
    backgroundColor: "#F9F6F1",
    padding: 24,
  },
  Title: {
    color: "#000",
    textAlign: "center",
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: 100,
    lineHeight: 1.5,
    letterSpacing: 1.4,
    fontStyle: "normal",
  },
  TitleTwoStory: {
    color: "#000",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 1.5,
    letterSpacing: 1.4,
    fontStyle: "normal",
    textTransform: "uppercase",
  },
  Subtitle: {
    color: "#000",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 100,
    lineHeight: 1.2,
    letterSpacing: 1.2,
    fontStyle: "normal",
    top: "5px",
  },
  SubtitleTwoStory: {
    color: "#000",
    textAlign: "center",
    fontSize: 10,
    fontWeight: 100,
    lineHeight: 1.2,
    letterSpacing: 1.2,
    fontStyle: "normal",
    top: "5px",
  },
  StoryContainer: {
    padding: 10,
    textAlign: "center",
  },
  Story: {
    color: "#000",
    textAlign: "center",
    fontSize: 12,
    lineHeight: 1.8,
    letterSpacing: "1.2px",
    fontWeight: 100,
    fontStyle: "normal",
  },
  StoryTwoStory: {
    color: "#000",
    textAlign: "center",
    fontSize: 10,
    lineHeight: 1.8,
    letterSpacing: "1.2px",
    fontWeight: 100,
    fontStyle: "normal",
  },
});

const PageContent = ({
  learningStories,
}: {
  learningStories: {
    fundamental: string;
    milestone: string;
    image: string;
    story: string;
  }[];
}) => {
  const getFontFamily = (text: string) => {
    return checkIsChineseChar(text) ? "KaiTi" : "FuturaLtBT";
  };

  return (
    <>
      {learningStories.length == 1 ? (
        <View style={{ ...templateStyle.StorySection }}>
          <View
            style={{
              position: "relative",
              width: "85%",
              height: "70%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image
              src={{
                uri: learningStories[0].image,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={{
                position: "relative",
                top: "8.7%",
                width: "79.5%",
                height: "63%",
                objectFit: "cover",
              }}
            />
            <Image
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src={Frame}
            />
            <View
              style={{
                ...templateStyle.titleContainer,
                position: "absolute",
                top: "395px",
                width: "80%",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  ...templateStyle.Title,
                  fontFamily: getFontFamily(learningStories[0].fundamental),
                }}
              >
                {learningStories[0].fundamental}
              </Text>
              <Text
                style={{
                  ...templateStyle.Subtitle,
                  fontFamily: "FuturaLtBT",
                }}
              >
                {learningStories[0].milestone}
              </Text>
            </View>
            <View
              style={{
                ...templateStyle.StoryContainer,
                position: "absolute",
                textAlign: "center",
                top: "520px",
                width: "80%",
              }}
            >
              <Text
                style={{
                  ...templateStyle.Story,
                  fontFamily: checkIsChineseChar(learningStories[0].story)
                    ? "KaiTi"
                    : "FuturaLtBT",
                  fontWeight: 100,
                }}
              >
                {learningStories[0].story}
              </Text>
            </View>
          </View>
        </View>
      ) : (
        <>
          {learningStories.map((story, index) => (
            <View
              key={index}
              style={{
                ...templateStyle.TwoStorySection,
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                gap: "10px",
              }}
            >
              {/* images and frame */}
              <View
                style={{
                  ...templateStyle.ImageFrame,
                  marginLeft: index % 2 === 0 ? "50px" : 0,
                  marginRight: index % 2 === 0 ? 0 : "20px",

                  // marginRight: "30px",
                  // marginLeft: "40px",
                  width: "262px",
                  height: "210px",
                }}
              >
                <Image
                  src={{
                    uri: story.image,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                  style={{
                    width: "80%",
                    height: "64%",
                    top: "8%",
                    left: "10.5%",
                    objectFit: "cover",
                  }}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src={Frame}
                />
              </View>
              <View
                style={{
                  ...templateStyle.StoryTwoTextContainer,
                  marginLeft: index % 2 === 0 ? 0 : "50px",
                  marginRight: index % 2 === 0 ? "20px" : 0,
                }}
              >
                <View style={templateStyle.titleContainer}>
                  <Text
                    style={{
                      ...templateStyle.TitleTwoStory,
                      fontFamily: getFontFamily(story.fundamental),
                    }}
                  >
                    {story.fundamental}
                  </Text>
                  <Text
                    style={{
                      ...templateStyle.SubtitleTwoStory,
                      fontFamily: "FuturaLtBT",
                    }}
                  >
                    {story.milestone}
                  </Text>
                </View>
                <View style={{ ...templateStyle.StoryContainer }}>
                  <Text
                    style={{
                      ...templateStyle.StoryTwoStory,
                      fontFamily: checkIsChineseChar(story.story)
                        ? "KaiTi"
                        : "FuturaLtBT",
                      fontWeight: 100,
                    }}
                  >
                    {story.story}
                  </Text>
                </View>
              </View>
            </View>
          ))}
        </>
      )}
    </>
  );
};

export default PageContent;

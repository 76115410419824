import { StoreSlice } from "./store";

export interface ActivitySlice {
  isRefreshActivityInstruction: boolean;
  setIsRefreshActivityInstruction: (
    isRefreshActivityInstruction: boolean
  ) => void;
  isFullscreen: boolean;
  setIsFullscreen: (contentHeight: boolean) => void;
  isLessonIntentionAccordionOpen: boolean;
  setIsLessonIntentionAccordionOpen: (contentHeight: boolean) => void;
  isKeyVocabAccordionOpen: boolean;
  setIsKeyVocabAccordionOpen: (contentHeight: boolean) => void;
}

export const createActivitySlice: StoreSlice<ActivitySlice> = (set, get) => ({
  isRefreshActivityInstruction: false,
  setIsRefreshActivityInstruction: (isRefreshActivityInstruction: boolean) => {
    set((prev: ActivitySlice) => ({
      ...prev,
      isRefreshActivityInstruction: isRefreshActivityInstruction,
    }));
  },
  isFullscreen: false,
  setIsFullscreen: (isFullscreen: boolean) => {
    set((prev: ActivitySlice) => ({
      ...prev,
      isFullscreen: isFullscreen,
    }));
  },
  isLessonIntentionAccordionOpen: false,
  setIsLessonIntentionAccordionOpen: (
    isLessonIntentionAccordionOpen: boolean
  ) => {
    set((prev: ActivitySlice) => ({
      ...prev,
      isLessonIntentionAccordionOpen: isLessonIntentionAccordionOpen,
    }));
  },
  isKeyVocabAccordionOpen: false,
  setIsKeyVocabAccordionOpen: (isKeyVocabAccordionOpen: boolean) => {
    set((prev: ActivitySlice) => ({
      ...prev,
      isKeyVocabAccordionOpen: isKeyVocabAccordionOpen,
    }));
  },
});

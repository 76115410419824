import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { Image } from "react-grid-gallery";

import useStore from "store/store";

import { getGalleryByLesson } from "api/gallery-api";

import { API_Profile_Short } from "types/profile";

import "components/ProjectSummaryScreen/ReactGridGallery.css";
import { GalleryPhotoVideo } from "types/gallery";
import PhotoVideoGalleryComponent from "components/PhotoVideoGalleryComponents/PhotoVideoGalleryComponent";
import PhotoVideoGalleryModal from "components/PhotoVideoGalleryComponents/PhotoVideoGalleryModalV2";
import { API_Gallery_Photo_Video_Custom } from "containers/ProjectSummaryPage/Screens/ProjectPhotoAlbumScreen";
import {
  API_Lesson_Learning_Moment,
  API_Project_Lesson,
} from "types/project";

const LearningMomentPhotoGalleryComponent = ({
  photoVideoGalleryList,
  setPhotoVideoGalleryList,
  currentLesson,
  learningMomentObject,
  isForLearningMomentTab = false,
}: {
  photoVideoGalleryList: GalleryPhotoVideo[];
  setPhotoVideoGalleryList: React.Dispatch<
    React.SetStateAction<GalleryPhotoVideo[]>
  >;
  currentLesson: API_Project_Lesson;
  learningMomentObject: API_Lesson_Learning_Moment;
  isForLearningMomentTab?: boolean;
}) => {
  const {
    currGroup,
    currLanguage,
    isRefreshLearningMomentPhotoVideoGallery,
    setIsRefreshLearningMomentPhotoVideoGallery,
  } = useStore((state) => ({
    currGroup: state.currGroup,
    projID: state.currentLessons.id,
    currLanguage: state.currLanguage,
    isRefreshLearningMomentPhotoVideoGallery:
      state.isRefreshLearningMomentPhotoVideoGallery,
    setIsRefreshLearningMomentPhotoVideoGallery:
      state.setIsRefreshLearningMomentPhotoVideoGallery,
  }));

  //combined
  const [openGalleryModal, setOpenGalleryModal] = useState<boolean>(false);
  const [currPhotoVideoIndex, setCurrPhotoVideoIndex] = useState<number>(0);
  const [havePhotoVideo, setHavePhotoVideo] = useState<boolean>(false);

  //general
  const [galleryIndex, setGalleryIndex] = useState<number>(0);
  const [selectedProfiles, setSelectedProfiles] = useState<string[][][]>([]);
  const [profileDict, setProfileDict] = useState<{
    [id: string]: API_Profile_Short;
  }>({});

  const initializeGallery = async () => {
    // console.log("getting learning moment")
    const galleryResData = await getGalleryByLesson(
      currLanguage,
      currentLesson.id.toString(),
      localStorage.getItem("access"),
      localStorage.getItem("curr_group_id")
    );

    if (typeof galleryResData !== "string") {
      const newImageVideoList: GalleryPhotoVideo[] = [];
      const newProfileList: string[][][] = [];
      let lessonIndex = 0;
      const lessonList: string[] = [];
      let lessonName = "";

      galleryResData.forEach((data) => {
        if (
          data.lesson === undefined ||
          data.lesson === "" ||
          data.activity === null
        )
          return;

        lessonName = data.lesson!;
        lessonIndex = lessonList.indexOf(lessonName);

        //create new one if doesn't exist
        if (lessonIndex === -1) {
          lessonList.push(lessonName);
          newImageVideoList.push({
            lesson: lessonName,
            photo_video_list: [],
          });
          lessonIndex = newImageVideoList.length - 1;
          newProfileList.push([]);
        }

        if (data.videos.length > 0 || data.images.length > 0) {
          setHavePhotoVideo(true);
        }

        const photoVideoFullGalleryList = [...data.images, ...data.videos];
        photoVideoFullGalleryList.sort((a, b) => {
          const first = a.created_at
            .replace(/[^0-9]/g, "")
            .split("T")[0]
            .split("-")
            .join("");
          const second = b.created_at
            .replace(/[^0-9]/g, "")
            .split("T")[0]
            .split("-")
            .join("");
          return first > second ? 1 : first < second ? -1 : 0;
        });

        photoVideoFullGalleryList.forEach(
          (photoVideo: API_Gallery_Photo_Video_Custom) => {
            const isPhoto = photoVideo.video ? false : true;
            const isLearningMoment = photoVideo.learning_moment !== null;
            // YCTODO -> FILTER OUT PHOTO BASED ON LEARNING MOMENT (PREVIOUSLY IT BIND TO AN ACTIVITY, NOW IT GO DEEP INTO PER LEARNING MOMENT, IF TOO COMPLEX TELL CARMEN TO ENHANCE IT AFTERWARDS)
            // console.log(
            //   "learningMomentObject.id:",
            //   learningMomentObject.id,
            //   "vs photoVideo.learning_moment:",
            //   photoVideo.learning_moment,
            //   ">>",
            //   learningMomentObject.id! !== photoVideo.learning_moment!,
            //   ">>>>",
            //   !isLearningMoment ||
            //     learningMomentObject.activity! !== data.activity! ||
            //     learningMomentObject.id! !== photoVideo.learning_moment!
            // );

            if (
              !isLearningMoment ||
              learningMomentObject.activity! !== data.activity! ||
              learningMomentObject.id !== photoVideo.learning_moment!
            ) {
              return;
            }
            const imageData: Image = {
              key: `${isPhoto ? "photo" : "video"}-${photoVideo.id}${isLearningMoment
                ? `-learning-moment-${photoVideo.learning_moment!}`
                : ""
                }${data.activity ? `-activity-${data.activity}` : `-activity-none`
                }`,
              src: isPhoto ? photoVideo.image! : photoVideo.video!,
              width: photoVideo.width,
              height: photoVideo.height,
              alt: photoVideo.caption,
              customOverlay: (
                <>
                  {newImageVideoList[lessonIndex].photo_video_list.length !==
                    0 && (
                      <div className="number-overlay">
                        <p>
                          +
                          {newImageVideoList[lessonIndex].photo_video_list
                            .length + 1}
                        </p>
                      </div>
                    )}
                  <div className="custom-overlay">
                    <p>{photoVideo.caption}</p>
                  </div>
                </>
              ),
            };
            // console.log(imageData)
            newImageVideoList[lessonIndex].photo_video_list.unshift(imageData);
            newProfileList[lessonIndex].unshift(
              photoVideo.profile.map((prof) => prof.id.toString())
            );
          }
        );
      });
      setPhotoVideoGalleryList(newImageVideoList);
      setSelectedProfiles(newProfileList);
      setIsRefreshLearningMomentPhotoVideoGallery(false);
    }
  };

  const initializeProfiles = async () => {
    const profileList = currGroup.children.reduce((acc, child) => {
      acc[child.id.toString()] = {
        id: child.id,
        image: child.image,
        preferred_name: child.preferred_name,
        full_name: child.full_name ? child.full_name : "",
        dob: child.dob,
        school: child.school,
      };
      return acc;
    }, {} as { [id: string]: API_Profile_Short });
    setProfileDict(profileList);
  };

  useEffect(() => {
    if (currentLesson.id === -1 || currGroup.id === -1) return;
    initializeGallery();
    initializeProfiles();
    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLesson.id, currLanguage, currGroup]);

  useEffect(() => {
    if (currentLesson.id === -1 || currGroup.id === -1) return;

    if (isRefreshLearningMomentPhotoVideoGallery) {
      initializeGallery();
      initializeProfiles();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshLearningMomentPhotoVideoGallery]);

  return (
    <>
      <Box
        sx={{
          p: isForLearningMomentTab ? 0 : 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PhotoVideoGalleryComponent
          key={`photo-video-${currentLesson.id}`}
          isLearningMomentPhotoVideoGallery={true}
          isForLearningMomentTab={isForLearningMomentTab}
          lesson={currentLesson}
          learningMomentObject={learningMomentObject}
          lessonNo={currentLesson.weight}
          photoVideoGalleryList={photoVideoGalleryList}
          setGalleryIndex={setGalleryIndex}
          setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
          setOpen={setOpenGalleryModal}
          initializeGallery={initializeGallery}
        />
      </Box>
      {photoVideoGalleryList.length > 0 &&
        havePhotoVideo &&
        photoVideoGalleryList[galleryIndex].photo_video_list.length !== 0 && (
          <PhotoVideoGalleryModal
            open={openGalleryModal}
            setOpen={setOpenGalleryModal}
            lessonName={currentLesson.name}
            photoVideoGalleryList={photoVideoGalleryList}
            galleryIndex={galleryIndex}
            currPhotoVideoIndex={currPhotoVideoIndex}
            setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
            profileDict={profileDict}
            selectedProfiles={selectedProfiles}
            setSelectedProfiles={setSelectedProfiles}
            initializeGallery={initializeGallery}
            isLearningMomentPhotoAlbum
          />
        )}
    </>
  );
};

export default LearningMomentPhotoGalleryComponent;

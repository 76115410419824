import { StoreSlice } from "./store";

export interface TTASlice {
  isUpdateMcqQuestion: boolean; //no use now
  setIsUpdateMcqQuestion: (isUpdateMcqQuestion: boolean) => void; //no use now
}

export const createTTASlice: StoreSlice<TTASlice> = (set, get) => ({
  isUpdateMcqQuestion: false,
  setIsUpdateMcqQuestion: (isUpdateMcqQuestion: boolean) => {
    set((prev: TTASlice) => ({
      ...prev,
      isUpdateMcqQuestion: isUpdateMcqQuestion,
    }));
  },
});

import React from "react";
import { ScoreTitle } from "../../TeacherPage/TeacherPageComponents/CongrazModal";

import { Box, Typography } from "@mui/material";

import Button from "components/Button/Button";
import Image from "components/Image/Image";
import KeepTryingIcon from "images/keep-trying.png";
import { translation } from "constants/translation";
import LoopIcon from "@mui/icons-material/Loop";

const TryAgainPage = ({
  correctCount,
  totalCount,
  setCurrMcqQuestionIndex,
}: {
  correctCount: number;
  totalCount: number;
  setCurrMcqQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <ScoreTitle correctCount={correctCount} totalCount={totalCount} />

      {/* EMOTICON */}
      <Image
        src={KeepTryingIcon}
        alt={translation.keepTrying || "Keep Trying"}
        style={{ width: 100, height: 100 }}
      />

      <Typography sx={{ px: 2 }}>{translation.letsTryAgain}</Typography>

      {/* Retry button */}

      <Button
        buttonText={translation.retry || "Retry"}
        icon={<LoopIcon />}
        arrow={false}
        style={{
          // width: "10vw",
          textAlign: "center",
        }}
        onClick={() => {
          setCurrMcqQuestionIndex(0);
        }}
      />
    </Box>
  );
};

export default TryAgainPage;

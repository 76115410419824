import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import WarningDialog from "components/Dialog/WarningDialog";
import { translation } from "constants/translation";
import useDialog from "hooks/useDialog";
import useSnack from "hooks/useSnack";
import { API_Drag_And_Drop_Answer_Pair } from "types/teacher";
import { SubtitleWithAddIcon } from "../ActivityFormComponents";
import ClearIcon from "@mui/icons-material/Clear";
import { deleteDragAndDropAnswerPair } from "api/teacher-api";

export const DragAndDropField = ({
  activityId = -1,
  dragAndDropAnswerPairs,
  setDragAndDropAnswerPairs,
}: {
  activityId?: number;
  dragAndDropAnswerPairs: API_Drag_And_Drop_Answer_Pair[];
  setDragAndDropAnswerPairs: React.Dispatch<
    React.SetStateAction<API_Drag_And_Drop_Answer_Pair[]>
  >;
}) => {
  const handleAdd = () => {
    const updatedDragAndDropAnswerPair: API_Drag_And_Drop_Answer_Pair[] = [
      ...dragAndDropAnswerPairs,
      {
        id: `unassigned-id-${dragAndDropAnswerPairs.length}`,
        drag_and_drop_activity: activityId,
        question: "",
        answer: "",
      },
    ];
    setDragAndDropAnswerPairs(updatedDragAndDropAnswerPair);
    // onChange && onChange();
  };

  return (
    <>
      <Typography sx={{ textAlign: "left", mb: 1 }}>
        {translation.dragAndDropAnswerPairs} *
      </Typography>
      <Box
        sx={{
          border: "2px solid #98C4C5",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {dragAndDropAnswerPairs.map((dragAndDropAnswerPair, index) => {
          return (
            <Fragment key={`answer-pair-${index}`}>
              <IndividualAnswerPair
                index={index}
                dragAndDropAnswerPairs={dragAndDropAnswerPairs}
                setDragAndDropAnswerPairs={setDragAndDropAnswerPairs}
                dragAndDropAnswerPair={dragAndDropAnswerPair}
              />
            </Fragment>
          );
        })}
        <SubtitleWithAddIcon
          subtitle={translation.addAnswerPair || "Add Answer Pair"}
          handleAdd={handleAdd}
        />
      </Box>
    </>
  );
};

export const IndividualAnswerPair = ({
  index,
  dragAndDropAnswerPairs,
  setDragAndDropAnswerPairs,
  dragAndDropAnswerPair,
}: {
  index: number;
  dragAndDropAnswerPairs: API_Drag_And_Drop_Answer_Pair[];
  setDragAndDropAnswerPairs: React.Dispatch<
    React.SetStateAction<API_Drag_And_Drop_Answer_Pair[]>
  >;
  dragAndDropAnswerPair: API_Drag_And_Drop_Answer_Pair;
}) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteDragAndDropAnswerPair(
      Number(dragAndDropAnswerPair.id)
    );
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const filteredDragAndDropAnswerPairs = dragAndDropAnswerPairs.filter(
        (dragAndDropAnswerPair_) =>
          dragAndDropAnswerPair.id !== dragAndDropAnswerPair_.id
      );
      setDragAndDropAnswerPairs(filteredDragAndDropAnswerPairs);
      openSnack(translation.answerPairRemoved || "Answer Pair Removed", true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${translation.formatString(
          translation.answerPairNo || "Answer Pair {number}",
          { number: index + 1 }
        )}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Answer Pair?",
    warningContext: warningContext,
    handleDelete,
  };
  return (
    <>
      <WarningDialog {...warningBody} />
      <Typography sx={{ textAlign: "left", mt: index === 0 ? 0 : 1, mb: 1 }}>
        {translation.formatString(
          translation.answerPairNo || "Answer Pair {number}",
          {
            number: index + 1,
          }
        )}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            label={translation.question}
            value={dragAndDropAnswerPair.answer}
            onChange={(event) => {
              const dragAndDropAnswerPairsCopy: API_Drag_And_Drop_Answer_Pair[] =
                JSON.parse(JSON.stringify(dragAndDropAnswerPairs));
              dragAndDropAnswerPairsCopy[index].answer = event.target.value;
              setDragAndDropAnswerPairs(dragAndDropAnswerPairsCopy);
            }}
            required
          />
          <TextField
            fullWidth
            label={translation.answer}
            value={dragAndDropAnswerPair.question}
            onChange={(event) => {
              const dragAndDropAnswerPairsCopy: API_Drag_And_Drop_Answer_Pair[] =
                JSON.parse(JSON.stringify(dragAndDropAnswerPairs));
              dragAndDropAnswerPairsCopy[index].question = event.target.value;
              setDragAndDropAnswerPairs(dragAndDropAnswerPairsCopy);
            }}
            required
          />
        </Box>
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (
              typeof dragAndDropAnswerPair.id === "string" &&
              dragAndDropAnswerPair.id.includes("unassigned")
            ) {
              const filteredDragAndDropAnswerPairs =
                dragAndDropAnswerPairs.filter(
                  (dragAndDropAnswerPair_) =>
                    dragAndDropAnswerPair.id !== dragAndDropAnswerPair_.id
                );
              setDragAndDropAnswerPairs(filteredDragAndDropAnswerPairs);
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};

import Empathy from "./Empathy.png";
import GrossMotorSkills from "./GrossMotorSkills.png";
import Confidence from "./Confidence.png";
import Creativity from "./Creativity.png";
import ExecutiveFunction from "./ExecutiveFunction.png";
import GlobalPerspectives from "./GlobalPerspectives.png";
import EnglishLanguageAndLiteracy from "./EnglishLanguageAndLiteracy.png";
import EnvironmentalResponsibility from "./EnvironmentalResponsibility.png";
import FineMotorSkills from "./FineMotorSkills.png";
import STEAM from "./STEAM.png";
import GratitudeAndGivingBack from "./GratitudeAndGivingBack.png";
import Integrity from "./Integrity.png";
import MandarinLanguageAndLiteracy华语听说与读写能力 from "./MandarinLanguageAndLiteracy华语听说与读写能力.png";
import NumeracyAndMathConcepts from "./NumeracyAndMathConcepts.png";
import PerseveranceAndGrit from "./PerseveranceAndGrit.png";
import Interdependence from "./Interdependence.png";
import PracticalLifeSkills from "./PracticalLifeSkills.png";
import PublicSpeakingAndAdvocacy from "./PublicSpeakingAndAdvocacy.png";
import SelfRegulation from "./Self-Regulation.png";
import SocialAwareness from "./SocialAwareness.png";
import CommunicationSkills from "./CommunicationSkills.png";
import LittleCEO from "./LittleCEO.png";
import LittleChef from "./LittleChef.png";
import LittleCreative from "./LittleCreative.png";
import LittleEngineer from "./LittleEngineer.png";
import LittleEntrepreneur from "./LittleEntrepreneur.png";
import LittleJournalist from "./LittleJournalist.png";
import LittleMathematician from "./LittleMathematician.png";
import LittlePhilanthropist from "./LittlePhilanthropist.png";
import LittleEvaluator from "images/little-evaluator-logo.png";
import 小作者 from "./小作者.png";
import push_pin from "./push_pin.png";
import photo_camera from "./photo_camera.png";
import list_alt from "./list_alt.png";

export const images: any = {
  Empathy,
  GrossMotorSkills,
  Confidence,
  Creativity,
  ExecutiveFunction,
  GlobalPerspectives,
  EnglishLanguageAndLiteracy,
  EnvironmentalResponsibility,
  FineMotorSkills,
  STEAM,
  GratitudeAndGivingBack,
  Integrity,
  MandarinLanguageAndLiteracy华语听说与读写能力,
  NumeracyAndMathConcepts,
  PerseveranceAndGrit,
  PracticalLifeSkills,
  PublicSpeakingAndAdvocacy,
  SelfRegulation,
  SocialAwareness,
  Interdependence,
  CommunicationSkills,
  LittleCEO,
  LittleChef,
  LittleCreative,
  LittleEngineer,
  LittleEntrepreneur,
  LittleJournalist,
  LittleMathematician,
  LittlePhilanthropist,
  LittleEvaluator,
  小作者,
  push_pin,
  photo_camera,
  list_alt,
};

import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageEight from "../../../../../../../images/children-photo-book/page-8.png";
import PhotoBookPageEightTop from "../../../../../../../images/children-photo-book/page-8-top.png";
import { CaptionPDFComponent } from "./constant";
import {
  PDFScale,
  photoBookPageEightCaptionInfo,
  photoBookPageEightCaptionPositionList,
  photoBookPageEightImageInfo,
  photoBookPageEightImagePositionList,
} from "../photoBookCommonConstant";
import { Image } from "@react-pdf/renderer";
import {
  imageSrc,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";

export const PhotoBookPageEightComponent = ({
  images,
}: {
  images: ImageType[];
}) => {
  const scale = PDFScale;
  const imagePositionList = photoBookPageEightImagePositionList(images, scale);
  const captionPositionList = photoBookPageEightCaptionPositionList(
    images,
    scale
  );
  const imageInfo = photoBookPageEightImageInfo(scale);
  const captionInfo = photoBookPageEightCaptionInfo(scale);

  return (
    <>
      <Image src={imageSrc(PhotoBookPageEight)} style={styles.background} />

      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            key={`image-${imagePositionInfo.src}`}
            src={imageSrc(imagePositionInfo.src)}
            style={{
              position: "absolute",
              top: imagePositionInfo.top,
              left: imageInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
              objectFit: "cover",
            }}
          />
        );
      })}
      <Image src={imageSrc(PhotoBookPageEightTop)} style={styles.background} />

      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionPDFComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionPositionInfo.top}
            left={captionInfo.left}
            width={captionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize}
            textAlign="left"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageEightComponent;

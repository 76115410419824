import { Box } from "@mui/system";
import { API_Project } from "types/project";
import { useMediaQuery, useTheme } from "@mui/material";
import BackpackCardMobile from "./BackpackCardMobile";
const BackpackContentMobile = ({
  projects,
  groupId,
  setProjectCard,
  handleOpen,
  setIsUpdate,
}: {
  projects: API_Project[];
  groupId: number;
  setProjectCard: any;
  handleOpen: any;
  setIsUpdate: any;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        padding: isXs ? 0 : 2,
        display: "flex",
        flexDirection: isXs ? "column" : "row",
        overflowX: isXs ? "hidden" : "scroll",
        // overflowY: "hidden",
        overflowY: isXs ? "scroll" : "none",
        alignItems: isXs ? "flex-start" : "center",
        mx: isXs ? 0 : 5,
        width: "100%",
        // backgroundColor: "purple",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isXs ? "column" : "row",
          gap: isXs ? "12px" : "20px",
          overflowY: isXs ? "scroll" : "none",
          width: "100%",
        }}
      >
        {projects.map((project, index) => {
          return (
            <BackpackCardMobile
              project={project}
              key={index}
              groupId={groupId}
              handleOpen={handleOpen}
              setProjectCard={setProjectCard}
              setIsUpdate={setIsUpdate}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default BackpackContentMobile;

import React from "react";
import { Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Image from "components/Image/Image";
import Logo from "images/logo-2.png";
import PhotoLibraryIcon from "images/photoLibraryIcon";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";

export type ImageFieldProps = {
  image: File | string;
  setImage: React.Dispatch<React.SetStateAction<File | string>>;
};

export const ImageField = ({ image, setImage }: ImageFieldProps) => {
  const openSnack = useSnack();

  const MAX_IMAGE_SIZE_MB = 5; // Set the maximum image size limit in megabytes

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newImage: File | null = event.target.files
      ? event.target.files[0]
      : null;

    if (!newImage) return;

    if (newImage.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      // Display snack message for invalid images
      openSnack(
        `Images should be less than ${MAX_IMAGE_SIZE_MB}MB or compressed.`,
        false
      );
    } else {
      setImage(newImage);
    }

    event.target.value = ""; // Clear the input file value
  };

  return (
    <Box sx={{ pb: 2 }}>
      <input
        type="file"
        id="image-button-image"
        hidden
        onChange={handleImageChange}
      />
      <label
        htmlFor="image-button-image"
        style={{ width: "fit-content", display: "flex" }}
      >
        {image ? (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              mt: "16px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                height: "200px",
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Image
                src={
                  typeof image === "string" ? image : URL.createObjectURL(image)
                }
                alt={Logo}
              />
              <ClearIcon
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setImage("");
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              border: "1px dashed #8C8C8C",
              padding: "24px 12px",
              gap: 1.5,
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            <PhotoLibraryIcon />
            <Typography>{translation.selectDropActivity}</Typography>
          </Box>
        )}
      </label>
    </Box>
  );
};

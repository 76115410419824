
import { tabs } from "./constants";
import { translatedTabs } from "./constants";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { Box, Typography } from "@mui/material";
import { slugify } from "helper/slugify";

import useStore from "store/store";

const ProfilePageMiniDrawerMobile = () => {
  return (
    <Box
      sx={{
        display: { xs: "flex", lg: "none" },
        backgroundColor: `#F6F6F6`,
        width: "100%",
        position: "sticky",
        top: { xs: 56, sm: 64 },
        zIndex: 2,
        flexDirection: "row",
        overflowX: "auto",
        overflowY: "visible",
        "::-webkit-scrollbar": {
          width: 5,
          height: 5,
        },
        gap: 1,
        p: 0.5,
        mb: 0.5,
      }}
    >
      {tabs.map((text, index) => {
        return <Tabs text={text} key={index} />;
      })}
    </Box>
  );
};

const Tabs = ({ text }: { text: string }) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  return (
    <>
      <Box
        sx={{
          border: "1px solid #98C4C5",
          borderRadius: "10px",
          p: "4px 8px",
          paddingRight: 1,
          width: "100%",
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 100ms linear",
          "&:hover": {
            backgroundColor: "rgba(209, 228, 227, 0.37)",
            transition: "all 100ms linear",
          },
          whiteSpace: "nowrap",
          position: "relative",
          textDecoration: "none",
        }}
        onClick={() => {
          const target = document.querySelector(`#${slugify(text)}`);

          if (target) {
            const headerOffset = 80;
            const elementPosition = target.getBoundingClientRect().top;
            const offsetPosition =
              elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PlaylistAddCheckIcon />
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography sx={{ fontSize: "13px", color: "txt.dark2" }}>
              {translatedTabs[text]}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ProfilePageMiniDrawerMobile;

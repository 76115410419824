import { Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import { pageSize, imageSrc, styles } from "../ProjectReportPDF";
import { LessonType } from "../Preview/constant";

import Background from "images/end-of-project-report/one-img-bg.png";
import Wave from "images/end-of-project-report/wave.png";
import { checkIsChineseChar } from "helper/helper";

const templateStyles = StyleSheet.create({
  wrapper: {
    flex: 1,
    margin: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    flex: 1,
    width: 800,
    objectFit: "cover",
    marginVertical: 40,
  },
});

const LT1Image = ({
  lesson,
  gallery,
}: {
  lesson?: LessonType;
  gallery: GalleryImage;
}) => {
  const hyphenationCallback = (word: string) => {
    if (word.length === 1 || !checkIsChineseChar(word)) {
      return [word];
    }
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  };

  Font.registerHyphenationCallback(hyphenationCallback);
  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image
          src={Wave}
          style={[styles.background, styles.backgroundReverse]}
        />

        <View style={templateStyles.wrapper}>
          <Text style={styles.pageTitle}>
            {lesson ? lesson.title : gallery.lesson}
          </Text>

          <Image
            src={imageSrc(
              lesson ? lesson.images[0].file : gallery.images[0].src
            )}
            style={templateStyles.image}
          />

          {checkIsChineseChar(lesson?.caption) ? (
            <Text
              style={{
                textAlign: "center",
                fontFamily: "KaiTi",
              }}
            >
              {lesson ? lesson.caption : gallery.images[0].alt}
            </Text>
          ) : (
            <Text style={styles.textCenter}>
              {lesson ? lesson.caption : gallery.images[0].alt}
            </Text>
          )}
        </View>

        <Image src={Background} style={styles.background} />
      </View>
    </Page>
  );
};

export default LT1Image;

import React, { useEffect, useState } from "react";

import { Avatar, Box, IconButton } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Logo from "images/logo-2-colour.png";
import UploadPhoto from "components/PhotoVideoGalleryComponents/Photo/UploadPhotoModal";

type Props = {
  image: string;
  imageFallbackChar: string;
  setCurrentGroupData: React.Dispatch<React.SetStateAction<any>>;
  width?: ResponsiveStyleValue<number>;
  height?: ResponsiveStyleValue<number>;
};

const GroupAvatar = ({
  image,
  imageFallbackChar,
  setCurrentGroupData,
  width = 350,
  height = 350,
}: Props) => {
  const [currImage, setCurrImage] = useState<string>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedImage, setEditedImage] = useState<File | undefined>();

  useEffect(() => {
    setCurrImage(image);
  }, [image]);

  useEffect(() => {
    if (editedImage) {
      if (editedImage instanceof File) {
        setCurrImage(URL.createObjectURL(editedImage));
        setCurrentGroupData((prev: any) => ({
          ...prev,
          image: editedImage,
        }));
      } else {
        setCurrImage(editedImage);
      }
    }
  }, [editedImage]);

  const handleEditPhoto = () => {
    setEditModalOpen(true);
  };

  const handleCloseEditPhotoModal = () => {
    setEditModalOpen(false);
  };

  return (
    <Box sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}>
      <Avatar
        src={currImage}
        sx={{
          width,
          height,
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "8vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {imageFallbackChar}
      </Avatar>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20%",
          backgroundColor: "primary.main",
          opacity: 0.5,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
            transition: "all 150ms linear",
          },
        }}
      >
        <label htmlFor={`profile-image-button`}>
          <IconButton
            component="span"
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
            onClick={handleEditPhoto}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: "2.5vw",
                color: "primary.light",
                cursor: "pointer",
                transition: "all 50ms linear",
                "&:hover": {
                  color: "txt.secondary",
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box>

      {editModalOpen && (
        <UploadPhoto
          open={editModalOpen}
          handleClose={handleCloseEditPhotoModal}
          imageSrc={currImage || Logo}
          setEditedImage={setEditedImage}
          imageName="avatar.jpg"
        />
      )}
    </Box>
  );
};

export default GroupAvatar;

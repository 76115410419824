import React from "react";
import TabsContainerMobile from "./TabsContainerMobile";

const ChildPortfolioMiniDrawerMobile = ({
  currentTab,
}: {
  currentTab: string;
}) => {
  return <TabsContainerMobile currentTab={currentTab} />;
};

export default ChildPortfolioMiniDrawerMobile;

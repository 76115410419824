import { useEffect, useState } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  WidgetContainer,
  Loading,
  ScrollableContainerStyles,
  MessageCard,
  useWidgetColors,
} from "./Components";
import { translation } from "constants/translation";
import { API_Message } from "types/chat";
import { getUnseenChat } from "api/chat-api";

type Props = {
  setExpandMobile?: (value: string) => void;
  isExpanded: boolean;
  colorIndex: number;
};

const Chat = ({ isExpanded, setExpandMobile, colorIndex }: Props) => {
  const colors = useWidgetColors();
  const color = colors[colorIndex % colors.length];

  const [messages, setMessages] = useState<API_Message[]>();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const messages = await getUnseenChat();
        setMessages(typeof messages !== "string" ? messages : []);
      } catch (error) {
        setMessages([]);
      }
    };

    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WidgetContainer
      sx={{
        backgroundColor: color,
        height: {
          xs: !isExpanded ? "48px" : "100%",
          lg: !isExpanded ? "48px" : "100%",
        },
        minHeight: {
          xs: !isExpanded ? "48px" : "150px",
          lg: !isExpanded ? "48px" : "100%",
        },
        transition: "all 100ms ease-in-out",
      }}
    >
      {messages === undefined ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => setExpandMobile?.(isExpanded ? "" : "chat")}
          >
            <Typography>{translation.messages}</Typography>
            {!!setExpandMobile && (
              <ExpandMoreIcon
                sx={{
                  cursor: "pointer",
                  opacity: 0.5,
                  transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              />
            )}
          </Box>
          {messages.length === 0 ? (
            <Typography sx={{ pt: 1 }}>{translation.noNewMessage}</Typography>
          ) : (
            <Box
              sx={{
                pt: 1,
                ...ScrollableContainerStyles,
                gap: 1,
              }}
            >
              {messages.map((message, index) => (
                <MessageCard key={index} message={message} />
              ))}
            </Box>
          )}
        </>
      )}
    </WidgetContainer>
  );
};

export default Chat;

import Head from "images/head.png";
import Heart from "images/heart-white.png";
import Hands from "images/hand-white.png";

export const HEART_TEXT = "heart";
export const HEAD_TEXT = "head";
export const HANDS_TEXT = "hands";

export type H3_TAB_TYPE = "heart" | "head" | "hands";

export const H3_TABS: H3_TAB_TYPE[] = [HEART_TEXT, HEAD_TEXT, HANDS_TEXT];
export const H3_TABS_IMG = [Heart, Head, Hands];

export const CHILD_DETAIL_TABS = ["Overview", "Photo Album", "Portfolio"];

export const HEART_DEV_SLUGS = [
  "empathy",
  "confidence",
  "creativity",
  "integrity",
  "perseverance-&-grit",
  "self-regulation",
];

export const HEAD_DEV_SLUGS = [
  "gross-motor-skills",
  "executive-function",
  "english-language-&-literacy",
  "fine-motor-skills",
  "steam-(science,-technology,-engineering,-art,-math)",
  "mandarin-language-&-literacy-华语听说与读写能力",
  "numeracy-&-math-concepts",
  "communication-skills",
];

export const HANDS_DEV_SLUGS = [
  "global-perspectives",
  "environmental-responsibility",
  "gratitude-&-giving-back",
  "practical-life-skills",
  "public-speaking-&-advocacy",
  "social-awareness",
  "interdependence",
];

export const ALL_DEV_SLUGS = [
  "empathy",
  "confidence",
  "creativity",
  "integrity",
  "perseverance-&-grit",
  "self-regulation",
  "gross-motor-skills",
  "executive-function",
  "english-language-&-literacy",
  "fine-motor-skills",
  "steam-(science,-technology,-engineering,-art,-math)",
  "mandarin-language-&-literacy-华语听说与读写能力",
  "numeracy-&-math-concepts",
  "communication-skills",
  "global-perspectives",
  "environmental-responsibility",
  "gratitude-&-giving-back",
  "practical-life-skills",
  "public-speaking-&-advocacy",
  "social-awareness",
  "interdependence",
];

import axios from "api/axiosConfig";
import { AxiosError } from "axios";


export const createFavItem = async (language: string, module: number | string, profile: number | string) => {
  try {
    const res = await axios.post(`${language}/teacher/module/favorite/create`, {
        module,
        profile
    });
      const data = await res.data;
      console.log(data);
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteFavItem = async (language: string, module: number | string, profile: number | string) => {
  try {
    const res = await axios.post(`${language}/teacher/module/favorite/delete`, {
      module,
    });
    await res.data;
    return "success";
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Typography, Stack, TextField } from "@mui/material";



import Button from "components/Button/Button";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { API_Create_Question } from "types/prediscoveryquestionnaire";
import { translation } from "constants/translation";
import { createQuestion } from "api/prediscoveryquestionnaire-api";

const CreateQuestionPage = () => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { profileID } = useStore((state) => state);

  const [formData, setFormData] = useState<API_Create_Question>({
    name: "",
  });

  const handleSubmit = async () => {
    //Register auth account
    const res = await createQuestion(formData);

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(`Created ${formData.name} Question successfully!`, true);
      navigate(-1);
    }
  };

  return !!profileID ? (
    <Box sx={{ minHeight: "71vh", p: "16px" }}>
      <Stack
        component="form"
        sx={{ width: "80vw", margin: "auto", my: "16px", alignItems: "center" }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
          {translation.createPreDQQuestion}
        </Typography>

        <TextField
          fullWidth
          required
          label={translation.name}
          value={formData.name}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              name: event.target.value,
            }));
          }}
        />
        {/* <TextField
          sx={TEXT_FIELD_STYLE}
          type="number"
          required
          label={translation.weight}
          value={formData.weight}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              weight: Number(event.target.value),
            }));
          }}
        /> */}

        <Button
          buttonText={translation.submit || "Submit"}
          style={{ marginTop: "20px" }}
          onClick={handleSubmit}
          disabled={formData.name === ""}
        />
      </Stack>
    </Box>
  ) : (
    <Typography
      sx={{
        fontSize: "36px",
        height: "71vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {translation.not_logged_in}
    </Typography>
  );
};

export default CreateQuestionPage;

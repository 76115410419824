import { Box } from "@mui/system";
import { RIGHT_COLUMN_WIDTH } from "../../../constants/constants";
import DateRow from "./DateRow";

const RightColumnHeader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: `${RIGHT_COLUMN_WIDTH}vw`,
        height: "",
      }}
    >
      <DateRow />
    </Box>
  );
};

export default RightColumnHeader;

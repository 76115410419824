import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button as MuiButton,
} from "@mui/material";


const ChoiceDialog = ({
    openDialog,
    handleCloseDialog,
    choiceTitle,
    choiceContext,
    handleLeftButton,
    handleRightButton,
    leftButtonText,
    rightButtonText,
}: {
    openDialog: boolean;
    handleCloseDialog: (e?: any) => void;
    choiceTitle: string;
    choiceContext: string;
    handleLeftButton: (e?: any) => void;
    handleRightButton: (e?: any) => void;
    leftButtonText: string;
    rightButtonText: string;


}) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
        }}>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                // aria-labelledby="choice-dialog-title"
                // aria-describedby="choice-dialog-description"
                sx={{
                    // maxWidth: ''
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <DialogTitle id="choice-dialog-title" sx={{
                        fontSize: "18px", fontWeight: '600', color: "#323031"
                    }}>{choiceTitle}</DialogTitle>
                    <DialogContent
                        sx={{
                            minWidth: "320px",
                            maxWidth: "350px", 
                            // backgroundColor: 'pink'
                            display: 'flex', 
                            justifyContent: 'center',
                            alignItems: 'center, '
                        }}
                    >
                        <DialogContentText id="choice-dialog-description" sx={{

                            fontSize: "14px",
                            fontWeight: "400"
                        }}>
                            {choiceContext}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ padding: "20px", width: "100%" }}>
                        {/* LEFT BUTTON */}

                        {/* <Button
                        buttonText={leftButtonText}
                        arrow={false}
                        style={{
                            // fontSize: "14px",
                            // backgroundColor: "transparent",
                            // color: "var(--txt-secondary)",
                            // padding: "0",
                            // marginRight: "20px",
                            color: "#5C9391",
                            borderRadius: "$px",

                        }}
                        onClick={handleCloseDialog}
                    /> */}
                        <MuiButton variant='outlined' sx={{
                            color: "#5C9391",
                            borderRadius: "4px",
                            width: "100%",
                            textTransform: "none"
                        }} onClick={() => {
                            handleLeftButton()
                        }}>
                            {leftButtonText}
                        </MuiButton>

                        {/* RIGHT BUTTON */}
                        {/* <Button
                        buttonText={rightButtonText}
                        arrow={false}
                        style={{
                            fontSize: "14px",
                            backgroundColor: "var(--txt-secondary)",
                        }}
                        onClick={(e) => {
                            handleRightButton();
                            handleCloseDialog(e);
                        }}
                    /> */}
                        <MuiButton variant='contained' sx={{
                            backgroundColor: "#91C6C5",
                            color: "white",
                            borderRadius: "4px",
                            width: "100%",
                            textTransform: "none"
                        }} onClick={() => {
                            handleRightButton()
                        }}>
                            {rightButtonText}
                        </MuiButton>
                    </DialogActions>
                </Box>

            </Dialog>
        </Box>
    );
};

export default ChoiceDialog;
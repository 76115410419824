import React, { useState, useEffect } from "react";
import { Box, IconButton, Avatar } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Logo from "images/logo-2-colour.png";
import { UserRoleAbbr } from "types/auth";
import useSnack from "hooks/useSnack";
const MAX_FILE_SIZE_MB = 5; // Maximum allowed file size in megabytes

const ImageForm = ({
  image,
  role,
  setFormData,
}: {
  image: string;
  role: UserRoleAbbr;
  setFormData: any;
}) => {
  const [previewImg, setPreviewImg] = useState<string>("");
  const openSnack = useSnack();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      if (selectedFile.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        // File size exceeds the limit
        openSnack(
          `Cannot upload images larger than ${MAX_FILE_SIZE_MB}MB.`,
          false
        );
      } else {
        setFormData((prev: any) => ({
          ...prev,
          image: selectedFile,
        }));
      }
    }
  };

  return (
    <Box sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}>
      <Avatar
        src={image !== "" ? image : Logo}
        sx={{
          width: 150,
          height: 150,
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "8vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {"C"}
      </Avatar>

      <input
        accept="image/*"
        type="file"
        id={`profile-image-button-${role}`}
        hidden
        onChange={handleFileChange}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20%",
          backgroundColor: "primary.main",
          opacity: 0.5,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
            transition: "all 150ms linear",
          },
        }}
      >
        <label htmlFor={`profile-image-button-${role}`}>
          <IconButton
            component="span"
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: 20,
                color: "primary.light",
                cursor: "pointer",
                transition: "all 50ms linear",
                "&:hover": {
                  color: "txt.secondary",
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box>
    </Box>
  );
};

export default ImageForm;

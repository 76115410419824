import {
  Box,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Avatar,
  MenuItem,
  ListSubheader,
} from "@mui/material";

import { Fundamental_Milestone } from "types/project";
import { translation } from "constants/translation";
import useStore from "store/store";

type Props = {
  fundamentalMilestone: Fundamental_Milestone;
  handleDevelopmentalGoalChange: (
    event: SelectChangeEvent,
    instance: Fundamental_Milestone
  ) => void;
  handleMilestoneChange: (
    event: SelectChangeEvent,
    instance: Fundamental_Milestone
  ) => void;
};

const DevelopmentalGoalWithMilestone = ({
  fundamentalMilestone,
  handleDevelopmentalGoalChange,
  handleMilestoneChange,
}: Props) => {
  const { fundamentalDict, fundamentalListToDisplay } = useStore((state) => state);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        alignItems: "center",
      }}
    >
      <FormControl sx={{ width: "75%" }}>
        <InputLabel id="multiple-chip-label-developmental-goal-learning-moment">
          Developmental Goal
        </InputLabel>
        <Select
          labelId="multiple-chip-label-developmental-goal-learning-moment"
          value={
            fundamentalMilestone.fundamental
              ? fundamentalMilestone.fundamental.toString()
              : "-1"
          }
          onChange={(event) => {
            handleDevelopmentalGoalChange(event, fundamentalMilestone);
          }}
          input={
            <OutlinedInput
              id="select-multiple-chip-developmental-goal-learning-moment"
              label="Developmental Goal"
            />
          }
          renderValue={(learningMomentFundamentalId) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              <Chip
                avatar={
                  <Avatar
                    alt={fundamentalDict[learningMomentFundamentalId].name}
                    src={fundamentalDict[learningMomentFundamentalId].image}
                  />
                }
                key={learningMomentFundamentalId}
                label={fundamentalDict[learningMomentFundamentalId].name}
              />
            </Box>
          )}
        >
          {fundamentalListToDisplay.map((fundamental) => {
            return fundamental.sub_fundamentals &&
              fundamental.sub_fundamentals.length > 0 ? (
              <ListSubheader
                key={`${fundamental.id}-sub-header`}
                disableGutters
              >
                {fundamental.name}
              </ListSubheader>
            ) : fundamental.main_fundamental ? (
              <MenuItem
                key={`${fundamental.id}-dev-goal`}
                value={fundamental.id}
              >
                {fundamental.name}
              </MenuItem>
            ) : (
              [
                <ListSubheader
                  key={`${fundamental.id}-sub-header`}
                  disableGutters
                >
                  {fundamental.name}
                </ListSubheader>,
                <MenuItem
                  key={`${fundamental.id}-dev-goal`}
                  value={fundamental.id}
                >
                  {fundamental.name}
                </MenuItem>,
              ]
            );
          })}
        </Select>
      </FormControl>
      {fundamentalDict[
        fundamentalMilestone.fundamental ? fundamentalMilestone.fundamental : -1
      ].milestones.length > 0 ? (
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="multiple-chip-label-milestone">
            {translation.milestone}
          </InputLabel>

          <Select
            labelId="multiple-chip-label-milestone"
            value={
              fundamentalMilestone.milestone
                ? fundamentalMilestone.milestone?.toString()
                : "-1"
            }
            onChange={(event) => {
              handleMilestoneChange(event, fundamentalMilestone);
            }}
            input={
              <OutlinedInput
                id="select-multiple-chip-milestone-learning-moment"
                label="Milestones"
              />
            }
            renderValue={(selectedMilestoneId) => {
              const filteredMilestone = fundamentalDict[
                fundamentalMilestone.fundamental
                  ? fundamentalMilestone.fundamental
                  : "-1"
              ].milestones.filter(
                (milestone) => milestone.id.toString() === selectedMilestoneId
              );
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  <Chip
                    key={selectedMilestoneId}
                    label={
                      filteredMilestone.length > 0
                        ? filteredMilestone[0].name
                        : "Please select a milestone"
                    }
                  />
                </Box>
              );
            }}
          >
            {fundamentalDict[
              fundamentalMilestone.fundamental
                ? fundamentalMilestone.fundamental
                : "-1"
            ].milestones.map((milestone) => (
              <MenuItem key={milestone.id} value={milestone.id}>
                {milestone.name}
              </MenuItem>
            ))}
            <MenuItem disabled value="-1">
              Please select a milestone
            </MenuItem>
          </Select>
        </FormControl>
      ) : null}
    </Box>
  );
};

export default DevelopmentalGoalWithMilestone;

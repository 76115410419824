import React from "react";
import useStore from "store/store";
import { Box, Tooltip, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LandingScreen from "./LandingScreen";
import ConceptScreen from "./ConceptScreen";
import ModuleTag from "components/ModuleTag/ModuleTag";
import { translation } from "constants/translation";
import NextActionButton from "../ProjectSummarySideMenu/NextActionButton";
import { useInsights } from "hooks/projects";

const OverviewScreen = () => {
  const {
    profileDetails,
    currentProject,
  } = useStore((state) => state);

  const { isInBag, isInSchedule } = useInsights();

  return (
    <>
      <DetailsSection />
      <LandingScreen />

      {profileDetails.role !== "CE" && (
        <Box
          sx={{
            display: { xs: "flex", lg: "none" },
            flex: 1,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <NextActionButton
            currentProject={currentProject}
            isInBag={isInBag()}
            isInSchedule={isInSchedule()}
            sx={{ mt: 0, mb: 1 }}
          />
        </Box>
      )}
      <ConceptScreen />
    </>
  );
};

const DetailsSection = () => {
  const { currProject, currLessons } = useStore((state) => ({
    currProject: state.currentProject,
    currLessons: state.currentLessons,
  }));

  return (
    <Box
      sx={{
        p: "30px 30px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <ModuleTag projModule={currProject.module} />
        <Box />
      </Box>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "20px",
          color: "txt.secondary",
          mb: "10px",
          mt: "-10px",
          textAlign: "left",
        }}
      >
        {currProject.name}
      </Typography>

      <Typography
        sx={{
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <Tooltip title="Project Duration">
          <AccessTimeIcon />
        </Tooltip>
        {`${currLessons.lessons.length} ${
          currLessons.lessons.length > 1
            ? translation.lessons
            : translation.lesson
        }`}
      </Typography>
    </Box>
  );
};

export default OverviewScreen;

import { useState, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material"
import useStore from "store/store";
import computeTeacherDashboardSteps from "./computeTeacherDashboardSteps";
import computeTeacherProjectSteps from "./computeProjectPageSteps";
import computeAdminDashboardSteps from "./computeAdminDashboardSteps";
import computeAdminPageSteps from "./computeAdminPageSteps";
import WalkthroughModal from "./WalkthroughModal";
import { Step } from "./types";

type Props = {
  mode: "dashboard" | "project-summary" | "admin-page";
};

const WalkthroughSteps = ({ mode }: Props) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));


  useEffect(() => {
    if (!profileDetails.role) {
      return
    }

    const hasWalkthroughCompleted = localStorage.getItem(
      `walkthrough-${mode}-${profileDetails.role}`
    );
    if (!hasWalkthroughCompleted) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [mode, profileDetails.role]);

  const handleClose = () => {
    setOpen(false);
      localStorage.setItem(
        `walkthrough-${mode}-${profileDetails.role}`,
        "completed"
      );
  }
  const handleNextStep = () => {
    if (step >= steps.length - 1) {
      handleClose();
      return;
    }

    const isActionRequiredForNext = steps[step + 1]().mobileMenu;

    let mobileMenuElement: HTMLElement | null = null;
    let mobileMenuCloseElement: HTMLElement | null = null;
    if (mode === "dashboard") {
      mobileMenuElement = document.getElementById("mobile-menu-button");
      mobileMenuCloseElement = document.getElementById(
        "mobile-menu-close-button"
      );
    }

    const openCondition =
      isActionRequiredForNext && mobileMenuElement?.ariaExpanded === "false";
    const closeCondition =
      !isActionRequiredForNext && mobileMenuElement?.ariaExpanded === "true";

    if (isLg && mobileMenuElement && openCondition) {
      mobileMenuElement?.click();
    } else if (isLg && mobileMenuCloseElement && closeCondition) {
      mobileMenuCloseElement?.click();
    } else {
      setStep((step) => step + 1);
    }

    if (isLg && (openCondition || closeCondition)) {
      setTimeout(() => {
        setStep((step) => step + 1);
      }, 200);
    }
  };

  const handleBackStep = () => {
    setStep((step) => step - 1);
  };

  let steps: (() => Step)[] = [];
  if (mode === "dashboard" && profileDetails.role === "TR") {
    steps = computeTeacherDashboardSteps(
      setOpen,
      handleBackStep,
      handleNextStep,
      handleClose,
      isSm,
      isLg
    );
  } else if (mode === "dashboard" && profileDetails.role === "AD") {
    steps = computeAdminDashboardSteps(
      setOpen,
      handleBackStep,
      handleNextStep,
      handleClose,
      isSm,
      isLg
    );
  } else if (mode === "project-summary" && profileDetails.role === "TR") {
    steps = computeTeacherProjectSteps(handleNextStep, handleClose, isLg);
  } else if (mode === "admin-page" && profileDetails.role === "AD") {
    steps = computeAdminPageSteps(handleNextStep, handleClose, isLg, isSm);
  }

  if (!steps.length) {
    return null;
  }

  const activeStep = steps[step];

  return <WalkthroughModal isOpen={open} activeStep={activeStep()} />;
};

export default WalkthroughSteps;

export const DASHBOARD_TEXT = "My Dashboard";

export const LESSON_LIBRARY_TEXT = "Curriculum";
export const LIBRARY_TEXT = "Lesson Plan Library";
export const ASSESSMENT = "Assessments";
export const PORTFOLIOS_TEXT = "Portfolios";
export const MY_CHILDREN_TEXT = "My Children's Portfolios";
export const PROFILE_TEXT = "Profile";
export const PHOTOS_TEXT = "Photos";
export const NOTICES_TEXT = "Notices";

export const LITTLE_EVALUATOR_TEXT = "Little Evaluator";
export const CALENDAR_TEXT = "Calendar";

export const VISUAL_TIMETABLE_TEXT = "Visual Timetable";
export const SCHEDULER_TEXT = "Scheduler";

export const MY_CLASSES_TEXT = "My Classes";
export const CLASS_DETAIL = "Class Detail";
export const ATTENDANCE = 'Attendance';

// TTA
export const HOME = "teacher-training-academy";
export const MY_PROGRESS_TEXT = "My Progress";
export const OUR_STORY_TEXT = "Our Story";
export const COMMUNITY_TEXT = "Community";

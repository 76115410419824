import React from "react";
import {
  Box,
  Drawer,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Image from "../../../components/Image/Image";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";

const ConceptScreenDrawer = ({
  isDrawerOpen,
  handleClose,
  projectCards,
  developmentIndex,
  setDevelopmentIndex,
}: {
  isDrawerOpen: boolean;
  handleClose: () => void;
  projectCards: any;
  developmentIndex: number;
  setDevelopmentIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const goal = projectCards[developmentIndex];
  const objectives = goal?.objectives;
  const subFundamentals = goal?.subFundamentals;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log(projectCards);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="right"
        open={isDrawerOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "50vw",
            minWidth: "270px",
            maxWidth: "90vw",
          },
          sx: {
            boxShadow: 3,
          },
        }}
      >
        {
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    m: 2,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <ArrowBackIcon
                      sx={{
                        fontSize: "24px",
                        weight: "400",
                        color: "black",
                      }}
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      weight: "600",
                      color: "black",
                      ml: 2,
                    }}
                  >
                    Development Goal
                  </Typography>
                </Box>
                <Divider sx={{ borderBottomWidth: 2 }} />

                {goal && goal?.name ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isXs ? "column" : "row",
                      alignItems: "center",
                      justifyContent: isXs ? "center" : "flex-start",
                      m: 3,
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: 66,
                        height: 66,
                        minWidth: 66,
                        minHeight: 66,
                        backgroundColor: "#C4E4DF",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Image src={goal?.image} alt={goal?.name} />
                      </Box>
                    </Box>

                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: isXs ? "14px" : "16px",
                        color: "#5C9391",
                        padding: isXs ? "4px 7px" : "4px 12px",
                        borderRadius: "100px",
                        border: "1px solid #91C6C5",
                        backgroundColor: "#EBF8F8",
                        textAlign: "center",
                        p: "4px 10px",
                      }}
                    >
                      {goal?.name}
                    </Typography>
                  </Box>
                ) : null}
                {/* subfundamentals and objectives */}
                {goal && goal?.name ? (
                  <Box
                    sx={{
                      ml: 2,
                      mr: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {subFundamentals &&
                      subFundamentals?.length > 0 &&
                      subFundamentals.map(
                        (subFundamental: any, index: number) => (
                          <Box
                            sx={{
                              color: "#5C9391",
                            }}
                            key={index}
                          >
                            <Typography
                              sx={{
                                ml: 1,
                                fontSize: isXs ? "14px" : "16px",
                                fontWeight: 450,
                              }}
                            >
                              {subFundamental.name}
                            </Typography>

                            {subFundamental.objectives.map(
                              (objective: string, index: number) => (
                                <Box sx={{ display: "flex" }} key={index}>
                                  <Typography
                                    sx={{
                                      fontSize: isXs ? "14px" : "16px",
                                      textAlign: "center",
                                      color: "black",
                                    }}
                                  >
                                    •
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontSize: isXs ? "14px" : "16px",
                                      fontWeight: 400,
                                      color: "black",
                                      pr: 1,
                                    }}
                                    key={index}
                                  >
                                    {objective}
                                  </Typography>
                                </Box>
                              )
                            )}
                          </Box>
                        )
                      )}
                    {!subFundamentals || (subFundamentals?.length == 0 && null)}

                    {objectives &&
                      objectives?.length > 0 &&
                      objectives[0] != "" && (
                        <Box>
                          {objectives.map(
                            (objective: string, index: number) => (
                              <Box sx={{ display: "flex" }} key={index}>
                                <Typography
                                  sx={{
                                    fontSize: isXs ? "14px" : "16px",
                                    textAlign: "center",
                                    color: "black",
                                  }}
                                >
                                  •
                                </Typography>

                                <Typography
                                  sx={{
                                    fontSize: isXs ? "14px" : "16px",
                                    fontWeight: 400,
                                    pr: 1,
                                  }}
                                  key={index}
                                >
                                  {objective}
                                </Typography>
                              </Box>
                            )
                          )}
                        </Box>
                      )}

                    {!objectives ||
                      objectives?.length == 0 ||
                      (objectives[0] == "" && null)}
                  </Box>
                ) : null}
              </Box>
              {/* row of buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: 3,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ color: "black", borderColor: "#C9C9C9" }}
                  disabled={developmentIndex == 0}
                  onClick={() => {
                    setDevelopmentIndex((prev) => {
                      return (prev -= 1);
                    });
                  }}
                >
                  <ArrowBackIcon sx={{ mr: 1 }} />
                  <Typography
                    sx={{
                      color: developmentIndex == 0 ? "#C9C9C9" : "black",
                      textTransform: "none",
                    }}
                  >
                    Back
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor:
                      developmentIndex == projectCards.length - 1
                        ? "#C9C9C9"
                        : "#91C6C5",
                    "&:hover": {
                      backgroundColor: "#7FBFB9",
                    },
                  }}
                  disabled={developmentIndex == projectCards.length - 1}
                  onClick={() => {
                    setDevelopmentIndex((prev) => {
                      return (prev += 1);
                    });
                  }}
                >
                  <Typography
                    sx={{ mr: 1, color: "white", textTransform: "none" }}
                  >
                    Next
                  </Typography>
                  <ArrowForwardIcon sx={{ color: "white" }} />
                </Button>
              </Box>
              {/* end of button rows */}
            </Box>
          </>
        }
      </Drawer>
      <Backdrop isDrawerOpen={isDrawerOpen} handleClose={handleClose} />
    </>
  );
};

export default ConceptScreenDrawer;

export const Backdrop = ({
  isDrawerOpen,
  handleClose,
}: {
  isDrawerOpen: boolean;
  handleClose: () => void;
}) => (
  <Box
    className="drawer-overlay"
    sx={{
      position: "fixed",
      backgroundColor: "rgba(0,0,0,0.4)",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1150,
      pointerEvents: isDrawerOpen ? "auto" : "none",
      opacity: isDrawerOpen ? 0.2 : 0,
      transition: "all 200ms ease-in-out",
    }}
    onClick={() => {
      handleClose();
    }}
  />
);

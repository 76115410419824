import UploadAdapter from './UploadAdapter';

// const Plugin = require('@ckeditor/ckeditor5-core/src/plugin');
// const FileRepository = require('@ckeditor/ckeditor5-upload/src/filerepository');

// export default class UploadAdapterPlugin extends Plugin {
//   static get requires() {
//     return [FileRepository];
//   }

//   init() {
//     this.editor.plugins.get('FileRepository').createUploadAdapter = (
//       loader: any
//     ) => {
//       return new UploadAdapter(loader);
//     };
//   }
// }

const UploadAdapterPlugin = (editor: any) => {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new UploadAdapter(loader);
  };
};

export default UploadAdapterPlugin;

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import HelpPageMiniDrawer from "./HelpPageMiniDrawer";
const MainHelpPage = () => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <HelpPageMiniDrawer />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainHelpPage;

import { useEffect, useState } from "react";
import useStore from "store/store";
import { getGalleryByProject } from "api/gallery-api";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { GalleryImage } from "components/ProjectSummaryScreen/ProjectSummaryScreen";
import Preview from "components/ProjectReportPDF/Preview/Preview";
import { Box, Typography } from "@mui/material";
import pLimit from "p-limit";

const limit = pLimit(5);

const ProjectReportScreen = () => {
  const [gallery, setGallery] = useState<GalleryImage[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { projID, lessons, currGroup, currLanguage } = useStore((state) => ({
    projID: state.currentProject.id,
    lessons: state.currentLessons.lessons,
    currGroup: state.currGroup,
    currLanguage: state.currLanguage,
  }));

  useEffect(() => {
    if (projID === -1 || currGroup.id === -1) return;

    const initialiseGallery = async () => {
      try {
        setIsLoaded(false);

        const data = await getGalleryByProject(
          currLanguage,
          projID.toString(),
          localStorage.getItem("access"),
          localStorage.getItem("curr_group_id")
        );

        if (typeof data !== "string") {
          const newImagesList: GalleryImage[] = [];
          const lessonList: string[] = [];

          // Process all images first before filtration (as you suggested)
          const processedImageList = await Promise.all(
            data.map(async (d) => {
              const processedImages = await Promise.all(
                d.images.map(async (img: any, imageIndex: number) => {
                  return limit(async () => {
                    try {
                      let localImage = await fetch(img.image, {
                        headers: {
                          'Cache-Control': 'no-cache',
                          'Pragma': 'no-cache',
                          "Content-Type": "image/*",
                          "Access-Control-Allow-Origin": "*",
                        },
                      }).then((res) => res.blob());

                      // Handle "image/mpo" conversion
                      if (localImage.type === "image/mpo") {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");
                        const imgEl = new Image();

                        await new Promise<void>((resolve) => {
                          imgEl.onload = () => {
                            canvas.width = imgEl.width;
                            canvas.height = imgEl.height;
                            ctx?.drawImage(imgEl, 0, 0);
                            resolve();
                          };
                          imgEl.src = URL.createObjectURL(localImage);
                        });

                        const jpegBlob = await new Promise<Blob>((resolve) => {
                          canvas.toBlob(
                            (blob) => resolve(blob!),
                            "image/jpeg",
                            0.9
                          );
                        });

                        localImage = jpegBlob;
                      }

                      const localImageUrl = URL.createObjectURL(localImage);

                      return {
                        key: img.id ?? imageIndex, // Use imageIndex as a fallback if img.id is undefined
                        src: localImageUrl, // Blob URL
                        width: img.width,
                        height: img.height,
                        alt: img.caption,
                      };
                    } catch (err) {
                      return img;
                    }
                  });
                })
              );

              return {
                ...d,
                images: processedImages,
              };
            })
          );

          const filteredImageList = processedImageList.filter((d) => {
            return d.lesson && d.activity && d.images.length > 0;
          });

          // Group the filtered images by lesson
          filteredImageList.forEach((d) => {
            let lessonName = d.lesson!;
            let index = lessonList.indexOf(lessonName);

            if (index === -1) {
              lessonList.push(lessonName);
              newImagesList.push({ lesson: lessonName, images: [] });
              index = newImagesList.length - 1;
            }

            d.images.forEach((img) => {
              newImagesList[index].images.unshift(img);
            });
          });

          setGallery(newImagesList);
        }
      } catch (err) {
      } finally {
        setIsLoaded(true);
      }
    };

    initialiseGallery();

    return () => {
      // Clean up object URLs to prevent memory leaks
      gallery.forEach((imageList) => {
        imageList.images.forEach((image) => {
          URL.revokeObjectURL(image.src);
        });
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projID, currLanguage, currGroup]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        py: "30px",
        position: "relative",
      }}
    >
      {isLoaded ? (
        lessons.length !== 0 ? (
          <Preview gallery={gallery} />
        ) : (
          <Typography sx={{ textAlign: "center" }}>
            No lessons available for this project.
          </Typography>
        )
      ) : (
        <>
          <LoadingIndicator />
          <Typography sx={{ textAlign: "center" }}>
            Please Wait While We Load Your Project Report
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ProjectReportScreen;

import React from "react";
import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageThree from "../../../../../../../images/children-photo-book/page-3.png";
import Image from "../../../../../../../components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageThreeCaptionInfo,
  photoBookPageThreeImageInfo,
} from "../photoBookCommonConstant";

export const PhotoBookPageThreeComponent = ({
  images,
  scale,
}: {
  images: ImageType[];
  scale: number;
}) => {
  const imageInfo = photoBookPageThreeImageInfo(scale);
  const captionInfo = photoBookPageThreeCaptionInfo(scale);
  return (
    <>
      <Image
        src={PhotoBookPageThree}
        alt={"photo-book-page-3"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      <Image
        src={images[0].link}
        alt={"photo-book-page-1"}
        objectFit="cover"
        style={{
          position: "absolute",
          top: imageInfo.top,
          left: imageInfo.left,
          width: imageInfo.width,
          height: imageInfo.height,
        }}
      />
      <CaptionComponent
        top={captionInfo.top}
        left={captionInfo.left}
        width={captionInfo.width}
        caption={images[0].caption}
        clampLine={captionInfo.clampLine}
        fontSize={captionInfo.fontSize}
        textAlign="center"
      />
    </>
  );
};

export default PhotoBookPageThreeComponent;

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { useNavigate, useParams } from "react-router-dom";

import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import AnalyticModuleListTable from "./AnalyticModuleListTable";
import { getAllModulesForManagePage } from "api/teacher-api";
import useStore from "store/store";
import {
  API_Teacher_Module_Analytic,
  API_Teacher_Module_Manage,
  API_Teacher_Module_Visualization,
  ViewMode,
} from "types/teacher";
import {
  ACCESS_CONTROL,
  INSIGHTS,
  VISUALIZATION,
} from "constants/staticConstant";
import ManageModuleListTable from "./ManageModuleListTable";
import { Query_Data } from "types/project";
import VisualizationPage from "./VisualizationComponents/VisualizationPage";

const ManageTeacherModulePage = () => {
  const { pageModeSlug } = useParams();

  const { currLanguage, role } = useStore((state) => ({
    // moduleList: state.moduleList,
    // setModuleList: state.setModuleList,
    currLanguage: state.currLanguage,
    role: state.profileDetails.role,
  }));
  const [moduleList, setModuleList] = useState<
    API_Teacher_Module_Analytic[] | API_Teacher_Module_Manage[]
  >([]);
  const [moduleVisualizationInfo, setModuleVisualizationInfo] =
    useState<API_Teacher_Module_Visualization>({
      module_avg_rating_list: [],
      demographic_info: [],
    });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });

  const [selected, setSelected] = useState<string[]>([]);

  const updateData = async () => {
    setIsLoaded(false);
    const res = await getAllModulesForManagePage(
      currLanguage,
      pageModeSlug! as ViewMode,
      queryData
    );
    if (typeof res !== "string") {
      if (pageModeSlug === VISUALIZATION) {
        setModuleVisualizationInfo(res as API_Teacher_Module_Visualization);
      } else {
        setModuleList(
          res as API_Teacher_Module_Analytic[] | API_Teacher_Module_Manage[]
        );
      }
    }
    setIsLoaded(true);
  };

  useEffect(() => {
    if (queryData.query === null) return;

    const searchTimeout = setTimeout(async () => {
      if (queryData.query !== "") {
        setIsLoaded(false);
      }
      updateData();
    }, 1200);

    return () => clearTimeout(searchTimeout);
  }, [queryData.query]);

  useEffect(() => {
    pageModeSlug && updateData();
  }, [pageModeSlug, queryData.startDate, queryData.endDate]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          px: 3,
          py: 4,
          mx: "60px",
        }}
      >
        <FirstRow>{null}</FirstRow>

        {/* Bulk action */}
        {pageModeSlug !== VISUALIZATION && (
          <SecondRow
            moduleList={moduleList as API_Teacher_Module_Manage[]}
            setModuleList={
              setModuleList as React.Dispatch<
                React.SetStateAction<API_Teacher_Module_Manage[]>
              >
            }
            queryData={queryData}
            setQueryData={setQueryData}
            selected={selected}
          />
        )}

        {!isLoaded ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIndicator />
          </Box>
        ) : pageModeSlug === ACCESS_CONTROL ? (
          <ManageModuleListTable
            moduleList={moduleList as API_Teacher_Module_Manage[]}
            setModuleList={
              setModuleList as React.Dispatch<
                React.SetStateAction<API_Teacher_Module_Manage[]>
              >
            }
            setSelected={setSelected}
          />
        ) : pageModeSlug === INSIGHTS ? (
          <AnalyticModuleListTable
            moduleList={moduleList as API_Teacher_Module_Analytic[]}
            setModuleList={
              setModuleList as React.Dispatch<
                React.SetStateAction<API_Teacher_Module_Analytic[]>
              >
            }
            setSelected={setSelected}
          />
        ) : (
          <VisualizationPage
            moduleVisualizationInfo={moduleVisualizationInfo}
          />
        )}
      </Box>
    </Box>
  );
};

export default ManageTeacherModulePage;

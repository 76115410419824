import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import { translation } from "constants/translation";
import {
  CURLANG,
  TEACHER_MODULE_PAGE,
} from "constants/url";

const BackButton = ({ handleClose }: { handleClose: () => void }) => {
  const navigate = useNavigate();
  const { moduleSlug } = useParams();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        cursor: "pointer",
        color: "#5C9391",
      }}
      onClick={() => {
        handleClose();
        navigate(
          `${CURLANG(currLanguage)}/${TEACHER_MODULE_PAGE(
            moduleSlug ? moduleSlug : ""
          )}`
        );
      }}
    >
      <ArrowBackIcon sx={{ fontSize: "14px" }} />
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "450",
          lineHeight: 1.57
        }}
      >
        {translation.back}
      </Typography>
    </Box>
  );
};

export default BackButton;

import { FC } from "react";
import { Box, Modal } from "@mui/material";
import { ModalProps } from "./types";
import {
  ModalContainer,
  Title,
  Description,
  ActionButtonContainer,
  CancellationButton,
  CancellationButtonText,
  ConfirmationButton,
  ConfirmationButtonText,
} from "./styles";

const PermissionModal: FC<ModalProps> = ({
  title,
  description,
  isOpen,
  setIsOpen,
  onClose,
  onConfirm,
  onConfirmLabel,
  onCancelLabel,
}) => {
  return (
    <Modal
      open={isOpen}
      disableAutoFocus={true}
      onClose={onClose}
      BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
          setIsOpen(false);
        },
      }}
    >
      <ModalContainer>
        <Box>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Box>
        <ActionButtonContainer>
          <CancellationButton variant="outlined" onClick={onClose}>
            <CancellationButtonText>{onCancelLabel}</CancellationButtonText>
          </CancellationButton>
          <ConfirmationButton onClick={onConfirm}>
            <ConfirmationButtonText>{onConfirmLabel}</ConfirmationButtonText>
          </ConfirmationButton>
        </ActionButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default PermissionModal;

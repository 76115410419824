import React from "react";
import Image from "components/Image/Image";
import PhotoBookCoverBack from "images/children-photo-book/photo-book-cover-back.png";
import { photoBookImageStyle } from "./constant";

const PhotoBookCoverBackComponent = () => {
  return (
    <Image
      src={PhotoBookCoverBack}
      alt={"photo-book-cover-front"}
      objectFit="cover"
      style={{ ...photoBookImageStyle }}
    />
  );
};

export default PhotoBookCoverBackComponent;

import { Box, Typography, Modal, Backdrop, Fade } from "@mui/material";

import { translation } from "constants/translation";
import useStore from "store/store";
import Button from "components/Button/Button";
import Image from "components/Image/Image";
import CongratulationIcon from "images/congraz.png";

const EligibleCertificateModal = () => {
  const {
    displayObtainedCertificateModalInfo,
    setDisplayObtainedCertificateModalInfo,
  } = useStore((state) => ({
    displayObtainedCertificateModalInfo:
      state.displayObtainedCertificateModalInfo,
    setDisplayObtainedCertificateModalInfo:
      state.setDisplayObtainedCertificateModalInfo,
  }));

  const handleClose = () => {
    setDisplayObtainedCertificateModalInfo({
      isOpen: false,
      certificateName: "",
    });
  };
  const open = displayObtainedCertificateModalInfo.isOpen;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(49,47,48,0.4)",
        },
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 500,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* EMOTICON */}
            <Image
              src={CongratulationIcon}
              alt={translation.keepGoing || "Keep Going"}
              style={{ width: 100, height: 100 }}
            />

            {/* Great! */}
            <Typography sx={{ fontSize: 30, fontWeight: 500 }}>
              {translation.congratulation}
            </Typography>
            <Typography sx={{ fontSize: 20, textAlign: "center" }}>
              {translation.formatString(
                translation.congrazObtainCertz ||
                  "Congratulation! You have obtain {certificateName}! Check it out in your profile!",
                {
                  certificateName:
                    displayObtainedCertificateModalInfo.certificateName,
                }
              )}
            </Typography>

            {/* Okay button */}
            <Button
              buttonText={translation.okay || "Okay"}
              arrow={false}
              style={{
                display: "flex",
                width: "100%",
                textAlign: "center",
              }}
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EligibleCertificateModal;

import React, { useEffect, useState } from "react";
import { Box, TextField, SelectChangeEvent } from "@mui/material";

import {
  API_Activity_Instruction,
  API_Lesson_Learning_Moment,
} from "types/project";
import { translation } from "constants/translation";
import DevelopmentalGoalWithMilestone from "./DevelopmentalGoalWithMilestoneFIeld";

type Props = {
  activityInstructionLearningMoment: API_Lesson_Learning_Moment;
  activityInstructionId: string;
  activityInstructions: API_Activity_Instruction[];
  setActivityInstructions: React.Dispatch<
    React.SetStateAction<API_Activity_Instruction[]>
  >;
};

const ActivityInstructionLearningMomentField = ({
  activityInstructionLearningMoment,
  activityInstructionId,
  activityInstructions,
  setActivityInstructions,
}: Props) => {
  const [learningMomentTextFields, setLearningMomentTextFields] = useState({
    name: activityInstructionLearningMoment.name,
    description: activityInstructionLearningMoment.description,
    photo_instruction: activityInstructionLearningMoment.photo_instruction,
    photo_caption: activityInstructionLearningMoment.photo_caption,
  });

  useEffect(() => {
    const inputNameTimeout = setTimeout(async () => {
      const updatedActivityInstructions = activityInstructions.map(
        (instruction) => {
          if (instruction.id.toString() === activityInstructionId) {
            return {
              ...instruction,
              learning_moment: {
                ...instruction.learning_moment,
                name: learningMomentTextFields.name,
                description: learningMomentTextFields.description,
                photo_instruction: learningMomentTextFields.photo_instruction,
                photo_caption: learningMomentTextFields.photo_caption,
              },
            };
          }
          return instruction;
        }
      );

      setActivityInstructions(updatedActivityInstructions);
    }, 500);
    return () => clearTimeout(inputNameTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    learningMomentTextFields.name,
    learningMomentTextFields.description,
    learningMomentTextFields.photo_instruction,
    learningMomentTextFields.photo_caption,
  ]);

  const handleDevelopmentalGoalChange = (event: SelectChangeEvent) => {
    const fundamentalId = Number(event.target.value);

    const updatedActivityInstructions = activityInstructions.map(
      (instruction) => {
        if (instruction.id.toString() === activityInstructionId) {
          return {
            ...instruction,
            learning_moment: {
              ...instruction.learning_moment,
              fundamental: fundamentalId,
            },
          };
        }
        return instruction;
      }
    );

    setActivityInstructions(updatedActivityInstructions);
  };

  const handleMilestoneChange = (event: SelectChangeEvent) => {
    const milestoneId = Number(event.target.value);

    const updatedActivityInstructions = activityInstructions.map(
      (instruction) => {
        if (instruction.id.toString() === activityInstructionId) {
          return {
            ...instruction,
            learning_moment: {
              ...instruction.learning_moment,
              milestone: milestoneId,
            },
          };
        }
        return instruction;
      }
    );

    setActivityInstructions(updatedActivityInstructions);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 3 }}>
      <DevelopmentalGoalWithMilestone
        fundamentalMilestone={activityInstructionLearningMoment}
        handleMilestoneChange={handleMilestoneChange}
        handleDevelopmentalGoalChange={handleDevelopmentalGoalChange}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            gap: "12px",
          }}
        >
          <TextField
            label={translation.question}
            required
            fullWidth
            value={learningMomentTextFields.name}
            onChange={(event) => {
              setLearningMomentTextFields((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
          />
          <TextField
            label={translation.description}
            fullWidth
            value={learningMomentTextFields.description}
            onChange={(event) => {
              setLearningMomentTextFields((prev) => ({
                ...prev,
                description: event.target.value,
              }));
            }}
          />
          <TextField
            label={translation.photoInstruction}
            fullWidth
            value={learningMomentTextFields.photo_instruction}
            onChange={(event) => {
              setLearningMomentTextFields((prev) => ({
                ...prev,
                photo_instruction: event.target.value,
              }));
            }}
          />
          <TextField
            inputProps={{ maxLength: 120 }}
            label={translation.photoCaption}
            fullWidth
            value={learningMomentTextFields.photo_caption}
            onChange={(event) => {
              setLearningMomentTextFields((prev) => ({
                ...prev,
                photo_caption: event.target.value,
              }));
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityInstructionLearningMomentField;

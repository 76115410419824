import { useState, useEffect, useRef, useLayoutEffect } from "react";

// } from "api/project-api";

import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/utils";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import useDialog from "hooks/useDialog";
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import AvatarComponent from "containers/SiteAdminPage/ManagementPage/components/AvatarComponent";
import useSnack from "hooks/useSnack";

import Logo from "images/logo-2-colour.png";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import EditIcon from "@mui/icons-material/Edit";

import {
  DataGrid,
  GridRowModel,
  GridRowParams,
  GridEventListener,
  MuiEvent,
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridRowId,
  GridCellParams,
  GridColumns,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";

import {
  Avatar,
  Box,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import {
  updateModule,
  updateCategory,
  deleteCategory,
  deleteModule,
  extractNumericId,
} from "api/project-api";

const ManageModulesModalContent = ({
  projectCategories,
  populateProjectCategories,
}: {
  projectCategories: any;
  populateProjectCategories: any;
}) => {
  const openSnack = useSnack();
  const [imageEdits, setImageEdits] = useState<any>({});
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "",
    confirmDelete: false,
  });
  const [rowId, setRowId] = useState<any>("");

  const [warningContextState, setWarningContextState] = useState<string>("");
  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Module?",
    warningContext: warningContextState,
    setWarningBody,
  };

  const handleDelete = (id: number, warningBodyTitle: string) => {
    const moduleOrCategory = warningBodyTitle.split(" ")[1];

    if (moduleOrCategory == "module") {
      const deleteModuleResponse = async (id: any) => {
        const res = await deleteModule(String(id));
        if (res.success) {
          openSnack("Deleted Module Successfully", true);
          setRows(rows.filter((row) => row.id !== rowId));
        } else {
          openSnack("Error while deleting module", false);
        }
      };
      deleteModuleResponse(id);
    } else if (moduleOrCategory == "category") {
      const deleteCategoryResponse = async (id: any) => {
        const res = await deleteCategory(String(id));
        if (res.success) {
          openSnack("Deleted Category Successfully", true);
          setRows(rows.filter((row) => row.id !== rowId));
        } else {
          openSnack("Error while deleting category", false);
        }
      };
      deleteCategoryResponse(id);
    }
    setTimeout(() => {
      populateProjectCategories();
    }, 500);
  };

  useEffect(() => {
    if (warningBody.confirmDelete === true && warningBody.id != -1) {
      handleDelete(warningBody.id, warningBody.warningTitle);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);

  const [rows, setRows] = useState<any[]>([]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const processRowUpdateBody = (newRow: GridRowModel) => {
    //call update api

    // Remove groups since it's handle separately
    const body = Object.keys(newRow)
      .filter((key: string) => key != "groups")
      .reduce((acc: any, key: string) => {
        acc[key] = newRow[key];
        return acc;
      }, {});

    return body;
  };

  const processRowUpdate = async (newRow: any) => {
    const body = processRowUpdateBody(newRow);

    const imageEdit = imageEdits[body.id];
    if (imageEdit) {
      body.image = imageEdit;
    }

    if (body.type === "Category") {
      const updateCategoryResponse = async (body: any, accessToken: any) => {
        const res = await updateCategory(body, accessToken);
        if (res.success) {
          openSnack("Updated Category Successfully", true);
        } else {
          openSnack("Error while updating category", false);
        }
      };
      updateCategoryResponse(body, localStorage.getItem("access"));
    } else if (body.type === "Module") {
      const updateModuleResponse = async (body: any, accessToken: any) => {
        const res = await updateModule(body, accessToken);
        if (res.success) {
          openSnack("Updated Module Successfully", true);
          const { [body.id]: removedItem, ...newFormData } = imageEdits;
          setImageEdits(newFormData);
        } else {
          openSnack("Error while updating Module", false);
        }
      };
      updateModuleResponse(body, localStorage.getItem("access"));
    }
    setTimeout(() => {
      populateProjectCategories();
    }, 500);
    return newRow;
  };

  const handleProcessRowUpdateError = (error: any) => {
    console.error("An error occurred while updating a row:", error);
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const { [id]: removedItem, ...newFormData } = imageEdits;
    setImageEdits(newFormData);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const name = rows.filter((row) => row.id === id)[0]?.name;
    const numericId = extractNumericId(id);
    const stringId = String(id);
    setRowId(id);

    if (stringId.startsWith("module-")) {
      handleOpenDialog();

      setWarningBody({
        id: numericId,
        warningTitle: `Delete module ${name}?`,
        confirmDelete: false,
      });

      setWarningContextState("This Action is Irreversible");
    } else if (stringId.startsWith("category-")) {
      handleOpenDialog();

      setWarningBody({
        id: numericId,
        warningTitle: `Delete category ${name}?`,
        confirmDelete: false,
      });
      setWarningContextState(
        "If you delete a category, all modules inside will also be deleted, and this action is irreversible."
      );
    }
  };
  const initialRows = projectCategories.flatMap((category: any) => [
    {
      id: `category-${category.id}`,
      name: category.name,
      type: "Category",
      description: "",
      image: category.image,
      slug: category.slug,
    },
    ...category.modules.map((module: any) => ({
      id: `module-${module.id}`,
      parentId: `category-${category.id}`,
      name: module.name,
      type: "Module",
      category: category.name,
      description: module.description,
      image: module.image,
      slug: module.slug,
    })),
  ]);

  useEffect(() => {
    setRows(initialRows);
  }, [projectCategories]);

  useEffect(() => {
    populateProjectCategories();
  }, [updateModule, updateCategory]);

  function CustomEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
      if (hasFocus && ref.current) {
        ref.current.focus();
      }
    }, [hasFocus]);

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value; // The new value entered by the user
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
            height: 50,
          }}
        >
          <Avatar
            src={
              imageEdits[id]
                ? URL.createObjectURL(imageEdits[id])
                : props.row.image
            }
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 50,
              height: 50,
              backgroundColor: "primary.main",
              color: "txt.light",
              border: "1px solid",
              borderColor: "primary.main",
              fontSize: "2vw",
            }}
            imgProps={{
              onError: (
                event: React.SyntheticEvent<HTMLImageElement, Event>
              ) => {
                event.currentTarget.onerror = null;
                event.currentTarget.src = Logo;
              },
            }}
          />
          <input
            accept="image/*"
            type="file"
            id={`grid-edit`}
            hidden
            // onChange={handleChange}
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0) {
                const file = event.target.files[0];
                setImageEdits((prev: any) => ({
                  ...prev,
                  [id]: file,
                }));
              }
            }}
          />
          <label htmlFor={`grid-edit`}>
            <IconButton
              component="span"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              <PhotoCameraIcon
                sx={{
                  width: 15,
                  height: 15,
                  color: "primary.light",
                  cursor: "pointer",
                  opacity: 0.4,
                  transition: "all 50ms linear",
                  "&:hover": {
                    opacity: 0.8,
                    transition: "all 50ms linear",
                  },
                }}
              />
            </IconButton>
          </label>
        </Box>

        <TextField
          ref={ref}
          type="text"
          value={value}
          onChange={handleValueChange}
        />
      </Box>
    );
  }

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: true,
      renderCell: (params: GridCellParams) => {
        if (params.row.type == "Module") {
          return (
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <AvatarComponent imgsrc={params.row.image} letter={"I"} />

              <Typography
                sx={{ color: "black", fontWeight: "400", fontSize: "16px" }}
              >
                {params.value}
              </Typography>
            </Box>
          );
        }
        return (
          <Typography
            sx={{ color: "#91C6C5", fontWeight: "600", fontSize: "20px" }}
          >
            {params.row.name}
          </Typography>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponent {...params} />;
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: projectCategories.map((category: any) => ({
        value: category.name,
        label: category.name,
      })),
      renderCell: (params: GridCellParams) => {
        if (params.row.type == "Category") {
          return null;
        } else {
          return (
            <Chip
              label={params.value}
              variant="outlined"
              sx={{
                borderColor: "#5C9391",
                height: "auto",
                color: "#5C9391",
                "& .MuiChip-label": {
                  padding: "4px",
                  color: "#5C9391",
                },
                pointerEvents: "none",
              }}
            />
          );
        }
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }: { id: any }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <DataGridWarningDialog {...warningBodyStatic} {...warningBody} />
      <div style={{ height: "100%", width: "100%" }}>
        <DataGrid
          hideFooter
          hideFooterSelectedRowCount
          hideFooterPagination
          rows={rows}
          columns={columns}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          editMode="row"
          headerHeight={0}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </div>
    </>
  );
};

export default ManageModulesModalContent;

import React from "react";
import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageOne from "../../../../../../../images/children-photo-book/page-1.png";
import { Image } from "@react-pdf/renderer";
import { CaptionPDFComponent } from "./constant";
import {
  imageSrc,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";
import {
  PDFScale,
  photoBookPageOneCaptionInfo,
  photoBookPageOneImageInfo,
} from "../photoBookCommonConstant";

export const PhotoBookPageOneComponent = ({
  images,
}: {
  images: ImageType[];
}) => {
  const scale = PDFScale;
  const imageInfo = photoBookPageOneImageInfo(scale);
  const captionInfo = photoBookPageOneCaptionInfo(scale);
  return (
    <>
      <Image src={imageSrc(PhotoBookPageOne)} style={styles.background} />
      <Image
        src={imageSrc(images[0].link)}
        style={{
          position: "absolute",
          top: imageInfo.top,
          left: imageInfo.left,
          width: imageInfo.width,
          height: imageInfo.height,
          objectFit: "cover",
        }}
      />
      <CaptionPDFComponent
        top={captionInfo.top}
        left={captionInfo.left}
        width={captionInfo.width}
        caption={images[0].caption}
        clampLine={captionInfo.clampLine}
        fontSize={captionInfo.fontSize}
      />
    </>
  );
};

export default PhotoBookPageOneComponent;

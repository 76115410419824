import React from "react";
import { PROFILE_SECTION_ABOUT } from "../constants"
import { slugify } from "helper/slugify";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { RefreshInterface } from "containers/ProfilePage/ProfilePage";
import { translation } from "constants/translation";
import { Description } from "../ProfileComponents";

const AboutComponent = ({
  isOwner,
  setRefresh,
}: {
  isOwner: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<RefreshInterface>>;
}) => {
  return (
    <Card id={slugify(PROFILE_SECTION_ABOUT)} sx={{ width: "100%" }}>
      <CardHeader
        sx={{ backgroundColor: "#d0e3e2bd" }}
        title={(
          <Typography sx={{ fontSize: "1.25rem", lineHeight: 1.5, fontWeight: 500 }}>
            {translation.profileSectionAbout}
          </Typography>)
        }
      /> 
      <CardContent sx={{ padding: "0!important" }}>
        <Box>
          <Description isOwner={isOwner} setRefresh={setRefresh} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AboutComponent;

import { StoreSlice } from "./store";

import { API_Child } from "../types/portfolio";

export interface PortfolioSlice {
  childProfile?: API_Child.Portfolio;
  setChildProfile: (profiles: API_Child.Portfolio) => void;
  childPortfolioObservationList: API_Child.PortfolioObservation[];
  setChildPortfolioObservationList: (
    childPortfolioObservationList: API_Child.PortfolioObservation[]
  ) => void;
  isPortfolioMiniDrawerOpen: boolean;
  setIsPortfolioMiniDrawerOpen: (isPortfolioMiniDrawerOpen: boolean) => void;
  isRefreshLatestMilestone: boolean;
  setIsRefreshLatestMilestone: (isRefreshLatestMilestone: boolean) => void;
  isRefreshPortfolioObservation: boolean;
  setIsRefreshPortfolioObservation: (
    isRefreshPortfolioObservation: boolean
  ) => void;
  completedWorkList: API_Child.CompletedWork[];
  setCompletedWorkList: (completedWorkList: API_Child.CompletedWork[]) => void;
  isRefreshCompletedWork: boolean;
  setIsRefreshCompletedWork: (isRefreshPortfolioObservation: boolean) => void;
  isCompactView: boolean;
  setIsCompactView: (isCompactView: boolean) => void;
}

export const createPortfolioSlice: StoreSlice<PortfolioSlice> = (set, get) => ({
  setChildProfile: (childProfile: API_Child.Portfolio) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      childProfile: childProfile,
    }));
  },
  childPortfolioObservationList: [],
  setChildPortfolioObservationList: (
    childPortfolioObservationList: API_Child.PortfolioObservation[]
  ) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      childPortfolioObservationList: childPortfolioObservationList,
    }));
  },
  isPortfolioMiniDrawerOpen: true,
  setIsPortfolioMiniDrawerOpen: (isPortfolioMiniDrawerOpen: boolean) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      isPortfolioMiniDrawerOpen: isPortfolioMiniDrawerOpen,
    }));
  },
  isRefreshLatestMilestone: false,
  setIsRefreshLatestMilestone: (isRefreshLatestMilestone: boolean) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      isRefreshLatestMilestone: isRefreshLatestMilestone,
    }));
  },
  isRefreshPortfolioObservation: false,
  setIsRefreshPortfolioObservation: (
    isRefreshPortfolioObservation: boolean
  ) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      isRefreshPortfolioObservation: isRefreshPortfolioObservation,
    }));
  },
  completedWorkList: [],
  setCompletedWorkList: (completedWorkList: API_Child.CompletedWork[]) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      completedWorkList: completedWorkList,
    }));
  },
  isRefreshCompletedWork: false,
  setIsRefreshCompletedWork: (isRefreshCompletedWork: boolean) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      isRefreshCompletedWork: isRefreshCompletedWork,
    }));
  },
  isCompactView: false,
  setIsCompactView: (isCompactView: boolean) => {
    set((prev: PortfolioSlice) => ({
      ...prev,
      isCompactView: isCompactView,
    }));
  },
});

import React from "react";
import { Box, SxProps, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface DashedButtonProps {
  text: string;
  onClick: () => void;
  sx?: SxProps;
}

const DashedButton: React.FC<DashedButtonProps> = ({ sx, text, onClick }) => {
  return (
    <Box
      sx={{
        width: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 12px",
        gap: "6px",
        borderRadius: "6px",
        color: "neutral.900",
        border: "1px dashed",
        borderColor: "neutral.500",
        transition: "all 100ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms ease-in-out",
        },
        ...sx,
      }}
      onClick={onClick}
    >
      <AddIcon />
      <Typography
        sx={{
          lineHeight: 1.5,
          fontWeight: 450,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default DashedButton;
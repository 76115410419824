import { useMemo, ReactNode } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DndItem = ({
  id,
  disabled,
  children,
}: {
  id: string;
  disabled?: boolean;
  children: ReactNode;
}) => {
  const { setNodeRef, transition, transform, isDragging, attributes } =
    useSortable({
      id,
    });

  const style = useMemo(
    () => ({
      transition,
      transform: CSS.Translate.toString(transform),
      zIndex: isDragging ? 5 : 0,
      opacity: isDragging ? 0.5 : 1,
    }),
    [transition, transform, isDragging]
  );

  return (
    <div ref={disabled ? undefined : setNodeRef} style={style} {...attributes}>
      {children}
    </div>
  );
};

export default DndItem;

import React from "react";

import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
import Logo from "images/logo-2-colour.png";
import { LEFT_COLUMN_WIDTH, ROW_HEIGHT } from "containers/CalendarPage/constants/constants";

const GroupDisplay = ({
  name,
  height,
  img,
}: {
  name: string;
  height: number;
  img?: string;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: ROW_HEIGHT - 10,
        marginTop: "5px",
        marginBottom: "5px",
      }}
    >
      <Stack spacing={0.2} sx={{ alignItems: "center" }}>
        <Tooltip title={name} placement="top">
          <Avatar
            src={img}
            alt={name}
            sx={{
              width: ROW_HEIGHT - 30,
              height: ROW_HEIGHT - 30,
            }}
            imgProps={{
              onError: (
                event: React.SyntheticEvent<HTMLImageElement, Event>
              ) => {
                event.currentTarget.onerror = null;
                event.currentTarget.src = Logo;
              },
            }}
          >
            {name?.charAt(0).toUpperCase()}
          </Avatar>
        </Tooltip>
        <Typography
          noWrap
          sx={{
            marginTop: 0,
            fontSize: "13px",
            maxWidth: `${LEFT_COLUMN_WIDTH - 1}vw`,
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Box>
  );
};

export default GroupDisplay;

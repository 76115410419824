import { API_Project } from "../types/project";
import { StoreSlice } from "./store";

export interface AssessmentsSlice {
  assessmentModules: API_Project[];
  assessmentModuleFilters: string[];
  assessmentDevGoalsFilters: string[];
  setAssessmentModules: (assessments: API_Project[]) => void;
  setAssessmentModuleFilters: (moduleFilters: string[]) => void;
  setAssessmentDevGoalsFilters: (ageFilters: string[]) => void;
}

export const createAssessmentSlice: StoreSlice<AssessmentsSlice> = (
  set,
  get
) => ({
  assessmentModules: [],
  assessmentModuleFilters: [],
  assessmentDevGoalsFilters: [],
  setAssessmentModules: (assessments: API_Project[]) => {
    set((prev: AssessmentsSlice) => ({
      ...prev,
      assessmentModules: assessments,
    }));
  },
  setAssessmentModuleFilters: (moduleFilters: string[]) => {
    set((prev: AssessmentsSlice) => ({
      ...prev,
      assessmentModuleFilters: moduleFilters,
    }));
  },
  setAssessmentDevGoalsFilters: (devGoalsFilters: string[]) => {
    set((prev: AssessmentsSlice) => ({
      ...prev,
      assessmentDevGoalsFilters: devGoalsFilters,
    }));
  },
});

import { Box, Typography } from '@mui/material';

type Props = {
  text?: string;
  show: boolean;
  isForLearningMomentTab: boolean;
}

const TextOverlay = ({ text, show, isForLearningMomentTab }: Props) => {
  // const size = isForLearningMomentTab ? 100 : 120;
  const size = 120
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#fff',
        position: 'absolute',
        top: 0,
        left: 0,
        width: size,
        height: size,
        borderRadius: '10px',
        padding: 1.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0.5,
        textAlign: 'center',
        opacity: show ? 1 : 0,
        transition: 'all 200ms linear',
      }}
    >
      <Typography
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
          fontSize: '14px',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default TextOverlay;

import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import CloseIcon from "@mui/icons-material/Close";

import { Box, Stack, TextField } from "@mui/material";
import { translation } from "constants/translation";
import {
  API_Teacher_Community_Kanban_Board_Card,
  API_Teacher_Community_Kanban_Board_With_Card,
  API_Teacher_Create_Community_Kanban_Board_Card,
} from "types/teacher";
import Button from "components/Button/Button";
import {
  createCommunityKanbanBoardCard,
  editCommunityKanbanBoardCard,
} from "api/teacher-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

const CreateEditKanbanBoardCardModal = ({
  open,
  handleClose,
  kanbanBoardCard,
  kanbanBoard,
  setKanbanBoard,
}: {
  open: boolean;
  handleClose: () => void;
  kanbanBoardCard: API_Teacher_Community_Kanban_Board_Card;
  kanbanBoard: API_Teacher_Community_Kanban_Board_With_Card;
  setKanbanBoard: React.Dispatch<
    React.SetStateAction<API_Teacher_Community_Kanban_Board_With_Card>
  >;
}) => {
  const isCreate = kanbanBoardCard.id === -1;
  const openSnack = useSnack();
  const { currLanguage, setIsRefreshKanbanBoard } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setIsRefreshKanbanBoard: state.setIsRefreshKanbanBoard,
  }));

  const [currentKanbanBoardCardInfo, setCurrentKanbanBoardCardInfo] =
    useState<API_Teacher_Create_Community_Kanban_Board_Card>({
      name: kanbanBoardCard.name,
      board: kanbanBoardCard.board,
    });

  const handleSubmit = async () => {
    if (currentKanbanBoardCardInfo.name !== "") {
      let kanbanBoardCardRes: API_Teacher_Community_Kanban_Board_Card | string;
      // Update
      if (kanbanBoardCard.id !== -1) {
        const body = {
          ...currentKanbanBoardCardInfo,
          id: kanbanBoardCard.id,
          board: kanbanBoard.id,
        };
        kanbanBoardCardRes = await editCommunityKanbanBoardCard(
          currLanguage,
          body
        );
      } else {
        kanbanBoardCardRes = await createCommunityKanbanBoardCard(
          currLanguage,
          { ...currentKanbanBoardCardInfo, board: kanbanBoard.id }
        );
      }

      if (typeof kanbanBoardCardRes === "string") {
        openSnack(kanbanBoardCardRes, false);
        return;
      } else {
        // if (isCreate) {
        //   setKanbanBoard((prev) => {
        //     return {
        //       ...prev,
        //       cards: [
        //         ...prev.cards,
        //         kanbanBoardCardRes as API_Teacher_Community_Kanban_Board_Card,
        //       ],
        //     };
        //   });
        // } else {
        //   const updatedKanbanBoardCardList: API_Teacher_Community_Kanban_Board_Card[] =
        //     JSON.parse(JSON.stringify(kanbanBoard.cards));
        //   const index = updatedKanbanBoardCardList.findIndex(
        //     (kanbanBoardCard_) => kanbanBoardCard_.id === kanbanBoardCard.id
        //   );
        //   updatedKanbanBoardCardList[index] = kanbanBoardCardRes;
        //   setKanbanBoard((prev) => {
        //     return {
        //       ...prev,
        //       cards: updatedKanbanBoardCardList,
        //     };
        //   });
        // }
        setIsRefreshKanbanBoard(true);
        openSnack(
          isCreate
            ? translation.kanbanBoardCommentCreated ||
                "Kanban Board Comment Created"
            : translation.kanbanBoardCommentUpdated ||
                "Kanban Board Comment Updated",
          true
        );
        setCurrentKanbanBoardCardInfo({
          name: "",
          board: kanbanBoardCard.board,
        });
        handleClose();
      }
    }
  };

  useEffect(() => {
    setCurrentKanbanBoardCardInfo({
      name: kanbanBoardCard.name,
      board: kanbanBoardCard.board,
    });
  }, [kanbanBoardCard.id]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 8,
            paddingY: 4,
            borderRadius: "10px",
            alignItems: "center",
            width: "50vw",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          <Stack
            component="form"
            sx={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              alignItems: "center",
            }}
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              placeholder={translation.whatIsYourThought}
              label={translation.title}
              value={currentKanbanBoardCardInfo.name}
              onChange={(event) => {
                setCurrentKanbanBoardCardInfo((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
              required
            />

            <Button
              buttonText={
                isCreate
                  ? translation.submit || "Submit"
                  : translation.update || "Update"
              }
              disabled={false}
              onClick={handleSubmit}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateEditKanbanBoardCardModal;

import { useState } from "react";
import { TextField, IconButton } from "@mui/material";

import useStore from "store/store";
import { useParams } from "react-router-dom";
import ProfileAvatarList from "./ProfileAvatarList";
import { API_Profile_Short } from "types/profile";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";

const ClassInfoPage = () => {
  const { groupSlug } = useParams();
  const { groupList } = useStore((state) => ({
    groupList: state.groupList,
  }));

  const selectedGroup = groupList.filter((group) => group.slug === groupSlug);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  let filteredChildren: API_Profile_Short[] = [];

  if (selectedGroup.length > 0) {
    let children = selectedGroup[0].children as API_Profile_Short[];

    children = children.sort((a, b) =>
      sortAscending
        ? a.preferred_name.localeCompare(b.preferred_name)
        : b.preferred_name.localeCompare(a.preferred_name)
    );

    filteredChildren = children.filter((child) =>
      child.preferred_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  return (
    <Box sx={{ position: "relative", flex: "1" }}>
      <Box
        style={{
          marginBottom: "20vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          style={{ zIndex: 2 }}
          onClick={() => {
            setSortAscending((prev) => !prev);
          }}
        >
          <SortIcon
            style={{
              fontSize: "2rem",
            }}
          />
        </IconButton>
        <TextField
          sx={{
            position: "absolute",
            right: 0,
            zIndex: 1,
            width: {
              xs: "40%",
              sm: "35%",
              lg: "25%",
            },
          }}
          size="small"
          id="search-bar"
          className="text"
          value={searchQuery}
          variant="outlined"
          placeholder="Search..."
          InputProps={{
            startAdornment: <SearchIcon style={{ color: "gray" }} />,
          }}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflowX: "hidden",
          overflowY: "auto",
          py: 1,
          px: 1,
        }}
      >
        <ProfileAvatarList
          groupTeacherList={
            selectedGroup.length > 0 ? selectedGroup[0].teachers : []
          }
          groupChildList={filteredChildren}
        />
      </Box>
    </Box>
  );
};

export default ClassInfoPage;

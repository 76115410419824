import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Project_Summary_Report, Query_Data } from "types/project";
import { getProjectSummaryReportList } from "api/report-api";
import useStore from "store/store";
import ProjectSummaryReportCard from "containers/ChildrenPortfolioPage/Screens/ProjectSummaryReportComponents/ProjectSummaryReportCard";

// Filter
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import CustomButton from "components/Button/Button";
import ModuleFilterButton from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/ModuleFilterButton";
import ClassFilterButton from "../TeacherArchiveCommonComponent/ClassFilterButton";
import ChildFilterButton from "../TeacherArchiveCommonComponent/ChildFilterButton";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import {
  ClickSearchToBegin,
  MAX_QUERY,
  MaxQueryAdvice,
  teacherArchiveCardStyle,
} from "../TeacherArchiveCommonComponent/TeacherArchiveCommonComponent";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const TeacherArchiveProjectSummaryScreen = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  // Search
  const [isLoading, setIsLoading] = useState(false);
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });
  const [selectedModuleSlugs, setSelectedModuleSlugs] = useState<string[]>([]);
  const [selectedClassSlugs, setSelectedClassSlugs] = useState<string[]>([]);
  const [selectedChildIds, setSelectedChildIds] = useState<number[]>([]);

  const [projectSummaryReportList, setProjectSummaryReportList] = useState<
    Project_Summary_Report[]
  >([]);

  const populateProgressReportList = async () => {
    setIsLoading(true);

    const res = await getProjectSummaryReportList({
      language: currLanguage,
      selectedModuleSlugs: selectedModuleSlugs,
      selectedClassSlugs: selectedClassSlugs,
      selectedChildIds: selectedChildIds,
      queryData: queryData,
      isTeacherArchive: true,
    });
    if (typeof res !== "string") {
      setProjectSummaryReportList(res);
    }
    setIsLoading(false);
  };

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      isInitialized ||
      selectedModuleSlugs.length === 0 ||
      selectedClassSlugs.length === 0 ||
      selectedChildIds.length === 0
    )
      return;

    populateProgressReportList();
    setIsInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModuleSlugs, selectedClassSlugs, selectedChildIds]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, sm: 2 },
            width: {
              xs: "100%",
              xl: "80%",
            },
          }}
        >
          <SearchBar
            queryData={queryData}
            setQueryData={setQueryData}
            maxWidth={9999}
          />

          <CustomButton
            buttonText={isXs ? "" : "Search"}
            onClick={() => {
              populateProgressReportList();
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            flexWrap: "wrap",
            width: {
              xs: "100%",
              xl: "80%",
            },
          }}
        >
          <StartAtDateField
            queryData={queryData}
            setQueryData={setQueryData}
            // maxWidth={150}
          />
          <EndAtDateField
            queryData={queryData}
            setQueryData={setQueryData}
            // maxWidth={150}
          />
          <Box
            sx={{
              flex: { xs: "1 0 100%", sm: "1" },
            }}
          >
            <ModuleFilterButton
              selectedModuleSlugs={selectedModuleSlugs}
              setSelectedModuleSlugs={setSelectedModuleSlugs}
            />
          </Box>
          <ClassFilterButton
            selectedClassSlugs={selectedClassSlugs}
            setSelectedClassSlugs={setSelectedClassSlugs}
          />
          <ChildFilterButton
            selectedClassSlugs={selectedClassSlugs}
            selectedChildIds={selectedChildIds}
            setSelectedChildIds={setSelectedChildIds}
          />
        </Box>
      </Box>

      {isLoading ? (
        <LoadingIndicator />
      ) : projectSummaryReportList.length === 0 ? (
        <ClickSearchToBegin />
      ) : (
        <>
          <Box sx={teacherArchiveCardStyle}>
            {projectSummaryReportList.map((projectSummaryReport) => {
              return (
                <ProjectSummaryReportCard
                  key={`project-summary-report-${projectSummaryReport.id}`}
                  projectSummaryReport={projectSummaryReport}
                  isChildPortfolio
                />
              );
            })}
          </Box>
          {projectSummaryReportList.length === MAX_QUERY &&
            MaxQueryAdvice({ maxQuery: MAX_QUERY })}
        </>
      )}
    </>
  );
};

export default TeacherArchiveProjectSummaryScreen;

import React, { useEffect, useState } from "react";

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { CURLANG, LOGIN } from "constants/url";

const ProtectedRoute = ({
  isAuth,
  children,
}: {
  isAuth?: boolean;
  children?: React.ReactElement;
}) => {
  const openSnack = useSnack();
  const { currLanguage } = useStore((state) => state);
  const [accessToken] = useState(localStorage.getItem("access"));
  const [profileId] = useState(localStorage.getItem("profile_id"));
  const currentLocation = useLocation();

  if (!accessToken || !profileId) {
    openSnack("You need to be logged in to see this page!", false);
    return (
      <Navigate
        to={`${CURLANG(currLanguage)}/${LOGIN}?next=${
          currentLocation.pathname
        }`}
        replace
      />
    );
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

import React, { Fragment, useEffect, useState } from "react";

import { Avatar, Box, Chip, Modal, SxProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useStore from "store/store";
import { API_Lesson_Checkpoint } from "types/project";
import { checkpointTabIconStyle } from "./CheckpointSection";

import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";

const CheckpointQuestionNameComponent = ({
  name,
  description,
  fundamentalId,
  checkpointObject,
}: {
  name: string;
  description: string;
  fundamentalId: number;
  checkpointObject: API_Lesson_Checkpoint;
}) => {
  const { currScheduleObj } = useStore((state) => ({
    currScheduleObj: state.currScheduleObj,
  }));

  const isCheckpointQuestionDone =
    currScheduleObj.checkpoints.length > 0
      ? currScheduleObj.checkpoints.includes(checkpointObject.id!)
      : false;
  const CustomChip = () => {
    const { fundamentalDict } = useStore((state) => ({
      fundamentalDict: state.fundamentalDict,
    }));

    if (fundamentalDict[fundamentalId]) {
      const currFundamental = fundamentalDict[fundamentalId];
      return (
        <Box
          sx={{
            alignItem: "center",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Chip
            avatar={
              <Avatar alt={currFundamental.name} src={currFundamental.image} />
            }
            key={`chip-avatar-${currFundamental.name}-${name}`}
            label={
              <>
                {currFundamental.main_fundamental ? (
                  <Typography>
                    {`${
                      fundamentalDict[currFundamental.main_fundamental].name
                    }`}
                  </Typography>
                ) : (
                  ""
                )}
                <Typography>{currFundamental.name}</Typography>
              </>
            }
          />
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      {isCheckpointQuestionDone ? (
        <CircleCheckedFilled
          sx={{ ...checkpointTabIconStyle }}
          color="primary"
        />
      ) : (
        <CircleUnchecked sx={{ ...checkpointTabIconStyle }} color="primary" />
      )}

      <Typography
        sx={{ fontSize: 18, lineHeight: "1.25", fontWeight: 500 }}
      >{`${name}`}</Typography>

      <LightTooltip title={CustomChip ? <CustomChip /> : description}>
        <InfoOutlinedIcon
          sx={{
            width: "20px",
            opacity: "0.5",
          }}
        />
      </LightTooltip>
    </Box>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default CheckpointQuestionNameComponent;

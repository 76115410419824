import useStore from "store/store";

import { Box, Stack, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { translation } from "constants/translation";
import { useEffect, useState } from "react";
import CustomButton from "components/Button/Button";
import { resetPassword } from "api/auth-api";
import useSnack from "hooks/useSnack";
import { CURLANG, FORGOTTEN_PASSWORD, HOME, LOGIN } from "constants/url";
// import { forgotPassword } from "api/auth-api";

interface ResetPassword {
  password: string;
  password2: string;
}

const ResetPasswordPage = () => {
  const { currLanguage, profileID } = useStore((state) => state);
  const navigate = useNavigate();
  const openSnack = useSnack();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayErrorList, setDisplayErrorList] = useState<string[]>([]);
  const { next, uid, token } = useParams();
  const [passwordData, setPasswordData] = useState<ResetPassword>({
    password: "",
    password2: "",
  });
  const setError = (errorMessage: string[]) => {
    setDisplayErrorList(errorMessage);
    setIsLoading(false);
  };

  const handleChange = async () => {
    setIsLoading(true);
    if (passwordData.password !== passwordData.password2) {
      setError(["Passwords do not match"]);
      return;
    }

    const newErrors = [];

    if (passwordData.password.length < 8) {
      newErrors.push("Password must be at least 8 characters");
    }

    if (!/[a-z]/.test(passwordData.password)) {
      newErrors.push("Password must contain at least one lowercase letter");
    }

    if (!/[A-Z]/.test(passwordData.password)) {
      newErrors.push("Password must contain at least one uppercase letter");
    }

    if (!/\d/.test(passwordData.password)) {
      newErrors.push("Password must contain at least one digit");
    }

    // If there are errors, set them. Otherwise, clear the errors.
    if (newErrors.length > 0) {
      setError(newErrors);
      return;
    } else {
      setDisplayErrorList([]);
      // Submit the password here if no errors
    }

    const response = await resetPassword(
      uid!,
      token!,
      passwordData.password,
      passwordData.password2
    );

    if (typeof response === "number" && response === 204) {
      openSnack("Reset password successfully", true);
      navigate(`${CURLANG(currLanguage)}/${LOGIN}`);
    } else {
      console.log(response);
      openSnack(
        "The session has expired. Please submit a new password reset request or try again later.",
        false
      );
      navigate(`${CURLANG(currLanguage)}/${FORGOTTEN_PASSWORD}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (displayErrorList) {
      const timer = setTimeout(() => {
        setDisplayErrorList([]);
      }, 10000);

      // This function will run when component unmounts or when displayErrorList changes
      return () => clearTimeout(timer);
    }
  }, [displayErrorList]); // effect depends on value of displayErrorList

  useEffect(() => {
    if (!!profileID) {
      if (next) {
        navigate(next);
      } else {
        navigate(`${CURLANG(currLanguage)}/${HOME}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileID]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "auto",
        flex: 1,
      }}
    >
      <Stack
        component="form"
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          m: "auto",
          maxWidth: 800,
          minWidth: { xs: 0, sm: 300, md: 500 },
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          mt: "-30px",
        }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        <Typography
          sx={{
            fontSize: "2rem",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          {translation.resetYourPassword}
        </Typography>
        {displayErrorList && (
          <Typography
            component="div"
            sx={{
              color: "red",
              mt: "0px !important",
              maxWidth: { xs: 300, md: 500 },
            }}
          >
            <ul>
              {displayErrorList.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Typography>
        )}
        <TextField
          required
          label="New Password"
          type="password"
          onChange={(event) => {
            setPasswordData((prev) => ({
              ...prev,
              password: event.target.value,
            }));
          }}
          fullWidth
        />
        <TextField
          required
          label="Retype Password"
          type="password"
          onChange={(event) => {
            setPasswordData((prev) => ({
              ...prev,
              password2: event.target.value,
            }));
          }}
          fullWidth
        />
        <CustomButton
          buttonText="Change Password"
          arrow={false}
          disabled={
            passwordData.password === "" || passwordData.password2 === ""
          }
          onClick={handleChange}
          style={{ width: "100%" }}
          isLoading={isLoading}
        />
      </Stack>
    </Box>
  );
};

export default ResetPasswordPage;

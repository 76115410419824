import React, { useEffect, useState } from "react";

import { Box, Fade, Modal, Backdrop, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LineChart,
  BarChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from "recharts";

import Button from "components/Button/Button";
import { translation } from "constants/translation";
import { AnalyticPeriodType } from "types/analytic";
import {
  GENERAL_DATE_FORMAT,
  ANALYTIC_PERIOD_LIST,
  ONE_YEAR,
  PERIOD_MAPPING,
  SEVEN_DAYS,
  THIRTY_DAYS,
} from "constants/constantInGeneral";

import useStore from "store/store";
import { getStartEndDate } from "helper/helper";
import moment from "moment";
import {
  API_Project_Rating,
  API_Project_Rating_Count,
} from "types/project";
import {
  API_Teacher_Module_Analytic,
  API_Teacher_Module_Chart_Info,
} from "types/teacher";
import { getModuleChartInfo } from "api/teacher-api";

interface WeekBucket {
  count: number;
  sum: number;
  avg: number;
  weekEnd: string;
}

const preprocessData = (
  data: API_Project_Rating[],
  interval: AnalyticPeriodType
): API_Project_Rating[] => {
  let intervalNum = 7; //7 days default
  let bucketSize = 1; // default to daily buckets
  if (interval === ONE_YEAR) {
    intervalNum = 364;
    bucketSize = 7; // use weekly buckets for 364-day interval
  } else if (interval === THIRTY_DAYS) {
    intervalNum = 30;
  }

  const startDate = moment().subtract(intervalNum, "days");
  const bucketCount = intervalNum === 364 ? 52 : intervalNum;
  const buckets: Record<number, WeekBucket> = {};

  // Create empty buckets for all time periods
  for (let i = 0; i < bucketCount; i++) {
    const weekEnd = startDate.clone().add(i * bucketSize + bucketSize, "days");
    buckets[i] = {
      count: 0,
      sum: 0,
      avg: 0,
      weekEnd: weekEnd.toISOString(),
    };
  }

  data.forEach((item) => {
    const createdAt = moment(item.created_at);
    const bucketNumber = Math.floor(
      createdAt.diff(startDate, "days") / bucketSize
    );

    if (bucketNumber >= 0 && bucketNumber < bucketCount) {
      buckets[bucketNumber].count++;
      buckets[bucketNumber].sum += item.rating;
      buckets[bucketNumber].avg =
        buckets[bucketNumber].sum / buckets[bucketNumber].count;
    }
  });

  const values = Object.values(buckets);
  const finalData = values.map((value) => {
    return {
      created_at: moment(value.weekEnd).format(GENERAL_DATE_FORMAT),
      rating: parseFloat(value.avg.toFixed(2)),
    };
  });
  return finalData;
};

const IndividualChartModal = ({
  selectedModule,
  open,
  handleClose,
}: {
  selectedModule: API_Teacher_Module_Analytic;
  open: boolean;
  handleClose: () => void;
}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [interval, setInterval] = useState<AnalyticPeriodType>(SEVEN_DAYS);

  const [projectChartInfo, setModuleChartInfo] =
    useState<API_Teacher_Module_Chart_Info>({
      id: -1,
      name: "",
      slug: "",
      rating_list: [],
      rating_count: {
        rating_1: 0,
        rating_2: 0,
        rating_3: 0,
        rating_4: 0,
        rating_5: 0,
      },
    });

  useEffect(() => {
    const populateModuleChartInfo = async () => {
      const [startDate, endDate] = getStartEndDate(interval);

      const res = await getModuleChartInfo(
        currLanguage,
        selectedModule.id,
        startDate,
        endDate,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        const processedRatingList = preprocessData(res.rating_list, interval);
        const cleanedModuleChartInfo = {
          ...res,
          rating_list: processedRatingList,
        };
        setModuleChartInfo(cleanedModuleChartInfo);
      }
    };
    selectedModule.id !== -1 && populateModuleChartInfo();
  }, [interval, selectedModule.id]);

  const gridItemStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // console.log("projectChartInfo: ", projectChartInfo);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            paddingY: 0.5,
            borderRadius: "10px",
            width: "60vw",
            height: "80vh",
            // overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          {/* Content here */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              position: "relative",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 1,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "45%",
                  marginRight: 1,
                }}
              >
                {selectedModule.name}
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mr: 2, flex: 1 }}>
                {ANALYTIC_PERIOD_LIST.map((period) => {
                  return (
                    <Button
                      key={`${period}-key`}
                      btnType={period === interval ? "filled" : "outlined"}
                      arrow={false}
                      style={{
                        fontSize: 12,
                        width: "100%",
                        flex: 1,
                        textAlign: "center",
                        display: "flex",
                      }}
                      buttonText={PERIOD_MAPPING[period]}
                      onClick={() => {
                        setInterval(period as AnalyticPeriodType);
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "1fr 1fr",
                gridGap: 2,
              }}
            >
              <Box sx={gridItemStyle}>
                <LineChartComponent data={projectChartInfo.rating_list} />
              </Box>

              <Box sx={gridItemStyle}>
                <BarChartComponent data={projectChartInfo.rating_count} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const LineChartComponent = ({ data }: { data: API_Project_Rating[] }) => {
  return (
    <ResponsiveContainer width="90%" height="90%">
      <LineChart
        width={400}
        height={250}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created_at" />
        <YAxis dataKey="rating" domain={[0, 5]} tickCount={6} interval={0} />
        <Tooltip />
        <Legend
          formatter={(value, entry, index) => {
            return <span style={{ color: entry.color }}>{`Rating`}</span>;
          }}
        />
        {/* <Line
          type="monotone"
          dataKey="pv"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        /> */}
        <Line type="monotone" dataKey="rating" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};

const BarChartComponent = ({ data }: { data: API_Project_Rating_Count }) => {
  const dataArray = Object.entries(data).map(([rating, count]) => ({
    rating,
    count,
  }));

  const transformLabel = (label: string) => {
    const ratingNumber = label.split("_")[1];
    return `${ratingNumber} Star${ratingNumber === "1" ? "" : "s"}`;
  };

  interface CustomXTickProps {
    x: number;
    y: number;
    payload: {
      value: string;
    };
  }

  const CustomXTick = (props: CustomXTickProps) => {
    const { x, y, payload } = props;
    const labelText = transformLabel(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {labelText}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="90%" height="90%">
      <BarChart
        width={500}
        height={300}
        data={dataArray}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="rating" tick={CustomXTick} />
        <YAxis />
        <Tooltip />
        <Legend
          formatter={(value, entry, index) => {
            return (
              <span style={{ color: entry.color }}>{`Number of rating`}</span>
            );
          }}
        />
        <Bar dataKey="count" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default IndividualChartModal;

import React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "../Button/Button";
import { Typography } from "@mui/material";

const DataGridWarningDialog = ({
  openDialog,
  handleCloseDialog,
  warningTitle,
  warningContext,
  setWarningBody,
  names,
}: {
  openDialog: boolean;
  handleCloseDialog: () => void;
  warningTitle: string;
  warningContext: string;
  setWarningBody: React.Dispatch<
    React.SetStateAction<{
      id: number;
      warningTitle: string;
      confirmDelete: boolean;
    }>
  >;
  names?: string[];
}) => {
  return (
    <Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="warning-dialog-title"
        aria-describedby="warning-dialog-description"
      >
        <DialogTitle id="warning-dialog-title">{warningTitle}</DialogTitle>
        <DialogContent
          sx={{
            minWidth: "320px",
          }}
        >
          <DialogContentText
            id="warning-dialog-description"
            sx={{
              maxHeight: "210px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
            }}
          >
            {(names?.length ?? 0) > 0
              ? names?.map((string, index) => (
                  <Typography key={index}>{string}</Typography>
                ))
              : warningContext}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "20px" }}>
          <Button
            buttonText="Cancel"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "var(--txt-secondary)",
              padding: "0",
              marginRight: "20px",
            }}
            onClick={handleCloseDialog}
          />

          <Button
            buttonText="Delete"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
            }}
            onClick={() => {
              setWarningBody((prev) => ({
                ...prev,
                confirmDelete: true,
              }));
              handleCloseDialog();
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DataGridWarningDialog;

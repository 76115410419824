import { Box, Stack } from "@mui/material";

import  { useEffect, useRef, useState } from "react";

import useSnack from "hooks/useSnack";
import useStore from "store/store";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import { API_Create_Lesson, API_Edit_Lesson } from "types/project";
import {
  deleteLessonImage,
  editLesson,
  getLessonByID,
  deleteLesson,
} from "api/project-api";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Button from "components/Button/Button";

//Prompt user when leaving without saving
import BlockLeavingDialog from "components/Dialog/BlockLeavingDialog";
import useCallbackPrompt from "hooks/useCallbackPrompt";
import { translation } from "constants/translation";
import KeepChangesDialog from "components/Dialog/KeepChangesDialog";
import getBase64 from "helper/getBase64";

import {
  NameField,
  DurationField,
  KeyVocabField,
  InstructionsField,
  ImageField,
  IntentionField,
  DescriptionField,
  LessonObjectiveField,
  ObjectiveField,
} from "./LessonFormComponents";

import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { useNavigate, useParams } from "react-router-dom";
import DrawerHeader from "containers/EditProjectPage/DrawerHeader";
import MaterialButton from "components/Button/MaterialButton";

export const initialLesson = {
  name: undefined,
  description: "",
  project: -1,
  images: [],
  checkpoint_fundamentals: [],
  activities: [],
  resources: "",
  instruction: "",
  intention: "",
  vocabulary: "",
  material: "",
  duration: -1,
  objective: "",
  objective_fundamentals: [],
  is_required: false,
};

type Props = {
  assessment: boolean;
  lessonID: string;
  handleClose: () => void;
  isEditLessonPage?: boolean;
};

//used in drawer, latest
const EditLessonForm = ({
  assessment,
  lessonID,
  handleClose,
  isEditLessonPage = false,
}: Props) => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { projectSlug } = useParams();

  const [images, setImages] = useState<File[]>([]);
  const [isDeleteImageList, setIsDeleteImageList] = useState<boolean[]>([]);
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    setRefreshProjAndLess,
    currLesson,
    currLanguage,
    setCurrLesson,
    bringOverLesson,
    fundamentalDict,
  } = useStore((state) => state);
  useEffect(() => {
    const intialiseLesson = async () => {
      if (showChanges) return;
      setIsLoading(true);
      const less = await getLessonByID(currLanguage, lessonID);
      if (typeof less === "string") {
        openSnack(less, false);
      } else {
        setCurrLesson(less);
        setKeptData({});
        setImages([]);
        setIsDeleteImageList([]);
        setIsFilled(false);
      }

      setIsLoading(false);
    };

    intialiseLesson();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonID]);

  const [lesson, setLesson] = useState<API_Create_Lesson>(initialLesson);

  const [keptData, setKeptData] = useState<API_Edit_Lesson>({});

  const lessonRef = useRef(lesson);
  lessonRef.current = lesson;

  useEffect(() => {
    setLesson({
      ...lesson,
      project: bringOverLesson.project
        ? bringOverLesson.project
        : currLesson.project,
      images: [],
      checkpoint_fundamentals: bringOverLesson.checkpoint_fundamentals
        ? bringOverLesson.checkpoint_fundamentals
        : currLesson.checkpoint_fundamentals,
      activities: currLesson.activities.map((activity) => ({
        id: activity.id,
        name: activity.name,
      })),
      resources: bringOverLesson.resources
        ? bringOverLesson.resources
        : currLesson.resources,
      instruction: bringOverLesson.instruction
        ? bringOverLesson.instruction
        : currLesson.instruction,
      vocabulary: bringOverLesson.vocabulary
        ? bringOverLesson.vocabulary
        : currLesson.vocabulary,
      material: bringOverLesson.material
        ? bringOverLesson.material
        : currLesson.material,
      intention: bringOverLesson.intention
        ? bringOverLesson.intention
        : currLesson.intention,
      duration: bringOverLesson.duration
        ? bringOverLesson.duration
        : currLesson.duration
        ? currLesson.duration
        : 0,
      objective: bringOverLesson.objective
        ? bringOverLesson.objective
        : currLesson.objective,
      objective_fundamentals: bringOverLesson.objective_fundamentals
        ? bringOverLesson.objective_fundamentals
        : currLesson.objective_fundamentals,
      is_required:
        bringOverLesson.is_required !== undefined
          ? bringOverLesson.is_required
          : currLesson.is_required,
      name: bringOverLesson.name ? bringOverLesson.name : currLesson.name,
      description: bringOverLesson.description
        ? bringOverLesson.description
        : currLesson.description,
    });

    setKeptData(bringOverLesson);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLesson]);

  useEffect(() => {
    // let isCheckpointsFilled = true;
    // lesson.checkpoint_fundamentals.every((checkpoint_fundamental) => {
    //   return checkpoint_fundamental.checkpoints.every((checkpoint) => {
    //     isCheckpointsFilled = checkpoint.name !== "";
    //     return isCheckpointsFilled;
    //   });
    // });

    if (lesson.name !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [lesson]);

  const submitEditForm = async () => {
    setIsFilled(false);
    let editFailed = false;
    const lessonData: API_Edit_Lesson = {};

    if (lesson.name !== currLesson.name) lessonData.name = lesson.name;
    if (lesson.description !== currLesson.description)
      lessonData.description = lesson.description;
    if (lesson.duration !== currLesson.duration)
      lessonData.duration = lesson.duration;
    if (lesson.material !== currLesson.material)
      lessonData.material = lesson.material;
    if (lesson.instruction !== currLesson.instruction)
      lessonData.instruction = lesson.instruction;
    if (lesson.intention !== currLesson.intention)
      lessonData.intention = lesson.intention;
    if (lesson.vocabulary !== currLesson.vocabulary)
      lessonData.vocabulary = lesson.vocabulary;
    if (lesson.resources !== currLesson.resources)
      lessonData.resources = lesson.resources;
    if (lesson.objective !== currLesson.objective)
      lessonData.objective = lesson.objective;
    if (lesson.objective_fundamentals !== currLesson.objective_fundamentals)
      lessonData.objective_fundamentals = lesson.objective_fundamentals;
    if (lesson.is_required !== currLesson.is_required)
      lessonData.is_required = lesson.is_required;

    //IMAGES
    if (images.length > 0) {
      const base64Images: { file: string }[] = [];
      for (let i = 0; i < images.length; i++) {
        const base64 = await getBase64(images[i]);
        base64Images.push({ file: base64 });
      }
      lessonData.images = base64Images;
    }

    for (let i = 0; i < isDeleteImageList.length; i++) {
      if (isDeleteImageList[i]) {
        const res = await deleteLessonImage(currLesson.images[i].id);
        if (res !== "success") openSnack(res, false);
      }
    }

    if (Object.keys(lessonData).length > 0) {
      const res = await editLesson(currLanguage, currLesson.id, lessonData);
      if (typeof res === "string") {
        openSnack(`${translation.fail_update_lesson}: ${res}`, false);
        editFailed = true;
      }
    }

    if (!editFailed) {
      setRefreshProjAndLess(true);
      openSnack(
        translation.success_update_lesson || "Updated lesson successfully!",
        true
      );
      handleClose();
    }
  };

  //DIALOG
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const dialogBody = {
    showDialog: showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleAction: submitEditForm,
  };

  const changesDialogBody = {
    showChangesDialog: showChanges,
    confirmNavigation,
    cancelNavigation,
    project: {},
    lesson: keptData,
    activity: {},
  };
  return lesson.name === undefined ||
    isLoading ||
    Object.keys(fundamentalDict).length === 0 ? (
    <LoadingIndicator />
  ) : (
    <>
      {dialogBody.showDialog && <BlockLeavingDialog {...dialogBody} />}
      {changesDialogBody.showChangesDialog && (
        <KeepChangesDialog {...changesDialogBody} />
      )}
      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <DrawerHeader
          type="Lesson"
          mode="Edit"
          assessment={assessment}
          handleClose={handleClose}
        >
          <Box
            sx={{
              display: "flex",
              gap: "24px",
            }}
          >
            <DeleteLessonButton handleClose={handleClose} />
            <MaterialButton
              buttonText={translation.update || "Update"}
              onClick={() => {
                if (isFilled) {
                  setShowDialog(false);
                  submitEditForm();
                }
              }}
              disabled={!isFilled}
            />
          </Box>
        </DrawerHeader>
        <NameField
          lesson={lesson}
          setLesson={setLesson}
          onChange={(input) => {
            setShowDialog(true);
            setKeptData((prev) => ({ ...prev, name: input }));
          }}
        />
        {!assessment && (
          <ObjectiveField
            lesson={lesson}
            setLesson={setLesson}
            onChange={(input) => {
              setShowDialog(true);
              setKeptData((prev) => ({ ...prev, objective: input }));
            }}
          />
        )}
        <IntentionField
          label={assessment ? "Assessment Plan Intention" : undefined}
          lesson={lesson}
          setLesson={setLesson}
          onChange={(input) => {
            setShowDialog(true);
            setKeptData((prev) => ({ ...prev, objective: input }));
          }}
        />
        <DurationField
          lesson={lesson}
          setLesson={setLesson}
          onChange={(input) => {
            setShowDialog(true);
            setKeptData((prev) => ({ ...prev, duration: input }));
          }}
        />
        <LessonObjectiveField lesson={lesson} setLesson={setLesson} />
        {!assessment && (
          <KeyVocabField
            lesson={lesson}
            setLesson={setLesson}
            onChange={(input) => {
              setShowDialog(true);
              setKeptData((prev) => ({ ...prev, vocabulary: input }));
            }}
          />
        )}
        <DescriptionField
          richText={assessment}
          lesson={lesson}
          setLesson={setLesson}
          onChange={(input) => {
            setShowDialog(true);
            setKeptData((prev) => ({ ...prev, description: input }));
          }}
        />
        <InstructionsField
          label={assessment ? "Set-up Instructions (if any)" : undefined}
          lesson={lesson}
          setLesson={setLesson}
          onChange={(input) => {
            setShowDialog(true);
            setKeptData((prev) => ({ ...prev, instruction: input }));
          }}
        />
        <ImageField
          images={images}
          setImages={setImages}
          oldImages={currLesson.images}
          isDeleteImageList={isDeleteImageList}
          setIsDeleteImageList={setIsDeleteImageList}
          multiple
        />
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          {isEditLessonPage && (
            <Button
              arrow={false}
              buttonText={translation.back || "Back"}
              style={{
                fontSize: "14px",
                backgroundColor: "var(--txt-secondary)",
                height: "auto",
              }}
              onClick={() => {
                navigate(
                  `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
                    projectSlug!
                  )}/lesson-plans`
                );
              }}
              disabled={!isFilled}
            />
          )}
        </Box>
      </Stack>
    </>
  );
};

const DeleteLessonButton = ({ handleClose }: { handleClose: () => void }) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const { currLesson, setRefreshProjAndLess } = useStore((state) => state);

  const handleDelete = async () => {
    const res = await deleteLesson(currLesson.id);
    if (res !== "success") {
      openSnack(res, false);
    } else {
      openSnack(
        translation.success_remove_lesson || "Removed lesson successfully!",
        true
      );

      setRefreshProjAndLess(true);
      handleClose();
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Lesson?",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <MaterialButton
        buttonText={translation.delete || "Delete"}
        variant="text"
        onClick={handleOpenDialog}
      />
    </>
  );
};

export default EditLessonForm;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";

import {
  Box,
  Rating,
  TextField,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Avatar,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import Logo from "images/logo-2-colour.png";
import Button from "../Button/Button";
import Image from "../Image/Image";
import getBase64 from "helper/getBase64";

import {
  API_Review,
  API_Review_Image,
  API_Update_Review,
} from "types/review";
import {
  createReview,
  updateReview,
  deleteReviewImages,
  createDevelopmentFeedback,
} from "api/review-api";
import { translation } from "constants/translation";
import { Profile } from "./Components";
import { UserRoleMappingInverse } from "types/auth";

const ReviewModal = ({
  isBeforeDownload = false,
  open,
  setOpen,
  rating,
  setRating,
  comment,
  setComment,
  images,
  setImages,
  oldImages,
  deleteImages,
  setDeleteImages,
  isEdit = false,
  reviewId = -1,
  isPublicFeedbackDone = true,
  isDevelopmentFeedbackDone = true,
}: {
  isBeforeDownload?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number>>;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  images: File[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  oldImages?: API_Review_Image[];
  deleteImages?: boolean[];
  setDeleteImages?: React.Dispatch<React.SetStateAction<boolean[]>>;
  isEdit?: boolean;
  reviewId?: number;
  isPublicFeedbackDone?: boolean;
  isDevelopmentFeedbackDone?: boolean;
}) => {
  const { profile } = useStore((state) => ({
    profile: state.profileDetails,
  }));

  const handleClose = () => {
    setRating(0);
    setComment("");
    setImages([]);
    setDeleteImages && setDeleteImages([]);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(49,47,48,0.4)",
        },
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Title
            isBeforeDownload={isBeforeDownload}
            isPublicFeedbackDone={isPublicFeedbackDone}
          />

          <ReviewForm
            isPublicFeedbackDone={isPublicFeedbackDone}
            rating={rating}
            setRating={setRating}
            comment={comment}
            setComment={setComment}
            images={images}
            setImages={setImages}
            oldImages={oldImages}
            deleteImages={deleteImages}
            setDeleteImages={setDeleteImages}
          >
            <ProfileDetails
              avatar={profile.image}
              name={profile.preferred_name}
              role={UserRoleMappingInverse[profile.role]}
              school={profile.school ? profile.school.name : "Little Lab"}
            />
          </ReviewForm>

          <Submit
            isPublicFeedbackDone={isPublicFeedbackDone}
            isDevelopmentFeedbackDone={isDevelopmentFeedbackDone}
            isBeforeDownload={isBeforeDownload}
            rating={rating}
            comment={comment}
            images={images}
            oldImages={oldImages}
            deleteImages={deleteImages}
            isEdit={isEdit} //Project summary is always gonna be adding review
            reviewId={reviewId}
            handleClose={handleClose}
            handleOpen={handleOpen}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

const Title = ({
  isBeforeDownload,
  isPublicFeedbackDone,
}: {
  isBeforeDownload: boolean;
  isPublicFeedbackDone: boolean;
}) => (
  <Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
    {translation.howProjectShareExperience}
  </Typography>
);

const ProfileDetails = ({
  avatar,
  name,
  role,
  school,
}: {
  avatar: string;
  name: string;
  role: string;
  school: string;
}) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: 1.5,
      mb: 2,
    }}
  >
    <Avatar
      src={avatar}
      alt={name}
      sx={{ width: "40px", height: "40px", mb: 0.25 }}
      imgProps={{
        onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
          event.currentTarget.onerror = null;
          event.currentTarget.src = Logo;
        },
      }}
    />

    <Profile name={name} role={role} school={school} />
  </Box>
);

const ReviewForm = ({
  isPublicFeedbackDone,
  rating,
  setRating,
  comment,
  setComment,
  images,
  setImages,
  oldImages,
  deleteImages,
  setDeleteImages,
  children,
}: {
  isPublicFeedbackDone: boolean;
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number>>;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  images: File[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  oldImages?: API_Review_Image[];
  deleteImages?: boolean[];
  setDeleteImages?: React.Dispatch<React.SetStateAction<boolean[]>>;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {!isPublicFeedbackDone && (
        <Rating
          sx={{
            fontSize: "3.5rem",
            mt: 1,
            mb: 2,
          }}
          value={rating}
          onChange={(event, newValue) => {
            if (newValue !== null) setRating(newValue);
          }}
        />
      )}

      {children}

      <TextField
        placeholder={
          !isPublicFeedbackDone
            ? translation.shareReview
            : translation.feedbackToDevTeam
        }
        value={comment}
        onChange={(event) => {
          setComment(event.target.value);
        }}
        fullWidth
        multiline
        rows={6}
      />
      <ImageField
        images={images}
        setImages={setImages}
        oldImages={oldImages}
        deleteImages={deleteImages}
        setDeleteImages={setDeleteImages}
      />
    </Box>
  );
};

const ImageField = ({
  images,
  setImages,
  oldImages,
  deleteImages,
  setDeleteImages,
}: {
  images: File[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  oldImages?: API_Review_Image[];
  deleteImages?: boolean[];
  setDeleteImages?: React.Dispatch<React.SetStateAction<boolean[]>>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        mt: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}>
        {oldImages &&
          deleteImages &&
          setDeleteImages &&
          oldImages.map(({ image }, index) => (
            <Box
              sx={{
                width: "100px",
                height: "100px",
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
                display: `${deleteImages[index] ? "none" : "block"}`,
              }}
              key={index}
            >
              {deleteImages[index] && (
                <Box
                  sx={{
                    position: "absolute",
                    backgroundColor: "#fc6f6fb3",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                />
              )}

              <Image src={image} alt={image} />

              <ClearIcon
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  zIndex: 3,
                  color: "txt.secondary",
                  cursor: "pointer",
                  backgroundColor: "primary.light",
                  borderRadius: "50%",
                  p: "3px",
                  transition: "all 100ms linear",
                  "&:hover": {
                    p: "4px",
                    backgroundColor: "primary.main",
                    transition: "all 100ms linear",
                  },
                }}
                onClick={() => {
                  setDeleteImages((prev) => {
                    const temp = JSON.parse(JSON.stringify(prev));
                    temp[index] = !temp[index];
                    return temp;
                  });
                }}
              />
            </Box>
          ))}

        {images.map((img, index) => (
          <Box
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "10px",
              overflow: "hidden",
              position: "relative",
            }}
            key={index}
          >
            <Image src={URL.createObjectURL(img)} alt={img.name} />

            <ClearIcon
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                color: "txt.secondary",
                cursor: "pointer",
                backgroundColor: "primary.light",
                borderRadius: "50%",
                p: "3px",
                transition: "all 100ms linear",
                "&:hover": {
                  p: "4px",
                  backgroundColor: "primary.main",
                  transition: "all 100ms linear",
                },
              }}
              onClick={() => {
                setImages((prev) => {
                  const temp = [...prev];
                  temp.splice(index, 1);
                  return temp;
                });
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Submit = ({
  isPublicFeedbackDone,
  isDevelopmentFeedbackDone,
  isBeforeDownload,
  rating,
  comment,
  images,
  oldImages,
  deleteImages,
  isEdit = false,
  reviewId,
  handleClose,
  handleOpen,
}: {
  isPublicFeedbackDone: boolean;
  isDevelopmentFeedbackDone: boolean;
  isBeforeDownload: boolean;
  rating: number;
  comment: string;
  images: File[];
  oldImages?: API_Review_Image[];
  deleteImages?: boolean[];
  isEdit?: boolean;
  reviewId: number;
  handleClose: () => void;
  handleOpen: () => void;
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    projID,
    currLanguage,
    setRefreshProjAndLess,
    setIsRefreshReviewList,
    projSlug,
  } = useStore((state) => ({
    projID: state.currentProject.id,
    currLanguage: state.currLanguage,
    setRefreshProjAndLess: state.setRefreshProjAndLess,
    setIsRefreshReviewList: state.setIsRefreshReviewList,
    projSlug: state.currentProject.slug,
  }));

  const addReview = async () => {
    setIsLoading(true);

    const base64Images: { image: string }[] = [];
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const base64 = await getBase64(images[i]);

        base64Images.push({ image: base64 });
      }
    }

    const reviewData: API_Review = {
      comment,
      rating,
      images: base64Images,
      profile: JSON.parse(localStorage.getItem("profile_id") || ""),
      project: projID.toString(),
    };

    let res;
    if (!isPublicFeedbackDone) {
      res = await createReview(
        reviewData,
        localStorage.getItem("access"),
        currLanguage
      );
    } else {
      res = await createDevelopmentFeedback(
        {
          comment: reviewData.comment,
          images: reviewData.images,
          profile: reviewData.profile,
          project: reviewData.project,
        },
        localStorage.getItem("access"),
        currLanguage
      );
    }

    setIsLoading(false);

    if (res !== "Error") {
      if (!isPublicFeedbackDone) {
        setRefreshProjAndLess(true);
        setIsRefreshReviewList(true);
        openSnack(translation.reviewPosted, true);
        handleClose();
        if (!isDevelopmentFeedbackDone) {
          handleOpen(); // open up to show next private feedback
        } else {
          isBeforeDownload && handleRedirect(); //if done also then re-direct
        }
      } else {
        handleClose(); //this is on the private feedback page
        openSnack(translation.thankyouFeedback, true);
        isBeforeDownload && handleRedirect(); //redirect afterwards
      }
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const editReview = async () => {
    if (reviewId === -1) return;

    setIsLoading(true);

    // Delete selected images
    if (deleteImages && oldImages) {
      const imageIDs: number[] = [];

      for (let i = 0; i < deleteImages.length; i++) {
        if (deleteImages[i]) {
          imageIDs.push(oldImages[i].id);
        }
      }

      if (imageIDs.length !== 0) {
        await deleteReviewImages(
          currLanguage,
          imageIDs,
          localStorage.getItem("access")
        );
      }
    }

    const base64Images: { image: string }[] = [];

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const base64 = await getBase64(images[i]);

        base64Images.push({ image: base64 });
      }
    }

    const reviewData: API_Update_Review = {
      comment,
      rating,
      images: base64Images,
    };

    let res;

    res = await updateReview(
      currLanguage,
      reviewId,
      reviewData,
      localStorage.getItem("access")
    );

    setIsLoading(false);

    if (res !== "Error") {
      handleClose();
      openSnack(translation.reviewUpdated, true);
      setRefreshProjAndLess(true);
      setIsRefreshReviewList(true);
    } else {
      openSnack(translation.tryAgainLater, false);
    }
  };

  const handleRedirect = () =>
    navigate(
      `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projSlug)}/report`
    );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", sm: "flex-end" },
        mt: 4,
      }}
    >
      {isBeforeDownload && (
        <Box sx={{ width: "100%" }}>
          <Button
            buttonText={translation.skip || "Skip"}
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "var(--primary-main)",
              padding: "0",
              marginRight: "auto",
              textAlign: "center",
            }}
            onClick={() => {
              handleRedirect();
              handleClose();
            }}
          />
        </Box>
      )}

      <Button
        buttonText={translation.cancel || "Cancel"}
        arrow={false}
        style={{
          fontSize: "14px",
          backgroundColor: "transparent",
          color: "var(--primary-main)",
          border: "1px solid var(--primary-main)",
          padding: "9px 25px",
          marginRight: "16px",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        onClick={handleClose}
      />

      <Button
        arrow={false}
        disabled={(rating === 0 && comment === "") || isLoading}
        style={{
          fontSize: "14px",
          backgroundColor: "var(--primary-main)",
          width: "100%",
          maxWidth: "175px",
          textAlign: "center",
        }}
        buttonText={
          isEdit
            ? translation.update || "Update"
            : translation.submit || "Submit"
        }
        onClick={isEdit ? editReview : addReview}
      />
    </Box>
  );
};

export default ReviewModal;

import { getGroupLessonsInfo } from "api/profile-api";
import { useEffect } from "react";
import useStore from "store/store";

export const usePopulateLessonsInfo = () => {
  const {
    currLanguage,
    refreshGroupLessonInfoList,
    groupLessonInfoList,
    setIsGroupLessonInfoListLoading,
    setGroupLessonInfoList,
    setRefreshGroupLessonInfoList,
  } = useStore((state) => ({
    ...state,
    firstName: state.profileDetails.preferred_name,
    role: state.profileDetails.role,
  }));

  useEffect(() => {
    const populateLessons = async () => {
      setIsGroupLessonInfoListLoading(true);

      const groupLessonInfoList = await getGroupLessonsInfo(
        currLanguage,
        localStorage.getItem("access")
      );
      setGroupLessonInfoList(groupLessonInfoList);
      setRefreshGroupLessonInfoList(false);
      setIsGroupLessonInfoListLoading(false);
    };

    if (refreshGroupLessonInfoList || !groupLessonInfoList?.length) {
      populateLessons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLanguage, groupLessonInfoList?.length, refreshGroupLessonInfoList]);
};

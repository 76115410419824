import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography, Avatar, ListItem, IconButton } from "@mui/material";
import Logo from "images/logo-2.png";
import { API_Project } from "types/project";
const BackpackCardMobile = ({
  project,
  handleOpen,
  setProjectCard,
  setIsUpdate,
}: {
  project: API_Project;
  groupId: number;
  handleOpen: any;
  setProjectCard: any;
  setIsUpdate: any;
}) => {
  // console.log(project);
  // console.log(projects);
  // let foundObject = allProjects.find(
  //   (obj) => obj.projectTitle === project.name
  // );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        px: "12px",
      }}
      onClick={() => {
        setProjectCard((prevState: any) => ({
          id: project.id,
          projectTitle: project.name,
          image: project.images[0].file,
          projectSlug: project.slug,
          moduleName: project.module.name,
          startAt: null,
          endAt: null,
        }));
        setIsUpdate(false);
        handleOpen();
      }}
    >
      <ListItem
        sx={{
          m: 0,
          p: 0,
          display: "flex",
          width: "100%",
        }}
        onClick={() => {}}
      >
        <Box
          sx={{
            backgroundColor:
              project.module.category == "Little Projects"
                ? "#F8FDFD"
                : "#FFFFEF",
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",

            border: `1px solid ${
              project.module.category === "Little Projects"
                ? "#91C6C5"
                : "#ECEA5C"
            }`,

            borderLeft: `4px solid ${
              project.module.category === "Little Projects"
                ? "#91C6C5"
                : "#ECEA5C"
            }`,
            borderRadius: "4px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              p: "8px",
            }}
          >
            <Avatar
              src={project.images[0].file ? project.images[0].file : Logo}
              sx={{
                width: "40px",
                height: "40px",

                display: "flex",
              }}
              imgProps={{
                onError: (
                  event: React.SyntheticEvent<HTMLImageElement, Event>
                ) => {
                  event.currentTarget.onerror = null;
                  event.currentTarget.src = Logo;
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "450", fontSize: "14px" }}>
                {project.module.name}
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: "12px" }}>
                {project.name}
              </Typography>
            </Box>
          </Box>
          <IconButton>
            <AddIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Box>
      </ListItem>
    </Box>
  );
};

export default BackpackCardMobile;

import { useMemo } from "react";
import { Page, Image, View } from "@react-pdf/renderer";
import { pageSize, styles, imageSrc } from "./constant";
import { PDFInfoType } from "../Preview/constant";
import HeadBackground from "images/children-portfolio-v2/head-background.png";
import HandsBackground from "images/children-portfolio-v2/hands-background.png";
import HeartBackground from "images/children-portfolio-v2/heart-background.png";
import DevelopmentalGoal from "./DevelopmentalGoalComponents/DevelopmentalGoal";
import { API_Fundamental, API_Milestone } from "types/project";
import {
  HEART_TEXT,
  HANDS_TEXT,
} from "containers/PortfolioPage/portfolioPageConstants";
import { calculateItemArrangement } from "../Preview/DevelopmentalGoalPage";

const DevelopmentalGoalPage = ({
  PDFInfo,
  tag,
}: {
  PDFInfo: PDFInfoType;
  tag: string;
}) => {
  const achievedMilestones = useMemo(
    () =>
      PDFInfo.childAllAchievedMilestoneTrackers
        .filter((tracker) => tracker.status && tracker.milestone != null)
        .map((tracker) => tracker.milestone),
    [PDFInfo.childAllAchievedMilestoneTrackers]
  );

  const backgroundImage =
    tag.toLowerCase() === HEART_TEXT.toLowerCase()
      ? HeartBackground
      : tag.toLowerCase() === HANDS_TEXT.toLowerCase()
      ? HandsBackground
      : HeadBackground;
  const relatedDevelopmentalGoals = useMemo(() => {
    return PDFInfo.developmentalGoalList.filter(
      (developmentalGoal) =>
        developmentalGoal.tag.toLowerCase() === tag.toLowerCase()
    );
  }, [PDFInfo.developmentalGoalList, tag]);

  const consolidatedDevGoals = useMemo(() => {
    const _consolidatedDevGoals: API_Fundamental[] = [];

    relatedDevelopmentalGoals.forEach((devGoal) => {
      const newDevGoal = {
        ...devGoal,
        achievedMilestones: [] as API_Milestone[],
      };

      newDevGoal.achievedMilestones = newDevGoal.milestones.filter(
        (milestone) => {
          return achievedMilestones.includes(milestone.id);
        }
      );

      if (devGoal.sub_fundamentals && devGoal.sub_fundamentals.length > 0) {
        const subFundamentals = devGoal.sub_fundamentals?.sort(
          (a: any, b: any) => a.priority - b.priority
        );
        subFundamentals.forEach((subDevGoal) => {
          subDevGoal.milestones.forEach((milestone) => {
            if (achievedMilestones.includes(milestone.id)) {
              newDevGoal.achievedMilestones.push(milestone);
            }
          });
        });
      }

      newDevGoal.milestones = newDevGoal.achievedMilestones;
      _consolidatedDevGoals.push(newDevGoal);
    });

    return _consolidatedDevGoals;
  }, [achievedMilestones, relatedDevelopmentalGoals]);

  const devGoalsPages = useMemo(() => {
    const devGoalsWithMilestones = consolidatedDevGoals.filter(
      (devGoal) => devGoal.milestones.length > 0
    );
    return calculateItemArrangement(devGoalsWithMilestones, true);
  }, [consolidatedDevGoals]);

  if (devGoalsPages.length === 0) {
    return null;
  }

  return (
    <>
      {devGoalsPages.map((devGoals, index) => {
        if (devGoals.length === 0) {
          return null;
        }

        return (
          <Page key={index} size={pageSize} style={styles.body}>
            <View style={styles.main} wrap={false}>
              <Image
                src={imageSrc(backgroundImage)}
                style={styles.background}
              />

              <View
                style={{
                  position: "absolute",
                  top: 100,
                  left: 0,
                  width: "100%",
                  paddingRight: 20,
                  paddingLeft: 40,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {devGoals.map((devGoal, devGoalIndex, devGoalArray) => {
                  const devGoalIds = [devGoal.id];
                  if (
                    devGoal.sub_fundamentals &&
                    devGoal.sub_fundamentals.length > 0
                  ) {
                    devGoalIds.push(
                      ...devGoal.sub_fundamentals.map(
                        (subFundamental) => subFundamental.id
                      )
                    );
                  }

                  if (devGoal.main_fundamental) {
                    devGoalIds.push(devGoal.main_fundamental);
                  }

                  const devGoalObservations = PDFInfo.observationList.filter(
                    (observation) =>
                      devGoalIds.includes(observation.developmental_goal)
                  );

                  if (devGoal.milestones.length > 0) {
                    return (
                      <DevelopmentalGoal
                        key={devGoal.id}
                        devGoal={devGoal}
                        previousDevGoal={
                          devGoalIndex === 0
                            ? undefined
                            : devGoalArray[devGoalIndex - 1]
                        }
                        observation={
                          (devGoal as any).page <= devGoalObservations.length
                            ? devGoalObservations[(devGoal as any).page]
                            : undefined
                        }
                        developmentalGoals={
                          PDFInfo.childAllAchievedMilestoneTrackers
                        }
                      />
                    );
                  }
                })}
              </View>
            </View>
          </Page>
        );
      })}
    </>
  );
};

export default DevelopmentalGoalPage;

import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Modal from "components/Modal";
import Button from "components/Button/Button";
import { environment } from 'environment';

const TOO_MANY_REQUESTS_STATUS_CODE = 429;

type Props = {
  error: { status: number; message: string } | undefined;
};

const AuthError = ({ error }: Props) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    console.log("Error", error);
    if (error?.status === TOO_MANY_REQUESTS_STATUS_CODE) {
      setOpen(true);
    }
  }, [error]);

  if (!error) {
    return null;
  }

  return (
    <>
      {error && error.status !== 429 && (
        <Alert variant="outlined" severity="error" sx={{ width: "100%" }}>
          {error.message}
        </Alert>
      )}
      <Modal
        open={isOpen}
        handleClose={() => setOpen(false)}
        sx={{ width: "420px" }}
      >
        <Typography
          variant="h6"
          sx={{
            width: "90%",
            mx: "auto",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: 1.5,
            textAlign: "center",
          }}
        >
          Too Many Failed Login Attempts
        </Typography>
        <Typography
          sx={{
            color: "neutral.900",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: 1.5,
            textAlign: "center",
          }}
        >
          We're sorry, but your login attempts have exceeded the allowed limit.
          For security reasons, your account has been temporarily blocked. You
          will be able to attempt login again in approximately{" "}
          {environment.rateLimitBlockMinutes} minutes.
          <br />
          <br />
          If you have forgotten your password, please use the 'Forgot Password'
          option to reset your credentials.
          <br />
          If you believe this is a mistake or need further assistance, please
          contact our support team at edtech@trehaus.co. Thank you for helping
          us maintain the security of your account.
        </Typography>
        <Button
          style={{ width: "100%" }}
          buttonText="Got it"
          arrow={false}
          onClick={() => setOpen(false)}
        />
      </Modal>
    </>
  );
};

export default AuthError;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import { Box, Typography } from "@mui/material";
import ProjectSummaryDetailCard from "../DetailCard/ProjectSummaryDetailCard";
import { DownloadButton } from "../Button/Button";
import { getGalleryByProject } from "api/gallery-api";
import { Gallery, Image, ThumbnailImageProps } from "react-grid-gallery";
import "./ReactGridGallery.css";
import { translation } from "constants/translation";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import ReviewModal from "../Review/ReviewModal";
import CustomImage from "../Image/Image";
import { getIsDevelopmentFeedbackDone } from "api/review-api";
import { getProjectSummaryReport } from "api/report-api";
import { Project_Summary_Report } from "types/project";
import ProjectSummaryReportCard from "containers/ChildrenPortfolioPage/Screens/ProjectSummaryReportComponents/ProjectSummaryReportCard";
import {
  titleFontSize,
  titleMobileFontSize,
} from "constants/constantInGeneral";

export interface GalleryImage {
  lesson: string;
  images: Image[];
}

const ProjectSummaryScreen = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState<Image[]>([]);

  const {
    profileDetails,
    developmentalGoals,
    projID,
    currLanguage,
    projSlug,
    lessons,
    currGroup,
    reviews,
    fundamentalDict,
  } = useStore((state) => ({
    profileDetails: state.profileDetails,
    developmentalGoals: state.currentProject.fundamentals,
    projID: state.currentProject.id,
    currLanguage: state.currLanguage,
    projSlug: state.currentProject.slug,
    lessons: state.currentLessons.lessons,
    currGroup: state.currGroup,
    reviews: state.currentProject.reviews,
    fundamentalDict: state.fundamentalDict,
  }));

  const initialiseGallery = async () => {
    const data = await getGalleryByProject(
      currLanguage,
      projID.toString(),
      localStorage.getItem("access"),
      localStorage.getItem("curr_group_id")
    );

    if (typeof data !== "string") {
      const imagesList: Image[] = [];

      data
        .filter((d) =>
          d.lesson === undefined || d.lesson === "" || d.activity === null
            ? false
            : true
        )
        .sort(
          (a, b) =>
            lessons.findIndex((l) => l.name === a.lesson) -
            lessons.findIndex((l) => l.name === b.lesson)
        )
        .forEach((d) => {
          d.images.forEach((img) => {
            const isLearningMoment = img.learning_moment !== null;
            const imageData: Image = {
              key: `photo-${img.id}${isLearningMoment ? "-learning-moment" : ""
                }`,
              src: img.image,
              width: 120,
              height: 120,
              alt: img.caption,
              customOverlay:
                imagesList.length !== 0 ? (
                  <div className="number-overlay">
                    <p>+{imagesList.length + 1}</p>
                    <p className="view-all">{translation.viewAll}</p>
                  </div>
                ) : (
                  <div className="custom-overlay">
                    <p>{translation.viewAll}</p>
                  </div>
                ),
            };
            imagesList.unshift(imageData);
          });
        });

      setImages(imagesList);
    }
  };

  useEffect(() => {
    if (lessons.length === 0 || currGroup.id === -1) return;

    initialiseGallery();

    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currGroup, lessons]);

  const PhotoThumbnailComponent = (props: ThumbnailImageProps) => {
    const currPhoto = images.filter(
      (image) => image.src === props.imageProps.src
    )[0];
    const isLearningMoment = String(currPhoto.key).includes("learning-moment");
    return (
      <Box sx={{ width: 120, height: 120 }} key={props.imageProps.key}>
        {/* {isLearningMoment && (
            <Box sx={{ position: "absolute", top: 5, left: 5 }}>
              <LightbulbIcon color="primary" />
            </Box>
          )} */}
        <CustomImage
          src={props.imageProps.src}
          objectFit="cover"
          style={{
            minWidth: "120px",
            maxWidth: "120px",
            minHeight: "120px",
            maxHeight: "120px",
            borderRadius: "10px",
          }}
          alt="secondary"
        />
      </Box>
    );
  };

  // Review
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isPublicFeedbackDone, setIsPublicFeedbackDone] =
    useState<boolean>(false);
  const [isDevelopmentFeedbackDone, setIsDevelopmentFeedbackDone] =
    useState<boolean>(false);

  const populateIsDevelopmentFeedbackDone = async () => {
    const res = await getIsDevelopmentFeedbackDone(
      projSlug,
      currLanguage,
      localStorage.getItem("access")!
    );

    if (typeof res !== "string") {
      setIsDevelopmentFeedbackDone(res);
    }
  };

  useEffect(() => {
    projSlug && populateIsDevelopmentFeedbackDone();
  }, [projSlug]);

  useEffect(() => {
    const ratedUserIDs = reviews.map((review) => review.profile.id);
    setIsPublicFeedbackDone(ratedUserIDs.includes(Number(profileDetails.id)));
  }, [reviews.length]);

  let projectCards = []
  const flattenStructure = (items: any[]): any[] => {
    const seenItems = new Set();
    return items.reduce((acc, item) => {
      const shouldAddItem = !seenItems.has(item.name) && (!item.subFundamentals || !seenItems.has(item.subFundamentals.some((sub: any) => sub.name)));

      if (shouldAddItem) {
        acc.push(item);
        seenItems.add(item.name);
        if (item.subFundamentals) {
          item.subFundamentals.forEach((sub: any) => seenItems.add(sub.name));
        }
      }

      return acc;
    }, []);
  };

  const sortByPriority = (items: any[], comparator?: (a: any, b: any) => number): any[] => {
    return items.sort(comparator || ((a: any, b: any) => a.priority - b.priority));
  };
  const heart: any = [];
  const head: any = [];
  const hands: any = [];
  let targetArray: any[];
  developmentalGoals.forEach((devGoal: any) => {
    const filteredMilestones = fundamentalDict[
      devGoal.fundamental
    ].milestones.filter((milestone) => milestone.id === devGoal.milestone);
    const fundamentalCard = fundamentalDict[filteredMilestones[0].fundamental]
    const objectiveFundamentalMilestone = filteredMilestones[0]
    const objectiveFundamentalMilestoneName =
      filteredMilestones.length > 0 ? filteredMilestones[0].name : "";
    if (fundamentalCard.main_fundamental == null) {

      switch (fundamentalCard.tag) {
        case 'heart':
          targetArray = heart;
          break;
        case 'head':
          targetArray = head;
          break;
        case 'hands':
          targetArray = hands;
          break;
        default:
          console.error("Unknown tag:", fundamentalCard.tag);
          return;
      }

      const projectCard = targetArray.find((project: any) => project.name == fundamentalCard?.name)

      if (!projectCard) {
        targetArray.push({
          name: fundamentalCard?.name,
          image: fundamentalCard?.image,
          objectives: {
            [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
          },
          priority: fundamentalCard?.priority,
          tag: fundamentalCard?.tag
        });
      }
      else if (
        !projectCard.objectives.hasOwnProperty(objectiveFundamentalMilestone.name)
      ) {
        projectCard.objectives[objectiveFundamentalMilestone.name] = objectiveFundamentalMilestone.weight;

      }
    } else if (fundamentalCard.main_fundamental) {

      if (fundamentalCard.main_fundamental) {
        const mainFundamentalCard = fundamentalDict[fundamentalCard.main_fundamental]
        switch (fundamentalCard.tag) {
          case 'heart':
            targetArray = heart;
            break;
          case 'head':
            targetArray = head;
            break;
          case 'hands':
            targetArray = hands;
            break;
          default:
            console.error("Unknown tag:", fundamentalCard?.tag);
            return; // Exit the loop if an unknown tag is encountered
        }
        const projectCard = targetArray.find((project: any) => project.name == mainFundamentalCard?.name)
        if (!projectCard) {
          targetArray.push({
            name: mainFundamentalCard?.name,
            image: mainFundamentalCard?.image,
            priority: mainFundamentalCard?.priority,
            tag: mainFundamentalCard?.tag,

            subFundamentals: [
              {
                name: devGoal.name,
                objectives: {
                  [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
                },
                priority: fundamentalDict[devGoal.fundamental].priority
              },
            ],
          });
        } else if (
          !projectCard.subFundamentals.find(
            (sub: any) => sub.name == devGoal.name
          )
        ) {
          projectCard.subFundamentals.push({
            name: devGoal.name,
            objectives: {
              [objectiveFundamentalMilestone.name]: objectiveFundamentalMilestone.weight
            },
            priority: fundamentalDict[devGoal.fundamental].priority

          });
        }
        else if (
          !projectCard.subFundamentals.find((sub: any) =>
            sub.objectives.hasOwnProperty(objectiveFundamentalMilestone.name)
          )
        ) {
          projectCard.subFundamentals
            .find((sub: any) => sub.name == devGoal.name)
            .objectives[objectiveFundamentalMilestone.name] = objectiveFundamentalMilestone.weight;
        }
      }

    }
  });
  const sortedHeart = sortByPriority(flattenStructure(heart));
  const sortedHead = sortByPriority(flattenStructure(head));
  const sortedHands = sortByPriority(flattenStructure(hands));

  projectCards = [
    ...sortedHeart.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] })),
    ...sortedHead.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] })),
    ...sortedHands.map(card => ({ ...card, subFundamentals: card.subFundamentals ? sortByPriority(card.subFundamentals) : [] }))
  ].flat();


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        p: "30px",
        position: "relative",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: { xs: titleMobileFontSize, sm: titleFontSize },
            textAlign: "center",
            mb: "20px",
          }}
        >
          {translation.congrats}
          <br />
          {translation.completedProject}
        </Typography>

        <Box
          sx={{
            display: "flex",
            overflowX: "auto",
            gap: { xs: 1, sm: 2, md: 3 },
            pb: 2,
            "& > *:first-of-type": {
              marginLeft: "auto",
            },
            "& > *:last-of-type": {
              marginRight: "auto",
            },
            mb: -2,
          }}
        >
          {lessons.map((lesson, index) => (
            <ProjectSummaryDetailCard
              lesson={lesson}
              lessonIndex={index}
              key={lesson.id}
              handleClick={() => { }}
            />
          ))}
        </Box>
      </Box>

      {projectCards.length > 0 && (
        <Box>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "24px",
              textAlign: "center",
              mb: 2,
            }}
          >
            {translation.fundamental}
          </Typography>

          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              gap: { xs: 1, sm: 2, md: 3 },
              pb: 2,
              "& > *:first-of-type": {
                marginLeft: "auto",
              },
              "& > *:last-of-type": {
                marginRight: "auto",
              },
              mb: -2,
            }}
          >
            {projectCards.map((goal, index) => (
              <Box
                key={index}
                sx={{
                  flexShrink: 0,
                  width: { xs: 125, sm: 150 },
                  maxWidth: "150px",
                  borderRadius: "20px",
                  backgroundColor: "#F0F0F0BF",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CustomImage
                  src={goal.image}
                  alt={goal.name}
                  style={{
                    marginTop: "20px",
                    width: "80px",
                    height: "80px",
                    backgroundColor: "primary.light",
                  }}
                />

                <Typography
                  sx={{
                    fontWeight: 500,
                    textAlign: "center",
                    p: "10px",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {goal.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {images.length > 0 && (
        <Box>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "24px",
              textAlign: "center",
              mb: 0.5,
            }}
          >
            {translation.photoAlbum}
          </Typography>

          <Box
            className="project-summary-project-moments"
            sx={{
              position: "relative",
              mx: "-12px",
              display: "flex",
              flexDirection: "column",

              gap: "20px",
              pointerEvents: "none",
            }}
          >
            <Gallery
              images={images}
              onClick={() =>
                navigate(
                  `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
                    projSlug
                  )}/photo-album`
                )
              }
              enableImageSelection={false}
              margin={12}
              rowHeight={120}
              thumbnailStyle={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              maxRows={1}
              thumbnailImageComponent={PhotoThumbnailComponent}
            />
          </Box>
        </Box>
      )}
      <RateYourExperience
        isPublicFeedbackDone={isPublicFeedbackDone}
        isDevelopmentFeedbackDone={isDevelopmentFeedbackDone}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <ProjectSummaryReportSection
        isPublicFeedbackDone={isPublicFeedbackDone}
        isDevelopmentFeedbackDone={isDevelopmentFeedbackDone}
        setOpenModal={setOpenModal}
      />
    </Box>
  );
};

const ProjectSummaryReportSection = ({
  isPublicFeedbackDone,
  isDevelopmentFeedbackDone,
  setOpenModal,
}: {
  isPublicFeedbackDone: boolean;
  isDevelopmentFeedbackDone: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const initialProjectSummaryReport = {
    id: -1,
    file: "",
    child: [],
    project: {
      id: -1,
      module: {
        id: -1,
        name: "",
        image: "",
        category: "",
      },
      image: "",
      name: "",
    },
    group: -1,
    created_at: "",
    created_by: "",
  };

  const navigate = useNavigate();
  const { currLanguage, currentProject, currGroup } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currentProject: state.currentProject,
    currGroup: state.currGroup,
  }));

  const [projectSummaryReport, setProjectSummaryReport] =
    useState<Project_Summary_Report>(initialProjectSummaryReport);

  useEffect(() => {
    currGroup &&
      currGroup.id &&
      setProjectSummaryReport(initialProjectSummaryReport);
  }, [currGroup.id]);

  useEffect(() => {
    const populateProjectSummaryReport = async () => {
      const res = await getProjectSummaryReport(
        currentProject.id,
        currGroup.id
      );
      if (typeof res !== "string" && res !== undefined) {
        setProjectSummaryReport(res);
      }
    };

    currentProject &&
      currentProject.id !== -1 &&
      currGroup &&
      currGroup.id !== -1 &&
      populateProjectSummaryReport();
  }, [currentProject.id, currGroup.id]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {projectSummaryReport.file && projectSummaryReport.file !== "" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "24px",
              textAlign: "center",
              mb: 0.5,
            }}
          >
            {translation.projectSummaryReport}
          </Typography>
          <ProjectSummaryReportCard
            key={`project-summary-report-${projectSummaryReport.id}`}
            projectSummaryReport={projectSummaryReport}
          />
        </Box>
      ) : null}
      <DownloadButton
        style={{
          alignSelf: "center",
          padding: "8px 16px",
          width: "fit-content",
          height: "fit-content",
          fontWeight: 500,
          fontSize: "14px",
        }}
        btnText={
          projectSummaryReport.file !== ""
            ? translation.reGenerateReport
            : translation.getReport
        }
        onClick={() =>
          isPublicFeedbackDone && isDevelopmentFeedbackDone
            ? navigate(
              `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(
                currentProject.slug
              )}/report`
            )
            : setOpenModal(true)
        }
      />
    </Box>
  );
};

const RateYourExperience = ({
  openModal,
  setOpenModal,
  isPublicFeedbackDone,
  isDevelopmentFeedbackDone,
}: {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isPublicFeedbackDone: boolean;
  isDevelopmentFeedbackDone: boolean;
}) => {
  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);

  return (
    <>
      <ReviewModal
        isBeforeDownload={true}
        open={openModal}
        setOpen={setOpenModal}
        rating={rating}
        setRating={setRating}
        comment={comment}
        setComment={setComment}
        images={images}
        setImages={setImages}
        isPublicFeedbackDone={isPublicFeedbackDone}
        isDevelopmentFeedbackDone={isDevelopmentFeedbackDone}
      />
    </>
  );
};

export default ProjectSummaryScreen;

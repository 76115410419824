import React from "react";

import { Box, Typography, Avatar } from "@mui/material";
import CustomImage from "components/Image/Image";
import Logo from "images/logo-2.png";
import { API_Project } from "types/project";
import {
  AVATAR_WIDTH,
  BACKPACK_CARD_MIN_WIDTH,
  LITTLES_LEARNERS_COLOR,
  LITTLES_PROJECTS_COLOR,
  LITTLE_LEARNERS,
  PROJECT_BOX,
} from "../../constants/constants";
import { useDrag } from "react-dnd";
import useStore from "store/store";

const BackpackCard = ({
  project,
  groupId,
}: // removeProject,
{
  project: API_Project;
  groupId: number;
  // removeProject: () => void;
}) => {
  const { groupProjectBoxProps } = useStore((state) => ({
    groupProjectBoxProps: state.groupProjectBoxProps,
  }));
  const [{ isDragging }, drag] = useDrag(() => ({
    type: PROJECT_BOX,
    item: {
      projectId: project.id,
      groupId: groupId,
      groupProjectBoxPropsFromDrop: groupProjectBoxProps,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  const title1 = project?.module?.name;
  const title2 = project.name;
  // console.log("project.module.category: ", project.module.category);m
  return (
    <Box
      ref={drag}
      sx={{
        borderRadius: "10px",
        height: "100%",
        minWidth: BACKPACK_CARD_MIN_WIDTH,
        maxWidth: 250,
        position: "relative",
        overflow: "hidden",
        boxShadow:
          "4px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1)",
        userSelect: "none",
        cursor: "move",
        opacity: isDragging ? 0.5 : 1,
        backgroundColor:
          project?.module?.category === LITTLE_LEARNERS
            ? LITTLES_LEARNERS_COLOR
            : LITTLES_PROJECTS_COLOR,
      }}
    >
      <BackpackCardBoxContent
        image={project.images[0]?.file}
        title1={title1}
        title2={title2}
      />
      {/* <BackpackCardImg img={project.images[0]?.file} title={project.name} />
      <BackpackCardContent title={project.name} /> */}
    </Box>
  );
};

const BackpackCardBoxContent = ({
  image,
  title1,
  title2,
}: {
  image: string;
  title1: string;
  title2: string;
}) => {
  const fontStyleTemp = {
    fontSize: "12px",
    userSelect: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    pointerEvents: "none",
  };
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        m: 1,
      }}
    >
      <Avatar
        src={image ? image : Logo}
        sx={{
          position: "absolute",
          width: "-webkit-fill-available",
          height: "-webkit-fill-available",
          maxWidth: AVATAR_WIDTH,
          maxHeight: AVATAR_WIDTH,
          backgroundColor: "primary.main",
          color: "txt.light",
          pointerEvents: "none",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />
      <Box
        sx={{
          marginLeft: `${AVATAR_WIDTH + 10}px`,
          width: "-webkit-fill-available",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            ...fontStyleTemp,
          }}
        >
          {title1}
        </Typography>
        <Typography
          sx={{
            ...fontStyleTemp,
          }}
        >
          {title2}
        </Typography>
      </Box>
    </Box>
  );
};

const BackpackCardImg = ({ img, title }: { img: string; title: string }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "30px",
      }}
    >
      <CustomImage
        src={img || Logo}
        alt={title}
        objectFit="cover"
        draggable={false}
      />
    </Box>
  );
};

const BackpackCardContent = ({ title }: { title: string }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      sx={{
        backgroundColor: "#FCFCFC",
      }}
    >
      <Typography>{title}</Typography>
    </Box>
  );
};
export default BackpackCard;

import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  deleteCommunityKanbanBoard,
  getCommunityKanbanBoardList,
} from "api/teacher-api";
import UnderConstruction from "components/UnderConstruction/UnderConstruction";
import { API_Teacher_Community_Kanban_Board } from "types/teacher";

//Card
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import AddIcon from "@mui/icons-material/Add";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { translation } from "constants/translation";
import CreateEditKanbanBoardModal from "./CreateEditKanbanBoardModal";
import useModal from "hooks/useModal";
import EditIcon from "@mui/icons-material/Edit";
import { CARD_HEIGHT, CARD_WIDTH } from "./kanbanConstant";
import {
  COMMUNITY_KANBAN_BOARD_PAGE,
  CURLANG,
} from "constants/url";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useDialog from "hooks/useDialog";
import useSnack from "hooks/useSnack";
import WarningDialog from "components/Dialog/WarningDialog";

const initialKanbanBoard = {
  id: -1,
  name: "",
  slug: "",
  description: "",
  created_at: "",
  updated_at: "",
  created_by: -1,
};

{
  /* <UnderConstruction title="Community" /> */
}

const TeacherCommunityPage = () => {
  const { kanbanBoardList, setKanbanBoardList } = useStore((state) => ({
    kanbanBoardList: state.kanbanBoardList,
    setKanbanBoardList: state.setKanbanBoardList,
  }));
  const [isKanbanBoardListLoading, setIsKanbanBoardListLoading] =
    useState<boolean>(true);
  const [selectedKanbanBoard, setSelectedKanbanBoard] =
    useState<API_Teacher_Community_Kanban_Board>(initialKanbanBoard);
  const { open, handleOpen, handleClose } = useModal();

  const populateKanbanBoardList = async () => {
    const kanbanBoardListRes = await getCommunityKanbanBoardList();
    if (typeof kanbanBoardListRes !== "string") {
      setKanbanBoardList(kanbanBoardListRes);
    } else {
      setKanbanBoardList([]);
    }
    setIsKanbanBoardListLoading(false);
  };
  useEffect(() => {
    populateKanbanBoardList();
  }, []);

  return (
    <>
      <CreateEditKanbanBoardModal
        open={open}
        handleClose={handleClose}
        kanbanBoard={selectedKanbanBoard}
      />
      <Box
        sx={{
          width: "100%",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#FBFBFB",
            borderRadius: "10px",
            p: 1,
            width: "100%",
            maxWidth: "90vw",
          }}
        >
          {/* Header bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              px: 2,
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Box>
              <Typography
                sx={{ alignSelf: "center", fontWeight: 500 }}
                variant="h5"
              >
                {translation.communitySharing}
              </Typography>
            </Box>

            {/* <SearchBar
            handleChange={(searchVal: string) => {
              const searchValLowerCase = searchVal.toLowerCase();
              setCurrModules(
                teacherModules.filter((module_) =>
                  module_.name.toLowerCase().includes(searchValLowerCase)
                )
              );
            }}
          /> */}
          </Box>

          <Box>
            {isKanbanBoardListLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <LoadingIndicator />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2rem",
                    justifyContent: "center",
                  }}
                >
                  {kanbanBoardList?.map((kanbanBoard) => {
                    return (
                      <Fragment key={`kanban-board-${kanbanBoard.id}`}>
                        <KanbanBoardIndividualTopic
                          kanbanBoard={kanbanBoard}
                          handleOpen={handleOpen}
                          setSelectedKanbanBoard={setSelectedKanbanBoard}
                        />
                      </Fragment>
                    );
                  })}
                  <AddKanbanBoardButton
                    handleOpen={handleOpen}
                    setSelectedKanbanBoard={setSelectedKanbanBoard}
                  />
                </Box>
              </>
            )}
          </Box>

          {/* ---------- Cards Section end ---------- */}
        </Box>
      </Box>
    </>
  );
};

const KanbanBoardIndividualTopic = ({
  kanbanBoard,
  setSelectedKanbanBoard,
  handleOpen,
}: {
  kanbanBoard: API_Teacher_Community_Kanban_Board;
  setSelectedKanbanBoard: React.Dispatch<
    React.SetStateAction<API_Teacher_Community_Kanban_Board>
  >;
  handleOpen: () => void;
}) => {
  const navigate = useNavigate();
  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = !!anchorEl;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // console.log("kanbanBoard: ", kanbanBoard);
  return (
    <Box>
      <Card
        sx={{
          minHeight: CARD_HEIGHT,
          minWidth: CARD_WIDTH,
          maxWidth: CARD_WIDTH,
          borderRadius: "10px",
          backgroundColor: "#F0F0F0",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          cursor: "pointer",
          transition: "200ms all ease-in-out",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={() => {
          if (openMenu) {
          } else {
            navigate(
              `${CURLANG(currLanguage)}/${COMMUNITY_KANBAN_BOARD_PAGE(
                kanbanBoard.slug
              )}`
            );
          }
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: "13px 18px",
            flexGrow: 1,
            pb: "0px !important",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {/* <Title title={module.name} /> */}
              <Box sx={{ width: 40, height: 40 }} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: 22 }}>
                  {kanbanBoard.name}
                </Typography>
              </Box>
              {isProfileInTTAModuleEditableRoleList(profileDetails) ||
              kanbanBoard.created_by === profileDetails.user ? (
                <>
                  <IconButton
                    id="basic-icon-button"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event);
                    }}
                  >
                    <MoreHorizIcon />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedKanbanBoard(kanbanBoard);
                        handleOpen();
                        handleMenuClose();
                      }}
                    >
                      <Typography>{translation.edit}</Typography>
                    </MenuItem>

                    <DeleteTopicMenuItem
                      kanbanBoard={kanbanBoard}
                      handleMenuClose={handleMenuClose}
                    />
                  </Menu>
                </>
              ) : (
                <Box sx={{ width: 40, height: 40 }} />
              )}
            </Box>
            <Typography sx={{ fontSize: 20 }}>
              {kanbanBoard.description}
            </Typography>
            {/* <Desc desc={module.description} /> */}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const DeleteTopicMenuItem = ({
  handleMenuClose,
  kanbanBoard,
}: {
  handleMenuClose: () => void;
  kanbanBoard: API_Teacher_Community_Kanban_Board;
}) => {
  const { kanbanBoardList, setKanbanBoardList } = useStore((state) => ({
    kanbanBoardList: state.kanbanBoardList,
    setKanbanBoardList: state.setKanbanBoardList,
  }));
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleDelete = async () => {
    const res = await deleteCommunityKanbanBoard(
      kanbanBoard.id,
      localStorage.getItem("access")!
    );
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setKanbanBoardList(
        kanbanBoardList.filter(
          (kanbanBoard_) => kanbanBoard_.id !== kanbanBoard.id
        )
      );
      openSnack(
        translation.kanbanBoardTopicDeleted || "Kanban Board Topic Deleted",
        true
      );
      handleMenuClose();
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete ${kanbanBoard.name}?`,
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <MenuItem
        onClick={() => {
          handleOpenDialog();
        }}
      >
        {translation.delete}
      </MenuItem>
    </>
  );
};

const AddKanbanBoardButton = ({
  handleOpen,
  setSelectedKanbanBoard,
}: {
  handleOpen: () => void;
  setSelectedKanbanBoard: React.Dispatch<
    React.SetStateAction<API_Teacher_Community_Kanban_Board>
  >;
}) => {
  const navigate = useNavigate();

  const { profileDetails, role, projectSlug, currLanguage } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      role: state.profileDetails.role,
      projectSlug: state.currentProject.slug,
      currLanguage: state.currLanguage,
    })
  );

  return (
    <Box
      sx={{
        flex: 1,
        minHeight: CARD_HEIGHT,
        minWidth: CARD_WIDTH,
        maxWidth: CARD_WIDTH,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        borderRadius: "10px",
        color: "#444",
        border: "1px dashed",
        borderColor: "#444",
        transition: "all 100ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms ease-in-out",
        },
      }}
      onClick={() => {
        setSelectedKanbanBoard(initialKanbanBoard);
        handleOpen();
      }}
    >
      <AddIcon />
    </Box>
  );
};

export default TeacherCommunityPage;

import React, { useEffect, useState, Fragment } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LearningMomentQuestionScreen from "../LearningMomentQuestionScreen";
import { translation } from "constants/translation";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import HalfCompleteCircle from "images/half-check-mark-v2.png";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { API_Activity_Instruction, API_Project_Lesson } from "types/project";
import LearningMomentModal from "./LearningMomentModal";
import useStore from "store/store";
import CustomImage from "components/Image/Image";

import { Gallery, Image } from "react-grid-gallery";
import { getInstructionLearningMomentPhoto } from "api/lesson-activity-api";
import NotePin from "images/pin.png";
import { contentMobileFontSize } from "constants/constantInGeneral";
import { contentFontSize } from "../learningMomentConstant";

type Props = {
  instruction: API_Activity_Instruction;
  currentLesson: API_Project_Lesson;
};

const ActivityStepByStepInstruction = ({
  instruction,
  currentLesson,
}: Props) => {
  const theme = useTheme();
  const [openLearningMomentModal, setOpenLearningMomentModal] =
    useState<boolean>(false);
  const [isLearningMomentPhotoScreen, setIsLearningMomentPhotoScreen] =
    useState<boolean>(true);
  // console.log("openLearningMomentModal: ", openLearningMomentModal);

  const {
    currLanguage,
    currScheduleObj,
    isRefreshLearningMomentPhoto,
    setIsRefreshLearningMomentPhoto,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currScheduleObj: state.currScheduleObj,
    isRefreshLearningMomentPhoto: state.isRefreshLearningMomentPhoto,
    setIsRefreshLearningMomentPhoto: state.setIsRefreshLearningMomentPhoto,
  }));

  const isLearningMomentDone =
    currScheduleObj.learning_moments.length > 0
      ? !!instruction.learning_moment &&
        currScheduleObj.learning_moments.includes(
          instruction.learning_moment.id!
        )
      : false;

  // Learning moment photo
  const [learningMomentPhotoList, setLearningMomentPhotoList] = useState<
    Image[]
  >([]);

  const populateLearningMomentPhoto = async () => {
    let res = await getInstructionLearningMomentPhoto(
      currLanguage,
      localStorage.getItem("access"),
      Number(instruction.id),
      currScheduleObj.group
    );

    if (typeof res !== "string") {
      res = res.map((image, index) => {
        return {
          ...image,
          customOverlay: (
            <>
              {res.length - 1 !== index && (
                <div className="number-overlay">
                  <p>+{res.length - 1 - index}</p>
                </div>
              )}
              <div className="custom-overlay">
                <p>{image.caption}</p>
              </div>
            </>
          ),
        };
      });

      setLearningMomentPhotoList(res);
      setIsRefreshLearningMomentPhoto(false);
    }
  };

  useEffect(() => {
    currScheduleObj.group !== -1 && populateLearningMomentPhoto();
  }, [currScheduleObj.group]);

  useEffect(() => {
    isRefreshLearningMomentPhoto &&
      currScheduleObj.group !== -1 &&
      populateLearningMomentPhoto();
  }, [isRefreshLearningMomentPhoto]);

  return (
    <>
      <LearningMomentModal
        open={openLearningMomentModal}
        setOpen={setOpenLearningMomentModal}
        currentLesson={currentLesson}
        learningMomentObject={instruction.learning_moment!}
        isLearningMomentPhotoScreen={isLearningMomentPhotoScreen}
        setIsLearningMomentPhotoScreen={setIsLearningMomentPhotoScreen}
      />
      <Box sx={{ display: "flex", width: "100%", gap: 0.5, height: "100%" }}>
        <Typography
          sx={{ fontSize: "20px", paddingRight: 1 }}
        >{`${instruction.weight}. `}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            flexGrow: 1,
            height: "100%",
          }}
        >
          <div
            className="ck-content"
            style={{ fontSize: "20px" }}
            dangerouslySetInnerHTML={{
              __html: instruction.name,
            }}
          />

          {instruction.recommendation && (
            <Accordion
              sx={{
                backgroundColor: theme.palette.custom.lightOrange,
                "& .MuiButtonBase-root": {
                  minHeight: "34px",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0px",
                },
              }}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ borderBottom: "1px solid white" }}
              >
                <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                  <CustomImage
                    src={NotePin}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                  <Typography variant="h6" sx={{ fontSize: 20 }}>
                    {`${translation.notes}`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{ backgroundColor: theme.palette.custom.lightOrange }}
              >
                <Box
                  sx={{
                    "& .image img": { width: "100%" },
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                    // border: "2px solid var(--primary-main)",
                    p: 0.5,
                    m: "0px !important",
                  }}
                >
                  <Box>
                    <div
                      className="ck-content"
                      style={{
                        fontSize: "16px",
                        lineHeight: 1.3,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: instruction.recommendation,
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}

          {!!instruction.learning_moment && !currentLesson.assessment && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  paddingBottom: 0.5,
                  borderRadius: "10px",
                  color: "#444",
                  border: "1px dashed",
                  borderColor: isLearningMomentDone
                    ? "primary.main"
                    : theme.palette.custom.yellow,
                  transition: "all 100ms ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    // color: "primary.main",
                    borderColor: "primary.main",
                    backgroundColor: "rgba(209, 228, 227, 0.37)",
                    transition: "all 100ms ease-in-out",
                  },
                }}
                onClick={() => {
                  setOpenLearningMomentModal(true);
                }}
              >
                {/* No image + no progress */}
                {learningMomentPhotoList.length === 0 &&
                  !isLearningMomentDone && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      <AddAPhotoIcon
                        sx={{
                          mt: 1,
                          color: theme.palette.custom.yellow,
                          width: 90,
                          height: 90,
                        }}
                      />
                      <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        {instruction.learning_moment.photo_instruction}
                      </Typography>
                    </Box>
                  )}

                {/* No image + done learning moment */}
                {learningMomentPhotoList.length === 0 &&
                  isLearningMomentDone && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      <CustomImage
                        src={HalfCompleteCircle}
                        alt="half-completed-circle"
                        style={{
                          width: 90,
                          height: 90,
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: {
                              xs: contentMobileFontSize,
                              sm: contentFontSize,
                            },
                            textAlign: "center",
                          }}
                        >
                          {`${translation.photoIncomplete}`}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                {learningMomentPhotoList.length !== 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                      width: "100%",
                      "#ReactGridGallery > div": {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Gallery
                      images={learningMomentPhotoList}
                      onClick={() => {
                        setOpenLearningMomentModal(true);
                      }}
                      enableImageSelection={false}
                      margin={4}
                      rowHeight={100}
                      thumbnailStyle={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      maxRows={1}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: {
                            xs: contentMobileFontSize,
                            sm: contentFontSize,
                          },
                          textAlign: "center",
                        }}
                      >
                        {isLearningMomentDone
                          ? translation.learningMomentRecorded
                          : translation.milestoneCheckIncomplete}
                      </Typography>
                      {isLearningMomentDone ? (
                        <TaskAltIcon
                          sx={{
                            color: "primary.main",
                            width: 35,
                            height: 35,
                          }}
                        />
                      ) : (
                        <CustomImage
                          src={HalfCompleteCircle}
                          alt="half-completed-circle"
                          style={{
                            width: 35,
                            height: 35,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}
          {currentLesson.assessment && instruction.learning_moment && (
            <Box
              sx={{
                width: "calc(100% + 32px)",
                backgroundColor: "cyan.50",
                ml: "-32px",
                p: 1,
                border: "1px solid",
                borderColor: "cyan.500",
              }}
            >
              <LearningMomentQuestionScreen
                hideDone
                hideCheckbox
                isPhotoVideoExist={false}
                learningMomentObject={instruction.learning_moment}
                setIsLearningMomentPhotoScreen={setIsLearningMomentPhotoScreen}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ActivityStepByStepInstruction;

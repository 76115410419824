import { API_Group_Lesson_Info } from "../types/profile";
import { StoreSlice } from "./store";

export interface WelcomePageSlice {
  isGroupLessonInfoListLoading: boolean;
  setIsGroupLessonInfoListLoading: (
    isGroupLessonInfoListLoading: boolean
  ) => void;
  groupLessonInfoList: API_Group_Lesson_Info[];
  setGroupLessonInfoList: (
    groupLessonInfoList: API_Group_Lesson_Info[]
  ) => void;
  refreshGroupLessonInfoList: boolean;
  setRefreshGroupLessonInfoList: (refreshGroupLessonInfoList: boolean) => void;
}

export const createWelcomePageSlice: StoreSlice<WelcomePageSlice> = (
  set,
  get
) => ({
  isGroupLessonInfoListLoading: true,
  setIsGroupLessonInfoListLoading: (isGroupLessonInfoListLoading: boolean) => {
    set((prev: WelcomePageSlice) => ({
      ...prev,
      isGroupLessonInfoListLoading: isGroupLessonInfoListLoading,
    }));
  },
  groupLessonInfoList: [],
  setGroupLessonInfoList: (groupLessonInfoList: API_Group_Lesson_Info[]) => {
    set((prev: WelcomePageSlice) => ({
      ...prev,
      groupLessonInfoList: groupLessonInfoList,
    }));
  },
  refreshGroupLessonInfoList: true,
  setRefreshGroupLessonInfoList: (refreshGroupLessonInfoList: boolean) => {
    set((prev: WelcomePageSlice) => ({
      ...prev,
      refreshGroupLessonInfoList: refreshGroupLessonInfoList,
    }));
  },
});

import React, { Fragment, useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import useStore from "store/store";
import "components/ProjectSummaryScreen/ReactGridGallery.css";
import { translation } from "constants/translation";
import LearningMomentLesson from "./LearningMomentScreenComponents/LearningMomentScreenLesson";
import { API_Project_Lesson } from "types/project";

const LearningMomentScreen = ({
  lessonIdIncompleteLearningMomentList,
}: {
  lessonIdIncompleteLearningMomentList?: number[];
}) => {
  const { lessons } = useStore((state) => ({
    lessons: state.currentLessons.lessons,
  }));

  const [filteredLessons, setFilteredLessons] = useState<API_Project_Lesson[]>(
    []
  );

  useEffect(() => {
    let filteredLessonsTemp = [];
    if (lessonIdIncompleteLearningMomentList) {
      filteredLessonsTemp = lessons.filter((lesson) =>
        lessonIdIncompleteLearningMomentList.includes(lesson.id)
      );
    } else {
      filteredLessonsTemp = lessons.filter((lesson) => {
        let isLearningMomentInLesson = false;
        lesson.activities.every((activity) => {
          activity.instructions.every((instruction) => {
            isLearningMomentInLesson = !!instruction.learning_moment;
            return !isLearningMomentInLesson;
          });
          return !isLearningMomentInLesson;
        });
        return isLearningMomentInLesson;
      });
    }
    setFilteredLessons(filteredLessonsTemp);
  }, [lessonIdIncompleteLearningMomentList]);

  return (
    <>
      <Box sx={{ p: "30px", display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "500",
            fontSize: "24px",
            textAlign: "center",
          }}
        >
          {translation.learningMoments}
        </Typography>

        {filteredLessons.length !== 0 &&
          filteredLessons.map((lesson, index) => (
            <Fragment key={`${lesson.id}-index`}>
              <LearningMomentLesson lesson={lesson} lessonIndex={index} />
            </Fragment>
          ))}
      </Box>
    </>
  );
};

export default LearningMomentScreen;

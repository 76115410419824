import React from "react";
import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageThree from "../../../../../../../images/children-photo-book/page-3.png";
import { CaptionPDFComponent } from "./constant";
import {
  PDFScale,
  photoBookPageThreeCaptionInfo,
  photoBookPageThreeImageInfo,
} from "../photoBookCommonConstant";
import { Image } from "@react-pdf/renderer";
import {
  imageSrc,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";

export const PhotoBookPageThreeComponent = ({
  images,
}: {
  images: ImageType[];
}) => {
  const scale = PDFScale;
  const imageInfo = photoBookPageThreeImageInfo(scale);
  const captionInfo = photoBookPageThreeCaptionInfo(scale);
  return (
    <>
      <Image src={imageSrc(PhotoBookPageThree)} style={styles.background} />
      <Image
        src={imageSrc(images[0].link)}
        style={{
          position: "absolute",
          top: imageInfo.top,
          left: imageInfo.left,
          width: imageInfo.width,
          height: imageInfo.height,
          objectFit: "cover",
        }}
      />
      <CaptionPDFComponent
        top={captionInfo.top}
        left={captionInfo.left}
        width={captionInfo.width}
        caption={images[0].caption}
        clampLine={captionInfo.clampLine}
        fontSize={captionInfo.fontSize}
        textAlign="center"
      />
    </>
  );
};

export default PhotoBookPageThreeComponent;

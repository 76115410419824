import { Box, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  addActivityFileWithForm,
  deleteActivityFile,
  editActivity,
  getActivityByID,
  deleteLessonActivity,
  addOrUpdateActivityInstructions,
} from "api/project-api";
import {
  createCaption,
  editCaption,
  deleteCaption,
  getGalleryByActivityID,
} from "api/gallery-api";
import { API_Caption } from "types/gallery";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import {
  API_Activity_Instruction,
  API_Create_Activity,
  API_Edit_Activity,
} from "types/project";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { translation } from "constants/translation";

import useCallbackPrompt from "hooks/useCallbackPrompt";
import BlockLeavingDialog from "components/Dialog/BlockLeavingDialog";
import KeepChangesDialog from "components/Dialog/KeepChangesDialog";

import {
  NameField,
  DurationField,
  CompulsoryField,
  FileField,
  MaterialField,
  ImageField,
  InstructionsFieldWithDnd,
} from "./ActivityFormComponents";
import DrawerHeader from "containers/EditProjectPage/DrawerHeader";
import MaterialButton from "components/Button/MaterialButton";

const EditActivityForm = ({
  activityID,
  lessonID,
  handleClose,
}: {
  activityID: string;
  lessonID: string;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();

  const [files, setFiles] = useState<File[]>([]);
  const [image, setImage] = useState<File | string>("");
  const [delFiles, setDelFiles] = useState<boolean[]>([]);
  const [galleryID, setGalleryID] = useState<string>("");
  const [captions, setCaptions] = useState<API_Caption[]>([]);
  const [currCaptions, setCurrCaptions] = useState<API_Caption[]>([]);
  const [delCaption, setDelCaption] = useState<boolean[]>([]);
  const [filledRequired, setFilledRequired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    isRefreshActivityInstruction,
    setIsRefreshActivityInstruction,
    currLanguage,
    currActivity,
    setCurrActivity,
    setRefreshProjAndLess,
    setSnackMessage,
    setSnackSuccess,
    setSnackOpen,
    bringOverActivity,
  } = useStore((state) => state);

  const [activity, setActivity] = useState<API_Create_Activity>({
    lesson: lessonID,
    files: [],
    name: "",
    description: "",
    duration: 0,
    instruction: "",
    instructions: [],
    remark: "",
    is_compulsory: true,
  });
  const [activityInstructions, setActivityInstructions] = useState<
    API_Activity_Instruction[]
  >([]);

  const activityRef = useRef(activity);
  activityRef.current = activity;

  const initializeActivity = async () => {
    if (showChanges) return;

    const act = await getActivityByID(currLanguage, activityID);

    if (typeof act === "string") {
      openSnack(act, false);
    } else {
      setCurrActivity(act);

      const activityInstructionsRes: API_Activity_Instruction[] =
        act.instructions.map((instruction) => {
          const { learning_moment } = instruction;

          const updatedInstruction = { ...instruction };
          if (learning_moment) {
            updatedInstruction.learning_moment = {
              ...learning_moment,
              questions: (learning_moment.questions ?? []).map((question) => ({
                ...question,
                mode: "update",
                fundamental_set: (question.fundamental_set ?? []).map(
                  (fundamentalSetItem) => ({
                    ...fundamentalSetItem,
                    mode: "update",
                    fundamental: fundamentalSetItem.fundamental ?? -1,
                    milestone: fundamentalSetItem.fundamental
                      ? fundamentalSetItem.milestone
                      : -1,
                  })
                ),
              })),
            };

            if (!learning_moment.fundamental) {
              updatedInstruction.learning_moment.fundamental = -1;
              updatedInstruction.learning_moment.milestone = -1;
            }
          }

          return updatedInstruction;
        });

      setActivityInstructions(activityInstructionsRes);
      setImage(act.image ?? "");
      setFiles([]);
      setDelFiles([]);
      setFilledRequired(false);
    }
  };

  const initialiseCaptions = async () => {
    const gallery = await getGalleryByActivityID(currLanguage, activityID);

    if (typeof gallery === "string") {
      setSnackMessage("Failed to load captions!");
      setSnackSuccess(false);
      setSnackOpen(true);
      setGalleryID("");
      setCaptions([]);
      setCurrCaptions([]);
      setDelCaption([]);
    } else {
      setGalleryID(gallery.id.toString());
      setCaptions(gallery.captions);
      setCurrCaptions(JSON.parse(JSON.stringify(gallery.captions)));

      const newDelCaption: boolean[] = Array(gallery.captions.length).fill(
        false
      );
      setDelCaption(newDelCaption);
    }
  };

  useEffect(() => {
    const initialise = async () => {
      setIsLoading(true);

      await initializeActivity();
      await initialiseCaptions();

      setIsLoading(false);
    };
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityID]);

  useEffect(() => {
    if (isRefreshActivityInstruction) {
      initializeActivity();
      setIsRefreshActivityInstruction(false);
    }
  }, [isRefreshActivityInstruction]);

  useEffect(() => {
    setActivity({
      lesson: lessonID,
      files: [],
      name: "",
      description: "",
      duration: 0,
      instruction: "",
      instructions: [],
      remark: "",
      is_compulsory: true,
    });
    setActivity({
      lesson: currActivity.lesson.toString(),
      files: [],
      name: bringOverActivity.name ? bringOverActivity.name : currActivity.name,
      description: bringOverActivity.description
        ? bringOverActivity.description
        : currActivity.description,
      duration: bringOverActivity.duration
        ? bringOverActivity.duration
        : currActivity.duration
        ? currActivity.duration
        : 0,
      instruction: bringOverActivity.instruction
        ? bringOverActivity.instruction
        : currActivity.instruction,
      instructions: bringOverActivity.instructions
        ? bringOverActivity.instructions
        : currActivity.instructions,
      material: bringOverActivity.material
        ? bringOverActivity.material
        : currActivity.material,
      remark: bringOverActivity.remark
        ? bringOverActivity.remark
        : currActivity.remark,
      is_compulsory:
        bringOverActivity.is_compulsory !== undefined
          ? bringOverActivity.is_compulsory
          : currActivity.is_compulsory,
      assessment: currActivity.assessment,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currActivity]);

  useEffect(() => {
    // const isCaptionsFilled =
    //   captions.length !== 0
    //     ? !captions.some((cap) => cap.caption === "")
    //     : true;

    console.log("xx activity name", activity.name);
    if (activity.name !== "") {
      setFilledRequired(true);
    } else {
      setFilledRequired(false);
    }
  }, [activity]);

  const submitEditForm = async () => {
    setFilledRequired(false);
    let editFailed = false;
    const activityData: API_Edit_Activity = {};

    if (activity.name !== currActivity.name) activityData.name = activity.name;
    if (activity.lesson !== currActivity.lesson.toString())
      activityData.lesson = activity.lesson;
    if (activity.description !== currActivity.description)
      activityData.description = activity.description;
    if (activity.duration !== currActivity.duration)
      activityData.duration = activity.duration;
    if (activity.instruction !== currActivity.instruction)
      activityData.instruction = activity.instruction;
    if (activity.material !== currActivity.material)
      activityData.material = activity.material;
    if (typeof image === "object" || image === "") {
      activityData.image = image;
    }
    if (activity.remark !== currActivity.remark)
      activityData.remark = activity.remark;
    if (activity.is_compulsory !== currActivity.is_compulsory)
      activityData.is_compulsory = activity.is_compulsory;

    //update activity instructions
    if (activity.instructions !== activityInstructions) {
      await addOrUpdateActivityInstructions(
        currLanguage,
        activityInstructions,
        localStorage.getItem("access")
      );
    }

    //update files
    if (files.length > 0) {
      await addActivityFileWithForm(
        currLanguage,
        currActivity.id.toString(),
        files,
        localStorage.getItem("access")
      );
    }

    for (let i = 0; i < delFiles.length; i++) {
      if (delFiles[i]) {
        const res = await deleteActivityFile(currActivity.files[i].id);
        if (res !== "success") openSnack(res, false);
      }
    }

    // update existing captions
    for (let i = 0; i < currCaptions.length; i++) {
      if (captions[i].caption !== currCaptions[i].caption) {
        await editCaption(
          currLanguage,
          captions[i].caption,
          currCaptions[i].id.toString()
        );
      }
    }

    // add new captions
    for (let i = currCaptions.length; i < captions.length; i++) {
      if (captions[i].caption) {
        await createCaption(currLanguage, captions[i].caption, galleryID);
      }
    }

    // delete captions
    for (let i = 0; i < delCaption.length; i++) {
      if (delCaption[i]) {
        await deleteCaption(currLanguage, currCaptions[i].id.toString());
      }
    }

    if (Object.keys(activityData).length > 0) {
      const res = await editActivity(
        currLanguage,
        currActivity.id.toString(),
        activityData,
        localStorage.getItem("access")
      );
      if (typeof res === "string") {
        openSnack(`${translation.fail_update_lesson}: ${res}`, false);
        editFailed = true;
      }
    }

    if (!editFailed) {
      setRefreshProjAndLess(true);
      openSnack("Updated activity successfully!", true);
      handleClose();
    }
  };

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [keptData, setKeptData] = useState<API_Edit_Activity>({});
  const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const dialogBody = {
    showDialog: showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleAction: submitEditForm,
  };

  const changesDialogBody = {
    showChangesDialog: showChanges,
    confirmNavigation,
    cancelNavigation,
    project: {},
    lesson: {},
    activity: keptData,
  };

  if (activity.name === undefined || isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {dialogBody.showDialog && <BlockLeavingDialog {...dialogBody} />}
      {changesDialogBody.showChangesDialog && (
        <KeepChangesDialog {...changesDialogBody} />
      )}
      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <DrawerHeader
          type="Activity"
          mode="Edit"
          assessment={!!activity.assessment}
          handleClose={handleClose}
        >
          <Box
            sx={{
              display: "flex",
              gap: "24px",
            }}
          >
            <DeleteActivityButton
              activityID={activityID}
              handleClose={handleClose}
            />
            <MaterialButton
              buttonText={translation.update || "Update"}
              onClick={() => {
                if (filledRequired) {
                  setShowDialog(false);
                  submitEditForm();
                }
              }}
              disabled={!filledRequired}
            />
          </Box>
        </DrawerHeader>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <NameField
            activity={activity}
            setActivity={setActivity}
            onChange={(input) => {
              setShowDialog(true);
              setKeptData((prev) => ({ ...prev, name: input }));
            }}
          />
          <CompulsoryField
            activity={activity}
            setActivity={setActivity}
            onChange={(input) => {
              setShowDialog(true);
              setKeptData((prev) => ({ ...prev, is_compulsory: input }));
            }}
          />
        </Box>
        <DurationField
          activity={activity}
          setActivity={setActivity}
          onChange={(input) => {
            setShowDialog(true);
            setKeptData((prev) => ({ ...prev, duration: input }));
          }}
        />

        <MaterialField
          activity={activity}
          setActivity={setActivity}
          onChange={(input) => {
            setShowDialog(true);
            setKeptData((prev) => ({ ...prev, remark: input }));
          }}
        />
        <InstructionsFieldWithDnd
          assessment={!!activity.assessment}
          activityInstructions={activityInstructions}
          setActivityInstructions={setActivityInstructions}
        />
        <FileField
          files={files}
          setFiles={setFiles}
          oldFiles={currActivity}
          delFiles={delFiles}
          setDelFiles={setDelFiles}
          language={currLanguage}
          multiple
        />
        <ImageField image={image} setImage={setImage} />
      </Stack>
    </>
  );
};

const DeleteActivityButton = ({
  activityID,
  handleClose,
}: {
  activityID: string;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const { refreshProjAndLess, setRefreshProjAndLess } = useStore((state) => state);

  const handleDelete = async () => {
    const res = await deleteLessonActivity(+activityID);
    if (res !== "success") {
      openSnack(res, false);
    } else {
      openSnack(translation.success_remove_activity, true);
      setRefreshProjAndLess(true);
      handleClose();
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Activity?",
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <MaterialButton
        buttonText={translation.delete || "Delete"}
        variant="text"
        onClick={handleOpenDialog}
      />
    </>
  );
};

export default EditActivityForm;

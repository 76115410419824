import { Box, SxProps, Typography } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";

import { translation } from "constants/translation";

import { TreeViewData, Selected } from "./types";


const wrapperStyles: SxProps = {
  borderRadius: "8px",
  cursor: "pointer",
  "&:not(.active):hover": {
    backgroundColor: "rgba(0,0,0,0.04)",
  },
  "&.active": {
    backgroundColor: "rgba(209, 228, 227, 0.37)",
  },
};

const titleStyles: SxProps = {
  fontSize: "1.125rem",
  lineHeight: 1.25,
  p: "6px 16px",
};

const Activity = ({
  activities,
  activityId,
  activityIndex,
  selected,
  isSortingContainer,
  handleOpen,
}: {
  activities: TreeViewData[];
  activityId: string;
  activityIndex: number;
  selected: Selected | null;
  isSortingContainer: boolean;
  handleOpen: (selected: Selected) => void;
}) => {
  const { listeners, isDragging } = useSortable({
    id: activityId,
  });

    const activity = activities.find((activity) => activity.id === activityId);
    
    if (!activity) { 
        return null;
    }

  return (
    <Box
      className={
        selected &&
        selected.id === activity.id &&
        selected.type === activity.type
          ? "active"
          : ""
      }
      sx={{
        ...wrapperStyles,
        ml: 1.5,
        cursor: isDragging || isSortingContainer ? "grabbing" : "pointer",
      }}
      onClick={() =>
        handleOpen({
          id: activity.id,
          index: activityIndex,
          parentIndex: activity.parentIndex,
          parentId: activity.parentId,
          type: activity.type,
          assessment: false,
        })
      }
    >
      <Typography sx={titleStyles} {...listeners}>
        {translation.formatString(translation.activityNo, {
          number: activityIndex + 1,
        })}
        : {activity.name}
        {activity.isCompulsory && (
          <Typography
            component="span"
            sx={{ color: "red", ml: "2px", lineHeight: 1.25 }}
          >
            *
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export default Activity;

import { Box } from "@mui/material";

export const Backdrop = ({ isHovering }: { isHovering: boolean }) => (
  <Box
    className="drawer-overlay"
    sx={{
      position: "fixed",
      backgroundColor: "black",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1150,
      pointerEvents: "none",
      opacity: isHovering ? 0.2 : 0,
      transition: "all 200ms ease-in-out",
    }}
  />
);

export default Backdrop;
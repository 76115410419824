import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchBar } from "containers/teacher/TeacherAnalytic/SecondRow";
import { Query_Data } from "types/project";
import {
  DATE_FORMAT,
  ImageCard,
  ImagePreviewModal,
  ImageType,
} from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import { getAllChildImages } from "api/gallery-api";
import moment from "moment";
import {
  ClickSearchToBegin,
  MAX_QUERY,
  MaxQueryAdvice,
} from "../TeacherArchiveCommonComponent/TeacherArchiveCommonComponent";
import useStore from "store/store";

import CustomButton from "components/Button/Button";
import ModuleFilterButton from "containers/ChildrenPortfolioPage/Screens/CompletedWorkComponents/ModuleFilterButton";
import ClassFilterButton from "../TeacherArchiveCommonComponent/ClassFilterButton";
import ChildFilterButton from "../TeacherArchiveCommonComponent/ChildFilterButton";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

const TeacherArchivePhotoAlbumScreen = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  // Search
  const [isLoading, setIsLoading] = useState(false);
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });
  const [selectedModuleSlugs, setSelectedModuleSlugs] = useState<string[]>([]);
  const [selectedClassSlugs, setSelectedClassSlugs] = useState<string[]>([]);
  const [selectedChildIds, setSelectedChildIds] = useState<number[]>([]);

  const [images, setImages] = useState<ImageType[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImageType>();

  const handleOpen = (image: ImageType) => {
    setOpen(true);
    setSelectedImage(image);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(undefined);
  };

  const populatePhotoAlbumImageList = async () => {
    setIsLoading(true);
    const res = await getAllChildImages({
      language: currLanguage,
      selectedModuleSlugs: selectedModuleSlugs,
      selectedClassSlugs: selectedClassSlugs,
      selectedChildIds: selectedChildIds,
      queryData: queryData,
      isTeacherArchive: true,
    });
    if (typeof res !== "string") {
      setImages(res);
    }
    setIsLoading(false);
  };

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      isInitialized ||
      selectedModuleSlugs.length === 0 ||
      selectedClassSlugs.length === 0 ||
      selectedChildIds.length === 0
    )
      return;

    populatePhotoAlbumImageList();
    setIsInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModuleSlugs, selectedClassSlugs, selectedChildIds]);

  return (
    <>
      {open && selectedImage && (
        <ImagePreviewModal
          open={open}
          image={selectedImage}
          handleClose={handleClose}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, sm: 2 },
            width: {
              xs: "100%",
              xl: "80%",
            },
          }}
        >
          <SearchBar
            queryData={queryData}
            setQueryData={setQueryData}
            maxWidth={9999}
          />
          <CustomButton
            buttonText={isXs ? "" : "Search"}
            onClick={() => {
              populatePhotoAlbumImageList();
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            flexWrap: "wrap",
            width: {
              xs: "100%",
              xl: "80%",
            },
          }}
        >
          <StartAtDateField
            queryData={queryData}
            setQueryData={setQueryData}
            // maxWidth={150}
          />
          <EndAtDateField
            queryData={queryData}
            setQueryData={setQueryData}
            // maxWidth={150}
          />
          <Box
            sx={{
              flex: { xs: "1 0 100%", sm: "1" },
            }}
          >
            <ModuleFilterButton
              selectedModuleSlugs={selectedModuleSlugs}
              setSelectedModuleSlugs={setSelectedModuleSlugs}
            />
          </Box>
          <ClassFilterButton
            selectedClassSlugs={selectedClassSlugs}
            setSelectedClassSlugs={setSelectedClassSlugs}
          />
          <ChildFilterButton
            selectedClassSlugs={selectedClassSlugs}
            selectedChildIds={selectedChildIds}
            setSelectedChildIds={setSelectedChildIds}
          />
        </Box>
      </Box>
      {isLoading ? (
        <LoadingIndicator />
      ) : images.length === 0 ? (
        <ClickSearchToBegin />
      ) : (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: 2,
            }}
          >
            {images.map((image) => {
              return (
                <Box key={`image-${image.id}`}>
                  <ImageCard
                    src={image.link}
                    caption={image.caption}
                    module={image.module}
                    onClick={() => handleOpen(image)}
                    fromNow={moment(image.created_at).format(DATE_FORMAT)}
                  />
                </Box>
              );
            })}
          </Box>
          {images.length === MAX_QUERY &&
            MaxQueryAdvice({ maxQuery: MAX_QUERY })}
        </>
      )}
    </>
  );
};

// const ImageCard = ({
//   src,
//   label,
//   caption,
//   module,
//   fromNow,
//   onClick,
// }: {
//   src: string;
//   label?: string;
//   caption?: string;
//   module?: API_Project_Module;
//   fromNow?: string;
//   onClick: () => void;
// }) => (
//   <Box sx={cardStyles} onClick={onClick}>
//     {label && (
//       <Typography
//         sx={{
//           ...labelStyles,
//           top: 0,
//           left: 0,
//           fontWeight: 500,
//           py: 0.75,
//           px: 1.25,
//           borderBottomRightRadius: "8px",
//         }}
//       >
//         {label}
//       </Typography>
//     )}

//     {module && (
//       <ModuleTag
//         projModule={module}
//         styles={{
//           position: "absolute",
//           top: "8px",
//           left: "8px",
//           mb: 0,
//           backgroundColor: "rgba(209,228,227,0.75)",
//         }}
//       />
//     )}

//     <Image
//       src={src}
//       alt={caption ? caption : ""}
//       objectFit="cover"
//       style={{
//         display: "flex",
//         width: "100%",
//         height: "100%",
//       }}
//     />

//     {(caption || fromNow) && (
//       <Box
//         sx={{
//           ...labelStyles,
//           bottom: 0,
//           width: "100%",
//           p: 1,
//           display: "flex",
//           flexDirection: "column",
//           gap: 0.75,
//         }}
//       >
//         {caption && (
//           <Typography sx={{ fontSize: 14, lineHeight: 1 }} noWrap>
//             {caption}
//           </Typography>
//         )}

//         {fromNow && (
//           <Typography
//             sx={{
//               fontSize: 10,
//               lineHeight: 1,
//               fontWeight: 700,
//               color: "#312F30",
//             }}
//             noWrap
//           >
//             {fromNow}
//           </Typography>
//         )}
//       </Box>
//     )}
//   </Box>
// );

export default TeacherArchivePhotoAlbumScreen;

import moment from "moment";
import { DATETIME_FORMAT } from "containers/CalendarPage/constants/constants";

const USD = "$";
const CNY = "\u00A5";
const MYR = "RM";

export const convertUnixTimestampToStrDate = (unixTimestamp: number) => {
  if (unixTimestamp === null) {
    return "None";
  }
  return moment(new Date(unixTimestamp * 1000)).format(DATETIME_FORMAT);
};

export const convertDateToUnixTimestamp = (
  date: Date,
  trial_days = 7
) => {
  return Math.ceil(
    date.setDate(new Date().getDate() + trial_days).valueOf() / 1000
  );
};

export const convertCentsToString = (amount: number) => {
  if (String(amount) === "None") {
    return "None";
  }
  return `${String(amount).slice(0, -2)}.${String(amount).slice(-2)}`;
};

export const convertCurrencyStrToSymbol = (currency: string) => {
  switch (currency) {
    case "myr":
      return MYR;
    case "usd":
      return USD;
    case "cny":
      return CNY;
    default:
      return "";
  }
};

export const convertToUpperCase = (text: string) => {
  if (text === undefined) {
    return "None";
  }

  text = String(text);
  return text.slice(0, 1).toUpperCase() + text.slice(1);
};

export const isValidUrl = (text: string) => {
  try {
    new URL(text);
  } catch (_) {
    return false;
  }
  return true;
};

export const initialCustomerInfo = {
  product: {
    id: "-1",
    name: "-1",
    images: [],
    active: false,
    description: "-1",
    extra_description: "-1",
    product_id: "-1",
    metadata: {},
    default_price: {
      id: "-1",
      active: false,
      unit_amount: -1,
      currency: "-1",
      recurring: {
        interval: "-1",
      },
    },
  },
  customer: {
    id: "-1",
    email: "-1",
  },

  subscription: {
    collection_method: "-1",
    cancel_at_period_end: false,
    cancel_at: -1,
    current_period_start: -1,
    current_period_end: -1,
    days_until_due: null,
    trial_start: null,
    status: "",
    quantity: -1,
  },
  upcoming_invoice: {
    collection_method: "-1",
    currency: "-1",
    total: -1,
    next_payment_attempt: -1,
  },
  payment_history: [],
};

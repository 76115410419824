
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { API_Teacher_Module_Visualization_Demographic_Info } from "types/teacher";
import { responsiveContainerStyle } from "./VisualizationPage";


interface YearsOfExperienceInfo {
  category: string;
  count: number;
}

const experienceBucketLabels: Record<string, string> = {
  y00: "<0 year",
  y01: "1 year",
  y02: "2 years",
  y03: "3 years",
  y04: "4 years",
  y05: "5 years",
  y06: "6 years",
  y07: "7 years",
  y08: ">7 years",
};

const YearsOfExperienceChart = ({
  data,
}: {
  data: API_Teacher_Module_Visualization_Demographic_Info[];
}) => {
  // const theme = useTheme();
  let processedData: YearsOfExperienceInfo[] = data.reduce(
    (
      acc: YearsOfExperienceInfo[],
      item: API_Teacher_Module_Visualization_Demographic_Info
    ) => {
      const experienceBucketName =
        experienceBucketLabels[
          `y${
            item.years_of_experience >= 8
              ? "08"
              : item.years_of_experience.toString().padStart(2, "0")
          }`
        ];

      let experienceInfo: YearsOfExperienceInfo | undefined = acc.find(
        (d: YearsOfExperienceInfo) => d.category === experienceBucketName
      );
      if (!experienceInfo) {
        experienceInfo = {
          category: experienceBucketName,
          count: 0,
        };
        acc.push(experienceInfo);
      }
      experienceInfo.count += 1;
      return acc;
    },
    []
  );

  processedData = processedData.sort((a, b) => (b.count < a.count ? -1 : 1));

  return (
    <ResponsiveContainer {...responsiveContainerStyle}>
      <BarChart
        layout="horizontal"
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="category" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="count" fill={"#82ca9d"} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default YearsOfExperienceChart;

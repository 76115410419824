import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  getAllChildProfile,
  getAllParentProfile,
} from "api/profile-api";
import { API_Profile_Short } from "types/profile";
import Logo from "images/logo-2-colour.png";
import { Avatar, IconButton } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import {
  useGridApiContext,
  GridColumns,
  GridColDef,
  DataGrid,
  GridRowId,
  GridEventListener,
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridRowModel,
  GridCellParams,
  GridColumnVisibilityModel,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";

//School
import { getAllSchool } from "api/school-api";
import { API_School } from "types/school";

//Profile
import {
  deleteProfile,
  deleteProfileBulk,
  updateProfile,
} from "api/auth-api";
import {
  ErrorMessage,
  UpdateProfile,
  UpdateProfileResponse,
} from "types/auth";

//warning
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import DataGridWarningDialog from "components/Dialog/DataGridWarningDialog";
import useDialog from "hooks/useDialog";
import { DATA_GRID_STYLE } from "./constants/styling";

import { DOB_COLUMN, processRowUpdateBody } from "./constants/gridColumn";

import {
  ACTIONS_COLUMN,
  EditToolbarProfile,
  EMAIL_COLUMN,
  FULL_NAME_COLUMN,
  GROUP_COLUMN,
  ID_COLUMN,
  IMAGE_COLUMN,
  PREFERRED_NAME_COLUMN,
  SCHOOL_COLUMN,
} from "./constants/gridColumnHelper";

import EditGroupModal from "./components/EditGroupModal";

const ChildManagePage = () => {
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [bulkDeleteLoading, setBulkDeleteLoading] = useState<boolean>(false);
  const [bulkUpdateLoading, setBulkUpdateLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [profileId, setProfileId] = useState<number>(-1);
  const [tempImage, setTempImage] = useState<string>("");
  const [bulkActionNames, setbulkActionNames] = useState<any>([]);
  const [filterGroups, setFilterGroups] = useState<any>("");
  const [schoolList, setSchoolList] = useState<API_School[]>([]);
  const [rows, setRows] = useState<API_Profile_Short[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const [bulkUpdateClasses, setBulkUpdateClasses] = useState<any>();

  const [warningBody, setWarningBody] = useState({
    id: -1,
    warningTitle: "Delete Child?",
    confirmDelete: false,
  });

  const { adminPageParentList, setAdminPageParentList } = useStore((state) => ({
    adminPageParentList: state.adminPageParentList,
    setAdminPageParentList: state.setAdminPageParentList,
  }));

  const warningBodyStatic = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Child?",
    warningContext: "This action is irreversable!",
    setWarningBody,
  };

  const handleSelectionModelChange = (newSelectionModel: any) => {
    if (!bulkUpdateLoading && !bulkDeleteLoading) {
      setSelectionModel(newSelectionModel);
    }
  };

  const handleDelete = (id: GridRowId) => {
    //call delete api
    const callDeleteProfile = async () => {
      const res = await deleteProfile(String(id));
      if (typeof res === "string" && res.length >= 1) {
        openSnack(res, false);
      } else {
        openSnack(`Delete Child successfully!`, true);
        setRows(rows.filter((row) => row.id !== id));
      }
    };

    callDeleteProfile();
  };
  const handleBulkDelete = (selectionModel: any) => {
    setBulkDeleteLoading(true);
    const callBulkDeleteProfile = async () => {
      const res = await deleteProfileBulk(selectionModel);
      if (res.status === "success") {
        setRows(rows.filter((row) => !selectionModel.includes(row.id)));
        openSnack(`Deleted Children successfully!`, true);
      } else if (res.status === "partial") {
        const failedNames = res.failed_ids
          .map((id: any) => rows.find((row) => row.id === id)?.preferred_name)
          .join(", ");
        openSnack(`Failed to Delete: ${failedNames}`, false);
      } else {
        openSnack(`All deletions failed!`, false);
      }
      setBulkDeleteLoading(false);
      setSelectionModel([]);
    };
    callBulkDeleteProfile();
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setSelectionModel([]);
    setbulkActionNames([]);
    const preferred_name = rows.filter((row) => row.id === id)[0]?.full_name;
    handleOpenDialog();
    setWarningBody({
      id: Number(id),
      warningTitle: `Delete child "${preferred_name}"?`,
      confirmDelete: false,
    });
  };

  const handleBulkDeleteClick = (selectionModel: any[]) => {
    const namesArray: any[] = [];
    selectionModel.forEach((id) => {
      namesArray.push(rows.filter((row) => row.id === id)[0]?.preferred_name);
    });
    setbulkActionNames(namesArray);
    handleOpenDialog();
    setWarningBody({
      id: 100000,
      warningTitle: "Delete Children below?",
      confirmDelete: false,
    });
  };

  const handleBulkClassClick = (selectionModel: any[]) => {
    const schoolArray: any[] = [];
    const groups: any[] = [];
    selectionModel.forEach((id) => {
      const row = rows.find((row) => row.id === id);
      schoolArray.push(row?.school);
      row?.groups?.forEach((group) => {
        const groupString = JSON.stringify(group);
        if (!groups.find((g) => JSON.stringify(g) === groupString)) {
          groups.push(group.id);
        }
      });
    });
    const namesArray: any[] = [];
    selectionModel.forEach((id) => {
      namesArray.push(rows.filter((row) => row.id === id)[0]?.preferred_name);
    });
    setbulkActionNames(namesArray);
    setBulkUpdateClasses(groups);
    const schoolSet = new Set(schoolArray);
    if (schoolSet.size > 1) {
      openSnack("Please select children from the same school!", false);
    } else {
      handleOpenModal(selectionModel[0]);
    }
  };
  const ImageEditCell = (props: GridRenderCellParams<any>) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const handleChange = (event: any) => {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.files[0],
      });
      setTempImage(URL.createObjectURL(event.target.files![0]));
    };
    return (
      <Box
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 50,
          height: 50,
        }}
      >
        <Avatar
          src={tempImage || value}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: 50,
            height: 50,
            backgroundColor: "primary.main",
            color: "txt.light",
            border: "1px solid",
            borderColor: "primary.main",
            fontSize: "2vw",
          }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        />
        <input
          accept="image/*"
          type="file"
          id={`grid-edit`}
          hidden
          onChange={handleChange}
        />
        <label htmlFor={`grid-edit`}>
          <IconButton
            component="span"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <PhotoCameraIcon
              sx={{
                width: 15,
                height: 15,
                color: "primary.light",
                cursor: "pointer",
                opacity: 0.4,
                transition: "all 50ms linear",
                "&:hover": {
                  opacity: 0.8,
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box>
    );
  };

  const renderImageEditCell: GridColDef["renderCell"] = (params) => {
    return <ImageEditCell {...params} />;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const body = processRowUpdateBody(newRow);
    const updateResponse: UpdateProfileResponse | ErrorMessage =
      await updateProfile(
        String(newRow.id),
        body as UpdateProfile,
        localStorage.getItem("access")
      );
    if ("error" in updateResponse) {
      openSnack(updateResponse.error.join(" "), false);
      return updateResponse.error.join(" ");
    } else {
      openSnack("Child is updated!", true);
    }
    newRow.image = updateResponse.image;
    return newRow;
  };
  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setTempImage("");
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleCloseModal = () => {
    setProfileId(-1);
    setOpenModal(false);
    setSelectionModel([]);
  };
  const handleOpenModal = (profileId: number) => {
    setProfileId(profileId);
    setOpenModal(true);
  };

  const columns: GridColumns = [
    {
      ...ID_COLUMN,
    },

    {
      ...IMAGE_COLUMN,
      renderEditCell: renderImageEditCell,
    },
    {
      ...EMAIL_COLUMN,
    },
    {
      ...PREFERRED_NAME_COLUMN,
    },
    {
      ...FULL_NAME_COLUMN,
    },
    {
      ...DOB_COLUMN,
    },
    {
      field: "parent",
      headerName: "Parent Name",
      type: "singleSelect",
      valueOptions: (params) => {
        const adminPageParentListFiltered = adminPageParentList.filter(
          (parent) => parent.school === params.row.school
        );

        return [
          ...adminPageParentListFiltered.map((parent) => ({
            value: parent.id,
            label: parent.full_name,
          })),
          { value: -1, label: "No Parent" },
        ];
      },
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const parentOption = adminPageParentList.map((parent) => ({
          value: parent.id,
          label: parent.full_name,
        }));
        return parentOption?.find((opt) => opt?.value === value)?.label ?? "";
      },
      width: 180,
      editable: true,
    },
    {
      ...GROUP_COLUMN(handleOpenModal),
    },
    {
      ...SCHOOL_COLUMN(schoolList),
    },
    {
      ...ACTIONS_COLUMN({
        rowModesModel,
        handleSaveClick,
        handleCancelClick,
        handleEditClick,
        handleDeleteClick,
      }),
    },
  ];

  const [columnVisibility, setColumnVisibility] = useState<any>(() => {
    const finalObj = {};
    columns.forEach((column: any) => {
      Object.assign(finalObj, { [column.field]: true });
    });
    return finalObj;
  });

  useEffect(() => {
    const populateData = async () => {
      const childListRes = await getAllChildProfile();
      setRows(childListRes as API_Profile_Short[]);
      const schoolRes = await getAllSchool();
      setSchoolList(schoolRes);
      const parentRes = await getAllParentProfile();
      setAdminPageParentList(parentRes);
    };
    populateData();
  }, [bulkUpdateLoading]);

  useEffect(() => {
    const populateChildren = async () => {
      const children = await getAllChildProfile();
      setRows(children);
    };
    populateChildren();
  }, []);

  useEffect(() => {
    if (
      warningBody.confirmDelete === true &&
      warningBody.id !== -1 &&
      warningBody.id !== 100000
    ) {
      handleDelete(warningBody.id);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    } else if (warningBody.confirmDelete == true && warningBody.id === 100000) {
      handleBulkDelete(selectionModel);
      setWarningBody({
        id: -1,
        warningTitle: "",
        confirmDelete: false,
      });
    }
  }, [warningBody]);
  return (
    <>
      <EditGroupModal
        open={openModal}
        handleClose={handleCloseModal}
        profileId={profileId}
        setRows={setRows}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        bulkUpdateClasses={bulkUpdateClasses}
        names={bulkActionNames}
        setBulkUpdateLoading={setBulkUpdateLoading}
      />
      <DataGridWarningDialog
        {...warningBodyStatic}
        {...warningBody}
        names={bulkActionNames}
      />
      <Box>
        <div style={DATA_GRID_STYLE}>
          <DataGrid
            sx={{
              fontSize: 18,
              "& .MuiDataGrid-cell--withRenderer": {
                padding: 0,
              },
              "& .MuiDataGrid-row .MuiDataGrid-cellCheckbox": {
                opacity: bulkUpdateLoading || bulkDeleteLoading ? 0.5 : 1,
                pointerEvents:
                  bulkUpdateLoading || bulkDeleteLoading ? "none" : "auto",
              },
            }}
            checkboxSelection
            rows={rows.filter(
              (row) =>
                filterGroups === "" ||
                row?.groups?.some((group: any) =>
                  group.name.toLowerCase().includes(filterGroups.toLowerCase())
                )
            )}
            columns={columns}
            columnVisibilityModel={columnVisibility}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            onCellClick={(
              params: GridCellParams,
              event: MuiEvent<React.MouseEvent>
            ) => {
              event.defaultMuiPrevented = true;
              if (!bulkDeleteLoading && !bulkUpdateLoading) {
                setSelectionModel([]);
                setBulkUpdateClasses([]);
                setbulkActionNames([]);
              }
            }}
            onColumnVisibilityModelChange={(
              model: GridColumnVisibilityModel,
              details: GridCallbackDetails
            ) => {
              setColumnVisibility(model);
            }}
            components={{
              Toolbar: EditToolbarProfile,
            }}
            componentsProps={{
              toolbar: {
                filterGroups,
                setFilterGroups,
                rows,
                setRows,
                setRowModesModel,
                role: "CH",
                adminPageParentList,
                setAdminPageParentList,
                showImportButton: true,
                bulkDeleteLoading: bulkDeleteLoading,
                bulkUpdateLoading: bulkUpdateLoading,
                handleBulkDeleteClick: handleBulkDeleteClick,
                handleBulkClassClick: handleBulkClassClick,
                selectionModel: selectionModel,
                setSelectionModel: setSelectionModel,
                names: bulkActionNames,
                childBulkAction: true,

                // showImportButton: false,
              },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </div>
      </Box>
    </>
  );
};

export default ChildManagePage;

import React, { useEffect, useState } from "react";
import { getCustomerSubscriptionList } from "api/payment-api";

import { PaymentHistory, PaymentProduct } from "types/payment";

import useStore from "store/store";
import "./constants/payment.css";
import PaymentProductComponent from "./PaymentProductComponent";
import { Box, Typography } from "@mui/material";
import { translation } from "constants/translation";

const PaymentProductDisplay = ({
  productList,
  handleCustomerPortal,
}: {
  productList: PaymentProduct[];
  handleCustomerPortal: () => Promise<void>;
}) => {
  const { currLanguage, profileDetails, customerInfo } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    customerInfo: state.customerInfo,
  }));

  const [isEligibleToTrial, setEligibleToTrial] = useState<boolean>();

  useEffect(() => {
    if (customerInfo.customer.id === "-1") return;

    const populateEligibleToTrial = async () => {
      if (customerInfo.customer.id !== "") {
        const res = await getCustomerSubscriptionList(
          customerInfo.customer.id,
          "canceled"
        );
        if (typeof res !== "string" && res.length > 0) {
          setEligibleToTrial(false);
        } else {
          setEligibleToTrial(true);
        }
      } else {
        setEligibleToTrial(true);
      }
    };
    populateEligibleToTrial();
  }, [customerInfo.customer.id]);

  const isLoading =
    isEligibleToTrial === undefined || customerInfo.customer.id === "-1";

  const haveSubscribed =
    productList.filter((product) => product.id === customerInfo.product.id)
      .length > 0;
  // console.log("haveSubscribed: ", haveSubscribed);
  // console.log("isEligibleToTrial: ", isEligibleToTrial);
  // console.log("isLoading: ", isLoading);
  // console.log("productList: ", productList);
  // console.log("customerInfo: ", customerInfo);
  return (
    <>
      {productList ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignSelf: "center",
                justifyContent: "center",
                gap: 4,
                flexWrap: "wrap",
              }}
            >
              {productList
                .sort(
                  (a, b) =>
                    a.default_price.unit_amount - b.default_price.unit_amount
                )
                .map((product) => (
                  <PaymentProductComponent
                    key={product.id}
                    product={product}
                    isEligibleToTrial={isEligibleToTrial}
                    isSubscribed={customerInfo.product.id === product.id}
                    haveSubscribed={haveSubscribed}
                    isLoading={isLoading}
                    handleCustomerPortal={handleCustomerPortal}
                  />
                ))}
            </Box>
          </Box>
        </>
      ) : (
        <Typography
          variant="body1"
          sx={{
            fontWeight: "500",
            fontSize: "26px",
          }}
        >
          {translation.noProduct}
        </Typography>
      )}
    </>
  );
};
export default PaymentProductDisplay;

import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import moment from "moment";
import {
  Box, SxProps, Typography, useTheme, useMediaQuery,
} from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { API_Timetable_Event_Info } from "types/timetable";
import { convert24HrsTo12Hrs } from "helper/timetable";
import useColors from "hooks/useColors";
import { API_Message } from "types/chat";
import { DATE_FORMAT } from "containers/AnnouncementsPage/AnnouncementComponents";
import { CHAT, CURLANG } from "constants/url";


export const useWidgetColors = () => {
  const theme = useTheme();
  const colors = useColors();

  const widgetColors = colors.filter(
    (color) => color !== theme.palette.custom.blue
  );

  return widgetColors;
};

export const WidgetContainer = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => {
  const {
    profileDetails,

  } = useStore((state) => ({

    profileDetails: state.profileDetails,

  }));
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box
      sx={{
        width: "100%",
        flex: isLg ? 1 : "auto",
        maxWidth: { xs: "100%", lg: "auto" },
        borderRadius: "10px",
        py: 1.5,
        px: 2,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const ScrollableContainerStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  overflowY: "auto",
  mr: -2,
  pr: 2,
};

export const Loading = () => (
  <Box
    sx={{
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <LoadingIndicator />
  </Box>
);

export const TimetableEventCard = ({
  event,
}: {
  event: API_Timetable_Event_Info;
}) => {
  const colors = useColors();
  const startTime = convert24HrsTo12Hrs(event.start);
  const color = colors[event.color % colors.length];

  return (
    <Typography
      sx={{
        py: 1,
        px: 2,
        borderRadius: "10px",
        border: "1px solid",
        borderColor: color,
        backgroundColor: color,
        flexShrink: 0,
      }}
      noWrap
    >
      <Typography
        component="span"
        sx={{
          fontSize: 12,
          display: "inline-block",
          mr: 1,
        }}
      >
        {startTime}
      </Typography>
      {event.name}
    </Typography>
  );
};

export const MessageCard = ({ message }: { message: API_Message }) => {
  const navigate = useNavigate();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const handleClick = () =>
    navigate(`${CURLANG(currLanguage)}/${CHAT(message.chat.toString())}`);

  return (
    <Box
      sx={{
        cursor: "pointer",
        py: 1,
        px: 2,
        borderRadius: "10px",
        backgroundColor: "white",
        flexShrink: 0,
      }}
      onClick={handleClick}
    >
      <Typography>{message.author}</Typography>

      <Typography
        sx={{
          fontSize: 12,
          mb: 0.5,
          color: "#312F30",
        }}
      >
        {moment(message.timestamp).format(DATE_FORMAT)}
      </Typography>

      <Typography>{message.content}</Typography>
    </Box>
  );
};

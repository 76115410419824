import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { Box, Typography, Stack, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_Teacher_Certificate_Create } from "types/teacher";
import {
  createTeacherCertificate,
  deleteTeacherCertificate,
  editTeacherCertificate,
  getCertificateBySlug,
} from "api/teacher-api";
import WarningDialog from "components/Dialog/WarningDialog";
import CustomButton from "components/Button/Button";
import { translation } from "constants/translation";
import ModuleTransferList from "./ModuleTransferList";
import useDialog from "hooks/useDialog";
import Logo from "images/logo-2.png";
import { API_Teacher_Certificate_Edit } from "types/teacher";
import useInitialiseTeacherModules from "hooks/useInitialiseTeacherModules";
import CertificateImageField from "./CertificateImageField";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";

const CreateEditCertificatePage = ({
  isModal = false,
  handleClose,
  setFormData,
}: {
  isModal?: boolean;
  handleClose?: () => void;
  setFormData?: any;
}) => {
  useInitialiseTeacherModules();
  const { pathname } = useLocation();
  const isCreate = pathname.toString().includes("/create");
  const openSnack = useSnack();
  const navigate = useNavigate();
  const { certificateSlug } = useParams();

  //Warning Dialog
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [currentCertificateData, setCurrentCertificateData] =
    useState<API_Teacher_Certificate_Edit>({
      id: -1,
      name: "",
      description: "",
      image: "",
      module: [],
    });

  const { currLanguage, profileID } = useStore((state) => state);

  useEffect(() => {
    if (certificateSlug) {
      const populateCertificateBySlug = async () => {
        const res = await getCertificateBySlug(certificateSlug!);
        if (typeof res !== "string") {
          setCurrentCertificateData({
            id: res.id,
            name: res.name,
            description: res.description,
            image: res.image,
            module: res.modules.map((module_) => module_.id),
          });
        }
      };
      populateCertificateBySlug();
    }
  }, [certificateSlug]);

  const handleSubmit = async () => {
    if (isCreate) {
      const res = await createTeacherCertificate(
        currLanguage,
        currentCertificateData as API_Teacher_Certificate_Create
      );
      if (typeof res === "string") {
        // console.log("Res: ", res);
        openSnack(res, false);
      } else {
        openSnack(
          `Created ${currentCertificateData.name} certificate successfully!`,
          true
        );

        if (handleClose) {
          handleClose();
        } else {
          // navigate(`${CURLANG(currLanguage)}/${CLASS}`);
          navigate(-1);
        }
      }
    } else {
      //update
      const res = await editTeacherCertificate(
        currLanguage,
        currentCertificateData
      );
      if (typeof res === "string") {
        openSnack(res, false);
      } else {
        openSnack(
          `Updated ${currentCertificateData.name} group successfully!`,
          true
        );
        if (handleClose) {
          handleClose();
        } else {
          // navigate(`${CURLANG(currLanguage)}/${CLASS}`);
          navigate(-1);
        }
      }
    }
  };

  const handleDelete = async () => {
    const res = await deleteTeacherCertificate(
      currentCertificateData.id,
      localStorage.getItem("access")!
    );

    if (typeof res === "string" && res.length >= 1) {
      openSnack(res, false);
    } else {
      openSnack(
        translation.successDeleteCertificate ||
          "Deleted Certificate Successfully",
        true
      );
      navigate(-1);
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete ${currentCertificateData.name} Certificate?`,
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return profileID ? (
    <Box sx={{ p: "16px" }}>
      {isCreate ? null : <WarningDialog {...warningBody} />}
      <Stack
        sx={{ margin: "auto", my: "16px", alignItems: "center" }}
        spacing={2}
        direction="column"
      >
        {!isModal ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <CustomButton
              buttonText={``}
              arrow={true}
              backward={true}
              onClick={() => {
                // navigate(`${CURLANG(currLanguage)}/${CLASS}`);
                navigate(-1);
              }}
            />
            <Box>
              <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
                {isCreate
                  ? translation.createCertificate
                  : translation.editCertificate}

                {isCreate ? null : (
                  <DeleteIcon
                    sx={{
                      cursor: "pointer",
                      color: "txt.secondary",
                      transition: "all 100ms ease-in-out",
                      "&:hover": {
                        color: "primary.main",
                        transition: "all 100ms ease-in-out",
                      },
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleOpenDialog();
                    }}
                  />
                )}
              </Typography>
            </Box>
            <CustomButton
              buttonText={``}
              arrow={true}
              backward={true}
              onClick={() => {
                // navigate(`${CURLANG(currLanguage)}/${CLASS}`);
                navigate(-1);
              }}
              style={{ background: "transparent" }}
              disabled={true}
            />
          </Box>
        ) : (
          <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
            {translation.createCertificate}
          </Typography>
        )}

        <CertificateImageField
          image={
            typeof currentCertificateData.image === "string" &&
            currentCertificateData.image !== ""
              ? currentCertificateData.image
              : typeof currentCertificateData.image === "object"
              ? currentCertificateData.image !== null
                ? URL.createObjectURL(currentCertificateData.image)
                : Logo
              : Logo
          }
          setCurrentCertificateData={setCurrentCertificateData}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: "24px !important",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            required
            label={translation.name}
            value={currentCertificateData.name}
            onChange={(event) => {
              setCurrentCertificateData((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
          />

          <Box>
            <Typography sx={{ mb: 1 }}>{translation.description}</Typography>

            <RichCKEditor
              data={currentCertificateData.description}
              onChange={(event: any, editor: any) => {
                const data: string = editor.getData();

                setCurrentCertificateData((prev) => ({
                  ...prev,
                  description: data,
                }));
              }}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
            {translation.assignModuleToCertificate}
          </Typography>
        </Box>
        <Box>
          <ModuleTransferList
            currentCertificateData={currentCertificateData}
            setCurrentCertificateData={setCurrentCertificateData}
          />
        </Box>

        <CustomButton
          buttonText={
            isCreate
              ? translation.submit || "Submit"
              : translation.update || "Update"
          }
          style={{ marginTop: "20px" }}
          onClick={handleSubmit}
          disabled={currentCertificateData.name === ""}
        />
      </Stack>
    </Box>
  ) : (
    <Typography
      sx={{
        fontSize: "36px",
        height: "71vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {translation.not_logged_in}
    </Typography>
  );
};

export default CreateEditCertificatePage;

import { useEffect } from "react";
import { getAllModules } from "../api/teacher-api";

import useStore from "../store/store";
import useSnack from "./useSnack";

const useInitialiseTeacherModules = () => {
  const openSnack = useSnack();
  const { currLanguage, setTeacherModules } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setTeacherModules: state.setTeacherModules,
  }));

  useEffect(() => {
    const initialiseTeacherModules = async (showLoading: boolean) => {
      const teacherModuleListRes = await getAllModules(currLanguage, "");
      // console.log("Returned module: ", module);
      if (typeof teacherModuleListRes === "string") {
        openSnack(teacherModuleListRes, false);
      } else {
        setTeacherModules(teacherModuleListRes);
      }
    };

    initialiseTeacherModules(true);
  }, []);

  // useEffect(() => {
  //   isRefreshTeacherModule && initialiseTeacherModules(false);
  // }, [isRefreshTeacherModule]);
};

export default useInitialiseTeacherModules;

import React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";

import useStore from "store/store";
import Image from "../Image/Image";

import { API_Group_Full } from "types/group";
import Logo from "../../images/logo-2-colour.png";
import { useParams } from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&.active": {
        backgroundColor: theme.palette.primary.light,
        pointerEvents: "none",
      },
    },
  },
}));

export default function GroupSelector({
  isMobileView = false,
}: {
  isMobileView?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { groupSlug } = useParams();
  const {
    currGroup,
    setCurrGroup,
    groupList,
    setRefreshGroupBackpack,
    setRefreshGroupFavourite,
  } = useStore((state) => state);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return groupList.length !== 0 && currGroup.id !== -1 ? (
    <>
      <Box
        onClick={(e) => {
          !groupSlug && handleClick(e);
        }}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          mr: { xs: 0, lg: "10px" },
          cursor: groupSlug ? "default" : "pointer",
          alignItems: "center",
          backgroundColor: "#DFDDDD",
          p: 1,
          borderRadius: `10px`,
          "&:hover": {
            backgroundColor: `white`,
          },
          width: isMobileView ? 120 : "default",
          maxWidth: 120,
        }}
      >
        <Box sx={{ display: { xs: "none", lg: "flex" }, mr: 1 }}>
          <Image
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
            }}
            objectFit="cover"
            src={typeof currGroup.image === "string" ? currGroup.image : Logo}
            alt={currGroup.name}
          />
        </Box>

        <Box sx={{ display: { xs: "flex", lg: "none" }, mb: 1 }}>
          <Image
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
            }}
            objectFit="cover"
            src={typeof currGroup.image === "string" ? currGroup.image : Logo}
            alt={currGroup.name}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              alignSelf: "center",
              maxWidth: 60,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: 14,
            }}
          >
            {currGroup.name}
          </Box>

          {!groupSlug && <ArrowDropDownIcon fontSize="small" />}
        </Box>
      </Box>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {groupList.map((group: API_Group_Full) => (
          <MenuItem
            className={group.id === currGroup.id ? "active" : ""}
            key={group.id}
            onClick={() => {
              handleClose();
              setCurrGroup(group);
              setRefreshGroupBackpack(true);
              setRefreshGroupFavourite(true);
              localStorage.setItem("curr_group_id", String(group.id));
            }}
            disableRipple
          >
            <Image
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
              objectFit="cover"
              src={typeof group?.image === "string" ? group?.image : Logo}
              alt=""
            />
            {group?.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  ) : null;
}

import { useState } from "react";
import { Box, Typography } from "@mui/material";
import useStore from "store/store";
import { translation } from "constants/translation";
import { TITLE } from "constants/title";

import "./EditDevelopmentalGoalPage.css";
import DevelopmentalGoalResizableDrawer from "./DevelopmentalGoalResizableDrawer";
import DevelopmentalGoalTreeView from "./DevelopmentalGoalTreeView";

export type TagType = "Head" | "Hands" | "Heart";
export const initialDevelopmentalGoal = {
  id: -1,
  name: "",
  description: "",
  image: "",
  title: "",
  tag: "",
  milestones: [],
  priority: 0,
  slug: "",
};

const EditDevelopmentalGoalPage = () => {
  document.title = TITLE;
  const {
    currDevelopmentalGoal,
    setCurrDevelopmentalGoal,
    developmentalGoals,
  } = useStore((state) => state);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleOpen = (
    selectedDevelopmentalGoalId: number,
    parentDevelopmentalGoalId?: number
  ) => {
    if (
      isDrawerOpen &&
      selectedDevelopmentalGoalId === currDevelopmentalGoal.id &&
      (!parentDevelopmentalGoalId ||
        parentDevelopmentalGoalId === currDevelopmentalGoal.main_fundamental)
    ) {
      handleClose();
      return;
    }

    const developmentalGoal = parentDevelopmentalGoalId
      ? developmentalGoals
          .filter(
            (developmentalGoal) =>
              developmentalGoal.id === parentDevelopmentalGoalId
          )[0]
          .sub_fundamentals?.filter(
            (subDevelopmentalGoal) =>
              subDevelopmentalGoal.id === selectedDevelopmentalGoalId
          )[0]
      : developmentalGoals.filter(
          (developmentalGoal) =>
            developmentalGoal.id === selectedDevelopmentalGoalId
        )[0];

    if (developmentalGoal) {
      setCurrDevelopmentalGoal(developmentalGoal);
    } else {
      setCurrDevelopmentalGoal({
        ...initialDevelopmentalGoal,
        id: selectedDevelopmentalGoalId,
        main_fundamental: parentDevelopmentalGoalId,
      });
    }
    document.body.classList.add("drawer-open");
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    if (!isDrawerOpen) return;

    document.body.classList.remove("drawer-open");
    setCurrDevelopmentalGoal(initialDevelopmentalGoal);
    setIsDrawerOpen(false);
  };

  return (
    <Box
      className="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: "60px",
        my: 3,
        gap: 2,
      }}
    >
      <Title />
      <Box
        sx={{
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
          flex: 1,
          p: 4,
        }}
      >
        <DevelopmentalGoalTreeView
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </Box>

      <DevelopmentalGoalResizableDrawer
        isDrawerOpen={isDrawerOpen}
        handleClose={handleClose}
      />
    </Box>
  );
};

const Title = () => (
  <Box
    sx={{
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography
      sx={{
        fontSize: "26px",
        fontWeight: "500",
        textAlign: "center",
      }}
    >
      {translation.editingDevelopmentalGoal}
    </Typography>
  </Box>
);

export default EditDevelopmentalGoalPage;

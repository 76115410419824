import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CHAT, CHATHOME, CURLANG } from "constants/url";
import useStore from "store/store";
import { API_Chat } from "types/chat";
import { API_Profile_Details } from "types/profile";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import { deleteChat } from "api/chat-api";
import useSnack from "hooks/useSnack";
import formatDate from "helper/formatDate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ChatComponent = ({
  chat,
  isSidePanel,
}: {
  chat: API_Chat;
  isSidePanel?: boolean;
}) => {
  const { profileID } = useStore((state) => ({
    profileID: state.profileID,
  }));

  const [imgSrc, setImgSrc] = useState<string>("");
  const [altName, setAltName] = useState<string>("");
  const [chatProfiles, setChatProfiles] = useState<API_Profile_Details[]>([]);

  useEffect(() => {
    //remove current profile
    const profiles = chat.profiles.filter(
      (prof) => prof.id.toString() !== profileID
    );
    setChatProfiles(profiles);

    //If there's only 1 profile + the image is not null
    if (profiles.length === 1 && profiles[0].image !== null) {
      setImgSrc(profiles[0].image);
    }

    const name = profiles.map((p) => p.preferred_name).join(", ");
    setAltName(name);
  }, [chat]);

  return isSidePanel ? (
    <SidePanel
      chat={chat}
      altName={altName}
      chatProfiles={chatProfiles}
      imgSrc={imgSrc}
    />
  ) : (
    <ChatInfo
      chat={chat}
      altName={altName}
      chatProfiles={chatProfiles}
      imgSrc={imgSrc}
    />
  );
};

export const SharedAvatar = ({
  imgSrc,
  isGroup,
  visible = true,
}: {
  imgSrc: string;
  isGroup: boolean;
  visible?: boolean;
}) => (
  <Avatar
    src={imgSrc}
    sx={{
      width: 36,
      height: 36,
      visibility: visible ? "visible" : "hidden",
    }}
  >
    {imgSrc === "" ? isGroup ? <GroupsIcon /> : <PersonIcon /> : ""}
  </Avatar>
);

const SidePanel = ({
  chat,
  altName,
  chatProfiles,
  imgSrc,
}: {
  chat: API_Chat;
  altName: string;
  chatProfiles: API_Profile_Details[];
  imgSrc: string;
}) => {
  const navigate = useNavigate();

  const { currLanguage, profileID } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileID: state.profileID,
  }));

  const { chatID } = useParams();

  return (
    <Box
      sx={{
        cursor: "pointer",
        pointerEvents: chatID === chat.id.toString() ? "none" : "auto",
        bgcolor: chatID === chat.id.toString() ? "#FBFBFB" : "transparent",
        borderBottom: "1px solid #E1E2E9",
        py: 1.5,
        pl: 2,
        pr: 1.5,
        order: chat.latest_messages.length > 0 ? 0 : -chat.id,
        "&:hover": {
          backgroundColor: "rgba(209, 228, 227, 0.37)",
        },
      }}
      onClick={() =>
        navigate(`${CURLANG(currLanguage)}/${CHAT(chat.id.toString())}`)
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.25,
          position: "relative",
        }}
      >
        <SharedAvatar imgSrc={imgSrc} isGroup={chatProfiles.length > 1} />

        <Box
          sx={{
            flex: 1,
            minWidth: 0,
            display: "flex",
            flexDirection: "column",
            gap: 0.25,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography noWrap sx={{ fontWeight: 500, lineHeight: "1.25" }}>
              {chat?.name ? chat?.name : altName}
            </Typography>

            <Role
              // chat={chat}
              chatProfiles={chatProfiles}
            />

            {chat.latest_messages.length > 0 && (
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "1",
                  color: "#312F30",
                  opacity: 0.75,
                  ml: 1,
                  whiteSpace: "nowrap",
                  alignSelf: "flex-start",
                }}
              >
                {formatDate(chat.latest_messages[0].timestamp)}
              </Typography>
            )}
          </Box>

          {chat.latest_messages.length > 0 && (
            <Typography
              noWrap
              sx={{
                fontSize: 14,
                lineHeight: "1.25",
                maxWidth: chat.unseen_messages_count !== 0 ? "200px" : "100%",
              }}
            >
              {chat.latest_messages[0].author_id === parseInt(profileID!)
                ? `You: ${chat.latest_messages[0].content}`
                : chatProfiles.length > 1
                ? `${chat.latest_messages[0].author}: ${chat.latest_messages[0].content}`
                : `${chat.latest_messages[0].content}`}
            </Typography>
          )}
        </Box>

        {chat.unseen_messages_count !== 0 && (
          <Box
            sx={{
              display: "flex",
              width: 24,
              height: 24,
              borderRadius: "50%",
              color: "white",
              bgcolor: "primary.main",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 12,
              position: "absolute",
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
          >
            {chat.unseen_messages_count > 99
              ? "99+"
              : chat.unseen_messages_count}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ChatInfo = ({
  chat,
  altName,
  chatProfiles,
  imgSrc,
}: {
  chat: API_Chat;
  altName: string;
  chatProfiles: API_Profile_Details[];
  imgSrc: string;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const openSnack = useSnack();
  const { allChats, setAllChats, currLanguage } = useStore((state) => ({
    allChats: state.allChats,
    setAllChats: state.setAllChats,
    currLanguage: state.currLanguage,
  }));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEll, setAnchorEll] = useState<null | HTMLElement>(null);

  //Menu to show group member
  const handleClickEl = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEl = () => {
    setAnchorEl(null);
  };

  //Menu to show delete option
  const handleClickEll = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEll(event.currentTarget);
  };
  const handleCloseEll = () => {
    setAnchorEll(null);
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleDelete = async () => {
    const res: any = await deleteChat(chat.id);
    if (res.status !== 204) {
      openSnack(res.data, false);
    } else {
      const updatedChats = allChats;
      const currChatIndex = updatedChats.findIndex(
        (chat_) => chat_.id === chat.id
      );
      if (updatedChats[currChatIndex]) {
        updatedChats.splice(currChatIndex, 1);
        setAllChats(updatedChats);
      }
      navigate(`${CURLANG(currLanguage)}/${CHATHOME}`);
      openSnack("Deleted the chat!", true);
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete Chat ${altName}?`,
    warningContext:
      "The action is irreversable and the chat history will be gone!",
    handleDelete,
  };
  return (
    <>
      <WarningDialog {...warningBody} />

      {chatProfiles.length > 1 && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseEl}>
          {chatProfiles.map((chatProfile) => (
            <MenuItem
              key={`${chat.id}-${chatProfile.id}`}
              style={{ pointerEvents: "none" }}
            >
              {chatProfile.preferred_name}
            </MenuItem>
          ))}
        </Menu>
      )}

      <Menu
        anchorEl={anchorEll}
        open={!!anchorEll}
        onClose={handleCloseEll}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "200px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleCloseEll();
            handleOpenDialog();
          }}
        >
          Delete Chat
        </MenuItem>
      </Menu>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 0.75,
          py: 1.25,
          px: 3,
          borderBottom: "1px solid #E1E2E9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            cursor: chatProfiles.length > 1 ? "pointer" : "auto",
            alignItems: "center",
            gap: 1,
          }}
          onClick={(e) => (chatProfiles.length > 1 ? handleClickEl(e) : {})}
        >
          {isXs && (
            <IconButton
              onClick={() => {
                navigate(`${CURLANG(currLanguage)}/${CHATHOME}`);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <SharedAvatar imgSrc={imgSrc} isGroup={chatProfiles.length > 1} />

          <Typography noWrap sx={{ fontWeight: 500, maxWidth: "250px" }}>
            {chat?.name ? chat?.name : altName}
          </Typography>

          <Role
            // chat={chat}
            chatProfiles={chatProfiles}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.2,
            cursor: "pointer",
            transition: "all 200ms linear",
            "&:hover": {
              opacity: 1,
              transition: "all 200ms linear",
            },
            width: 30,
            height: 30,
            borderRadius: "50%",
            bgcolor: "primary",
            ml: "auto",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleClickEll(e);
          }}
        >
          <MoreVertIcon />
        </Box>
      </Box>
    </>
  );
};

export const Role = ({
  // chat,
  chatProfiles,
}: {
  // chat: API_Chat;
  chatProfiles: API_Profile_Details[];
}) => {
  // console.log("chat: ", chat);
  // console.log("chatProfiles: ", chatProfiles);
  if (chatProfiles.length === 0) {
    return null;
  }
  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "1.25",
        color:
          chatProfiles.length > 1
            ? "#B86D85"
            : chatProfiles[0].role === "PR"
            ? "#0065C1"
            : "#007505",
        bgcolor:
          chatProfiles.length > 1
            ? "#FCDDEC"
            : chatProfiles[0].role === "PR"
            ? "#F0F8FF"
            : "#EBF8EC",
        px: 1,
        py: 0.25,
        borderRadius: "99px",
        mr: "auto",
      }}
    >
      {chatProfiles.length > 1
        ? "Group"
        : chatProfiles[0].role === "PR"
        ? "Parent"
        : chatProfiles[0].role === "AD"
        ? "School Admin"
        : chatProfiles[0].role === "TR"
        ? "Teacher"
        : chatProfiles[0].role === "CE"
        ? "Content Editor"
        : "Other"}
    </Typography>
  );
};

export default ChatComponent;

import { Backdrop, Box, Fade, Modal, TextField } from "@mui/material";
import { useState } from "react";
import { createGroupChat } from "api/chat-api";
import { API_Chat_Create } from "types/chat";

import { API_Profile_Details } from "types/profile";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import { CHAT, CURLANG } from "constants/url";
import CustomButton from "components/Button/Button";
import { SearchBar, Title, ContactList } from "./CommonComponents"
import useSnack from "hooks/useSnack";
import { translation } from "constants/translation";
import { chatModalStyle } from "./chatConstants";

const GroupChatModal = ({
  open,
  setOpen,
  allContacts,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allContacts: API_Profile_Details[];
}) => {
  const navigate = useNavigate();
  const openSnack = useSnack();

  const { allChats, setAllChats, currLanguage } = useStore((state) => ({
    allChats: state.allChats,
    setAllChats: state.setAllChats,
    currLanguage: state.currLanguage,
  }));
  const [newGroupChat, setNewGroupChat] = useState<API_Chat_Create>({
    name: "",
    profile: [],
    is_group: true,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleGroupModalClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    resetInput();
    setOpen(false);
  };

  const resetInput = () => {
    setSearchQuery("");
    setNewGroupChat({
      name: "",
      profile: [],
      is_group: true,
    });
  };

  const startGroupChat = async () => {
    const res = await createGroupChat(
      newGroupChat,
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const updatedAllChats = [...allChats, res];
      setAllChats(updatedAllChats);
      resetInput();
      setOpen(false);
      navigate(`${CURLANG(currLanguage)}/${CHAT(res.id.toString())}`);
    }
  };

  const contacts =
    searchQuery === ""
      ? allContacts
      : allContacts.filter((contact) =>
          contact.preferred_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );

  return (
    <Modal
      open={open}
      onClose={handleGroupModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={chatModalStyle}>
          <Title title={translation.selectContact} />

          <TextField
            fullWidth
            label="Chat Name (Optional)"
            value={newGroupChat.name}
            onChange={(event) => {
              setNewGroupChat((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
            sx={{
              mb: 2,
              maxWidth: "500px",
              mx: "auto",
            }}
          />

          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />

          <ContactList
            contacts={contacts}
            profiles={newGroupChat.profile}
            onClick={(id: number) => {
              const updatedNewGroupChat = JSON.parse(
                JSON.stringify(newGroupChat)
              );

              if (updatedNewGroupChat.profile.includes(id)) {
                const index = updatedNewGroupChat.profile.indexOf(id);
                updatedNewGroupChat.profile.splice(index, 1);
              } else {
                updatedNewGroupChat.profile.push(id);
              }

              setNewGroupChat(updatedNewGroupChat);
            }}
          />

          <CustomButton
            buttonText={
              translation.startANewClassChat || "Start a new class chat"
            }
            arrow={false}
            disabled={newGroupChat.profile.length < 2}
            style={{
              margin: "24px auto 0",
              flexShrink: 0,
              padding: "10px 16px",
            }}
            onClick={startGroupChat}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default GroupChatModal;

import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { translation } from "constants/translation";
import useStore from "store/store";
import {
  CURLANG,
  CHILDREN_PORTFOLIO,
  MY_CHILDREN_PORTFOLIO,
} from "constants/url";
import { translatedTabs, PHOTO_ALBUM, ALL_PHOTOS, ALBUMS } from "./constants";

const Breadcrumbs = ({
  className,
  classSlug,
  childName,
  currentTab,
  isParentView = false,
}: {
  className?: string;
  classSlug?: string;
  childName: string;
  currentTab: string;
  isParentView?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const photoAlbumTab = pathname.includes(PHOTO_ALBUM)
    ? pathname.includes(ALL_PHOTOS)
      ? ALL_PHOTOS
      : ALBUMS
    : "";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        mb: 3,
        flexWrap: "wrap",
      }}
    >
      <Typography
        sx={{
          cursor: "pointer",
          lineHeight: 1,
        }}
        onClick={() =>
          navigate(
            `${CURLANG(currLanguage)}/${
              isParentView ? MY_CHILDREN_PORTFOLIO : CHILDREN_PORTFOLIO
            }`
          )
        }
      >
        {translation.childrenPortfolio}
      </Typography>

      <NavigateNextIcon fontSize="small" />

      {classSlug !== undefined && className !== undefined && (
        <>
          <Typography
            sx={{
              cursor: "pointer",
              lineHeight: 1,
            }}
            onClick={() =>
              navigate(
                `${CURLANG(currLanguage)}/${CHILDREN_PORTFOLIO}/${classSlug}`
              )
            }
          >
            {className}
          </Typography>

          <NavigateNextIcon fontSize="small" />
        </>
      )}

      <Typography
        sx={{
          lineHeight: 1,
        }}
      >
        {childName}
      </Typography>

      <NavigateNextIcon fontSize="small" />

      {photoAlbumTab !== "" ? (
        <>
          <Typography
            sx={{
              lineHeight: 1,
            }}
          >
            {translatedTabs[currentTab]}
          </Typography>

          <NavigateNextIcon fontSize="small" />

          <Typography
            sx={{ fontWeight: "bold", color: "#407374", lineHeight: 1 }}
          >
            {translatedTabs[photoAlbumTab]}
          </Typography>
        </>
      ) : (
        <Typography
          sx={{ fontWeight: "bold", color: "#407374", lineHeight: 1 }}
        >
          {translatedTabs[currentTab]}
        </Typography>
      )}
    </Box>
  );
};

export default Breadcrumbs;

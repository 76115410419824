import  { useEffect, useState} from "react";

import {
  Box,
  Typography,
  List,
  ListItem,
  Avatar,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BackpackSideMenu from "./BackpackSideMenu/BackpackSideMenu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { LEFT_COLUMN_WIDTH, RIGHT_COLUMN_WIDTH } from "./constants/constants";
import CalendarHeadSection from "./CalendarSection/CalendarHeadSection";
import CalendarBodySection from "./CalendarSection/CalendarBodySection";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useStore from "store/store";
import moment from "moment";

import {
  computeMonthDates,
  computeWeekDates,
  computeYearDates,
  generateMonthDates,
  generateWeekDates,
  generateYearDates,
} from "./constants/helper";

import {
  CalendarViewOptions,
  GroupProjectBoxProps,
} from "types/calendar";
import { useNavigate } from "react-router-dom";

const CalendarPage = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const {
    currGroup,
    setCurrGroup,
    groupList,
    startAndEndDate,
    setStartAndEndDate,
    calendarViews,
    calendarDates,
    calendarStartDate,
    isLoadingCalendar,
    setIsLoadingCalendar,
    setCalendarDates,
    setRefreshProjectBox,
    refreshProjectBox,
    isRefreshBypassLoading,
    setIsRefreshBypassLoading,
    groupProjectBoxProps,
    currentView,
    setCalendarViews,
  } = useStore((state) => ({
    calendarDates: state.calendarDates,
    currGroup: state.currGroup,
    setCurrGroup: state.setCurrGroup,
    setCalendarViews: state.setCalendarViews,
    currentView: Object.keys(state.calendarViews).filter(
      (view) => state.calendarViews[view as CalendarViewOptions] === true
    )[0] as CalendarViewOptions,
    groupList: state.groupList,
    groupProjectBoxProps: state.groupProjectBoxProps,
    refreshProjectBox: state.refreshProjectBox,
    startAndEndDate: state.startAndEndDate,
    setStartAndEndDate: state.setStartAndEndDate,
    setCalendarDates: state.setCalendarDates,
    setIsLoadingCalendar: state.setIsLoadingCalendar,
    setRefreshProjectBox: state.setRefreshProjectBox,
    calendarViews: state.calendarViews,
    calendarStartDate: state.calendarStartDate,
    isLoadingCalendar: state.isLoadingCalendar,
    isRefreshBypassLoading: state.isRefreshBypassLoading,
    setIsRefreshBypassLoading: state.setIsRefreshBypassLoading,
  }));
  const [classProjects, setClassProjects] = useState<any[]>([]);
  useEffect(() => {
    // console.log("Getting calendar start date...");
    // get current view: week / month / 3-MONTHS
    const currentView: CalendarViewOptions = Object.keys(calendarViews).filter(
      (view) => calendarViews[view as CalendarViewOptions] === true
    )[0] as CalendarViewOptions;
    // populate the days state with calculated days
    if (currentView === "Month") {
      setStartAndEndDate(computeMonthDates(calendarStartDate));
    } else if (currentView === "Week") {
      setStartAndEndDate(computeWeekDates(calendarStartDate));
    } else if (currentView === "Year") {
      setStartAndEndDate(computeYearDates(calendarStartDate));
    }

    // setCurrentView
  }, [calendarStartDate, calendarViews]);

  const { startDate, endDate } = startAndEndDate;

  const isBetweenDate = (projectStartAt: string, projectEndAt: string) => {
    const projectStartAtMoment = moment(projectStartAt);
    const projectEndAtMoment = moment(projectEndAt);

    return (
      (projectStartAtMoment.isSameOrAfter(startDate) &&
        projectStartAtMoment.isSameOrBefore(endDate)) || // inner
      (projectEndAtMoment.isSameOrAfter(startDate) &&
        projectEndAtMoment.isSameOrBefore(endDate)) || // inner
      (projectStartAtMoment.isSameOrBefore(startDate) &&
        projectEndAtMoment.isSameOrAfter(endDate))
    );
  };

  const getProjectBoxes = () => {
    if (Object.keys(groupProjectBoxProps).length === 0) {
      return {};
    }
    const projectBoxesTemp: any = {};

    for (const [groupId, projectBoxes] of Object.entries(
      groupProjectBoxProps
    )) {
      // console.log("groupProjectBoxProps: ", groupProjectBoxProps);
      // console.log("projectBoxes: ", projectBoxes);
      const filteredProjects = projectBoxes.filter((projectBox) =>
        isBetweenDate(projectBox.startAt, projectBox.endAt)
      );
      // console.log("filteredProjects: ", filteredProjects);
      projectBoxesTemp[groupId.toString()] = filteredProjects;
    }

    // console.log("calling getProjectBoxes projectBoxesTemp: ", projectBoxesTemp);

    return projectBoxesTemp;
  };

  const [projectBoxesToRender, setProjectBoxesToRender] = useState<
    GroupProjectBoxProps | undefined
  >(undefined);
  // console.log(
  //   startAndEndDate.startDate,
  //   startAndEndDate.startDate.getTime(),
  //   startAndEndDate.endDate,
  //   startAndEndDate.endDate.getTime()
  // );
  useEffect(() => {
    // console.log("refreshProjectBox: ", refreshProjectBox);
    // console.log("isLoadingCalendar: ", isLoadingCalendar);
    if (
      !refreshProjectBox && //refreshprojectbox false = done loading groupprojectboxprops
      isLoadingCalendar &&
      startAndEndDate.startDate.getTime() !== startAndEndDate.endDate.getTime()
    ) {
      const projectBoxesRes = getProjectBoxes();
      // console.log("projectBoxesRes: ", projectBoxesRes);
      setProjectBoxesToRender(projectBoxesRes);
      // console.log(projectBoxesRes);
    }
  }, [
    refreshProjectBox,
    isLoadingCalendar,
    startAndEndDate.startDate.getTime(),
  ]);

  useEffect(() => {
    // console.log("isRefreshBypassLoading: ", isRefreshBypassLoading);
    if (isRefreshBypassLoading) {
      const projectBoxesRes = getProjectBoxes();
      setProjectBoxesToRender(projectBoxesRes);
      setIsRefreshBypassLoading(false);
    }
  }, [isRefreshBypassLoading]);

  // useEffect(() => {
  //   if (Object.keys(groupProjectBoxProps).length > 0) {
  //     setProjectBoxes(getProjectBoxes());
  //     setRefreshProjectBox(false);
  //   }
  // }, [Object.keys(groupProjectBoxProps).length]);
  useEffect(() => {
    if (
      projectBoxesToRender === undefined ||
      isLoadingCalendar === false ||
      refreshProjectBox === true
    ) {
      return;
    }
    // console.log("isLoaindg: ", isLoadingCalendar);
    // console.log("refreshProjectBoxes: ", refreshProjectBox);
    // console.log("projectBoxesToRender: ", projectBoxesToRender);
    // console.log("setting to false");
    setIsLoadingCalendar(false);
    // const loadTimeout = setTimeout(async () => {
    //   setIsLoadingCalendar(false);
    // }, 500);
    // // setIsLoaded(true);
    // return () => clearTimeout(loadTimeout);
  }, [projectBoxesToRender]);
  // console.log(
  //   "isLoading: ",
  //   isLoadingCalendar === false
  //     ? "**********************************FALSE"
  //     : "==================================True"
  // );
  useEffect(() => {
    // console.log("Set start and end date...");
    let dates: Date[];
    switch (currentView) {
      case "Week":
        dates = generateWeekDates(startAndEndDate);
        // generateDayLabels(dates);
        break;
      case "Month":
        dates = generateMonthDates(startAndEndDate);
        break;
      case "Year":
        dates = generateYearDates(startAndEndDate);
        break;
      default:
        dates = [];
    }
    setCalendarDates(dates);
  }, [startAndEndDate, currentView]);

  useEffect(() => {
    setRefreshProjectBox(true);
  }, [calendarDates]);

  // console.log(projectBoxesToRender);
  // console.log(groupList);

  useEffect(() => {
    let keys: any;
    if (projectBoxesToRender) {
      keys = Object.keys(projectBoxesToRender);
    } else {
      keys = [];
    }
    const newArray = groupList.map((group: any) => {
      let littleProjectsCount = 0;
      let littleLearnersCount = 0;
      if (keys.includes(group.id.toString())) {
        if (projectBoxesToRender) {
          projectBoxesToRender[group.id].forEach((project: any) => {
            if (project.moduleCategoryName === "Little Projects") {
              littleProjectsCount++;
            } else if (project.moduleCategoryName === "Little Learners") {
              littleLearnersCount++;
            }
          });
          return {
            ...group,
            projects: projectBoxesToRender[group.id],
            littleProjects: littleProjectsCount,
            littleLearners: littleLearnersCount,
          };
        }
      } else {
        return group;
      }
    });

    setClassProjects(newArray);
  }, [groupList, projectBoxesToRender]);

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setIsLoadingCalendar(true);
      const updatedViews = { ...calendarViews };
      Object.keys(updatedViews).forEach((view) => {
        updatedViews[view as CalendarViewOptions] = false;
      });
      // updatedViews[view as CalendarViewOptions] = true;
      updatedViews["Year"] = true;
      setCalendarViews(updatedViews);
    }
  }, []);

  return isXs ? (
    <Box
      sx={{
        display: "flex",
        // backgroundColor: "lightblue",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* mini indicator */}
      <Box
        sx={{
          // backgroundColor: "pink",

          display: "flex",
          justifyContent: "flex-end",
          width: "100%",

          p: "16px",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#C4E4DF",
            borderRadius: "100px",
            py: "2px",
            px: "4px",
          }}
        >
          <Typography sx={{ fontWeight: "400", fontSize: "10px" }}>
            Little Projects
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#ECEA5C",
            borderRadius: "100px",
            py: "2px",
            px: "4px",
          }}
        >
          <Typography sx={{ fontWeight: "400", fontSize: "10px" }}>
            Little Learners
          </Typography>
        </Box>
      </Box>
      {/* end of mini indicator */}

      {/* start of list */}
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "lightcoral",
          m: 0,
          p: 0,
          width: "100%",
        }}
      >
        {classProjects.map((item: any, index: number) => {
          // console.log(item);
          return (
            <Box
              key={index}
              sx={{ m: 0, p: 0, width: "100%" }}
              onClick={() => {
                // console.log(item);
                setCurrGroup(
                  groupList.filter((group: any) => group.id === item.id)[0]
                );
                // console.log(currGroup);
                navigate(item.slug);
                // setCurrGroup()
              }}
            >
              <ListItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // width: "100%",
                  // m: "16px",
                  m: 0,
                  p: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    m: 0,
                    p: 0,
                    width: "100%",
                  }}
                >
                  <Avatar
                    src={item.image ? item.image : null}
                    sx={{
                      width: "40px",
                      height: "40px",
                      display: "flex",
                    }}
                  />

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "450" }}>
                      {item.name}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "4px" }}>
                      {item.littleProjects > 0 && (
                        <Box
                          sx={{
                            backgroundColor: "#C4E4DF",
                            borderRadius: "100px",
                            px: "4px",
                            py: "2px",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "400", fontSize: "10px" }}
                          >
                            {item.littleProjects} projects
                          </Typography>
                        </Box>
                      )}
                      {item.littleLearners > 0 && (
                        <Box
                          sx={{
                            backgroundColor: "#ECEA5C",
                            borderRadius: "100px",
                            px: "4px",
                            py: "2px",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "400", fontSize: "10px" }}
                          >
                            {item.littleLearners} projects
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <ArrowRightIcon />
              </ListItem>
              {index !== classProjects.length - 1 && <Divider />}
            </Box>
          );
        })}
      </List>
      {/* <BackpackSideMenuMobile /> */}

      {/* end of list */}
    </Box>
  ) : (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          display: "flex",
          flexGrow: "1",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            mt: "20px",
            mx: `${(100 - (RIGHT_COLUMN_WIDTH + LEFT_COLUMN_WIDTH)) / 2}vw`,
            width: "100%",
            height: 25,
          }}
        >
          <CalendarHeadSection />
          {/* body */}

          <CalendarBodySection />
        </Box>

        <BackpackSideMenu />
        {/* <BigCalendar /> */}
      </Box>
    </DndProvider>
  );
};

export default CalendarPage;

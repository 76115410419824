
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { API_Teacher_Lesson, API_Teacher_Video_Activity } from "types/teacher";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { translation } from "constants/translation";
import "./videoActivity.css";
import { TeacherLessonActivityScreenDoneButton } from "../TeacherPage/TeacherPageSideMenuScreens/TeacherLessonActivityScreenButtons";

const TeacherVideoActivityScreen = ({
  teacherCurrentActivity,
  teacherCurrentLesson,
}: {
  teacherCurrentActivity: API_Teacher_Video_Activity;
  teacherCurrentLesson: API_Teacher_Lesson;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 0",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <video
          style={{ maxHeight: "60vh" }}
          controls
          controlsList="nodownload"
          width="100%"
          height="100%"
          poster={
            typeof teacherCurrentActivity.thumbnail === "string" &&
            teacherCurrentActivity.thumbnail !== ""
              ? teacherCurrentActivity.thumbnail
              : ""
          }
          key={teacherCurrentActivity.id}
        >
          {typeof teacherCurrentActivity.video === "string" && (
            <source src={teacherCurrentActivity.video} type="video/mp4" />
          )}
        </video>
      </Box>
      <Accordion disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translation.videoTranscript}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            dangerouslySetInnerHTML={{
              __html: teacherCurrentActivity.transcript
                ? teacherCurrentActivity.transcript
                : "",
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <TeacherLessonActivityScreenDoneButton
          activityId={teacherCurrentActivity.id}
        />
      </Box>
    </Box>
  );
};

export default TeacherVideoActivityScreen;

import React from "react";
import useStore from "store/store";
import { Box } from "@mui/material";
import { API_Group_Full } from "types/group";
import Logo from "images/logo-2.png";
import {
  CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
  CALENDAR_BORDER_BOLD_STYLE,
  CALENDAR_CELL_BACKGROUND_COLOR,
  CALENDAR_SELECTED_CELL_BACKGROUND_COLOR,
  LEFT_COLUMN_WIDTH,
  ROW_HEIGHT,
} from "containers/CalendarPage/constants/constants";
import { GroupProjectBoxProps } from "types/calendar";
import GroupDisplay from "./GroupDisplay";

const LeftColumnBody = ({
  projectBoxes,
  groupList,
}: {
  projectBoxes: GroupProjectBoxProps;
  groupList: API_Group_Full[];
}) => {
  // const { groupList } = useStore((state) => ({
  //   groupList: state.groupList,
  // }));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: `${LEFT_COLUMN_WIDTH}vw`,
      }}
    >
      {groupList.map((group) => (
        <GroupCell
          group={group}
          key={group.id}
          projectBoxesNumber={projectBoxes[group.id]?.length}
        />
      ))}
    </Box>
  );
};

const GroupCell = ({
  group,
  projectBoxesNumber,
}: {
  group: API_Group_Full;
  projectBoxesNumber: number;
}) => {
  const minHeight = Math.max(ROW_HEIGHT * projectBoxesNumber, ROW_HEIGHT);
  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));

  return (
    <Box
      sx={{
        p: "19px",
        borderRight: CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE,
        borderBottom: CALENDAR_BORDER_BOLD_STYLE,
        minHeight: minHeight,
        maxHeight: minHeight,
        display: "flex",
        alignItems: "center",
        zIndex: 3,
        // backgroundColor: "background.paper",
        backgroundColor:
          currGroup.id === group.id
            ? CALENDAR_SELECTED_CELL_BACKGROUND_COLOR
            : CALENDAR_CELL_BACKGROUND_COLOR,
      }}
    >
      <GroupDisplay
        name={group.name}
        height={minHeight}
        img={group.image ? group.image : Logo}
      />
    </Box>
  );
};

export default LeftColumnBody;

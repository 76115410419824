import React, { useState } from "react";
import useStore from "store/store";
import { Button, Chip, TextField, Typography, Box, Fade, Modal } from "@mui/material";


import Backdrop from "@mui/material/Backdrop";
import Image from "components/Image/Image";
import Logo from "images/logo-2-colour.png";


import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import useSnack from "hooks/useSnack";
import { createOrUpdateAttendance } from "api/auth-api";


// import { createAttendance, getAllAttendance, getAttendanceToday, } from "api/auth-api";
const AttendanceDetailModal = ({
  open,
  handleClose,


  dateKey,
  modalText,
  setModalText,
  modalAbsentee,
  setModalAbsentee,
  view,
}: {
  open: boolean;
  handleClose: () => void;


  dateKey?: any;
  modalText: any;
  setModalText: any;
  modalAbsentee: any;
  setModalAbsentee: any;
  view: string;
}) => {
  const openSnack = useSnack();




  const { currGroup, } = useStore((state) => ({
    currGroup: state.currGroup,
  }));




  // const updateAttendanceReason = async (person: any) => {
  //   // console.log(modalText)
  //   // const obj1 = { [dateKey]: modalText };
  //   console.log(modalText)
  //   const access = localStorage.getItem("access");
  //   if (access) {
  //     console.log(modalText)
  //     console.log(access)
  //     const updateResponse: any = await getAllAttendance(
  //       access
  //     );


  //   }
  // };


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: "16px",
            borderRadius: "10px",
            overflow: "auto",
            width: "40%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "600" }} onClick={() => {
              console.log(modalAbsentee)
              console.log(modalText)
            }}>Reasons for Absence</Typography>
            <Box sx={{ display: "flex", marginTop: "40px", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  overflow: "hidden",
                  position: "relative",
                  width: 60,
                  height: 60,
                }}
              >
                <Box
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    paddingTop: "100%",
                  }}
                >
                  <Image
                    src={modalAbsentee.image || Logo}
                    alt={modalAbsentee.full_name}
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#5C9391",
                  }}
                >
                  {modalAbsentee.full_name}
                </Typography>
                <Box sx={{ display: "flex", height: "32px", gap: 1, marginBottom: "20px" }}>
                  <Chip
                    label="Present"
                    variant="outlined"
                    sx={{
                      backgroundColor: "#C9C9C9",
                      color: "white",
                    }}
                    icon={
                      <DoneIcon
                        sx={{
                          color: "white !important",
                        }}
                      />
                    }
                  />
                  <Chip
                    label="Absent"
                    variant="outlined"
                    sx={{ backgroundColor: "#FF5D53", color: "white" }}
                    icon={
                      <CloseIcon
                        sx={{
                          color: "white !important",
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <TextField
              value={modalText}
              sx={{
                marginTop: "4",
                "& .MuiInputBase-root": { height: "100px" },
              }}
              onChange={(e: any) => {
                setModalText(e.target.value);
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
                gap: 1,
              }}
            >
              <Button sx={{ width: "100%" }} onClick={handleClose}>
                Back
              </Button>
              <Button
                variant="contained"
                sx={{ width: "100%", color: "white" }}
                onClick={() => {
                  const access = localStorage.getItem("access")
                  if (view == 'today') {
                    if (access) {
                      const updateSingleAbsentee = async () => {
                        const response = await createOrUpdateAttendance([{ profile: modalAbsentee.id, absent_reason: modalText }], currGroup.id, access)
                        console.log(response)
                        if (response?.data.length == 1 && response.status == 200) {
                          openSnack("Child Attendance Updated!", true)
                          console.log(response.data[0].absent_reason)
                          setModalText(response.data[0].absent_reason)
                        }
                        else if (response?.status != 200) {
                          openSnack("Error setting Attendance", false)
                        }
                        setModalAbsentee(null)
                      }
                      updateSingleAbsentee()
                    }
                  }
                  else if (view != 'today') {
                    if (access) {
                      const updateSingleAbsentee = async () => {
                        const response = await createOrUpdateAttendance([{ profile: modalAbsentee.profile, absent_reason: modalText, date: modalAbsentee.date }], currGroup.id, access)
                        if (response?.data.length == 1 && response.status == 200) {
                          openSnack("Child Attendance Updated!", true)
                          console.log(response.data[0].absent_reason)
                          setModalText(response.data[0].absent_reason)
                        }
                        else if (response?.status != 200) {
                          openSnack("Error setting Attendance", false)
                        }
                        setModalAbsentee(null)
                      }
                      updateSingleAbsentee()


                    }
                  }
                  // console.log(modalAbsentee)


                }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};


export default AttendanceDetailModal;




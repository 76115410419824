import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/store";
import { API_Edit_Activity, API_Edit_Lesson, API_Edit_Project } from "types/project";
import Button from "../Button/Button";

const KeepChangesDialog = ({
  showChangesDialog,
  cancelNavigation,
  confirmNavigation,
  project,
  lesson,
  activity,
}: {
  showChangesDialog: any;
  cancelNavigation: any;
  confirmNavigation: any;
  project: API_Edit_Project,
  lesson: API_Edit_Lesson,
  activity: API_Edit_Activity
}) => {

  const { setBringOverProject, setBringOverLesson, setBringOverActivity } = useStore((state) => state)

  return (
    <Box>
      <Dialog
        open={showChangesDialog}
        onClose={cancelNavigation}
        aria-labelledby="block-dialog-title"
        aria-describedby="block-dialog-description"
      >
        <DialogTitle id="block-dialog-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="block-dialog-description">
            There are unsaved changes. Do you want bring over to the new edit page?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "20px" }}>
          <Button
            buttonText="Cancel"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "var(--txt-secondary)",
              padding: "0",
              marginRight: "auto",
            }}
            onClick={cancelNavigation}
          />

          <Button
            buttonText="Discard"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "transparent",
              color: "var(--txt-secondary)",
              padding: "0",
              marginRight: "20px",
            }}
            onClick={() => {
              setBringOverProject({});
              setBringOverLesson({});
              confirmNavigation();
            }}
          />

          <Button
            buttonText="Bring them over"
            arrow={false}
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
            }}
            onClick={() => {
              setBringOverProject(project);
              setBringOverLesson(lesson);
              setBringOverActivity(activity);
              confirmNavigation();
            }}
          />

        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default KeepChangesDialog;
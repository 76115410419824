import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { translation } from "constants/translation";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { API_Project } from "types/project";
import { getChildProjects } from "api/project-api";
import useStore from "store/store";
import DetailCardSimplified from "./DetailCardSimplified";
import ProjectModal from "components/ProjectModal/ProjectModal";

import NowLearningBackground from "./NowLearningComponents/NowLearningBackground";
import Image from "components/Image/Image";
import UnderlineHighlight from "images/dashboard/underline-highlight.png";
import Slider from "react-slick";
import "./NowLearningComponents/nowLearning.css";

export const setResponsiveness = (
  setSlidesToShow: React.Dispatch<React.SetStateAction<number>>
) => {
  if (window.innerWidth <= 400) {
    setSlidesToShow(1);
  } else if (window.innerWidth <= 680) {
    setSlidesToShow(1);
  } else if (window.innerWidth <= 900) {
    setSlidesToShow(2);
  } else if (window.innerWidth <= 1199) {
    setSlidesToShow(3);
  } else if (window.innerWidth <= 1450) {
    setSlidesToShow(2);
  } else if (window.innerWidth <= 1600) {
    setSlidesToShow(3);
  } else if (window.innerWidth <= 1800) {
    setSlidesToShow(4);
  } else if (window.innerWidth <= 2000) {
    setSlidesToShow(4);
  } else {
    setSlidesToShow(4);
  }
};

const NowLearningSection = ({ childId }: { childId: number }) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  const [projects, setProjects] = useState<API_Project[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<number>(-1);

  useEffect(() => {
    if (childId === -1) return;

    const initializeChildProjects = async () => {
      setIsLoading(true);

      const res = await getChildProjects(
        childId,
        currLanguage,
        localStorage.getItem("access")!
      );
      setProjects(res);
      setIsLoading(false);
    };

    initializeChildProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childId]);

  // console.log("projects: ", projects);
  const handleOpen = (projectId: number) => {
    setOpen(true);
    setSelectedProjectId(projectId);
  };

  const [justifyContent, setJustifyContent] = useState("center");

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setJustifyContentFunction = () => {
      if (containerRef.current) {
        // Check if there's overflow
        const isOverflowing =
          containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setJustifyContent(isOverflowing ? "flex-start" : "center");
      }
    };
    window.addEventListener("resize", () => setJustifyContentFunction());

    return () => {
      window.removeEventListener("resize", () => setJustifyContentFunction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // re-run effect when 'projects' changes

  // slick
  const [slidesToShow, setSlidesToShow] = useState(6);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  useEffect(() => {
    setResponsiveness(setSlidesToShow);
    window.addEventListener("resize", () => setResponsiveness(setSlidesToShow));

    return () => {
      window.removeEventListener("resize", () =>
        setResponsiveness(setSlidesToShow)
      );
    };
  }, []);

  // console.log("justifyContent: now learning:", justifyContent);

  return (
    <Box
      sx={{
        // backgroundColor: "white",
        // border: "1px solid #C4C4C4",
        borderRadius: "10px",
        p: 3,
        paddingBottom: 4,
        position: "relative",
      }}
    >
      <NowLearningBackground />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Box
          sx={{
            p: 1,
            px: 4,
            paddingBottom: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 24,
              }}
            >
              {translation.nowLearning}...
            </Typography>
            <Image
              src={UnderlineHighlight}
              alt={"underline-highlight"}
              style={{ width: 175, marginTop: "-4px" }}
            />
          </Box>

          {projects.length === 0 ? (
            <Typography>{translation.noProject}</Typography>
          ) : projects.length <= slidesToShow ? (
            <Box
              ref={containerRef}
              sx={{
                display: "flex",
                // flexWrap: "wrap",
                p: 1,
                gap: 7,
                justifyContent,
                overflowX: "auto",
              }}
            >
              {projects.map((project, index) => (
                <DetailCardSimplified
                  key={`$detail-card-simplified-${index}-${project.id}`}
                  project={project}
                  onClick={() => handleOpen(project.id)}
                />
              ))}
            </Box>
          ) : (
            <Slider {...settings} className="nowLearningSectionSlider">
              {projects.map((project, index) => (
                <DetailCardSimplified
                  key={`$detail-card-simplified-${index}-${project.id}`}
                  project={project}
                  onClick={() => handleOpen(project.id)}
                />
              ))}
            </Slider>
          )}

          {open && selectedProjectId && (
            <ProjectModal
              open={open}
              setOpen={setOpen}
              projectId={selectedProjectId}
              setProjectId={setSelectedProjectId}
              isParentView={true}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default NowLearningSection;

import React from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, SxProps, Typography, Checkbox } from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_Teacher_Module_Manage } from "types/teacher";
import {
  CURLANG,
  EDIT_TEACHER_MODULE_PAGE,
  TEACHER_MODULE_PAGE,
} from "constants/url";
import { translation } from "constants/translation";
import { editTeacherModule } from "api/teacher-api";

const ManageModuleListTable = ({
  moduleList,
  setModuleList,
  setSelected,
}: {
  moduleList: API_Teacher_Module_Manage[];
  setModuleList: React.Dispatch<
    React.SetStateAction<API_Teacher_Module_Manage[]>
  >;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const openSnack = useSnack();

  const { currLanguage, role } = useStore((state) => ({
    currLanguage: state.currLanguage,
    role: state.profileDetails.role,
  }));

  const handlePublish = async (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    module: API_Teacher_Module_Manage
  ) => {
    e.stopPropagation();

    const res = await editTeacherModule(currLanguage, {
      id: module.id,
      is_published: !module.is_published,
    });

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const updatedModules = moduleList.map((project_) => {
        if (project_.id === module.id) {
          return {
            ...project_,
            is_published: !module.is_published,
          };
        }
        return project_;
      });
      setModuleList(updatedModules);
      module.is_published
        ? openSnack(`Unpublished ${module.name}!`, true)
        : openSnack(`Published ${module.name}!`, true);
    }
  };

  const iconStyles: SxProps = {
    color: "#312F30B2",
    cursor: "pointer",
  };

  const columns: GridColumns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Link
          style={{
            color: "#407374",
          }}
          to={`${CURLANG(currLanguage)}/${TEACHER_MODULE_PAGE(
            params.row.module.slug
          )}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "lesson_no",
      headerName: "# of Lessons",
      type: "number",
      // maxWidth: 200,
      // minWidth: 95,
    },
    {
      field: "is_published",
      headerName: "Published",
      sortable: false,
      maxWidth: 100,
      renderCell: (params) =>
        params.value ? (
          <CheckBoxOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyles, color: "black" }}
            onClick={(e) => handlePublish(e, params.row.module)}
          />
        ) : (
          <CheckBoxOutlineBlankOutlinedIcon
            fontSize="small"
            sx={iconStyles}
            onClick={(e) => handlePublish(e, params.row.module)}
          />
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              to={`${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(
                params.row.module.slug
              )}`}
            >
              <EditIcon fontSize="small" sx={iconStyles} />
            </Link>

            {/* <AssessmentOutlinedIcon
              fontSize="small"
              sx={{ ...iconStyles, opacity: 0.5, pointerEvents: "none" }}
            /> */}
          </Box>
        );
      },
    },
  ];

  const rows =
    moduleList.length === 0
      ? []
      : moduleList.map((module) => ({
          module,
          id: module.id,
          title: module.name,
          lesson_no: module.lesson_no,
          is_published: module.is_published,
        }));
  // console.log("rows: ", rows);
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      // columnVisibilityModel={{
      //   access: currSchool.id !== -1, //show access if school exist
      //   published: currSchool.id === -1, //show publish if school doesn't exist
      // }}
      disableColumnMenu
      checkboxSelection
      components={{
        BaseCheckbox(props) {
          return (
            <Checkbox
              sx={{
                "&:hover": {
                  backgroundColor: "transparent !important",
                },
              }}
              icon={
                <CheckBoxOutlineBlankOutlinedIcon
                  fontSize="small"
                  sx={iconStyles}
                />
              }
              checkedIcon={
                <CheckBoxOutlinedIcon
                  fontSize="small"
                  sx={{ ...iconStyles, color: "black" }}
                />
              }
              indeterminateIcon={
                <IndeterminateCheckBoxOutlinedIcon
                  fontSize="small"
                  sx={iconStyles}
                />
              }
              disableRipple
              {...props}
            />
          );
        },
        NoRowsOverlay: NoModuleFound,
      }}
      loading={false}
      sx={{
        border: "none",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: 0,
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
      }}
      onSelectionModelChange={(ids) => {
        const selectedIds = ids.map((id) => id.toString());
        setSelected(selectedIds);
      }}
    />
  );
};

const NoModuleFound = () => (
  <Typography
    sx={{
      fontWeight: "bold",
      fontSize: "1.25rem",
      textAlign: "center",
      backgroundColor: "#FBFBFB",
      p: 2,
    }}
  >
    {translation.noModuleFound}
  </Typography>
);

export default ManageModuleListTable;

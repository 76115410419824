import Image from "components/Image/Image";
import LearningHighlightPageBackground from "images/children-portfolio-v2/learning-highlights-background.png";
import Page from "./Page";

const LearningHighlightPage = () => {
  return (
    <Page>
      <Image src={LearningHighlightPageBackground} alt="" />
    </Page>
  );
};

export default LearningHighlightPage;

import { useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { translation } from "constants/translation";
import {
  Container,
  Title,
  ProgressBar,
  BottomNavigation,
  Answer,
} from "../Components";
import { Age_Group } from "types/project";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { updateGroupLevel } from "api/prediscoveryquestionnaire-api";

const ChildCommunicationMethod = ({
  pageNumber,
  setCurrentPage,
  answer,
  setAnswer,
  levelId,
  setLevelId,
}: {
  pageNumber: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  answer: Answer;
  setAnswer: React.Dispatch<React.SetStateAction<Answer>>;
  levelId: number;
  setLevelId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const CHOICES: {
    id: number;
    name: string;
    level: Age_Group;
  }[] = [
    {
      id: 1,
      name: translation.levelAnswer1,
      level: "Sprouts",
    },
    {
      id: 2,
      name: translation.levelAnswer2,
      level: "Sprouts",
    },
    {
      id: 3,
      name: translation.levelAnswer3,
      level: "Sproutlings",
    },
    {
      id: 4,
      name: translation.levelAnswer4,
      level: "Buds",
    },
    {
      id: 5,
      name: translation.levelAnswer5,
      level: "Blossoms",
    },
  ];

  const openSnack = useSnack();

  const { currGroup } = useStore((state) => ({
    currGroup: state.currGroup,
  }));

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const res = await updateGroupLevel({
      groupId: currGroup.id,
      level: answer.level,
    });

    if (typeof res === "string" && res !== "Success!") {
      openSnack(res, false);
    } else {
      setCurrentPage(pageNumber + 1);
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <Title
        title={translation.myChildCommunicationMethod}
        subTitle={translation.myChildCommunicationMethodSub}
      />

      <ProgressBar pageNumber={pageNumber} />

      <RadioGroup
        sx={{
          flexWrap: "nowrap",
          gap: 1,
        }}
      >
        {CHOICES.map((choice, index) => (
          <FormControlLabel
            key={index}
            control={
              <Radio
                size="small"
                checked={choice.id === levelId}
                sx={{ padding: 0 }}
              />
            }
            value={index}
            onChange={() => {
              setLevelId(choice.id);
              setAnswer((prev) => ({ ...prev, level: choice.level }));
            }}
            label={
              <Typography
                sx={{
                  lineHeight: 1.25,
                }}
              >
                {choice.name}
              </Typography>
            }
            sx={{
              border: "2px solid",
              borderColor: choice.id === levelId ? "#98C4C5" : "#F0F0F0",
              borderRadius: "10px",
              backgroundColor:
                choice.id === levelId
                  ? "rgba(209, 228, 227, 0.37)"
                  : "transparent",
              mx: "auto",
              p: 2,
              alignItems: "flex-start",
              gap: 1.5,
              width: "100%",
              maxWidth: "500px",
            }}
          />
        ))}
      </RadioGroup>

      <BottomNavigation
        pageNumber={pageNumber}
        setCurrentPage={setCurrentPage}
        disabled={answer.level === "" || levelId === -1}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default ChildCommunicationMethod;

import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";

import useSnack from "../../../../../../hooks/useSnack";
import useStore from "../../../../../../store/store";
import Button from "../../../../../../components/Button/Button";
import { translation } from "../../../../../../constants/translation";
import {
  NameField,
  PublishField,
  DescriptionField,
  DescriptionFieldNormal,
} from "../../TeacherFormComponents/ActivityFormComponents";
import {
  API_Create_Teacher_Activity,
  API_Drag_And_Drop_Answer_Pair,
} from "../../../../../../types/teacher";
import { RESOURCE_TYPE_DRAG_AND_DROP } from "../../../teacherPageConstants";
import { createTeacherActivity } from "../../../../../../api/teacher-api";
import { DragAndDropField } from "../../TeacherFormComponents/ActivityFormComponents/DragAndDropActivityFormComponent";

const CreateDragAndDropActivityForm = ({
  lessonId,
  handleClose,
}: {
  lessonId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();

  const [isCreatingActivity, setIsCreatingActivity] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [dragAndDropAnswerPairs, setDragAndDropAnswerPairs] = useState<
    API_Drag_And_Drop_Answer_Pair[]
  >([]);

  const { currLanguage, teacherCurrentLesson, setIsRefreshTeacherModule } =
    useStore((state) => ({
      currLanguage: state.currLanguage,
      teacherCurrentLesson: state.teacherCurrentLesson,
      setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
    }));
  const [updatedActivity, setUpdatedActivity] =
    useState<API_Create_Teacher_Activity>({
      lesson: lessonId,
      resourcetype: RESOURCE_TYPE_DRAG_AND_DROP,
      name: "",
      description: "",
      is_published: false,
    });

  const activityRef = useRef(updatedActivity);
  activityRef.current = updatedActivity;
  // console.log("video: ", video);

  const submitForm = async () => {
    if (updatedActivity.name === "" || dragAndDropAnswerPairs.length === 0) {
      openSnack("Name and Answer Pair content is required!", false);
      return;
    }

    const submitData: API_Create_Teacher_Activity = {
      lesson: lessonId,
      resourcetype: updatedActivity.resourcetype,
      name: updatedActivity.name,
      description: updatedActivity.description,
      is_published: updatedActivity.is_published,
    };

    submitData.drag_and_drop_answer_pairs = dragAndDropAnswerPairs;

    const res = await createTeacherActivity(
      currLanguage,
      submitData,
      setIsCreatingActivity,
      setProgressPercentage,
      localStorage.getItem("access")!
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setIsRefreshTeacherModule(true);
      openSnack("Activity created successfully!", true);
      handleClose();
    }
  };

  return (
    <Stack
      component="form"
      sx={{
        width: "100%",
        mb: 8,
      }}
      spacing={3}
      noValidate
      autoComplete="off"
    >
      <PublishField
        activity={updatedActivity}
        setActivity={setUpdatedActivity}
      />
      <NameField activity={updatedActivity} setActivity={setUpdatedActivity} />

      <DescriptionFieldNormal
        label={translation.dragAndDropDescription}
        activity={updatedActivity}
        setActivity={setUpdatedActivity}
      />

      <DragAndDropField
        dragAndDropAnswerPairs={dragAndDropAnswerPairs}
        setDragAndDropAnswerPairs={setDragAndDropAnswerPairs}
      />

      <Box
        sx={{
          display: "flex",
          gap: "24px",
          position: "fixed",
          bottom: 24,
          right: 34,
          zIndex: 10,
        }}
      >
        <Button
          buttonText={
            isCreatingActivity
              ? progressPercentage !== 100
                ? `${progressPercentage}%`
                : translation.uploadFinalizing
              : translation.create
          }
          style={{
            fontSize: "14px",
            backgroundColor: "var(--txt-secondary)",
            height: "auto",
          }}
          onClick={() => submitForm()}
        />
      </Box>
    </Stack>
  );
};

export default CreateDragAndDropActivityForm;

import { translation } from "constants/translation";
import { slugify } from "helper/slugify";
import { PROGRESS_REPORT } from "containers/ChildrenPortfolioPage/constants";
import { COMPLETED_WORKS, PHOTO_ALBUM } from "containers/ProjectSummaryPage/constant";

const PROJECT_SUMMARY = "project-summary";

export const teacherArchiveTabs: string[] = [
  PROGRESS_REPORT,
  slugify(COMPLETED_WORKS),
  slugify(PHOTO_ALBUM),
  PROJECT_SUMMARY,
];

export const teacherArchiveTranslatedTabs: { [key: string]: string } = {
  [PROGRESS_REPORT]: translation.progressReport,
  [slugify(COMPLETED_WORKS)]: translation.completedWorks,
  [slugify(PHOTO_ALBUM)]: translation.photoAlbum,
  [PROJECT_SUMMARY]: translation.projectSummary,
};

export const BY_CLASS = "by-class";
export const BY_YEAR = "by-year";
export const BY_PROJECT = "by-project";

export const allCategoryTabs = [BY_CLASS, BY_YEAR, BY_PROJECT];

export const translatedCategoryTabs: { [key: string]: string } = {
  [BY_CLASS]: translation.byClass,
  [BY_YEAR]: translation.byYear,
  [BY_PROJECT]: translation.byProject,
};

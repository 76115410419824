import { Box } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { CURLANG, PROJECT_SUMMARY_PAGE } from "constants/url";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";

const NavigateProjectComponent = ({
  projectSlug,
  inModal = false,
}: {
  projectSlug: string;
  inModal?: boolean;
}) => {
  const navigate = useNavigate();
  const { currLanguage, setRefreshProjAndLess } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setRefreshProjAndLess: state.setRefreshProjAndLess,
  }));
  return (
    <Box
      className="nodrag"
      sx={{
        position: inModal ? "" : "absolute",
        display: inModal ? "flex" : "",
        top: 0,
        right: 25,
        cursor: "pointer",
        height: "18px",
        width: "18px",
      }}
      onClick={() => {
        setRefreshProjAndLess(true);
        navigate(
          `${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(projectSlug)}`
        );
      }}
    >
      <OpenInNewIcon sx={{ fontSize: "18px", color: "var(--txt-secondary)" }} />
    </Box>
  );
};

export default NavigateProjectComponent;

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import useStore from "store/store";
import {
  API_Project_Access_Control,
  API_Project_Analytic,
  Query_Data,
} from "types/project";
import { getAllSchoolWithStripeInfo } from "api/school-api";
import {
  getProjectBySchoolID,
  getFilteredProjectList,
} from "api/access-api";
import { useNavigate, useParams } from "react-router-dom";
import {
  PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG,
  CURLANG,
} from "constants/url";

import { API_School } from "types/school";
import MiniDrawer from "./MiniDrawer";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import SubscriptionControlBar from "./SubscriptionControlBar";
import ProjectsTable from "./ProjectsTable";
import { ViewMode } from "types/teacher";
import { ACCESS_CONTROL, INSIGHTS } from "constants/staticConstant";
import ProjectsAnalyticTable from "./ProjectsAnalyticTable";
import IsDemoSchoolControlBar from "./IsDemoSchoolControlBar";

export const overviewSchool = {
  id: -1,
  name: "Overview",
  slug: "overview",
  is_bypass_payment: true,
  is_demo_school: false,
};

const ManageSchoolProjectPage = () => {
  const { languageSlug, schoolSlug, pageModeSlug } = useParams();
  const navigate = useNavigate();

  const { currLanguage, schools, setSchools, role } = useStore((state) => ({
    // projects: state.projects,
    // setProjects: state.setProjects,
    currLanguage: state.currLanguage,
    schools: state.schools,
    setSchools: state.setSchools,
    role: state.profileDetails.role,
  }));

  const isWebAdmin = role === "WAD";

  const [pageMode, setPageMode] = useState<ViewMode>(
    pageModeSlug ? (pageModeSlug as ViewMode) : (ACCESS_CONTROL as ViewMode)
  );
  const [projects, setProjects] = useState<
    API_Project_Access_Control[] | API_Project_Analytic[]
  >([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [queryData, setQueryData] = useState<Query_Data>({
    query: null,
    startDate: null,
    endDate: null,
  });

  // moment(new Date()).format("YYYY-MM-DD").toString()

  const [accessList, setAccessList] = useState<{ [projectID: string]: number }>(
    {}
  ); //projectID: access object ID
  const [currSchool, setCurrSchool] = useState<API_School>(overviewSchool);
  const [isBypassPayment, setIsBypassPayment] = useState<boolean>(false);
  const [isDemoSchool, setIsDemoSchool] = useState<boolean>(false);

  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (!isWebAdmin) return;
    const populateSchools = async () => {
      const schoolStripeRes = await getAllSchoolWithStripeInfo();
      setSchools(schoolStripeRes);
    };
    populateSchools();
  }, [languageSlug, isWebAdmin]);

  useEffect(() => {
    if (!isWebAdmin) return;

    if (schoolSlug === undefined) {
      navigate(
        `${CURLANG(currLanguage)}/${PROJECT_SCHOOL_MANAGE_PAGE_WITH_SLUG(
          currSchool.slug
        )}/${ACCESS_CONTROL}`
      );
    } else if (schools.length > 0 && schoolSlug) {
      const index = schools.findIndex((school) => school.slug === schoolSlug);
      const currSchoolTemp = schools.filter(
        (school) => school.slug === schoolSlug
      );
      if (currSchoolTemp.length > 0) {
        setCurrSchool(currSchoolTemp[0]);
      } else {
        setCurrSchool(overviewSchool);
      }

      if (index === -1) {
        setIsBypassPayment(false);
        setIsDemoSchool(false);
      } else {
        setIsBypassPayment(schools[index].is_bypass_payment);
        setIsDemoSchool(schools[index].is_demo_school);
      }
    }
  }, [schoolSlug, isWebAdmin, schools]);

  useEffect(() => {
    pageModeSlug && setPageMode(pageModeSlug as ViewMode);
  }, [pageModeSlug]);

  const updateData = async () => {
    setIsLoaded(false);

    if (isWebAdmin) {
      const getAccess = async () => {
        if (currSchool.id !== -1) {
          setAccessList(
            await getProjectBySchoolID(
              currSchool.id,
              localStorage.getItem("access")
            )
          );
        }
      };
      getAccess();
    }

    const updatedProj: API_Project_Access_Control[] | API_Project_Analytic[] =
      await getFilteredProjectList({
        pageMode: pageMode,
        language: currLanguage,
        schoolID: currSchool.id,
        queryData: queryData,
        recommendationFilters: "",
        moduleFilters: "",
        accessToken: localStorage.getItem("access"),
      });

    setProjects(updatedProj);
    setIsLoaded(true);
  };

  useEffect(() => {
    updateData();
  }, [
    pageMode,
    currSchool,
    isWebAdmin,
    queryData.startDate,
    queryData.endDate,
  ]);

  useEffect(() => {
    if (queryData.query === null) return;

    const searchTimeout = setTimeout(async () => {
      if (queryData.query !== "") {
        setIsLoaded(false);
      }
      updateData();
    }, 1200);

    return () => clearTimeout(searchTimeout);
  }, [queryData.query]);
  // console.log("projects: ", projects);
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      {isWebAdmin && (
        <MiniDrawer
          schools={schools}
          currSchool={currSchool}
          setQueryData={setQueryData}
          setSelected={setSelected}
        />
      )}

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          px: 3,
          py: 4,
          mx: "60px",
        }}
      >
        {/* Subscription */}
        <FirstRow
          pageMode={pageMode}
          setPageMode={setPageMode}
          currSchool={currSchool}
        >
          {isWebAdmin && currSchool.id !== -1 && (
            <>
              <IsDemoSchoolControlBar
                schools={schools}
                setSchools={setSchools}
                currSchool={currSchool}
                isDemoSchool={isDemoSchool}
                setIsDemoSchool={setIsDemoSchool}
              />
              <SubscriptionControlBar
                schools={schools}
                setSchools={setSchools}
                currSchool={currSchool}
                isBypassPayment={isBypassPayment}
                setIsBypassPayment={setIsBypassPayment}
              />
            </>
          )}
        </FirstRow>

        {/* Bulk action */}

        <SecondRow
          pageMode={pageMode}
          projects={projects as API_Project_Access_Control[]}
          setProjects={
            setProjects as React.Dispatch<
              React.SetStateAction<API_Project_Access_Control[]>
            >
          }
          queryData={queryData}
          setQueryData={setQueryData}
          currSchool={currSchool}
          accessList={accessList}
          setAccessList={setAccessList}
          selected={selected}
        />

        {!isLoaded ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingIndicator />
          </Box>
        ) : pageMode === (ACCESS_CONTROL as ViewMode) ? (
          <ProjectsTable
            projects={projects as API_Project_Access_Control[]}
            setProjects={
              setProjects as React.Dispatch<
                React.SetStateAction<API_Project_Access_Control[]>
              >
            }
            currSchool={currSchool}
            accessList={accessList}
            setAccessList={setAccessList}
            setSelected={setSelected}
          />
        ) : pageMode === (INSIGHTS as ViewMode) ? (
          <ProjectsAnalyticTable
            currSchool={currSchool}
            projects={projects as API_Project_Analytic[]}
            setProjects={
              setProjects as React.Dispatch<
                React.SetStateAction<API_Project_Analytic[]>
              >
            }
            setSelected={setSelected}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default ManageSchoolProjectPage;

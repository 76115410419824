import React, { Fragment, useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import "components/ProjectSummaryScreen/ReactGridGallery.css";
import { translation } from "constants/translation";
import {
  API_Lesson_Learning_Moment,
  API_Project_Lesson,
} from "types/project";
import LearningMomentScreenActivity from "./LearningMomentScreenActivity";
import useStore from "store/store";
import { getIsLessonLearningMomentDone } from "api/lesson-activity-api";

import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  titleFontSize,
  titleMobileFontSize,
} from "constants/constantInGeneral";

const LearningMomentLessonScreen = ({
  lesson,
  lessonIndex,
}: {
  lesson: API_Project_Lesson;
  lessonIndex: number;
}) => {
  const { currLanguage, currGroup, currScheduleObj } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currGroup: state.currGroup,
    currScheduleObj: state.currScheduleObj,
  }));
  const isShowActivityTitle = () => {
    let activityWithLearningMomentList: number[] = [];
    lesson.activities.forEach((activity) => {
      activity.instructions.forEach((instruction) => {
        if (instruction.learning_moment) {
          activityWithLearningMomentList = [
            ...activityWithLearningMomentList,
            activity.id,
          ];
        }
      });
    });
    const learningMomentNumber = new Set(activityWithLearningMomentList).size;
    return learningMomentNumber > 1;
  };

  const iconStyle = {
    width: 24,
    height: 24,
  };

  const [isLessonLearningMomentDone, setIsLessonLearningMomentDone] =
    useState<boolean>(false);

  const populateIsLessonDone = async () => {
    const isLessonDoneRes = await getIsLessonLearningMomentDone(
      currLanguage,
      localStorage.getItem("access"),
      lesson.id,
      currGroup.id,
      lesson.project_id
    );
    if (typeof isLessonDoneRes !== "string") {
      setIsLessonLearningMomentDone(isLessonDoneRes);
    } else {
      console.log("Error when populating is lesson done: ", isLessonDoneRes);
    }
  };

  useEffect(() => {
    currGroup.id !== -1 && populateIsLessonDone();
  }, [currGroup.id, currScheduleObj]);

  return (
    <>
      <Accordion disableGutters>
        <AccordionSummary
          sx={{ backgroundColor: "#D8F2EE" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Box sx={{ ...iconStyle, position: "" }}>
              {isLessonLearningMomentDone ? (
                <CircleCheckedFilled
                  sx={{ width: "100%", height: "100%" }}
                  color="primary"
                />
              ) : (
                <CircleUnchecked
                  sx={{ width: "100%", height: "100%" }}
                  color="primary"
                />
              )}
            </Box>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: titleMobileFontSize, sm: titleFontSize } }}
            >
              {`${translation.formatString(translation.lessonNo, {
                number: lesson.weight,
              })}: ${lesson.name}`}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#FBFBFB" }}>
          {lesson.activities.map((activity, activityIndex) => {
            const learningMomentObjectList: API_Lesson_Learning_Moment[] = [];

            activity.instructions.forEach((instruction) => {
              if (instruction.learning_moment) {
                learningMomentObjectList.push(instruction.learning_moment);
              }
            });
            return (
              <Fragment key={`learning-moment-for-activity-${activityIndex}`}>
                {learningMomentObjectList.length > 0 ? (
                  <LearningMomentScreenActivity
                    lesson={lesson}
                    lessonIndex={lessonIndex}
                    activity={activity}
                    learningMomentObjectList={learningMomentObjectList}
                    activityIndex={activityIndex}
                    isShowActivityTitle={isShowActivityTitle()}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LearningMomentLessonScreen;

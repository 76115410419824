// import React from "react";
// import TeacherArchiveCompletedWorkScreenByYear from "./TeacherArchiveCompletedWorkScreenByYear";

const CompletedWorkByYear = () => {
  return null;
};

// const CompletedWorkByYear = () => {
//   return <TeacherArchiveCompletedWorkScreenByYear />;
// };

export default CompletedWorkByYear;

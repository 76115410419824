import React from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";

import Image from "components/Image/Image";
import Logo from "images/logo-2.png";

import RichCKEditor from "components/RichCKEditor/RichCKEditor";
import { translation } from "constants/translation";
import { API_Teacher_Module_Create } from "types/teacher";

const NameField = ({
  data,
  setData,
  onChange,
}: {
  data: API_Teacher_Module_Create;
  setData: React.Dispatch<React.SetStateAction<API_Teacher_Module_Create>>;
  onChange?: (input: string) => void;
}) => (
  <TextField
    label={translation.moduleName}
    value={data.name}
    onChange={(event) => {
      setData((prev) => ({ ...prev, name: event.target.value }));
      onChange && onChange(event.target.value);
    }}
    required
  />
);

const DescriptionField = ({
  data,
  setData,
  onChange,
}: {
  data: API_Teacher_Module_Create;
  setData: React.Dispatch<React.SetStateAction<API_Teacher_Module_Create>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>
      {translation.moduleDescription}
      {/* <Typography component="span" sx={{ color: "red", ml: "2px" }}>
        *
      </Typography> */}
    </Typography>

    <RichCKEditor
      data={data.description}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setData((prev) => ({ ...prev, description: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

// Image
const ImageField = ({
  image,
  setImage,
}: {
  image: File | string;
  setImage: React.Dispatch<React.SetStateAction<File | string>>;
}) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
    <Typography>{translation.image}</Typography>

    <input
      type="file"
      accept="image/*"
      id="image-button-image"
      hidden
      onChange={(event) => {
        const newImage: File = event.target.files![0];
        setImage(newImage);
      }}
    />

    <label htmlFor="image-button-image">
      <IconButton
        component="span"
        sx={{
          borderRadius: "8px",
          backgroundColor: "primary.light",
          "&:hover": {
            backgroundColor: "primary.main",
          },
        }}
      >
        <ImageIcon sx={{ color: "txt.secondary" }} />
      </IconButton>
    </label>
    {image && (
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 100,
            height: 100,
            borderRadius: "10px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Image
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt={Logo}
          />
          <ClearIcon
            sx={{
              position: "absolute",
              top: 5,
              right: 5,
              color: "txt.secondary",
              cursor: "pointer",
              backgroundColor: "primary.light",
              borderRadius: "50%",
              p: "3px",
              transition: "all 100ms linear",
              "&:hover": {
                p: "4px",
                backgroundColor: "primary.main",
                transition: "all 100ms linear",
              },
            }}
            onClick={() => {
              setImage("");
            }}
          />
        </Box>
      </Box>
    )}
  </Box>
);

const PublishField = ({
  data,
  setData,
  onChange,
}: {
  data: API_Teacher_Module_Create;
  setData: React.Dispatch<React.SetStateAction<API_Teacher_Module_Create>>;
  onChange?: (input: boolean) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>{translation.publish}</Typography>

    {data.is_published ? (
      <CheckBoxIcon
        sx={{ cursor: "pointer", color: "txt.secondary" }}
        onClick={() => {
          setData((prev: any) => ({
            ...prev,
            is_published: !prev.is_published,
          }));

          onChange && onChange(false);
        }}
      />
    ) : (
      <CheckBoxOutlineBlankIcon
        sx={{ cursor: "pointer", color: "txt.secondary" }}
        onClick={() => {
          setData((prev: any) => ({
            ...prev,
            is_published: !prev.is_published,
          }));

          onChange && onChange(true);
        }}
      />
    )}
  </Box>
);

export { NameField, DescriptionField, PublishField, ImageField };

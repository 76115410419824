import React from "react";

import {
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";


import useStore from "store/store";
import useSnack from "hooks/useSnack";
import useDialog from "hooks/useDialog";

import {
  deleteImages,
  deleteVideos,
} from "api/gallery-api";


import WarningDialog from "components/Dialog/WarningDialog";

import "components/ProjectSummaryScreen/ReactGridGallery.css";

import { translation } from "constants/translation";

import {
  isProfileInGalleryAccessRoleList,
} from "helper/helper";

// Pop out when click on image or video.

type Props = {
  lessonName: string;
  selectedPhotoVideoIDs: string[];
  setSelectedPhotoVideoIDs: React.Dispatch<React.SetStateAction<string[]>>;
  initializeGallery: () => Promise<void>;
  handleClose: () => void;
}
const PhotoAndVideoListHeader = ({
  lessonName,
  selectedPhotoVideoIDs,
  setSelectedPhotoVideoIDs,
  initializeGallery,
  handleClose,
}: Props) => {
  const {
    currLanguage,
    setIsRefreshLearningMomentPhotoVideoGallery,
    setIsRefreshLearningMomentPhoto,
    role
  } = useStore((state) => ({
    ...state,
    role: state.profileDetails.role,
  }));

  const openSnack = useSnack();


  const handleDeletePhotoVideo = async (
    photoIds: number[],
    videoIds: number[]
  ) => {
    let isVideoDeleted = false;
    let isPhotoDeleted = false;
    let isError = false;
    let errorMessage = "";

    if (videoIds.length > 0) {
      const videoRes = await deleteVideos(currLanguage, videoIds);
      if (typeof videoRes === "string") {
        isError = true;
        errorMessage += videoRes;
      } else {
        isVideoDeleted = true;
      }
    }

    if (photoIds.length > 0) {
      const photoRes = await deleteImages(currLanguage, photoIds);
      if (typeof photoRes === "string") {
        isError = true;
        errorMessage += photoRes;
      } else {
        isPhotoDeleted = true;
      }
    }

    if (isError) {
      openSnack(errorMessage, false);
    } else {
      openSnack(
        isVideoDeleted && isPhotoDeleted
          ? translation.success_delete_photo_video
          : isVideoDeleted
          ? translation.success_delete_video
          : translation.success_delete_image,
        true
      );
      handleClose();
      initializeGallery();
      setIsRefreshLearningMomentPhotoVideoGallery(true);
      setIsRefreshLearningMomentPhoto(true);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle:
      translation.formatString(translation.delete_photo_video, {
        number: selectedPhotoVideoIDs.length,
      }) + "?",
    warningContext: translation.irreversibleAction,
    handleDelete: () => {
      const selectedPhotoIds = selectedPhotoVideoIDs.filter(
        (photoVideoID) => photoVideoID.split("-")[0] === "photo"
      );
      const selectedVideoIds = selectedPhotoVideoIDs.filter(
        (photoVideoID) => photoVideoID.split("-")[0] === "video"
      );

      handleDeletePhotoVideo(
        selectedPhotoIds.map((selectedPhotoId) =>
          Number(selectedPhotoId.split("-")[1])
        ),
        selectedVideoIds.map((selectedVideoId) =>
          Number(selectedVideoId.split("-")[1])
        )
      );
    },
  };
  
  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "8px",
          flexDirection: { xs: "column", sm: "row" }
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          {lessonName ?? translation.allPhotosVideos}
        </Typography>
        {isProfileInGalleryAccessRoleList(role) &&
          selectedPhotoVideoIDs.length !== 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              mr: "12px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "4px",
                cursor: "pointer",
              }}
              onClick={() => setSelectedPhotoVideoIDs([])}
            >
              <CloseIcon
                fontSize="small"
                sx={{
                  mt: "-2px",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              />
                {selectedPhotoVideoIDs.length + " " + translation.selected}
            </Typography>
            <Tooltip
              title={translation.formatString(translation.delete_photo_video, {
                number: selectedPhotoVideoIDs.length,
              })}
            >
              <DeleteIcon
                onClick={handleOpenDialog}
                fontSize="small"
                sx={{
                  mt: "-2px",
                  cursor: "pointer",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              />
            </Tooltip>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PhotoAndVideoListHeader;
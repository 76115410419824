import Image from "components/Image/Image";
import DevelopmentalGoalsAndMilestonesPageBackground from "images/children-portfolio-v2/developmental-goals-and-milestone-background.png";
import Page from "./Page";

const DevelopmentalGoalsAndMilestonesPage = () => {
  return (
    <Page>
      <Image src={DevelopmentalGoalsAndMilestonesPageBackground} alt="" />
    </Page>
  );
};

export default DevelopmentalGoalsAndMilestonesPage;

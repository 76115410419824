import axios from "api/axiosConfig";

import {
  API_Caption,
  API_Gallery,
  API_Gallery_Create,
  API_Gallery_Image,
  API_Gallery_Image_Create,
  API_Update_Caption,
  API_Update_Photo,
  API_Gallery_Video_Create,
  API_Gallery_Video,
  API_Update_Video,
} from "types/gallery";

import { AxiosError } from "axios";
import {
  Album,
  ImageType,
} from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import { Query_Data } from "types/project";
import { MAX_QUERY } from "containers/teacher/TeacherArchivePage/TeacherArchivePageComponent/TeacherArchiveCommonComponent/TeacherArchiveCommonComponent";

export const getGalleryByProject = async (
  // + group
  language: string,
  projectID: string,
  accessToken: string | null,
  curr_group_id: string | null
) => {
  try {
    const res = await axios.get(`/${language}/gallery/project/${projectID}?group_id=${curr_group_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Gallery[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getGalleryByLesson = async (
  // + group
  language: string,
  lessonId: string,
  accessToken: string | null,
  curr_group_id: string | null
) => {
  try {
    const res = await axios.get(`/${language}/gallery/lesson/${lessonId}?group_id=${curr_group_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Gallery[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getGalleryByGroup = async (
  // + group
  curr_group_id: string | null,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`/gallery/group/${curr_group_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Gallery[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getAllChildImages = async ({
  language,
  childId,
  queryData,
  selectedModuleSlugs,
  selectedClassSlugs,
  selectedChildIds,
  groupId,
  projectId,
  isTeacherArchive,
}: {
  language: string;
  childId?: number;
  queryData?: Query_Data;
  selectedModuleSlugs?: string[];
  selectedClassSlugs?: string[];
  selectedChildIds?: number[];
  groupId?: number;
  projectId?: number;
  isTeacherArchive?: boolean;
}) => {
  try {
    let path = `${language}/gallery/image/list?query_limit=${MAX_QUERY}`;
    if (childId) {
      path += `&child_id=${childId}`;
    }
    if (queryData && queryData.query) {
      path += `&search=${queryData.query}`;
    }
    if (queryData && queryData.startDate) {
      path += `&start_date=${queryData.startDate}`;
    }
    if (queryData && queryData.endDate) {
      path += `&end_date=${queryData.endDate}`;
    }
    if (selectedModuleSlugs) {
      path += `&module_slug=${selectedModuleSlugs.length > 0 ? selectedModuleSlugs.join(",") : "none"}`;
    }
    if (selectedClassSlugs) {
      path += `&class_slug=${selectedClassSlugs.length > 0 ? selectedClassSlugs.join(",") : "none"}`;
    }
    if (selectedChildIds) {
      path += `&child_id_list=${selectedChildIds.length > 0 ? selectedChildIds.join(",") : "none"}`;
    }
    if (groupId) {
      path += `&group_id=${groupId}`;
    }
    if (projectId) {
      path += `&project_id=${projectId}`;
    }
    if (isTeacherArchive) {
      path += `&is_teacher_archive=true`;
    }
    const res = await axios.get(path);
    const data: ImageType[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getChildImages = async (
  // + group
  childId: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`/gallery/image/profile/${childId}/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Gallery_Image[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getLearningMomentImages = async (
  // + group
  childId: number,
  learning_moment_id: number,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`/gallery/image/learning_moment/${learning_moment_id}/${childId}/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Gallery_Image[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createGallery = async (language: string, galleryData: API_Gallery_Create) => {
  try {
    const res = await axios.post(language + "/gallery/create", galleryData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    const data: API_Gallery = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getGalleryByActivityID = async (language: string, activityID: string) => {
  try {
    const res = await axios.get(language + "/gallery/activity/" + activityID);
    const data: API_Gallery = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const updateGalleryLessonID = async (language: string, galleryData: API_Gallery_Create) => {
  try {
    const res = await axios.post(language + "/gallery/lesson/update", galleryData);
    const data: string = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//videos
export const addVideos = async (
  language: string,
  videoData: API_Gallery_Video_Create,
  setIsAddingVideo: React.Dispatch<React.SetStateAction<boolean>>,
  setProgressPercentage: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    const res = await axios.post(language + "/gallery/addvideo", videoData, {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);
        setProgressPercentage(percentage);
      },
    });
    const data: API_Gallery_Video[] = await res.data;
    setIsAddingVideo(false);
    setProgressPercentage(0);
    return data;
  } catch (error: unknown) {
    setIsAddingVideo(false);
    setProgressPercentage(0);
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editVideo = async (language: string, videoData: API_Update_Video) => {
  try {
    const res = await axios.patch(language + `/gallery/video/${videoData.id}/update`, videoData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    const data: API_Gallery_Video = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteVideos = async (language: string, videoIDs: number[]) => {
  try {
    const res = await axios.delete(language + `/gallery/video/delete`, {
      data: {
        videoIDs,
      },
    });
    const data: API_Gallery_Video = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editVideoCaption = async (language: string, videoData: API_Update_Caption) => {
  try {
    const res = await axios.patch(language + `/gallery/video/${videoData.id}/update`, videoData);
    const data: API_Gallery_Video = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

//images
export const getImageById = async (language: string, imageID: string, accessToken: string | null) => {
  try {
    const res = await axios.get(`/${language}/gallery/image/${imageID}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: API_Gallery_Image[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const addImages = async (
  language: string,
  imageData: API_Gallery_Image_Create,
  setIsAddingPhoto: React.Dispatch<React.SetStateAction<boolean>>,
  setPhotoProgressPercentage: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    console.log(imageData);
    const res = await axios.post(language + "/gallery/addimage", imageData, {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);
        setPhotoProgressPercentage(percentage);
      },
    });
    const data: API_Gallery_Image[] = await res.data;
    setIsAddingPhoto(false);
    setPhotoProgressPercentage(0);
    return data;
  } catch (error: unknown) {
    setIsAddingPhoto(false);
    setPhotoProgressPercentage(0);
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editImage = async (language: string, imageData: API_Update_Photo) => {
  try {
    const res = await axios.patch(language + `/gallery/image/${imageData.id}/update`, imageData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    const data: API_Gallery_Image = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const deleteImages = async (language: string, imageIDs: number[]) => {
  try {
    const res = await axios.delete(language + `/gallery/image/delete`, {
      data: {
        imageIDs,
      },
    });
    const data: API_Gallery_Image = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const editImageCaption = async (language: string, imageData: API_Update_Caption) => {
  try {
    const res = await axios.patch(language + `/gallery/image/${imageData.id}/update`, imageData);
    const data: API_Gallery_Image = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const createCaption = async (language: string, caption: string, gallery: string) => {
  const res = await axios.post(language + "/gallery/caption/create", {
    gallery: gallery,
    caption: caption,
  });
  const data: API_Caption = await res.data;
  return data;
};

export const editCaption = async (language: string, caption: string, captionID: string) => {
  const res = await axios.patch(language + `/gallery/caption/${captionID}/update`, {
    caption,
  });
  const data: API_Caption = await res.data;
  return data;
};

export const deleteCaption = async (language: string, captionID: string) => {
  const res = await axios.delete(language + `/gallery/caption/${captionID}/delete`);
  const data: API_Caption = await res.data;
  return data;
};

export const tagProfilesToImage = async (imageId: string, profileList: string[]) => {
  const res = await axios.post(`en/gallery/image/${imageId}/tag-to-profiles`, {
    profiles: profileList,
  });
  const data: string = await res.data;
  return data;
};

export const tagProfilesToVideo = async (videoId: string, profileList: string[]) => {
  const res = await axios.post(`en/gallery/video/${videoId}/tag-to-profiles`, {
    profiles: profileList,
  });
  const data: string = await res.data;
  return data;
};

// Album
export const getAlbum = async (childId: number, accessToken: string | null) => {
  try {
    const res = await axios.get(`/gallery/image/profile/${childId}/album`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: Album[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getProjectAlbum = async (childId: number, accessToken: string | null) => {
  try {
    const res = await axios.get(`/gallery/image/profile/${childId}/project-album`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: Album[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getImagesByModuleChild = async (
  childId: number,
  moduleId: string,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`/gallery/image/profile/${childId}/album/module/${moduleId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: Album[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getAlbumByModule = async (childId: number, moduleId: string, accessToken: string | null) => {
  try {
    const res = await axios.get(`/gallery/image/profile/${childId}/album/module/${moduleId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data: Album[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getAlbumByProject = async (
  childId: number,
  moduleId: number | string,
  projectId: number | string,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(
      `/gallery/image/profile/${childId}/album/module/${moduleId}/project/${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data: Album[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

export const getChildProjectImages = async (
  childId: number,
  projectId: number | string,
  accessToken: string | null
) => {
  try {
    const res = await axios.get(`/gallery/image/profile/${childId}/project/${projectId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // Categorized by caption
    const data: ImageType[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "Error!";
  }
};

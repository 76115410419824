import { Document, StyleSheet, Font } from "@react-pdf/renderer";

import KaiTi from "fonts/KaiTi.ttf";
import { GalleryImage } from "../ProjectSummaryScreen/ProjectSummaryScreen";
import { API_Project, API_Project_Lesson } from "types/project";

import Logo from "images/end-of-project-report/trehaus-school-logo.png";

import FrontPage from "./Templates/FrontPage";
import Overview from "./Templates/Overview";
import DevelopmentalGoals from "./Templates/DevelopmentalGoals";
import LessonPage from "./Templates/LessonPage";
import LT1Image from "./Templates/LT1Image";
import LT2Images from "./Templates/LT2Images";
import LT3Images from "./Templates/LT3Images";
import LT4Images from "./Templates/LT4Images";
import LT5Images from "./Templates/LT5Images";
import LastPage from "./Templates/LastPage";
import { PDFInfoType } from "./Preview/constant";

type HTTPMethod = "GET" | "HEAD" | "POST" | "PUT" | "DELETE" | "PATCH";

type SourceObject = {
  uri: string;
  method: HTTPMethod;
  body: any;
  headers: any;
};

const imageSrc = (src: string): SourceObject => ({
  uri: src,
  method: "GET",
  headers: { "Cache-Control": "no-cache" },
  body: "",
});

const pageSize = {
  width: 1440,
  height: 810,
};

//Register Font
Font.register({
  family: "Jost",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/jost/v14/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf",
      fontStyle: "normal",
      fontWeight: "medium",
    },
  ],
});

Font.register({
  family: "KaiTi",
  src: KaiTi,
});

const styles = StyleSheet.create({
  body: {
    fontSize: 24,
    lineHeight: 1.5,
    color: "#312F30",
    backgroundColor: "#DFEBEB",
  },

  englishFont: {
    fontFamily: "Jost",
  },

  chineseFont: {
    fontFamily: "KaiTi",
  },

  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },

  background: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    objectFit: "contain",
  },

  backgroundShifted: {
    position: "absolute",
    top: 140,
    bottom: 0,
    left: 440,
    right: 0,
    objectFit: "contain",
  },

  backgroundReverse: {
    transform: "scaleX(-1)",
  },

  pageTitle: {
    fontSize: 40,
    fontWeight: 500,
    lineHeight: 1.25,
    textAlign: "center",
  },

  textCenter: {
    textAlign: "center",
  },
});

const ProjectReportPDF = ({
  PDFInfo,
  lessons,
  currentProject,
  gallery,
  currentLanguage,
  projectTitle,
}: {
  PDFInfo?: PDFInfoType;
  lessons: API_Project_Lesson[];
  currentProject: API_Project;
  gallery: GalleryImage[];
  currentLanguage: string;
  projectTitle: string;
}) => {
  const isChinese =
    currentLanguage === "zh" || projectTitle.match(/\p{Script=Han}/gu);

  if (isChinese) {
    styles.body = { ...styles.body, ...styles.chineseFont };
  } else {
    styles.body = { ...styles.body, ...styles.englishFont };
  }

  Font.registerHyphenationCallback((word: string) => {
    if (word.length === 1 || !isChinese) {
      return [word];
    }

    // Break chinese text
    return Array.from(word)
      .map((char) => [char, ""])
      .reduce((arr, current) => {
        arr.push(...current);
        return arr;
      }, []);
  });

  const lessonsImages = lessons.map((lesson) => {
    if (lesson.images.length >= 1) {
      return lesson.images[0].file;
    } else {
      return Logo;
    }
  });

  return (
    <Document
      title={PDFInfo ? PDFInfo.name : projectTitle}
      language={currentLanguage}
    >
      <FrontPage PDFInfo={PDFInfo} project={currentProject} />

      <Overview PDFInfo={PDFInfo} project={currentProject} />

      {currentProject.fundamentals.length !== 0 && (
        <DevelopmentalGoals fundamentals={currentProject.fundamentals} />
      )}

      {PDFInfo
        ? PDFInfo.lessons.map((lesson, index) => (
            <LessonPage key={index} lesson={lesson} />
          ))
        : gallery.map((gallery_, index) => {
            if (gallery_.images.length === 0) {
              return null;
            } else if (gallery_.images.length === 1) {
              return <LT1Image key={index} gallery={gallery_} />;
            } else if (gallery_.images.length === 2) {
              return <LT2Images key={index} gallery={gallery_} />;
            } else if (gallery_.images.length === 3) {
              return <LT3Images key={index} gallery={gallery_} />;
            } else if (gallery_.images.length === 4) {
              return <LT4Images key={index} gallery={gallery_} />;
            } else {
              return <LT5Images key={index} gallery={gallery_} />;
            }
          })}

      <LastPage PDFInfo={PDFInfo} images={lessonsImages} />
    </Document>
  );
};

export { pageSize, imageSrc, styles };
export default ProjectReportPDF;

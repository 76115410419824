import { Box, Typography } from "@mui/material";
import FooterImage from "images/tta-footer.png";

const FooterSection = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
          m: { xs: "24px 16px", sm: "30px 40px", md: "40px 60px", lg: "40px 80px" },
        }}
      >
        <Box
          sx={{
            marginTop: 6,
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 18, fontWeight: 600, m: 2 }}>CONTACT US</Typography>
          <Typography sx={{ color: "#aaa" }}>Trehaus @ City Hall</Typography>
          <Typography sx={{ color: "#aaa" }}>109 North Bridge Road, #07-21 to #07-33</Typography>
          <Typography sx={{ color: "#aaa" }}>Singapore 179097 (Via Lift Lobby A)</Typography>
          <Typography sx={{ color: "#aaa" }}>Tel no.: (65) 9843 8077</Typography>
          <Typography sx={{ color: "#aaa" }}>
            Email: <>hello@trehaus.co</>
          </Typography>
          <Typography sx={{ fontSize: 18, fontWeight: 600, mt: 2, mb: 1.5 }}>How to get here</Typography>
          <Typography sx={{ color: "#aaa" }}>Train: City Hall MRT Station</Typography>
        </Box>
        <Box
          sx={{
            width: { xs: "70%", md: "50%" },
            maxWidth: { xs: "70%", md: "50%" },
            my: "48px",
          }}
        >
          <img
            src={FooterImage}
            alt="footer"
            style={{
              margin: "0 !important",
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: "8px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#EBF8F8",
        }}
      >
        <Typography sx={{ lineHeight: 1.5 }}>Copyright © Trehaus Pte Ltd. All rights reserved</Typography>
      </Box>
    </>
  );
};

export default FooterSection;

import React, { Fragment, useEffect, useState } from "react";
import { Box, TextField, Typography, Checkbox } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { translation } from "constants/translation";
import { API_Interactive_Data_Entry_Prompt } from "types/teacher";
import useDialog from "hooks/useDialog";
import { deleteInteractiveDataEntryPrompt } from "api/teacher-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import WarningDialog from "components/Dialog/WarningDialog";
import { SubtitleWithAddIcon } from "../ActivityFormComponents";
import { InteractiveDataEntryPromptSortableList } from "../../TeacherForms/ActivityForms/InteractiveDataEntryPromptDnd/InteractiveDataEntryPromptSortableList";

export const InteractiveDataEntryPromptField = ({
  activityId = -1,
  interactiveDataEntryPrompts,
  setInteractiveDataEntryPrompts,
}: {
  activityId?: number;
  interactiveDataEntryPrompts: API_Interactive_Data_Entry_Prompt[];
  setInteractiveDataEntryPrompts: React.Dispatch<
    React.SetStateAction<API_Interactive_Data_Entry_Prompt[]>
  >;
}) => {
  const handleAdd = () => {
    const updatedInteractiveDataEntryPrompts: API_Interactive_Data_Entry_Prompt[] =
      [
        ...interactiveDataEntryPrompts,
        {
          id: `unassigned-id-${interactiveDataEntryPrompts.length}`,
          interactive_data_entry_activity: activityId,
          name: "",
          weight: interactiveDataEntryPrompts.length + 1,
        },
      ];
    setInteractiveDataEntryPrompts(updatedInteractiveDataEntryPrompts);
    // onChange && onChange();
  };

  return (
    <>
      <Typography sx={{ textAlign: "left", mb: 1 }}>
        {translation.interactiveDataEntryPrompt}
      </Typography>
      <Box
        sx={{
          border: "2px solid #98C4C5",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <InteractiveDataEntryPromptSortableList
          items={interactiveDataEntryPrompts}
          setItems={setInteractiveDataEntryPrompts}
          renderItem={(item) => {
            return (
              <InteractiveDataEntryPromptSortableList.Item id={item.id}>
                <InteractiveDataEntryPromptFieldForDnd
                  item={item}
                  interactiveDataEntryPrompts={interactiveDataEntryPrompts}
                  setInteractiveDataEntryPrompts={
                    setInteractiveDataEntryPrompts
                  }
                />
                <InteractiveDataEntryPromptSortableList.DragHandle />
              </InteractiveDataEntryPromptSortableList.Item>
            );
          }}
        />
        {/* {interactiveDataEntryPrompts.map(
          (interactiveDataEntryPrompt, index) => {
            return (
              <Fragment key={`flip-card-${index}`}>
                <IndividualPrompt
                  index={index}
                  interactiveDataEntryPrompts={interactiveDataEntryPrompts}
                  setInteractiveDataEntryPrompts={
                    setInteractiveDataEntryPrompts
                  }
                  interactiveDataEntryPrompt={interactiveDataEntryPrompt}
                />
              </Fragment>
            );
          }
        )} */}
        <SubtitleWithAddIcon
          subtitle={translation.addPrompt || "Add Prompt"}
          handleAdd={handleAdd}
        />
      </Box>
    </>
  );
};

export const InteractiveDataEntryPromptFieldForDnd = ({
  item,
  interactiveDataEntryPrompts,
  setInteractiveDataEntryPrompts,
}: {
  item: API_Interactive_Data_Entry_Prompt;
  interactiveDataEntryPrompts: API_Interactive_Data_Entry_Prompt[];
  setInteractiveDataEntryPrompts: React.Dispatch<
    React.SetStateAction<API_Interactive_Data_Entry_Prompt[]>
  >;
  onChange?: (input: string) => void;
}) => {
  const openSnack = useSnack();
  const [interactiveDataEntryPromptName, setInteractiveDataEntryPromptName] =
    useState<string>(item.name);
  useEffect(() => {
    const inputNameTimeout = setTimeout(async () => {
      // console.log("Updating name now");
      const currInteractiveDataEntryPromptIndex =
        interactiveDataEntryPrompts.findIndex(
          (interactiveDataEntryPrompt) =>
            interactiveDataEntryPrompt.id === item.id
        );
      const updatedInteractiveDataEntryPrompts: API_Interactive_Data_Entry_Prompt[] =
        JSON.parse(JSON.stringify(interactiveDataEntryPrompts));
      updatedInteractiveDataEntryPrompts[
        currInteractiveDataEntryPromptIndex
      ].name = interactiveDataEntryPromptName;
      setInteractiveDataEntryPrompts(updatedInteractiveDataEntryPrompts);
    }, 250);
    return () => clearTimeout(inputNameTimeout);
  }, [interactiveDataEntryPromptName]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteInteractiveDataEntryPrompt(Number(item.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setInteractiveDataEntryPrompts(
        res.interactive_data_entry_prompts
          ? res.interactive_data_entry_prompts
          : []
      );
      openSnack(
        translation.interactiveDataEntryPromptRemoved ||
          "Interactive Data Entry Prompt Removed",
        true
      );
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! "${item.name}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.name]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Prompt?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{ display: "flex", alignItems: "center", gap: 4, width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography>
            {`${translation.formatString(
              translation.interactiveDataEntryPromptNo ||
                "Interactive Data Entry Prompt No",
              {
                number: item.weight,
              }
            )}`}
          </Typography>

          <TextField
            fullWidth
            label={translation.prompt}
            value={interactiveDataEntryPromptName}
            onChange={(event) => {
              setInteractiveDataEntryPromptName(event.target.value);
            }}
            required
          />
        </Box>
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (typeof item.id === "string" && item.id.includes("unassigned")) {
              const filteredInteractiveDataEntryPrompts =
                interactiveDataEntryPrompts.filter(
                  (interactiveDataEntryPrompt) =>
                    interactiveDataEntryPrompt.id !== item.id
                );
              const finalInteractiveDataEntryPrompts: API_Interactive_Data_Entry_Prompt[] =
                JSON.parse(JSON.stringify(filteredInteractiveDataEntryPrompts));
              filteredInteractiveDataEntryPrompts.forEach(
                (interactiveDataEntryPrompts, index) => {
                  finalInteractiveDataEntryPrompts[index].weight = index + 1;
                }
              );
              setInteractiveDataEntryPrompts(finalInteractiveDataEntryPrompts);
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};

// export const IndividualPrompt = ({
//   index,
//   interactiveDataEntryPrompts,
//   setInteractiveDataEntryPrompts,
//   interactiveDataEntryPrompt,
// }: {
//   index: number;
//   interactiveDataEntryPrompts: API_Interactive_Data_Entry_Prompt[];
//   setInteractiveDataEntryPrompts: React.Dispatch<
//     React.SetStateAction<API_Interactive_Data_Entry_Prompt[]>
//   >;
//   interactiveDataEntryPrompt: API_Interactive_Data_Entry_Prompt;
// }) => {
//   const openSnack = useSnack();
//   const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
//   const [warningContext, setWarningContext] = useState<string>("");

//   const handleDelete = async () => {
//     const res = await deleteInteractiveDataEntryPrompt(
//       Number(interactiveDataEntryPrompt.id)
//     );
//     if (typeof res === "string") {
//       openSnack(res, false);
//     } else {
//       const filteredInteractiveDataEntryPrompts =
//         interactiveDataEntryPrompts.filter(
//           (interactiveDataEntryPrompt_) =>
//             interactiveDataEntryPrompt.id !== interactiveDataEntryPrompt_.id
//         );
//       setInteractiveDataEntryPrompts(filteredInteractiveDataEntryPrompts);
//       openSnack(translation.interactiveDataEntryPromptRemoved, true);
//     }
//   };

//   useEffect(() => {
//     const initialiseWarningContext = async () => {
//       setWarningContext(
//         `This action is irreversable! "${translation.formatString(
//           translation.interactiveDataEntryPromptNo,
//           { number: index + 1 }
//         )}" will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
//       );
//     };

//     initialiseWarningContext();

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const warningBody = {
//     openDialog,
//     handleCloseDialog,
//     warningTitle: "Delete Prompt?",
//     warningContext: warningContext,
//     handleDelete,
//   };
//   return (
//     <>
//       <WarningDialog {...warningBody} />
//       <Typography sx={{ textAlign: "left", mt: index === 0 ? 0 : 1, mb: 1 }}>
//         {translation.formatString(translation.interactiveDataEntryPromptNo, {
//           number: index + 1,
//         })}
//       </Typography>
//       <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: 1,
//             width: "100%",
//           }}
//         >
//           <TextField
//             fullWidth
//             label={translation.prompt}
//             value={interactiveDataEntryPrompt.name}
//             onChange={(event) => {
//               const interactiveDataEntryPromptsCopy: API_Interactive_Data_Entry_Prompt[] =
//                 JSON.parse(JSON.stringify(interactiveDataEntryPrompts));
//               interactiveDataEntryPromptsCopy[index].name = event.target.value;
//               setInteractiveDataEntryPrompts(interactiveDataEntryPromptsCopy);
//             }}
//             required
//           />
//         </Box>
//         <ClearIcon
//           sx={{
//             color: "red",
//             cursor: "pointer",
//             transition: "all 100ms linear",
//             "&:hover": {
//               color: "darkred",
//               transition: "all 100ms linear",
//             },
//           }}
//           onClick={async () => {
//             if (
//               typeof interactiveDataEntryPrompt.id === "string" &&
//               interactiveDataEntryPrompt.id.includes("unassigned")
//             ) {
//               const filteredInteractiveDataEntryPrompts =
//                 interactiveDataEntryPrompts.filter(
//                   (interactiveDataEntryPrompt_) =>
//                     interactiveDataEntryPrompt.id !==
//                     interactiveDataEntryPrompt_.id
//                 );
//               setInteractiveDataEntryPrompts(
//                 filteredInteractiveDataEntryPrompts
//               );
//             } else {
//               handleOpenDialog();
//             }
//           }}
//         />
//       </Box>
//     </>
//   );
// };

import { API_Group_Full } from "../types/group";
import { StoreSlice } from "./store";

export const initialGroupFull = {
  id: -1,
  name: "",
  slug: "",
  description: "",
  age: "",
  school: -1,
  children: [],
  children_school: [],
  teachers: [],
  teachers_school: [],
  image: "",
  interest: [],
  fundamental_target: [],
  communication_lvl: "",
  current_lessons: [],
  next_lessons: [],
};

export interface GroupSlice {
  groupList: API_Group_Full[];
  currGroup: API_Group_Full;
  isCurrGroupLoaded: boolean;
  refreshGroupTeacherList: boolean;
  refreshGroupChildrenList: boolean;
  setGroupList: (groupList: API_Group_Full[]) => void;
  setCurrGroup: (currGroup: API_Group_Full) => void;
  setIsCurrGroupLoaded: (isCurrGroupLoaded: boolean) => void;
  setRefreshGroupTeacherList: (refreshGroupTeacherList: boolean) => void;
  setRefreshGroupChildrenList: (refreshGroupChildrenList: boolean) => void;
}

export const createGroupSlice: StoreSlice<GroupSlice> = (set, get) => ({
  groupList: [],
  currGroup: initialGroupFull,
  isCurrGroupLoaded: false,
  refreshGroupTeacherList: true,
  refreshGroupChildrenList: true,
  setGroupList: (groupList: API_Group_Full[]) => {
    set((prev: GroupSlice) => ({
      ...prev,
      groupList: groupList,
    }));
  },
  setCurrGroup: (currGroup: API_Group_Full) => {
    set((prev: GroupSlice) => ({
      ...prev,
      currGroup: currGroup,
    }));
  },
  setIsCurrGroupLoaded: (isCurrGroupLoaded: boolean) => {
    set((prev: GroupSlice) => ({
      ...prev,
      isCurrGroupLoaded: isCurrGroupLoaded,
    }));
  },
  setRefreshGroupTeacherList: (refreshGroupTeacherList: boolean) => {
    set((prev: GroupSlice) => ({
      ...prev,
      refreshGroupTeacherList: refreshGroupTeacherList,
    }));
  },
  setRefreshGroupChildrenList: (refreshGroupChildrenList: boolean) => {
    set((prev: GroupSlice) => ({
      ...prev,
      refreshGroupChildrenList: refreshGroupChildrenList,
    }));
  },
});

export const HEART = "heart";
export const HEAD = "head";
export const HANDS = "hands";

export const FUNDAMENTAL_TAG = [HEART, HEAD, HANDS];

//manage project/module
export const ACCESS_CONTROL = "access-control";
export const INSIGHTS = "insights";
export const VISUALIZATION = "visualization";

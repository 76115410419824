import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteScheduleObjectComponent = ({
  handleOpenDialog,
  inModal = false,
}: {
  handleOpenDialog: () => void;
  inModal?: boolean;
}) => {
  return (
    <Box
      className="nodrag"
      sx={{
        position: inModal ? "" : "absolute",
        display: inModal ? "flex" : "",
        top: 0,
        right: 6,
        cursor: "pointer",
        height: "18px",
        width: "18px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleOpenDialog();
      }}
    >
      <DeleteIcon sx={{ fontSize: "18px", color: "var(--txt-secondary)" }} />
    </Box>
  );
};

export default DeleteScheduleObjectComponent;

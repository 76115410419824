import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import ClassListMiniDrawer from "./ClassListMiniDrawer";

const ChildrenPortfolioOverviewPage = () => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <ClassListMiniDrawer />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          // px: 3,
          py: 4,
          mx: "20px",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default ChildrenPortfolioOverviewPage;

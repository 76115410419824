import { Box, Stack, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import useSnack from "../../../../../../hooks/useSnack";
import useStore from "../../../../../../store/store";

import Button from "../../../../../../components/Button/Button";

import { translation } from "../../../../../../constants/translation";

import useCallbackPrompt from "../../../../../../hooks/useCallbackPrompt";

import {
  NameField,
  PublishField,
  FileField,
  ImageField,
  DescriptionField,
  ActivityTypeField,
  TranscriptField,
  DurationField,
} from "../../TeacherFormComponents/ActivityFormComponents";
import BlockLeavingDialog from "../../../../../../components/Dialog/BlockLeavingDialog";
import {
  API_Edit_Teacher_Activity,
  API_Teacher_Video_Activity,
} from "../../../../../../types/teacher";
import { editTeacherActivity } from "../../../../../../api/teacher-api";
import { DeleteActivityButton } from "./CommonActivityFormComponents";

const EditVideoActivityForm = ({
  activityId,
  lessonId,
  handleClose,
}: {
  activityId: number;
  lessonId: number;
  handleClose: () => void;
}) => {
  // Better to split this into different types of activities to prevent complexity.
  const {
    currLanguage,
    teacherCurrentActivity,
    setTeacherCurrentActivity,
    setIsRefreshTeacherModule,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    teacherCurrentActivity:
      state.teacherCurrentActivity as API_Teacher_Video_Activity,
    setTeacherCurrentActivity: state.setTeacherCurrentActivity,
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));
  const openSnack = useSnack();

  //If video activity
  const [video, setVideo] = useState<File | string>(
    teacherCurrentActivity.video ? teacherCurrentActivity.video : ""
  );

  const [thumbnail, setThumbnail] = useState<File | string>(
    teacherCurrentActivity.thumbnail ? teacherCurrentActivity.thumbnail : ""
  );
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [isCreatingActivity, setIsCreatingActivity] = useState<boolean>(false);

  const [updatedActivity, setUpdatedActivity] =
    useState<API_Edit_Teacher_Activity>({
      id: activityId,
      lesson: lessonId,
      resourcetype: teacherCurrentActivity.resourcetype,
      name: teacherCurrentActivity.name,
      description: teacherCurrentActivity.description,
      weight: teacherCurrentActivity.weight,
      is_published: teacherCurrentActivity.is_published,
      transcript: teacherCurrentActivity.transcript,
      duration: teacherCurrentActivity.duration,
    });

  const activityRef = useRef(updatedActivity);
  activityRef.current = updatedActivity;

  const submitEditForm = async () => {
    //Check
    if (
      updatedActivity.name === "" ||
      (video === "" && updatedActivity.is_published === true)
    ) {
      //filled
      openSnack(
        "Activity name and video is required in order to publish!",
        false
      );
      return;
    }

    let editFailed = false;
    const activityData: API_Edit_Teacher_Activity = {
      id: activityId,
      lesson: lessonId,
      resourcetype: teacherCurrentActivity.resourcetype,
    };

    if (updatedActivity.name !== teacherCurrentActivity.name)
      activityData.name = updatedActivity.name;
    if (updatedActivity.description !== teacherCurrentActivity.description)
      activityData.description = updatedActivity.description;
    if (updatedActivity.is_published !== teacherCurrentActivity.is_published)
      activityData.is_published = updatedActivity.is_published;

    if (typeof video === "object" || video === "") {
      activityData.video = video;
    }
    if (typeof thumbnail === "object" || thumbnail === "") {
      activityData.thumbnail = thumbnail;
    }
    if (updatedActivity.transcript) {
      activityData.transcript = updatedActivity.transcript;
    }

    if (Object.keys(activityData).length > 0) {
      const res = await editTeacherActivity(
        currLanguage,
        activityData,
        setIsCreatingActivity,
        setProgressPercentage,
        localStorage.getItem("access")!
      );

      if (typeof res === "string") {
        // console.log("res: ", res);
        openSnack(`${translation.failUpdateActivity}: ${res}`, false);
        editFailed = true;
      }
    }

    if (!editFailed) {
      setIsRefreshTeacherModule(true);
      openSnack("Updated activity successfully!", true);
      handleClose();
    }
  };

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const dialogBody = {
    showDialog: showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleAction: submitEditForm,
  };

  return (
    <>
      <BlockLeavingDialog {...dialogBody} />

      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <ActivityTypeField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          isDisabled
        />
        <PublishField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <NameField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />
        <DurationField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        {/* <DescriptionField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        /> */}

        <ImageField
          fieldName={translation.videoThumbnail}
          image={thumbnail}
          setImage={setThumbnail}
        />
        <FileField file={video} setFile={setVideo} language={currLanguage} />

        {/* Pass in video to test further */}
        <TranscriptField
          video={video}
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
        />

        <Divider />

        <DeleteActivityButton
          activityId={activityId}
          handleClose={handleClose}
        />

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          <Button
            buttonText={
              isCreatingActivity
                ? progressPercentage !== 100
                  ? `${progressPercentage}%`
                  : translation.uploadFinalizing
                : translation.update
            }
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
              height: "auto",
            }}
            onClick={() => {
              submitEditForm();
            }}
            disabled={isCreatingActivity}
          />
        </Box>
      </Stack>
    </>
  );
};

export default EditVideoActivityForm;

import { useState } from "react";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import QuickQuestionImage1 from "images/quick_questions_intro_image_1.png";
import QuickQuestionImage2 from "images/quick_questions_intro_image_2.png";
import Image from "components/Image/Image";
import { translation } from "constants/translation";
import { Container, Title, CustomButton } from "../Components";

const Introduction = ({
  pageNumber,
  setCurrentPage,
}: {
  pageNumber: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);

  return (
    <Container>
      {isFirstPage ? (
        <>
          <Title title={translation.welcomeToTTB} />

          <Image
            src={QuickQuestionImage1}
            alt=""
            style={{
              width: "100%",
              maxWidth: "250px",
              margin: "8px auto 4px",
              objectFit: "contain",
            }}
          />

          <Typography
            sx={{
              textAlign: "center",
              fontSize: 18,
              mb: 0.5,
            }}
          >
            {translation.introFirstParagraph}
          </Typography>

          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0.75,
                cursor: "pointer",
              }}
              onClick={() => setIsFirstPage(false)}
            >
              <Typography>{translation.next}</Typography>
              <ChevronRightIcon fontSize="small" />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Title title={translation.everyChildIsUnique} />

          <Image
            src={QuickQuestionImage2}
            alt=""
            style={{
              width: "100%",
              maxWidth: "250px",
              margin: "8px auto 4px",
              objectFit: "contain",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: 1.5, sm: 2.5 },
              my: 0.5,
            }}
          >
            <Level
              name={translation.Sprouts}
              image={SproutsIcon}
              description={translation.sproutsDescription}
            />

            <Level
              name={translation.Buds}
              image={BudsIcon}
              description={translation.budsDescription}
            />

            <Level
              name={translation.Blossoms}
              image={BlossomsIcon}
              description={translation.blossomsDescription}
            />
          </Box>

          <Typography
            sx={{
              textAlign: "center",
              mb: 0.5,
            }}
          >
            {translation.introLastParagraph}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomButton
              text={translation.begin}
              onClick={() => setCurrentPage(pageNumber + 1)}
              alignCenter
              yellowButton
            />
          </Box>
        </>
      )}
    </Container>
  );
};

const Level = ({
  name,
  image,
  description,
}: {
  name: string;
  image: string;
  description: string;
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 0,
      py: 1.5,
      px: 4,
      backgroundColor: "#C5E7E8",
      borderRadius: "9999px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.5,
        flexShrink: 0,
        width: "100%",
        maxWidth: "60px",
      }}
    >
      <Image
        src={image}
        alt={name}
        style={{
          width: 32,
          height: 32,
          objectFit: "contain",
          objectPosition: "bottom center",
        }}
      />

      <Typography
        sx={{
          lineHeight: 1.25,
          fontWeight: 500,
        }}
      >
        {name}
      </Typography>
    </Box>

    <Typography
      sx={{
        textAlign: "center",
      }}
    >
      {description}
    </Typography>
  </Box>
);

export default Introduction;

import React, { useEffect, useState } from "react";
import useStore from "store/store";
import { getChildProjectImages } from "api/gallery-api";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import DownloadPDFButton from "./DownloadPDFButton";
import AlbumPreviewContent from "./AlbumPreviewContent";

const ImageTypePreviewModal = ({
  open,
  childId,
  projectId,
  projectName,
  projectImageSrc,
  moduleName,
  handleClose,
}: {
  open: boolean;
  childId: number;
  projectId: number;
  projectName: string;
  projectImageSrc: string;
  moduleName: string;
  handleClose: () => void;
}) => {
  const { childrenInSession } = useStore((state) => ({
    childrenInSession: state.childrenInSession,
  }));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [images, setImages] = useState<ImageType[]>([]);
  const [squareImages, setSquareImages] = useState<ImageType[]>([]);
  const [horizontalImages, setHorizontalImages] = useState<ImageType[]>([]);
  const [verticalImages, setVerticalImages] = useState<ImageType[]>([]);

  useEffect(() => {
    const getGalleryData = async () => {
      setIsLoading(true);

      const res = await getChildProjectImages(
        childId,
        projectId,
        localStorage.getItem("access")
      );

      if (typeof res !== "string") {
        setImages(res);
        setSquareImages(
          res.filter(
            (image) =>
              image.is_horizontal === false && image.is_vertical === false
          )
        );
        setHorizontalImages(
          res.filter((image) => image.is_horizontal === true)
        );
        setVerticalImages(res.filter((image) => image.is_vertical === true));
      }

      setIsLoading(false);
    };

    getGalleryData();
  }, []);
  // console.log("squareImages: ", squareImages);
  // console.log("horizontalImages: ", horizontalImages);
  // console.log("verticalImages: ", verticalImages);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "1000px",
            bgcolor: "background.paper",
            border: "1px solid var(--primary-main)",
            borderRadius: "10px",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                p: 4,
              }}
            >
              <LoadingIndicator />
            </Box>
          ) : (
            <>
              <Box sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Box />
                  <DownloadPDFButton
                    projectName={projectName}
                    childName={
                      childrenInSession.filter(
                        (child) => child.id === Number(childId)
                      )[0].preferred_name
                    }
                    moduleName={moduleName}
                    projectImageSrc={projectImageSrc}
                    squareImages={squareImages}
                    horizontalImages={[...horizontalImages, ...squareImages]}
                    verticalImages={verticalImages}
                  />
                </Box>
                <AlbumPreviewContent
                  projectImageSrc={projectImageSrc}
                  squareImages={squareImages}
                  horizontalImages={[...horizontalImages, ...squareImages]}
                  verticalImages={verticalImages}
                  projectName={projectName}
                  moduleName={moduleName}
                />
              </Box>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ImageTypePreviewModal;

export const DATETIME_FORMAT = "YYYY/MM/DD";

export const RIGHT_COLUMN_WIDTH = 80; // vw
export const LEFT_COLUMN_WIDTH = 10; // vw
export const ROW_HEIGHT = 80; // px

export const BACKPACK_HEIGHT = 150; //px
export const BACKPACK_CARD_MIN_WIDTH = 200; //px
export const BACKPACK_BOTTOM_PERCENT = 12; //%

export const CALENDAR_HEIGHT = 63; // vh

export const CALENDAR_BORDER_TRANSPARENT_STYLE = `1px solid transparent`;
export const CALENDAR_BORDER_STYLE = `1px solid #F0F0F0`;
export const CALENDAR_BORDER_BOLD_STYLE = `1px solid var(--primary-main)`;
export const CALENDAR_BORDER_BOLD_LOWER_OPACITY_STYLE = `1px solid rgba(152, 196, 197, 0.5)`;
export const CALENDAR_CELL_BACKGROUND_COLOR = "#f7fafa"; //rgba(152, 196, 197, 0.08)
export const CALENDAR_SELECTED_CELL_BACKGROUND_COLOR = "#e0ecef";

export const AVATAR_WIDTH = 45; //px

export const GROUP_COLORS = ["#648E8F", "#324B4C", "#1C884C"];

export const PROJECT_BOX = "projectBox";
export const PROJECT_BOX_HEIGHT = 30;

export const MONTH_VIEW_LABEL_DISPLAY = 7; //7 days per label

export const LITTLE_LEARNERS = "Little Learners";
export const LITTLE_PROJECTS = "Little Projects";
export const LITTLES_PROJECTS_COLOR = "var(--primary-analogous-1)";
export const LITTLES_LEARNERS_COLOR = "var(--primary-analogous-2)";

export const WEEKEND_COLOR = "#F5F5F5";

export const FONT_SIZE = 13; //px

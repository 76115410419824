import TabsContainerMobile from "./TabsContainerMobile";
const ChildPortfolioMiniDrawerMobile = ({
  currentTab,
}: {
  childName: string;
  currentTab: string;
  isParentView?: boolean;
}) => {
  return <TabsContainerMobile currentTab={currentTab} />;
};

export default ChildPortfolioMiniDrawerMobile;

import React from "react";

import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  FormControl,
  Menu,
  TextField,
} from "@mui/material";

import "components/ProjectSummaryScreen/ReactGridGallery.css";

import Logo from "images/logo-2-colour.png";
import { API_Profile_Short } from "types/profile";

const CompletedWorkChildTaggingMenu = ({
  anchorEl,
  isOpenMenu,
  handleCloseMenu,
  selectedChildList,
  setSelectedChildList,
  profileDict,
}: {
  anchorEl: HTMLElement | null;
  isOpenMenu: boolean;
  handleCloseMenu: () => void;
  selectedChildList: number[];
  setSelectedChildList: React.Dispatch<React.SetStateAction<number[]>>;
  profileDict: {
    [id: string]: API_Profile_Short;
  };
}) => {
  const profileList = Object.keys(profileDict).map((profileId) => {
    return {
      profile_id: Number(profileId),
      preferred_name: profileDict[profileId].preferred_name,
      image: profileDict[profileId].image,
    };
  });

  const selectedProfileList = selectedChildList.reduce<
    { profile_id: number; preferred_name: string; image: string }[]
  >((acc, profileId) => {
    if (profileDict[profileId]) {
      acc.push({
        profile_id: profileId,
        preferred_name: profileDict[profileId].preferred_name,
        image: profileDict[profileId].image,
      });
    }
    return acc;
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isOpenMenu}
      onClose={handleCloseMenu}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 12,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <FormControl sx={{ m: 1, width: 300, px: 2 }}>
        {/* <InputLabel id="multiple-chip-label">{translation.profiles}</InputLabel> */}
        <Autocomplete
          multiple
          id="tags-standard"
          value={selectedProfileList}
          onChange={(event, values) => {
            const profileIdList = values.map((profile) => profile.profile_id);
            setSelectedChildList(profileIdList);
          }}
          options={profileList}
          getOptionLabel={(option) => option.preferred_name}
          isOptionEqualToValue={(option, value) =>
            option.profile_id === value.profile_id
          }
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Profiles" />
          )}
          renderTags={(value, getTagProps) => {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  {value.map((option, index) => (
                    <Chip
                      key={`chip-of-${option.preferred_name}`}
                      avatar={
                        <Avatar
                          alt={option.preferred_name}
                          src={option.image}
                          imgProps={{
                            onError: (
                              event: React.SyntheticEvent<
                                HTMLImageElement,
                                Event
                              >
                            ) => {
                              event.currentTarget.onerror = null;
                              event.currentTarget.src = Logo;
                            },
                          }}
                        />
                      }
                      label={option.preferred_name}
                    />
                  ))}
                </Box>
              </>
            );
          }}
        />
      </FormControl>
    </Menu>
  );
};

export default CompletedWorkChildTaggingMenu;

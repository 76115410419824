import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Typography, Stack, TextField, Autocomplete } from "@mui/material";

import Button from "components/Button/Button";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { API_Update_Profile } from "types/profile";
import { translation } from "constants/translation";
import { registerAccount, updateProfile } from "api/auth-api";
import {
  ErrorMessage,
  RegisterAccountResponse,
  UpdateProfile,
  UpdateProfileResponse,
  UserRoleAbbr,
  UserRoleMappingInverse,
} from "types/auth";
import { API_School } from "types/school";
import { getAllSchool } from "api/school-api";
import { TEXT_FIELD_STYLE } from "containers/SiteAdminPage/ManagementPage/constants/styling";
import { GridRowsProp } from "@mui/x-data-grid";
import { API_Group_Simple } from "types/group";
import { getGroupListBySchool } from "api/profile-api";
import CreateGroupModal from "containers/SiteAdminPage/ManagementPage/components/CreateGroupModal";
import ImageForm from "./ImageForm";
import { addButtonStyle } from "./addButtonStyle";
import {
  GenderForm,
  HighestEducationQualificationForm,
  MobileNumberForm,
} from "containers/AccountPage/AccountPage";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const CreateProfilePage = ({
  role,
  groupId = -1,
  schoolId = -1,
  handleClose,
  setRows,
  setChildFormData,
}: {
  role: UserRoleAbbr;
  groupId?: number;
  schoolId?: number;
  handleClose?: () => void;
  setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setChildFormData?: any;
}) => {
  const openSnack = useSnack();
  const navigate = useNavigate();
  const {
    profileDetails,
    schools,
    profileID,
    adminPageParentList,
    setSchools,
    setAdminPageParentList,
    setRefreshNumberOfActiveUserInfo,
    setRefreshGroupTeacherList,
  } = useStore((state) => state);
  const [formData, setFormData] = useState<API_Update_Profile>({
    email: "",
    mobile_number: "",
    role: role,
    preferred_name: "",
    full_name: "",
    gender: "",
    highest_education_qualification: "",
    education_start_date: "",
    dob: "",
    school: -1,
    groups: [],
    image: "",
    description: "",
  });
  const [password, setPassword] = useState<string>("");
  const [groupListDisplay, setGroupListDisplay] = useState<API_Group_Simple[]>(
    []
  );
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [defaultSchool, setDefaultSchool] = useState<API_School>();

  // Get school list
  useEffect(() => {
    const populateData = async () => {
      setSchools(await getAllSchool());
    };
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //assign school if profile used to create have school
  useEffect(() => {
    if (schoolId !== -1) {
      setFormData((prev) => ({
        ...prev,
        school: schoolId,
      }));
      setDefaultSchool(schools.filter((school) => school.id === schoolId)[0]);
    } else if (profileDetails && profileDetails.role !== "WAD") {
      setFormData((prev) => ({
        ...prev,
        school: profileDetails?.school ? profileDetails.school.id : -1,
      }));
      setDefaultSchool(
        schools.filter((school) => school.id === profileDetails?.school!.id)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails, schools]);

  useEffect(() => {
    const populateData = async () => {
      if (formData.school && formData.school !== -1) {
        const res = await getGroupListBySchool(formData.school);
        setGroupListDisplay(res);
      }
    };
    setFormData((prev) => ({
      ...prev,
      groups: [],
    }));
    populateData();
    if (groupId !== -1) {
      setFormData((prev) => ({
        ...prev,
        groups: [...prev.groups, groupId],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.school]);

  const handleSubmit = async () => {
    //Register auth account
    const body = formData;
    const response: RegisterAccountResponse | ErrorMessage =
      await registerAccount({
        preferred_name: body.preferred_name, //for email
        email: body.email,
        password: password,
        role: body.role,
      });

    if ("error" in response) {
      openSnack(response.error.join(" "), false);
    } else {
      const updateResponse: UpdateProfileResponse | ErrorMessage =
        await updateProfile(
          response.profile_id,
          body as UpdateProfile,
          response.tokens.access
        );
      // console.log("Update Response: ", updateResponse);

      if ("error" in updateResponse) {
        const errorMessages = updateResponse.error
          .map((errorObj) => {
            // Assuming each property in errorObj is an array of strings
            return Object.values(errorObj).flat().join(" ");
          })
          .join(" ");
        openSnack(errorMessages, false);
      } else {
        openSnack(`${UserRoleMappingInverse[role]} is registered!`, true);

        if (setRows) {
          setRows((prev: any) => {
            return [
              ...prev,
              {
                ...updateResponse,
                groups: groupListDisplay.filter((group) =>
                  body.groups.includes(group.id)
                ),
              },
            ];
          });
        }

        if (setChildFormData) {
          setChildFormData((prev: any) => ({
            ...prev,
            parent: updateResponse.id,
          }));
        }

        if (updateResponse.role === "PR") {
          setAdminPageParentList([...adminPageParentList, updateResponse]);
        }

        if (updateResponse.role === "TR") {
          setRefreshGroupTeacherList(true);
          setRefreshNumberOfActiveUserInfo({
            isRefresh: true,
            schoolId: updateResponse.school,
          });
        }

        if (handleClose) {
          // navigate(`${CURLANG(currLanguage)}/${SITE_ADMIN_PAGE}/${TEACHER}`);
          handleClose();
        } else {
          navigate(-1);
        }
      }
    }
  };

  const disabledGeneral =
    formData.email === "" ||
    password === "" ||
    formData.full_name === "" ||
    formData.school === -1;
  const disabledCE = formData.email === "" || password === "";

  // console.log("formData; ", formData);
  // console.log("defaultschool: ", defaultSchool);
  // console.log("schools: ", schools);

  const today = new Date().toLocaleDateString("en-ca");

  return !!profileID ? (
    <Box sx={{ p: "16px" }}>
      <CreateGroupModal
        open={openGroupModal}
        handleClose={() => setOpenGroupModal(false)}
        schoolId={formData.school ? formData.school : -1}
        setFormData={setFormData}
        setGroupListDisplay={setGroupListDisplay}
      />
      <Stack
        component="form"
        sx={{ margin: "auto", my: "16px", alignItems: "center" }}
        spacing={1}
        noValidate
        autoComplete="off"
      >
        <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
          {role === "WAD" && translation.registerSiteAdmin}
          {role === "AD" && translation.registerSchoolAdmin}
          {role === "CE" && translation.registerContentEditor}
          {role === "TTACE" && translation.registerTTAContentEditor}
          {role === "TR" && translation.registerTeacher}
          {role === "PR" && translation.registerParent}
        </Typography>
        <ImageForm
          setFormData={setFormData}
          role={role}
          image={
            typeof formData.image === "string"
              ? formData.image
              : typeof formData.image === "object"
              ? formData.image !== null
                ? URL.createObjectURL(formData.image)
                : ""
              : ""
          }
        />
        <MobileNumberForm
          formData={formData as any}
          setFormData={setFormData as any}
        />
        <Stack spacing={1} direction="row">
          <TextField
            sx={TEXT_FIELD_STYLE}
            required
            label={translation.email}
            value={formData.email}
            onChange={(event) => {
              setFormData((prev) => ({ ...prev, email: event.target.value }));
            }}
          />
          <TextField
            sx={TEXT_FIELD_STYLE}
            type="password"
            required
            label={translation.password}
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </Stack>

        <Stack spacing={1} direction="row">
          <TextField
            sx={TEXT_FIELD_STYLE}
            required
            label={translation.full_name}
            value={formData.full_name}
            onChange={(event) => {
              setFormData((prev) => ({
                ...prev,
                full_name: event.target.value,
              }));
            }}
          />
          <TextField
            sx={TEXT_FIELD_STYLE}
            label={translation.preferred_name}
            value={formData.preferred_name}
            onChange={(event) => {
              setFormData((prev) => ({
                ...prev,
                preferred_name: event.target.value,
              }));
            }}
          />
        </Stack>
        {role === "TR" && (
          <>
            <Stack spacing={1} direction="row">
              <GenderForm formData={formData} setFormData={setFormData} />
              <HighestEducationQualificationForm
                formData={formData}
                setFormData={setFormData}
              />
            </Stack>
          </>
        )}

        <Stack
          spacing={1}
          direction="row"
          sx={{ width: "100%", display: "flex" }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              maxDate={moment(today)}
              label={translation.dob}
              inputFormat="YYYY-MM-DD"
              value={
                formData.dob !== ""
                  ? moment(formData.dob).format("YYYY-MM-DD")
                  : null
              }
              onChange={(newValue: moment.Moment | null) => {
                if (newValue !== null) {
                  setFormData((prev: any) => ({
                    ...prev,
                    dob: newValue.format("YYYY-MM-DD").toString(),
                  }));
                }
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ flex: 1 }} />
              )}
            />
          </LocalizationProvider>
          {/* <TextField
            sx={TEXT_FIELD_STYLE}
            label={translation.dob}
            type="date"
            defaultValue={formData.dob}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setFormData((prev) => ({ ...prev, dob: event.target.value }));
            }}
            inputProps={{
              max: today,
            }}
          /> */}
          {/* {role === "TR" && (
            <EducationStartDateField
              formData={formData}
              setFormData={setFormData}
            />
          )} */}
        </Stack>

        {profileDetails.role === "WAD" && formData.role !== "WAD" ? (
          <Autocomplete
            sx={TEXT_FIELD_STYLE}
            disabled={schoolId !== -1}
            disablePortal
            id="combo-box-school"
            onChange={(event: any, school_updated: API_School | null) => {
              if (school_updated !== null) {
                setFormData((prev) => ({
                  ...prev,
                  school: school_updated.id,
                }));
                setDefaultSchool(school_updated);
              }
            }}
            value={defaultSchool || null}
            options={schools}
            getOptionLabel={(school) => school.name}
            // renderOption={(props, option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => {
              return (
                <>
                  {formData.role.includes("CE") ? (
                    <TextField {...params} label="Schools" placeholder="" />
                  ) : (
                    <TextField
                      {...params}
                      required
                      label="Schools"
                      placeholder=""
                    />
                  )}
                </>
              );
            }}
          />
        ) : null}
        {role === "TR" && (
          <Box sx={{ display: "flex" }}>
            <Autocomplete
              disabled={formData.school === -1}
              multiple
              key={groupListDisplay.map((group) => group.id).join("-")}
              sx={TEXT_FIELD_STYLE}
              disablePortal
              id="combo-box-groups"
              onChange={(
                event: any,
                groups_updated: API_Group_Simple[] | null
              ) => {
                if (groups_updated !== null) {
                  setFormData((prev) => ({
                    ...prev,
                    groups: groups_updated?.map((group) => group.id),
                  }));
                }
              }}
              options={groupListDisplay}
              getOptionLabel={(group) => group.name}
              // renderOption={(props, option) => option.name}
              value={groupListDisplay.filter((group) =>
                formData!.groups.includes(group.id)
              )}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              renderInput={(params) => {
                return <TextField {...params} label="Groups" placeholder="" />;
              }}
            />
            <Button
              style={{
                ...addButtonStyle,
                position: "absolute",
              }}
              buttonText={`+`}
              onClick={() => {
                setOpenGroupModal(true);
              }}
              arrow={false}
              disabled={formData.school === -1}
            />
          </Box>
        )}
        {formData.role === "WAD" || formData.role.includes("CE") ? (
          <Button
            buttonText={translation.submit || "Submit"}
            style={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={disabledCE}
          />
        ) : (
          <Button
            buttonText={translation.submit || "Submit"}
            style={{ marginTop: "20px" }}
            onClick={handleSubmit}
            disabled={disabledGeneral}
          />
        )}
      </Stack>
    </Box>
  ) : (
    <Typography
      sx={{
        fontSize: "36px",
        height: "71vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {translation.not_logged_in}
    </Typography>
  );
};

export default CreateProfilePage;

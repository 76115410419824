import {
  Box,
  SxProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { API_Document } from "types/announcement";
import React from "react";
import { UserRoleMappingInverse } from "types/auth";
import moment from "moment";
import { DATE_FORMAT } from "containers/AnnouncementsPage/AnnouncementComponents";
import { FilePreview } from "containers/ProjectSummaryPage/Screens/TeachingMaterialsScreen";

const DashboardDocumentCard = ({
  document,
  sx,
  children,
  isViewOnly = true,
  isPreview = false,
}: {
  document: API_Document;
  sx?: SxProps;
  children?: React.ReactNode;
  isViewOnly?: boolean;
  isPreview?: boolean;
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const widthHeight = 100;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: { xs: 1, sm: 4 },
        alignItems: "center",
        minHeight: 100,
        ":hover": {
          backgroundColor: "#f6f1ed",
        },
        ...sx,
      }}
    >
      {children}

      {document.files.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              rowGap: 0.5,
              columnGap: 1,
              flexWrap: "wrap",
              "& > a": {
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              },
            }}
          >
            {document.files.map((file) => (
              // <a key={file.id} href={file.file} target="_blank" rel="noreferrer">
              //   {file.name}
              // </a>
              <Tooltip title={file.name} placement="top">
                <a
                  key={file.name}
                  href={file.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 5,
                      cursor: "pointer",
                      width: isXs || isSm ? widthHeight : widthHeight,
                      height: isXs || isSm ? widthHeight : widthHeight,
                    }}
                    // onClick={() => {
                    //   handleDownload(file);
                    // }}
                  >
                    <FilePreview
                      file={file}
                      noName
                      style={{
                        width: isXs || isSm ? widthHeight : widthHeight,
                        height: isXs || isSm ? widthHeight : widthHeight,
                      }}
                    />
                  </Box>
                </a>
              </Tooltip>
            ))}
          </Box>
          <Typography
            sx={{
              lineHeight: 1.25,
              fontWeight: 450,
              fontSize: 20,
            }}
          >
            {document.files[0].name.split(".")[0]}
          </Typography>
        </>
      )}

      <Typography
        sx={{
          fontSize: 14,
          lineHeight: 1.25,
          color: "rgba(0, 0, 0, 0.6)",
          textAlign: "right",
          flex: "1 1 0",
          alignSelf: "end",
        }}
      >
        {`${document.created_by_preferred_name} | ${
          UserRoleMappingInverse[document.created_by_role]
        } | ${moment(document.created_at).format(DATE_FORMAT)}`}
      </Typography>
    </Box>
  );
};

export default DashboardDocumentCard;

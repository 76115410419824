import { Box, CircularProgress, Typography } from "@mui/material";
import { translation } from "constants/translation";

const LoadingIndicator = ({ text }: { text?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
      }}
    >
      <Typography sx={{ color: "txt.secondary" }}>
        {text ? text : translation.loadingDots}
      </Typography>
      <CircularProgress sx={{ color: "txt.secondary" }} />
    </Box>
  );
};

export default LoadingIndicator;

import React, { useState, useLayoutEffect, forwardRef } from "react";

import LogoBlur from "images/logo-2-colour-blur.png";
import Logo from "images/logo-2-colour.png";

import styles from "./Image.module.css";

const Image = forwardRef<
  HTMLImageElement,
  {
    src?: string;
    alt: string;
    objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
    style?: React.CSSProperties;
    className?: string;
    draggable?: boolean;
    loadingImgSrc?: string;
    onLoad?: () => void;
  }
>(
  (
    {
      src = Logo,
      alt,
      objectFit = "contain",
      style,
      className,
      draggable = true,
      loadingImgSrc,
      onLoad,
    },
    ref
  ) => {
    const [imgSrc, setImgSrc] = useState<string>(
      loadingImgSrc ? loadingImgSrc : LogoBlur
    );

    useLayoutEffect(() => {
      setImgSrc(src);
    }, [src]);

    return (
      <img
        ref={ref}
        src={imgSrc}
        alt={alt}
        onLoad={() => {
          if (onLoad) {
            onLoad();
          }
        }}
        style={{
          width: "100%",
          height: "100%",
          objectFit: objectFit,
          objectPosition: "center",
          ...style,
        }}
        className={`${styles.image} ${className ? className : ""}`}
        draggable={draggable}
        onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
          event.currentTarget.onerror = null;
          event.currentTarget.src = Logo;
        }}
      />
    );
  }
);
export default Image;

import { Box } from "@mui/system";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import { API_Project_Module } from "types/project";
import Image from "../Image/Image";
import { Typography, SxProps } from "@mui/material";

type Props = {
  projModule: API_Project_Module;
  styles?: SxProps;
  fontSize?: string;
  moduleIconWidth?: string;
  moduleIconHeight?: string;
  containerPadding?: string;
};

const ModuleTag = ({
  projModule,
  styles = {},
  fontSize = "0.8rem",
  moduleIconWidth = "20px",
  moduleIconHeight = "20px",
  containerPadding = "4px 8px",
}: Props) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        backgroundColor: "cyan.200",
        alignItems: "center",
        p: containerPadding,
        borderRadius: "10px",
        mb: "10px",
        ml: "1px",
        ...styles,
      }}
    >
      {!!projModule?.image ? (
        <Box
          sx={{
            width: moduleIconWidth,
            height: moduleIconHeight,
            overflow: "hidden",
          }}
        >
          <Image
            src={projModule?.image}
            alt={projModule?.name}
            objectFit="contain"
          />
        </Box>
      ) : (
        <AccessibilityNewIcon
          sx={{
            width: "20px",
            height: "20px",
            overflow: "hidden",
            color: "black",
          }}
        />
      )}
      <Typography
        sx={{
          fontSize: fontSize,
          fontWeight: "500",
          mx: "6px",
          color: "txt.dark2",
        }}
      >
        {projModule?.name?.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default ModuleTag;

import React, { useState } from "react";
import {
  Paper,
  InputBase,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { translation } from "constants/translation";
import { API_Teacher_Module_Manage } from "types/teacher";
import { bulkPublishModule } from "api/teacher-api";
import { Query_Data } from "types/project";
import {
  ACCESS_CONTROL,
  INSIGHTS,
  VISUALIZATION,
} from "constants/staticConstant";
import {
  EndAtDateField,
  StartAtDateField,
} from "containers/SchoolAccessControlPage/SecondRow";
import { useParams } from "react-router-dom";

const SecondRow = ({
  moduleList,
  setModuleList,
  queryData,
  setQueryData,
  selected,
}: {
  moduleList: API_Teacher_Module_Manage[];
  setModuleList: React.Dispatch<
    React.SetStateAction<API_Teacher_Module_Manage[]>
  >;
  queryData: Query_Data;
  setQueryData: (value: React.SetStateAction<Query_Data>) => void;
  selected: string[];
}) => {
  const { pageModeSlug } = useParams();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, minWidth: 336 }}>
        <SearchBar queryData={queryData} setQueryData={setQueryData} />
        {(pageModeSlug === INSIGHTS || pageModeSlug === VISUALIZATION) && (
          <>
            <StartAtDateField
              queryData={queryData}
              setQueryData={setQueryData}
            />
            <EndAtDateField queryData={queryData} setQueryData={setQueryData} />
          </>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2.75,
        }}
      >
        {/* <DownloadButton /> */}
        {pageModeSlug === ACCESS_CONTROL && (
          <BulkAction
            moduleList={moduleList}
            setModuleList={setModuleList}
            selected={selected}
          />
        )}
      </Box>
    </Box>
  );
};

export const SearchBar = ({
  queryData,
  setQueryData,
  maxWidth = 400,
}: {
  queryData: Query_Data;
  setQueryData: (value: React.SetStateAction<Query_Data>) => void;
  maxWidth?: number;
}) => {
  const handleChange = (query: string) => {
    setQueryData((prev) => ({ ...prev, query: query }));
  };
  return (
    <Paper
      component="form"
      sx={{
        py: 0.5,
        px: 1.5,
        display: "flex",
        alignItems: "center",
        width: "100%",
        maxWidth: maxWidth,
        borderRadius: "10px",
        border: "1px solid #DFDDDD",
        flex: 1,
      }}
      elevation={0}
    >
      <IconButton sx={{ p: 0, pointerEvents: "none" }} aria-label="search">
        <SearchIcon fontSize="small" />
      </IconButton>

      <InputBase
        sx={{
          ml: 1.5,
          flex: 1,
        }}
        placeholder={translation.search}
        inputProps={{ "aria-label": "Search" }}
        value={queryData.query === null ? "" : queryData.query}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      />
    </Paper>
  );
};
export const SearchBarForNormalUse = ({
  query,
  setQuery,
}: {
  query: string | null;
  setQuery: (value: React.SetStateAction<string | null>) => void;
}) => {
  const handleChange = (query: string) => setQuery(query);

  return (
    <Paper
      component="form"
      sx={{
        py: 0.5,
        px: 1.5,
        display: "flex",
        alignItems: "center",
        width: "100%",
        maxWidth: 400,
        borderRadius: "10px",
        border: "1px solid #DFDDDD",
      }}
      elevation={0}
    >
      <IconButton sx={{ p: 0, pointerEvents: "none" }} aria-label="search">
        <SearchIcon fontSize="small" />
      </IconButton>

      <InputBase
        sx={{ ml: 1.5, flex: 1 }}
        placeholder={translation.search}
        inputProps={{ "aria-label": "Search" }}
        value={query === null ? "" : query}
        onChange={(e) => handleChange(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      />
    </Paper>
  );
};

const DownloadButton = () => {
  return (
    <CloudDownloadOutlinedIcon
      sx={{
        color: "#312F30B2",
        cursor: "pointer",
      }}
    />
  );
};

const BulkAction = ({
  moduleList,
  setModuleList,
  selected,
}: {
  moduleList: API_Teacher_Module_Manage[];
  setModuleList: React.Dispatch<
    React.SetStateAction<API_Teacher_Module_Manage[]>
  >;
  selected: string[];
}) => {
  const openSnack = useSnack();

  const { role } = useStore((state) => ({
    role: state.profileDetails.role,
  }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePublish = async () => {
    const moduleIdList = moduleList
      .filter(
        (module) =>
          selected.includes(module.id.toString()) && !module.is_published
      )
      .map((module) => module.id.toString());
    if (moduleIdList.length === 0) {
      openSnack("Selected module(s) already published.", false);
      return;
    }
    const res = await bulkPublishModule(
      true,
      moduleIdList,
      localStorage.getItem("access")
    );
    if (res === "Success") {
      const updatedModuleList = moduleList.map((module_) => {
        if (moduleIdList.includes(module_.id.toString())) {
          return {
            ...module_,
            is_published: true,
          };
        }
        return module_;
      });
      setModuleList(updatedModuleList);
      openSnack(
        `${selected.length} module${selected.length > 1 ? "s" : ""} published!`,
        true
      );
    } else {
      openSnack(res, false);
    }
  };

  const handleUnpublish = async () => {
    const moduleIdList = moduleList
      .filter(
        (module) =>
          selected.includes(module.id.toString()) && module.is_published
      )
      .map((module) => module.id.toString());
    if (moduleIdList.length === 0) {
      openSnack("Selected module(s) already unpublished.", false);
      return;
    }
    const res = await bulkPublishModule(
      false,
      moduleIdList,
      localStorage.getItem("access")
    );
    if (res === "Success") {
      const updatedModuleList = moduleList.map((module_) => {
        if (moduleIdList.includes(module_.id.toString())) {
          return {
            ...module_,
            is_published: false,
          };
        }
        return module_;
      });
      setModuleList(updatedModuleList);
      openSnack(
        `${selected.length} module${
          selected.length > 1 ? "s" : ""
        } unpublished!`,
        true
      );
    } else {
      openSnack(res, false);
    }
  };

  const actions = [
    {
      title: "Publish",
      function: handlePublish,
    },
    {
      title: "Unpublish",
      function: handleUnpublish,
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          cursor: "pointer",
          border: "1px solid #DFDDDD",
          py: 1,
          px: 4,
          borderRadius: "10px",
          opacity: selected.length === 0 ? 0.5 : 1,
          pointerEvents: selected.length === 0 ? "none" : "auto",
          transition: "all 0.2s ease-in-out",
        }}
        onClick={handleClick}
      >
        <Typography>{translation.bulkAction}</Typography>

        <ExpandMoreIcon
          sx={{
            transform: "translateY(-1px)",
            color: "#312F30",
          }}
        />
      </Box>
      <Menu
        elevation={2}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "200px",
          },
        }}
      >
        <Typography
          sx={{
            px: 2,
            py: 1,
          }}
        >
          {`${selected.length} module${
            selected.length > 1 ? "s" : ""
          } selected`}
        </Typography>

        <Divider
          sx={{
            mb: 1,
          }}
        />

        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              action.function();
              handleClose();
            }}
          >
            {action.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SecondRow;

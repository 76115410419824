import { Box, Typography, Avatar } from "@mui/material";
import Logo from "images/logo-2-colour.png";
import { API_Profile_Short } from "types/profile";
import { translation } from "constants/translation";
import { useNavigate } from "react-router-dom";

const ProfileAvatarList = ({
  groupTeacherList,
  groupChildList,
  children,
}: {
  groupTeacherList: API_Profile_Short[];
  groupChildList: API_Profile_Short[];
  children?: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        mt: {
          xs: 5,
          lg: 14,
        },
        mb: 3,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
          height: "80%",
          justifyContent: "space-around",
        }}
      >
        <ProfileAvatarRow
          title={translation.children}
          groupProfileList={groupChildList}
        />
        <ProfileAvatarRow
          title={translation.teachers}
          groupProfileList={groupTeacherList}
        />
        {children}
      </Box>
    </Box>
  );
};

const ProfileAvatarRow = ({
  title,
  groupProfileList,
  avatarSize = 80,
}: {
  title: string;
  groupProfileList: API_Profile_Short[];
  avatarSize?: number;
}) => {
  const navigate = useNavigate();
  const isChild = title === translation.children;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "1.5rem",
          mb: 1,
        }}
      >
        {title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          gap: 4,
          pb: 0.5,
          "& > *:first-of-type": {
            marginLeft: "auto",
          },
          "& > *:last-of-type": {
            marginRight: "auto",
          },
          width: "100%",
          overflow: "auto",
        }}
      >
        {groupProfileList.length > 0 ? (
          groupProfileList.map((profile, index) => (
            <Box
              key={index}
              sx={{
                cursor: isChild ? "pointer" : "default",
              }}
              onClick={() => {
                if (isChild) {
                  navigate(profile.id.toString());
                }
              }}
            >
              <Avatar
                src={profile.image ? profile.image : Logo}
                sx={{
                  mb: 1,
                  width: { xs: avatarSize, md: avatarSize + 20 },
                  height: { xs: avatarSize, md: avatarSize + 20 },
                  mx: "auto",
                  backgroundColor: "primary.main",
                  "&:hover": {
                    border: isChild ? "2px solid var(--primary-main)" : "",
                  },
                }}
                imgProps={{
                  onError: (
                    event: React.SyntheticEvent<HTMLImageElement, Event>
                  ) => {
                    event.currentTarget.onerror = null;
                    event.currentTarget.src = Logo;
                  },
                }}
              ></Avatar>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "1.125rem",
                  lineHeight: "1.25",
                  fontWeight: "normal",
                  maxWidth: { xs: "80px", sm: "100px" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  lineClamp: 2,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {profile.preferred_name}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography>
            {isChild
              ? translation.noChildAssigned
              : translation.noTeacherAssigned}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProfileAvatarList;

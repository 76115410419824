export const languageOptions = {
  en: "English",
  zh: "中文",
  id: "Bahasa",
  ur: "اردو", // Urdu
  fr: "Français", // French
};

export const languageList = ["en", "zh", "id", "ur", "fr"];

export const languageStr = (currLanguage: string) => {
  switch (currLanguage) {
    case "en":
      return languageOptions.en;
    case "zh":
      return languageOptions.zh;
    case "id":
      return languageOptions.id;
    case "ur":
      return languageOptions.ur;
    case "fr":
      return languageOptions.fr;
    default:
      return languageOptions.en;
  }
};

import { Box, Typography } from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const BackpackTitle = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "2px",
        alignItems: "center",
        justifyContent: "center",
        mt: 2,
      }}
    >
      <ShoppingBagIcon sx={{ fontSize: "18px" }} />
      <Typography sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
        Backpack
      </Typography>
    </Box>
  );
};
export default BackpackTitle;

import CloseIcon from "@mui/icons-material/Close";

import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Typography,
  List,
  ListItem,
} from "@mui/material";

import { translation } from "constants/translation";
import { useNavigate } from "react-router-dom";
import CompactViewImage from "images/compact-view-image.png";
import Image from "components/Image/Image";

const CompactViewModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              p: 4,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              // border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              borderRadius: "10px",
              overflow: "overlay",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                // color: 'txt.secondary',
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5">{`${translation.whatIsParentView}?`}</Typography>
              <Image
                src={CompactViewImage}
                alt="compact-view-image"
                objectFit="cover"
              />
              <List
                sx={{
                  p: "0px !important",
                  listStyleType: "disc",
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  {translation.showDevGoalWithRecordedLearningMoment}
                </ListItem>
                <ListItem>{translation.filterAndShowParent}</ListItem>
              </List>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CompactViewModal;

import EditProjectForm from "components/ProjectForms/EditProjectForm/EditProjectForm";
import EditLessonForm from "components/ProjectForms/EditLessonForm";
import EditActivityForm from "components/ProjectForms/EditActivityForm";
import CreateActivityForm from "components/ProjectForms/CreateActivityForm";
import CreateLessonForm from "components/ProjectForms/CreateLessonForm";

import { Selected } from "./types";

const ProjectForm = ({
  selected,
  handleClose,
}: {
  selected: Selected;
  handleClose: () => void;
}) => {
  return selected.id !== "-1" ? (
    selected.type === "Project" ? (
      <EditProjectForm handleClose={handleClose} />
    ) : selected.type === "Lesson" ? (
      <EditLessonForm
        assessment={selected.assessment}
        lessonID={selected.id}
        handleClose={handleClose}
      />
    ) : (
      <EditActivityForm
        activityID={selected.id}
        lessonID={selected.parentId!}
        handleClose={handleClose}
      />
    )
  ) : selected.type === "Lesson" ? (
      <CreateLessonForm
        assessment={selected.assessment}
        projectID={selected.parentId!}
        handleClose={handleClose}
    />
  ) : (
    <CreateActivityForm
      assessment={selected.assessment}
      lessonID={selected.parentId!}
      handleClose={handleClose}
    />
  );
};

export default ProjectForm;

import { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useStore from "store/store";
import { CURLANG, TEACHER_PORTFOLIO } from "constants/url";

import TeacherArchiveMiniDrawer from "./TeacherArchiveMiniDrawer";
import TeacherArchiveMiniDrawerMobile from "./TeacherArchiveMiniDrawerMobile";
import TeacherArchiveBreadcrumbs from "./TeacherArchiveBreadcrumbs";
import { teacherArchiveTabs } from "./teacherArchivePageConstant";

const TeacherArchivePage = () => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState<string>(teacherArchiveTabs[0]);

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  useEffect(() => {
    const landingScreen = String(pathname).split("/").slice(-1)[0];

    let returnFlag = false;
    teacherArchiveTabs.forEach((tabs) => {
      if (String(pathname).split("/").slice(-2).join("/").includes(tabs)) {
        setCurrentTab(tabs);
        returnFlag = true;
      }
    });
    if (returnFlag) {
      return;
    }

    if (!teacherArchiveTabs.includes(landingScreen)) {
      navigate(
        `${CURLANG(currLanguage)}/${TEACHER_PORTFOLIO}/${teacherArchiveTabs[0]}`
      );
    } else {
      setCurrentTab(landingScreen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navigate]);

  return isLg ? (
    <TeacherArchivePageContent currentTab={currentTab} />
  ) : (
    <TeacherArchivePageMobileContent currentTab={currentTab} />
  );
};

const TeacherArchivePageContent = ({ currentTab }: { currentTab: string }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
      }}
    >
      <TeacherArchiveMiniDrawer currentTab={currentTab} />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          px: 1.5,
          py: 4,
          mx: { xs: "10px", md: "60px" },
        }}
      >
        <TeacherArchiveBreadcrumbs currentTab={currentTab} />
        <Outlet />
      </Box>
    </Box>
  );
};

const TeacherArchivePageMobileContent = ({
  currentTab,
}: {
  currentTab: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          backgroundColor: `#F6F6F6`,
          width: "100%",
          position: "sticky",
          top: { xs: 56, sm: 64 },
          zIndex: 2,
          flexDirection: "row",
          overflowX: "auto",
          overflowY: "visible",
          "::-webkit-scrollbar": {
            width: 5,
            height: 5,
          },
          gap: 1,
          p: 0.5,
          mb: 0.5,
        }}
      >
        <TeacherArchiveMiniDrawerMobile currentTab={currentTab} />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          py: 4,
          mx: "20px",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default TeacherArchivePage;

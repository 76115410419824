import React, { Fragment, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { API_Group_Id_Only } from "types/group";
import { API_Child } from "types/profile";
import { Avatar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { AddButton } from "components/Button/Button";
import CreateProfileModal from "containers/SiteAdminPage/ManagementPage/components/CreateProfileModal";
import { translation } from "constants/translation";

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
  return [...a, ...not(b, a)];
}

export default function ChildTransferList({
  setSelectedChild,
  currentGroupData,
  childList,
}: {
  setSelectedChild: React.Dispatch<React.SetStateAction<number[]>>;
  currentGroupData: API_Group_Id_Only;
  childList: API_Child[];
}) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [childrenNotInGroup, setChildrenNotInGroup] = React.useState<
    readonly number[]
  >([]);
  const [childrenInGroup, setChildrenInGroup] = React.useState<
    readonly number[]
  >([]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const initialChildList = () => {
      return currentGroupData.children;
    };

    const childrenNotInGroupTemp = () => {
      const notSelectedChildren = childList.filter((child) => {
        return !initialChildList().includes(child.id);
      });

      return notSelectedChildren.map((child) => {
        return child.id;
      });
    };
    setSelectedChild(initialChildList());
    setChildrenInGroup(initialChildList());
    setChildrenNotInGroup(childrenNotInGroupTemp());
  }, [childList, currentGroupData.id]);

  const childrenNotInGroupChecked = intersection(checked, childrenNotInGroup);
  const childrenInGroupChecked = intersection(checked, childrenInGroup);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (childrenId: readonly number[]) =>
    intersection(checked, childrenId).length;

  const handleToggleAll = (childrenId: readonly number[]) => () => {
    if (numberOfChecked(childrenId) === childrenId.length) {
      setChecked(not(checked, childrenId));
    } else {
      setChecked(union(checked, childrenId));
    }
  };

  const handleCheckedAssignedChildren = () => {
    setChildrenInGroup(childrenInGroup.concat(childrenNotInGroupChecked));
    setSelectedChild(childrenInGroup.concat(childrenNotInGroupChecked));
    setChildrenNotInGroup(not(childrenNotInGroup, childrenNotInGroupChecked));
    setChecked(not(checked, childrenNotInGroupChecked));
  };

  const handleCheckedUnassignedChildren = () => {
    setChildrenNotInGroup(childrenNotInGroup.concat(childrenInGroupChecked));
    setChildrenInGroup(not(childrenInGroup, childrenInGroupChecked));
    setSelectedChild(not(childrenInGroup, childrenInGroupChecked));
    setChecked(not(checked, childrenInGroupChecked));
  };

  const CustomList = (
    title: React.ReactNode,
    childrenId: readonly number[],
    childList: API_Child[],
    addButton: boolean
  ) => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #98C4C5",
          borderRadius: "10px",
          width: "100%",
          height: "100%",
        }}
      >
        <CardHeader
          sx={{ py: "8px", pr: "16px", pl: "10px", m: 0 }}
          avatar={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Checkbox
                sx={{
                  m: 0,
                  p: 0,
                }}
                onClick={handleToggleAll(childrenId)}
                checked={
                  numberOfChecked(childrenId) === childrenId.length &&
                  childrenId.length !== 0
                }
                indeterminate={
                  numberOfChecked(childrenId) !== childrenId.length &&
                  numberOfChecked(childrenId) !== 0
                }
                disabled={childrenId.length === 0}
                inputProps={{
                  "aria-label": "all childrenId selected",
                }}
              />
            </Box>
          }
          title={
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                    {title}
                  </Typography>
                  <Typography sx={{ color: "#8C8C8C" }}>
                    {numberOfChecked(childrenId)}/{childrenId.length} selected
                  </Typography>
                </Box>

                {addButton && (
                  <AddButton
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      maxWidth: 1,
                      maxHeight: 1,
                      background: "None",
                      color: "#98C4C5",
                    }}
                    btnText={``}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  />
                )}
              </Box>
            </>
          }
        />

        <Divider sx={{ borderColor: "primary.main" }} />

        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
            }
          }}
        />

        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
          <List
            sx={{
              bgcolor: "background.paper",
              overflowX: "hidden",
              overflowY: "auto",
            }}
            dense
            component="div"
            role="list"
          >
            {childrenId.map((childId: number) => {
              const labelId = `transfer-list-all-item-${childId}-label`;
              const targetChild = childList.filter((child) => {
                return child.id === childId;
              })[0];

              return (
                <Fragment key={childId}>
                  {targetChild &&
                  targetChild.preferred_name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ? (
                    <ListItem
                      key={childId}
                      role="listitem"
                      button
                      onClick={handleToggle(childId)}
                      sx={{ mx: 0, px: 0 }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          sx={{ mx: 0, px: "16px" }}
                          checked={checked.indexOf(childId) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Avatar
                              alt={targetChild.preferred_name}
                              src={targetChild.image}
                            />
                            <Typography
                              sx={{ fontSize: "16px", fontWeight: "400" }}
                            >
                              {targetChild.preferred_name}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  ) : (
                    <> </>
                  )}
                </Fragment>
              );
            })}
          </List>
        </Box>
      </Card>
    );
  };

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <CreateProfileModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        role={"CH"}
        groupId={currentGroupData.id}
      />
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        sx={{
          m: 0,
          height: "100%",
          width: "100%",
          gap: isXs ? 2 : 0,
        }}
      >
        <Grid
          item
          sx={{
            height: isXs ? "90vh" : "100%",

            m: 0,
            p: 0,
            width: isXs ? "100%" : "40%",
            flexGrow: 1,
          }}
        >
          {CustomList(
            "Children To Be Assigned",
            childrenNotInGroup,
            childList,
            true
          )}
        </Grid>
        <Grid item>
          <Grid
            container
            direction={{ xs: "row", sm: "column" }}
            alignItems="center"
            sx={{ px: "10px" }}
          >
            <Button
              sx={{
                my: { xs: 1, sm: 0.5 },
                mx: { xs: 1, sm: 0 },
                transform: { xs: "rotate(90deg)", sm: "rotate(0deg)" },
                padding: { xs: "10px 20px" },
                minWidth: { xs: 30, md: 45 },
                minHeight: { xs: 30, md: 45 },
              }}
              variant="outlined"
              size="medium"
              onClick={handleCheckedAssignedChildren}
              disabled={childrenNotInGroupChecked.length === 0}
              aria-label="move selected childrenInGroup"
            >
              &gt;
            </Button>
            <Button
              sx={{
                my: { xs: 1, sm: 1 },
                mx: { xs: 1, sm: 0 },
                transform: { xs: "rotate(90deg)", sm: "rotate(0deg)" },
                padding: { xs: "10px 20px" },
                minWidth: { xs: 30, md: 45 },
                minHeight: { xs: 30, md: 45 },
              }}
              variant="outlined"
              size="medium"
              onClick={handleCheckedUnassignedChildren}
              disabled={childrenInGroupChecked.length === 0}
              aria-label="move selected childrenNotInGroup"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            height: isXs ? "90vh" : "100%",
            flexGrow: 1,
            m: 0,
            p: 0,
            width: isXs ? "100%" : "40%",
          }}
        >
          {CustomList("Children in Class", childrenInGroup, childList, false)}
        </Grid>
      </Grid>
    </Box>
  );
}

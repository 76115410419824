import { useState } from "react";
import { Box } from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import DownloadIcon from "@mui/icons-material/Download";

import ChildrenPhotoBook from "./ChildrenPhotoBook";
import useStore from "store/store";
import { saveAs } from "file-saver";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import SmallLoadingIndicator from "components/LoadingIndicator/SmallLoadingIndicator";

import PhotoBookPageOneComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageOneComponent";
import PhotoBookPageTwoComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageTwoComponent";
import PhotoBookPageThreeComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageThreeComponent";
import PhotoBookPageFourComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageFourComponent";
import PhotoBookPageFiveComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageFiveComponent";
import PhotoBookPageSixComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageSixComponent";
import PhotoBookPageSevenComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageSevenComponent ";
import PhotoBookPageEightComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageEightComponent";
import PhotoBookPageElevenComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageElevenComponent";
import PhotoBookPageTwelveComponent from "./Templates/PhotoBookPDFComponents/PhotoBookPageTwelveComponent";
import { componentList } from "./Templates/photoBookCommonConstant";

const DownloadPDFButton = ({
  projectName,
  childName,
  moduleName,
  projectImageSrc,
  squareImages,
  horizontalImages,
  verticalImages,
}: {
  projectName: string;
  childName: string;
  moduleName: string;
  projectImageSrc: string;
  squareImages: ImageType[];
  horizontalImages: ImageType[];
  verticalImages: ImageType[];
}) => {
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0); //Progress is useless here as it's generating PDF that takes time, but no way to track
  const [isCreatingReport, setIsCreatingReport] = useState<boolean>(false); //isGeneratingPDF replace
  // const [imageListToRender, setImageListToRender] = useState<ImageType[][]>([]);
  // const [indexToRender, setIndexToRender] = useState<number[]>([]);

  const [componentTemplateList, setComponentTemplateList] = useState<any>({
    0: PhotoBookPageOneComponent,
    1: PhotoBookPageTwoComponent,
    2: PhotoBookPageThreeComponent,
    3: PhotoBookPageFourComponent,
    4: PhotoBookPageFiveComponent,
    5: PhotoBookPageSixComponent,
    6: PhotoBookPageSevenComponent,
    7: PhotoBookPageEightComponent,
    8: PhotoBookPageElevenComponent,
    9: PhotoBookPageTwelveComponent,
  });

  const downloadPDF = async () => {
    if (isGeneratingPDF) return;

    setIsGeneratingPDF(true);

    // INFO

    let imageListToRender: ImageType[][];

    // console.log("scale: ", scale);
    const horizontalImagesCopy: ImageType[] = JSON.parse(
      JSON.stringify([...horizontalImages, ...squareImages])
    );
    const verticalImagesCopy: ImageType[] = JSON.parse(
      JSON.stringify(verticalImages)
    );

    const imageList: ImageType[][] = [];
    const indexToRender: number[] = [];
    let flag = true;
    while (
      (horizontalImagesCopy.length > 0 || verticalImagesCopy.length > 0) &&
      flag === true
    ) {
      for (let i = 0; i < componentList.length; i++) {
        // console.log("horizontalImagesCopy: ", horizontalImagesCopy);
        // console.log("verticalImagesCopy: ", verticalImagesCopy);
        if (
          horizontalImagesCopy.length >= componentList[i].horizontal &&
          verticalImagesCopy.length >= componentList[i].vertical
        ) {
          const masterImages = [];
          if (componentList[i].horizontal > 0) {
            for (let j = 0; j < componentList[i].horizontal; j++) {
              const horizontalImage = horizontalImagesCopy.pop()!;
              masterImages.push(horizontalImage!);
            }
          }
          if (componentList[i].vertical > 0) {
            for (let k = 0; k < componentList[i].vertical; k++) {
              const verticalImage = verticalImagesCopy.pop()!;
              masterImages.push(verticalImage!);
            }
          }
          imageList.push(masterImages);
          indexToRender.push(i);
        }
        if (indexToRender.length === 20) {
          flag = false;
          break;
        }
      }
    }

    imageListToRender = imageList;

    // INFO

    const blob = await pdf(
      <ChildrenPhotoBook
        projectName={projectName}
        childName={childName}
        moduleName={moduleName}
        projectImageSrc={projectImageSrc}
        imageListToRender={imageListToRender}
        indexToRender={indexToRender}
        componentTemplateList={componentTemplateList}
        currLanguage={currLanguage}
      />
    ).toBlob();
    saveAs(blob, `${childName}-${projectName}-photo-book`);

    setIsGeneratingPDF(false);
  };

  return (
    <Box onClick={downloadPDF}>
      {!isGeneratingPDF ? (
        <DownloadIcon
          sx={{
            cursor: "pointer",
            transition: "all 100ms ease-in-out",
            "&:hover": {
              color: "var(--primary-main)",
              transition: "all 100ms ease-in-out",
            },
          }}
        />
      ) : (
        <SmallLoadingIndicator />
      )}
    </Box>
  );
};

export default DownloadPDFButton;

import { useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import SearchBar from "components/SearchBar/SearchBar";
import QuickQuestionsModal from "containers/QuickQuestions/QuickQuestionsModal";

import DevGaolsSection from "./DevGoalsSection";
import Assessments from "./Assessments";

const AssessmentsPage = () => {
  const [query, setQuery] = useState<string | null>(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isMobileView && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 20px", // padding to search bar and filter icon
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <SearchBar handleChange={(query: string) => setQuery(query)} />
              <IconButton onClick={() => setIsFilterDrawerOpen(true)}>
                <FilterListIcon />
              </IconButton>
            </div>
          </Box>

          <Drawer
            anchor="right"
            open={isFilterDrawerOpen}
            onClose={() => setIsFilterDrawerOpen(false)}
            PaperProps={{
              sx: {
                padding: 2,
                gap: 2,
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
              <Typography sx={{ fontWeight: "500" }}>Browse</Typography>
              <SearchBar
                placeholder="Search for Assessment"
                sx={{
                  width: "100%",
                }}
                handleChange={(query: string) => setQuery(query)}
              />
            </Box>
            <DevGaolsSection />
          </Drawer>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {!isMobileView && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "260px", md: "300px" },
              minWidth: { xs: "260px", md: "300px" },
              p: "24px",
              gap: 3,
              backgroundColor: "neutral.100",
              borderRight: "1px solid",
              borderColor: "neutral.300",
              overflowY: "scroll",
              overflowX: "clip",
              height: "calc(100vh - 64px)",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
              <Typography sx={{ fontWeight: "500" }}>Browse</Typography>
              <SearchBar
                placeholder="Search for Assessment"
                sx={{ width: "100%" }}
                handleChange={(query: string) => setQuery(query)}
              />
            </Box>
            <DevGaolsSection />
          </Box>
        )}
        <Assessments query={query} />
      </Box>
      <QuickQuestionsModal />
    </>
  );
};

export default AssessmentsPage;

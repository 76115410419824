import React from "react";
import { Box } from "@mui/material";

import UnderConstruction from "components/UnderConstruction/UnderConstruction";
import TeacherLibrary from "./TeacherLibrary/TeacherLibrary";

const TeacherPage = () => {
  return (
    // <Box>
    //   <UnderConstruction title='Teacher Page' />
    // </Box>
    <div>
      <TeacherLibrary />
    </div>
  );
};

export default TeacherPage;

import { useState, useEffect, useRef } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, TextField, IconButton, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import useStore from "store/store";
import { useLocation, useParams, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Image from "components/Image/Image";
import { translation } from "constants/translation";
import { API_Profile_Short } from "types/profile";
import SearchIcon from "@mui/icons-material/Search";
import { isProfileInCreateGroupRoleList } from "helper/helper";
import Logo from "images/logo-2-colour.png";
import BlossomsIcon from "images/age-group/blossoms-icon.png";
import BudsIcon from "images/age-group/buds-icon.png";
import SproutsIcon from "images/age-group/sprouts-icon.png";
import { BLOSSOMS, BUDS, SPROUTS } from "constants/project-lesson-activity";

const GroupPage = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const ageCategories = [
    {
      name: BLOSSOMS,
      src: BlossomsIcon,
    },
    {
      name: BUDS,
      src: BudsIcon,
    },
    {
      name: SPROUTS,
      src: SproutsIcon,
    },
  ];
  const { groupSlug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string>("children");

  const { groupList, profileDetails } = useStore((state) => ({
    groupList: state.groupList,
    profileDetails: state.profileDetails,
  }));

  // console.log(profileDetails);

  const selectedGroup = groupList.filter((group) => group.slug === groupSlug);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  let filteredChildren: API_Profile_Short[] = [];
  let filteredTeachers: any = [];
  if (selectedGroup.length > 0) {
    let children = selectedGroup[0].children as API_Profile_Short[];
    const teachers = selectedGroup[0].teachers;

    children = children.sort((a, b) =>
      sortAscending ? a.full_name.localeCompare(b.full_name) : b.full_name.localeCompare(a.full_name)
    );

    filteredChildren = children.filter((child) =>
      child.preferred_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    filteredTeachers = teachers.filter((teacher) =>
      teacher.preferred_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const groupedPeople = filteredChildren.reduce((groups: any, person: any) => {
    const firstLetter = person.full_name[0].toUpperCase();
    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(person);
    return groups;
  }, {});

  if (selectedGroup[0]?.age == "Sproutlings") {
    selectedGroup[0].age = "Sprouts";
    selectedGroup[0].communication_lvl = "Sprouts";
  } else if (selectedGroup[0]?.age == "Blooms") {
    selectedGroup[0].age = "Blossoms";
    selectedGroup[0].communication_lvl = "Blossoms";
  }

  const imageSrc = ageCategories.find((obj: any) => obj.name == selectedGroup[0]?.age)?.src;

  return (
    <>
      {location.pathname.includes("attendance") ? (
        // Your existing GroupPage content here
        <Outlet />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {/* name  com level edit button */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {/* name desc communication level */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                // backgroundColor: "lightblue",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // gap: 3, p: 4, pb: 0
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "24px",
                    color: "#323031",
                    ml: 4,
                  }}
                >
                  {selectedGroup.length > 0 && selectedGroup[0]?.name}
                </Typography>
                {/* communication icon in box below */}
                <Box sx={{ display: "flex", ml: 3 }}>
                  <Box
                    sx={{
                      width: "24px",
                      height: "24px",
                      overflow: "hidden",
                      mr: 1,
                    }}
                  >
                    <Image src={imageSrc} alt={selectedGroup[0]?.age} objectFit="contain" />
                  </Box>
                  <Typography>{selectedGroup.length > 0 && selectedGroup[0]?.age}</Typography>
                </Box>
              </Box>
              {/* edit button */}
              {isProfileInCreateGroupRoleList(profileDetails.role) && (
            <Box
              sx={{
                display: "flex",
                margin: { xs: 2, sm: 3, md: 5 },
              }}
              onClick={() => navigate("edit")}
            >
              <Button
                variant="outlined"
                sx={{
                  fontWeight: "450",
                  fontSize: isXs ? "14px" : "16px",
                  color: "#5C9391",
                }}
              >
                {translation.edit_group}
              </Button>
            </Box>
          )}
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#000000",
              mx: 4,
              // backgroundColor: "lightblue",
              width: "45%",
            }}
          >
            {selectedGroup[0]?.description}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ m: 4, display: "flex", gap: 2 }}>
              <Box
                sx={{
                  borderBottom: 2,
                  borderColor: selected === "children" ? "primary.main" : "transparent",
                  display: "flex",
                }}
              >
                <Button
                  sx={{
                    fontSize: isXs ? "12px" : "14px",
                  }}
                  variant="text"
                  onClick={() => setSelected("children")}
                  disableRipple
                >
                  CHILDREN ({selectedGroup[0]?.children.length})
                </Button>
              </Box>
              <Box
                sx={{
                  borderBottom: 2,
                  borderColor: selected === "teachers" ? "primary.main" : "transparent",
                  display: "flex",
                }}
              >
                <Button
                  sx={{
                    fontSize: isXs ? "12px" : "14px",
                  }}
                  variant="text"
                  onClick={() => setSelected("teachers")}
                  disableRipple
                >
                  TEACHERS ({selectedGroup[0]?.teachers.length})
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                width: "70%",
                // backgroundColor: "red",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 3,
                mr: 4,
              }}
            >
              <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                Total:{" "}
                {selected == "children"
                  ? filteredChildren.length + " Children"
                  : filteredTeachers.length + " Teachers"}
              </Typography>
              <TextField
                sx={{
                  zIndex: 1,
                  width: {
                    xs: "40%",
                    sm: "35%",
                    lg: "25%",
                  },
                }}
                size="small"
                id="search-bar"
                className="text"
                value={searchQuery}
                variant="outlined"
                placeholder="Search..."
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "gray" }} />,
                }}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </Box>
          </Box>
          {/* <ChildRow children={selectedGroup[0]?.children} /> */}
          <Box sx={{ width: "98%" }}>
            <Display
              selected={selected}
              selectedGroup={selectedGroup}
              groupedPeople={groupedPeople}
              filteredTeachers={filteredTeachers}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default GroupPage;

const Display = ({
  selected,
  selectedGroup,
  groupedPeople,
  filteredTeachers,
}: {
  selected: any;
  selectedGroup: any;
  groupedPeople: any;
  filteredTeachers: any;
}) => {
  const letters = Object.keys((groupedPeople as any) || {}).sort();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // overflowX: "auto",
      }}
    >
      {selected == "children" &&
        letters.map((letter: any, index: number) => {
          return (
            <LetterContainer
              key={index}
              letter={letter}
              id={index}
              lengthLetters={letters.length}
              groupedPeople={groupedPeople}
            />
          );
        })}

      {/* {selected == "teachers" && selectedGroup[0]?.teachers.map((teacher: any)) => {
        return (
          <Box>helps</Box>
        )
      }} */}
      {selected == "teachers" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            ml: 5,
          }}
        >
          {filteredTeachers.map((teacher: any) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mr: 5,
                  gap: 3,
                }}
                // ref={containerRef}
                onClick={() => {}}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    overflow: "hidden",
                    position: "relative",
                    width: 200, // You can adjust this
                    height: 200, // You can adjust this
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      paddingTop: "100%",
                    }}
                  >
                    <Image
                      src={teacher.image || Logo}
                      alt={teacher.full_name}
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000000",
                  }}
                >
                  {teacher.full_name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

const LetterContainer = ({
  letter,
  id,
  lengthLetters,
  groupedPeople,
}: {
  letter: any;
  id: any;
  lengthLetters: number;
  groupedPeople: any;
}) => {
  const containerRef: any = useRef(null);
  const boxRef: any = useRef(null);

  const [scrollIndex, setScrollIndex] = useState<any>(0);
  const [scrollCount, setScrollCount] = useState<any>(0);
  const [scrollAmount, setScrollAmount] = useState<any>(0);
  // const [scrollCount, setScrollCount] = useState<any>(0);
  const numPersons = groupedPeople[letter]?.length;

  const getTotalWidth = () => {
    const computedStyle = getComputedStyle(boxRef.current);
    const width = parseFloat(computedStyle.width);
    const marginRight = parseFloat(computedStyle.marginRight);
    // console.log(width + marginRight);
    return width + marginRight;
  };

  useEffect(() => {
    setScrollCount(Math.floor(numPersons / Math.round(containerRef.current.offsetWidth / getTotalWidth())));
    setScrollAmount(Math.round(containerRef.current.offsetWidth / getTotalWidth()));
  }, []);

  const handleRightClick = () => {
    const newIndex = Math.min(scrollIndex + 1, scrollCount);

    if (newIndex !== scrollIndex) {
      setScrollIndex(newIndex);
    }
  };

  const handleLeftClick = () => {
    const newIndex = Math.max(scrollIndex - 1, 0);
    if (newIndex !== scrollIndex) {
      setScrollIndex(newIndex);
    }
  };
  useEffect(() => {
    if (containerRef.current) {
      const scrollPosition = scrollIndex * scrollAmount;
      containerRef.current.children[scrollPosition]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [scrollIndex]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
      }}
      key={letter}
    >
      <Typography
        sx={{
          // width: 30,

          fontWeight: "600",
          fontSize: "24px",
          color: "#91C6C5",
          ml: 2,
          display: "flex",
          justifyContent: "center",
          minWidth: 50,
          alignItems: "center",
        }}
      >
        {letter}
      </Typography>
      <IconButton
        sx={{ visibility: scrollCount != 0 ? "visible" : "hidden" }}
        disabled={scrollIndex == 0}
        onClick={() => {
          handleLeftClick();
        }}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
        ref={containerRef}
      >
        {groupedPeople[letter].map((person: any, index: any) => {
          return <Person person={person} key={index} boxRef={boxRef} />;
        })}
      </Box>
      <Box>
        <IconButton
          sx={{ visibility: scrollCount != 0 ? "visible" : "hidden" }}
          disabled={scrollIndex == scrollCount}
          onClick={() => {
            handleRightClick();
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const Person = ({ person, boxRef }: { person: any; boxRef: any }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 100,
        mr: 5,
      }}
      ref={boxRef}
      // ref={containerRef}
      onClick={() => {}}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          overflow: "hidden",
          position: "relative",
          width: 100, // You can adjust this
          height: 100, // You can adjust this
        }}
      >
        <Box
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            paddingTop: "100%",
          }}
        >
          <Image
            src={person.image || Logo}
            alt={person.full_name}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "400",
          color: "#000000",
        }}
      >
        {person.full_name}
      </Typography>
    </Box>
  );
};

import useStore from "store/store";

import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { translation } from "constants/translation";
import { useEffect, useState } from "react";
import CustomButton from "components/Button/Button";
import { CURLANG, HOME, LOGIN } from "constants/url";
import { sendResetPasswordEmail } from "api/auth-api";
import useSnack from "hooks/useSnack";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const { next } = useParams();
  const { currLanguage, profileID } = useStore((state) => state);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      openSnack("Invalid email format", false);
      setIsLoading(false);
      return;
    }

    await sendResetPasswordEmail(email);
    setIsSubmitted(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!!profileID) {
      if (next) {
        navigate(next);
      } else {
        navigate(`${CURLANG(currLanguage)}/${HOME}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileID]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "auto",
        maxWidth: 800,
        minWidth: { xs: 0, sm: 300, md: 500 },
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          // border: "2px solid var(--primary-light)",
          mt: "-30px",
        }}
        spacing={2}
      >
        {isSubmitted ? (
          <>
            <Typography sx={{ fontSize: 30, fontWeight: 500 }}>
              {translation.emailSubmittedTitle}
            </Typography>
            <Divider />
            <Typography>{translation.emailSubmittedResponse}</Typography>
          </>
        ) : (
          <>
            <Typography sx={{ fontSize: 30, fontWeight: 500 }}>
              {translation.findYourAccount}
            </Typography>
            <Divider />
            <Typography>{translation.enterYourEmail}</Typography>

            <TextField
              required
              label={translation.email}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
              <CustomButton
                buttonText={translation.cancel || "Cancel"}
                arrow={false}
                onClick={() => {
                  navigate(`${CURLANG(currLanguage)}/${LOGIN}`);
                }}
                isLoading={isLoading}
                type={"button"}
                btnType="outlined"
              />
              <CustomButton
                buttonText={translation.submit || "Submit"}
                arrow={false}
                disabled={email === ""}
                onClick={handleSubmit}
                type="submit"
                isLoading={isLoading}
              />
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ForgotPasswordPage;

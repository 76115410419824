import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  Fade,
  Modal,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { editImage } from "api/gallery-api";

import { API_Profile_Short } from "types/profile";
import { GalleryPhotoVideo } from "types/gallery";

import "components/ProjectSummaryScreen/ReactGridGallery.css";

import { translation } from "constants/translation";
import EditPhotoModal from "../Photo/EditPhotoModal";
import PhotoVideoList from "./PhotoVideoList";
import PhotoCaption from "./PhotoCaption";
import PhotoAndVideoListHeader from "./PhotoAndVideoListHeader";
import ImageAndVideoBox from "./ImageAndVideoBox"

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  photoVideoGalleryList: GalleryPhotoVideo[];
  galleryIndex: number;
  currPhotoVideoIndex: number;
  setCurrPhotoVideoIndex: React.Dispatch<React.SetStateAction<number>>;
  profileDict: {
    [id: string]: API_Profile_Short;
  };
  selectedProfiles: string[][][]; //Gallery, PhotoVideo index array, profile array
  setSelectedProfiles: React.Dispatch<React.SetStateAction<string[][][]>>;
  initializeGallery: () => Promise<void>;
  isLearningMomentPhotoAlbum?: boolean;
  lessonName: string;
}

// Pop out when click on image or video.
const PhotoVideoGalleryModal = ({
  open,
  setOpen,
  photoVideoGalleryList,
  galleryIndex,
  currPhotoVideoIndex,
  setCurrPhotoVideoIndex,
  profileDict,
  selectedProfiles,
  setSelectedProfiles,
  initializeGallery,
  isLearningMomentPhotoAlbum = false,
  lessonName
}: Props) => {

  const openSnack = useSnack();

  const {
    currLanguage,
  } = useStore((state) => state);

  const [editCaption, setEditCaption] = useState<boolean>(false);
  const [selectedPhotoVideoIDs, setSelectedPhotoVideoIDs] = useState<string[]>(
    []
  );
  const [openEditModal, setOpenEditModal] = useState<boolean>(false); // open edit modal or not
  const [editedPhoto, setEditedPhoto] = useState<File | undefined>(); // final output of edited photo
  const [photoId, setPhotoId] = useState<string>(""); //  raw photo id to pass
  const [photoName, setPhotoName] = useState<string>(""); // raw photo name to pass
  const [photoSource, setPhotoSource] = useState<string>(""); // raw source to pass  

  const handleClose = () => {
    setCurrPhotoVideoIndex(-1);
    setSelectedPhotoVideoIDs([]);
    setEditCaption(false);
    setOpen(false);
  };

  const handleCloseEditImageModal = () => {
    setPhotoName("");
    setPhotoSource("");
    setOpenEditModal(false);
  };

  const updateImage = async () => {
    if (editedPhoto === undefined || photoId === "") return;

    const res = await editImage(currLanguage, {
      id: photoId,
      image: editedPhoto,
    });

    if (res === "Error!") {
      openSnack(res, false);
    } else {
      openSnack(translation.success_update_image, true);
      setPhotoId("");
      setEditedPhoto(undefined);
      initializeGallery();
    }
  };

  useEffect(() => {
    updateImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedPhoto, photoId]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          m: "24px",
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: "800px",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: "5px 5px 20px var(--primary-main)",
              p: 4,
              borderRadius: "10px",
              overflow: "auto",
              maxHeight: "100%",
            }}
          >
            {currPhotoVideoIndex > -1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    pl: "16px",
                  }}
                  onClick={() => {
                    setCurrPhotoVideoIndex(-1);
                  }}
                >
                  <ArrowBackIcon sx={{ fontSize: "24px" }} />
                </Box>
                <ImageAndVideoBox
                  open={open}
                  photoVideoGalleryList={photoVideoGalleryList}
                  galleryIndex={galleryIndex}
                  currPhotoVideoIndex={currPhotoVideoIndex}
                  profileDict={profileDict}
                  selectedProfiles={selectedProfiles}
                  editedPhoto={editedPhoto}
                  photoId={photoId}
                  setSelectedProfiles={setSelectedProfiles}
                  setOpenEditModal={setOpenEditModal}
                  setPhotoName={setPhotoName}
                  setPhotoSource={setPhotoSource}
                  setEditedPhoto={setEditedPhoto}
                  setPhotoId={setPhotoId}
                  initializeGallery={initializeGallery}
                />
                <PhotoCaption
                  editCaption={editCaption}
                  setEditCaption={setEditCaption}
                  photoVideoGalleryList={photoVideoGalleryList}
                  galleryIndex={galleryIndex}
                  currPhotoVideoIndex={currPhotoVideoIndex}
                  initializeGallery={initializeGallery}
                  selectedPhotoVideoIDs={selectedPhotoVideoIDs}
                />
              </>
            )}
            <PhotoAndVideoListHeader
              lessonName={currPhotoVideoIndex === -1 ? lessonName : ''}
              selectedPhotoVideoIDs={selectedPhotoVideoIDs}
              handleClose={handleClose}
              setSelectedPhotoVideoIDs={setSelectedPhotoVideoIDs}
              initializeGallery={initializeGallery}
            />
            <PhotoVideoList
              fullFrame={currPhotoVideoIndex === -1}
              photoVideoGalleryList={photoVideoGalleryList}
              galleryIndex={galleryIndex}
              setCurrPhotoVideoIndex={setCurrPhotoVideoIndex}
              setEditCaption={setEditCaption}
              isLearningMomentPhotoAlbum={isLearningMomentPhotoAlbum}
              selectedPhotoVideoIDs={selectedPhotoVideoIDs}
              setSelectedPhotoVideoIDs={setSelectedPhotoVideoIDs}
            />
          </Box>
        </Fade>
      </Modal>
      {openEditModal && photoSource !== "" && (
        <EditPhotoModal
          open={openEditModal}
          handleClose={handleCloseEditImageModal}
          imageSrc={photoSource}
          setEditedImage={(file) => {
            setEditedPhoto(file)
            updateImage();
          }}
          imageName={photoName}
        />
      )}
    </>
  );
};

export default PhotoVideoGalleryModal;
import { Box, Stack, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import useSnack from "hooks/useSnack"
import useStore from "store/store"

import Button from "../../../../../../components/Button/Button";
import { editTeacherActivity } from "../../../../../../api/teacher-api";

import { translation } from "../../../../../../constants/translation";

import useCallbackPrompt from "../../../../../../hooks/useCallbackPrompt";

import {
  NameField,
  PublishField,
  ActivityTypeField,
  TextActivityComponentField,
  DurationField,
} from "../../TeacherFormComponents/ActivityFormComponents";
import BlockLeavingDialog from "../../../../../../components/Dialog/BlockLeavingDialog";
import {
  API_Edit_Teacher_Activity,
  API_Teacher_Text_Activity,
  API_Teacher_Text_Activity_Component,
} from "../../../../../../types/teacher";
import { DeleteActivityButton } from "./CommonActivityFormComponents";
import FlipBookPDFComponent from "../../TeacherFormComponents/FlipBookPDFComponent";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EditTextActivityForm = ({
  activityId,
  lessonId,
  handleClose,
}: {
  activityId: number;
  lessonId: number;
  handleClose: () => void;
}) => {
  // Better to split this into different types of activities to prevent complexity.
  const { currLanguage, teacherCurrentActivity, setTeacherCurrentActivity, setIsRefreshTeacherModule } =
    useStore((state) => ({
      currLanguage: state.currLanguage,
      teacherCurrentActivity: state.teacherCurrentActivity as API_Teacher_Text_Activity,
      setTeacherCurrentActivity: state.setTeacherCurrentActivity,
      setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
    }));
  const openSnack = useSnack();

  const [isCreatingActivity, setIsCreatingActivity] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [textActivityComponents, setTextActivityComponents] = useState<API_Teacher_Text_Activity_Component[]>(
    teacherCurrentActivity.text_activity_components || []
  );

  const [updatedActivity, setUpdatedActivity] = useState<API_Teacher_Text_Activity>({
    id: activityId,
    lesson: lessonId,
    resourcetype: teacherCurrentActivity.resourcetype,
    name: teacherCurrentActivity.name,
    description: teacherCurrentActivity.description,
    instruction: teacherCurrentActivity.instruction,
    slug: teacherCurrentActivity.slug,
    weight: teacherCurrentActivity.weight,
    is_published: teacherCurrentActivity.is_published,
    text_activity_components: teacherCurrentActivity.text_activity_components,
    duration:teacherCurrentActivity.duration,
  });

  const activityRef = useRef(updatedActivity);
  activityRef.current = updatedActivity;

  const submitEditForm = async () => {
    //Check
    if (updatedActivity.name === "") {
      //filled
      openSnack("Activity name is required!", false);
      return;
    }

    let editFailed = false;
    const activityData: API_Edit_Teacher_Activity = {
      id: activityId,
      lesson: lessonId,
      duration: updatedActivity.duration,
      resourcetype: teacherCurrentActivity.resourcetype,
    };

    if (updatedActivity.name !== teacherCurrentActivity.name) activityData.name = updatedActivity.name;
    if (updatedActivity.is_published !== teacherCurrentActivity.is_published)
      activityData.is_published = updatedActivity.is_published;
    activityData.text_activity_components = textActivityComponents;
    console.log("activityData: ", activityData);
    //regular data
    if (Object.keys(activityData).length > 0) {
      const res = await editTeacherActivity(
        currLanguage,
        activityData,
        setIsCreatingActivity,
        setProgressPercentage,
        localStorage.getItem("access")!
      );

      if (typeof res === "string") {
        // console.log("res: ", res);
        openSnack(`${translation.failUpdateActivity}: ${res}`, false);
        editFailed = true;
      }
    }

    if (!editFailed) {
      setIsRefreshTeacherModule(true);
      openSnack("Updated activity successfully!", true);
      handleClose();
    }
  };

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, showChanges, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  const dialogBody = {
    showDialog: showPrompt,
    confirmNavigation,
    cancelNavigation,
    handleAction: submitEditForm,
  };

  const [flipDimensions, setFlipDimensions] = useState({ width: 500, height: 500 }); // Default dimensions

  useEffect(() => {
    // Make sure the flip_book array exists and has at least one item before attempting to access its properties
    if (
      teacherCurrentActivity?.flip_book &&
      teacherCurrentActivity?.flip_book?.length > 0 &&
      teacherCurrentActivity.flip_book[0].file
    ) {
      const fileURL = teacherCurrentActivity.flip_book[0].file;
      pdfjs
        .getDocument(fileURL)
        .promise.then((pdfDoc) => {
          return pdfDoc.getPage(1);
        })
        .then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          setFlipDimensions({ width: viewport.width, height: viewport.height });
        })
        .catch((error) => {
          console.error("Error loading PDF document:", error);
        });
    }
  }, [teacherCurrentActivity.flip_book]);

  return (
    <>
      <BlockLeavingDialog {...dialogBody} />

      <Stack
        component="form"
        sx={{
          width: "100%",
        }}
        spacing={3}
        noValidate
        autoComplete="off"
      >
        <ActivityTypeField activity={updatedActivity} setActivity={setUpdatedActivity} isDisabled />
        <PublishField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <NameField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />
          <DurationField
          activity={updatedActivity}
          setActivity={setUpdatedActivity}
          onChange={(input) => {
            setShowDialog(true);
          }}
        />

        <TextActivityComponentField
          activityId={updatedActivity.id}
          textActivityComponents={textActivityComponents}
          setTextActivityComponents={setTextActivityComponents}
        />

        <Divider />

        {teacherCurrentActivity.flip_book && teacherCurrentActivity.flip_book.length > 0 && (
          <FlipBookPDFComponent
            file={teacherCurrentActivity.flip_book[0].file}
            dimensions={{ width: flipDimensions.width, height: flipDimensions.height }}
            scale={1}
          />
        )}

        <DeleteActivityButton activityId={activityId} handleClose={handleClose} />

        <Box
          sx={{
            display: "flex",
            gap: "24px",
            position: "fixed",
            bottom: 24,
            right: 34,
            zIndex: 10,
          }}
        >
          <Button
            buttonText={
              isCreatingActivity
                ? progressPercentage !== 100
                  ? `${progressPercentage}%`
                  : translation.uploadFinalizing
                : translation.update
            }
            style={{
              fontSize: "14px",
              backgroundColor: "var(--txt-secondary)",
              height: "auto",
            }}
            onClick={() => {
              submitEditForm();
            }}
            disabled={isCreatingActivity}
          />
        </Box>
      </Stack>
    </>
  );
};

export default EditTextActivityForm;

import React, { useState, useCallback } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";

import { translation } from "constants/translation";
import { Selected } from "./EditModulePage/EditModulePage";
import ModuleForm from "./ModuleForm";
import useStore from "store/store";
import Backdrop from "components/Backdrop";
import { Resizer } from "components/Resizer";

const ModuleResizableDrawer = ({
  selected,
  openDrawer,
  handleClose,
}: {
  selected: Selected | null;
  openDrawer: boolean;
  handleClose: () => void;
}) => {
  const { teacherCurrentModule, teacherCurrentLesson, teacherCurrentActivity } =
    useStore((state) => ({
      teacherCurrentModule: state.teacherCurrentModule,
      teacherCurrentLesson: state.teacherCurrentLesson,
      teacherCurrentActivity: state.teacherCurrentActivity,
    }));

  const defaultDrawerWidth = localStorage.getItem("drawerWidth")
    ? localStorage.getItem("drawerWidth")!
    : "65vw";

  const [drawerWidth, setDrawerWidth] = useState<string>(defaultDrawerWidth);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="right"
        open={openDrawer}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: drawerWidth,
            minWidth: "600px",
            maxWidth: "90vw",
          },
          sx: {
            boxShadow: 3,
          },
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {selected && (
          <Box
            sx={{
              height: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Resizer setDrawerWidth={setDrawerWidth} />
            <Box
              sx={{
                p: 3,
                pb: 1.5,
              }}
            >
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  borderRadius: "4px",
                  fontSize: "1.25rem",
                  mb: 0.75,
                  transition: "all 200ms ease-in-out",
                  color: "#444",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                    transition: "all 200ms ease-in-out",
                  },
                }}
                onClick={handleClose}
              />

              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: "#444",
                }}
              >
                {selected.type === "Module"
                  ? translation.module
                  : selected.type === "Lesson"
                  ? teacherCurrentModule.name
                    ? teacherCurrentModule.name
                    : ""
                  : selected.parentIndex !== undefined
                  ? `${translation.formatString(translation.lessonNo, {
                      number: selected.parentIndex + 1,
                    })}: ${
                      teacherCurrentModule.lessons[selected.parentIndex].name
                        ? teacherCurrentModule.lessons[selected.parentIndex]
                            .name
                        : ""
                    }`
                  : ""}
              </Typography>

              {selected.id !== "-1" ? (
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  {selected.type === "Module"
                    ? teacherCurrentModule.name
                      ? teacherCurrentModule.name
                      : ""
                    : selected.type === "Lesson"
                    ? `${translation.formatString(translation.lessonNo, {
                        number: selected.index + 1,
                      })} : ${
                        teacherCurrentLesson.name
                          ? teacherCurrentLesson.name
                          : ""
                      }`
                    : selected.parentIndex !== undefined
                    ? `${translation.formatString(translation.activityNo, {
                        number: selected.index + 1,
                      })}: ${
                        teacherCurrentActivity.name
                          ? teacherCurrentActivity.name
                          : ""
                      }`
                    : ""}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  {selected.type === "Lesson"
                    ? translation.add_lesson
                    : translation.add_activity}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                flex: 1,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  overflowX: "hidden",
                  overflowY: "auto",
                  p: 3,
                  pt: 3.5,
                  borderTop: "1px solid rgba(0,0,0,0.2)",
                }}
              >
                <ModuleForm selected={selected} handleClose={handleClose} />
              </Box>
            </Box>
          </Box>
        )}
      </Drawer>
      <Backdrop isHovering={openDrawer && isHovering} />
    </>
  );
};

export default ModuleResizableDrawer;

import { useState, MouseEvent, useEffect } from "react";
import { Box, Typography, Popover, Avatar, useTheme, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate, useParams } from "react-router-dom";
import { translation } from "constants/translation";
import useStore from "store/store";
import { API_Group_Full } from "types/group";
import Logo from "images/logo-2-colour.png";
import { useLocation } from "react-router-dom";

const ClassListMiniDrawer = () => {
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const navigate = useNavigate();
  const { groupSlug } = useParams();
  const { groupList, setCurrGroup } = useStore((state) => ({
    groupList: state.groupList,
    setCurrGroup: state.setCurrGroup,
  }));
  const [popOverGroupName, setPopOverGroupName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>, name: string) => {
    setPopOverGroupName(name);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const openPopOver = !!anchorEl;

  const handleDrawer = () => setOpen((prev) => !prev);

  useEffect(() => {
    if (groupList.length === 0) {
      return;
    }

    const storedGroupId = localStorage.getItem("curr_group_id");

    if (groupSlug) {
      const tempGroup = groupList.find((group) => group.slug === groupSlug);
      if (tempGroup) {
        setCurrGroup(tempGroup);
        localStorage.setItem("curr_group_id", String(tempGroup.id));
      }
    } else if (storedGroupId) {
      const storedGroup = groupList.find((group) => group.id === parseInt(storedGroupId));
      if (storedGroup) {
        setCurrGroup(storedGroup);
        navigate(storedGroup.slug);
      } else {
        // If stored group ID is invalid, default to the first group
        setCurrGroup(groupList[0]);
        navigate(groupList[0].slug);
      }
    } else {
      // Default to the first group in the list if no groupSlug and no stored group
      setCurrGroup(groupList[0]);
      navigate(groupList[0].slug);
    }
  }, [groupSlug, groupList, setCurrGroup, navigate]);

  useEffect(() => {
    if (isBelowSm) {
      setOpen(false);
    }
  }, [isBelowSm]);

  return (
    <Box
      sx={{
        position: "relative",
        flex: 100,
        maxWidth: open ? "300px" : 82,
        transition: "all 200ms ease-in-out",
      }}
    >
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflowX: "hidden",
          overflowY: "auto",
          // py: 1,
          // px: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(240, 240, 240, 0.5)",
            maxWidth: "300px",
            flex: open ? "1 0 auto" : "0 0 auto",
            p: 2,
            pt: "21px",
            overflowX: "hidden",
            whiteSpace: "nowrap",
            transition: "all 200ms ease-in-out",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mb: 1,
            }}
          >
            {open && (
              <Typography
                sx={{
                  fontWeight: "bold",
                  lineHeight: 1,
                  mr: "auto",
                }}
              >
                {location.pathname.split("/")[2] != "my-classes" ? translation.classInfo : "Class List"}
              </Typography>
            )}

            <Box
              sx={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                p: 0.75,
                m: 0.5,
                borderRadius: "999px",
                transition: "all 200ms ease-in-out",
                color: "#444",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
              }}
              onClick={handleDrawer}
            >
              {isBelowSm ? null : open ? (
                <CloseIcon fontSize="small" />
              ) : (
                <ChevronRightIcon fontSize="small" />
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
            {groupList.map((group, index) => (
              <DrawerButton
                key={index}
                group={group}
                open={open}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
              />
            ))}
          </Box>

          {!open && (
            <Popover
              sx={{
                pointerEvents: "none",
                borderRadius: "10px",
                ml: 1,
              }}
              elevation={2}
              open={openPopOver}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography
                sx={{
                  color: "#444",
                  p: 1.5,
                  lineHeight: 1,
                }}
              >
                {popOverGroupName}
              </Typography>
            </Popover>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const DrawerButton = ({
  group,
  open,
  handlePopoverOpen,
  handlePopoverClose,
}: {
  group: API_Group_Full;
  open: boolean;
  handlePopoverOpen: (event: MouseEvent<HTMLElement>, name: string) => void;
  handlePopoverClose: () => void;
}) => {
  const { groupSlug } = useParams();
  const location = useLocation();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const isActiveTab = group.slug === groupSlug;
  return (
    <Link
      to={location.pathname.includes("attendance") ? `${group.slug}/attendance` : `${group.slug}`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "8px",
        cursor: isActiveTab ? "default" : "pointer",
        textDecoration: "none",
        color: "#444",
        marginBottom: 1,
      }}
      onMouseEnter={(e) => handlePopoverOpen(e, group.name)}
      onMouseLeave={() => handlePopoverClose()}
      onClick={() => console.log(location.pathname)}
    >
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
          p: 0.5,
          width: "40px",
          height: "40px",
          borderRadius: "999px",
          border: "1px solid",
          borderColor: isActiveTab ? "#98C4C5" : "#F0F0F0",
          backgroundColor: isActiveTab ? "primary.light" : "var(--txt-light)",
        }}
      >
        <PlaylistAddCheckIcon
          sx={{
            backgroundColor: "white",
            borderRadius: "999px",
            p: "4px",
          }}
        />
      </Box> */}

      <Avatar
        src={group.image ? group.image : Logo}
        sx={{
          width: "40px",
          height: "40px",
          border: "4px solid",
          borderColor: isActiveTab ? "#98C4C5" : "#F0F0F0",
          backgroundColor: isActiveTab ? "primary.light" : "var(--txt-light)",
          display: "flex",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />

      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: isActiveTab ? "bold" : "normal",
              color: isActiveTab ? "primary.main" : "#444",
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {group.name}
          </Typography>
          {location.pathname.split("/")[2] == "my-classes" ? (
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#323031",
              }}
            >
              The {group.communication_lvl} group
            </Typography>
          ) : null}
        </Box>
      )}
    </Link>
  );
};

export default ClassListMiniDrawer;

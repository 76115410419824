import { StoreSlice } from './store';

export interface ToastSlice {
  snackOpen: boolean;
  snackSuccess: boolean;
  snackMessage: string;
  setSnackOpen: (snackOpen: boolean) => void;
  setSnackSuccess: (snackSuccess: boolean) => void;
  setSnackMessage: (snackMessage: string) => void;
}

export const createToastSlice: StoreSlice<ToastSlice> = (set, get) => ({
  snackOpen: false,
  snackSuccess: false,
  snackMessage: '',
  setSnackOpen: (snackOpen: boolean) => {
    set((prev: ToastSlice) => ({
      ...prev,
      snackOpen: snackOpen,
    }));
  },
  setSnackSuccess: (snackSuccess: boolean) => {
    set((prev: ToastSlice) => ({
      ...prev,
      snackSuccess: snackSuccess,
    }));
  },
  setSnackMessage: (snackMessage: string) => {
    set((prev: ToastSlice) => ({
      ...prev,
      snackMessage: snackMessage,
    }));
  },
});

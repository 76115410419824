import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { getAlbumByProject } from "api/gallery-api";
import {
  NoPhotoYet,
  Album,
  ImageType,
  ImageCard,
  containerStyles,
  ImagePreviewModal,
} from "./PhotoAlbumComponents";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import { translation } from "constants/translation";

const ProjectAlbum = ({
  onLoad,
}: {
  onLoad: (moduleName: string, projectName: string) => void;
}) => {
  const [searchParams] = useSearchParams();

  const moduleId = searchParams.get("module");
  const projectId = searchParams.get("project");

  const { childID } = useParams();

  const [album, setAlbum] = useState<Album[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<ImageType>();

  useEffect(() => {
    if (!childID || !moduleId || !projectId) return;

    const initializeChildImages = async () => {
      setIsLoading(true);

      const album = await getAlbumByProject(
        Number(childID),
        moduleId,
        projectId,
        localStorage.getItem("access")
      );

      if (typeof album !== "string") {
        album.length !== 0 &&
          onLoad(album[0].project.module.name, album[0].project.name);
        setAlbum(album);
      }

      setIsLoading(false);
    };

    initializeChildImages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childID, moduleId, projectId]);

  const handleOpen = (image: ImageType) => {
    setOpen(true);
    setSelectedImage(image);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(undefined);
  };

  return isLoading ? (
    <LoadingIndicator />
  ) : album.length === 0 ? (
    <NoPhotoYet />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {album[0].project.lessons.map((lessonId, index) => {
        const images = album.filter((image) => image.lesson_id === lessonId);

        return images.length !== 0 ? (
          <Box key={index}>
            <Typography
              sx={{
                fontWeight: 500,
                lineHeight: 1,
                mb: 1.25,
              }}
            >
              {`${translation.formatString(translation.lessonNo, {
                number: index + 1,
              })} : ${images[0].lesson_name}`}
            </Typography>

            <Box sx={containerStyles}>
              {images.map((image, index) => (
                <ImageCard
                  key={index}
                  src={image.link}
                  caption={image.caption}
                  fromNow={moment(image.created_at).fromNow()}
                  onClick={() => handleOpen(image)}
                />
              ))}
            </Box>
          </Box>
        ) : null;
      })}

      {open && selectedImage && (
        <ImagePreviewModal
          open={open}
          image={selectedImage}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
};

export default ProjectAlbum;

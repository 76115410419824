import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useStore from "store/store";
import useSnack from "hooks/useSnack";

import { Box, Stack, Divider, Typography } from "@mui/material";

import Button from "components/Button/Button";
import {
  CURLANG,
  EDIT_PROJECT_PAGE,
  EDIT_TEACHER_MODULE_PAGE,
  TEACHER_TRAINING_ACADEMY,
} from "constants/url";

import { translation } from "constants/translation";

//Prompt user when leaving without saving
import BlockLeavingDialog from "components/Dialog/BlockLeavingDialog";
import useCallbackPrompt from "hooks/useCallbackPrompt";

import {
  NameField,
  DescriptionField,
  ImageField,
  PublishField,
} from "../TeacherFormComponents/ModuleFormComponents";

import useInitialiseCurrModule from "hooks/useInitialiseCurrModule";
import { createTeacherModule } from "api/teacher-api";
import { API_Teacher_Module_Create } from "types/teacher";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CreateModuleForm = () => {
  const openSnack = useSnack();
  const navigate = useNavigate();

  const { teacherCurrentModule, currLanguage } = useStore((state) => ({
    teacherCurrentModule: state.teacherCurrentModule,
    currLanguage: state.currLanguage,
  }));

  const [image, setImage] = useState<File | string>("");
  const [mainFormData, setMainFormData] = useState<API_Teacher_Module_Create>({
    name: "",
    description: "",
    is_published: false,
  });

  const handleSubmit = async () => {
    if (mainFormData.name === "") {
      openSnack("Name and is required!", false);
      return;
    }

    const createData: API_Teacher_Module_Create = {
      name: "",
      description: "",
      is_published: false,
    };

    if (mainFormData.name !== teacherCurrentModule.name)
      createData.name = mainFormData.name;
    if (mainFormData.description !== teacherCurrentModule.description)
      createData.description = mainFormData.description;
    if (mainFormData.is_published !== teacherCurrentModule.is_published)
      createData.is_published = mainFormData.is_published;

    if (typeof image === "object") {
      createData.image = image;
    }

    //Update module
    const res = await createTeacherModule(currLanguage, createData);
    console.log("res: ", res);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack("Created module successfully!", true);
      navigate(
        `${CURLANG(currLanguage)}/${EDIT_TEACHER_MODULE_PAGE(res.slug)}`
      );
    }
  };
  const [showDialog, setShowDialog] = useState<boolean>(false);
  // const [showPrompt, showChanges, confirmNavigation, cancelNavigation] =
  //   useCallbackPrompt(showDialog);

  // const dialogBody = {
  //   showDialog: showPrompt,
  //   confirmNavigation,
  //   cancelNavigation,
  //   handleAction: handleSubmit,
  // };

  return (
    <>
      {/* <BlockLeavingDialog {...dialogBody} /> */}

      <Box
        sx={{
          p: 6,
          mx: 3,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <BackButton />
          <Typography
            variant="h5"
            sx={{ textAlign: "center", mb: 2, fontWeight: 500 }}
          >
            {translation.createModule}
          </Typography>
        </Box>

        <Stack
          component="form"
          sx={{
            width: "100%",
          }}
          spacing={3}
          noValidate
          autoComplete="off"
        >
          <PublishField
            data={mainFormData}
            setData={setMainFormData}
            onChange={(input) => {
              setShowDialog(true);
            }}
          />
          <NameField
            data={mainFormData}
            setData={setMainFormData}
            onChange={(input) => {
              setShowDialog(true);
            }}
          />

          <DescriptionField
            data={mainFormData}
            setData={setMainFormData}
            onChange={(input) => {
              setShowDialog(true);
            }}
          />

          <ImageField image={image} setImage={setImage} />

          <Divider />

          <Box
            sx={{
              display: "flex",
              gap: "24px",
              position: "fixed",
              bottom: 24,
              right: 34,
              zIndex: 10,
            }}
          >
            <Button
              buttonText={translation.create || "Create"}
              style={{
                fontSize: "14px",
                backgroundColor: "var(--txt-secondary)",
                height: "auto",
              }}
              onClick={() => {
                handleSubmit();
              }}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

const BackButton = () => {
  const navigate = useNavigate();
  const { currLanguage, projSlug } = useStore((state) => ({
    currLanguage: state.currLanguage,
    projSlug: state.currentProject.slug,
  }));

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        display: "flex",
        gap: "6px",
        alignItems: "center",
        cursor: "pointer",
        pl: "16px",
      }}
      onClick={() => {
        navigate(`${CURLANG(currLanguage)}/${TEACHER_TRAINING_ACADEMY}`);
      }}
    >
      <ArrowBackIcon sx={{ fontSize: "14px" }} />
      <Typography>{translation.back}</Typography>
    </Box>
  );
};

export default CreateModuleForm;

import React, { useState, useEffect } from 'react'
import useStore from "store/store";

import {
    Box,
    Typography,
    Avatar,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { translation } from "constants/translation";
import Logo from "images/logo-2-colour.png";

import totalChildren from "images/total-children.png";
import totalClasses from "images/total-classes.png";
import totalTeachers from "images/total-teachers.png";

import {
    getAllTeacherProfile,
    getAllChildProfile,
    getGroupLessonsInfo,
} from "api/profile-api";

const TotalBoxes = () => {


    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));

    const {
        groupList,
    } = useStore((state) => ({
        ...state,
        groupList: state.groupList,
    }));


    const [totalTeachersLength, setTotalTeachersLength] = useState<number>(0);
    const [totalChildrenLength, setTotalChildrenLength] = useState<number>(0);


    useEffect(() => {
        const populateData = async () => {
            const teacherListRes = await getAllTeacherProfile();
            const childrenListRes = await getAllChildProfile();
            setTotalTeachersLength(teacherListRes.length);
            setTotalChildrenLength(childrenListRes.length);
        };
        populateData();
    }, []);
    return (
        <Box
            sx={{
                width: isLg ? "20%" : '100%',
                height: "100%",
                display: "flex",
                flexDirection: isLg ? "column" : "row",
                gap: "12px",

            }}
        >
            <TotalBox picture={totalChildren} translation={translation.totalChildren} length={totalChildrenLength} />
            <TotalBox picture={totalTeachers} translation={translation.totalTeachers} length={totalTeachersLength} />
            <TotalBox picture={totalClasses} translation={translation.totalClasses} length={groupList.length} />

        </Box>
    )
}

export default TotalBoxes



const TotalBox = ({ picture, translation, length }: { picture: any; translation: any; length: any }) => {

    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    return <Box
        sx={{
            width: '100%',

            display: "flex",
            flex: 1,
            borderRadius: "4px",
            backgroundColor: "white",
            alignItems: "center",
            p: "8px",
            gap: "12px",
        }}
    >
        <Avatar
            src={picture}
            sx={{
                width: isLg ? "50px" : "28px",
                height: isLg ? "50px" : "28px",
                display: "flex",
            }}
            imgProps={{
                onError: (
                    event: React.SyntheticEvent<
                        HTMLImageElement,
                        Event
                    >
                ) => {
                    event.currentTarget.onerror = null;
                    event.currentTarget.src = Logo;
                },
            }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
                sx={{ fontWeight: "400", fontSize: isLg ? "16px" : "12px" }}
            >
                {translation}
            </Typography>
            <Typography
                sx={{ fontWeight: "600", fontSize: isLg ? "20px" : "16px" }}
            >
                {length}
            </Typography>
        </Box>
    </Box>

}
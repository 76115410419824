import * as React from "react";
import { View, Text, Image as PdfImage, StyleSheet } from "@react-pdf/renderer";
import Frame from "images/children-portfolio-v2/LittlelearnerFrame.png";
import { checkIsChineseChar } from "helper/helper";

interface Project {
  title: string;
  id: number;
  category: string;
  image_url: string;
}

type ImageStructureProps = {
  projects: Project[];
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 20,
  },
  projectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    gap: "32px",
  },
  frameContainer: {
    position: "relative",
    width: 350,
    height: 250,
    marginBottom: 5,
  },
  frameImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  projectImage: {
    position: "relative",
    top: "8.9%",
    left: "11%",
    width: "79.1%",
    height: "63%",
    objectFit: "cover",
  },
  captionContainer: {
    width: 300,
    height: 60,
    top: -50,
    backgroundColor: "#F9F6F1",
    textAlign: "center",
    borderRadius: 5,
    padding: 5,
    boxSizing: "border-box",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-35px",
  },
  categoryText: {
    color: "#000",
    fontWeight: 400,
    fontSize: 14,
  },
  titleText: {
    color: "#000",
    fontWeight: 300,
    fontSize: 12,
  },
});

const ImageStructure: React.FC<ImageStructureProps> = ({ projects }) => {
  return (
    <View style={styles.container}>
      {projects.map((project, index) => {
        const isChineseCategory = checkIsChineseChar(project.category);
        const isChineseTitle = checkIsChineseChar(project.title);

        return (
          <View key={index} style={styles.projectContainer}>
            <View style={styles.frameContainer}>
              <PdfImage
                style={styles.projectImage}
                src={{
                  uri: project.image_url,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
              />
              <PdfImage style={styles.frameImage} src={Frame} />
            </View>
            <View style={styles.captionContainer}>
              <Text
                style={[
                  styles.categoryText,
                  {
                    fontFamily: isChineseCategory ? "KaiTi" : "FuturaBkBT",
                  },
                ]}
              >
                {project.category}
              </Text>
              <Text
                style={[
                  styles.titleText,
                  {
                    fontFamily: isChineseTitle ? "KaiTi" : "FuturaBkBT",
                  },
                ]}
              >
                {project.title}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default ImageStructure;

import React from "react";
import { Box, Typography } from "@mui/material";
import {
  API_Create_Activity,
} from "types/project";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";

export const MaterialField = ({
  activity,
  setActivity,
  onChange,
}: {
  activity: API_Create_Activity;
  setActivity: React.Dispatch<React.SetStateAction<API_Create_Activity>>;
  onChange?: (input: string) => void;
}) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>{translation.materials}</Typography>
    <RichCKEditor
      data={activity.material}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setActivity((prev) => ({ ...prev, material: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

import React, { useState, useEffect, CSSProperties } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  Switch,
  Stack,
  Tooltip,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button, {
  AddButton,
  EditButton,
  FactoryButton,
} from "components/Button/Button";
import { API_Timetable_Event_Info } from "types/timetable";
import { EventPopup } from "./TimetableComponents";
import { translation } from "constants/translation";
import { API_Profile } from "types/profile";
import { getAccountProfile } from "api/profile-api";
import useStore from "store/store";
import useSnack from "hooks/useSnack";
import { switchStyle } from "containers/SchoolAccessControlPage/SubscriptionControlBar";
import { updateProfile } from "api/auth-api";
import { ErrorMessage, UpdateProfileResponse } from "types/auth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import UploadIcon from "@mui/icons-material/Upload";

const TimetableHeader = ({
  isEdit,
  setIsEdit,
  onlyToday,
  setOnlyToday,
  events,
  setEvents,
  modules,
  isViewOnly,
  isCustomTimetable,
  setIsCustomTimetable,
}: {
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onlyToday: boolean;
  setOnlyToday: React.Dispatch<React.SetStateAction<boolean>>;
  events: API_Timetable_Event_Info[];
  setEvents: React.Dispatch<
    React.SetStateAction<API_Timetable_Event_Info[] | undefined>
  >;
  modules: string[];
  isViewOnly: boolean;
  isCustomTimetable: boolean;
  setIsCustomTimetable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { profileId } = useParams();
  const theme = useTheme();
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (!isViewOnly || !profileId) return;

    const getName = async () => {
      const data: API_Profile = await getAccountProfile(
        profileId,
        localStorage.getItem("access")
      );

      setName(data.preferred_name);
    };

    getName();
  }, [isViewOnly, profileId]);
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box
      sx={{
        display: isLg ? "flex" : "grid",
        flexDirection: isLg ? "row-reverse" : "auto",
        // backgroundColor: "purple",
        width: isLg ? "100%" : "auto",
        justifyContent: isLg ? "space-between" : "auto",

        gridTemplateColumns: {
          lg: `repeat(${isEdit ? 2 : 3}, 1fr)`,
          // xs: "1fr",
        },
        alignItems: "center",
        gap: 2,
        mb: 2,
      }}
    >
      {isEdit ? (
        <>
          <AddNewEventButton
            events={events}
            setEvents={setEvents}
            modules={modules}
          />

          <DoneButton setIsEdit={setIsEdit} />
        </>
      ) : (
        <>
          {isViewOnly && name !== "" && (
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: 500,
                textAlign: {
                  lg: "left",
                  xs: "center",
                },
              }}
            >
              {translation.formatString(translation.timetableTitle, {
                name,
              })}
            </Typography>
          )}

          {!isCustomTimetable ? (
            <Box
              sx={{
                alignSelf: "flex-end",
                display: {
                  lg: "flex",
                  // xs: "none",
                },
                alignItems: "flex-end",
                gap: 1,
                order: isViewOnly && name !== "" ? 1 : 0,
                justifySelf: isViewOnly && name !== "" ? "end" : "start",
                // backgroundColor: "lightblue",
              }}
            >
              <ToggleDurationButton
                isTodayButton={false}
                onlyToday={onlyToday}
                setOnlyToday={setOnlyToday}
              />
              <ToggleDurationButton
                isTodayButton={true}
                onlyToday={onlyToday}
                setOnlyToday={setOnlyToday}
              />
            </Box>
          ) : (
            <Box
              sx={{
                order: isViewOnly && name !== "" ? 1 : 0,
                display: {
                  lg: "flex",
                  // xs: "none",
                },
              }}
            />
          )}

          <ToggleViewButton
            isViewOnly={isViewOnly && name !== ""}
            isCustomTimetable={isCustomTimetable}
            setIsCustomTimetable={setIsCustomTimetable}
            onlyToday={onlyToday}
            setOnlyToday={setOnlyToday}
          />

          {!isViewOnly && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              justifySelf="end"
              sx={{
                display: {
                  lg: "flex",
                  xs: "none",
                },
              }}
            >
              <ToggleShareButton />

              {!isCustomTimetable && (
                <EditTimetableButton
                  setIsEdit={setIsEdit}
                  setOnlyToday={setOnlyToday}
                />
              )}
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

const buttonStyles: React.CSSProperties = {
  flexDirection: "row-reverse",
  gap: "8px",
  backgroundColor: "transparent",
  border: "1px solid",
  padding: "10px 16px",
  fontSize: "14px",
  fontWeight: 500,
};

const ToggleDurationButton = ({
  isTodayButton,
  onlyToday,
  setOnlyToday,
}: {
  isTodayButton: boolean;
  onlyToday: boolean;
  setOnlyToday: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isActive = isTodayButton === onlyToday;

  return (
    <Button
      arrow={false}
      buttonText={isTodayButton ? translation.today : translation.thisWeek}
      style={{
        // ...buttonStyles,
        // padding: "11px 16px",
        color: "#312F30",
        borderColor: isActive ? "#D1E4E3" : "#DFDDDD",
        backgroundColor: isActive ? "#D1E4E3" : "transparent",
        pointerEvents: isActive ? "none" : "auto",
      }}
      onClick={() => setOnlyToday(isTodayButton)}
    />
  );
};

const EditTimetableButton = ({
  setIsEdit,
  setOnlyToday,
}: {
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOnlyToday: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <EditButton
    btnText={translation.editTimetable}
    style={{
      ...buttonStyles,
      padding: "8px 16px",
      color: "#312F30",
      borderColor: "#DFDDDD",
    }}
    onClick={() => {
      setIsEdit(true);
      setOnlyToday(false);
    }}
  />
);

const AddNewEventButton = ({
  events,
  setEvents,
  modules,
}: {
  events: API_Timetable_Event_Info[];
  setEvents: React.Dispatch<
    React.SetStateAction<API_Timetable_Event_Info[] | undefined>
  >;
  modules: string[];
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => setOpen(true);

  return (
    <>
      <AddButton
        btnText={translation.addNewEvent}
        style={{
          flexDirection: "row-reverse",
          gap: "4px",
          paddingLeft: "10px",
          border: "1px solid var(--secondary-bg-color)",
        }}
        onClick={handleClick}
      />

      {open && (
        <EventPopup
          events={events}
          open={open}
          setOpen={setOpen}
          setEvents={setEvents}
          modules={modules}
        />
      )}
    </>
  );
};

const DoneButton = ({
  setIsEdit,
}: {
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Button
    arrow={false}
    buttonText={translation.done}
    style={{
      ...buttonStyles,
      color: "#407374",
      borderColor: "#98C4C5",
      width: "100%",
      maxWidth: "125px",
      justifySelf: "end",
    }}
    onClick={() => setIsEdit(false)}
  />
);

const ToggleShareButton = () => {
  const openSnack = useSnack();

  const { profileDetails, setProfileDetails } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    setProfileDetails: state.setProfileDetails,
  }));

  const [tooltipTitle, setTooltipTitle] = useState<string>(
    translation.copyShareLink
  );

  const handleToggle = async () => {
    const response: UpdateProfileResponse | ErrorMessage = await updateProfile(
      profileDetails.id.toString(),
      {
        share_timetable: !profileDetails.share_timetable,
      },
      localStorage.getItem("access")
    );

    if ("error" in response) {
      openSnack(response.error.join(" "), false);
    } else {
      setProfileDetails({
        ...profileDetails,
        share_timetable: response.share_timetable,
      });
      openSnack(
        response.share_timetable
          ? translation.timetableShared
          : translation.timetableUnshared,
        true
      );
    }
  };

  const onMouseEnter = () => setTooltipTitle(translation.copyShareLink);

  const handleCopyShareLink = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setTooltipTitle(translation.shareLinkCopied);
  };

  return (
    <Stack direction="row" alignItems="center">
      <Switch
        sx={{ ...switchStyle(20), height: 24, py: "6px", px: 1.5 }}
        checked={profileDetails.share_timetable}
        onChange={handleToggle}
      />

      <Typography
        sx={{
          fontSize: "0.875rem",
          ml: profileDetails.share_timetable ? 0.25 : 0,
        }}
      >
        {translation.shareTimetable}
      </Typography>

      {profileDetails.share_timetable && (
        <Tooltip title={tooltipTitle}>
          <ContentCopyIcon
            fontSize="small"
            sx={{
              ml: 0.75,
              cursor: "pointer",
              color: "#312F30",
              opacity: 0.5,
              transition: "all 100ms ease-in-out",
              "&:hover": {
                color: "#407374",
                opacity: 1,
                transition: "all 100ms ease-in-out",
              },
            }}
            onClick={handleCopyShareLink}
            onMouseEnter={onMouseEnter}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

const ToggleViewButton = ({
  isViewOnly,
  isCustomTimetable,
  setIsCustomTimetable,
  onlyToday,
  setOnlyToday,
}: {
  isViewOnly: boolean;
  isCustomTimetable: boolean;
  setIsCustomTimetable: React.Dispatch<React.SetStateAction<boolean>>;
  onlyToday: boolean;
  setOnlyToday: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const buttonContainerStyles: SxProps = { width: "100%", maxWidth: "120px" };
  const customButtonStyles: CSSProperties = {
    padding: "8px 16px",
    color: "#312F30",
    width: "100%",
  };

  const [, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        // backgroundColor: "lightcoral",
      }}
    >
      <Tooltip
        title={isViewOnly ? "" : translation.defaultTimetableButtonTitle}
      >
        <Box
          sx={{
            ...buttonContainerStyles,
            pointerEvents: !isCustomTimetable ? "none" : "auto",
          }}
        >
          <FactoryButton
            btnText=""
            icon={<EditCalendarIcon />}
            style={{
              ...buttonStyles,
              ...customButtonStyles,
              borderColor: !isCustomTimetable ? "#D1E4E3" : "#DFDDDD",
              backgroundColor: !isCustomTimetable ? "#D1E4E3" : "transparent",
            }}
            onClick={() => {
              setSearchParams({});
              setIsCustomTimetable(false);
            }}
          />
        </Box>
      </Tooltip>

      <Tooltip
        title={isViewOnly ? "" : translation.uploadedTimetableButtonTitle}
      >
        <Box
          sx={{
            ...buttonContainerStyles,
            pointerEvents: isCustomTimetable ? "none" : "auto",
          }}
        >
          <FactoryButton
            btnText=""
            icon={<UploadIcon />}
            style={{
              ...buttonStyles,
              ...customButtonStyles,
              borderColor: isCustomTimetable ? "#D1E4E3" : "#DFDDDD",
              backgroundColor: isCustomTimetable ? "#D1E4E3" : "transparent",
            }}
            onClick={() => {
              setSearchParams({
                isCustomTimetableTab: "true",
              });
              setIsCustomTimetable(true);
            }}
          />
        </Box>
      </Tooltip>

      {/* {isLg && (
        <Tooltip
          title={isViewOnly ? "" : translation.defaultTimetableButtonTitle}
        >
          <Box
            sx={{
              ...buttonContainerStyles,
            }}
          >
            <FactoryButton
              btnText=""
              icon={<TodayIcon />}
              style={{
                ...buttonStyles,
                ...customButtonStyles,
                borderColor: onlyToday ? "#D1E4E3" : "#DFDDDD",
                backgroundColor: onlyToday ? "#D1E4E3" : "transparent",
              }}
              onClick={() => {
                setOnlyToday((prev) => !prev);
              }}
            />
          </Box>
        </Tooltip>
      )} */}
    </Box>
  );
};

export default TimetableHeader;

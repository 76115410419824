import WalkthroughStep from "./components/WalkthroughStep";
import { getLinearElementDetails } from "./computeTeacherDashboardSteps";
import type { EmptyFn, Step } from "./types";

const lessonPlansStepId = "lesson-plan-tab-lesson-plans";

export const getHorizontalElementDetails = (elementId: string, height: number) => { 
  const element = document.getElementById(elementId);

  const elementHeight = element?.offsetHeight ?? 0;
  const elementWidth = element?.clientWidth ?? 0;
  const elementTop = (element?.offsetTop ?? 0) + 55; // 88 is constant considered that top is a relative position
  const elementLeft = (element?.offsetLeft ?? 0) + 60;

  return {
    left: elementLeft + elementWidth - 10,
    top: elementTop + 30 - ((height - elementHeight) / 2),
    html: element?.innerHTML ?? "",
  }
}

const computeTeacherSteps = (handleNextStep: EmptyFn, handleClose: EmptyFn, isLg: boolean): (() => Step)[] => {

  const lessonPlansStep = (): Step => {
    const { left, top } = isLg
      ? getLinearElementDetails(lessonPlansStepId, 270)
      : getHorizontalElementDetails(lessonPlansStepId, 188);
    
    return {
      width: isLg ? 270 : 350,
      height: isLg ? 'fit-content' : 165,
      top: isLg ? top + 65 : top,
      left: isLg ? left - 10 : left,
      line: isLg ? "vertical" : "horizontal",
      component: (
        <WalkthroughStep
          handleNext={handleNextStep}
          steps={1} step={1}
          description="Read about what the lesson plan, along with the estimated project duration and development goals in project detail"
        />
      ),
    }
  };

  if (Number(lessonPlansStep().top) < 100) { 
    return [];
  }

  return [
    lessonPlansStep
  ];
};

export default computeTeacherSteps;

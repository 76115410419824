import React, { useEffect, useRef, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import moment from "moment";

import useStore from "store/store";
import {
  BoxPosition,
  ProjectBoxProps,
} from "types/calendar";
import {
  AVATAR_WIDTH,
  DATETIME_FORMAT,
  FONT_SIZE,
  RIGHT_COLUMN_WIDTH,
  ROW_HEIGHT,
} from "containers/CalendarPage/constants/constants";
import {
  getMousePos,
  pxToDays,
  pxToVw,
  vwToDays,
  vwToPx,
} from "containers/CalendarPage/constants/helper";

import {
  deleteScheduleDatetime,
  updateScheduleDatetime,
} from "api/calendar-api";
import useSnack from "hooks/useSnack";
import WarningDialog from "components/Dialog/WarningDialog";
import useDialog from "hooks/useDialog";
import Logo from "images/logo-2-colour.png";
import useModal from "hooks/useModal";
import ScheduleGroupPageModal from "../../../ScheduleGroupPageModal";
import NavigateProjectComponent from "./NavigateProjectComponent";
import DeleteScheduleObjectComponent from "./DeleteScheduleObjectComponent";

type Props = {
  id: number;
  scheduleId: number;
  projectId: number;
  groupId: number;
  projectTitle: string;
  projectDescription: string;
  projectSlug: string;
  moduleName: string;
  moduleCategoryName: string;
  image: string;
  bgColor?: string;
  width: string;
  height?: string;
  initialGridPosXMultiplier: number;
  initialGridPosYMultiplier: number;
  startAt: string;
  endAt: string;
};

const ProjectBox = ({
  id,
  scheduleId,
  projectId,
  groupId,
  projectTitle,
  projectSlug,
  moduleName,
  image,
  width,
  height,
  bgColor,
  initialGridPosXMultiplier,
  initialGridPosYMultiplier,
  startAt,
  endAt,
}: Props) => {
  const container = useRef<HTMLDivElement>(null);
  const openSnack = useSnack();

  const {
    groupProjectBoxProps,
    setGroupProjectBoxProps,
    calendarDates,
    setRefreshGroupLessonInfoList,
    setIsRefreshBypassLoading,
  } = useStore((state) => ({
    calendarDates: state.calendarDates,
    groupProjectBoxProps: state.groupProjectBoxProps,
    setGroupProjectBoxProps: state.setGroupProjectBoxProps,
    setRefreshGroupLessonInfoList: state.setRefreshGroupLessonInfoList,
    setIsRefreshBypassLoading: state.setIsRefreshBypassLoading,
  }));

  const updateProjectBoxDrag = (xDiff: number) => {
    const updatedProjectBoxProps: ProjectBoxProps[] = JSON.parse(
      JSON.stringify(groupProjectBoxProps[groupId])
    );
    const index = updatedProjectBoxProps.findIndex((item) => item.id === id);
    const projectBox = updatedProjectBoxProps[index];
    const diffDays = pxToDays(xDiff, gridCellWidth);
    const startDate = moment(projectBox.startAt)
      .subtract(diffDays, "days")
      .format(DATETIME_FORMAT);
    const endDate = moment(projectBox.endAt)
      .subtract(diffDays, "days")
      .format(DATETIME_FORMAT);
    // console.log("endDate: ", endDate);
    // console.log("gridCellWidth: ", gridCellWidth);

    //call api update schedule obj
    const res = updateScheduleDatetime(
      projectBox.id,
      projectBox.scheduleId,
      startDate,
      endDate
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(
        `Updated "${projectBox.projectTitle}: start date to ${startDate}, end date to ${endDate} "`,
        true
      );
      projectBox.endAt = endDate;
      projectBox.startAt = startDate;
      setGroupProjectBoxProps({
        ...groupProjectBoxProps,
        [groupId]: updatedProjectBoxProps,
      });
      setRefreshGroupLessonInfoList(true);
      setIsRefreshBypassLoading(true);
    }
  };

  const updateProjectBoxResize = async (updatedWidth: string) => {
    const updatedProjectBoxProps: ProjectBoxProps[] = JSON.parse(
      JSON.stringify(groupProjectBoxProps[groupId])
    );
    const index = updatedProjectBoxProps.findIndex((item) => item.id === id);
    const projectBox = updatedProjectBoxProps[index];

    const endDate = moment(projectBox.endAt)
      .subtract(
        vwToDays(
          Number(projectBox.width.slice(0, -2)) -
            Number(updatedWidth.slice(0, -2)),
          gridCellWidth
        ),
        "days"
      )
      .format(DATETIME_FORMAT);
    // console.log("endDate: ", endDate);
    // console.log("gridCellWidth: ", gridCellWidth);

    //call api update schedule obj
    const res = await updateScheduleDatetime(
      projectBox.id,
      projectBox.scheduleId,
      projectBox.startAt,
      endDate
    );
    // console.log("res: ", res);
    if (typeof res === "string") {
      openSnack(res, false);
      return false;
    } else {
      openSnack(
        `Updated "${projectBox.projectTitle} end date to ${endDate} "`,
        true
      );
      projectBox.endAt = endDate;
      projectBox.width = updatedWidth;
      // projectBox.startAt = ?
      // console.log("projectBox: ", projectBox);
      setGroupProjectBoxProps({
        ...groupProjectBoxProps,
        [groupId]: updatedProjectBoxProps,
      });
      setRefreshGroupLessonInfoList(true);
      setIsRefreshBypassLoading(true);
      return true;
    }
  };

  const gridCellWidth = RIGHT_COLUMN_WIDTH / calendarDates.length;
  // console.log("gridCellWidth: ", gridCellWidth);
  const [dragging, setDragging] = useState<boolean>(false);

  const [resizing, setResizing] = useState<boolean>(false);
  const [startPos, setStartPos] = useState<BoxPosition>({
    startX: vwToPx(initialGridPosXMultiplier * gridCellWidth),
    startY: initialGridPosYMultiplier * ROW_HEIGHT + 5,
  });
  const [currWidth, setCurrWidth] = useState<string>(width); // To make it controllable for the current container size

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    // console.log("bclicked: ", clicked);
    // console.log("bresizing: ", resizing);
    // console.log("bdragging: ", dragging);

    if (container && container.current) {
      container.current.style.zIndex = "2";
      const [mouseX, _] = getMousePos(event, container);
      // const { startX, startY } = startPos;

      if (resizing) {
        // console.log("Resizing...");
        // console.log("container.current.style: ", container.current.style);
        const calculatedVw =
          Math.floor(pxToVw(mouseX) / gridCellWidth) * gridCellWidth;
        container.current.style.width = `${calculatedVw}vw`;
      }
    }
    // console.log("aclicked: ", clicked);
    // console.log("aresizing: ", resizing);
    // console.log("adragging: ", dragging);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.style.height = "100vw";
    event.currentTarget.style.width = "100vh";
    if (container.current) {
      container.current.style.zIndex = "100";
    }

    setResizing(true);
  };

  const handleMouseUp = async (event: React.MouseEvent<HTMLDivElement>) => {
    // console.log("handleMouseUp");
    // console.log("event: ", event);
    event.currentTarget.style.height = "";
    event.currentTarget.style.width = "";
    if (container && container.current) {
      container.current.style.zIndex = "1";

      const [mouseX, _] = getMousePos(event, container);
      const calculatedVw =
        Math.floor(pxToVw(mouseX) / gridCellWidth) * gridCellWidth;
      // console.log("calculatedVw: ", calculatedVw);
      if (await updateProjectBoxResize(`${calculatedVw}vw`)) {
        setCurrWidth(`${calculatedVw}vw`);
      } else {
        container.current.style.width = currWidth;
      }
    }
    setResizing(false);
  };

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setDragging(true);
    if (container.current) {
      container.current.style.zIndex = "2";
    }
  };

  const handleDragEnd = (e: DraggableEvent, data: DraggableData) => {
    // console.log("handleDragEnd");
    // console.log("DATA: ", data);
    if (container.current) {
      container.current.style.zIndex = "1";
    }
    const diff = startPos.startX - data.x;
    if (diff !== 0) {
      updateProjectBoxDrag(diff);
      setStartPos((prev) => ({
        ...prev,
        startX: data.x,
      }));
    }
    // setDragging(false);
  };

  const handleDelete = (id: number) => async () => {
    const res = await deleteScheduleDatetime(id);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack("The schedule has been deleted successfully!", true);
      setGroupProjectBoxProps({
        ...groupProjectBoxProps,
        [groupId]: groupProjectBoxProps[groupId].filter(
          (projectBoxProp) => projectBoxProp.id !== id
        ),
      });
      setRefreshGroupLessonInfoList(true);
      setIsRefreshBypassLoading(true);
    }
  };

  useEffect(() => {
    if (width !== undefined) {
      // console.log("Setting width...");
      setCurrWidth(width);
    }
  }, [width]);

  // useEffect(() => {
  //   if (initialGridPosXMultiplier !== undefined && initialGridPosYMultiplier !== undefined) {
  //     setStartPos({
  //       startX: vwToPx(initialGridPosXMultiplier * gridCellWidth),
  //       startY: initialGridPosYMultiplier * ROW_HEIGHT,
  //     });
  //   }
  // }, [initialGridPosXMultiplier, initialGridPosYMultiplier]);

  // console.log("startPos: ", startPos);

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const { open, handleOpen, handleClose } = useModal();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete Schedule for ${projectTitle} from ${startAt} to ${endAt}?`,
    warningContext: "The action is irreversable!",
    handleDelete: handleDelete(id),
  };

  const title1 = moduleName;
  const title2 = projectTitle;
  // console.log("resizing: ", resizing);
  // console.log("dragging: ", dragging);
  return (
    <>
      <WarningDialog {...warningBody} />
      <ScheduleGroupPageModal
        open={open}
        handleClose={handleClose}
        projectId={projectId}
        projectTitle={projectTitle}
        assessment={false}
        groupId={Number(groupId)}
        scheduleId={scheduleId}
        scheduleDatetimeId={id}
        startAt={startAt}
        endAt={endAt}
        handleOpenDialog={handleOpenDialog}
        projectSlug={projectSlug}
        isUpdate={true}
      />

      <Draggable
        grid={[vwToPx(RIGHT_COLUMN_WIDTH / calendarDates.length), 0]}
        axis="x"
        // defaultPosition={{ x: startPos[0], y: 0 }}
        position={{ x: startPos.startX, y: startPos.startY }}
        onDrag={handleDrag}
        onStop={handleDragEnd}
        bounds="parent"
        cancel=".nodrag"
      >
        <Box
          onClick={() => {
            if (resizing || dragging) {
              setDragging(false);
            } else {
              handleOpen();
            }
          }}
          sx={{
            backgroundColor: bgColor,
            borderRadius: "10px",
            padding: "8px",
            position: "absolute",
            top: 0,
            left: 0,
            width: width,
            height: height,
            cursor: "move",
            zIndex: 2,
          }}
          ref={container}
          data-projectboxid={id}
          id="projectbox"
        >
          <ProjectBoxContent
            image={image}
            startAt={startAt}
            endAt={endAt}
            title1={title1}
            title2={title2}
          />

          {/* Resize */}
          <Box
            className="nodrag"
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%) rotate(90deg)",
              cursor: "ew-resize",
              userSelect: "none",
              height: "fit-content",
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              opacity: "0.5",
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <DragHandleIcon />
          </Box>
          {Number(currWidth.slice(0, -2)) >= 6 && (
            <>
              <DeleteScheduleObjectComponent
                handleOpenDialog={handleOpenDialog}
              />
              <NavigateProjectComponent projectSlug={projectSlug} />
            </>
          )}
        </Box>
      </Draggable>
    </>
  );
};

const ProjectBoxContent = ({
  image,
  startAt,
  endAt,
  title1,
  title2,
}: {
  image: string;
  startAt: string;
  endAt: string;
  title1: string;
  title2: string;
}) => {
  const fontStyleTemp = {
    fontSize: `${FONT_SIZE}px`,
    userSelect: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    pointerEvents: "none",
  };
  const { calendarDates, startAndEndDate } = useStore((state) => ({
    calendarDates: state.calendarDates,
    startAndEndDate: state.startAndEndDate,
  }));

  const diffDays =
    (startAndEndDate.startDate.getTime() - new Date(startAt).getTime()) /
    (1000 * 60 * 60 * 24);
  const left =
    (RIGHT_COLUMN_WIDTH / calendarDates.length) * Math.max(0, diffDays);
  // console.log("startAt: ", startAt);
  // console.log("startAndEndDate: ", startAndEndDate);
  // console.log("diffDays: ", diffDays);

  // width: `${(RIGHT_COLUMN_WIDTH / calendarDates.length) * diffDays}vw`,
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        paddingLeft: `${left}vw`,
        // position: "relative",
      }}
    >
      <Avatar
        src={image ? image : Logo}
        sx={{
          position: "fixed",
          width: "-webkit-fill-available",
          height: "-webkit-fill-available",
          maxWidth: AVATAR_WIDTH,
          maxHeight: AVATAR_WIDTH,
          backgroundColor: "primary.main",
          color: "txt.light",
          pointerEvents: "none",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      />
      <Box
        sx={{
          marginLeft: `${AVATAR_WIDTH + 10}px`,
          width: "-webkit-fill-available",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold", ...fontStyleTemp }}>
          {/* {startAt} - {endAt} - {title1} */}
          {title1}
        </Typography>
        <Typography
          sx={{
            ...fontStyleTemp,
          }}
        >
          {title2}
        </Typography>
      </Box>
    </Box>
  );
};
export default ProjectBox;

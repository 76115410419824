import { Page, Text, Image, View, StyleSheet } from "@react-pdf/renderer";

import { API_Project } from "types/project";
import { pageSize, styles } from "../ProjectReportPDF";

import Background from "images/end-of-project-report/front-page-bg.png";
import { PDFInfoType } from "../Preview/constant";

const templateStyles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 40,
    marginVertical: 140,
    marginHorizontal: 220,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginBottom: 60,
  },
  title: {
    textAlign: "center",
    fontSize: 60,
    fontWeight: 500,
    lineHeight: 1.25,
  },
  footer: {
    textAlign: "center",
    fontSize: 32,
    fontWeight: 500,
  },
});

const FrontPage = ({
  PDFInfo,
  project,
}: {
  PDFInfo?: PDFInfoType;
  project: API_Project;
}) => {
  const year = new Date().getFullYear();

  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Background} style={styles.background} />

        <View style={templateStyles.wrapper}>
          <View style={templateStyles.titleWrapper}>
            <Text style={templateStyles.title}>
              {PDFInfo ? PDFInfo.name : project.name}
            </Text>
          </View>

          <Text style={styles.textCenter}>
            {PDFInfo ? PDFInfo.presentedBy : "Proudly Presented By:"}
          </Text>

          {PDFInfo ? (
            <>
              <Text style={templateStyles.footer}>{PDFInfo.group}</Text>
              <Text style={templateStyles.footer}>{PDFInfo.school}</Text>
            </>
          ) : (
            <Text style={templateStyles.footer}>
              The {project.age} Class {year}
            </Text>
          )}
        </View>
      </View>
    </Page>
  );
};

export default FrontPage;

import React from "react";

interface Props {
  create?: boolean;
}

const EditQuestion = ({ create = false }: Props) => {
  return <div>EditQuestion</div>;
};

export default EditQuestion;

import { Box, Modal } from "@mui/material";
import type { Step } from "./types";

type Props = {
  activeStep: Step;
  isOpen: boolean;
}

const WalkthroughModal = ({ activeStep, isOpen }: Props) => {
  const { top, left, right, width, height, component, line } = activeStep;

  return (
    <Modal
      open={isOpen}
      disableAutoFocus={true}
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          transition: "all 300ms linear",
          position: "absolute",
          zIndex: "2",
          top,
          left,
          right,
          width: { xs: "80%", sm: width },
          maxWidth: width,
          height,
          overflow: "hidden",
          display: "flex",
          flexDirection: line === "vertical" || line === "curved-right" ? "column" : "row",
        }}
      >
        {line === "vertical" && (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <Box sx={{ width: "20px", height: "20px", borderRadius: "10px", marginBottom: "-5px",  bgcolor: "#fff" }} />
            <Box sx={{ width: "3px", height: "38.37px", bgcolor: "#fff" }} />
          </Box>
        )}
        {line === "horizontal" && (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Box sx={{ width: "20px", height: "20px", borderRadius: "10px", marginRight: "-5px",  bgcolor: "#fff" }} />
            <Box sx={{ width: "34.6px", height: "3px", bgcolor: "#fff" }} />
          </Box>
        )}
        {line === "curved-right" && (
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", ml: "10%", height: "100%" }}>
              <Box sx={{ width: "80%", height: "3px", bgcolor: "#fff" }} />
              <Box sx={{ width: "20px", height: "20px", borderRadius: "10px", marginleft: "-5px", bgcolor: "#fff" }} />
            </Box>
            <Box sx={{ height: "100px", width: "3px", bgcolor: "#fff", ml: "10%", mt: "-11px" }} />
          </Box>
        )}
        <Box
          sx={{
            transition: "all 300ms linear",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            height,
            width: "100%",
            gap: 1,
            boxShadow: "2px 2px 20px var(--primary-main)",
            borderRadius: 2,
            bgcolor: "background.paper",
          }}>
          {component}
        </Box>
      </Box>
    </Modal>
  );
};

export default WalkthroughModal;

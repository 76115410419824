import { Text, Image, View } from "@react-pdf/renderer";
import { API_Child } from "types/portfolio";
import { checkIsChineseChar } from "helper/helper";
import { truncateText } from "./helper";

interface IndividualObservationProps {
  observation: API_Child.PortfolioObservation;
}

const IndividualObservation = ({ observation }: IndividualObservationProps) => {
  let caption;
  if (checkIsChineseChar(observation.caption)) {
    caption = truncateText(observation.caption, 3, 30);
  } else {
    caption = truncateText(observation.caption, 3, 120);
  }

  return (
    <View
      style={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 8,
        position: "relative",
        height: 150,
        width: 120,
      }}
    >
      <Image
        style={{
          position: "absolute",
          top: "10%",
          left: "16%",
          width: "98%",
          height: "61%",
          objectFit: "cover",
          objectPosition: "center center",
        }}
        src={observation.localImageUrl}
        cache={true}
      />
      {checkIsChineseChar(caption) ? (
        <Text
          style={{
            position: "absolute",
            bottom: "15%",
            left: "13%",
            width: "103%",
            fontFamily: "KaiTi",
            fontSize: 6,
            color: "black",
            textAlign: "center",
            lineHeight: 1.5,
            overflow: "hidden",
          }}
        >
          {caption}
        </Text>
      ) : (
        <Text
          style={{
            position: "absolute",
            bottom: "8%",
            left: "13%",
            width: "105%",
            height: "30%",
            fontSize: 6,
            fontFamily: "FuturaLtBT",
            color: "#000",
            textAlign: "center",
            lineHeight: 1.5,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {caption}
        </Text>
      )}
    </View>
  );
};

export default IndividualObservation;

import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Switch } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { arrayMove } from "@dnd-kit/sortable";
import {
  API_Activity_Instruction,
  API_Lesson_Learning_Moment,
} from "types/project";
import { translation } from "constants/translation";
import RichCKEditor from "components//RichCKEditor/RichCKEditor";
import useStore from "store/store";
import ActivityInstructionLearningMomentField from "./ActivityInstructionLearningMomentField";
import useDialog from "hooks/useDialog";
import { deleteActivityInstruction } from "api/project-api";
import useSnack from "hooks/useSnack";
import WarningDialog from "components/Dialog/WarningDialog";
import ActivityInstructionEvaluationMomentField from "./ActivityInstructionEvaluationMomentField";

type Props = {
  item: API_Activity_Instruction;
  assessment: boolean;
  activityInstructions: API_Activity_Instruction[];
  setActivityInstructions: React.Dispatch<
    React.SetStateAction<API_Activity_Instruction[]>
  >;
  onChange?: (input: string) => void;
};

const InstructionFieldForDnd = ({
  item,
  assessment,
  activityInstructions,
  setActivityInstructions,
}: Props) => {
  const { fundamentalDict, setIsRefreshActivityInstruction } = useStore(
    (state) => state
  );

  const openSnack = useSnack();
  const [activityInstructionName, setActivityInstructionName] =
    useState<string>(item.name);
  const [
    activityInstructionRecommedation,
    setActivityInstructionRecommendation,
  ] = useState<string>(item.recommendation);

  useEffect(() => {
    const inputNameTimeout = setTimeout(async () => {
      // console.log("Updating name now");
      const currActivityInstructionIndex = activityInstructions.findIndex(
        (activityInstruction) => activityInstruction.id === item.id
      );
      const updatedActivityInstructions: API_Activity_Instruction[] =
        JSON.parse(JSON.stringify(activityInstructions));
      updatedActivityInstructions[currActivityInstructionIndex].name =
        activityInstructionName;
      updatedActivityInstructions[currActivityInstructionIndex].recommendation =
        activityInstructionRecommedation;

      setActivityInstructions(updatedActivityInstructions);
    }, 500);
    return () => clearTimeout(inputNameTimeout);
  }, [activityInstructionName, activityInstructionRecommedation]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteActivityInstruction(Number(item.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setIsRefreshActivityInstruction(true);
      openSnack(translation.deletedInstructions, true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(`Are you sure you want to delete this instruction?`);
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.name]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Activity Instruction?",
    warningContext: warningContext,
    handleDelete,
  };

  const handleMoveUp = () => {
    const activeIndex = activityInstructions.findIndex(
      (instruction) => instruction.id === item.id
    );
    if (activeIndex > 0) {
      // ensure the item is not the first one
      const overIndex = activeIndex - 1;
      let updatedInstructions = arrayMove(
        activityInstructions,
        activeIndex,
        overIndex
      );

      updatedInstructions = updatedInstructions.map((item, itemIndex) => {
        return { ...item, weight: itemIndex + 1 };
      });

      setActivityInstructions(updatedInstructions);
    }
  };

  const handleMoveDown = () => {
    const activeIndex = activityInstructions.findIndex(
      (instruction) => instruction.id === item.id
    );
    if (activeIndex < activityInstructions.length - 1) {
      // ensure the item is not the last one
      const overIndex = activeIndex + 1;
      let updatedInstructions = arrayMove(
        activityInstructions,
        activeIndex,
        overIndex
      );

      updatedInstructions = updatedInstructions.map((item, itemIndex) => {
        return { ...item, weight: itemIndex + 1 };
      });

      setActivityInstructions(updatedInstructions);
    }
  };

  const handleChangeLearningMoment = () => {
    const updatedActivityInstructions = activityInstructions.map(
      (instruction) => {
        if (instruction.id !== item.id) {
          return instruction;
        }

        if (instruction.learning_moment) {
          return {
            ...instruction,
            learning_moment: undefined,
          };
        }

        const learning_moment: API_Lesson_Learning_Moment = {
          id: 0,
          name: "",
          description: "",
          photo_instruction: "",
          photo_caption: "",
          fundamental: fundamentalDict[Object.keys(fundamentalDict)[0]].id,
          activity: Number(instruction.activity),
          milestone: -1,
          activity_instruction: Number(instruction.id),
          questions: [],
        };

        if (assessment) {
          learning_moment.questions = [
            {
              id: `question-0`,
              mode: "create",
              name: "",
              fundamental_set: [
                {
                  id: `question-0-fundamental-0`,
                  mode: "create",
                  fundamental:
                    fundamentalDict[Object.keys(fundamentalDict)[0]].id,
                  milestone: -1,
                },
              ],
            },
          ];
        }

        return { ...instruction, learning_moment };
      }
    );

    setActivityInstructions(updatedActivityInstructions);
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{
          display: "flex",
          backgroundColor: "neutral.100",
          p: 2,
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <Box
          id="instruction-ckeditor"
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
            width: "95%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{`${translation.formatString(translation.activityStep, {
              weight: item.weight,
            })}`}</Typography>
            <Box sx={{ display: "flex" }}>
              <IconButton
                disabled={
                  activityInstructions.findIndex(
                    (instruction) => instruction.id === item.id
                  ) === 0
                }
                onClick={handleMoveUp}
                sx={{ p: 0, m: 0 }}
              >
                <KeyboardArrowUpIcon sx={{ fontSize: "30px" }} />
              </IconButton>
              <IconButton
                disabled={
                  activityInstructions.findIndex(
                    (instruction) => instruction.id === item.id
                  ) ===
                  activityInstructions.length - 1
                }
                onClick={handleMoveDown}
                sx={{ p: 0, m: 0 }}
              >
                <KeyboardArrowDownIcon sx={{ fontSize: "30px" }} />
              </IconButton>
              <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                <Switch
                  checked={!!item.learning_moment}
                  onChange={handleChangeLearningMoment}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography>
                  {assessment
                    ? translation.evaluationMoment
                    : translation.learningMoment}
                </Typography>
              </Box>
            </Box>
          </Box>
          <RichCKEditor
            data={item.name}
            onChange={(event: any, editor: any) => {
              const data: string = editor.getData();
              setActivityInstructionName(data);
            }}
          />
          {!assessment && (
            <>
              <Typography>{`${translation.notes}`}:</Typography>
              <RichCKEditor
                data={item.recommendation}
                onChange={(event: any, editor: any) => {
                  const data: string = editor.getData();
                  setActivityInstructionRecommendation(data);
                }}
              />
            </>
          )}
          {item.learning_moment && (
            <>
              {assessment ? (
                <ActivityInstructionEvaluationMomentField
                  activityInstructionLearningMoment={item.learning_moment}
                  activityInstructionId={item.id.toString()}
                  activityInstructions={activityInstructions}
                  setActivityInstructions={setActivityInstructions}
                />
              ) : (
                <ActivityInstructionLearningMomentField
                  activityInstructionLearningMoment={item.learning_moment}
                  activityInstructionId={item.id.toString()}
                  activityInstructions={activityInstructions}
                  setActivityInstructions={setActivityInstructions}
                />
              )}
            </>
          )}
        </Box>

        <ClearIcon
          onClick={async () => {
            if (typeof item.id === "string" && item.id.includes("unassigned")) {
              const filteredActivityInstructions = activityInstructions.filter(
                (activityInstruction) => activityInstruction.id !== item.id
              );
              const finalActivityInstructions: API_Activity_Instruction[] =
                JSON.parse(JSON.stringify(filteredActivityInstructions));
              filteredActivityInstructions.forEach(
                (activityInstructions, index) => {
                  finalActivityInstructions[index].weight = index + 1;
                }
              );

              setActivityInstructions(finalActivityInstructions);
            } else {
              handleOpenDialog();
            }
          }}
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
        />
      </Box>
    </>
  );
};

export default InstructionFieldForDnd;

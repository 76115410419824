import React from "react";
import { ImageType } from "containers/ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageFive from "images/children-photo-book/page-5.png";
import Image from "components/Image/Image";
import { CaptionComponent, photoBookImageStyle } from "./constant";
import {
  photoBookPageFiveCaptionInfo,
  photoBookPageFiveImageInfo,
} from "../photoBookCommonConstant";

export const PhotoBookPageFiveComponent = ({
  scale,
  images,
}: {
  scale: number;
  images: ImageType[];
}) => {
  const imageInfo = photoBookPageFiveImageInfo(scale);
  const captionInfo = photoBookPageFiveCaptionInfo(scale);

  return (
    <>
      <Image
        src={PhotoBookPageFive}
        alt={"photo-book-page-5"}
        objectFit="cover"
        style={{ ...photoBookImageStyle }}
      />
      <Image
        src={images[0].link}
        alt={"photo-book-page-5"}
        objectFit="cover"
        style={{
          position: "absolute",
          top: imageInfo.top,
          left: imageInfo.left,
          width: imageInfo.width,
          height: imageInfo.height,
        }}
      />
      <CaptionComponent
        top={captionInfo.top}
        left={captionInfo.left}
        width={captionInfo.width}
        caption={images[0].caption}
        clampLine={captionInfo.clampLine}
        fontSize={captionInfo.fontSize}
      />
    </>
  );
};

export default PhotoBookPageFiveComponent;

import { Avatar, Box, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_School } from "types/school";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import Logo from "images/logo-2.png";
import Button from "components/Button/Button";
import {
  createSchool,
  editSchool,
  getAllSchool,
  getSchoolByID,
  getSchoolBySlug,
} from "api/school-api";
import useStore from "store/store";
import { CURLANG, SCHOOLS } from "constants/url";
import Loader from "components/LoadingIndicator/LoadingIndicator";

import { GridRowsProp } from "@mui/x-data-grid";
import useSnack from "hooks/useSnack";

interface School_Data {
  name: string;
  image?: File;
}

const CreateSchoolPage = ({
  handleClose,
  setRows,
}: {
  handleClose?: () => void;
  setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
}) => {
  const [schoolData, setSchoolData] = useState<School_Data>({
    name: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const openSnack = useSnack();
  const { schoolSlug } = useParams();

  const {
    currLanguage,
    currSchool,
    setCurrSchool,
    setSchools,
    setSnackOpen,
    setSnackSuccess,
    setSnackMessage,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currSchool: state.currSchool,
    setCurrSchool: state.setCurrSchool,
    setSchools: state.setSchools,
    setSnackOpen: state.setSnackOpen,
    setSnackSuccess: state.setSnackSuccess,
    setSnackMessage: state.setSnackMessage,
  }));

  useEffect(() => {
    setLoading(true);
    const populateData = async () => {
      if (schoolSlug) {
        const res = await getSchoolBySlug(schoolSlug);
        if (typeof res !== "string") {
          setCurrSchool(res);
        }
      }
    };
    populateData();
    setSchoolData((prev) => ({ name: currSchool.name }));
    setLoading(false);
  }, [schoolSlug]);

  const handleSubmit = async () => {
    const res: API_School | string = await createSchool(
      schoolData,
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack(`"${res.name}" school is created successfully`, true);
      if (setRows) {
        setRows((prev: any) => {
          return [...prev, res];
        });
      }
    }
    setSchools(await getAllSchool());
  };

  const handleUpdate = async () => {
    const res: API_School | string = await editSchool(
      currSchool.id.toString(),
      schoolData,
      localStorage.getItem("access")
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      openSnack("School is updated successfully", true);
    }
    setSchools(await getAllSchool());
  };

  return (
    <Box
      sx={{
        my: "auto",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "26px",
          fontWeight: "500",
          textAlign: "center",
          mb: "20px",
        }}
      >
        {schoolSlug ? "Edit School Info" : "Add a New School"}
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SchoolImage
            image={currSchool.image ? currSchool.image : Logo}
            imageFallbackChar={currSchool.name}
            setSchoolData={setSchoolData}
          />
          <NameForm schoolData={schoolData} setSchoolData={setSchoolData} />
          <Box
            sx={{
              alignSelf: "center",
              mt: "30px",
            }}
          >
            <Button
              buttonText={schoolSlug ? "Update" : "Create"}
              arrow={true}
              onClick={() => {
                if (schoolSlug) {
                  handleUpdate();
                } else {
                  handleSubmit();
                }
                if (handleClose) {
                  handleClose();
                } else {
                  navigate(`${CURLANG(currLanguage)}/${SCHOOLS}`);
                }
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const NameForm = ({
  schoolData,
  setSchoolData,
}: {
  schoolData: School_Data;
  setSchoolData: React.Dispatch<React.SetStateAction<School_Data>>;
}) => {
  return (
    <TextField
      label="Name"
      value={schoolData.name}
      onChange={(event) => {
        setSchoolData((prev) => ({ ...prev, name: event.target.value }));
      }}
    />
  );
};

const SchoolImage = ({
  image,
  imageFallbackChar,
  setSchoolData,
}: {
  image: string;
  imageFallbackChar: string;
  setSchoolData: React.Dispatch<React.SetStateAction<School_Data>>;
}) => {
  const [currImage, setCurrImage] = useState<string>(image);
  const openSnack = useSnack();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];

    if (newFile) {
      const fileSizeLimit = 5 * 1024 * 1024; // 5MB limit

      if (newFile.size > fileSizeLimit) {
        // Display a snack to inform the user
        openSnack("Image should be less than 5MB or compressed", false);
        return;
      }

      setCurrImage(URL.createObjectURL(newFile));
      setSchoolData((prev) => ({ ...prev, image: newFile }));
    }
  };
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "50%",
        width: "20vw",
        marginX: "auto",
        marginBottom: "30px",
      }}
    >
      <Avatar
        src={currImage}
        sx={{
          width: "20vw",
          height: "20vw",
          backgroundColor: "primary.main",
          color: "txt.light",
          border: "2px solid",
          borderColor: "primary.main",
          fontSize: "10vw",
        }}
        imgProps={{
          onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = Logo;
          },
        }}
      >
        {imageFallbackChar}
      </Avatar>
      <input
        accept="image/*"
        type="file"
        id={`profile-image-button`}
        hidden
        onChange={handleFileChange}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20%",
          backgroundColor: "primary.main",
          opacity: 0,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
            transition: "all 150ms linear",
          },
        }}
      >
        <label htmlFor={`profile-image-button`}>
          <IconButton
            component="span"
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: "2.5vw",
                color: "primary.light",
                cursor: "pointer",
                transition: "all 50ms linear",
                "&:hover": {
                  color: "txt.secondary",
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box>
    </Box>
  );
};

export default CreateSchoolPage;

import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import useStore from "store/store";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";

import useSnack from "hooks/useSnack";

import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import { API_Project_Unit_Guide } from "types/project";
import { ProjectUnitGuideSortableList } from "../ProjectUnitGuideComponents/ProjectUnitGuideSortableList";
import { deleteProjectUnitGuide } from "api/project-api";
import DashedButton  from "components/Button/DashedButton";

// dnd have 3 layers: DndContext, SortableContext, Item>
const ProjectUnitGuideFieldWithDnd = ({
  projectUnitGuides,
  setProjectUnitGuides,
}: {
  projectUnitGuides: API_Project_Unit_Guide[];
  setProjectUnitGuides: React.Dispatch<
    React.SetStateAction<API_Project_Unit_Guide[]>
  >;
}) => {
  // console.log("currDevelopmentalGoal: ", currDevelopmentalGoal);
  const { currentProject } = useStore((state) => state);
  return (
    <Box sx={{ width: "100%" }}>
      <Typography>{translation.unitGuide}</Typography>
      <ProjectUnitGuideSortableList
        items={projectUnitGuides}
        setItems={setProjectUnitGuides}
        renderItem={(item) => {
          return (
            <ProjectUnitGuideSortableList.Item id={item.id}>
              <ProjectUnitGuideField
                item={item}
                projectUnitGuides={projectUnitGuides}
                setProjectUnitGuides={setProjectUnitGuides}
              />
              <ProjectUnitGuideSortableList.DragHandle />
            </ProjectUnitGuideSortableList.Item>
          );
        }}
      />
      <DashedButton text="Add Resource Guide" onClick={() => {
          const updatedProjectUnitGuides = [
            ...projectUnitGuides,
            {
              id: `unassigned-id-${projectUnitGuides.length}`,
              project: currentProject.id,
              name: "",
              description: "",
              weight: projectUnitGuides.length + 1,
            },
          ];
          setProjectUnitGuides(updatedProjectUnitGuides);
        }}
      />
    </Box>
  );
};

const ProjectUnitGuideField = ({
  item,
  projectUnitGuides,
  setProjectUnitGuides,
}: {
  item: API_Project_Unit_Guide;
  projectUnitGuides: API_Project_Unit_Guide[];
  setProjectUnitGuides: React.Dispatch<
    React.SetStateAction<API_Project_Unit_Guide[]>
  >;
}) => {
  const openSnack = useSnack();
  const [updatedProjectUnitGuideData, setUpdatedProjectUnitGuideData] =
    useState<API_Project_Unit_Guide>({
      id: item.id,
      project: item.project,
      name: item.name,
      description: item.description,
      weight: item.weight,
    });

  const { currLanguage, currentProject, setCurrentProject } = useStore((state) => state);

  // To fix the re-order jumping around issue, make sure when re-ordering, if the updatedProjectUnitGuideData value doesn't change, don't run this useEffect
  // Make sure that the weight doesn't get change upon other value change.

  useEffect(() => {
    const updatedProjectUnitGuides: API_Project_Unit_Guide[] = JSON.parse(
      JSON.stringify(projectUnitGuides)
    );
    const currProjectUnitGuideIndex = projectUnitGuides.findIndex(
      (currProjectUnitGuide) => currProjectUnitGuide.id === item.id
    );
    updatedProjectUnitGuides[currProjectUnitGuideIndex].name =
      updatedProjectUnitGuideData.name;
    updatedProjectUnitGuides[currProjectUnitGuideIndex].description =
      updatedProjectUnitGuideData.description;

    setProjectUnitGuides(updatedProjectUnitGuides);
  }, [
    updatedProjectUnitGuideData.name,
    updatedProjectUnitGuideData.description,
  ]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const handleDelete = async () => {
    const res = await deleteProjectUnitGuide(Number(item.id));
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setCurrentProject({ ...currentProject, unit_guides: res });
      setProjectUnitGuides(res);
      openSnack(translation.success_remove_unit_guide, true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! ${item.name} will be deleted for all languages.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.name]);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Project Unit Guide?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
        key={`unitGuide-${item.id}`}
      >
        <Typography>{`${item.weight}.`}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "90%",
          }}
        >
          <TextField
            fullWidth
            label={translation.title}
            value={updatedProjectUnitGuideData.name}
            onChange={(event) => {
              setUpdatedProjectUnitGuideData((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
            sx={{
              textDecoration: "none",
            }}
          />
          <Box>
            <Typography sx={{ mb: "8px" }}>
              {translation.description}
              {/* <Typography component="span" sx={{ color: "red", ml: "2px" }}>
                *
              </Typography> */}
            </Typography>

            <RichCKEditor
              data={updatedProjectUnitGuideData.description}
              onChange={(event: any, editor: any) => {
                const data: string = editor.getData();

                setUpdatedProjectUnitGuideData((prev) => ({
                  ...prev,
                  description: data,
                }));
              }}
            />
          </Box>
        </Box>

        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (typeof item.id === "string" && item.id.includes("unassigned")) {
              setProjectUnitGuides(
                projectUnitGuides.filter(
                  (currProjectUnitGuide) => currProjectUnitGuide.id !== item.id
                )
              );
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Box>
    </>
  );
};

export default ProjectUnitGuideFieldWithDnd;

import { WindowSharp } from "@mui/icons-material";
import moment from "moment";
import { translation } from "constants/translation";
import {
  MONTHS,
  DAYS,
  StartAndEndDate,
  CalendarViewOptions,
  CalendarLabel,
  API_Group_Schedule,
} from "types/calendar";
import {
  LITTLES_LEARNERS_COLOR,
  LITTLES_PROJECTS_COLOR,
  LITTLE_LEARNERS,
  MONTH_VIEW_LABEL_DISPLAY,
  RIGHT_COLUMN_WIDTH,
  ROW_HEIGHT,
} from "./constants";

export const translateMonth = (monthTitle: string) => {
  switch (monthTitle) {
    case "January":
      return translation.january;
    case "February":
      return translation.february;
    case "March":
      return translation.march;
    case "April":
      return translation.april;
    case "May":
      return translation.may;
    case "June":
      return translation.june;
    case "July":
      return translation.july;
    case "August":
      return translation.august;
    case "September":
      return translation.september;
    case "October":
      return translation.october;
    case "November":
      return translation.november;
    case "December":
      return translation.december;
  }
};

export const daysOfAYear = (year: number) => {
  return isLeapYear(year) ? 366 : 365;
};

export const isLeapYear = (year: number) => {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
};

export const generateWeekDates = (weekDates: StartAndEndDate) => {
  const labels: Date[] = [];

  const startDate = new Date(weekDates.startDate.valueOf());
  const endDate = new Date(weekDates.endDate.valueOf());

  for (
    let currDate = startDate;
    currDate <= endDate;
    currDate.setDate(currDate.getDate() + 1)
  ) {
    labels.push(new Date(currDate.getTime()));
  }

  return labels;
};

export const generateMonthDates = (monthDates: StartAndEndDate) => {
  const labels: Date[] = [];

  const startDate = new Date(monthDates.startDate.valueOf());
  const endDate = new Date(monthDates.endDate.valueOf());

  for (
    let currDate = startDate;
    currDate <= endDate!;
    currDate.setDate(currDate.getDate() + 1)
  ) {
    labels.push(new Date(currDate.getTime()));
  }

  return labels;
};

export const generateYearDates = (startAndEndDate: StartAndEndDate): Date[] => {
  // return [...MONTHS].map((m, index) => ({
  //   title: m,
  //   subtitle: (index + 1).toString(),
  // }));
  // const currYear = new Date().getFullYear();
  // return [...MONTHS].map((_, index) => new Date(currYear, index));
  const labels: Date[] = [];

  const startDate = new Date(startAndEndDate.startDate.valueOf());
  const endDate = new Date(startAndEndDate.endDate.valueOf());

  for (
    let currDate = startDate;
    currDate <= endDate!;
    currDate.setDate(currDate.getDate() + 1)
  ) {
    labels.push(new Date(currDate.getTime()));
  }

  return labels;
};

export const generateDayLabels = (dates: Date[]): CalendarLabel[] =>
  dates.map((date) => ({
    title: date.getDate().toString(),
    subtitle: DAYS[date.getDay()].slice(0, 3),
  }));

const getDateStr = (date: Date) => {
  return `${date.getDate().toString()} ${MONTHS[date.getMonth()].slice(0, 3)}`;
};

export const getWeekNumber = (date: Date) => {
  const startDate = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor(
    (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
  );
  const weekNumber = Math.ceil(days / 7);
  // console.log("date: ", date, "-> ", weekNumber);
  return weekNumber;
};

export const generateMonthLabels = (dates: Date[]): CalendarLabel[] => {
  const weekDays = dates.filter((date, index) => {
    if (
      // (index % MONTH_VIEW_LABEL_DISPLAY === 0 ||
      //   index % MONTH_VIEW_LABEL_DISPLAY === MONTH_VIEW_LABEL_DISPLAY - 1 ||
      //   index === dates.length - 1)
      (date.getDay() === 0 ||
        date.getDay() === 1 ||
        index === dates.length - 1) &&
      dates.length > MONTH_VIEW_LABEL_DISPLAY
    ) {
      return date;
    }
    return;
  });

  const size = dates.length % MONTH_VIEW_LABEL_DISPLAY;

  // const cleanWeekDays = weekDays.filter((weekday) => weekday !== undefined);
  // console.log(weekDays);
  const labels = weekDays.map((weekDay, index) => {
    if (index % 2 !== 0) {
      return {
        title: `Wk ${getWeekNumber(weekDays[index - 1])}`,
        subtitle: `${getDateStr(weekDays[index - 1])} - ${getDateStr(
          weekDays[index]
        )}`,
        size: size,
      };
    }
  });

  return labels.filter((label) => label !== undefined) as CalendarLabel[];
};

export const generateYearLabels = (): CalendarLabel[] => {
  const labels = MONTHS.map((MONTH, index) => {
    const currDate = new Date(new Date().getFullYear(), 1 + index, 1);
    return {
      title: `${MONTH}`,
      subtitle: ``,
      // subtitle: `1 - ${new Date(
      //   currDate.getFullYear(),
      //   currDate.getMonth(),
      //   0
      // ).getDate()}`,
      endDay: new Date(
        currDate.getFullYear(),
        currDate.getMonth(),
        0
      ).getDate(),
    };
  });
  // console.log("Labels: ", labels);
  return labels as CalendarLabel[];
};

export const generateDetailedMonthLabels = (monthDates: StartAndEndDate[]) => {
  const labels: string[] = [];

  monthDates.forEach((obj) => {
    const { startDate, endDate } = obj;
    labels.push(
      `${startDate.getDate()} ${
        MONTHS[startDate.getMonth()]
      } - ${endDate!.getDate()} ${MONTHS[endDate!.getMonth()]}`
    );
  });

  return labels;
};

export const generateTermLabels = () => {
  const labels: string[] = [];

  for (let i = 1; i <= 5; i++) {
    labels.push(`${i}`);
  }

  return labels;
};

export const generateDurationLabel = (
  currentView: CalendarViewOptions,
  startAndEndDate: StartAndEndDate
): string => {
  // ${date.getDay()}-${date.getDay() + 7}
  switch (currentView) {
    case "Week":
      return `${
        MONTHS[startAndEndDate.startDate.getMonth()]
      } ${startAndEndDate.startDate.getFullYear()}`;
    case "Month":
      // const startMonth =
      //   startAndEndDate.startDate.getDate() >= 20
      //     ? (startAndEndDate.startDate.getMonth() + 1) %12
      //     : startAndEndDate.startDate.getMonth();
      // const year =
      //   startAndEndDate.startDate.getDate() >= 20
      //     ? startAndEndDate.startDate.getMonth() + 1
      //     : startAndEndDate.startDate.getMonth();

      return `${
        MONTHS[startAndEndDate.startDate.getMonth()]
      } ${startAndEndDate.startDate.getFullYear()} - ${
        MONTHS[startAndEndDate.endDate.getMonth()]
      } ${startAndEndDate.endDate.getFullYear()}`;
    case "Year":
      return `${startAndEndDate.startDate.getFullYear()}`;
    default:
      return new Date().getFullYear().toString();
  }
};

//week = 7 DAYS range
export const computeWeekDates = (date: Date) => {
  const firstDate = new Date(date);
  const lastDate = new Date(date);
  firstDate.setDate(firstDate.getDate() - firstDate.getDay() + 1); //get the Monday
  lastDate.setDate(lastDate.getDate() - lastDate.getDay() + 7); // get the Sunday

  const startDate = new Date(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate()
  );
  const endDate = new Date(
    lastDate.getFullYear(),
    lastDate.getMonth(),
    lastDate.getDate()
  );

  return { startDate: startDate, endDate: endDate };
};

export const computeMonthDates = (date: Date) => {
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  firstDate.setDate(firstDate.getDate() - firstDate.getDay() + 1); //get the Monday
  lastDate.setDate(lastDate.getDate() - lastDate.getDay() + 7); // get the Sunday

  const startDate = new Date(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate()
  );
  const endDate = new Date(
    lastDate.getFullYear(),
    lastDate.getMonth(),
    lastDate.getDate()
  );
  return { startDate: startDate, endDate: endDate };
};

export const computeYearDates = (date: Date) => {
  const startDate = new Date(date.getFullYear(), 0, 1);
  const endDate = new Date(date.getFullYear(), 11, 31); //11 is december (0-11 = Jan to Dec)
  return { startDate: startDate, endDate: endDate };
};

export const addDays = (date: Date, DAYS: number) => {
  const res = new Date(date);
  res.setDate(res.getDate() + DAYS);
  return res;
};

export const convertPxToDurationInDays = (
  px: string | number,
  timeUnit: "Day" | "Week" | "Month"
) => {
  switch (timeUnit) {
    case "Day":
      if (typeof px === "string")
        return Math.round(parseInt(px.slice(0, -2)) / 50) * 1440;
      else return Math.round(px / 50) * 1440;
    default:
      return 4320; // 3 DAYS
  }
};

export const convertDurationInMinToPx = (
  durationInMin: number,
  timeUnit: "Day" | "Week" | "Month"
) => {
  switch (timeUnit) {
    case "Day":
      return `${Math.round(durationInMin / 1440) * 148}px`;
    default:
      return "100px";
  }
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const vwToPx = (vw: number) => {
  return (vw * window.innerWidth) / 100;
};

export const vhToPx = (vh: number) => {
  return (vh * window.innerHeight) / 100;
};

export const pxToVw = (px: number) => {
  return (px * 100) / window.innerWidth;
};

export const vwToDays = (vw: number, gridCellWidth: number) => {
  const days = Math.round(vw / gridCellWidth);
  return days;
};

export const pxToDays = (px: number, gridCellWidth: number) => {
  const vw = pxToVw(px);
  const days = Math.round(vw / gridCellWidth);
  return days;
};

//----

export const getMousePos = (
  event: React.MouseEvent<HTMLDivElement>,
  container: React.RefObject<HTMLDivElement>
) => {
  // console.log("event", event);
  if (container && container.current) {
    const bounds = container.current.getBoundingClientRect();
    // console.log("bounds", bounds);

    const mouseX = event.pageX - bounds.left - window.scrollX;
    const mouseY = event.pageY - bounds.top - window.scrollY;
    // console.log("window.scrollY: ", window.scrollY);
    // console.log("mouseX: ", mouseX);
    // console.log("mouseY: ", mouseY);
    return [mouseX, mouseY];
  }
  return [0, 0];
};

// ----API get schedule obj

export const getProjectBoxObjects = (
  schedule: API_Group_Schedule,
  calendarDates: Date[]
) => {
  const temp_schedules = schedule.schedules_datetime.map(
    (schedule_datetime) => {
      const endAt = moment(schedule_datetime.end_at);
      const startAt = moment(schedule_datetime.start_at);
      const diffDays = endAt.diff(startAt, "days") + 1; //Plus one cause if end at 1st Jan, 1st Jan is counted.

      return {
        id: schedule_datetime.id,
        projectId: schedule.project.id,
        scheduleId: schedule.id,
        groupId: schedule.group.toString(),
        projectTitle: schedule.project.name,
        projectDescription: schedule.project.description,
        projectSlug: schedule.project.slug,
        moduleName: schedule.project.module?.name,
        moduleCategoryName: schedule.project?.module?.category,
        image: schedule.project.image,
        width: `${(RIGHT_COLUMN_WIDTH / calendarDates.length) * diffDays}vw`,
        height: `${ROW_HEIGHT - 10}px`,
        startAt: schedule_datetime.start_at,
        endAt: schedule_datetime.end_at,
        bgColor:
          schedule.project.module?.category === LITTLE_LEARNERS
            ? LITTLES_LEARNERS_COLOR
            : LITTLES_PROJECTS_COLOR,
      };
    }
  );
  return temp_schedules;
};

export const convertTimestampToString = (timestamp: string) => {
  return new Date(timestamp).toLocaleString();
};

export const getHourDiff = (currTimestamp: string, prevTimestamp: string) => {
  const currentMsgTimestamp = new Date(currTimestamp);
  const prevMsgTimestamp = new Date(prevTimestamp);

  const hourDiff =
    (currentMsgTimestamp.getTime() - prevMsgTimestamp.getTime()) /
    (1000 * 60 * 60);

  // console.log(
  //   "currentMsgTimestamp: ",
  //   currentMsgTimestamp,
  //   "XXX",
  //   "prevMsgTimestamp: ",
  //   prevMsgTimestamp,
  //   "hourDiff: ",
  //   hourDiff
  // );
  return hourDiff;
};

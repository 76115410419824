import React from "react";
import { useParams } from "react-router-dom";
import { useTeacherArchiveContext } from "./TeacherArchiveCommonComponents";
import { Box } from "@mui/material";
import { PROGRESS_REPORT } from "containers/ChildrenPortfolioPage/constants";
import { BY_CLASS, BY_PROJECT, BY_YEAR } from "../teacherArchivePageConstant";
import ProgressReportByClass from "./TeacherArchiveProgressReport/ProgressReportByClass";
import ProgressReportByYear from "./TeacherArchiveProgressReport/ProgressReportByYear";
import { COMPLETED_WORKS } from "containers/ProjectSummaryPage/constant";
import CompletedWorkByClass from "./TeacherArchiveCompletedWork/CompletedWorkByClass";
import CompletedWorkByYear from "./TeacherArchiveCompletedWork/CompletedWorkByYear";
import CompletedWorkByProject from "./TeacherArchiveCompletedWork/CompletedWorkByProject";
import { slugify } from "helper/slugify";

const TeacherArchiveTabCategoryLandingPage = () => {
  const { teacherArchiveTab, categoryTabType } = useParams();
  const { query } = useTeacherArchiveContext();
  console.log("teacherArchiveTab: ", teacherArchiveTab);
  return (
    <>
      {/* Progress Report */}
      {teacherArchiveTab === PROGRESS_REPORT ? (
        categoryTabType === BY_CLASS ? (
          <ProgressReportByClass />
        ) : (
          <ProgressReportByYear />
        )
      ) : teacherArchiveTab === slugify(COMPLETED_WORKS) ? (
        categoryTabType === BY_CLASS ? (
          <CompletedWorkByClass />
        ) : categoryTabType === BY_YEAR ? (
          <CompletedWorkByYear />
        ) : (
          <CompletedWorkByProject />
        )
      ) : (
        `${teacherArchiveTab}-${categoryTabType}-${query}`
      )}
    </>
  );
};

export default TeacherArchiveTabCategoryLandingPage;

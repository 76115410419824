import { useState } from "react";

import useStore from "store/store";
import LessonDetailCard from "components/DetailCard/LessonDetailCard";
import DndGrid from "components/DndLesson/DndGrid";
import DndCard from "components/DndLesson/DndCard";
import TeacherLessonModal from "containers/WelcomeBackPage/components/TeacherDashboardComponent/TeacherLessonModal";
import { isProfileInProjectEditableRoleList } from "helper/helper";
import { API_Project_Lesson } from "types/project";
import AddLessonButton from "./AddLessonButton";
import { usePopulateLessonsInfo } from "hooks/lessons";

const LessonsContainer = ({
  lessons,
  teacher,
}: {
  lessons: API_Project_Lesson[];
  teacher: boolean;
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currLesson, setCurrLesson] = useState<API_Project_Lesson>();
  const [currLessonIndex, setCurrLessonIndex] = useState<number>();
  const [allLessons, setAllLessons] = useState<API_Project_Lesson[]>(lessons);
  usePopulateLessonsInfo();
  const { profileDetails, currGroup, role, groupLessonInfoList } = useStore(
    (state) => ({
      profileDetails: state.profileDetails,
      currGroup: state.currGroup,
      role: state.profileDetails.role,
      groupLessonInfoList: state.groupLessonInfoList,
    })
  );

  const isTodayLesson = () => {
    if (currLesson) {
      try {
        const currentGroupLessonIdList = groupLessonInfoList
          .filter((groupLessonInfo) => groupLessonInfo.id === currGroup.id)[0]
          .current_lessons.map((currentLesson) => currentLesson.id);
        return currentGroupLessonIdList.includes(currLesson.id);
      } catch (error: unknown) {
        console.log("Error: ", error);
        return false;
      }
    }
    return false;
  };

  const currLessonWeight = () => {
    if (currLesson) {
      try {
        const currentGroupLesson = groupLessonInfoList
          .filter((groupLessonInfo) => groupLessonInfo.id === currGroup.id)[0]
          .current_lessons.filter(
            (currentLesson) =>
              currentLesson.project_id === currLesson.project_id
          )[0];
        return currentGroupLesson.weight - 1;
      } catch (error: unknown) {
        console.log("No group process found for this lesson.");

        return 0;
      }
    }
    return 0;
  };

  // const currGroupLessonInfo = groupLessonInfoList.filter((groupLessonInfo) => groupLessonInfo.id === currGroup.id)[0]

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpen = (less: API_Project_Lesson, lessonIndex: number) => {
    setCurrLesson(less);
    setCurrLessonIndex(lessonIndex);
    setOpenModal(true);
  };

  return (
    <>
      {isProfileInProjectEditableRoleList(profileDetails) ? (
        <DndGrid allLessons={allLessons} setAllLessons={setAllLessons}>
          {allLessons.map((lesson, index) => (
            <DndCard key={lesson.id} lesson={lesson}>
              <LessonDetailCard
                lesson={lesson}
                lessonIndex={index}
                handleClick={() => handleOpen(lesson, index)}
              />
            </DndCard>
          ))}
        </DndGrid>
      ) : (
        lessons.map((lesson, index) => (
          <LessonDetailCard
            key={lesson.id}
            lesson={lesson}
            lessonIndex={index}
            handleClick={() => handleOpen(lesson, index)}
          />
        ))
      )}

      <AddLessonButton />
      {currLesson && currLessonIndex !== undefined && (
        <TeacherLessonModal
          groupId={currGroup.id}
          open={openModal}
          setOpen={setOpenModal}
          lesson={currLesson}
          currLessonWeight={currLessonWeight()}
          isTodayLesson={isTodayLesson()}
        />
      )}
    </>
  );
};

export default LessonsContainer;

import { AxiosError } from "axios";
import {
  CustomerInfo,
  PaymentProduct,
  PaymentRecord,
  Subscription,
} from "types/payment";
import axios from "api/axiosConfig";

export const makePayment = async (data: any) => {
  const res = await axios.post("en/payment/save-stripe-info", data);
  return {};
};

export const createCheckoutSession = async ({
  price_id,
  customer,
  customerEmail,
  successUrl,
  cancelUrl,
  trialEnd,
  metadata,
  tiersMode,
}: {
  price_id: string;
  customer: string | undefined;
  customerEmail: string | undefined;
  successUrl: string;
  cancelUrl: string;
  trialEnd: number;
  metadata: object;
  tiersMode: string;
}) => {
  const body = {
    price_id: price_id,
    success_url: successUrl,
    cancel_url: cancelUrl,
    metadata: metadata,
    tiers_mode: tiersMode,
  };

  if (customer !== "") {
    Object.assign(body, { customer: customer });
  }

  if (customerEmail !== "") {
    Object.assign(body, { customer_email: customerEmail });
  }

  if (trialEnd !== 0) {
    Object.assign(body, { trial_end: trialEnd });
  }

  try {
    const res = await axios.post(`en/payment/create-checkout-session`, body);
    const data: any = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const createCustomerPortal = async ({
  customer,
  returnUrl,
}: // metadata,
{
  customer: string;
  returnUrl: string;
  // metadata: object;
}) => {
  const body = {
    return_url: returnUrl,
    // metadata: metadata,
  };

  if (customer !== "") {
    Object.assign(body, { customer: customer });
  }

  console.log("Body: ", body);
  try {
    const res = await axios.post(`en/payment/create-customer-portal`, body);
    const data: any = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getPaymentRecord = async ({ school }: { school: number }) => {
  try {
    const res = await axios.get(
      `en/payment/payment-record/get?school=${school}`
    );
    const data: PaymentRecord[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getPaymentRecordList = async () => {
  const res = await axios.get(`en/payment/payment-record/list`);
  const data: PaymentRecord[] = res.data;
  return data;
};

export const createPaymentRecord = async ({
  schoolId,
  customerId,
}: {
  schoolId: number;
  customerId: string;
}) => {
  const body = {
    school: schoolId,
    customer_id: customerId,
  };
  try {
    const res = await axios.post(`en/payment/payment-record/create`, body);
    const data: PaymentRecord = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const updatePaymentRecord = async ({
  paymentRecordId,
  schoolId,
  customerId,
}: {
  paymentRecordId: number;
  schoolId: number;
  customerId: string;
}) => {
  const body = {
    school: schoolId,
    customer_id: customerId,
  };
  try {
    const res = await axios.patch(
      `en/payment/payment-record/${paymentRecordId}/update`,
      body
    );
    const data: PaymentRecord[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const deletePaymentRecord = async (paymentRecordId: number) => {
  try {
    const res = await axios.delete(
      `en/payment/payment-record/${paymentRecordId}/delete`
    );
    const data: PaymentRecord = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

// export const updatePaymentRecordCustomerId = async ({
//   schoolId,
//   customerId,
// }: {
//   schoolId: number;
//   customerId: string;
// }) => {
//   let body = {
//     school: schoolId,
//     customer_id: customerId,
//   };
//   try {
//     const res = await axios.patch(
//       `en/payment/payment-record/update-customer-id-by-school`,
//       body
//     );
//     const data: PaymentRecord = res.data;
//     return data;
//   } catch (error: unknown) {
//     if (error instanceof AxiosError && error.response) {
//       return JSON.stringify(error.response.data);
//     }
//     return "No catch!";
//   }
// };

export const getCustomerInfo = async (customerId: string) => {
  try {
    const res = await axios.get(
      `en/payment/get-customer-info?customer_id=${customerId}`
    );
    const data: CustomerInfo = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getStripePaymentProductList = async () => {
  try {
    const res = await axios.get(`en/payment/product/stripe-list`);
    const data: PaymentProduct[] = await res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

export const getCustomerSubscriptionList = async (
  customerId: string,
  status = "active"
) => {
  try {
    const res = await axios.get(
      `en/payment/get-subscription-list?customer=${customerId}&status=${status}`
    );
    const data: Subscription[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

// export const getStripePaymentPriceList = async () => {
//   try {
//     const res = await axios.get(`en/payment/price/stripe-list`);
//     const data: PaymentProduct[] = res.data;
//     return data;
//   } catch (error: unknown) {
//     if (error instanceof AxiosError && error.response) {
//       return JSON.stringify(error.response.data);
//     }
//     return "No catch!";
//   }
// };
export const updateSubscriptionUsageRecord = async (
  subscriptionItemId: string,
  numberOfActiveUser: number
) => {
  try {
    const res = await axios.post(
      `en/payment/subscription/update-subscription-usage-record`,
      {
        subscription_item_id: subscriptionItemId,
        number_of_active_user: numberOfActiveUser,
      }
    );
    const data: Subscription[] = res.data;
    return data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return JSON.stringify(error.response.data);
    }
    return "No catch!";
  }
};

import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Avatar,
  MenuItem,
  ListSubheader,
  SelectChangeEvent,
  Stack,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

import {
  API_Create_Lesson,
  API_Lesson_Fundamental,
} from "types/project";
import { translation } from "constants/translation";
import useStore from "store/store";
import useDialog from "hooks/useDialog";
import useSnack from "hooks/useSnack";
import WarningDialog from "components/Dialog/WarningDialog";
import { deleteLessonObjective } from "api/project-api";

const LessonObjectiveFundamentalField = ({
  objectiveFundamental,
  index,
  handleDevelopmentalGoalChange,
  handleMilestoneChange,
  lesson,
  setLesson,
}: {
  objectiveFundamental: API_Lesson_Fundamental;
  index: number;
  handleDevelopmentalGoalChange: (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => void;
  handleMilestoneChange: (
    event: SelectChangeEvent,
    objectiveFundamentalIndex: number
  ) => void;
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
}) => {
  const openSnack = useSnack();
  const { fundamentalDict, fundamentalListToDisplay } = useStore((state) => state);
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [warningContext, setWarningContext] = useState<string>("");

  const removeLessonObjectiveFundamental = () => {
    const updatedObjectiveFundamentals: API_Lesson_Fundamental[] = JSON.parse(
      JSON.stringify(lesson.objective_fundamentals)
    );
    let filteredObjectiveFundamentals = updatedObjectiveFundamentals.filter(
      (objFundamental) => objFundamental.id !== objectiveFundamental.id
    );

    if (objectiveFundamental.id.toString().includes("unassigned-id")) {
      let count = -1;
      filteredObjectiveFundamentals = filteredObjectiveFundamentals.map(
        (objFundamental, index) => {
          if (objFundamental.id.toString().includes("unassigned-id")) {
            count += 1;
            return { ...objFundamental, id: `unassigned-id-${count}` };
          } else {
            return objFundamental;
          }
        }
      );
    }
    setLesson({
      ...lesson,
      objective_fundamentals: filteredObjectiveFundamentals,
    });
  };

  const handleDelete = async () => {
    const res = await deleteLessonObjective(Number(objectiveFundamental.id));
    if (res !== "success") {
      openSnack(res, false);
    } else {
      removeLessonObjectiveFundamental();
      openSnack(translation.deleteSuccess, true);
    }
  };

  useEffect(() => {
    const initialiseWarningContext = async () => {
      setWarningContext(
        `This action is irreversable! The Lesson Objective will be deleted for all languages. All the unsaved changes will be cleared as well. Please update them before deleting.`
      );
    };

    initialiseWarningContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: "Delete Lesson Objective?",
    warningContext: warningContext,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />
      <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="multiple-chip-label-developmental-goal-learning-moment">
            Developmental Goal
          </InputLabel>

          <Select
            labelId="multiple-chip-label-developmental-goal-learning-moment"
            value={
              objectiveFundamental
                ? objectiveFundamental.fundamental.toString()
                : "-1"
            }
            onChange={(event) => {
              handleDevelopmentalGoalChange(event, index);
            }}
            input={
              <OutlinedInput
                id="select-multiple-chip-developmental-goal-learning-moment"
                label="Developmental Goal"
              />
            }
            renderValue={(lessonObjectiveFundamentalId) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                <Chip
                  avatar={
                    <Avatar
                      alt={fundamentalDict[lessonObjectiveFundamentalId].name}
                      src={fundamentalDict[lessonObjectiveFundamentalId].image}
                    />
                  }
                  key={lessonObjectiveFundamentalId}
                  label={fundamentalDict[lessonObjectiveFundamentalId].name}
                />
              </Box>
            )}
          >
            {fundamentalListToDisplay.map((fundamental) => {
              return fundamental.sub_fundamentals &&
                fundamental.sub_fundamentals.length > 0 ? (
                <ListSubheader
                  key={`${fundamental.id}-sub-header`}
                  disableGutters
                >
                  {fundamental.name}
                </ListSubheader>
              ) : fundamental.main_fundamental ? (
                <MenuItem
                  key={`${fundamental.id}-dev-goal`}
                  value={fundamental.id}
                >
                  {fundamental.name}
                </MenuItem>
              ) : (
                [
                  <ListSubheader
                    key={`${fundamental.id}-sub-header`}
                    disableGutters
                  >
                    {fundamental.name}
                  </ListSubheader>,
                  <MenuItem
                    key={`${fundamental.id}-dev-goal`}
                    value={fundamental.id}
                  >
                    {fundamental.name}
                  </MenuItem>,
                ]
              );
            })}
          </Select>
        </FormControl>
        {fundamentalDict[objectiveFundamental.fundamental || "-1"]
          .milestones ? (
          <>
            {fundamentalDict[objectiveFundamental.fundamental || "-1"]
              .milestones.length > 0 ? (
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="multiple-chip-label-milestone">
                  {translation.milestone}
                </InputLabel>

                <Select
                  labelId="multiple-chip-label-milestone"
                  value={
                    objectiveFundamental.milestone
                      ? objectiveFundamental.milestone?.toString()
                      : "-1"
                  }
                  onChange={(event) => {
                    handleMilestoneChange(event, index);
                  }}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip-milestone-learning-moment"
                      label="Milestones"
                    />
                  }
                  renderValue={(selectedMilestoneId) => {
                    const filteredMilestone = fundamentalDict[
                      objectiveFundamental.fundamental || "-1"
                    ].milestones.filter(
                      (milestone) =>
                        milestone.id.toString() === selectedMilestoneId
                    );
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        <Chip
                          key={selectedMilestoneId}
                          label={
                            filteredMilestone.length > 0
                              ? filteredMilestone[0].name
                              : "Please select a milestone"
                          }
                        />
                      </Box>
                    );
                  }}
                >
                  {fundamentalDict[
                    objectiveFundamental
                      ? objectiveFundamental.fundamental
                      : "-1"
                  ].milestones.map((milestone) => (
                    <MenuItem key={milestone.id} value={milestone.id}>
                      {milestone.name}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="-1">
                    Please select a milestone
                  </MenuItem>
                </Select>
              </FormControl>
            ) : null}
          </>
        ) : null}
        <ClearIcon
          sx={{
            color: "red",
            cursor: "pointer",
            transition: "all 100ms linear",
            "&:hover": {
              color: "darkred",
              transition: "all 100ms linear",
            },
          }}
          onClick={async () => {
            if (
              typeof objectiveFundamental.id === "string" &&
              objectiveFundamental.id.includes("unassigned")
            ) {
              removeLessonObjectiveFundamental();
            } else {
              handleOpenDialog();
            }
          }}
        />
      </Stack>
    </>
  );
};


export default LessonObjectiveFundamentalField;
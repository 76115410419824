import React from "react";
import { ImageType } from "../../../../../../ChildrenPortfolioPage/Screens/PhotoAlbumScreenComponents/PhotoAlbumComponents";
import PhotoBookPageTwo from "../../../../../../../images/children-photo-book/page-2.png";
import { CaptionPDFComponent } from "./constant";
import {
  imageSrc,
  styles,
} from "../../../../../../../components/ChildrenPortfolioPDF/Templates/constant";
import { Image } from "@react-pdf/renderer";
import {
  PDFScale,
  photoBookPageTwoCaptionInfo,
  photoBookPageTwoCaptionPositionList,
  photoBookPageTwoImageInfo,
  photoBookPageTwoImagePositionList,
} from "../photoBookCommonConstant";

export const PhotoBookPageTwoComponent = ({
  images,
}: {
  images: ImageType[];
}) => {
  const scale = PDFScale;
  const imagePositionList = photoBookPageTwoImagePositionList(images, scale);
  const captionPositionList = photoBookPageTwoCaptionPositionList(
    images,
    scale
  );
  const imageInfo = photoBookPageTwoImageInfo(scale);
  const captionInfo = photoBookPageTwoCaptionInfo(scale);

  return (
    <>
      <Image src={imageSrc(PhotoBookPageTwo)} style={styles.background} />

      {imagePositionList.map((imagePositionInfo) => {
        return (
          <Image
            key={`image-${imagePositionInfo.src}`}
            src={imageSrc(imagePositionInfo.src)}
            style={{
              position: "absolute",
              top: imageInfo.top,
              left: imagePositionInfo.left,
              width: imageInfo.width,
              height: imageInfo.height,
              objectFit: "cover",
            }}
          />
        );
      })}
      {captionPositionList.map((captionPositionInfo) => {
        return (
          <CaptionPDFComponent
            key={`caption-${captionPositionInfo.caption}`}
            top={captionInfo.top}
            left={captionPositionInfo.left}
            width={captionInfo.width}
            caption={captionPositionInfo.caption}
            clampLine={captionInfo.clampLine}
            fontSize={captionInfo.fontSize * scale}
            textAlign="center"
          />
        );
      })}
    </>
  );
};

export default PhotoBookPageTwoComponent;

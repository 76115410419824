import { pageSize, styles } from "./constant";
import Background from "images/children-portfolio-v2/concluding-remark.png";

import { Page, Text, Image, View } from "@react-pdf/renderer";

const ConcludingRemarksPageEnglish = ({
  paragraphs,
  englishFirst,
  multipleLanguages,
  exception,
}: {
  paragraphs: any[];
  englishFirst: boolean;
  multipleLanguages: boolean;
  exception?: boolean;
}) => {
  const englishText = paragraphs.join("\n\n");

  let partOne = "";
  let accumulatedLength = 0;
  let partTwo = "";
  let i = 0;

  if (paragraphs.length > 4 || englishText.length > 2000) {
    for (; i < paragraphs.length; i++) {
      let newAccumulatedLength = accumulatedLength + paragraphs[i].length + 50;
      if (newAccumulatedLength <= 2000) {
        partOne += paragraphs[i] + "\n\n";
        accumulatedLength = newAccumulatedLength;
      } else {
        break;
      }
    }
    partTwo = paragraphs.slice(i).join("\n\n");
  } else {
    partOne = englishText;
  }

  if (exception) {
    return (
      <Page size={pageSize} style={styles.body}>
        <View style={styles.main} wrap={false}>
          <Image src={Background} style={styles.background} />
          <Text
            style={{
              position: "relative",
              top: 170,
              left: 85,
              right: 80,
              fontSize: 12,
              maxWidth: "78%",
              fontFamily: "FuturaLtBT",
            }}
          >
            {englishText}
          </Text>
        </View>
      </Page>
    );
  }

  if (englishText.length < 1100 && paragraphs.length <= 3) {
    if (multipleLanguages) {
      return (
        <Text
          style={{
            position: "relative",
            top: englishFirst ? 170 : 190,
            left: 85,
            right: 80,
            fontSize: 12,
            maxWidth: "78%",
            fontFamily: "FuturaLtBT",
          }}
        >
          {englishText}
        </Text>
      );
    } else {
      return (
        <Page size={pageSize} style={styles.body}>
          <Image src={Background} style={styles.background} />

          <View style={styles.main} wrap={false}>
            <Text
              style={{
                position: "relative",
                top: 170,
                left: 85,
                right: 80,
                fontSize: 12,
                maxWidth: "78%",
                fontFamily: "FuturaLtBT",
              }}
            >
              {englishText}
            </Text>
          </View>
        </Page>
      );
    }
  } else
    return (
      <>
        <Page size={pageSize} style={styles.body}>
          <View style={styles.main} wrap={false}>
            <Image src={Background} style={styles.background} />

            <Text
              style={{
                position: "relative",
                top: 170,
                left: 85,
                right: 80,
                fontSize: 12,
                maxWidth: "78%",
                fontFamily: "FuturaLtBT",
              }}
            >
              {partOne}
            </Text>
          </View>
        </Page>

        {/* Conditionally render second page if partTwo exists */}
        {partTwo && (
          <Page size={pageSize} style={styles.body}>
            <View style={styles.main} wrap={false}>
              <Image src={Background} style={styles.background} />

              <Text
                style={{
                  position: "relative",
                  top: 170,
                  left: 85,
                  right: 80,
                  fontSize: 12,
                  maxWidth: "78%", // Adjust based on your needs

                  fontFamily: "FuturaLtBT",
                }}
              >
                {partTwo}
              </Text>
            </View>
          </Page>
        )}
      </>
    );
};

export default ConcludingRemarksPageEnglish;

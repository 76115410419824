import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import useStore from "store/store";
import {
  translatedTabs,
  PHOTO_ALBUM,
  ALL_PHOTOS,
  ALBUMS,
} from "containers/ChildrenPortfolioPage/constants";
import { staticTabStyle } from "containers/ProjectSummaryPage/Screens/ConceptScreen";
import {
  CHILDREN_PORTFOLIO_PAGE,
  CURLANG,
  MY_CHILDREN_PORTFOLIO,
} from "constants/url";

const Container = ({
  children,
  isParentView = false,
}: {
  children: React.ReactNode;
  isParentView?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { groupSlug, childID } = useParams();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const tabs = [ALL_PHOTOS, ALBUMS];
  const tabIndex = tabs.findIndex((tab) => pathname.includes(tab));

  const handleTabChange = (event: React.SyntheticEvent, index: number) =>
    navigate(
      `${CURLANG(currLanguage)}/${
        isParentView
          ? `${MY_CHILDREN_PORTFOLIO}/${childID}`
          : CHILDREN_PORTFOLIO_PAGE(groupSlug!, childID!)
      }/${PHOTO_ALBUM}/${tabs[index]}`
    );

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="fullWidth"
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={translatedTabs[tab]}
            sx={{
              ...staticTabStyle,
              ml: index === 0 ? 0 : 2,
              backgroundColor:
                index === tabIndex ? "primary.light" : "transparent",
              pointerEvents: index === tabIndex ? "none" : "auto",
            }}
          />
        ))}
      </Tabs>

      <Box
        sx={{
          p: 3,
          border: "2px solid var(--primary-main)",
          backgroundColor: "background.paper",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Container;

import LocalizedStrings from "react-localization";

export const translation = new LocalizedStrings({
  en: {
    //English
    //General
    updated: "Updated",
    story:"Story",

    //LandingPage
    whereKidsLoveLearning: "Where Kids Love Learning",
    findOutMore: "Find out more",
    takeAPeek:
      " Take a peek into our Littles' Projects dedicated to raising change-makers",
    getAccessToLittleLab: "Get access to Little Lab",
    everyChildIsDifferent: "Every child is different!",
    comeAndExperienceIndividualized:
      "Come and experience an individualized learning tailored to",
    your: "your",
    childSmall: "child",
    createAUniqueLearningExperience:
      "Create a unique learning experience today",
    greatContentCreatedBy:
      " Great content created by leading experts in early education based on the latest research and pedagogical methods",
    learnMore: "Learn more",
    superEasyAndIntuitive:
      "Super easy and intuitive to use whether you are a parent or teacher.",
    perfectEvenFor: "Perfect even for",
    homeSchoolers: "Home Schoolers",
    andSmall: "and",
    learningCommunities: "learning communities",
    discoverTheFutureOfEducation: "Discover the future of education",
    ourProprietaryTrehaus:
      "  Our Proprietary Trehaus Little Lab curriculum comprises 6 future and soft skills",
    ourLittleLearners: "Our Little Learners",
    enrichYourChild:
      "Enrich your child's knowledge through these fully-structured and self-paced lessons designed to engage your child in meaningful and thoughtful activities in literacy and numeracy.",
    hearWhatOtherParents: "  Hear what other parents and educators have to say",
    weSimplyLove:
      "We simply love the value-driven approach to education that Trehaus stands for. This is not just a pre-school, this is a strong community based on amazing values. I highly recommend Trehaus.",
    confidence: "Confidence",
    empathy: "Empathy",
    creativity: "Creativity",
    perseveranceGrit: "Perseverance & Grit",
    communicationSkills: "Communication Skills",
    selfRegulation: "Self-Regulation",
    integrity: "Integrity",
    executiveFunction: "Executive Function",
    steam: "STEAM",
    englishLanguageLiteracy: "English Language & Literacy",
    mandarinLanguageLiteracy: "Mandarin Language & Literacy",
    numeracyMathConcepts: "Numeracy and Math Concepts",
    grossMotorSkills: "Gross Motor Skills",
    fineMotorSkills: "Fine Motor Skills",
    gratitudeGivingBack: "Gratitude & Giving Back",
    environmentalResponsibility: "Environmental Responsibility",
    socialAwareness: "Social Awareness",
    publicSpeakingAdvocacy: "Public Speaking & Advocacy",
    interdependence: "Interdependence",
    practicalLifeSkills: "Practical Life Skills",
    globalPerspectives: "Global Perspectives",
    littleCreative: "Little Creative",
    littleCeo: "Little CEO",
    littleChef: "Little Chef",
    littlePhilanthropist: "Little Philanthropist",
    littleEngineer: "Little Engineer",
    littleEntrepreneur: "Little Entrepreneur",
    theLearningExperiencesDesigned:
      "The learning experiences designed in this curriculum are relevant to your child’s daily life, providing them a purposeful context to develop knowledge and skills in Mandarin language and literacy.",
    littleJournalist: "Little Journalist",
    exposeYourChildToKnowledge:
      "Expose your child to the knowledge of English language and literacy in a fun and engaging way. Little Journalist provides a unique multi-sensory learning experiences to integrate all aspects of literacy for your child.",
    littleMathematician: "Little Mathematician",
    littleMathematicianProvidesAnAuthentic:
      "Little Mathematician provides an authentic, fun and hands-on learning experience to support your child in developing a solid foundation in math and logical thinking, step-by-step, developing and honing their ability to think mathematically and problem-solve.",
    trehausSchoolMomHelle: "Trehaus School Mom, Helle",
    lauraIsAlwaysExcited:
      "Laura is always excited to go for lessons! Thank you for encouraging her independence, instilling important life values and making it absolutely fun to learn. Our minds are at ease while we work, because of your love and care.",
    trehausSchoolMomRaine: "Trehaus School Mom, Raine",
    jaydenIsReallyLucky:
      "Jayden is really lucky to have gentle and caring teachers, who always shower the children with love and concern. Jayden loves them!  Sending Jayden to Trehaus School and working at Trehaus is really the best decision we have made!",
    trehausSchoolMomOlivia: "Trehaus School Mom, Olivia",
    myBiggestSatisfactionIsSeeing:
      "My biggest satisfaction is seeing my son Nate thrive and grow in Trehaus, he loves school so much, and I love how I can work in the Business Club a few steps away, and be part of these precious first years of his life.",
    trehausSchoolMomElaine: "Trehaus School Mom, Elaine",
    incrediblyDedicatedAndNurturing:
      "Incredibly dedicated and nurturing teaching staff and principal at Trehaus School. I was absolutely impressed by their developmental reports and the amount of effort placed on articulating our daughter's progress at school.",

    trehausSchoolDadStephen: "Trehaus School Dad, Stephen",
    everyPersonInTheSchool:
      "Every person in the school is genuinely caring for the children and treats all the children as their own, so I trust that my son is in good hands every day when I go to work.",
    trehausSchoolDadRodney: "Trehaus School Dad, Rodney",

    //Dashboard
    totalChildren: "Total Children",
    totalTeachers: "Total Teachers",
    totalClasses: "Total Classes",
    currentlyOn: "Currently On",
    proTips: "Pro Tips: Type text followed by [] to add a todo",
    typeYourNoteHere: "Type your note here",
    message: "Message",
    timeTable: "Timetable",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    teachers: "Teachers",
    teacher: "Teacher",
    children: "Children",
    total: "Total",
    viewClass: "View Class",

    //HomePage
    welcome: "Welcome Back, ",
    dashboard: "My Dashboard",
    whoLearning: "Who will be learning today?",
    viewGroup: "View class",
    updateProfile: "Update profile",
    broadcastMsg: "Broadcast Message",
    allGroups: "All Classes",
    noOngoingLesson: "No Ongoing Lesson",
    noLesson: "No Lesson",
    students: "Students",
    formTeacher: "Form Teacher",
    todayLessons: "Today's Lessons",
    tmrLessons: "Upcoming Lessons",
    lessonCompleted: "Lesson Completed",
    lessonsCompleted: "Lessons Completed",
    lessonConducted: "Lesson Conducted",
    lessonsConducted: "Lessons Conducted",
    resume: "Resume",
    resumeLesson: "Resume Lesson",
    goToLesson: "Go To Lesson",
    newEnrollments: "New Enrolments",
    lesson_number: "Lesson {number}",
    myClasses: "My Classes",
    classDetail: "Class Detail",
    attendance: "Attendance",
    currentLessons: "Ongoing Lessons",
    view: "View",
    myChildren: "My Children",
    announcements: "Announcements",
    noAnnouncementYet: "No announcement yet",
    noActionRequiredYet: "No action required yet",
    viewAllAnnouncements: "View all announcements",
    showActiveAnnouncementsOnly: "Show active announcements only",
    littleAlbum: "Little Album",
    mostRecentPhotos: "Most recent photos",
    quickItems: "Quick Items",
    addTodo: "Add Todo",
    notesUpdatedSuccess: "Notes updated successfully!",
    notesUpdatedFailed: "Failed to update notes!",
    addTodoInstruction: "Pro Tips: Type [] to add a todo!",
    typeYourNotesHere: "Type your notes here...",

    //MyChildrenPage
    viewPortfolio: "View Portfolio",
    bornOn: "Born on",
    parent: "Parent",

    //My Classes
    classList: "Class List",
    editClass: "Edit Class",

    //Edit Class
    update: "Update",

    //AnnouncementsPage
    createAnnouncement: "Create Announcement",
    addNewAnnouncement: "Add New Announcement",
    editAnnouncement: "Edit Announcement",
    deleteAnnouncement: "Delete Announcement",
    announcementCreatedSuccess: "Announcement created successfully!",
    announcementCreatedFailed: "Failed to create announcement!",
    announcementUpdatedSuccess: "Announcement updated successfully!",
    announcementUpdatedFailed: "Failed to update announcement!",
    announcementRecalledSuccess: "Announcement recalled successfully!",
    announcementRecalledFail: "Failed to recall announcement!",
    announcementRecalled: "This announcement has been recalled.",
    announcementDeletedSuccess: "Announcement deleted successfully!",
    announcementDeletedFailed: "Failed to delete announcement!",
    announcementStartAt: "Announcement Start At",
    announcementEndAt: "Announcement End At",
    includesAttachment: "Includes attachment(s)",
    pinAnnouncement: "Pin Announcement?",
    anyResponseRequired: "Any response required?",
    responseRequired: "Response required",
    responseQuestion: "Response question",
    responseType: "Response type",
    respondBy: "Respond by",
    allowTextResponse: "Allow text response?",
    textResponse: "Text Response",
    textResponseInstruction: "Text response instruction",
    allowAttachmentUpload: "Allow attachment upload?",
    attachmentInstruction: "Attachment instruction",
    responseOption: "Response Option",
    addResponseOption: "Add Response Option",
    responseUpdated: "Response updated!",
    responseUpdateFail: "Response update fail! Please try again later",
    uploadDocument: "Upload Document",
    acknowledge: "Acknowledge",

    // Documents Page
    createDocument: "Create Document",
    addNewDocument: "Add New Document",
    editDocument: "Edit Document",
    deleteDocument: "Delete Document",
    document: "Document",
    documents: "Documents",
    noDocumentYet: "No document yet",
    pleaseUploadAtLeastOneDocument: "Please upload at least 1 document!",

    //BrowsePage
    noChild: "You have no child registered",
    noGroup: "You have no class registered",
    noGroupAssigned:
      "No class is assigned to you! Please contact school admin for further clarification",
    browse: "Browse All Projects",
    noProjectFound: "No Project Found",
    searchForProjects: "Search for projects",
    recommendations: "Recommendations",
    displayAll: "Display All Project",
    specially: "Specially For You",
    specialDesc: "Our best-in-class individualised learning experience",
    trending: "Trending Projects",
    trendDesc: "See what's popular on The Little Lab",
    new: "New in!",
    newDesc: "Browse the latest projects by our team of experts",
    byModule: "By Modules",
    byLevels: "By Levels",
    addToBackpack: "Add to Backpack",
    removeFromBackpack: "Remove",
    addedToBackpack: "Added to backpack!",
    viewProjectDetails: "View project details",
    viewAssessmentDetails: "View assessment details",
    addOrScheduleItToView: "Add / Schedule it to view!",

    //navigationbar
    home: "Home",
    lessonLibrary: "Curriculum",
    library: "Lesson Plan Library",
    assessments: "Assessments",
    backpack: "Backpack & Favourites",
    visualTimetable: "Visual Timetable",
    scheduler: "Scheduler",
    littleEvaluator: "Little Evaluator",
    calendar: "Calendar",
    myClass: "My Class",
    portfolios: "Portfolios",
    childrenPortfolio: "Children's Portfolios",
    teacherPortfolio: "Teacher's Archive",
    notices: "Notices",

    //ProjectDetailPage
    lesson: "Lesson",
    lessons: "Lessons",
    saved: "Saved",
    notSaved: "Not Saved",
    overview: "Overview",
    unitGuide: "Resource Guide",
    plan: "Lesson Plans",
    assessmentPlans: "Assessment Plans",
    teachingMaterials: "Teaching Materials",
    communitySharing: "Community Sharing",
    whatPeopleLike: "What People Like",
    tags: "Tags",

    //Teaching Material
    getTeachingMaterials: "Get unit materials",
    downloadMaterials: "Download Materials",

    //ProjectMoment
    uploadPhoto: "Upload photo",
    uploadPhotos: "Upload photos",
    uploadVideos: "Upload videos",
    uploadMedia: "Upload media",
    caption: "Caption",
    typeCaption: "Type a caption",
    addCaption: "Add caption",
    upload: "Upload",
    uploadFinalizing: "Finalizing...Please do not refresh...",
    uploadPhotoFinalizing: "Finalizing photos...Please do not refresh...",
    uploadVideoFinalizing: "Finalizing videos...Please do not refresh...",
    noActivity: "No activity",
    noPhoto: "No photo yet",
    failed_load_gallery: "Failed to load gallery!",
    failed_upload: "Failed to upload!",
    failed_add_photos: "Failed to add photos!",
    failed_add_videos: "Failed to add videos!",
    failed_add_photos_videos: "Failed to add photos or videos!",
    success_upload: "Uploaded successfully!",
    success_upload_photos: "Photos are uploaded successfully!",
    success_upload_videos: "Videos are uploaded successfully!",
    success_upload_photos_videos:
      "Photos and videos are uploaded successfully!",
    success_update_image: "Updated successfully!",
    success_delete_image: "Photo(s) deleted successfully!",
    success_delete_video: "Video(s) deleted successfully!",
    success_delete_photo_video: "Photo(s) and Video(s) deleted successfully!",
    fail_delete_photo_video: "Failed to delete Photo(s) and Video(s)!",
    delete_photo: "Delete {number} selected photo(s)",
    delete_video: "Delete {number} selected video(s)",
    delete_photo_video: "Delete {number} selected photo(s)/video(s)",
    selected: "Selected",
    allPhotos: "All Photos",
    allVideos: "All Videos",
    allPhotosVideos: "All photos and videos",
    irreversibleAction: "The action is irreversible!",
    tagChildren: "Tag children",
    profiles: "Profiles",
    edit_image: "Edit image",
    edit_video: "Edit video",

    //Edit Image
    flipHoriz: "Flip Horizontally",
    flipVerti: "Flip Vertically",
    rotateAntiClockwise: "Rotate Anti-Clockwise",
    rotateClockwise: "Rotate Clockwise",

    //LessonDetailPage
    begin: "Let's begin!",
    scheduleProject: "Schedule project",
    day: "day",
    days: "days",
    objective: "Learning Objective",
    fundamental: "Developmental Goals",
    noFundamental: "No Developmental Goals",
    guidingQuestions: "Focus Questions",
    ratings: "Ratings",
    noRating: "No Ratings Yet",
    lessonNo: "Lesson {number}",
    assessmentPlanNo: "Assessment Plan {number}",
    min: "min",
    conduct: "{number} activities to be conducted",
    Sproutlings: "Sproutlings",
    Sprouts: "Sprouts",
    Buds: "Buds",
    Blossoms: "Blossoms",

    //Account page
    male: "Male",
    female: "Female",
    gender: "Gender",
    highestEducationQualification: "Highest Educational Qualification",
    educationStartDate: "Education Start Date",
    noFormalRecordYet: "No Formal Record Yet",
    highSchool: "High School",
    associateDegree: "Associate Degree",
    bachelorsDegree: "Bachelor’s Degree",
    mastersDegree: "Master’s Degree",
    doctorateDegree: "Doctorate Degree",

    //profileSidebar
    profileSectionAbout: "About",
    profileSectionExperience: "Experience",
    profileSectionReflections: "Reflections",
    profileSectionCertifications: "Certifications",
    profileSectionTestimonials: "Testimonials",
    profileSectionProjects: "Past Projects",

    //profileIconMenu
    account: "Account",
    settings: "Settings",
    profile: "Profile",
    schoolProfile: "School Profile",
    adminPage: "Admin Page",
    createNewClass: "Create New Class",
    addNewChild: "Add New Child",
    manageProjects: "Manage Projects",
    helpCenter: "Help Center",
    registerSiteAdmin: "Register Site Admin",
    registerSchoolAdmin: "Register School Admin",
    registerContentEditor: "Register Content Editor",
    registerTTAContentEditor: "Register TTA Content Editor",
    registerTeacher: "Register Teacher",
    registerParent: "Register Parent",
    registerChild: "Register Child",
    enroll: "Enroll Child",
    provideFeedback: "Provide Feedback",
    viewFeedback: "View Feedback",
    logout: "Logout",
    switchToTTA: "Switch to Teacher Training Academy",
    switchToTTB: "Switch to Teaching ToolBox",

    //Library
    backToLibrary: "Back to Library",
    speciallySelectedForYourChildren: "Specially selected for your children",
    browseL: "Browse",

    //Schedule Modal
    scheduleModalTagline:
      "Schedule when your project is going to take place!{0}You can view this in your curriculum calendar under our 'Scheduler' feature.",
    projectStartDate: "Project Start Date",
    projectEndDate: "Project End Date",

    //School Profile Page
    addBackgroundPhoto: "Add background photo",
    removeBackgroundPhoto: "Remove background photo",
    updateBackgroundPhoto: "Update background photo",
    addDescription: "Include a short write-up!",
    deleteSuccess: "Successfully deleted!",
    deleteFailed: "Failed to delete!",
    updateSuccess: "Successfully updated!",
    updateFailed: "Failed to update!",
    createSuccess: "Successfully created!",
    createFailed: "Failed to create!",
    removePhoto: "Remove photo",

    //ActivityPage
    back: "Back",
    pResources: "Project Resources",
    activityNo: "Activity {number}",
    optionNo: "Option {number}",
    lessonOverview: "Lesson Overview",
    checkpoints: "Summary and Checkpoint",
    previousLesson: "Previous Lesson",
    nextLesson: "Next Lesson",
    activities: "Activities",
    startActivity: "Start Activity",
    reqConduct: "required activities to be conducted",
    startLesson: "Start Lesson",
    noVocabulary: "No Vocabulary",
    noMaterial: "No Material",
    noInstruction: "No Instruction",
    deletedInstructions: "Step has been successfully removed",
    pleaseSchedule:
      "Please Schedule this project before marking the activity as done!",
    pleaseUploadPhotoVideo:
      "Please upload at least 1 photo or 1 video before marking the activity as done!",
    completeAll: "Complete all the activities!",
    completePartial: "Complete {number} out of {total} activities!",
    pickToComplete: "Pick 1 activity to complete!",

    //ActivityDetailPage(DrawingPad)
    stepByStepInstructions: "Step By Step Instructions",
    addStep: "Add Step",
    done: "Done",
    activityCompleted: "Activity Completed",
    notDone: "Not Done",
    checkpointIcon: "Checkpoints:",
    noCheckpoints: "No checkpoints",
    addCheckpoint: "Add Checkpoint",
    noLearningMoments: "No Learning Moment",
    learningMoment: "Learning Moment",
    evaluationMoment: "Evaluation Moment",
    learningMoments: "Learning Moments",
    evaluationMoments: "Evaluation Moments",
    addLearningMoment: "Add Learning Moment",
    learningMomentRecorded: "Learning moment recorded",
    milestoneCheckIncomplete:
      "You're almost there, click to finish your learning moment!",
    photoIncomplete:
      "You’re almost there, upload photo to finish your learning moment!",
    previous: "Previous",
    summary: "Lesson Summary",
    selectAll: "Select All",
    deselectAll: "Deselect All",
    milestoneCheck: "Milestone Check",
    milestoneCheckDescription:
      "Use this checklist to see if your children have achieved these projected milestones. Feel free to do this in between lessons or at the end of your unit.",

    milestoneCheckWithCheckpoint: "Milestone Check (Checkpoints)",
    pleaseNavigateToLastPageToClickDone: `Please navigate to the last page in order to access and click the "Done" button.`,

    //Review
    noReview: "No review yet",
    noContent: "No Content yet",
    writeAReview: "Write a review",
    shareReview:
      "What do you like about the project, and/or what worked well for you?",
    feedbackToDevTeam:
      "Any feedback or suggestions you have to provide to help us make the project better?",
    thankyouFeedback: "Thank you for your feedback!",
    viewReviews: "View All Reviews",
    thanksReviews: "Thanks for your review!",
    reviewPosted: "Review posted!",
    reviewUpdated: "Review updated!",
    reviewDeleted: "Review deleted!",
    tryAgainLater: "Please try again later",
    deleteReview: "Delete review",
    replyTo: "Reply to {name}...",
    writeAComment: "Write a comment...",
    deleteComment: "Delete comment?",
    commentDeleted: "Comment deleted!",
    edited: "Edited",
    like: "Like",
    liked: "Liked!",
    unLiked: "Un-liked!",
    reply: "Reply",
    comment: "Comment",
    addPhotos: "Add Photos",
    mostRecent: "Most Recent",
    mostLiked: "Most Liked",

    //projectSummaryPage
    projectSummary: "Project Summary",
    projectSummaries: "Project Summaries",
    projectSummaryReport: "Project Summary Report",
    backProject: "Back to Project",
    congrats: "Congratulations!",
    completedProject: "You have completed the project.",
    photoAlbum: "Photo Album",
    rate: "Rate your experience",
    howProjectShareExperience: "How was the project? Share your experience!",
    beforeDownload: "Before you download,",
    beforeGo: "Before you go,",
    recoProj: "Recommended Projects",
    completedCP: "Checkpoints Completed:",
    downloadReport: "Download Report",
    openReport: "Open Report",
    generatingReport: "Generating Report",
    viewMore: "View More",
    viewAll: "View All",
    reGenerateReport: "Re-generate your report now",
    getReport: "Download your custom report now",
    photos: "Photos",
    videos: "Videos",
    totalNumberLessons: "Total No. of Lessons",

    //ProjectReportPDF
    projectOverview: "Project Overview",
    presentedBy: "Proudly Presented By:",
    congratsText: "on the successful completion of the project",
    changeTemplate: "Change Template",
    pickImage: "Pick An Image",
    pickTemplate: "Pick A Template",

    //Backpack Page
    noenrolledChild: "You have no enrolled children",
    noBackpack: "No Backpack  items",
    favourites: "Favourites",
    addFavourites: "Add to Favourites",
    noFavourites:
      "No items here yet. Go to our library to find your favourite projects!",

    //Analytic
    sevenDays: "Last 7 days",
    thirtyDays: "Last 30 days",
    oneYear: "1 Year",
    allTime: "All Time",

    //portfoliopage
    age: "Age",
    year: "year",
    years: "years",
    month: "month",
    months: "months",
    appraisal: "Overall Teacher's appraisal for {name}",
    noAppraisal: "No appraisal",
    presentGoals: "Present goals for {name}",
    goalHistory: "View goal history",
    noGoals: "No goals",
    headProgress: "{name}'s {tab} progress",
    progressHistory: "View progress history",
    latestAccom: "Latest accomplishments",
    accomHistory: "View accomplishment history",
    improve: "Area for improvements",
    projectDone: "Projects done by {name}",
    allDetails: "View all details",
    hideDetails: "Hide all details",
    pastProject: "View all past projects",
    recoAct: "Recommended activities for {name}'s improvement",
    noCompletedProject: "No completed projects",
    downloadpdf: "Download pdf report",
    showLesson: "Show Lessons",
    hideLesson: "Hide Lessons",
    portfolio: "Portfolio",
    childPortfolio: "{name}'s Portfolio",
    teacherNameArchive: "{name}'s Archive",
    developmentalGoals: "Developmental Goals",
    progressReport: "Progress Report",
    completedWorks: "Little Works",
    createNew: "Create New",
    selectDuration: "Select Duration",
    names: "{name}'s",
    learningJourney: "Learning Journey",
    classInfo: "Class Info",
    noChildAssigned: "No child is assigned to the class",
    noTeacherAssigned: "No teacher is assigned to the class",
    observation: "Observation",
    addObservation: "Add Observation",
    milestoneCompleted: "Milestone Completed",
    nextMilestone: "Next Milestone",
    whatsNext: "What's next?",
    takeAction: "Take action!",
    noReflections: "No reflections yet, why not write one?",
    noTestimonials:
      "No testimonials yet, why not ask your peers to write you one?",
    noTestimonialsVisitor: "No testimonials yet. Why not write one?",
    noCertifications:
      "No certifications yet, head over to Teacher Training Academy to get certified!",
    visible: "Visible",
    hidden: "Hidden",
    attained: "Attained",
    notAttained: "Not attained",
    published: "Published",
    unPublished: "Unpublished",
    dateObserved: "Date Observed",
    deleteObservation: "Delete Observation",
    reachHighestMilestone:
      "Congratulation! You have reached the highest milestone in this developmental goal.",
    findNewProject: `Find a new project in our Library!`,
    parentView: `Parent View`,
    whatIsParentView: `What is Parent View`,
    nextMilestoneIsHidden: `Next Milestone is hidden`,
    blankSubDevGoalAreHidden: `Blank sub-developmental goals are hidden`,
    albums: "Albums",
    addNewAlbum: "Add new album",
    noAttainedMilestone: "No attained milestone!",
    congratulation: "Congratulation!",
    milestoneCompletedCongratulation:
      "Hooray! You have achieved your milestone!",

    showDevGoalWithRecordedLearningMoment: `Turn on 'Parent View' to show only developmental goals with recorded learning moments!`,
    filterAndShowParent: `You can also choose to filter and show parents learning moments that have happened over a specific period of time.`,
    learningStories: "Learning Stories",

    //Portfolio PDF
    selectProgressReportDateRange:
      "Please select the date range of the progress report.",
    introductionText: `The assessment portfolio combines pictures, anecdotal records, and rubrics to support our child-centered, strength-based approach of learning at Trehaus School. While pictures and anecdotal notes provide lively outlooks of how the child nurtures his/her Hands and Heart, a proficiency rubric reflects a child’s achievements in relation to the standards for the school term in Head knowledge. Through multiple means and varied strategies, the student's learning is made visible, and their successes celebrated.`,
    littleProjectsAccomplished: "Little Projects Accomplished",
    littleLearnersAccomplished: "Little Learners Accomplished",
    photoGallery: "Photo Gallery",
    startedOn: "Started On",
    completedOn: "Completed On",
    observedOn: "Observed On",
    profileShowcased:
      "{profileName} showcased these values during these learning moment below:",
    profileDemonstrates:
      "As a Trehaus child, {profileName} demonstrates competency in this category of our curriculum framework with the most prominent achievements being:",
    concludingRemarks: "Concluding Remarks",
    sneakPeek: "Sneak Peek",
    goals: "Goals",
    thankYouBeingJourney:
      "Thank you for being a part of my journey in {className} {year}!",
    myTeachers: "My Teachers",

    //Create child page
    preferred_name: "Preferred Name",
    full_name: "Full Name",
    dob: "Date of Birth",
    image: "Image",
    submit: "Submit",
    not_logged_in: "You are not logged in!",

    // Class Page -> Group === Class
    group_name: "Class Name",
    create_group: "Create Class",
    addGroup: "Add Class",
    edit_group: "Edit Class",
    group: "Class",
    assignChildrenToGroup: "Assign Child to the Class",
    childrenInGroup: "Children in Class",
    assignTeacherToGroup: "Assign Teacher to the Class",
    teacherInGroup: "Teachers in Class",

    //Enroll Child Page
    child: "Child",
    project: "Project",
    enroll_child: "Enroll a child to a project",
    module: "Module",
    unit: "Unit",

    //Project Page - Edit + Create
    editing: "Editing",
    managing_proj: "Managing Project",
    projectEditor: "Edit Project",
    create_new_proj: "Create a new project",
    create_project: "Create Project",
    create_assessment: "Create a new assessment",
    proj_for_child: "Project for the children",
    sproutsExplainationPartOne: "Generally 18 months to 2 years old, ",
    sproutsExplainationPartTwo:
      "children are starting to pick up new information from the world around them.",
    budsExplainationPartOne: "Generally 3 to 4 years old, ",
    budsExplainationPartTwo:
      "children have some knowledge and are learning new skills to apply this new information.",
    blossomsExplainationPartOne: "Generally 5 to 6 years old, ",
    blossomsExplainationPartTwo:
      "they have knowledge and skills and are actively applying and practising using them.",

    sprouts: "Sprouts",
    sprouts_with_age: "Sprouts (18 months - 2 years)",
    sproutlings_with_age: "Sproutlings (2 years - 3 years)",
    buds_with_age: "Buds (3 years - 4 years)",
    blossoms_with_age: "Blossoms (5 years - 6 years)",
    blooms_with_age: "Blooms (5 years - 6 years)",
    sprouts_with_age_external: "Sprouts (18 months - 2 years)",
    blossoms_with_age_external: "Blossoms (5 years - 6 years)",
    programme: "Programme",
    proj_name: "Project Name",
    level: "Level",
    basic: "Basic",
    intermediate: "Intermediate",
    advanced: "Advanced",
    proj_duration: "Expected Duration (mins)",
    proj_description: "Project Description",
    learning_objectives: "Learning Objectives",
    publish: "Publish",
    saveAsDraft: "Save as draft",
    recall: "Recall",
    recalled: "Recalled",
    success_remove_project: "Removed project successfully!",
    success_remove_unit_guide: "Resource Guide is removed successfully!",
    projectTags: "Project Tags",
    learningObjectives: "Learning Objectives",
    createAndManageModules: "Create and manage modules",
    addObjective: "Add Objective",

    //Lessons
    activity: "Activity",
    activity_name: "Activity Name",
    add_activity: "Add Activity",
    success_remove_activity: "Removed activity successfully!",
    activity_duration_min: "Activity Duration (minutes)",
    attachment_if_any: "Attachments (if any)",
    attachment_if_copy: `Attachments (check the box to add the attachment into "Documents")`,
    add_attachement_into_doc: `Add attachment into 'Documents'`,
    attachments: "Attachments",
    lesson_name: "Lesson Name",
    lesson_description: "Lesson Description",
    lesson_objective: "Lesson Objective",
    lesson_objectives: "Lesson Objectives",
    addLessonObjective: "Add Lesson Objective",
    lesson_intention: "Lesson Intention",
    lessonToolbox: "Lesson Toolbox",
    expected_duration_mins: "Expected Duration (mins)",
    key_vocabulary: "Key Vocabulary",
    material: "Material",
    materials: "Materials",
    materials_required: "Materials Required",
    recommendedSetupInstructionIfAny:
      "Recommended Set-up Instructions (if any)",
    setupInstruction: "Setup Instruction",
    image_set: "Image set",
    lesson_learning_moment: "Lesson Learning Moment",
    project_checkpoints: "Project Checkpoints",
    delete: "Delete",
    edit: "Edit",
    description: "Description",
    required: "Required?",
    lessons_for_children: "Lessons for Children",
    edit_lessons: "Edit Lessons",
    create_lessons: "Create Lessons",
    fail_update_lesson: "Failed to update lesson!",
    fail_create_lesson: "Failed to create lesson!",
    success_create_lesson: "Created lesson successfully!",
    success_update_lesson: "Updated lesson successfully!",
    success_remove_lesson: "Removed lesson successfully!",
    success_reorder_lessons: "Reorder lessons successfully!",
    add_lesson: "Add Lesson",
    submitting: "Submitting...",
    delete_lesson: "Delete Lesson",
    downloadLessonPlan: "Download Lesson Plan",
    generatingLessonPlan: "Generating Lesson Plan",
    thisLesson: "This Lesson",
    multipleLessons: "Multiple Lessons",

    //Activity
    notes: "Notes",
    stickyNote: "Sticky Note",
    activityStepInstruction: "Step {weight} Instruction:",
    activityStep: "Step {weight}",
    editing_activity: "Editing Activity",
    creating_activity: "Creating Activity",
    remark: "Remark",
    remarks: "Remarks",
    compulsory: "Compulsory",
    cancel: "Cancel",
    skip: "Skip",
    create: "Create",
    delete_activity: "Delete Activity",
    success_reorder_activities: "Reorder activities successfully!",
    success_move_activity: "Move activity successfully!",
    question: "Question",

    //Checkpoint
    checkpoint: "Checkpoint",

    //Learning Moment
    isLearningMoment: "Any learning moments?",
    learningMomentPhoto: "Learning Moment Photo",
    learningMomentQuestion: "Learning Moment Question",

    //Objective
    adding_curriculum_fundamental_to: "Adding Curriculum Fundamental to",
    editing_curriculum_fundamental_of: "Editing Curriculum Fundamental of",
    objective_str: "Objective",
    add_objective: "Add Objective",
    delete_fundamental: "Delete Fundamental",
    fundamental_str: "Fundamental",

    //Login,
    forgottenPassword: "Forgotten password?",
    findYourAccount: "Find Your Account",
    enterYourEmail:
      "Please enter your email address to search for your account.",
    pleaseCheckYourEmailAndPassword:
      "Please check whether your email and password is correct.",
    emailSubmittedTitle: "Password Reset Request Submitted",
    emailSubmittedResponse:
      "If the email you provided exists in our system, we've sent a link to reset your password to it. Please check your inbox and spam folders.",

    //Register
    email: "Email",
    mobile_number: "Mobile Number",
    password: "Password",
    already_have_account_login: "Already have an account? Login",
    role: "Role",

    //Reset
    resetYourPassword: "Reset your password",

    //Admin Page
    add: "Add",
    actions: "Actions",
    siteAdmin: "Site Admin",
    contentEditor: "Content Editor",
    ttaContentEditor: "TTA Content Editor",
    schoolAdmin: "School Admin",
    paymentRecord: "Payment Record",

    //Settings Page
    emailPreferences: "Email Preferences",

    //Email Preferences Page
    stayInformed: "Stay Informed",
    toggleOnOrOffEmails: "Toggle on or off to choose which emails you receive.",
    announcementsAndMessages: "Announcements and messages",

    //Questionnaire
    createInterest: "Create interest",
    createPreDQQuestion: "Create pre-discovery questionnaire question",
    pickLanguage: "Pick your language choices",
    selectModules: `Select module(s) you will be teaching`,
    selectDevelopmentalAreas: `Select key developmental areas (Recommended: Top 5)`,
    selectInterests: `Select interest(s) that class {groupName} is most interested in`,
    weight: "Ordering",
    name: "Name",
    priority: "Priority",

    //Quick questions
    welcomeToTTB: "Welcome to our Teaching Tool Box!",
    everyChildIsUnique: "Every Child is Unique",
    introFirstParagraph:
      "The Trehaus Curriculum believes in providing engaging, meaningful and differentiated lessons for every learner. Recognising that every child is unique and develops at their own pace, we have created three learning levels that help teachers to curate lessons to their children's current stage of learning.",
    sproutsDescription:
      "Generally for children aged 18 months to 2 years old, the Sprouts Children are just learning about the world, growing their roots and beginning to take in new knowledge.",
    budsDescription:
      "Generally for children aged 3 to 4 years old, the Buds Children are absorbing information and actively applying it to support their budding skills.",
    blossomsDescription:
      "Generally for children aged 5 to 6 years old, the Blossoms Children are brimming with information and actively developing their new skills.",
    introLastParagraph:
      "Before browsing our lesson plan library, please take a few minutes to answer these Quick Questions so that we can recommend projects according to your child's current stage of growth.",
    myChildInterests: "My children are interested in…",
    myChildInterestsSub: "(choose as many as you like!)",
    myChildGoals: "My children could benefit from…",
    myChildGoalsSub: "(choose which goals you want to work on!)",
    myChildCommunicationMethod: "My children communicate using…",
    myChildCommunicationMethodSub:
      "(regardless of language, choose what your child uses!)",
    concludingTextFirst:
      "Great! Based on your answers… your children are at the {0} level.",
    concludingTextLast:
      "Go to our lesson plan library to see what fun projects we would recommend for you.",
    quickQuestionsCompleted: "Quick Questions Completed!",
    levelAnswer1: "Gestures and Sounds",
    levelAnswer2: "One to Two Words",
    levelAnswer3: "Short Phrases",
    levelAnswer4: "Simple Sentences",
    levelAnswer5: "Detailed Sentences",
    loading: "Loading",
    intro: "Intro",

    //Access Control
    school: "School",
    schools: "Schools",
    search: "Search",
    bulkAction: "Bulk Action",
    editProject: "Edit Project",

    //Manage project page
    startDate: "Start Date",
    endDate: "End Date",

    //Ticketing
    subject: "Subject",
    itSupportFeedback: "IT Support - Feedback",
    feedback: "Feedback",
    next: "Next",

    //Payments
    payment: "Payment",
    subscription: "Subscription",
    subscriptions: "Subscriptions",
    paymentHistory: "Payment History",
    noSubscribe: "No product is subscribed yet!",
    noProduct: "Stay tuned for this product!",
    paymentTitleSchool: "Choose the right plan for your school",
    paymentTitleParent: "Choose the right plan for your family",
    selectPlan: "Select plan",
    startFreeTrial: "Start free 7-days trial",
    sevenDayTrial: "7-days trial",
    manageOrUpdatePlan: "Manage or Update Plan",
    paymentTitle: "Choose the right plan",
    subscribedProduct: "Subscribed product",
    onlySchoolAdmin:
      "Please login as a school admin to view the subscription plan!",
    subscribed: "Subscribed",
    noRenew: "No Re-new",
    willRenew: "Re-new",
    noProject: "No project found",
    pleaseSubscribe:
      "Please subscribe to our plan or contact the admins for more information",
    addPaymentRecord: "Add Payment Record",
    canViewProjects: `Can View Projects`,
    createPaymentRecord: "Create Payment Record",
    customerId: "Customer Id",
    bypassPayment: "Bypass Payment",
    demoSchool: "Demo School",
    subscribeToViewProject: "Subscribe to view project",
    oopsDemoAccount:
      "Oops! This is a demo account. Sign up on LittleLab.com for the full experience!",

    //Calendar
    calendarViewWeek: "Week",
    calendarViewMonth: "Month",
    calendarViewYear: "Year",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",

    //Timetable
    timetable: "Timetable",
    today: "Today",
    thisWeek: "This Week",
    editTimetable: "Edit Timetable",
    addNewEvent: "Add New Event",
    editEvent: "Edit Event",
    discardEdits: "Discard Edits",
    saveChanges: "Save Changes",
    noChanges: "No Changes",
    timetableUpdated: "Timetable Updated!",
    eventTitle: "Event Title",
    eventType: "Type of Event",
    dayOfWeek: "Day of Week",
    startingTime: "Starting Time",
    endingTime: "Ending Time",
    timetableTitle: "{name}'s Timetable",
    shareTimetable: "Share Timetable?",
    copyShareLink: "Copy Share Link",
    shareLinkCopied: "Share Link Copied!",
    timetableShared: "Anyone with the link can view your timetable.",
    timetableUnshared: "Only you can view your timetable.",
    defaultTimetableButtonTitle:
      "Make your class plan with our Visual Timetable!",
    uploadedTimetableButtonTitle: "Upload your own plan for your reference!",
    uploadNewTimetable: "Upload new timetable",
    removeTimetable: "Remove timetable",
    noUploadedTimetable: "No uploaded timetable",
    uploadYourTimetable: "Upload an image of your own timetable",
    uploading: "Uploading",

    successAddEvent: "New event added successfully!",
    failAddEvent: "Failed to add new event. Please try again later!",
    successUpdateEvent: "Event updated successfully!",
    failUpdateEvent: "Failed to update event. Please try again later!",
    successDeleteEvent: "Event deleted successfully!",
    failDeleteEvent: "Event cannot be deleted. Please try again later!",
    successDuplicateEvent: "Event duplicated successfully!",
    failDuplicateEvent: "Event cannot be duplicated. Please try again later!",
    successUploadTimetable: "Timetable uploaded successfully!",
    failUploadTimetable: "Failed to upload timetable. Please try again later!",
    successUpdateTimetable: "Timetable updated successfully!",
    failUpdateTimetable: "Failed to update timetable. Please try again later!",
    successDeleteTimetable: "Timetable deleted successfully!",
    failDeleteTimetable: "Timetable cannot be deleted. Please try again later!",
    noEventToday: "No event today",

    //Chat
    messages: "Messages",
    newChat: "New Chat",
    newClassChat: "New Class Chat",
    selectContact: "Select Contact",
    startANewChat: "Start a new chat",
    startANewClassChat: "Start a new class chat",
    typeYourMsgHere: "Type your message here...",
    noNewMessage: "No new message",

    //Developmental Goal
    manageDevelopmentalGoals: "Manage Developmental Goals",
    developmentalGoal: "Developmental Goal",
    subDevelopmentalGoal: "Sub Developmental Goal",

    addDevelopmentalGoal: "Add {tab} Developmental Goal",
    editingDevelopmentalGoal: "Editing Developmental Goal",
    deleteDevelopmentalGoal: "Delete Developmental Goal",
    createdDevelopmentalGoal: "Created Developmental Goal successfully!",
    updatedDevelopmentalGoal: "Updated Developmental Goal successfully!",
    removedDevelopmentalGoal: "Removed Developmental Goal successfully!",

    addSubDevelopmentalGoal: "Add {tab} Sub Developmental Goal",
    editingSubDevelopmentalGoal: "Editing Sub Developmental Goal",
    deleteSubDevelopmentalGoal: "Delete Sub Developmental Goal",
    createdSubDevelopmentalGoal: "Created Sub Developmental Goal successfully!",
    updatedSubDevelopmentalGoal: "Updated Sub Developmental Goal successfully!",
    removedSubDevelopmentalGoal: "Removed Sub Developmental Goal successfully!",

    stillFindingOut: "We're still finding out!",
    stillFindingOutHeadLibrary:
      "No learning moments recorded here... Head to the Library to start a project and create learning moments!",
    noObservations: "No observations recorded",
    noCaption: "No caption",
    title: "Title",
    subtitle: "Subtitle",
    milestone: "Milestone",
    successReorderMilestone: "Milestone is re-ordered successfully!",
    addMilestone: "Add Milestone",
    deleteMilestone: "Delete Milestone",
    milestoneNo: "Milestone {number}",
    photoInstruction: "Photo Instruction",
    photoCaption: "Photo Caption",
    sixMonthNoObservation:
      "It's been a while since your last observation... let's do another learning moment to see some progress!",

    //Prohibited Word
    prohibitedWords: "Prohibited Words",
    manageProhibitedWords: "Manage Prohibited Words",
    addProhibitedWords: "Add Prohibited Words",

    //Completed Work
    addCompletedWork: "Add Little Work",
    uploadChildrenCompletedWork: "Upload Children's Completed Works",

    //TTA

    instructions: "Instructions",
    draft: "Draft",

    resources: "Resources",
    myProgress: "My Progress",
    ourStory: "Our Story",
    community: "Community",

    manageModule: "Manage Module",
    manageCertificates: "Manage Certificates",
    profileCertificate: "{profileName}'s {certificateName}",
    profileCertificateSubtitle:
      "The certificate below verifies that {name} has completed all of the modules in Trehaus Teacher Academy on {date}.",
    mastered: "{name} has mastered how to:",
    modules: "Modules",
    moduleNumber: "Module {number}",
    moduleName: "Module Name",
    moduleDescription: "Module Description",
    createModule: "Create Module",
    editingModule: "Editing Module",
    successDeleteModule: "Module is deleted successfully!",
    noModuleFound: "No module is found!",
    modulelCompleted: "Module Completed",
    whatDidYouFeelModule: "What did you feel about the module?",
    whatDoYouLikeModule: "What do you like about the module?",

    //TTA Certificate
    noCertificateFound: "No certificate is found!",
    addCertificate: "Add Certificate",
    createCertificate: "Create Certificate",
    editCertificate: "Edit Certificate",
    assignModuleToCertificate: "Assign Module to Certificate",
    moduleToBeAssigned: "Module to be assigned",
    moduleRequiredByCertificate: "Module required by certificate",
    congrazObtainCertz:
      "Congratulation! You have obtain {certificateName}! Check it out in your profile!",

    successDeleteCertificate: "Certificate is deleted successfully!",
    successCreateCertificate: "Certificate is created successfully!",
    successUpdateCertificate: "Certificate is updated successfully!",

    searchForProfile: "Search for profile",
    acquisitionDate: "Acquisition Date",

    issuingOrganization: "Issuing organization",
    credentialId: "Credential ID",
    credentialURL: "Credential URL",
    showCredential: "Show Credential",

    currentlyLearning: "Currently Learning",

    noteChangesSaved: "Note changes saved!",

    createPost: "Create a post",
    postCreated: "Post is created!",
    postDeleted: "Post is deleted!",
    deletePostQuestionMark: "Delete the post?",
    successUpdatePost: "Post is updated successfully!",
    failUpdatePost: "Post cannot be updated, something went wrong!",

    commentCreated: "Comment is created!",
    deleteCommentQuestionMark: "Delete the comment?",
    successUpdateComment: "Comment is updated successfully!",
    failUpdateComment: "Post cannot be updated, something went wrong!",

    completed: "Completed",

    readingImages: "Reading Images",
    deletedImage: "The image is deleted successfully!",

    textActivityComponent: "Text Activity Component",
    textActivityComponents: "Text Activity Components",
    textActivityComponentTitle: "Text Activity Component Title",

    addTextActivityComponent: "Add Component",
    textActivityComponentDeleted: "Text Activity Component is deleted!",
    textActivityComponentNo: "Activity Component {number}",

    discussion: "Discussion",
    lessonDiscussionTopic: "Lesson Discussion Topic",
    lessonDescription: "Lesson Description",
    activityType: "Activity Type",
    activityDescription: "Activity Description",
    failUpdateActivity: "Activity update failed!",

    videoThumbnail: "Video Thumbnail",
    videoTranscript: "Video Transcript",
    video: "Video",

    addFlipCard: "Add Flash Card",
    flipCards: "Flash Cards",
    flipCardNo: "Flash Card {number}",
    flipCardFrontText: "Flash Card Front Text",
    flipCardBackText: "Flash Card Back Text",
    flipCardRemoved: "Flash Card is deleted!",

    dragAndDrop: "Drag and Drop",
    dragAndDropDescription: "Drag and Drop Description",
    addAnswerPair: "Add Answer Pair",
    dragAndDropAnswerPairs: "Drag and Drop Answer Pairs",
    answerPairNo: "Answer Pair {number}",
    answerPairRemoved: "Answer Pair is deleted!",

    quiz: "Quiz",
    mcq: "Multiple-choice questions",
    mcqAnswered: "{answerCount}/{totalCount}",
    mcqAnsweredAllCorrect:
      "Well done! You have answered all the questions correctly!",
    mcqPleaseRetry:
      "Sorry, you answered {wrongAnswerCount} question/s wrongly. Please try again!",

    score: "Score",
    keepTrying: "Take a deep breath, keep trying!",
    keepGoing: "You're almost there, keep going!",
    super: "Super! Onward and upward!",
    great: "Great!",
    okay: "Okay",
    retry: "Retry",
    letsKeepStreakGoing: "Let's keep this streak going!",
    letsTryAgain:
      "Looks like your answers were not correct. Don't worry! Let's try again till we get it right.",
    reviewCreated: "Your review is recorded. Thanks for your review!",

    mcqQuestion: "Question",
    mcqQuestions: "MCQ Questions",
    addMcqQuestion: "Add Question",
    mcqQuestionNo: "Question {number}",
    mcqQuestionRemoved: "Question is deleted!",

    mcqOption: "MCQ Option",
    mcqOptions: "MCQ Options",
    addMcqOption: "Add Option",
    mcqOptionNo: "Option {number}",
    mcqOptionRemoved: "Option is deleted!",

    answerSuccessfullySubmit: "The answer is submitted successfully!",
    answerFailedSubmit: "Fail to submit the answer. Please try again later!",
    pleaseAnswerAll: "Please answer all questions before submitting!",

    prompt: "Prompt",
    addPrompt: "Add Prompt",
    answer: "Answer",
    interactiveDataEntry: "Interactive Data Entry",
    interactiveDataEntryPrompt: "Interactive Data Entry Prompt",
    interactiveDataEntryPromptDescription: "Prompt Description",
    interactiveDataEntryPromptNo: "Prompt {number}",
    interactiveDataEntryPromptRemoved: "Prompt is deleted!",
    // interactiveDataEntryPrompts: "Interactive Data Entry Prompt",

    pictorialDescription: "Picture description",

    pleaseIndicateAnswer:
      "Answers: Please indicate correct answers with a check",

    memoryCards: "Memory Cards",

    startLearning: "Start learning",

    //KANBAN BOARD
    kanbanBoardTopicCreated: "Topic is created!",
    kanbanBoardTopicUpdated: "Topic is updated!",
    kanbanBoardTopicDeleted: "Topic is deleted!",
    kanbanBoardCommentCreated: "Comment is created!",
    kanbanBoardCommentUpdated: "Comment is updated!",
    kanbanBoardCommentDeleted: "Comment is deleted!",

    giveATitle: "Give a title!",
    addAnAwesomeDescription: "Add an awesome description!",
    whatIsYourThought: "What is your thought?",

    // Teacher Portfolio
    byClass: "By Class",
    byYear: "By Year",
    byProject: "By Project",

    //wip
    underConstruction:
      "Oops! This page is still under construction. Stay tuned!",

    // Parent dashboard
    nowLearning: "Now learning",

    //Other Components
    updateBackpack: "Update backpack",
    updateFavorite: "Update Favorite",
    templateFor: "Template for",
    activitiesToBeConducted: "activities to be conducted",
    loadingDots: "Loading...",
    youNeedToEnrollYourGroup: " You need to enroll your group to access",
    selectDropAttachments: "Select or drop attachments",
    selectDropActivity: "Select or drop activity thumbnail",
    selectDropLesson: "Select or drop Lesson thumbnail",
    translateThisLessonTo: "Translate this lesson to:",
  },

  //MANDARIN
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  zh: {
    //General
    updated: "更新",
    story:"故事",

    //LandingPage
    whereKidsLoveLearning: "孩子热爱学习的地方",
    findOutMore: "了解更多",
    takeAPeek: "偷偷瞧一眼我们的小小项目，致力于培养改变世界的小改变者",
    getAccessToLittleLab: "获取进入小实验室的权限",
    everyChildIsDifferent: "每个孩子都是独一无二的！",
    comeAndExperienceIndividualized: "来体验为您的孩子量身定制的个性化学习",
    your: "你的",
    childSmall: "孩子",
    createAUniqueLearningExperience: "打造独特的学习体验",
    greatContentCreatedBy:
      "由早期教育领域的领先专家基于最新研究和教学方法创建的优质内容",
    learnMore: "了解更多",
    superEasyAndIntuitive: "对于家长和老师来说，使用起来非常简单和直观。",
    perfectEvenFor: "甚至适用于",
    homeSchoolers: "家庭学生",
    andSmall: "和",
    learningCommunities: "学习社区",
    discoverTheFutureOfEducation: "发现教育的未来",
    ourProprietaryTrehaus: "我们独有的Trehaus小实验室课程包括6种未来和软技能",
    ourLittleLearners: "我们的小学习者",
    enrichYourChild:
      "通过这些完全结构化和自主学习的课程，丰富您孩子的知识，旨在让他们参与有意义和深思熟虑的文学和数学活动。",
    hearWhatOtherParents: "听听其他父母和教育工作者的看法",
    weSimplyLove:
      "我们简直热爱Trehaus所倡导的以价值为导向的教育方法。这不仅仅是一所学前学校，更是一个基于惊人价值观的强大社区。我强烈推荐Trehaus。",
    confidence: "自信",
    empathy: "同理心",
    creativity: "创造力",
    perseveranceGrit: "毅力与坚韧",
    communicationSkills: "沟通技巧",
    selfRegulation: "自我调节",
    integrity: "正直",
    executiveFunction: "执行力",
    steam: "STEAM",
    englishLanguageLiteracy: "英语语言和文学",
    mandarinLanguageLiteracy: "中文语言和文学",
    numeracyMathConcepts: "数学和数学概念",
    grossMotorSkills: "大肌肉运动技能",
    fineMotorSkills: "小肌肉运动技能",
    gratitudeGivingBack: "感恩与回馈",
    environmentalResponsibility: "环境责任",
    socialAwareness: "社会意识",
    publicSpeakingAdvocacy: "演讲和倡导",
    interdependence: "相互依赖",
    practicalLifeSkills: "实用生活技能",
    globalPerspectives: "全球视野",
    littleCreative: "小创意家",
    littleCeo: "小首席执行官",
    littleChef: "小厨师",
    littlePhilanthropist: "小慈善家",
    littleEngineer: "小工程师",
    littleEntrepreneur: "小企业家",
    theLearningExperiencesDesigned:
      "本课程设计的学习体验与您孩子的日常生活相关，为他们提供了一个有意义的背景，以发展中文语言和文学的知识和技能。",
    littleJournalist: "小记者",
    exposeYourChildToKnowledge:
      "以有趣和引人入胜的方式让您的孩子接触英语语言和文学的知识。小记者提供独特的多感官学习体验，整合了文学的各个方面。",
    littleMathematician: "小数学家",
    littleMathematicianProvidesAnAuthentic:
      "小数学家提供真实、有趣且动手的学习体验，支持您的孩子逐步建立坚实的数学和逻辑思维基础，培养和磨练他们数学思维和解决问题的能力。",
    trehausSchoolMomHelle: "Trehaus学校妈妈，Helle",
    lauraIsAlwaysExcited:
      "Laura总是对上课感到兴奋！感谢您鼓励她独立，灌输重要的生活价值观，并使学习变得非常有趣。在工作时我们心无旁骛，因为有您的爱和关心。",
    trehausSchoolMomRaine: "Trehaus学校妈妈，Raine",
    jaydenIsReallyLucky:
      "Jayden很幸运能有温柔关爱的老师，他们总是满溢着对孩子们的爱和关心。Jayden很喜欢他们！把Jayden送到Trehaus学校并在Trehaus工作是我们做过的最好的决定！",
    trehausSchoolMomOlivia: "Trehaus学校妈妈，Olivia",
    myBiggestSatisfactionIsSeeing:
      "我最大的满足感是看到我的儿子Nate在Trehaus茁壮成长，他非常喜欢学校，而我则喜欢能够在几步之外的商务俱乐部工作，并参与他生命中宝贵的最初几年。",
    trehausSchoolMomElaine: "Trehaus学校妈妈，Elaine",
    incrediblyDedicatedAndNurturing:
      "Trehaus学校的教职工和校长非常敬业和关爱。我对他们的发展报告以及在表达我们女儿在学校的进步方面所付出的努力感到非常印象深刻。",
    trehausSchoolDadStephen: "Trehaus学校爸爸，Stephen",
    everyPersonInTheSchool:
      "学校的每个人都真心关爱孩子，把每个孩子都当成自己的孩子，所以我相信我的儿子每天在工作时都在得到良好的照顾。",
    trehausSchoolDadRodney: "Trehaus学校爸爸，Rodney",

    //Dashboard
    totalChildren: "学生总数",
    totalTeachers: "教师总数",
    totalClasses: "班级总数",
    currentlyOn: "当前在线",
    proTips: "专业提示: 输入 [] 添加待办事项",
    typeYourNoteHere: "在这里输入您的备注",
    message: "消息",
    timeTable: "时间表",
    jan: "一月",
    feb: "二月",
    mar: "三月",
    apr: "四月",
    jun: "六月",
    jul: "七月",
    aug: "八月",
    sep: "九月",
    oct: "十月",
    nov: "十一月",
    dec: "十二月",
    teachers: "教师",
    teacher: "教师",
    children: "学生",
    total: "总计",
    viewClass: "查看班级",

    //Homepage
    welcome: "欢迎回来，",
    dashboard: "我的仪表板",
    whoLearning: "今天谁将学习？",
    viewGroup: "查看班级",
    updateProfile: "更新个人资料",
    broadcastMsg: "广播消息",
    allGroups: "所有班级",
    noOngoingLesson: "没有进行中的课程",
    noLesson: "没有课程",
    students: "学生",
    formTeacher: "班主任",
    todayLessons: "今天的课程",
    tmrLessons: "即将到来的课程",
    lessonCompleted: "课程完成",
    lessonsCompleted: "课程完成",
    lessonConducted: "进行的课程",
    lessonsConducted: "进行的课程",
    resume: "继续",
    resumeLesson: "继续课程",
    goToLesson: "前往课程",
    newEnrollments: "新注册",
    lesson_number: "第 {number} 课",
    myClasses: "我的班级",
    classDetail: "班级详情",
    attendance: "出勤情况",
    currentLessons: "进行中的课程",
    view: "查看",
    myChildren: "我的孩子",
    announcements: "公告",
    noAnnouncementYet: "暂无公告",
    noActionRequiredYet: "目前无需操作",
    viewAllAnnouncements: "查看所有公告",
    showActiveAnnouncementsOnly: "仅显示活动公告",
    littleAlbum: "小相册",
    mostRecentPhotos: "最近的照片",
    quickItems: "快捷事项",
    addTodo: "添加待办事项",
    notesUpdatedSuccess: "笔记更新成功！",
    notesUpdatedFailed: "笔记更新失败！",
    addTodoInstruction: "专业提示: 输入 [] 添加待办事项！",
    typeYourNotesHere: "在这里输入您的备注...",

    // MyChildrenPage
    viewPortfolio: "查看作品集",
    bornOn: "出生日期",
    parent: "家长",

    // My Classes
    classList: "班级列表",
    editClass: "编辑班级",

    // Edit Class
    update: "更新",

    // AnnouncementsPage
    createAnnouncement: "创建公告",
    addNewAnnouncement: "添加新公告",
    editAnnouncement: "编辑公告",
    deleteAnnouncement: "删除公告",
    announcementCreatedSuccess: "公告创建成功！",
    announcementCreatedFailed: "无法创建公告！",
    announcementUpdatedSuccess: "公告更新成功！",
    announcementUpdatedFailed: "无法更新公告！",
    announcementRecalledSuccess: "公告成功召回！",
    announcementRecalledFail: "无法召回公告！",
    announcementRecalled: "此公告已被召回。",
    announcementDeletedSuccess: "公告删除成功！",
    announcementDeletedFailed: "无法删除公告！",
    announcementStartAt: "公告开始于",
    announcementEndAt: "公告结束于",
    includesAttachment: "包含附件",
    pinAnnouncement: "固定公告？",
    anyResponseRequired: "需要任何回复吗？",
    responseRequired: "需要回复",
    responseQuestion: "回复问题",
    responseType: "回复类型",
    respondBy: "截止回复日期",
    allowTextResponse: "允许文本回复？",
    textResponse: "文本回复",
    textResponseInstruction: "文本回复说明",
    allowAttachmentUpload: "允许上传附件？",
    attachmentInstruction: "附件说明",
    responseOption: "回复选项",
    addResponseOption: "添加回复选项",
    responseUpdated: "回复已更新！",
    responseUpdateFail: "回复更新失败！请稍后再试",
    uploadDocument: "上传文件",
    acknowledge: "确认",

    // Documents Page
    createDocument: "创建文档",
    addNewDocument: "添加新文档",
    editDocument: "编辑文档",
    deleteDocument: "删除文档",
    document: "文档",
    documents: "文档",
    noDocumentYet: "暂无文档",
    pleaseUploadAtLeastOneDocument: "请至少上传1个文档！",

    // BrowsePage
    noChild: "您尚未注册任何孩子",
    noGroup: "您尚未注册任何班级",
    noGroupAssigned: "您尚未分配班级！请与学校管理员联系以获取进一步的解释",
    browse: "浏览所有项目",
    noProjectFound: "未找到项目",
    searchForProjects: "搜索项目",
    recommendations: "推荐",
    displayAll: "显示所有项目",
    specially: "特别为您",
    specialDesc: "我们最佳的个性化学习体验",
    trending: "热门项目",
    trendDesc: "查看The Little Lab上的热门项目",
    new: "新品上市！",
    newDesc: "浏览我们专家团队最新的项目",
    byModule: "按模块",
    byLevels: "按级别",
    addToBackpack: "添加到背包",
    removeFromBackpack: "移除",
    addedToBackpack: "已添加到背包！",
    viewProjectDetails: "查看项目详情",
    viewAssessmentDetails: "查看评估详情",
    addOrScheduleItToView: "添加/安排以查看！",

    // NavigationBar
    home: "首页",
    lessonLibrary: "课程",
    library: "教案库",
    assessments: "评估",
    backpack: "背包与收藏夹",
    visualTimetable: "视觉时间表",
    scheduler: "日程安排",
    littleEvaluator: "小小评估员",
    calendar: "日历",
    myClass: "我的班级",
    portfolios: "作品集",
    childrenPortfolio: "儿童作品集",
    teacherPortfolio: "教师档案",
    notices: "通知",

    // ProjectDetailPage
    lesson: "课程",
    lessons: "课程",
    saved: "已保存",
    notSaved: "未保存",
    overview: "概览",
    unitGuide: "资源指南",
    plan: "教案",
    assessmentPlans: "评估计划",
    teachingMaterials: "教学材料",
    communitySharing: "社区分享",
    whatPeopleLike: "人们喜欢什么",
    tags: "标签",

    // Teaching Material
    getTeachingMaterials: "获取教材",
    downloadMaterials: "下载材料",

    // ProjectMoment
    uploadPhoto: "上传照片",
    uploadPhotos: "上传照片",
    uploadVideos: "上传视频",
    uploadMedia: "上传媒体",
    caption: "标题",
    typeCaption: "输入标题",
    addCaption: "添加标题",
    upload: "上传",
    uploadFinalizing: "正在处理...请勿刷新...",
    uploadPhotoFinalizing: "正在处理照片...请勿刷新...",
    uploadVideoFinalizing: "正在处理视频...请勿刷新...",
    noActivity: "无活动",
    noPhoto: "尚无照片",
    failed_load_gallery: "加载相册失败！",
    failed_upload: "上传失败！",
    failed_add_photos: "添加照片失败！",
    failed_add_videos: "添加视频失败！",
    failed_add_photos_videos: "添加照片或视频失败！",
    success_upload: "上传成功！",
    success_upload_photos: "照片上传成功！",
    success_upload_videos: "视频上传成功！",
    success_upload_photos_videos: "照片和视频上传成功！",
    success_update_image: "更新成功！",
    success_delete_image: "照片已成功删除！",
    success_delete_video: "视频已成功删除！",
    success_delete_photo_video: "照片和视频已成功删除！",
    fail_delete_photo_video: "无法删除照片和视频！",
    delete_photo: "删除 {number} 张选定照片",
    delete_video: "删除 {number} 个选定视频",
    delete_photo_video: "删除 {number} 张/个选定照片/视频",
    selected: "已选",
    allPhotos: "所有照片",
    allVideos: "所有视频",
    allPhotosVideos: "所有照片和视频",
    irreversibleAction: "此操作不可逆！",
    tagChildren: "标记儿童",
    profiles: "个人资料",
    edit_image: "编辑图片",
    edit_video: "编辑视频",

    // Edit Image
    flipHoriz: "水平翻转",
    flipVerti: "垂直翻转",
    rotateAntiClockwise: "逆时针旋转",
    rotateClockwise: "顺时针旋转",

    // LessonDetailPage
    begin: "让我们开始！",
    scheduleProject: "安排项目",
    day: "天",
    days: "天",
    objective: "学习目标",
    fundamental: "发展目标",
    noFundamental: "没有发展目标",
    guidingQuestions: "关注问题",
    ratings: "评级",
    noRating: "尚无评级",
    lessonNo: "课程 {number}",
    assessmentPlanNo: "评估计划 {number}",
    min: "分钟",
    conduct: "将要进行 {number} 项活动",
    Sproutlings: "嫩芽",
    Sprouts: "芽苗",
    Buds: "花苞",
    Blossoms: "花朵",

    // Account page
    male: "男性",
    female: "女性",
    gender: "性别",
    highestEducationQualification: "最高学历",
    educationStartDate: "教育开始日期",
    noFormalRecordYet: "尚无正式记录",
    highSchool: "高中",
    associateDegree: "副学士学位",
    bachelorsDegree: "学士学位",
    mastersDegree: "硕士学位",
    doctorateDegree: "博士学位",

    //Settings Page
    emailPreferences: "邮件偏好设置",

    //Email Preferences Page
    stayInformed: "保持了解",
    toggleOnOrOffEmails: "切换打开或关闭以选择您要接收的电子邮件。",
    announcementsAndMessages: "公告和消息",

    // profileSidebar
    profileSectionAbout: "关于",
    profileSectionExperience: "经验",
    profileSectionReflections: "反思",
    profileSectionCertifications: "认证",
    profileSectionTestimonials: "推荐",
    profileSectionProjects: "过去的项目",

    // profileIconMenu
    account: "账户",
    settings: "设置",
    profile: "个人资料",
    schoolProfile: "学校资料",
    adminPage: "管理员页面",
    createNewClass: "创建新班级",
    addNewChild: "添加新孩子",
    manageProjects: "管理项目",
    helpCenter: "帮助和支持",
    registerSiteAdmin: "注册站点管理员",
    registerSchoolAdmin: "注册学校管理员",
    registerContentEditor: "注册内容编辑员",
    registerTTAContentEditor: "注册TTA内容编辑员",
    registerTeacher: "注册教师",
    registerParent: "注册家长",
    registerChild: "注册孩子",
    enroll: "注册孩子",
    provideFeedback: "提供反馈",
    viewFeedback: "查看反馈",
    logout: "注销",
    switchToTTA: "切换到教师培训学院",
    switchToTTB: "切换到教学工具箱",

    // Library
    backToLibrary: "返回图书馆",
    speciallySelectedForYourChildren: "专为您的孩子精选",
    browseL: "浏览",

    // Schedule Modal
    scheduleModalTagline:
      "安排您的项目何时发生！{0}您可以在我们的“调度程序”功能下的课程日历中查看此信息。",
    projectStartDate: "项目开始日期",
    projectEndDate: "项目结束日期",

    // School Profile Page
    addBackgroundPhoto: "添加背景照片",
    removeBackgroundPhoto: "删除背景照片",
    updateBackgroundPhoto: "更新背景照片",
    addDescription: "包括一个简短的写作！",
    deleteSuccess: "成功删除！",
    deleteFailed: "删除失败！",
    updateSuccess: "成功更新！",
    updateFailed: "更新失败！",
    createSuccess: "创建成功！",
    createFailed: "创建失败！",
    removePhoto: "删除照片",

    // ActivityPage
    back: "返回",
    pResources: "项目资源",
    activityNo: "活动 {number}",
    optionNo: "选项 {number}",
    lessonOverview: "课程概述",
    checkpoints: "摘要和检查点",
    previousLesson: "上一课",
    nextLesson: "下一课",
    activities: "活动",
    startActivity: "开始活动",
    reqConduct: "需要进行的活动",
    startLesson: "开始课程",
    noVocabulary: "没有词汇",
    noMaterial: "没有材料",
    noInstruction: "没有说明",
    deletedInstructions: "步骤已成功删除",
    pleaseSchedule: "请在标记活动完成之前安排此项目！",
    pleaseUploadPhotoVideo: "请在标记活动完成之前上传至少1张照片或1个视频！",
    completeAll: "完成所有活动！",
    completePartial: "完成 {number} 项，共 {total} 项活动！",
    pickToComplete: "选择 1 项活动以完成！",

    // ActivityDetailPage(DrawingPad)
    stepByStepInstructions: "分步说明",
    addStep: "添加步骤",
    done: "完成",
    activityCompleted: "活动完成",
    notDone: "未完成",
    checkpointIcon: "检查点：",
    noCheckpoints: "没有检查点",
    addCheckpoint: "添加检查点",
    noLearningMoments: "没有学习时刻",
    learningMoment: "学习时刻",
    evaluationMoment: "评估时刻",
    learningMoments: "学习时刻",
    evaluationMoments: "评估时刻",
    addLearningMoment: "添加学习时刻",
    learningMomentRecorded: "学习时刻记录",
    milestoneCheckIncomplete: "你快到了，点击完成你的学习时刻！",
    photoIncomplete: "你快到了，上传照片以完成你的学习时刻！",
    previous: "上一页",
    summary: "课程总结",
    selectAll: "全选",
    deselectAll: "取消全选",
    milestoneCheck: "里程碑检查",
    milestoneCheckDescription:
      "使用此检查表查看您的孩子是否已达到这些预期的里程碑。随时在课程之间或单元结束时执行此操作。",
    milestoneCheckWithCheckpoint: "里程碑检查（检查点）",
    pleaseNavigateToLastPageToClickDone: `请导航到最后一页以访问并点击"完成"按钮。`,

    // Review
    noReview: "暂无评论",
    noContent: "暂无内容",
    writeAReview: "写一篇评论",
    shareReview: "您喜欢这个项目的什么地方，和/或对您有什么好处？",
    feedbackToDevTeam: "您有任何反馈或建议，以帮助我们改进这个项目吗？",
    thankyouFeedback: "感谢您的反馈！",
    viewReviews: "查看所有评论",
    thanksReviews: "感谢您的评论！",
    reviewPosted: "评论已发布！",
    reviewUpdated: "评论已更新！",
    reviewDeleted: "评论已删除！",
    tryAgainLater: "请稍后再试",
    deleteReview: "删除评论",
    replyTo: "回复 {name}...",
    writeAComment: "写评论...",
    deleteComment: "删除评论？",
    commentDeleted: "评论已删除！",
    edited: "已编辑",
    like: "点赞",
    liked: "已点赞！",
    unLiked: "取消点赞！",
    reply: "回复",
    comment: "评论",
    addPhotos: "添加照片",
    mostRecent: "最新",
    mostLiked: "最受欢迎",

    projectSummary: "项目摘要",
    projectSummaries: "项目摘要",
    projectSummaryReport: "项目摘要报告",
    backProject: "返回项目",
    congrats: "恭喜！",
    completedProject: "您已完成该项目。",
    photoAlbum: "照片集",
    rate: "评价您的体验",
    howProjectShareExperience: "项目如何？分享您的经验！",
    beforeDownload: "在下载之前",
    beforeGo: "在离开之前",
    recoProj: "推荐项目",
    completedCP: "已完成的检查点：",
    downloadReport: "下载报告",
    openReport: "打开报告",
    generatingReport: "正在生成报告",
    viewMore: "查看更多",
    viewAll: "查看全部",
    reGenerateReport: "现在重新生成您的报告",
    getReport: "立即下载您的定制报告",
    photos: "照片",
    videos: "视频",
    totalNumberLessons: "总课节数",

    // Project Report PDF
    projectOverview: "项目概览",
    presentedBy: "自豪地呈现者：",
    congratsText: "成功完成该项目",
    changeTemplate: "更改模板",
    pickImage: "选择图片",
    pickTemplate: "选择模板",

    // Backpack Page
    noenrolledChild: "您尚未注册任何孩子",
    noBackpack: "没有背包物品",
    favourites: "收藏夹",
    addFavourites: "添加到收藏夹",
    noFavourites: "这里还没有项目。去我们的图书馆找到您喜欢的项目吧！",

    // Analytic
    sevenDays: "最近7天",
    thirtyDays: "最近30天",
    oneYear: "1年",
    allTime: "所有时间",

    //portfoliopage
    age: "年龄",
    year: "年",
    years: "年",
    month: "月",
    months: "月",
    appraisal: "{name}的综合教师评估",
    noAppraisal: "无评估",
    presentGoals: "{name}的当前目标",
    goalHistory: "查看目标历史",
    noGoals: "无目标",
    headProgress: "{name}的{tab}进展",
    progressHistory: "查看进展历史",
    latestAccom: "最新成就",
    accomHistory: "查看成就历史",
    improve: "改进领域",
    projectDone: "{name}完成的项目",
    allDetails: "查看所有详情",
    hideDetails: "隐藏所有详情",
    pastProject: "查看所有过去的项目",
    recoAct: "{name}改进的推荐活动",
    noCompletedProject: "无已完成项目",
    downloadpdf: "下载PDF报告",
    showLesson: "显示课程",
    hideLesson: "隐藏课程",
    portfolio: "作品集",
    childPortfolio: "{name}的作品集",
    teacherNameArchive: "{name}的存档",
    developmentalGoals: "发展目标",
    progressReport: "进展报告",
    completedWorks: "小作品",
    createNew: "创建新的",
    selectDuration: "选择时长",
    names: "{name}的",
    learningJourney: "学习历程",
    classInfo: "班级信息",
    noChildAssigned: "未分配到班级的学生",
    noTeacherAssigned: "未分配到班级的教师",
    observation: "观察",
    addObservation: "添加观察",
    milestoneCompleted: "里程碑已完成",
    nextMilestone: "下一个里程碑",
    whatsNext: "接下来是什么？",
    takeAction: "采取行动！",
    noReflections: "暂无反思，为什么不写一篇呢？",
    noTestimonials: "暂无推荐，为什么不请同行为您写一篇呢？",
    noTestimonialsVisitor: "暂无推荐，为什么不写一篇呢？",
    noCertifications: "暂无认证，前往教师培训学院获得认证！",
    visible: "可见",
    hidden: "隐藏",
    attained: "已达成",
    notAttained: "未达成",
    published: "已发布",
    unPublished: "未发布",
    dateObserved: "观察日期",
    deleteObservation: "删除观察",
    reachHighestMilestone: "恭喜！您已达到此发展目标的最高里程碑。",
    findNewProject: "在我们的图书馆中找到一个新项目！",
    parentView: "家长视图",
    whatIsParentView: "什么是家长视图",
    nextMilestoneIsHidden: "下一个里程碑已隐藏",
    blankSubDevGoalAreHidden: "空的子发展目标已隐藏",
    albums: "相册",
    addNewAlbum: "添加新相册",
    noAttainedMilestone: "无已达成的里程碑！",
    congratulation: "祝贺！",
    milestoneCompletedCongratulation: "万岁！您已经实现了您的里程碑！",
    showDevGoalWithRecordedLearningMoment:
      "打开'家长视图'，只显示带有记录学习时刻的发展目标！",
    filterAndShowParent:
      "您还可以选择过滤并显示在特定时间内发生的家长学习时刻。",
    learningStories:"學習故事",

    //Portfolio PDF
    selectProgressReportDateRange: "请选择进展报告的日期范围。",
    introductionText: `评估作品集结合了图片、轶事记录和评分表，支持我们在Trehaus School采用的以儿童为中心、以优势为基础的学习方法。图片和轶事记录为孩子如何培养自己的手和心提供了生动的展望，而熟练的评分表反映了学生相对于头脑知识的学校学期标准的成就。通过多种手段和多样化的策略，学生的学习变得可见，他们的成功值得庆祝。`,
    littleProjectsAccomplished: "完成的小项目",
    littleLearnersAccomplished: "完成的小学习者",
    photoGallery: "相册",
    startedOn: "开始于",
    completedOn: "完成于",
    observedOn: "观察于",
    profileShowcased: "{profileName} 在以下学习时刻展示了这些价值观：",
    profileDemonstrates:
      "作为Trehaus的孩子，{profileName} 在我们课程框架的这个类别中展示了能力，最显著的成就是：",
    concludingRemarks: "总结备注",
    sneakPeek: "一瞥",
    goals: "目标",
    thankYouBeingJourney: "感谢您成为我在{className} {year}学程中的一部分！",
    myTeachers: "我的老师",

    // Create child page
    preferred_name: "首选名",
    full_name: "全名",
    dob: "出生日期",
    image: "图片",
    submit: "提交",
    not_logged_in: "您尚未登录！",

    // Class Page -> Group === Class
    group_name: "班级名称",
    create_group: "创建班级",
    addGroup: "添加班级",
    edit_group: "编辑班级",
    group: "班级",
    assignChildrenToGroup: "分配学生到班级",
    childrenInGroup: "班级中的学生",
    assignTeacherToGroup: "分配教师到班级",
    teacherInGroup: "班级中的教师",

    // Enroll Child Page
    child: "学生",
    project: "项目",
    enroll_child: "为项目注册学生",
    module: "模块",
    unit: "单元",

    // Project Page - Edit + Create
    editing: "编辑",
    managing_proj: "管理项目",
    projectEditor: "编辑项目",
    create_new_proj: "创建新项目",
    create_project: "创建项目",
    create_assessment: "创建新评估",
    proj_for_child: "为儿童创建项目",
    sproutsExplainationPartOne: "一般为18个月至2岁，",
    sproutsExplainationPartTwo: "孩子开始从周围的世界中获取新信息。",
    budsExplainationPartOne: "一般为3到4岁，",
    budsExplainationPartTwo:
      "孩子们具有一些知识，并学习新技能以应用这些新信息。",
    blossomsExplainationPartOne: "一般为5到6岁，",
    blossomsExplainationPartTwo: "他们拥有知识和技能，并积极运用和练习它们。",
    sprouts: "嫩芽",
    sprouts_with_age: "嫩芽（18个月 - 2岁）",
    sproutlings_with_age: "嫩芽苗（2岁 - 3岁）",
    buds_with_age: "芽花（3岁 - 4岁）",
    blossoms_with_age: "花朵（5岁 - 6岁）",
    blooms_with_age: "花朵（5岁 - 6岁）",
    sprouts_with_age_external: "嫩芽（18个月 - 2岁）",
    blossoms_with_age_external: "花朵（5岁 - 6岁）",
    programme: "项目",
    proj_name: "项目名称",
    level: "级别",
    basic: "基础",
    intermediate: "中级",
    advanced: "高级",
    proj_duration: "预计时长（分钟）",
    proj_description: "项目描述",
    learning_objectives: "学习目标",
    publish: "发布",
    saveAsDraft: "另存为草稿",
    recall: "召回",
    recalled: "已召回",
    success_remove_project: "成功删除项目！",
    success_remove_unit_guide: "资源指南已成功移除！",
    projectTags: "项目标签",
    learningObjectives: "学习目标",
    createAndManageModules: "创建和管理模块",
    addObjective: "添加目标",

    //Lessons
    activity: "活动",
    activity_name: "活动名称",
    add_activity: "添加活动",
    success_remove_activity: "成功移除活动！",
    activity_duration_min: "活动时长（分钟）",
    attachment_if_any: "附件（如果有）",
    attachment_if_copy: `附件（选中框以将附件添加到“文档”中）`,
    add_attachement_into_doc: `将附件添加到“文档”中`,
    attachments: "附件",
    lesson_name: "课程名称",
    lesson_description: "课程描述",
    lesson_objective: "课程目标",
    lesson_objectives: "课程目标",
    addLessonObjective: "添加课程目标",
    lesson_intention: "课程意图",
    lessonToolbox: "课程工具箱",
    expected_duration_mins: "预计时长（分钟）",
    key_vocabulary: "关键词汇",
    material: "材料",
    materials: "材料",
    materials_required: "所需材料",
    recommendedSetupInstructionIfAny: "建议的设置说明（如果有）",
    setupInstruction: "设置说明",
    image_set: "图像集",

    //Activity
    notes: "笔记",
    stickyNote: "便签",
    activityStepInstruction: "步骤 {weight} 说明:",
    activityStep: "步骤 {weight}",
    editing_activity: "编辑活动",
    creating_activity: "创建活动",
    remark: "备注",
    remarks: "备注",
    compulsory: "强制",
    cancel: "取消",
    skip: "跳过",
    create: "创建",
    delete_activity: "删除活动",
    success_reorder_activities: "成功重新排序活动！",
    success_move_activity: "成功移动活动！",
    question: "问题",

    //Checkpoint
    checkpoint: "检查点",

    //Learning Moment
    isLearningMoment: "有任何学习时刻吗？",
    learningMomentPhoto: "学习时刻照片",
    learningMomentQuestion: "学习时刻问题",

    //Objective
    adding_curriculum_fundamental_to: "将课程基础添加到",
    editing_curriculum_fundamental_of: "编辑",
    objective_str: "目标",
    add_objective: "添加目标",
    delete_fundamental: "删除基础",
    fundamental_str: "基础",

    //Login
    forgottenPassword: "忘记密码？",
    findYourAccount: "找回您的账户",
    enterYourEmail: "请输入您的电子邮件地址以查找您的账户。",
    pleaseCheckYourEmailAndPassword: "请检查您的电子邮件和密码是否正确。",
    emailSubmittedTitle: "已提交密码重置请求",
    emailSubmittedResponse:
      "如果您提供的电子邮件在我们的系统中存在，我们已向其发送了重置密码的链接。请检查您的收件箱和垃圾邮件文件夹。",

    //Register
    email: "电子邮件",
    mobile_number: "手机号码",
    password: "密码",
    already_have_account_login: "已经有账户？登录",
    role: "角色",

    //Reset
    resetYourPassword: "重置您的密码",

    //Admin Page
    add: "新增",
    actions: "操作",
    siteAdmin: "网站管理员",
    contentEditor: "内容编辑",
    ttaContentEditor: "TTA内容编辑",
    schoolAdmin: "学校管理员",
    paymentRecord: "支付记录",

    //Questionnaire
    createInterest: "创建兴趣",
    createPreDQQuestion: "创建发现前调查问卷问题",
    pickLanguage: "选择您的语言偏好",
    selectModules: `选择您将教授的模块`,
    selectDevelopmentalAreas: `选择关键发展领域（建议：前5个）`,
    selectInterests: `选择您的兴趣（适用于{groupName}班的最感兴趣的兴趣）`,
    weight: "排序",
    name: "姓名",
    priority: "优先 (yōuxiān)",

    //Quick questions
    welcomeToTTB: "欢迎来到我们的教学工具箱！",
    everyChildIsUnique: "每个孩子都是独一无二的",
    introFirstParagraph:
      "Trehaus课程致力于为每个学习者提供引人入胜、有意义且差异化的课程。我们认识到每个孩子都是独一无二的，以自己的步伐发展，因此我们创建了三个学习层次，帮助教师为孩子当前的学习阶段策划课程。",
    sproutsDescription:
      "一般适用于18个月至2岁的儿童，Sprouts儿童正在了解这个世界，扎根并开始吸收新的知识。",
    budsDescription:
      "一般适用于3至4岁的儿童，芽儿童正在吸收信息，并积极将其应用于支持他们正在发展的技能。",
    blossomsDescription:
      "一般适用于5至6岁的儿童，Blossoms儿童充满了信息，并积极发展他们的新技能。",
    introLastParagraph:
      "在浏览我们的课程计划库之前，请花几分钟回答这些快速问题，以便我们根据您的孩子当前的成长阶段为您推荐项目。",
    myChildInterests: "我的孩子对...感兴趣",
    myChildInterestsSub: "（选择您喜欢的所有选项！）",
    myChildGoals: "我的孩子可以从中受益...",
    myChildGoalsSub: "（选择您想要努力的目标！）",
    myChildCommunicationMethod: "我的孩子使用...进行交流",
    myChildCommunicationMethodSub:
      "（无论语言如何，请选择您的孩子使用的方式！）",
    concludingTextFirst: "太棒了！根据您的答案... 您的孩子目前处于{0}水平。",
    concludingTextLast: "前往我们的课程计划库，查看我们为您推荐的有趣项目。",
    quickQuestionsCompleted: "快速问题已完成！",
    levelAnswer1: "手势和声音",
    levelAnswer2: "一到两个词",
    levelAnswer3: "简短短语",
    levelAnswer4: "简单句子",
    levelAnswer5: "详细句子",
    loading: "加载中",
    intro: "介绍",

    //Access Control
    school: "学校",
    schools: "学校",
    search: "搜索",
    bulkAction: "批量操作",
    editProject: "编辑项目",

    //Manage project page
    startDate: "开始日期",
    endDate: "结束日期",

    //Ticketing
    subject: "主题",
    itSupportFeedback: "IT支持 - 反馈",
    feedback: "反馈",
    next: "下一步",

    //Payments
    payment: "支付",
    subscription: "订阅",
    subscriptions: "订阅",
    paymentHistory: "支付记录",
    noSubscribe: "尚未订阅任何产品！",
    noProduct: "敬请期待此产品！",
    paymentTitleSchool: "为您的学校选择合适的计划",
    paymentTitleParent: "为您的家庭选择合适的计划",
    selectPlan: "选择计划",
    startFreeTrial: "开始7天免费试用",
    sevenDayTrial: "7天试用",
    manageOrUpdatePlan: "管理或更新计划",
    paymentTitle: "选择合适的计划",
    subscribedProduct: "已订阅产品",
    onlySchoolAdmin: "请以学校管理员身份登录以查看订阅计划！",
    subscribed: "已订阅",
    noRenew: "无需续订",
    willRenew: "续订",
    noProject: "未找到项目",
    pleaseSubscribe: "请订阅我们的计划或与管理员联系以获取更多信息",
    addPaymentRecord: "添加支付记录",
    canViewProjects: "可以查看项目",
    createPaymentRecord: "创建支付记录",
    customerId: "客户ID",
    bypassPayment: "绕过支付",
    demoSchool: "演示学校",
    subscribeToViewProject: "订阅以查看项目",
    oopsDemoAccount:
      "糟糕！这是一个演示帐户。在LittleLab.com上注册以获得完整体验！",

    //Calendar
    calendarViewWeek: "周",
    calendarViewMonth: "月",
    calendarViewYear: "年",
    january: "一月",
    february: "二月",
    march: "三月",
    april: "四月",
    may: "五月",
    june: "六月",
    july: "七月",
    august: "八月",
    september: "九月",
    october: "十月",
    november: "十一月",
    december: "十二月",

    //Timetable
    timetable: "课程表",
    today: "今天",
    thisWeek: "本周",
    editTimetable: "编辑课程表",
    addNewEvent: "添加新事件",
    editEvent: "编辑事件",
    discardEdits: "放弃编辑",
    saveChanges: "保存更改",
    noChanges: "没有更改",
    timetableUpdated: "课程表已更新！",
    eventTitle: "事件标题",
    eventType: "事件类型",
    dayOfWeek: "星期几",
    startingTime: "开始时间",
    endingTime: "结束时间",
    timetableTitle: "{name}的课程表",
    shareTimetable: "分享课程表？",
    copyShareLink: "复制分享链接",
    shareLinkCopied: "分享链接已复制！",
    timetableShared: "任何有链接的人都可以查看您的课程表。",
    timetableUnshared: "只有您可以查看您的课程表。",
    defaultTimetableButtonTitle: "使用我们的视觉课程表制定您的课程计划！",
    uploadedTimetableButtonTitle: "上传您自己的计划以供参考！",
    uploadNewTimetable: "上传新课程表",
    removeTimetable: "删除课程表",
    noUploadedTimetable: "没有上传的课程表",
    uploadYourTimetable: "上传您自己课程表的图片",
    uploading: "上传中",

    successAddEvent: "成功添加新事件！",
    failAddEvent: "无法添加新事件。请稍后重试！",
    successUpdateEvent: "事件成功更新！",
    failUpdateEvent: "无法更新事件。请稍后重试！",
    successDeleteEvent: "事件成功删除！",
    failDeleteEvent: "无法删除事件。请稍后重试！",
    successDuplicateEvent: "事件成功复制！",
    failDuplicateEvent: "无法复制事件。请稍后重试！",
    successUploadTimetable: "成功上传课程表！",
    failUploadTimetable: "无法上传课程表。请稍后重试！",
    successUpdateTimetable: "成功更新课程表！",
    failUpdateTimetable: "无法更新课程表。请稍后重试！",
    successDeleteTimetable: "成功删除课程表！",
    failDeleteTimetable: "无法删除课程表。请稍后重试！",
    noEventToday: "今天没有事件",

    // Chat
    messages: "消息",
    newChat: "新聊天",
    newClassChat: "新班级聊天",
    selectContact: "选择联系人",
    startANewChat: "开始新的聊天",
    startANewClassChat: "开始新的班级聊天",
    typeYourMsgHere: "在这里输入您的消息...",
    noNewMessage: "没有新消息",

    // Developmental Goal
    manageDevelopmentalGoals: "管理发展目标",
    developmentalGoal: "发展目标",
    subDevelopmentalGoal: "子发展目标",

    addDevelopmentalGoal: "添加{tab}发展目标",
    editingDevelopmentalGoal: "编辑发展目标",
    deleteDevelopmentalGoal: "删除发展目标",
    createdDevelopmentalGoal: "成功创建发展目标！",
    updatedDevelopmentalGoal: "成功更新发展目标！",
    removedDevelopmentalGoal: "成功移除发展目标！",

    addSubDevelopmentalGoal: "添加{tab}子发展目标",
    editingSubDevelopmentalGoal: "编辑子发展目标",
    deleteSubDevelopmentalGoal: "删除子发展目标",
    createdSubDevelopmentalGoal: "成功创建子发展目标！",
    updatedSubDevelopmentalGoal: "成功更新子发展目标！",
    removedSubDevelopmentalGoal: "成功移除子发展目标！",

    // Still Finding Out
    stillFindingOut: "我们仍在了解中！",
    stillFindingOutHeadLibrary:
      "这里没有记录学习时刻...前往图书馆开始一个项目并创造学习时刻！",
    noObservations: "没有记录观察",
    noCaption: "没有标题",
    title: "标题",
    subtitle: "副标题",
    milestone: "里程碑",
    successReorderMilestone: "里程碑已成功重新排序！",
    addMilestone: "添加里程碑",
    deleteMilestone: "删除里程碑",
    milestoneNo: "里程碑 {number}",
    photoInstruction: "照片说明",
    photoCaption: "照片标题",
    sixMonthNoObservation:
      "距离您上次观察已经有一段时间了...让我们做另一个学习时刻来看看一些进展！",

    // Prohibited Word
    prohibitedWords: "禁止使用的词汇",
    manageProhibitedWords: "管理禁止使用的词汇",
    addProhibitedWords: "添加禁止使用的词汇",

    // Completed Work
    addCompletedWork: "添加小作品",
    uploadChildrenCompletedWork: "上传儿童完成的作品",
    // Teacher Portfolio
    byClass: "按班级",
    byYear: "按年份",
    byProject: "按项目",

    // WIP
    underConstruction: "糟糕！此页面仍在建设中。敬请期待！",

    // Parent Dashboard
    nowLearning: "正在学习",

    // Other Components
    updateBackpack: "更新背包",
    updateFavorite: "更新收藏",
    templateFor: "模板用于",
    activitiesToBeConducted: "要进行的活动",
    loadingDots: "加载中...",
    youNeedToEnrollYourGroup: "您需要注册您的群组以访问",
    selectDropAttachments: "选择或拖放附件",
    selectDropActivity: "选择或拖放活动缩略图",
    selectDropLesson: "选择或拖放课程缩略图",
    translateThisLessonTo: "将此课程翻译为",
  },

  //URDU
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  ur: {
    //General
    updated: "تازہ کردہ",
    story:"کہانی",
    //LandingPage
    whereKidsLoveLearning: "جہاں بچے محبت سے سیکھتے ہیں",
    findOutMore: "مزید جانیں",
    takeAPeek:
      "ہمارے چھوٹوں کے منصوبوں میں چھپ کر دیکھیں، جو تبدیلی پیدا کرنے والوں کو بڑھانے میں مصروف ہیں",
    getAccessToLittleLab: "لٹل لیب تک رسائی حاصل کریں",
    everyChildIsDifferent: "ہر بچہ مختلف ہوتا ہے!",
    comeAndExperienceIndividualized:
      "آئیے ایک انفرادی تعلیم کا تجربہ کریں، جو آپ کے بچے کے لئے مخصوص ہے",
    your: "آپ کا",
    childSmall: "بچہ",
    createAUniqueLearningExperience: "آج ایک یونیک تعلیمی تجربہ بنائیں",
    greatContentCreatedBy:
      "تازہ ترین تحقیق اور تعلیمی تراکیب پر مبنی اہم ترین ماہرین کے زیر اہتمام بنایا گیا عظیم مواد",
    learnMore: "مزید جانیں",
    superEasyAndIntuitive:
      "استعمال کرنا بہت آسان اور سیدھا ہے چاہے آپ والد ہوں یا استاد ہوں۔",
    perfectEvenFor: "بلکل ٹھیک ہے",
    homeSchoolers: "ہوم اسکولرز",
    andSmall: "اور",
    learningCommunities: "تعلیمی کمیونٹیز",
    discoverTheFutureOfEducation: "تعلیم کا مستقبل دریافت کریں",
    ourProprietaryTrehaus:
      "ہمارا خاص Trehaus لٹل لیب نصاب 6 مستقبل اور نرم ہنر شامل ہے",
    ourLittleLearners: "ہمارے چھوٹے سیکھنے والے",
    enrichYourChild:
      "آپ کے بچے کے علم کو بڑھانے کے لئے یہ مکمل ڈھانچے اور خود کو پیس کرنے والے درسوں کے ذریعے دلچسپ اور غور سے مصروف ہونے کے لئے ڈیزائن کئے گئے ہیں۔",
    hearWhatOtherParents: "سنیں دوسرے والدین اور تعلیمی کارکنان کی رائے",
    weSimplyLove:
      "ہم Trehaus کے تعلیم کے لئے ایک قدرتی دیکھ بھال کے دورہ ہونے والے نظریے سے محبت کرتے ہیں۔ یہ صرف ایک پری-اسکول نہیں ہے، یہ عجیب قیمتوں پر مبنی ایک مضبوط کمیونٹی ہے۔ میں Trehaus کو خیلی سراہتا ہوں۔",
    confidence: "اعتماد",
    empathy: "ہمدردی",
    creativity: "تخلیق",
    perseveranceGrit: "استقامت اور ہمت",
    communicationSkills: "مواصلاتی مہارتیں",
    selfRegulation: "خود انضباطی",
    integrity: "اخلاقیت",
    executiveFunction: "ایگزیکٹو فعل",
    steam: "STEAM",
    englishLanguageLiteracy: "انگریزی زبان اور لٹریسی",
    mandarinLanguageLiteracy: "مینڈارن زبان اور لٹریسی",
    numeracyMathConcepts: "عددیہ اور ریاستی مفاہم",
    grossMotorSkills: "براہ راست موٹر مہارتیں",
    fineMotorSkills: "فائن موٹر مہارتیں",
    gratitudeGivingBack: "شکریہ اور دینا",
    environmentalResponsibility: "ماحولی ذمہ داری",
    socialAwareness: "سوشیل اوئیرنیس",
    publicSpeakingAdvocacy: "عوامی بول چال اور وکالت",
    interdependence: "تعلق",
    practicalLifeSkills: "عملی زندگی کی مہارتیں",
    globalPerspectives: "عالمی منظر",
    littleCreative: "چھوٹا تخلیقی",
    littleCeo: "چھوٹا ایکزیکٹو آفیسر",
    littleChef: "چھوٹا شیف",
    littlePhilanthropist: "چھوٹا خیرات گزار",
    littleEngineer: "چھوٹا انجینئر",
    littleEntrepreneur: "چھوٹا کاروباری",
    theLearningExperiencesDesigned:
      "اس نصاب میں تخلیقی تعلیمی تجربات آپ کے بچے کی روزانہ کی زندگی سے متعلق ہیں، جو انہیں اپنے علم اور مہارتوں کو مضبوط کرنے کے لئے ایک مفید سیاق و سباق فراہم کرتی ہے۔",
    littleJournalist: "چھوٹا صحافی",
    exposeYourChildToKnowledge:
      "اپنے بچے کو انگریزی زبان اور تعلیم میں معلومات فراہم کرنے کے لئے مزیدار اور دلچسپ طریقے سے واقف کرائیں۔ چھوٹا صحافی آپ کے بچے کے لئے تمام اہم پہلوؤں کو ایک ساتھ ملا دیتا ہے.",
    littleMathematician: "چھوٹا ریاستدان",
    littleMathematicianProvidesAnAuthentic:
      "چھوٹا ریاستدان ایک مستقل، دلچسپ اور ہاتھ سے کام کرنے والا تعلیمی تجربہ فراہم کرتا ہے تاکہ آپ کا بچہ ریاستی اور منطقی سوچ میں مضبوط بن سکے، ہنرمندی اور مسئلہ حل کرنے کی صلاحیت کو ترتیب دے۔",
    trehausSchoolMomHelle: "ٹرہاؤس اسکول کی ماں، ہیلی",
    lauraIsAlwaysExcited:
      "لارا ہمیشہ پڑھائی جانے کے لئے ہوشیار ہے! اسکول کے تمام معمولات میں ان کی آزادی کو بڑھانے اور اہم زندگی کی قیمتی اصولوں کو جما کر سکھانے کے لئے شکریہ. ہمارے دل تنہا یہ ہیں کہ ہم کام کرتے ہیں، کیونکہ آپ کی محبت اور دیکھ بھال ہے۔",
    trehausSchoolMomRaine: "ٹرہاؤس اسکول کی ماں، رین",
    jaydenIsReallyLucky:
      "جےڈن کو دیکھ کر واضح ہوتا ہے کہ اسے نرم اور دیکھ بھال کرنے والے استادھان کا خوش قسمت ہونا ہے. جےڈن انہیں پسند کرتا ہے! جےڈن کو ٹرہاؤس اسکول بھیجنا اور ٹرہاؤس میں کام کرنا ہم نے واقعی میں بہترین فیصلہ کیا ہے!",
    trehausSchoolMomOlivia: "ٹرہاؤس اسکول کی ماں، اولیویا",
    myBiggestSatisfactionIsSeeing:
      "میری سب سے بڑی خوشی یہ ہے کہ میں اپنے بیٹے نیٹ کو ٹرہاؤس میں فلاحی اور بڑھتی ہوئی دیکھتا ہوں، اسے مکمل طور پر اسکول سے محبت ہے، اور مجھے یہ کس طرح میں بیزنس کلب میں کام کر سکتا ہوں، اور اس کے زندگی کے پہلے سالوں میں شامل ہو سکتا ہوں.",
    trehausSchoolMomElaine: "ٹرہاؤس اسکول کی ماں، ایلین",
    incrediblyDedicatedAndNurturing:
      "ٹرہاؤس اسکول کے اساتذہ اور پرنسپل کی نہایت مصرف اور دیکھ بھال میں مصروف ہونے والی اسٹاف کا میں بہت متاثر ہوا. میں نے ان کی ترقی کے رپورٹس اور ان کے اجتماعی فرائض پر دی گئی کوششوں سے بہت متاثر ہوا.",
    trehausSchoolDadStephen: "ٹرہاؤس اسکول کا باپ، اسٹیفن",
    everyPersonInTheSchool:
      "اسکول میں ہر ایک شخص بچوں کے لئے حقیقی طور پر دیکھ بھال کرتا ہے اور ہر بچہ کو اپنا بچہ سمجھتا ہے، لہذا میں یقین کرتا ہوں کہ میرا بیٹا ہر دن جب میں کام پر جاتا ہوں تو اچھے ہاتھوں میں ہے.",
    trehausSchoolDadRodney: "ٹرہاؤس اسکول کا باپ، راڈنی",

    //Dashboard
    totalChildren: "کل بچے",
    totalTeachers: "کل ٹیچرز",
    totalClasses: "کل کلاسیں",
    currentlyOn: "حال ہی میں",
    proTips: "پرو ٹپس: [] ٹائپ کریں تاکہ ٹو ڈو ڈالیں",
    typeYourNoteHere: "یہاں اپنا یادداشت ٹائپ کریں",
    message: "پیغام",
    timeTable: "ٹائم ٹیبل",
    jan: "جنوری",
    feb: "فروری",
    mar: "مارچ",
    apr: "اپریل",
    jun: "جون",
    jul: "جولائی",
    aug: "اگست",
    sep: "ستمبر",
    oct: "اکتوبر",
    nov: "نومبر",
    dec: "دسمبر",
    teachers: "ٹیچرز",
    teacher: "ٹیچر",
    children: "بچے",
    total: "کل",
    viewClass: "کلاس دیکھیں",

    //Homepage
    welcome: "خوش آمدید،",
    dashboard: "میرا ڈیش بورڈ",
    whoLearning: "آج کون پڑھ رہا ہے؟",
    viewGroup: "کلاس دیکھیں",
    updateProfile: "پروفائل اپ ڈیٹ کریں",
    broadcastMsg: "براڈکاسٹ میسج",
    allGroups: "تمام کلاسیں",
    noOngoingLesson: "کوئی چل رہی ہوئی گزرا ہوا سبق نہیں ہے",
    noLesson: "کوئی سبق نہیں",
    students: "طلباء",
    formTeacher: "فارم ٹیچر",
    todayLessons: "آج کے سبق",
    tmrLessons: "آئندہ سبق",
    lessonCompleted: "سبق مکمل ہوا",
    lessonsCompleted: "سبق مکمل ہوا",
    lessonConducted: "منعقد شدہ سبق",
    lessonsConducted: "منعقد شدہ سبق",
    resume: "استیعاب",
    resumeLesson: "استیعاب سبق",
    goToLesson: "سبق پر جائیں",
    newEnrollments: "نئی رجسٹریشنیں",
    lesson_number: "سبق {number}",
    myClasses: "میری کلاسیں",
    classDetail: "کلاس کی تفصیلات",
    attendance: "حضور",
    currentLessons: "چل رہے سبق",
    view: "دیکھیں",
    myChildren: "میرے بچے",
    announcements: "اعلانات",
    noAnnouncementYet: "ابھی تک کوئی اعلان نہیں",
    noActionRequiredYet: "ابھی کوئی کارروائی ضروری نہیں ہے",
    viewAllAnnouncements: "تمام اعلانات دیکھیں",
    showActiveAnnouncementsOnly: "صرف فعال اعلانات دکھائیں",
    littleAlbum: "چھوٹا البم",
    mostRecentPhotos: "تازہ ترین تصاویر",
    quickItems: "تیز چیزیں",
    addTodo: "ٹو ڈو شامل کریں",
    notesUpdatedSuccess: "نوٹس کامیابی سے اپ ڈیٹ ہوئے ہیں!",
    notesUpdatedFailed: "نوٹس کو اپ ڈیٹ کرنے میں ناکامی!",
    addTodoInstruction: "پرو ٹپس: [] ٹائپ کریں تاکہ ٹو ڈو ڈالیں!",
    typeYourNotesHere: "یہاں اپنے نوٹس ٹائپ کریں...",

    // MyChildrenPage
    viewPortfolio: "پورٹ فولیو دیکھیں",
    bornOn: "پیدا ہونے کی تاریخ",
    parent: "والدین",

    // My Classes
    classList: "کلاس کی فہرست",
    editClass: "کلاس میں ترتیب دیکھیں",

    // Edit Class
    update: "اپ ڈیٹ",

    // AnnouncementsPage
    createAnnouncement: "اعلان بنائیں",
    addNewAnnouncement: "نیا اعلان شامل کریں",
    editAnnouncement: "اعلان میں ترتیب دیکھیں",
    deleteAnnouncement: "اعلان حذف کریں",
    announcementCreatedSuccess: "اعلان کامیابی سے بنایا گیا ہے!",
    announcementCreatedFailed: "اعلان بنانے میں ناکامی!",
    announcementUpdatedSuccess: "اعلان کامیابی سے تازہ کیا گیا ہے!",
    announcementUpdatedFailed: "اعلان کو اپ ڈیٹ کرنے میں ناکامی!",
    announcementRecalledSuccess: "اعلان کامیابی سے واپس بلایا گیا ہے!",
    announcementRecalledFail: "اعلان واپس بلانے میں ناکامی!",
    announcementRecalled: "اس اعلان کو واپس بلایا گیا ہے۔",
    announcementDeletedSuccess: "اعلان کامیابی سے حذف ہوا!",
    announcementDeletedFailed: "اعلان حذف کرنے میں ناکامی!",
    announcementStartAt: "اعلان شروع ہو گا",
    announcementEndAt: "اعلان ختم ہو گا",
    includesAttachment: "میں منسلکہ ہے",
    pinAnnouncement: "اعلان کو چٹکنا؟",
    anyResponseRequired: "کیا کوئی جواب ضروری ہے؟",
    responseRequired: "جواب کی ضرورت ہے",
    responseQuestion: "جواب کا سوال",
    responseType: "جواب کا نوع",
    respondBy: "تکمیل جواب بیان کریں",
    allowTextResponse: "متنی جواب کی اجازت ہے؟",
    textResponse: "متنی جواب",
    textResponseInstruction: "متنی جواب ہدایت",
    allowAttachmentUpload: "منسلکہ اپ لوڈ کرنے کی اجازت ہے؟",
    attachmentInstruction: "منسلکہ ہدایت",
    responseOption: "جواب کا اختیار",
    addResponseOption: "جواب کا اختیار شامل کریں",
    responseUpdated: "جواب تازہ کیا گیا ہے!",
    responseUpdateFail:
      "جواب تازہ کرنے میں ناکامی! براہ کرم کے بعد دوبارہ کوشش کریں",
    uploadDocument: "دستاویز اپ لوڈ کریں",
    acknowledge: "اقرار",

    // Documents Page
    createDocument: "دستاویز بنائیں",
    addNewDocument: "نیا دستاویز شامل کریں",
    editDocument: "دستاویز میں ترتیب دیکھیں",
    deleteDocument: "دستاویز ہٹائیں",
    document: "دستاویز",
    documents: "دستاویزات",
    noDocumentYet: "ابھی تک کوئی دستاویز نہیں ہے",
    pleaseUploadAtLeastOneDocument:
      "براہ کرم کم سے کم ایک دستاویز اپ لوڈ کریں!",

    // BrowsePage
    noChild: "آپ کا کوئی بچہ رجسٹر ہوا نہیں ہے",
    noGroup: "آپ کا کوئی کلاس رجسٹر ہوا نہیں ہے",
    noGroupAssigned:
      "آپ کوئی کلاس مختصر نہیں ہے! براہ کرم مزید وضاحت کے لئے اسکول ایڈمن سے رابطہ کریں",
    browse: "تمام منصوبوں کا جائزہ لیں",
    noProjectFound: "کوئی منصوبہ نہیں ملا",
    searchForProjects: "منصوبے تلاش کریں",
    recommendations: "تجویزات",
    displayAll: "تمام منصوبے دکھائیں",
    specially: "خصوصاً آپ کے لئے",
    specialDesc: "ہمارے بہترین فردی تعلیم کا تجربہ",
    trending: "مشہور منصوبے",
    trendDesc: "دی لٹل لیب پر مشہور کیا جا رہا ہے",
    new: "نیا!",
    newDesc: "ہمارے ٹیم کے ماہرین کی تازہ ترین منصوبے دیکھیں",
    byModule: "ماڈیول کے ذریعے",
    byLevels: "سطحوں کے ذریعے",
    addToBackpack: "پیک میں شامل کریں",
    removeFromBackpack: "ہٹائیں",
    addedToBackpack: "پیک میں شامل ہوگیا!",
    viewProjectDetails: "منصوبے کی تفصیلات دیکھیں",
    viewAssessmentDetails: "تفصیلات کا جائزہ لیں",
    addOrScheduleItToView: "دیکھنے کے لئے شامل یا شیڈول کریں!",

    // NavigationBar
    home: "ہوم",
    lessonLibrary: "نصاب",
    library: "درس خطة کتب",
    assessments: "تشخیصات",
    backpack: "بیک پیک اور مقبولی",
    visualTimetable: "مرئی وقت کا جدول",
    scheduler: "شیڈولر",
    littleEvaluator: "چھوٹے ملازم",
    calendar: "کیلنڈر",
    myClass: "میری کلاس",
    portfolios: "پورٹفولیو",
    childrenPortfolio: "بچوں کا پورٹفولیو",
    teacherPortfolio: "استاد کا آرکائیو",
    notices: "اطلاعات",

    // ProjectDetailPage
    lesson: "سبق",
    lessons: "سبقات",
    saved: "محفوظ ہوگیا",
    notSaved: "محفوظ نہیں ہوا",
    overview: "جائزہ",
    unitGuide: "ریسورس گائیڈ",
    plan: "سبق کا منصوبہ",
    assessmentPlans: "تشخیصاتی منصوبے",
    teachingMaterials: "تعلیمی مواد",
    communitySharing: "کمیونٹی کا تبادلہ",
    whatPeopleLike: "لوگوں کو کیا پسند ہے",
    tags: "ٹیگز",

    // Teaching Material
    getTeachingMaterials: "یونٹ مواد حاصل کریں",
    downloadMaterials: "مواد ڈاؤن لوڈ کریں",

    // ProjectMoment
    uploadPhoto: "تصویر اپ لوڈ کریں",
    uploadPhotos: "تصاویر اپ لوڈ کریں",
    uploadVideos: "ویڈیوز اپ لوڈ کریں",
    uploadMedia: "میڈیا اپ لوڈ کریں",
    caption: "کیپشن",
    typeCaption: "کیپشن ٹائپ کریں",
    addCaption: "کیپشن شامل کریں",
    upload: "اپ لوڈ کریں",
    uploadFinalizing:
      "آخری ترتیب دی جا رہی ہے...براہ کرم ایک دم تازہ کرنے سے گریز کریں...",
    uploadPhotoFinalizing:
      "تصاویر کو آخری ترتیب دی جا رہی ہے...براہ کرم ایک دم تازہ کرنے سے گریز کریں...",
    uploadVideoFinalizing:
      "ویڈیو کو آخری ترتیب دی جا رہی ہے...براہ کرم ایک دم تازہ کرنے سے گریز کریں...",
    noActivity: "کوئی گتاگو نہیں",
    noPhoto: "ابھی تک کوئی تصویر نہیں",
    failed_load_gallery: "گیلری لوڈ کرنے میں ناکامی!",
    failed_upload: "اپ لوڈ ہونے میں ناکامی!",
    failed_add_photos: "تصاویر شامل کرنے میں ناکامی!",
    failed_add_videos: "ویڈیوز شامل کرنے میں ناکامی!",
    failed_add_photos_videos: "تصاویر یا ویڈیوز شامل کرنے میں ناکامی!",
    success_upload: "کامیابی کے ساتھ اپ لوڈ ہوگیا!",
    success_upload_photos: "تصاویر کامیابی کے ساتھ اپ لوڈ ہوگئی ہیں!",
    success_upload_videos: "ویڈیوز کامیابی کے ساتھ اپ لوڈ ہوگئی ہیں!",
    success_upload_photos_videos:
      "تصاویر اور ویڈیوز کامیابی کے ساتھ اپ لوڈ ہوگئی ہیں!",
    success_update_image: "کامیابی کے ساتھ تازہ کر دیا گیا ہے!",
    success_delete_image: "تصویریں کامیابی کے ساتھ ہٹا دی گئی ہیں!",
    success_delete_video: "ویڈیوز کامیابی کے ساتھ ہٹا دی گئی ہیں!",
    success_delete_photo_video:
      "تصاویر اور ویڈیوز کامیابی کے ساتھ ہٹا دی گئی ہیں!",
    fail_delete_photo_video:
      "تصاویر اور ویڈیوز ہٹانے میں ناکامی! براہ کرم دوبارہ کوشش کریں",
    delete_photo: "منتخب {number} تصویریں ہٹائیں",
    delete_video: "منتخب {number} ویڈیوز ہٹائیں",
    delete_photo_video: "منتخب {number} تصویریں/ویڈیوز ہٹائیں",
    selected: "منتخب",
    allPhotos: "تمام تصاویر",
    allVideos: "تمام ویڈیوز",
    allPhotosVideos: "تمام تصاویر اور ویڈیوز",
    irreversibleAction: "یہ عمل غیرقابل واپسی ہے!",
    tagChildren: "بچوں کو ٹیگ کریں",
    profiles: "پروفائلز",
    edit_image: "تصویر میں ترتیب دیں",
    edit_video: "ویڈیو میں ترتیب دیں",

    // Edit Image
    flipHoriz: "افقی موڑیں",
    flipVerti: "عمودی موڑیں",
    rotateAntiClockwise: "الٹے گھڑی کی سمت میں گھمائیں",
    rotateClockwise: "گھڑی کی سمت میں گھمائیں",

    // LessonDetailPage
    begin: "چلو شروع ہوتا ہے!",
    scheduleProject: "منصوبہ کا جدول",
    day: "دن",
    days: "دن",
    objective: "تعلم کا مقصد",
    fundamental: "ترقی کے اہم اڑے",
    noFundamental: "کوئی ترقی کے اہم اڑے نہیں",
    guidingQuestions: "مرکوز سوالات",
    ratings: "ریٹنگ",
    noRating: "ابھی تک کوئی ریٹنگ نہیں",
    lessonNo: "سبق {number}",
    assessmentPlanNo: "تشخیص منصوبہ {number}",
    min: "منٹ",
    conduct: "کرنے کے لئے {number} سرگرمیاں",
    Sproutlings: "نکلنے والے پودے",
    Sprouts: "پودے",
    Buds: "کھلتے بچوں",
    Blossoms: "پھولوں",

    // Account page
    male: "نر",
    female: "مادہ",
    gender: "جنس",
    highestEducationQualification: "سب سے زیادہ تعلیمی قابلیت",
    educationStartDate: "تعلیم شروع ہونے کی تاریخ",
    noFormalRecordYet: "ابھی تک کوئی رسمی ریکارڈ نہیں",
    highSchool: "ہائی اسکول",
    associateDegree: "ایسوسی ایٹ ڈگری",
    bachelorsDegree: "بیچلرز ڈگری",
    mastersDegree: "ماسٹرز ڈگری",
    doctorateDegree: "ڈاکٹریٹ ڈگری",

    //Settings Page
    emailPreferences: "ای میل ترجیحات",

    //Email Preferences Page
    stayInformed: "مطلع رہیں",
    toggleOnOrOffEmails:
      "آن یا آف کرنے کے لیے ٹوگل کریں تاکہ آپ وہ ای میلز دریافت کریں۔",
    announcementsAndMessages: "اعلانات اور پیغامات",

    // profileSidebar
    profileSectionAbout: "کے بارے میں",
    profileSectionExperience: "تجربہ",
    profileSectionReflections: "عکس انداز",
    profileSectionCertifications: "سندات",
    profileSectionTestimonials: "تعریفات",
    profileSectionProjects: "پچھلے منصوبے",

    // profileIconMenu
    account: "اکاؤنٹ",
    settings: "ترتیبات",
    profile: "پروفائل",
    schoolProfile: "اسکول پروفائل",
    adminPage: "ایڈمن پیج",
    createNewClass: "نیا کلاس بنائیں",
    addNewChild: "نیا بچہ شامل کریں",
    manageProjects: "منصوبے کا منظم کریں",
    helpCenter: "مدد اور حمایت",
    registerSiteAdmin: "سائٹ ایڈمن رجسٹر کریں",
    registerSchoolAdmin: "اسکول ایڈمن رجسٹر کریں",
    registerContentEditor: "مواد سربراہ رجسٹر کریں",
    registerTTAContentEditor: "ٹی ٹی اے کونٹینٹ ایڈیٹر رجسٹر کریں",
    registerTeacher: "استاد رجسٹر کریں",
    registerParent: "والدین رجسٹر کریں",
    registerChild: "بچہ رجسٹر کریں",
    enroll: "بچے کو درج کریں",
    provideFeedback: "فیڈبیک فراہم کریں",
    viewFeedback: "فیڈبیک دیکھیں",
    logout: "لاگ آوٹ",
    switchToTTA: "ٹیچر ٹریننگ اکیڈمی میں تبدیل کریں",
    switchToTTB: "ٹیچنگ ٹول باکس میں تبدیل کریں",

    // Library
    backToLibrary: "لائبریری واپس",
    speciallySelectedForYourChildren:
      "آپ کے بچوں کے لئے خصوصی طور پر منتخب کیا گیا ہے",
    browseL: "براؤز",

    // Schedule Modal
    scheduleModalTagline:
      "انتظام کریں کہ آپکا منصوبہ کب ہونے والا ہے! {0}آپ ہمارے 'شیڈولر' فیچر کے تحت اپنی کوریکولم کیلنڈر میں اسے دیکھ سکتے ہیں۔",
    projectStartDate: "منصوبہ کا آغاز ہونے کی تاریخ",
    projectEndDate: "منصوبہ ختم ہونے کی تاریخ",

    // School Profile Page
    addBackgroundPhoto: "پس منظر تصویر شامل کریں",
    removeBackgroundPhoto: "پس منظر تصویر ہٹائیں",
    updateBackgroundPhoto: "پس منظر تصویر کو اپ ڈیٹ کریں",
    addDescription: "ایک مختصر تحریر شامل کریں!",
    deleteSuccess: "کامیابی کے ساتھ حذف ہوگیا!",
    deleteFailed: "حذف کرنے میں ناکامی!",
    updateSuccess: "کامیابی کے ساتھ اپ ڈیٹ ہوگیا!",
    updateFailed: "اپ ڈیٹ میں ناکامی!",
    createSuccess: "کامیابی کے ساتھ بنایا گیا!",
    createFailed: "بنانے میں ناکامی!",
    removePhoto: "تصویر ہٹائیں",

    // ActivityPage
    back: "واپس",
    pResources: "پروجیکٹ ریسورسز",
    activityNo: "انشاط {number}",
    optionNo: "اختیار {number}",
    lessonOverview: "سبق کا جائزہ",
    checkpoints: "خلاصہ اور چیک پوائنٹس",
    previousLesson: "پچھلا سبق",
    nextLesson: "اگلا سبق",
    activities: "انشاط",
    startActivity: "انشاط شروع کریں",
    reqConduct: "انشاط کو کنڈکٹ کرنے کے لئے ضروری ہے",
    startLesson: "سبق شروع کریں",
    noVocabulary: "کوئی لغت نہیں",
    noMaterial: "کوئی مواد نہیں",
    noInstruction: "کوئی ہدایت نہیں",
    deletedInstructions: "قدم موفقیت سے ہٹا دیا گیا ہے",
    pleaseSchedule: "براہ کرم اس پروجیکٹ کو مکمل کرنے سے پہلے شیڈول کریں!",
    pleaseUploadPhotoVideo:
      "براہ کرم اس انشاط کو مکمل کرنے سے پہلے کم سے کم 1 تصویر یا 1 ویڈیو اپ لوڈ کریں!",
    completeAll: "تمام انشاط مکمل کریں!",
    completePartial: "{total} انشاط میں سے {number} مکمل کریں!",
    pickToComplete: "مکمل کرنے کے لئے ایک انشاط منتخب کریں!",

    // ActivityDetailPage(DrawingPad)
    stepByStepInstructions: "مراحل بنیادی ہدایات",
    addStep: "مرحلہ شامل کریں",
    done: "ہوگیا",
    activityCompleted: "انشاط مکمل ہوگئی",
    notDone: "نہیں ہوا",
    checkpointIcon: "چیک پوائنٹس:",
    noCheckpoints: "کوئی چیک پوائنٹ نہیں",
    addCheckpoint: "چیک پوائنٹ شامل کریں",
    noLearningMoments: "کوئی سیکھنے کا لمحہ نہیں",
    learningMoment: "سیکھنے کا لمحہ",
    evaluationMoment: "تشخیصی لمحہ",
    learningMoments: "سیکھنے کے لمحے",
    evaluationMoments: "تشخیصی لمحے",
    addLearningMoment: "سیکھنے کا لمحہ شامل کریں",
    learningMomentRecorded: "سیکھنے کا لمحہ ریکارڈ ہوگیا",
    milestoneCheckIncomplete:
      "آپ تقریباً پہنچ چکے ہیں، مکمل کرنے کے لئے آپ کلک کریں!",
    photoIncomplete:
      "آپ تقریباً پہنچ چکے ہیں، آپنی سیکھنے کے لمحے کو مکمل کرنے کے لئے تصویر اپ لوڈ کریں!",
    previous: "پچھلا",
    summary: "سبق کا خلاصہ",
    selectAll: "سب کچھ منتخب کریں",
    deselectAll: "سب کچھ منتخب نہیں کریں",
    milestoneCheck: "مائلسٹون چیک",
    milestoneCheckDescription:
      "اس چیک لسٹ کا استعمال کریں تاکہ دیکھا جا سکے کہ آپ کے بچے نے یہ منصوبہ کتنا حصہ مکمل کیا ہے۔ یہ آپ چاہے لیسن کے درمیان یا آپ کے یونٹ کے اختتام پر کر سکتے ہیں۔",
    milestoneCheckWithCheckpoint: "مائلسٹون چیک (چیک پوائنٹس کے ساتھ)",
    pleaseNavigateToLastPageToClickDone: `براہ کرم اسٹیپ کے آخری صفحے پر جانے کے لئے اور "ہوگیا" کلک کرنے کے لئے!`,

    // Review
    noReview: "ابھی تک کوئی جائزہ نہیں",
    noContent: "ابھی تک کوئی مواد نہیں",
    writeAReview: "جائزہ لکھیں",
    shareReview:
      "پروجیکٹ میں آپ کو کیا پسند آیا ہے، اور/یا آپ کے لئے کیا بہتر ہوا؟",
    feedbackToDevTeam:
      "کیا آپ ہمیں اس پروجیکٹ کو بہتر بنانے کے لئے کوئی فیڈبیک یا تجویز فراہم کر سکتے ہیں؟",
    thankyouFeedback: "آپ کی فیڈبیک کا شکریہ!",
    viewReviews: "تمام جوابات دیکھیں",
    thanksReviews: "آپ کی جائزہ کا شکریہ!",
    reviewPosted: "جائزہ پوسٹ ہوگیا!",
    reviewUpdated: "جائزہ اپ ڈیٹ ہوا!",
    reviewDeleted: "جائزہ ہٹا دیا گیا!",
    tryAgainLater: "براہ کرم مہربانی کرکے بعد میں کوشش کریں",
    deleteReview: "جائزہ ہٹائیں",
    replyTo: "{name} کو جواب دیں...",
    writeAComment: "تبادلہ خیال لکھیں...",
    deleteComment: "تبادلہ خیال ہٹائیں؟",
    commentDeleted: "تبادلہ خیال ہٹا دیا گیا ہے!",
    edited: "ترتیب دی گئی",
    like: "پسند کریں",
    liked: "پسند کیا گیا!",
    unLiked: "پسند نہیں کیا گیا!",
    reply: "جواب دیں",
    comment: "تبادلہ خیال",
    addPhotos: "تصویریں شامل کریں",
    mostRecent: "سب سے حد تازہ",
    mostLiked: "سب سے زیادہ پسندیدہ",

    // Project Summary Page
    projectSummary: "پروجیکٹ کا خلاصہ",
    projectSummaries: "پروجیکٹ کے خلاصے",
    projectSummaryReport: "پروجیکٹ کا خلاصہ رپورٹ",
    backProject: "پروجیکٹ پر واپس جائیں",
    congrats: "مبارک ہو!",
    completedProject: "آپ نے پروجیکٹ مکمل کر لی ہے۔",
    photoAlbum: "فوٹو البم",
    rate: "اپنے تجربے کو درجہ بنائیں",
    howProjectShareExperience: "پروجیکٹ کیسا تھا؟ اپنے تجربے کا حصہ کریں!",
    beforeDownload: "ڈاؤن لوڈ کرنے سے پہلے",
    beforeGo: "جانے سے پہلے",
    recoProj: "تجویز کردہ پروجیکٹس",
    completedCP: "چیک پوائنٹس مکمل ہوگئے ہیں:",
    downloadReport: "رپورٹ ڈاؤن لوڈ کریں",
    openReport: "رپورٹ کھولیں",
    generatingReport: "رپورٹ جاری ہے",
    viewMore: "مزید دیکھیں",
    viewAll: "سب دیکھیں",
    reGenerateReport: "اب اپنی رپورٹ دوبارہ تخلیق کریں",
    getReport: "ابھی اپنی کسٹم رپورٹ ڈاؤن لوڈ کریں",
    photos: "تصاویر",
    videos: "ویڈیوز",
    totalNumberLessons: "کل درسوں کی تعداد",

    // Project Report PDF
    projectOverview: "پروجیکٹ کا جائزہ",
    presentedBy: "فخر سے پیش کرتا ہے:",
    congratsText: "پروجیکٹ کامیابی سے مکمل ہوگیا ہے",
    changeTemplate: "ٹیمپلیٹ تبدیل کریں",
    pickImage: "تصویر چنیں",
    pickTemplate: "ٹیمپلیٹ چنیں",

    // Backpack Page
    noenrolledChild: "آپ کے کوئی رجسٹر چائلڈرن نہیں ہیں",
    noBackpack: "کوئی بیک پیک آئٹم نہیں ہے",
    favourites: "پسندیدہ",
    addFavourites: "پسندیدہ میں شامل کریں",
    noFavourites:
      "یہاں کوئی چیزیں نہیں ہیں۔ ہماری لائبریری جائیں اور اپنے پسندیدہ منصوبے تلاش کریں!",

    // Analytic
    sevenDays: "آخری 7 دن",
    thirtyDays: "آخری 30 دن",
    oneYear: "1 سال",
    allTime: "سب وقت",

    //portfoliopage
    age: "عمر",
    year: "سال",
    years: "سال",
    month: "مہینہ",
    months: "مہینے",
    appraisal: "{name} کے لئے معلم کی جمعی تنخواہ",
    noAppraisal: "کوئی جمعی تنخواہ نہیں",
    presentGoals: "{name} کے لئے موجودہ مقصدات",
    goalHistory: "مقصدات کی تاریخ دیکھیں",
    noGoals: "کوئی مقصد نہیں",
    headProgress: "{name} کا {tab} پیشرفت",
    progressHistory: "پیشرفت کی تاریخ دیکھیں",
    latestAccom: "تازہ ترین کامیابیاں",
    accomHistory: "کامیابیوں کی تاریخ دیکھیں",
    improve: "بہتری کے لئے شعبہ",
    projectDone: "{name} کے ذریعے مکمل کردہ منصوبے",
    allDetails: "تمام تفصیلات دیکھیں",
    hideDetails: "تمام تفصیلات چھپائیں",
    pastProject: "تمام ماضی کے منصوبے دیکھیں",
    recoAct: "{name} کی بہتری کے لئے تجویز کردہ سرگرمیاں",
    noCompletedProject: "کوئی مکمل شدہ منصوبہ نہیں",
    downloadpdf: "پی ڈی ایف رپورٹ ڈاؤن لوڈ کریں",
    showLesson: "سبق دکھائیں",
    hideLesson: "سبق چھپائیں",
    portfolio: "پورٹفولیو",
    childPortfolio: "{name} کا پورٹفولیو",
    teacherNameArchive: "{name} کی آرکائیو",
    developmentalGoals: "ترقیاتی مقصدات",
    progressReport: "پیشرفت کی رپورٹ",
    completedWorks: "چھوٹے کام",
    createNew: "نیا بنائیں",
    selectDuration: "دوران منتخب کریں",
    names: "{name} کی",
    learningJourney: "تعلیمی سفر",
    classInfo: "کلاس کی معلومات",
    noChildAssigned: "کوئی بچہ کلاس میں مختصر نہیں ہے",
    noTeacherAssigned: "کوئی استاد کلاس میں مختصر نہیں ہے",
    observation: "مشاہدہ",
    addObservation: "مشاہدہ شامل کریں",
    milestoneCompleted: "مائل اہم مرحلہ",
    nextMilestone: "اگلا مائل اہم مرحلہ",
    whatsNext: "اگلا کیا ہے؟",
    takeAction: "عمل کریں!",
    noReflections: "ابھی تک کوئی عکس نہیں ہے، یہاں لکھو کیوں نہیں؟",
    noTestimonials:
      "ابھی تک کوئی تعریف نہیں ہے، اپنے ہم راستوں سے کسی سے لکھو کیوں نہیں؟",
    noTestimonialsVisitor: "ابھی تک کوئی تعریف نہیں ہے، یہاں لکھو کیوں نہیں؟",
    noCertifications:
      "ابھی تک کوئی تصدیقات نہیں ہیں، چلو ٹیچر ٹریننگ اکیڈمی کی طرف چلیں اور تصدیق حاصل کریں!",
    visible: "ظاہر",
    hidden: "پوشیدہ",
    attained: "حاصل شدہ",
    notAttained: "نہیں حاصل",
    published: "شائع ہوا",
    unPublished: "شائع نہیں ہوا",
    dateObserved: "مشاہدہ کی تاریخ",
    deleteObservation: "مشاہدہ حذف کریں",
    reachHighestMilestone:
      "مبارک ہو! آپ نے اس ترقیاتی مقصد میں بلند ترین مائل اہم مرحلہ حاصل کیا ہے۔",
    findNewProject: "ہماری لائبریری میں نیا منصوبہ تلاش کریں!",
    parentView: "والدین کا منظر",
    whatIsParentView: "والدین کا منظر کیا ہے",
    nextMilestoneIsHidden: "اگلا مائل اہم مرحلہ چھپا ہوا ہے",
    blankSubDevGoalAreHidden: "خالی زیرہ ترقیاتی مقصد چھپے ہوئے ہیں",
    albums: "تصویریں",
    addNewAlbum: "نیا البم شامل کریں",
    noAttainedMilestone: "کوئی حاصل کردہ مائل اہم مرحلہ نہیں ہے!",
    congratulation: "مبارک ہو!",
    milestoneCompletedCongratulation:
      "ہورے! آپ نے اپنے مائل اہم مرحلے کو حاصل کر لیا ہے!",
    showDevGoalWithRecordedLearningMoment:
      " 'والدین کا منظر' کو چالو کریں تاکہ صرف وہ ترقیاتی مقصد دکھایا جائے جس میں ریکارڈ کردہ سیکھنے کے لمحے ہیں!",
    filterAndShowParent:
      "آپ یہ بھی چن سکتے ہیں کہ والدین کے لمحے یومیہ ہونے والے وقت کے دوران فلٹر اور دکھائیں۔",
    learningStories:"سیکھنے کی کہانیاں",

    //Portfolio PDF
    selectProgressReportDateRange:
      "براہ کرم متناسب گزارش کی تاریخ کا دائرہ وار کریں۔",
    introductionText: `تشخیصی پورٹفولیو چھوٹے پروجیکٹس، ہنر کا ٹیکنیکی منصوبے، اور روبیکس کو جمع کرتا ہے تاکہ ہمارا بچوں کو مرکوز، مضبوط بنیادوں پر مبنی تعلیمی نظام کو تسلیم کرنے میں مدد ملے۔ جبکہ تصاویر اور ہنر کی مختصر ریکارڈات بچے کی ہاتھ اور دل کو کس طرح پرورش دیتی ہیں، وہیں ایک ماہریت روبیکس بچے کے حصولات کو سکول کے فہم میں معیاروں کے ساتھ ملاتا ہے۔ مختلف ذرائع اور مختلف حکمت عملی کے ذریعے، طلباء کی تعلیم ظاہر ہوتی ہے، اور ان کے کامیابی کا جشن منایا جاتا ہے۔`,
    littleProjectsAccomplished: "چھوٹے منصوبے مکمل ہوگئے",
    littleLearnersAccomplished: "چھوٹے سیکھنے والے مکمل ہوگئے",
    photoGallery: "فوٹو گیلری",
    startedOn: "شروع ہوا",
    completedOn: "مکمل ہوا",
    observedOn: "مشاہدہ ہوا",
    profileShowcased:
      "{profileName} نے یہ قیمتیں یہاں یہ لمحے سیکھاتے وقت دکھائیں ہیں:",
    profileDemonstrates:
      "ایک ٹریہاؤس بچہ کے طور پر، {profileName} ہمارے نصاب کے اس حصے میں اہلیت دکھاتا ہے، جس میں سب سے عظیم حصولات یہ ہیں:",
    concludingRemarks: "ختم ہونے والی تنبیہات",
    sneakPeek: "جھلک",
    goals: "مقصد",
    thankYouBeingJourney:
      "آپ کا شکریہ کہ آپ میرے سفر کا حصہ ہیں {className} {year}!",
    myTeachers: "میرے استاذاء",

    // Create child page
    preferred_name: "ترجیحی نام",
    full_name: "پورا نام",
    dob: "تاریخ پیدائش",
    image: "تصویر",
    submit: "جمع کرائیں",
    not_logged_in: "آپ لاگ ان نہیں ہیں!",

    // Class Page -> Group === Class
    group_name: "کلاس کا نام",
    create_group: "کلاس بنائیں",
    addGroup: "کلاس شامل کریں",
    edit_group: "کلاس میں ترتیب دیں",
    group: "کلاس",
    assignChildrenToGroup: "بچوں کو کلاس میں تفویض کریں",
    childrenInGroup: "کلاس میں بچے",
    assignTeacherToGroup: "استاد کو کلاس میں تفویض کریں",
    teacherInGroup: "کلاس میں اساتذہ",

    // Enroll Child Page
    child: "بچہ",
    project: "پروجیکٹ",
    enroll_child: "پروجیکٹ میں بچے کو رجسٹر کریں",
    module: "ماڈیول",
    unit: "یونٹ",

    // Project Page - Edit + Create
    editing: "ترتیب دیں",
    managing_proj: "مینجمنٹ پروجیکٹ",
    projectEditor: "پروجیکٹ میں ترتیب دیں",
    create_new_proj: "نیا پروجیکٹ بنائیں",
    create_project: "پروجیکٹ بنائیں",
    create_assessment: "نیا اندازی",
    proj_for_child: "بچوں کے لئے پروجیکٹ",
    sproutsExplainationPartOne: "عام طور پر 18 ماہ تا 2 سال کے لئے،",
    sproutsExplainationPartTwo:
      "بچے دنیا کے گرد سے نئی معلومات حاصل کرنا شروع ہو رہے ہیں۔",
    budsExplainationPartOne: "عام طور پر 3 سے 4 سال کے لئے،",
    budsExplainationPartTwo:
      "بچے کچھ علم حاصل کرتے ہیں اور نئی مہارتیں سیکھتے ہیں تاکہ یہ نئی معلومات کو لاگو کریں۔",
    blossomsExplainationPartOne: "عام طور پر 5 سے 6 سال کے لئے،",
    blossomsExplainationPartTwo:
      "ان کے پاس علم اور مہارتیں ہیں اور وہ انہیں فعالیت سے استعمال اور مشق کر رہے ہیں۔",

    sprouts: "چھوٹے پودے",
    sprouts_with_age: "چھوٹے پودے (18 ماہ - 2 سال)",
    sproutlings_with_age: "چھوٹے پودے (2 سال - 3 سال)",
    buds_with_age: "پودے (3 سال - 4 سال)",
    blossoms_with_age: "پھول (5 سال - 6 سال)",
    blooms_with_age: "پھول (5 سال - 6 سال)",
    sprouts_with_age_external: "چھوٹے پودے (18 ماہ - 2 سال)",
    blossoms_with_age_external: "پھول (5 سال - 6 سال)",
    programme: "پروگرام",
    proj_name: "پروجیکٹ کا نام",
    level: "سطح",
    basic: "بنیادی",
    intermediate: "متوسط",
    advanced: "اعلی",
    proj_duration: "متوقع مدت (منٹ)",
    proj_description: "پروجیکٹ کا تفصیلی تفصیل",
    learning_objectives: "سیکھنے کے اہم مقصد",
    publish: "شائع کریں",
    saveAsDraft: "ڈرافٹ کے طور پر محفوظ کریں",
    recall: "یاد کریں",
    recalled: "یاد دھیان",
    success_remove_project: "پروجیکٹ کو کامیابی کے ساتھ ہٹا دیا گیا!",
    success_remove_unit_guide: "ریسورس گائیڈ کو کامیابی کے ساتھ ہٹا دیا گیا!",
    projectTags: "پروجیکٹ ٹیگز",
    learningObjectives: "سیکھنے کا مقصد",
    createAndManageModules: "ماڈیولز بنائیں اور مینج کریں",
    addObjective: "مقصد شامل کریں",

    // Lessons
    activity: "مشغل",
    activity_name: "مشغل کا نام",
    add_activity: "مشغل شامل کریں",
    success_remove_activity: "مشغل کامیابی کے ساتھ ہٹا دیا گیا!",
    activity_duration_min: "مشغل کا وقت (منٹ)",
    attachment_if_any: "منسلکہ (اگر کوئی ہو)",
    attachment_if_copy: `منسلکہ (منسلکہ کو "دستاویزات" میں شامل کرنے کے لیے خانے کو چیک کریں)`,
    add_attachement_into_doc: `دستاویزات میں منسلکہ شامل کریں`,
    attachments: "منسلکہ",
    lesson_name: "سبق کا نام",
    lesson_description: "سبق کا تفصیلی تفصیل",
    lesson_objective: "سبق کا مقصد",
    lesson_objectives: "سبق کے مقاصد",
    addLessonObjective: "سبق کا مقصد شامل کریں",
    lesson_intention: "سبق کا ارادہ",
    lessonToolbox: "سبق ٹول باکس",
    expected_duration_mins: "متوقع مدت (منٹ)",
    key_vocabulary: "کلیدی الفاظ",
    material: "مواد",
    materials: "مواد",
    materials_required: "مطلوبہ مواد",
    recommendedSetupInstructionIfAny:
      "موصول ہونے والے ترتیبات کا خیال (اگر ہو)",
    setupInstruction: "ترتیبات کا ہدایت",
    image_set: "تصویر سیٹ",

    // Activity
    notes: "نوٹس",
    stickyNote: "اسٹکی نوٹ",
    activityStepInstruction: "مرحلہ {weight} ہدایت:",
    activityStep: "مرحلہ {weight}",
    editing_activity: "مشغلہ میں ترتیب دیں",
    creating_activity: "مشغلہ بناتے ہوئے",
    remark: "تنقید",
    remarks: "تنقیدات",
    compulsory: "لازمی",
    cancel: "منسوخ کریں",
    skip: "چھوڑیں",
    create: "بنائیں",
    delete_activity: "مشغلہ حذف کریں",
    success_reorder_activities: "مشغلے کو دوبارہ ترتیب دینے میں کامیاب!",
    success_move_activity: "مشغلہ کو کامیابی سے منتقل کریں!",
    question: "سوال",

    //Checkpoint
    checkpoint: "چیک پوائنٹ",

    //Learning Moment
    isLearningMoment: "کیا کوئی سیکھنے کا لمحہ ہے؟",
    learningMomentPhoto: "سیکھنے کا لمحہ فوٹو",
    learningMomentQuestion: "سیکھنے کا لمحہ سوال",

    //Objective
    adding_curriculum_fundamental_to: "میں درسی بنیاد ڈال رہا ہوں",
    editing_curriculum_fundamental_of: "تدوین درسی بنیاد",
    objective_str: "مقصد",
    add_objective: "مقصد شامل کریں",
    delete_fundamental: "بنیاد ہٹائیں",
    fundamental_str: "بنیاد",

    //Login
    forgottenPassword: "پاسورڈ بھول گئے ہیں؟",
    findYourAccount: "اپنا اکاؤنٹ تلاش کریں",
    enterYourEmail:
      "براہ کرم آپنا ای میل درج کریں تاکہ آپکا اکاؤنٹ تلاش کیا جا سکے۔",
    pleaseCheckYourEmailAndPassword:
      "براہ کرم آپنا ای میل اور پاسورڈ چیک کریں کہ کے درست ہیں۔",
    emailSubmittedTitle: "پاسورڈ ری سیٹ درخواست دی گئی",
    emailSubmittedResponse:
      "اگر آپکا فراہم کردہ ای میل ہمارے نظام میں موجود ہے تو ہم نے ایک لنک بھیج دیا ہے تاکہ آپ اپنا پاسورڈ ری سیٹ کر سکیں۔ براہ کرم آپنے ان باکسز اور اسپیم فولڈرز چیک کریں۔",

    //Register
    email: "ای میل",
    mobile_number: "موبائل نمبر",
    password: "پاسورڈ",
    already_have_account_login: "پہلے ہی اکاؤنٹ ہے؟ لاگ ان",
    role: "کردار",

    //Reset
    resetYourPassword: "اپنا پاسورڈ دوبارہ ترتیب دیں",

    //Admin Page
    add: "شامل کریں",
    actions: "عمل",
    siteAdmin: "سائٹ ایڈمن",
    contentEditor: "مواد ایڈیٹر",
    ttaContentEditor: "ٹی ٹی اے مواد ایڈیٹر",
    schoolAdmin: "اسکول ایڈمن",
    paymentRecord: "ادائیگی ریکارڈ",

    //Questionnaire
    createInterest: "شوق بنائیں",
    createPreDQQuestion: "پہلے ڈسکووری کوئیسٹنئر سوالات بنائیں",
    pickLanguage: "اپنی زبان کا انتخاب کریں",
    selectModules: `وہ ماڈیول منتخب کریں جنہیں آپ سکھا رہے ہیں`,
    selectDevelopmentalAreas: `اہم ترین ترتیبات منتخب کریں (تجویز: ٹاپ 5)`,
    selectInterests: `وہ شوق منتخب کریں جو کے {groupName} کلاس کے لیے سب سے زیادہ دلچسپ ہیں`,
    weight: "ترتیب",
    name: "نام",
    priority: "ترجیح",

    //Quick questions
    welcomeToTTB: "ہمارے ٹیچنگ ٹول باکس میں خوش آمدید!",
    everyChildIsUnique: "ہر بچہ یکتا ہے",
    introFirstParagraph:
      "ٹریہاؤس کریکولم م میں ہر شاگرد کے لیے دلچسپ، معنی خیز اور مختلف درس فراہم کرنے کا عہد ہے۔ ہر بچہ یکتا ہوتا ہے اور اپنی رفتار پر ترقی حاصل کرتا ہے، لہذا ہم نے تین تعلمی حصے تخلیق کئے ہیں جو اساتذہ کو ان کے بچوں کی موجودہ سیکھنے کی حالت کیساتھ سبقات چننے میں مدد فراہم کرتے ہیں۔",
    sproutsDescription:
      "عام طور پر 18 ماہ سے 2 سال کے بچوں کے لیے، سپروٹس چلچلاتے ہوئے دنیا کو سیکھ رہے ہیں، اپنے جڑوں کو بڑھا رہے ہیں اور نئی معلومات حاصل کرنے میں مصروف ہیں۔",
    budsDescription:
      "عام طور پر 3 سے 4 سال کے بچوں کے لیے، بڈس چلچلاتے ہوئے معلومات کو جذب کر رہے ہیں اور اسے اپنے نکلتے ہوئے ہنر میں مدد فراہم کرنے کے لیے استعمال کر رہے ہیں۔",
    blossomsDescription:
      "عام طور پر 5 سے 6 سال کے بچوں کے لیے، بلاسمس چلچلاتے ہوئے معلومات سے بھرپور ہیں اور اسے فعالیت سے اپنے نئے ہنر ترقی دے رہے ہیں۔",
    introLastParagraph:
      "ہمارے سبق منصوبہ کتاب خانہ کو براؤز کرنے سے پہلے، براہ کرم یہ فیو منٹ لیں کہ آپ ان تیز سوالات کا جواب دیں تاکہ ہم آپ کو آپ کے بچے کی موجودہ نشوونما کے مطابق منصوبے تجویز کر سکیں۔",
    myChildInterests: "میرے بچے کو... میں دلچسپ ہے",
    myChildInterestsSub: " (جتنے چاہیں چنیں!)",
    myChildGoals: "میرے بچوں کو... سے فائدہ ہوسکتا ہے",
    myChildGoalsSub: " (وہ اہم مقصد چنیں جن پر آپ کام کرنا چاہتے ہیں!)",
    myChildCommunicationMethod:
      "میرے بچے یہ استعمال کرتے ہیں... سے مواصلہ کرنا",
    myChildCommunicationMethodSub:
      " (زبان کے بناۓ؟ جو بھی چاہتے ہیں انہیں چنیں!)",
    concludingTextFirst:
      "عظیم! آپ کے جوابوں کے مطابق... آپ کے بچے {0} سطح پر ہیں۔",
    concludingTextLast:
      "ہمارے سبق منصوبہ کتاب خانہ پر جائیں تاکہ ہم آپ کے لیے کون سا مزیدار منصوبہ تجویز کرتے ہیں۔",
    quickQuestionsCompleted: "تیز سوالات مکمل ہوگئے ہیں!",
    levelAnswer1: "اشارے اور آواز",
    levelAnswer2: "ایک سے دو لفظ",
    levelAnswer3: "مختصر جملے",
    levelAnswer4: "سادہ جملے",
    levelAnswer5: "تفصیلی جملے",
    loading: "لوڈ ہو رہا ہے",
    intro: "آغاز",

    //Access Control
    school: "اسکول",
    schools: "اسکولز",
    search: "تلاش کریں",
    bulkAction: "بلک کارروائی",
    editProject: "پروجیکٹ میں ترتیب دیں",

    //Manage project page
    startDate: "شروع ہونے کی تاریخ",
    endDate: "ختم ہونے کی تاریخ",

    //Ticketing
    subject: "موضوع",
    itSupportFeedback: "آئی ٹی حمایت - فیڈبیک",
    feedback: "فیڈبیک",
    next: "اگلا",

    //Payments
    payment: "ادائیگی",
    subscription: "سبسکرائب",
    subscriptions: "سبسکرائبشنز",
    paymentHistory: "ادائیگی کی تاریخ",
    noSubscribe: "ابھی تک کوئی مصنوعہ سبسکرائب نہیں!",
    noProduct: "اس مصنوعہ کے لئے مترقب رہیں!",
    paymentTitleSchool: "اپنے اسکول کے لئے صحیح منصوبہ منتخب کریں",
    paymentTitleParent: "اپنے خاندان کے لئے صحیح منصوبہ منتخب کریں",
    selectPlan: "منصوبہ منتخب کریں",
    startFreeTrial: "7 دنوں کا مفت ٹرائیل شروع ہو گیا",
    sevenDayTrial: "7 دنوں کی ٹرائیل",
    manageOrUpdatePlan: "منصوبہ کا منظور یا اپ ڈیٹ کریں",
    paymentTitle: "صحیح منصوبہ منتخب کریں",
    subscribedProduct: "سبسکرائب کردہ مصنوعہ",
    onlySchoolAdmin:
      "براہ کرم اسکول ایڈمن کے طور پر لاگ ان ہوکر سبسکرائبشن منصوبہ دیکھیں!",
    subscribed: "سبسکرائب ہوگیا",
    noRenew: "نیا کرائیں نہیں",
    willRenew: "نیا کرائے گا",
    noProject: "کوئی پروجیکٹ نہیں ملی",
    pleaseSubscribe:
      "براہ کرم ہمارے منصوبے کا منصوبہ یا مزید معلومات کے لئے ایڈمنز سے رابطہ کریں",
    addPaymentRecord: "ادائیگی کا ریکارڈ ڈالیں",
    canViewProjects: "منصوبے دیکھ سکتے ہیں",
    createPaymentRecord: "ادائیگی کا ریکارڈ بنائیں",
    customerId: "کسٹمر آئی ڈی",
    bypassPayment: "ادائیگی کو چھوڑیں",
    demoSchool: "ڈیمو اسکول",
    subscribeToViewProject: "منصوبہ دیکھنے کے لئے سبسکرائب ہوں",
    oopsDemoAccount:
      "اوہو! یہ ایک ڈیمو اکاؤنٹ ہے۔ پورے تجربے کے لئے LittleLab.com پر سائن اپ کریں!",

    //Calendar
    calendarViewWeek: "ہفتہ",
    calendarViewMonth: "مہینہ",
    calendarViewYear: "سال",
    january: "جنوری",
    february: "فروری",
    march: "مارچ",
    april: "اپریل",
    may: "مئی",
    june: "جون",
    july: "جولائی",
    august: "اگست",
    september: "ستمبر",
    october: "اکتوبر",
    november: "نومبر",
    december: "دسمبر",

    //Timetable
    timetable: "ٹائم ٹیبل",
    today: "آج",
    thisWeek: "اس ہفتہ",
    editTimetable: "ٹائم ٹیبل میں ترتیب دیں",
    addNewEvent: "نیا واقعہ شامل کریں",
    editEvent: "واقعہ ترتیب دیں",
    discardEdits: "ترتیبات کو رد کریں",
    saveChanges: "ترتیبات محفوظ کریں",
    noChanges: "کوئی ترتیبات نہیں",
    timetableUpdated: "ٹائم ٹیبل کو اپ ڈیٹ کیا گیا ہے!",
    eventTitle: "واقعہ کا عنوان",
    eventType: "واقعہ کی قسم",
    dayOfWeek: "ہفتے کا دن",
    startingTime: "شروع ہونے کا وقت",
    endingTime: "ختم ہونے کا وقت",
    timetableTitle: "{name} کا ٹائم ٹیبل",
    shareTimetable: "تعلیمی ٹائم ٹیبل کا تبادلہ؟",
    copyShareLink: "تبادلہ لنک کا کاپی",
    shareLinkCopied: "تبادلہ لنک کاپی ہوگئی!",
    timetableShared: "لنک کے ساتھ کوئی بھی آپ کا ٹائم ٹیبل دیکھ سکتا ہے۔",
    timetableUnshared: "صرف آپ ہی اپنا ٹائم ٹیبل دیکھ سکتے ہیں۔",
    defaultTimetableButtonTitle:
      "ہمارے وجدانی ٹائم ٹیبل کے ساتھ اپنی کلاس کی منصوبہ بنائیں!",
    uploadedTimetableButtonTitle:
      "اپنی حوصلے کے لئے اپنی خود کی منصوبہ بنائیں!",
    uploadNewTimetable: "نیا ٹائم ٹیبل اپ لوڈ کریں",
    removeTimetable: "ٹائم ٹیبل ہٹائیں",
    noUploadedTimetable: "کوئی اپ لوڈ نہیں ہوا ٹائم ٹیبل",
    uploadYourTimetable: "اپنا ٹائم ٹیبل کی تصویر اپ لوڈ کریں",
    uploading: "اپ لوڈ ہو رہا ہے",

    successAddEvent: "نیا واقعہ کامیابی سے شامل ہوا!",
    failAddEvent: "نیا واقعہ شامل کرنے میں ناکامی! براہ کرم دوبارہ کوشش کریں!",
    successUpdateEvent: "واقعہ کامیابی سے اپ ڈیٹ ہوا!",
    failUpdateEvent: "واقعہ اپ ڈیٹ کرنے میں ناکامی! براہ کرم دوبارہ کوشش کریں!",
    successDeleteEvent: "واقعہ کامیابی سے حذف ہوا!",
    failDeleteEvent: "واقعہ حذف کرنے میں ناکامی! براہ کرم دوبارہ کوشش کریں!",
    successDuplicateEvent: "واقعہ کامیابی سے ڈپلیکیٹ ہوا!",
    failDuplicateEvent:
      "واقعہ ڈپلیکیٹ کرنے میں ناکامی! براہ کرم دوبارہ کوشش کریں!",
    successUploadTimetable: "ٹائم ٹیبل کامیابی سے اپ لوڈ ہوا!",
    failUploadTimetable:
      "ٹائم ٹیبل اپ لوڈ کرنے میں ناکامی! براہ کرم دوبارہ کوشش کریں!",
    successUpdateTimetable: "ٹائم ٹیبل کامیابی سے اپ ڈیٹ ہوا!",
    failUpdateTimetable:
      "ٹائم ٹیبل اپ ڈیٹ کرنے میں ناکامی! براہ کرم دوبارہ کوشش کریں!",
    successDeleteTimetable: "ٹائم ٹیبل کامیابی سے حذف ہوا!",
    failDeleteTimetable:
      "ٹائم ٹیبل حذف کرنے میں ناکامی! براہ کرم دوبارہ کوشش کریں!",
    noEventToday: "آج کوئی واقعہ نہیں",

    // Chat
    messages: "پیغامات",
    newChat: "نیا چیٹ",
    newClassChat: "نیا کلاس چیٹ",
    selectContact: "رابطہ منتخب کریں",
    startANewChat: "نیا چیٹ شروع کریں",
    startANewClassChat: "نیا کلاس چیٹ شروع کریں",
    typeYourMsgHere: "یہاں اپنا پیغام ٹائپ کریں...",
    noNewMessage: "کوئی نیا پیغام نہیں",

    // Developmental Goal
    manageDevelopmentalGoals: "ترقیاتی مقصد کا منظار رکھیں",
    developmentalGoal: "ترقیاتی مقصد",
    subDevelopmentalGoal: "ذیلی ترقیاتی مقصد",

    addDevelopmentalGoal: "{tab} ترقیاتی مقصد شامل کریں",
    editingDevelopmentalGoal: "ترقیاتی مقصد میں ترتیب دیں",
    deleteDevelopmentalGoal: "ترقیاتی مقصد کو ہٹا دیں",
    createdDevelopmentalGoal: "ترقیاتی مقصد کامیابی سے بنایا گیا!",
    updatedDevelopmentalGoal: "ترقیاتی مقصد کامیابی سے اپ ڈیٹ ہوگیا!",
    removedDevelopmentalGoal: "ترقیاتی مقصد کامیابی سے ہٹا دیا گیا!",

    addSubDevelopmentalGoal: "{tab} ذیلی ترقیاتی مقصد شامل کریں",
    editingSubDevelopmentalGoal: "ذیلی ترقیاتی مقصد میں ترتیب دیں",
    deleteSubDevelopmentalGoal: "ذیلی ترقیاتی مقصد کو ہٹا دیں",
    createdSubDevelopmentalGoal: "ذیلی ترقیاتی مقصد کامیابی سے بنایا گیا!",
    updatedSubDevelopmentalGoal: "ذیلی ترقیاتی مقصد کامیابی سے اپ ڈیٹ ہوگیا!",
    removedSubDevelopmentalGoal: "ذیلی ترقیاتی مقصد کامیابی سے ہٹا دیا گیا!",

    // Still Finding Out
    stillFindingOut: "ہمیں مزید معلومات حاصل ہو رہی ہے!",
    stillFindingOutHeadLibrary:
      "یہاں کوئی تعلیمی لمحے ریکارڈ نہیں ہیں... لائبریری جائیں اور ایک منصوبہ شروع کریں اور تعلیمی لمحے بنائیں!",
    noObservations: "کوئی مشاہدات ریکارڈ نہیں ہوئیں",
    noCaption: "کوئی کیپشن نہیں",
    title: "عنوان",
    subtitle: "ذیلی عنوان",
    milestone: "مائلسٹون",
    successReorderMilestone: "مائلسٹون کو موفقیت سے دوبارہ ترتیب دی گئی ہے!",
    addMilestone: "مائلسٹون شامل کریں",
    deleteMilestone: "مائلسٹون حذف کریں",
    milestoneNo: "مائلسٹون {number}",
    photoInstruction: "تصویر ہدایت",
    photoCaption: "تصویر کی کیپشن",
    sixMonthNoObservation:
      "آپ کی آخری مشاہدہ کوئی وقت ہو گیا ہے... ہمیں دیکھنے کے لئے ایک اور تعلیمی لمحہ کریں تاکہ کچھ ترقی ہوسکے!",

    // Prohibited Word
    prohibitedWords: "ممنوعہ الفاظ",
    manageProhibitedWords: "ممنوعہ الفاظ کا منظار رکھیں",
    addProhibitedWords: "ممنوعہ الفاظ شامل کریں",

    // Completed Work
    addCompletedWork: "چھوٹا کام شامل کریں",
    uploadChildrenCompletedWork: "بچوں کا مکمل ہونے والا کام اپ لوڈ کریں",

    // Teacher Portfolio
    byClass: "کلاس کے ذریعے",
    byYear: "سال کے ذریعے",
    byProject: "پروجیکٹ کے ذریعے",

    // WIP
    underConstruction: "اوہو! یہ صفحہ ابھی تک تعمیر میں ہے. رہیں بھریں!",

    // Parent Dashboard
    nowLearning: "ابھی سیکھ رہے ہیں",

    // Other Components
    updateBackpack: "بیک پیک کو اپ ڈیٹ کریں",
    updateFavorite: "پسندیدہ کو اپ ڈیٹ کریں",
    templateFor: "کے لئے ٹیمپلیٹ",
    activitiesToBeConducted: "کرنے کے لئے گتھ",
    loadingDots: "لوڈ ہو رہا ہے...",
    youNeedToEnrollYourGroup:
      "آپ کو اپنے گروہ کو انرول کرنا ہوگا تاکہ دسترس حاصل ہو سکے",
    selectDropAttachments: "الیکٹرانکس چنیں یا ڈراپ کریں",
    selectDropActivity: "سیلیکٹ یا ڈراپ کریں ایکٹوٹھم تھمنیل",
    selectDropLesson: "سیلیکٹ یا ڈراپ کریں لیسن تھمنیل",
    translateThisLessonTo: "اس سبق کا ترجمہ کریں",
  },

  //FRENCH
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  fr: {
    //General
    updated: "Mis à jour",

    //LandingPage
    whereKidsLoveLearning: "Là où les enfants aiment apprendre",
    findOutMore: "En savoir plus",
    takeAPeek:
      "Jetez un coup d'œil à nos petits projets dédiés à l'éducation des futurs change-makers",
    getAccessToLittleLab: "Accédez au Petit Lab",
    everyChildIsDifferent: "Chaque enfant est unique !",
    comeAndExperienceIndividualized:
      "Venez vivre une expérience d'apprentissage individualisée adaptée à",
    your: "votre",
    childSmall: "enfant",
    createAUniqueLearningExperience:
      "Créez une expérience d'apprentissage unique dès aujourd'hui",
    greatContentCreatedBy:
      "Un excellent contenu créé par des experts de l'éducation précoce basé sur les dernières recherches et méthodes pédagogiques",
    learnMore: "En savoir plus",
    superEasyAndIntuitive:
      "Super facile et intuitif à utiliser que vous soyez parent ou enseignant.",
    perfectEvenFor: "Parfait même pour",
    homeSchoolers: "les parents enseignants à domicile",
    andSmall: "et",
    learningCommunities: "communautés d'apprentissage",
    discoverTheFutureOfEducation: "Découvrez l'avenir de l'éducation",
    ourProprietaryTrehaus:
      "Notre programme exclusif Trehaus Little Lab comprend 6 compétences futures et douces",
    ourLittleLearners: "Nos petits apprenants",
    enrichYourChild:
      "Enrichissez les connaissances de votre enfant grâce à des leçons entièrement structurées et à son propre rythme, conçues pour engager votre enfant dans des activités significatives et réfléchies en littératie et numératie.",
    hearWhatOtherParents:
      "Écoutez ce que disent les autres parents et éducateurs",
    weSimplyLove:
      "Nous adorons simplement l'approche axée sur les valeurs de l'éducation défendue par Trehaus. Ce n'est pas simplement une école maternelle, c'est une communauté forte basée sur des valeurs incroyables. Je recommande vivement Trehaus.",
    confidence: "Confiance",
    empathy: "Empathie",
    creativity: "Créativité",
    perseveranceGrit: "Persévérance et courage",
    communicationSkills: "Compétences en communication",
    selfRegulation: "Autorégulation",
    integrity: "Intégrité",
    executiveFunction: "Fonction exécutive",
    steam: "STEAM",
    englishLanguageLiteracy: "Littératie en langue anglaise",
    mandarinLanguageLiteracy: "Littératie en langue mandarine",
    numeracyMathConcepts: "Concepts numériques et mathématiques",
    grossMotorSkills: "Compétences motrices globales",
    fineMotorSkills: "Compétences motrices fines",
    gratitudeGivingBack: "Gratitude et retour",
    environmentalResponsibility: "Responsabilité environnementale",
    socialAwareness: "Conscience sociale",
    publicSpeakingAdvocacy: "Prise de parole en public et plaidoyer",
    interdependence: "Interdépendance",
    practicalLifeSkills: "Compétences pratiques pour la vie",
    globalPerspectives: "Perspectives mondiales",
    littleCreative: "Petit Créatif",
    littleCeo: "Petit PDG",
    littleChef: "Petit Chef",
    littlePhilanthropist: "Petit Philanthrope",
    littleEngineer: "Petit Ingénieur",
    littleEntrepreneur: "Petit Entrepreneur",
    theLearningExperiencesDesigned:
      "Les expériences d'apprentissage conçues dans ce programme sont pertinentes pour la vie quotidienne de votre enfant, leur fournissant un contexte intentionnel pour développer des connaissances et des compétences en langue et littératie mandarines.",
    littleJournalist: "Petit Journaliste",
    exposeYourChildToKnowledge:
      "Exposez votre enfant à la connaissance de la langue anglaise et de la littératie de manière amusante et engageante. Le Petit Journaliste propose des expériences d'apprentissage multisensorielles uniques pour intégrer tous les aspects de la littératie pour votre enfant.",
    littleMathematician: "Petit Mathématicien",
    littleMathematicianProvidesAnAuthentic:
      "Le Petit Mathématicien offre une expérience d'apprentissage authentique, amusante et pratique pour aider votre enfant à développer une base solide en mathématiques et en pensée logique, étape par étape, développant et perfectionnant leur capacité à penser de manière mathématique et à résoudre des problèmes.",
    trehausSchoolMomHelle: "Maman de l'école Trehaus, Helle",
    lauraIsAlwaysExcited:
      "Laura est toujours excitée d'aller en classe ! Merci de l'encourager dans son indépendance, d'inculquer des valeurs de vie importantes et de rendre l'apprentissage absolument amusant. Notre esprit est tranquille pendant que nous travaillons, grâce à votre amour et à vos soins.",
    trehausSchoolMomRaine: "Maman de l'école Trehaus, Raine",
    jaydenIsReallyLucky:
      "Jayden a vraiment de la chance d'avoir des enseignants doux et attentionnés, qui douchent toujours les enfants d'amour et de préoccupations. Jayden les adore ! Envoyer Jayden à l'école Trehaus et travailler à Trehaus est vraiment la meilleure décision que nous ayons prise !",
    trehausSchoolMomOlivia: "Maman de l'école Trehaus, Olivia",
    myBiggestSatisfactionIsSeeing:
      "Ma plus grande satisfaction est de voir mon fils Nate prospérer et grandir à Trehaus, il aime tellement l'école, et j'adore comment je peux travailler au Business Club à quelques pas, et faire partie de ces précieuses premières années de sa vie.",
    trehausSchoolMomElaine: "Maman de l'école Trehaus, Elaine",
    incrediblyDedicatedAndNurturing:
      "Personnel enseignant incroyablement dévoué et attentionné ainsi que le directeur de l'école Trehaus. J'ai été absolument impressionné par leurs rapports de développement et par la quantité d'efforts déployés pour articuler les progrès de notre fille à l'école.",
    trehausSchoolDadStephen: "Papa de l'école Trehaus, Stephen",
    everyPersonInTheSchool:
      "Chaque personne dans l'école se soucie vraiment des enfants et traite tous les enfants comme les siens, donc je fais confiance que mon fils est entre de bonnes mains chaque jour quand je vais travailler.",
    trehausSchoolDadRodney: "Papa de l'école Trehaus, Rodney",

    //Dashboard
    totalChildren: "Total des enfants",
    totalTeachers: "Total des enseignants",
    totalClasses: "Total des classes",
    currentlyOn: "Actuellement en ligne",
    proTips: "Conseils de Pro : Tapez [] pour ajouter une tâche à faire",
    typeYourNoteHere: "Tapez votre note ici",
    message: "Message",
    timeTable: "Emploi du temps",
    jan: "Janv",
    feb: "Fév",
    mar: "Mar",
    apr: "Avr",
    jun: "Juin",
    jul: "Juil",
    aug: "Août",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Déc",
    teachers: "Enseignants",
    teacher: "Enseignant",
    children: "Enfants",
    total: "Total",
    viewClass: "Voir la classe",

    //Homepage
    welcome: "Bienvenue,",
    dashboard: "Mon tableau de bord",
    whoLearning: "Qui va apprendre aujourd'hui?",
    viewGroup: "Voir la classe",
    updateProfile: "Mettre à jour le profil",
    broadcastMsg: "Message en direct",
    allGroups: "Toutes les classes",
    noOngoingLesson: "Aucune leçon en cours",
    noLesson: "Aucune leçon",
    students: "Élèves",
    formTeacher: "Professeur principal",
    todayLessons: "Leçons du jour",
    tmrLessons: "Leçons à venir",
    lessonCompleted: "Leçon terminée",
    lessonsCompleted: "Leçons terminées",
    lessonConducted: "Leçon dispensée",
    lessonsConducted: "Leçons dispensées",
    resume: "Reprendre",
    resumeLesson: "Reprendre la leçon",
    goToLesson: "Aller à la leçon",
    newEnrollments: "Nouvelles inscriptions",
    lesson_number: "Leçon {number}",
    myClasses: "Mes classes",
    classDetail: "Détail de la classe",
    attendance: "Présence",
    currentLessons: "Leçons en cours",
    view: "Voir",
    myChildren: "Mes enfants",
    announcements: "Annonces",
    noAnnouncementYet: "Aucune annonce pour le moment",
    noActionRequiredYet: "Aucune action requise pour le moment",
    viewAllAnnouncements: "Voir toutes les annonces",
    showActiveAnnouncementsOnly: "Afficher uniquement les annonces actives",
    littleAlbum: "Petit album",
    mostRecentPhotos: "Photos les plus récentes",
    quickItems: "Éléments rapides",
    addTodo: "Ajouter une tâche à faire",
    notesUpdatedSuccess: "Notes mises à jour avec succès !",
    notesUpdatedFailed: "Échec de la mise à jour des notes !",
    addTodoInstruction:
      "Conseils de Pro : Tapez [] pour ajouter une tâche à faire !",
    typeYourNotesHere: "Tapez vos notes ici...",

    // MyChildrenPage
    viewPortfolio: "Voir le portfolio",
    bornOn: "Né le",
    parent: "Parent",

    // My Classes
    classList: "Liste de classe",
    editClass: "Modifier la classe",

    // Edit Class
    update: "Mettre à jour",

    // AnnouncementsPage
    createAnnouncement: "Créer une annonce",
    addNewAnnouncement: "Ajouter une nouvelle annonce",
    editAnnouncement: "Modifier l'annonce",
    deleteAnnouncement: "Supprimer l'annonce",
    announcementCreatedSuccess: "Annonce créée avec succès !",
    announcementCreatedFailed: "Échec de la création de l'annonce !",
    announcementUpdatedSuccess: "Annonce mise à jour avec succès !",
    announcementUpdatedFailed: "Échec de la mise à jour de l'annonce !",
    announcementRecalledSuccess: "Annonce rappelée avec succès !",
    announcementRecalledFail: "Échec du rappel de l'annonce !",
    announcementRecalled: "Cette annonce a été rappelée.",
    announcementDeletedSuccess: "Annonce supprimée avec succès !",
    announcementDeletedFailed: "Échec de la suppression de l'annonce !",
    announcementStartAt: "Annonce commencera à",
    announcementEndAt: "Annonce se terminera à",
    includesAttachment: "Comprend la ou les pièces jointes",
    pinAnnouncement: "Épingler l'annonce ?",
    anyResponseRequired: "Une réponse est-elle requise ?",
    responseRequired: "Réponse requise",
    responseQuestion: "Question de réponse",
    responseType: "Type de réponse",
    respondBy: "Répondre d'ici le",
    allowTextResponse: "Autoriser la réponse par texte ?",
    textResponse: "Réponse textuelle",
    textResponseInstruction: "Instruction de réponse textuelle",
    allowAttachmentUpload: "Autoriser le téléchargement de pièces jointes ?",
    attachmentInstruction: "Instruction de pièce jointe",
    responseOption: "Option de réponse",
    addResponseOption: "Ajouter une option de réponse",
    responseUpdated: "Réponse mise à jour !",
    responseUpdateFail:
      "Échec de la mise à jour de la réponse ! Veuillez réessayer plus tard",
    uploadDocument: "Télécharger le document",
    acknowledge: "Reconnaître",

    // Documents Page
    createDocument: "Créer un document",
    addNewDocument: "Ajouter un nouveau document",
    editDocument: "Modifier le document",
    deleteDocument: "Supprimer le document",
    document: "Document",
    documents: "Documents",
    noDocumentYet: "Aucun document pour le moment",
    pleaseUploadAtLeastOneDocument:
      "Veuillez télécharger au moins 1 document !",

    // BrowsePage
    noChild: "Vous n'avez aucun enfant inscrit",
    noGroup: "Vous n'avez aucune classe inscrite",
    noGroupAssigned:
      "Aucune classe ne vous est attribuée ! Veuillez contacter l'administrateur de l'école pour plus de clarifications",
    browse: "Parcourir tous les projets",
    noProjectFound: "Aucun projet trouvé",
    searchForProjects: "Rechercher des projets",
    recommendations: "Recommandations",
    displayAll: "Afficher tous les projets",
    specially: "Spécialement pour vous",
    specialDesc: "Notre meilleure expérience d'apprentissage individualisée",
    trending: "Projets tendance",
    trendDesc: "Découvrez ce qui est populaire sur The Little Lab",
    new: "Nouveauté !",
    newDesc: "Parcourez les derniers projets de notre équipe d'experts",
    byModule: "Par modules",
    byLevels: "Par niveaux",
    addToBackpack: "Ajouter au sac à dos",
    removeFromBackpack: "Retirer",
    addedToBackpack: "Ajouté au sac à dos !",
    viewProjectDetails: "Voir les détails du projet",
    viewAssessmentDetails: "Voir les détails de l'évaluation",
    addOrScheduleItToView: "Ajouter/Planifier pour voir !",

    // NavigationBar
    home: "Accueil",
    lessonLibrary: "Programme",
    library: "Bibliothèque de plans de cours",
    assessments: "Évaluations",
    backpack: "Sac à dos et favoris",
    visualTimetable: "Emploi du temps visuel",
    scheduler: "Planificateur",
    littleEvaluator: "Petit évaluateur",
    calendar: "Calendrier",
    myClass: "Ma classe",
    portfolios: "Portfolios",
    childrenPortfolio: "Portfolios des enfants",
    teacherPortfolio: "Archives de l'enseignant",
    notices: "Avis",

    // ProjectDetailPage
    lesson: "Leçon",
    lessons: "Leçons",
    saved: "Enregistré",
    notSaved: "Non enregistré",
    overview: "Aperçu",
    unitGuide: "Guide des ressources",
    plan: "Plans de leçon",
    assessmentPlans: "Plans d'évaluation",
    teachingMaterials: "Matériel pédagogique",
    communitySharing: "Partage communautaire",
    whatPeopleLike: "Ce que les gens aiment",
    tags: "Étiquettes",

    // Teaching Material
    getTeachingMaterials: "Obtenir des matériels de cours",
    downloadMaterials: "Télécharger les matériels",

    // ProjectMoment
    uploadPhoto: "Télécharger une photo",
    uploadPhotos: "Télécharger des photos",
    uploadVideos: "Télécharger des vidéos",
    uploadMedia: "Télécharger des médias",
    caption: "Légende",
    typeCaption: "Tapez une légende",
    addCaption: "Ajouter une légende",
    upload: "Télécharger",
    uploadFinalizing: "Finalisation en cours... Veuillez ne pas rafraîchir...",
    uploadPhotoFinalizing:
      "Finalisation des photos en cours... Veuillez ne pas rafraîchir...",
    uploadVideoFinalizing:
      "Finalisation des vidéos en cours... Veuillez ne pas rafraîchir...",
    noActivity: "Aucune activité",
    noPhoto: "Aucune photo pour le moment",
    failed_load_gallery: "Échec du chargement de la galerie !",
    failed_upload: "Échec du téléchargement !",
    failed_add_photos: "Échec de l'ajout de photos !",
    failed_add_videos: "Échec de l'ajout de vidéos !",
    failed_add_photos_videos: "Échec de l'ajout de photos ou de vidéos !",
    success_upload: "Téléchargé avec succès !",
    success_upload_photos: "Photos téléchargées avec succès !",
    success_upload_videos: "Vidéos téléchargées avec succès !",
    success_upload_photos_videos: "Photos et vidéos téléchargées avec succès !",
    success_update_image: "Mise à jour réussie !",
    success_delete_image: "Photo(s) supprimée(s) avec succès !",
    success_delete_video: "Vidéo(s) supprimée(s) avec succès !",
    success_delete_photo_video:
      "Photo(s) et Vidéo(s) supprimée(s) avec succès !",
    fail_delete_photo_video:
      "Échec de la suppression de Photo(s) et Vidéo(s) !",
    delete_photo: "Supprimer {number} photo(s) sélectionnée(s)",
    delete_video: "Supprimer {number} vidéo(s) sélectionnée(s)",
    delete_photo_video: "Supprimer {number} photo(s)/vidéo(s) sélectionnée(s)",
    selected: "Sélectionné",
    allPhotos: "Toutes les photos",
    allVideos: "Toutes les vidéos",
    allPhotosVideos: "Toutes les photos et vidéos",
    irreversibleAction: "L'action est irréversible !",
    tagChildren: "Taguer les enfants",
    profiles: "Profils",
    edit_image: "Modifier l'image",
    edit_video: "Modifier la vidéo",

    // Edit Image
    flipHoriz: "Retourner horizontalement",
    flipVerti: "Retourner verticalement",
    rotateAntiClockwise: "Faire pivoter dans le sens antihoraire",
    rotateClockwise: "Faire pivoter dans le sens horaire",

    // LessonDetailPage
    begin: "Commençons !",
    scheduleProject: "Planifier le projet",
    day: "jour",
    days: "jours",
    objective: "Objectif d'apprentissage",
    fundamental: "Objectifs de développement",
    noFundamental: "Pas d'objectif de développement",
    guidingQuestions: "Questions clés",
    ratings: "Évaluations",
    noRating: "Pas encore d'évaluation",
    lessonNo: "Leçon {number}",
    assessmentPlanNo: "Plan d'évaluation {number}",
    min: "min",
    conduct: "{number} activités à mener",
    Sproutlings: "Jeunes pousses",
    Sprouts: "Pousses",
    Buds: "Bourgeons",
    Blossoms: "Fleurs",

    // Account page
    male: "Homme",
    female: "Femme",
    gender: "Genre",
    highestEducationQualification: "Plus haute qualification éducative",
    educationStartDate: "Date de début de l'éducation",
    noFormalRecordYet: "Pas encore d'enregistrement formel",
    highSchool: "Lycée",
    associateDegree: "Diplôme associé",
    bachelorsDegree: "Baccalauréat",
    mastersDegree: "Master",
    doctorateDegree: "Doctorat",

    //Settings Page
    emailPreferences: "Préférences de messagerie électronique",

    //Email Preferences Page
    stayInformed: "Rester informé",
    toggleOnOrOffEmails:
      "Activez ou désactivez pour choisir les e-mails que vous recevez.",
    announcementsAndMessages: "Annonces et messages",

    // profileSidebar
    profileSectionAbout: "À propos de",
    profileSectionExperience: "Expérience",
    profileSectionReflections: "Réflexions",
    profileSectionCertifications: "Certifications",
    profileSectionTestimonials: "Témoignages",
    profileSectionProjects: "Projets passés",

    // profileIconMenu
    account: "Compte",
    settings: "Paramètres",
    profile: "Profil",
    schoolProfile: "Profil de l'école",
    adminPage: "Page d'administration",
    createNewClass: "Créer une nouvelle classe",
    addNewChild: "Ajouter un nouvel enfant",
    manageProjects: "Gérer les projets",
    helpCenter: "centre d'aide",
    registerSiteAdmin: "Inscrire l'administrateur du site",
    registerSchoolAdmin: "Inscrire l'administrateur de l'école",
    registerContentEditor: "Inscrire l'éditeur de contenu",
    registerTTAContentEditor: "Inscrire l'éditeur de contenu TTA",
    registerTeacher: "Inscrire l'enseignant",
    registerParent: "Inscrire le parent",
    registerChild: "Inscrire l'enfant",
    enroll: "Inscrire l'enfant",
    provideFeedback: "Fournir des commentaires",
    viewFeedback: "Voir les commentaires",
    logout: "Se déconnecter",
    switchToTTA: "Passer à l'Académie de formation des enseignants",
    switchToTTB: "Passer à Teaching ToolBox",

    // Library
    backToLibrary: "Retour à la bibliothèque",
    speciallySelectedForYourChildren:
      "Spécialement sélectionné pour vos enfants",
    browseL: "Parcourir",

    // Schedule Modal
    scheduleModalTagline:
      "Planifiez quand votre projet aura lieu !{0}Vous pouvez consulter cela dans votre calendrier de programme sous notre fonction 'Programmateur'.",
    projectStartDate: "Date de début du projet",
    projectEndDate: "Date de fin du projet",

    // School Profile Page
    addBackgroundPhoto: "Ajouter une photo de fond",
    removeBackgroundPhoto: "Supprimer la photo de fond",
    updateBackgroundPhoto: "Mettre à jour la photo de fond",
    addDescription: "Incluez une courte rédaction !",
    deleteSuccess: "Supprimé avec succès !",
    deleteFailed: "Échec de la suppression !",
    updateSuccess: "Mise à jour réussie !",
    updateFailed: "Échec de la mise à jour !",
    createSuccess: "Création réussie !",
    createFailed: "Échec de la création !",
    removePhoto: "Supprimer la photo",

    // ActivityPage
    back: "Retour",
    pResources: "Ressources du projet",
    activityNo: "Activité {number}",
    optionNo: "Option {number}",
    lessonOverview: "Aperçu de la leçon",
    checkpoints: "Résumé et points de contrôle",
    previousLesson: "Leçon précédente",
    nextLesson: "Leçon suivante",
    activities: "Activités",
    startActivity: "Commencer l'activité",
    reqConduct: "activités nécessaires à mener",
    startLesson: "Commencer la leçon",
    noVocabulary: "Pas de vocabulaire",
    noMaterial: "Pas de matériel",
    noInstruction: "Pas d'instruction",
    deletedInstructions: "Étape supprimée avec succès",
    pleaseSchedule:
      "Veuillez planifier ce projet avant de marquer l'activité comme terminée !",
    pleaseUploadPhotoVideo:
      "Veuillez télécharger au moins 1 photo ou 1 vidéo avant de marquer l'activité comme terminée !",
    completeAll: "Terminer toutes les activités !",
    completePartial: "Terminer {number} sur {total} activités !",
    pickToComplete: "Choisissez 1 activité à compléter !",

    // ActivityDetailPage(DrawingPad)
    stepByStepInstructions: "Instructions Étape par Étape",
    addStep: "Ajouter une étape",
    done: "Terminé",
    activityCompleted: "Activité terminée",
    notDone: "Non terminé",
    checkpointIcon: "Points de contrôle :",
    noCheckpoints: "Pas de points de contrôle",
    addCheckpoint: "Ajouter un point de contrôle",
    noLearningMoments: "Aucun moment d'apprentissage",
    learningMoment: "Moment d'apprentissage",
    evaluationMoment: "Moment d'évaluation",
    learningMoments: "Moments d'apprentissage",
    evaluationMoments: "Moments d'évaluation",
    addLearningMoment: "Ajouter un moment d'apprentissage",
    learningMomentRecorded: "Moment d'apprentissage enregistré",
    milestoneCheckIncomplete:
      "Vous y êtes presque, cliquez pour terminer votre moment d'apprentissage !",
    photoIncomplete:
      "Vous y êtes presque, téléchargez une photo pour terminer votre moment d'apprentissage !",
    previous: "Précédent",
    summary: "Résumé de la leçon",
    selectAll: "Tout sélectionner",
    deselectAll: "Tout désélectionner",
    milestoneCheck: "Vérification des étapes importantes",
    milestoneCheckDescription:
      "Utilisez cette liste de contrôle pour voir si vos enfants ont atteint ces étapes importantes projetées. N'hésitez pas à le faire entre les leçons ou à la fin de votre unité.",
    milestoneCheckWithCheckpoint:
      "Vérification des étapes importantes (Points de contrôle)",
    pleaseNavigateToLastPageToClickDone: `Veuillez naviguer jusqu'à la dernière page pour accéder et cliquer sur le bouton "Terminé".`,

    // Review
    noReview: "Aucune critique pour le moment",
    noContent: "Aucun contenu pour le moment",
    writeAReview: "Écrire une critique",
    shareReview:
      "Qu'est-ce que vous aimez dans le projet, et/ou qu'est-ce qui a bien fonctionné pour vous ?",
    feedbackToDevTeam:
      "Avez-vous des commentaires ou des suggestions à nous fournir pour nous aider à améliorer le projet ?",
    thankyouFeedback: "Merci pour vos commentaires !",
    viewReviews: "Voir toutes les critiques",
    thanksReviews: "Merci pour votre critique !",
    reviewPosted: "Critique publiée !",
    reviewUpdated: "Critique mise à jour !",
    reviewDeleted: "Critique supprimée !",
    tryAgainLater: "Veuillez réessayer ultérieurement",
    deleteReview: "Supprimer la critique",
    replyTo: "Répondre à {name}...",
    writeAComment: "Écrire un commentaire...",
    deleteComment: "Supprimer le commentaire ?",
    commentDeleted: "Commentaire supprimé !",
    edited: "Édité",
    like: "J'aime",
    liked: "Aimé !",
    unLiked: "Non aimé !",
    reply: "Répondre",
    comment: "Commentaire",
    addPhotos: "Ajouter des photos",
    mostRecent: "Plus récent",
    mostLiked: "Plus aimé",

    // Project Summary Page
    projectSummary: "Résumé du projet",
    projectSummaries: "Résumés de projets",
    projectSummaryReport: "Rapport de synthèse du projet",
    backProject: "Retour au projet",
    congrats: "Félicitations !",
    completedProject: "Vous avez terminé le projet.",
    photoAlbum: "Album photo",
    rate: "Évaluez votre expérience",
    howProjectShareExperience:
      "Comment était le projet ? Partagez votre expérience !",
    beforeDownload: "Avant de télécharger",
    beforeGo: "Avant de partir",
    recoProj: "Projets recommandés",
    completedCP: "Points de contrôle terminés :",
    downloadReport: "Télécharger le rapport",
    openReport: "Ouvrir le rapport",
    generatingReport: "Génération du rapport",
    viewMore: "Voir plus",
    viewAll: "Voir tout",
    reGenerateReport: "Régénérez votre rapport maintenant",
    getReport: "Téléchargez votre rapport personnalisé maintenant",
    photos: "Photos",
    videos: "Vidéos",
    totalNumberLessons: "Nombre total de leçons",

    // Project Report PDF
    projectOverview: "Aperçu du projet",
    presentedBy: "Présenté avec fierté par :",
    congratsText: "pour la réussite du projet",
    changeTemplate: "Changer de modèle",
    pickImage: "Choisir une image",
    pickTemplate: "Choisir un modèle",

    // Backpack Page
    noenrolledChild: "Vous n'avez aucun enfant inscrit",
    noBackpack: "Aucun article dans le sac à dos",
    favourites: "Favoris",
    addFavourites: "Ajouter aux favoris",
    noFavourites:
      "Aucun élément ici pour le moment. Allez dans notre bibliothèque pour trouver vos projets préférés !",

    // Analytic
    sevenDays: "7 derniers jours",
    thirtyDays: "30 derniers jours",
    oneYear: "1 an",
    allTime: "Tout le temps",

    //portfoliopage
    age: "Âge",
    year: "an",
    years: "ans",
    month: "mois",
    months: "mois",
    appraisal: "Appréciation globale de l'enseignant pour {name}",
    noAppraisal: "Aucune appréciation",
    presentGoals: "Objectifs actuels pour {name}",
    goalHistory: "Voir l'historique des objectifs",
    noGoals: "Aucun objectif",
    headProgress: "Progrès de {name} dans {tab}",
    progressHistory: "Voir l'historique des progrès",
    latestAccom: "Dernières réalisations",
    accomHistory: "Voir l'historique des réalisations",
    improve: "Domaine d'amélioration",
    projectDone: "Projets réalisés par {name}",
    allDetails: "Voir tous les détails",
    hideDetails: "Masquer tous les détails",
    pastProject: "Voir tous les projets passés",
    recoAct: "Activités recommandées pour l'amélioration de {name}",
    noCompletedProject: "Aucun projet terminé",
    downloadpdf: "Télécharger le rapport PDF",
    showLesson: "Afficher les leçons",
    hideLesson: "Masquer les leçons",
    portfolio: "Portfolio",
    childPortfolio: "Portfolio de {name}",
    teacherNameArchive: "Archive de {name}",
    developmentalGoals: "Objectifs de développement",
    progressReport: "Rapport de progrès",
    completedWorks: "Petits travaux",
    createNew: "Créer un nouveau",
    selectDuration: "Sélectionner la durée",
    names: "{name}'s",
    learningJourney: "Parcours d'apprentissage",
    classInfo: "Informations sur la classe",
    noChildAssigned: "Aucun enfant n'est attribué à la classe",
    noTeacherAssigned: "Aucun enseignant n'est attribué à la classe",
    observation: "Observation",
    addObservation: "Ajouter une observation",
    milestoneCompleted: "Étape Franchie",
    nextMilestone: "Prochaine Étape",
    whatsNext: "Qu'est-ce qui vient ensuite ?",
    takeAction: "Agir !",
    noReflections:
      "Aucune réflexion pour le moment, pourquoi ne pas en écrire une ?",
    noTestimonials:
      "Aucun témoignage pour le moment, pourquoi ne pas demander à vos pairs d'en écrire un pour vous ?",
    noTestimonialsVisitor:
      "Aucun témoignage pour le moment. Pourquoi ne pas en écrire un ?",
    noCertifications:
      "Aucune certification pour le moment, rendez-vous à l'Académie de Formation des Enseignants pour obtenir une certification !",
    visible: "Visible",
    hidden: "Masqué",
    attained: "Atteint",
    notAttained: "Non atteint",
    published: "Publié",
    unPublished: "Non publié",
    dateObserved: "Date d'observation",
    deleteObservation: "Supprimer l'observation",
    reachHighestMilestone:
      "Félicitations ! Vous avez atteint la plus haute étape de cet objectif de développement.",
    findNewProject: "Trouvez un nouveau projet dans notre bibliothèque !",
    parentView: "Vue parent",
    whatIsParentView: "Qu'est-ce que la vue parent",
    nextMilestoneIsHidden: "La prochaine étape est masquée",
    blankSubDevGoalAreHidden:
      "Les objectifs de développement secondaires vierges sont masqués",
    albums: "Albums",
    addNewAlbum: "Ajouter un nouvel album",
    noAttainedMilestone: "Aucune étape atteinte !",
    congratulation: "Félicitations !",
    milestoneCompletedCongratulation:
      "Hourra ! Vous avez atteint votre étape !",
    showDevGoalWithRecordedLearningMoment:
      "Activez la 'Vue parent' pour afficher uniquement les objectifs de développement avec des moments d'apprentissage enregistrés !",
    filterAndShowParent:
      "Vous pouvez également choisir de filtrer et d'afficher les moments d'apprentissage des parents qui ont eu lieu sur une période spécifique.",
    learningStories:"Apprendre des histoires",

    // PortfolioPDF
    selectProgressReportDateRange:
      "Veuillez sélectionner la plage de dates du rapport de progression.",
    introductionText: `Le portfolio d'évaluation combine des photos, des comptes rendus anecdotiques et des grilles pour soutenir notre approche centrée sur l'enfant et axée sur les forces de l'apprentissage à l'école Trehaus. Alors que les photos et les notes anecdotiques offrent des perspectives animées sur la manière dont l'enfant nourrit ses Mains et son Cœur, une grille de compétence reflète les réalisations d'un enfant par rapport aux normes du trimestre scolaire en matière de connaissance de la Tête. À travers divers moyens et stratégies variées, l'apprentissage de l'élève est rendu visible, et ses succès sont célébrés.`,
    littleProjectsAccomplished: "Petits Projets Réalisés",
    littleLearnersAccomplished: "Petits Apprenants Accomplis",
    photoGallery: "Galerie de Photos",
    startedOn: "Commencé Le",
    completedOn: "Complété Le",
    observedOn: "Observé Le",
    profileShowcased:
      "{profileName} a présenté ces valeurs lors de ces moments d'apprentissage ci-dessous :",
    profileDemonstrates:
      "En tant qu'enfant de Trehaus, {profileName} démontre sa compétence dans cette catégorie de notre cadre curriculaire, avec les réalisations les plus remarquables étant :",
    concludingRemarks: "Remarques Finales",
    sneakPeek: "Coup d'Œil",
    goals: "Objectifs",
    thankYouBeingJourney:
      "Merci d'avoir fait partie de mon parcours en {className} {year}!",
    myTeachers: "Mes Professeurs",

    // Create child page
    preferred_name: "Nom préféré",
    full_name: "Nom complet",
    dob: "Date de naissance",
    image: "Image",
    submit: "Soumettre",
    not_logged_in: "Vous n'êtes pas connecté !",

    // Class Page -> Group === Class
    group_name: "Nom de la classe",
    create_group: "Créer une classe",
    addGroup: "Ajouter une classe",
    edit_group: "Modifier la classe",
    group: "Classe",
    assignChildrenToGroup: "Attribuer des élèves à la classe",
    childrenInGroup: "Enfants dans la classe",
    assignTeacherToGroup: "Attribuer un enseignant à la classe",
    teacherInGroup: "Enseignants dans la classe",

    // Enroll Child Page
    child: "Enfant",
    project: "Projet",
    enroll_child: "Inscrire un enfant à un projet",
    module: "Module",
    unit: "Unité",

    // Project Page - Edit + Create
    editing: "Édition",
    managing_proj: "Gestion du projet",
    projectEditor: "Éditeur de projet",
    create_new_proj: "Créer un nouveau projet",
    create_project: "Créer un projet",
    create_assessment: "Créer une nouvelle évaluation",
    proj_for_child: "Projet pour les enfants",
    sproutsExplainationPartOne: "Généralement de 18 mois à 2 ans, ",
    sproutsExplainationPartTwo:
      "les enfants commencent à capter de nouvelles informations du monde qui les entoure.",
    budsExplainationPartOne: "Généralement de 3 à 4 ans, ",
    budsExplainationPartTwo:
      "les enfants ont quelques connaissances et apprennent de nouvelles compétences pour appliquer ces nouvelles informations.",
    blossomsExplainationPartOne: "Généralement de 5 à 6 ans, ",
    blossomsExplainationPartTwo:
      "ils ont des connaissances et des compétences et les appliquent activement en les pratiquant.",
    sprouts: "Pousses",
    sprouts_with_age: "Pousses (18 mois - 2 ans)",
    sproutlings_with_age: "Pousses (2 ans - 3 ans)",
    buds_with_age: "Bourgeons (3 ans - 4 ans)",
    blossoms_with_age: "Fleurs (5 ans - 6 ans)",
    blooms_with_age: "Fleurs (5 ans - 6 ans)",
    sprouts_with_age_external: "Pousses (18 mois - 2 ans)",
    blossoms_with_age_external: "Fleurs (5 ans - 6 ans)",
    programme: "Programme",
    proj_name: "Nom du projet",
    level: "Niveau",
    basic: "De base",
    intermediate: "Intermédiaire",
    advanced: "Avancé",
    proj_duration: "Durée prévue (en minutes)",
    proj_description: "Description du projet",
    learning_objectives: "Objectifs d'apprentissage",
    publish: "Publier",
    saveAsDraft: "Enregistrer comme brouillon",
    recall: "Rappel",
    recalled: "Rappelé",
    success_remove_project: "Projet supprimé avec succès !",
    success_remove_unit_guide:
      "Le guide des ressources a été supprimé avec succès !",
    projectTags: "Étiquettes du projet",
    learningObjectives: "Objectifs d'apprentissage.",
    createAndManageModules: "Créer et gérer des modules",
    addObjective: "Ajouter un objectif",

    // Lessons
    activity: "Activité",
    activity_name: "Nom de l'activité",
    add_activity: "Ajouter une activité",
    success_remove_activity: "Activité supprimée avec succès !",
    activity_duration_min: "Durée de l'activité (minutes)",
    attachment_if_any: "Pièces jointes (le cas échéant)",
    attachment_if_copy: `Pièces jointes (cochez la case pour ajouter la pièce jointe dans "Documents")`,
    add_attachement_into_doc: `Ajouter la pièce jointe dans 'Documents'`,
    attachments: "Pièces jointes",
    lesson_name: "Nom de la leçon",
    lesson_description: "Description de la leçon",
    lesson_objective: "Objectif de la leçon",
    lesson_objectives: "Objectifs de la leçon",
    addLessonObjective: "Ajouter un objectif de leçon",
    lesson_intention: "Intention de la leçon",
    lessonToolbox: "Boîte à outils de la leçon",
    expected_duration_mins: "Durée prévue (minutes)",
    key_vocabulary: "Vocabulaire clé",
    material: "Matériel",
    materials: "Matériaux",
    materials_required: "Matériaux requis",
    recommendedSetupInstructionIfAny:
      "Instructions de configuration recommandées (le cas échéant)",
    setupInstruction: "Instruction de configuration",
    image_set: "Ensemble d'images",

    // Activity
    notes: "Remarques",
    stickyNote: "Note autocollante",
    activityStepInstruction: "Étape {weight} Instruction :",
    activityStep: "Étape {weight}",
    editing_activity: "Modification de l'activité",
    creating_activity: "Création de l'activité",
    remark: "Remarque",
    remarks: "Remarques",
    compulsory: "Obligatoire",
    cancel: "Annuler",
    skip: "Passer",
    create: "Créer",
    delete_activity: "Supprimer l'activité",
    success_reorder_activities: "Réorganiser les activités avec succès !",
    success_move_activity: "Déplacer l'activité avec succès !",
    question: "Question",

    //Checkpoint
    checkpoint: "Point de contrôle",

    //Learning Moment
    isLearningMoment: "Y a-t-il des moments d'apprentissage ?",
    learningMomentPhoto: "Photo du moment d'apprentissage",
    learningMomentQuestion: "Question du moment d'apprentissage",

    //Objective
    adding_curriculum_fundamental_to: "Ajout du fondamental au programme",
    editing_curriculum_fundamental_of: "Édition du fondamental de",
    objective_str: "Objectif",
    add_objective: "Ajouter un objectif",
    delete_fundamental: "Supprimer le fondamental",
    fundamental_str: "Fondamental",

    //Login
    forgottenPassword: "Mot de passe oublié ?",
    findYourAccount: "Trouver votre compte",
    enterYourEmail:
      "Veuillez entrer votre adresse e-mail pour rechercher votre compte.",
    pleaseCheckYourEmailAndPassword:
      "Veuillez vérifier si votre adresse e-mail et votre mot de passe sont corrects.",
    emailSubmittedTitle: "Demande de réinitialisation de mot de passe soumise",
    emailSubmittedResponse:
      "Si l'e-mail que vous avez fourni existe dans notre système, nous avons envoyé un lien pour réinitialiser votre mot de passe. Veuillez vérifier votre boîte de réception et vos dossiers indésirables.",

    //Register
    email: "Email",
    mobile_number: "Numéro de téléphone portable",
    password: "Mot de passe",
    already_have_account_login: "Vous avez déjà un compte ? Connectez-vous",
    role: "Rôle",

    //Reset
    resetYourPassword: "Réinitialisez votre mot de passe",

    //Admin Page
    add: "Ajouter",
    actions: "Actions",
    siteAdmin: "Administrateur du site",
    contentEditor: "Éditeur de contenu",
    ttaContentEditor: "Éditeur de contenu TTA",
    schoolAdmin: "Administrateur de l'école",
    paymentRecord: "Historique des paiements",

    //Questionnaire
    createInterest: "Créer un intérêt",
    createPreDQQuestion: "Créer une question pré-questionnaire de découverte",
    pickLanguage: "Choisissez vos préférences linguistiques",
    selectModules: `Sélectionnez le(s) module(s) que vous enseignerez`,
    selectDevelopmentalAreas: `Sélectionnez les principaux domaines de développement (Recommandé : Top 5)`,
    selectInterests: `Sélectionnez les intérêts les plus importants pour la classe {groupName}`,
    weight: "Ordre",
    name: "Nom",
    priority: "priorité",

    //Quick questions
    welcomeToTTB: "Bienvenue dans notre Boîte à Outils Pédagogiques !",
    everyChildIsUnique: "Chaque enfant est unique",
    introFirstParagraph:
      "Le programme pédagogique Trehaus vise à fournir des cours captivants, significatifs et différenciés pour chaque apprenant. Reconnaissant que chaque enfant est unique et se développe à son propre rythme, nous avons créé trois niveaux d'apprentissage qui aident les enseignants à concevoir des cours en fonction de la phase d'apprentissage actuelle de leurs enfants.",
    sproutsDescription:
      "Généralement, pour les enfants de 18 mois à 2 ans, les enfants Sprouts commencent à découvrir le monde, à développer leurs racines et à assimiler de nouvelles connaissances.",
    budsDescription:
      "Généralement, pour les enfants de 3 à 4 ans, les enfants Buds acquièrent des connaissances et apprennent de nouvelles compétences pour appliquer ces nouvelles informations.",
    blossomsDescription:
      "Généralement, pour les enfants de 5 à 6 ans, les enfants Blossoms ont des connaissances et des compétences et les appliquent activement et les mettent en pratique.",
    introLastParagraph:
      "Avant de consulter notre bibliothèque de plans de cours, veuillez prendre quelques minutes pour répondre à ces questions rapides afin que nous puissions recommander des projets en fonction de la phase de croissance actuelle de votre enfant.",
    myChildInterests: "Mes enfants s'intéressent à…",
    myChildInterestsSub: "(choisissez autant que vous le souhaitez !)",
    myChildGoals: "Mes enfants pourraient bénéficier de…",
    myChildGoalsSub:
      "(choisissez les objectifs sur lesquels vous souhaitez travailler !)",
    myChildCommunicationMethod: "Mes enfants communiquent en utilisant…",
    myChildCommunicationMethodSub:
      "(peu importe la langue, choisissez ce que votre enfant utilise !)",
    concludingTextFirst:
      "Super ! En fonction de vos réponses… vos enfants sont au niveau {0}.",
    concludingTextLast:
      "Rendez-vous dans notre bibliothèque de plans de cours pour voir quels projets amusants nous vous recommanderions.",
    quickQuestionsCompleted: "Questions rapides terminées !",
    levelAnswer1: "Gestes et Sons",
    levelAnswer2: "Un à Deux Mots",
    levelAnswer3: "Phrases Courtes",
    levelAnswer4: "Phrases Simples",
    levelAnswer5: "Phrases Détaillées",
    loading: "Chargement",
    intro: "Introduction",

    //Access Control
    school: "École",
    schools: "Écoles",
    search: "Rechercher",
    bulkAction: "Action en masse",
    editProject: "Modifier le projet",

    //Manage project page
    startDate: "Date de début",
    endDate: "Date de fin",

    //Ticketing
    subject: "Sujet",
    itSupportFeedback: "Assistance informatique - Retour d'information",
    feedback: "Retour d'information",
    next: "Suivant",

    //Payments
    payment: "Paiement",
    subscription: "Abonnement",
    subscriptions: "Abonnements",
    paymentHistory: "Historique des paiements",
    noSubscribe: "Aucun produit n'est encore abonné !",
    noProduct: "Restez à l'écoute pour ce produit !",
    paymentTitleSchool: "Choisissez le bon plan pour votre école",
    paymentTitleParent: "Choisissez le bon plan pour votre famille",
    selectPlan: "Sélectionner un plan",
    startFreeTrial: "Commencez l'essai gratuit de 7 jours",
    sevenDayTrial: "Essai gratuit de 7 jours",
    manageOrUpdatePlan: "Gérer ou mettre à jour le plan",
    paymentTitle: "Choisissez le bon plan",
    subscribedProduct: "Produit abonné",
    onlySchoolAdmin:
      "Veuillez vous connecter en tant qu'administrateur d'école pour voir le plan d'abonnement !",
    subscribed: "Abonné",
    noRenew: "Pas de renouvellement",
    willRenew: "Renouvellera",
    noProject: "Aucun projet trouvé",
    pleaseSubscribe:
      "Veuillez vous abonner à notre plan ou contacter les administrateurs pour plus d'informations",
    addPaymentRecord: "Ajouter un enregistrement de paiement",
    canViewProjects: "Peut voir les projets",
    createPaymentRecord: "Créer un enregistrement de paiement",
    customerId: "Identifiant client",
    bypassPayment: "Passer le paiement",
    demoSchool: "École de démonstration",
    subscribeToViewProject: "Abonnez-vous pour voir le projet",
    oopsDemoAccount:
      "Oups ! Ceci est un compte de démonstration. Inscrivez-vous sur LittleLab.com pour une expérience complète !",

    //Calendar
    calendarViewWeek: "Semaine",
    calendarViewMonth: "Mois",
    calendarViewYear: "Année",
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",

    //Timetable
    timetable: "Emploi du temps",
    today: "Aujourd'hui",
    thisWeek: "Cette semaine",
    editTimetable: "Modifier l'emploi du temps",
    addNewEvent: "Ajouter un nouvel événement",
    editEvent: "Modifier l'événement",
    discardEdits: "Ignorer les modifications",
    saveChanges: "Sauvegarder les modifications",
    noChanges: "Pas de modifications",
    timetableUpdated: "Emploi du temps mis à jour !",
    eventTitle: "Titre de l'événement",
    eventType: "Type d'événement",
    dayOfWeek: "Jour de la semaine",
    startingTime: "Heure de début",
    endingTime: "Heure de fin",
    timetableTitle: "Emploi du temps de {name}",
    shareTimetable: "Partager l'emploi du temps ?",
    copyShareLink: "Copier le lien de partage",
    shareLinkCopied: "Lien de partage copié !",
    timetableShared:
      "Toute personne possédant le lien peut consulter votre emploi du temps.",
    timetableUnshared: "Vous seul pouvez consulter votre emploi du temps.",
    defaultTimetableButtonTitle:
      "Élaborez votre plan de classe avec notre emploi du temps visuel !",
    uploadedTimetableButtonTitle:
      "Téléchargez votre propre plan pour votre référence !",
    uploadNewTimetable: "Télécharger un nouvel emploi du temps",
    removeTimetable: "Supprimer l'emploi du temps",
    noUploadedTimetable: "Aucun emploi du temps téléchargé",
    uploadYourTimetable:
      "Téléchargez une image de votre propre emploi du temps",
    uploading: "Téléchargement en cours",

    successAddEvent: "Nouvel événement ajouté avec succès !",
    failAddEvent:
      "Impossible d'ajouter un nouvel événement. Veuillez réessayer plus tard !",
    successUpdateEvent: "Événement mis à jour avec succès !",
    failUpdateEvent:
      "Échec de la mise à jour de l'événement. Veuillez réessayer plus tard !",
    successDeleteEvent: "Événement supprimé avec succès !",
    failDeleteEvent:
      "Impossible de supprimer l'événement. Veuillez réessayer plus tard !",
    successDuplicateEvent: "Événement dupliqué avec succès !",
    failDuplicateEvent:
      "Impossible de dupliquer l'événement. Veuillez réessayer plus tard !",
    successUploadTimetable: "Emploi du temps téléchargé avec succès !",
    failUploadTimetable:
      "Échec du téléchargement de l'emploi du temps. Veuillez réessayer plus tard !",
    successUpdateTimetable: "Emploi du temps mis à jour avec succès !",
    failUpdateTimetable:
      "Impossible de mettre à jour l'emploi du temps. Veuillez réessayer plus tard !",
    successDeleteTimetable: "Emploi du temps supprimé avec succès !",
    failDeleteTimetable:
      "Impossible de supprimer l'emploi du temps. Veuillez réessayer plus tard !",
    noEventToday: "Aucun événement aujourd'hui",

    // Chat
    messages: "Messages",
    newChat: "Nouvelle discussion",
    newClassChat: "Nouvelle discussion de classe",
    selectContact: "Sélectionner un contact",
    startANewChat: "Commencer une nouvelle discussion",
    startANewClassChat: "Commencer une nouvelle discussion de classe",
    typeYourMsgHere: "Tapez votre message ici...",
    noNewMessage: "Aucun nouveau message",

    // Developmental Goal
    manageDevelopmentalGoals: "Gérer les objectifs de développement",
    developmentalGoal: "Objectif de développement",
    subDevelopmentalGoal: "Sous-objectif de développement",

    addDevelopmentalGoal: "Ajouter un objectif de développement {tab}",
    editingDevelopmentalGoal: "Édition de l'objectif de développement",
    deleteDevelopmentalGoal: "Supprimer l'objectif de développement",
    createdDevelopmentalGoal: "Objectif de développement créé avec succès !",
    updatedDevelopmentalGoal:
      "Objectif de développement mis à jour avec succès !",
    removedDevelopmentalGoal:
      "Objectif de développement supprimé avec succès !",

    addSubDevelopmentalGoal: "Ajouter un sous-objectif de développement {tab}",
    editingSubDevelopmentalGoal: "Édition du sous-objectif de développement",
    deleteSubDevelopmentalGoal: "Supprimer le sous-objectif de développement",
    createdSubDevelopmentalGoal:
      "Sous-objectif de développement créé avec succès !",
    updatedSubDevelopmentalGoal:
      "Sous-objectif de développement mis à jour avec succès !",
    removedSubDevelopmentalGoal:
      "Sous-objectif de développement supprimé avec succès !",

    // Still Finding Out
    stillFindingOut: "Nous sommes toujours en train de découvrir !",
    stillFindingOutHeadLibrary:
      "Aucun moment d'apprentissage enregistré ici... Allez à la bibliothèque pour commencer un projet et créer des moments d'apprentissage !",
    noObservations: "Aucune observation enregistrée",
    noCaption: "Pas de légende",
    title: "Titre",
    subtitle: "Sous-titre",
    milestone: "Jalon",
    successReorderMilestone: "Le jalon est réordonné avec succès !",
    addMilestone: "Ajouter un jalon",
    deleteMilestone: "Supprimer le jalon",
    milestoneNo: "Jalon {number}",
    photoInstruction: "Instruction de la photo",
    photoCaption: "Légende de la photo",
    sixMonthNoObservation:
      "Cela fait un moment depuis votre dernière observation... faisons un autre moment d'apprentissage pour voir des progrès !",

    // Prohibited Word
    prohibitedWords: "Mots interdits",
    manageProhibitedWords: "Gérer les mots interdits",
    addProhibitedWords: "Ajouter des mots interdits",

    // Completed Work
    addCompletedWork: "Ajouter un petit travail",
    uploadChildrenCompletedWork: "Télécharger les travaux terminés des enfants",

    // Teacher Portfolio
    byClass: "Par classe",
    byYear: "Par année",
    byProject: "Par projet",

    // WIP
    underConstruction:
      "Oups ! Cette page est encore en construction. Restez à l'écoute !",

    // Parent Dashboard
    nowLearning: "Apprendre maintenant",

    // Other Components
    updateBackpack: "Mettre à jour le sac à dos",
    updateFavorite: "Mettre à jour les favoris",
    templateFor: "Modèle pour",
    activitiesToBeConducted: "activités à réaliser",
    loadingDots: "Chargement...",
    youNeedToEnrollYourGroup: "Vous devez inscrire votre groupe pour accéder à",
    selectDropAttachments: "Sélectionnez ou déposez les pièces jointes",
    selectDropActivity: "Sélectionnez ou déposez la vignette de l'activité",
    selectDropLesson: "Sélectionnez ou déposez la vignette de la leçon",
    translateThisLessonTo: "Traduire cette leçon en ",
  },

  //BAHASA
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  //-----------------------------------------------------
  id: {
    //General
    updated: "Diperbarui",
    story:"Cerita",

    //LandingPage
    whereKidsLoveLearning: "Di mana Anak-Anak Menyukai Belajar",
    findOutMore: "Cari tahu lebih lanjut",
    takeAPeek:
      "Lihat proyek Little kami yang didedikasikan untuk mendidik para pembuat perubahan",
    getAccessToLittleLab: "Dapatkan akses ke Little Lab",
    everyChildIsDifferent: "Setiap anak berbeda!",
    comeAndExperienceIndividualized:
      "Datang dan alami pembelajaran yang terindividualisasi disesuaikan dengan",
    your: "anakmu",
    childSmall: "anak",
    createAUniqueLearningExperience:
      "Ciptakan pengalaman belajar yang unik hari ini",
    greatContentCreatedBy:
      "Konten hebat yang dibuat oleh para ahli terkemuka dalam pendidikan awal berdasarkan penelitian dan metode pedagogis terbaru",
    learnMore: "Pelajari lebih lanjut",
    superEasyAndIntuitive:
      "Sangat mudah dan intuitif digunakan baik Anda seorang orang tua atau guru.",
    perfectEvenFor: "Sempurna bahkan untuk",
    homeSchoolers: "Para pengajar rumahan",
    andSmall: "dan",
    learningCommunities: "komunitas belajar",
    discoverTheFutureOfEducation: "Temukan masa depan pendidikan",
    ourProprietaryTrehaus:
      "Kurikulum Little Lab Trehaus eksklusif kami mencakup 6 keterampilan masa depan dan keterampilan lunak",
    ourLittleLearners: "Pembelajar Kecil Kami",
    enrichYourChild:
      "Perkaya pengetahuan anak Anda melalui pelajaran yang sepenuhnya terstruktur dan mandiri yang dirancang untuk melibatkan anak Anda dalam kegiatan literasi dan numerasi yang bermakna dan berpikir.",
    hearWhatOtherParents:
      "Dengar apa yang dikatakan orang tua dan pendidik lainnya",
    weSimplyLove:
      "Kami hanya mencintai pendekatan berbasis nilai terhadap pendidikan yang diusung oleh Trehaus. Ini bukan hanya taman kanak-kanak, ini adalah komunitas kuat berdasarkan nilai-nilai luar biasa. Saya sangat merekomendasikan Trehaus.",
    confidence: "Keyakinan",
    empathy: "Empati",
    creativity: "Kreativitas",
    perseveranceGrit: "Perseverance & Grit",
    communicationSkills: "Keterampilan Komunikasi",
    selfRegulation: "Regulasi Diri",
    integrity: "Integritas",
    executiveFunction: "Fungsi Eksekutif",
    steam: "STEAM",
    englishLanguageLiteracy: "Literasi Bahasa Inggris",
    mandarinLanguageLiteracy: "Literasi Bahasa Mandarin",
    numeracyMathConcepts: "Konsep Numerasi dan Matematika",
    grossMotorSkills: "Keterampilan Motorik Kasar",
    fineMotorSkills: "Keterampilan Motorik Halus",
    gratitudeGivingBack: "Gratitude & Giving Back",
    environmentalResponsibility: "Tanggung Jawab Lingkungan",
    socialAwareness: "Kesadaran Sosial",
    publicSpeakingAdvocacy: "Public Speaking & Advocacy",
    interdependence: "Interdependensi",
    practicalLifeSkills: "Keterampilan Hidup Praktis",
    globalPerspectives: "Perspektif Global",
    littleCreative: "Kreatif Kecil",
    littleCeo: "CEO Kecil",
    littleChef: "Koki Kecil",
    littlePhilanthropist: "Filantropis Kecil",
    littleEngineer: "Insinyur Kecil",
    littleEntrepreneur: "Entrepreneur Kecil",
    theLearningExperiencesDesigned:
      "Pengalaman belajar yang dirancang dalam kurikulum ini relevan dengan kehidupan sehari-hari anak Anda, memberikan konteks yang bermakna untuk mengembangkan pengetahuan dan keterampilan dalam bahasa Mandarin dan literasi.",
    littleJournalist: "Jurnalis Kecil",
    exposeYourChildToKnowledge:
      "Ekspos anak Anda pada pengetahuan bahasa Inggris dan literasi dengan cara yang menyenangkan dan menarik. Jurnalis Kecil menyediakan pengalaman belajar multisensori yang unik untuk mengintegrasikan semua aspek literasi untuk anak Anda.",
    littleMathematician: "Matematikawan Kecil",
    littleMathematicianProvidesAnAuthentic:
      "Matematikawan Kecil menyediakan pengalaman belajar yang autentik, menyenangkan, dan praktis untuk mendukung anak Anda dalam mengembangkan dasar yang kuat dalam matematika dan pemikiran logis, langkah demi langkah, mengembangkan dan mengasah kemampuan mereka untuk berpikir secara matematis dan memecahkan masalah.",
    trehausSchoolMomHelle: "Ibu Sekolah Trehaus, Helle",
    lauraIsAlwaysExcited:
      "Laura selalu bersemangat untuk pergi ke pelajaran! Terima kasih telah mendorong kemandirian, menanamkan nilai-nilai hidup penting, dan membuatnya benar-benar menyenangkan untuk belajar. Pikiran kami tenang saat kami bekerja, berkat cinta dan perhatian Anda.",
    trehausSchoolMomRaine: "Ibu Sekolah Trehaus, Raine",
    jaydenIsReallyLucky:
      "Jayden sangat beruntung memiliki guru yang lembut dan peduli, yang selalu memberikan kasih sayang dan perhatian pada anak-anak. Jayden sangat menyukai mereka! Mengirim Jayden ke Sekolah Trehaus dan bekerja di Trehaus adalah keputusan terbaik yang kami buat!",
    trehausSchoolMomOlivia: "Ibu Sekolah Trehaus, Olivia",
    myBiggestSatisfactionIsSeeing:
      "Kepuasan terbesar saya adalah melihat putra saya Nate berkembang dan tumbuh di Trehaus, dia sangat mencintai sekolah, dan saya menyukai bagaimana saya bisa bekerja di Business Club hanya beberapa langkah saja, dan menjadi bagian dari tahun-tahun pertama yang berharga dalam hidupnya.",
    trehausSchoolMomElaine: "Ibu Sekolah Trehaus, Elaine",
    incrediblyDedicatedAndNurturing:
      "Staf pengajar dan kepala sekolah yang sangat berdedikasi dan peduli di Sekolah Trehaus. Saya benar-benar terkesan oleh laporan perkembangan mereka dan jumlah usaha yang ditempatkan untuk mengartikulasikan kemajuan putri kami di sekolah.",
    trehausSchoolDadStephen: "Ayah Sekolah Trehaus, Stephen",
    everyPersonInTheSchool:
      "Setiap orang di sekolah benar-benar peduli pada anak-anak dan memperlakukan semua anak sebagai anak sendiri, jadi saya percaya bahwa putra saya berada di tangan yang baik setiap hari saat saya pergi bekerja.",
    trehausSchoolDadRodney: "Ayah Sekolah Trehaus, Rodney",

    //Dashboard
    totalChildren: "Total Anak",
    totalTeachers: "Total Guru",
    totalClasses: "Total Kelas",
    currentlyOn: "Saat Ini Aktif",
    proTips: "Tips Pro: Ketik [] untuk menambahkan tugas",
    typeYourNoteHere: "Ketik catatan Anda di sini",
    message: "Pesan",
    timeTable: "Jadwal",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    jun: "Jun",
    jul: "Jul",
    aug: "Ags",
    sep: "Sep",
    oct: "Okt",
    nov: "Nov",
    dec: "Des",
    teachers: "Guru",
    teacher: "Guru",
    children: "Anak-anak",
    total: "Total",
    viewClass: "Lihat Kelas",

    //Homepage
    welcome: "Selamat kembali,",
    dashboard: "Dashboard Saya",
    whoLearning: "Siapa yang akan belajar hari ini?",
    viewGroup: "Lihat kelas",
    updateProfile: "Perbarui profil",
    broadcastMsg: "Pesan siaran",
    allGroups: "Semua kelas",
    noOngoingLesson: "Tidak ada pelajaran yang sedang berlangsung",
    noLesson: "Tidak ada pelajaran",
    students: "Siswa",
    formTeacher: "Wali kelas",
    todayLessons: "Pelajaran hari ini",
    tmrLessons: "Pelajaran mendatang",
    lessonCompleted: "Pelajaran selesai",
    lessonsCompleted: "Pelajaran selesai",
    lessonConducted: "Pelajaran diselenggarakan",
    lessonsConducted: "Pelajaran diselenggarakan",
    resume: "Melanjutkan",
    resumeLesson: "Melanjutkan pelajaran",
    goToLesson: "Pergi ke pelajaran",
    newEnrollments: "Pendaftaran baru",
    lesson_number: "Pelajaran {number}",
    myClasses: "Kelas saya",
    classDetail: "Detail Kelas",
    attendance: "Kehadiran",
    currentLessons: "Pelajaran sedang berlangsung",
    view: "Lihat",
    myChildren: "Anak-anak saya",
    announcements: "Pengumuman",
    noAnnouncementYet: "Belum ada pengumuman",
    noActionRequiredYet: "Belum ada tindakan yang diperlukan",
    viewAllAnnouncements: "Lihat semua pengumuman",
    showActiveAnnouncementsOnly: "Hanya tampilkan pengumuman aktif",
    littleAlbum: "Album Kecil",
    mostRecentPhotos: "Foto terbaru",
    quickItems: "Item cepat",
    addTodo: "Tambahkan Tugas",
    notesUpdatedSuccess: "Catatan berhasil diperbarui!",
    notesUpdatedFailed: "Gagal memperbarui catatan!",
    addTodoInstruction: "Tips Pro: Ketik [] untuk menambahkan tugas!",
    typeYourNotesHere: "Ketik catatan Anda di sini...",

    // MyChildrenPage
    viewPortfolio: "Lihat Portofolio",
    bornOn: "Lahir pada",
    parent: "Orang Tua",

    // My Classes
    classList: "Daftar Kelas",
    editClass: "Edit Kelas",

    // Edit Class
    update: "Perbarui",

    // AnnouncementsPage
    createAnnouncement: "Buat Pengumuman",
    addNewAnnouncement: "Tambahkan Pengumuman Baru",
    editAnnouncement: "Edit Pengumuman",
    deleteAnnouncement: "Hapus Pengumuman",
    announcementCreatedSuccess: "Pengumuman berhasil dibuat!",
    announcementCreatedFailed: "Gagal membuat pengumuman!",
    announcementUpdatedSuccess: "Pengumuman berhasil diperbarui!",
    announcementUpdatedFailed: "Gagal memperbarui pengumuman!",
    announcementRecalledSuccess: "Pengumuman berhasil dipanggil kembali!",
    announcementRecalledFail: "Gagal memanggil kembali pengumuman!",
    announcementRecalled: "Pengumuman ini telah dipanggil kembali.",
    announcementDeletedSuccess: "Pengumuman berhasil dihapus!",
    announcementDeletedFailed: "Gagal menghapus pengumuman!",
    announcementStartAt: "Pengumuman Dimulai Pada",
    announcementEndAt: "Pengumuman Berakhir Pada",
    includesAttachment: "Termasuk lampiran",
    pinAnnouncement: "Pasang Pengumuman?",
    anyResponseRequired: "Apakah respons apa pun diperlukan?",
    responseRequired: "Respon diperlukan",
    responseQuestion: "Pertanyaan Respon",
    responseType: "Tipe Respon",
    respondBy: "Mersepon Pada",
    allowTextResponse: "Izinkan respon teks?",
    textResponse: "Respon Teks",
    textResponseInstruction: "Instruksi respon teks",
    allowAttachmentUpload: "Izinkan unggah lampiran?",
    attachmentInstruction: "Instruksi lampiran",
    responseOption: "Opsi Respon",
    addResponseOption: "Tambahkan Opsi Respon",
    responseUpdated: "Respon diperbarui!",
    responseUpdateFail: "Gagal memperbarui respon! Silakan coba lagi nanti",
    uploadDocument: "Unggah Dokumen",
    acknowledge: "Mengakui",

    // Documents Page
    createDocument: "Buat Dokumen",
    addNewDocument: "Tambahkan Dokumen Baru",
    editDocument: "Edit Dokumen",
    deleteDocument: "Hapus Dokumen",
    document: "Dokumen",
    documents: "Dokumen",
    noDocumentYet: "Belum ada dokumen",
    pleaseUploadAtLeastOneDocument: "Harap unggah setidaknya 1 dokumen!",

    // BrowsePage
    noChild: "Anda tidak memiliki anak yang terdaftar",
    noGroup: "Anda tidak memiliki kelas yang terdaftar",
    noGroupAssigned:
      "Tidak ada kelas yang ditugaskan untuk Anda! Harap hubungi administrator sekolah untuk klarifikasi lebih lanjut",
    browse: "Telusuri Semua Proyek",
    noProjectFound: "Tidak Ada Proyek Ditemukan",
    searchForProjects: "Cari proyek",
    recommendations: "Rekomendasi",
    displayAll: "Tampilkan Semua Proyek",
    specially: "Khusus Untuk Anda",
    specialDesc: "Pengalaman belajar individual terbaik kami",
    trending: "Proyek Populer",
    trendDesc: "Lihat apa yang populer di The Little Lab",
    new: "Baru!",
    newDesc: "Telusuri proyek terbaru dari tim ahli kami",
    byModule: "Menurut Modul",
    byLevels: "Menurut Tingkatan",
    addToBackpack: "Tambahkan ke Ransel",
    removeFromBackpack: "Hapus",
    addedToBackpack: "Ditambahkan ke ransel!",
    viewProjectDetails: "Lihat rincian proyek",
    viewAssessmentDetails: "Lihat rincian penilaian",
    addOrScheduleItToView: "Tambahkan/Jadwalkan untuk dilihat!",

    // NavigationBar
    home: "Beranda",
    lessonLibrary: "Kurikulum",
    library: "Perpustakaan Rencana Pelajaran",
    assessments: "Penilaian",
    backpack: "Ransel & Favorit",
    visualTimetable: "Jadwal Visual",
    scheduler: "Penjadwal",
    littleEvaluator: "Evaluator Kecil",
    calendar: "Kalender",
    myClass: "Kelas Saya",
    portfolios: "Portofolio",
    childrenPortfolio: "Portofolio Anak-anak",
    teacherPortfolio: "Arsip Guru",
    notices: "Pemberitahuan",

    // ProjectDetailPage
    lesson: "Pelajaran",
    lessons: "Pelajaran",
    saved: "Tersimpan",
    notSaved: "Belum Tersimpan",
    overview: "Ikhtisar",
    unitGuide: "Panduan Sumber",
    plan: "Rencana Pelajaran",
    assessmentPlans: "Rencana Penilaian",
    teachingMaterials: "Bahan Ajar",
    communitySharing: "Berbagi Komunitas",
    whatPeopleLike: "Apa yang Disukai Orang",
    tags: "Tag",

    // Teaching Material
    getTeachingMaterials: "Dapatkan bahan ajar",
    downloadMaterials: "Unduh Materi",

    // ProjectMoment
    uploadPhoto: "Unggah foto",
    uploadPhotos: "Unggah foto",
    uploadVideos: "Unggah video",
    uploadMedia: "Unggah media",
    caption: "Keterangan",
    typeCaption: "Ketikkan keterangan",
    addCaption: "Tambahkan keterangan",
    upload: "Unggah",
    uploadFinalizing: "Pemrosesan selesai... Jangan segarkan...",
    uploadPhotoFinalizing: "Pemrosesan foto selesai... Jangan segarkan...",
    uploadVideoFinalizing: "Pemrosesan video selesai... Jangan segarkan...",
    noActivity: "Tidak ada aktivitas",
    noPhoto: "Belum ada foto",
    failed_load_gallery: "Gagal memuat galeri!",
    failed_upload: "Gagal mengunggah!",
    failed_add_photos: "Gagal menambahkan foto!",
    failed_add_videos: "Gagal menambahkan video!",
    failed_add_photos_videos: "Gagal menambahkan foto atau video!",
    success_upload: "Berhasil diunggah!",
    success_upload_photos: "Foto berhasil diunggah!",
    success_upload_videos: "Video berhasil diunggah!",
    success_upload_photos_videos: "Foto dan video berhasil diunggah!",
    success_update_image: "Berhasil diperbarui!",
    success_delete_image: "Foto(s) berhasil dihapus!",
    success_delete_video: "Video(s) berhasil dihapus!",
    success_delete_photo_video: "Foto(s) dan Video(s) berhasil dihapus!",
    fail_delete_photo_video: "Gagal menghapus Foto(s) dan Video(s)!",
    delete_photo: "Hapus {number} foto terpilih",
    delete_video: "Hapus {number} video terpilih",
    delete_photo_video: "Hapus {number} foto/video terpilih",
    selected: "Terpilih",
    allPhotos: "Semua Foto",
    allVideos: "Semua Video",
    allPhotosVideos: "Semua foto dan video",
    irreversibleAction: "Aksi ini tidak dapat dikembalikan!",
    tagChildren: "Tag anak-anak",
    profiles: "Profil",
    edit_image: "Edit gambar",
    edit_video: "Edit video",

    // Edit Image
    flipHoriz: "Flip Horisontal",
    flipVerti: "Flip Vertikal",
    rotateAntiClockwise: "Putar Berlawanan Arah Jarum Jam",
    rotateClockwise: "Putar Searah Jarum Jam",

    // LessonDetailPage
    begin: "Ayo mulai!",
    scheduleProject: "Jadwalkan proyek",
    day: "hari",
    days: "hari",
    objective: "Tujuan Pembelajaran",
    fundamental: "Tujuan Pengembangan",
    noFundamental: "Tidak Ada Tujuan Pengembangan",
    guidingQuestions: "Pertanyaan Fokus",
    ratings: "Penilaian",
    noRating: "Belum Ada Penilaian",
    lessonNo: "Pelajaran {number}",
    assessmentPlanNo: "Rencana Penilaian {number}",
    min: "menit",
    conduct: "Akan dilakukan {number} kegiatan",
    Sproutlings: "Sproutlings",
    Sprouts: "Sprouts",
    Buds: "Buds",
    Blossoms: "Blossoms",

    // Account page
    male: "Laki-laki",
    female: "Perempuan",
    gender: "Jenis kelamin",
    highestEducationQualification: "Kualifikasi Pendidikan Tertinggi",
    educationStartDate: "Tanggal Mulai Pendidikan",
    noFormalRecordYet: "Belum Ada Catatan Formal",
    highSchool: "SMA",
    associateDegree: "Gelar Associate",
    bachelorsDegree: "Gelar Sarjana",
    mastersDegree: "Gelar Magister",
    doctorateDegree: "Gelar Doktor",

    //Settings Page
    emailPreferences: "Preferensi Email",

    //Email Preferences Page
    stayInformed: "Tetap Terinformasi",
    toggleOnOrOffEmails:
      "Aktifkan atau nonaktifkan untuk memilih email yang Anda terima.",
    announcementsAndMessages: "Pengumuman dan Pesan",

    // profileSidebar
    profileSectionAbout: "Tentang",
    profileSectionExperience: "Pengalaman",
    profileSectionReflections: "Refleksi",
    profileSectionCertifications: "Sertifikasi",
    profileSectionTestimonials: "Testimoni",
    profileSectionProjects: "Proyek Terdahulu",

    // profileIconMenu
    account: "Akun",
    settings: "Pengaturan",
    profile: "Profil",
    schoolProfile: "Profil Sekolah",
    adminPage: "Halaman Admin",
    createNewClass: "Buat Kelas Baru",
    addNewChild: "Tambahkan Anak Baru",
    manageProjects: "Kelola Proyek",
    helpCenter: "Pusat Bantuan",
    registerSiteAdmin: "Daftarkan Admin Situs",
    registerSchoolAdmin: "Daftarkan Admin Sekolah",
    registerContentEditor: "Daftarkan Editor Konten",
    registerTTAContentEditor: "Daftarkan Editor Konten TTA",
    registerTeacher: "Daftarkan Guru",
    registerParent: "Daftarkan Orang Tua",
    registerChild: "Daftarkan Anak",
    enroll: "Daftarkan Anak",
    provideFeedback: "Beri Masukan",
    viewFeedback: "Lihat Masukan",
    logout: "Keluar",
    switchToTTA: "Beralih ke Akademi Pelatihan Guru",
    switchToTTB: "Beralih ke Teaching ToolBox",

    // Library
    backToLibrary: "Kembali ke Perpustakaan",
    speciallySelectedForYourChildren: "Khusus dipilih untuk anak-anak Anda",
    browseL: "Telusuri",

    // Schedule Modal
    scheduleModalTagline:
      "Jadwalkan kapan proyek Anda akan berlangsung! {0}Anda dapat melihat ini di kalender kurikulum Anda di bawah fitur 'Penjadwal' kami.",
    projectStartDate: "Tanggal Mulai Proyek",
    projectEndDate: "Tanggal Selesai Proyek",

    // School Profile Page
    addBackgroundPhoto: "Tambahkan foto latar belakang",
    removeBackgroundPhoto: "Hapus foto latar belakang",
    updateBackgroundPhoto: "Perbarui foto latar belakang",
    addDescription: "Sertakan tulisan singkat!",
    deleteSuccess: "Berhasil dihapus!",
    deleteFailed: "Gagal menghapus!",
    updateSuccess: "Berhasil diperbarui!",
    updateFailed: "Gagal memperbarui!",
    createSuccess: "Berhasil dibuat!",
    createFailed: "Gagal membuat!",
    removePhoto: "Hapus foto",

    // ActivityPage
    back: "Kembali",
    pResources: "Sumber Daya Proyek",
    activityNo: "Aktivitas {number}",
    optionNo: "Opsi {number}",
    lessonOverview: "Ikhtisar Pelajaran",
    checkpoints: "Ringkasan dan Poin Pengecekan",
    previousLesson: "Pelajaran Sebelumnya",
    nextLesson: "Pelajaran Berikutnya",
    activities: "Aktivitas",
    startActivity: "Mulai Aktivitas",
    reqConduct: "aktivitas yang harus dilakukan",
    startLesson: "Mulai Pelajaran",
    noVocabulary: "Tidak Ada Kosakata",
    noMaterial: "Tidak Ada Materi",
    noInstruction: "Tidak Ada Instruksi",
    deletedInstructions: "Langkah telah berhasil dihapus",
    pleaseSchedule:
      "Harap jadwalkan proyek ini sebelum menandai aktivitas sebagai selesai!",
    pleaseUploadPhotoVideo:
      "Harap unggah setidaknya 1 foto atau 1 video sebelum menandai aktivitas sebagai selesai!",
    completeAll: "Selesaikan semua aktivitas!",
    completePartial: "Selesaikan {number} dari {total} aktivitas!",
    pickToComplete: "Pilih 1 aktivitas untuk diselesaikan!",

    // ActivityDetailPage(DrawingPad)
    stepByStepInstructions: "Petunjuk Langkah demi Langkah",
    addStep: "Tambahkan Langkah",
    done: "Selesai",
    activityCompleted: "Aktivitas Selesai",
    notDone: "Belum Selesai",
    checkpointIcon: "Checkpoints:",
    noCheckpoints: "Tidak ada checkpoint",
    addCheckpoint: "Tambahkan Checkpoint",
    noLearningMoments: "Tidak Ada Moment Pembelajaran",
    learningMoment: "Moment Pembelajaran",
    evaluationMoment: "Moment Evaluasi",
    learningMoments: "Moment Pembelajaran",
    evaluationMoments: "Moment Evaluasi",
    addLearningMoment: "Tambahkan Moment Pembelajaran",
    learningMomentRecorded: "Moment pembelajaran tercatat",
    milestoneCheckIncomplete:
      "Anda hampir sampai, klik untuk menyelesaikan momen pembelajaran Anda!",
    photoIncomplete:
      "Anda hampir sampai, unggah foto untuk menyelesaikan momen pembelajaran Anda!",
    previous: "Sebelumnya",
    summary: "Ringkasan Pelajaran",
    selectAll: "Pilih Semua",
    deselectAll: "Batal Pilih Semua",
    milestoneCheck: "Pengecekan Milestone",
    milestoneCheckDescription:
      "Gunakan daftar ini untuk melihat apakah anak-anak Anda telah mencapai milestone yang diharapkan. Silakan lakukan ini di antara pelajaran atau pada akhir unit Anda.",
    milestoneCheckWithCheckpoint: "Pengecekan Milestone (Checkpoints)",
    pleaseNavigateToLastPageToClickDone: `Harap navigasi ke halaman terakhir untuk mengakses dan klik tombol "Selesai".`,

    // Review
    noReview: "Belum ada ulasan",
    noContent: "Belum ada konten",
    writeAReview: "Tulis ulasan",
    shareReview:
      "Apa yang Anda sukai dari proyek ini, dan/atau apa yang berhasil untuk Anda?",
    feedbackToDevTeam:
      "Ada umpan balik atau saran yang ingin Anda berikan untuk membantu kami membuat proyek ini lebih baik?",
    thankyouFeedback: "Terima kasih atas umpan balik Anda!",
    viewReviews: "Lihat Semua Ulasan",
    thanksReviews: "Terima kasih atas ulasan Anda!",
    reviewPosted: "Ulasan diposting!",
    reviewUpdated: "Ulasan diperbarui!",
    reviewDeleted: "Ulasan dihapus!",
    tryAgainLater: "Silakan coba lagi nanti",
    deleteReview: "Hapus ulasan",
    replyTo: "Balas ke {name}...",
    writeAComment: "Tulis komentar...",
    deleteComment: "Hapus komentar?",
    commentDeleted: "Komentar dihapus!",
    edited: "Diedit",
    like: "Suka",
    liked: "Disukai!",
    unLiked: "Tidak disukai!",
    reply: "Balas",
    comment: "Komentar",
    addPhotos: "Tambahkan Foto",
    mostRecent: "Paling Terbaru",
    mostLiked: "Paling Disukai",

    // Project Summary Page
    projectSummary: "Ringkasan Proyek",
    projectSummaries: "Ringkasan Proyek",
    projectSummaryReport: "Laporan Ringkasan Proyek",
    backProject: "Kembali ke Proyek",
    congrats: "Selamat!",
    completedProject: "Anda telah menyelesaikan proyek.",
    photoAlbum: "Album Foto",
    rate: "Beri penilaian pada pengalaman Anda",
    howProjectShareExperience: "Bagaimana proyek ini? Bagikan pengalaman Anda!",
    beforeDownload: "Sebelum mengunduh",
    beforeGo: "Sebelum pergi",
    recoProj: "Proyek yang Direkomendasikan",
    completedCP: "Checkpoint Selesai:",
    downloadReport: "Unduh Laporan",
    openReport: "Buka Laporan",
    generatingReport: "Menghasilkan Laporan",
    viewMore: "Lihat Lebih Banyak",
    viewAll: "Lihat Semua",
    reGenerateReport: "Hasilkan ulang laporan Anda sekarang",
    getReport: "Unduh laporan kustom Anda sekarang",
    photos: "Foto",
    videos: "Video",
    totalNumberLessons: "Total Jumlah Pelajaran",

    // Project Report PDF
    projectOverview: "Ikhtisar Proyek",
    presentedBy: "Dipresentasikan dengan bangga oleh:",
    congratsText: "atas keberhasilan proyek",
    changeTemplate: "Ganti Template",
    pickImage: "Pilih Gambar",
    pickTemplate: "Pilih Template",

    // Backpack Page
    noenrolledChild: "Anda tidak memiliki anak yang terdaftar",
    noBackpack: "Tidak ada item Ransel",
    favourites: "Favorit",
    addFavourites: "Tambahkan ke Favorit",
    noFavourites:
      "Belum ada item di sini. Pergi ke perpustakaan kami untuk menemukan proyek favorit Anda!",

    // Analytic
    sevenDays: "7 hari terakhir",
    thirtyDays: "30 hari terakhir",
    oneYear: "1 Tahun",
    allTime: "Sepanjang waktu",

    //portfoliopage
    age: "Usia",
    year: "tahun",
    years: "tahun",
    month: "bulan",
    months: "bulan",
    appraisal: "Penilaian Guru secara Keseluruhan untuk {name}",
    noAppraisal: "Tidak ada penilaian",
    presentGoals: "Tujuan saat ini untuk {name}",
    goalHistory: "Lihat riwayat tujuan",
    noGoals: "Tidak ada tujuan",
    headProgress: "Progres {name} pada {tab}",
    progressHistory: "Lihat riwayat progres",
    latestAccom: "Pencapaian Terbaru",
    accomHistory: "Lihat riwayat pencapaian",
    improve: "Area untuk perbaikan",
    projectDone: "Proyek yang diselesaikan oleh {name}",
    allDetails: "Lihat semua detail",
    hideDetails: "Sembunyikan semua detail",
    pastProject: "Lihat semua proyek sebelumnya",
    recoAct: "Aktivitas yang direkomendasikan untuk perbaikan {name}",
    noCompletedProject: "Tidak ada proyek yang diselesaikan",
    downloadpdf: "Unduh laporan PDF",
    showLesson: "Tampilkan Pelajaran",
    hideLesson: "Sembunyikan Pelajaran",
    portfolio: "Portofolio",
    childPortfolio: "Portofolio {name}",
    teacherNameArchive: "Arsip {name}",
    developmentalGoals: "Tujuan Pengembangan",
    progressReport: "Laporan Kemajuan",
    completedWorks: "Karya Kecil",
    createNew: "Buat Baru",
    selectDuration: "Pilih Durasi",
    names: "{name}'s",
    learningJourney: "Perjalanan Belajar",
    classInfo: "Informasi Kelas",
    noChildAssigned: "Tidak ada anak yang ditugaskan ke kelas",
    noTeacherAssigned: "Tidak ada guru yang ditugaskan ke kelas",
    observation: "Observasi",
    addObservation: "Tambahkan Observasi",
    milestoneCompleted: "Tercapai",
    nextMilestone: "Tahap Berikutnya",
    whatsNext: "Apa yang selanjutnya?",
    takeAction: "Ambil tindakan!",
    noReflections: "Belum ada refleksi, mengapa tidak menulis satu?",
    noTestimonials:
      "Belum ada testimoni, mengapa tidak meminta rekan-rekan Anda untuk menulis satu untuk Anda?",
    noTestimonialsVisitor: "Belum ada testimoni, mengapa tidak menulis satu?",
    noCertifications:
      "Belum ada sertifikasi, beralihlah ke Akademi Pelatihan Guru untuk mendapatkan sertifikasi!",
    visible: "Terlihat",
    hidden: "Tersembunyi",
    attained: "Tercapai",
    notAttained: "Belum Tercapai",
    published: "Dipublikasikan",
    unPublished: "Tidak Dipublikasikan",
    dateObserved: "Tanggal Diamati",
    deleteObservation: "Hapus Observasi",
    reachHighestMilestone:
      "Selamat! Anda telah mencapai pencapaian tertinggi dalam tujuan pengembangan ini.",
    findNewProject: "Temukan proyek baru di perpustakaan kami!",
    parentView: "Tampilan Orang Tua",
    whatIsParentView: "Apa itu Tampilan Orang Tua",
    nextMilestoneIsHidden: "Tahap berikutnya tersembunyi",
    blankSubDevGoalAreHidden: "Tujuan pengembangan sub kosong tersembunyi",
    albums: "Album",
    addNewAlbum: "Tambah Album Baru",
    noAttainedMilestone: "Tidak ada tahap yang dicapai!",
    congratulation: "Selamat!",
    milestoneCompletedCongratulation:
      "Hore! Anda telah mencapai pencapaian tahap Anda!",
    showDevGoalWithRecordedLearningMoment:
      "Nyalakan 'Tampilan Orang Tua' untuk menampilkan hanya tujuan pengembangan dengan momen pembelajaran yang tercatat!",
    filterAndShowParent:
      "Anda juga dapat memilih untuk menyaring dan menampilkan momen pembelajaran orang tua yang terjadi selama periode waktu tertentu.",
    learningStories:"Cerita Pembelajaran",

    // Portfolio PDF
    selectProgressReportDateRange:
      "Silakan pilih rentang tanggal laporan kemajuan.",
    introductionText: `Portofolio penilaian menggabungkan gambar, catatan anekdotal, dan rubrik untuk mendukung pendekatan pembelajaran berbasis anak dan berbasis kekuatan di Trehaus School. Sementara gambar dan catatan anekdotal memberikan pandangan hidup tentang bagaimana anak menumbuhkan Tangan dan Hati mereka, rubrik kecakapan mencerminkan prestasi seorang anak dalam kaitannya dengan standar untuk semester sekolah dalam pengetahuan Kepala. Melalui berbagai cara dan strategi yang beragam, pembelajaran siswa dibuat terlihat, dan keberhasilan mereka dirayakan.`,
    littleProjectsAccomplished: "Proyek Kecil Diselesaikan",
    littleLearnersAccomplished: "Pelajar Kecil Diselesaikan",
    photoGallery: "Galeri Foto",
    startedOn: "Dimulai Pada",
    completedOn: "Diselesaikan Pada",
    observedOn: "Diamati Pada",
    profileShowcased:
      "{profileName} memamerkan nilai-nilai ini selama momen pembelajaran di bawah ini:",
    profileDemonstrates:
      "Sebagai anak Trehaus, {profileName} menunjukkan kompetensi dalam kategori kerangka kurikulum kami dengan pencapaian paling menonjol adalah:",
    concludingRemarks: "Ucapan Penutup",
    sneakPeek: "Tatapan Cepat",
    goals: "Tujuan",
    thankYouBeingJourney:
      "Terima kasih telah menjadi bagian dari perjalanan saya di {className} {year}!",
    myTeachers: "Guru-guru Saya",

    // Create child page
    preferred_name: "Nama yang Disukai",
    full_name: "Nama Lengkap",
    dob: "Tanggal Lahir",
    image: "Gambar",
    submit: "Kirim",
    not_logged_in: "Anda tidak masuk!",

    // Class Page -> Group === Class
    group_name: "Nama Kelas",
    create_group: "Buat Kelas",
    addGroup: "Tambahkan Kelas",
    edit_group: "Edit Kelas",
    group: "Kelas",
    assignChildrenToGroup: "Tugaskan Anak ke Kelas",
    childrenInGroup: "Anak-anak dalam Kelas",
    assignTeacherToGroup: "Tugaskan Guru ke Kelas",
    teacherInGroup: "Guru dalam Kelas",

    // Enroll Child Page
    child: "Anak",
    project: "Proyek",
    enroll_child: "Daftarkan anak ke proyek",
    module: "Modul",
    unit: "Unit",

    // Project Page - Edit + Create
    editing: "Mengedit",
    managing_proj: "Mengelola Proyek",
    projectEditor: "Edit Proyek",
    create_new_proj: "Buat proyek baru",
    create_project: "Buat Proyek",
    create_assessment: "Buat penilaian baru",
    proj_for_child: "Proyek untuk anak-anak",
    sproutsExplainationPartOne: "Umumnya 18 bulan hingga 2 tahun, ",
    sproutsExplainationPartTwo:
      "anak-anak mulai mengambil informasi baru dari dunia sekitar mereka.",
    budsExplainationPartOne: "Umumnya 3 hingga 4 tahun, ",
    budsExplainationPartTwo:
      "anak-anak memiliki beberapa pengetahuan dan sedang belajar keterampilan baru untuk mengaplikasikan informasi baru ini.",
    blossomsExplainationPartOne: "Umumnya 5 hingga 6 tahun, ",
    blossomsExplainationPartTwo:
      "mereka memiliki pengetahuan dan keterampilan serta aktif mengaplikasikan dan berlatih menggunakannya.",
    sprouts: "Tunas",
    sprouts_with_age: "Tunas (18 bulan - 2 tahun)",
    sproutlings_with_age: "Tunas (2 tahun - 3 tahun)",
    buds_with_age: "Kuncup (3 tahun - 4 tahun)",
    blossoms_with_age: "Bunga (5 tahun - 6 tahun)",
    blooms_with_age: "Bunga (5 tahun - 6 tahun)",
    sprouts_with_age_external: "Tunas (18 bulan - 2 tahun)",
    blossoms_with_age_external: "Bunga (5 tahun - 6 tahun)",
    programme: "Program",
    proj_name: "Nama Proyek",
    level: "Tingkat",
    basic: "Dasar",
    intermediate: "Menengah",
    advanced: "Lanjutan",
    proj_duration: "Durasi yang Diharapkan (menit)",
    proj_description: "Deskripsi Proyek",
    learning_objectives: "Tujuan Pembelajaran",
    publish: "Terbitkan",
    saveAsDraft: "Simpan sebagai draf",
    recall: "Panggilan",
    recalled: "Terpanggil",
    success_remove_project: "Proyek berhasil dihapus!",
    success_remove_unit_guide: "Panduan Sumber Daya berhasil dihapus!",
    projectTags: "Tag Proyek",
    learningObjectives: "Tujuan Proyek",
    createAndManageModules: "Buat dan kelola modul",
    addObjective: "Tambahkan Tujuan",

    // Lessons
    activity: "Aktivitas",
    activity_name: "Nama Aktivitas",
    add_activity: "Tambahkan Aktivitas",
    success_remove_activity: "Aktivitas berhasil dihapus!",
    activity_duration_min: "Durasi Aktivitas (menit)",
    attachment_if_any: "Lampiran (jika ada)",
    attachment_if_copy: `Lampiran (centang kotak untuk menambahkan lampiran ke "Dokumen")`,
    add_attachement_into_doc: `Tambahkan lampiran ke 'Dokumen'`,
    attachments: "Lampiran",
    lesson_name: "Nama Pelajaran",
    lesson_description: "Deskripsi Pelajaran",
    lesson_objective: "Tujuan Pelajaran",
    lesson_objectives: "Tujuan Pelajaran",
    addLessonObjective: "Tambahkan Tujuan Pelajaran",
    lesson_intention: "Niat Pelajaran",
    lessonToolbox: "Toolbox Pelajaran",
    expected_duration_mins: "Durasi yang Diharapkan (menit)",
    key_vocabulary: "Kosa Kata Kunci",
    material: "Bahan",
    materials: "Bahan",
    materials_required: "Bahan yang Diperlukan",
    recommendedSetupInstructionIfAny:
      "Petunjuk Penyiapan yang Direkomendasikan (jika ada)",
    setupInstruction: "Petunjuk Penyiapan",
    image_set: "Set Gambar",

    // Activity
    notes: "Catatan",
    stickyNote: "Catatan Perekat",
    activityStepInstruction: "Langkah {weight} Petunjuk:",
    activityStep: "Langkah {weight}",
    editing_activity: "Mengedit Aktivitas",
    creating_activity: "Membuat Aktivitas",
    remark: "Catatan",
    remarks: "Catatan",
    compulsory: "Wajib",
    cancel: "Batal",
    skip: "Lewati",
    create: "Buat",
    delete_activity: "Hapus Aktivitas",
    success_reorder_activities: "Berhasil menyusun ulang aktivitas!",
    success_move_activity: "Berhasil memindahkan aktivitas!",
    question: "Pertanyaan",

    //Checkpoint
    checkpoint: "Titik Pengecekan",

    //Learning Moment
    isLearningMoment: "Ada momen pembelajaran?",
    learningMomentPhoto: "Foto Momen Pembelajaran",
    learningMomentQuestion: "Pertanyaan Momen Pembelajaran",

    //Objective
    adding_curriculum_fundamental_to: "Menambahkan Dasar Kurikulum ke",
    editing_curriculum_fundamental_of: "Mengedit Dasar Kurikulum dari",
    objective_str: "Tujuan",
    add_objective: "Tambahkan Tujuan",
    delete_fundamental: "Hapus Dasar",
    fundamental_str: "Dasar",

    //Login
    forgottenPassword: "Lupa kata sandi?",
    findYourAccount: "Temukan Akun Anda",
    enterYourEmail:
      "Silakan masukkan alamat email Anda untuk mencari akun Anda.",
    pleaseCheckYourEmailAndPassword:
      "Harap periksa apakah email dan kata sandi Anda benar.",
    emailSubmittedTitle: "Permintaan Pengaturan Ulang Kata Sandi Diajukan",
    emailSubmittedResponse:
      "Jika email yang Anda berikan ada dalam sistem kami, kami telah mengirimkan tautan untuk mengatur ulang kata sandi ke sana. Harap periksa kotak masuk dan folder spam Anda.",

    //Register
    email: "Email",
    mobile_number: "Nomor Ponsel",
    password: "Kata Sandi",
    already_have_account_login: "Sudah memiliki akun? Masuk",
    role: "Peran",

    //Reset
    resetYourPassword: "Atur Ulang Kata Sandi Anda",

    //Admin Page
    add: "Tambahkan",
    actions: "Tindakan",
    siteAdmin: "Admin Situs",
    contentEditor: "Editor Konten",
    ttaContentEditor: "Editor Konten TTA",
    schoolAdmin: "Admin Sekolah",
    paymentRecord: "Catatan Pembayaran",

    //Questionnaire
    createInterest: "Buat minat",
    createPreDQQuestion: "Buat pertanyaan kuesioner pra-penemuan",
    pickLanguage: "Pilih pilihan bahasa Anda",
    selectModules: `Pilih modul yang akan Anda ajarkan`,
    selectDevelopmentalAreas: `Pilih area perkembangan utama (Direkomendasikan: 5 Teratas)`,
    selectInterests: `Pilih minat yang paling menarik untuk kelas {groupName}`,
    weight: "Urutan",
    name: "Nama",
    priority: "prioritas",

    //Quick questions
    welcomeToTTB: "Selamat datang di Teaching Tool Box kami!",
    everyChildIsUnique: "Setiap Anak Unik",
    introFirstParagraph:
      "Kurikulum Trehaus percaya dalam menyediakan pelajaran yang menarik, bermakna, dan berbeda untuk setiap pelajar. Menyadari bahwa setiap anak unik dan berkembang pada kecepatan mereka sendiri, kami telah membuat tiga tingkatan pembelajaran yang membantu guru menyusun pelajaran sesuai tahap pembelajaran saat ini anak-anak mereka.",
    sproutsDescription:
      "Umumnya untuk anak-anak berusia 18 bulan hingga 2 tahun, Anak-anak Sprouts baru mulai belajar tentang dunia, mengembangkan akar mereka, dan mulai mengambil pengetahuan baru.",
    budsDescription:
      "Umumnya untuk anak-anak berusia 3 hingga 4 tahun, Anak-anak Buds memiliki beberapa pengetahuan dan belajar keterampilan baru untuk mengaplikasikan informasi baru ini.",
    blossomsDescription:
      "Umumnya untuk anak-anak berusia 5 hingga 6 tahun, Anak-anak Blossoms memiliki pengetahuan dan keterampilan, dan secara aktif mengaplikasikan dan berlatih menggunakannya.",
    introLastParagraph:
      "Sebelum menjelajahi perpustakaan rencana pelajaran kami, harap luangkan beberapa menit untuk menjawab Pertanyaan Cepat ini sehingga kami dapat merekomendasikan proyek sesuai dengan tahap pertumbuhan saat ini anak Anda.",
    myChildInterests: "Anak-anak saya tertarik pada…",
    myChildInterestsSub: "(pilih sebanyak yang Anda suka!)",
    myChildGoals: "Anak-anak saya bisa mendapat manfaat dari…",
    myChildGoalsSub: "(pilih tujuan yang ingin Anda kerjakan!)",
    myChildCommunicationMethod:
      "Anak-anak saya berkomunikasi dengan menggunakan…",
    myChildCommunicationMethodSub:
      "(terlepas dari bahasa, pilih apa yang digunakan anak Anda!)",
    concludingTextFirst:
      "Hebat! Berdasarkan jawaban Anda… anak-anak Anda berada pada tingkat {0}.",
    concludingTextLast:
      "Buka perpustakaan rencana pelajaran kami untuk melihat proyek apa yang kami rekomendasikan untuk Anda.",
    quickQuestionsCompleted: "Pertanyaan Cepat Selesai!",
    levelAnswer1: "Gerakan dan Suara",
    levelAnswer2: "Satu hingga Dua Kata",
    levelAnswer3: "Frasa Pendek",
    levelAnswer4: "Kalimat Sederhana",
    levelAnswer5: "Kalimat Rinci",
    loading: "Memuat",
    intro: "Pendahuluan",

    //Access Control
    school: "Sekolah",
    schools: "Sekolah-sekolah",
    search: "Cari",
    bulkAction: "Tindakan Massal",
    editProject: "Edit Proyek",

    //Manage project page
    startDate: "Tanggal Mulai",
    endDate: "Tanggal Selesai",

    //Ticketing
    subject: "Subjek",
    itSupportFeedback: "Dukungan TI - Umpan Balik",
    feedback: "Umpan Balik",
    next: "Berikutnya",

    //Payments
    payment: "Pembayaran",
    subscription: "Langganan",
    subscriptions: "Langganan",
    paymentHistory: "Riwayat Pembayaran",
    noSubscribe: "Belum ada produk yang diikuti!",
    noProduct: "Tunggu produk ini!",
    paymentTitleSchool: "Pilih rencana yang tepat untuk sekolah Anda",
    paymentTitleParent: "Pilih rencana yang tepat untuk keluarga Anda",
    selectPlan: "Pilih rencana",
    startFreeTrial: "Mulai uji coba gratis 7 hari",
    sevenDayTrial: "Uji coba 7 hari",
    manageOrUpdatePlan: "Kelola atau Perbarui Rencana",
    paymentTitle: "Pilih rencana yang tepat",
    subscribedProduct: "Produk yang diikuti",
    onlySchoolAdmin:
      "Silakan masuk sebagai admin sekolah untuk melihat rencana langganan!",
    subscribed: "Diikuti",
    noRenew: "Tidak ada Perpanjangan",
    willRenew: "Akan Diperbarui",
    noProject: "Tidak ada proyek ditemukan",
    pleaseSubscribe:
      "Silakan berlangganan ke rencana kami atau hubungi admin untuk informasi lebih lanjut",
    addPaymentRecord: "Tambahkan Catatan Pembayaran",
    canViewProjects: "Dapat Melihat Proyek",
    createPaymentRecord: "Buat Catatan Pembayaran",
    customerId: "ID Pelanggan",
    bypassPayment: "Lewati Pembayaran",
    demoSchool: "Sekolah Demo",
    subscribeToViewProject: "Berlangganan untuk melihat proyek",
    oopsDemoAccount:
      "Ups! Ini adalah akun demo. Daftar di LittleLab.com untuk pengalaman penuh!",

    //Calendar
    calendarViewWeek: "Minggu",
    calendarViewMonth: "Bulan",
    calendarViewYear: "Tahun",
    january: "Januari",
    february: "Februari",
    march: "Maret",
    april: "April",
    may: "Mei",
    june: "Juni",
    july: "Juli",
    august: "Agustus",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Desember",

    //Timetable
    timetable: "Jadwal",
    today: "Hari ini",
    thisWeek: "Minggu ini",
    editTimetable: "Edit Jadwal",
    addNewEvent: "Tambah Acara Baru",
    editEvent: "Edit Acara",
    discardEdits: "Buang Perubahan",
    saveChanges: "Simpan Perubahan",
    noChanges: "Tidak ada Perubahan",
    timetableUpdated: "Jadwal Diperbarui!",
    eventTitle: "Judul Acara",
    eventType: "Jenis Acara",
    dayOfWeek: "Hari dalam Seminggu",
    startingTime: "Waktu Mulai",
    endingTime: "Waktu Selesai",
    timetableTitle: "Jadwal {name}",
    shareTimetable: "Bagikan Jadwal?",
    copyShareLink: "Salin Tautan Bagikan",
    shareLinkCopied: "Tautan Bagikan Disalin!",
    timetableShared: "Siapa pun dengan tautan dapat melihat jadwal Anda.",
    timetableUnshared: "Hanya Anda yang dapat melihat jadwal Anda.",
    defaultTimetableButtonTitle:
      "Buat rencana kelas Anda dengan Jadwal Visual kami!",
    uploadedTimetableButtonTitle:
      "Unggah rencana Anda sendiri untuk referensi Anda!",
    uploadNewTimetable: "Unggah jadwal baru",
    removeTimetable: "Hapus jadwal",
    noUploadedTimetable: "Tidak ada jadwal yang diunggah",
    uploadYourTimetable: "Unggah gambar jadwal Anda sendiri",
    uploading: "Mengunggah",

    successAddEvent: "Acara baru berhasil ditambahkan!",
    failAddEvent: "Gagal menambahkan acara baru. Harap coba lagi nanti!",
    successUpdateEvent: "Acara berhasil diperbarui!",
    failUpdateEvent: "Gagal memperbarui acara. Harap coba lagi nanti!",
    successDeleteEvent: "Acara berhasil dihapus!",
    failDeleteEvent: "Acara tidak dapat dihapus. Harap coba lagi nanti!",
    successDuplicateEvent: "Acara berhasil diduplikasi!",
    failDuplicateEvent: "Acara tidak dapat diduplikasi. Harap coba lagi nanti!",
    successUploadTimetable: "Jadwal berhasil diunggah!",
    failUploadTimetable: "Gagal mengunggah jadwal. Harap coba lagi nanti!",
    successUpdateTimetable: "Jadwal berhasil diperbarui!",
    failUpdateTimetable: "Gagal memperbarui jadwal. Harap coba lagi nanti!",
    successDeleteTimetable: "Jadwal berhasil dihapus!",
    failDeleteTimetable: "Jadwal tidak dapat dihapus. Harap coba lagi nanti!",
    noEventToday: "Tidak ada acara hari ini",

    // Chat
    messages: "Pesan",
    newChat: "Obrolan Baru",
    newClassChat: "Obrolan Kelas Baru",
    selectContact: "Pilih Kontak",
    startANewChat: "Mulai obrolan baru",
    startANewClassChat: "Mulai obrolan kelas baru",
    typeYourMsgHere: "Ketik pesan Anda di sini...",
    noNewMessage: "Tidak ada pesan baru",

    // Developmental Goal
    manageDevelopmentalGoals: "Kelola Tujuan Pengembangan",
    developmentalGoal: "Tujuan Pengembangan",
    subDevelopmentalGoal: "Sub Tujuan Pengembangan",

    addDevelopmentalGoal: "Tambahkan Tujuan Pengembangan {tab}",
    editingDevelopmentalGoal: "Mengedit Tujuan Pengembangan",
    deleteDevelopmentalGoal: "Hapus Tujuan Pengembangan",
    createdDevelopmentalGoal: "Tujuan Pengembangan berhasil dibuat!",
    updatedDevelopmentalGoal: "Tujuan Pengembangan berhasil diperbarui!",
    removedDevelopmentalGoal: "Tujuan Pengembangan berhasil dihapus!",

    addSubDevelopmentalGoal: "Tambahkan Sub Tujuan Pengembangan {tab}",
    editingSubDevelopmentalGoal: "Mengedit Sub Tujuan Pengembangan",
    deleteSubDevelopmentalGoal: "Hapus Sub Tujuan Pengembangan",
    createdSubDevelopmentalGoal: "Sub Tujuan Pengembangan berhasil dibuat!",
    updatedSubDevelopmentalGoal: "Sub Tujuan Pengembangan berhasil diperbarui!",
    removedSubDevelopmentalGoal: "Sub Tujuan Pengembangan berhasil dihapus!",

    // Still Finding Out
    stillFindingOut: "Kami masih mencari tahu!",
    stillFindingOutHeadLibrary:
      "Tidak ada momen pembelajaran yang tercatat di sini... Pergi ke Perpustakaan untuk memulai proyek dan menciptakan momen pembelajaran!",
    noObservations: "Tidak ada observasi yang tercatat",
    noCaption: "Tanpa keterangan",
    title: "Judul",
    subtitle: "Subjudul",
    milestone: "Milestone",
    successReorderMilestone:
      "Milestone berhasil diurutkan kembali dengan sukses!",
    addMilestone: "Tambahkan Milestone",
    deleteMilestone: "Hapus Milestone",
    milestoneNo: "Milestone {number}",
    photoInstruction: "Petunjuk Foto",
    photoCaption: "Keterangan Foto",
    sixMonthNoObservation:
      "Sudah lama sejak observasi terakhir Anda... mari lakukan momen pembelajaran lain untuk melihat kemajuan!",

    // Prohibited Word
    prohibitedWords: "Kata-kata Terlarang",
    manageProhibitedWords: "Kelola Kata-kata Terlarang",
    addProhibitedWords: "Tambahkan Kata-kata Terlarang",

    // Completed Work
    addCompletedWork: "Tambahkan Pekerjaan Kecil",
    uploadChildrenCompletedWork:
      "Unggah Pekerjaan yang Telah Selesai Anak-anak",

    // Teacher Portfolio
    byClass: "Menurut Kelas",
    byYear: "Menurut Tahun",
    byProject: "Menurut Proyek",

    // WIP
    underConstruction:
      "Ups! Halaman ini masih dalam konstruksi. Tetap bersemangat!",

    // Parent Dashboard
    nowLearning: "Sedang belajar",

    // Other Components
    updateBackpack: "Perbarui ransel",
    updateFavorite: "Perbarui Favorit",
    templateFor: "Template untuk",
    activitiesToBeConducted: "kegiatan yang akan dilakukan",
    loadingDots: "Sedang memuat...",
    youNeedToEnrollYourGroup:
      "Anda perlu mendaftarkan grup Anda untuk mengakses",
    selectDropAttachments: "Pilih atau seret lampiran",
    selectDropActivity: "Pilih atau seret gambar kecil aktivitas",
    selectDropLesson: "Pilih atau seret gambar kecil pelajaran",
    translateThisLessonTo: "Terjemahkan pelajaran ini ke",
  },
});

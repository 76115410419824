import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { translation } from "constants/translation";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { createBackpackItem } from "api/backpack-api";
import Button, { AddToBagButton } from "components/Button/Button";
import {
  ASSESSMENT_PAGE,
  CURLANG,
  LESSON_ACTIVITY_PAGE,
  PROJECT_SUMMARY_PAGE,
} from "constants/url";
import Image from "components/Image/Image";
import { TabPanel } from "containers/ProjectPage/LessonScreens/CanvasComponents/Content";
import { staticTabStyle } from "containers/ProjectSummaryPage/Screens/ConceptScreen";
import {
  API_Project,
  API_Project_Fundamental,
  API_Project_Lesson,
} from "types/project";
import useModal from "hooks/useModal";
import ScheduleGroupPageModal from "containers/CalendarPage/ScheduleGroupPageModal";

export const CustomModal = ({
  open,
  handleClose,
  children,
}: {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      sx: {
        backgroundColor: "rgba(49,47,48,0.4)",
      },
    }}
    sx={{
      m: "24px",
    }}
  >
    <Fade in={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "background.paper",
          p: { xs: 2, sm: 3 },
          borderRadius: "10px",
          overflow: "auto",
          maxHeight: "100%",
          color: "#312F30",
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        {children}
      </Box>
    </Fade>
  </Modal>
);

export const Title = ({ title }: { title: string }) => (
  <Typography
    sx={{
      fontSize: "1.125rem",
      fontWeight: "bold",
      lineHeight: 1.25,
    }}
  >
    {title}
  </Typography>
);

export const Duration = ({
  number,
  unit,
}: {
  number: number;
  unit: string;
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 0.75,
    }}
  >
    <AccessTimeIcon fontSize="small" />

    <Typography>
      {number} {unit}
    </Typography>
  </Box>
);

export const Description = ({ description }: { description: string }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 0.5,
    }}
  >
    {/* <Typography
      sx={{
        fontWeight: "bold",
        lineHeight: 1.25,
        fontSize: 14,
      }}
    >
      {translation.description}
    </Typography> */}

    <Box
      sx={{
        fontSize: 14,
        "& figure": {
          display: "none",
        },
      }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </Box>
);

export const CustomImage = ({ src, name }: { src: string; name: string }) => (
  <Image
    src={src}
    objectFit="cover"
    alt={name}
    style={{
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      overflow: "hidden",
      flexShrink: 0,
    }}
  />
);

export const ViewProjectButton = ({ slug, assessment }: { slug: string, assessment: boolean }) => {
  const navigate = useNavigate();

  const { currLanguage } = useStore((state) => state);

  const handleClick = () => { 
    if (assessment) { 
      navigate(`${CURLANG(currLanguage)}/${ASSESSMENT_PAGE(slug)}`);
    } else {
      navigate(`${CURLANG(currLanguage)}/${PROJECT_SUMMARY_PAGE(slug)}`);
    }
  }
    

  return (
    <Button
      arrow={false}
      buttonText={assessment ? translation.viewAssessmentDetails : translation.viewProjectDetails}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        handleClick();
      }}
      style={{
        width: "100%",
        fontWeight: 500,
        textTransform: "capitalize",
      }}
    />
  );
};

export const BackpackButton = ({
  groupID,
  projectID,
}: {
  groupID: number;
  projectID: number;
}) => {
  const openSnack = useSnack();

  const { setRefreshGroupBackpack } = useStore((state) => state);

  const addProject = async (projectID: number, groupID: number) => {
    const res = await createBackpackItem(projectID, groupID);
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      setRefreshGroupBackpack(true);
      openSnack(translation.addedToBackpack, true);
    }
  };

  return (
    <AddToBagButton
      btnText={translation.addToBackpack}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (groupID !== -1) {
          addProject(projectID, groupID);
        } else {
          openSnack(translation.noGroupAssigned, false);
        }
      }}
    />
  );
};

export const ScheduleProjectButton = ({
  project,
}: {
  project: API_Project;
}) => {
  const { currGroup } = useStore((state) => state);

  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <ScheduleGroupPageModal
        open={open}
        handleClose={handleClose}
        projectId={project.id}
        projectTitle={project.name}
        assessment={project.assessment}
        groupId={currGroup.id}
        projectSlug={project.slug}
      />

      <Button
        arrow={false}
        buttonText={project.assessment ? "Schedule Assessment" : translation.scheduleProject}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          handleOpen();
        }}
        style={{
          width: "100%",
          fontWeight: 500,
          textTransform: "capitalize",
        }}
      />
    </>
  );
};

export const CustomTabs = ({
  tabIndex,
  handleTabChange,
  tabs,
  isLonger = false,
}: {
  tabIndex: number;
  handleTabChange: (event: React.SyntheticEvent, index: number) => void;
  tabs: {
    name: string;
    content: React.ReactNode;
  }[];
  isLonger?: boolean;
}) => (
  <Box
    sx={{
      mt: 1,
    }}
  >
    <Tabs
      value={tabIndex}
      onChange={handleTabChange}
      variant="fullWidth"
      TabIndicatorProps={{ style: { display: "none" } }}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.name}
          sx={{
            ...staticTabStyle,
            ml: index === 0 ? 0 : 1,
            backgroundColor:
              index === tabIndex ? "primary.light" : "transparent",
            pointerEvents: index === tabIndex ? "none" : "auto",
          }}
        />
      ))}
    </Tabs>

    {tabs.map((tab, index) => (
      <TabPanel
        key={index}
        value={tabIndex}
        index={index}
        sx={{
          border: "2px solid var(--primary-main)",
          backgroundColor: "background.paper",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          overflowY: "auto",
          overflowX: "hidden",
          height: isLonger ? "485px" : "255px",
        }}
      >
        {tab.content}
      </TabPanel>
    ))}
  </Box>
);

export const NoContent = ({ title }: { title: string }) => (
  <Typography sx={{ textAlign: "center" }}>{title}</Typography>
);

export const FundamentalCard = ({
  objectiveFundamental,
}: {
  objectiveFundamental: API_Project_Fundamental;
}) => {
  const { fundamentalDict } = useStore((state) => state);

  const objectiveFundamentalName =
    fundamentalDict[objectiveFundamental.fundamental].name;

  const objectiveFundamentalImage =
    fundamentalDict[objectiveFundamental.fundamental].image;

  const filteredMilestones = fundamentalDict[
    objectiveFundamental.fundamental
  ].milestones.filter(
    (milestone) => milestone.id === objectiveFundamental.milestone
  );

  const objectiveFundamentalMilestoneName =
    filteredMilestones.length > 0 ? filteredMilestones[0].name : "";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        p: 2,
        borderRadius: "10px",
        backgroundColor: "#98C4C5",
        boxShadow: 1,
        overflow: "hidden",
        width: 160,
      }}
      onClick={() => console.log(objectiveFundamental)}
    >
      <Box
        sx={{
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          backgroundColor: "white",
          overflow: "hidden",
          margin: "0 auto",
          padding: "16px",
        }}
      >
        <Image
          src={objectiveFundamentalImage ? objectiveFundamentalImage : ""}
          alt={objectiveFundamentalName}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            display: "flex",
          }}
        />
      </Box>

      <Typography
        sx={{
          textAlign: "center",
          fontSize: 14,
          lineHeight: 1.25,
          mt: 1,
        }}
      >
        {objectiveFundamentalName.toUpperCase()}
      </Typography>

      <Typography
        sx={{
          textAlign: "center",
          fontSize: 12,
          lineHeight: 1.25,
        }}
      >
        {objectiveFundamentalMilestoneName}
      </Typography>
    </Box>
  );
};

export const LessonDetailCard = ({
  lesson,
  index,
  isInBag,
  isInSchedule,
}: {
  lesson: API_Project_Lesson;
  index: number;
  isInBag: boolean;
  isInSchedule: boolean;
}) => {
  const navigate = useNavigate();

  const { currLanguage } = useStore((state) => state);

  const activitySlug =
    lesson.activities.length > 0 ? `activity/${lesson.activities[0].id}` : "";

  return (
    <Tooltip
      title={isInBag && isInSchedule ? "" : translation.addOrScheduleItToView}
      placement="top"
      followCursor
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          backgroundColor: "#F0F0F0",
          boxShadow: 1,
          overflow: "hidden",
          cursor: isInBag && isInSchedule ? "pointer" : "not-allowed",
          transition: "all 0.1s ease-in-out",
          "&:hover": {
            boxShadow: 2,
            transition: "all 0.1s ease-in-out",
          },
          width: "100%",
          maxWidth: 220,
        }}
        onClick={() =>
          isInBag &&
          isInSchedule &&
          navigate(
            `${CURLANG(currLanguage)}/${LESSON_ACTIVITY_PAGE(
              lesson.project_slug,
              lesson.id.toString()
            )}/${activitySlug}`
          )
        }
      >
        <Image
          src={lesson.images.length > 0 ? lesson.images[0].file : ""}
          alt={lesson.name}
          style={{
            width: "100%",
            height: "100px",
            objectFit: "cover",
            display: "flex",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.75,
            p: 1.5,
            flex: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: 1,
            }}
          >
            {translation.formatString(translation.lessonNo, {
              number: index + 1,
            })}
          </Typography>

          <Typography
            sx={{
              fontSize: 14,
              lineHeight: 1.25,
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              lineClamp: "2",
              overflow: "hidden",
            }}
          >
            {lesson.name}
          </Typography>
          <Box />

          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              justifyContent: "center",
              mt: "auto",
            }}
          >
            <IconWithText
              number={lesson.duration || "?"}
              unit={translation.min}
              icon={<AccessTimeIcon fontSize="small" />}
            />

            <Box
              sx={{
                borderRight: "1px solid black",
              }}
            />

            <IconWithText
              number={lesson.activities.length}
              unit={
                lesson.activities.length > 1
                  ? translation.activities
                  : translation.activity
              }
              icon={<AssignmentOutlinedIcon fontSize="small" />}
            />
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

const IconWithText = ({
  number,
  unit,
  icon,
}: {
  number: number | string;
  unit: string;
  icon: React.ReactNode;
}) => (
  <Box
    sx={{
      display: "flex",
      gap: 0.5,
      alignItems: "center",
      height: "fit-content",
    }}
  >
    {icon}

    <Box>
      <Typography sx={{ textAlign: "center", fontSize: 12, lineHeight: 1 }}>
        {number}
      </Typography>

      <Typography sx={{ textAlign: "center", fontSize: 12, lineHeight: 1 }}>
        {unit}
      </Typography>
    </Box>
  </Box>
);

import { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate} from "react-router-dom";
import Button, {
  EditButton,
  DeleteButton,
} from "components/Button/Button";
import useStore from "store/store";
import {
  CURLANG,
  PRE_DISCOVERY_QUESTIONNAIRES,
} from "constants/url";

const PreDiscoveryQuestionnaireDetail = () => {
  const navigate = useNavigate();
  // const { questionnaireSlug } = useParams();
  const {
    currLanguage,
    currentEvaluationQuestionnaire,
    setCurrentEvaluationQuestionnaire,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    currentEvaluationQuestionnaire: state.currentEvaluationQuestionnaire,
    setCurrentEvaluationQuestionnaire: state.setCurrentEvaluationQuestionnaire,
  }));

  const [questionNumber, setQuestionNumber] = useState<number>(0);

  const nextQuestion = () => {
    setQuestionNumber((prev) => {
      return prev + 1;
    });
  };

  const prevQuestion = () => {
    setQuestionNumber((prev) => {
      return prev - 1;
    });
  };

  // useInitialiseEvaluationQuestionnaire(questionnaireSlug);

  // console.log("current questionnaires: ", currentQuestionnaire);

  return (
    <>
      <Box sx={{ display: "flex", mx: "60px", gap: "32px" }}>
        <Container
          sx={{
            backgroundColor: "#FBFBFB",
            borderRadius: "16px",
            flex: 1,
            alignSelf: "start",
            my: 3,
          }}
          maxWidth={false}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "30px",
              pb: "30px",
            }}
          >
            <Button
              buttonText={``}
              arrow={true}
              backward={true}
              onClick={() => {
                navigate(
                  `${CURLANG(currLanguage)}/${PRE_DISCOVERY_QUESTIONNAIRES}`
                );
              }}
            />
            <Typography
              sx={{
                display: "flex",
                fontWeight: "bold",
                fontSize: "2.5rem",
                textAlign: "center",
                justifyContent: "center",
                width: "50vw",
              }}
            >
              <Box
                sx={{
                  marginLeft: 5,
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <EditButton
                  style={{ marginLeft: 5, marginRight: 5 }}
                  btnText=""
                  onClick={() => {
                    // navigate();
                    //   EDIT_TEACHER_MODULE_PAGE(teacherCurrentModule.slug)
                  }}
                />
                <DeleteButton
                  style={{ backgroundColor: "red" }}
                  btnText=""
                  onClick={() => {
                    // handleOpenDialog();
                  }}
                />
              </Box>
            </Typography>
            <Box></Box>
          </Box>
          {/* <Box sx={{ fontSize: 30 }}>
            {currentQuestionnaire.questions.map((question, index) => {
              return index === questionNumber ? (
                <>
                  <p>{question.name}</p>
                  <ul>
                    {question.answers.map((answer) => {
                      return (
                        <li>
                          <Link
                            component="button"
                            sx={{ fontSize: 30 }}
                            onClick={() => {
                              nextQuestion();
                            }}
                          >
                            {answer.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <></>
              );
            })}
          </Box> */}
          <Button
            buttonText={`Previous Question`}
            arrow={true}
            backward={true}
            onClick={() => {
              prevQuestion();
            }}
            disabled={questionNumber === 0}
          />
          <Button
            buttonText={`Next Question`}
            arrow={true}
            onClick={() => {
              nextQuestion();
            }}
            // disabled={
            //   questionNumber === currentQuestionnaire.questions.length - 1
            // }
          />
        </Container>
      </Box>
    </>
  );
};

export default PreDiscoveryQuestionnaireDetail;

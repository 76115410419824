import { Box, Typography } from "@mui/material";

import Background from "images/end-of-project-report/ProjectSummaryCongratulations.png"
import Image from "components/Image/Image";

import { PDFInfoType, editableStyle, styles } from "./constant";

const LastPage = ({
  scale,
  PDFInfo,
  setPDFInfo,
}: {
  scale: number;
  PDFInfo: PDFInfoType;
  setPDFInfo: React.Dispatch<React.SetStateAction<PDFInfoType>>;
}) => {
  const handleChange = (key: string, value: string | null) =>
    setPDFInfo((prev) => ({ ...prev, [key]: value ? value : "" }));

  const strip = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Box sx={styles.body}>
      <Image src={Background} alt="" />



    </Box>
  );
};

export default LastPage;

import { Box, Typography } from "@mui/material";
import {
  API_Lesson_Learning_Moment,
  API_Project_Lesson,
} from "../../../types/project";
import LearningMomentPhotoGalleryComponent from "./LearningMomentPhotoGalleryComponent";
import { GalleryPhotoVideo } from "../../../types/gallery";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";

export const learningMomentTabIconStyle = {
  width: 20,
  height: 20,
};

const LearningMomentPhotoScreen = ({
  photoVideoGalleryList,
  setPhotoVideoGalleryList,
  isPhotoVideoExist,
  learningMomentObject,
  currentLesson,
  isForLearningMomentTab = false,
}: {
  photoVideoGalleryList: GalleryPhotoVideo[];
  setPhotoVideoGalleryList: React.Dispatch<
    React.SetStateAction<GalleryPhotoVideo[]>
  >;
  isPhotoVideoExist: boolean;
  learningMomentObject: API_Lesson_Learning_Moment;
  currentLesson: API_Project_Lesson;
  setIsLearningMomentPhotoScreen: React.Dispatch<React.SetStateAction<boolean>>;
  isForLearningMomentTab?: boolean;
}) => (  
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 1, minHeight: 28 }}
    >
      {isPhotoVideoExist ? (
        <CircleCheckedFilled
          sx={{ ...learningMomentTabIconStyle }}
          color="primary"
        />
      ) : (
        <CircleUnchecked
          sx={{ ...learningMomentTabIconStyle }}
          color="primary"
        />
      )}
      <CameraAltIcon sx={{ ...learningMomentTabIconStyle }} />
      <Typography sx={{ fontSize: 18 }}>
        {learningMomentObject.photo_instruction}
      </Typography>
    </Box>
    <Box sx={{ width: "100%", pl: 3.5 }}>
      <LearningMomentPhotoGalleryComponent
        photoVideoGalleryList={photoVideoGalleryList}
        setPhotoVideoGalleryList={setPhotoVideoGalleryList}
        currentLesson={currentLesson}
        learningMomentObject={learningMomentObject}
        isForLearningMomentTab={isForLearningMomentTab}
      />
    </Box>
  </Box>
);

export default LearningMomentPhotoScreen;

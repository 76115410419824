import { useRef, useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";

import useSnack from "../../../../../../hooks/useSnack";
import useStore from "../../../../../../store/store";
import Button from "../../../../../../components/Button/Button";
import { translation } from "../../../../../../constants/translation";
import {
  NameField,
  PublishField,
  TextActivityComponentField,
} from "../../TeacherFormComponents/ActivityFormComponents";
import {
  API_Create_Teacher_Activity,
  API_Teacher_Text_Activity_Component,
} from "../../../../../../types/teacher";
import { RESOURCE_TYPE_TEXT } from "../../../teacherPageConstants";
import { createTeacherActivity } from "../../../../../../api/teacher-api";
import FlipBookPDFComponent from "../../TeacherFormComponents/FlipBookPDFComponent";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CreateTextActivityForm = ({
  lessonId,
  handleClose,
}: {
  lessonId: number;
  handleClose: () => void;
}) => {
  const openSnack = useSnack();

  const { currLanguage, setIsRefreshTeacherModule } = useStore((state) => ({
    currLanguage: state.currLanguage,
    setIsRefreshTeacherModule: state.setIsRefreshTeacherModule,
  }));

  const [isCreatingActivity, setIsCreatingActivity] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  // const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [textActivityComponents, setTextActivityComponents] = useState<
    API_Teacher_Text_Activity_Component[]
  >([]);

  const [updatedActivity, setUpdatedActivity] =
    useState<API_Create_Teacher_Activity>({
      lesson: lessonId,
      resourcetype: RESOURCE_TYPE_TEXT,
      name: "",
      description: "",
      is_published: false,
    });

  // State for storing the selected PDF file
  const [selectedPdf, setSelectedPdf] = useState<{
    file: File | null;
    dimensions: { width: number; height: number };
  }>({ file: null, dimensions: { width: 0, height: 0 } });

  const activityRef = useRef(updatedActivity);
  activityRef.current = updatedActivity;
  const MAX_FILE_SIZE_MB = 15;

  const submitForm = async () => {
    if (updatedActivity.name === "") {
      //filled
      openSnack("Activity name is required!", false);
      return;
    }

    const submitData: API_Create_Teacher_Activity = {
      lesson: lessonId,
      resourcetype: updatedActivity.resourcetype,
      name: updatedActivity.name,
      description: updatedActivity.description,
      is_published: updatedActivity.is_published,
      text_activity_components: textActivityComponents,
      flip_book: selectedPdf.file,
    };

    const res = await createTeacherActivity(
      currLanguage,
      submitData,
      setIsCreatingActivity,
      setProgressPercentage,
      localStorage.getItem("access")!
    );

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      // const imageRes = await addTextActivityImage(
      //   currLanguage,
      //   res.id,
      //   imageFiles,
      //   setIsCreatingActivity,
      //   setProgressPercentage,
      //   localStorage.getItem("access")!
      // );

      setIsRefreshTeacherModule(true);
      openSnack("Activity created successfully!", true);
      handleClose();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        openSnack(
          `Cannot upload PDF larger than ${MAX_FILE_SIZE_MB}MB. Please compress before uploading`,
          false
        );
        return;
      }
      if (file.type === "application/pdf") {
        setSelectedPdf({ file, dimensions: { width: 0, height: 0 } }); // Set initial state with file and default dimensions
      } else {
        openSnack("Please select a valid PDF file", false);
      }
    }
  };

  const removeSelectedPdf = () => {
    setSelectedPdf({ file: null, dimensions: { width: 0, height: 0 } });
    openSnack("PDF removed successfully.", true);
  };

  useEffect(() => {
    if (selectedPdf.file) {
      const fileURL = URL.createObjectURL(selectedPdf.file);
      pdfjs.getDocument(fileURL).promise.then((pdfDoc) => {
        pdfDoc.getPage(1).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          setSelectedPdf((prev) => ({
            ...prev,
            dimensions: { width: viewport.width, height: viewport.height },
          }));
        });
      });
    }
  }, [selectedPdf.file]);

  return (
    <Stack
      sx={{
        width: "100%",
        mb: 8,
      }}
      spacing={3}
    >
      <PublishField
        activity={updatedActivity}
        setActivity={setUpdatedActivity}
      />
      <NameField activity={updatedActivity} setActivity={setUpdatedActivity} />
      {/* <ImageListField
        imageFiles={imageFiles}
        setImageFiles={setImageFiles}
        activity={updatedActivity}
        setActivity={setUpdatedActivity}
        multiple
      /> */}
      {/* <DescriptionField
        activity={updatedActivity}
        setActivity={setUpdatedActivity}
      /> */}
      <TextActivityComponentField
        textActivityComponents={textActivityComponents}
        setTextActivityComponents={setTextActivityComponents}
      />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          buttonText={
            selectedPdf.file ? "Update PDF Flip Book" : "Add PDF Flip Book"
          }
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            border: "1px solid var(--Cyan-700, #5C9391)",
            backgroundColor: "#ffffff", // Set background color to white
            color: "var(--Cyan-700, #5C9391)", // Set text color to green
            fontFamily: '"Futura PT"',
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 450,
            lineHeight: "24px",
            padding: "6px 16px",
          }}
          onClick={() => document.getElementById("pdfFileInput")?.click()}
          arrow={false}
        />

        {selectedPdf.file && (
          <Button
            buttonText={"Remove PDF Flip Book"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ffffff", // Set background color to white
              color: "red", // Set text color to green
              fontFamily: '"Futura PT"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 450,
              lineHeight: "24px",
              padding: "6px 16px",
            }}
            onClick={removeSelectedPdf}
            arrow={false}
          />
        )}
      </Box>
      <input
        type="file"
        id="pdfFileInput"
        accept="application/pdf"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {selectedPdf.file && (
        <>
          {" "}
          <FlipBookPDFComponent
            file={selectedPdf.file}
            dimensions={selectedPdf.dimensions}
            scale={1}
          />{" "}
        </>
      )}

      <Box
        sx={{
          display: "flex",
          gap: "24px",
          position: "fixed",
          bottom: 24,
          right: 34,
          zIndex: 10,
        }}
      >
        <Button
          buttonText={
            isCreatingActivity
              ? progressPercentage !== 100
                ? `${progressPercentage}%`
                : translation.uploadFinalizing
              : translation.create
          }
          style={{
            fontSize: "14px",
            backgroundColor: "var(--txt-secondary)",
            height: "auto",
          }}
          onClick={() => submitForm()}
        />
      </Box>
    </Stack>
  );
};

export default CreateTextActivityForm;

import React, { useState, useRef } from "react";

import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  Tooltip,
  SxProps,
} from "@mui/material";

import CropIcon from "@mui/icons-material/Crop";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import Crop54Icon from "@mui/icons-material/Crop54";
import Crop75Icon from "@mui/icons-material/Crop75";
import FlipIcon from "@mui/icons-material/Flip";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Button from "../../Button/Button";
import { translation } from "constants/translation";

const TO_RADIANS = Math.PI / 180;
const ASPECT_RATIO = [
  {
    label: "Custom",
    value: "",
    icon: <CropIcon fontSize="small" />,
  },
  {
    label: "Square",
    value: 1,
    icon: <CropSquareIcon fontSize="small" />,
  },
  {
    label: "16:9",
    value: 16 / 9,
    icon: <Crop169Icon fontSize="small" />,
  },
  {
    label: "9:16",
    value: 9 / 16,
    icon: <Crop169Icon sx={{ transform: "rotate(90deg)" }} fontSize="small" />,
  },
  {
    label: "3:2",
    value: 3 / 2,
    icon: <Crop32Icon fontSize="small" />,
  },
  {
    label: "2:3",
    value: 2 / 3,
    icon: <Crop32Icon sx={{ transform: "rotate(90deg)" }} fontSize="small" />,
  },
  {
    label: "5:4",
    value: 5 / 4,
    icon: <Crop54Icon fontSize="small" />,
  },
  {
    label: "4:5",
    value: 4 / 5,
    icon: <Crop54Icon sx={{ transform: "rotate(90deg)" }} fontSize="small" />,
  },
  {
    label: "7:5",
    value: 7 / 5,
    icon: <Crop75Icon fontSize="small" />,
  },
  {
    label: "5:7",
    value: 5 / 7,
    icon: <Crop75Icon sx={{ transform: "rotate(90deg)" }} fontSize="small" />,
  },
];

const EditPhoto = ({
  open,
  handleClose,
  imageSrc,
  setEditedImage,
  imageName,
  toBlob = false,
  setEditedImageInBlob,
}: {
  open: boolean;
  handleClose: () => void;
  imageSrc: string;
  setEditedImage?: React.Dispatch<React.SetStateAction<File | undefined>>;
  imageName: string;
  toBlob?: boolean;
  setEditedImageInBlob?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState<number>(1);
  const [flipHor, setFlipHor] = useState<boolean>(false);
  const [flipVer, setFlipVer] = useState<boolean>(false);
  const [rotate, setRotate] = useState<number>(0);
  const [aspect, setAspect] = useState<number | undefined>();
  const [imgSrc, setImgSrc] = useState<string>(imageSrc);

  const resetCrop = (width: number, height: number) => {
    setCrop(centerAspectCrop(width, height, width / height));
    setCompletedCrop({
      x: 0,
      y: 0,
      width,
      height,
      unit: "px",
    });
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    resetCrop(width, height);
  };

  const handleRotate = (direction: string) => {
    if (imgRef.current) {
      setImgSrc(
        getRotatedImage(imgRef.current, direction !== "left", imageName)
      );
    }
  };

  const handleFlip = (direction: string) => {
    if (imgRef.current) {
      if (direction === "horizontal") {
        setFlipHor((prev) => !prev);
      } else {
        setFlipVer((prev) => !prev);
      }

      const { width, height } = imgRef.current;
      resetCrop(width, height);
    }
  };

  // const handleAspect = (event: SelectChangeEvent) => {
  //   const ratio = event.target.value;

  //   if (ratio === "") {
  //     setAspect(undefined);
  //   } else if (imgRef.current) {
  //     const { width, height } = imgRef.current;
  //     setAspect(Number(ratio));
  //     setCrop(centerAspectCrop(width, height, Number(ratio)));
  //   }
  // };

  const iconStyle: SxProps = {
    cursor: "pointer",
    transition: "all 100ms ease-in-out",
    "&:hover": {
      color: "primary.main",
      transition: "all 100ms ease-in-out",
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
          }}
        >
          <Typography variant="h6" component="h2">
            {translation.edit_image}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "12px",
              mb: "24px",
              backgroundColor: "#FBFBFB",
              height: "400px",
              overflow: "hidden",
            }}
          >
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(pixelCrop) => setCompletedCrop(pixelCrop)}
              aspect={aspect}
              ruleOfThirds={true}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                alt={imageName}
                style={{
                  transform: `scaleX(${flipHor ? -scale : scale}) scaleY(${
                    flipVer ? -scale : scale
                  }) rotate(${rotate}deg)`,
                  objectFit: "contain",
                  maxHeight: "400px",
                }}
                onLoad={onImageLoad}
                crossOrigin="anonymous"
              />
            </ReactCrop>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                mr: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1.5,
                }}
              >
                <Tooltip title="Aspect Ratio">
                  <AspectRatioIcon fontSize="small" />
                </Tooltip>

                <Select
                  value={aspect ? aspect.toString() : ""}
                  onChange={handleAspect}
                  size="small"
                  variant="standard"
                  displayEmpty
                  sx={{
                    minWidth: "80px",
                  }}
                  inputProps={{
                    sx: {
                      "& svg": {
                        display: "none",
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                >
                  {ASPECT_RATIO.map((ratio, index) => (
                    <MenuItem key={index} sx={{ gap: 1 }} value={ratio.value}>
                      {ratio.icon}
                      {ratio.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box> */}

              <Tooltip title={translation.flipHoriz}>
                <FlipIcon
                  fontSize="small"
                  sx={iconStyle}
                  onClick={() => handleFlip("horizontal")}
                />
              </Tooltip>

              <Tooltip title={translation.flipVerti}>
                <FlipIcon
                  fontSize="small"
                  sx={{
                    ...iconStyle,
                    transform: "rotate(90deg)",
                  }}
                  onClick={() => handleFlip("vertical")}
                />
              </Tooltip>

              <Tooltip title={translation.rotateAntiClockwise}>
                <Rotate90DegreesCcwIcon
                  fontSize="small"
                  sx={iconStyle}
                  onClick={() => handleRotate("left")}
                />
              </Tooltip>

              <Tooltip title={translation.rotateClockwise}>
                <Rotate90DegreesCcwIcon
                  fontSize="small"
                  sx={{
                    ...iconStyle,
                    transform: "rotate(180deg) scaleY(-1)",
                  }}
                  onClick={() => handleRotate("right")}
                />
              </Tooltip>
            </Box>

            <Button
              buttonText={translation.cancel || "Cancel"}
              arrow={false}
              style={{
                fontSize: "14px",
                backgroundColor: "transparent",
                color: "var(--txt-secondary)",
                padding: "0",
                marginRight: "20px",
              }}
              onClick={handleClose}
            />

            <Button
              arrow={false}
              disabled={completedCrop === undefined}
              style={{
                fontSize: "14px",
                backgroundColor: "var(--txt-secondary)",
              }}
              buttonText={translation.done || "Done"}
              onClick={async () => {
                if (completedCrop === undefined || imgRef.current === null)
                  return;

                if (toBlob) {
                  if (!setEditedImageInBlob) return;

                  const editedImage = (await getEditedImg(
                    imgRef.current,
                    completedCrop,
                    scale,
                    rotate,
                    flipHor,
                    flipVer,
                    imageName,
                    toBlob
                  )) as string;

                  setEditedImageInBlob(editedImage);
                } else {
                  if (!setEditedImage) return;

                  const editedImage = (await getEditedImg(
                    imgRef.current,
                    completedCrop,
                    scale,
                    rotate,
                    flipHor,
                    flipVer,
                    imageName,
                    toBlob
                  )) as File;

                  setEditedImage(editedImage);
                }

                handleClose();
              }}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
const getRotatedImage = (
  image: HTMLImageElement,
  isClockwise: boolean,
  fileName: string
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;
  const { naturalWidth, naturalHeight } = image;

  // Calculate new dimensions based on rotation
  const newWidth = isClockwise ? naturalHeight : naturalWidth;
  const newHeight = isClockwise ? naturalWidth : naturalHeight;

  // Set canvas dimensions to match the new rotated dimensions
  canvas.width = newWidth;
  canvas.height = newHeight;

  // Rotate and draw image onto canvas
  ctx.save();
  ctx.translate(newWidth / 2, newHeight / 2);

  // Adjust rotation direction based on clockwise or anticlockwise
  const rotation = isClockwise ? (90 * Math.PI) / 180 : (-90 * Math.PI) / 180;
  ctx.rotate(rotation);

  ctx.drawImage(image, -naturalWidth / 2, -naturalHeight / 2);
  ctx.restore();

  // Convert canvas to base64 data URL
  const fileExtension = fileName.toLowerCase().split(".").pop() || "png";
  const dataUrl = canvas.toDataURL(`image/${fileExtension}`, 1);

  return dataUrl;
};

const getEditedImg = (
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
  flipHor: boolean,
  flipVer: boolean,
  fileName: string,
  toBlob: boolean
) => {
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(flipHor ? -scale : scale, flipVer ? -scale : scale);
  ctx.translate(-centerX, -centerY);

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  let fileExtension = fileName.toLowerCase().split(".").pop();
  if (fileExtension) {
    if (fileExtension === "jpg") {
      fileExtension = "jpeg";
    }
  } else {
    fileExtension = "png";
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob === null) return;

      const result = toBlob
        ? URL.createObjectURL(blob)
        : new File([blob], fileName, { type: blob.type });

      resolve(result);
    }, `image/${fileExtension}`);
  });
};

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );

export default EditPhoto;

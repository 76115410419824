import React, { useState, useEffect } from "react";
import useStore from "store/store";
import { Box, Typography } from "@mui/material";
import { CalendarViewOptions } from "types/calendar";
import Legends from "./Legends";
import { translation } from "constants/translation";

const ViewControl = () => {
  const {
    calendarViews,
    setCalendarViews,
    setIsLoadingCalendar,
    setRefreshProjectBox,
  } = useStore((state) => ({
    calendarViews: state.calendarViews,
    setCalendarViews: state.setCalendarViews,
    setIsLoadingCalendar: state.setIsLoadingCalendar,
    setRefreshProjectBox: state.setRefreshProjectBox,
  }));

  const handleClick = (view: string) => {
    setIsLoadingCalendar(true);
    const updatedViews = { ...calendarViews };
    Object.keys(updatedViews).forEach((view) => {
      updatedViews[view as CalendarViewOptions] = false;
    });
    updatedViews[view as CalendarViewOptions] = true;
    setCalendarViews(updatedViews);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "7px" }}>
      {Object.entries(calendarViews).map(
        ([view, active]: [view: string, active: boolean], index) => (
          <Box
            key={`${view}-${index}-calendar`}
            sx={{
              width: "fit-content",
              height: "fit-content",
              padding: "5px",
              border: `1px solid ${active ? "#D1E4E3" : "#C4C4C4"}`,
              borderRadius: "5px",
              background: `${active ? "#D1E4E3" : "inherit"}`,
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => {
              handleClick(view);
            }}
          >
            {view === "Week"
              ? translation.calendarViewWeek
              : view === "Month"
              ? translation.calendarViewMonth
              : view === "Year"
              ? translation.calendarViewYear
              : ""}
          </Box>
        )
      )}
      <Legends />
    </Box>
  );
};
export default ViewControl;

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Button, { FactoryButton } from "components/Button/Button";
import { translation } from "constants/translation";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  createCustomTimetable,
  deleteCustomTimetable,
  updateCustomTimetable,
} from "api/timetable-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import { API_Custom_Timetable } from "types/timetable";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";

const buttonStyles: React.CSSProperties = {
  flexDirection: "row-reverse",
  backgroundColor: "white",
  color: "#98C4C5",
  border: "1px solid #98C4C5",
  padding: "10px 16px",
  fontSize: "14px",
  fontWeight: 500,
};

export const UploadNewTimetableButton = ({
  timetable,
  setTimetable,
}: {
  timetable: API_Custom_Timetable;
  setTimetable: React.Dispatch<
    React.SetStateAction<API_Custom_Timetable | null>
  >;
}) => {
  const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (file === null) return;

    const updateTimetable = async () => {
      setIsLoading(true);

      const updatedTimetable = await updateCustomTimetable(
        currLanguage,
        timetable.id,
        {
          file,
        },
        localStorage.getItem("access")
      );

      if (typeof updatedTimetable !== "string") {
        setTimetable(updatedTimetable);
        openSnack(translation.successUpdateTimetable, true);
      } else {
        openSnack(translation.failUpdateTimetable, false);
      }

      setFile(null);
      setIsLoading(false);
    };

    updateTimetable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      <Box
        component="label"
        htmlFor="upload-timetable"
        sx={{
          opacity: isLoading ? 0.5 : 1,
          pointerEvents: isLoading ? "none" : "auto",
        }}
      >
        <FactoryButton
          btnText={
            isLoading ? translation.uploading : translation.uploadNewTimetable
          }
          style={buttonStyles}
        />
      </Box>

      <FileInput setFile={setFile} />
    </>
  );
};

export const RemoveTimetableButton = ({
  timetable,
  setTimetable,
}: {
  timetable: API_Custom_Timetable;
  setTimetable: React.Dispatch<
    React.SetStateAction<API_Custom_Timetable | null>
  >;
}) => {
  const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const handleDelete = async () => {
    const res = await deleteCustomTimetable(currLanguage, timetable.id);

    if (res === "Success") {
      setTimetable(null);
      openSnack(translation.successDeleteTimetable, true);
    } else {
      openSnack(translation.failDeleteTimetable, false);
    }
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `${translation.removeTimetable}?`,
    warningContext: translation.irreversibleAction,
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <Button
        arrow={false}
        buttonText={translation.removeTimetable}
        style={{
          ...buttonStyles,
          color: "#FF0000",
          border: "1px solid #FF0000",
        }}
        onClick={handleOpenDialog}
      />
    </>
  );
};

export const UploadTimetableBox = ({
  setTimetable,
}: {
  setTimetable: React.Dispatch<
    React.SetStateAction<API_Custom_Timetable | null>
  >;
}) => {
  const openSnack = useSnack();

  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));

  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (file === null) return;

    const uploadTimetable = async () => {
      setIsLoading(true);

      const newTimetable = await createCustomTimetable(
        currLanguage,
        {
          file,
        },
        localStorage.getItem("access")
      );

      if (typeof newTimetable !== "string") {
        setTimetable(newTimetable);
        openSnack(translation.successUploadTimetable, true);
      } else {
        openSnack(translation.failUploadTimetable, false);
      }

      setFile(null);
      setIsLoading(false);
    };

    uploadTimetable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          padding: 1,
          borderRadius: "10px",
          color: "#444",
          border: "1px dashed",
          borderColor: isLoading ? "primary.main" : "custom.yellow",
          backgroundColor: isLoading
            ? "rgba(209, 228, 227, 0.37)"
            : "transparent",
          transition: "all 100ms ease-in-out",
          cursor: "pointer",
          width: "100%",
          maxHeight: "400px",
          mx: "auto",
          pointerEvents: isLoading ? "none" : "auto",
          p: 3,
          "&:hover": {
            borderColor: "primary.main",
            backgroundColor: "rgba(209, 228, 227, 0.37)",
            transition: "all 100ms ease-in-out",
          },
        }}
        component="label"
        htmlFor="upload-timetable"
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <AddAPhotoIcon
              sx={{
                color: "custom.yellow",
                width: 80,
                height: 80,
              }}
            />
            <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
              {translation.uploadYourTimetable}
            </Typography>
          </>
        )}
      </Box>

      <FileInput setFile={setFile} />
    </>
  );
};

const FileInput = ({
  setFile,
}: {
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}) => (
  <input
    accept="image/*,application/pdf"
    id="upload-timetable"
    type="file"
    capture="environment"
    hidden
    onChange={(event) => {
      if (event.target.files === null) {
        event.target.value = "";
        setFile(null);
        return;
      }

      const newFile: File = event.target.files[0];

      setFile(newFile);

      event.target.value = "";
    }}
  />
);

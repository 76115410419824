import {
    Box,
    Typography,
    Avatar,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Logo from "images/logo-2-colour.png";


const Person = ({ profile }: { profile: any }) => {
    const theme = useTheme();

    const isSm = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "57px",
                height: "60px",
            }}
        >
            <Avatar
                src={profile.image ? profile.image : Logo}
                sx={{
                    width: "32px",
                    height: "32px",
                    display: "flex",
                }}
                imgProps={{
                    onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        event.currentTarget.onerror = null;
                        event.currentTarget.src = Logo;
                    },
                }}
            />
            <Typography
                sx={{
                    fontWeight: "400",
                    fontSize: isSm ? "12px" : "14px",

                    textAlign: "center",
                }}
            >
                {profile.preferred_name}
            </Typography>
        </Box>
    );
};


export default Person;
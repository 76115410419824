import { Page, Text, Image, View, StyleSheet } from "@react-pdf/renderer";

import { pageSize, imageSrc, styles } from "../ProjectReportPDF";

import Background from "images/end-of-project-report/last-page-bg.png";
import { PDFInfoType } from "../Preview/constant";

const templateStyles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    flex: 1,
    marginTop: 280,
    marginBottom: 50,
    marginHorizontal: 50,
    overflow: "hidden",
  },
  title: {
    position: "absolute",
    top: 96,
    left: 400,
    right: 400,
  },
  subtitle: {
    position: "absolute",
    top: 154,
    left: 400,
    right: 400,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 220,
    borderRadius: 8,
    marginVertical: 10,
    marginHorizontal: 10,
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: 100,
    objectFit: "cover",
    backgroundColor: "#fbfbfb",
  },
  text: {
    paddingTop: 6,
    paddingBottom: 14,
    fontSize: 18,
    lineHeight: 1,
  },
});

const LastPage = ({
  PDFInfo,
  images,
}: {
  PDFInfo?: PDFInfoType;
  images: string[];
}) => {
  return (
    <Page size={pageSize} style={styles.body}>
      <View style={styles.main} wrap={false}>
        <Image src={Background} style={styles.background} />

        <Text style={[styles.pageTitle, templateStyles.title]}>
          {PDFInfo ? PDFInfo.congratsTitle : "Congratulations!"}
        </Text>
        <Text style={[styles.textCenter, templateStyles.subtitle]}>
          {PDFInfo
            ? PDFInfo.congratsText
            : "on the successful completion of the project"}
        </Text>

        <View style={templateStyles.wrapper}>
          {images.map((image, index) => {
            return (
              <View key={index} style={templateStyles.card}>
                <Image src={imageSrc(image)} style={templateStyles.image} />

                <Text style={[styles.textCenter, templateStyles.text]}>
                  Lesson {index + 1}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </Page>
  );
};

export default LastPage;

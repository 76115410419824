import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import CloseIcon from "@mui/icons-material/Close";

import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { translation } from "constants/translation";
import {
  API_Teacher_Community_Kanban_Board,
  API_Teacher_Create_Community_Kanban_Board,
} from "types/teacher";
import Button from "components/Button/Button";
import {
  createCommunityKanbanBoard,
  editCommunityKanbanBoard,
} from "api/teacher-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

const CreateEditKanbanBoardModal = ({
  open,
  handleClose,
  kanbanBoard,
}: {
  open: boolean;
  handleClose: () => void;
  kanbanBoard: API_Teacher_Community_Kanban_Board;
}) => {
  const isCreate = kanbanBoard.id === -1;
  const openSnack = useSnack();

  const { currLanguage, kanbanBoardList, setKanbanBoardList } = useStore(
    (state) => ({
      kanbanBoardList: state.kanbanBoardList,
      setKanbanBoardList: state.setKanbanBoardList,
      currLanguage: state.currLanguage,
    })
  );

  const [currentKanbanBoardInfo, setCurrentKanbanBoardInfo] =
    useState<API_Teacher_Create_Community_Kanban_Board>({
      name: kanbanBoard.name,
      description: kanbanBoard.description,
    });

  const handleSubmit = async () => {
    if (
      currentKanbanBoardInfo.name !== "" &&
      currentKanbanBoardInfo.description !== ""
    ) {
      let kanbanBoardRes: API_Teacher_Community_Kanban_Board | string;
      // Update
      if (kanbanBoard.id !== -1) {
        const body = { id: kanbanBoard.id, ...currentKanbanBoardInfo };
        kanbanBoardRes = await editCommunityKanbanBoard(currLanguage, body);
      } else {
        kanbanBoardRes = await createCommunityKanbanBoard(
          currLanguage,
          currentKanbanBoardInfo
        );
      }

      if (typeof kanbanBoardRes === "string") {
        openSnack(kanbanBoardRes, false);
        return;
      } else {
        if (isCreate) {
          setKanbanBoardList([
            ...kanbanBoardList,
            kanbanBoardRes as API_Teacher_Community_Kanban_Board,
          ]);
        } else {
          const updatedKanbanBoardList: API_Teacher_Community_Kanban_Board[] =
            JSON.parse(JSON.stringify(kanbanBoardList));
          const index = updatedKanbanBoardList.findIndex(
            (kanbanBoard_) => kanbanBoard_.id === kanbanBoard.id
          );
          updatedKanbanBoardList[index] = kanbanBoardRes;
          setKanbanBoardList(updatedKanbanBoardList);
        }
        openSnack(
          isCreate
            ? translation.kanbanBoardTopicCreated ||
                "Kanban Board Topic Created"
            : translation.kanbanBoardTopicUpdated ||
                "Kanban Board Topic Updated",
          true
        );
        handleClose();
      }
    }
  };

  useEffect(() => {
    setCurrentKanbanBoardInfo({
      name: kanbanBoard.name,
      description: kanbanBoard.description,
    });
  }, [kanbanBoard.id]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: "5px 5px 20px var(--primary-main)",
            p: 8,
            paddingY: 4,
            borderRadius: "10px",
            alignItems: "center",
            width: "50vw",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              // color: 'txt.secondary',
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>

          <Stack
            component="form"
            sx={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              alignItems: "center",
            }}
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              placeholder={translation.giveATitle}
              label={translation.title}
              value={currentKanbanBoardInfo.name}
              onChange={(event) => {
                setCurrentKanbanBoardInfo((prev) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
              required
            />
            <TextField
              fullWidth
              placeholder={translation.addAnAwesomeDescription}
              label={translation.subtitle}
              value={currentKanbanBoardInfo.description}
              onChange={(event) => {
                setCurrentKanbanBoardInfo((prev) => ({
                  ...prev,
                  description: event.target.value,
                }));
              }}
              required
            />
            <Button
              buttonText={
                isCreate
                  ? translation.submit || "Submit"
                  : translation.update || "Update"
              }
              disabled={false}
              onClick={handleSubmit}
            />
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateEditKanbanBoardModal;

import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { getSchoolByID } from "api/school-api";
import { API_School } from "types/school";
import useStore from "store/store";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  AddBackgroundButton,
  BackgroundImage,
  SchoolImage,
  SchoolName,
  SchoolDescription,
} from "./SchoolProfileComponents";

const SchoolProfilePage = () => {
  const { role, id } = useStore((state) => ({
    id: state.profileDetails.id,
    role: state.profileDetails.role,
  }));

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        px: 3,
        pt: 4,
        mx: {
          lg: "60px",
        },
      }}
    >
      {id === -1 ? (
        <LoadingIndicator />
      ) : role === "AD" ? (
        <SchoolProfile />
      ) : (
        <NotFoundPage />
      )}
    </Box>
  );
};

export interface RefreshInterface {
  refresh: boolean;
  callback: () => void;
}

const SchoolProfile = () => {
  const [school, setSchool] = useState<API_School>();
  const [refresh, setRefresh] = useState<RefreshInterface>({
    refresh: true,
    callback: () => {},
  });

  const { schoolID } = useStore((state) => ({
    schoolID: state.profileDetails.school?.id,
  }));

  useEffect(() => {
    if (schoolID === undefined || !refresh.refresh) return;

    const fetchSchool = async () => {
      const school = await getSchoolByID(schoolID.toString());
      setSchool(school);
      refresh.callback();
      setRefresh({ refresh: false, callback: () => {} });
    };

    fetchSchool();
  }, [schoolID, refresh]);

  return school === undefined ? (
    <LoadingIndicator />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2.5,
      }}
    >
      <AddBackgroundButton
        haveImage={school.background_image !== null}
        schoolId={school.id.toString()}
        setRefresh={setRefresh}
      />

      <BackgroundImage image={school.background_image} />

      <SchoolImage
        image={school.image}
        name={school.name}
        schoolId={school.id.toString()}
        setRefresh={setRefresh}
      />

      <SchoolName
        schoolId={school.id.toString()}
        name={school.name}
        setRefresh={setRefresh}
      />

      <SchoolDescription
        schoolId={school.id.toString()}
        description={school.description}
        setRefresh={setRefresh}
      />
    </Box>
  );
};

export default SchoolProfilePage;

import { useState, useEffect, useMemo } from "react";

import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  IconButton,
} from "@mui/material";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import useSnack from "hooks/useSnack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Logo from "images/logo-2-colour.png";

const CreateModuleModalContent = ({
  projectCategories,
  createModuleForm,
  setCreateModuleForm,
  isCreatingNewCategoryField,
  setIsCreatingNewCategoryField,
}: {
  projectCategories: any;
  createModuleForm: any;
  setCreateModuleForm: any;
  isCreatingNewCategoryField: any;
  setIsCreatingNewCategoryField: any;
}) => {
  const ImageForm = ({
    image,
    setFormData,
  }: {
    image: any;
    setFormData: any;
  }) => {
    const openSnack = useSnack();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFile = event.target.files?.[0];

      if (newFile) {
        const fileSizeLimit = 5 * 1024 * 1024; // 5MB limit

        if (newFile.size > fileSizeLimit) {
          // Display a snack to inform the user
          openSnack("Image should be less than 5MB or compressed", false);
          return;
        }

        setFormData((prev: any) => ({
          ...prev,
          image: newFile,
        }));
      }
    };

    return (
      <Box
        sx={{ position: "relative", overflow: "hidden", borderRadius: "50%" }}
      >
        <Avatar
          src={image !== "" ? image : Logo}
          // src={Logo}
          sx={{
            width: 150,
            height: 150,
            backgroundColor: "primary.main",
            color: "txt.light",
            border: "2px solid",
            borderColor: "primary.main",
            fontSize: "8vw",
          }}
          imgProps={{
            onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
              event.currentTarget.onerror = null;
              event.currentTarget.src = Logo;
            },
          }}
        ></Avatar>

        <input
          accept="image/*"
          type="file"
          id={`profile-image-button`}
          hidden
          onChange={handleFileChange}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "20%",
            backgroundColor: "primary.main",
            opacity: 0.5,
            transition: "all 150ms linear",
            "&:hover": {
              opacity: 1,
              transition: "all 150ms linear",
            },
          }}
        >
          <label htmlFor={`profile-image-button`}>
            <IconButton
              component="span"
              sx={{
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              <PhotoCameraIcon
                sx={{
                  fontSize: 20,
                  color: "primary.light",
                  cursor: "pointer",
                  transition: "all 50ms linear",
                  "&:hover": {
                    color: "txt.secondary",
                    transition: "all 50ms linear",
                  },
                }}
              />
            </IconButton>
          </label>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <Box
        sx={{
          width: 150,
          height: 150,
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <ImageForm
          setFormData={setCreateModuleForm}
          image={
            typeof createModuleForm.image === "string"
              ? createModuleForm.image
              : typeof createModuleForm.image === "object"
              ? createModuleForm.image !== null
                ? URL.createObjectURL(createModuleForm.image)
                : ""
              : ""
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={"category"}
            onChange={(event: SelectChangeEvent) => {
              if (event.target.value != "Create New Category") {
                setCreateModuleForm({
                  ...createModuleForm,
                  category: event.target.value,
                });
              } else {
                setCreateModuleForm({
                  ...createModuleForm,
                  category: "",
                  name: "Little Evaluator",
                });
              }
            }}
          >
            {projectCategories.map((category: any) => {
              return (
                <MenuItem
                  value={category.id}
                  onClick={() => {
                    setIsCreatingNewCategoryField(false);
                  }}
                >
                  {category.name}
                </MenuItem>
              );
            })}
            <MenuItem
              value="Create New Category"
              onClick={() => {
                setIsCreatingNewCategoryField(true);
              }}
            >
              Create New Category
            </MenuItem>
          </Select>
        </FormControl>
        {isCreatingNewCategoryField && (
          <Box
            sx={{
              backgroundColor: "#F8FDFD",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              sx={{ margin: 1 }}
              id="outlined-basic"
              label="Category Name"
              variant="outlined"
              value={createModuleForm.category}
              onChange={(e) => {
                setCreateModuleForm({
                  ...createModuleForm,
                  category: e.target.value,
                });
              }}
            />
            <FormControlLabel
              sx={{ marginLeft: 1 }}
              control={
                <Checkbox
                  checked={createModuleForm.assessment}
                  onChange={(e) => {
                    setCreateModuleForm({
                      ...createModuleForm,
                      assessment: e.target.checked,
                    });
                  }}
                />
              }
              label="Assessment"
            />
          </Box>
        )}
        <TextField
          id="outlined-basic"
          label="Module Name"
          variant="outlined"
          value={createModuleForm.name}
          onChange={(e) => {
            setCreateModuleForm({
              ...createModuleForm,
              name: e.target.value,
            });
          }}
        />
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          value={createModuleForm.description}
          onChange={(e) => {
            setCreateModuleForm({
              ...createModuleForm,
              description: e.target.value,
            });
          }}
        />
      </Box>
    </>
  );
};

export default CreateModuleModalContent;

import  { useState } from "react";
import { IconButton } from "@mui/material";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import { createFavItem, deleteFavItem } from "api/teacher"

import useStore from "store/store";

import {
  API_Teacher_Module,
} from "types/teacher";

type Props = {
  module: API_Teacher_Module;
  profile: number | string;
};

const AddToFavsButton = ({ module, profile }: Props) => {
  const [selected, setSelected] = useState(module.favorite);
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));


  const handleChangeFav = async () => {
    if (selected) {
      await deleteFavItem(currLanguage, module.id, profile);
      setSelected(false);
    } else { 
      await createFavItem(currLanguage, module.id, profile);
      setSelected(true);
    }
  };

  return (
    <IconButton sx={{ display: "flex" }} onClick={handleChangeFav}>
      {selected ? <BookmarkAddIcon /> : <BookmarkAddOutlinedIcon />}
    </IconButton>
  );
};

export default AddToFavsButton;

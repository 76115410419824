import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllChat, getAllContacts } from "api/chat-api";
import useStore from "store/store";
import MessageBoxes from "./component/MessageBoxes";
import SidePanel from "./component/SidePanel";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import {
  CHAT_MESSAGE_TYPE,
  FETCH_MESSAGE_TYPE,
  SEEN_MESSAGE_TYPE,
} from "./component/chatConstants";
import { environment } from 'environment';

const Chat = () => {
  const { chatID } = useParams();
  const profileID = localStorage.getItem("profile_id");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    allChats,
    setAllChats,
    setAllContacts,
    isLoadingChat,
    setIsLoadingChat,
    chatSockets,
    setChatSockets,
  } = useStore((state) => ({
    allChats: state.allChats,
    setAllChats: state.setAllChats,
    setAllContacts: state.setAllContacts,
    isLoadingChat: state.isLoadingChat,
    setIsLoadingChat: state.setIsLoadingChat,
    chatSockets: state.chatSockets,
    setChatSockets: state.setChatSockets,
  }));

  const populateChats = async () => {
    const allChatsRes = await getAllChat(localStorage.getItem("access"));
    setAllChats(allChatsRes);
    setIsLoadingChat(false);
    const allContactsRes = await getAllContacts(localStorage.getItem("access"));
    const sortedContacts = allContactsRes.sort((a, b) =>
      a.preferred_name.toLowerCase() > b.preferred_name.toLowerCase() ? 1 : -1
    );
    setAllContacts(sortedContacts);
  };

  useEffect(() => {
    populateChats();
  }, []);

  useEffect(() => {
    const updatedSockets = chatSockets;
    //initialize + enstablish all chatSockets --> We keep updating the onmessaging everytime a new socket is enstablish to ensure that the updatedChats are always with the latest number of chats. We only build new websocket if there isn't to avoid duplicate error.

    allChats.map((chat) => {
      if (!updatedSockets[chat.id]) {
        //this doesn't matter whether the chat.id is number or string
        updatedSockets[chat.id] = new WebSocket(
          `${environment.socketUrl}/ws/chat/${chat.id}`
        );
      }

      updatedSockets[chat.id].onmessage = async (event: any) => {
        const res = JSON.parse(event.data); //this is sent by backend -> chat msg

        if (res["type"] === CHAT_MESSAGE_TYPE) {
          const chatID = res.content[0]["chat"].toString();

          const updatedChats = allChats;
          const currChatIndex = updatedChats.findIndex(
            (chat) => chat.id.toString() === chatID
          );

          updatedChats[currChatIndex].latest_messages = [
            res.content[0],
            ...updatedChats[currChatIndex].latest_messages,
          ];

          if (profileID! !== res.content[0].author_id.toString()) {
            updatedChats[currChatIndex].unseen_messages_count += 1;
          }

          setAllChats(updatedChats);
        }

        // No suitable usecase for now
        if (res["type"] === FETCH_MESSAGE_TYPE && profileID! === res["from"]) {
          console.log(
            "************************************************************************",
            "message count res: ",
            res
          );
        }

        // No suitable usecase for now
        if (res["type"] === SEEN_MESSAGE_TYPE && profileID! === res["from"]) {
          console.log(
            "=================================================================",
            "seen msg triggered! ",
            res
          );
        }
      };
    });
    setChatSockets(updatedSockets);
  }, [allChats]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {isLoadingChat ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            mt: 4,
          }}
        >
          <LoadingIndicator />
        </Box>
      ) : (
        <Box
          sx={{
            flex: 1,
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {isXs ? (
              chatID ? (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "100vw",
                  }}
                >
                  <MessageBoxes chatID={chatID} />
                </Box>
              ) : (
                <SidePanel />
              )
            ) : (
              <>
                <SidePanel />

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {chatID ? (
                    <MessageBoxes chatID={chatID} />
                  ) : (
                    <NoChatSelected />
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const NoChatSelected = () => (
  <Box
    sx={{
      p: 3,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    }}
  >
    <Typography>Pick a chat from left menu</Typography>
  </Box>
);

export default Chat;

import { useState, useRef, useEffect, forwardRef, HTMLAttributes } from "react";
import useStore from "store/store";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { translation } from "constants/translation";
import Timetable from "./widgets/Timetable";
import Chat from "./widgets/Chat";
import TodoList from "./widgets/TodoList";
import Help from "./widgets/Help";
import TotalBoxes from "../TotalBoxes";

interface QuickItemsProps extends HTMLAttributes<HTMLDivElement> {
  isExpanded?: any;
  setIsExpanded?: any;
}

const QuickItems = forwardRef<HTMLDivElement, QuickItemsProps>((props, ref) => {
  const { isExpanded, setIsExpanded } = props;

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const [expandedSectionMobile, setExpandedSectionMobile] = useState<string>("");
  const [colorIndex, setColorIndex] = useState<number>(0);
  const {
    role,
    profileDetails,
    currLanguage,
    groupList,
    currGroup,
    groupLessonInfoList,
    groupProjectBoxProps,
  } = useStore((state) => ({
    role: state.profileDetails.role,
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
    refreshGroup: state.refreshGroup,
    groupList: state.groupList,
    currGroup: state.currGroup,
    groupLessonInfoList: state.groupLessonInfoList,
    groupProjectBoxProps: state.groupProjectBoxProps,
  }));

  return (
    <Box
      sx={{
        cursor: isExpanded ? "auto" : "pointer",
        width: "100%",
      }}
      ref={ref}
    >
      {isLg && profileDetails.role == "TR" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            cursor: "pointer",
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: 500,
            }}
          >
            {translation.quickItems}
          </Typography>
          {isLg && (
            <ExpandMoreIcon
              sx={{
                opacity: 0.5,
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
              }}
            />
          )}
        </Box>
      )}
      <Box
        sx={{
          py: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: { xs: 1.5, lg: 3 },
          overflowX: "hidden",
          overflowY: "hidden",
          height: isExpanded ? { xs: "auto", lg: "300px" } : { xs: "auto", lg: "64px" },
          transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          width: "100%",
        }}
      >
        {profileDetails.role == "TR" && (
          <Help
            isExpanded={isLg ? isExpanded : expandedSectionMobile === "helpcenter"}
            setExpandMobile={isLg ? undefined : setExpandedSectionMobile}
          />
        )}
        {profileDetails.role != "TR" && <TotalBoxes />}

        <TodoList
          isExpanded={isLg ? isExpanded : expandedSectionMobile === "notes"}
          setExpandMobile={isLg ? undefined : setExpandedSectionMobile}
          setColorIndex={setColorIndex}
        />
        <Chat
          isExpanded={isLg ? isExpanded : expandedSectionMobile === "chat"}
          colorIndex={colorIndex + 2}
          setExpandMobile={isLg ? undefined : setExpandedSectionMobile}
        />
      </Box>
    </Box>
  );
});

export default QuickItems;

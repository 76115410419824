import { Box, Typography } from "@mui/material";

type Props = {
    isActivityDone: boolean;
    correctCount: number;
    totalCount: number;
}

const ScreenStatus = ({
  isActivityDone,
}: Props) => {
    if (isActivityDone) {
        return <></>;   
    }

    return (
        <Box sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            padding: 1.5,
            backgroundColor: "#F2F2F2",
            borderRadius: "8px",
            color: "#323031"
        }}>
            <Typography>
                You need at least 80/100% to pass
            </Typography>
        </Box>
    );    
};


export default ScreenStatus;

import {
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCommunityKanbanBoardCard,
  getCommunityKanbanBoardBySlug,
  likeKanbanBoardCard,
} from "api/teacher-api";
import useSnack from "hooks/useSnack";
import useStore from "store/store";
import {
  API_Teacher_Community_Kanban_Board_Card,
  API_Teacher_Community_Kanban_Board_With_Card,
} from "types/teacher";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  COMMUNITY,
  CURLANG,
} from "constants/url";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { BackButton } from "components/Button/Button";
import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import { translation } from "constants/translation";
import useDialog from "hooks/useDialog";
import WarningDialog from "components/Dialog/WarningDialog";
import useModal from "hooks/useModal";
import CreateEditKanbanBoardCardModal from "./CreateEditKanbanBoardCardModal";
import AddIcon from "@mui/icons-material/Add";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import DndKanbanGrid from "./KanbanBoardCardDndComponents/DndKanbanGrid";
import DndKanbanCard from "./KanbanBoardCardDndComponents/DndKanbanCard";

import Masonry from "@mui/lab/Masonry";

const initialKanbanBoardCard = {
  id: -1,
  name: "",
  board: -1,
  likes: [],
  weight: -1,
  created_at: "",
  updated_at: "",
  created_by: -1,
};

const TeacherKanbanBoardPage = () => {
  const { kanbanBoardSlug } = useParams();
  const {
    currLanguage,
    profileDetails,
    isRefreshKanbanBoard,
    setIsRefreshKanbanBoard,
  } = useStore((state) => ({
    currLanguage: state.currLanguage,
    profileDetails: state.profileDetails,
    isRefreshKanbanBoard: state.isRefreshKanbanBoard,
    setIsRefreshKanbanBoard: state.setIsRefreshKanbanBoard,
  }));

  const openSnack = useSnack();
  const navigate = useNavigate();
  const [kanbanBoard, setKanbanBoard] =
    useState<API_Teacher_Community_Kanban_Board_With_Card>({
      id: -1,
      name: "",
      slug: "",
      description: "",
      cards: [],
      created_at: "",
      updated_at: "",
      created_by: -1,
    });
  const [isKanbanBoardLoading, setIsKanbanBoardLoading] =
    useState<boolean>(true);

  const { open, handleOpen, handleClose } = useModal();

  const [selectedKanbanBoardCard, setSelectedKanbanBoardCard] =
    useState<API_Teacher_Community_Kanban_Board_Card>(initialKanbanBoardCard);

  const populateKanbanBoard = async (isRefresh?: boolean) => {
    !isRefresh && setIsKanbanBoardLoading(true);
    const res = await getCommunityKanbanBoardBySlug(
      currLanguage,
      kanbanBoardSlug!
    );

    if (typeof res === "string") {
      // openSnack(res, false,)
    } else {
      // openSnack
      setKanbanBoard(res);
      setSelectedKanbanBoardCard((prev) => ({ ...prev, board: res.id }));
    }
    setIsKanbanBoardLoading(false);
    setIsRefreshKanbanBoard(false);
  };

  useEffect(() => {
    populateKanbanBoard();
  }, [kanbanBoardSlug]);

  useEffect(() => {
    kanbanBoardSlug && isRefreshKanbanBoard && populateKanbanBoard(false);
  }, [isRefreshKanbanBoard]);

  const isBoardOwnerOrAdmin =
    isProfileInTTAModuleEditableRoleList(profileDetails) ||
    profileDetails.user === kanbanBoard.created_by;

  return (
    <>
      <CreateEditKanbanBoardCardModal
        open={open}
        handleClose={handleClose}
        kanbanBoardCard={selectedKanbanBoardCard}
        kanbanBoard={kanbanBoard}
        setKanbanBoard={setKanbanBoard}
      />
      {isKanbanBoardLoading ? (
        <LoadingIndicator />
      ) : (
        <Box
          sx={{
            width: "100%",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "left", width: "100%" }}>
            <BackButton
              onClick={() => {
                {
                  navigate(`${CURLANG(currLanguage)}/${COMMUNITY}`);
                }
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#FBFBFB",
              borderRadius: "10px",
              p: 1,
              width: "100%",
              maxWidth: "90vw",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography sx={{ fontSize: 30, fontWeight: 500 }}>
                {kanbanBoard.name}
              </Typography>
              <Typography sx={{ fontSize: 20 }}>
                {kanbanBoard.description}
              </Typography>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Masonry
                  columns={{ xs: 1, s: 2, lg: 3 }}
                  spacing={2}
                  sx={{ width: "100%" }}
                  defaultSpacing={2}
                >
                  {isBoardOwnerOrAdmin ? (
                    <DndKanbanGrid
                      cards={kanbanBoard.cards}
                      setKanbanBoard={setKanbanBoard}
                    >
                      {kanbanBoard.cards.map((kanbanBoardCard, index) => (
                        <DndKanbanCard
                          key={`kanban-board-card-dnd-${kanbanBoardCard.id}`}
                          cardId={kanbanBoardCard.id}
                        >
                          <IndividualKanbanBoardCard
                            isBoardOwnerOrAdmin={isBoardOwnerOrAdmin}
                            handleOpen={handleOpen}
                            kanbanBoardCard={kanbanBoardCard}
                            setSelectedKanbanBoardCard={
                              setSelectedKanbanBoardCard
                            }
                            kanbanBoard={kanbanBoard}
                            setKanbanBoard={setKanbanBoard}
                          />
                        </DndKanbanCard>
                      ))}
                    </DndKanbanGrid>
                  ) : (
                    kanbanBoard.cards.map((kanbanBoardCard) => {
                      return (
                        <Box
                          key={`kanban-board-kanbanBoardCard-${kanbanBoardCard.id}`}
                        >
                          <IndividualKanbanBoardCard
                            isBoardOwnerOrAdmin={isBoardOwnerOrAdmin}
                            handleOpen={handleOpen}
                            kanbanBoardCard={kanbanBoardCard}
                            setSelectedKanbanBoardCard={
                              setSelectedKanbanBoardCard
                            }
                            kanbanBoard={kanbanBoard}
                            setKanbanBoard={setKanbanBoard}
                          />
                        </Box>
                      );
                    })
                  )}
                  {/* <Box sx={{ height: 250 }}> */}
                  <AddKanbanBoardCardButton
                    handleOpen={handleOpen}
                    kanbanBoardId={kanbanBoard.id}
                    setSelectedKanbanBoardCard={setSelectedKanbanBoardCard}
                  />

                  {/* </Box> */}
                </Masonry>
                {/* <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                      lg: "repeat(3, 1fr)",
                    },
                    gridAutoRows: "max-content",
                    gridGap: 16,
                    justifyContent: "center",
                    gridAutoFlow: "dense",
                  }}
                >
                  {isBoardOwnerOrAdmin ? (
                    <DndKanbanGrid
                      cards={kanbanBoard.cards}
                      setKanbanBoard={setKanbanBoard}
                    >
                      {kanbanBoard.cards.map((kanbanBoardCard, index) => (
                        <DndKanbanCard
                          key={`kanban-board-card-dnd-${kanbanBoardCard.id}`}
                          cardId={kanbanBoardCard.id}
                        >
                          <IndividualKanbanBoardCard
                            isBoardOwnerOrAdmin={isBoardOwnerOrAdmin}
                            handleOpen={handleOpen}
                            kanbanBoardCard={kanbanBoardCard}
                            setSelectedKanbanBoardCard={
                              setSelectedKanbanBoardCard
                            }
                            kanbanBoard={kanbanBoard}
                            setKanbanBoard={setKanbanBoard}
                          />
                        </DndKanbanCard>
                      ))}
                    </DndKanbanGrid>
                  ) : (
                    kanbanBoard.cards.map((kanbanBoardCard) => {
                      return (
                        <Fragment
                          key={`kanban-board-kanbanBoardCard-${kanbanBoardCard.id}`}
                        >
                          <IndividualKanbanBoardCard
                            isBoardOwnerOrAdmin={isBoardOwnerOrAdmin}
                            handleOpen={handleOpen}
                            kanbanBoardCard={kanbanBoardCard}
                            setSelectedKanbanBoardCard={
                              setSelectedKanbanBoardCard
                            }
                            kanbanBoard={kanbanBoard}
                            setKanbanBoard={setKanbanBoard}
                          />
                        </Fragment>
                      );
                    })
                  )}

                  <AddKanbanBoardCardButton
                    handleOpen={handleOpen}
                    kanbanBoardId={kanbanBoard.id}
                    setSelectedKanbanBoardCard={setSelectedKanbanBoardCard}
                  />
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const IndividualKanbanBoardCard = ({
  isBoardOwnerOrAdmin,
  handleOpen,
  kanbanBoardCard,
  setSelectedKanbanBoardCard,
  kanbanBoard,
  setKanbanBoard,
}: {
  isBoardOwnerOrAdmin: boolean;
  handleOpen: () => void;
  kanbanBoardCard: API_Teacher_Community_Kanban_Board_Card;
  setSelectedKanbanBoardCard: React.Dispatch<
    React.SetStateAction<API_Teacher_Community_Kanban_Board_Card>
  >;
  kanbanBoard: API_Teacher_Community_Kanban_Board_With_Card;
  setKanbanBoard: React.Dispatch<
    React.SetStateAction<API_Teacher_Community_Kanban_Board_With_Card>
  >;
}) => {
  const openSnack = useSnack();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = !!anchorEl;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));

  const handleLike = async () => {
    const res = await likeKanbanBoardCard(currLanguage, kanbanBoardCard.id);

    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      const cardsCopy: API_Teacher_Community_Kanban_Board_Card[] = JSON.parse(
        JSON.stringify(kanbanBoard.cards)
      );
      const cardIndex = cardsCopy.findIndex(
        (card_) => card_.id === kanbanBoardCard.id
      );
      cardsCopy[cardIndex].likes = res;
      setKanbanBoard((prev) => ({
        ...prev,
        cards: cardsCopy,
      }));
      if (kanbanBoardCard.likes.includes(profileDetails.id)) {
        openSnack(translation.unLiked, true);
      } else {
        openSnack(translation.liked, true);
      }
    }
  };
  // if (kanbanBoardCard.likes.length > 0) {
  //   console.log(
  //     "kanbanBoardCard.likes.includes(profileDetails.id): ",
  //     kanbanBoardCard.likes.includes(profileDetails.id)
  //   );

  //   console.log("kanbanBoardCard.likes:", kanbanBoardCard.likes);
  //   console.log("profileDetails.id: ", profileDetails.id);
  // }

  const isCardOwnerOrAdmin =
    isProfileInTTAModuleEditableRoleList(profileDetails) ||
    profileDetails.user === kanbanBoardCard.created_by;

  return (
    <Box sx={{ height: "100%", maxHeight: 400 }}>
      <Card
        sx={{
          // width: "25vw",
          height: "100%",
          borderRadius: "10px",
          backgroundColor: "#F0F0F0",
          filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))",
          transition: "200ms all ease-in-out",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          cursor: isBoardOwnerOrAdmin ? "pointer" : "",
        }}
        // onClick={() => {}}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: "13px 18px",
            flexGrow: 1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {/* <Title title={module.name} /> */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: 16 }}>
                  {kanbanBoardCard.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {isCardOwnerOrAdmin ? (
                  <IconButton
                    sx={{ alignSelf: "start" }}
                    id="basic-icon-button"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event);
                    }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                ) : (
                  <Box sx={{ width: 40, height: 40 }}> </Box>
                )}

                <IconButton
                  sx={{ alignSelf: "start" }}
                  id="thumb-off-button"
                  onClick={handleLike}
                >
                  <Badge badgeContent={kanbanBoardCard.likes.length}>
                    {kanbanBoardCard.likes.includes(profileDetails.id) ? (
                      <ThumbUpIcon sx={{ color: "primary.main" }} />
                    ) : (
                      <ThumbUpOffAltIcon />
                    )}
                  </Badge>
                </IconButton>
              </Box>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedKanbanBoardCard(kanbanBoardCard);
                    handleOpen();
                    handleMenuClose();
                  }}
                >
                  <Typography>{translation.edit}</Typography>
                </MenuItem>

                <DeleteCommentMenuItem
                  kanbanBoardCard={kanbanBoardCard}
                  handleMenuClose={handleMenuClose}
                  setKanbanBoard={setKanbanBoard}
                />
              </Menu>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const DeleteCommentMenuItem = ({
  handleMenuClose,
  kanbanBoardCard,
  setKanbanBoard,
}: {
  handleMenuClose: () => void;
  kanbanBoardCard: API_Teacher_Community_Kanban_Board_Card;
  setKanbanBoard: React.Dispatch<
    React.SetStateAction<API_Teacher_Community_Kanban_Board_With_Card>
  >;
}) => {
  const { isRefreshKanbanBoard, setIsRefreshKanbanBoard } = useStore(
    (state) => ({
      isRefreshKanbanBoard: state.isRefreshKanbanBoard,
      setIsRefreshKanbanBoard: state.setIsRefreshKanbanBoard,
    })
  );
  const openSnack = useSnack();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const handleDelete = async () => {
    const res = await deleteCommunityKanbanBoardCard(
      kanbanBoardCard.id,
      localStorage.getItem("access")!
    );
    if (typeof res === "string") {
      openSnack(res, false);
    } else {
      // setKanbanBoard(res);
      setIsRefreshKanbanBoard(true);
      openSnack(
        translation.kanbanBoardCommentDeleted || "Kanban Board Comment Deleted",
        true
      );
      handleMenuClose();
    }
  };

  const warningBody = {
    openDialog,
    handleCloseDialog,
    warningTitle: `Delete ${kanbanBoardCard.name}?`,
    warningContext: "The action is irreversable!",
    handleDelete,
  };

  return (
    <>
      <WarningDialog {...warningBody} />

      <MenuItem
        onClick={() => {
          handleOpenDialog();
        }}
      >
        {translation.delete}
      </MenuItem>
    </>
  );
};

const AddKanbanBoardCardButton = ({
  handleOpen,
  kanbanBoardId,
  setSelectedKanbanBoardCard,
}: {
  handleOpen: () => void;
  kanbanBoardId: number;
  setSelectedKanbanBoardCard: React.Dispatch<
    React.SetStateAction<API_Teacher_Community_Kanban_Board_Card>
  >;
}) => {
  const navigate = useNavigate();

  const { profileDetails, currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));

  return (
    <Box
      sx={{
        maxHeight: 150,
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        borderRadius: "10px",
        color: "#444",
        border: "1px dashed",
        borderColor: "#444",
        transition: "all 100ms ease-in-out",
        cursor: "pointer",
        "&:hover": {
          color: "primary.main",
          borderColor: "primary.main",
          backgroundColor: "rgba(209, 228, 227, 0.37)",
          transition: "all 100ms ease-in-out",
        },
      }}
      onClick={() => {
        setSelectedKanbanBoardCard({
          ...initialKanbanBoardCard,
          board: kanbanBoardId,
        });
        handleOpen();
      }}
    >
      <AddIcon />
    </Box>
  );
};

export default TeacherKanbanBoardPage;

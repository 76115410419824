import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";

type Props = {
  isSelected?: boolean;
  isFilterSelected?: boolean;
  title: string;
  icon: React.ReactElement;
  handleSelected: () => void;
}

const SideTabView = ({
  isSelected = false,
  isFilterSelected = false,
  title,
  icon,
  handleSelected,
}: Props) => {

  return (
    <Box
      sx={
        isSelected
          ? {
              display: "flex",
              alignItems: "center",
              width: "100%",
              minHeight: "50px",
              border: "1px solid #98C4C5",
              background: "rgba(209, 228, 227, 0.37)",
              borderRadius: "4px",
              cursor: "pointer",
              p: "4px 16px",
            }
          : {
              display: "flex",
              alignItems: "center",
              width: "100%",
              minHeight: "50px",
              border: "1px solid",
              borderColor: "neutral.300",
              borderRadius: "4px",
              cursor: "pointer",
              p: "4px 16px",
            }
      }
      onClick={handleSelected}
    >
      {React.cloneElement(icon, { fontSize: "small" })}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "14px" }}>{title}</Typography>
        {(isFilterSelected || isSelected) && (
          <Checkbox
            size={"small"}
            checked={isSelected}
          />
        )}
      </Box>
    </Box>
  );
};

export default SideTabView;

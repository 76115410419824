import { getProjectCategories } from "api/project-api";
import { useEffect, useState } from "react";
import useStore from "store/store";
import { ProjectCategory, ProjectCategoryModule } from "types/project";

export const useCategoryModules = () => {
  const [moduleList, setModuleList] = useState<ProjectCategoryModule[]>([]);
  const { currLanguage } = useStore((state) => ({
    profileDetails: state.profileDetails,
    currLanguage: state.currLanguage,
  }));
    
    useEffect(() => {
      const populateModules = async () => {
      const categories = await getProjectCategories(currLanguage);
      const updatedModule: ProjectCategoryModule[] = categories.reduce(
        (arr: ProjectCategoryModule[], curr: ProjectCategory) => [
          ...arr,
          ...(curr.modules.map(module => ({...module, assessment: curr.assessment}))),
        ],
        []
      );

      setModuleList(updatedModule);
    };

    populateModules();
  }, [currLanguage]);
  
  return {
    moduleList,  
  };
};
import { Dispatch, SetStateAction, ReactNode } from "react";

import { API_Project_Lesson } from "types/project";
import useSnack from "hooks/useSnack";
import useStore from "store/store";

import { editLesson } from "api/project-api";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";

const DndGrid = ({
  allLessons,
  setAllLessons,
  children,
}: {
  allLessons: API_Project_Lesson[];
  setAllLessons: Dispatch<SetStateAction<API_Project_Lesson[]>>;
  children: ReactNode;
}) => {
  const openSnack = useSnack();
  const { currLanguage, currentLessons, setCurrentLessons } = useStore(
    (state) => state);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = allLessons.findIndex((l) => l.id === active.id);
      const newIndex = allLessons.findIndex((l) => l.id === over.id);

      const orderedLessons = arrayMove(allLessons, oldIndex, newIndex);

      const orderDifference: {
        id: number;
        weight: number;
      }[] = [];

      orderedLessons.forEach((lesson, index) => {
        if (lesson.id !== allLessons[index].id) {
          orderDifference.push({
            id: lesson.id,
            weight: index + 1,
          });
        }
      });

      if (orderDifference.length !== 0) {
        orderDifference.forEach(async (lesson) => {
          await editLesson(currLanguage, lesson.id, {
            weight: lesson.weight,
          });
        });

        setCurrentLessons({
          ...currentLessons,
          lessons: orderedLessons,
        });
        setAllLessons(orderedLessons);

        openSnack("Reorder lessons successfully!", true);
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={allLessons.map((lesson) => lesson.id)}
        strategy={rectSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default DndGrid;

import { Page,  Image, View, StyleSheet } from "@react-pdf/renderer";
import { imageSrc, pageSize, styles } from "./constant";
import { PDFInfoType } from "../Preview/constant";
import LearningStoriesPageBackground from "images/children-portfolio-v2/learning-stories-background.png";

const templateStyles = StyleSheet.create({
  text: {
    fontWeight: 500,
    lineHeight: 1.25,
    textAlign: "left",
    position: "absolute",
    left: 0,
    right: 0,
  },
});

const LearningStoriesCover = ({ PDFInfo }: { PDFInfo: PDFInfoType }) => {
  return (
    <Page size={pageSize} style={styles.body} wrap={false}>
      <View style={styles.main} wrap={false}>
        <Image
          src={imageSrc(LearningStoriesPageBackground)}
          style={styles.background}
        />
      </View>
    </Page>
  );
};

export default LearningStoriesCover;

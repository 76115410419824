import React, { useEffect, useState } from "react";

import { Avatar, Box, IconButton, useTheme } from "@mui/material";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Logo from "images/logo-2-colour.png";
import Image from "components/Image/Image";

const CertificateImageField = ({
  image,
  setCurrentCertificateData,
}: {
  image: string;
  setCurrentCertificateData: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const theme = useTheme();
  const [currImage, setCurrImage] = useState<string>();
  useEffect(() => {
    setCurrImage(image);
  }, [image]);
  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      <Image
        src={currImage ? currImage : Logo}
        alt={"certificate-image"}
        style={{
          maxWidth: 350,
          maxHeight: 350,
          color: "txt.light",
          border: "2px solid",
          borderColor: theme.palette.primary.main,
          fontSize: "8vw",
        }}
      />

      <input
        accept="image/*"
        type="file"
        id={`certificate-image-button`}
        hidden
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            setCurrentCertificateData((prev: any) => ({
              ...prev,
              image: event.target.files![0],
            }));
          }
        }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: 8,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "10%",
          // backgroundColor: "primary.main",
          opacity: 0.5,
          transition: "all 150ms linear",
          "&:hover": {
            opacity: 1,
            transition: "all 150ms linear",
          },
        }}
      >
        <label htmlFor={`certificate-image-button`}>
          <IconButton
            component="span"
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: "2.5vw",
                color: theme.palette.primary.light,
                cursor: "pointer",
                transition: "all 50ms linear",
                "&:hover": {
                  color: "txt.secondary",
                  transition: "all 50ms linear",
                },
              }}
            />
          </IconButton>
        </label>
      </Box>
    </Box>
  );
};

export default CertificateImageField;

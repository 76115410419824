import React, { useEffect, useState } from "react";
import { getMessage } from "api/chat-api";
import useStore from "store/store";

const useMessageLoad = (pageNumber: number, chatID: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const { allChats, setAllChats } = useStore((state) => ({
    allChats: state.allChats,
    setAllChats: state.setAllChats,
  }));

  useEffect(() => {
    const updatedChats = allChats;
    const currChatIndex = updatedChats.findIndex(
      (chat) => chat.id.toString() === chatID
    );
    if (updatedChats[currChatIndex]) {
      updatedChats[currChatIndex].latest_messages = [];
      setAllChats(updatedChats);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    console.log("pageNumber: ", pageNumber);
    const populateMessages = async () => {
      if (pageNumber !== 0) {
        const messages = await getMessage(chatID, pageNumber);
        if (typeof messages === "string") {
          console.log(messages);
        } else {
          const updatedChats = allChats;
          const currChatIndex = updatedChats.findIndex(
            (chat) => chat.id.toString() === chatID
          );
          updatedChats[currChatIndex].latest_messages = [
            ...updatedChats[currChatIndex].latest_messages,
            ...messages.results,
          ];
          setAllChats(updatedChats);
          setHasMore(messages.next !== null);
        }
      }
    };

    populateMessages();
    setLoading(false);
  }, [pageNumber]);

  return [loading, hasMore];
};

export default useMessageLoad;

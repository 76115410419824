import Backdrop from "@mui/material/Backdrop";

import { Box, Modal as ModalMUI, Fade, SxProps } from "@mui/material";

type Props = {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  sx?: SxProps;
};

const Modal = ({ open, handleClose, children, sx }: Props) => {
  return (
    <ModalMUI
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClick={(event: any) => event.stopPropagation()}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "neutral.50",
            width: "45%",
            maxHeight: "83vh",
            p: 2,
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 3,
            overflow: "auto",
            maxWidth: "100%",
            minWidth: "280px",
            overflowX: "hidden",
            ...sx,
          }}
        >
          {children}
        </Box>
      </Fade>
    </ModalMUI>
  );
};

export default Modal;

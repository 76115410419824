import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";

import {
  API_Create_Lesson,
} from "types/project";
import { translation } from "constants/translation";
import RichCKEditor from "components/RichCKEditor/RichCKEditor";

type Props = {
  label?: string; 
  lesson: API_Create_Lesson;
  setLesson: React.Dispatch<React.SetStateAction<API_Create_Lesson>>;
  onChange?: (input: string) => void;
}

const InstructionsField = ({
  label,
  lesson,
  setLesson,
  onChange,
}: Props) => (
  <Box>
    <Typography sx={{ mb: "8px" }}>
      {label ?? translation.recommendedSetupInstructionIfAny}
    </Typography>
    <RichCKEditor
      data={lesson.instruction}
      onChange={(event: any, editor: any) => {
        const data: string = editor.getData();

        setLesson((prev) => ({ ...prev, instruction: data }));
        onChange && onChange(data);
      }}
    />
  </Box>
);

export default InstructionsField;
import React from "react";
import { useNavigate } from "react-router-dom";

import useStore from "store/store";

import styles from "./Navbar.module.css";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";

import LogoImage from "images/logo.png";
import LoginButtonImage from "images/login-button.png";

import Image from "components/Image/Image";

import { TITLE } from "constants/title";

import {
  CURLANG,
  HOME,
  LOGIN,
  TEACHER_CERTIFICATE_MANAGE_PAGE,
  TEACHER_MODULE_MANAGE_PAGE_WITH_MODE,
  TTA_PROFILE_PAGE,
} from "constants/url";
import { logoutAccount } from "api/auth-api";
import { translation } from "constants/translation";
import { currGroupInitialState } from "containers/GroupPage/constants";
import { isProfileInTTAModuleEditableRoleList } from "helper/helper";
import useSnack from "hooks/useSnack";
import { Divider, Typography } from "@mui/material";
import { ACCESS_CONTROL } from "constants/staticConstant";
import { initialProfileDetails } from "store/auth-slice";

const TTALoginButton = () => {
  const navigate = useNavigate();
  const openSnack = useSnack();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    currLanguage,
    profileDetails,
    setProfileID,
    setProfileDetails,
    setCurrGroup,
    setGroupList,
    setChildrenInSession,
  } = useStore((state) => state);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    document.title = TITLE;
    logoutAccount();
    setProfileID("");
    setProfileDetails(initialProfileDetails);
    setCurrGroup(currGroupInitialState);
    setGroupList([]);
    setChildrenInSession([]);
    openSnack("Logged out successfully!", true);
    navigate(`${CURLANG(currLanguage)}/${HOME}`);
    setAnchorEl(null);
  };

  const handleToggleMode = () => {
    document.title = `${TITLE} | Toggle Mode`;
    navigate(`${CURLANG(currLanguage)}/${HOME}`);
    setAnchorEl(null);
  };

  const handleProfile = () => {
    document.title = `${TITLE} | Profile`;
    navigate(
      `${CURLANG(currLanguage)}/${TTA_PROFILE_PAGE(
        profileDetails.id.toString()
      )}`
    );
    setAnchorEl(null);
  };

  // const handleFeedback = () => {
  //   document.title = `${TITLE} | Add Feedback`;
  //   navigate(`${CURLANG(currLanguage)}/${CREATE_TICKET_PAGE}`);
  //   setAnchorEl(null);
  // };

  const handleManageModule = () => {
    document.title = `${TITLE} | Manage Module`;
    navigate(
      `${CURLANG(currLanguage)}/${TEACHER_MODULE_MANAGE_PAGE_WITH_MODE(
        ACCESS_CONTROL
      )}`
    );
    setAnchorEl(null);
  };

  const handleManageCertificate = () => {
    document.title = `${TITLE} | Manage Certificate`;
    navigate(`${CURLANG(currLanguage)}/${TEACHER_CERTIFICATE_MANAGE_PAGE}`);
    setAnchorEl(null);
  };

  // const handleViewFeedback = () => {
  //   document.title = `${TITLE} | View Feedback`;
  //   navigate(`${CURLANG(currLanguage)}/${TICKETING_SYSTEM}`);
  //   setAnchorEl(null);
  // };

  // const handleCreateProject = () => {
  //   document.title = `${TITLE} | Create Project`;
  //   navigate(`${CURLANG(currLanguage)}/${CREATE_PROJECT_PAGE}`);
  //   setAnchorEl(null);
  // };

  // const handleManageProhibitedWord = () => {
  //   document.title = `${TITLE} | Manage Prohibited Word`;
  //   navigate(`${CURLANG(currLanguage)}/${MANAGE_PROHIBITED_WORD}`);
  //   setAnchorEl(null);
  // };

  return (
    <Box sx={{ flexGrow: 0, display: "flex", gap: "6px" }}>
      {profileDetails.id !== -1 ? (
        <>
          <Avatar
            src={profileDetails.image}
            sx={{ cursor: "pointer", mr: "4px" }}
            onClick={handleClick}
            imgProps={{
              onError: (
                event: React.SyntheticEvent<HTMLImageElement, Event>
              ) => {
                event.currentTarget.onerror = null;
                event.currentTarget.src = LogoImage;
              },
            }}
          >
            {profileDetails.preferred_name &&
            profileDetails.preferred_name !== ""
              ? profileDetails.preferred_name.charAt(0).toUpperCase()
              : ""}
          </Avatar>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              "& .MuiMenuItem-root": {
                margin: "5px",
                "&:hover": {
                  backgroundColor: "txt.secondary",
                  borderRadius: "5px",
                  color: "primary.light",
                },
              },
            }}
          >
            <MenuItem
              onClick={handleToggleMode}
              sx={{
                color: "txt.secondary",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <Typography>{translation.switchToTTB}</Typography>
            </MenuItem>
            <Divider />
            {profileDetails.role !== "AD" && (
              <MenuItem onClick={handleProfile}>{translation.profile}</MenuItem>
            )}
            {isProfileInTTAModuleEditableRoleList(profileDetails) && (
              <MenuItem onClick={handleManageModule}>
                {translation.manageModule}
              </MenuItem>
            )}

            {isProfileInTTAModuleEditableRoleList(profileDetails) && (
              <MenuItem onClick={handleManageCertificate}>
                {translation.manageCertificates}
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>{translation.logout}</MenuItem>
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            width: "100px",
          }}
          onClick={() => {
            navigate(`${CURLANG(currLanguage)}/${LOGIN}`);
          }}
        >
          <Image
            src={LoginButtonImage}
            alt="Login Button"
            className={styles.loginBtn}
          />
        </Box>
      )}
    </Box>
  );
};

export default TTALoginButton;

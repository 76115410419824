import { useState } from "react";
import useStore from "store/store";

import { Box, Typography, Modal, Backdrop, Fade, Avatar } from "@mui/material";
import Logo from "images/logo-2-colour.png";
import Image from "../Image/Image";
import { API_Review_Image } from "types/review";
import { Profile } from "./Components";
import { UserRoleMappingInverse } from "types/auth";
import { API_Project_Review } from "types/project";

const ReviewModalReadOnly = ({
  open,
  handleModalClose,
  review,
}: {
  open: boolean;
  handleModalClose: (e: any) => void;
  review: API_Project_Review;
}) => {
  const { profileDetails } = useStore((state) => ({
    profileDetails: state.profileDetails,
  }));

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: "rgba(49,47,48,0.4)",
        },
      }}
      sx={{
        m: "24px",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            overflow: "auto",
            maxHeight: "100%",
            "&:focus-visible": {
              outline: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ProfileDetails
              avatar={review.profile.image}
              name={review.profile.preferred_name}
              role={UserRoleMappingInverse[review.profile.role]}
              school={
                review.profile.school
                  ? review.profile.school.name
                  : "Little Lab"
              }
            />

            <Typography>{review.comment}</Typography>

            <ImageField images={review.images} />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const ProfileDetails = ({
  avatar,
  name,
  role,
  school,
}: {
  avatar: string;
  name: string;
  role: string;
  school: string;
}) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      gap: 1.5,
      mb: 2,
    }}
  >
    <Avatar
      src={avatar}
      alt={name}
      sx={{ width: "40px", height: "40px", mb: 0.25 }}
      imgProps={{
        onError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
          event.currentTarget.onerror = null;
          event.currentTarget.src = Logo;
        },
      }}
    />

    <Profile name={name} role={role} school={school} />
  </Box>
);

const ImageField = ({ images }: { images: API_Review_Image[] }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {images.map((img, index) => (
          <Box
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "10px",
              overflow: "hidden",
              position: "relative",
            }}
            key={index}
          >
            <Image src={img.image} alt={`image-${index}`} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ReviewModalReadOnly;

import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useStore from "store/store";
import { translation } from "constants/translation";
import {
  CURLANG,
  TEACHER_MODULE_PAGE,
} from "constants/url";

import Button from "components/Button/Button";
import BackButton from "./BackButton";

const PageTitle = ({
  moduleSlug,
  handleClose,
}: {
  moduleSlug: string;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const { currLanguage } = useStore((state) => ({
    currLanguage: state.currLanguage,
  }));
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <BackButton handleClose={handleClose} />
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "600",
          textAlign: "center",
          color: "#323031",
        }}
      >
        Edit Module
      </Typography>

      <Button
        buttonText="View Module"
        style={{
            fontSize: "14px",
            fontWeight: "450",
            lineHeight: 1.57,
            padding: "4px 10px",
            borderWidth: "1px",
            borderRadius: "4px",
        }}
        btnType={"outlined"}
        arrow={false}
        onClick={() => {
          navigate(
            `${CURLANG(currLanguage)}/${TEACHER_MODULE_PAGE(moduleSlug)}`
          );
        }}
      />
    </Box>
  );
};

export default PageTitle;
